(function () {

    'use strict';

    function countryService($http, $q, $log) {
        var countries = [];
        var loading = null;

        function _loadAll() {
            return $http.get('api/country').then(function (response) {
                countries = response.data;
                return response;
            }, function (error) {
                $log.error('Couldn\'t load countries!', error);
            });
        }

        function _getAll() {
            var deferred = $q.defer();
            if (countries === null || countries.length === 0) {
                if (loading === null) {
                    loading = _loadAll();
                }
                loading.then(function () {
                    deferred.resolve(countries);
                });
            }
            else {
                deferred.resolve(countries);
            }
            return deferred.promise;
        }

        function _getOne(id) {
            var deferred = $q.defer();
            if (countries == null || countries.length === 0) {
                _getAll().then(function () {
                    deferred.resolve(_findOne(id));
                });
            }
            else {
                deferred.resolve(_findOne(id));
            }
            return deferred.promise;
        }

        function _findOne(id) {
            for (var i = 0; i < countries.length; i++) {
                if (countries[i].numericCode === id) {
                    return countries[i];
                }
            }
            return {name: 'NOTFOUND'};
        }

        return {
            getAll: _getAll,
            get: _getOne
        };
    }

    angular.module('emsv2App').service('Country', countryService);

})();
