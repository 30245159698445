'use strict';

/**
 * @description Class to run db scan cluster analysis for sensors, used for multi sensor labels, https://de.wikipedia.org/wiki/DBSCAN
 * @constructor
 */
function DBScanSensors() {};

/**
 * @description function to run dbscan algorithm for sensor position, see https://de.wikipedia.org/wiki/DBSCAN for details
 * @param {array} sensors array of sensor objects
 * @param {number} epsilon defines the ε-neighborhood
 * @returns {Array} returns array of clusters
 */
DBScanSensors.scan = function (sensors, epsilon) {
    var clusters = [];
    var sensors2 = sensors.slice(0);
    var visited = [];
    var idx2 = 0;
    var minPts = 1;
    while (sensors2.length > idx2) {
        var s = sensors2[idx2];
        if (!DBScanSensors.isSensorVisited(visited, s)) {
            visited.push(s);
            var neigh0 = DBScanSensors.getNeightbors(s, sensors2, epsilon);
            if (neigh0.length >= minPts) {
                var idx0 = 0;
                while (neigh0.length > idx0) {
                    var s0 = neigh0[idx0];
                    if (!DBScanSensors.isSensorVisited(visited, s0)) {
                        visited.push(s0);
                    }
                    var neigh1 = DBScanSensors.getNeightbors(s0, sensors2, epsilon);
                    if (neigh1.length >= minPts) {
                        neigh0 = DBScanSensors.merge(neigh0, neigh1);
                    }
                    idx0++;
                }
                if (!DBScanSensors.checkClusters(clusters, neigh0)) {
                    clusters.push(neigh0);
                }
            }
        }
        idx2++;
    }
    return clusters;
};

/**
 * @description function to get all neighbors in epsilon neighborhood for sensor s
 * @param {Sensor} s the sensor to find neighbors for
 * @param {Sensor[]} sensors array of all sensor to be used
 * @param {number} epsilon epsilon-neighborhood
 * @returns {Array} returns array of neighbors for provided sensor s
 */
DBScanSensors.getNeightbors = function (s, sensors, epsilon) {
    var result = [];
    for (var i = 0; i < sensors.length; i++) {
        var d = DBScanSensors.getDistance(s, sensors[i]);
        if (d <= epsilon) {
            result.push(sensors[i]);
        }
    }
    return result;
};

/**
 * @description function to get euclidean distance between the 2 provided sensors
 * @param {Sensor} s0 first sensor to use
 * @param {Sensor} s1 second sensor to use
 * @returns {*} returns euclidean distance between the 2 provided sensors
 */
DBScanSensors.getDistance = function (s0, s1) {
    return new THREE.Vector3(s0.pos.x, s0.pos.y, s0.pos.z).distanceTo(new THREE.Vector3(s1.pos.x, s1.pos.y, s1.pos.z));
};

/**
 * @description function to determine if provided sensor was already visited
 * @param {array} visited array of visited sensors
 * @param {Sensor} s sensor object to check if it was already visited
 * @returns {boolean} returns true if provided sensor s was already visited, otherwise false
 */
DBScanSensors.isSensorVisited = function (visited, s) {
    for (var i = 0; i < visited.length; i++) {
        if (s.id == visited[i].id) {
            return true;
        }
    }
    return false;
};

/**
 * @description function to merge 2 arrays of objects
 * @param {array} items0 array to merge into
 * @param {array} items1 array to merge from
 * @returns {*} returns merged array
 */
DBScanSensors.merge = function (items0, items1) {
    for (var i = 0; i < items1.length; i++) {
        if (!DBScanSensors.doesArrayContainSensor(items0, items1[i])) {
            items0.push(items1[i]);
        }
    }
    return items0;
};

/**
 * @description function to test whether provided sensor is already contained in provided array
 * @param {Sensor[]} arr array of sensor objects to test
 * @param {Sensor} s sensor to check if it is already contained in provided array
 * @returns {boolean} returns true if sensor is contained in provided array
 */
DBScanSensors.doesArrayContainSensor = function (arr, s) {
    for (var i = 0; i < arr.length; i++) {
        if (s.id == arr[i].id) {
            return true;
        }
    }
    return false;
};

/**
 * @description function to check if provided neighborhood of sensor objects is contained in cluster array
 * @param {array} clusters array of clusters
 * @param {array} neigh array of sensors objects defining a neighborhood
 * @returns {boolean} returns true if neighborhood is already in contained in cluster array, otherwise false
 */
DBScanSensors.checkClusters = function (clusters, neigh) {
    var ret = false;
    for (var i = 0; i < clusters.length; i++) {
        if (DBScanSensors.checkCluster(clusters[i], neigh)) {
            ret = true;
            break;
        }
    }
    return ret;
};

/**
 * @description function to check if cluster matches with provided neighborhood
 * @param {Sensor[]} cluster the cluster to check
 * @param {Sensor[]} neigh the neighborhood to check
 * @returns {boolean}
 */
DBScanSensors.checkCluster = function (cluster, neigh) {
    var ret = true;
    for (var i = 0; i < cluster.length; i++) {
        for (var j = 0; j < neigh.length; j++) {
            if (!new THREE.Vector3(cluster[i].pos.x, cluster[i].pos.y, cluster[i].pos.z).equals(new THREE.Vector3(neigh[j].pos.x, neigh[j].pos.y, neigh[j].pos.z))) {
                return false;
            }
        }
    }
    return ret;
};

/**
 * @description function to find 'center point' for provided cluster
 * @param {array} cluster cluster array of sensors
 * @returns {*} returns 3-dimensional center point for provided cluster of sensors
 */
DBScanSensors.findClusterCenter = function (cluster) {
    var x = 0, y = 0, z = 0;
    for (var i = 0; i < cluster.length; i++) {
        x += cluster[i].pos.x;
        y += cluster[i].pos.y;
        z += cluster[i].pos.z;
    }
    return new THREE.Vector3(x, y, z).multiplyScalar(1 / cluster.length);
};
