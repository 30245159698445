(function () {
    'use strict';

    function systemPropsController($state, $log, $window, $interval, Notify, GenDialogService, $scope, $translate, SystemProps) {

        var ctrl = this;
        var togglePassword = document.querySelector('#togglePassword');
        var icon = document.querySelector('#showPWIcon');
        var password = document.querySelector('#dbUserPwassword');
        var timeoutId = $interval(function () {
            ctrl.updateDate();
        }, 1000);

        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
        });

        ctrl.$onInit = function(){
            ctrl.getDBInfo();
            ctrl.getLiveDataInfo();
            ctrl.serverTime();
        };

        ctrl.db = {};
        ctrl.livedata = {};
        ctrl.sTime = null;
        ctrl.timeFormat = [
            {label: '24h', value: 'HH:mm:ss'},
            {label: '12h AM / PM', value: 'hh:mm:ss a'}
        ];
        ctrl.currentTimeFormat = ctrl.timeFormat[0];

        togglePassword.addEventListener('click', function (e) {
            // toggle the type attribute
            var type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
            // toggle the eye slash icon
            icon.classList.toggle('glyphicon-eye-close');
        });

        ctrl.getDBInfo = function () {
            SystemProps.getDatabaseInfo().then(function (response) {
                ctrl.db = response.data
            });
        };

        ctrl.getLiveDataInfo = function () {
            SystemProps.getLivedataInfo().then(function (response) {
                ctrl.livedata = response.data
            });
        };

        ctrl.serverTime = function () {
            SystemProps.getServerTime().then(function (response) {
                ctrl.serverTimezone = response.data;

            });
        };

        ctrl.updateDate = function () {
            var out = moment().tz(ctrl.serverTimezone);
            ctrl.currentTime = out.format(ctrl.currentTimeFormat.value);
        };

    }
    angular
        .module('emsv2App')
        .controller('systemPropsController', systemPropsController);
})();
