(function () {
    'use strict';
    var HeatmapController = function ($scope, $state, $translate, HeatmapService, $http, $log, $interval, ChangeService, Principal) {
        var ctrl = this;
        ctrl.timeoutId = null;
        ctrl.timeoutInterval = 3000;
        ctrl.heatmaps = null;
        ctrl.date = null;
        ctrl.temperaturePhysType = "°C";

        ctrl.newMap = function () {
            $state.go('heatmap.create');
        };
        ctrl.viewMap = function (map) {
            $state.go('heatmap.view', {id: map.id});
        };
        ctrl.editMap = function (map) {
            $state.go('heatmap.edit', {id: map.id});
        };
        ctrl.heatmapToDeleteID = -1;
        ctrl.setHeatmapToDelete = function (map) {
            ctrl.heatmapToDeleteID = map.id;
            $translate('heatmap.deleteMsg').then(function (value) {
                ctrl.deleteMsg = value;
                ctrl.deleteMsg = ctrl.deleteMsg.replace(/nameDummy/g, '<b class="text-danger text-">' + map.name + '</b>');
                $('#heatmapModal').modal('show');
            }, function () {});
        };
        ctrl.deleteMsg = "";
        ctrl.deleteMap = function () {
            var map = ctrl.heatmaps.filter(function (elem) {return elem.id == ctrl.heatmapToDeleteID;})[0];
            HeatmapService.deleteHeatmap(map)
                .success(function (response) {
                    if (response == 1) {
                        HeatmapService.getHeatmaps()
                            .success(function (response) {
                                ctrl.heatmaps = response;
                                ctrl.heatmapToDeleteID = -1;
                                $('#heatmapModal').modal('hide');
                            })
                            .error(function (response) {
                                $log.error("Error retrieving heatmap data\n" + response);
                                $('#heatmapModal').modal('hide');
                            });
                    }
                })
                .error(function (response) {
                    $log.error("Error delete heatmap\n" + response);
                    $('#heatmapModal').modal('hide');
                });
        };
        ctrl.toggleUpdate = function () {
            if (angular.isDefined(ctrl.timeoutId) && ctrl.timeoutId != null) {
                $interval.cancel(ctrl.timeoutId);
                ctrl.timeoutId = null;
            }
            else {
                ctrl.timeoutId = $interval(function () {
                    ctrl.continuouslyUpdateHeatmapData();
                }, ctrl.timeoutInterval);
            }
        };

        ctrl.continuouslyUpdateHeatmapData = function () {
            var uidList = [];
            for (var map in ctrl.heatmaps) {
                uidList.push(ctrl.heatmaps[map].uniqueId);
            }
            // 22 ordinal for heatmap in enum
            ChangeService.getListOfOjects(22, uidList, ctrl.date.getTime())
                .success(
                    function (response) {
                        if (angular.isDefined(response) && response !== null && response.length > 0) {
                            $log.info("List of Objects: ", response);
                            for (var newMap in response) {
                                var found = false;
                                for (var oldMap in ctrl.heatmaps) {
                                    if (response[newMap].uniqueId === ctrl.heatmaps[oldMap].uniqueId) {
                                        if (response[newMap].hasOwnProperty('deleted')) {
                                            if (response[newMap].deleted == true) {
                                                ctrl.heatmaps.splice(oldMap, 1);
                                            }
                                        }
                                        else {
                                            ctrl.heatmaps[oldMap] = response[newMap];
                                            $log.info("Replaced old map. new Data -> ", response[newMap]);
                                        }
                                        found = true;
                                    }
                                }
                                if (!found) {
                                    ctrl.heatmaps.push(response[newMap]);
                                }
                            }
                            ctrl.date = new Date();
                        }
                        else {
                            $log.info("Maps are uptodate.");
                        }
                    })
                .error(function (response) {
                    $log.error("Heatmap: Could not get changed object:\n", response);
                });
        };
        ctrl.hasRole = function (role) {
            return Principal.isInRole('ROLE_' + role);
        };

        ctrl.$onInit = function () {
            Principal.identity().then(function (user) {
                $scope.user = user;
                if(user.settings[1] && user.settings[1] !== undefined) {
                    if (user.settings[1].value === "0") {
                        ctrl.temperaturePhysType = "°C";
                    } else if (user.settings[1].value === "1") {
                        ctrl.temperaturePhysType = "°F";
                    } else if (user.settings[1].value === "2") {
                        ctrl.temperaturePhysType = "K";
                    }
                }
            }, function (error) {
                $log.error('Couldn\'t load user: ', error);
            });
            HeatmapService.getHeatmaps()
                .success(function (response) {
                    ctrl.heatmaps = response;
                    ctrl.date = new Date();
                })
                .error(function (response) {
                    console.error("Error getting heatmap information:\n", response);
                });
        };

        $scope.$on('$destroy', function(){ });

    };
    angular
        .module('emsv2App')
        .controller('HeatmapController', HeatmapController);
})();
