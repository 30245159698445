/**
 * Component for a (editable) building.
 */

(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name building
     * @param {Object} ngModel - Set building.edit to true to enable edit mode
     * @param {Object} location - For extra information
     * @param {Object[]} devices - Array of available devices
     * @requires $log, Tools, Buildings
     */
    angular
        .module('emsv2App')
        .component('building', {
            bindings: {
                ngModel: '=',
                location: '=',
                devices: '<',
                buildings: '=',
                rooms: '=',
                user: '=',
                allowRooms: '<'
            },
            templateUrl: 'scripts/components/building/building.component.html',
            controller: ['$log', '$timeout', '$scope', 'leafletData', 'Tools', 'Buildings', 'MessageService', 'EMSConstants', 'RoomService', 'LocationDetailService',
                function ($log, $timeout, $scope, leafletData, Tools, Buildings, MessageService, EMSConstants, RoomService, LocationDetailService) {
                    var ctrl = this;

                    ctrl.map = {
                        defaults: {
                            scrollWheelZoom: true
                        }
                    };
                    ctrl.EditBuildingForm = {};
                    ctrl.autoValue = '';
                    ctrl.positionSelect = 'manual';

                    var oldPosSelect = 'manual';

                    var currentMap;
                    var markerLayer;
                    var copiedBuilding;
                    var marker;

                    var saveInProgress = false;
                    var ignoreDueToDrag = false;

                    ctrl.$onInit = function () {
                        var edit = !!ctrl.ngModel.edit;
                        ctrl.availableDevices = angular.copy(ctrl.devices);
                        ctrl.positionSelect = (ctrl.ngModel.lat === 0 && ctrl.ngModel.lng === 0) ? 'auto' : 'manual';
                        Buildings.setupDevices(ctrl.buildings, ctrl.availableDevices);
                        Buildings.setupRooms(ctrl.buildings, ctrl.rooms);
                        if (ctrl.ngModel.id !== null && ctrl.ngModel.id > 0)
                            ctrl.ngModel = ctrl.buildings.filter(function (b) {
                                return b.id == ctrl.ngModel.id;
                            })[0];
                        // if (ctrl.ngModel.id < 0)
                        //     ctrl.buildings.push(ctrl.ngModel);
                        ctrl.ngModel.edit = edit;
                        copiedBuilding = ctrl.ngModel.clone();
                        leafletData.getMap('buildingmap').then(function (map) {
                            currentMap = map;
                            currentMap.on('dblclick', _onMapDblClick);
                            // currentMap.on('contextmenu', function (e) {
                            //     if (e.button === 2) {
                            //         console.log("Context menu");
                            //     }
                            // });
                            // Doesn't work (here) if immediately set. Works in map widget though..
                            $timeout(function () {
                                markerLayer = Buildings.setupBuildingMarkers(ctrl.location, [ctrl.ngModel], currentMap, markerLayer, undefined, undefined);
                                if (ctrl.ngModel.geoDataValid()) {
                                    marker = Buildings.markOnMap(markerLayer, ctrl.ngModel, false);
                                    // marker.on('dragstart', _onMarkerDragStart);
                                    // marker.on('drag', _onMarkerDrag);
                                    // marker.on('dragend', _onMarkerDragEnd);
                                }
                            }, 50);
                        }, function (error) {
                            $log.error('Couldn\'t get map instance!', error);
                        });
                    };

                    ctrl.$doCheck = function () {
                        if (!ctrl.ngModel.geoDataEqual(copiedBuilding) && !ignoreDueToDrag) {
                            copiedBuilding = ctrl.ngModel.clone();
                            if (currentMap !== undefined) {
                                markerLayer = Buildings.setupBuildingMarkers(ctrl.location, [ctrl.ngModel], currentMap, markerLayer, undefined, undefined);
                                marker = Buildings.markOnMap(markerLayer, ctrl.ngModel, ctrl.positionSelect === 'drag');
                                if (ctrl.positionSelect === 'drag') {
                                    setupMarkerDragEvents();
                                }
                            }
                        }
                        if (oldPosSelect !== ctrl.positionSelect) {
                            oldPosSelect = ctrl.positionSelect;

                            if (ctrl.positionSelect === 'drag') {
                                marker = Buildings.markOnMap(markerLayer, ctrl.ngModel, true);
                                setupMarkerDragEvents();
                            } else {
                                marker = Buildings.markOnMap(markerLayer, ctrl.ngModel, false);
                            }
                        }
                    };

                    ctrl.handlePositionSelectChange = function (type) {
                        ctrl.positionSelect = type;
                    };

                    var setupMarkerDragEvents = function () {
                        if(Tools.isDefinedNotNull(marker)) {
                            marker.on('dragstart', _onMarkerDragStart);
                            marker.on('dragend', _onMarkerDragEnd);
                            marker.on('drag', _onMarkerDrag);
                        }
                    };

                    var _onMapDblClick = function (e) {
                        if (!ctrl.ngModel.geoDataValid() && ctrl.positionSelect === 'drag') {
                            ctrl.ngModel.lat = e.latlng.lat;
                            ctrl.ngModel.lon = e.latlng.lng;
                            ctrl.ngModel.alt = ctrl.location.pos_alt !== null ? ctrl.location.pos_alt : 0;
                        }
                    };

                    var _onMarkerDragStart = function (e) {
                        $log.debug("drag start");
                        ignoreDueToDrag = true;
                    };

                    var _onMarkerDrag = function (e) {
                        $log.debug("dragging");
                        ctrl.ngModel.lat = e.latlng.lat;
                        ctrl.ngModel.lon = e.latlng.lng;

                        // console.log(ctrl.ngModel.lat + "|" + ctrl.ngModel.lon);
                    };

                    var _onMarkerDragEnd = function (e) {
                        $log.debug("drag end");
                        ctrl.ngModel.lat = e.target._latlng.lat;
                        ctrl.ngModel.lon = e.target._latlng.lng;
                        ignoreDueToDrag = false;
                    };

                    ctrl.onAutoPositionChange = function () {
                        var split = String(ctrl.autoValue).split(';');
                        ctrl.ngModel.lat = parseFloat((split.length > 2) ? split[1] : 0);
                        ctrl.ngModel.lon = parseFloat((split.length > 2) ? split[0] : 0);
                        ctrl.ngModel.alt = parseFloat((split.length > 2) ? split[2] : 0);
                        ctrl.positionSelect = 'manual';
                    };

                    // Concat the URL to the selected device
                    ctrl.getFindLocationUrl = function () {
                        return Tools.getFindLocationUrl(ctrl.ngModel.street, ctrl.location.plz, ctrl.location.place);
                    };

                    ctrl.addRoom = function (room) {
                        Buildings.addRoom(ctrl.ngModel, room);
                    };

                    MessageService.subscribe("removeBuildingAssociation", function (msg, opts) {
                        removeRoom(opts);
                    });

                    MessageService.subscribe("deletedRoom", function (msg, opts) {
                        var rooms = [];
                        RoomService.getRooms(ctrl.location.id).then(function (response) {
                                for (var i in response.data) {
                                    rooms.push(Room.parseFromHtmlObject(response.data[i]));
                                }
                                ctrl.rooms = rooms;
                                for (var i in ctrl.ngModel.roomsFull) {
                                    if (ctrl.ngModel.roomsFull[i].id === opts.roomid) {
                                        ctrl.ngModel.roomsFull.splice(parseInt(i), 1);
                                        return;
                                    }
                                }

                                for (var i in ctrl.ngModel.rooms) {
                                    if (ctrl.ngModel.rooms[i].fkRoom === opts.roomid) {
                                        ctrl.ngModel.rooms.splice(parseInt(i), 1);
                                    }
                                }

                                Buildings.setupRooms(ctrl.buildings, ctrl.rooms);
                            },
                            function (error) {
                            })

                    });

                    var removeRoom = function (room) {
                        Buildings.removeRoom(ctrl.ngModel, room);
                    };

                    ctrl.addDevice = function (device) {
                        var newDevice = new BuildingDevice(device.mac, ctrl.ngModel.id, '');
                        newDevice.name = device.name;
                        newDevice.hwtyp = angular.copy(device.hwtyp);
                        newDevice.macStr = Tools.formatMac(Tools.intToHex(device.mac));
                        if (!ctrl.ngModel.hasOwnProperty('devices')) ctrl.ngModel.devices = [];
                        if (ctrl.ngModel.devices.filter(function (device) {
                            return device.name === newDevice.name
                        }).length === 0) {
                            ctrl.ngModel.devices.push(newDevice);
                            for (var i in ctrl.availableDevices) {
                                if (ctrl.availableDevices[i].mac === device.mac) {
                                    ctrl.availableDevices.splice(i, 1);
                                }
                            }
                        }
                        Buildings.setupDevices(ctrl.buildings, ctrl.availableDevices);
                    };

                    ctrl.removeDevice = function (device) {

                        for (var i in ctrl.ngModel.devices) {
                            if (ctrl.ngModel.devices[i].mac === device.mac) {
                                if (ctrl.availableDevices.filter(function (device) {
                                    return device.name === ctrl.ngModel.devices[i].name
                                }).length === 0) {
                                    ctrl.availableDevices.push(ctrl.ngModel.devices[i]);
                                }
                                ctrl.ngModel.devices.splice(i, 1);
                            }
                        }
                        for (var i in ctrl.availableDevices.length) {
                            if (ctrl.availableDevices[i].mac === device.mac) {
                                ctrl.availableDevices[i].buildingId = null;
                            }
                        }
                        Buildings.setupDevices(ctrl.buildings, ctrl.availableDevices);
                    };

                    MessageService.subscribe('saveStart', function (msg, opts) {
                        saveInProgress = true;
                    });

                    MessageService.subscribe('saveSuccess', function (msg, opts) {
                        Buildings.setupDevices(ctrl.buildings, ctrl.availableDevices);
                        Buildings.setupRooms(ctrl.buildings, ctrl.rooms);
                        markerLayer = Buildings.setupBuildingMarkers(ctrl.location, [ctrl.ngModel], currentMap, markerLayer, undefined, undefined);
                        marker = Buildings.markOnMap(markerLayer, ctrl.ngModel, false);
                        ctrl.ngModel = ctrl.buildings.filter(function (b) {
                            return b.id === ctrl.ngModel.id;
                        })[0];
                        ctrl.handlePositionSelectChange('manual');

                        saveInProgress = false;
                    });

                    MessageService.subscribe("saveError", function (msg, opts) {
                        saveInProgress = false;
                    });

                    $scope.$watch(function () { return ctrl.EditBuildingForm.$dirty; }, function () {
                        if (ctrl.EditBuildingForm.$dirty || ctrl.EditBuildingForm.$invalid) {
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = 1;
                        } else {
                            LocationDetailService.canChange = true;
                            LocationDetailService.onlyActiveTab = null;
                        }
                    });

                }]
        });

})();


