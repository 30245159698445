(function () {

    'use strict';

    function widgetContentController($scope, $rootScope, $compile, $log, $http, Principal, $state) {
        var ctrl = this;
        ctrl.dashboardLocked = false;
        ctrl.state = $state.current.name;

        ctrl.$onInit = function () {
            Principal.identity().then(function (account) {
                if(localStorage.getItem("locked-dashboard-user-id-" + account.id) === "true") {
                    ctrl.dashboardLocked = true;
                } else {
                    ctrl.dashboardLocked = false;
                }
            }, function (error) {
                $log.error('Couldn\'t load user!', error);
            });
        };

        $scope.custom = {
            method: null,
            title: null,
            icon: 'edit'
        };
        $scope.configMethod = null;
        $scope.showTools = true;
        $scope.resizeHandler = null;

        $scope.isDashboardState = function () {
            return ctrl.state === 'dashboard';
        }
        $scope.closeWidget = function () {
            $rootScope.$broadcast('closeWidget', {widget: $scope.widget, index: $scope.index});
        };

        $scope.setCustomMethod = function (options) {
            if (!options.hasOwnProperty('method')) {
                $log.error('[custom-method]: "method" is required, but it\'s missing');
            }
            else {
                $scope.custom.method = options.method;
                if (options.hasOwnProperty('title')) {
                    $scope.custom.title = options.title;
                }
                if (options.hasOwnProperty('icon')) {
                    $scope.custom.icon = options.icon;
                }
            }
        };

        $scope.setConfigMethod = function (method) {
            $scope.configMethod = method;
        };

        $scope.setResizeHandler = function (handler) {
            $scope.resizeHandler = handler;
        };

        $scope.compareRooms = function (r1, r2) {
            return r1.mac == r2.mac &&
                r1.room == r2.room &&
                r1.locName == r2.locName &&
                r1.devName == r2.devName;
        };

        $scope.parseRoomFromSettings = function (settings) {
            var ret = {};
            for (var i = 0; i < settings.length; i++) {
                ret[settings[i].key] = settings[i].value;
            }
            return ret;
        };

        $scope.getRoomBySettings = function (settings, listOfRooms) {
            var room = this.parseRoomFromSettings(settings);
            if (listOfRooms) {
                for (var i = 0; i < listOfRooms.length; i++) {
                    var current = listOfRooms[i];
                    if (this.compareRooms(current, room)) {
                        return current;
                    }
                }
            }
            return null;
        };

        $scope.generateContent = function (element, attrs) {
            try {
                var html = "<" + $scope.widget.type + " widget=\"widget\" index=\"index\" isreportwidget =\"isreportwidget\" isdashboardwidget=\"isdashboardwidget\" isprintwidget=\"isprintwidget\" active=\"active\"></" + $scope.widget.type + ">";
                for (var i = 0; element[0].children[i]; i++) {
                    var currentel = angular.element(element[0].children[i]);
                    if (currentel[0].id == "widgetContent") {
                        currentel.append(html);
                    }
                }
                $compile(element.contents())($scope);
            }
            catch (e) {
                $log.error('Could not compile widget template. type = name of directive?\n' +
                    'Provided type is: ' + $scope.widget.type + '\n' +
                    'Error:\n' + e);
            }
        };

        $scope.$on("toggleLockGridstack", function ($ev, param) {
            $scope.showTools = !param.locked;
            ctrl.dashboardLocked = param.locked;
        });
    }

    angular.module('emsv2App')
        .controller('WidgetContentController', widgetContentController);
})();
