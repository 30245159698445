(function () {

    'use strict';

    function intervalService() {
        return {
            getPriorityLevel: function (priority) {return parseInt(priority / 75)},
            getPriorityInterval: function () {
                return {
                    infoPrioStart: 0,
                    infoPrioEnd: 74,
                    warnPrioStart: 75,
                    warnPrioEnd: 149,
                    errorPrioStart: 150
                };
            }
        };
    }

    angular.module('emsv2App').service('IntervalService', intervalService);

})();
