(function () {
    'use strict';

    function overviewTokenController($log, User, $translate, TokenService,Notify, $state,$scope, $interval,Principal,$window) {
        var ctrl = this;
        ctrl.data = [];
        ctrl.copy = null;
        ctrl.rowCopy= false;
        ctrl.targetHelp = $window.location.origin + "/swagger-ui/index.html";
        ctrl.user = {};
        ctrl.hasMore = false;
        ctrl.page = 0;
        ctrl.query = '';
        ctrl.doQuery = function () {
            return _query(false);
        };

        ctrl.tableModel = {
            tableName: 'jhi_user_token',
            translation: 'token.management',
            orderBy: 'description',
            orderDir: false,
            // onSort: ctrl.doSort,
            // doFilter: _doFilter,
            head: [
                {name: 'token', translation: 'token.management.token'},
                {name: 'description', translation: 'token.management.description',  sortable: true},
                {name: 'created', translation: 'token.management.created',  sortable: true},
                {name: 'expiry', translation: 'token.management.expiry', sortable: true},
                {empty: true}
            ]
        };

        /*Filtering function
        function _doFilter(params) {
            $log.log("Inside doFilter>>>",params)
            ctrl.query = params;
            _query(true);
        }*/

        function _query(reset) {
            ctrl.page = reset ? 0 : ctrl.page + 1;
            ctrl.query = '?orderby=' + ctrl.tableModel.orderBy + '&direction=' + (ctrl.tableModel.orderDir ? 'desc' : 'asc');
            return TokenService.rsql(ctrl.page, ctrl.query).then(function (response) {
                ctrl.hasMore = response.data.length === 20;
                if (!reset) {
                    for (var i = 0; i < response.data.length; i++) {
                        ctrl.data.push(response.data[i]);
                    }
                } else {
                    ctrl.data = response.data;
                }
            }, function (error) {
                $log.error("Couldn't load token!", error);
                ctrl.data = [];
            });

        }


        ctrl.$onInit = function () {
              ctrl.copy = false;
              updateUserLocale();
              _query(true);
              // listAllTokens();
        }

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderDir = sortReverse;
            ctrl.tableModel.orderBy = sortField;
             _query(true);
        };

        ctrl.revokeToken = function (tokenObj){
            TokenService.revokeToken(tokenObj).then( function(response){
                $log.debug("TOKEN revoke success",response);
                Notify.success("global.notification.success.title", "global.notification.success.revoketoken", 2000);
                listAllTokens();
                $state.go('tokenOverview');
            },function (error){
                $log.error("TOKEN REVOKE FAILED",error);
                Notify.error("global.notification.error.title", "global.notification.error.revoketoken", 2000);
            });
        }


        ctrl.deleteToken = function (tokenObj){
            TokenService.deleteToken(tokenObj).then(function (res){
                $log.debug("TOKEN DELETE success");
                Notify.defaultSuccess();
                listAllTokens();
                $state.go('tokenOverview');
            },function (error) {
                $log.error("TOKEN DELETE FAILED ",error);
                Notify.error("global.notification.error.title", "global.notification.error.deletetoken", 2000);
            });
        }


        ctrl.copyToken = function (tokenValue) {
            ctrl.rowCopy = true;
            ctrl.copiedToken = tokenValue;
        }


        function listAllTokens(){
            ctrl.data = [];
            TokenService.listAllToken().then( function (response){
                if(response.data != null){
                    for (var i = 0; i < response.data.length; i++) {
                        ctrl.data.push(response.data[i]);
                    }
                }
                else {
                    ctrl.data = [];
                }
            });
        }
        function updateUserLocale() {
            Principal.identity().then(function (user) {
                ctrl.user = user;
                if(user){
                    if (!user.terms) {
                        $state.go("legalConfirm", {}, {reload: true});
                    }
                }

            }, function (error) {
                $log.error('Couldn\'t load user: ', error);
            });
        }


        $scope.$on('$destroy', function () {
            ctrl.$onDestroy();
        });

        ctrl.$onDestroy = function () {
        };

        ctrl.openDocument = function (){
            $window.open(ctrl.targetHelp, '_blank');
        }
    }

    angular
        .module('emsv2App')
        .controller('OverviewTokenController', overviewTokenController);

})();