(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name ConditionalOperatorService
     * @requires $http
     */
    angular.module('emsv2App').service('ConditionalOperatorService', ['$http', function ($http) {
        return {
            //get Event Categories
            'getConditionalOperators': function () {
                return $http.get('api/locations/errorConfig/conditionalOperator');
            }
        };
    }]);
})();
