(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgettext', function () {

        function controller($scope, $element, $uibModal, $interval, $translate, WidgetData, Tools) {
            var ctrl = this;
            var modalInstance = null;
            ctrl.edit = false;
            ctrl.text = '';
            ctrl.title = {
                text: '',
                visibility: {
                    toc: true,
                    page: true
                }
            };
            ctrl.fontSize = 20;

            ctrl.autoSave = function () {
                _saveSettings();
            };

            ctrl.closeModal = function () {
                modalInstance.close();
            };

            ctrl.saveSettings = function () {
                _saveSettings();
                ctrl.closeModal();
            };

            function _saveSettings() {
                var newSettings = [];
                newSettings.push({key: 'text', value: ctrl.text});
                newSettings.push({key: 'title', value: ctrl.title.text});
                newSettings.push({key: 'visible_page', value: ctrl.title.visibility.page});
                newSettings.push({key: 'visible_toc', value: ctrl.title.visibility.toc});
                $scope.widget.settings = newSettings;
                _updateWidgetTitle();
                if ($scope.isdashboardwidget) {
                    WidgetData.saveWidgetSettings($scope.widget);
                }
            }

            function _loadSettings() {
                if ($scope.widget.settings !== null) {
                    for (var i = 0; i < $scope.widget.settings.length; i++) {
                        if ($scope.widget.settings[i].key === 'text') {
                            ctrl.text = $scope.widget.settings[i].value;
                        }
                        else if ($scope.widget.settings[i].key === 'title') {
                            ctrl.title.text = $scope.widget.settings[i].value;
                        }
                        else if ($scope.widget.settings[i].key === 'visible_page') {
                            ctrl.title.visibility.page = $scope.widget.settings[i].value === 'true';
                        }
                        else if ($scope.widget.settings[i].key === 'visible_toc') {
                            ctrl.title.visibility.toc = $scope.widget.settings[i].value === 'true';
                        }
                    }
                    _updateWidgetTitle();
                }
                else {
                    $log.error('Couldn\'t load text widget settings!');
                }
                return null;
            }

            function _edit() {
                if (ctrl.edit) {
                    _saveSettings();
                }
                ctrl.edit = !ctrl.edit;
            }

            function _configure() {
                modalInstance = $uibModal.open({
                    templateUrl: 'scripts/app/dashboard/widget.templates/text/widget.text.settings.modal.html',
                    animation: false,
                    windowClass: 'animated fadeInDown',
                    scope: $scope
                });
            }

            function _updateWidgetTitle() {
                $scope.$parent.widget.title = $translate.instant('dashboard.widget.text.title');
                if (ctrl.title.text.length) {
                    $scope.$parent.widget.title += ": " + ctrl.title.text;
                }
            }

            (function () {
                $scope.$parent.setCustomMethod({
                    method: _edit,
                    title: $translate.instant('dashboard.widget.text.edit')
                });
                if (!$scope.isdashboardwidget) {
                    $scope.$parent.setConfigMethod(_configure);
                }

                _loadSettings();
                if ($scope.isreportwidget) {
                    var interval = $interval(function () {
                        if (typeof $($element).children().eq(0).innerHeight() === "number") {
                            if ($($element).children().eq(0).innerHeight() > $($element).parents().eq(2).outerHeight() && ctrl.fontSize > 8) {
                                ctrl.fontSize -= 0.5;
                                $($element).find('h1').css('font-size', (ctrl.fontSize + 10) + 'px');
                                $($element).find('h2').css('font-size', (ctrl.fontSize + 8) + 'px');
                                $($element).find('h3').css('font-size', (ctrl.fontSize + 6) + 'px');
                                $($element).find('h4').css('font-size', (ctrl.fontSize + 4) + 'px');
                                $($element).find('h5').css('font-size', (ctrl.fontSize + 2) + 'px');
                                $($element).find('h6').css('font-size', (ctrl.fontSize) + 'px');
                            }
                            else {
                                if (ctrl.fontSize === 8) {
                                    $($element).parents().eq(2).css('overflow', 'hidden');
                                    $($element).css('text-overflow', 'ellipsis');
                                }
                                $interval.cancel(interval);
                                interval = null;
                            }
                        }
                    });
                }
            })();
        }

        return {
            restrict: "E",
            controller: controller,
            controllerAs: 'textCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/text/widget.text.template.html'
        };
    });
})();
