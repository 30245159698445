(function () {
    'use strict';

    /**
     * @description service to handle part library objects
     */
    angular.module('emsv2App').service("PartlibService", function ($http, $log, $translate) {

        /**
         * @description function to build information object to be used in frontend
         * @param {object} data the data structure containing unprocessed information about part library objects
         * @returns {{rack: *, racksInfo: {manufacturer: Array}, racksInfoCustom: {manufacturer: Array}, asset: *, assetsInfo: {manufacturer: Array}, assetsInfoCustom: {manufacturer: Array}, sensor: *, sensorsInfo: {manufacturer: Array}, floortile: *, floortilesInfo: {manufacturer: Array}, floortilesInfoCustom: {manufacturer: Array}, cooling: *, coolingsInfo: {manufacturer: Array}, coolingsInfoCustom: {manufacturer: Array}, slot: *, slotsInfo: {manufacturer: Array}, slotsInfoCustom: {manufacturer: Array}, ups: *, upsInfo: {manufacturer: Array}, upsInfoCustom: {manufacturer: Array}}}
         */
        var buildPartlibObject = function (data) {
            var obj = {
                rack: data[0],
                racksInfo: getManuModelInfoObject(data[0], false),
                racksInfoCustom: getManuModelInfoObject(data[0], true),
                asset: data[1],
                assetsInfo: getManuModelInfoObject(data[1], false),
                assetsInfoCustom: getManuModelInfoObject(data[1], true),
                sensor: data[2],
                sensorsInfo: getManuModelInfoObject(data[2], false),
                floortile: data[3],
                floortilesInfo: getManuModelInfoObject(data[3], false),
                floortilesInfoCustom: getManuModelInfoObject(data[3], true),
                cooling: data[4],
                coolingsInfo: getManuModelInfoObject(data[4], false),
                coolingsInfoCustom: getManuModelInfoObject(data[4], true),
                slot: data[5],
                slotsInfo: getManuModelInfoObject(data[5], false),
                slotsInfoCustom: getManuModelInfoObject(data[5], true),
                ups: data[6],
                upsInfo: getManuModelInfoObject(data[6], false),
                upsInfoCustom: getManuModelInfoObject(data[6], true)
            };
            return obj;
        };

        /**
         * @description function to get translate string for given air direction
         * @param {string} dir the air direction string
         * @returns {string|Object} returns translated air direction string
         */
        var getAirDirectionTranslateString = function (dir) {
            switch (dir) {
                case "D":
                    return $translate.instant("partlib.airDirection.down");
                case "U":
                    return $translate.instant("partlib.airDirection.up");
                case "RF":
                    return $translate.instant("partlib.dirDirection.right");
                case "FF":
                    return $translate.instant("partlib.airDirection.front");
                case "LF":
                    return $translate.instant("partlib.airDirection.left");
                case "BF":
                    return $translate.instant("partlib.airDirection.both");
            }
        };

        /**
         * @description function to get translated version string from given version and stulz family
         * @param {string} version the version abbreviation
         * @param {string} family the family abbreviation
         * @returns {string|Object} returns translated string for given version and family
         */
        var getVersionTranslateString = function (version, family) {
            if (family === "CR") {
                return version === "S" ? $translate.instant("partlib.version.std") : $translate.instant("partlib.version.large");
            }
            else {
                return version === "S" ? $translate.instant("partlib.version.std") : $translate.instant("partlib.version.lownoise");
            }
        };

        /**
         * @description function to build information object for part library frontend
         * @param {array} objArray array of part library objects
         * @param {boolean} customEntity whether to setup custom or standard part library information
         * @returns {{manufacturer: Array}} returns object for frontend, grouped by manufacturer and model name
         */
        var getManuModelInfoObject = function (objArray, customEntity) {
            var obj = {manufacturer: []};
            for (var i in objArray) {
                if (objArray[i].customEntity != customEntity) continue;
                if(objArray[i].manufacturer.indexOf("partlib.") != -1) objArray[i].manufacturer = $translate.instant(objArray[i].manufacturer);
                if(objArray[i].name.indexOf("partlib.") != -1) objArray[i].name = $translate.instant(objArray[i].name);
                if(objArray[i].comment && objArray[i].comment.indexOf("partlib.") != -1) objArray[i].comment = $translate.instant(objArray[i].comment);
                if (obj[objArray[i].manufacturer] === undefined) {
                    obj[objArray[i].manufacturer] = [];
                    obj.manufacturer.push(objArray[i].manufacturer);
                }
                if (objArray[i].manufacturer != "STULZ" || (objArray[i].manufacturer === "STULZ" && objArray[i].customEntity)) {
                    if(objArray[i].model.indexOf("partlib.") != -1) objArray[i].model = $translate.instant(objArray[i].model);
                    if (obj[objArray[i].manufacturer].indexOf(objArray[i].model) == -1) {
                        obj[objArray[i].manufacturer].push(objArray[i].model);
                    }
                }
                else {
                    if (!obj[objArray[i].manufacturer].models) obj[objArray[i].manufacturer].models = [];
                    if (obj[objArray[i].manufacturer][objArray[i].model] === undefined) {
                        obj[objArray[i].manufacturer][objArray[i].model] = {
                            sizes: [],
                            versions: [],
                            csystems: [],
                            airDirections: [],
                            circuits: []
                        };
                    }
                    if (obj[objArray[i].manufacturer].models.indexOf(objArray[i].model) == -1) obj[objArray[i].manufacturer].models.push(objArray[i].model);
                    var tModel = obj[objArray[i].manufacturer][objArray[i].model];
                    if (objArray[i].stulzInfo != null) {
                        if (tModel.sizes.length >= 0 && tModel.sizes.indexOf(objArray[i].stulzInfo.size) == -1) tModel.sizes.push(objArray[i].stulzInfo.size);
                        if (tModel.circuits.length >= 0 && tModel.circuits.indexOf(objArray[i].stulzInfo.circuits) == -1) tModel.circuits.push(objArray[i].stulzInfo.circuits);
                        if (tModel.csystems.length >= 0 && tModel.csystems.indexOf(objArray[i].stulzInfo.cSystem) == -1) tModel.csystems.push(objArray[i].stulzInfo.cSystem);
                    }

                    if (objArray[i].stulzInfo !== null) {
                        objArray[i].name = objArray[i].model == "CyberRow" ?
                            objArray[i].stulzInfo.family + "" + objArray[i].stulzInfo.version + " " + objArray[i].stulzInfo.refridgeCap + "" + objArray[i].stulzInfo.circuits + " " + objArray[i].stulzInfo.cSystem :
                            objArray[i].stulzInfo.family + "" + objArray[i].stulzInfo.version + objArray[i].stulzInfo.airDirection + " " + objArray[i].stulzInfo.refridgeCap + "" + objArray[i].stulzInfo.circuits + " " + objArray[i].stulzInfo.cSystem;
                        objArray[i].stulzInfo.airDirection = getAirDirectionTranslateString(objArray[i].stulzInfo.airDirection);
                        objArray[i].stulzInfo.version = getVersionTranslateString(objArray[i].stulzInfo.version, objArray[i].stulzInfo.family);

                        if (tModel.airDirections.indexOf(objArray[i].stulzInfo.airDirection) == -1) tModel.airDirections.push(objArray[i].stulzInfo.airDirection);
                        if (tModel.versions.indexOf(objArray[i].stulzInfo.version) == -1) tModel.versions.push(objArray[i].stulzInfo.version);
                    }
                }
            }
            return obj;
        };

        /**
         * @description function to get information about part library objects for frontend use
         * @returns {*} returns information object for frontend
         */
        var getAllParts = function () {
            return $http.get("/api/partlib").then(function (response) {
                return buildPartlibObject(response.data);
            }, function (error) {
                return error;
            });
        };

        /**
         * @description function to retrieve save quickbar items from backend
         * @returns {*}
         */
        var getQuickbarItems = function () {
            return $http.get("/api/partlib/qbitems").then(function (response) {
                return response.data; // TODO might need some more processing
            }, function (error) {
                return error;
            });
        };

        var saveQuickbarItems = function (items) {
            //TODO implement me
        };

        /**
         * @description function to get part library objects for given type and id
         * @param {number} type the part library object type
         * @param {number} id the part library object id
         * @returns {*}
         */
        var getPartLibInfoByTypeAndId = function (type, id) {
            var obj = {type: type, id: id};
            return $http.post("api/partlib/info", obj).then(function (response) {
                if (response.data[0] === undefined || response.data[0] === null ) return null;
                return response.data[0];
            }, function (error) {
                $log.error("Error getting part info for: " + type + " with id: " + id + "\n" + error);
                return null;
            });
        };

        /**
         * @description function to save new part in database
         * @param {object} part the part object to be saved
         * @returns {*}
         */
        var savePart = function (part) {
            return $http.post("/api/partlib/save", part).then(function (response) {
                return response.data;
            }, function (error) {
                $log.error("Error saving part " + part + "\n" + error);
                return error;
            });
        };

        /**
         * @description function to delete object from part library given by its type and id
         * @param {number} type the part library object type
         * @param {number} id the part library object id
         * @returns {*}
         */
        var deletePart = function (type, id) {
            return $http.post("/api/partlib/delete", {type: type, id: id}).then(function (response) {
                return response.data;
            }, function (error) {
                $log.error("Error deleting part wíth type " + type + " and id " + id + "\n" + error);
                return error;
            });
        };

        /**
         * @description function to get information about specific part library object in parts array
         * @param {number} type the part library object type
         * @param {number} id the part library object id
         * @param {array} parts array of parts to search in
         * @returns {*} returns found part otherwise null
         */
        var getPartLibInfoByTypeAndIdLocal = function (type, id, parts) {
            var subStruct = type.toLowerCase();
            if (subStruct === "cooling" || subStruct === "asset" || subStruct === "ups") {
                var tpi = parts[subStruct].filter(function(el){return el.id === id;});
                if (tpi.length) return tpi[0];
            }
            return null;
        };
        var convertTabToType = function(tabIndex) {
            switch (tabIndex) {
                case 0: return 1; // Asset
                case 1: return 2; // Cooling
                case 2: return 4; // Rack
                case 3: return 3; // Floor tiles
                case 4: return 5; // UPS
                case 5: return 6; // Slots
            }
        };

        var convertTypeToTab = function(type) {
            switch (type) {
                case 1: return 0; // Asset
                case 2: return 1; // Cooling
                case 4: return 2; // 2 - Rack
                case 3: return 3; // 3 - Floor tiles
                case 5: return 4; // UPS
                case 6: return 5; // Slots
            }
        };

        return {
            getAllParts: getAllParts,
            getQuickBarItems: getQuickbarItems,
            saveQuickbarItems: saveQuickbarItems,
            savePart: savePart,
            deletePart: deletePart,
            getPartLibInfoByTypeAndId: getPartLibInfoByTypeAndId,
            getPartLibInfoByTypeAndIdLocal: getPartLibInfoByTypeAndIdLocal,
            convertTypeToTab: convertTypeToTab,
            convertTabToType: convertTabToType
        };
    });

})();
