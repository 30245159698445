(function () {

    'use strict';

    function overviewController($log, Analysis, user, isAdmin, GenDialogService, $translate, $timeout, Notify, License) {
        var ctrl = this;
        ctrl.filter = {
            templates: true,
            showTemplates: function (show) {
                ctrl.filter.templates = show;
                ctrl.toggleFilter(null);
            },
            active: 'all', // possible values: all/group/system/personal
            group: false
        };

        ctrl.userInfo = user;
        ctrl.isAdmin = isAdmin;

        // Rsql, Sorting, Paging
        ctrl.data = [];
        ctrl.query = '';
        ctrl.page = 0;
        ctrl.doQuery = function () {
            return _query(false);
        };
        ctrl.hasMore = false;
        ctrl.tableModel = {
            restriction: '',
            head: [
                {name: 'title', translation: 'analysis.table.title'},
                {type: 'dynamic', translation: 'analysis.table.dynamic', sortable: false},
                {name: 'creator', translation: 'analysis.table.creator'},
                {name: 'groupName', translation: 'analysis.table.fk_group', sortable: false},
                {empty: true}
            ],
            table: 'analysis',
            translation: 'analysis.table',
            orderBy: 'title',
            orderDir: false
        };
        ctrl.LicenseExpired = false;
        ctrl.isFreeVersion = false;
        ctrl.limitReached = false;

        function _query(reset) {
            reset = reset !== undefined ? reset : true;
            ctrl.page = reset ? 0 : ctrl.page + 1;
            ctrl.query = ctrl.tableModel.restriction
                + '?orderby=' + ctrl.tableModel.orderBy
                + '&direction=' + (ctrl.tableModel.orderDir ? 'desc' : 'asc')
                + '&template='+ ctrl.filter.templates;
            return Analysis.rsql(ctrl.page, ctrl.query).then(function (response) {
                ctrl.hasMore = response.data.length === 20;
                if (!reset) {
                    for (var i = 0; i < response.data.length; i++) {
                        ctrl.data.push(response.data[i]);
                    }
                    ctrl.checkLicense();
                } else {
                    ctrl.data = response.data;
                    ctrl.checkLicense();
                }

            }, function (error) {
                $log.error('Couldn\'t load devices!', error);
                ctrl.data = [];
            });
        }

        ctrl.$onInit = function () {
            License.getIsFreeVersion().then(function (response) {
                if (response.data === "freeVersion") {
                    ctrl.isFreeVersion = true;
                } else {
                    ctrl.isFreeVersion = false;
                }
            });
            License.getExpired().then(function (response) {
                ctrl.LicenseExpired = response.data;
            });
            ctrl.toggleFilter('all');
            _query(true);
        };

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderBy = sortField;
            ctrl.tableModel.orderDir = sortReverse;
            _query(true);
        };

        ctrl.toggleFilter = function (selection) {
            ctrl.tableModel.restriction = '';
            if (selection !== null) {
                ctrl.filter.active = selection;
                ctrl.tableModel.restriction = selection.toUpperCase();
            }

            _query(true);
        };

        ctrl.deleteModal = function (analysisId, analysisName) {
            $translate(['analysis.title.delete', 'analysis.action.askDelete', 'global.btn.cancel', 'global.btn.delete']).then(function (trans) {
                GenDialogService.showDialog(true, {
                    headText: trans['analysis.title.delete'],
                    messageText: $translate.instant('analysis.action.askDelete', {value: analysisName}),
                    showClose: false,
                    textButton0: trans['global.btn.cancel'],
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton0: 'btn-close',
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    },
                    textButton1: trans['global.btn.delete'],
                    iconButton1: 'glyphicon glyphicon-remove-circle',
                    classButton1: 'btn-delete',
                    callbackButton1: function () {
                        GenDialogService.hideDialog(analysisId);
                        _delete(analysisId);
                        ctrl.checkLicense();
                    }
                });
            })

        };

        /**
         * @description function to check license
         */

        ctrl.checkLicense = function () {
            if (ctrl.isFreeVersion) {
                if (ctrl.data.length >= 3) {
                    ctrl.limitReached = true;
                    setTimeout(function () {
                        var button = document.getElementById("addAnalysisButton");
                        var tooltip = $translate.instant('global.btn.freeVersion');
                        button.setAttribute("title", tooltip);
                    }, 100);
                } else if (ctrl.data.length <= 3) {
                    ctrl.limitReached = false;
                    var button = document.getElementById("addAnalysisButton");
                    button.removeAttribute("title");
                }
            }
        }

        function _delete(analysisId) {
            Analysis.remove(analysisId).then(function () {
                $timeout(function () {
                    _query(true);
                    Notify.success("global.notification.success.title", "analysis.notification.success.deleted", 2000);
                }, 3000);
            }, function (error) {
                $timeout(function () {
                    Notify.error("global.notification.error.title", "analysis.notification.error.deleted", 2000);
                }, 3000);
                $log.error('Couldn\'t delete analysis ' + analysisId, error);
            })
        }
    }

    angular.module('emsv2App')
        .controller('AnalysisOverviewController', overviewController);

})();
