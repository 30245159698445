'use strict';

/**
 * @description Constructor to create BuildingDevice object (describes association between building and devices)
 * @param {string | number} mac the mac (id of the device) to set
 * @param {number} buildingId the building id to set
 * @param {string} text the text to set (currently not used, ask gg what this is for)
 * @constructor
 */
var BuildingDevice = function (mac, buildingId, text) {
    this.mac = mac;
    this.fkBuilding = buildingId;
    this.text = text;
};
BuildingDevice.prototype.constructor = BuildingDevice;

/**
 * @description Function to create a building device object from json data object delivered by backend.
 * @param {object} obj the json data object
 * @returns {BuildingDevice} returns the created building device object
 */
BuildingDevice.parseFromHtmlObject = function (obj) {
    return new BuildingDevice(obj.mac, obj.fkBuilding, obj.text);
};
