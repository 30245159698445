(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('account', {
                    parent: 'site',
                    redirectTo: 'settings',
                    data: {
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'global.menu.account.main'
                    }
                })
                .state('warning', {
                    parent: 'site',
                    url: '/account/warning',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/warning/warning.login.html',
                            controller: 'WarningController',
                            controllerAs: 'wCtrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('settings');
                            $translatePartialLoader.addPart('login');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function($timeout, Principal){
                        'ngInject';
                        $timeout(function(){
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                            else {
                                Principal.redirectToHomepage();
                            }
                        });
                    }
                });
        });

})();
