(function () {

    'use strict';

    function dashBoardController($rootScope, $scope, $uibModal, $log, WidgetData, currentWidgets, userInfo, $timeout, Tools, Notify, AnalysisWidgetLimit, $translate, DashboardService, $cookies) {
        var ctrl = this;
        ctrl.dashboardLocked = Tools.isDefinedNotNull(localStorage) ? localStorage.getItem("locked-dashboard-user-id-" + userInfo.id) === "true" : false;
        ctrl.newItemAdded = false;
        ctrl.onChangeCoutner = 0;
        var modalInstance = null;
        ctrl.widgets = currentWidgets;
        ctrl.widgetsInitialized = false;
        ctrl.internalWidgetId = -1;

        ctrl.options = {
            cellHeight: 100,
            cellWidth: 200,
            verticalMargin: 15,
            float: true,
            animate: true,
            alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            disableResize: ctrl.dashboardLocked,
            disableDrag: ctrl.dashboardLocked,
            draggable: {
                handle: '.ibox-title'
            }
        };

        ctrl.addWidget = function (posX, posY, width, height, type, title, reSize, minWidth, minHeight, maxWidth, maxHeight) {
            // Filter singleton widgets
            if (type === 'widgetlocationmap') {
                var tmpWidgets = WidgetData.getWidgets();
                for (var i = 0; i < tmpWidgets.length; i++) {
                    if (tmpWidgets[i].type === 'widgetlocationmap') return;
                }
            }
            if (type === 'widgetspeedometer') {
                title =$translate.instant('dashboard.widget.speedometer.label');
            }
            if (type === 'widgetanalysis' || type === 'widgetanalysistable') {
                var numwidgetanalysis = 0;
                for (var w = 0; w < ctrl.widgets.length; w++) {
                    if (ctrl.widgets[w].type === 'widgetanalysis' || ctrl.widgets[w].type === 'widgetanalysistable') {
                        numwidgetanalysis ++;
                    }
                }
                if (numwidgetanalysis >= AnalysisWidgetLimit) {
                    var errorlist = $translate.instant('dashboard.widget.analysis.error.dashboardWidgetsLimit1') + AnalysisWidgetLimit +  $translate.instant('dashboard.widget.analysis.error.dashboardWidgetsLimit2');
                    Notify.error("global.notification.error.title", errorlist, 2000);
                    return;
                }
            }

            if (!angular.isDefined(ctrl.widgets) || ctrl.widgets == null) {
                ctrl.widgets = [];
            }
            var newWidget = {
                wid: ctrl.internalWidgetId,
                ownerId: -1,
                x: posX,
                y: posY,
                width: width,
                height: height,
                type: type,
                title: title,
                reSize: reSize,
                minWidth: minWidth,
                minHeight: minHeight,
                maxWidth: maxWidth,
                maxHeight: maxHeight,
                settings: []
            };
            ctrl.internalWidgetId--;
            ctrl.newItemAdded = true;
            ctrl.widgets.push(newWidget);
            ctrl.onChangeCoutner = 0;
        };

        ctrl.openModal = function () {
            modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.modal/add.widget.modal.html',
                animation: false,
                windowClass: 'animated fadeInDown',
                controller: 'WidgetModalController',
                controllerAs: 'wModalCtrl'
            });
        };

        ctrl.lockGrid = function () {
            ctrl.dashboardLocked = !ctrl.dashboardLocked;
            localStorage.setItem("locked-dashboard-user-id-" + userInfo.id, ctrl.dashboardLocked);
            $rootScope.$broadcast("toggleLockGridstack", {locked: ctrl.dashboardLocked, widgets: ctrl.widgets});
            // if (!ctrl.dashboardLocked) {
                WidgetData.getWidgets().then(function (response) {
                    if (response != null) {
                        ctrl.widgets = response.data;
                    }
                });
             // }
        };

        var newWidgetCast = $rootScope.$on('newWidget', function (event, args) {
            ctrl.addWidget(0, 0, args.width, args.height, args["type"], args["title"], args["reSize"], args["minWidth"], args["minHeight"], args["maxWidth"], args["maxHeight"]);
        })

        ctrl.$onInit = function () {
            const locations = [];
            for(var i in ctrl.widgets){
                let locationId = ctrl.widgets[i].selectedLocationId;
                if(locationId && locations.indexOf(locationId) < 0){
                    DashboardService.loadRooms(locationId);
                    locations.push(locationId);
                }
            }
        };

        $scope.$on('$destroy', newWidgetCast);

        var closeWidgetCast = $rootScope.$on('closeWidget', function (event, arg) {
            if (!ctrl.dashboardLocked) {
                WidgetData.deleteWidget(ctrl.widgets[arg["index"]].wid).then(function () {
                    ctrl.widgets.splice(arg["index"], 1);
                });
            }
        });
        $scope.$on('$destroy', closeWidgetCast);

        var closeModalCast = $rootScope.$on('closeModal', function () {
            modalInstance.close();
            modalInstance = null;
        });
        $scope.$on('$destroy', closeModalCast);

        $scope.onChange = function (event, items) {
            if (ctrl.widgetsInitialized && !ctrl.newItemAdded && angular.isDefined(items) && items != null) {
                _checkEventItem(items);
            }
            if(items != null && !ctrl.widgetsInitialized){
                ctrl.widgetsInitialized = allWidgetsInitialized(items.length);
            }
        };


        $rootScope.$on("unsecure_connection_intercepted", function () {

            var broadCastCooke = $cookies.get('sec_connection_broadcast');
            if (!broadCastCooke) {
                var expireDate = new Date(new Date().getTime() + 15 * 60000);

                var cookieConfig = {
                    expires: expireDate
                };

                $cookies.put('sec_connection_broadcast', true, cookieConfig);
                Notify.warning("global.notification.warning.connectionUnsecure.title","global.notification.warning.connectionUnsecure.text", 25000);
            }
        });

        $scope.$on('$destroy', closeModalCast);

        function allWidgetsInitialized(itemCount) {
            let reportWidgets = 0;
            for (var j = 0; j < ctrl.widgets.length; j++) {
                if(ctrl.widgets[j].isreportwidget){
                    reportWidgets++;
                }
            }

            return itemCount === (ctrl.widgets.length - reportWidgets);
        }

        function _checkEventItem(items) {
            ctrl.onChangeCoutner++;
            $log.debug("ChangeCount", ctrl.onChangeCoutner);

            var list = [];
            for (var i = 0; i < items.length; i++) {
                for (var j = 0; j < ctrl.widgets.length; j++) {
                    if (parseInt(items[i].id) == parseInt(ctrl.widgets[j].wid)
                        && parseInt(items[i].id) > 0) {
                        if (!angular.isDefined(ctrl.widgets[j].isreportwidget)) {
                            list.push(ctrl.widgets[j]);
                        }
                        else if (ctrl.widgets[j].isreportwidget == null || ctrl.widgets[j].isreportwidget == false) {
                            list.push(ctrl.widgets[j]);
                        }
                        else {
                            $log.debug("Report widgets will be ignored");
                        }
                    }
                }
            }
            // no saving of initial setup
            if (list.length > 0) {
                $log.debug("Gathered list", list);
                WidgetData.saveWidgets(list).then(function () {
                    ctrl.newItemAdded = false;
                });
            }
        }

        $scope.onItemAdded = function (item) {
            if (item.id < 0) {
                for (var k = 0; k < ctrl.widgets.length; k++) {
                    if (item.id == ctrl.widgets[k].wid) {
                        WidgetData.saveWidget(ctrl.widgets[k]).then(function () {
                            WidgetData.getWidgets().then(function (response) {
                                if (response.data != null && response.data.length > 0) {
                                    ctrl.widgets = response.data;
                                    ctrl.newItemAdded = false;
                                }
                            });
                        });
                    }
                }
            }
        };

        $timeout(function () {
            $rootScope.$broadcast("toggleLockGridstack", {locked: ctrl.dashboardLocked, widgets: ctrl.widgets});
        }, 250);

    }

    angular.module('emsv2App')
        .controller('DashBoardController', dashBoardController);
})();
