(function () {
    'use strict';

    function arrayHelper() {
        return {
            toggleValue: function (arr, value) {
                var index = arr.indexOf(value);
                if (index > -1) { arr.splice(index, 1); }
                else { arr.push(value); }
            },
            removeValue: function (arr, value) {
                var index = arr.indexOf(value);
                if (index > -1) { arr.splice(index, 1); }
                else {
                    // Do nothing... Value is not in the array
                }
            },
            addValue: function (arr, value) {
                var index = arr.indexOf(value);
                if (index > -1) {
                    // Do nothing... Value is already in the array
                }
                else { arr.push(value); }
            }
        };
    }

    angular
        .module('emsv2App')
        .service('ArrayHelper', arrayHelper);
})();
