(function () {

    'use strict';

    angular.module('emsv2App')
        .directive('emsv2AppPager', function () {
            return {
                templateUrl: 'scripts/components/form/pager.html'
            };
        });

})();
