(function () {
    'use strict';

    function licenseController($window, $log, $uibModal, $state, License, VERSION, BRANCH, currentLicense, localStorageService) {

        var licenseModal = null;
        var ctrl = this;
        ctrl.license = !currentLicense.company ? null : currentLicense;
        ctrl.mac = [];
        ctrl.VERSION = VERSION;
        ctrl.BRANCH = BRANCH;

        ctrl.$onInit = function () {
            License.getAvailableMac().then(function (response) {
                ctrl.mac = response.data;
            });
            License.get().then(function (response){
                localStorageService.set("hostedpublicly",response['data']['data']['hostedpublicly']);
            })
        };
        ctrl.goToDashboard = function () {
            $state.go('home');
        }

        ctrl.update = function () {
            closeLicenseModal();
            licenseModal = $uibModal.open({
                animation: false,
                component: 'licenseComponent',
                windowClass: 'animated fadeInDown',
                size: 'lg',
                close: closeLicenseModal(),
                resolve: {
                    mac: function () {
                        return ctrl.mac;
                    }
                }
            });

        };

        var closeLicenseModal = function () {
            if (licenseModal !== null) licenseModal.close();
        };

        ctrl.reload = function () {
            $window.location.reload();
        };
    }

    angular
        .module('emsv2App')
        .controller('LicenseController', licenseController);
})();
