/**
 * Wrapping alarm modal into a reusable component.
 */

(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name alarmModal
     */
    angular
        .module('emsv2App')
        .component('alarmModal', {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/alarm/alarm.modal.component.html',
            controller: ['$state', '$stateParams', '$log', '$http', '$window', '$q', 'Tools',
                'IntervalService', 'AlarmService', 'Alarm', 'WebGLService', 'notify', 'Principal', 'RoomService',
                function ($state, $stateParams, $log, $http, $window, $q, Tools, IntervalService,
                          AlarmService, Alarm, WebGLService, Notify, Principal, RoomService) {
                    var ctrl = this;
                    var notificationOmitted = false;
                    var user = null;

                    ctrl.viewConfig = {
                        mapTypes: [
                            {id: 0, name: "alarm.mapTypes.temp"},
                            {id: 1, name: "alarm.mapTypes.press"},
                            {id: 2, name: "alarm.mapTypes.humi"}
                        ],
                        showMapConfig: false,
                        selectedMapType: 0,
                        viewType: 0,
                        mapActive: false,
                        showControls: false,
                        mapTransparency: 0.5,
                        mapPosition: 0.5,
                        showsRoom: false,
                        showCompute: false
                    };
                    ctrl.intToHex = Tools.intToHex;
                    ctrl.currentAlarm = null;
                    ctrl.currentDevice = null;
                    ctrl.isSDCIMDevice = false;
                    ctrl.isCyberHubNodeDevice = false;
                    ctrl.showQuitButton = false;
                    ctrl.isCurrentAlarm = false;
                    ctrl.roomName = null;

                    var canceller = $q.defer();

                    ctrl.$onInit = function () {
                        ctrl.currentAlarm = ctrl.resolve.currentAlarm;
                        ctrl.currentDevice = ctrl.resolve.currentDevice;
                        ctrl.isSDCIMDevice = ctrl.resolve.isSDCIMDevice;
                        ctrl.isCyberHubNodeDevice = ctrl.resolve.isCyberHubNodeDevice;
                        ctrl.isVirtualSNMP = ctrl.resolve.isVirtualSNMP;
                        ctrl.isCurrentAlarm = ctrl.resolve.isCurrentAlarm;
                        if(Tools.isDefinedNotNull(ctrl.resolve.stamp)){
                            ctrl.currentAlarm.stamp = ctrl.resolve.stamp;
                        }
                        user = ctrl.resolve.user;
                        if (ctrl.isCurrentAlarm) ctrl.showQuitButton = isAdmin() || hasEditRight();

                        if (ctrl.currentAlarm.errorCode < 9 && (ctrl.currentAlarm.historicalId == undefined || ctrl.currentAlarm.historicalId == "")) {
                            if (ctrl.isCyberHubNodeDevice || ctrl.isVirtualSNMP) {
                                $log.debug("Alarm: Device is online -> getting params for error codes");
                                //TODO: Research why there are object differences, when clicking on alarm table and calendar event(contains id and idAlarm)
                                if (Tools.isDefinedNotNull(ctrl.currentAlarm.idAlarm)) {
                                    ctrl.alarmParamId = ctrl.currentAlarm.idAlarm;
                                } else {
                                    ctrl.alarmParamId = ctrl.currentAlarm.id;
                                }
                                Alarm.getAlarmParam(ctrl.alarmParamId).then(function (response) {
                                    var requestedParam = response.data;
                                    var paramData = requestedParam.value;
                                    $log.debug("Alarm: Got params:", paramData);
                                    if (Tools.isDefinedNotNull(paramData)) {
                                        var moreData = true;
                                    } else {
                                        var moreData = false;
                                    }
                                    var paramList = [];

                                    while (moreData) {
                                        $log.debug("param");
                                        var type = paramData.substring(0, paramData.search(' '));
                                        paramData = paramData.substring(paramData.search(' ') + 1, paramData.length);

                                        var id = paramData.substring(0, paramData.search(' '));
                                        paramData = paramData.substring(paramData.search(' ') + 1, paramData.length);

                                        paramList.push({type: type, id: id});
                                        if (paramData.length <= 2) moreData = false;
                                    }
                                    if (paramList.length) {
                                        RoomService.getRoom(ctrl.currentAlarm.locationId, paramList[0].id).then(function (response) {
                                            ctrl.roomName = response.data.name;
                                        });
                                        for (var i = 0; i < 2; i++) {
                                            if (paramList[i].type == 1 || paramList[i].type == 2) {
                                                $log.debug('Alarm: Found Room param. Getting heatmap data.');
                                                var stampDate = new Date(ctrl.currentAlarm.stamp);
                                                AlarmService.showRoomNode(paramList[i].id, stampDate.getTime(), user, function (heatmaps) {
                                                    ctrl.viewConfig.showsRoom = true;
                                                    ctrl.viewConfig.showControls = true;
                                                    ctrl.viewConfig.mapTypes = heatmaps;
                                                    ctrl.viewConfig.selectedMapType = heatmaps[0].id;
                                                    AlarmService.changeMapTypeNode(ctrl.viewConfig.selectedMapType);
                                                    AlarmService.markAlarmObject(paramList[1]);
                                                    AlarmService.colorizeRoom();
                                                }, canceller);
                                            }
                                        }
                                    }
                                }, function () {
                                    $log.error('Alarm: Could not get Parameters for Errorcode ', ctrl.currentAlarm.id_alarm);
                                });
                            }
                        }
                    };

                    ctrl.$onDestroy = function () {
                        canceller.resolve();
                        AlarmService.destroyRenderer();
                    };

                    function isAdmin() {
                        return Principal.isInRole('ROLE_ADMIN');
                    }

                    function hasEditRight() {
                        return Principal.isInRole("ROLE_LOCATION_EDIT");
                    }

                    function quit() {
                        ctrl.close({$value: ctrl.currentAlarm});
                    }

                    ctrl.getPrioLabel = function (prio) {
                        switch (IntervalService.getPriorityLevel(prio)) {
                            case 0 :
                                return "label-info";
                            case 1 :
                                return "label-warning";
                            default:
                                return "label-danger";
                        }
                    };

                    ctrl.quitAlarm = function (alarm) {
                        if (ctrl.showQuitButton) {
                            if (ctrl.currentDevice.online === false) {
                                //TODO: message no quit
                                return;
                            }
                            //TODO: check alarm version
                            // Notify.warning("global.notification.warn.title", "alarm.notifications.warnOldVersionQuit", 10000);
                            var message = "";
                            if (alarm.action !== null) {
                                message = alarm.action;
                            }
                            if (alarm.version <= 3) {
                                if (!notificationOmitted) {
                                    Notify.warning("global.notification.warning.warn", "alarm.notifications.warnOldVersionQuit", 10000);
                                    notificationOmitted = true;
                                    return;
                                }
                                notificationOmitted = false;
                            }
                            AlarmService.quitAlarm(alarm.idAlarm, message).then(function (response) {
                                if (response.data) {
                                    if (response.data === true) {
                                        ctrl.currentAlarm.quit = true;
                                    }
                                }
                                quit();
                            });
                        }
                    };

                    ctrl.canQuit = function () {
                        if (ctrl.showQuitButton) {
                            return (ctrl.currentDevice.online && !ctrl.currentAlarm.quit && ctrl.currentAlarm.version > 0);
                        }
                    };

                    ctrl.formatMac = function (mac) {
                        return Tools.formatMac(Tools.intToHex(mac));
                    };

                    //prepare for Node 3D
                    ctrl.showSdcimPanel = function () {
                        return ctrl.currentDevice
                            && ctrl.isVirtualSNMP && ctrl.currentDevice.hwtyp.id === 20 && ctrl.currentAlarm.errorCode < 9;
                    };

                    ctrl.toggleMapConfig = function () {
                        ctrl.viewConfig.showMapConfig = !ctrl.viewConfig.showMapConfig;
                    };

                    ctrl.toggleViewType = function (type) {
                        if (ctrl.isSDCIMDevice) {
                            if (type === "3D" && ctrl.viewConfig.viewType === 1) {
                                ctrl.viewConfig.viewType = 0;
                                AlarmService.toggle2D3D();
                            }
                            if (type === "2D" && ctrl.viewConfig.viewType === 0) {
                                ctrl.viewConfig.viewType = 1;
                                AlarmService.toggle2D3D();
                            }
                        }
                        if (ctrl.isCyberHubNodeDevice || ctrl.isVirtualSNMP) {
                            if (type === "3D" && ctrl.viewConfig.viewType === 1) {
                                ctrl.viewConfig.viewType = 0;
                                WebGLService.switch3D();
                            }
                            if (type === "2D" && ctrl.viewConfig.viewType === 0) {
                                ctrl.viewConfig.viewType = 1;
                                if (AlarmService.getRoom().floorTiles.length) {
                                    WebGLService.updateContentBBox(AlarmService.getRoom().bbox.clone());
                                }
                                WebGLService.switch2D();
                            }
                        }
                    };

                    ctrl.toggleMap = function () {
                        if (ctrl.isSDCIMDevice) AlarmService.toggleMap();
                        if (ctrl.isCyberHubNodeDevice || ctrl.isVirtualSNMP) AlarmService.toggleMapNode();
                    };

                    ctrl.modMapTransparency = function () {
                        if (ctrl.isSDCIMDevice) AlarmService.setMapTransparency(ctrl.viewConfig.mapTransparency);
                        if (ctrl.isCyberHubNodeDevice || ctrl.isVirtualSNMP) AlarmService.setMapTransparencyNode(ctrl.viewConfig.mapTransparency);
                    };

                    ctrl.modMapPosition = function () {
                        if (ctrl.isSDCIMDevice) AlarmService.setMapPosition(ctrl.viewConfig.mapPosition);
                        if (ctrl.isCyberHubNodeDevice || ctrl.isVirtualSNMP) AlarmService.setMapPositionNode(ctrl.viewConfig.mapPosition);
                    };

                    ctrl.changeMapType = function () {
                        if (ctrl.isSDCIMDevice) AlarmService.changeMapType(ctrl.viewConfig.selectedMapType);
                        if (ctrl.isCyberHubNodeDevice || ctrl.isVirtualSNMP) AlarmService.changeMapTypeNode(ctrl.viewConfig.selectedMapType);
                    };

                    ctrl.gotoLocation = function () {
                        ctrl.dismiss();
                        $state.go('location.overview', {id: ctrl.currentAlarm.locationId, mode: 'view'});
                    };

                    ctrl.gotoDeviceEdit = function () {
                        ctrl.dismiss();
                        $state.go('location.devices.edit', {
                            id: ctrl.currentAlarm.locationId,
                            mode: 'view',
                            device: ctrl.currentAlarm.mac,
                            routeState: 'view'
                        });
                    };

                    ctrl.gotoRoom = function () {
                        ctrl.dismiss();
                        Alarm.getAlarmParam(ctrl.currentAlarm.idAlarm).then(function (response) {
                            var requestedParam = response.data;
                            var paramData = requestedParam.value;
                            $log.debug("Alarm: Got params:", paramData);
                            var moreData = true;
                            var paramList = [];

                            while (moreData) {
                                $log.debug("param");
                                var type = paramData.substring(0, paramData.search(' '));
                                paramData = paramData.substring(paramData.search(' ') + 1, paramData.length);

                                var id = paramData.substring(0, paramData.search(' '));
                                paramData = paramData.substring(paramData.search(' ') + 1, paramData.length);

                                paramList.push({type: type, id: id});
                                if (paramData.length <= 2) moreData = false;
                            }
                            $state.go('location.rooms.view', {
                                id: ctrl.currentAlarm.locationId,
                                mode: "view",
                                roomid: paramList[0].id,
                                roomMode: 'view'
                            });

                        }, function () {
                            $log.error('Alarm: Could not get Parameters for Errorcode ', ctrl.currentAlarm.id_alarm);
                        });
                    };

                    ctrl.gotoDevice = function () {
                        ctrl.dismiss();
                        if ((ctrl.isVirtualSNMP || ctrl.isCyberHubNodeDevice) && ctrl.currentAlarm.errorCode < 9) {
                            Alarm.getAlarmParam(ctrl.currentAlarm.idAlarm).then(function (response) {
                                var requestedParam = response.data;
                                if (requestedParam !== null && requestedParam !== {} && requestedParam !== "") {
                                    var paramData = requestedParam.value;
                                    $log.debug("Alarm: Got params:", paramData);
                                    var moreData = true;
                                    var paramList = [];

                                    while (moreData) {
                                        $log.debug("param");
                                        var type = paramData.substring(0, paramData.search(' '));
                                        paramData = paramData.substring(paramData.search(' ') + 1, paramData.length);

                                        var id = paramData.substring(0, paramData.search(' '));
                                        paramData = paramData.substring(paramData.search(' ') + 1, paramData.length);

                                        paramList.push({type: type, id: id});
                                        if (paramData.length <= 2) moreData = false;
                                    }
                                    if (paramList.length >= 1) {
                                        $state.go('location.rooms.view', {
                                            id: ctrl.currentAlarm.locationId,
                                            mode: "view",
                                            roomid: paramList[0].id,
                                            roomMode: 'view'
                                        });
                                    }

                                }
                            }, function () {
                                $log.error('Alarm: Could not get Parameters for Errorcode ', ctrl.currentAlarm.id_alarm);
                            });
                        } else {
                            //ToDo: Anpassung für Ticket #1571 (State go to device view instead of device page)
                            // var target = '/OpenDevice.htm?mac=' + ctrl.intToHex(ctrl.currentDevice.mac) + '&file=/';
                            // $window.open($window.location.origin + target, "_blank");
                            $state.go('location.devices.edit', {
                                id: ctrl.currentAlarm.locationId,
                                mode: 'view',
                                device: ctrl.currentAlarm.mac,
                                routeState: 'view'
                            });
                        }
                    };
                }]
        });

})
();


