(function () {
    'use strict';

    angular.module('emsv2App')
        .factory('Principal', function Principal($q, $log, Account, GFXSettingsService, Tools, localStorageService
            , $state, ThemeService) {
            var _identity,
                _authenticated = false,
                _acceptedTerms = false,
                _showChangePasswordSite = false;

            function _showChangePasswordState() {
                $state.go("warning");
            }

            function _isAuthenticated() {
                return _authenticated;
            }

            function _isInRole(role) {
                if (!_authenticated || !_identity || !_identity.roles) {
                    return false;
                }
                return _identity.roles.indexOf(role) !== -1;
            }

            function _redirectToHomepage() {
                var route;
                _identityFunction().then(function (account) {
                    if (_isAuthenticated() && _isInRole('ROLE_ADMIN') || _isInRole('ROLE_DASHBOARD')) {

                        if (Tools.isDefinedNotNull(account.startroute)) {
                            route = account.startroute;
                        } else {
                            route = 'dashboard';
                        }
                    }
                    else {
                        if (Tools.isDefinedNotNull(account.startroute)) {
                            route = account.startroute;
                        } else {
                            var firstRoles = account.roles[0];
                            $log.debug("All Roles ", account.roles);
                            $log.debug("0th Index Role", firstRoles);

                            switch (firstRoles) {
                                case 'ROLE_DASHBOARD':
                                    if (_isAuthenticated() && _isInRole('ROLE_DASHBOARD')) {
                                        route = 'dashboard';
                                    }
                                    break;
                                case 'ROLE_ALARM_MANAGEMENT':
                                    if (_isAuthenticated() && _isInRole('ROLE_ALARM_MANAGEMENT')) {
                                        route = 'alarm.table';
                                    }
                                    break;
                                case 'ROLE_ANALYSIS':
                                    if (_isAuthenticated() && _isInRole('ROLE_ANALYSIS')) {
                                        route = 'analysis';
                                    }
                                    break;
                                case 'ROLE_CONTACT':
                                    if (_isAuthenticated() && _isInRole('ROLE_CONTACT')) {
                                        route = 'contacts';
                                    }
                                    break;
                                case 'ROLE_GROUP':
                                    if (_isAuthenticated() && _isInRole('ROLE_GROUP')) {
                                        route = 'groupOverview';
                                    }
                                    break;
                                case 'ROLE_LEGALS':
                                    if (_isAuthenticated() && _isInRole('ROLE_LEGALS')) {
                                        route = 'legal';
                                    }
                                    break;
                                case 'ROLE_LICENSING':
                                    if (_isAuthenticated() && _isInRole('ROLE_LICENSING')) {
                                        route = 'license';
                                    }
                                    break;
                                case 'ROLE_LOCATION_CREATE':
                                    if (_isAuthenticated() && _isInRole('ROLE_LOCATION_CREATE')) {
                                        route = 'location';
                                    }
                                    break;
                                case 'ROLE_LOCATION_VIEW':
                                    if (_isAuthenticated() && _isInRole('ROLE_LOCATION_VIEW')) {
                                        route = 'location';
                                    }
                                    break;
                                case 'ROLE_LOCATION_DELETE':
                                    if (_isAuthenticated() && _isInRole('ROLE_LOCATION_DELETE')) {
                                        route = 'location';
                                    }
                                    break;
                                case 'ROLE_LOGS':
                                    if (_isAuthenticated() && _isInRole('ROLE_LOGS')) {
                                        route = 'logfiles';
                                    }
                                    break;
                                case 'ROLE_REPORT':
                                    if (_isAuthenticated() && _isInRole('ROLE_REPORT')) {
                                        route = 'reports';
                                    }
                                    break;
                                case 'ROLE_USER_MANAGEMENT':
                                    if (_isAuthenticated() && _isInRole('ROLE_USER_MANAGEMENT')) {
                                        route = 'userOverview';
                                    }
                                    break;
                                case 'ROLE_USER':
                                    if (_isAuthenticated() && _isInRole('ROLE_USER')) {
                                        route = 'location';
                                    }
                                    break;
                                default:
                                    route = 'dashboard';
                                    break;
                            }
                        }
                    }
                    // route = Tools.isDefinedNotNull(account) && Tools.isDefinedNotNull(account.startroute) ? account.startroute : route;
                    if (route.indexOf('/') === -1) {
                        $state.go(route);
                    } else {
                        var str = route.substr(route.lastIndexOf('#') + 1);
                        location.href = '#' + str; // custom url
                    }
                    ThemeService.check(account);
                });
            }

            function _identityFunction(force) {
                var deferred = $q.defer();
                if (force === true) {
                    _identity = undefined;
                }

                // check and see if we have retrieved the identity data from the server.
                // if we have, reuse it by immediately resolving

                //commented because of CGLT-574
                if ($state.current.name === "warning") {
                    if (Tools.isDefinedNotNull(_identity)) {
                        deferred.resolve(_identity);
                        return deferred.promise;
                    }
                }

                // retrieve the identity data from the server, update the identity object, and then resolve.
                Account.getAccount().then(function (account) {
                    _identity = new UserInfo(account.data);
                    _identity.graphics = GFXSettingsService.getSettingsForUser(_identity.login);
                    if (_identity.settings.length === 0) {
                        $log.debug("No user settings creating default settings for temp and theme");
                        _identity.settings[0] = {key: "countQuitedAlarms", value: "0", id: -1};
                        _identity.settings[1] = {key: "temp", value: "0", id: -2};
                        _identity.settings[2] = {key: "theme", value: "0", id: -3};
                        _identity.settings[3] = {key: "weight", value: "0", id: -4};
                        _identity.settings[4] = {key: "playAlarmSound", value: true, id: -5};
                    }
                    _authenticated = true;
                    _acceptedTerms = _identity.terms;
                    if ($state.current.name !== "warning") {
                        _showChangePasswordSite = _identity.showChangePasswordSite;
                    }
                    deferred.resolve(_identity);
                }).catch(function () {
                    _identity = null;
                    _authenticated = false;
                    _acceptedTerms = false;
                    deferred.resolve(_identity);
                });
                return deferred.promise;
            }

            return {
                redirectToHomepage: function () {
                    _redirectToHomepage();
                },
                showChangePasswordState: function () {
                    if ($state.current.name === "login" || $state.current.name === "home") {
                        _showChangePasswordState();
                    }
                },
                showChangePasswordSite: function () {
                    return _showChangePasswordSite;
                },
                setShowChangePasswordSite: function (bool) {
                    _showChangePasswordSite = bool;
                },
                isIdentityResolved: function () {
                    return Tools.isDefinedNotNull(_identity);
                },
                isAuthenticated: function () {
                    return _isAuthenticated();
                },
                isTermsAccepted: function () {
                    return _acceptedTerms;
                },
                isInRole: function (role) {
                    return _isInRole(role);
                },
                isInAnyRole: function (roles) {
                    if (!_authenticated || !_identity.roles) {
                        return false;
                    }
                    for (var i = 0; i < roles.length; i++) {
                        if (this.isInRole(roles[i])) {
                            return true;
                        }
                    }
                    return false;
                },
                authenticate: function (identity) {
                    _identity = identity;
                    _authenticated = identity !== null;
                    _acceptedTerms = identity !== null && identity.terms;
                },
                identity: function (force) {
                    return _identityFunction(force);
                },
                isIdentityPossiblyResolveable: function () {
                    return Tools.isDefinedNotNull(localStorageService.get("token"))
                }
            };
        });
})();
