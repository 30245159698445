(function(){
    'use strict';

    /**
     * @description component to handle consumption input fields
     */
    angular.module("emsv2App")
        .component('maxConsumptionInput', {
            require: {
                ngModelCtrl : "ngModel"
            },
            bindings:{
                settings: '<',
                ngModel: '='
            },
            template:
                '<div class="form-group">' +
                '<label  translate="room.edit.maxConsumption" class="col-sm-4 control-label"></label>' +
                '<div class="col-sm-8">'+
                '<input type="number" ng-model="$ctrl.ngModel" class="form-control" ondragstart="return false;"/>' +
                '</div>' +
                '</div>',
            controller:[function(){
                var ctrl = this;
                ctrl.maxConsumption = 0;
            }],
            controllerAs:'$ctrl'
        });

    angular.module("emsv2App")
        .component('currentConsumptionInput', {
            require: {
                ngModelCtrl : "ngModel"
            },
            bindings:{
                settings: '<',
                ngModel: '='
            },
            template:
                '<div class="form-group">' +
                '<label  translate="room.edit.currConsumption" class="col-sm-4 control-label"></label>' +
                '<div class="col-sm-8">'+
                '<input type="number" ng-model="$ctrl.ngModel"  readonly="true" class="form-control" ondragstart="return false;"/>' +
                '</div>' +
                '</div>',
            controller:[function(){
                var ctrl = this;
                ctrl.currConsumption = 0;
            }],
            controllerAs:'$ctrl'
        });

})();
