(function () {
    'use strict';

    /**
     * Base rsql metadata service.
     * @ngdoc service
     * @name Filter
     * @requires $http
     */
    angular.module('emsv2App').service('Filter', ['$http',
        function ($http) {
            return {
                saveQuery: function (table, data) {
                    return $http.put('api/rsql/' + table + '/queries', data);
                },
                deleteQuery: function (table, query_id) {
                    return $http.delete('api/rsql/' + table + '/queries/' + query_id);
                },
                getSavedQueries: function (table) {
                    return $http.get('api/rsql/' + table + '/queries');
                },
                getDefinitions: function (table) {
                    return $http.get('api/rsql/' + table + '/definitions');
                }
            };
        }
    ]);

    /**
     * Rsql v2 metadata service.
     * @ngdoc service
     * @name Rsql
     * @requires $http
     */
    angular.module('emsv2App').service('Rsql', ['$http',
        function ($http) {
            return {
                find: function (module, page, query) {
                    page = page !== undefined && page !== null ? page : 0;
                    query = query !== undefined && query !== null ? query : '';
                    return $http.get('api/rsql2/' + module + '/query/' + page + '/20/' + query);
                },
                getAllDefinitions: function (table) {
                    return $http.get('api/rsql2/' + table + '/definitions/all');
                }
            };
        }
    ]);

})();
