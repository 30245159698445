'use strict';

/**
 * @description Constructor to create new driver parameter value object
 * @param {Number} id the id to set
 * @param {Number} driverParameterId the parent id to set
 * @param {String} description the description to set
 * @param {Name} value the value to set
 * @constructor
 */
function DriverParameterValue(id, driverParameterId, description, value) {
    this.id = id !== undefined ? id : null;
    this.driverParameterId = driverParameterId !== undefined ? driverParameterId : null;
    this.description = description !== undefined ? description : "";
    this.value = value !== undefined ? value : null;
}

DriverParameterValue.prototype.constructor = DriverParameterValue;
