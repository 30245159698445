(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetimage', function () {
        return {
            restrict: 'E',
            controller: 'widgetImageController',
            controllerAs: 'imageCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/image/widget.image.template.html'
        };
    });

    app.directive('fallbackImgTag', function ($log, $translate, Tools) {
        function controller() {
            var ctrl = this;
            ctrl.baseUrl = null;

            ctrl.setErrorMessage = function (imgTag, errorSpan) {
                ctrl.error = "";
                imgTag.style.display = 'none';
                errorSpan.style.display = 'block';
                var imgFormat = imgTag.src.substring(imgTag.src.indexOf('/') + 1, imgTag.src.indexOf(';'));
                if (imgFormat !== 'jpeg' || imgFormat !== 'png') {
                    if (imgFormat === 'http:/' || imgFormat === 'https:/' || imgFormat === 'https:' || imgFormat === '') {
                        ctrl.error = $translate.instant("dashboard.widget.image.error.emptyURL");
                    } else ctrl.error = $translate.instant("dashboard.widget.image.error.wrongFormat");
                } else ctrl.error = $translate.instant("dashboard.widget.image.error.brokenURL");
            }
        }

        return {
            controller: controller,
            controllerAs: 'imageErrorCtrl',
            link: function postLink(scope, iElement, iAttrs, controller) {
                iElement.bind('error', function () {
                    var imgTag = document.getElementById('imageTag' + scope.widget.wid);
                    var errorSpan = document.getElementById('errorSpan' + scope.widget.wid);
                    controller.setErrorMessage(imgTag, errorSpan);
                });
                iElement.bind('load', function () {
                    var imgTag = document.getElementById('imageTag' + scope.widget.wid);
                    var errorSpan = document.getElementById('errorSpan' + scope.widget.wid);
                    imgTag.style.display = 'block';
                    errorSpan.style.display = 'none';
                });
            }
        }
    });
    app.directive('report', function () {
        return {
            controller: 'ReportsGenerateController',
            controllerAs: 'reportCtrl'
        }
    })
})();
