(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgetdate', ['$interval', function ($interval) {

        function link(scope, element, attrs, controller) {
            // start the UI update process; save the timeoutId for canceling
            var timeoutId = $interval(function () {
                controller.updateDate();
            }, 1000);

            element.on('$destroy', function () {
                $interval.cancel(timeoutId);
            });

            //Initial call so that the date will be displayed immediately
            controller.updateDate();
        }

        return {
            restrict: "E",
            controller: 'WidgetDateController',
            controllerAs: 'dateCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/date/widget.date.template.html',
            link: link
        };
    }]);
})();
