(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name date
     * @param {number} value - The unix timestamp as 'long' to format
     */
    angular.module('emsv2App')
        .component('date', {
            bindings: {
                value: '<'
            },
            template: '<span ng-bind="$ctrl.formatted"></span>',
            controller: ['$translate', function ($translate) {
                var ctrl = this;
                var dateFormat = $translate.instant('global.dateFormat');
                ctrl.formatted = ctrl.value && dateFormat
                    ? moment(ctrl.value).format(dateFormat)
                    : $translate.instant('global.na');
            }]
        });

})();
