(function(){
    'use strict';

    /**
     * @description directive to modify slot height input fields to allow to either enter the height in meters or in height units (HU)
     */
    angular.module("emsv2App").directive("slotHeight", function(){
        return {
            restrict:'A',
            require:"ngModel",
            scope:{
                heightType:'='
            },
            link:function(scope, element, attrs, ngModel){
                ngModel.$parsers.push(function handleInput(value){
                   if(scope.heightType === 0) return value;
                   if(scope.heightType === 1) return value * 0.0424;
                });

                var formatValue = function(value){
                    if(scope.heightType === 0) return value;
                    if(scope.heightType === 1) {
                        var val = value/0.0424;
                        return Math.round(val*100)/100;
                    }
                };

                ngModel.$formatters.push(formatValue);

                scope.$watch("heightType", function(n,o){
                    ngModel.$viewValue = formatValue(ngModel.$modelValue);
                    ngModel.$render();
                });
            }
        };
    });


})();
