(function () {
    'use strict';
    angular.module('emsv2App').directive('emsInclude', function ($compile, $http) {
        var setHTMLContent = function (elem, templateUrl, scope) {
            $http.get(templateUrl).then(function (resp) {
                $(elem).html($compile(resp.data)(scope));
            }, function (resp) {
                console.log(resp);
            });
        };
        return {
            compile: function (elem, attrs) {
                return {
                    pre: function (scope, elem, attrs) {
                        setHTMLContent(elem, scope.ctrl.view, scope);
                        scope.$watch('ctrl.view', function (n, o) {
                            if (n !== undefined && n != o) {
                                setHTMLContent(elem, scope.ctrl.view, scope);
                            }
                        });
                    },
                    post: function (scope, elem, attrs) {

                    }
                };
            }
        };
    });
})();
