(function () {
    'use strict';

    angular.module('emsv2App').directive('webGl', function ($compile, $http, WebGLService) {
        return {
            scope: {
                obj: '=',
                options: '='
            },
            // controller:['$scope','WebGLService', function($scope, WebGLService){
            //     $scope.config = {
            //         viewType:0
            //     };
            //     $scope.show3D = function(){
            //         if($scope.config.viewType == 1) {
            //             $scope.config.viewType = WebGLService.switch2D3D();
            //         }
            //     };
            //     $scope.show2D = function(){
            //         if($scope.config.viewType == 0) {
            //             $scope.config.viewType = WebGLService.switch2D3D();
            //         }
            //     };
            // }],
            link: function (scope, elem, attr) {
                if (scope.options.hasOwnProperty('addControlOverlay') && scope.options.addControlOverlay) {
                    $http.get('./scripts/components/webgl/webgl.controls.html')
                        .success(function (data) {
                            // elem.html("");
                            // elem.append($compile(data)(scope));
                            // WebGLService.init(elem[0].id, scope.options);
                            //debug
                            // WebGLService.addControlListener("debug",function(){
                            //    console.log("control update");
                            // });
                        })
                        .error(function (data) {
                            $log.warn('Unable to load control overlay template\n' + data);

                        });
                }
                else {
                    // WebGLService.init(elem[0].id, scope.options);
                    //debug
                    // WebGLService.addControlListener("debug",function(){
                    //     console.log("control update");
                    // });
                }
            }
        }
    });
})();
