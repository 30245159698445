(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Alarm
     */
    angular.module('emsv2App').service('Alarm', function ($http, $q, Tools) {
        return {
            getGlobalCount: function () {
                return $http.get('api/alarm/count');
            },
            getGlobalErrorLogCount: function () {
                return $http.get('api/errorlog/count');
            },
            getAlarmParam: function (alarmId) {
                return $http.get('/api/alarm/byid/' + alarmId + '/params');
            },
            getAlarmsForEntity: function (entityType, entityId) {
                var promise = $q.defer();
                $http.post("api/alarm/entity", {type: entityType, id: entityId}).then(function (response) {
                    promise.resolve(response.data);
                }, function (error) {
                    promise.reject(error);
                });
                return promise.promise;
            },
            getCount: function (locationId, prio) {
                return $http.get('api/alarm/location/' + locationId + '/count/LocPrioGoE/' + prio).then(function (response) {
                    return response.data;
                }, function (error) {
                    return error;
                });
            },
            rsql: function (page, query, pageSize, fullTextSearch) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
                return $http.get('api/alarm/query/' + page + '/' + pageSize + query);
            },
            fetchAllAlarms: function (month, year, page, query, pageSize, fullTextSearch) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
                if (Tools.isDefinedNotNull(fullTextSearch)
                    && typeof fullTextSearch === 'boolean'
                    && fullTextSearch) {
                    return $http.get('api/alarm/calendar/query/' + month + '/' + year + '/' + page + '/' + fullTextSearch + '/' + pageSize + '/' + query);
                } else {
                    return $http.get('api/alarm/calendar/query/' + month + '/' + year + '/' + page + '/' + pageSize + '/' + query);
                }
            },
            monthlyAlarmCount: function (month, year, page, query, pageSize, fullTextSearch) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
                if (Tools.isDefinedNotNull(fullTextSearch)
                    && typeof fullTextSearch === 'boolean'
                    && fullTextSearch) {
                    return $http.get('api/alarmCount/calendar/query/' + month + '/' + year + '/' + page + '/' + fullTextSearch + '/' + pageSize + query);
                } else {
                    return $http.get('api/alarmCount/calendar/query/' + month + '/' + year + '/' + page + '/' + pageSize + query);
                }
            },
            monthlyErrorCount: function (month, year, page, query, pageSize, fullTextSearch) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
                if (Tools.isDefinedNotNull(fullTextSearch)
                    && typeof fullTextSearch === 'boolean'
                    && fullTextSearch) {
                    return $http.get('api/historicalCount/calendar/query/' + month + '/' + year + '/' + page + '/' + fullTextSearch + '/' + pageSize + query);
                } else {
                    return $http.get('api/historicalCount/calendar/query/' + month + '/' + year + '/' + page + '/' + pageSize + query);
                }
            },
            dateSpecificAlarmList: function (year, month, query) {
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/daySpecCount/calendar/query/' + year + '/' + month + query);
            },

            errorLogRsql: function (locationId, page, query, pageSize) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
                return $http.get('api/errorlog/query/' + locationId + '/' + page + '/' + pageSize + query);
            },
            locationAlarmRsql: function (locationId, page, query, pageSize, fullTextSearch) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
                return $http.get('api/alarm/locations/' + locationId + '/query/' + page + '/' + fullTextSearch + '/' + pageSize + query);
            },
            getWidgetAlarms: function (widgetId, params) {
                return $http.get('api/alarm/widget/' + widgetId, {params: params});
            }
        };
    });

})();
