(function () {

    'use strict';

    var getConsumer = ['$log', '$stateParams', 'EnergyConsumerService', function ($log, $stateParams, EnergyConsumerService) {
        return EnergyConsumerService.getConsumer().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Error retrieving energy consumer\n" + error.data);
        })
    }];

    var getOldConsumer = ['$log', '$stateParams', 'EnergyConsumerService', function ($log, $stateParams, EnergyConsumerService) {
        return EnergyConsumerService.getOldParameterData().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Error retrieving old consumer\n" + error.data);
        })
    }];


    var getConsumerById = ['$log', '$state', '$stateParams', 'EnergyConsumerService',
        function ($log, $state, $stateParams, EnergyConsumerService) {
            return EnergyConsumerService.getConsumerById($stateParams.eid).then(function (response) {
                return response.data;
            }, function (error) {
                $state.go("notfound");
                $log.error("Error retrieving energy consumer\n" + error.data);
            })
        }];

    var getGroupsLocation = ['$log', '$stateParams', 'Location', function ($log, $stateParams, Location) {
        return Location.getGroups($stateParams.id).then(function (response) {
            if (response.data != null && response.data.length > 0) {
                return response.data;
            }
        }, function (error) {
            $log.error('Error retrieving groups\n' + error.data)
        });
    }];

    var getGroupsUser = ['$log', 'User', function ($log, User) {
        return User.getGroups().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Error retrieving user group\n" + error);
        })
    }];

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('location.ebalance', {
                parent: 'location.detail',
                url: '/ebalance',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.ebalance'
                },
                templateUrl: 'scripts/app/location/tabs/ebalance/ebalance.html',
                controller: 'ebalanceController',
                controllerAs: 'ebctrl',
                resolve: {
                    security: ['$stateParams', '$state', '$q', 'userInfo', 'Location', function ($stateParams, $state, $q, userInfo) {
                        if ($stateParams.mode === 'edit') {
                            if (userInfo.roles.indexOf("ROLE_ADMIN") === -1 && userInfo.roles.indexOf("ROLE_LOCATION_EDIT") === -1) {
                                return $state.go('location.devices' + $stateParams.editElement, {
                                    id: $stateParams.id,
                                    mode: 'view'
                                }, {reload: true});
                            }
                        }
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('systemmessages');
                        $translatePartialLoader.addPart('ebalance');
                        return $translate.refresh();
                    }],
                    consumer: getConsumer,
                    oldConsumer: getOldConsumer,
                    groupsLoc: getGroupsLocation,
                    groupsUser: getGroupsUser
                }
            })
        // .state('location.ebalance.edit', {
        //     parent: 'location.ebalance',
        //     url: '/:eid/edit',
        //     data: {
        //         roles: ['ROLE_USER'],
        //         pageTitle: 'location.home.pageTitle',
        //         breadcrumbsTitle: '{{consumer.name}}'
        //     },
        //     templateUrl: 'scripts/app/location/tabs/ebalance/input.mask.html',
        //     controller: 'ebalanceEditController',
        //     controllerAs: 'ebctrl',
        //     resolve: {
        //         consumer: getConsumerById
        //     }
        // })
        // .state('location.ebalance.new', {
        //     parent: 'location.ebalance',
        //     url: '/create',
        //     data: {
        //         roles: ['ROLE_USER'],
        //         pageTitle: 'location.home.pageTitle',
        //         breadcrumbsTitle: 'room.consumer.create'
        //     },
        //     templateUrl: 'scripts/app/location/tabs/ebalance/input.mask.html',
        //     controller: 'ebalanceNewController',
        //     controllerAs: 'ebctrl'
        // })
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
