/**
 * Created by MAS on 12.01.2021
 */
angular.module('emsv2App').service('DatapointService', ['$http', '$log', '$q', function ($http) {
    return {
        getAvailableDataPoints: function (locationId, includeArchivedDataPoints) {
            return $http.get('api/locations/' + locationId + '/charts/' + includeArchivedDataPoints);
        },
        getAvailableDataPointsFilteredByPhysType: function (locationId, includeArchivedDataPoints, phyType) {
            return $http.get('api/locations/' + locationId + '/charts/' + includeArchivedDataPoints + "/" + phyType);
        },
        getAvailableDataPointsFilteredByPhysTypeNoMB: function (locationId, includeArchivedDataPoints, phyType) {
            return $http.get('api/locations/' + locationId + '/charts/noMB/' + includeArchivedDataPoints + "/" + phyType);
        },
    }
}]);