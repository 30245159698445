(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgetracks', ['$interval', '$log', function ($interval, $log) {
        return {
            restrict: "E",
            controller: 'WidgetRackController',
            controllerAs: "racksCtrl",
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/racks/widget.racks.template.html'
        };
    }]);
})();
