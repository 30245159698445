'use strict';

/**
 * @description Constructor to create a named entity object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {number} uid the unique id to set
 * @constructor
 */
function NamedEntity(id, pos, size, rot, name, comment, uid) {
    Entity.call(this, id, pos, size, rot, uid);
    this.name = name !== undefined ? name : "";
    this.comment = comment !== undefined || comment !== null ? comment : "";
    this.isNamedEntity = true;
    this.isNameInvalid = false;
    this.isCommentInvalid = false;
}

NamedEntity.prototype = Object.create(Entity.prototype);
NamedEntity.prototype.constructor = NamedEntity;
/**
 * @description Function to test equality between this object and the provided object
 * @param {object} cn the object to compare this object with
 * @returns {boolean} returns true if this object equals the provided object
 */
NamedEntity.prototype.equalsNamedEntity = function (cn) {
    if (!(cn instanceof NamedEntity)) return false;
    if (!this.equalsEntity(cn)) return false;
    if (this.name !== cn.name) return false;
    if (this.comment !== cn.comment) return false;
    return true;
};

/**
 * @description Function to set the position of this object with respect to provided bounding box
 * @param {THREE.Vector3} parentBBoxMin the bounding box minimal point to compute relative position for
 */
NamedEntity.prototype.genRelativePositions = function (parentBBoxMin) {
    this.relX = Math.round((this.pos.x - parentBBoxMin.x) * 1000) / 1000;
    this.relY = Math.round((this.pos.y - parentBBoxMin.y) * 1000) / 1000;
    this.relZ = Math.round((this.pos.z - parentBBoxMin.z) * 1000) / 1000;
};

/**
 * @description Function to compute the 'global' position from objects relative position
 * @param {THREE.Vector3} parentBBoxMin the bounding box minimal point to use for computation of 'global' position
 * @param {object} posRel 3-dimensional vector object describing this objects relative position with respect to provided bounding box
 * @returns {THREE.Vector3} returns 3d vector describing the global position of the object
 */
NamedEntity.prototype.getRealPositionFromRelative = function (parentBBoxMin, posRel) {
    var pos = new THREE.Vector3();
    pos.x = Math.round((parentBBoxMin.x + posRel.x) * 1000) / 1000;
    pos.y = Math.round((parentBBoxMin.y + posRel.y) * 1000) / 1000;
    pos.z = Math.round((parentBBoxMin.z + posRel.z) * 1000) / 1000;
    return pos;
};

/**
 * @description Function to generate floor position info for this object
 */
NamedEntity.prototype.genFloorPosition = function () {
    if (this.stdFloorPos === undefined) return;
    this.floorPosition = this.stdFloorPos;
    // this.floorPosition = this.floorPanelCustomPos !== null ? this.floorPanelCustomPos : "";
    // if(this.floorPosition !== "")   this.floorPosition = this.floorPosition.replace("|fp|",this.stdFloorPos);
    // else                            this.floorPosition = this.stdFloorPos;
};

/**
 * @description Function validates this object
 * @returns {ErrorObject[]} array of error objects
 */
NamedEntity.prototype.validateNamedEntity = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateEntity());
    errorList = errorList.concat(this.validateNameComment());
    return errorList;
};

/**
 * @description Function to validate this objects name and comment properties
 * @returns {ErrorObject[]} returns array of error objects
 */
NamedEntity.prototype.validateNameComment = function () {
    var errorList = [];
    if (this.name === undefined || this.name === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "name"));
    if (this.comment === undefined || this.comment === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "comment"));
    return errorList;
};

/**
 * @description Function to retrieve the position of this object with respect to provided bounding box
 * @param {THREE.Vector3} parentBBoxMin the bounding box minimal point to use for computation of relative position
 * @param {object} pos 3-dimensional vector object to use for the computation
 * @returns {*} returns the relative position for this object with respect to the provided minimal point
 */
NamedEntity.getRelativePosition = function (parentBBoxMin, pos) {
    pos.sub(parentBBoxMin);
    pos.x = Math.round(pos.x * 1000) / 1000;
    pos.y = Math.round(pos.y * 1000) / 1000;
    pos.z = Math.round(pos.z * 1000) / 1000;
    return pos;
};

