'use strict';

/**
 * @description Constructor to create new driver value object
 * @param {number} id the id to set
 * @param {number} uniqueId the unique id to set
 * @param {Driver} driver the driver to set
 * @param {DriverParameter} parameter the parameter to set
 * @param consumerType
 * @param {number} sensorId the sensor id to set
 * @param physicalType
 * @param {boolean} useHover if true this driver value will be used in overlay
 * @param {number} driverValueGroupId the driverValueGroupId assigned to this driverValue
 * @param {number} deviceMac device mac
 * @param {String} name name
 * @constructor
 */

function DriverValue(id, uniqueId, driver, parameter, consumerType, sensorId, physicalType, useHover, driverValueGroupId, deviceMac, name, recordLiveData, getAndSet) {
    this.id = id !== undefined ? id : -1;
    this.name = name !== undefined ? name : "not provided";
    this.uniqueId = uniqueId !== undefined ? uniqueId : -1;
    this.driver = driver !== undefined ? driver : null;
    this.parameter = parameter !== undefined ? parameter : null;
    this.deviceMac = deviceMac !== undefined ? deviceMac : null;
    this.driverParameterId = null;
    this.consumerType = consumerType !== undefined ? consumerType : null;
    this.sensorId = sensorId !== undefined ? sensorId : null;
    this.physicalType = physicalType !== undefined ? physicalType : null;
    this.limits = [];
    this.useHover = useHover !== undefined ? useHover : false;
    this.driverValueGroupId = driverValueGroupId !== undefined ? driverValueGroupId : null;
    this.recordLiveData = (recordLiveData === undefined || recordLiveData === null) ? true : recordLiveData;
    this.getAndSet = 'getOnly';
}

DriverValue.toggleRecordLiveData = function (driverValues) {
    driverValues.recordLiveData = !driverValues.recordLiveData;
};

DriverValue.prototype = {};
DriverValue.prototype.constructor = DriverValue;

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
DriverValue.prototype.validate = function () {
    var errorList = [];

    if (this.id === undefined || this.id === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "id"));
    if (this.uniqueId === undefined || this.uniqueId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "uniqueId"));
    // if (this.sensorId === undefined || this.sensorId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.sensorId, "sensorId"));
    if (this.physicalType === undefined || this.physicalType === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.physicalType, "physicalType"));

    if (this.driver !== undefined && this.driver !== null) {
        errorList = errorList.concat(Driver.validateDriverObject(this.driver));
    }

    for (var i = 0; i < this.limits.length; i++) {
        errorList = errorList.concat(this.limits[i].validate());
    }
    return errorList;
};

DriverValue.prototype.findObjectPathByUniqueId = function (uniqueId) {
    var i, retObjs = [];
    if (this.uniqueId === uniqueId) return [this];
    if (this.driver !== undefined && this.driver !== null && this.driver.uniqueId === uniqueId) return [this, this.driver];
    for (i = 0; i < this.limits.length; i++) {
        if (this.limits[i].uniqueId === uniqueId) return [this, this.limits[i]];
    }
    return retObjs;
};

/**
 * @description Function to validate provided object as driver value object
 * @param {object} obj the object to validate
 * @returns {ErrorObject[]} returns array of error objects
 */
DriverValue.validateGeneral = function (obj) {
    var errorList = [];

    if (obj.id === undefined || obj.id === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "id"));
    if (obj.uniqueId === undefined || obj.uniqueId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "uniqueId"));
    if (obj.sensorId === undefined || obj.sensorId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.sensorId, "sensorId"));
    if (obj.physicalType === undefined || obj.physicalType === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.physicalType, "physicalType"));

    for (var i = 0; i < obj.limits.length; i++) {
        if (obj.limits[i] instanceof Limit) {
            errorList = errorList.concat(obj.limits[i].validate());
        } else {
            errorList = errorList.concat(Limit.validateGeneral(obj.limits[i]));
        }
    }

    return errorList;
};

/**
 * @description Function to create new driver value object from json data object from backend
 * @param {object} obj the json data object to use
 * @returns {DriverValue} returns created driver value object
 */
DriverValue.parseFromHtmlObject = function (obj) {
    var dv = new DriverValue(obj.id, obj.uniqueId, obj.driver, obj.parameter, obj.consumerType, obj.sensorId, obj.physicalType, obj.useHover, obj.driverValueGroupId, obj.deviceMac, obj.name, obj.recordLiveData);
    if (obj.parameter !== undefined && obj.parameter !== null) dv.driverParameterId = obj.parameter.id;
    if (obj.limits !== undefined && obj.limits !== null) {
        dv.limits = [];
        for (var i = 0; i < obj.limits.length; i++) {
            dv.limits.push(Limit.parseFromHtmlObject(obj.limits[i]));
        }
    }
    return dv;
};

/**
 * @description Function to create new driver value object from simple javascript object
 * @param {object} obj the javascrpt object to use
 * @returns {DriverValue} returns created driver value object
 */
DriverValue.parseFromSimpleObject = function (obj) {
    if (obj === undefined || obj === null) return;
    if (obj.uniqueId === undefined || obj.id === undefined) return;
    var dv = new DriverValue(obj.id, obj.uniqueId, obj.driver, obj.parameter, obj.consumerType, obj.sensorId, obj.physicalType, obj.useHover, obj.driverValueGroupId);
    if (obj.parameter !== null) dv.driverParameterId = obj.parameter.id;
    if (obj.limits !== undefined && obj.limits !== null) {
        dv.limits = [];
        for (var i = 0; i < obj.limits.length; i++) {
            dv.limits.push(Limit.parseFromSimpleObject(obj.limits[i]));
        }
    }
    return dv;
};


