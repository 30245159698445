(function () {
    'use strict';
    angular.module("emsv2App").controller("PartlibEditController", function ($rootScope, $translate, PartlibService, $state, $stateParams, $window, Notify, GenDialogService, $scope, part, user, Principal) {

        var ctrl = this;
        ctrl.create = $stateParams.create === "";
        ctrl.edit = $stateParams.editType !== undefined;
        ctrl.InvalidRackHuNumber = false;
        var objectType = $stateParams.editType ? $stateParams.editType : $stateParams.viewType;

        ctrl.types = [
            {name: "partlib.assets", id: 1},
            {name: "partlib.coolings", id: 2},
            {name: "partlib.floortiles", id: 3},
            {name: "partlib.racks", id: 4},
            {name: "partlib.upss", id: 5},
            {name: "partlib.slots", id: 6}
        ];

        var getPartFromType = function () {
            if ($stateParams.type != undefined) {
                var x = PartlibService.convertTabToType($stateParams.type);
                return PartlibService.convertTabToType($stateParams.type);
            }
            return null;
        };

        ctrl.part = part;
        var initPart = null;

        if (ctrl.part == null) {
            ctrl.part = {
                type: getPartFromType(),
                manufacturer: $translate.instant("partlib.generic"),
                model: $translate.instant("partlib.generic"),
                name: "",
                id: null,
                uniqueId: null,
                serialNumber: "",
                comment: "",
                width: 1,
                height:  $stateParams.type ==2?2:1,
                depth: 1,
                groupId: null,
                customEntity: true,
                airDirection: "1",
                maxCooling: 0,
                heightUnits: 42
            }
        }
        else {
            var type = null;
            if (objectType == "Asset") type = 1;
            if (objectType == "Cooling") type = 2;
            if (objectType == "FloorTile") type = 3;
            if (objectType == "Rack") type = 4;
            if (objectType == "Ups") type = 5;
            if (objectType == "Slot") type = 6;
            ctrl.part.type = type;
            if (ctrl.part.type == 2) {
                ctrl.part.airDirection = ctrl.part.airDirection.toString();
            }
        }

        if (initPart === undefined || initPart === null) initPart = angular.copy(ctrl.part);

        ctrl.hasRole = function (role) {
            var roles = Principal.isInRole('ROLE_' + role);
            return roles;
        };

        ctrl.handleRackHUNumber = function () {
            if (ctrl.part.type == 4) {
                ctrl.maxHu = Math.round(ctrl.part.height / 0.046);
                document.getElementById("partheightunit").setAttribute("max", ctrl.maxHu);
                ctrl.InvalidRackHuNumber = !(ctrl.part.heightUnits >= 1 && ctrl.part.heightUnits <= ctrl.maxHu);
            }
        };

        ctrl.validateInput = function () {
            if (ctrl.part.type === null) return false;
            if (ctrl.part.name == undefined || ctrl.part.name === "") return false;
            if (ctrl.part.manufacturer == undefined || ctrl.part.manufacturer === "") return false;
            if (ctrl.part.model == undefined || ctrl.part.model === "") return false;
            if (ctrl.part.width == undefined || ctrl.part.height == undefined || ctrl.part.depth == undefined) return false;
            if (ctrl.part.width < 0.001 || ctrl.part.height < 0.001 || ctrl.part.depth < 0.001) return false;

            // check racks
            if (ctrl.part.type == 4) {
                ctrl.handleRackHUNumber();
                if (ctrl.part.heightUnits == undefined || ctrl.part.heightUnits < 1 || ctrl.part.heightUnits > 100 || ctrl.part.heightUnits > ctrl.maxHu) return false;
            }

            // check cooling
            if (ctrl.part.type == 2) {
                if (ctrl.part.maxCooling == undefined || ctrl.part.maxCooling < 1 || ctrl.part.maxCooling > 10000) return false;
            }

            return true;
        };

        ctrl.comparePartLibValues = function (settingsA, settingsB) {

            if (settingsA !== null) {
                var aProps = Object.getOwnPropertyNames(settingsA);
                var bProps = Object.getOwnPropertyNames(settingsB);

                if (aProps.length !== bProps.length) {
                    return true;
                }
                if (settingsA["type"] !== settingsB["type"]) return true;
                if (settingsA["manufacturer"] !== settingsB["manufacturer"]) return true;
                if (settingsA["model"] !== settingsB["model"]) return true;
                if (settingsA["name"] !== settingsB["name"]) return true;
                if (settingsA["comment"] !== settingsB["comment"]) return true;
                if (settingsA["serialNumber"] !== settingsB["serialNumber"]) return true;
                if (settingsA["width"] !== settingsB["width"]) return true;
                if (settingsA["height"] !== settingsB["height"]) return true;
                if (settingsA["depth"] !== settingsB["depth"]) return true;
                if (settingsA["airDirection"] !== settingsB["airDirection"]) return true;
                if (settingsA["maxCooling"] !== settingsB["maxCooling"]) return true;
                if (settingsA["heightUnits"] !== settingsB["heightUnits"]) return true;

                return false;
            }
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalpartLibChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalpartLibChange = function () {
            var currPart = angular.copy(ctrl.part);
            if (ctrl.comparePartLibValues(initPart, currPart)) return true;
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (ctrl.evalpartLibChange() && !ignoreNextStateChange) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('global.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('global.modal.empty.yes'),
                    textButton0: $translate.instant('global.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initPart = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        ctrl.savePart = function () {
            if (!this.validateInput()) {
                Notify.error("global.notification.error.title", "partlib.notifications.invalidInputs", 2000);
                return;
            }
            PartlibService.savePart(ctrl.part).then(function (response) {
                Notify.success("global.notification.success.title", "partlib.notifications.saveSuccess", 2000);
                initPart = angular.copy(ctrl.part);
                gotoListView(ctrl.part.type);
            }, function (error) {
                Notify.error("global.notification.error.title", "partlib.notifications.saveError", 2000);
            });
        };

        ctrl.goBack = function () {
            gotoListView(ctrl.part.type);
        };

        var gotoListView = function (activeTab) {
            $state.go("partlib", {objectType: activeTab}, {reload: true});
        };

    });
})();
