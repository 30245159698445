(function () {
    'use strict';

    function NavbarController($scope, $state, $rootScope, $timeout, $translate, Auth, Principal, SmtpEmailServerConfigurationService) {

        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.$state = $state;
        $scope.isWarningClose = false;
        $scope.mailSettingsOk = true;
        SmtpEmailServerConfigurationService.get().then(function (response) {
            if (response.data !== null && response.data !== "") {
                $scope.mailSettingsOk = true;
            } else {
                $scope.mailSettingsOk = false;
            }
        });
        $scope.$state = $state;

        $scope.roleReturn = function (role) {
            return Principal.isInRole('ROLE_' + role);
        };

        $scope.logout = function () {
            Auth.logout();
        };

        $scope.goToMailSetting = function () {
            $state.go('mailsettings');
        };

        $scope.closeWarn = function () {
            $scope.isWarningClose = true;
        };
    }

    angular
        .module('emsv2App')
        .controller('NavbarController', NavbarController);

})();
