'use strict';

/**
 * @description Constructor to create new room object
 * @constructor
 */
function RoomConfiguration(roomConfiguration) {
    if (roomConfiguration !== undefined && roomConfiguration !== null) {
        this.temperatureSetpoint = roomConfiguration.temperatureSetpoint !== undefined ? roomConfiguration.temperatureSetpoint : 0;
        this.temperatureThreshold = roomConfiguration.temperatureThreshold !== undefined ? roomConfiguration.temperatureThreshold : 0;
        this.pressureSetpoint = roomConfiguration.pressureSetpoint !== undefined ? roomConfiguration.pressureSetpoint : 0;
        this.pressureThreshold = roomConfiguration.pressureThreshold !== undefined ? roomConfiguration.pressureThreshold : 0;
        this.useForLimits = roomConfiguration.useForLimits !== undefined ? roomConfiguration.useForLimits : false;
    } else {
        this.temperatureSetpoint = 0;
        this.temperatureThreshold = 0;
        this.pressureSetpoint = 0;
        this.pressureThreshold = 0;
        this.useForLimits = false;
    }
    if (roomConfiguration === null){
        this.temperatureSetpoint = '';
        this.temperatureThreshold = '';
        this.pressureSetpoint = '';
        this.pressureThreshold = '';
        this.useForLimits = false;
    }
}

RoomConfiguration.prototype = {};
RoomConfiguration.prototype.constructor = RoomConfiguration;
