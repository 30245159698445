(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetipwebcam', function () {
        return {
            restrict: 'E',
            controller: 'widgetIpWebcamController',
            controllerAs: 'ipwcCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/ipwebcam/widget.ipwebcam.template.html'
        };
    });

    app.directive('fallbackVidTag', function ($translate) {
        function controller() {
            var ctrl = this;

            ctrl.setErrorMessage = function (imgTag, errorSpan) {
                ctrl.error = null;
                imgTag.style.display = 'none';
                errorSpan.style.display = 'block';
                ctrl.error = $translate.instant("dashboard.widget.ipwebcam.error.brokenURL");
            };
        }

        return {
            controller: controller,
            controllerAs: 'ipwcErrorCtrl',
            link: function postLink(scope, iElement, iAttrs, controller) {
                iElement.bind('error', function () {
                    var imgTag = document.getElementById('imageTag' + scope.widget.wid);
                    var errorSpan = document.getElementById('errorSpan' + scope.widget.wid);
                    controller.setErrorMessage(imgTag, errorSpan);
                });
                iElement.bind('load', function () {
                    var imgTag = document.getElementById('imageTag' + scope.widget.wid);
                    var errorSpan = document.getElementById('errorSpan' + scope.widget.wid);
                    imgTag.style.display = 'block';
                    errorSpan.style.display = 'none';
                });
            }
        }
    });
})();
