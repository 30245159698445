(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name contactsEdit
     * @requires $log, $timeout, $state, Contacts, notify
     * @param {Object} currentContact - The current contact to edit
     * @param {Object[]} countries - List of avialable countries
     */
    angular.module('emsv2App')
        .component('contactsEdit', {
            bindings: {
                currentContact: '=',
                countries: '='
            },
            templateUrl: 'scripts/app/contacts/edit/contacts-edit.component.html',
            controller: ['$log', '$timeout', '$state', 'Contacts', 'Notify', '$window', 'GenDialogService', '$scope', '$translate',
                function ($log, $timeout, $state, Contacts, Notify, $window, GenDialogService, $scope, $translate) {
                    var ctrl = this;
                    var initContact = null;

                    ctrl.$onInit = function () {
                        if (!ctrl.currentContact.single) {
                            $state.go('contacts');
                        }
                        initContact = angular.copy(ctrl.currentContact);
                    };

                    ctrl.compareContactValues = function (contactA, contactB) {

                        if (contactA !== null) {
                            var aProps = Object.getOwnPropertyNames(contactA);
                            var bProps = Object.getOwnPropertyNames(contactB);

                            if (aProps.length !== bProps.length) {
                                return true;
                            }
                            if (contactA["company"] !== contactB["company"]) return true;
                            if (contactA["country"] !== contactB["country"]) return true;
                            if (contactA["email"] !== contactB["email"]) return true;
                            if (contactA["secEmail"] !== contactB["secEmail"]) return true;
                            if (contactA["fax"] !== contactB["fax"]) return true;
                            if (contactA["firstname"] !== contactB["firstname"]) return true;
                            if (contactA["gender"] !== contactB["gender"]) return true;
                            if (contactA["lastname"] !== contactB["lastname"]) return true;
                            if (contactA["mobile"] !== contactB["mobile"]) return true;
                            if (contactA["phone"] !== contactB["phone"]) return true;
                            if (contactA["street"] !== contactB["street"]) return true;
                            if (contactA["zip"] !== contactB["zip"]) return true;
                            if (contactA["location"] !== contactB["location"]) return true;

                            return false;
                        }
                    };

                    $window.onbeforeunload = function (e) {
                        if (ctrl.evalContactChange()) {
                            e.preventDefault();
                            return $translate.instant('location.modal.changedValues');
                        }
                    };

                    ctrl.evalContactChange = function () {
                        var currContact = angular.copy(ctrl.currentContact);
                        if (ctrl.compareContactValues(initContact, currContact)) return true;
                    };

                    var ignoreNextStateChange = false;
                    $scope.$on("$stateChangeStart", function (event, toState, toParams) {
                        if (ctrl.evalContactChange() && !ignoreNextStateChange) {
                            event.preventDefault();
                            GenDialogService.showDialog(false, {
                                headText: $translate.instant('global.dialog.head.warning'),
                                headIcon: 'glyphicon glyphicon-warning-sign',
                                messageText: $translate.instant('global.modal.changedValues'),
                                showClose: false,
                                textButton1: $translate.instant('global.modal.empty.yes'),
                                textButton0: $translate.instant('global.modal.empty.no'),
                                iconButton1: 'glyphicon glyphicon-trash',
                                iconButton0: 'glyphicon glyphicon-chevron-left',
                                classButton1: 'btn-danger',
                                classButton0: 'btn-default',
                                callbackButton1: function () {
                                    GenDialogService.hideDialog();
                                    ignoreNextStateChange = true;
                                    initContact = null;
                                    $state.go(toState.name, toParams);
                                },
                                callbackButton0: function () {
                                    GenDialogService.hideDialog();
                                }
                            });
                        }
                    });

                    ctrl.save = function () {
                        return Contacts.save(ctrl.currentContact).then(function (response) {
                            if (ctrl.currentContact.id === null) {
                                Notify.success("global.notification.success.title", "contacts.notification.created", 2000);
                                // Go to edit mode after creation
                                initContact = angular.copy(ctrl.currentContact);
                                $timeout(function () {
                                    $state.go('contacts-edit', {id: response.data.id});
                                });
                            }
                            else {
                                Notify.success("global.notification.success.title", "contacts.notification.saved", 2000);
                                ctrl.currentContact = response.data;
                                initContact = angular.copy(ctrl.currentContact);
                                $state.reload();
                            }
                        }, function (error) {
                            Notify.error("global.notification.error.title", "contacts.notification.cantsave", 3000);
                            $log.error('Couldn\'t save contact!', error);
                        });
                    };
                }]
        });

})();
