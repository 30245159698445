(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name contactsView
     * @requires $log, $timeout, $state, Contacts
     * @param {Object} currentContact - The contact to view
     * @param {Object[]} countries - List of available countries
     */
    angular.module('emsv2App')
        .component('contactsView', {
            bindings: {
                currentContact: '=',
                countries: '='
            },
            templateUrl: 'scripts/app/contacts/view/contacts-view.component.html'
        });

})();
