(function () {
    'use strict';

    function deleteGroupController($scope, $state, Group, selectedGroup, Notify) {
        $scope.deleteBtn = 'init';
        $scope.group = selectedGroup;
        $scope.isVirtual = typeof selectedGroup.virtual === "boolean" ? selectedGroup.virtual : false;
        $scope.isGenerated = typeof selectedGroup.generated === "boolean" ? selectedGroup.generated : false;
        $scope.isDetail = true;
        $scope.placeholder = $translate.instant('global.form.select');

        $scope.accept = function () {
            $scope.deleteBtn = 'load';
            Group.delete($scope.group.id).then(function () {
                $scope.deleteBtn = 'success';
                Notify.success("global.notification.success.title", "global.notification.success.groupDelete", 4000);
                $state.go('groupOverview');
            }, function () {
                $scope.deleteBtn = 'error';
                Notify.success("global.notification.error.title", "global.notification.error.groupDelete", 4000);
            });
        };
    }

    angular
        .module('emsv2App')
        .controller('DeleteGroupController', deleteGroupController);
})();
