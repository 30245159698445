(function () {
    'use strict';

    /**
     * @description Directive to allow setup drag objects
     */
    angular.module('emsv2App').directive("draggable", function (DragDropService) {
        return {
            scope: {
                draginfo: '=',
                dragobj: '='
            },
            link: function (scope, element, attr) {
                var el = element[0];
                el.draggable = true;
                el.addEventListener('dragstart', function (e) {
                    if (scope.draginfo === null) {
                        e.cancelBubble = true;
                        e.preventDefault();
                        return false;
                    }
                    e.dataTransfer.effectAllowed = true;
                    e.dataTransfer.setData("Text", this.id);
                    DragDropService.setDragObject({
                        type: scope.dragobj,
                        id: scope.draginfo
                    });
                    return false;
                }, false);
            }
        }
    });
})();
