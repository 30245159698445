(function () {
    'use strict';

    angular.module('emsv2App')
        .directive('keyTab', function () {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    element.keydown(function (e) {
                        if (e.keyCode === 9) { // tab was pressed
                            // get caret position/selection
                            var start = this.selectionStart;
                            var end = this.selectionEnd;

                            var $this = $(this);

                            // set textarea value to: text before caret + tab + text after caret
                            $this.val($this.val().substring(0, start)
                                + "\t"
                                + $this.val().substring(end));

                            // put caret at right position again
                            this.selectionStart = this.selectionEnd = start + 1;

                            // trigger change
                            element.change();
                            // prevent the focus lose
                            return false;
                        }
                    });
                }
            };
        })
        .directive('keySave', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    var expr = attr["keySave"] || "";
                    if (expr) {
                        element.keydown(function (e) {
                            if (e.keyCode === 83 && e.ctrlKey) { // CTRL + S was pressed
                                scope.$eval(expr);
                                // prevent the browser save dialog to open
                                return false;
                            }
                        });
                    }
                }
            };
        });
})();
