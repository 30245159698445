(function () {

    'use strict';

    /**
     * Licensing information.
     * @ngdoc service
     * @name License
     * @requires $http, $log, $q
     */
    angular.module('emsv2App').service('License', ['$http', '$log', '$q', function ($http, $log, $q) {
        var license = null;
        var macs = null;
        return {
            get: function () {
                if (license !== null) return $q.when(license);
                return $http.get('api/license/').then(function (response) {
                    license = response;
                    return response;
                }, function (error) {
                    $log.error('Couldn\'t retrieve license!', error);
                    return error;
                });
            },
            reload: function () {
                return $http.get('api/license/').then(function (response) {
                    license = response;
                    return license;
                }, function (error) {
                    $log.error('Couldn\'t retrieve license!', error);
                    return error;
                });
            },
            upload: function (data) {
                return $http({
                    url: 'api/license/upload',
                    method: 'POST',
                    data: data,
                    headers: {
                        transformRequest: angular.identity,
                        'Content-Type': undefined
                    }
                });
            },
            getAvailableMac: function () {
                if (macs !== null) return $q.when(macs);
                return $http.get('api/license/getAvailableMac').then(function (response) {
                    macs = response;
                    return macs;
                });
            }
            ,
            getExpired: function () {
                return $http.get('api/license/expired').then(function (response) {
                    return response;
                });
            },

            getContactMail: function () {
                return $http.get('api/license/getContactMail').then(function (response) {
                    return response;
                });
            },

            getDaysExpired: function () {
                return $http.get('api/license/daysExpired').then(function (response) {
                    return response;
                });
            },

            getIsFreeVersion: function () {
                return $http.get('api/license/isFreeVersion').then(function (response) {
                    return response;
                });
            },

            getLocationLimitReached: function () {
                return $http.get('api/license/isLocationLimitReached').then(function (response) {
                    return response;
                });
            },

            getRoomLimitReached: function () {
                return $http.get('api/license/isRoomLimitReached').then(function (response) {
                    return response;
                });
            },

            getAnalysisLimitReached: function () {
                return $http.get('api/license/isAnalysisLimitReached').then(function (response) {
                    return response;
                });
            },

            getReportLimitReached: function () {
                return $http.get('api/license/isReportLimitReached').then(function (response) {
                    return response;
                });
            }
        };
    }]);

})();
