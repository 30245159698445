
'use strict';

// Creates a UserInfo object from the java pojo
function UserInfo(userPojo) {
    this.activated = userPojo.activated;
    this.contact = userPojo.contact;
    this.created = userPojo.created;
    this.graphics = userPojo.graphics;
    this.id = userPojo.id;
    this.langKey = userPojo.langKey;
    this.ldap = userPojo.ldap;
    this.login = userPojo.login;
    this.notify = userPojo.notify;
    this.password = userPojo.password;
    this.roles = userPojo.roles;
    this.sendActivationMail = userPojo.sendActivationMail;
    this.sendInfoMail = userPojo.sendInfoMail;
    this.sendPWUpdateMail = userPojo.sendPWUpdateMail;
    this.settings = userPojo.settings;
    this.startroute = userPojo.startroute;
    this.terms = userPojo.terms;
    this.globalRsqlId = userPojo.globalRsqlId;
    this.graphics = undefined;
    this.showChangePasswordSite = userPojo.showChangePasswordSite;
}

UserInfo.prototype = {};

UserInfo.prototype.hasRole = function (role) {
    return this.roles.findIndex(function (item) {
        return item === role;
    }) > -1;
};

UserInfo.prototype.isAdmin = function () {
    return this.hasRole('ROLE_ADMIN');
};
