(function () {
    "use strict";

    angular.module('emsv2App').service('PhysicalTypeService', function ($q, $http) {

        var _physicalTypes = null;

        /**
         * @description function to get physical types
         * @param {boolean} force if set to true it will always get information from backend, otherwise it will use local information
         * @returns {*}
         */
        function getPhysicalTypes(force) {

            var deferred = $q.defer();

            if (force) _physicalTypes == null;
            if (_physicalTypes !== null) {
                deferred.resolve(_physicalTypes);
                return deferred.promise;
            }
            return $http.get("/api/physType/all").then(function (response) {
                _physicalTypes = response.data;
                return response.data;
            }, function (error) {
                return error;
            });
        }

        function getPhysTypeArray() {
            return $http.get("/api/physType/all");
        }

        return {
            getPhysTypeArray: getPhysTypeArray,
            getPhysicalTypes: getPhysicalTypes
        }
    });
})();
