(function () {
    'use strict';

    /**
     * @description Service to handle measurements in 3d-context
     */
    angular.module('emsv2App').service('GLMeasureService', function (Tools, WebGLService, TrackingDivLabelFactory, NumberService, MathService, MessageService) {

        var colors = {
            wallend: 0xff0000,
            wallNear: 0xffff00,
            wallY: 0x00ff00,
            obj: 0xffff00
        };

        var measureState = 0;

        var measureMesh = new THREE.Mesh(new THREE.BoxBufferGeometry(1, 1, 1), new THREE.MeshBasicMaterial({color: 0xffffff}));
        measureMesh.scale.set(1, 0.05, 0.05);

        var measureObj = null;
        var measureObj3d = null;
        var measureObjContain = null;
        var measureObjParams = null;
        var measureObjObb = null;
        var measureObjRoom = null;
        var negXPoints, posXPoints, negZPoints, posZPoints;
        var measureAgainstObjects = null;
        var negXDir = new THREE.Vector3(-1, 0, 0), posXDir = new THREE.Vector3(1, 0, 0),
            negYDir = new THREE.Vector3(0, -1, 0), posYDir = new THREE.Vector3(0, 1, 0),
            negZDir = new THREE.Vector3(0, 0, -1), posZDir = new THREE.Vector3(0, 0, 1);

        var wallMeasure = {
            wallStart: null,
            wallEnd: null,
            wallTop: null,
            wallBottom: null,
            wallNext: null,
            wallPrev: null
        };

        var objMeasure = {
            negX: null,
            posX: null,
            negZ: null,
            posZ: null
        };

        var wallMeasureLabels = {
            wallStart: null,
            wallEnd: null,
            wallTop: null,
            wallBottom: null,
            wallPrev: null,
            wallNext: null
        };

        var objMeasureLabels = {
            negX: null,
            posX: null,
            negZ: null,
            posZ: null
        };

        var lengthMeasureLabels = {
            0: null,
            1: null,
            2: null
        };

        var ignoreNextClick = false;

        var getIgnoreNextClick = function () {
            var ret = ignoreNextClick;
            if (ignoreNextClick) ignoreNextClick = false;
            return ret;
        };

        /**
         * @description builds a 3d-object of specified len to use in 3d-context
         * @param {number} len the length of the measurement
         * @returns {THREE.Mesh} returns THREE.Mesh where the provided length is scaled on objects x-axis
         */
        var buildMeasureMesh = function (len) {
            var m = measureMesh.clone();
            m.material = new THREE.MeshBasicMaterial({color: 0xffffff});
            m.needsUpdate = true;
            if (len === 0) len = 0.001;
            if (len === Infinity) len = 1;
            m.scale.setX(len);
            return m;
        };

        /**
         * @description function to find the smallest distance in result
         * @param {THREE.Mesh} obj the object to measure distances for
         * @param {array} result the intersecting objects from casting a ray through space
         * @returns {number} returns the smallest distance
         */
        var handleRaycasterResult = function (obj, result) {
            var min = Infinity;
            for (var i in result) {
                if (result[i].object.name.indexOf('window') != -1 && result[i].object.parent.uuid === obj.uuid) continue;
                if (result[i].object.uuid === obj.uuid) continue;
                if (result[i].distance < min) min = result[i].distance;
            }
            return min;
        };
        // wall objs doors windows
        /**
         * @description function to setup measurement objects for doors/windows
         * @param {number} rotation the rotation around the y-asis of used wall in radians
         * @param {THREE.Vector3} start the start of the current wall
         * @param {THREE.Vector3} end the end of the current wall
         * @param {OBB} obb the oriented bounding box for object to measure
         */
        var setWallMeasurePos = function (rotation, start, end, obb) {
            if (measureState) {
                var wallDir = end.clone().sub(start).normalize();
                wallMeasure.wallStart.rotation.y = rotation;
                wallMeasure.wallStart.position.copy(start);
                wallMeasure.wallStart.position.add(wallDir.clone().multiplyScalar(wallMeasure.wallStart.userData.len / 2));
                wallMeasure.wallStart.position.y = obb.c.y - obb.e.y;

                wallMeasure.wallEnd.rotation.y = rotation;
                wallMeasure.wallEnd.position.copy(end);
                wallMeasure.wallEnd.position.add(wallDir.clone().multiplyScalar(wallMeasure.wallEnd.userData.len / 2 * -1));
                wallMeasure.wallEnd.position.y = obb.c.y - obb.e.y;

                wallMeasure.wallTop.position.copy(obb.c);
                wallMeasure.wallTop.position.add(obb.u[1].clone().multiplyScalar(obb.e.y + wallMeasure.wallTop.userData.len / 2));
                wallMeasure.wallTop.rotation.y = rotation;
                wallMeasure.wallTop.rotation.z = Math.PI / 2;

                wallMeasure.wallBottom.position.copy(obb.c);
                wallMeasure.wallBottom.position.add(obb.u[1].clone().multiplyScalar((obb.e.y + wallMeasure.wallBottom.userData.len / 2) * -1));
                wallMeasure.wallBottom.rotation.y = rotation;
                wallMeasure.wallBottom.rotation.z = Math.PI / 2;

                wallMeasure.wallPrev.position.copy(obb.c);
                wallMeasure.wallPrev.position.add(wallDir.clone().multiplyScalar((wallMeasure.wallPrev.userData.len / 2 + obb.e.x) * -1));
                wallMeasure.wallPrev.rotation.y = rotation;
                if (wallMeasure.wallPrev.userData.measurePos === 0) wallMeasure.wallPrev.position.y -= obb.e.y;
                if (wallMeasure.wallPrev.userData.measurePos === 1) wallMeasure.wallPrev.position.y += obb.e.y;

                wallMeasure.wallNext.position.copy(obb.c);
                wallMeasure.wallNext.position.add(wallDir.clone().multiplyScalar(wallMeasure.wallNext.userData.len / 2 + obb.e.x));
                wallMeasure.wallNext.rotation.y = rotation;
                if (wallMeasure.wallNext.userData.measurePos === 0) wallMeasure.wallNext.position.y -= obb.e.y;
                if (wallMeasure.wallNext.userData.measurePos === 1) wallMeasure.wallNext.position.y += obb.e.y;
            }

        };

        /**
         * @description function to set measurement values for input labels
         */
        var setWallMeasureLabels = function () {
            wallMeasureLabels.wallStart.setValue(wallMeasure.wallStart.userData.len);
            wallMeasureLabels.wallEnd.setValue(wallMeasure.wallEnd.userData.len);
            wallMeasureLabels.wallTop.setValue(wallMeasure.wallTop.userData.len);
            wallMeasureLabels.wallBottom.setValue(wallMeasure.wallBottom.userData.len);
            wallMeasureLabels.wallPrev.setValue(wallMeasure.wallPrev.userData.len);
            wallMeasureLabels.wallNext.setValue(wallMeasure.wallNext.userData.len);
            TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
        };

        /**
         * @description function to trigger update of current measurements (after movement of door/window, size change)
         * @param {number} rotation the rotation around the y-axis of the current wall in radians
         * @param {THREE.Vector3} start the start point of the current wall
         * @param {THREE.Vector3} end the end point of the current wall
         * @param {OBB} obb the oriented bounding box of the object to measure
         */
        var updateWallMeasure = function (rotation, start, end, obb) {
            setWallMeasurePos(rotation, start, end, obb);
            setWallMeasureLabels();
        };

        /**
         * @description function to get shortest distances between the object to measure and its surroundings
         * @param {THREE.Object3D} obj the 3d-object to get distances for
         * @param {THREE.Object3D} wall the 3d-object of the current wall
         * @param {array} wallObjects array of other objects to potentially find measurements against
         * @returns {{ls: null, le: null, t: null, b: null}} returns object with information about distances to other objects,
         *          ls: length to start of the wall
         *          le: length to end of the wall
         *          t: length to top of the wall
         *          b: length to the bottom of the wall
         */
        var getDistancesWall = function (obj, wall, wallObjects) {
            // obj.userData.obb.showDebugObject(WebGLService.getDummyObject(), true);
            var ret = {ls: null, le: null, t: null, b: null};
            var pg = obj.userData.obb.c.clone().setY(0);
            ret.ls = pg.clone().sub(wall.userData.s).length();
            ret.le = pg.clone().sub(wall.userData.e).length();
            ret.ls -= obj.userData.obb.e.x;
            ret.le -= obj.userData.obb.e.x;
            ret.lb = obj.position.y - obj.userData.obb.e.y;
            if (wall.userData.type && wall.userData.type == 1) ret.lb -= (wall.userData.obb.c.y - wall.userData.obb.e.y);
            ret.lt = wall.userData.h - (obj.position.y + obj.userData.obb.e.y);
            if (wall.userData.type && wall.userData.type == 1) ret.lt += (wall.userData.obb.c.y - wall.userData.obb.e.y);
            ret.ls = NumberService.roundToPrecision(ret.ls, 3);
            ret.le = NumberService.roundToPrecision(ret.le, 3);
            ret.lt = NumberService.roundToPrecision(ret.lt, 3);
            ret.lb = NumberService.roundToPrecision(ret.lb, 3);
            ret.posx = Infinity;
            ret.negx = Infinity;
            var ray = new THREE.Raycaster();
            if (wallObjects.length > 1) {

                ret.posxCorner = null;
                ret.negxCorner = null;
                // posX, posY
                ray.set(obj.userData.obb.c.clone().add(obj.userData.obb.u[0].clone().multiplyScalar(obj.userData.obb.e.x)).add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y)), obj.userData.obb.u[0].clone());
                ret.posx = handleRaycasterResult(obj, ray.intersectObjects(wallObjects, true));
                ret.posxCorner = 1;
                // posX, negY
                ray.set(obj.userData.obb.c.clone().add(obj.userData.obb.u[0].clone().multiplyScalar(obj.userData.obb.e.x)).add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y * -1)), obj.userData.obb.u[0].clone());
                var tposx = handleRaycasterResult(obj, ray.intersectObjects(wallObjects, true));
                if (tposx < ret.posx) {
                    ret.posx = tposx;
                    ret.posxCorner = 0;
                }
                // negX, posY
                ray.set(obj.userData.obb.c.clone().add(obj.userData.obb.u[0].clone().multiplyScalar(obj.userData.obb.e.x * -1)).add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y)), obj.userData.obb.u[0].clone().multiplyScalar(-1));
                ret.negx = handleRaycasterResult(obj, ray.intersectObjects(wallObjects, true));
                ret.negxCorner = 1;
                // negX, negY
                ray.set(obj.userData.obb.c.clone().add(obj.userData.obb.u[0].clone().multiplyScalar(obj.userData.obb.e.x * -1)).add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y * -1)), obj.userData.obb.u[0].clone().multiplyScalar(-1));
                var tnegx = handleRaycasterResult(obj, ray.intersectObjects(wallObjects, true));
                if (tnegx < ret.negx) {
                    ret.negx = tnegx;
                    ret.negxCorner = 0;
                }
                if (ret.negx !== Infinity) ret.negx = NumberService.roundToPrecision(ret.negx, 3);
                if (ret.posx !== Infinity) ret.posx = NumberService.roundToPrecision(ret.posx, 3);
            }

            return ret;
        };

        /**
         * @description function to initialise a wall measurement object
         * @param {string} name name of the measurement object
         * @param {string} objname the name to set for measurement object
         * @param {number} color hexadecimal color code for measurement 'lines'
         * @param {number} len the length to set for measurement object
         * @param {object} labelTrackingObj the position to setup the label for
         * @param {OBB} obb the oriented bounding box for current object to measure
         */
        var initWallMeasureObject = function (name, objname, color, len, labelTrackingObj, obb) {
            wallMeasure[name] = buildMeasureMesh(len);
            wallMeasure[name].name = objname;
            wallMeasure[name].userData.len = len;
            wallMeasure[name].material.color.setHex(color);
            if (!labelTrackingObj) labelTrackingObj = wallMeasure[name].position;
            wallMeasureLabels[name] = TrackingDivLabelFactory.buildLabel(labelTrackingObj, "input", "glContainer");
            wallMeasureLabels[name].setValue(len);
            wallMeasureLabels[name].setOnChangeCallback(handleInputOnChange);
            wallMeasureLabels[name].setOnBlurCallback(function (e) {
                handleInputOnChange();
                ignoreNextClick = true;
            });
            wallMeasureLabels[name].setTrackedValue(name);
            if (name.indexOf('E') !== -1 || name.indexOf("S") !== -1) wallMeasureLabels[name].getObj().setY(obb.c.y - obb.e.y);
        };

        /**
         * @description function to update specified wall measure object
         * @param {string} name the name of the measure object
         * @param {number} len the length to update mesh with
         * @param {OBB} obb the oriented bounding box of the currently measured object
         */
        var updateWallMeasureObject = function (name, len, obb) {
            if (len !== 0 && len !== Infinity) {
                if (!wallMeasure[name].parent) WebGLService.add3DObjectToMeasureObj(wallMeasure[name]);
                wallMeasure[name].scale.setX(len);

                if (name.indexOf('E') !== -1 || name.indexOf("S") !== -1) wallMeasureLabels[name].getObj().setY(obb.c.y - obb.e.y);
            }
            else {
                WebGLService.remove3DObjectFromMeasureObj(wallMeasure[name]);
            }
            wallMeasure[name].userData.len = len;
            wallMeasureLabels[name].setValue(len);
            if (name[4] === "P" || name[4] === "N") {
                if (len === Infinity) {
                    wallMeasureLabels[name].remove();
                }
                else {
                    wallMeasureLabels[name].insert();
                }
            }
        };

        /**
         * @description function to measure a distances for a wall object (door/window)
         * @param {Door|Window} obj the object to get measurements for
         * @param {THREE.Object3D} obj3d the 3d-object to get measurements for
         * @param {THREE.Object3D} wall the 3d-object of the current wall
         * @param {array} wallObjects array of other object located on current wall
         */
        var measureWallObject = function (obj, obj3d, wall, wallObjects) {
            var wlens = getDistancesWall(obj3d, wall, wallObjects);
            if (wallMeasure.wallStart === null) {
                initWallMeasureObject("wallStart", "measureWallStart", colors.wallend, wlens.ls, wall.userData.s.clone(), obj3d.userData.obb);
                initWallMeasureObject("wallEnd", "measureWallEnd", colors.wallend, wlens.le, wall.userData.e.clone(), obj3d.userData.obb);
                initWallMeasureObject("wallTop", "measureWallTop", colors.wallY, wlens.lt);
                initWallMeasureObject("wallBottom", "measureWallBottom", colors.wallY, wlens.lb);
                initWallMeasureObject("wallPrev", "measureWallPrev", colors.wallNear, wlens.negx);
                initWallMeasureObject("wallNext", "measureWallNext", colors.wallNear, wlens.posx);
                wallMeasure.wallPrev.userData.measurePos = wlens.negxCorner;
                wallMeasure.wallNext.userData.measurePos = wlens.posxCorner;
                if (wlens.ls > 0) WebGLService.add3DObjectToMeasureObj(wallMeasure.wallStart);
                if (wlens.le > 0) WebGLService.add3DObjectToMeasureObj(wallMeasure.wallEnd);
                if (wlens.lt > 0) WebGLService.add3DObjectToMeasureObj(wallMeasure.wallTop);
                if (wlens.lb > 0) WebGLService.add3DObjectToMeasureObj(wallMeasure.wallBottom);
                if (wlens.negx !== Infinity) {
                    WebGLService.add3DObjectToMeasureObj(wallMeasure.wallPrev);
                }
                else {
                    wallMeasureLabels.wallPrev.remove();
                }
                if (wlens.posx !== Infinity) {
                    WebGLService.add3DObjectToMeasureObj(wallMeasure.wallNext);
                }
                else {
                    wallMeasureLabels.wallNext.remove();
                }
            }
            else {
                updateWallMeasureObject("wallStart", wlens.ls, obj3d.userData.obb);
                updateWallMeasureObject("wallEnd", wlens.le, obj3d.userData.obb);
                updateWallMeasureObject("wallTop", wlens.lt);
                updateWallMeasureObject("wallBottom", wlens.lb);
                updateWallMeasureObject("wallPrev", wlens.negx);
                updateWallMeasureObject("wallNext", wlens.posx);
                wallMeasure.wallPrev.userData.measurePos = wlens.negxCorner;
                wallMeasure.wallNext.userData.measurePos = wlens.posxCorner;
            }
            updateWallMeasure(wall.rotation.y, wall.userData.s, wall.userData.e, obj3d.userData.obb);
        };

        // Room obj aka racks, coolings pillars etc

        /**
         * @description function to get points for axis
         * @param {THREE.Vector3} axis the axis to get points for
         * @param {THREE.Vector3[]} obbPoints array of points in 3d-space
         * @param {OBB} obb the oriented bounding box of the object to get measurements for
         * @returns {Array} returns array of 3d-points for specified axis
         */
        var getPointsForAxis = function (axis, obbPoints, obb) {
            var axisAligned = false;
            var a = obb.u[0].angleTo(new THREE.Vector3(1, 0, 0));
            if (a % Math.PI === 0) axisAligned = true;
            var pointsDists = [];
            var len = new THREE.Vector3(obb.e.x, 0, obb.e.z).length();
            var p = new THREE.Vector3();
            p.add(axis.clone().multiplyScalar(len));
            var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(axis, p);
            for (var i = 0; i < obbPoints.length; i++) {
                var d = plane.distanceToPoint(obbPoints[i]);
                pointsDists.push({d: d, obj: obbPoints[i].clone()});
            }
            pointsDists.sort(function (a, b) {
                return b.d - a.d;
            });
            if (axisAligned) {
                var ret = [];
                for (var i = 0; i < 4; i++) {
                    ret.push(pointsDists[i].obj);
                }
                return ret;
            }
            else {
                var ret = [];
                for (var i = 0; i < 6; i++) {
                    ret.push(pointsDists[i].obj);
                }
                return ret;
            }
        };

        /**
         * @description function get shortest distances to other objects for specified object
         * @param {OBB} obb the oriented bounding box of the object to measure
         * @param {THREE.Object3D[]} objs array of possible objects to check for measurement
         * @param {Asset|Cooling|FloorTile|Rack|Sensor|Ups} obj the object to measure distances for
         * @returns {{negx: {d: null, orgPos: null}, posx: {d: null, orgPos: null}, negz: {d: null, orgPos: null}, posz: {d: null, orgPos: null}}}
         *          returns object describing measurement data
         *          each sub object contains information about the distance (d) and the position this distance was measure from
         */
        var getDistancesRoom = function (obb, objs, obj) {
            var distances = {
                negx: {
                    d: null,
                    orgPos: null
                },
                posx: {
                    d: null,
                    orgPos: null
                },
                negz: {
                    d: null,
                    orgPos: null
                },
                posz: {
                    d: null,
                    orgPos: null
                }
            };
            // if (measureObjObb == null || !MathService.obbsEqual(measureObjObb, obb, true)) {
            measureObjObb = obb;
            var ps = obj instanceof FloorTile ? obb.getTopPlanePoints(true) : obb.getPoints(true);
            negXPoints = getPointsForAxis(negXDir, ps, obb);
            posXPoints = getPointsForAxis(posXDir, ps, obb);
            negZPoints = getPointsForAxis(negZDir, ps, obb);
            posZPoints = getPointsForAxis(posZDir, ps, obb);
            // }
            var i;
            var ray = new THREE.Raycaster();
            for (i = 0; i < negXPoints.length; i++) {
                ray.set(negXPoints[i].clone().add(obb.c), negXDir);
                var tnegx = handleRaycasterResult(measureObj3d, ray.intersectObjects(objs, true));
                if (distances.negx.d === null || tnegx < distances.negx.d) {
                    distances.negx.d = tnegx;
                    distances.negx.orgPos = ray.ray.origin.clone();
                }
            }
            distances.negx.d = NumberService.roundToPrecision(distances.negx.d, 3);
            for (i = 0; i < posXPoints.length; i++) {
                ray.set(posXPoints[i].clone().add(obb.c), posXDir);
                var tposx = handleRaycasterResult(measureObj3d, ray.intersectObjects(objs, true));
                if (distances.posx.d === null || tposx < distances.posx.d) {
                    distances.posx.d = tposx;
                    distances.posx.orgPos = ray.ray.origin.clone();
                }
            }
            distances.posx.d = NumberService.roundToPrecision(distances.posx.d, 3);
            for (i = 0; i < negZPoints.length; i++) {
                ray.set(negZPoints[i].clone().add(obb.c), negZDir);
                var tnegz = handleRaycasterResult(measureObj3d, ray.intersectObjects(objs, true));
                if (distances.negz.d === null || tnegz < distances.negz.d) {
                    distances.negz.d = tnegz;
                    distances.negz.orgPos = ray.ray.origin.clone();
                }
            }
            distances.negz.d = NumberService.roundToPrecision(distances.negz.d, 3);
            for (i = 0; i < posZPoints.length; i++) {
                ray.set(posZPoints[i].clone().add(obb.c), posZDir);
                var tposz = handleRaycasterResult(measureObj3d, ray.intersectObjects(objs, true));
                if (distances.posz.d === null || tposz < distances.posz.d) {
                    distances.posz.d = tposz;
                    distances.posz.orgPos = ray.ray.origin.clone();
                }
            }
            distances.posz.d = NumberService.roundToPrecision(distances.posz.d, 3);
            return distances;
        };

        /**
         * @description function to initialise measurement object
         * @param {string} name the name of the measurement sub-object
         * @param {string} objName the name to set for the object
         * @param {number} color color for object in hexadecimal format
         * @param {number} len the length to set
         * @param {THREE.Vector3} pos the position to set
         * @param {THREE.Vector3} dir the direction to orient the measurement object
         */
        var initObjectMeasureObject = function (name, objName, color, len, pos, dir) {
            objMeasure[name] = buildMeasureMesh(len);
            objMeasure[name].name = objName;
            objMeasure[name].userData.len = len;
            objMeasure[name].material.color.setHex(color);
            var labelPos = pos.clone().add(dir.clone().multiplyScalar(len));
            objMeasureLabels[name] = TrackingDivLabelFactory.buildLabel(labelPos, "input", "glContainer");
            objMeasureLabels[name].setValue(len);
            objMeasureLabels[name].setOnChangeCallback(handleInputOnChange);
            objMeasureLabels[name].setTrackedValue(name);
        };

        /**
         * @description function to update a measurement object specified by name
         * @param {string} name the name of the measurement object to update
         * @param {number} len the length to update measurement object with
         */
        var updateObjectMeasureObject = function (name, len) {
            if (len !== 0) {
                if (!objMeasure[name].parent) WebGLService.add3DObjectToMeasureObj(objMeasure[name]);
                objMeasure[name].scale.setX(len);
                objMeasure[name].userData.len = len;
                objMeasureLabels[name].setValue(len);
            }
            else {
                WebGLService.remove3DObjectFromMeasureObj(objMeasure[name]);
            }
        };

        /**
         * @description function to set measurement objects position
         * @param {object} distInfo object containing distance information (returned by 'getDistancesRoom')
         */
        var setObjectMeasurePos = function (distInfo) {
            objMeasure.negX.position.copy(distInfo.negx.orgPos);
            objMeasure.negX.position.add(negXDir.clone().multiplyScalar(distInfo.negx.d / 2));
            objMeasure.posX.position.copy(distInfo.posx.orgPos);
            objMeasure.posX.position.add(posXDir.clone().multiplyScalar(distInfo.posx.d / 2));
            objMeasure.negZ.position.copy(distInfo.negz.orgPos);
            objMeasure.negZ.position.add(negZDir.clone().multiplyScalar(distInfo.negz.d / 2));
            objMeasure.negZ.rotation.y = Math.PI / 2;
            objMeasure.posZ.position.copy(distInfo.posz.orgPos);
            objMeasure.posZ.position.add(posZDir.clone().multiplyScalar(distInfo.posz.d / 2));
            objMeasure.posZ.rotation.y = Math.PI / 2;
        };

        /**
         * @description function to update input label positions
         * @param {object} distInfo object containing distance information (returned by 'getDistancesRoom')
         */
        var setObjectMeasureLabels = function (distInfo) {
            objMeasureLabels.negX.setValue(distInfo.negx.d);
            var ndx = distInfo.negx.orgPos.clone().add(negXDir.clone().multiplyScalar(distInfo.negx.d));
            if (distInfo.negx.d < 1.5) ndx.add(negXDir.clone().multiplyScalar(1));
            objMeasureLabels.negX.setObj(ndx);
            objMeasureLabels.posX.setValue(distInfo.posx.d);
            var pdx = distInfo.posx.orgPos.clone().add(posXDir.clone().multiplyScalar(distInfo.posx.d));
            if (distInfo.posx.d < 1.5) pdx.add(posXDir.clone().multiplyScalar(1));
            objMeasureLabels.posX.setObj(pdx);
            objMeasureLabels.negZ.setValue(distInfo.negz.d);
            var ndz = distInfo.negz.orgPos.clone().add(negZDir.clone().multiplyScalar(distInfo.negz.d));
            if (distInfo.negz.d < 1.5) ndz.add(negZDir.clone().multiplyScalar(1));
            objMeasureLabels.negZ.setObj(ndz);
            objMeasureLabels.posZ.setValue(distInfo.posz.d);
            var pdz = distInfo.posz.orgPos.clone().add(posZDir.clone().multiplyScalar(distInfo.posz.d));
            if (distInfo.posz.d < 1.5) pdz.add(posZDir.clone().multiplyScalar(1));
            objMeasureLabels.posZ.setObj(pdz);
            TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
        };

        /**
         * @description function to update measurement objects positions and labels
         * @param distInfo
         */
        var updateObjectMeasure = function (distInfo) {
            setObjectMeasurePos(distInfo);
            setObjectMeasureLabels(distInfo);
        };

        /**
         * @description function to measure objects distances within a room
         * @param {Asset|Cooling|FloorTile|Rack|Sensor|Ups|Pillar} obj the object to measure distances for
         * @param {THREE.Object3D} obj3d the 3d-object matching the provided object
         * @param {THREE.Object3D} room the 3d-object representing the room
         * @param {object} params deprecated
         * @param {Room} roomObj the room object containing the object to measure distances for
         */
        var measureRoomObject = function (obj, obj3d, room, params, roomObj) {
            var distances = getDistancesRoom(obj3d.userData.obb, measureAgainstObjects, obj);
            var offset = obj instanceof Pillar && roomObj.hasRaisedFloor ? new THREE.Vector3(0, roomObj.getRaisedFloor().size.y, 0) : new THREE.Vector3(0, 0, 0);
            //commented becasue it produced infinity measurement issue (CGLT-476)
            if (obj instanceof FloorTile) offset.setY(obj.size.y / -2);
            if (objMeasure.negX === null) {
                initObjectMeasureObject("negX", "measureNegX", colors.obj, distances.negx.d, distances.negx.orgPos.add(offset), negXDir);
                initObjectMeasureObject("posX", "measurePosX", colors.obj, distances.posx.d, distances.posx.orgPos.add(offset), posXDir);
                initObjectMeasureObject("negZ", "measureNegZ", colors.obj, distances.negz.d, distances.negz.orgPos.add(offset), negZDir);
                initObjectMeasureObject("posZ", "measurePosZ", colors.obj, distances.posz.d, distances.posz.orgPos.add(offset), posZDir);
                if (distances.negx.d > 0) WebGLService.add3DObjectToMeasureObj(objMeasure.negX);
                if (distances.posx.d > 0) WebGLService.add3DObjectToMeasureObj(objMeasure.posX);
                if (distances.negz.d > 0) WebGLService.add3DObjectToMeasureObj(objMeasure.negZ);
                if (distances.posz.d > 0) WebGLService.add3DObjectToMeasureObj(objMeasure.posZ);
            }
            else {
                updateObjectMeasureObject("negX", distances.negx.d, negXDir);
                updateObjectMeasureObject("posX", distances.posx.d, posXDir);
                updateObjectMeasureObject("negZ", distances.negz.d, negZDir);
                updateObjectMeasureObject("posZ", distances.posz.d, posZDir);
            }
            updateObjectMeasure(distances);
        };

        //walls
        /**
         * @description function to measure distances for objects on walls
         * @param {THREE.Object3D} obj3d the 3d-object of the current object
         * @param {function} measureInputCallback callback function to be called after changes to input in label
         */
        var measureWallObjects = function (obj3d, measureInputCallback) {
            for (var i in obj3d) {
                if (obj3d[i] === null) continue;
                lengthMeasureLabels[i] = TrackingDivLabelFactory.buildLabel(obj3d[i], "input", "glContainer");
                var len = NumberService.roundToPrecision(obj3d[i].userData.e.clone().sub(obj3d[i].userData.s).length(), 3);
                lengthMeasureLabels[i].setValue(len);
                lengthMeasureLabels[i].setOnChangeCallback(measureInputCallback);
                lengthMeasureLabels[i].setTrackedValue(i);
            }
            TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
        };

        /**
         * @description function to measure distances for provided object
         * @param {Asset|Cooling|FloorTile|Rack|Sensor|Ups|Pillar|Window|Door} obj the object to measure distances for
         * @param {THREE.Object3D} obj3d the 3d-object representing the object to measure in 3d-context
         * @param {THREE.Object3D} containObj object containing the object to measure
         * @param {object} params parameters to use for measurement objects
         * @param {Room} room the room containing the object to measure distacnce for
         */
        var measureObject = function (obj, obj3d, containObj, params, room) {
            clearMeasure();
            // if((obj instanceof Asset || obj instanceof Cooling || obj instanceof Rack || obj instanceof Sensor || obj instanceof Ups) && !params.pts2d) params.pts2d = params.getCornerPoints(2);
            measureObj3d = obj3d;
            measureObjContain = containObj;
            measureObjParams = params;
            if (obj instanceof Door || obj instanceof Window) {
                measureState = 1;
                measureObj = obj;
                measureWallObject(obj, obj3d, containObj, params);
            }
            if (obj instanceof Pillar) {
                if (measureObj === null || (measureObj && measureObj.uniqueId != obj.uniqueId)) {
                    measureAgainstObjects = [];
                    containObj.traverse(function (o) {
                        if (o.name.indexOf("wall") !== -1) measureAgainstObjects.push(o);
                        if (o.name.indexOf("pillar") !== -1 && o.uuid != obj3d.uuid) measureAgainstObjects.push(o);
                        if (o.name.indexOf("door") !== -1) measureAgainstObjects.push(o);
                        if (o.name.indexOf("window") !== -1) measureAgainstObjects.push(o);
                    });
                }
                measureState = 0;
                measureObj = obj;
                measureObjRoom = room;
                measureRoomObject(obj, obj3d, containObj, params, room);
            }
            if (obj instanceof Asset || obj instanceof Rack || obj instanceof Cooling || obj instanceof Sensor || obj instanceof Ups || obj instanceof Containment ||obj instanceof FloorTile) {
                measureAgainstObjects = [];
                containObj.traverse(function (o) {
                    if (o.name.indexOf("wall") !== -1) measureAgainstObjects.push(o);
                    if (o.name.indexOf("pillar") !== -1 && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                    if (o.name.indexOf("cooling") !== -1 && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                    if (o.name.indexOf("rack") !== -1 && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                    if (o.name.indexOf("asset") !== -1 && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                    if (o.name.indexOf("ups") !== -1 && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                    if (o.name.indexOf("wind_glass") !== -1) measureAgainstObjects.push(o);
                    if (o.name === "door") measureAgainstObjects.push(o);
                    if (o.name === "containment" && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                    if (o.name === "floortile" && o.uuid !== obj3d.uuid) measureAgainstObjects.push(o);
                });
                measureState = 0;
                measureObj = obj;
                measureObjRoom = room;
                measureRoomObject(obj, obj3d, containObj, params, room);
                WebGLService.updateMarkerPosition(obj3d);
            }
            
            if (obj3d instanceof Array) {//walls
                measureState = 2;
                clearMeasure();
                measureWallObjects(measureObj3d, params);
            }
        };

        /**
         * @description function to remove wall measurement object defined by name from 3d-context
         * @param {string} name the name describing the measurement object to remove
         */
        var clearWallMeasure = function (name) {
            if (wallMeasure[name] !== null) {
                WebGLService.remove3DObjectFromMeasureObj(wallMeasure[name]);
                wallMeasure[name] = null;
                TrackingDivLabelFactory.removeLabel(wallMeasureLabels[name].getId());
                wallMeasureLabels[name] = null;
            }
        };

        /**
         * @description function to remove measurement object defined by name from 3d-context
         * @param {stzing} name the name describing the measurement object to remove
         */
        var clearObjMeasure = function (name) {
            if (objMeasure[name] !== null) {
                WebGLService.remove3DObjectFromMeasureObj(objMeasure[name]);
                objMeasure[name] = null;
                TrackingDivLabelFactory.removeLabel(objMeasureLabels[name].getId());
                objMeasureLabels[name] = null;
            }
        };

        /**
         * @description function to remove the length measurement object defined by name from 3d-context
         * @param name
         */
        var clearLengthMeasure = function (name) {
            if (lengthMeasureLabels[name] !== null) {
                TrackingDivLabelFactory.removeLabel(lengthMeasureLabels[name].getId());
                lengthMeasureLabels[name] = null;
            }
        };

        /**
         * @description function to clear all measurement objects from 3d-context
         */
        var clearMeasure = function () {
            clearWallMeasure("wallStart");
            clearWallMeasure("wallEnd");
            clearWallMeasure("wallTop");
            clearWallMeasure("wallBottom");
            clearWallMeasure("wallNext");
            clearWallMeasure("wallPrev");
            clearObjMeasure("negX");
            clearObjMeasure("posX");
            clearObjMeasure("negZ");
            clearObjMeasure("posZ");
            clearLengthMeasure(0);
            clearLengthMeasure(1);
            clearLengthMeasure(2);
            measureObj = null;
        };

        /**
         * @description function to handle input in labels
         * @param {event} e the event triggered by changes to input fields in labels
         */
        var handleInputOnChange = function (e) {
            if (Tools.isDefinedNotNull(e)) {
                var inputValue = parseFloat(e.currentTarget.value.replace(",", "."));
                if (inputValue < 0) inputValue = 0;
                var diff = inputValue - $(e.currentTarget).attr("ov");
                var tv = $(e.currentTarget).attr("trackedvalue");
                var op = measureObj3d.position.clone();
                switch (tv) {
                    case "negX":
                        measureObj3d.position.add(posXDir.clone().multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "posX":
                        measureObj3d.position.add(negXDir.clone().multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "negZ":
                        measureObj3d.position.add(posZDir.clone().multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "posZ":
                        measureObj3d.position.add(negZDir.clone().multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "wallStart":
                        var dir = measureObjContain.userData.e.clone().sub(measureObjContain.userData.s).normalize();
                        measureObj3d.position.add(dir.multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "wallEnd":
                        var dir = measureObjContain.userData.s.clone().sub(measureObjContain.userData.e).normalize();
                        measureObj3d.position.add(dir.multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "wallPrev":
                        var dir = measureObjContain.userData.e.clone().sub(measureObjContain.userData.s).normalize();
                        measureObj3d.position.add(dir.multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "wallNext":
                        var dir = measureObjContain.userData.s.clone().sub(measureObjContain.userData.e).normalize();
                        measureObj3d.position.add(dir.multiplyScalar(diff));
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "wallTop":
                        measureObj3d.position.y -= diff;
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                    case "wallBottom":
                        measureObj3d.position.y += diff;
                        measureObj3d.userData.obb.c.copy(measureObj3d.position);
                        break;
                }
                if (measureObj instanceof Window || measureObj instanceof Door) {
                    var collide = false;
                    for (var i = 0; i < measureObjParams.length; i++) {
                        if (measureObj3d.uuid == measureObjParams[i].uuid) continue;
                        if (measureObj3d.userData.obb.isIntersectionBox(measureObjParams[i].userData.obb)) {
                            collide = true;
                            break;
                        }
                    }
                    if (collide || measureObj.checkWallCollide(measureObjContain.userData.s, measureObjContain.userData.e, measureObjContain.userData.h, measureObj3d.position.clone(), measureObjContain.userData.obb.c.y - measureObjContain.userData.obb.e.y)[0]) {
                        MessageService.publish("showCollideMessage", {
                            yes_callback: function () {
                                measureObject(measureObj, measureObj3d, measureObjContain, measureObjParams);
                                measureObj.pos.x = measureObj3d.position.x;
                                measureObj.pos.y = measureObj3d.position.y;
                                measureObj.pos.z = measureObj3d.position.z;
                            },
                            no_callback: function () {
                                measureObj3d.position.copy(op);
                                measureObj3d.userData.obb.c.copy(op);
                                measureObject(measureObj, measureObj3d, measureObjContain, measureObjParams);
                            }
                        });
                    }
                    else {
                        measureObject(measureObj, measureObj3d, measureObjContain, measureObjParams);
                        measureObj.pos.x = measureObj3d.position.x;
                        measureObj.pos.y = measureObj3d.position.y;
                        measureObj.pos.z = measureObj3d.position.z;
                    }
                }
                else {
                    var collide = false;
                    measureObjContain.traverse(function (o) {
                        //TODO add more possible obj names
                        if (o instanceof THREE.Mesh && (o.name.indexOf("wall") !== -1 || (o.name == "pillar" && o.uuid != measureObj3d.uuid))) {
                            if (o.userData.obb.isIntersectionBox(measureObj3d.userData.obb)) collide = true;
                        }
                    });
                    if (collide || !MathService.checkPointInPolygon(measureObj3d.userData.obb.c, measureObjRoom.getCornerPoints(2))) {
                        MessageService.publish('showCollideMessage', {
                            yes_callback: function () {
                                measureObject(measureObj, measureObj3d, measureObjContain, measureObjRoom, measureObjRoom);
                                measureObj.pos.x = measureObj3d.position.x;
                                measureObj.pos.y = measureObj3d.position.y;
                                measureObj.pos.z = measureObj3d.position.z;
                            }, no_callback: function () {
                                measureObj3d.position.copy(op);
                                measureObj3d.userData.obb.c.copy(op);
                                measureObject(measureObj, measureObj3d, measureObjContain, measureObjRoom, measureObjRoom);
                            }
                        });
                    }
                    else {
                        measureObject(measureObj, measureObj3d, measureObjContain, measureObjRoom, measureObjRoom);
                        measureObj.pos.x = measureObj3d.position.x;
                        measureObj.pos.y = measureObj3d.position.y;
                        measureObj.pos.z = measureObj3d.position.z;
                    }
                }
            }
        };

        return {
            measureObject: measureObject,
            clearMeasure: clearMeasure,
            getIgnoreNextClick: getIgnoreNextClick
        };
    });

})();
