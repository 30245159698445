(function () {

    'use strict';
    function pueMonitorController($scope, $log, $uibModal, $interval, $timeout, $translate, highchart, DateConstants, PueMonitorService, Tools, Notify) {
        var ctrl = this;
        var settingsModal;
        var PUEIntervall;
        var dateFormat = 'YYYY-MM-DD HH:mm';

        var currentMonth= {
            start: moment().startOf('month').format(dateFormat),
            end: moment().endOf('month').format(dateFormat)
        };

        ctrl.legendConfig = {};

        ctrl.idPrefix = 'pue-monitor';
        ctrl.idSuffix = '';
        ctrl.compareEnabled = false;
        ctrl.selectLocationEnabled = false;
        ctrl.selectedLocation = null;
        ctrl.noData = false;
        ctrl.locations = [];
        ctrl.errors = [];

        ctrl.resizeTimeout = null;

        ctrl.chartConfig = {
            chart: {
                height: 400,
                type: 'line'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                },
                line: {
                    events:{}
                }
            },
            tooltip: {
                shared: true
            },
            colors: ['#058DC7'],
            series: [],
            title: {
                text: 'PUE'
            },
            yAxis: [
                {
                    opposite: true,
                    title: {
                        text: 'KW'
                    }
                },
                {
                    min: 1,
                    max: 3,
                    title: {
                        text: 'PUE'
                    }
                }
            ],
            xAxis: [{
                id: '0',
                type: "datetime",
                visible: true,
                title: {
                    text: ''
                },
                endOnTick: false,
                startOnTick: false,
                dateTimeLabelFormats: {
                    day: "%e. %b",
                    month: "%b '%y",
                    year: "%Y"
                }
            },
            {
                id: '1',
                type: "datetime",
                visible: false,
                title: {
                    text: ''
                },
                endOnTick: false,
                startOnTick: false,
                dateTimeLabelFormats: {
                    day: "%e. %b",
                    month: "%b '%y",
                    year: "%Y"
                }
            }]
        };

        ctrl.dateRange = {
                options: {
                    singleDatePicker: true,
                    timePicker: true,
                    timePicker24Hour: true,
                    autoUpdateInput: true,
                    autoApply: true,
                    applyClass: 'btn-primary',
                    format: dateFormat,
                    locale: {
                        format: dateFormat,
                        daysOfWeek: DateConstants.getDaysOfWeek(),
                        monthNames: DateConstants.getMonthNames()
                    },
                    showDropdowns: true,
                    eventHandlers : {
                        'apply.daterangepicker' : function() {
                            ctrl.dateRangeValidation();
                        }
                    }
                }
        };

        ctrl.dateRanges = [
            {start: currentMonth.start, end: currentMonth.end},
            {start: '', end: ''}
        ];

        ctrl.$onInit = function () {
            PUEIntervall = $interval(ctrl.loadPueData, 30000);
            if(ctrl.selectLocationEnabled) {
                ctrl.loadLocations();
            }
            ctrl.loadPueData();
        };

        ctrl.$onChanges = function (changes) {
            if (changes.showSettings && changes.showSettings.currentValue) {
                ctrl.openSettings();
            }
            if (changes.locationId && changes.locationId.currentValue) {
                ctrl.locationId = changes.locationId.currentValue;
                filterSelectedLocation();
            }

            if (changes.ranges && changes.ranges.currentValue) {
                ctrl.dateRanges = changes.ranges.currentValue;
                configureXAxis();
            }

            if (changes.compare && changes.compare.currentValue) {
                ctrl.compareEnabled = changes.compare.currentValue;
            }

            if (changes.legendConfig && changes.legendConfig.currentValue) {
                ctrl.legendConfig = changes.legendConfig.currentValue;
            }

            if (changes.idSuffix && changes.idSuffix.currentValue) {
                ctrl.idSuffix = '_' + changes.idSuffix.currentValue;
            }

            if (changes.selectLocationEnabled  !== undefined && changes.selectLocationEnabled.currentValue !== undefined) {
                ctrl.selectLocationEnabled = changes.selectLocationEnabled.currentValue;
            }
        };

        ctrl.openSettings = function() {
            settingsModal = $uibModal.open({
                templateUrl: 'scripts/components/energy/pue/pue-monitor.modal.html',
                animation: false,
                backdrop: 'static',
                windowClass: 'animated fadeInDown',
                scope: $scope
            });

            settingsModal.closed.then(function(){
                ctrl.closeSettings();
            });
        };

        ctrl.loadPueData = function(){
            ctrl.noData = false;
            if(ctrl.locationId){
                configureXAxis();
               /* var chart = $('#' + ctrl.idPrefix + ctrl.idSuffix).highcharts();
                if(chart){
                    var hchart = ctrl.chartConfig.getHighcharts();
                    if(hchart) {
                        hchart.options.xAxis = ctrl.chartConfig.xAxis;
                    }
                    $('#' + ctrl.idPrefix + ctrl.idSuffix).highcharts().reflow();
                }*/
                var ranges = getDateRanges();
                PueMonitorService.loadData(ctrl.locationId, ranges).then(function (response) {
                    if(response && response.data){
                        ctrl.handlePueData(response.data);
                    }
                });
            }
        };

        ctrl.loadLocations = function() {
            PueMonitorService.loadLocations().then(function (response) {
                var promises = [];
                ctrl.locations = response.data;
                filterSelectedLocation();
            });
        }

        ctrl.saveSettings = function (){

            if(!validate()){
                Notify.error("global.notification.error.title", 'ebalance.pueMonitor.invalidSettings', 4000);
                return;
            }

            updateSettings();

            if(!ctrl.selectLocationEnabled) {
                Notify.success("global.notification.success.title", "ebalance.pueMonitor.changesApplied", 4000);
            }
            ctrl.loadPueData();
            ctrl.closeSettings();
        };


        ctrl.resetSettings = function () {

            if(!ctrl.selectLocationEnabled) {
                ctrl.dateRanges = [
                    {start: currentMonth.start, end: currentMonth.end},
                    {start: '', end: ''}
                ];
            };

            ctrl.onCloseSettings({$value: false});

            if (settingsModal){
                settingsModal.close();
            };
        }

        ctrl.closeSettings = function () {
            ctrl.onCloseSettings({$value: false});

            if (settingsModal){
                settingsModal.close();
            };
        }

        ctrl.handlePueData = function (data){
            //  ctrl.chart.config.xAxis = [];
            var series = [];
            for(var i in data.charts) {
                var chart = data.charts[i];

                if(!chart.length) {
                    ctrl.noData = true;
                    Notify.error("global.notification.error.title", 'ebalance.pueMonitor.noPUEForTimeRange', 4000);
                } else {
                    var chartSeries = createChartSeries(chart, i);
                    series = series.concat(chartSeries);
                }
            }

            ctrl.chartConfig.series = series;

            addLegendClickEventListener();
        }


        var addLegendClickEventListener = function(){

            var hchart = ctrl.chartConfig.getHighcharts();
            if(hchart){
                hchart.options.plotOptions.line.events.legendItemClick = function(event) {
                    $log.info(event);
                    if(event.target && event.target.userOptions){
                        var id = event.target.userOptions.id;
                        var visible = !ctrl.legendConfig[id];
                        ctrl.legendConfig[id] = visible;
                        updateSettings();
                    }
                };
            }

        }


        var updateSettings = function(){
            if(ctrl.selectedLocation) {
                ctrl.locationId = ctrl.selectedLocation.id;
            }

            ctrl.onSettingsChanged( {
                $settings:  {
                    locationId: ctrl.locationId,
                    compare: ctrl.compareEnabled,
                    ranges: JSON.stringify(ctrl.dateRanges),
                    legendConfig: JSON.stringify(ctrl.legendConfig)
                }
            });
        };

        var configureXAxis = function (){
            var ranges = getDateRanges();
            for(var i in ranges) {
                ctrl.chartConfig.xAxis[i].min = parseInt(moment(ranges[i].start).format('x'));
                ctrl.chartConfig.xAxis[i].max = parseInt(moment(ranges[i].end).format('x'));
                ctrl.chartConfig.xAxis[i].title.text = builtXAxisTitles(i);
            }

            ctrl.chartConfig.xAxis[1].visible = ranges.length == 2;
//            return xAxis;
        };

        var builtXAxisTitles = function(chartIndex) {
            return ("PUE - Series "+ chartIndex + ": " + moment(ctrl.dateRanges[chartIndex].start).format('YYYY-MM-DD') + " - " + moment(ctrl.dateRanges[chartIndex].end).format('YYYY-MM-DD') + "<br>")
        }

        var createChartSeries = function(chart, chartIndex){

            var series = [];
            var pueValueData = [];
            var itLoadData = [];
            var facilityLoadData = [];
            var colorScheme =
                [{
                    pue: '#00adba',
                    it: '#55ffc7',
                    facility: '#45e3ff'
                },
                    {
                        pue: '#9200ba',
                        it: '#ff55e3',
                        facility: '#d745ff'
                    }
                ];
            for(var i in chart) {
                var current = chart[i];
                var date = new Date(current.createdAt.toString()).getTime();
                pueValueData.push({
                    x: date,
                    y: parseFloat((current.pueValue / 1000).toFixed(2))
                });
                itLoadData.push({
                    x: date,
                    y: parseFloat((current.itLoad / 1000).toFixed(2))
                });
                facilityLoadData.push({
                    x: date,
                    y: parseFloat((current.facilityLoad / 1000).toFixed(2))
                });
            }

            series.push(builtChartSeriesElem(chartIndex, pueValueData, "", colorScheme, "pue", "pue_", "PUE", true, 1));
            series.push(builtChartSeriesElem(chartIndex, itLoadData, " KW", colorScheme, "it", "it_load_", "IT Load", false, 0));
            series.push(builtChartSeriesElem(chartIndex, facilityLoadData," KW", colorScheme, "facility", "facility_load_", "Facility Load", false, 0));

            return series;
        }
        var builtChartSeriesElem = function(chartIndex, data, unitSuffix, colorScheme, colorKey, idPrefix, namePrefix, visibility, yAxis) {
            var dateFormat = 'YYYY-MM-DD';
            var seriesElem = "";

            var visible =  ctrl.legendConfig && ctrl.legendConfig[idPrefix + chartIndex] != undefined ? ctrl.legendConfig[idPrefix + chartIndex] : visibility
            seriesElem = {
                id: idPrefix + chartIndex,
                name: namePrefix + " (" + moment(data[0].x).format(dateFormat) + " - " + moment(data[data.length -1].x).format(dateFormat) + ")",
                color: colorScheme[chartIndex][colorKey],
                data: data,
                visible:visible,
                tooltip: {
                    split: false,
                    shared: true,
                    headerFormat: "{point.x:%d/%m/%Y, %H:%M:%S} <br/>",
                    valueSuffix: unitSuffix
                },
                xAxis: chartIndex,
                yAxis: yAxis,
                line: {
                    xAxis: chartIndex,
                }
            }

            if(ctrl.legendConfig[idPrefix + chartIndex] == undefined){
                ctrl.legendConfig[idPrefix + chartIndex] = visible;
            }

            return seriesElem;
        }


        ctrl.dateRangeValidation = function() {
            var hasValidRanges= true;
            var ranges = ctrl.dateRanges;


            for(var i in ranges) {
                ranges[i].error = {};

                if(!ctrl.compareEnabled && i > 0) {
                    break;
                }

                var range = ctrl.dateRanges[i];

                if (range.start === '' || range.end === '') {
                    range.error = {
                        incomplete: true
                    };
                    hasValidRanges = false;
                }
                if(range.start && range.end) {
                    if (moment(range.end).isBefore(range.start)) {
                        range.error = {
                            before: true
                        };
                        hasValidRanges = false;
                    }
                    if (moment(range.end).diff(range.start, 'days') > 365) {
                        range.error = {
                            limit: true
                        };
                        hasValidRanges = false;
                    }
                }
            }
            return hasValidRanges;
        };

        var createCompleteDateFormat = function(date){
            var completeDate = date;

            var shortRegEx = /[0-9]{4}-[0-1][0-9]-[0-9]{2} [0-9]{2}:[0-9]{2}/g;
            if(date.match(shortRegEx)){
                completeDate = date.replace(' ','T');
                completeDate += ':00.000Z';
            }

            return completeDate;
        };

        var isValidStringDateFormat = function(date){
            //-- Complete precision:
            var completeRegEx = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/
            return date.match(completeRegEx);
        };

        var getDateRanges = function(){
            var ranges = [];
            for(var index in ctrl.dateRanges){
                var start = ctrl.dateRanges[index].start;
                var end = ctrl.dateRanges[index].end;

                if(start instanceof moment){
                    start = start.format(dateFormat);
                }
                if(end instanceof moment){
                    end = end.format(dateFormat);
                }

                var completeStart = createCompleteDateFormat(start);
                var completeEnd = createCompleteDateFormat(end);

                if(isValidStringDateFormat(completeStart) && isValidStringDateFormat(completeEnd)){
                    ranges.push({start: completeStart, end: completeEnd});
                }

                if(!ctrl.compareEnabled){
                    break;
                }
            }

            return ranges;
        };

        var filterSelectedLocation = function (){
            if(!ctrl.selectedLocation && ctrl.locations && ctrl.locations.length && ctrl.locationId){
                for(var i in ctrl.locations){
                    var location = ctrl.locations[i];
                    if(location.id === ctrl.locationId){
                        ctrl.selectedLocation = location;
                        ctrl.chartConfig.title.text =  'PUE of ' + ctrl.selectedLocation.name;
                        break;
                    }
                }
            }
        };


        var validate = function(){
            ctrl.errors = [];
            var isValid = true;

            var requiredLocationMissing = ctrl.selectLocationEnabled && !ctrl.selectedLocation;
            var requiredRangeMissing = ctrl.dateRanges.length === 0 || (!ctrl.dateRanges[0].start || !ctrl.dateRanges[0].end);
            var compareEnabledRangeMissing = ctrl.compareEnabled && (ctrl.dateRanges[1].start === "" || ctrl.dateRanges[1].end === "");

            if(requiredLocationMissing){
                ctrl.errors.push('location_required');
                isValid = false;
            }

            if(requiredRangeMissing || compareEnabledRangeMissing){
                ctrl.errors.push('range_required');
                isValid = false;
            }

            if(!ctrl.dateRangeValidation()){
                isValid = false;
            }

            return isValid;
        };


        var handleWindowResize = function (e) {
            if(document.getElementById( ctrl.idPrefix + ctrl.idSuffix)){
                if(ctrl.resizeTimeout){
                    $timeout.cancel(ctrl.resizeTimeout);
                }
                ctrl.resizeTimeout = $timeout(function () {
                    $('#' + ctrl.idPrefix + ctrl.idSuffix).highcharts().reflow();
                }, 200);
            }
        };

        $scope.$on("widgetOnResizeStop", function ($ev, param) {
            handleWindowResize();
        });

        $scope.$on('$destroy', function () {
            ctrl.$onDestroy();
        });

        ctrl.$onDestroy = function () {
            $interval.cancel(PUEIntervall);
        };


    }

    angular.module('emsv2App')
        .controller('PueMonitorController', pueMonitorController);
})();
