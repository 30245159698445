(function () {
    'use strict';
    /**
     * @ngdoc service
     * @name WebGLService
     * @description Service to handle basic webgl functionality (mouse handler, camera, lighting)
     */

    angular.module('emsv2App').service('WebGLService', function ($compile, $log, MathService, Object3DFactory
        , MessageService, Tools) {

        var scene, renderer, cam2d, cam3d, activeCamera, domElement, animationID, controls = null;
        var options;
        var content = new THREE.Object3D();
        content.name = 'rootObj';
        var controlObject3D = new THREE.Object3D();
        controlObject3D.name = 'controlObj';
        var dummyObject3D = new THREE.Object3D();
        dummyObject3D.name = "dummyObj";
        var lineObject3D = new THREE.Object3D();
        lineObject3D.name = "lineObj";
        var hoverObject3D = new THREE.Object3D();
        hoverObject3D.name = "hoverObj";
        var measureObject3D = new THREE.Object3D();
        measureObject3D.name = "measureObj";
        var markArrowObject = new THREE.Object3D();
        markArrowObject.name = "markObj";

        var ray = new THREE.Raycaster();
        var markArrow = null;

        var initZoom = null;
        var maxZoom = 64;
        var minZoom = 0.1;


        var cameraState = {
            type: 0,
            perspectivePos: null,
            orthoPos: null,
            perspectiveTarget: null,
            orthoTarget: null,
            perspectiveZoom: 1,
            orthoZoom: 1,
            zoomSpeed: 1
        };

        var objectState = {
            bbox: new THREE.Box3()
        };

        var getScrollBarWidth = function () {
            var $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body'),
                widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).outerWidth();
            $outer.remove();
            return 100 - widthWithScroll;
        };

        /**
         * @description function to switch antialias on/off
         * @param val the value to determine if antialias should be used or not
         */
        var setAntialias = function (val) {
            var newRenderer = new THREE.WebGLRenderer({alpha: true, antialias: val == "1" ? true : false});
            newRenderer.setPixelRatio(renderer.getPixelRatio());
            var s = renderer.getSize();
            newRenderer.setSize(s.width, s.height);
            newRenderer.setClearColor(0x000000, 0);
            var events = $._data($(renderer.domElement).get(0), 'events');
            $.each(events, function (eventType, eventArray) {
                $.each(eventArray, function (index, event) {
                    var eb = event.namespace.length > 0 ? event.type + "." + event.namespace : event.type;
                    $(newRenderer.domElement).bind(eb, event.data, event.handler);
                });
            });
            $(renderer.domElement).remove();
            destroy();
            renderer = newRenderer;
            $('#glContainer').append(renderer.domElement);
            addControls();
            animate();
        };

        /**
         * @description function to set the zoom speed
         * @param val the value determining which zoom speed should be used
         */
        var setZoomSpeed = function (val) {
            var zoomVal = 1.0;
            cameraState.zoomSpeed = val;
            switch (val) {
                case "0":
                    zoomVal = 0.5;
                    break;
                case "1":
                    zoomVal = 1.0;
                    break;
                case "2":
                    zoomVal = 2.0;
                    break;
            }
            controls.zoomSpeed = zoomVal;
        };

        /**
         * @description function to compute the usable height for the container
         * @param {boolean} calcOnlyContainer if set to true only the container height will be used, otherwise the
         *                                    height will be based on the height of the browser window
         * @returns {number} returns the available height for the web gl dom element in pixel
         */
        var availHeight = function (calcOnlyContainer) {
            if (!calcOnlyContainer) {
                var fullheight = $(window).height();
                var offsetPos = $('#room-panel').position();
                var buttonHeight = $('#roomEditorGenControls').height() + 100;

                return fullheight - offsetPos.top - buttonHeight;
            }
            else {
                return domElement.height();
            }
        };

        /**
         * @description function to initialise the web gl context
         * @param {string} idContainer the id of the container to add the webgl context to
         * @param {object} opts options object
         */
        var init = function (idContainer, opts) {
            options = opts;
            domElement = $('#' + idContainer);
            var ah = availHeight(options.pureService);
            // var domOuterElement = $('#room-panel');
            scene = new THREE.Scene();
            var w = domElement.outerWidth();
            var h = ah !== 0 ? ah : w / 16 * 9;
            renderer = new THREE.WebGLRenderer({antialias: opts.antialias, alpha: true});
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(w, h);
            renderer.setClearColor(0x000000, 0);

            cam2d = new THREE.OrthographicCamera(w / -2, w / 2, h / 2, h / -2, -50, 100);
            cam3d = new THREE.PerspectiveCamera(75, w / h, 0.1, 10000);

            cam2d.position.set(0, 10, 0);
            cam3d.position.set(0, 0, 10);

            cam2d.lookAt(scene.position);
            cam3d.lookAt(scene.position);

            cameraState.perspectivePos = cam3d.position.clone();
            cameraState.orthoPos = cam2d.position.clone();

            scene.add(cam2d);
            scene.add(cam3d);

            activeCamera = cam3d;
            addControls();

            domElement.append(renderer.domElement);
            $(window).resize(function () {
                handleSize();
            });
            configureLights();

            //TODO remove debug obj
            // scene.add(new THREE.Mesh(new THREE.IcosahedronGeometry(2,3), new THREE.MeshPhongMaterial({color:0xff0000})));

            scene.add(content);
            scene.add(dummyObject3D);
            scene.add(controlObject3D);
            scene.add(lineObject3D);
            scene.add(hoverObject3D);
            scene.add(measureObject3D);
            scene.add(markArrowObject);

            if (options.hasOwnProperty('addPickplane') && options.addPickplane) {
                var pickMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(1000, 1000, 1, 1), new THREE.MeshBasicMaterial({
                    color: 0xff0000,
                    side: THREE.DoubleSide
                }));
                pickMesh.rotation.x = Math.PI / -2;
                pickMesh.visible = false;
                controlObject3D.add(pickMesh);
            }

            // markArrow = Object3DFactory.buildMarkArrow();

            animate();
            // handleSize();
        };

        /**
         * @description function to destory the web gl context and reset the service
         */
        var destroy = function () {
            cancelAnimationFrame(animationID);
            if (renderer === null || renderer === undefined) return;
            $(renderer.domElement).remove();
            cleanObject(scene);
            cleanObject(controlObject3D);
            cleanAll();
            // $(window).off("resize");
            cameraState.type = 0;
            scene.remove(cam2d);
            scene.remove(cam3d);
            cam2d = null;
            cam3d = null;
            scene = null;
            renderer = null;
            domElement = null;
        };

        /**
         * @description function to draw only a basic object
         * @param {THREE.Object3D} object the 3d-object to draw
         */
        var draw3DObject = function (object) {
            cleanObject(content);
            content.add(object);
        };

        /**
         * @description function to add orbit controls to the context and remove already present controls
         */
        var addControls = function () {
            if (controls !== null) {
                controls.dispose();
            }
            controls = new THREE.OrbitControls(activeCamera, renderer.domElement);
            controls.enableKeys = false;
            controls.addEventListener('change', callControlListener);
            setZoomSpeed(cameraState.zoomSpeed);
        };

        /**
         * @description function to disable the currently used controls
         */
        var disableControls = function () {
            controls.enabled = false;
        };

        /**
         * @description function to enable the currently used controls
         */
        var enableControls = function () {
            controls.enabled = true;
        };

        /**
         * @description function to disable rotation for currently used controls
         */
        var disableControlsRotate = function () {
            controls.enableRotate = false;
        };

        /**
         * @description function to enable rotation for currently used controls
         */
        var enableControlsRotate = function () {
            controls.enableRotate = true;
        };

        /**
         * @description function to start render loop
         */
        var animate = function () {
            animationID = requestAnimationFrame(animate);
            // setRenderOrder();
            draw();
        };

        /**
         * @description function to trigger rendering
         */
        var draw = function () {
            renderer.render(scene, activeCamera);
        };

        /**
         * @description function to handle resize events
         * @param {boolean?} onlyContainer if set to true only the container height will be used, otherwise the height of the full browser window will be used as basis for height computation
         */
        var handleSize = function (onlyContainer) {
            if (domElement === undefined || domElement === null) return;
            var width = domElement.width();
            // var height = domElement.height();
            // if(options.heightPercent == "adjust")
            //     height = width/16*6.5;
            // if(typeof options.heightPercent == 'number')
            //     height *= options.heightPercent/100;
            var height = availHeight(onlyContainer);
            cam3d.aspect = width / height;
            cam2d.left = width / -2;
            cam2d.right = width / 2;
            cam2d.top = height / 2;
            cam2d.bottom = height / -2;
            cam2d.updateProjectionMatrix();
            cam3d.updateProjectionMatrix();
            renderer.setSize(width, height);
        };

        /**
         * @description function to setup the lights for 3d-context
         */
        var configureLights = function () {
            var ld0 = new THREE.DirectionalLight(0xffffff, 0.6);
            ld0.position.set(1, 1, 1);
            var ld1 = new THREE.DirectionalLight(0xffffff, 0.6);
            ld1.position.set(-1, -1, -1);
            var la = new THREE.AmbientLight(0xffffff, 0.3);
            scene.add(ld0);
            scene.add(ld1);
            scene.add(la);
        };

        /**
         * @description function to remove content from given 3d-object
         * @param {THREE.Object3D} obj the 3d-object to clean
         * @param {string?} name the name of the objects to remove, if no name is provided the whole object will be cleaned
         */
        var cleanObject = function (obj, name) {
            if (!name) {
                for (var i = obj.children.length - 1; i >= 0; i--) {
                    obj.remove(obj.children[i]);
                }
            }
            else {
                for (var i = obj.children.length - 1; i >= 0; i--) {
                    if (obj.children[i].name == name) obj.remove(obj.children[i]);
                }
            }

        };

        /**
         * @description function to update the AABB of the whole displayed content
         * @param {THREE.Box3=} customBBox custom AABB for the content, if present the content bbox will be set from this,
         *                                 otherwise the content AABB will be computed from the present content
         */
        var updateContentBBox = function (customBBox) {
            if (customBBox) {
                objectState.bbox = customBBox.clone();
                return;
            }
            var bbox = new THREE.Box3();
            bbox.setFromObject(content);
            objectState.bbox.min = bbox.min.clone();
            objectState.bbox.max = bbox.max.clone();
        };

        /**
         * @description function to get the content AABB
         * @returns {THREE.Box3} return the content AABB
         */
        var getContentBoundingBox = function () {
            return objectState.bbox;
        };
        //region object funcs
        /**
         * @description function to add 3d-object to content object
         * @param {THREE.Object3D} object the object to add to the content object
         * @param {THREE.Box3?} customBBox optional AABB provided by caller
         */
        var add3DObjectToContent = function (object, customBBox) {
            content.add(object);
            updateContentBBox(customBBox);
        };

        /**
         * @description function to remove the provided object from content object
         * @param {THREE.Object3D} object the object to remove from content object
         */
        var remove3DObjectFromContent = function (object) {
            content.remove(object);
            updateContentBBox();
        };

        /**
         * @description function to clean the content object, if a name is provided only objects with provided name
         *              will be removed otherwise content object will be fully cleaned
         * @param {string?} name the name of the objects to clean, optional
         */
        var cleanContent = function (name) {
            cleanObject(content, name);
        };

        /**
         * @description function to add the given object to the dummy 3d-object
         * @param {THREE.Object3D} object the object to add to the dummy content object
         */
        var add3DObjectToDummyObj = function (object) {
            dummyObject3D.add(object);
        };

        /**
         * @description function to remove given object from dummy content object
         * @param object
         */
        var remove3DObjectFromDummyObj = function (object) {
            dummyObject3D.remove(object);
        };

        /**
         * @description function to clean the dummy content object, if a name is provided only objects with matching
         *              name will be removed, otherwise full object will be cleaned
         * @param {string?} name the name of the objects to clean, optional
         */
        var cleanDummy = function (name) {
            cleanObject(dummyObject3D, name);
        };

        /**
         * @description function to add the provided object to the line object
         * @param {THREE.Object3D} object the object to add to the line object
         */
        var add3DObjectToLineObj = function (object) {
            lineObject3D.add(object);
        };

        /**
         * @description function to remove given object from line object
         * @param {THREE.Object3D} object the object to remove from line object
         */
        var remove3DObjectFromLineObj = function (object) {
            lineObject3D.remove(object);
        };

        /**
         * @description function to clean the line object, if name is provided only objects with given name will be removed, otherwise the whole line object will be cleaned
         * @param {string?} name the name of the objects to clean, optional
         */
        var cleanLineObj = function (name) {
            cleanObject(lineObject3D, name);
        };

        /**
         * @description function to add the provided object to the hover content object
         * @param {THREE.Object3D} object the object to add
         */
        var add3DObjectToHoverObj = function (object) {
            hoverObject3D.add(object);
        };

        /**
         * @description function to remove object from hover content object
         * @param {THREE.Object3D} object the object to remove
         */
        var remove3DObjectFromHoverObj = function (object) {
            hoverObject3D.remove(object);
        };

        /**
         * @description function to clean the line object, if name is provided only objects with given name will be removed, otherwise the whole line object will be cleaned
         * @param {string?} name the name of the objects to clean
         */
        var cleanHoverObj = function (name) {
            cleanObject(hoverObject3D, name);
        };

        /**
         * @description function to add the provided object to the measure content object
         * @param {THREE.Object3D} object the object to add
         */
        var add3DObjectToMeasureObj = function (object) {
            measureObject3D.add(object);
        };

        /**
         * @description function to remove given object from measure content object
         * @param {THREE.Object3D} object the object to remove
         */
        var remove3DObjectFromMeasureObj = function (object) {
            measureObject3D.remove(object);
        };

        /**
         * @description function to clean measure content object
         */
        var cleanMeasureObj = function () {
            cleanObject(measureObject3D);
        };

        /**
         * @description function to add object to marking content object
         * @param {THREE.Object3D} object the object to add to the marking content object
         */
        var add3DObjectToMarkObj = function (object) {
            markArrowObject.add(object);
        };

        /**
         * @description function to remove given object from marking content object
         * @param {THREE.Object3D} object the object to remove
         * @returns {boolean} returns true if object was removed, otherwise false
         */
        var remove3DObjectFromMarkObj = function (object) {
            markArrowObject.remove(object);
            if (object.parent) {
                return false;
            }
            else {
                return true;
            }
        };

        /**
         * @description function to clean marking content object
         */
        var cleanMarkObj = function () {
            cleanObject(markArrowObject);
        };

        /**
         * @description function to get the dummy content object
         * @returns {THREE.Object3D} returns dummy content object
         */
        var getDummyObject = function () {
            return dummyObject3D;
        };

        /**
         * @description function to get the hover content object
         * @returns {THREE.Object3D} returns hover content object
         */
        var getHoverObject = function () {
            return hoverObject3D;
        };

        /**
         * @description function to get the scene object
         * @returns {THREE.Scene} returns the current scene object
         */
        var getSceneObject = function () {
            return scene;
        };

        /**
         * @description function to add the provided object to the scene object
         * @param {THREE.Object3D} obj the object to add
         */
        var add3DObjectToScene = function (obj) {
            scene.add(obj);
        };

        /**
         * @description function to remove given object from scene
         * @param {THREE.Object3D} obj the object to remove
         */
        var remove3DObjectFromScene = function (obj) {
            scene.remove(obj);
        };

        /**
         * @description function to remove object with provided name from provided parent object
         * @param {string} objectname the name of the object to remove
         * @param {THREE.Object3D} parent the parent object to remove object from
         * @returns {boolean} returns true if object was removed, otherwise false
         */
        var removeObjectByNameFromObject = function (objectname, parent) {
            if (parent === undefined) return false;
            var obj = findObjectByName(objectname, parent);
            if (obj !== null) {
                parent.remove(obj);
                return true;
            }
            else {
                return false;
            }
        };

        /**
         * @description function to find object with given name in provided object childs
         * @param {string} objectname the name of the object to remove
         * @param {THREE.Object3D} object the object to search for objects with given name
         * @returns {*} returns the found object, otherwise null
         */
        var findObjectByName = function (objectname, object) {
            var obj = null;
            if (object !== undefined && object instanceof THREE.Object3D) {
                object.traverse(function (o) {
                    if (o.name == objectname) {
                        obj = o;
                        return;
                    }
                });
            }
            else {
                scene.traverse(function (o) {
                    if (o.name == objectname) {
                        obj = o;
                        return;
                    }
                });
            }
            return obj;
        };

        /**
         * @description function to find object with given unique id in provided object
         * @param {number} uid the unique id to search for
         * @param {THREE.Object3D} object the object to search in
         * @returns {*} returns found object, otherwise null
         */
        var findObjectByUniqueId = function (uid, object) {
            var obj = null;
            if (object === undefined) object = scene;
            if (object !== undefined && object instanceof THREE.Object3D) {
                object.traverse(function (o) {
                    if (o.userData && o.userData.hasOwnProperty("uid") && o.userData.uid == uid) {
                        obj = o;
                        return;
                    }
                });
            }
            return obj;
        };

        /**
         * @description function to find object with given typename and id in provided object
         * @param {string} type the type name
         * @param {number} id the id to set
         * @param {THREE.Object3D} object the object to search in
         * @returns {*} returns object if found otherwise null
         */
        var findObjectByTypeAndID = function (type, id, object) {
            var obj = null;
            if (object === undefined) object = scene;
            if (object !== undefined && object instanceof THREE.Object3D) {
                object.traverse(function (o) {
                    if (o.userData && o.userData.hasOwnProperty("id") && o.userData.id == id && o.name == type) {
                        obj = o;
                        return;
                    }
                });
            }
            return obj;
        };

        /**
         * @description function to clean all content objects
         */
        var cleanAll = function () {
            cleanContent();
            cleanDummy();
            cleanHoverObj();
            cleanLineObj();
            cleanMeasureObj();
            cleanMarkObj();
        };

        /**
         * @description function to clean dummy, line and hover content object
         */
        var cleanDummyLineHover = function () {
            cleanDummy();
            cleanLineObj();
            cleanHoverObj();
        };
        //endregion
        //camera switch etc
        /**
         * @description function to return the currently active camera
         * @returns {*} returns the currently active camera
         */
        var getActiveCamera = function () {
            return activeCamera;
        };

        /**
         * @description function to get the controls object (OrbitControls)
         * @returns {*} returns the current control object
         */
        var getControls = function () {
            return controls;
        };

        /**
         * @description function to check if the current camera is 3D
         * @returns {boolean} returns true if the active camera is using perspective projection
         */
        var isCam3D = function () {
            return activeCamera instanceof THREE.PerspectiveCamera;
        };

        /**
         * @description function to zoom orthographic camera to show content
         * @param {number} x the width of the object to zoom on
         * @param {number} y the height of the object to zoom on
         * @param {THREE.Vector3} target the position to look at
         * @param {boolean} topView true if orthographic projection looks down the y-axis, otherwise false
         * @param {number?} zFac custom zoom factor, optional
         */
        var zoomOrtho = function (x, y, target, topView, zFac) {
            var min = Math.min(activeCamera.top, activeCamera.right);
            if (topView) {
                min *= zFac ? zFac : 1.5;
            }
            else {
                min *= 2;
            }
            var max = Math.max(x, y);
            var zoom = min / max;
            activeCamera.zoom = zoom;
            if (topView) {
                activeCamera.position.set(target.x, activeCamera.position.y, target.z);
            }
            else {
                activeCamera.position.set(target.x, target.y, activeCamera.position.z);
            }
            activeCamera.lookAt(target);
            activeCamera.updateProjectionMatrix();
            controls.target.copy(target);
            controls.update();
        };

        /**
         * @description function to switch to 2d-view
         * @returns {number} returns the camera type
         */
        var switch2D = function () {
            if (cameraState.type == 0) {
                var roomView = true;
                if (content.children[0].name == "innerRack") {
                    var view2dAdd = Object3DFactory.buildRack2DView(content.children[0]);
                    add3DObjectToContent(view2dAdd[0]);
                    roomView = false;
                    cameraState.orthoPos = new THREE.Vector3(view2dAdd[1].x, view2dAdd[1].y, 1);
                    cameraState.orthoTarget = new THREE.Vector3(view2dAdd[1].x, view2dAdd[1].y, 0);
                }
                cameraState.perspectivePos = activeCamera.position.clone();
                cameraState.perspectiveTarget = controls.target.clone();
                cameraState.perspectiveZoom = activeCamera.zoom;
                activeCamera = cam2d;
                if (cameraState.orthoPos !== null) {
                    activeCamera.position.copy(cameraState.orthoPos);
                }
                if (cameraState.orthoTarget !== null) {
                    activeCamera.lookAt(cameraState.orthoTarget);
                }
                activeCamera.zoom = cameraState.orthoZoom;
                cameraState.type = 1;
                addControls();
                if (cameraState.type == 1) {
                    if (roomView) {
                        zoomOrtho(objectState.bbox.max.x - objectState.bbox.min.x, objectState.bbox.max.z - objectState.bbox.min.z, cameraState.perspectiveTarget, true);
                        if (cameraState.orthoRotation) controls.rotateLeft(-cameraState.orthoRotation);
                    }
                    else {
                        zoomOrtho(objectState.bbox.max.x - objectState.bbox.min.x, objectState.bbox.max.y - objectState.bbox.min.y, cameraState.orthoTarget, false);
                        initZoom = activeCamera.zoom;
                        addControlListener("handleZoomRack2DView", handleZoomRack2DView);
                    }
                }
            }
            return cameraState.type;
        };

        /**
         * @description function to switch to 3d view
         * @returns {number} returns the camera type
         */
        var switch3D = function () {
            if (cameraState.type == 1) {
                if (content.children[0].name == "innerRack") {
                    cleanContent("backview");
                    removeControlListener("handleZoomRack2DView", handleZoomRack2DView);
                }
                cameraState.orthoTarget = controls.target.clone();
                cameraState.orthoPos = activeCamera.position.clone();
                cameraState.orthoZoom = activeCamera.zoom;
                cameraState.orthoRotation = controls.getAzimuthalAngle();
                activeCamera = cam3d;
                if (cameraState.perspectivePos !== null) {
                    activeCamera.position.copy(cameraState.perspectivePos);
                }
                if (cameraState.perspectiveTarget !== null) {
                    activeCamera.lookAt(cameraState.perspectiveTarget);
                }
                activeCamera.zoom = cameraState.perspectiveZoom;
                activeCamera.updateProjectionMatrix();
                cameraState.type = 0;
                addControls();
                if (cameraState.perspectiveTarget !== null) {
                    controls.target.copy(cameraState.perspectiveTarget);
                    controls.update();
                }
            }
            // controls.target.copy(cameraState.perspectivePos);
            // controls.update();
            return cameraState.type;
        };

        /**
         * @description function to switch active camera between 2d/3d
         * @returns {number} returns the camera type
         */
        var switch2D3D = function () {
            if (cameraState.type === 0) {
                cameraState.perspectivePos = activeCamera.position.clone();
                cameraState.perspectiveTarget = controls.target.clone();
                cameraState.perspectiveZoom = activeCamera.zoom;
                activeCamera = cam2d;
                if (cameraState.orthoPos !== null) {
                    activeCamera.position.copy(cameraState.orthoPos);
                }
                if (cameraState.orthoTarget !== null) {
                    activeCamera.lookAt(cameraState.orthoTarget);
                }
                activeCamera.zoom = cameraState.orthoZoom;
                cameraState.type = 1;
            }
            if (cameraState.type == 1) {
                cameraState.orthoTarget = controls.target.clone();
                cameraState.orthoPos = activeCamera.position.clone();
                cameraState.orthoZoom = activeCamera.zoom;
                activeCamera = cam3d;
                if (cameraState.perspectivePos !== null) {
                    activeCamera.position.copy(cameraState.perspectivePos);
                }
                if (cameraState.perspectiveTarget !== null) {
                    activeCamera.lookAt(cameraState.perspectiveTarget);
                }
                activeCamera.zoom = cameraState.perspectiveZoom;
                cameraState.type = 0;
            }
            addControls();
            if (cameraState.type == 1) {
                zoomOrtho(objectState.bbox.max.x - objectState.bbox.min.x, objectState.bbox.max.z - objectState.bbox.min.z, controls.target);
            }
            return cameraState.type;
        };

        /**
         * @description function to setup the camera zoom for 2d rack view
         */
        var handleZoomRack2DView = function () {
            if (activeCamera.zoom > initZoom * 1.25) {
                content.traverse(function (o) {
                    if (o.name == "hudisp_1") o.visible = true;
                    if (o.name == "hudisp_2") o.visible = false;
                });
            }
            else {
                content.traverse(function (o) {
                    if (o.name == "hudisp_1") o.visible = false;
                    if (o.name == "hudisp_2") o.visible = true;
                });
            }
        };

        /**
         * @description function to setup the camera position and target for given object
         * @param {THREE.Object3D} object the object to use
         */
        var setupCameraForObject = function (object) {
            switch (object.name) {
                case "innerwall":
                case "outerwall":
                    var hw = Math.max(object.userData.w / 2, object.userData.h / 2);
                    var ha = activeCamera.fov / 2;

                    var d = hw / Math.tan(MathService.degToRad(ha)) * -1;

                    activeCamera.position.copy(object.position);
                    activeCamera.position.add(object.userData.normal.clone().multiplyScalar(d));
                    activeCamera.lookAt(object.position);
                    controls.target = object.position.clone();
                    controls.update();

                    break;
            }
        };

        /**
         * @description function to set the active camera target to provided position
         * @param {THREE.Vector3} pos the position to target the camera at
         */
        var focusObjectPosition = function (pos) {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                activeCamera.lookAt(pos);
                controls.target.copy(pos);
                controls.update();
            }
            if (activeCamera instanceof THREE.OrthographicCamera) {
                activeCamera.position.setX(pos.x).setZ(pos.z);
                activeCamera.lookAt(activeCamera.position.clone().setY(0));
                controls.target.copy(activeCamera.position.clone().setY(0));
                controls.update();
            }
        };

        /**
         * @description function to set active camera properties
         * @param {THREE.Vector3} pos the position of the camera
         * @param {THREE.Vector3} target the target to point the camera to
         */
        var setActiveCameraProps = function (pos, target) {
            if (!pos) return;
            activeCamera.position.copy(pos);
            if (target) {
                activeCamera.lookAt(target);
                controls.target.copy(target);
            }
            controls.update();
        };

        //region cam control funcs
        var stdYAxis = new THREE.Vector3(0, 1, 0);
        var stdXAxis = new THREE.Vector3(1, 0, 0);
        var stdZAxis = new THREE.Vector3(0, 0, 1);

        /**
         * @description function to move the active camera to the left
         */
        var camLeft = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                var camDir = controls.target.clone().sub(activeCamera.position);
                camDir.y = 0;
                camDir.normalize();
                camDir.applyAxisAngle(stdYAxis, Math.PI / 2);
                activeCamera.position.add(camDir);
                controls.target.add(camDir);
                controls.update();
                activeCamera.updateMatrixWorld();
            }
            if (activeCamera instanceof THREE.OrthographicCamera) {
                var moveAxis = null;
                if (content.children[0].name == "innerRack") {
                    moveAxis = stdXAxis.clone().multiplyScalar(0.25);
                }
                else {
                    moveAxis = Math.abs(activeCamera.rotation.z % Math.PI) != 0 ? stdZAxis : stdXAxis;
                }
                activeCamera.position.sub(moveAxis);
                controls.target.sub(moveAxis);
                controls.update();
            }
        };

        /**
         * @description function to move the active camera to the right
         */
        var camRight = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                var camDir = controls.target.clone().sub(activeCamera.position);
                camDir.y = 0;
                camDir.normalize();
                camDir.applyAxisAngle(stdYAxis, Math.PI / -2);
                activeCamera.position.add(camDir);
                controls.target.add(camDir);
                controls.update();
                activeCamera.updateMatrixWorld();
            }
            if (activeCamera instanceof THREE.OrthographicCamera) {
                var moveAxis = null;
                if (content.children[0].name == "innerRack") {
                    moveAxis = stdXAxis.clone().multiplyScalar(0.25);
                }
                else {
                    moveAxis = Math.abs(activeCamera.rotation.z % Math.PI) != 0 ? stdZAxis : stdXAxis;
                }
                activeCamera.position.add(moveAxis);
                controls.target.add(moveAxis);
                controls.update();
            }
        };

        /**
         * @description function to move the camera forward
         */
        var camForward = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                var camDir = controls.target.clone().sub(activeCamera.position);
                camDir.y = 0;
                camDir.normalize();
                camDir.applyAxisAngle(stdYAxis, Math.PI);
                activeCamera.position.add(camDir);
                controls.target.add(camDir);
                controls.update();
                activeCamera.updateMatrixWorld();
            }
            if (activeCamera instanceof THREE.OrthographicCamera) {
                var moveAxis = null;
                if (content.children[0].name == "innerRack") {
                    moveAxis = stdYAxis.clone().multiplyScalar(0.25);
                }
                else {
                    moveAxis = Math.abs(activeCamera.rotation.z % Math.PI) != 0 ? stdXAxis : stdZAxis;
                }
                activeCamera.position.add(moveAxis);
                controls.target.add(moveAxis);
                controls.update();
            }
        };

        /**
         * @description function to move the camera backward
         */
        var camBackward = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                var camDir = controls.target.clone().sub(activeCamera.position);
                camDir.y = 0;
                camDir.normalize();
                activeCamera.position.add(camDir);
                controls.target.add(camDir);
                controls.update();
                activeCamera.updateMatrixWorld();
            }
            if (activeCamera instanceof THREE.OrthographicCamera) {
                var moveAxis = null;
                if (content.children[0].name == "innerRack") {
                    moveAxis = stdYAxis.clone().multiplyScalar(0.25);
                }
                else {
                    moveAxis = Math.abs(activeCamera.rotation.z % Math.PI) != 0 ? stdXAxis : stdZAxis;
                }
                activeCamera.position.sub(moveAxis);
                controls.target.sub(moveAxis);
                controls.update();

            }
        };

        /**
         * @description function to target to camera at the middle of the content
         */
        var camCenter = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                controls.target = getContentBoundingBox().getCenter();
                controls.update();
            }
        };

        /**
         * @description function to zoom in for active camera
         */
        var camZoomIn = function () {
            activeCamera.zoom *= 2;
            if(activeCamera.zoom > maxZoom) {
                activeCamera.zoom = maxZoom;
            }
            handleZoomState();
        };

        /**
         * @description function to zoom out for active camera
         */
        var camZoomOut = function () {
            activeCamera.zoom *= 0.5;
            if(activeCamera.zoom < minZoom) {
                activeCamera.zoom = minZoom;
            }
            handleZoomState();
        };

        /**
         * @description function to handle the current zoom state
         */
        var handleZoomState = function() {
            activeCamera.updateProjectionMatrix();
            controls.update();
            if (content.children[0].name === "innerRack") handleZoomRack2DView();
        }

        /**
         * @description function to move the active camera up, only for PerspectiveCamera
         */
        var camMoveUp = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                activeCamera.position.add(stdYAxis);
                controls.target.add(stdYAxis);
                controls.update();
            }
        };

        /**
         * @description function to move the active camera down, only for PerspectiveCamera
         */
        var camMoveDown = function () {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                activeCamera.position.sub(stdYAxis);
                controls.target.sub(stdYAxis);
                controls.update();
            }
        };

        //endregion
        //region control change listeners
        var controlListener = {};

        /**
         * @description function to trigger all callback functions after controls have been updated
         */
        var callControlListener = function () {
            for (var ctrl in controlListener) controlListener[ctrl]();
        };

        /**
         * @description function to add a callback to be call when controls object updates
         * @param {string} name the name to set
         * @param {function} func the callback function
         */
        var addControlListener = function (name, func) {
            if (controlListener[name] === undefined) {
                controlListener[name] = func;
            }
        };

        /**
         * @description function to remove control update callback functions, if name is provided only callbacks for given name will be removed, otherwise all callbacks will be removed
         * @param {string?} name the name for the callback
         */
        var removeControlListener = function (name) {
            if (controlListener[name] !== undefined) {
                delete controlListener[name];
            }
            if (name === undefined) {
                for (var i in controlListener) delete controlListener[i];
            }
        };
        //endregion
        //region mouse handler

        /**
         * @description function to unbind mouse handler, if targetEventName is provided only mouse handler for this event name will be unbound, otherwise all mouse handler will be unbound
         * @param targetEventName
         */
        var unbindMouseHandler = function (targetEventName) {
            if (targetEventName) {
                $(renderer.domElement).unbind(targetEventName);
            }
            else {
                $(renderer.domElement).unbind();
            }
        };

        /**
         * @description function to setup mouse handler functions
         * @param {function} mouseDownHandler callback function for mouse down event
         * @param {function} mouseMoveHandler callback function for mouse move event
         * @param {function} mouseUpHandler callback function for mouse up event
         * @param {function} dblClickHandler callback function for mouse double click event
         */
        var bindMouseHandler = function (mouseDownHandler, mouseMoveHandler, mouseUpHandler, dblClickHandler) {
            unbindMouseHandler();
            if (mouseDownHandler) {
                $(renderer.domElement).on('mousedown', mouseDownHandler);
                $(renderer.domElement).on('touchstart', function (e) {
                    e.cancelBubble = true;
                    e.preventDefault();
                    mouseDownHandler(e);
                    return false;
                });
            }
            if (mouseMoveHandler) {
                $(renderer.domElement).on('mousemove', mouseMoveHandler);
                $(renderer.domElement).on('touchmove', function (e) {
                    e.cancelBubble = true;
                    e.preventDefault();
                    mouseMoveHandler(e);
                    return false;
                });
            }

            if (mouseUpHandler) {
                $(renderer.domElement).on('mouseup', mouseUpHandler);
                $(renderer.domElement).on('touchend', function (e) {
                    e.cancelBubble = true;
                    e.preventDefault();
                    return false;
                });
            }

            if (dblClickHandler) {
                $(renderer.domElement).on("dblclick", dblClickHandler);
                //TODO find solution for doubletap

            }

            $(renderer.domElement).on('mouseleave', function (e) {
                if (e.originalEvent.buttons !== undefined && e.originalEvent.buttons !== 0) {
                    MessageService.publish("mouseleave", {buttons: e.buttons, offsetX: e.offsetX, offsetY: e.offsetY});
                }
                // workaround for safari
                else if (e.originalEvent.buttons === undefined && e.originalEvent.which !== undefined && e.originalEvent.which !== 0) {
                    MessageService.publish("mouseleave", {buttons: e.which, offsetX: e.offsetX, offsetY: e.offsetY});
                }
            });

        };

        /**
         * @description function to add handler function for target event name
         * @param {function} handler the callback function to bind
         * @param {string} targetEventName the event name to bind to
         */
        var addMouseHandler = function (handler, targetEventName) {
            $(renderer.domElement).unbind(targetEventName);
            $(renderer.domElement).bind(targetEventName, handler);
        };

        /**
         * @description function to set drag enter mouse listener function
         * @param {function} handler the callback function for dragenter event
         */
        var addDragEnterListener = function (handler) {
            $(renderer.domElement).unbind("dragenter");
            $(renderer.domElement).bind("dragenter", function (e) {
                e.preventDefault();
                handler(e, getPositionPickplane(e));
            });
        };

        /**
         * @description function to set dragover mouse listener function
         * @param {function} handler the callback function for dragover event
         */
        var addDragOverListener = function (handler) {
            $(renderer.domElement).unbind("dragover");
            $(renderer.domElement).bind("dragover", function (e) {
                e.preventDefault();
                handler(e, getPositionPickplane(e));
            });
        };

        /**
         * @description function to set drop mouse listener function
         * @param {function} handler the callback function for drop event
         */
        var addDropListener = function (handler) {
            $(renderer.domElement)[0].dropable = true;
            $(renderer.domElement).unbind("drop");
            $(renderer.domElement).bind("drop", function (e) {
                handler(e, getPositionPickplane(e));
            });
        };

        /**
         * @description function to set dragleave mouse listener function
         * @param {function} handler the callback function for dragleave event
         */
        var addDragLeaveListener = function (handler) {
            $(renderer.domElement).unbind("dragleave");
            $(renderer.domElement).bind("dragleave", function (e) {
                handler(e);
            });
        };
        //endregion
        //region picking
        /**
         * @description function to get the mouse position in screen space
         * @param {MouseEvent} e the mouse event to compute position for
         * @returns {THREE.Vector3} returns mouse position in screen space
         */
        var getMousePos = function (e) {
            var _event = e;
            if (e.hasOwnProperty("originalEvent")) {
                _event = e.originalEvent;
                if (_event.touches) {
                    _event = e.originalEvent.touches[0];
                }
            }
            var mousePos = new THREE.Vector3();
            var eox = e.offsetX;
            var eoy = e.offsetY;
            if (e.offsetX === undefined && _event) {
                eox = _event.clientX - $(_event.target).offset().left;
                eoy = _event.clientY - $(_event.target).offset().top;
            }
            mousePos.x = ((eox) / $(renderer.domElement).width()) * 2 - 1;
            mousePos.y = -((eoy) / $(renderer.domElement).height()) * 2 + 1;
            mousePos.z = 1;
            return mousePos;
        };

        /**
         * @description function to setup picking ray
         * @param {THREE.Vector3} mp the mouse position in screen space
         */
        var setupRay = function (mp) {
            if (activeCamera instanceof THREE.PerspectiveCamera) {
                mp.unproject(activeCamera);
                ray.set(activeCamera.position, mp.sub(activeCamera.position).normalize());
            }
            if (activeCamera instanceof THREE.OrthographicCamera) {
                var t0 = new THREE.Vector3(mp.x, mp.y, -1).unproject(activeCamera);
                var dir = new THREE.Vector3(0, 0, -1).transformDirection(activeCamera.matrixWorld);
                ray.set(t0, dir);
            }
        };

        /**
         * @description function to get the position on the pick plane for mouse action
         * @param {MouseEvent} e the mouse event
         * @returns {Array} returns array of intersected objects
         */
        var getPositionPickplane = function (e) {
            if (controlObject3D.children.length == 1) {
                setupRay(getMousePos(e));
                var intersects = [];
                controlObject3D.children[0].raycast(ray, intersects);
                return intersects;
            }
        };

        /**
         * @description function to set the pick plane position
         * @param {THREE.Vector3} pos the position to set
         */
        var setPickplanePosition = function (pos) {
            controlObject3D.children[0].position.copy(pos);
        };

        /**
         * @description function to reset the pick plane position
         */
        var resetPickplanePosition = function () {
            controlObject3D.children[0].position.set(0, 0, 0);
        };

        /**
         * @description function to find intersections for mouse position/ray with given objects
         * @param {MouseEvent} e the mouse event to use
         * @param {array|THREE.Object3D} objects object/objects to check intersection with projected ray
         * @returns {*} returns array of intersection information
         */
        var pickObjects = function (e, objects) {
            if (objects === undefined) return;
            setupRay(getMousePos(e));
            if (objects instanceof THREE.Object3D) {
                return ray.intersectObject(objects, true);
            }
            if (objects instanceof Array) {
                return ray.intersectObjects(objects, true);
            }
        };

        /**
         * @description function to find intersection for mouse position/ray with given objects, only objects with a name that is present in names array will be returned
         * @param {MouseEvent} e the mouse event
         * @param {array|THREE.Object3D} objects the objects to run intersection test for
         * @param {string[]} names array of names to filter for
         * @returns {*}
         */
        var pickObjectsName = function (e, objects, names) {
            if (!(names instanceof Array)) {
                names = [names];
            }
            var i = pickObjects(e, objects);
            if (i === undefined) return [];
            if (i.length > 0) {
                for (var ii = i.length - 1; ii >= 0; ii--) {
                    var found = false;
                    for (var iii = 0; iii < names.length; iii++) {
                        if (i[ii].object.name.indexOf(names[iii]) !== -1) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        i.splice(ii, 1);
                    }
                }
            }
            return i;
        };
        //endregion

        /**
         * @description function to rotate the provided to face the current camera
         * @param {THREE.Object3D} obj the object to rotate to face the camera
         */
        var rotateObjectToCamera = function (obj) {
            if (activeCamera instanceof THREE.OrthographicCamera) {
                obj.rotation.y = 0;
            }
            else {
                var pc = activeCamera.position.clone();
                var po = obj.position.clone();
                pc.y = po.y = 0;
                pc.sub(po);
                var a = new THREE.Vector3(0, 0, 1).angleTo(pc);
                if (pc.x < 0) a *= -1;
                obj.rotation.y = a;
            }
        };
        //region object marking
        var markedObject = null; // currently marked object
        var persistentMarkedObject = null; // currently persistent marked object
        var markers = {};

        /**
         * @description function to modify the color of child element of provided object
         * @param {THREE.Object3D} obj the object to modify children for
         * @param {number} color the color to set
         */
        var setChildColors = function (obj, color) {
            for (var i = 0; i < obj.children.length; i++) {
                obj.children[i].material.color.setHex(color);
            }
        };

        /**
         * @description function to setup marking object
         * @param {THREE.Object3D} obj the object to setup
         * @param {boolean} persistent if true object will be setup as persistent marked object, otherwise object will
         *                             be setup as normal marked object
         */
        var setMarkObjects = function (obj, persistent) {
            if (persistent) {
                persistentMarkedObject = obj;
                if (markedObject !== null && obj && markedObject.uuid == obj.uuid) markedObject = null;
            }
            else {
                markedObject = obj;
            }
        };

        /**
         * @description function to mark a 3d-object
         * @param {THREE.Object3D|THREE.Mesh} obj the object to mark
         * @param {object?} param optional parameters to use, color
         * @param {boolean} persistent if true object will be marked persistently otherwise object will only be marked temporarily
         */
        var markObject = function (obj, param, persistent) {
            var markColor = 0x00ff00;
            if (param && param == "delete") markColor = 0xff0000;
            if (obj === undefined || obj === null) return;
            if (markedObject !== null && obj.uuid !== markedObject.uuid) unmarkObject(markedObject);
            if (persistent && persistentMarkedObject !== null && obj.uuid !== persistentMarkedObject.uuid) unmarkObject(persistentMarkedObject, persistent);

            switch (obj.name) {
                case "raisedFloor":
                    obj.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "outerwall":
                    obj.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "innerwall":
                    obj.material.color.setHex(markColor);
                    if (obj.userData.type == 1) obj.children[0].material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "lattice":
                    obj.material.color.setHex(markColor);
                    obj.parent.material.color.setHex(markColor);
                    setMarkObjects(obj.parent, persistent);
                    break;
                case "door":
                    obj.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "window_obj":
                    obj.traverse(function (o) {
                        if (o.name == "window_obj") return;
                        if (o.material.visible) {
                            o.material.color.setHex(markColor);
                        }
                    });
                    setMarkObjects(obj, persistent);
                    break;
                case "pillar":
                    obj.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "b_rotate":
                case "t_rotate":
                    setChildColors(obj, 0xffedae);
                    setMarkObjects(obj, persistent);
                    break;
                case "xpos_move":
                case "xneg_move":
                    setChildColors(obj, 0xf49ba1);
                    setMarkObjects(obj, persistent);
                    break;
                case "zpos_move":
                case "zneg_move":
                    setChildColors(obj, 0xa3bad4);
                    setMarkObjects(obj, persistent);
                    break;
                case "close":
                    setChildColors(obj, 0xcdcdcd);
                    setMarkObjects(obj, persistent);
                    break;
                case "hoverWall":
                    var w = obj.userData.parent;
                    w.material.color.setHex(markColor);
                    w.userData.outline.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "wallEndPoint":
                    obj.material.color.setHex(markColor);
                    if (Tools.isDefinedNotNull(obj.userData.connectedTo)) obj.userData.connectedTo.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                case "rack":
                    if (markers[obj.uuid] !== undefined && !persistent) break;
                    var markArrow = Object3DFactory.buildMarkArrow();
                    markArrow.position.copy(obj.userData.obb.c);
                    markArrow.position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 0.7));
                    // obj.userData.marker = markArrow;
                    markers[obj.uuid] = markArrow;
                    if (persistent) {
                        obj.material.visible = false;
                        obj.children[0].visible = true;
                        markArrow.material.color.setHex(0x00ff00);
                    }
                    else {
                        obj.material.visible = false;
                        obj.children[0].visible = true;
                    }
                    add3DObjectToMarkObj(markArrow);
                    setMarkObjects(obj, persistent);
                    break;
                case "asset":
                case "cooling":
                case "floortile":
                case "ups":
                case "containment":
                case "sensor":
                    if (markers[obj.uuid] !== undefined && !persistent) break;
                    var markArrow = Object3DFactory.buildMarkArrow();
                    markArrow.position.copy(obj.userData.obb.c);
                    markArrow.position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 0.7));
                    // obj.userData.marker = markArrow;
                    markers[obj.uuid] = markArrow;
                    if (persistent) {
                        markArrow.material.color.setHex(0x00ff00);
                    }
                    add3DObjectToMarkObj(markArrow);
                    setMarkObjects(obj, persistent);
                    break;
                case "containment_door":
                case "containment_plane":
                    if (markers[obj.parent.uuid] !== undefined && !persistent) break;
                    var markArrow = Object3DFactory.buildMarkArrow();
                    var oo = obj.parent;
                    markArrow.position.copy(oo.userData.obb.c);
                    markArrow.position.add(oo.userData.obb.u[1].clone().multiplyScalar(oo.userData.obb.e.y + 0.7));
                    markers[oo.uuid] = markArrow;
                    if (persistent) {
                        markArrow.material.color.setHex(0x00ff00);
                    }
                    add3DObjectToMarkObj(markArrow);
                    setMarkObjects(oo, persistent);
                    break;
                case "slot":
                    if (!persistent && persistentMarkedObject != null && persistentMarkedObject.uuid == obj.uuid) break;
                    obj.material.color.setHex(markColor);
                    if (obj.userData.type == 3) {
                        obj.parent.traverse(function (o) {
                            if (o.name == "blade") o.material.color.setHex(markColor);
                        });
                    }
                    setMarkObjects(obj, persistent);
                    break;
                case "blade":
                    markObject(obj.parent.children[0]);
                    break;
                case "tileObjX":
                case "tileObjZ":
                    obj.material.uniforms.color.value.setHex(Object3DFactory.colors.letterColor.mark);
                    setMarkObjects(obj, persistent);
                    break;
                case "hoverSlot":
                    obj.material.color.setHex(markColor);
                    setMarkObjects(obj, persistent);
                    break;
                default:
            }
        };

        /**
         * @description function to mark a 3d-object in alarm modal content
         * @param {THREE.Object3D|THREE.Mesh} obj the object to mark
         * @param {object?} param optional parameters to use, color
         * @param {boolean} persistent if true object will be marked persistently otherwise object will only be marked temporarily
         */
        var markAlarmObject = function (obj, param, persistent) {
            var markColor = 0xff0000;
            if (obj === undefined || obj === null) return;
            if (markedObject !== null && obj.uuid !== markedObject.uuid) unmarkObject(markedObject);
            if (persistent && persistentMarkedObject !== null && obj.uuid !== persistentMarkedObject.uuid) unmarkObject(persistentMarkedObject, persistent);

            switch (obj.name) {
                case "rack":
                    if (markers[obj.uuid] !== undefined && !persistent) break;
                    var markArrow = Object3DFactory.buildAlarmMarkArrow();
                    markArrow.position.copy(obj.userData.obb.c);
                    markArrow.position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 1));
                    markers[obj.uuid] = markArrow;
                    if (persistent) {
                        obj.material.visible = false;
                        obj.children[0].visible = true;
                        markArrow.material.color.setHex(markColor);
                    }
                    else {
                        obj.material.visible = false;
                        obj.children[0].visible = true;
                    }
                    add3DObjectToMarkObj(markArrow);
                    setMarkObjects(obj, persistent);
                    break;
                case "asset":
                case "cooling":
                    var markArrow = Object3DFactory.buildAlarmMarkArrow();
                    markArrow.position.copy(obj.userData.obb.c);
                    markArrow.position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 1));
                    markers[obj.uuid] = markArrow;
                    if (persistent) {
                        markArrow.material.color.setHex(markColor);
                    }
                    add3DObjectToMarkObj(markArrow);
                    setMarkObjects(obj, persistent);
                    break;
                case "floortile":
                case "ups":
                case "containment":
                case "sensor":
                    if (markers[obj.uuid] !== undefined && !persistent) break;
                    var markArrow = Object3DFactory.buildAlarmMarkArrow();
                    markArrow.position.copy(obj.userData.obb.c);
                    markArrow.position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 1));
                    markers[obj.uuid] = markArrow;
                    if (persistent) {
                        markArrow.material.color.setHex(markColor);
                    }
                    add3DObjectToMarkObj(markArrow);
                    setMarkObjects(obj, persistent);
                    break;
                default:
            }
        };

        /**
         * @description function to unmark the provided object
         * @param {THREE.Object3D|THREE.Mesh} obj the object to unmark
         * @param {boolean} persistent if true object to unmark has to be marked persistently, if false marked object has not to be marked persistently
         */
        var unmarkObject = function (obj, persistent) {
            if (obj === undefined || obj === null) {
                if ((persistent && persistentMarkedObject === null) || (markedObject === null && !persistent)) return;
            }
            if (obj === undefined && !persistent) obj = markedObject;
            if (obj === undefined && persistent) obj = persistentMarkedObject;
            switch (obj.name) {
                case "b_rotate":
                case "t_rotate":
                    setChildColors(obj, 0xffd500);
                    break;
                case "xpos_move":
                case "xneg_move":
                    setChildColors(obj, 0xe30613);
                    break;
                case "zpos_move":
                case "zneg_move":
                    setChildColors(obj, 0x006598);
                    break;
                case "close":
                    setChildColors(obj, 0x000000);
                    break;
                case "hoverWall":
                    var w = obj.userData.parent;
                    w.material.color.setHex(Object3DFactory.colors.flatWall.plane);
                    w.userData.outline.material.color.setHex(obj.userData.type ? Object3DFactory.colors.flatWall.outlineCage : Object3DFactory.colors.flatWall.outline);
                    break;
                case "wallEndPoint":
                    obj.material.color.setHex(Object3DFactory.colors.pointmarker.point);
                    if (Tools.isDefinedNotNull(obj.userData.connectedTo)) obj.userData.connectedTo.material.color.setHex(Object3DFactory.colors.pointmarker.point);
                    break;
                case "innerwall":
                    if (obj.userData.type == 0) {
                        obj.material.color.setRGB(1, 1, 1);
                    }
                    else {
                        obj.material.color.setHex(Object3DFactory.colors.cage.outer);
                        if (obj.children.length) obj.children[0].material.color.setHex(Object3DFactory.colors.cage.outer);
                    }
                    break;
                case "rack":
                    if (markers[obj.uuid] !== undefined && remove3DObjectFromMarkObj(markers[obj.uuid])) delete (markers[obj.uuid]);
                    if (!persistent) {
                        obj.material.visible = true;
                        obj.children[0].visible = false;
                    }
                    else {
                        obj.material.visible = true;
                        obj.children[0].visible = false;
                    }
                    break;
                case "cooling":
                case "asset":
                case "floortile":
                case "ups":
                case "containment":
                case "sensor":
                    // scene.remove(obj.userData.marker);
                    if (markers[obj.uuid] !== undefined && remove3DObjectFromMarkObj(markers[obj.uuid])) delete (markers[obj.uuid]);
                    break;
                case "slot":
                    obj.material.color.setHex(0xffffff);
                    if (obj.userData.type == 3) {
                        if(Tools.isDefinedNotNull(obj.parent)){
                            obj.parent.traverse(function (o) {
                                if (o.name == "blade") o.material.color.setHex(0xffffff);
                            });
                        }
                    }
                    break;
                case "window_obj":
                    obj.traverse(function (o) {
                        if (o.name == "window_obj") return;
                        if (o.material.visible) o.material.color.setHex(0xffffff);
                    });
                    break;
                case "tileObjX":
                case "tileObjZ":
                    obj.material.uniforms.color.value.setHex(Object3DFactory.colors.letterColor.color);
                    break;
                case "hoverSlot":
                    obj.material.color.setHex(0xcdcdcd);
                    break;

                default:
                    obj.material.color.setRGB(1, 1, 1);
            }
            setMarkObjects(null, persistent);
        };

        /**
         * @description function to update the position of the object marker
         * @param {THREE.Object3D|THREE.Mesh} obj the object to update marker position for
         */
        var updateMarkerPosition = function (obj) {
            if (markers[obj.uuid] !== undefined) {
                markers[obj.uuid].position.copy(obj.userData.obb.c);
                markers[obj.uuid].position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 0.7));
            }
        };

        /**
         * @description function to mark provided object while other objects are marked to (used for alignment)
         * @param {THREE.Object3D|THREE.Mesh} obj the object to mark
         * @param {string} param if 'first' the markers color will be modify
         */
        var markObjectMulti = function (obj, param) {
            if (obj.name == "rack" || obj.name == "asset" || obj.name == "cooling" || obj.name == "ups" || obj.name == "floortile" || obj.name == "sensor") {
                var arrow = Object3DFactory.buildMarkArrow();
                arrow.position.copy(obj.userData.obb.c);
                arrow.position.add(obj.userData.obb.u[1].clone().multiplyScalar(obj.userData.obb.e.y + 0.7));
                markers[obj.uuid] = arrow;
                if (param == "first") arrow.material.color.setHex(0x00ff00);
                add3DObjectToMarkObj(arrow);
            }
        };

        /**
         * @description function to unmark multi-marked object
         * @param {THREE.Object3D|THREE.Mesh} obj the object to unmark
         */
        var unmarkObjectMulti = function (obj) {
            if (obj === undefined || obj === null) {
                for (var i in markers) {
                    remove3DObjectFromMarkObj(markers[i]);
                }
                markers = {};
            }
            else {
                if (markers[obj.uuid] !== undefined) {
                    if (remove3DObjectFromMarkObj(markers[obj.uuid])) delete markers[obj.uuid];
                }
            }
        };

        /**
         * @description function to get currently marked object
         * @returns {*} returns the marked object or null if no object is marked
         */
        var getMarkedObject = function () {
            return markedObject;
        };

        /**
         * @description function to get current persistent marked object
         * @returns {*} returns the persistent marked object or null if no object is marked
         */
        var getPersistentMarkedObject = function () {
            return persistentMarkedObject;
        };

        /**
         * @description function to unmark all objects
         */
        var unmarkAllObjects = function () {
            if (persistentMarkedObject !== null) unmarkObject(persistentMarkedObject, true);
            if (markedObject !== null) unmarkObject(markedObject);
        };
        //endregion

        /**
         * @description function to check whether the provided obb intersects the obbs of provided objects
         * @param {OBB} obb the bounding box to run test for
         * @param {array} objs array of 3d-objects to run intersection test against
         * @returns {boolean} returns true if provided obb intersects any of the provided objects obbs, otherwise false
         */
        var checkMultiObjectIntersectionOBB = function (obb, objs) {
            if (objs === undefined || objs.length === 0) return false;
            for (var i = 0; i < objs.length; i++) {
                if (objs[i].userData.obb.isIntersectionBox(obb)) return true;
            }
            return false;
        };

        /**
         * @description function to check in provided move object intersects any other object in contain object
         * @param {THREE.Object3D|THREE.Mesh} moveObject the 'active' object to test intersections for
         * @param {THREE.Object3D} containObject the container object to check children for intersection with 'active' object
         * @param {Room} room the room object
         * @returns {boolean} returns true if intersection is detected, otherwise false
         */
        var checkCollision = function (moveObject, containObject, room) {
            switch (moveObject.name) {
                case 'rack':
                case 'cooling':
                case 'sensor':
                case 'asset':
                case 'floortile':
                    var obb = moveObject.userData.obb;
                    if (room.checkOBBInRoom(obb)) {
                        var triggered = false;
                        containObject.traverse(function (o) {
                            if (o.name == "cooling" || o.name == "asset" || o.name == "pillar" || (o.name == "rack" && o.uuid != moveObject.uuid) || o.name == "containment" || o.name == "outerwall" || o.name == "innerwall") {
                                if (o.userData.obb.isIntersectionBox(obb)) {
                                    triggered = true;
                                }
                            }
                        });
                        if (triggered) return true;
                    }
                    else {
                        return true;
                    }
                    break;
                case 'slot':
                    break;
            }
            return false;
        };

        /**
         * @description function to show overlay while loading data
         */
        var showWaitingContainer = function () {
            $('#fullOverlayContainer').show();
        };

        /**
         * @description function to hide overlay
         */
        var hideWaitingContainer = function () {
            $('.glOverlayContainer').removeClass("hidden");
            $('.roomEditorSideBar').removeClass("hidden");
            $('#fullOverlayContainer').hide();
        };

        /**
         * @description function to get the projected pixel coordinates for the canvas of provided vector
         * @param {number} x x-component value
         * @param {number} y y-component value
         * @param {number} z z-component value
         * @returns {object} object with x and y properties for pixel position
         */
        var getProjectedPixelCoordinatesFromPosition = function (x, y, z) {
            var vec = new THREE.Vector3(x, y, z);
            vec.project(activeCamera);
            vec.x = Math.round((vec.x + 1) * renderer.getSize().width / 2);
            vec.y = Math.round((-vec.y + 1) * renderer.getSize().height / 2);
            return {x: vec.x, y: vec.y};
        };

        /**
         * @description function to get the projected pixel coordinates for the canvas of the provided vectors
         * @param {array} positions array of objects describing a vector in R³
         * @returns {Array} array of projected pixel positions for the provided vectors
         */
        var getProjectedPixelCoordinatesFromPositions = function (positions) {
            var ret = [];
            for (var i = 0; i < positions.length; i++) {
                ret.push(getProjectedPixelCoordinatesFromPosition(positions[i].x, positions[i].y, positions[i].z));
            }
            return ret;
        };

        /**
         * @description function to get the renderer DOM element, the canvas
         * @returns {*} returns the renderer DOM element
         */
        var getRendererDomElement = function () {
            return renderer.domElement;
        };

        // setup listener for size changes
        MessageService.subscribe("sidebar-change", function () {
            handleSize();
        });

        return {
            init: init,
            destroy: destroy,
            draw3DObject: draw3DObject,
            add3DObjectToContent: add3DObjectToContent,
            remove3DObjectFromContent: remove3DObjectFromContent,
            add3DObjectToDummyObj: add3DObjectToDummyObj,
            remove3DObjectFromDummyObj: remove3DObjectFromDummyObj,
            add3DObjectToScene: add3DObjectToScene,
            remove3DObjectFromScene: remove3DObjectFromScene,
            add3DObjectToLineObj: add3DObjectToLineObj,
            remove3DObjectFromLineObj: remove3DObjectFromLineObj,
            add3DObjectToHoverObj: add3DObjectToHoverObj,
            remove3DObjectFromHoverObj: remove3DObjectFromHoverObj,
            add3DObjectToMeasureObj: add3DObjectToMeasureObj,
            remove3DObjectFromMeasureObj: remove3DObjectFromMeasureObj,
            removeObjectByNameFromObject: removeObjectByNameFromObject,
            findObjectByName: findObjectByName,
            findObjectByUniqueId: findObjectByUniqueId,
            findObjectByTypeAndID: findObjectByTypeAndID,
            cleanLineObj: cleanLineObj,
            cleanContent: cleanContent,
            cleanDummy: cleanDummy,
            cleanHoverObj: cleanHoverObj,
            cleanMeasureObj: cleanMeasureObj,
            cleanAll: cleanAll,
            cleanDummyLineHover: cleanDummyLineHover,
            cleanObject: cleanObject,
            getDummyObject: getDummyObject,
            getHoverObject: getHoverObject,
            getSceneObject: getSceneObject,
            switch2D3D: switch2D3D,
            switch2D: switch2D,
            switch3D: switch3D,
            setupCameraForObject: setupCameraForObject,
            getActiveCamera: getActiveCamera,
            getControls: getControls,
            addControls: addControls,
            disableControls: disableControls,
            enableControls: enableControls,
            disableControlsRotate: disableControlsRotate,
            enableControlsRotate: enableControlsRotate,
            addControlListener: addControlListener,
            removeControlListener: removeControlListener,
            bindMouseHandler: bindMouseHandler,
            unbindMouseHandler: unbindMouseHandler,
            addMouseHandler: addMouseHandler,
            getPositionPickplane: getPositionPickplane,
            setPickplanePosition: setPickplanePosition,
            resetPickplanePosition: resetPickplanePosition,
            pickObjects: pickObjects,
            pickObjectsName: pickObjectsName,
            rotateObjectToCamera: rotateObjectToCamera,
            markObject: markObject,
            markAlarmObject: markAlarmObject,
            unmarkObject: unmarkObject,
            updateMarkerPosition: updateMarkerPosition,
            markObjectMulti: markObjectMulti,
            unmarkObjectMulti: unmarkObjectMulti,
            getMarkedObject: getMarkedObject,
            getPersistentMarkedObject: getPersistentMarkedObject,
            unmarkAllObjects: unmarkAllObjects,
            checkMultiObjectIntersectionOBB: checkMultiObjectIntersectionOBB,
            camCenter: camCenter,
            camLeft: camLeft,
            camRight: camRight,
            camForward: camForward,
            camBackward: camBackward,
            camZoomIn: camZoomIn,
            camZoomOut: camZoomOut,
            camMoveDown: camMoveDown,
            camMoveUp: camMoveUp,
            addDragEnterListener: addDragEnterListener,
            addDragOverListener: addDragOverListener,
            addDropListener: addDropListener,
            addDragLeaveListener: addDragLeaveListener,
            setActiveCameraProps: setActiveCameraProps,
            getContentBoundingBox: getContentBoundingBox,
            checkCollision: checkCollision,
            setAntialias: setAntialias,
            setZoomSpeed: setZoomSpeed,
            zoomOrtho: zoomOrtho,
            isCam3D: isCam3D,
            focusObjectPosition: focusObjectPosition,
            showWaitingContainer: showWaitingContainer,
            hideWaitingContainer: hideWaitingContainer,
            handleSize: handleSize,
            handleZoomRack2DView: handleZoomRack2DView,
            updateContentBBox: updateContentBBox,
            getProjectedPixelCoordinatesFromPosition: getProjectedPixelCoordinatesFromPosition,
            getProjectedPixelCoordinatesFromPositions: getProjectedPixelCoordinatesFromPositions,
            getRendererDomElement: getRendererDomElement
        }
    });
})();
