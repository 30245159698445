'use strict';

/**
 * @description Constructor for new slot objects
 * @param {number} id the id to set
 * @param {Object} pos 3-dimensional vector object describing objects position
 * @param {Object} size 3-dimensional vector object describing objects size
 * @param {Object} rot 3-dimensional vector object describing objects rotation
 * @param {String} name the name to set
 * @param {String} comment the comment to set
 * @param {String} url the url to set
 * @param {String} inventoryNumber the inventory number to set
 * @param {String} serialNumber the serial number to set
 * @param {number} type the type to set
 * @param {number} direction the direction to set (front or back)
 * @param {number} bladeRow number of blade rows to set (only relevant for blade enclosures)
 * @param {number} bladeCol number of blade columns to set (only relevant for blade enclosures)
 * @param {Array} blades array of blade objects
 * @param {number} partlibrarySlotId the part library object id from which this object was derived
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set (rack id)
 * @param {number} weight the weight of the object in kg
 * @constructor
 */
function Slot(id, pos, size, rot, name, comment, url, inventoryNumber, serialNumber, type, direction, bladeRow, bladeCol, blades, partlibrarySlotId, uid, pid, weight, maxConsumption, driverValues, deepCopy) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.url = url !== undefined ? url : "";
    this.type = type !== undefined ? type : 0;
    this.direction = direction !== undefined ? direction : 0;
    this.bladeRow = bladeRow !== undefined ? bladeRow : 1;
    this.bladeCol = bladeCol !== undefined ? bladeCol : 1;
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    // Next all controls for a deep copy of all blades inside of a slot
    if (blades !== undefined && blades instanceof Array && deepCopy) {
        for (var blade =0; blade < blades.length; blade++) {
            blades[blade].id = angular.copy(Entity.getNewLocaleUniqueId());
            blades[blade].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (blades[blade].cpus.length > 0) {
                var bladeId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var cpu = 0; cpu < blades[blade].cpus.length; cpu++) {
                    blades[blade].cpus[cpu].id = angular.copy(Entity.getNewLocaleUniqueId());
                    blades[blade].cpus[cpu].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    blades[blade].cpus[cpu].bladeId = bladeId;
                    if (blades[blade].cpus[cpu].driverValues.length > 0) {
                        for(var dv = 0; dv < blades[blade].cpus[cpu].driverValues.length; dv++) {
                            blades[blade].cpus[cpu].driverValues[dv].id = angular.copy(Entity.getNewLocaleUniqueId());
                            blades[blade].cpus[cpu].driverValues[dv].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                            blades[blade].cpus[cpu].driverValues[dv].driver.id = angular.copy(Entity.getNewLocaleUniqueId());
                            blades[blade].cpus[cpu].driverValues[dv].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                            if (blades[blade].cpus[cpu].driverValues[dv].limits.length > 0) {
                                var cpuDriverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                                for (var limit = 0; limit < blades[blade].cpus[cpu].driverValues[dv].limits.length; limit++) {
                                    blades[blade].cpus[cpu].driverValues[dv].limits[limit].id = angular.copy(Entity.getNewLocaleUniqueId());
                                    blades[blade].cpus[cpu].driverValues[dv].limits[limit].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                                    blades[blade].cpus[cpu].driverValues[dv].limits[limit].driverValueId = cpuDriverValueId;
                                }
                            }
                        }
                    }
                }
            }
        }
        this.blades = blades;
    } else {
        this.blades = [];
    }
    this.partlibrarySlotId = partlibrarySlotId !== undefined ? partlibrarySlotId : "";
    this.rackId = pid !== undefined ? pid : null;
    this.allowDataPoints = type === 5;
    this.isSlot = true;
    this.posHU = null;
    // Next all controls for a deep copy of all driver values inside of a slot
    if (driverValues !==null && driverValues !==undefined && driverValues.length > 0 && deepCopy) {
        var sensorId = angular.copy(Entity.getNewLocaleUniqueId());
        for (var i = 0; i < driverValues.length; i++) {
            driverValues[i].id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].sensorId = sensorId;
            driverValues[i].driver.id= angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (driverValues[i].limits.length > 0) {
                var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var l=0; l < driverValues[i].limits.length; l++) {
                    driverValues[i].limits[l].id = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].driverValueId = driverValueId;
                }
            }
        }
        this.driverValues = driverValues;
    } else {
        this.driverValues = [];
    }

    this.weight = weight !== undefined && weight !== null && !isNaN(weight) ? weight : null;
    this.consumption = maxConsumption !== undefined && maxConsumption !== null && !isNaN(maxConsumption) ? maxConsumption : null;
}

Slot.prototype = Object.create(NamedEntity.prototype);
Slot.prototype.constructor = Slot;

/**
 * @description Function to test equality of this object and the provided object
 * @param {Object} cs the object to compare this with
 * @returns {boolean} returns true if objects are equal, otherwise false
 */
Slot.prototype.equals = function (cs) {
    if (!(cs instanceof Slot)) return false;
    if (!this.equalsNamedEntity(cs)) return false;
    if (this.rackId !== cs.rackId) return false;
    if (this.inventoryNumber !== cs.inventoryNumber) return false;
    if (this.serialNumber !== cs.serialNumber) return false;
    // if(this.objectMac != cs.objectMac) return false;
    if (this.url !== cs.url) return false;
    if (this.partlibrarySlotId !== cs.partlibrarySlotId) return false;
    if (this.type !== cs.type) return false;
    if (this.bladeCol !== cs.bladeCol) return false;
    if (this.bladeRow !== cs.bladeRow) return false;
    if (!this.compareDriverValues(cs.driverValues)) return false;
    if (this.blades.length !== cs.blades.length) return false;
    for (var i = 0; i < this.blades.length; i++) {
        var blade = this.blades[i];
        var cb = cs.blades.filter(function (b) {return b.id === blade.id;})[0];
        if (!cb) return false;
        if (!blade.equals(cb)) return false;
    }
    return true;
};

/**
 * @description Function to reset negative new object ids of this object and its children (set to null)
 */
Slot.prototype.resetIds = function () {
    var i;
    if (this.id < 0) this.id = null;
    if (this.uniqueId < 0) this.uniqueId = null;
    if (this.hasOwnProperty("driverValues")) {
        for (i = 0; i < this.driverValues.length; i++) {
            if (this.driverValues[i].id < 0) this.driverValues[i].id = null;
            if (this.driverValues[i].uniqueId < 0) this.driverValues[i].uniqueId = null;
            if (this.driverValues[i].driver === null) continue;
            if (this.driverValues[i].driver.id < 0) this.driverValues[i].driver.id = null;
            if (this.driverValues[i].driver.uniqueId < 0) this.driverValues[i].driver.uniqueId = null;
        }
    }
    for (i = 0; i < this.blades.length; i++) this.blades[i].resetIds();
};

/**
 * @description Function to alter objects rotation, position and size properties to work for backend
 */
Slot.prototype.parseSizeRotPosForBackend = function () {
    this.rotX = this.rot.x;
    this.rotY = this.rot.y;
    this.rotZ = this.rot.z;

    this.posX = this.pos.x;
    this.posY = this.pos.y;
    this.posZ = this.pos.z;

    this.scaleX = this.size.x;
    this.scaleY = this.size.y;
    this.scaleZ = this.size.z;
    for (var i = 0; i < this.blades.length; i++) this.blades[i].parseSizeRotPosForBackend();
};

/**
 * @description Function to compute objects height unit position (integer)
 * @param {Rack} rack the rack object containing this slot object
 */
Slot.prototype.computeHUPosition = function (rack) {
    var heightRails = rack.heightUnits * 0.045;
    var offsetBottom = (rack.size.y - heightRails) / 2;
    var topPos = this.pos.y + this.size.y / 2 + rack.size.y / 2 - offsetBottom;
    this.posHU = Math.ceil(topPos / 0.045);
};

/**
 * @description Function to compute this slots position in parents object space
 * @param {Rack} rack the rack object containing this slot object
 * @returns {THREE.Vector3} returns this slots position in parents object space
 */
Slot.prototype.computePositionForHU = function (rack) {
    var pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var heightRails = rack.heightUnits * 0.045;
    var offsetBottom = (rack.size.y - heightRails) / 2;
    var topPos = (this.posHU * 0.045) + offsetBottom - this.size.y / 2;
    pos.y = rack.size.y / -2 + topPos;
    return pos;
};

/**
 * @description Function to get axis aligned bounding box for this slot object
 * @returns {THREE.Box3} returns axis aligned bounding box for this slot object
 */
Slot.prototype.getAABB = function () {
    return new THREE.Box3().setFromCenterAndSize(new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z), new THREE.Vector3(this.size.x, this.size.y, this.size.z));
};

/**
 * @description Function run collision tests for this slot object
 * @param {Rack} rack the rack object containing this slot object
 * @param {THREE.Vector3=} pos the new position to run collision check with
 * @returns {boolean} returns true if collision test finds collision, otherwise false
 */
Slot.prototype.checkCollision = function (rack, pos) {
    var box = new THREE.Box3();
    box.min.set(this.size.x / -2, this.size.y / -2, this.size.z / -2);
    box.max.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    if (!pos) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    box.min.add(pos);
    box.max.add(pos);
    var rackBox = new THREE.Box3();

    var sizeY = rack.heightUnits * 0.045;

    rackBox.min.set(0.451 / -2, sizeY / -2, rack.size.z / -2);
    rackBox.max.set(0.451 / 2, sizeY / 2, rack.size.z / 2);
    //check rack collide
    if (!rackBox.containsBox(box)) return true;
    //check slot collide
    for (var i = 0; i < rack.slots.length; i++) {
        if (rack.slots[i].uniqueId === this.uniqueId) continue;
        if (box.intersectsBox(rack.slots[i].getAABB())) return true;
    }
    return false;
};

/**
 * @description Function to get used and unused parameters for this object
 * @param {Array} availableParameters array of all parameters for this object type
 * @returns array containing sub-arrays of used(index 0) and unused parameters(index 1)
 */
Slot.prototype.setupParameters = function (availableParameters) {

    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = this.driverValues.filter(function (elem) {
            return elem.parameter.id === availableParameters[ap].id;
        });
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        }
        else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to validate this object prevent 'fishy' backend data
 * @returns {ErrorObject[]} returns array of error objects
 */
Slot.prototype.validate = function () {
    var i, errorList = [];
    errorList = errorList.concat(this.validateNamedEntity());
    if (this.rackId === undefined || this.rackId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "rackId"));
    if (this.partlibrarySlotId === undefined || this.partlibrarySlotId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "partlibrarySlotId"));
    if (this.driverValues !== undefined && this.driverValues instanceof Array) {
        for (i = 0; i < this.driverValues.length; i++) errorList = errorList.concat(this.driverValues[i].validate());
    }
    for (i = 0; i < this.blades.length; i++) errorList = errorList.concat(this.blades[i].validate());
    return errorList;
};

Slot.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    var i, objs;
    for (i = 0; i < this.driverValues.length; i++) {
        objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    for (i = 0; i < this.blades.length; i++) {
        objs = this.blades[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }

    return [];
};

/**
 * @description Function to get applicable driver types for this object type
 * @param {Object} allDrivers array(associative) of all supported driver types
 * @returns {Object} return array of applicable driver types for this object type
 */
Slot.getAvailableDrivers = function (allDrivers) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "WIB8000") delete availableDrivers[d];
        if (d === "WIRELESS") delete availableDrivers[d];
        if (d === "D0") delete availableDrivers[d];
        if (d === "DBUS") delete availableDrivers[d];
        if (d === "S0") delete availableDrivers[d];
        if (d === "WEBBUS") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to create new slot object from json data object (backend)
 * @param {Object} obj the json data object to use
 * @param {Number} pid the parent id (rack id)
 * @returns {Slot} returns the created slot object
 */
Slot.parseFromHtmlObject = function (obj, pid) {
    var slot = new Slot(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.url, obj.inventoryNumber, obj.serialNumber, obj.type, obj.direction, obj.bladeRow, obj.bladeCol, undefined, obj.partlibrarySlotId, obj.uniqueId, pid, obj.weight, obj.consumption);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, slot);
    slot.modifyDriverValueLimitsForFrontend();
    return slot;
};

/**
 * @description Function to create new slot object from javascript object (dummy copy loses object information)
 * @param {Object} obj the javascript object to use
 * @param {Number} pid the parent id to set (rack id)
 * @returns {Slot} returns the created slot object
 */
Slot.parseFromSimpleObject = function (obj, pid) {
    var slot = new Slot(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.url, obj.inventoryNumber, obj.serialNumber, obj.type, obj.direction, obj.bladeRow, obj.bladeCol, undefined, obj.partlibrarySlotId, obj.uniqueId, pid, obj.weight, obj.consumption);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, slot);
    return slot;
};


