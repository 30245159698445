angular.module('emsv2App')
    .service('AlarmService', function ($http, $log, $q, LiveDataService, WebGLService,
                                       RoomService, RoomEditorService, Object3DFactory,
                                       AssetService, HeatMapGLService, Tools, Notify) {

        //region oldVersion

        var FAC = 0.001;
        var container = null;
        var assetData = {
            textures: {
                rackDiff: {
                    path: "assets/textures/rack_diff.png",
                    tex: null
                },
                rackNormal: {
                    path: "assets/textures/rack_normal.png",
                    tex: null
                },
                doorDiff: {
                    path: "assets/textures/door_diff.png",
                    tex: null
                },
                doorNormal: {
                    path: "assets/textures/door_normal.png",
                    tex: null
                },
                wallDiff: {
                    path: "assets/textures/wall_diff.png",
                    tex: null
                },
                wallNormal: {
                    path: "assets/textures/wall_normal.png",
                    tex: null
                },
                windowDiff: {
                    path: "assets/textures/window_diff.png",
                    tex: null
                },
                windowNormal: {
                    path: "assets/textures/window_normal.png",
                    tex: null
                },
                floorDiff: {
                    path: "assets/textures/floor_diff.png",
                    tex: null
                },
                tileDiff: {
                    path: "assets/textures/floor_vent_diff.png",
                    tex: null
                },
                tileNormal: {
                    path: "assets/textures/floor_vent_normal.png",
                    tex: null
                },
                coolingStdDiff: {
                    path: "assets/textures/cool_std_diff.png",
                    tex: null
                },
                coolingStulzDiff: {
                    path: "assets/textures/cool_diff.png",
                    tex: null
                },
                coolingNormal: {
                    path: "assets/textures/cool_normal.png",
                    tex: null
                },
                coolingRowDiff: {
                    path: "assets/textures/cool_cyberrow_diff.png",
                    tex: null
                },
                coolingRowNormal: {
                    path: "assets/textures/cool_cyberrow_normal.png",
                    tex: null
                },
                coolingBotDiff: {
                    path: "assets/textures/cool_bottom_normal.png",
                    tex: null
                },
                coolingBotNormal: {
                    path: "assets/textures/cool_bottom_normal.png",
                    tex: null
                },
                deskDiff: {
                    path: "assets/textures/desk_diff.png",
                    tex: null
                },
                deskNormal: {
                    path: "assets/textures/desk_normal.png",
                    tex: null
                },
                chairDiff: {
                    path: "assets/textures/office_chair_diff.png",
                    tex: null
                },
                chairNormal: {
                    path: "assets/textures/office_chair_normal.png",
                    tex: null
                }
            },
            models: {
                rack: {
                    path: "assets/models/rack.json",
                    geo: null
                },
                rackD: {
                    path: "assets/models/rack_double.json",
                    geo: null
                },
                windowFrame: {
                    path: "assets/models/window_frame.json",
                    geo: null
                },
                doorStd: {
                    path: "assets/models/door.json",
                    geo: null
                },
                doorSlide: {
                    path: "assets/models/door_slide.json",
                    geo: null
                },
                coolingRow: {
                    path: "assets/models/cool_cyberrow.json",
                    geo: null
                },
                cooling1: {
                    path: "assets/models/cool.json",
                    geo: null
                },
                cooling2: {
                    path: "assets/models/cool_2x.json",
                    geo: null
                },
                cooling3: {
                    path: "assets/models/cool_3x.json",
                    geo: null
                },
                cooling4: {
                    path: "assets/models/cool_4x.json",
                    geo: null
                },
                coolingBot: {
                    path: "assets/models/cool_bottom.json",
                    geo: null
                },
                arrow: {
                    path: "assets/models/arrow.json",
                    geo: null
                },
                desk: {
                    path: "assets/models/desk.json",
                    geo: null
                },
                chair: {
                    path: "assets/models/office_chair.json",
                    geo: null
                }
            }
        };
        var texturesReady = false;
        var modelsReady = false;
        var raisedFloor = null;

        var animationID = null;

        var cam3D = null;
        var cam2D = null;

        var activeCamera = null;

        var scene = null;

        var renderer = null;

        var controls = null;

        var roomContainer = null;
        var markMesh = null;
        var textureLoader = null;
        var modelLoader = null;

        var roomDataJson = [];

        var horizontalTransPlanes = [];

        /**
         * Load assets
         */
        function loadAssets() {
            textureLoader = new THREE.TextureLoader();
            modelLoader = new THREE.JSONLoader();

            function checkTexReady() {
                for (var i in assetData.textures) {
                    if (assetData.textures[i].tex === null) {
                        return;
                    }
                }
                texturesReady = true;
            }

            function checkModelReady() {
                for (var i in assetData.models) {
                    if (assetData.models[i].geo === null) {
                        return;
                    }
                }
                modelsReady = true;
            }

            function loadTex(obj) {
                textureLoader.load(obj.path, function (tex) {
                    obj.tex = tex;
                    checkTexReady();
                });
            }

            function loadModel(obj) {
                modelLoader.load(obj.path, function (geo) {
                    obj.geo = geo;
                    checkModelReady();
                });
            }

            for (var i in assetData.textures) {
                loadTex(assetData.textures[i]);
            }
            for (var i in assetData.models) {
                loadModel(assetData.models[i]);
            }
        }

        /**
         * Chancel animations
         */
        function killRenderer() {
            cancelAnimationFrame(animationID);
            renderer = null;
        }

        /**
         * Mark an object with a arrow
         * @param {object} param is the object to mark if possible
         */
        function markObject(param) {
            roomContainer.traverse(function (o) {
                if (o instanceof THREE.Mesh && o.userData.type !== undefined && o.userData.type == param.type && o.userData.id == param.id) {
                    if (o.geometry.boundingSphere === null) {
                        o.geometry.computeBoundingSphere();
                    }
                    if (markMesh === null) {
                        markMesh = new THREE.Mesh(assetData.models.arrow.geo, new THREE.MeshPhongMaterial({color: 0x00ff00}));
                    }
                    markMesh.position.copy(o.position);
                    markMesh.position.y += 500 * FAC;
                    scene.add(markMesh);
                }
            });
        }

        var showsMap = false;
        var heatMapPlane = null;

        /**
         * Toggle the heat map
         */
        function toggleMap() {
            if (showsMap) {
                roomContainer.remove(heatMapPlane);
                heatMapPlane = null;
                showsMap = false;
            } else {
                addHeatmapPlane();
                showsMap = true;
                if (calcFinished()) {
                    buildHeatMapCanvas();
                    writeHeatMapDataToCanvas();
                    updateHeatMapTexture();
                }
            }
        }

        /**
         * Change the heat map type
         * @param {string} typeUI is the string for the type
         */
        function changeMapType(typeUI) {
            //TODO implement with new Heatmap shit
            switch (typeUI) {
                case 0:
                    type = "temp";
                    break;
                case 2:
                    type = "humi";
                    break;
                case 1:
                    type = "press";
                    break;
            }
            writeHeatMapDataToCanvas();
            updateHeatMapTexture();
        }

        var currentViewType = 0; // 0 -> 3D, 1 -> 2D
        /**
         * Toggle the 2D/3D view
         */
        function toggle2D3D() {
            if (currentViewType === 0) {
                currentViewType = 1;
                if (activeCamera instanceof THREE.PerspectiveCamera) {
                    activeCamera = cam2D;
                    var c = roomDataJson.bb.center();
                    activeCamera.position.set(c.x, 1000 * FAC, c.z);
                    activeCamera.lookAt(c);
                    activeCamera.rotation.y = Math.PI / 2;
                    addControls();
                    activeCamera.updateProjectionMatrix();
                    var x = roomDataJson.bb.max.x - roomDataJson.bb.min.x;
                    var z = roomDataJson.bb.max.z - roomDataJson.bb.min.z;
                    zoomIn2D(x, z, c);
                    controls.target.copy(c);
                    controls.enableRotate = false;
                    controls.update();
                }
            } else {
                currentViewType = 0;
                if (activeCamera instanceof THREE.OrthographicCamera) {
                    activeCamera = cam3D;
                    addControls();
                    hideWalls();
                }
            }
        }

        // region private funcs
        var worker1 = null;
        var worker1Running = false;
        var worker2 = null;
        var worker2Running = false;
        var genGridStructure = null;
        var resultGrid = null;
        var sensorData = null;
        var gradientCanvas = null;
        var gradientTexture = null;
        var heatmapCanvas = null;
        var heatMapTexture = null;
        var gridSize = 0.2;
        var maxVal = null;
        var minVal = null;
        var type = "temp";

        var callbackCompute = null;

        var heatMapGradientInfoTemp = [{value: 15, color: "#0000FF"}, {value: 21, color: "#00FF00"}, {
            value: 27,
            color: "#FFFF00"
        }, {value: 33, color: "#FFA000"}, {value: 35, color: "#FF0000"}];
        var heatMapGradientInfoHumi = [{value: 0, color: "#FFC700"}, {value: 25, color: "#EBFF00"}, {
            value: 50,
            color: "#14FF00"
        }, {value: 75, color: "#00C2FF"}, {value: 100, color: "#0057FF"}];
        var heatMapGradientInfoPressure = [{value: 0, color: "#8C3E00"}, {value: 7.5, color: "#FF0000"}, {
            value: 10,
            color: "#00FF00"
        }, {value: 15, color: "#00E0FF"}, {value: 30, color: "#1400FF"}];

        /**
         * Set map param for the alarm modal component
         * @param value ist configured map position
         */
        function setMapPosition(value) {
            heatMapPlane.position.y = value * (roomDataJson.Size.Y * FAC);
            if (calcFinished()) {
                writeHeatMapDataToCanvas();
                updateHeatMapTexture();
            }
        }

        /**
         * Set transparency of the map
         * @param value
         */
        function setMapTransparency(value) {
            heatMapPlane.material.uniforms.opacity.value = value;
            heatMapPlane.material.needsUpdate = true;
        }

        /**
         * Has no functionality
         * @param roomData
         */
        function initWorker1(roomData) {
            if (worker1Running) {
                return;
            }
            worker1Running = true;
            worker1 = spawnWorker("/scripts/plugin/heatmapworker1.js", handleWorker1Response);
            worker1.postMessage({name: "start", data: roomData, gridSize: gridSize});
        }

        /**
         * Has no functionality
         */
        function initWorker2() {
            if (worker2Running) {
                return;
            }
            worker2Running = true;
            worker2 = spawnWorker("/scripts/plugin/heatmapworker2.js", handleWorker2Response);
            worker2.postMessage({name: "start", data: {sensorData: sensorData, gridStructure: genGridStructure}});
        }

        /**
         * Has no functionality
         * @param e
         */
        function handleWorker1Response(e) {
            worker1Running = false;
            genGridStructure = e.data.data;
            worker1.terminate();
            //console.log("duration worker 1: "+e.data.duration+" \n starting worker 2");
            initWorker2();
        }

        /**
         * Has no functionality
         * @param e
         */
        function handleWorker2Response(e) {
            worker2Running = false;
            worker2.terminate();
            resultGrid = e.data.data;
            if (callbackCompute) {
                callbackCompute();
            }
            if (showsMap) {
                buildHeatMapCanvas();
                writeHeatMapDataToCanvas();
                updateHeatMapTexture();
            }
            // console.log("duration worker 2: "+e.data.duration);
        }

        /**
         * Has no functionality
         * @param scriptPath
         * @param responseHandler
         * @returns {Worker}
         */
        function spawnWorker(scriptPath, responseHandler) {
            var w = new Worker(scriptPath);
            w.onmessage = function (e) {
                responseHandler(e);

            };
            return w;
        }

        /**
         * Has no functionality
         * @param roomObj
         * @returns {{boundingBox: null, sensors: {}, containments: {}, rfHeight: number, hasSuspendedCeiling: boolean, h: number, hasRaisedFloor: boolean, ceilHeight: number}}
         */
        function genWorker1Data(roomObj) {
            var ret = {
                sensors: {},
                containments: {},
                hasRaisedFloor: false,
                hasSuspendedCeiling: false,
                rfHeight: 0,
                ceilHeight: 0,
                h: roomObj.Size.Y * FAC,
                boundingBox: null
            };
            for (var i in roomObj.Sensor) {
                ret.sensors[i] = {
                    x: roomObj.Sensor[i].Position.X * FAC,
                    y: roomObj.Sensor[i].Position.Y * FAC,
                    z: roomObj.Sensor[i].Position.Z * FAC
                };
            }
            for (var i in roomObj.RoomObj) {
                if (roomObj.RoomObj[i].Type === 10000) {
                    ret.containments[i] = {
                        x: roomObj.RoomObj[i].Position.X * FAC,
                        y: roomObj.RoomObj[i].Position.Y * FAC,
                        z: roomObj.RoomObj[i].Position.Z * FAC,
                        w: roomObj.RoomObj[i].Size.X * FAC,
                        h: roomObj.RoomObj[i].Size.Y * FAC,
                        d: roomObj.RoomObj[i].Size.Z * FAC,
                        rot: roomObj.RoomObj[i].Rotation.Y * FAC
                    };
                }
            }
            if (roomObj.hasRaisedFloor) {
                ret.rfHeight = roomObj.floorPanelHeight * FAC;
                ret.hasRaisedFloor = true;
            }
            ret.pts = roomObj.pts;
            ret.boundingBox = {
                min: {x: roomObj.bb.min.x, y: roomObj.bb.min.y, z: roomObj.bb.min.z},
                max: {x: roomObj.bb.max.x, y: roomObj.bb.max.y, z: roomObj.bb.max.z}
            };
            return ret;
        }

        /**
         * Has no functionality
         * @returns {boolean}
         */
        function isWorker1Running() {
            return worker1Running;
        }

        /**
         * Has no functionality
         * @returns {boolean}
         */
        function isWorker2Running() {
            return worker2Running;
        }

        /**
         * Returns a boolean if an global variable is null or not.
         * @returns {boolean} to check the a state
         */
        function calcFinished() {
            return resultGrid !== null ? true : false;
        }

        /**
         * Build a canvas for gradient
         * @param info is the object about the gradient information
         */
        function buildGradientCanvas(info) {
            minVal = info[0].value;
            maxVal = info[info.length - 1].value;

            if (gradientCanvas === null) {
                gradientCanvas = document.createElement("canvas");
            }
            gradientCanvas.width = 256;
            gradientCanvas.height = 1;
            var cc = gradientCanvas.getContext("2d");
            var grad = cc.createLinearGradient(0, 0, 256, 1);
            for (var i = 0; i < info.length; i++) {
                var percent = (info[i].value - minVal) / (maxVal - minVal);
                if (percent < 0) percent = 0;
                if (percent > 1) percent = 1;
                grad.addColorStop(percent, info[i].color);
            }
            cc.fillStyle = grad;
            cc.fillRect(0, 0, 256, 1);
        }

        /**
         * Build a heat map canvas
         */
        function buildHeatMapCanvas() {
            if (heatmapCanvas === null) {
                heatmapCanvas = document.createElement("canvas");
                // document.getElementById("testBox").appendChild(heatmapCanvas);
            }
            if (heatMapTexture === null) {
                heatMapTexture = new THREE.Texture(heatmapCanvas, THREE.UVMapping, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearFilter);
                heatMapTexture.needsUpdate = true;
            }
        }

        /**
         * Fill the heat map with information
         */
        function writeHeatMapDataToCanvas() {
            var pos = heatMapPlane.position.y;
            var grid;
            var maxY;
            if (roomDataJson.hasRaisedFloor && pos <= roomDataJson.floorPanelHeight * FAC) {
                grid = resultGrid.rfGrid;
                maxY = grid[0].length;
            }
            if (!roomDataJson.hasRaisedFloor || pos > roomDataJson.floorPanelHeight * FAC) {
                grid = resultGrid.mainGrid;
                maxY = grid[0].length;
                if (roomDataJson.hasRaisedFloor) {
                    pos -= roomDataJson.floorPanelHeight * FAC;
                }
            }
            var botRow = Math.floor(pos / gridSize);
            var topRow = Math.ceil(pos / gridSize);
            var ignoreTop = false;
            if (topRow > (maxY - 1)) {
                ignoreTop = true;
                topRow = botRow;
            }
            heatmapCanvas.width = genGridStructure.gridInfo.maxX;
            heatmapCanvas.height = genGridStructure.gridInfo.maxZ;
            var cc = heatmapCanvas.getContext("2d");
            var d = cc.getImageData(0, 0, genGridStructure.gridInfo.maxX, genGridStructure.gridInfo.maxZ);
            for (var z = 0; z < genGridStructure.gridInfo.maxZ; z++) {
                for (var x = 0; x < genGridStructure.gridInfo.maxX; x++) {
                    var dp = z * genGridStructure.gridInfo.maxX * 4 + x * 4;
                    var pir = 0;
                    if (grid[x][botRow][z].value[type] === null) {
                        pir = 255;
                    }
                    d.data[dp] = pir;
                    d.data[dp + 1] = pir;
                    d.data[dp + 2] = pir;
                    if (pir !== 255) {
                        var yMax = topRow * gridSize;
                        var yMin = botRow * gridSize;

                        var vTop = 0;
                        if (!ignoreTop) {
                            vTop = grid[x][topRow][z].value[type];
                        }
                        var vBot = grid[x][botRow][z].value[type];

                        var topInContain = grid[x][topRow][z].value.hasOwnProperty("inContain");
                        var botInContain = grid[x][botRow][z].value.hasOwnProperty("inContain");

                        if (botInContain && !topInContain) {
                            var check = isPlaneInContainment(grid[x][botRow][z].value.inContain.d, botRow, pos);
                            if (check) {
                                vTop = vBot;
                            } else {
                                vBot = vTop;
                            }
                        }
                        var dP = (pos - yMin) / (yMax - yMin);
                        if (ignoreTop) {
                            vTop = vBot;
                        }
                        if (topRow == botRow) {
                            dP = 0;
                        }
                        var v = dP * vBot + (1 - dP) * vTop;
                        var v1 = (v - minVal) / (maxVal - minVal);
                        if (v1 < 0) v1 = 0;
                        if (v1 > 1) v1 = 1;
                        d.data[dp + 3] = Math.round(255 * v1);
                    } else {
                        d.data[dp + 3] = 255;
                        d.data[dp] = 255;
                        d.data[dp + 1] = 0;
                        d.data[dp + 2] = 0;
                    }
                }
            }
            cc.putImageData(d, 0, 0);
        }

        /**
         * Check if the plane is in the containment
         * @param d is a parameter from {@link writeHeatMapDataToCanvas}
         * @param gy is the bottom row
         * @param pos is the position
         */
        function isPlaneInContainment(d, gy, pos) {
            var y = gy * gridSize;
            var c = y - pos;
        }

        /**
         * Update the heat map
         */
        function updateHeatMapTexture() {
            if (heatMapTexture === null) {
                heatMapTexture = new THREE.Texture(heatmapCanvas, THREE.UVMapping, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearFilter);
                heatMapTexture.minFilter = THREE.NearestFilter;
            }
            heatMapTexture.needsUpdate = true;
        }

        /**
         * Creates a a dummy texture
         * @returns {THREE.Texture} a dummy texture
         */
        function createDummyTexture() {
            heatmapCanvas = document.createElement("canvas");
            // document.getElementById("testBox").appendChild(heatmapCanvas);
            heatmapCanvas.width = heatmapCanvas.height = 8;
            var cc = heatmapCanvas.getContext("2d");
            cc.fillStyle = "rgba(10,10,10,0.1)";
            cc.fillRect(0, 0, 10, 10);
            heatMapTexture = new THREE.Texture(heatmapCanvas, THREE.UVMapping, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearFilter);
            heatMapTexture.needsUpdate = true;
            return heatMapTexture;
        }

        /**
         * Get the gradient type
         * @param {string} type is the type of the gradient
         * @returns {THREE.Texture} the gradient texture
         */
        function getGradientTexture(type) {
            var gradInfo;
            switch (type) {
                case "Temp":
                    gradInfo = heatMapGradientInfoTemp;
                    break;
                case "Humi":
                    gradInfo = heatMapGradientInfoHumi;
                    break;
                case "Pressure":
                    gradInfo = heatMapGradientInfoPressure;
                    break;
                default:
                    gradInfo = heatMapGradientInfoTemp;
            }
            buildGradientCanvas(gradInfo);
            if (gradientTexture === null) {
                gradientTexture = new THREE.Texture(gradientCanvas);
            }
            gradientTexture.needsUpdate = true;
            return gradientTexture;
        }

        /**
         * Adds a heat map
         */
        function addHeatmapPlane() {
            var heatmapMaterial = createShaderMaterial(createDummyTexture(), getGradientTexture());
            var pts2d = [];
            for (var i = 0; i < roomDataJson.pts.length; i++) {
                pts2d.push(cV2(roomDataJson.pts[i].x, roomDataJson.pts[i].z));
            }
            var shape = new THREE.Shape(pts2d);
            var geo = new THREE.ShapeGeometry(shape);
            var mesh = new THREE.Mesh(geo, heatmapMaterial);
            mesh.rotation.x = Math.PI / 2;
            mesh.position.y = 2000 * FAC;
            generateHeatmapPlaneUVs(mesh);
            mesh.geometry.computeFaceNormals();
            mesh.geometry.computeVertexNormals();
            roomContainer.add(mesh);
            heatMapPlane = mesh;
            mesh.material.uniforms.heat.value.needsUpdate = true;
            mesh.material.needsUpdate = true;
        }

        /**
         * Generate a heat map plane UVs
         * @param mesh is the mash object to work with
         */
        function generateHeatmapPlaneUVs(mesh) {
            var xMax = roomDataJson.bb.max.x - roomDataJson.bb.min.x;
            var zMax = roomDataJson.bb.max.z - roomDataJson.bb.min.z;
            for (var i = 0; i < mesh.geometry.faces.length; i++) {
                var uva = cV2((mesh.geometry.vertices[mesh.geometry.faces[i].a].x - roomDataJson.bb.min.x) / xMax, (mesh.geometry.vertices[mesh.geometry.faces[i].a].y - roomDataJson.bb.min.z) / zMax);
                var uvb = cV2((mesh.geometry.vertices[mesh.geometry.faces[i].b].x - roomDataJson.bb.min.x) / xMax, (mesh.geometry.vertices[mesh.geometry.faces[i].b].y - roomDataJson.bb.min.z) / zMax);
                var uvc = cV2((mesh.geometry.vertices[mesh.geometry.faces[i].c].x - roomDataJson.bb.min.x) / xMax, (mesh.geometry.vertices[mesh.geometry.faces[i].c].y - roomDataJson.bb.min.z) / zMax);
                uva.y = 1 - uva.y;
                uvc.y = 1 - uvc.y;
                uvb.y = 1 - uvb.y;
                mesh.geometry.faceVertexUvs[0][i][0].copy(uva);
                mesh.geometry.faceVertexUvs[0][i][1].copy(uvb);
                mesh.geometry.faceVertexUvs[0][i][2].copy(uvc);
            }
        }

        /**
         * Create a shader material
         * @param dataTex defines the data texture
         * @param gradientTex defines the gradient texture
         * @returns {THREE.ShaderMaterial} a new {@link THREE.ShaderMaterial}
         */
        function createShaderMaterial(dataTex, gradientTex) {
            var uniforms = {
                heat: {type: "t", value: dataTex},
                grad: {type: "t", value: gradientTex},
                opacity: {type: "f", value: 0.5}
            };
            return new THREE.ShaderMaterial({
                uniforms: uniforms,
                vertexShader: createVertexShader(),
                fragmentShader: createFragmentShader(),
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.5
            });
        }

        /**
         * Creates a vertex shader
         * @returns {string} the vertex
         */
        function createVertexShader() {
            var srcVert = [
                "varying vec2 vUv;",
                "void main(){",
                "vUv = uv;",
                "gl_Position=projectionMatrix * modelViewMatrix * vec4(position,1.0);",
                "}"
            ].join("\n");
            return srcVert;
        }

        /**
         * Creates a fragment shader
         * @returns {string} the fragment
         */
        function createFragmentShader() {
            var srcFrag = [
                "uniform sampler2D heat;",
                "uniform sampler2D grad;",
                "uniform float opacity;",
                "varying vec2 vUv;",
                "void main(){",
                "float dr = vUv.x;",
                "vec4 col = texture2D(heat,vUv);",
                "vec4 colFrag = texture2D(grad, vec2(col.a,0.5));",
                "if(col.r != 0.0)",
                "colFrag.rgb = vec3(0.1,0.1,0.1);",
                // "colFrag.rgb = col.rgb;",
                "colFrag.a = opacity;",
                "gl_FragColor = colFrag;",
                "}"
            ].join("\n");
            return srcFrag;
        }

        /**
         * No functionality
         * @param domElement is DOM element
         */
        function init(domElement) {
            // $log.info('Provided DeviceMac', deviceMac);
            container = domElement;
            scene = new THREE.Scene();
            var width = domElement.width();
            var height = domElement.height();
            height = height !== 0 ? height : width / 16 * 9;
            renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(width, height);
            renderer.setClearColor(0x000000, 0);
            cam2D = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, -50, 100);
            cam3D = new THREE.PerspectiveCamera(75, width / height, 0.1, 10000000);

            cam2D.position.set(0, 10, 0);
            cam3D.position.set(0, 0, 10);

            cam2D.lookAt(scene.position);
            cam3D.lookAt(scene.position);

            scene.add(cam2D);
            scene.add(cam3D);

            activeCamera = cam3D;
            addControls();
            domElement.html("");//TODO check for mem fuckups regarding delete of renderer
            domElement.append(renderer.domElement);
            $(window).resize(function () {
                handleResize();
            });
            configureLights();
            animate();
        }

        /**
         * Adding controls
         */
        function addControls() {
            if (activeCamera instanceof THREE.PerspectiveCamera) console.debug("controls for perspective cam add");
            if (activeCamera instanceof THREE.OrthographicCamera) console.debug("controls for ortho cam add");
            if (controls !== null) {
                controls.dispose();
            }
            controls = new THREE.OrbitControls(activeCamera, renderer.domElement);
            controls.enableKeys = false;
            controls.addEventListener('change', function () {
                hideWalls();
            });
        }

        /**
         * No functionality
         * @param pts is a object without context...
         */
        function setupCameras(pts) {
            var bb = roomDataJson.bb;
            cam3D.position.set(bb.min.x - (bb.max.x - bb.min.x) / 2, roomDataJson.Size.Y * FAC + 10000 * FAC, bb.max.z + (bb.max.z - bb.min.z) / 2);
            cam3D.lookAt(bb.center());
            cam3D.updateProjectionMatrix();
            cam2D.position.copy(bb.center().add(cV(0, 10, 0)));
            cam2D.lookAt(bb.center());
            cam2D.updateProjectionMatrix();
            controls.target.copy(bb.center());
            controls.update();
        }

        /**
         * Zoom to a target in the 2D view
         * @param {number} x is the axle
         * @param {number} y is the axle
         * @param {object} target is the object to be zoom on.
         */
        function zoomIn2D(x, y, target) {
            var min0 = Math.min(activeCamera.top, activeCamera.right);
            min0 *= 2;
            var max0 = Math.max(x, y);
            var z0 = min0 / max0;
            activeCamera.zoom = z0;
            activeCamera.position.set(target.x, activeCamera.position.y, target.z);
            activeCamera.lookAt(target);
            activeCamera.updateProjectionMatrix();
        }

        /**
         * Get data for a room
         * @param {string} strMac is the MAC as string
         * @param {number} roomId is the id of the room
         * @returns {*} a promise
         */
        function getRoomData(strMac, roomId) {
            var promise = $q.defer();
            $http
                .get("api/sdcim/getSdcminAppJson/" + strMac).then(function (response) {
                var roomData = response.data;
                if (roomData.Room != null) {
                    promise.resolve(roomData.Room[roomId]);
                } else {
                    $log.info('No Room data provided. Please check connection to SDCIM.');
                    promise.reject();
                }
            }, function (err) {
                $log.error(err);
                promise.reject();
            });
            return promise.promise;
        }

        /**
         * Draw the current scene
         */
        function draw() {
            renderer.render(scene, activeCamera);
        }

        /**
         * Checks the order of the polygon
         * @param {array} pts is the array with data to check if the polygon is in the right order
         * @returns {number} a counter to check if it's in order or not
         */
        function checkPolygonOrder(pts) {
            var i, j, k;
            var count = 0;
            var r = 0.0;
            if (pts.length < 3) {
                return 0;
            }
            for (i = 0; i < pts.length; i++) {
                pts.z *= -1;
                j = (i + 1) % pts.length;
                k = (i + 2) % pts.length;
                r = (pts[j].x - pts[i].x) * (pts[k].z - pts[j].z);
                r -= (pts[j].z - pts[i].z) * (pts[k].x - pts[j].x);
                if (r < 0) {
                    count--;
                } else if (r > 0) {
                    count++;
                }
            }
            if (count > 0) {
                return 1;
            } else if (count < 0) {
                return -1;
            } else {
                return 0;
            }
        }

        /**
         * No functionality
         * @param callback is a callback
         */
        function drawRoom(callback) {
            if (!modelsReady || !texturesReady) {
                window.setTimeout(function () {
                    drawRoom(callback);
                }, 250);
                return;
            }
            horizontalTransPlanes = [];
            var objectRoom = new THREE.Object3D();
            objectRoom.name = "roomContainer";
            var pts = [];
            for (var i in roomDataJson.Corner) {
                pts.push(new THREE.Vector3(roomDataJson.Corner[i].X * FAC, 0, roomDataJson.Corner[i].Z * FAC));
            }
            roomDataJson.bb = new THREE.Box3().setFromPoints(pts);
            roomDataJson.pts = pts;
            if (checkPolygonOrder(roomDataJson.pts) != 1) roomDataJson.pts.reverse();
            roomDataJson.doors = [];
            roomDataJson.windows = [];
            buildRoomObjects(objectRoom, pts);
            buildWalls(pts, objectRoom);
            if (!roomDataJson.hasRaisedFloor) {
                buildStdFloor(objectRoom, pts);
            }
            buildRacks(objectRoom);
            buildCoolingUnits(objectRoom);
            buildAssets(objectRoom);
            buildSensors(objectRoom);
            callback(objectRoom, pts);
        }

        /**
         * Build walls
         * @param {array} pts is array with coordinates
         * @param {object} obj is a room object
         */
        function buildWalls(pts, obj) {
            var walls = [];
            var wallMaterial = new THREE.MeshPhongMaterial({map: assetData.textures.wallDiff.tex});
            var w = null;
            var h = roomDataJson.Size.Y * FAC;
            for (var i = 0; i < pts.length - 1; i++) {
                w = buildWall(pts[i], pts[i + 1], h, wallMaterial);
                walls.push(w);
                obj.add(w);
            }
            w = buildWall(pts[pts.length - 1], pts[0], h, wallMaterial);
            walls.push(w);
            obj.add(w);
            roomDataJson.walls = [];
            modWalls(walls);
            cutWalls(walls, obj);
            roomDataJson.walls = walls;
        }

        /**
         * Helper function of {@link buildWalls} to build a wall
         * @param {obj} s
         * @param e
         * @param h
         * @param mat
         * @returns {THREE.Mesh}
         */
        function buildWall(s, e, h, mat) {
            var dir = e.clone().sub(s);
            var angle = dir.clone().normalize().angleTo(new THREE.Vector3(1, 0, 0));
            var perp = rotateVecY(dir.clone().normalize(), Math.PI / -2).normalize();
            var w = new THREE.Mesh(new THREE.BoxGeometry(dir.length(), h, 100 * FAC, 1, 1, 1), mat.clone());
            w.position.copy(s.clone().add(dir.clone().multiplyScalar(0.5)));
            w.position.y = h / 2;
            w.position.sub(perp.multiplyScalar(50 * FAC));
            if (dir.z > 0) {
                angle *= -1;
            }
            w.rotation.y = angle;
            w.userData.s = s.clone();
            w.userData.e = e.clone();
            w.userData.normal = perp.clone().normalize();
            w.name = "wall";
            return w;
        }

        /**
         * Modifies walls
         * @param {array} wallArray
         */
        function modWalls(wallArray) {
            for (var i = 0; i < wallArray.length; i++) {
                var next, prev;
                if (i === 0) {
                    prev = wallArray[wallArray.length - 1];
                } else {
                    prev = wallArray[i - 1];
                }
                if (i === wallArray.length - 1) {
                    next = wallArray[0];
                } else {
                    next = wallArray[i + 1];
                }
                modWallGeo(wallArray[i], prev, next);
            }
        }

        /**
         * Modify the wall geometry
         * @param wall is the wall object to modify
         * @param prev is previous state
         * @param next is next state
         */
        function modWallGeo(wall, prev, next) {
            wall.updateMatrixWorld();
            prev.updateMatrixWorld();
            next.updateMatrixWorld();
            var topLeftOuterPos = wall.geometry.vertices[4].clone().applyMatrix4(wall.matrixWorld);
            var topLeftPos2D = cV2(topLeftOuterPos.x, topLeftOuterPos.z);
            var topRightOuterPos = wall.geometry.vertices[1].clone().applyMatrix4(wall.matrixWorld);
            var topRightPos2D = cV2(topRightOuterPos.x, topRightOuterPos.z);
            var dirWall = topRightOuterPos.clone().sub(topLeftOuterPos).normalize();
            var prevPosLeft = prev.geometry.vertices[4].clone().applyMatrix4(prev.matrixWorld);
            var prevPosRight = prev.geometry.vertices[1].clone().applyMatrix4(prev.matrixWorld);
            var prevDir = prevPosLeft.clone().sub(prevPosRight).normalize();
            var nextPosLeft = next.geometry.vertices[4].clone().applyMatrix4(next.matrixWorld);
            var nextPosRight = next.geometry.vertices[1].clone().applyMatrix4(next.matrixWorld);
            var nextDir = nextPosRight.clone().sub(nextPosLeft).normalize();
            var prevIntersect = intersectLineLine(topLeftPos2D, cV2(topLeftPos2D.x + dirWall.x, topLeftPos2D.y + dirWall.z), cV2(prevPosRight.x, prevPosRight.z), cV2(prevPosRight.x + prevDir.x, prevPosRight.z + prevDir.z));
            var nextIntersect = intersectLineLine(topRightPos2D, cV2(topRightPos2D.x + dirWall.x, topRightPos2D.y + dirWall.z), cV2(nextPosLeft.x, nextPosLeft.z), cV2(nextPosLeft.x + nextDir.x, nextPosLeft.z + nextDir.z));
            if (prevIntersect == null || nextIntersect == null) {
                return;
            }
            var t0 = new THREE.Vector3(nextIntersect[0], topRightOuterPos.y, nextIntersect[1]);
            var t1 = new THREE.Vector3(prevIntersect[0], topLeftOuterPos.y, prevIntersect[1]);
            var m1 = new THREE.Matrix4().getInverse(wall.matrixWorld);
            t0.applyMatrix4(m1);
            t1.applyMatrix4(m1);
            wall.geometry.vertices[1].z = t0.z;
            wall.geometry.vertices[3].x = t0.x;
            wall.geometry.vertices[3].z = t0.z;
            wall.geometry.vertices[1].x = t0.x;
            wall.geometry.vertices[4].x = t1.x;
            wall.geometry.vertices[4].z = t1.z;
            wall.geometry.vertices[6].x = t1.x;
            wall.geometry.vertices[6].z = t1.z;
            wall.geometry.verticesNeedUpdate = true;
            wall.updateMatrixWorld();
        }

        /**
         * Cut a wall
         * @param {array} wallArray is the array of all walls
         * @param {object} obj is the object to cut in
         */
        function cutWalls(wallArray, obj) {
            for (var i = 0; i < wallArray.length; i++) {
                var ds = getObjectsForWall(wallArray[i], roomDataJson.doors);
                var ws = getObjectsForWall(wallArray[i], roomDataJson.windows);
                if (ds.length > 0 || ws.length > 0) {
                    var mesh = cutWall(wallArray[i], ds, ws);
                    mesh.geometry.uvsNeedUpdate = true;
                    obj.remove(wallArray[i]);
                    mesh.userData.windows = ws;
                    mesh.userData.doors = ds;
                    obj.add(mesh);
                    mesh.name = "wallCut";
                    wallArray[i] = mesh;
                }
            }
        }

        /**
         * Gets all objects that are located on the wall
         * @param {object} wall is the wall object
         * @param {array} objectArray is an array with all doors/windows
         * @returns {Array} with all objects located on the wall
         */
        function getObjectsForWall(wall, objectArray) {
            var ret = [];
            var wallLine = new THREE.Line3(wall.userData.s, wall.userData.e);
            for (var i = 0; i < objectArray.length; i++) {
                var p0 = cV(objectArray[i].Position.X * FAC, 0, objectArray[i].Position.Z * FAC);
                var p1 = wallLine.closestPointToPoint(p0);
                var d = p1.distanceTo(p0);
                if (d < 0.05) {
                    ret.push(objectArray[i]);
                }
            }
            return ret;
        }

        /**
         * Cut one wall
         * @param {object} wall is the wall
         * @param {array} ds is the array of doors
         * @param {array} ws is the array of windows
         */
        function cutWall(wall, ds, ws) {
            var invW = new THREE.Matrix4().getInverse(wall.matrixWorld);
            var wallGeo = wall.geometry.clone();
            var m = new THREE.Mesh(wallGeo, new THREE.MeshBasicMaterial({color: 0xff0000}));
            var wallcsg = new ThreeBSP(m);
            var objMat = new THREE.MeshBasicMaterial({color: 0x00ff00});
            for (var i = 0; i < ds.length; i++) {
                var geo = new THREE.BoxGeometry(ds[i].Size.X * FAC, ds[i].Size.Y * FAC, 2000 * FAC);
                var m0 = new THREE.Mesh(geo, objMat);
                m0.position.set(ds[i].Position.X * FAC, ds[i].Position.Y * FAC, ds[i].Position.Z * FAC).applyMatrix4(invW);
                var csg = new ThreeBSP(m0);

                wallcsg = wallcsg.subtract(csg);
            }
            for (var i = 0; i < ws.length; i++) {
                var geo = new THREE.BoxGeometry(ws[i].Size.X * FAC, ws[i].Size.Y * FAC, 2000 * FAC);
                var m0 = new THREE.Mesh(geo, objMat);
                m0.position.set(ws[i].Position.X * FAC, ws[i].Position.Y * FAC, ws[i].Position.Z * FAC).applyMatrix4(invW);
                var csg = new ThreeBSP(m0);

                wallcsg = wallcsg.subtract(csg);
            }
            var mesh = wallcsg.toMesh(wall.material.clone());
            mesh.geometry.computeVertexNormals();
            setUvs(mesh.geometry, wall.userData.e.clone().sub(wall.userData.s).length() + 200 * FAC, roomDataJson.Size.Y * FAC, 100 * FAC);
            mesh.geometry.verticesNeedUpdate = true;
            mesh.geometry.normalsNeedUpdate = true;
            mesh.geometry.elementsNeedUpdate = true;
            mesh.position.copy(wall.position);
            mesh.rotation.y = wall.rotation.y;
            mesh.userData.s = wall.userData.s.clone();
            mesh.userData.e = wall.userData.e.clone();
            mesh.userData.normal = wall.userData.normal.clone();
            return mesh;
        }

        /**
         * Set UVs
         * @param {object} geo is the variable vor geometric data
         * @param {number} len is the length for
         * @param {number} height is the variable for height
         * @param {number} depth is the variable for depth
         */
        function setUvs(geo, len, height, depth) {
            var hx = len / 2;
            var hy = height / 2;
            var hz = depth / 2;
            var epsilon = 0.15;
            for (var i = 0; i < geo.faces.length; i++) {
                var a = geo.vertices[geo.faces[i].a];
                var uva = getUV4Pos(a, hx, hy, hz, geo.faces[i].normal, epsilon);
                var b = geo.vertices[geo.faces[i].b];
                var uvb = getUV4Pos(b, hx, hy, hz, geo.faces[i].normal, epsilon);
                var c = geo.vertices[geo.faces[i].c];
                var uvc = getUV4Pos(c, hx, hy, hz, geo.faces[i].normal, epsilon);
                geo.faceVertexUvs[0][i] = [];
                geo.faceVertexUvs[0][i][0] = uva;
                geo.faceVertexUvs[0][i][1] = uvb;
                geo.faceVertexUvs[0][i][2] = uvc;
            }
        }

        /**
         * Helper function
         * @param p is a parameter
         * @param hx is a parameter
         * @param hy is a parameter
         * @param hz is a parameter
         * @param normal is a parameter
         * @param epsilon is a parameter
         * @param offset is a parameter
         * @returns {THREE.Vector2} a vector
         */
        function getUV4Pos(p, hx, hy, hz, normal, epsilon, offset) {
            var n = normal.clone().normalize();
            var off = cV(0, 0, 0);
            if (offset) {
                off.copy(offset);
            }
            if (((p.x - hx) < epsilon) && ((p.y - hy) < epsilon) && ((p.z - hz) < epsilon)) {
                var uv = cV2(0.0, 0.0);
                if (n.y == 0 || Math.abs(n.y) < epsilon) {
                    var x0 = p.x + hx;
                    var y0 = p.y + hy;
                    uv.x = x0 / (hx * 2);
                    uv.y = y0 / (hy * 2);
                } else {
                    if (n.x != 0 || n.z != 0) {
                        // console.log("Normal: "+n.x+":"+n.y+":"+n.z);
                    }
                    uv.x = 0.98;
                    uv.y = 0.98;
                }
                return uv;
            } else {
                // console.log("error compute uv");
            }
        }

        /**
         * Build a standard floor
         * @param {object} objectRoom is the room object
         * @param {array} pts is for the build of a shape
         */
        function buildStdFloor(objectRoom, pts) {
            var pts2d = [];
            for (var i = 0; i < pts.length; i++) {
                pts2d.push(cV2(pts[i].x, pts[i].z));
            }
            var shape = new THREE.Shape(pts2d);
            var shape3D = shape.extrude({amount: 100 * FAC, bevelEnabled: false});
            assetData.textures.floorDiff.tex.wrapS = assetData.textures.floorDiff.tex.wrapT = THREE.RepeatWrapping;
            assetData.textures.floorDiff.tex.repeat.set(1, 1);
            var floorMaterial = new THREE.MeshPhongMaterial({wireframe: false, map: assetData.textures.floorDiff.tex});
            var meshFloor = new THREE.Mesh(shape3D, floorMaterial);
            meshFloor.rotation.x = Math.PI / 2;
            meshFloor.geometry.computeFaceNormals();
            meshFloor.geometry.computeVertexNormals();
            meshFloor.geometry.elementsNeedUpdate = true;
            objectRoom.add(meshFloor);
        }

        /**
         * Build and add all room objects
         * @param {object} objectRoom is the room object
         * @param {array} pts is for the build of a shape
         */
        function buildRoomObjects(objectRoom, pts) {
            for (var i in roomDataJson.RoomObj) {
                if (roomDataJson.RoomObj[i].Type >= 12000 && roomDataJson.RoomObj[i].Type < 13000) buildRaisedFloor(roomDataJson.RoomObj[i], objectRoom, pts);
            }
            for (var i in roomDataJson.RoomObj) {
                if (roomDataJson.RoomObj[i].Type >= 0 && roomDataJson.RoomObj[i].Type < 1000) buildDoor(roomDataJson.RoomObj[i], objectRoom);
                if (roomDataJson.RoomObj[i].Type >= 1000 && roomDataJson.RoomObj[i].Type < 2000) buildWindow(roomDataJson.RoomObj[i], objectRoom);
                if (roomDataJson.RoomObj[i].Type >= 2000 && roomDataJson.RoomObj[i].Type < 3000) buildPillar(roomDataJson.RoomObj[i], objectRoom);
                if (roomDataJson.RoomObj[i].Type >= 3000 && roomDataJson.RoomObj[i].Type < 4000) buildFloorVent(roomDataJson.RoomObj[i], objectRoom, pts);
                if (roomDataJson.RoomObj[i].Type >= 4000 && roomDataJson.RoomObj[i].Type < 5000) buildVentDuct(roomDataJson.RoomObj[i]);
                if (roomDataJson.RoomObj[i].Type >= 10000 && roomDataJson.RoomObj[i].Type < 11000) buildContainment(roomDataJson.RoomObj[i], objectRoom);
                if (roomDataJson.RoomObj[i].Type >= 14000 && roomDataJson.RoomObj[i].Type < 15000) buildDesk(roomDataJson.RoomObj[i], objectRoom);
                if (roomDataJson.RoomObj[i].Type >= 15000 && roomDataJson.RoomObj[i].Type < 16000) buildChair(roomDataJson.RoomObj[i], objectRoom);
            }
        }

        /**
         * Builds a door
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildDoor(objInfo, objectRoom) {
            var geo = assetData.models.doorStd.geo;
            var mat = new THREE.MeshPhongMaterial({
                map: assetData.textures.doorDiff.tex,
                normalMap: assetData.textures.doorNormal.tex
            });
            var m = new THREE.Mesh(geo, mat);
            m.scale.set((objInfo.Size.X * FAC) / 1, (objInfo.Size.Y * FAC) / 1, (objInfo.Size.Z * FAC) / 1);
            m.rotation.y = deg2Rad(objInfo.Rotation.Y * 0.001);
            m.position.set(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            objectRoom.add(m);
            objInfo.mesh = m;
            roomDataJson.doors.push(objInfo);
        }

        /**
         * Builds a window
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildWindow(objInfo, objectRoom) {
            var mat = new THREE.MeshPhongMaterial({
                map: assetData.textures.windowDiff.tex,
                normalMap: assetData.textures.doorNormal.tex
            });
            var geo = assetData.models.windowFrame.geo;
            var m = new THREE.Mesh(geo, mat);
            m.scale.set((objInfo.Size.X * FAC) / (1000 * FAC), (objInfo.Size.Y * FAC) / (1000 * FAC), (objInfo.Size.Z * FAC) / 1);
            m.rotation.y = deg2Rad(objInfo.Rotation.Y * FAC);
            m.position.set(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            m.name = "windowFrame";
            var glass = new THREE.Mesh(new THREE.PlaneBufferGeometry(objInfo.Size.X * FAC, objInfo.Size.Y * FAC, objInfo.Size.Z * FAC), new THREE.MeshPhongMaterial({
                transparent: true,
                opacity: 0.2,
                color: 0x0000dd
            }));
            glass.rotation.y = m.rotation.y;
            glass.position.copy(m.position);
            glass.name = "windowGlass";
            objectRoom.add(glass);
            objectRoom.add(m);
            objInfo.mesh = m;
            objInfo.glass = glass;
            roomDataJson.windows.push(objInfo);
        }

        /**
         * Builds a pillar
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildPillar(objInfo, objectRoom) {
            var geo = new THREE.BoxBufferGeometry(objInfo.Size.X * FAC, objInfo.Size.Y * FAC, objInfo.Size.Z * FAC);
            var mat = new THREE.MeshPhongMaterial({map: assetData.textures.wallDiff.tex});
            var pillarMesh = new THREE.Mesh(geo, mat);
            pillarMesh.position.set(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            pillarMesh.rotation.y = -deg2Rad(objInfo.Rotation.Y * FAC);
            objectRoom.add(pillarMesh);
        }

        /**
         * Builds a floor
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildFloorVent(objInfo, objectRoom, pts) {
            if (!roomDataJson.hasRaisedFloor) {
                return;
            }
            var bb = roomDataJson.bb;
            var geoD = new THREE.BoxGeometry(100000 * FAC, roomDataJson.floorPanelHeight * FAC, 100000 * FAC);
            var matD = new THREE.MeshLambertMaterial({color: 0xff0000, wireframe: true});
            var d = new THREE.Mesh(geoD, matD);
            d.visible = false;
            d.position.copy(bb.center());
            d.position.y = roomDataJson.floorPanelHeight * FAC * 0.5;
            d.updateMatrixWorld();
            d.geometry.computeVertexNormals();
            d.geometry.computeFaceNormals();
            d.geometry.verticesNeedUpdate = true;
            d.geometry.uvsNeedUpdate = true;
            d.geometry.normalsNeedUpdate = true;
            d.geometry.morphTargetsNeedUpdate = true;
            d.geometry.elementsNeedUpdate = true;
            objectRoom.add(d);
            var p = cV(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            var r = cV(Math.PI / 2, -deg2Rad(objInfo.Rotation.Y * FAC), 0);
            var s = cV((objInfo.Size.X * FAC) / (1000 * FAC), (objInfo.Size.Z * FAC) / (1000 * FAC), 1);
            var geo = new THREE.DecalGeometry(d, p, r, s, cV(1, 1, 1));
            var mat = new THREE.MeshPhongMaterial({
                map: assetData.textures.tileDiff.tex,
                normalMap: assetData.textures.tileNormal.tex,
                transparent: false,
                normalScale: cV2(.15, .15),
                polygonOffset: true,
                polygonOffsetFactor: -4,
                depthTest: true,
                depthWrite: true
            });
            var mesh = new THREE.Mesh(geo, mat);
            objectRoom.add(mesh);
        }

        function buildVentDuct(objInfo) {

        }

        /**
         * Builds a containment
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildContainment(objInfo, objectRoom) {
            var containObject = new THREE.Object3D();
            var matDoor = new THREE.MeshPhongMaterial({
                map: assetData.textures.doorDiff.tex,
                normalMap: assetData.textures.doorNormal.tex
            });
            var geoDoor = assetData.models.doorSlide.geo;
            var sx = (objInfo.Size.X * FAC);
            var sy = (objInfo.Size.Y * FAC);

            var meshF = new THREE.Mesh(geoDoor, matDoor);
            meshF.scale.set(sx, sy, 0.1);
            meshF.rotation.y = Math.PI;
            meshF.position.set(0, 0, (objInfo.Size.Z * FAC) / 2 - (50 * FAC));
            var meshB = new THREE.Mesh(geoDoor, matDoor);
            meshB.scale.set(sx, sy, 0.1);
            meshB.rotation.y = -Math.PI;
            meshB.position.set(0, 0, (objInfo.Size.Z * FAC) / -2 + (50 * FAC));
            containObject.add(meshF);
            containObject.add(meshB);
            containObject.position.set(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            containObject.rotation.y = -deg2Rad(objInfo.Rotation.Y * FAC);
            var glassMat = new THREE.MeshPhongMaterial({transparent: true, opacity: 0.1, color: 0xaaaaff});
            var meshTop = new THREE.Mesh(new THREE.PlaneBufferGeometry(objInfo.Size.X * FAC, objInfo.Size.Z * FAC), glassMat);
            meshTop.position.y = (objInfo.Size.Y * FAC) / 2;
            meshTop.rotation.x = Math.PI / -2;
            horizontalTransPlanes.push(meshTop);
            containObject.add(meshTop);
            var geoSide = new THREE.PlaneBufferGeometry((objInfo.Size.Z * FAC) - (250 * FAC), objInfo.Size.Y * FAC);
            var meshR = new THREE.Mesh(geoSide, glassMat);
            var meshL = new THREE.Mesh(geoSide, glassMat);
            meshR.position.x = objInfo.Size.X * FAC / -2;
            meshR.rotation.y = Math.PI / -2;
            meshL.position.x = objInfo.Size.X * FAC / 2;
            meshL.rotation.y = Math.PI / 2;
            containObject.add(meshR);
            containObject.add(meshL);
            var matBrace = new THREE.MeshPhongMaterial({color: 0xdddddd});
            var geoBrace = new THREE.BoxBufferGeometry(objInfo.Size.X * FAC, 40 * FAC, 10 * FAC);
            var meshFB = new THREE.Mesh(geoBrace, matBrace);
            var meshBB = new THREE.Mesh(geoBrace, matBrace);
            meshFB.position.set(0, objInfo.Size.Y * FAC / 2, objInfo.Size.Z * FAC / 2);
            meshBB.position.set(0, objInfo.Size.Y * FAC / 2, objInfo.Size.Z * FAC / -2);
            containObject.add(meshFB);
            containObject.add(meshBB);
            var countBrace = Math.floor(objInfo.Size.Z * FAC);
            var gap = objInfo.Size.Z * FAC / (countBrace - 1);
            for (var i = 1; i < (countBrace - (1000 * FAC)); i++) {
                var mb = meshFB.clone();
                mb.position.z = meshFB.position.z - i * gap;
                containObject.add(mb);
            }
            objectRoom.add(containObject);
        }

        /**
         * Builds a raised floor
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildRaisedFloor(objInfo, objectRoom, pts) {
            roomDataJson.hasRaisedFloor = true;
            roomDataJson.floorPanelHeight = objInfo.Size.Y;
            var bbox = roomDataJson.bb;
            var calcCustomOffset = function (offX, offZ) {
                var xOff = (Math.abs(bbox.min.x)) / (objInfo.Size.X * FAC);
                var zOff = (Math.abs(bbox.min.z)) / (objInfo.Size.Z * FAC);
                xOff -= offX / (objInfo.Size.X * FAC);
                zOff -= offZ / (objInfo.Size.Z * FAC);
                xOff -= Math.floor(xOff);
                zOff -= Math.floor(zOff);
                return [xOff, zOff];
            }
            var calcStdOffset = function () {
                var xOff = Math.abs(bbox.min.x) / (objInfo.Size.X * FAC);
                var zOff = Math.abs(bbox.min.z) / (objInfo.Size.Z * FAC);
                xOff -= Math.floor(xOff);
                zOff -= Math.floor(zOff);
                return [xOff, zOff];
            }
            var pts2d = [];
            for (var i = 0; i < pts.length; i++) {
                pts2d.push(cV2(pts[i].x, pts[i].z));
            }
            var shape = new THREE.Shape(pts2d);
            var shape3d = shape.extrude({amount: objInfo.Size.Y * FAC, bevelEnabled: false});
            var offset;
            if (objInfo.Position.Y === 1000) {
                offset = calcCustomOffset(objInfo.Position.X * FAC, objInfo.Position.Z * FAC);
            } else {
                offset = calcStdOffset();
            }
            var rfMaterial = new THREE.MeshPhongMaterial({shininess: 1, map: assetData.textures.floorDiff.tex});
            assetData.textures.floorDiff.tex.wrapS = assetData.textures.floorDiff.tex.wrapT = THREE.RepeatWrapping;
            assetData.textures.floorDiff.tex.repeat.set(1 / (objInfo.Size.X * FAC), 1 / (objInfo.Size.Z * FAC));
            assetData.textures.floorDiff.tex.offset.set(offset[0], offset[1]);

            var rfMesh = new THREE.Mesh(shape3d, rfMaterial);
            rfMesh.rotation.x = Math.PI / 2;
            rfMesh.geometry.computeFaceNormals();
            rfMesh.geometry.computeVertexNormals();
            rfMesh.elementsNeedUpdate = true;
            rfMesh.position.y = objInfo.Size.Y * FAC;
            objectRoom.add(rfMesh);
            raisedFloor = rfMesh;
        }

        /**
         * Builds a desk
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildDesk(objInfo, objectRoom) {
            var geo = assetData.models.desk.geo;
            var mat = new THREE.MeshPhongMaterial({
                map: assetData.textures.deskDiff.tex,
                normalMap: assetData.textures.deskNormal.tex
            });
            var m = new THREE.Mesh(geo, mat);
            m.scale.set((objInfo.Size.X * FAC) / 1, (objInfo.Size.Y * FAC) / 1, (objInfo.Size.Z * FAC) / 1);
            m.position.set(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            m.rotation.y = -deg2Rad(objInfo.Rotation.Y * FAC);
            objectRoom.add(m);
            objInfo.mesh = m;
        }

        /**
         * Builds a chair
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildChair(objInfo, objectRoom) {
            var geo = assetData.models.chair.geo;
            var mat = new THREE.MeshPhongMaterial({
                map: assetData.textures.chairDiff.tex,
                normalMap: assetData.textures.chairNormal.tex
            });
            var m = new THREE.Mesh(geo, mat);
            m.scale.set((objInfo.Size.X * FAC) / 1, (objInfo.Size.Y * FAC) / 1, (objInfo.Size.Z * FAC) / 1);
            m.position.set(objInfo.Position.X * FAC, objInfo.Position.Y * FAC, objInfo.Position.Z * FAC);
            m.rotation.y = -deg2Rad(objInfo.Rotation.Y * FAC);
            objectRoom.add(m);
            objInfo.mesh = m;
        }

        /**
         * Builds a racks
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildRacks(objectRoom) {
            for (var i in roomDataJson.Rack) {
                var geo = roomDataJson.Rack[i].Type == 0 ? assetData.models.rack.geo : assetData.models.rackD.geo;
                var mat = new THREE.MeshPhongMaterial({
                    map: assetData.textures.rackDiff.tex,
                    normalMap: assetData.textures.rackNormal.tex
                });
                var rackMesh = new THREE.Mesh(geo, mat);
                rackMesh.position.set(roomDataJson.Rack[i].Position.X * FAC, roomDataJson.Rack[i].Position.Y * FAC, roomDataJson.Rack[i].Position.Z * FAC);
                rackMesh.rotation.y = -deg2Rad(roomDataJson.Rack[i].Rotation.Y * FAC);
                rackMesh.scale.set((roomDataJson.Rack[i].Size.X * FAC) / (1000 * FAC), (roomDataJson.Rack[i].Size.Y * FAC) / (1000 * FAC), (roomDataJson.Rack[i].Size.Z * FAC) / (1000 * FAC));
                rackMesh.userData.type = "3";
                rackMesh.userData.id = i.toString();
                objectRoom.add(rackMesh);
            }
        }

        /**
         * Builds a cooling unit
         * @param {object} objInfo is the object with some 3D context
         * @param {object} objectRoom is the room object
         */
        function buildCoolingUnits(objectRoom) {
            for (var i in roomDataJson.Cooling) {
                var isStulz = isNaN(roomDataJson.Cooling[i].Type);
                var geo = getCoolingModel(isStulz, roomDataJson.Cooling[i].Type, roomDataJson.Cooling[i].Size.X * FAC);
                var tex = getCoolingTex(isStulz, roomDataJson.Cooling[i].Type);
                var mat = new THREE.MeshPhongMaterial({map: tex[0], normalMap: tex[1]});
                var mesh = new THREE.Mesh(geo, mat);
                mesh.scale.set((roomDataJson.Cooling[i].Size.X * FAC) / (1000 * FAC), (roomDataJson.Cooling[i].Size.Y * FAC) / (1000 * FAC), (roomDataJson.Cooling[i].Size.Z * FAC) / (1000 * FAC));
                mesh.position.set(roomDataJson.Cooling[i].Position.X * FAC, roomDataJson.Cooling[i].Position.Y * FAC, roomDataJson.Cooling[i].Position.Z * FAC);
                mesh.rotation.y = -deg2Rad(roomDataJson.Cooling[i].Rotation.Y * FAC);
                if (isStulz && checkForBottomVentsByType(roomDataJson.Cooling[i].Type)) {
                    var aboveFloor = roomDataJson.Cooling[i].Type.indexOf("E") !== -1 ? true : false;
                    var geoBot = assetData.models.coolingBot.geo;
                    var matBot = new THREE.MeshPhongMaterial({
                        map: assetData.textures.coolingBotDiff.tex,
                        normalMap: assetData.textures.coolingBotNormal.tex
                    });
                    var meshBot = new THREE.Mesh(geoBot, matBot);
                    meshBot.scale.set((roomDataJson.Cooling[i].Size.X * FAC) / (1000 * FAC), 0.5, (roomDataJson.Cooling[i].Size.Z * FAC) / (1000 * FAC));
                    meshBot.position.set(roomDataJson.Cooling[i].Position.X * FAC, 0, roomDataJson.Cooling[i].Position.Z * FAC);
                    meshBot.position.y = ((roomDataJson.Cooling[i].Position.Y * FAC) * -0.5) - (250 * FAC);
                    objectRoom.add(meshBot);
                }
                mesh.userData.type = "4";
                mesh.userData.id = i;
                objectRoom.add(mesh);
            }
        }

        /**
         * Returns the cooling unit
         * @param {boolean} isStulz is a bool to check if the cooling unit is STULZ product
         * @param {string} typeString is a string with a type
         * @param {number} width defines the width of the object
         * @returns {*} a cooling unit
         */
        function getCoolingModel(isStulz, typeString, width) {
            function getCoolingModelNonStulz() {
                if (width < 1500 * FAC) return assetData.models.cooling1.geo;
                if (width < 2000 * FAC) return assetData.models.cooling2.geo;
                if (width < 3000 * FAC) return assetData.models.cooling3.geo;
                if (width > 4000 * FAC) return assetData.models.cooling4.geo;
            }

            if (isStulz) {
                if (typeString.substring(0, 1) == "A") {
                    return getCoolingModelNonStulz();
                } else if (typeString.substring(0, 2) == "CR") {
                    return assetData.models.coolingRow.geo;
                } else {
                    return getCoolingModelNonStulz();
                }
            } else {
                return getCoolingModelNonStulz();
            }
        }

        /**
         * Get the text from a cooling unit
         * @param {boolean} isStulz is a bool to check if the cooling unit is STULZ product
         * @param {string} typeString is a string with a type
         * @returns {string} the text from the cooling
         */
        function getCoolingTex(isStulz, typeString) {
            if (isStulz) {
                if (typeString.substring(0, 1) == "A") {
                    return [assetData.textures.coolingStulzDiff.tex, assetData.textures.coolingNormal.tex];
                } else if (typeString.substring(0, 2) == "CR") {
                    return [assetData.textures.coolingRowDiff.tex, assetData.textures.coolingRowNormal.tex];
                } else {
                    return [assetData.textures.coolingStulzDiff.tex, assetData.textures.coolingNormal.tex];
                }
            } else {
                return [assetData.textures.coolingStdDiff.tex, assetData.textures.coolingNormal.tex];
            }
        }

        /**
         * Checks the bottom vents by type
         * @param {string} typeString is a string with a type
         * @returns {boolean} a boolean
         */
        function checkForBottomVentsByType(typeString) {
            var ret = true;
            if (typeof typeString == "string") {
                var idxCW = typeString.indexOf("CW");
                var idxE = typeString.indexOf("E");
                var idxU = typeString.indexOf("U");
                if (idxCW !== -1 && ((idxU !== -1 && idxU > idxCW) || (idxE !== -1 && idxE > idxCW))) {
                    ret = true;
                } else {
                    ret = false;
                }
            } else {
                ret = false;
            }
            return ret;
        }

        /**
         * Builds a assets
         * @param {object} objectRoom is the room object
         */
        function buildAssets(objectRoom) {
            var geo = new THREE.BoxBufferGeometry(1, 1, 1);
            var mat = new THREE.MeshPhongMaterial({color: 0xaaaaaa});
            for (var i in roomDataJson.Asset) {
                var m = new THREE.Mesh(geo, mat);
                m.scale.set((roomDataJson.Asset[i].Size.X * FAC) / (1000 * FAC), (roomDataJson.Asset[i].Size.Y * FAC) / (1000 * FAC), (roomDataJson.Asset[i].Size.Z * FAC) / (1000 * FAC));
                m.position.set(roomDataJson.Asset[i].Position.X * FAC, roomDataJson.Asset[i].Position.Y * FAC, roomDataJson.Asset[i].Position.Z * FAC);
                m.rotation.y = -deg2Rad(roomDataJson.Asset[i].Rotation.Y * FAC);
                m.userData.type = "8";
                m.userData.id = i.toString();
                objectRoom.add(m);
            }
        }

        /**
         * Builds a sensor
         * @param {object} objectRoom is the room object
         */
        function buildSensors(objectRoom) {
            var geo = new THREE.SphereBufferGeometry(0.1, 16, 16, 0, Math.PI * 2, 0, Math.PI * 2);
            var mat = new THREE.MeshPhongMaterial({color: 0xffff00});
            var mesh = new THREE.Mesh(geo, mat);
            for (var i in roomDataJson.Sensor) {
                var cm = mesh.clone();
                cm.position.set(roomDataJson.Sensor[i].Position.X * FAC, roomDataJson.Sensor[i].Position.Y * FAC, roomDataJson.Sensor[i].Position.Z * FAC);
                cm.userData.type = "7";
                cm.userData.id = i.toString();
                objectRoom.add(cm);
            }
        }

        /**
         * Helper function to hide walls
         */
        function hideWalls() {
            for (var i = 0; i < roomDataJson.walls.length; i++) {
                var c = roomDataJson.bb.center().add(cV(0, activeCamera.position.y, 0));
                var camDir = c.sub(activeCamera.position);
                var wallNormal = roomDataJson.walls[i].userData.normal.clone();
                var a = camDir.angleTo(wallNormal);

                if (a < Math.PI / 2) {
                    roomDataJson.walls[i].material.transparent = true;
                    roomDataJson.walls[i].material.opacity = 0.2;
                    if (roomDataJson.walls[i].userData.hasOwnProperty("doors") && roomDataJson.walls[i].userData.doors.length > 0) {
                        for (var j = 0; j < roomDataJson.walls[i].userData.doors.length; j++) {
                            roomDataJson.walls[i].userData.doors[j].mesh.material.transparent = true;
                            roomDataJson.walls[i].userData.doors[j].mesh.material.opacity = 0.2;
                        }
                    }
                    if (roomDataJson.walls[i].userData.hasOwnProperty("windows") && roomDataJson.walls[i].userData.windows.length > 0) {
                        for (var j = 0; j < roomDataJson.walls[i].userData.windows.length; j++) {
                            roomDataJson.walls[i].userData.windows[j].mesh.transparent = true;
                            roomDataJson.walls[i].userData.windows[j].mesh.opacity = 0.2;
                        }
                    }
                } else {
                    roomDataJson.walls[i].material.transparent = false;
                    roomDataJson.walls[i].material.opacity = 1.0;
                    roomDataJson.walls[i].renderOrder = 0;
                    if (roomDataJson.walls[i].userData.hasOwnProperty("doors") && roomDataJson.walls[i].userData.doors.length > 0) {
                        for (var j = 0; j < roomDataJson.walls[i].userData.doors.length; j++) {
                            roomDataJson.walls[i].userData.doors[j].mesh.material.transparent = false;
                            roomDataJson.walls[i].userData.doors[j].mesh.material.opacity = 1.0;
                            roomDataJson.walls[i].userData.doors[j].mesh.renderOrder = 0;
                        }
                    }
                    if (roomDataJson.walls[i].userData.hasOwnProperty("windows") && roomDataJson.walls[i].userData.windows.length > 0) {
                        for (var j = 0; j < roomDataJson.walls[i].userData.windows.length; j++) {
                            roomDataJson.walls[i].userData.windows[j].mesh.transparent = false;
                            roomDataJson.walls[i].userData.windows[j].mesh.material.opacity = 1.0;
                            roomDataJson.walls[i].userData.windows[j].mesh.renderOrder = 0;
                        }
                    }
                }
            }
        }

        /**
         * Helper function to handle resize
         */
        function handleResize() {
            var width = container.width();
            var height = width / 16 * 9;
            cam3D.aspect = width / height;
            cam2D.left = width / -2;
            cam2D.right = width / 2;
            cam2D.top = height / 2;
            cam2D.bottom = height / -2;
            cam2D.updateProjectionMatrix();
            cam3D.updateProjectionMatrix();
            renderer.setSize(width, height);
        }

        /**
         * Helper function to configure lights
         */
        function configureLights() {
            var dirLight = new THREE.DirectionalLight(0xffffff, .6);
            dirLight.position.set(1, 1, 1);
            var ambiLight = new THREE.AmbientLight(0xffffff, .4);
            scene.add(dirLight);
            scene.add(ambiLight);
        }

        /**
         * Helper function for animation
         */
        function animate() {
            animationID = requestAnimationFrame(animate);
            setRenderOrder();
            draw();
        }

        /**
         * Helper function to set render order
         */
        function setRenderOrder() {
            if (heatMapPlane === null) {
                return;
            }
            heatMapPlane.renderOrder = 2;
            for (var i in horizontalTransPlanes) {
                if (horizontalTransPlanes[i].position.y > heatMapPlane.position.y) {
                    horizontalTransPlanes[i].renderOrder = 1;
                } else {
                    horizontalTransPlanes[i].renderOrder = 3;
                }
            }
            for (var i in roomDataJson.walls) {
                if (roomDataJson.walls[i].material.transparent) {
                    roomDataJson.walls[i].renderOrder = 5;
                    for (var j in roomDataJson.walls[i].doors) {
                        roomDataJson.walls[i].doors[j].mesh.renderOrder = 0;
                    }
                    for (var j in roomDataJson.walls[i].windows) {
                        roomDataJson.walls[i].windows[j].mesh.renderOrder = 0;
                        roomDataJson.walls[i].windows[j].glass.renderOrder = 0;
                    }
                }
            }
        }

        /**
         * Helper function
         * @param {number} x is one of the axis
         * @param {number} y is one of the axis
         * @returns {THREE.Vector2} a vector
         */
        function cV2(x, y) {
            return new THREE.Vector2(x, y);
        }

        /**
         * Helper function
         * @param {number} x is one of the axis
         * @param {number} y is one of the axis
         * @param {number} z is one of the axis
         * @returns {THREE.Vector2} a vector
         */
        function cV(x, y, z) {
            return new THREE.Vector3(x, y, z);
        }

        /**
         *
         * @param {THREE.Vector3} vec is the vector that will be modified
         * @param {number} angle is the angle for modification
         * @returns {*} a vector
         */
        function rotateVecY(vec, angle) {
            var mat = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), angle);
            return vec.clone().applyMatrix4(mat);
        }

        /**
         * Helper function
         * @param l1s is a variable
         * @param l1e is a variable
         * @param l2s is a variable
         * @param l2e is a variable
         * @returns {array} an array
         */
        function intersectLineLine(l1s, l1e, l2s, l2e) {
            var denom = (l1s.x - l1e.x) * (l2s.y - l2e.y) - (l1s.y - l1e.y) * (l2s.x - l2e.x);
            if (denom == 0) {
                return null;
            } else {
                var x = (l1s.x * l1e.y - l1s.y * l1e.x) * (l2s.x - l2e.x) - (l1s.x - l1e.x) * (l2s.x * l2e.y - l2s.y * l2e.x);
                var y = (l1s.x * l1e.y - l1s.y * l1e.x) * (l2s.y - l2e.y) - (l1s.y - l1e.y) * (l2s.x * l2e.y - l2s.y * l2e.x);
                x /= denom;
                y /= denom;
                return [x, y];
            }
        }

        /**
         * Helper function
         * @param deg is a variable
         * @returns {number} a radius
         */
        function deg2Rad(deg) {
            return deg * (Math.PI / 180);
        }

        var room = null;
        var roomObj = null;
        var nodeHeatmaps = null;
        var mapOpacity = 0.5;
        var mapPos = 0.5;
        var selectedHeatmap = null;
        /**
         * Set driver values
         * @param sensorData is the data of a sensor
         */
        var setDriverValueValues = function (sensorData) {
            for (var i in sensorData) {
                var sensor = room.sensors.filter(function (s) {
                    return s.uniqueId == i;
                });
                if (sensor.length == 1) {
                    for (var j in sensorData[i]) {
                        var dv = sensor[0].driverValues.filter(function (e) {
                            return e.uniqueId == j
                        });
                        if (dv.length == 1) LiveDataService.getValueForDV(dv[0], sensorData[i][j]);
                    }
                }
            }
        };

        /**
         * Helper function to draw a room node
         * @param room is the room
         * @param callback is a callback function
         */
        var drawRoomNode = function (room, callback) {
            WebGLService.init("threeJsContainer", {
                widthPercent: 100,
                heightPercent: 'adjust',
                addPickplane: false,
                antialias: true,
                pureService: true
            });
            roomObj = Object3DFactory.buildRoom(room, "full", false);
            WebGLService.add3DObjectToContent(roomObj);
            WebGLService.setActiveCameraProps(new THREE.Vector3((room.bbox.max.x - room.bbox.min.x) * -0.75, room.size.y + 10, (room.bbox.max.z - room.bbox.min.z) * 0.75).add(room.bbox.getCenter().setY(0)), room.bbox.getCenter());
            WebGLService.addControls();
            WebGLService.addControlListener("hidewalls", function () {
                RoomEditorService.hideWalls(roomObj, room);
            });
            RoomEditorService.hideWalls(roomObj, room);
            if (callback) callback(nodeHeatmaps);
        };

        /**
         * Helper function to set min and max values at the heat map
         */
        var setupMinMaxValuesForHeatmaps = function () {
            for (var i in nodeHeatmaps) {
                for (var j in nodeHeatmaps[i].heatmapValues) {
                    if (nodeHeatmaps[i].heatmapValues[j].key < nodeHeatmaps[i].minValue) nodeHeatmaps[i].minValue = nodeHeatmaps[i].heatmapValues[j].key;
                    if (nodeHeatmaps[i].heatmapValues[j].key > nodeHeatmaps[i].maxValue) nodeHeatmaps[i].maxValue = nodeHeatmaps[i].heatmapValues[j].key;
                }
            }
        };

        /**
         * Helper function to show the room node
         * @param roomId is the id of the room
         * @param timeStamp is the time stamp to get the alarms
         * @param user is the current user
         * @param callback is a callback function
         * @param requestCanceller is canceller
         */
        var showRoomNode = function (roomId, timeStamp, user, callback, requestCanceller) {
            RoomService.getAlarmInfoData(roomId, timeStamp, requestCanceller).then(function (response) {
                if (!Tools.isDefinedNotNull(response)
                    || !Tools.isDefinedNotNull(response.data)
                    || angular.equals(response.data, {})
                    || angular.equals(response.data.length, 0)
                    || response.data === "") {
                    return;
                }
                if (Tools.isDefinedNotNull(response.data.error)) {
                    if (response.data.error === 0) Notify.warning("alarm.error.noRoomAvailable.title", "alarm.error.noRoomAvailable.message", 5000);
                    if (response.data.error === 1) Notify.warning("alarm.error.roomIsInvalid.title", "alarm.error.roomIsInvalid.message", 5000);
                    return;
                }
                room = Room.parseFromHtmlObject(response.data.roomData);
                setDriverValueValues(response.data.sensorData);
                nodeHeatmaps = response.data.heatmapData;
                setupMinMaxValuesForHeatmaps();
                if (AssetService.isReady()) {
                    if (room !== null) drawRoomNode(room, callback);
                } else {
                    AssetService.loadData(function () {
                        if (room !== null) drawRoomNode(room, callback);
                    });
                }
            });
        };

        /**
         * Helper function to destroy the renderer
         */
        var destroyRenderer = function () {
            WebGLService.removeControlListener("hidewalls");
            WebGLService.destroy();
            room = null;
            roomObj = null;
            showsMap = false;
        };

        /**
         * Helper function to toggle the map node
         */
        var toggleMapNode = function () {
            if (showsMap) {
                showsMap = false;
                HeatMapGLService.removeHeatmapFromRoom();
            } else {
                showsMap = true;
                HeatMapGLService.addHeatmapToRoom(room, roomObj, selectedHeatmap, mapPos, mapOpacity);
                HeatMapGLService.updateValues(room, nodeHeatmaps[0]);
            }
        };

        /**
         * Helper function to set the opacity
         * @param {number} opacity is the opacity
         */
        var setMapTransparencyNode = function (opacity) {
            HeatMapGLService.updateHeatmapTransparency(opacity);
        };

        /**
         * Helper function to set the position
         * @param {number} pos is the position
         */
        var setMapPositionNode = function (pos) {
            HeatMapGLService.updateHeatmapPos(pos);
        };

        /**
         * Helper function to change
         * @param {number} type is the type to change to
         */
        var changeMapTypeNode = function (type) {
            selectedHeatmap = nodeHeatmaps.filter(function (e) {
                return e.id == type;
            });
            if (selectedHeatmap.length == 1) {
                selectedHeatmap = selectedHeatmap[0];
                if (selectedHeatmap.physicalTypeId == 1) colorizeRoom();
            }
            if (showsMap) {
                HeatMapGLService.removeHeatmapFromRoom();
                HeatMapGLService.addHeatmapToRoom(room, roomObj, selectedHeatmap, mapPos, mapOpacity);
            }
        };

        /**
         * Mark an object
         * @param {object} objInfo is the object with the information of the object
         */
        var markAlarmObject = function (objInfo) {
            roomObj.traverse(function (o) {
                if (o instanceof THREE.Mesh) {
                    if (o.name == "sensor" && objInfo.type == "7" && o.userData.id == parseInt(objInfo.id)) {
                        return WebGLService.markAlarmObject(o, undefined, true);
                    }
                    if (o.name == "rack" || o.name == "slot") {
                        if (o.name == "slot" && objInfo.type == "5" && o.parent.parent.userData.id == parseInt(objInfo.id)) {
                            return WebGLService.markAlarmObject(o.parent.parent, undefined, true);
                        }
                        if (objInfo.type == "3" && o.parent.parent.userData.id == parseInt(objInfo.id)) {
                            return WebGLService.markAlarmObject(o.parent.parent, undefined, true);
                        }
                    }
                    if (o.name == "cooling" && objInfo.type == "4" && o.userData.id == parseInt(objInfo.id)) {
                        return WebGLService.markAlarmObject(o, undefined, true);
                    }
                    if (o.name == "asset" && objInfo.type == "8" && o.userData.id == parseInt(objInfo.id)) {
                        return WebGLService.markAlarmObject(o, undefined, true);
                    }
                    if (o.name == "floortile" && objInfo.type == "13" && o.userData.id == parseInt(objInfo.id)) {
                        return WebGLService.markAlarmObject(o, undefined, true);
                    }
                    if (o.name == "ups" && objInfo.type == "14" && o.userData.id == parseInt(objInfo.id)) {
                        return WebGLService.markAlarmObject(o, undefined, true);
                    }
                }
            });
        };

        /**
         * Helper function
         */
        var colorizeRoom = function () {
            RoomService.colorizeRoomTemp(room, roomObj, selectedHeatmap);
        };

        /**
         * Helper function
         */
        var getRoom = function () {
            return room;
        };

        /**
         * Helper function
         */
        var quitAlarm = function (alarmId, message) {
            return $http.get('api/alarm/quit/' + alarmId + "?message=" + message);
        };

        return {
            quitAlarm: quitAlarm,
            draw: draw,
            markObject: markObject,
            toggleMap: toggleMap,
            toggle2D3D: toggle2D3D,
            setMapTransparency: setMapTransparency,
            setMapPosition: setMapPosition,
            changeMapType: changeMapType,
            isWorker1Running: isWorker1Running,
            isWorker2Running: isWorker2Running,
            killRenderer: killRenderer,
            setDriverValueValues: setDriverValueValues,
            showRoomNode: showRoomNode,
            destroyRenderer: destroyRenderer,
            toggleMapNode: toggleMapNode,
            setMapTransparencyNode: setMapTransparencyNode,
            setMapPositionNode: setMapPositionNode,
            changeMapTypeNode: changeMapTypeNode,
            markAlarmObject: markAlarmObject,
            colorizeRoom: colorizeRoom,
            getRoom: getRoom
        };
    });



