'use strict';

/**
 * @description Constructor to create new ups object
 * @param {Number} id the id to set
 * @param {Object} pos 3-dimensional vector object describing objects position
 * @param {Object} size 3-dimensional vector object describing objects size
 * @param {Object} rot 3-dimensional vector object describing objects rotation
 * @param {String} name the name to set
 * @param {String} comment the comment to set
 * @param {String} inventoryNumber the inventory number to set
 * @param {String} serialNumber the serial number to set
 * @param {Number} objectMac the object mac to set (not used)
 * @param {String} url the url to set
 * @param {Number} type the type to set
 * @param {String} floorPanelCustomPos the custom user defined floor position
 * @param {Number} partLibraryUsvId the part library id of the part used to create this object
 * @param {Number} uid the unique id to set
 * @param {Number} pid the parent id to set
 * @constructor
 */
function Ups(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, objectMac, url, type, floorPanelCustomPos, partLibraryUsvId, uid, pid, driverValues) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.objectMac = objectMac !== undefined ? objectMac : "";
    this.url = url !== undefined ? url : "";
    this.type = type !== undefined ? type : 1;
    this.roomId = pid !== undefined ? pid : null;
    this.floorPanelCustomPos = floorPanelCustomPos !== undefined ? floorPanelCustomPos : "";
    this.partLibraryUsvId = partLibraryUsvId !== undefined ? partLibraryUsvId : null;
    // Next all controls for a deep copy of a ups (a copy with all parameters inside of it)
    if (driverValues !==null && driverValues !==undefined && driverValues.length > 0) {
        for (var i = 0; i < driverValues.length; i++) {
            driverValues[i].id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.id= angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (driverValues[i].limits.length > 0) {
                var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var l=0; l < driverValues[i].limits.length; l++) {
                    driverValues[i].limits[l].id = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].driverValueId = driverValueId;
                }
            }
        }
        this.driverValues = driverValues;
    } else {
        this.driverValues = [];
    }

    this.stdFloorPos = null;
    this.floorPosition = "";
    this.isUps = true;
    this.allowDataPoints = true;
}

Ups.prototype = Object.create(NamedEntity.prototype);
Ups.prototype.constructor = Ups;

/**
 * @description Function to check equality between this object and the provided object
 * @param {Object} cups the object to test equality with
 * @returns {boolean} returns true if the provided object is equal to this object, otherwise false
 */
Ups.prototype.equals = function (cups) {
    if (!(cups instanceof Ups)) return false;
    if (!this.equalsNamedEntity(cups)) return false;
    if (this.roomId !== cups.roomId) return false;
    if (this.inventoryNumber !== cups.inventoryNumber) return false;
    if (this.serialNumber !== cups.serialNumber) return false;
    if (this.objectMac !== cups.objectMac) return false;
    if (this.url !== cups.url) return false;
    if (this.type !== cups.type) return false;
    if (this.floorPanelCustomPos !== cups.floorPanelCustomPos) return false;
    if (this.partLibraryUsvId !== cups.partLibraryUsvId) return false;
    if (!this.compareDriverValues(cups.driverValues)) return false;
    return true;
};

/**
 * @description Function to get used and unused parameters for this object
 * @param {Array} availableParameters list of all applicable parameters for this object type
 * @returns array containing used(1st element) and unused(2nd element) parameters
 */
Ups.prototype.setupParameters = function (availableParameters) {

    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = this.driverValues.filter(function (elem) {
            return elem.parameter.id === availableParameters[ap].id;
        });
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        }
        else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to check collisions for this object
 * @param {THREE.Object3D} obj3d_self this objects corresponding 3d object
 * @param {THREE.Object3D} obj3d_room this objects parent corresponding 3d object
 * @param {THREE.Vector3} pos the position to test
 * @param {boolean} onlyWalls if true only walls will be checked against, otherwise all relevant objects will be checked
 * @param {Room} roomObj this objects parent object (room)
 * @returns {boolean} returns true if a collision is detected, otherwise false
 */
Ups.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d_self.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    testObb.rotateY(this.rot.y * (Math.PI / 180));
    //check point in room
    if (!roomObj.checkOBBInRoom(testObb, false)) return true;
    //check collide walls
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === "asset" || o.name === "ups" || o.name === "floortile")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d_self.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
};

/**
 * @description Function to get this object part library id
 * @returns {*} returns this objects part library id
 */
Ups.prototype.getPartLibraryId = function () {
    return this.partLibraryUsvId;
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
Ups.prototype.validate = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateNamedEntity());
    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));
    if (this.partLibraryUsvId === undefined || this.partLibraryUsvId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "partLibraryUsvId"));
    for (var i = 0; i < this.driverValues.length; i++) {
        errorList = errorList.concat(this.driverValues[i].validate());
    }
    return errorList;
};

Ups.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    for (var i = 0; i < this.driverValues.length; i++) {
        var objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    return [];
};

/**
 * @description Function to get applicable driver types for this object type
 * @param {Array} allDrivers all supported driver types
 * @returns {Object} list of applicable drivers for this object
 */
Ups.getAvailableDrivers = function (allDrivers) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "WIB8000") delete availableDrivers[d];
        if (d === "WIRELESS") delete availableDrivers[d];
        if (d === "D0") delete availableDrivers[d];
        if (d === "DBUS") delete availableDrivers[d];
        if (d === "S0") delete availableDrivers[d];
        if (d === "WEBBUS") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to create a new ups object from provided json data object
 * @param {Object} obj the json data object
 * @param {Number} pid the parent id to set (room id)
 * @returns {Ups} the created ups object
 */
Ups.parseFromHtmlObject = function (obj, pid) {
    var ups = new Ups(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.type, obj.floorPanelCustomPos, obj.partLibraryUsvId, obj.uniqueId, null);
    if (pid) ups.roomId = pid;
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, ups);
    ups.modifyDriverValueLimitsForFrontend();
    return ups;
};

/**
 * @description Function to create new ups object from provided javascript object
 * @param {Object} obj javascript object to use
 * @param {Number} pid the parent id to set
 * @returns {Ups} returns created ups object
 */
Ups.parseFromSimpleObject = function (obj, pid) {
    var ups = new Ups(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.type, obj.floorPanelCustomPos, obj.partLibraryUsvId, obj.uniqueId, null);
    if (pid) ups.roomId = pid;
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, ups);
    return ups;
};
