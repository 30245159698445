(function () {
    'use strict';

    angular.module('emsv2App').controller("EntityDetailController", function ($stateParams, $log, $interval, $scope, $rootScope, $window, LiveDataService, Alarm,
                                                                              PartlibService, DetailPictureService, OIDService, RoomService, EMSConstants,
                                                                              entity, user, alarms, physicalTypes, Notify) {
        var ctrl = this;
        ctrl.entity = entity;
        if (ctrl.entity instanceof Asset || ctrl.entity instanceof Ups || (ctrl.entity instanceof Cooling && !ctrl.entity.isStulz())) {
            for (var i in ctrl.entity.driverValues) ctrl.entity.driverValues[i].display = true;
        }
        ctrl.alarms = alarms;
        ctrl.alarmCount = alarms !== null ? alarms.filter(function (el) {return el.priority >= 150;}).length : 0;
        ctrl.showPic = true;
        ctrl.partInfo = null;
        ctrl.user = user;
        ctrl.edit = false;
        
        ctrl.disableControls = false;

        ctrl.allowEdit = user.roles.indexOf("ROLE_LOCATION_EDIT") !== -1;

        ctrl.jumpToUrl = function () {
            var pattern = new RegExp(/(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-]*)?\??(?:[\-\+=&;%@\.\w]*)#?(?:[\.\!\/\\\w]*))?))|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b)/);
            if (pattern.test(ctrl.entity.url)) {
                if (ctrl.entity.url.toLowerCase().indexOf("http://") === -1 && ctrl.entity.url.toLowerCase().indexOf("https://") === -1) {
                    $window.open("http://" + ctrl.entity.url, '_blank');
                }
                else {
                    $window.open(ctrl.entity.url, '_blank');
                }
            }
        };

        var queryIntervalLiveData = null;
        var queryIntervalUnitAlarms = null;

        var startLiveDataQuery = function () {
            if (queryIntervalLiveData === null) queryIntervalLiveData = $interval(queryLiveData, 5000);
        };
        var queryLiveData = function () {
            LiveDataService.getLiveDataForEntity(ctrl.entity).then(function (response) {

            }, function (error) {
                $log.error("Error getting livedata for " + ctrl.entity.constructor.name + " with id " + ctrl.entity.id + "\n" + error);
            });
        };

        var startAlarmQuery = function () {
            if (queryIntervalUnitAlarms === null) queryIntervalUnitAlarms = $interval(queryAlarms, 7500);
        };
        var queryAlarms = function () {
            Alarm.getAlarmsForEntity($stateParams.entityType, $stateParams.entityId).then(function (response) {
                ctrl.alarms = response;
                ctrl.alarmCount = response.filter(function (el) {return el.priority >= 150}).length;
            }, function (error) {
                $log.error("Could not load alarms for " + $stateParams.entityType + " with id " + $stateParams.entityId + "\n" + error);
            });
        };

        var getPartInfo = function () {
            var id = null;
            switch (ctrl.entity.constructor.name) {
                case "Asset":
                    id = ctrl.entity.partLibraryAssetId;
                    break;
                case "Cooling":
                    id = ctrl.entity.partLibraryCoolingId;
                    break;
                case "Rack":
                    id = ctrl.entity.partLibraryRackId;
                    break;
                case "Ups":
                    id = ctrl.entity.partLibraryUsvId;
                    break;
            }
            PartlibService.getPartLibInfoByTypeAndId(ctrl.entity.constructor.name, id).then(function (response) {
                if (response && response.stulzInfo !== null && response.stulzInfo !== undefined) {
                    ctrl.entity.stulzType = response.model == "CyberRow" ?
                        response.stulzInfo.family + "" + response.stulzInfo.version + " " + response.stulzInfo.refridgeCap + "" + response.stulzInfo.circuits + " " + response.stulzInfo.cSystem :
                        response.stulzInfo.family + "" + response.stulzInfo.version + response.stulzInfo.airDirection + " " + response.stulzInfo.refridgeCap + "" + response.stulzInfo.circuits + " " + response.stulzInfo.cSystem;
                    DetailPictureService.buildDetailView(response.stulzInfo);
                }
            }, function (error) {
                $log.error("Error retrieving part info for: " + ctrl.entity.constructor.name + "with part id: " + id + "\n" + error);
            });
        };
        getPartInfo();
        queryLiveData();
        startLiveDataQuery();
        startAlarmQuery();

        ctrl.modValue = function (dv) {
            return LiveDataService.modDVValue(dv, user);
        };

        ctrl.handleMouseOver = function (dv) {
            if (dv.parameter.oidId !== null) DetailPictureService.markComponent(dv.parameter.oidDataPoint.componentEnum);
        };

        ctrl.handleMouseLeave = function (dv) {
            if (dv.parameter.oidId !== null) DetailPictureService.unmarkComponent(dv.parameter.oidDataPoint.componentEnum);
        };

        ctrl.sendValue = function (dv) {

            if(dv.driver.driverType == 5) {
                Notify.error("global.notification.error.title", "room.notifications.modbustcpNotImplemented", 2000);
                return;
            }
            if (dv.driverTableName.indexOf('snmp') != -1 && dv.driver.community != 'private') {
                Notify.error("global.notification.error.title", "room.notifications.snmpNotPrivate", 2000);
                return;
            }
            if (dv.sendValue != undefined && !isNaN(dv.sendValue)) {
                ctrl.disableControls = true;
                var mappedValue = parseInt(dv.sendValue);
                if (dv.physicalType != 6 && dv.physicalType != 0) mappedValue = parseFloat(dv.sendValue) * 1000;
                RoomService.sendEntityDriverValue(dv, ctrl.entity, mappedValue).then(function (response) {
                    ctrl.disableControls = false;
                    if (parseInt(response.data) == mappedValue) {
                        Notify.success("global.notification.success.title", "room.notifications.sendSuccess", 2000);
                    }
                    else {
                        Notify.error("global.notification.error.title", "room.notifications.sendError", 2000);
                    }
                }, function (error) {
                    ctrl.disableControls = false;
                    Notify.error("global.notification.error.title", "room.notifications.sendError", 2000);
                    $log.error("Error sending value: " + dv.sendValue + "\n" + error);
                });
            }
        };

        ctrl.goBack = function () {
            $rootScope.back();
        };

        ctrl.toggleEdit = function () {
            if (ctrl.allowEdit) {
                ctrl.edit = true;
            }
        };

        $scope.$on("$destroy", function () {
            if (queryIntervalLiveData) $interval.cancel(queryIntervalLiveData);
            if (queryIntervalUnitAlarms) $interval.cancel(queryIntervalUnitAlarms);
            ctrl.entity = null;
            ctrl.alarms = null;
            ctrl.partInfo = null;
        });

    });
})();
