(function () {
    'use strict';

    function escapeHtmlService() {

        var entityMap = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            '\'': '&#39;',
            '/': '&#x2F;'
        };

        return function (string) {
            return String(string).replace(/[&<>"'\/]/g, function fromEntityMap(s) {
                return entityMap[s];
            });
        };

    }

    angular
        .module('emsv2App')
        .service('escapeHtml', escapeHtmlService);

})();
