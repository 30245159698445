(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name alarmModal
     */
    angular
        .module('emsv2App')
        .component('pushModal', {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/roomedit/room.edit.push.modal.component.html',
            controller: ['PushToDeviceService', '$scope', '$interval', 'Notify', '$state', '$translate', function (PushToDeviceService, $scope,
                                                                                                                   $interval, Notify, $state, $translate) {
                var ctrl = this;
                ctrl.objectId = null;
                ctrl.pushProcessId = null;
                ctrl.currentState = PushState.PUSH_INIT;
                ctrl.interval = null;

                ctrl.errorMessage = $translate('room.push.error.0');

                ctrl.PUSH_INIT = PushState.PUSH_INIT;
                ctrl.PUSH_IN_PROGRESS = PushState.PUSH_IN_PROGRESS;
                ctrl.PUSH_FAILED = PushState.PUSH_FAILED;
                ctrl.UNKNOWN = PushState.UNKNOWN;
                ctrl.PUSH_SUCCEEDED = PushState.PUSH_SUCCEEDED;

                ctrl.$onInit = function () {
                };

                ctrl.push = function () {
                    var request = new PushRequest();
                    if (ctrl.resolve.hasOwnProperty("roomId")) {
                        request.objectId = ctrl.resolve.roomId;
                        request.pushType = PushType.ROOM;
                    }
                    else if (ctrl.resolve.hasOwnProperty("deviceId")) {
                        request.objectId = ctrl.resolve.deviceId;
                        request.pushType = PushType.DEVICE;
                    }
                    else {
                        Notify.error("room.push.pushFailed", "room.push.error.6", "2000");
                        return;
                    }
                    ctrl.pushDevice(request);
                };

                ctrl.pushDevice = function (request) {
                    PushToDeviceService.pushDevice(request).then(function (response) {
                        ctrl.pushProcessId = response.data;
                        ctrl.currentState = PushState.PUSH_IN_PROGRESS;
                        ctrl.checkPushState();
                    }, function (error) {
                        Notify.error('global.notification.error.title',
                            'global.notification.error.pushRequestNotSaved', 3000)
                    });
                };

                ctrl.checkPushState = function () {
                    if (ctrl.interval !== null) {
                        $interval.cancel(ctrl.interval);
                    }

                    ctrl.interval = $interval(function () {
                        if (ctrl.pushProcessId === null || ctrl.pushProcessId === undefined) {
                            Notify.error('global.notification.error.title',
                                'global.notification.error.noPushProcessIdSaved', 3000);
                            $interval.cancel(this);
                        }
                        PushToDeviceService.checkActivePushProcess(ctrl.pushProcessId).then(function (response) {
                            if (response.data.pushState === PushState.PUSH_SUCCEEDED) {
                                ctrl.currentState = response.data.pushState;
                                $interval.cancel(ctrl.interval);
                                ctrl.interval = null;
                                ctrl.pushProcessId = null;
                            }
                            else if (response.data.pushState === PushState.PUSH_FAILED || response.data.pushState === PushState.UNKNOWN) {
                                ctrl.currentState = response.data.pushState;
                                if (response.data.errorCode !== null && response.data.errorCode !== undefined) {
                                    ctrl.errorMessage = $translate.instant('room.push.error.' + response.data.errorCode);
                                }
                                $interval.cancel(ctrl.interval);
                                ctrl.interval = null;
                                ctrl.pushProcessId = null;
                            }
                            else {
                                //keep waiting
                            }
                        });
                    }, 1000);

                };

                $scope.$on('destroy', function (event) {
                    if (ctrl.interval !== null) {
                        $interval.cancel(ctrl.interval);
                    }
                })

            }]
        });

})();


