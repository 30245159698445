(function () {

    'use strict';

    //region resolve functions

    var getRoomData = ['$state', '$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'Principal', function ($state, $q, $log, $stateParams, localStorageService, RoomService) {
        if ($stateParams.roomid === "" || $stateParams.roomid === undefined) return $q.when(null);
        if (parseInt($stateParams.roomid) < 0 || ($stateParams.rackid && parseInt($stateParams.rackid) < 0)) {

            var room = localStorageService.get("roomData");
            if (room) {
                return $q.when(Room.parseFromSimpleObject(room));
            } else {
                return $q.when(null);
            }
        }
        var room = localStorageService.get("roomData");
        if (room) {
            room = Room.parseFromSimpleObject(room);
        }
        return RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
            if (response.data === "" && response.status == 204) {
                $state.go("notfound");
            } else {
                var dbRoom = Room.parseFromHtmlObject(response.data);
                if (!room || room.equals(dbRoom)) {
                    return dbRoom;
                }
                if (room && !room.equals(dbRoom)) {
                    localStorageService.add("dbRoom", dbRoom);
                    return room;
                }
            }
        }, function (error) {
            $state.go("notfound");
            $log.error("Could not load room: " + $stateParams.roomid + " for location: " + $stateParams.id + "\n" + error);
        });
    }];

    var getRackData = ['$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'Principal', function ($q, $log, $stateParams, localStorageService, RoomService) {
        if ($stateParams.rackid === "") return $q.when(null);
        if (parseInt($stateParams.rackid) < 0) {
            var room = localStorageService.get("roomData");
            if (room) {
                var r = Room.parseFromSimpleObject(room);
                var rack = r.findObjectByTypeAndID("rack", parseInt($stateParams.rackid));
                if (rack) {
                    return $q.when(rack);
                } else {
                    return $q.when(null);
                }
            } else {
                return $q.when(null);
            }
        }
        return RoomService.getEntity("Rack", $stateParams.roomid, $stateParams.rackid).then(function (response) {
            return Rack.parseFromHtmlObject(response.data, response.data.roomId);
        }, function (error) {
            $log.error("Could not load rack: " + $stateParams.rackid + "\n" + error);
        });
    }];

    var getEntityData = ['$q', '$log', '$stateParams', 'RoomService', 'Principal', function ($q, $log, $stateParams, RoomService) {
        if ($stateParams.entityType === "" || $stateParams.entityId === "") return $q.when(null);
        return RoomService.getEntity($stateParams.entityType, $stateParams.roomId, $stateParams.entityId).then(function (response) {
            return response;
        }, function (error) {
            $log.error("Could not load entity: " + $stateParams.entityType + " " + $stateParams.entityId + "\n" + error);
        });
    }];

    var getAlarms = ['$q', '$log', '$stateParams', 'Alarm', function ($q, $log, $stateParams, Alarm) {
        if ($stateParams.entityType === "" || $stateParams.entityId === "") return $q.when(null);
        return Alarm.getAlarmsForEntity($stateParams.entityType, $stateParams.entityId).then(function (response) {
            return response;
        }, function (error) {
            $log.error("Could not load alarms for " + $stateParams.entityType + " with id " + $stateParams.entityId + "\n" + error);
        });
    }];

    var getUser = ['$log', 'Principal', function ($log, Principal) {
        return Principal.identity().then(function (acc) {
            return acc;
        }, function (error) {
            $log.error("Could not get current user information\n" + error);
        });
    }];

    var getBuildings = ['$log', '$stateParams', 'Buildings', function ($log, $stateParams, Buildings) {
        return Buildings.getByLocation($stateParams.id).then(function (response) {
            var buildings = [];
            for (var i in response.data) {
                buildings.push(Building.parseFromHtmlObject(response.data[i]));
            }
            return buildings;
        }, function (error) {
            $log.error('Error retrieving building for location with id: ' + $stateParams.id + "\n" + error);
        });
    }];

    var getHeatMaps = ['$log', '$q', 'HeatmapService', function ($log, $q, HeatmapService) {
        return HeatmapService.getHeatmaps().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Error retrieving heatmaps\n" + error.data);

        });
    }];

    var getSortedRooms = ['$log', '$filter', '$stateParams', 'RoomService', function ($log, $filter, $stateParams, RoomService) {
        var rooms = [];
        var sortedRooms = {};
        var parseRooms = function (htmlObject) {
            for (var i in htmlObject) {
                var r = Room.parseFromHtmlObject(htmlObject[i]);
                r.showsTree = false;
                var attrName = htmlObject[i].floor;
                if (sortedRooms.hasOwnProperty(attrName)) {
                    sortedRooms[attrName].rooms.push(r);
                } else {
                    sortedRooms[attrName] = {};
                    sortedRooms[attrName].floor = attrName;
                    sortedRooms[attrName].rooms = [];
                    sortedRooms[attrName].rooms.push(r);
                }
            }
        };
        return RoomService.getRooms($stateParams.id).then(function (response) {
            parseRooms(response.data);
            return sortedRooms;
        }, function (errResp) {
            $log.error("Error retrieving rooms\n" + errResp);
        });
    }];

    var getRooms = ['$log', '$stateParams', 'RoomService', function ($log, $stateParams, RoomService) {
        return RoomService.getRooms($stateParams.id).then(function (response) {
            var rooms = [];
            for (var i in response.data) {
                rooms.push(Room.parseFromHtmlObject(response.data[i]));
                rooms[rooms.length - 1].showsTree = false;
                rooms[rooms.length - 1].ffloor = response.data[i].floor;
            }
            return rooms;
        }, function (error) {
            $log.error("Error retrieving rooms for location with id: " + $stateParams.id + "\n" + error);
            return error;
        });
    }];

    var getFloorRooms = ['$log', '$stateParams', 'RoomService', function ($log, $stateParams, RoomService) {
        return RoomService.getFloorRooms($stateParams.id, $stateParams.floorid).then(function (response) {
            return response;
        }, function (error) {
            $log.error("Error retrieving rooms for floor: " + $stateParams.floorid + " and location: " + $stateParams.id + "\n" + error);
            return error;
        });
    }];

    // var getWirelessSensorInfo = ['$log', '$stateParams', 'Location', function ($log, $stateParams, Location) {
    //     return Location.getAllWirelessGateways($stateParams.id).then(function (response) {
    //         return response;
    //     }, function (error) {
    //         $log.error("Error retrieving wireless information for loaction: " + $stateParams.id + "\n" + error);
    //         return error;
    //     });
    // }];

    var getPhysicalTypes = ['$log', '$stateParams', 'PhysicalTypeService', function ($log, $stateParams, PhysicalTypeService) {
        return PhysicalTypeService.getPhysicalTypes().then(function (response) {
            return response;
        }, function (error) {
            $log.error("Error retrieving physical types\n" + error);
        });
    }];

    var getParts = ['$log', '$stateParams', 'PartlibService', function ($log, $stateParams, PartlibService) {
        return PartlibService.getAllParts().then(function (data) {
            return data;
        }, function (error) {
            $log.error("Error retrieving parts from Partlibrary\n" + error.data);
        })
    }];

    var getAlarmOids = ['$log', 'OIDService', function ($log, OIDService) {
        return OIDService.getAlarmOids().then(function (response) {
            return response;
        }, function (error) {
            $log.error("Error retrieving alarm oids\n" + error.data);
            return error;
        });
    }];

    var getConsumer = ['$log', 'EnergyConsumerService', function ($log, EnergyConsumerService) {
        return EnergyConsumerService.getConsumer().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Error retrieving energy consumer\n" + error);
        })
    }];

    var getLicense = ['$log', "License", function ($log, License) {
        return License.get().then(function (response) {
            return response.data.data;
        }, function (error) {
            $log.error('Error retrieving license information\n' + error);
        });
    }];

    // var getUsedWirelessSensorIdsForLocation = ['$log', '$stateParams', 'Location', function ($log, $stateParams, Location) {
    //     return Location.getUsedWirelessSensorIdsForLocation($stateParams.id).then(function (response) {
    //         return response.data;
    //     }, function (error) {
    //         $log.error("Error retrieving used wireless sensor ids for location: " + $stateParams.id + "\n" + error.data);
    //     });
    // }];

    var getQuickBarSettings = ['$log', 'Principal', 'QuickBarSettingsService', function ($log, Principal, QuickBarSettingsService) {
        return Principal.identity().then(function (account) {
            return QuickBarSettingsService.get(account.id);
        });
    }];

    var getSNMPv3authProtocols = ['snmpV3SecurityUtilsService', function (snmpV3SecurityUtilsService) {
            return snmpV3SecurityUtilsService.getAuthProtocols();
    }];

    var getSNMPv3privProtocols = ['snmpV3SecurityUtilsService', function (snmpV3SecurityUtilsService) {
            return snmpV3SecurityUtilsService.getPrivProtocols();
    }];

    //endregion

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('location.rooms', {
                parent: 'location.detail',
                url: '/rooms?view&bid',
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.rooms',
                    rolesMatch: 'all',
                    license: '3droomedit'
                },
                templateUrl: 'scripts/app/location/tabs/room/room.html',
                controller: 'roomController',
                controllerAs: '$ctrl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('livedata');
                        return $translate.refresh();
                    }],
                    user: getUser,
                    rooms: ['$q', '$log', '$stateParams', 'RoomService', function ($q, $log, $stateParams, RoomService) {
                        if ($stateParams.id === "") return $q.when(null);
                        return RoomService.getSimpleRooms($stateParams.id).then(function (response) {
                            var rooms = [];
                            for (var i in response.data) {
                                rooms.push(Room.parseFromHtmlObject(response.data[i]));
                                rooms[rooms.length - 1].showsTree = false;
                                rooms[rooms.length - 1].ffloor = response.data[i].floor;
                            }
                            return rooms;
                        }, function (error) {
                            $log.error("Error retrieving rooms for location with id: " + $stateParams.id + "\n" + error + "\n" + error.data);
                            return error;
                        });
                    }],
                    buildings: getBuildings
                }
            })
            .state("location.rooms.floorview", {
                parent: 'location.rooms',
                url: '/floorview/:floorid',
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'room.floorview.floorview',
                    rolesMatch: 'all'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/location/tabs/room/floor.view.html',
                        controller: 'FloorViewController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('room');
                        return $translate.refresh();
                    }],
                    rooms: getFloorRooms,
                    user: getUser,
                    buildings: getBuildings
                }

            })
            .state('location.rooms.view', {
                parent: 'location.rooms',
                url: '/view/:roomid?ruuid',
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: '{{room.name}}',
                    rolesMatch: 'all',
                    edit: false
                },
                reloadOnSearch: false,
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/roomedit/roompanel.view.html',
                        controller: 'RoomEditController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('partlib');
                        $translatePartialLoader.addPart('entitydetail');
                        return $translate.refresh();
                    }],
                    object: getRoomData,
                    user: getUser,
                    heatmaps: getHeatMaps,
                    room: ['$state', '$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'RoomEditorService', function ($state, $q, $log, $stateParams, localStorageService, RoomService, RoomEditorService) {
                        if ($stateParams.roomid === "") return $q.when(null);
                        if (parseInt($stateParams.roomid) < 0 || ($stateParams.rackid && parseInt($stateParams.rackid) < 0)) {
                            var room = localStorageService.get("roomData");
                            if (room) {
                                return $q.when(Room.parseFromSimpleObject(room));
                            } else {
                                return $q.when(null);
                            }
                        }
                        var room = localStorageService.get("roomData");
                        if (room) {
                            room = Room.parseFromSimpleObject(room);
                        }
                        return RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
                            if (response.data === "" && response.status == 204) {
                                $state.go("notfound");
                            } else {
                                var dbRoom = Room.parseFromHtmlObject(response.data);
                                if (room && !room.equals(dbRoom)) {
                                    localStorageService.add("dbRoom", dbRoom);
                                    return room;
                                }
                                return dbRoom;
                            }
                        }, function (error) {
                            $state.go("notfound");
                            $log.error("Could not load room: " + $stateParams.roomid + " for location: " + $stateParams.id + "\n" + error);
                            // return {name:"narf edit"};
                        });
                    }],
                    // wirelessinfo: getWirelessSensorInfo,
                    // usedwirelessinfo: getUsedWirelessSensorIdsForLocation,
                    physicalTypes: getPhysicalTypes,
                    parts: getParts,
                    alarmOids: getAlarmOids,
                    consumer: getConsumer,
                    license: getLicense,
                    SNMPv3authProtocols: getSNMPv3authProtocols,
                    SNMPv3privProtocols: getSNMPv3privProtocols,
                    lang: ['Language', function (Language) {
                        return Language.getCurrent();
                    }],
                    buildings: getBuildings,
                    quickbarSettings: getQuickBarSettings
                }
            })
            .state('location.rooms.edit', {
                parent: 'location.rooms',
                url: '/edit/:roomid?ruuid',
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW', 'ROLE_LOCATION_EDIT'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: '{{room.name}}',
                    rolesMatch: 'all',
                    edit: true
                },
                reloadOnSearch: false,
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/roomedit/roompanel.view.html',
                        controller: 'RoomEditController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('partlib');
                        $translatePartialLoader.addPart('entitydetail');
                        return $translate.refresh();
                    }],
                    object: getRoomData,
                    user: getUser,
                    heatmaps: getHeatMaps,
                    room: ['$state', '$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'RoomEditorService', function ($state, $q, $log, $stateParams, localStorageService, RoomService, RoomEditorService) {
                        if ($stateParams.roomid === "") return $q.when(null);
                        if (parseInt($stateParams.roomid) < 0 || ($stateParams.rackid && parseInt($stateParams.rackid) < 0)) {
                            var room = localStorageService.get("roomData");
                            if (room) {
                                return $q.when(Room.parseFromSimpleObject(room));
                            } else {
                                return $q.when(null);
                            }
                        }
                        var room = localStorageService.get("roomData");
                        if (room) {
                            room = Room.parseFromSimpleObject(room);
                        }
                        return RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
                            var dbRoom = Room.parseFromHtmlObject(response.data);
                            if (room && !room.equals(dbRoom)) {
                                localStorageService.add("dbRoom", dbRoom);
                                return room;
                            }
                            return dbRoom;
                        }, function (error) {
                            $state.go("notfound");
                            $log.error("Could not load room: " + $stateParams.roomid + " for location: " + $stateParams.id + "\n" + error);
                            // return {name:"narf edit"};
                        });
                    }],
                    // wirelessinfo: getWirelessSensorInfo,
                    // usedwirelessinfo: getUsedWirelessSensorIdsForLocation,
                    physicalTypes: getPhysicalTypes,
                    parts: getParts,
                    alarmOids: getAlarmOids,
                    consumer: getConsumer,
                    license: getLicense,
                    SNMPv3authProtocols: getSNMPv3authProtocols,
                    SNMPv3privProtocols: getSNMPv3privProtocols,
                    lang: ['Language', function (Language) {
                        return Language.getCurrent();
                    }],
                    buildings: getBuildings,
                    quickbarSettings: getQuickBarSettings,
                    security: ['$stateParams', '$state', 'userInfo', function ($stateParams, $state, userInfo) {
                        if (userInfo.roles.indexOf("ROLE_LOCATION_EDIT") === -1) {
                            return $state.go('location.rooms.view', {
                                roomMode: "view",
                                roomid: $stateParams.roomid
                            }, {reload: true});
                        }
                    }]
                }
            })
            .state('location.rooms.create', {
                parent: 'location.rooms',
                url: '/create',
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW', 'ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'room.createNewRoom',
                    rolesMatch: 'all'
                },
                reloadOnSearch: false,
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/roomedit/roompanel.view.html',
                        controller: 'RoomEditController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('partlib');
                        return $translate.refresh();
                    }],
                    object: getRoomData,
                    user: getUser,
                    heatmaps: getHeatMaps,

                    license: ['$log', 'License', '$state', function ($log, License, $state) {
                        return License.getRoomLimitReached().then(function (response) {
                            if (response.data) {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $log.error('Couldn\'t retrieve license!', error);
                        });
                    }],

                    room: ['$state', '$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'Principal', function ($state, $q, $log, $stateParams, localStorageService, RoomService) {
                        if ($stateParams.roomid === "" || $stateParams.roomid === undefined) return $q.when(null);
                        if (parseInt($stateParams.roomid) < 0 || ($stateParams.rackid && parseInt($stateParams.rackid) < 0)) {
                            var room = localStorageService.get("roomData");
                            if (room) {
                                return $q.when(Room.parseFromSimpleObject(room));
                            } else {
                                return $q.when(null);
                            }
                        }
                        var room = localStorageService.get("roomData");
                        if (room) {
                            room = Room.parseFromSimpleObject(room);
                        }
                        return RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
                            var dbRoom = Room.parseFromHtmlObject(response.data);
                            if (!room || room.equals(dbRoom)) {
                                return dbRoom;
                            }
                            if (room && !room.equals(dbRoom)) {
                                localStorageService.add("dbRoom", dbRoom);
                                return room;
                            }
                            return dbRoom;
                        }, function (error) {
                            $state.go("notfound");
                            $log.error("Could not load room: " + $stateParams.roomid + " for location: " + $stateParams.id + "\n" + error);
                            // return {name:"narf create"};
                        });
                    }],
                    // wirelessinfo: getWirelessSensorInfo,
                    // usedwirelessinfo: getUsedWirelessSensorIdsForLocation,
                    physicalTypes: getPhysicalTypes,
                    parts: getParts,
                    alarmOids: getAlarmOids,
                    consumer: getConsumer,
                    // license: getLicense,
                    SNMPv3authProtocols: getSNMPv3authProtocols,
                    SNMPv3privProtocols: getSNMPv3privProtocols,
                    lang: ['Language', function (Language) {
                        return Language.getCurrent();
                    }],
                    buildings: getBuildings,
                    quickbarSettings: getQuickBarSettings
                }
            })
            .state('location.rooms.view.rack', {
                parent: 'location.rooms.view',
                url: "/rack/:rackid?rauuid",
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: '{{object.name}}',
                    rolesMatch: 'all',
                    edit: false
                },
                reloadOnSearch: false,
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/roomedit/roompanel.view.html',
                        controller: "RoomEditController",
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('partlib');
                        return $translate.refresh();
                    }],
                    object: getRackData,
                    user: getUser,
                    heatmaps: getHeatMaps,
                    room: ['$state', '$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'Principal', function ($state, $q, $log, $stateParams, localStorageService, RoomService) {
                        if ($stateParams.roomid === "") return $q.when(null);
                        if (parseInt($stateParams.roomid) < 0 || ($stateParams.rackid && parseInt($stateParams.rackid) < 0)) {
                            var room = localStorageService.get("roomData");
                            if (room) {
                                return $q.when(Room.parseFromSimpleObject(room));
                            } else {
                                return $q.when(null);
                            }
                        }
                        var room = localStorageService.get("roomData");
                        if (room) {
                            room = Room.parseFromSimpleObject(room);
                        }
                        return RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
                            var dbRoom = Room.parseFromHtmlObject(response.data);
                            if (!room || room.equals(dbRoom)) {
                                return dbRoom;
                            }
                            if (room && !room.equals(dbRoom)) {
                                localStorageService.add("dbRoom", dbRoom);
                                return room;
                            }
                            return dbRoom;
                        }, function (error) {
                            $state.go("notfound");
                            $log.error("Could not load room: " + $stateParams.roomid + " for location: " + $stateParams.id + "\n" + error);
                            // return {name:"narf rack"};
                        });
                    }],
                    physicalTypes: getPhysicalTypes,
                    parts: getParts,
                    alarmOids: getAlarmOids,
                    consumer: getConsumer,
                    license: getLicense,
                    SNMPv3authProtocols: getSNMPv3authProtocols,
                    SNMPv3privProtocols: getSNMPv3privProtocols,
                    lang: ['Language', function (Language) {
                        return Language.getCurrent();
                    }],
                    buildings: getBuildings,
                    quickbarSettings: getQuickBarSettings
                }
            })
            .state('location.rooms.edit.rack', {
                parent: 'location.rooms.edit',
                url: "/rack/:rackid?rauuid",
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW', 'ROLE_LOCATION_EDIT'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: '{{object.name}}',
                    rolesMatch: 'all',
                    edit: true
                },
                reloadOnSearch: false,
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/roomedit/roompanel.view.html',
                        controller: "RoomEditController",
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('partlib');
                        return $translate.refresh();
                    }],
                    object: getRackData,
                    user: getUser,
                    heatmaps: getHeatMaps,
                    room: ['$state', '$q', '$log', '$stateParams', 'localStorageService', 'RoomService', 'Principal', function ($state, $q, $log, $stateParams, localStorageService, RoomService) {
                        if ($stateParams.roomid === "") return $q.when(null);
                        if (parseInt($stateParams.roomid) < 0 || ($stateParams.rackid && parseInt($stateParams.rackid) < 0)) {
                            var room = localStorageService.get("roomData");
                            if (room) {
                                return $q.when(Room.parseFromSimpleObject(room));
                            } else {
                                return $q.when(null);
                            }
                        }
                        var room = localStorageService.get("roomData");
                        if (room) {
                            room = Room.parseFromSimpleObject(room);
                        }
                        return RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
                            var dbRoom = Room.parseFromHtmlObject(response.data);
                            if (!room || room.equals(dbRoom)) {
                                return dbRoom;
                            }
                            if (room && !room.equals(dbRoom)) {
                                localStorageService.add("dbRoom", dbRoom);
                                return room;
                            }
                            return dbRoom;
                        }, function (error) {
                            $state.go("notfound");
                            $log.error("Could not load room: " + $stateParams.roomid + " for location: " + $stateParams.id + "\n" + error);
                            // return {name:"narf rack"};
                        });
                    }],
                    physicalTypes: getPhysicalTypes,
                    parts: getParts,
                    alarmOids: getAlarmOids,
                    consumer: getConsumer,
                    license: getLicense,
                    SNMPv3authProtocols: getSNMPv3authProtocols,
                    SNMPv3privProtocols: getSNMPv3privProtocols,
                    lang: ['Language', function (Language) {
                        return Language.getCurrent();
                    }],
                    buildings: getBuildings,
                    quickbarSettings: getQuickBarSettings
                }
            })
            .state('location.rooms.edit.entity', {
                parent: 'location.rooms.edit',
                url: "/:entityType/:entityId",
                data: {
                    roles: ['ROLE_USER', 'ROLE_LOCATION_VIEW'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: '{{"entitydetail.title" | translate}} {{entity.name}}',
                    rolesMatch: 'all'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/location/tabs/room/entity.detail.view.html',
                        controller: 'EntityDetailController',
                        controllerAs: 'ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('entitydetail');
                        $translatePartialLoader.addPart('alarm');
                        return $translate.refresh();
                    }],
                    entity: getEntityData,
                    user: getUser,
                    alarms: getAlarms,
                    physicalTypes: getPhysicalTypes,
                    SNMPv3authProtocols: getSNMPv3authProtocols,
                    SNMPv3privProtocols: getSNMPv3privProtocols,
                }
            })
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);
})();
