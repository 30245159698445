(function () {
    "use strict";
    var myApp = angular.module('emsv2App');
    myApp.service('ResetErrorService', function ($http) {

        function resetDevices(roomId) {
            return $http.put("/api/reseterrors/" + roomId);
        }
        function availableRooms(locationId) {
            return $http.get("/api/reseterrors/availableRooms/" + locationId);
        }
        function resetAll(locationId) {
            return $http.put("/api/reseterrors/all/" + locationId);
        }

        return {
            resetDevices: resetDevices,
            availableRooms: availableRooms,
            resetAll: resetAll
        }
    });
})();
