(function () {
    'use strict';

    angular
        .module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('group', {
                    parent: 'admin',
                    abstract: true
                })
                .state('groupOverview', {
                    parent: 'group',
                    url: '/group',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_GROUP'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'group.management.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: '/scripts/app/admin/group/group.html',
                            controller: 'GroupController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('group');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        currentUser: ['$log', 'Principal', '$state', _currentUser]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('newGroup', {
                    parent: 'groupOverview',
                    url: '/new',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_GROUP'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'group.management.new.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: '/scripts/app/admin/group/new/new.group.html',
                            controller: 'NewGroupController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('group');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        locations: ['$log', 'Location', _loadLocations],
                        filters: ['$log', 'Filter', _loadFilters],
                        users: ['$log', 'User', _loadUsers]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('group.selected', {
                    parent: 'groupOverview',
                    url: '/:gid',
                    abstract: false,
                    redirectTo: 'group.edit',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_GROUP'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: '{{selectedGroup.name}}'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('group');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        locations: ['$log', 'Location', _loadLocations],
                        users: ['$log', 'User', _loadUsers],
                        selectedGroup: ['$log', '$stateParams', 'Group', '$state', _loadGroup]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('group.edit', {
                    parent: 'group.selected',
                    url: '/edit',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_GROUP'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'group.management.edit.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: '/scripts/app/admin/group/edit/edit.group.html',
                            controller: 'EditGroupController'
                        }
                    },
                    resolve: {
                        selectedGroup: ['$log', '$stateParams', 'Group', '$state', _loadGroup],
                        filters: ['$log', 'Filter', _loadFilters]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('deleteGroup', {
                    parent: 'group.selected',
                    url: '/delete',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_GROUP'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'group.management.delete.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: '/scripts/app/admin/group/delete/delete.group.html',
                            controller: 'DeleteGroupController'
                        }
                    },
                    resolve: {
                        deletable: ['$log', '$stateParams', 'Group', '$state', _deletable],
                        selectedGroup: ['$log', '$stateParams', 'Group', _loadGroup]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('viewGroup', {
                    parent: 'group.selected',
                    url: '/view',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_GROUP'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'group.management.view.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: '/scripts/app/admin/group/view/view.group.html',
                            controller: 'ViewGroupController'
                        }
                    },
                    resolve: {
                        selectedGroup: ['$log', '$stateParams', 'Group', '$state', _loadGroup],
                        filters: ['$log', 'Filter', _loadFilters]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });

            function _loadLocations($log, Location) {
                return Location.queryAll().then(function (response) {
                    return response.data;
                }, function (error) {
                    $log.error("Couldn't load locations!", error);
                });
            }

            function _loadFilters($log, Filter) {
                return Filter.getSavedQueries('location').then(function (response) {
                    //console.log("_loadFilters:response:data:", response.data);
                    return response.data;
                }, function (error) {
                    $log.error("Couldn't load filters!", error);
                });
            }

            function _loadUsers($log, User) {
                return User.query().then(function (response) {
                    return response.data;
                }, function (error) {
                    $log.error("Couldn't load users!", error);
                });
            }

            function _loadGroup($log, $stateParams, Group, $state) {
                return Group.get($stateParams.gid).then(function (response) {
                    return response.data;
                }, function (error) {
                    $state.go("notfound");
                    $log.error("Couldn't load group " + $stateParams.gid, error);
                });
            }

            function _deletable($log, $stateParams, Group, $state) {
                return Group.get($stateParams.gid).then(function (response) {
                    if (response.data.generated === true) {
                        $state.go('accessdenied');
                    }
                }, function (error) {
                    $state.go("notfound");
                    $log.error("Couldn't load group " + $stateParams.gid, error);
                });
            }

            function _currentUser($log, Principal, $state) {
                return Principal.identity().then(function (user) {
                    $log.debug('Loaded userInfo: ', user);
                    if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_GROUP')) {
                        return user;
                    }
                    else {
                        $state.go("accessdenied");
                    }
                }, function (error) {
                    $state.go("notfound");
                    $log.error("Couldn't load user ", error);
                });
            }
        });
})();
