(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name DashboardService
     */
    angular.module('emsv2App').service('PueMonitorService', function ($http) {

        var service = this;

        this.loadData = function(locationId, request) {
            if(locationId){

                return $http.post('api/pue/monitor/data?locationId=' + locationId, request).then(function (response) {
                    return response;
                });
            }

            return [];
        }

        this.loadLocations = function(){
                return $http.get('api/locations/forUser');
        }


        return {
            loadData: this.loadData,
            loadLocations: this.loadLocations
        };
    });

})();
