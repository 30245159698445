(function (moment) {

    'use strict';

    function AnalysisController($scope, $window, $log, $timeout, $q, $translate, $state, $uibModal, $http, DateConstants, digiChart,
                                highchart, Tools, Analysis, Session, Notify, user, staticDataPoints, PIL,
                                aggregationOptions, groupingOptions, isAdmin, groupList, currentAnalysis, locationsAndDevices, GenDialogService) {
        var ctrl = this;
        var initDateRange = null;
        var initChart = null;
        var initSaves = null;
        var initTemplate = null;
        var initDatapointsList = null;
        ctrl.datapointLimit = null;
        ctrl.isLocationSelected = false;
        ctrl.isRoomSelected = false;
        ctrl.isEntitySelected = false;
        ctrl.entitySpinnerDisplay = false;
        ctrl.dpSpinnerDisplay = false;
        ctrl.rooms = [];
        ctrl.roomsAvailable = false;
        ctrl.selectedEntity = null;
        ctrl.searchTerm = undefined;
        ctrl.dpSearchTerm = undefined;

        ctrl.template = {
            active: false,
            generatable: false,
            devices: [],
            location: null,
            toggle: _toggleTemplate,
            doTemplateActivation: _doTemplateActivation,
            cancelTemplateActivation: _cancelTemplateActivation,
            setLocation: _setTemplateLocation,
            checkGenerate: _checkGenerate
        };

        ctrl.maxLast = 56;
        ctrl.isDpFilterSearchTerm = true;
        ctrl.validSearchTerm = false;
        ctrl.validDpSearchTerm = false;
        ctrl.dateranges = {
            list: [],
            fixed: true,
            dynamicRange: {
                value: 1,
                range: 1,
                scale: 0,
                rangeValues: [
                    {key: 0, value: 'day', enabled: true, label: "analysis.groupBy.DAY"},
                    {key: 1, value: 'week', enabled: true, label: "analysis.groupBy.WEEK"},
                    {key: 2, value: 'month', enabled: true, label: "analysis.groupBy.MONTH"},
                    {key: 3, value: 'year', enabled: true, label: "analysis.groupBy.YEAR"}
                ],
                scaleValues: [
                    {key: 0, value: 'day', enabled: true, label: "analysis.groupBy.DAY"},
                    {key: 1, value: 'week', enabled: true, label: "analysis.groupBy.WEEK"},
                    {key: 2, value: 'month', enabled: true, label: "analysis.groupBy.MONTH"},
                    {key: 3, value: 'year', enabled: true, label: "analysis.groupBy.YEAR"}
                ]
            },
            // Date range picker customization (From - To)
            options: {
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true,
                autoUpdateInput: true,
                autoApply: true,
                applyClass: 'btn-primary',
                format: $translate.instant('global.dateTimeFormat'),
                locale: {
                    format: $translate.instant('global.dateTimeFormat'),
                    daysOfWeek: DateConstants.getDaysOfWeek(),
                    monthNames: DateConstants.getMonthNames()
                },
                showDropdowns: true,
                eventHandlers : {
                    'apply.daterangepicker' : function() {
                        if(moment(ctrl.dateranges.list[0].end).isBefore(ctrl.dateranges.list[0].start)) {
                            ctrl.timeInvalid = true;
                        } else {
                            ctrl.timeInvalid = false;
                        }
                    },
                    'hide.daterangepicker' : function() {
                        if(moment(ctrl.dateranges.list[0].end).isBefore(ctrl.dateranges.list[0].start)) {
                            ctrl.timeInvalid = true;
                        } else {
                            ctrl.timeInvalid = false;
                        }
                    }
                },
            },
            remove: _removeDaterange,
            // add: _addDateRange,
            // split: _splitFixedDateRange,
            canSplit: _canSplitFixedDateRange,
            toggleRangeStyle: _toggleRangeStyle,
            setRangeStyle: _setRangeStyle,
            setDynamicRange: _setDynamicRange
        };
        ctrl.locations = {
            available: locationsAndDevices.value1,
            devices: locationsAndDevices.value2,
            selected: {}
        };
        ctrl.options = {
            edit: true,
            delete: false,
            splitValues: [
                {key: 0, value: 'day', label: "analysis.groupBy.DAY"},
                {key: 1, value: 'week', label: "analysis.groupBy.WEEK"},
                {key: 2, value: 'month', label: "analysis.groupBy.MONTH"},
                {key: 3, value: 'year', label: "analysis.groupBy.YEAR"}
            ],
            saveBtn: 'init',
            deleteBtn: 'init'
        };
        ctrl.saves = {
            selected: currentAnalysis,
            saveFor: 'user',
            saveGroup: null,
            template: false,
            canSave: _canSaveState,
            save: _save,
            delete: _delete
        };
        ctrl.user = {
            isAdmin: isAdmin,
            current: user,
            groups: groupList
        };
        ctrl.datapoints = {
            list: [],
            currentId: -1,
            remove: _removeDataPoint,
            toggleAdd: function () {
                // here the value returned from backend api/uiRestrictions/getAnalysisDatapointLimit, if return an error is set to default value 10
                if (ctrl.datapoints.list.length < ctrl.datapointLimit) {
                    _showModal('scripts/app/analysis/detailed/selection.modal.html');
                } else {
                    var errorlist = $translate.instant('analysis.notification.error.datapointsLimit1') + ctrl.datapointLimit + $translate.instant('analysis.notification.error.datapointsLimit2');
                    Notify.error("global.notification.error.title", errorlist, 2000);
                }
            },
            canAdd: _canAddDataPoints,
            addPoints: _addDataPoints,
            getNextId: function () {
                return --ctrl.datapoints.currentId;
            }
        };
        ctrl.showAddpoint = false;
        ctrl.canExport = false;
        ctrl.canExportDisabled = false;
        ctrl.tree = {
            options: {
                multiSelection: true,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            model: {
                datapoints: [],
                current: [],
                selected: [],
                staticpoints: staticDataPoints
            }
        };
        ctrl.entityTree = {
            options: {
                multiSelection: false,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            entityModel: {
                entities: [],
                current: [],
                selected: [],
            }
        };

        ctrl.chart = {
            title: '',
            selectedHighDpObj: '',
            selectedLowDpObj: '',
            chartBoundsMax: null,
            chartBoundsMin: null,
            highLimitColor: '#ff0000',
            lowLimitColor: '#ff0000',
            selectedHighLimitDpId: '',
            selectedLowLimitDpId: '',
            hidden: true,
            showLimitLines: false,
            config: [],
            types: angular.copy(highchart.chartTypes),
            aggregationOptions: aggregationOptions,
            groupingOptions: groupingOptions,
            canGenerate: _chartCanGenerate,
            export: _chartExport,
            generate: _chartGenerate,
            setShowLimitLines: _setShowLimitLines,
            setHighLimitEditModel: _setHighLimitEditModel,
            setLowLimitEditModel: _setLowLimitEditModel
        };
        ctrl.highLowLimitEditModel = {
            selectedHighDpObj: '',
            selectedLowDpObj: '',
            highId: '',
            lowId: '',
            chartBoundsMax: null,
            chartBoundsMin: null
        };
        ctrl.cpdefault = {
            showInput: true,
            preferredFormat: 'hex',
            allowEmpty: false,
            clickoutFiresChange: true,
            cancelText: $translate.instant('global.btn.cancel'),
            chooseText: $translate.instant('global.btn.choose')
        };
        ctrl.modal = {
            open: _showModal,
            close: _closeModal
        };
        ctrl.tmp = {
            loadLocation: null,
            loadRoom: null,
            aggregation: ctrl.chart.aggregationOptions[0],
            grouping: ctrl.chart.groupingOptions[0],
            loadDatapoint: null
        };

        ctrl.isGeneratable = true;
        ctrl.locationSelectionBox = null;
        var saveTemplate = {
            id: null,
            title: null,
            userId: null,
            groupId: null,
            chartBoundsMax: null,
            chartBoundsMin: null,
            highLimitColor: '',
            lowLimitColor: '',
            selectedHighLimitDpId: '',
            selectedLowLimitDpId: '',
            showLimitLines: true,
            creatorId: null,
            dataPoints: [],
            dateRanges: []
        };
        var currentModal = null;
        var colorSupplier = new ColorSupplier();

        ctrl.$onInit = function () {

            Analysis.getDatapointLimit().then(function (response) {
                ctrl.datapointLimit = response.data;
            }, function (error) {
                $log.debug('No configuration found, set default value to 10', error);
                ctrl.datapointLimit = 10;
            });

            ctrl.tmp.chartType = ctrl.chart.types[0];
            _addDateRange();

            if (Tools.isDefinedNotNull($state.current.data) && Tools.isDefinedNotNull($state.current.data.delete)) {
                ctrl.options.delete = $state.current.data.delete;
            }
            if (Tools.isDefinedNotNull($state.params) && Tools.isDefinedNotNull($state.params.analysisId)
                && $state.params.analysisId !== '' && Tools.isDefinedNotNull(ctrl.saves.selected)) {
                _loadSavedState();
            } else {
                ctrl.options.edit = true;
            }

            var dps = Session.pop("analysis_datapoints");
            var drs = Session.pop("analysis_dateranges");
            if (Tools.isDefinedNotNull(dps) && Tools.isDefinedNotNull(drs) && Array.isArray(dps) && Array.isArray(drs)) {
                ctrl.datapoints.list = dps;
                ctrl.dateranges.list = drs;
            }
            initDateRange = angular.copy(ctrl.dateranges);
            initChart = angular.copy(ctrl.chart);
            initSaves = angular.copy(ctrl.saves);
            initTemplate = angular.copy(ctrl.template);
            if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                for (var i = 0; i < ctrl.saves.selected.dataPoints.length; i++) {
                    if (!Tools.isDefinedNotNull(ctrl.saves.selected.dataPoints[i].title)) {
                        if (Tools.isDefinedNotNull(ctrl.saves.selected.dataPoints[i].translation)) {
                            ctrl.saves.selected.dataPoints[i].title = $translate.instant(ctrl.saves.selected.dataPoints[i].translation);

                        }
                    }
                }
                initDatapointsList = angular.copy(ctrl.saves.selected.dataPoints);
            }
        };

        ctrl.compareDateRangeList = function (dateRangeA, dateRangeB) {
            if (dateRangeA.length <= 0 || dateRangeB.length <= 0) {
                return false;
            }
            var aProps = Object.getOwnPropertyNames(dateRangeA);
            var bProps = Object.getOwnPropertyNames(dateRangeB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            for (var i = 0; i < dateRangeA.length; i++) {
                if (dateRangeA[i] != null) {
                    if (ctrl.compareDate(dateRangeA[i]["start"]["_d"], dateRangeB[i]["start"]["_d"])) return true;
                    if (ctrl.compareDate(dateRangeA[i]["end"]["_d"], dateRangeB[i]["end"]["_d"])) return true;
                }
            }
        };

        ctrl.compareDate = function (dateA, dateB) {
            if (moment(dateA).isBefore(dateB) || moment(dateA).isAfter(dateB)) return true;
        };

        ctrl.compareTemplate = function (chartA, chartB) {
            if (chartA !== null) {
                if (chartA["active"] !== chartB["active"]) return true;
            }
        };

        ctrl.compareChart = function (chartA, chartB) {
            if (chartA !== null) {
                if (chartA["title"] !== chartB["title"]) return true;
            }
        };

        ctrl.compareSaves = function (savesA, savesB) {
            if (savesA !== null) {
                if (savesA["saveFor"] !== savesB["saveFor"]) return true;

                if (savesB["saveFor"] === "group") {
                    if (savesA["saveGroup"]["name"] !== savesB["saveGroup"]["name"]) return true;
                }
            }
            return false;
        };

        ctrl.compareDatapointList = function (chartA, chartB) {

            if (chartA === null && chartB === null) {
                return false;
            } else if (chartA === null || chartB === null) {
                return true;
            }
            if (Tools.isDefinedNotNull(chartA)) {
                chartA.sort(function (a, b) {
                    return a.id - b.id;
                });
                chartB.sort(function (a, b) {
                    return a.id - b.id;
                });

                if (chartA !== null) {
                    var aProps = Object.getOwnPropertyNames(chartA);
                    var bProps = Object.getOwnPropertyNames(chartB);

                    if (aProps.length !== bProps.length) {
                        return true;
                    }

                    for (var i = 0; i < chartB.length; i++) {

                        if (chartA[i].title !== chartB[i].title) return true;
                        if (chartA[i].aggregation !== chartB[i].aggregation) return true;
                        if (chartA[i].grouping !== chartB[i].grouping) return true;
                        if (chartA[i].type !== chartB[i].type) return true;
                        if (chartA[i].color !== chartB[i].color) return true;
                    }
                }
            }
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalAnalysisChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalAnalysisChange = function () {

            //Todo: switch to Dynamic, Start and End Value of time ranges

            var currDateRange = angular.copy(ctrl.dateranges);
            var currChart = angular.copy(ctrl.chart);
            var currSaves = angular.copy(ctrl.saves);
            var currTemplate = angular.copy(ctrl.template);
            var currDatapointList = angular.copy(ctrl.datapoints.list);
            if (initDateRange !== null) {
                if (ctrl.compareDateRangeList(initDateRange["list"], currDateRange["list"])) return true;
            }
            if (ctrl.compareChart(initChart, currChart)) return true;
            if (ctrl.compareSaves(initSaves, currSaves)) return true;
            if (ctrl.compareTemplate(initTemplate, currTemplate)) return true;
            if (ctrl.tmp.loadLocation !== null && currDatapointList.length !== 0) {
                if (ctrl.compareDatapointList(initDatapointsList, currDatapointList)) return true;
            }
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams, fromState) {
            if (fromState.name !== "analysis.detailed.view") {
                if (ctrl.evalAnalysisChange() && !ignoreNextStateChange) {
                    event.preventDefault();
                    GenDialogService.showDialog(false, {
                        headText: $translate.instant('global.dialog.head.warning'),
                        headIcon: 'glyphicon glyphicon-warning-sign',
                        messageText: $translate.instant('global.modal.changedValues'),
                        showClose: false,
                        textButton1: $translate.instant('global.modal.empty.yes'),
                        textButton0: $translate.instant('global.modal.empty.no'),
                        iconButton1: 'glyphicon glyphicon-trash',
                        iconButton0: 'glyphicon glyphicon-chevron-left',
                        classButton1: 'btn-danger',
                        classButton0: 'btn-default',
                        callbackButton1: function () {
                            GenDialogService.hideDialog();
                            ignoreNextStateChange = true;
                            initDateRange = null;
                            initChart = null;
                            initSaves = null;
                            initTemplate = null;
                            initDatapointsList = null;
                            $state.go(toState.name, toParams);
                        },
                        callbackButton0: function () {
                            GenDialogService.hideDialog();
                        }
                    });
                }
            }
        });

        // ctrl.dateranges.setDynamicRange()
        // When dynamicrange is set (x days/weeks/months/years) (en/dis)able different groupings.
        function _setDynamicRange() {
            switch (ctrl.dateranges.dynamicRange.range) {
                case 1:
                    _enabledRangeValues(true, true, false, false);
                    ctrl.dateranges.dynamicRange.scale = 0;
                    ctrl.maxLast = 56;
                    break;
                case 2:
                    _enabledRangeValues(false, true, true, false);
                    ctrl.dateranges.dynamicRange.scale = 1;
                    ctrl.maxLast = 12;
                    break;
                case 3:
                    _enabledRangeValues(false, false, true, true);
                    ctrl.dateranges.dynamicRange.scale = 2;
                    ctrl.maxLast = 730;
                    break;
                case 0:
                default:
                    _enabledRangeValues(true, false, false, false);
                    ctrl.dateranges.dynamicRange.scale = 0;
                    ctrl.maxLast = 365;
            }

            function _enabledRangeValues(day, week, month, year) {
                ctrl.dateranges.dynamicRange.scaleValues[0].enabled = day;
                ctrl.dateranges.dynamicRange.scaleValues[1].enabled = week;
                ctrl.dateranges.dynamicRange.scaleValues[2].enabled = month;
                ctrl.dateranges.dynamicRange.scaleValues[3].enabled = year;
            }
        }

        // ctrl.dateranges.canSplit()
        function _canSplitFixedDateRange(start, end) {
            var diff = Math.abs(moment(start).diff(end));
            return (diff - 1) > (24 * 60 * 60 * 1000) && moment(start).isBefore(end); // 1ms tolerance
        }

        function _addDateRange() {
            var today = moment(moment().format('YYYY-MM-DD'));
            ctrl.dateranges.list.push({
                start: moment(moment().subtract(1, 'week').format('YYYY-MM-DD')).startOf('day'),
                end: moment(today).endOf('day')
            });
        }

        function _removeDaterange(date) {
            var index = ctrl.dateranges.list.indexOf(date);
            if (index > -1) {
                ctrl.dateranges.list.splice(index, 1);
            }
        }

        function _setRangeStyle(b) {
            ctrl.dateranges.fixed = b;
            _toggleRangeStyle();
        }

        function _setShowLimitLines(b) {
            ctrl.chart.showLimitLines = b;
        }

        function _setHighLimitEditModel() {
            if (ctrl.highLowLimitEditModel.selectedHighDpObj === "" || ctrl.highLowLimitEditModel.selectedHighDpObj === null) {
                ctrl.highLowLimitEditModel.chartBoundsMax = 0;
            } else {
                ctrl.chart.selectedHighDpObj = ctrl.highLowLimitEditModel.selectedHighDpObj;
                ctrl.highLowLimitEditModel.highId = ctrl.highLowLimitEditModel.selectedHighDpObj.parameter.DRIVERVALUE_UID; // driver value unique id as highId of selected datapoint
                _setDpChartBoundsMax(ctrl.highLowLimitEditModel.selectedHighDpObj);
                ctrl.chart.selectedHighLimitDpId = ctrl.highLowLimitEditModel.highId; // ctrl.chart.selectedHighLimitDpId will be saved into analysis table
            }

        }

        function _setDpChartBoundsMax(selectedDPObj) {
            // when creation of new analysis, datapoints contains limits which will used to set the chartBoundsMax
            if (Tools.isDefinedNotNull(selectedDPObj.datapoints)) {
                for (var i = 0; i < selectedDPObj.datapoints.length; i++) {
                    if (selectedDPObj.parameter.HASH === selectedDPObj.datapoints[i].hash) {
                        var limitsMaxMin = _getLimitsMaxMix(selectedDPObj.datapoints[i].limits);
                        //ctrl.highLowLimitEditModel.chartBoundsMax = limitsMaxMin[0] / 1000;
                        if (Tools.isDefinedNotNull(limitsMaxMin)) {
                            ctrl.highLowLimitEditModel.chartBoundsMax = limitsMaxMin[0];
                        }
                    }
                }
            }
            // when edit a saved analysis:
            else {
                //when dataponts=== null, set chart bounds max by high limit value
                if (selectedDPObj.highLimit !== null) {
                    ctrl.highLowLimitEditModel.chartBoundsMax = selectedDPObj.highLimit;
                } else { //when high limit of the selected dp === null, set default as 0 (changeable if needed based on requirement)
                    ctrl.highLowLimitEditModel.chartBoundsMax = 0;
                }
            }
        }

        function _setDpChartBoundsMin(selectedDPObj) {
            // when creation of new analysis, datapoints contains limits which will used to set the chartBoundsMin
            if (Tools.isDefinedNotNull(selectedDPObj.datapoints)) {
                for (var i = 0; i < selectedDPObj.datapoints.length; i++) {
                    if (selectedDPObj.parameter.HASH === selectedDPObj.datapoints[i].hash) {
                        var limitsMaxMin = _getLimitsMaxMix(selectedDPObj.datapoints[i].limits);
                        if (Tools.isDefinedNotNull(limitsMaxMin)) {
                            ctrl.highLowLimitEditModel.chartBoundsMin = limitsMaxMin[1];
                        }

                    }
                }
            }
            // when edit a saved analysis:
            else {
                //when dataponts=== null, set chart bounds min by low limit value
                if (selectedDPObj.lowLimit !== null) {
                    ctrl.highLowLimitEditModel.chartBoundsMin = selectedDPObj.lowLimit;
                } else { // when low limit of the selected dp === null, set default as 0 (changeable if needed based on requirement)
                    ctrl.highLowLimitEditModel.chartBoundsMin = 0;
                }
            }
        }

        /**
         * One datapoint might be able to have more than one driver value limit.
         * @param limitsList, the list of all limits of the selected datapoint.
         * @returns a list, which contain the max value of all the limits on the first postion, and min value on the second position.
         * @private
         */
        function _getLimitsMaxMix(limitsList) {
            if (Tools.isDefinedNotNull(limitsList)) {
                var out = [];
                var max, min;
                if (limitsList.length === 0) {
                    max = 0;
                    min = 0;
                } else {
                    var l = [];
                    for (var i = 0; i < limitsList.length; i++) {
                        l.push(limitsList[i].value);
                    }
                    max = Math.max.apply(null, l);
                    min = Math.min.apply(null, l);
                }
                out.push(max);
                out.push(min);
                return out;
            }
        }

        function _setLowLimitEditModel() {
            if (ctrl.highLowLimitEditModel.selectedLowDpObj === "" || ctrl.highLowLimitEditModel.selectedLowDpObj === null) {
                ctrl.highLowLimitEditModel.chartBoundsMin = 0;
            } else {
                ctrl.chart.selectedLowDpObj = ctrl.highLowLimitEditModel.selectedLowDpObj;
                ctrl.highLowLimitEditModel.lowId = ctrl.highLowLimitEditModel.selectedLowDpObj.parameter.DRIVERVALUE_UID;
                _setDpChartBoundsMin(ctrl.highLowLimitEditModel.selectedLowDpObj);
                ctrl.chart.selectedLowLimitDpId = ctrl.highLowLimitEditModel.lowId;
            }

        }

        // ctrl.dateranges.toggleDynamic()
        function _toggleRangeStyle() {
            ctrl.dateranges.list = [];
            if (!ctrl.dateranges.fixed) {
                ctrl.dateranges.dynamicRange.range = 1;
                ctrl.dateranges.dynamicRange.value = 1;
                _setDynamicRange();
            } else {
                ctrl.dateranges.dynamicRange.range = null;
                ctrl.dateranges.dynamicRange.value = null;
                ctrl.dateranges.dynamicRange.scale = null;
                _addDateRange();
            }
        }

        // ctrl.saves.canSave()
        function _canSaveState() {
            var result = (ctrl.template.active) ? true : ctrl.chart.canGenerate();
            result = result && Tools.isDefinedNotNull(ctrl.chart.title) && ctrl.chart.title !== '';
            if (ctrl.saves.saveFor === 'group') {
                result = result && Tools.isDefinedNotNull(ctrl.saves.saveGroup);
            }
            if (ctrl.dateranges.fixed) {
                result = result && (ctrl.dateranges.list.length > 0);
            } else {
                result = result && Tools.isDefinedNotNull(ctrl.dateranges.dynamicRange.value)
                    && Tools.isDefinedNotNull(ctrl.dateranges.dynamicRange.range)
                    && Tools.isDefinedNotNull(ctrl.dateranges.dynamicRange.scale)
                    && ctrl.dateranges.dynamicRange.value > -1
                    && ctrl.dateranges.dynamicRange.range > -1
                    && ctrl.dateranges.dynamicRange.scale > -1;
            }

            if (ctrl.chart.showLimitLines) {
                result = result && !(ctrl.chart.showLimitLines);

                if (ctrl.highLowLimitEditModel.selectedHighDpObj !== "" && ctrl.highLowLimitEditModel.selectedLowDpObj !== "") {
                    result = true;
                }
                if (ctrl.highLowLimitEditModel.selectedHighDpObj === null || ctrl.highLowLimitEditModel.selectedLowDpObj === null) {
                    result = false;
                }
                if(ctrl.chart.showLimitLines && (ctrl.highLowLimitEditModel.selectedHighDpObj === "" || ctrl.highLowLimitEditModel.selectedLowDpObj === "")) {
                    result = false;
                }
            }
            if(ctrl.dateranges.list.length > 0){
                if(moment(ctrl.dateranges.list[0].end).isBefore(ctrl.dateranges.list[0].start))
                {
                    result = false;
                }
            }
            if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                if (Tools.isDefinedNotNull(ctrl.user.current)) {
                    if (ctrl.saves.selected.creatorId !== ctrl.user.current.id && !ctrl.user.isAdmin) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            return result;
        }

        // ctrl.saves.load()
        function _loadSavedState() {
            var promises = [];
            if (Tools.isDefinedNotNull($state.current) && Tools.isDefinedNotNull($state.current.data) && Tools.isDefinedNotNull($state.current.data.edit)) {
                ctrl.options.edit = $state.current.data.edit;
            } else {
                ctrl.options.edit = false;
            }
            var state = ctrl.saves.selected;
            var i = 0;
            if (Tools.isDefinedNotNull(state.groupId) && state.groupId > -1) {
                ctrl.saves.saveFor = 'group';
                // ctrl.user.groups
                // ctrl.saves.saveGroup
                for (i = 0; i < ctrl.user.groups.length; i++) {
                    if (ctrl.user.groups[i].id === state.groupId) {
                        ctrl.saves.saveGroup = ctrl.user.groups[i];
                    }
                }
            } else if (Tools.isDefinedNotNull(state.userId) && state.userId > -1) {
                ctrl.saves.saveFor = 'user';
            } else {
                ctrl.saves.saveFor = 'system';
            }
            //load high-low limit related
            ctrl.chart.chartBoundsMax = state.chartBoundsMax;
            ctrl.chart.highLimitColor = state.highLimitColor;
            ctrl.chart.chartBoundsMin = state.chartBoundsMin;
            ctrl.chart.lowLimitColor = state.lowLimitColor;
            ctrl.chart.selectedHighLimitDpId = state.selectedHighLimitDpId; // driver value unique id of high limit selected datapoint
            ctrl.chart.selectedLowLimitDpId = state.selectedLowLimitDpId; // driver value unique id of low limit selected datapoint
            ctrl.chart.showLimitLines = state.showLimitLines;
            if (state.selectedHighLimitDpId !== null) {
                for (var x = 0; x < state.dataPoints.length; x++) {
                    if (ctrl.chart.selectedHighLimitDpId === parseInt(state.dataPoints[x].parameter.DRIVERVALUE_UID)) {
                        ctrl.chart.selectedHighDpObj = state.dataPoints[x];
                    }
                }
            }
            if (state.selectedLowLimitDpId !== null) {
                for (var y = 0; y < state.dataPoints.length; y++) {
                    if (ctrl.chart.selectedLowLimitDpId === parseInt(state.dataPoints[y].parameter.DRIVERVALUE_UID)) {
                        ctrl.chart.selectedLowDpObj = state.dataPoints[y];
                    }
                }
            }

            //convert to the ctrl.highLowLimitEditModel
            ctrl.highLowLimitEditModel.chartBoundsMax = ctrl.chart.chartBoundsMax;
            ctrl.highLowLimitEditModel.chartBoundsMin = ctrl.chart.chartBoundsMin;
            ctrl.highLowLimitEditModel.highId = ctrl.chart.selectedHighLimitDpId;
            ctrl.highLowLimitEditModel.lowId = ctrl.chart.selectedLowLimitDpId;
            ctrl.highLowLimitEditModel.selectedHighDpObj = ctrl.chart.selectedHighDpObj;
            ctrl.highLowLimitEditModel.selectedLowDpObj = ctrl.chart.selectedLowDpObj;


            // Load dateranges
            ctrl.dateranges.list = [];
            if (state.dateRanges.length === 1 && state.dateRanges[0].dynamicRange !== null) {
                ctrl.dateranges.fixed = false;
                ctrl.dateranges.dynamicRange.range = state.dateRanges[0].dynamicRange;
                ctrl.dateranges.dynamicRange.scale = state.dateRanges[0].dynamicScale;
                ctrl.dateranges.dynamicRange.value = state.dateRanges[0].dynamicValue;
            } else {
                ctrl.dateranges.fixed = true;
                for (i = 0; i < state.dateRanges.length; i++) {
                    ctrl.dateranges.list.push({
                        start: moment(state.dateRanges[i].start),
                        end: moment(state.dateRanges[i].end)
                    });
                }
            }

            // Locations & datapoints
            ctrl.datapoints.list = [];
            var currentDps = angular.copy(state.dataPoints);
            for (var j = 0; j < currentDps.length; j++) {
                promises.push(_addLoadedDataPoint(currentDps[j]));
            }
            ctrl.template.active = state.template;
            if (state.template) {
                _selectStaticDataPointSet();
            }
            ctrl.chart.title = ctrl.saves.selected.title;
            ctrl.saves.showLoadDialog = false;
            if (!Tools.isDefinedNotNull(state.template) || !state.template) {
                $q.all(promises).then(function () {
                    ctrl.chart.generate();
                });
            }

            function _addLoadedDataPoint(dp) {
                var defer = $q.defer();
                if (!Tools.isDefinedNotNull(dp.title)) {
                    $translate(dp.translation, dp.translationParams).then(function (result) {
                        dp.title = result;
                        if (!Tools.isDefinedNotNull(dp.color)) {
                            dp.color = colorSupplier.next(); //ctrl.colors.nextColor();
                        }
                        ctrl.datapoints.list.push(dp);
                        defer.resolve();
                    });
                } else {
                    if (!Tools.isDefinedNotNull(dp.color)) {
                        dp.color = colorSupplier.next(); //ctrl.colors.nextColor();
                    }
                    ctrl.datapoints.list.push(dp);
                    defer.resolve();
                }
                return defer.promise;
            }

        }

        // ctrl.datapoints.canAdd()
        function _canAddDataPoints() {
            return (Tools.isDefinedNotNull(ctrl.tmp.loadLocation)
                    && Tools.isDefinedNotNull(ctrl.tree.model.selected)
                    && ctrl.tree.model.selected.length > 0)
                || (
                    ctrl.template.active
                    && Tools.isDefinedNotNull(ctrl.tree.model.selected)
                    && ctrl.tree.model.selected.length > 0);
        }

        // ctrl.datapoints.addPoints()
        function _addDataPoints() {
            // here the value returned from backend api/uiRestrictions/getAnalysisDatapointLimit, if return an error is set to default value 10
            if (ctrl.datapoints.list.length + ctrl.tree.model.selected.length > ctrl.datapointLimit) {
                var errorlist = $translate.instant('analysis.notification.error.datapointsLimit1') + ctrl.datapointLimit + $translate.instant('analysis.notification.error.datapointsLimit2');
                Notify.error("global.notification.error.title", errorlist, 2000);
                return;
            }

            _closeModal();
            for (var i = 0; i < ctrl.tree.model.selected.length; i++) {
                var dp = angular.copy(ctrl.tree.model.selected[i]);
                delete dp.children;
                if (!Tools.isDefinedNotNull(dp.title)) {
                    _translateAddDataPoint(dp);
                } else {
                    _addDataPoint(dp);
                }
            }
            ctrl.tree.model.selected = [];

            function _translateAddDataPoint(dp) {
                $translate(dp.translation, dp.translationParams).then(function (result) {
                    dp.title = result;
                    _addDataPoint(dp);
                });
            }

            function _addDataPoint(dp) {
                dp.id = ctrl.datapoints.getNextId();
                dp.locationName = _filterLocation(dp.locationId);
                dp.aggregation = ctrl.tmp.aggregation;
                dp.grouping = ctrl.tmp.grouping;
                dp.type = ctrl.tmp.chartType;
                dp.color = colorSupplier.next(); //ctrl.colors.nextColor();
                ctrl.datapoints.list.push(dp);
            }
        }

        function _filterLocation(locaionId) {
            for (var i = 0; i < ctrl.locations.available.length; i++) {
                if (ctrl.locations.available[i].id == locaionId) {
                    return ctrl.locations.available[i].name;
                }
            }
        }

        // ctrl.saves.delete()
        // Delete analysis
        function _delete() {
            ctrl.options.deleteBtn = 'load';
            Analysis.remove(ctrl.saves.selected.id).then(function () {
                ctrl.options.deleteBtn = 'success';
                $timeout(function () {
                    $state.go('analysis.overview');
                }, 3000);
            }, function (error) {
                ctrl.options.deleteBtn = 'error';
                $timeout(function () {
                    ctrl.options.deleteBtn = 'init';
                }, 3000);
                $log.error('Couldn\'t delete analysis ' + ctrl.saves.selected.id, error);
            })
        }

        ctrl.entityFilterChange = function (searchTerm) {
            ctrl.getRoomEntities();
            if (searchTerm === undefined || searchTerm === null || searchTerm === "") {
                ctrl.validSearchTerm = false;
            } else {
                ctrl.validSearchTerm = true;
                ctrl.filterEntityNode(searchTerm);
            }
        }
        ctrl.filterEntityNode = function (searchTerm) {
            ctrl.entityTree.entityModel.entities = filter(ctrl.entityTree.entityModel.current, searchTerm.toLowerCase());
        }

        ctrl.dpFilterChange = function (dpSearchTerm) {
            ctrl.tree.model.datapoints = ctrl.searchReference;
            if (dpSearchTerm === undefined || dpSearchTerm === null || dpSearchTerm === "") {
                ctrl.validDpSearchTerm = false;
            } else {
                ctrl.validDpSearchTerm = true;
                ctrl.filterDpNode(dpSearchTerm);
            }
        }
        ctrl.filterDpNode = function (dpSearchTerm) {
            ctrl.tree.model.datapoints = filter(ctrl.tree.model.datapoints, dpSearchTerm.toLowerCase());
        }

        ctrl.checkTimeValidity = function (start, end) {
            if(moment(end).isBefore(start)) {
                ctrl.timeInvalid = true;
            } else {
                ctrl.timeInvalid = false;
            }
        }

        function filter(array, text) {
            var getNodes = function getFilteredNodes(result, object) {
                if (object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    return result;
                }
                if (Array.isArray(object.children)) {
                    var children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }

        // ctrl.saves.save()
        function _save() {
            if (!_canSaveState()) {
                return;
            }
            ctrl.options.saveBtn = 'load';
            var currentSave = null;
            if (Tools.isDefinedNotNull(ctrl.saves.selected) && ctrl.saves.selected.id > -1) {
                currentSave = angular.copy(ctrl.saves.selected);
            } else {
                currentSave = angular.copy(saveTemplate);
            }

            currentSave.title = ctrl.chart.title;
            currentSave.showLimitLines = ctrl.chart.showLimitLines;
            //currentSave.chartBoundsMax=ctrl.chart.chartBoundsMax*1000; //2save the GUI inputted high limit into col chart_bounds_max of table "analysis"
            if (ctrl.chart.chartBoundsMax !== ctrl.highLowLimitEditModel.chartBoundsMax) {
                currentSave.chartBoundsMax = ctrl.highLowLimitEditModel.chartBoundsMax;
            } else {
                currentSave.chartBoundsMax = ctrl.chart.chartBoundsMax;
            }
            currentSave.highLimitColor = ctrl.chart.highLimitColor; //2save highLimitColor

            if (ctrl.chart.selectedHighDpObj === null || (!Tools.isDefinedNotNull(ctrl.chart.selectedHighDpObj)) || ctrl.chart.selectedHighDpObj === "") {
                currentSave.selectedHighLimitDpId = null;
            } else {
                currentSave.selectedHighLimitDpId = ctrl.chart.selectedHighDpObj.parameter.DRIVERVALUE_UID;
            }

            //currentSave.chartBoundsMin=ctrl.chart.chartBoundsMin*1000; //2save the GUI inputted low limit into col chart_bounds_min of table "analysis"
            if (ctrl.chart.chartBoundsMin !== ctrl.highLowLimitEditModel.chartBoundsMin) {
                currentSave.chartBoundsMin = ctrl.highLowLimitEditModel.chartBoundsMin;
            } else {
                currentSave.chartBoundsMin = ctrl.chart.chartBoundsMin;
            }

            currentSave.lowLimitColor = ctrl.chart.lowLimitColor; // 2save lowLimitColor
            if (ctrl.chart.selectedLowDpObj === null || (!Tools.isDefinedNotNull(ctrl.chart.selectedLowDpObj)) || ctrl.chart.selectedLowDpObj === "") {
                currentSave.selectedLowLimitDpId = null;
            } else {
                currentSave.selectedLowLimitDpId = ctrl.chart.selectedLowDpObj.parameter.DRIVERVALUE_UID;
            }

            currentSave.template = ctrl.template.active;
            currentSave.dataPoints = [];
            currentSave.dateRanges = [];
            for (var i = 0; i < ctrl.datapoints.list.length; i++) {
                var dp = angular.copy(ctrl.datapoints.list[i]);
                if (ctrl.template.active) {
                    // delete dp.MAC;
                    delete dp.parameter.MAC;
                    dp.locationId = null;
                    dp.locationName = null;
                }
                // dp.format = angular.toJson(dp.format);
                delete dp.device;
                currentSave.dataPoints.push(dp);
            }
            if (ctrl.dateranges.fixed) {
                for (var j = 0; j < ctrl.dateranges.list.length; j++) {
                    currentSave.dateRanges.push(angular.copy(ctrl.dateranges.list[j]));
                }
            } else {
                currentSave.dateRanges.push({
                    start: null,
                    end: null,
                    dynamicRange: ctrl.dateranges.dynamicRange.range,
                    dynamicValue: ctrl.dateranges.dynamicRange.value,
                    dynamicScale: ctrl.dateranges.dynamicRange.scale
                });
            }

            if (ctrl.saves.saveFor === 'group') {
                if (Tools.isDefinedNotNull(ctrl.saves.saveGroup) && ctrl.saves.saveGroup.id > -1) {
                    currentSave.groupId = ctrl.saves.saveGroup.id;
                    currentSave.userId = null;
                }
            } else if (ctrl.saves.saveFor === 'system') {
                currentSave.groupId = null;
                currentSave.userId = null;
            } else {
                currentSave.userId = ctrl.user.current.id;
            }

            if (Tools.isDefinedNotNull(currentSave.title) && currentSave.title !== '') {
                Analysis.save(currentSave).then(function (response) {
                    ctrl.options.saveBtn = 'success';
                    currentSave.id = response.data;
                    ctrl.saves.selected = currentSave;
                    Notify.defaultSuccess();
                    ctrl.options.saveBtn = 'init';
                    $state.go('analysis.detailed.edit', {analysisId: currentSave.id});
                }, function (error) {
                    ctrl.options.saveBtn = 'error';
                    Notify.defaultError();
                    $log.error('Couldn\'t save analysis!', error);
                })
            }
            initDateRange = angular.copy(ctrl.dateranges);
            initChart = angular.copy(ctrl.chart);
            initSaves = angular.copy(ctrl.saves);
            initTemplate = angular.copy(ctrl.template);
            initDatapointsList = angular.copy(ctrl.datapoints.list);
            ctrl.chart.generate();
        }

        // ctrl.datapoints.remove()
        function _removeDataPoint(dp) {
            for (var i = 0; i < ctrl.datapoints.list.length; i++) {
                if (ctrl.datapoints.list[i].id === dp.id) {
                    ctrl.tmp.loadLocation = ctrl.datapoints.list[i];
                    ctrl.datapoints.list.splice(i, 1);
                    ctrl.tmp.loadLocation = null;
                    ctrl.tmp.loadRoom = null;
                    ctrl.entityTree.entityModel.selected = null;
                    ctrl.tree.model.current = [];
                }
            }
        }

        ctrl.getRoomEntities = function () {
            ctrl.tree.model.datapoints = [];
            ctrl.dpSearchTerm = undefined;
            ctrl.isEntitySelected = false;
            ctrl.isRoomSelected = true;
            ctrl.entitySpinnerDisplay = true;
            if (Tools.isDefinedNotNull(ctrl.tmp.loadRoom)) {
                $http.get('api/rooms/getRoomEntitiesByRoomId/' + ctrl.tmp.loadRoom.id).then(function (response) {
                    ctrl.entityTree.entityModel.entities = response.data;
                    ctrl.entityTree.entityModel.current = response.data;
                });
            }
            ctrl.entitySpinnerDisplay = false;
        }

        ctrl.displayEntityDataPoints = function (node, selected) {
            ctrl.isEntitySelected = selected;
            if (selected) {
                ctrl.entityTree.entityModel.selected = node;
                ctrl.dpSearchTerm = undefined;
                ctrl.tree.model.datapoints = [];
                ctrl.selectedEntity = node;
                var currentRoom = ctrl.tmp.loadRoom;
                ctrl.dpSpinnerDisplay = true;
                if (ctrl.selectedEntity.key === "cpus") {
                    $http.post('api/rooms/getDriverValuesForCpu',
                        {
                            "roomId": currentRoom.id,
                            "rackId": ctrl.selectedEntity.rackId,
                            "slotId": ctrl.selectedEntity.slotId,
                            "cpuId": ctrl.selectedEntity.id,
                        }).then(function (response) {
                        ctrl.tree.model.datapoints = response.data;
                        ctrl.searchReference = ctrl.tree.model.datapoints;
                        for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                            ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                        }
                        _prepareDpSet();
                        ctrl.dpSpinnerDisplay = false;
                    });
                } else if (ctrl.selectedEntity.key === "slots") {
                    $http.post('api/rooms/getDriverValuesForSlot',
                        {
                            "roomId": currentRoom.id,
                            "rackId": ctrl.selectedEntity.rackId,
                            "slotId": ctrl.selectedEntity.id,
                        }).then(function (response) {
                        ctrl.tree.model.datapoints = response.data;
                        ctrl.searchReference = ctrl.tree.model.datapoints;
                        for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                            ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                        }
                        _prepareDpSet();
                        ctrl.dpSpinnerDisplay = false;
                    });
                } else {
                    $http.post('api/rooms/getDriverValuesForEntity',
                        {
                            "entityId": ctrl.selectedEntity.id,
                            "roomId": currentRoom.id,
                            "entityType": ctrl.selectedEntity.key,
                        }).then(function (response) {
                        ctrl.tree.model.datapoints = response.data;
                        ctrl.searchReference = ctrl.tree.model.datapoints;
                        for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                            ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                        }
                        _prepareDpSet();
                        ctrl.dpSpinnerDisplay = false;
                    });
                }
            } else {
                ctrl.tree.model.datapoints = [];
                ctrl.searchTerm = undefined;
                ctrl.dpSearchTerm = undefined;
            }
        }

        function _prepareDpSet() {
            var defer = $q.defer();
            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                if (ctrl.template.active) {
                    var dps = {
                        location: ctrl.tree.model.datapoints[i].location,
                        datapoints: ctrl.tree.model.datapoints[i].datapoints
                    };
                    ctrl.tree.model.current = angular.copy(dps);
                } else {
                    ctrl.tree.model.current = ctrl.tree.model.datapoints[i];
                }
                defer.resolve();
            }
        }

        ctrl.enableRoomSelection = function () {
            ctrl.searchTerm = undefined;
            ctrl.dpSearchTerm = undefined;
            ctrl.isEntitySelected = false;
            ctrl.isRoomSelected = false;
            ctrl.tmp.loadRoom = null;
            ctrl.entityTree.entityModel.selected = null;
            ctrl.tree.model.datapoints = [];
            ctrl.entityTree.entityModel.entities = []
            ctrl.rooms = [];
            if (Tools.isDefinedNotNull(ctrl.tmp.loadLocation)) {
                $http.get('api/rooms/getRoomById/' + ctrl.tmp.loadLocation.id).then(function (response) {
                    ctrl.rooms = response.data;
                    if (!ctrl.rooms.length) {
                        ctrl.roomsAvailable = false;
                        _selectDataPointSet(ctrl.tmp.loadLocation.id, undefined);
                    } else {
                        ctrl.roomsAvailable = true;
                    }
                });
            }
        }

        function updateTreeModel() {
            if (ctrl.locationSelectionBox !== null && ctrl.locationSelectionBox.disabled && ctrl.roomSelectionBox.disabled) {
                $timeout(function () {
                    $scope.$apply(function () {
                        ctrl.tree.model.current.datapoints;
                        ctrl.locationSelectionBox.disabled = false;
                        if (ctrl.roomsAvailable) {
                            ctrl.roomSelectionBox.disabled = false;
                        }
                        ctrl.isLocationSelected = true;
                        ctrl.spinnerDisplay = false;
                    });
                }, 1000);
            }
        }

        function _cancelTemplateActivation() {
            ctrl.template.active = false;
            currentModal.close();
        }

        function _doTemplateActivation() {
            ctrl.datapoints.list = _filterNonStatic(ctrl.datapoints.list);
            _selectStaticDataPointSet();
            currentModal.close();
        }

        // Toggle the template mode and filter out any non-static nodes from the currently selected datapoints
        function _toggleTemplate() {
            if (ctrl.template.active) {
                _showModal("scripts/app/analysis/detailed/confirm.modal.html");
            } else {
                if (ctrl.tmp.loadLocation) {
                    _selectDataPointSet(ctrl.tmp.loadLocation.id);
                } else {
                    ctrl.tree.model.current = {
                        location: null,
                        datapoints: []
                    };
                    ctrl.datapoints.list = [];
                }
            }
        }

        function _selectStaticDataPointSet() {
            ctrl.tree.model.current = {
                location: null,
                datapoints: angular.copy(ctrl.tree.model.staticpoints)
            };
        }

        // ctrl.template.setLocation()
        function _setTemplateLocation() {
            for (var i = 0; i < ctrl.datapoints.list.length; i++) {
                ctrl.datapoints.list[i].device = _getDevice(ctrl.datapoints.list[i].hwtyp);
            }
            _checkGenerate();

            function _getDevice(hwtyp) {
                for (var i = 0; i < ctrl.locations.devices.length; i++) {
                    var device = ctrl.locations.devices[i];
                    if (device.locationId === ctrl.template.location.id && device.hwtyp.id === hwtyp.id) {
                        return device;
                    }
                }
                return null;
            }
        }

        // ctrl.template.checkGenerate()
        // Check if the template is missing devices
        function _checkGenerate() {
            ctrl.template.generatable = _canGenerateTemplate();
        }

        // Look in all available sets if they're already loaded and load them (if not)
        function _selectDataPointSet(locationId, roomId) {
            var defer = $q.defer();
            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                if (ctrl.tree.model.datapoints[i].location === locationId) {
                    if (ctrl.template.active) {
                        var dps = {
                            location: ctrl.tree.model.datapoints[i].location,
                            datapoints: ctrl.tree.model.datapoints[i].datapoints
                        };
                        ctrl.tree.model.current = angular.copy(dps);
                    } else {
                        ctrl.tree.model.current = ctrl.tree.model.datapoints[i];
                    }
                    defer.resolve();
                }
            }
            //todo call this, whenever a room is available
            if (Tools.isDefinedNotNull(roomId)) {
                digiChart.getAvailableSensorsbyRoom(locationId, roomId, true).then(function (response) {
                    var resultantData = [];
                    for (var i = 0; i < response.data.length; i++) {
                        resultantData.push(JSON.parse(response.data[i]));
                    }

                    var dpset = {
                        location: locationId,
                        datapoints: resultantData
                    };
                    ctrl.tree.model.datapoints.push(dpset);
                    ctrl.tree.model.current = dpset;
                    ctrl.tmp.loadDatapoint = resultantData;
                    defer.resolve();
                    updateTreeModel();
                });
                return defer.promise;
            } else {
                digiChart.getAvailableSensors(locationId, true).then(function (response) {
                    var resultantData = [];
                    for (var i = 0; i < response.data.length; i++) {
                        resultantData.push(JSON.parse(response.data[i]));
                    }

                    var dpset = {
                        location: locationId,
                        datapoints: resultantData
                    };
                    ctrl.tree.model.datapoints.push(dpset);
                    ctrl.tree.model.current = dpset;
                    ctrl.tmp.loadDatapoint = resultantData;
                    defer.resolve();
                    updateTreeModel();
                });
                return defer.promise;
            }
        }

        // ctrl.chart.canGenerate()
        // Check if everything is set to generate the chart
        function _chartCanGenerate() {
            if (!ctrl.isGeneratable) return false;
            return Tools.isDefinedNotNull(ctrl.datapoints.list)
                && ctrl.datapoints.list.length > 0
                && _canGenerateDateRanges()
                && _canGenerateTemplate();
        }

        function _canGenerateDateRanges() {
            return (ctrl.dateranges.fixed && Tools.isDefinedNotNull(ctrl.dateranges.list) && ctrl.dateranges.list.length > 0)
                || (!ctrl.dateranges.fixed && ctrl.dateranges.dynamicRange.value > 0 && ctrl.dateranges.dynamicRange.scale > -1 && ctrl.dateranges.dynamicRange.range > -1);
        }

        // ctrl.chart.canGenerateTemplate()
        function _canGenerateTemplate() {
            if (!ctrl.template.active) return true;
            var result = true;
            for (var i = 0; i < ctrl.datapoints.list.length; i++) {
                result = result && Tools.isDefinedNotNull(ctrl.datapoints.list[i].device);
            }
            return result;
        }

        // Take the dynamicRange parameters from ctrl.dateranges and compute the desired dateranges on demand.
        function _generateDynamicDateRanges() {
            if (ctrl.dateranges.fixed) {
                return ctrl.dateranges.list;
            } else {
                var result = [];
                var now = moment();
                var range = ctrl.options.splitValues[ctrl.dateranges.dynamicRange.range].value;
                var scale = ctrl.options.splitValues[ctrl.dateranges.dynamicRange.scale].value;

                var i = ctrl.dateranges.dynamicRange.value;
                var startDate = moment(now).subtract(i, range);
                while (startDate.format('X') < now.format('X')) {
                    var current = moment(startDate).add(1, scale);
                    result.push({
                        start: startDate,
                        end: current
                    });
                    startDate = current;
                    i--;
                }

                return result;
            }
        }

        function _chartExport(analysis) {
            if(ctrl.chart.showLimitLines && (ctrl.highLowLimitEditModel.selectedHighDpObj === "" || ctrl.highLowLimitEditModel.selectedLowDpObj === "")) {
                Notify.error("global.notification.error.title", 'analysis.notification.error.exportConfig', 4000);
            } else {
                $('<iframe/>').attr({
                    src: 'api/analysis/download/' + analysis.id,
                    style: 'visibility:hidden;display:none'
                }).appendTo($('#download-analysis'));
            }
        }
        // ctrl.chart.generate()
        function _chartGenerate() {
            ctrl.canExport = true;
            if (ctrl.evalAnalysisChange()) {
                ctrl.canExportDisabled = true;
            } else {
                //control to prevent some issues when you go from quick to detailed analysis
                if ($state.params.analysisId !== '' && $state.params.analysisId !== undefined) {
                    ctrl.canExportDisabled = false;
                } else {
                    ctrl.canExportDisabled = true;
                }
            }
            ctrl.showAddpoint = false;
            if (!ctrl.chart.canGenerate()) {
                return;
            }
            ctrl.isGeneratable = false;
            ctrl.chart.hidden = false;
            ctrl.chart.config = [];
            var datapoints = [];
            if (!ctrl.template.active) {
                for (var i = 0; i < ctrl.datapoints.list.length; i++) {
                    var dpo = angular.copy(ctrl.datapoints.list[i]);
                    var typPath = Tools.isDefinedNotNull(dpo.hwtyp) ? dpo.hwtyp.stringValue + ': ' : '';
                    var path = (Tools.isDefinedNotNull(dpo.path) && dpo.path.length > 0) ? dpo.path : typPath;
                    dpo.title = '(' + dpo.locationName + ') ' + path + dpo.title;
                    datapoints.push(dpo);
                }
            } else {
                for (var j = 0; j < ctrl.datapoints.list.length; j++) {
                    var dp = angular.copy(ctrl.datapoints.list[j]);
                    if (Tools.isDefinedNotNull(dp.device)) {
                        dp.locationName = dp.device.locationName; // TODO: Check if no hwtyp
                        var path2 = (Tools.isDefinedNotNull(dp.path) && dp.path.length > 0) ? dp.path : dp.hwtyp.stringValue + ': ';
                        dp.title = '(' + dp.locationName + ') ' + path2 + dp.title;
                        dp.locationId = dp.device.locationId;
                        dp.parameter.MAC = dp.device.mac;
                        datapoints.push(dp);
                    }
                }
            }

            var chartConfig = {
                dateRanges: angular.copy(_generateDynamicDateRanges()),
                options: {
                    chart: {
                        backgroundColor: '#ff0000'
                    },
                    title: {
                        text: ctrl.chart.title
                    },
                    subtitle: {
                        text: (function () {
                            var result = '';
                            for (var i = 0; i < ctrl.dateranges.list.length; i++) {
                                var dr = ctrl.dateranges.list[i];
                                if (result !== '') {
                                    result += ', ';
                                }
                                result += dr.start.format($translate.instant('global.dateFormat')) + ' - ' + dr.end.format($translate.instant('global.dateFormat'));
                            }
                            return result;
                        })()
                    },
                    options: {
                        chart: {
                            height: 400
                        },
                        navigation: {
                            buttonOptions: {
                                enabled: true
                            }
                        }
                    }
                }
            };
            PIL.getTempSetting().then(function (temp) {
                var tempSettings = temp.data;
                // convert ctrl.highLowLimitEditModel to chartBoundsMax, chartBoundsMin, and selectedHighDpName, selectedLowDpName
                ctrl.chart.selectedHighDpObj = ctrl.highLowLimitEditModel.selectedHighDpObj;
                ctrl.chart.chartBoundsMax = ctrl.highLowLimitEditModel.chartBoundsMax;

                ctrl.chart.selectedLowDpObj = ctrl.highLowLimitEditModel.selectedLowDpObj;
                ctrl.chart.chartBoundsMin = ctrl.highLowLimitEditModel.chartBoundsMin;

                // paras ready for drawing diagram
                var highLimit = ctrl.chart.chartBoundsMax * 1000;
                var lowLimit = ctrl.chart.chartBoundsMin * 1000;
                var highDp = ctrl.chart.selectedHighDpObj;
                var lowDp = ctrl.chart.selectedLowDpObj;
                var showLimitsFlag = ctrl.chart.showLimitLines;
                var highColor = ctrl.chart.highLimitColor;
                var lowColor = ctrl.chart.lowLimitColor;

                highchart.buildConfiguration(tempSettings, datapoints, chartConfig,
                    _onChartRender, colorSupplier, highLimit, lowLimit, highDp, lowDp, showLimitsFlag, highColor, lowColor);
            });
        }

        function _onChartRender(config, zoom) {
            ctrl.chart.config[0] = config;
            ctrl.isGeneratable = true;
            ctrl.isZoomed = zoom;
        }

        // Show a modal dialog
        function _showModal(page) {
            if (Tools.isDefinedNotNull(currentModal)) {
                currentModal.close();
            }
            if (!Tools.isDefinedNotNull(ctrl.tmp.loadLocation)) {
                ctrl.tree.model.datapoints = [];
                ctrl.entityTree.entityModel.entities = [];
                ctrl.isRoomSelected = false;
                ctrl.isEntitySelected = false;
                ctrl.validDpSearchTerm = false;
                ctrl.validSearchTerm = false;
                ctrl.dpSearchTerm = undefined;
                ctrl.searchTerm = undefined;
            }

            currentModal = $uibModal.open({
                templateUrl: page,
                animation: true,
                windowClass: 'animated fadeInDown',
                size: "lg",
                backdrop: 'static',
                scope: $scope
            });
        }

        // Close the modal window
        function _closeModal() {
            if (currentModal !== null) {
                currentModal.close();
                currentModal = null;
            }
            // ctrl.inputChanged();
        }

        ctrl.getDpDeviceFilter = function (dp) {
            var filter = {
                hwtyp: dp.hwtyp
            };
            if (ctrl.template.location !== undefined && ctrl.template.location !== null) {
                filter.locationId = ctrl.template.location.id;
            }
            return filter;
        };

        ctrl.getDpLocationInfo = function (dp) {
            var result = dp.locationName;
            if (dp.hwtyp !== undefined && dp.hwtyp !== null) {
                result += ' / ' + dp.hwtyp.stringValue;
            } else {
                if (dp.parameter !== undefined && dp.parameter !== null && dp.parameter.hasOwnProperty("HWTYP")) {
                    var typId = parseInt(dp.parameter.HWTYP);
                    result += ' / ' + Tools.getHwTyp(typId);
                }
            }
            return result;
        }
    }

    angular
        .module('emsv2App')
        .controller('AnalysisDetailedController', AnalysisController);

})(moment);
