(function () {

    'use strict';

    var translatePartialLoader = ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        $translatePartialLoader.addPart('legal');
        return $translate.refresh();
    }];

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('legal.privacy', {
                parent: 'legal',
                url: '/privacy',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'global.menu.legal.pageTitle',
                    breadcrumbsTitle: 'global.menu.legal.privacy',
                    page: 'privacy',
                    hasReset: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/legal/legal.html',
                        controller: 'LegalController',
                        controllerAs: '$ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: translatePartialLoader
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) {
                            Principal.showChangePasswordState();
                        }
                    });
                }
            })
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
