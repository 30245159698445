(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgetanalysis', function ($interval) {

        function controller($scope, $element, $log, $timeout, $q, $translate, $translatePartialLoader, $uibModal,
                            Tools, Location, highchart, Analysis, WidgetData, Notify, PIL, Principal, $state) {
            var ctrl = this;
            var modalinstance = null;
            ctrl.state = $state.current.name;
            ctrl.configure = null;
            ctrl.dashboardLocked = false;
            ctrl.settings = $scope.widget.settings;
            ctrl.placeholder = $translate.instant('global.form.select');
            ctrl.saves = {
                id: null,
                available: [],
                selected: null,
                devices: [],
                showDeviceSelection: false,
                hideLegend: true
            };
            ctrl.chart = {
                title: '',
                config: [],
                types: angular.copy(highchart.chartTypes),
                datapoints: [],
                dateranges: []
            };
            ctrl.options = {
                configure: false,
                splitValues: [
                    {key: 0, value: 'day'},
                    {key: 1, value: 'week'},
                    {key: 2, value: 'month'},
                    {key: 3, value: 'year'}
                ]
            };

            var chartHeightSub = 0;
            if ($scope.isreportwidget) {
                var h = $($element).parents().eq(2).outerHeight() - 78; // 78 = Titles outer height + padding[15]
                chartHeightSub = h < 500 ? (500 - h) + 20 : 0;
                $timeout(function () {
                    var sz = parseInt($($element).find("h2").css("font-size"));
                    while ($($element).parents().eq(2).outerHeight() - 30 < $($element).outerHeight() && sz > 14) {
                        sz -= 0.5;
                        $($element).find("h2").css("font-size", sz + "px");
                    }
                }, 1000);
            }

            ctrl.colors = {
                list: [
                    '#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5',
                    '#64E572', '#FF9655', '#FFF263', '#6AF9C4', '#F07979'
                ],
                nextColor: function (num) {
                    if (!Tools.isDefinedNotNull(num)) num = ctrl.datapoints.list.length;
                    if (num < 10) {
                        return ctrl.colors.list[num];
                    } else {
                        return ctrl.colors.nextColor(num - 10);
                    }
                }
            };

            function _closeModal() {
                modalinstance.close();
                modalinstance = null;
            }

            ctrl.cancelModal = function () {
                _closeModal();
                ctrl.options.configure = !Tools.isDefinedNotNull(ctrl.saves.selected);
                _loadFromSettings();
            };

            ctrl.$onInit = function () {
                Analysis.findAllExcludeDatapoints().then(function (response) {
                    ctrl.saves.available = response.data;
                }, function (error) {
                    $log.debug('No analysis found.', error);
                });
                Principal.identity().then(function (account) {
                    if(localStorage.getItem("locked-dashboard-user-id-" + account.id) === "true") {
                        ctrl.dashboardLocked = true;
                    } else {
                        ctrl.dashboardLocked = false;
                    }
                }, function (error) {
                    $log.error('Couldn\'t load user!', error);
                });
                _loadFromSettings();
                ctrl.configure = _configure;
            };

            function _configure() {
                modalinstance = $uibModal.open({
                    templateUrl: 'scripts/app/dashboard/widget.templates/analysis/widget.analysis.settings.modal.html',
                    animation: true,
                    windowClass: 'animated fadeInDown',
                    scope: $scope
                });
            }

            // Save widget settings & prepare datapoints for analysis if we have a template
            ctrl.saveSettings = function () {
                ctrl.options.configure = false;
                var settings = [];
                settings.push({key: 'analysisId', value: ctrl.saves.selected.id});
                settings.push({key: 'hideLegend', value: ctrl.saves.hideLegend});
                if (ctrl.saves.selected.template) {
                    for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                        var dp = ctrl.chart.datapoints[i];

                        if (Tools.isDefinedNotNull(dp.device)) {
                            var devSet = dp.device.mac;
                            dp.parameter.MAC = dp.device.mac;

                            if (Tools.isDefinedNotNull(dp.device.locationName)) {
                                devSet += ';' + dp.device.locationName;
                                var pretitle = dp.title.split(")");

                                if (pretitle.length === 2) {
                                    dp.title = '(' + dp.device.locationName + ') ' + pretitle[1].trim();
                                } else {
                                    dp.title = '(' + dp.device.locationName + ') ' + pretitle[0].trim();
                                }
                            }
                            settings.push({key: dp.id, value: devSet});
                        }
                    }
                }
                _closeModal();
                $scope.widget.settings = settings;
                ctrl.settings = $scope.widget.settings;
                if ($scope.isdashboardwidget) {
                    WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                        Notify.defaultSuccess();
                    }, function (error) {
                        Notify.error("global.notification.error.title", "dashboard.widget.analysis.saveErrorMsg", 2000);
                    });
                }
                if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                    _loadAnalysis().then(function () {
                        ctrl.saves.showDeviceSelection = ctrl.saves.selected.template;
                        _generateChart();
                    });
                }
            };

            // Check if the save button should be enabled
            ctrl.saveEnabled = function () {
                return Tools.isDefinedNotNull(ctrl.saves.selected)
                    && (!ctrl.saves.selected.template || _allDevicesSet());

                function _allDevicesSet() {
                    var result = true;
                    for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                        result = result && Tools.isDefinedNotNull(ctrl.chart.datapoints[i].device);
                    }
                    return result;
                }
            };

            // Don't load analysis on selection changed as before. Wait for saving it.
            ctrl.selectionChanged = function () {
            };

            // Get "(Location) Title" from fixed datapoints, "Device: Title" from templates
            ctrl.getDpTitle = function (dp) {
                if (ctrl.saves.selected !== null && ctrl.saves.selected.template) {
                    var split = dp.title.split(')');
                    var result = (split.length === 1) ? split[0] : split[1];
                    return dp.hwtyp.stringValue + ': ' + result;
                }
                return dp.title;
            };

            ctrl.getFormatedDate = function (date) {
                return moment(date).format('DD.MM.YYYY');
            };

            // Load datapoint translation strings
            function _loadTranslation() {
                var defer = $q.defer();
                $translatePartialLoader.addPart('datapoint');
                $translatePartialLoader.addPart('analysis');
                $translate.refresh().then(function () {
                    defer.resolve();
                });
                return defer.promise;
            }

            // Load selected analysis with dateranges & (translated) datapoints to chart memory
            function _loadAnalysis() {
                var defer = $q.defer();
                ctrl.chart.title = ctrl.saves.selected.title;
                ctrl.chart.config = [];
                ctrl.chart.datapoints = [];
                ctrl.chart.dateranges = [];

                var analysisId = _getFieldFromSettings("analysisId");
                if (Tools.isDefinedNotNull(analysisId)) {
                    Analysis.load(analysisId).then(function (response) {
                        if (response.data !== "") {
                            ctrl.saves.selected = response.data;
                            _loadDateRanges(ctrl.saves.selected.dateRanges);
                            _loadTranslation().then(function () {
                                var promises = [];
                                for (var j = 0; j < ctrl.saves.selected.dataPoints.length; j++) {
                                    var dp = angular.copy(ctrl.saves.selected.dataPoints[j]);
                                    promises.push(_addLoadedDataPoint(dp));
                                }

                                $q.all(promises).then(function () {
                                    defer.resolve();
                                });
                            });
                        } else {
                            Notify.info("global.notification.info.title", "dashboard.widget.analysis.info.deleted", 2000);
                        }
                    }, function (error) {
                        $log.debug('No analysis found.', error);
                    });
                }


                return defer.promise;
            }

            // Generate chart (configs)
            function _generateChart() {
                ctrl.chart.config = [];
                PIL.getTempSetting().then(function (settings) {
                    var tempSettings = settings.data;
                    highchart.buildConfiguration(tempSettings, ctrl.chart.datapoints, {
                            // replaced by _generateDynamicDateRanges
                            dateRanges: ctrl.chart.dateranges,
                            options: {
                                title: {
                                    text: $scope.active ? ctrl.chart.title : ''
                                },
                                subtitle: {
                                    text: (function () {
                                        var result = '';
                                        for (var i = 0; i < ctrl.chart.dateranges.length; i++) {
                                            var dr = ctrl.chart.dateranges[i];
                                            if (result !== '') {
                                                result += ', ';
                                            }
                                            result += dr.start.format('DD.MM.YYYY') + ' - ' + dr.end.format('DD.MM.YYYY');
                                        }
                                        return result;
                                    })()
                                },
                                options: {
                                    chart: {
                                        height: 500 - chartHeightSub,
                                        animation: false,
                                        zoomType: ''
                                    },
                                    navigation: {
                                        buttonOptions: {
                                            enabled: false
                                        }
                                    },
                                    plotOptions: {
                                        series: {
                                            animation: false
                                        }
                                    },
                                    legend: {
                                        itemStyle: {},
                                        layout: 'vertical',
                                        enabled: !ctrl.saves.hideLegend
                                    }
                                }
                            }
                        },
                        function (config) {
                            ctrl.chart.config[0] = config;
                        }
                    );
                })
            }

            // Prepare/translate a datapoint and add it to the chart memory
            function _addLoadedDataPoint(dp) {
                var defer = $q.defer();
                delete dp.children;

                if (ctrl.saves.selected.template && Tools.isDefinedNotNull(dp.device)) {
                    dp.parameter.MAC = dp.device.mac;
                }
                if (!Tools.isDefinedNotNull(dp.color)) {
                    dp.color = ctrl.colors.nextColor();
                }

                if (!Tools.isDefinedNotNull(dp.title)) {
                    $translate(dp.translation, {value: dp.translationParam}).then(function (result) {
                        var path = (Tools.isDefinedNotNull(dp.path) && dp.path.length > 0) ? dp.path : '';
                        dp.title = (ctrl.saves.selected.template) ? path + result : '(' + dp.locationName + ') ' + path + result;
                        ctrl.chart.datapoints.push(dp);
                        defer.resolve();
                    });
                } else {
                    var path = (Tools.isDefinedNotNull(dp.path) && dp.path.length > 0) ? dp.path : '';
                    dp.title = (ctrl.saves.selected.template) ? path + dp.title : '(' + dp.locationName + ') ' + path + dp.title;
                    ctrl.chart.datapoints.push(dp);
                    defer.resolve();
                }
                return defer.promise;
            }

            // Compute dateranges and add them to the chart memory
            function _loadDateRanges(ranges) {
                ctrl.chart.dateranges = [];
                if (ranges.length === 1 && Tools.isDefinedNotNull(ranges[0].dynamicRange)) {
                    var now = moment();
                    // now.set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 1});
                    var range = ctrl.options.splitValues[ranges[0].dynamicRange].value;
                    var scale = ctrl.options.splitValues[ranges[0].dynamicScale].value;
                    var i = ranges[0].dynamicValue;
                    var startDate = moment(now).subtract(i, range);

                    while (startDate.format('X') < now.format('X')) {
                        var current = moment(startDate).add(1, scale);
                        ctrl.chart.dateranges.push({
                            start: startDate,
                            end: current
                        });
                        startDate = current;
                        i--;
                    }
                } else {
                    for (var j = 0; j < ranges.length; j++) {
                        ctrl.chart.dateranges.push({
                            start: moment(ranges[j].start),
                            end: moment(ranges[j].end).add({hours: 23, minutes: 59, seconds: 59})
                        })
                    }
                }
            }

            // Get a named field from the widget settings
            function _getFieldFromSettings(fieldname) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    if ($scope.widget.settings[i].key === fieldname) {
                        return $scope.widget.settings[i].value;
                    }
                }
                return null;
            }

            // Load an analysis from the widget settings and prepare for edit/show
            function _loadFromSettings() {
                var id = _getFieldFromSettings('analysisId');
                ctrl.saves.hideLegend = (_getFieldFromSettings('hideLegend') === 'true');
                if (Tools.isDefinedNotNull(id)) {
                    for (var j = 0; j < ctrl.saves.available.length; j++) {
                        if (ctrl.saves.available[j].id === id) {
                            ctrl.saves.selected = ctrl.saves.available[j];
                            break;
                        }
                    }

                    if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                        ctrl.saves.showDeviceSelection = ctrl.saves.selected.template;
                        _loadAnalysis().then(function () {
                            // Set mac parameter for template datapoints
                            if (ctrl.saves.selected.template) {
                                for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                                    var dp = ctrl.chart.datapoints[i];
                                    var kv = _getFieldFromSettings(dp.id);

                                    if (Tools.isDefinedNotNull(kv)) {
                                        var kva = kv.split(";");
                                        dp.parameter.MAC = kva[0];
                                        var device = _findDeviceByMac(kva[0]);

                                        if (Tools.isDefinedNotNull(device)) {
                                            dp.device = device;
                                        }
                                        if (kva.length === 2) {
                                            dp.title = '(' + kva[1] + ') ' + dp.title;
                                        }
                                    }
                                }
                            }
                            _generateChart();
                        });
                    }
                } else {
                    ctrl.saves.selected = null;
                }

                // Find a device in the ctrl.saves.devices array and return it
                function _findDeviceByMac(mac) {
                    for (var i = 0; i < ctrl.saves.devices.length; i++) {
                        if (ctrl.saves.devices[i].mac === mac) {
                            return ctrl.saves.devices[i];
                        }
                    }
                    return null;
                }

            }

            // Load all (accessible) locations and build the location-device array in ctrl.saves.devices
            function _loadLocations() {
                var defer = $q.defer();
                Location.queryCompact().then(function (response) {
                    var locations = response.data;
                    var promises = [];
                    for (var i = 0; i < locations.length; i++) {
                        promises.push(_loadLocationDevices(locations[i]));
                    }
                    $q.all(promises).then(function () {
                        defer.resolve();
                    });

                    function _loadLocationDevices(location) {
                        return Location.getDevices(location.id).then(function (response) {
                            var devices = response.data;
                            location.devices = devices;
                            for (var i = 0; i < devices.length; i++) {
                                var dev = angular.copy(devices[i]);
                                dev.locationName = location.name;
                                ctrl.saves.devices.push(dev);
                            }
                        });
                    }
                });
                return defer.promise;
            }

            (function () {
                $scope.setConfigMethod(_configure);

                // Preload locations & devices
                var locationsLoaded = _loadLocations();
                var analysisId = _getFieldFromSettings("analysisId")

                if (Tools.isDefinedNotNull(analysisId)) {
                    Analysis.load(analysisId).then(function (response) {
                        if (response.data !== "") {
                            ctrl.saves.selected = response.data;
                            // Check if we have data to load
                            if (Tools.isDefinedNotNull($scope.widget.settings) && $scope.widget.settings.length > 0) {
                                locationsLoaded.then(function () {
                                    _loadFromSettings();
                                });
                            } else {
                                ctrl.options.configure = true;
                            }
                        } else {
                            Notify.info("global.notification.info.title", "dashboard.widget.analysis.info.deleted", 2000);
                        }
                    }, function (error) {
                        $log.debug('No analysis found.', error);
                    });
                }

                if ($scope.isreportwidget) {
                    var interval = $interval(function () {
                        if ($($element).find('.highcharts-legend').find('text').outerWidth() >= $($element).find('svg').outerWidth()) {
                            if (ctrl.chart.config[0].options.legend.itemStyle.fontSize === undefined) {
                                ctrl.chart.config[0].options.legend.itemStyle.fontSize = 16;
                            }
                            ctrl.chart.config[0].options.legend.itemStyle.fontSize = parseInt(ctrl.chart.config[0].options.legend.itemStyle.fontSize) - 0.5;
                        } else {
                            if ($($element).find('.highcharts-legend').find('text').outerWidth() !== 0) {
                                $interval.cancel(interval);
                            }
                        }
                    });
                }

            })();
        }

        function link(scope, element, attrs, controller) {
            var timeoutId;
            element.on('$destroy', function () {
                $interval.cancel(timeoutId);
            });

            // check if widget size changed and update chart
            timeoutId = $interval(function () {
                var $element = $(element).find("#charts");
                if (controller.chart.config.length) {
                    var chart = controller.chart.config[0].getHighcharts();
                    if (chart.chartWidth !== $element.outerWidth()) {
                        chart.reflow();
                    }
                }
            }, 250);
        }

        return {
            restrict: 'E',
            controller: controller,
            controllerAs: 'analysis',
            templateUrl: 'scripts/app/dashboard/widget.templates/analysis/widget.analysis.template.html',
            link: link
        };
    });
})();
