(function () {
    'use strict';

    /**
     * @function MessageService
     * @description message publishing service use instead of $rootScope.broadcast (<- very slow)
     */
    angular.module('emsv2App').service('MessageService', function () {
        var listener = {};
        return {
            /**
             * @description Function to subscribe to specified message
             * @param {String} msg message to subscribe to
             * @param {Function} callback the function to call if subscribed message is published
             */
            subscribe: function (msg, callback) {
                if (listener[msg] === undefined) {
                    listener[msg] = [];
                }
                var idx = this.checkFunctionAlreadyInserted(msg, callback);
                if (idx === -1) {
                    listener[msg].push(callback);
                }
                else {
                    listener[msg][idx] = callback;
                }
            },
            /**
             * @description Function to unsubscribe callback from specified message
             * @param {String} msg the message to unsubscribe from
             * @param {Function} callback the callback to unsubscribe
             * @returns {boolean} returns true if callback was removed successfully, otherwise false
             */
            unsubscribe: function (msg, callback) {
                if (!listener.hasOwnProperty(msg)) {
                    return false;
                }
                else {
                    listener[msg].splice(listener[msg].indexOf(callback), 1);
                }
            },
            /**
             * @description Function to publish a new message with specified options
             * @param {String} msg the message to publish
             * @param {Object} opts the options to publish with this message
             */
            publish: function (msg, opts) {
                if (listener.hasOwnProperty(msg)) {
                    for (var i = 0; i < listener[msg].length; i++) {
                        listener[msg][i](msg, opts);
                    }
                }
            },
            /**
             * @description Function to check if provided callback is already setup for specified message
             * @param {String} msg the message to check for
             * @param {Function} callback the callback function to test
             * @returns {number} returns index if function is found, otherwise -1 will be returned
             */
            checkFunctionAlreadyInserted: function (msg, callback) {
                if (listener[msg] !== undefined) {
                    var idx = -1;
                    for (var i = 0; i < listener[msg].length; i++) {
                        if (listener[msg][i].toString() == callback.toString()) {
                            idx = i;
                            break;
                        }
                    }
                    return idx;
                }
                return -1;
            }
        };
    });
})();
