(function () {

    'use strict';

    function alarmController(Location, $scope, $state, $stateParams, $log, User, IntervalService, Notify, $uibModal, $translate,
                             ResetErrorService, license, EMSConstants, LocationDetailService,
                             isAdmin, hasViewRight, hasEditRight, hasCreateRight, hasDeleteRight, currentLocation) {
        var actrl = this;
        actrl.rooms = [];
        actrl.currentPrio = 'reset';
        actrl.select = null;
        actrl.devices = [];

        actrl.isAdmin = isAdmin;
        actrl.hasViewRight = hasViewRight;
        actrl.hasEditRight = hasEditRight;
        actrl.hasCreateRight = hasCreateRight;
        actrl.hasDeleteRight = hasDeleteRight;
        actrl.stateCache = ['location.alarm.errorConfig'];

        actrl.locationName = currentLocation.name;
        actrl.children = {};
        actrl.disableButton = true;
        var modal = null;
        $scope.modal = {
            device: null,
            delete: null,
            close: function () {
                modal.close();
            }
        };

        $scope.$on('$stateChangeStart', function (event, toState) {
            if (toState.hasOwnProperty('redirectTo')) {
                $state.go(toState.redirectTo, {}, {reload:true});
            }
            else if (toState.name.match(/location\.alarm\./)) {
                setupActiveSubTabFromState(toState.name);
            }
        });

        actrl.stateChange = function (index, state, subTabState) {
            if (subTabState != null && angular.isDefined(subTabState)) {
                actrl.stateCache.push('location.alarm.' + subTabState);
                $state.go('location.alarm.' + subTabState);
            }
        };


        actrl.goBack = function () {
            if ($state.current.name.includes('errorConfig.edit')) {
                $state.go('location.alarm.errorConfig.list', {}, {reload: true});
            }
            else if ($state.current.name.includes('escalationlevel.edit')){
                $state.go('location.alarm.escalationlevel.list', {}, {reload: true});
            }
            else {
                $state.go('location', {id: $stateParams.id, mode: $stateParams.mode}, {reload: true});
            }
        };

        actrl.checkCurrentState = function () {
            return $state.current.name.contains('edit') && $stateParams.mode != "view" && actrl.hasEditRight;
        };

        actrl.saveAction = function (){ // saveAction löst Broadcast an die Childcontroller aus -> abhängig davon welcher state grad offen ist
            if ($state.current.name === "location.alarm.errorConfig.edit"){
                $scope.$broadcast('errorConfigSave'); // Broadcast wird an errorconfig.edit.controller gesendet
            }
            else if ($state.current.name === "location.alarm.escalationlevel.edit"){
                $scope.$broadcast('escalationSave'); // Broadcast wird an escalationlevel.edit.controller gesendet
            }
        };

        actrl.collapse = function (obj) {
            if (obj.collapseState == false || obj.collapseState == undefined) {
                obj.collapseState = true;
            }
            else {
                obj.collapseState = false;
            }
        };

        function _resetAllErrors() {
            ResetErrorService.resetAll($stateParams.id).then(function () {
                modal.close();
                Notify.defaultSuccess();
            }, function () {
                modal.close();
                Notify.error("global.notification.error.title", "global.notification.error.text", 3000);
            });
        }

        function _resetDeviceErrors() {
            ResetErrorService.resetDevices(actrl.select.id).then(function () {
                modal.close();
                Notify.defaultSuccess();
            }, function () {
                modal.close();
                Notify.error("global.notification.error.title", "global.notification.error.text", 3000);
            });
        }
        //Massenquittieren
        actrl.restErrors = function () {
            if (actrl.select === null) {
                return;
            }
            if (actrl.select.id === -1 || actrl.select.id === "-1") {
                $scope.modal.device = false;
                $scope.modal.name = actrl.locationName;
                $scope.modal.delete = _resetAllErrors;
            }
            else {
                $scope.modal.device = true;
                $scope.modal.name = actrl.select.name;
                $scope.modal.delete = _resetDeviceErrors;
            }
            modal = $uibModal.open({ // Bestätigungsmodal für das Massenquittieren
                animation: false,
                templateUrl: "scripts/app/location/tabs/alarm/advancedSettings/advancedSettings.quit.html",
                windowClass: 'animated fadeInDown',
                scope: $scope,
                size: 'md'
            });
        };

        actrl.alarmTable = {
            model: ['priority', 'device_name', 'stamp', 'hash_text'],
            title: 'Alarms',
            table: 'alarm',
            headless: false,
            restriction: 'wait',
            translation: 'location.alarm',
            contentDecorator: _decorateAlarmContent,
            rowDecorator: _decorateRow,
            orderby: 'stamp'
        };

        actrl.alarmConfigTable = {
            model: ['priority', 'active', 'device_name', 'stamp', 'hash_text'],
            title: 'Alarms Configuration',
            table: 'alarmConfig',
            headless: true,
            restriction: 'wait',
            translation: 'location.alarm',
            contentDecorator: _decorateAlarmContent,
            rowDecorator: _decorateRow,
            orderby: 'stamp'
        };

        //Filter for Error History
        actrl.updateQueryPrio = function (prio) {
            actrl.currentPrio = prio;
            if (actrl.storedQuery !== 'wait' && actrl.storedQuery !== 0) {
                if (prio === 'error') {
                    actrl.alarmTable.restriction = actrl.storedQuery + ' and priority>=(' + IntervalService.getPriorityInterval().errorPrioStart + ')';
                }
                else if (prio === 'warn') {
                    actrl.alarmTable.restriction = actrl.storedQuery + ' and priority>=(' + IntervalService.getPriorityInterval().warnPrioStart + ')' +
                        ' and priority<=(' + IntervalService.getPriorityInterval().warnPrioEnd + ')';
                }
                else if (prio === 'info') {
                    actrl.alarmTable.restriction = actrl.storedQuery + ' and priority>=(' + IntervalService.getPriorityInterval().infoPrioStart + ')' +
                        ' and priority<=(' + IntervalService.getPriorityInterval().infoPrioEnd + ')';
                }
                else if (prio === 'reset') {
                    actrl.alarmTable.restriction = actrl.storedQuery;
                }
            }
        };

        actrl.updateTable = function () {
            User.devices().then(function (response) {
                var result = response.data;
                if (result !== null && result.length !== 0) {
                    var tableQuery = '';
                    for (var i = 0; i < result.length; i++) {
                        if (tableQuery.length > 0) {
                            tableQuery += ',';
                        }
                        tableQuery += result[i].mac;
                    }
                    if (tableQuery.length > 0) {
                        tableQuery = 'mac=in=(' + tableQuery + ')';
                    }
                    actrl.storedQuery = tableQuery;
                    actrl.alarmTable.restriction = tableQuery;
                }

            }, function () {
                $log.error('Could not acquire devices.');
            });
        };

        actrl.updateTable();

        function _decorateAlarmContent(name, value) {
            if (name === 'priority') {
                if (value >= 150) {
                    return 'glyphicon glyphicon-remove-sign';
                }
                else if (value >= 75 && value < 150) {
                    return 'fa fa-warning text-warning';
                }
                return 'glyphicon glyphicon-info-sign';
            }
            return '';
        }

        function _decorateRow(data) {
            if (data.priority < 150 && data.priority >= 75) {
                return 'row-warning';
            }
            else if (data.priority >= 150) {
                return 'row-error';
            }
            //return 'white-bg';
            return 'row-info'; //DL
        }



        var setupActiveSubTabFromState = function(stateName) {
            if (stateName.contains('errorHistory')) {
                actrl.activeSubtab = 3;
            }
            else if (stateName.contains("errorConfig")) {
                actrl.activeSubtab = 0;
            }
            else if (stateName.contains("escalationlevel")) {
                actrl.activeSubtab = 1;
            }
            else if (stateName.contains("alarmHistory")) {
                actrl.activeSubtab = 2;
            }
            else {
                actrl.activeSubtab = 2;
                $state.go($state.current.redirectTo);
            }
        };

        actrl.$onInit = function(){
            setupActiveSubTabFromState($state.current.name);
        };

        $scope.$watch(function () { return LocationDetailService.canChange; }, function () {
                if (LocationDetailService.canChange === false && LocationDetailService.onlyActiveTab === -1) {
                    switch (LocationDetailService.alarmsOnlyActiveTab) {
                        case 0 :
                            $scope.disabled0 = false;
                            $scope.disabled1 = true;
                            $scope.disabled2 = true;
                            $scope.disabled3 = true;
                            break;
                        case 1 :
                            $scope.disabled0 = true;
                            $scope.disabled1 = false;
                            $scope.disabled2 = true;
                            $scope.disabled3 = true;
                            break;
                    }
                } else {
                    $scope.disabled0 = false;
                    $scope.disabled1 = false;
                    $scope.disabled2 = false;
                    $scope.disabled3 = false;
                }
        });
    }

    angular
        .module('emsv2App')
        .controller('AlarmTabController', alarmController);

})();
