'use strict';

// Creates a ColorSupplier with given or default colors
function ColorSupplier(colors) {
    // this.colors = colors;
    this.index = 0;
    if (colors !== undefined && colors !== null && Array.isArray(colors) && colors.length > 0) {
        this.colors = colors;
    }
    else {
        this.colors = [
            '#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5',
            '#64E572', '#FF9655', '#FFF263', '#6AF9C4', '#F07979'
        ];
    }
}

ColorSupplier.prototype = {};

ColorSupplier.prototype.constructor = ColorSupplier;

ColorSupplier.prototype.current = function () {
    return this.colors[this.index];
};

ColorSupplier.prototype.next = function () {
    this.index++;
    if (this.index >= this.colors.length) {
        this.index = 0;
    }
    return this.colors[this.index];
};

ColorSupplier.prototype.reset = function () {
    this.index = 0;
};
