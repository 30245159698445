(function () {
    'use strict';

    function PingService($http) {
        function ping() {
            return $http.get('api/ping');
        }

        return {ping: ping};
    }

    angular
        .module('emsv2App')
        .factory('Ping', PingService);

})();
