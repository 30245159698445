(function () {

    'use strict';

    function WidgetSpeedometerController($scope, $uibModal, $q, $log, $translate, $timeout, $interval, $http, $window, $state,
                                         Notify, RoomService, WidgetData, Location, Tools, digiChart, DatapointService, DashboardService) {

        var ctrl = this;
        var currentModal;
        var chartReady = false;
        var containerInformation = null;

        var defaultValues = {
            colors: {
                left: '#00ff00',
                mid: '#ffff00',
                right: '#ff0000'
            }
        };

        ctrl.isDpFilterSearchTerm = true;
        ctrl.loadDatapoint = null;
        ctrl.locationSelectionBox = null;
        ctrl.spinnerDisplay = false;
        ctrl.isLocationSelected = false;
        ctrl.isRoomSelected = false;
        ctrl.isEntitySelected = false;
        ctrl.validSearchTerm = false;
        ctrl.validDpSearchTerm = false;
        ctrl.entitySpinnerDisplay = false;
        ctrl.searchTerm = undefined;
        ctrl.dpSearchTerm = undefined;
        ctrl.roomsAvailable = false;
        ctrl.colors = {
            left: {
                color: "",
                value: null
            },
            middle: {
                color: "",
                value: null
            },
            right: {
                color: "",
                value: null
            }
        };

        ctrl.startValue = null;
        ctrl.endValue = null;

        ctrl.driverOutMin = 0;
        ctrl.driverOutMax = 0;
        ctrl.inValid = {
            startValue: ctrl.startValue !== ctrl.driverOutMin,
            endValue: ctrl.endValue !== ctrl.driverOutMax,
        };

        ctrl.unit = "";
        ctrl.unitOfMeasurement = "";
        ctrl.headline = "";
        ctrl.canSave = false;
        ctrl.haveData = false;

        ctrl.locations = null;
        ctrl.rooms = [];

        ctrl.displayData = 0;
        ctrl.liveData = 0;
        ctrl.previousLiveData = 0;
        ctrl.chart = null;

        ctrl.spOpts = {
            showInput: true,
            preferredFormat: 'hex',
            allowEmpty: false,
            clickoutFiresChanges: true,
            cancelText: $translate.instant("global.btn.cancel"),
            chooseText: $translate.instant("global.btn.choose")
        };

        ctrl.tree = {
            model: [],
            options: {
                multiSelection: false,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            filter: "",
            expandedNodes: [],
            selectedNode: null
        };

        ctrl.entityTree = {
            options: {
                multiSelection: false,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            entityModel: {
                entities: [],
                current: [],
                selected: [],
            }
        };

        var entityType = [
            {
                key: 0,
                value: "assets",
            },
            {
                key: 1,
                value: "coolings",
            },
            {
                key: 2,
                value: "ups",
            },
            {
                key: 3,
                value: "sensors",
            },
            {
                key: 4,
                value: "racks",
            },
            {
                key: 5,
                value: "blades",
            },
            {
                key: 6,
                value: "slots",
            },
            {
                key: 7,
                value: "cpus",
            },
            {
                key: 8,
                value: "floorTiles",
            }
        ];

        ctrl.preSelectedLocationId = null;
        ctrl.selectedLocationId = null;
        ctrl.selectedRoomId = null;
        ctrl.selectedEntityId = null;
        ctrl.selectedEntityType = null;
        ctrl.selectedDriverValue = null;
        ctrl.selectedDriverValueId = null;
        ctrl.selectedDriverValueUniqueId = null;
        ctrl.useGradient = false;
        ctrl.displayPercentageVal = false;

        ctrl.leftValue = null;
        ctrl.middleValue = null;
        ctrl.rightValue = null;
        ctrl.dpArray = [];

        ctrl.handleTreeSelect = function (node, selected) {
            if (selected) {
                ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, node.parameter.DRIVERVALUE_UID);
                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                    ctrl.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    ctrl.startValue = ctrl.selectedDriverValue.driver !== null ? ctrl.selectedDriverValue.driver.outMin : 0;
                    ctrl.driverOutMin = angular.copy(ctrl.startValue);
                    ctrl.endValue = ctrl.selectedDriverValue.driver !== null ? ctrl.selectedDriverValue.driver.outMax : 100;
                    ctrl.driverOutMax = angular.copy(ctrl.endValue);
                    ctrl.unitOfMeasurement = $translate.instant(ctrl.selectedDriverValue.parameter.name);
                    ctrl.unit = node.parameter.FORMATLIVE.replace(/[&\\#,+()$~'":*?<>{} ]/gi, '');
                    ctrl.colors.left.value = ctrl.startValue;
                    ctrl.colors.right.value = ctrl.endValue;
                    ctrl.colors.middle.value = ctrl.startValue + ((ctrl.endValue - ctrl.startValue) / 2);
                    ctrl.inValid.startValue = ctrl.startValue !== ctrl.driverOutMin;
                    ctrl.inValid.endValue = ctrl.endValue !== ctrl.driverOutMax;
                    setupColorValues();
                } else {
                    Notify.warning("global.notification.warning.warn", "dashboard.widget.speedometer.modal.warningAvailable", 4000);
                }

            } else {
                cleanInputs();
            }
            ctrl.canSave = inputValid();
        };

        var cleanInputs = function () {
            ctrl.colors.left.color = ctrl.colors.middle.color = ctrl.colors.right.color = "";
            ctrl.colors.left.value = ctrl.colors.middle.value = ctrl.colors.right.value = "";
            ctrl.startValue = null;
            ctrl.endValue = null;
            ctrl.unitOfMeasurement = '';
            ctrl.unit = null;
            ctrl.headline = '';
            ctrl.tree.selectedNode = null;
            ctrl.selectedDriverValueUniqueId = null;
        };

        var setupColorValues = function () {
            ctrl.colors.left.color = defaultValues.colors.left;
            ctrl.colors.middle.color = defaultValues.colors.mid;
            ctrl.colors.right.color = defaultValues.colors.right;
        };

        ctrl.inputChanged = function (searchTerm) {
            if (searchTerm === undefined || searchTerm === null || searchTerm === "" ||
                ctrl.selectedLocationId === undefined || ctrl.selectedLocationId === null) {
                ctrl.isDpFilterSearchTerm = true;
                ctrl.tree.model = ctrl.loadDatapoint;
            } else {
                ctrl.isDpFilterSearchTerm = false;
            }
        }

        ctrl.entityFilterChange = function (searchTerm) {
            ctrl.getRoomEntities();
            if (searchTerm === undefined || searchTerm === null || searchTerm === "") {
                ctrl.validSearchTerm = false;
                ctrl.getRoomEntities();
            } else {
                ctrl.validSearchTerm = true;
                ctrl.filterEntityNode(searchTerm);
            }
        }

        ctrl.filterEntityNode = function (searchTerm) {
            ctrl.entityTree.entityModel.entities = filter(ctrl.entityTree.entityModel.current, searchTerm.toLowerCase());
        }

        ctrl.dpFilterChange = function (dpSearchTerm) {
            ctrl.tree.model.datapoints = ctrl.searchReference;
            if (dpSearchTerm === undefined || dpSearchTerm === null || dpSearchTerm === "") {
                ctrl.validDpSearchTerm = false;
            } else {
                ctrl.validDpSearchTerm = true;
                ctrl.filterDpNode(dpSearchTerm);
            }
        }
        ctrl.filterDpNode = function (dpSearchTerm) {
            ctrl.tree.model.datapoints = filter(ctrl.tree.model.datapoints, dpSearchTerm.toLowerCase());
        }

        function filter(array, text) {
            var getNodes = function getFilteredNodes(result, object) {
                if (object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    return result;
                }
                if (Array.isArray(object.children)) {
                    var children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }

        ctrl.enableRoomSelection = function () {
            ctrl.rooms = [];
            ctrl.searchTerm = undefined;
            ctrl.dpSearchTerm = undefined;
            ctrl.isEntitySelected = false;
            ctrl.isRoomSelected = false;
            ctrl.tree.model.datapoints = [];
            ctrl.entityTree.entityModel.entities = [];
            if (Tools.isDefinedNotNull(ctrl.selectedLocationId)) {
                var defer = $q.defer();
                $http.get('api/rooms/' + ctrl.selectedLocationId).then(function (rooms) {
                    if (rooms.data.length > 0) {
                        for (var index = 0; index < rooms.data.length; index++) {
                            ctrl.rooms.push(Room.parseFromHtmlObject(rooms.data[index]));
                        }
                        ctrl.roomsAvailable = true;
                    } else {
                        ctrl.roomsAvailable = false;
                    }
                    if (ctrl.selectedDriverValueId !== null && ctrl.selectedDriverValueId !== undefined) {
                        var setting = $scope.widget.settings.filter(function (setting) {
                            return setting.key === "selectedDriverValueUniqueId"
                        });
                        ctrl.selectedDriverValueUniqueId = parseInt(setting[0].value);
                        ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, ctrl.selectedDriverValueUniqueId);
                    }
                    ctrl.canSave = inputValid();
                    defer.resolve();
                });
            }
        }

        ctrl.getRoomEntities = function () {
            var defer = $q.defer();
            ctrl.tree.model.datapoints = [];
            ctrl.dpSearchTerm = undefined;
            ctrl.isEntitySelected = false;
            ctrl.isRoomSelected = true;
            ctrl.entitySpinnerDisplay = true;
            if (Tools.isDefinedNotNull(ctrl.selectedRoomId)) {
                $http.get('api/rooms/getRoomEntitiesByRoomId/' + ctrl.selectedRoomId).then(function (response) {
                    ctrl.entityTree.entityModel.entities = response.data;
                    ctrl.entityTree.entityModel.current = response.data;
                    if (ctrl.isEntitySelected) {
                        ctrl.entityTree.entityModel.selected = findNodeInTreeModel(ctrl.entityTree.entityModel.entities, "id", ctrl.entityTree.entityModel.selected.id);
                    }
                    defer.resolve();
                });
            }
            ctrl.entitySpinnerDisplay = false;
            return defer.promise;
        }

        function findNodeInTreeModel(treeModel, propertyName, value) {
            var foundNode = null;
            var traverse = function (nodes) {
                var newNodes = angular.copy(nodes);
                for (var index = 0; index < newNodes.length; index++) {
                    var node = newNodes[index];
                    if (propertyName === "DRIVERVALUE_UID") {
                        if (node.hasOwnProperty("parameter") &&
                            node.parameter.hasOwnProperty(propertyName) &&
                            node.parameter[propertyName] === value.toString()) {
                            foundNode = node;
                            return foundNode;
                        }
                    } else if (propertyName === "id") {
                        if (node.hasOwnProperty(propertyName) && node[propertyName] === value) {
                            foundNode = node;
                            searchChildToExpand([], treeModel, foundNode.id);
                            ctrl.getRoomEntities();
                        }
                    }
                    if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        traverse(node.children);
                    }
                }
            };
            traverse(treeModel);
            return foundNode;
        }

        ctrl.displayEntityDataPoints = function (node, selected) {

            var selectedEntityType = entityType.filter(function (entity) {
                return entity.value === node.key;
            })[0];

            ctrl.isEntitySelected = selected;
            ctrl.selectedEntityId = node.id;
            if (selected) {
                if(selectedEntityType !== undefined) {
                    ctrl.entityTree.entityModel.selected = node;
                    ctrl.dpSearchTerm = undefined;
                    ctrl.tree.model.datapoints = [];
                    ctrl.selectedEntity = node;
                    var currentRoom = ctrl.selectedRoomId;
                    ctrl.dpSpinnerDisplay = true;
                    ctrl.selectedEntityType = selectedEntityType.key;
                    if (ctrl.selectedEntity.key === "cpus") {

                        $http.post('api/rooms/getDriverValuesForCpu',
                            {
                                "roomId": currentRoom,
                                "rackId": ctrl.selectedEntity.rackId,
                                "slotId": ctrl.selectedEntity.slotId,
                                "cpuId": ctrl.selectedEntity.id,
                            }).then(function (response) {
                            ctrl.tree.model.datapoints = response.data;
                            ctrl.searchReference = ctrl.tree.model.datapoints;
                            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                                ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                            }
                            ctrl.dpSpinnerDisplay = false;
                            if (Tools.isDefinedNotNull(ctrl.selectedDriverValueId)) {
                                ctrl.tree.model.selected = findNodeInTreeModel(ctrl.tree.model.datapoints, "DRIVERVALUE_UID", ctrl.selectedDriverValueUniqueId);
                            }
                        });
                    } else if (ctrl.selectedEntity.key === "slots") {
                        $http.post('api/rooms/getDriverValuesForSlot',
                            {
                                "roomId": currentRoom,
                                "rackId": ctrl.selectedEntity.rackId,
                                "slotId": ctrl.selectedEntity.id,
                            }).then(function (response) {
                            ctrl.tree.model.datapoints = response.data;
                            ctrl.searchReference = ctrl.tree.model.datapoints;
                            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                                ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                            }
                            ctrl.dpSpinnerDisplay = false;
                            if (Tools.isDefinedNotNull(ctrl.selectedDriverValueId)) {
                                ctrl.tree.model.selected = findNodeInTreeModel(ctrl.tree.model.datapoints, "DRIVERVALUE_UID", ctrl.selectedDriverValueUniqueId);
                            }
                        });
                    } else {
                        $http.post('api/rooms/getDriverValuesForEntity',
                            {
                                "entityId": ctrl.selectedEntity.id,
                                "roomId": currentRoom,
                                "entityType": ctrl.selectedEntity.key,
                            }).then(function (response) {
                            ctrl.tree.model.datapoints = response.data;
                            ctrl.searchReference = ctrl.tree.model.datapoints;
                            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                                ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                            }
                            ctrl.dpSpinnerDisplay = false;
                            if (Tools.isDefinedNotNull(ctrl.selectedDriverValueId)) {
                                ctrl.tree.model.selected = findNodeInTreeModel(ctrl.tree.model.datapoints, "DRIVERVALUE_UID", ctrl.selectedDriverValueUniqueId);
                            }
                        });
                    }
                } else {
                    Notify.warning("global.notification.warning.warn", "dashboard.widget.speedometer.modal.updateConfiguration", 4000);
                }
            } else {
                ctrl.tree.model.datapoints = [];
                ctrl.searchTerm = undefined;
                ctrl.dpSearchTerm = undefined;
            }
        }

        ctrl.toggleUseGradient = function () {
            if (Tools.isDefinedNotNull(ctrl.chart)) {
                ctrl.chart.yAxis.stops = setupColorStops(ctrl.useGradient);
            }
        };

        // ctrl.displayPercentage = function () {
        //     ctrl.showPercent = !ctrl.showPercent;
        // }

        var getStopKey = function (value) {
            var range = ctrl.endValue - ctrl.startValue;
            var tv = value - ctrl.startValue;

            if (tv / range < 0) return 0;
            if (tv / range > 1) return 1;

            return tv / range;
        };

        var setupColorStops = function (gradient) {
            var stops = [];
            if (gradient) {
                stops.push([getStopKey(ctrl.colors.left.value), ctrl.colors.left.color]);
                stops.push([getStopKey(ctrl.colors.middle.value), ctrl.colors.middle.color]);
                stops.push([getStopKey(ctrl.colors.right.value), ctrl.colors.right.color]);
            } else {
                stops.push([0, ctrl.colors.left.color]);
                stops.push([getStopKey(ctrl.colors.middle.value) - 0.0001, ctrl.colors.left.color]);
                stops.push([getStopKey(ctrl.colors.middle.value), ctrl.colors.middle.color]);
                stops.push([getStopKey(ctrl.colors.right.value) - 0.0001, ctrl.colors.middle.color]);
                stops.push([getStopKey(ctrl.colors.right.value), ctrl.colors.right.color]);
                stops.push([1, ctrl.colors.right.color]);
            }

            return stops;
        };

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl._configure);
            _loadSettings();
            setupGauge();
        };

        ctrl._configure = function () {
            stopLiveDataQuery();
            openModal();
            getLocations();
            _loadSettings();
            if (ctrl.selectedLocationId !== null) {
                ctrl.getRoomEntities();
                ctrl.roomsAvailable = true;
                if (ctrl.selectedRoom !== null) {
                    var node = {
                        "id": ctrl.selectedEntityId,
                        "key": ctrl.selectedEntityType
                    }
                    ctrl.displayEntityDataPoints(node, true);
                }
            }
            ctrl.preSelectedLocationId = ctrl.selectedLocationId;
            ctrl.canSave_();
        };

        function openModal() {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/speedometer/widget.speedometer.modal.html',
                animation: true,
                size: 'lg',
                backdrop: 'static',
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }

        function openModal() {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/speedometer/widget.speedometer.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }

        $scope.$on("widgetSizeChanged", function (e, param) {
            if ($scope.widget.wid === param.id) {
                _handleResize(param);
            }
        });

        var _handleResize = function (param) {
            $timeout(function () {
                containerInformation = _computeSize(param);
                if (ctrl.chart) {
                    loadSpeedometer();
                }
            }, 250);
        };

        var _computeSize = function (param) {
            var element = $('#speedometer' + $scope.widget.wid);
            var width = element.parent().parent().parent().parent().width();
            var height = element.parent().parent().parent().parent().height();
            var minSize = Math.min(width, height);
            element.css("width", width + "px");
            element.css("height", ((minSize - 48) * 1.5) + "px");
            return {
                width: width, height: (minSize - 48) * 1.5, cellWidth: param.width, cellHeight: param.height,
                cellWidthPixel: Math.round(width / param.width), cellHeightPixel: Math.round(height / param.height)
            };
        };

        var handleWindowResize = function (e) {
            if (e.originalEvent !== undefined && e.originalEvent.handleObj !== undefined) {
                if (e.originalEvent.handleObj && e.originalEvent.handleObj.type === "mousemove") return;
                _handleResize({width: $scope.widget.width, height: $scope.widget.height});
            }
        };

        $(window).on("resize", handleWindowResize);

        ctrl.closeModal = function () {
            if (currentModal) {
                currentModal.close();
                $state.go($state.current, {}, {reload: true});
            }
        };

        ctrl.cancelModal = function () {
            ctrl.closeModal();
        };

        function _loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch ($scope.widget.settings[i].key) {
                        case 'selectedLocationId':
                            ctrl.selectedLocationId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedRoomId':
                            ctrl.selectedRoomId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedEntityId':
                            ctrl.selectedEntityId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedEntityType':
                            var selectedEntityType = parseInt($scope.widget.settings[i].value);

                            if($scope.widget.settings[i].value != null){
                                ctrl.selectedEntityType = entityType.filter(function (entity) {
                                    return entity.key === selectedEntityType;
                                })[0].value;
                            }
                            break;
                        case 'selectedDriverValueUniqueId':
                            ctrl.selectedDriverValueUniqueId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedDriverValueId':
                            ctrl.selectedDriverValueId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'left_color':
                            ctrl.colors.left.color = $scope.widget.settings[i].value;
                            break;
                        case 'left_color_value':
                            ctrl.colors.left.value = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'middle_color':
                            ctrl.colors.middle.color = $scope.widget.settings[i].value;
                            break;
                        case 'middle_color_value':
                            ctrl.colors.middle.value = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'right_color':
                            ctrl.colors.right.color = $scope.widget.settings[i].value;
                            break;
                        case 'right_color_value':
                            ctrl.colors.right.value = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'start_value':
                            ctrl.startValue = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'end_value':
                            ctrl.endValue = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'unit':
                            ctrl.unit = $scope.widget.settings[i].value;
                            break;
                        case 'unit_of_measurement':
                            ctrl.unitOfMeasurement = $scope.widget.settings[i].value;
                            break;
                        case 'headline':
                            ctrl.headline = $scope.widget.settings[i].value;
                            break;
                        case 'use_gradient':
                            ctrl.useGradient = $scope.widget.settings[i].value === "true";
                            break;
                        case 'display_percent':
                            ctrl.displayPercentageVal = $scope.widget.settings[i].value === "true";
                    }
                }
            }
        }

        var inputValid = function () {
            return (Tools.isDefinedNotNull(ctrl.startValue) || ctrl.startValue !== '' || !isNaN(ctrl.startValue)) &&
                (Tools.isDefinedNotNull(ctrl.endValue) || ctrl.endValue !== '' || !isNaN(ctrl.endValue)) &&
                (ctrl.startValue !== ctrl.endValue) &&
                (Tools.isDefinedNotNull(ctrl.selectedDriverValueId)) &&
                (Tools.isDefinedNotNull(ctrl.colors.right.value) || !isNaN(ctrl.colors.right.value)) &&
                (Tools.isDefinedNotNull(ctrl.colors.middle.value) || !isNaN(ctrl.colors.middle.value)) &&
                (Tools.isDefinedNotNull(ctrl.colors.left.value) || !isNaN(ctrl.colors.left.value));
        };

        ctrl.canSave_ = function () {
            ctrl.canSave = inputValid();
        };

        ctrl.handleBoundaryChange = function () {
            ctrl.canSave = inputValid();
            if (ctrl.canSave) {
                validateColorKey("left");
                validateColorKey("right");
                validateColorKey("middle");
                if (!ctrl.leftValue || !ctrl.middleValue || !ctrl.rightValue) {
                    ctrl.canSave = false;
                }
            }
        };
        // use this as ctrl func to validate single input fields for marking
        var validateColorKey = function (position) {
            var v = ctrl.colors[position].value;
            if (position === "left") {
                if (v > ctrl.colors.middle.value || v > ctrl.colors.right.value || v < ctrl.startValue) {
                    ctrl.leftValue = false;
                } else {
                    ctrl.leftValue = true;
                }
            }
            if (position === "right") {
                if (v < ctrl.colors.left.value || v < ctrl.colors.middle.value || v > ctrl.endValue) {
                    ctrl.rightValue = false;
                } else {
                    ctrl.rightValue = true;
                }
            }
            if (position === "middle") {
                if (v <= ctrl.colors.left.value || v >= ctrl.colors.right.value) {
                    ctrl.middleValue = false;
                } else {
                    ctrl.middleValue = true;
                }
            }
        };

        ctrl.saveSettings = function () {
            var newSettings = [];
            if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                newSettings.push({key: 'selectedLocationId', value: ctrl.selectedLocationId});
                newSettings.push({key: 'selectedRoomId', value: ctrl.selectedRoomId});
                newSettings.push({key: 'selectedEntityId', value: ctrl.selectedEntityId});
                newSettings.push({key: 'selectedEntityType', value: ctrl.selectedEntityType});
                newSettings.push({key: 'selectedDriverValueId', value: ctrl.selectedDriverValue.id});
                newSettings.push({key: 'selectedDriverValueUniqueId', value: ctrl.selectedDriverValue.uniqueId});
                newSettings.push({key: 'left_color', value: ctrl.colors.left.color});
                newSettings.push({key: 'left_color_value', value: ctrl.colors.left.value});
                newSettings.push({key: 'middle_color', value: ctrl.colors.middle.color});
                newSettings.push({key: 'middle_color_value', value: ctrl.colors.middle.value});
                newSettings.push({key: 'right_color', value: ctrl.colors.right.color});
                newSettings.push({key: 'right_color_value', value: ctrl.colors.right.value});
                newSettings.push({key: 'start_value', value: ctrl.startValue});
                newSettings.push({key: 'end_value', value: ctrl.endValue});
                newSettings.push({key: 'unit', value: ctrl.unit});
                newSettings.push({key: 'unit_of_measurement', value: ctrl.unitOfMeasurement});
                newSettings.push({key: 'headline', value: ctrl.headline});
                newSettings.push({key: "use_gradient", value: ctrl.useGradient.toString()});
                newSettings.push({key: "display_percent", value: ctrl.displayPercentageVal.toString()});
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
            } else {
                $scope.widget.settings = newSettings;
                ctrl.selectedLocationId = null;
                ctrl.tree.model = [];
                cleanInputs();
                if (ctrl.chart !== undefined && ctrl.chart !== null) ctrl.chart.destroy();
                chartReady = false;
                $(window).off("resize", handleWindowResize);
                $log.debug("Saving Settings:", $scope.widget.settings);
            }
            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                        ctrl.selectedDriverValueUniqueId = ctrl.selectedDriverValue.uniqueId;
                        ctrl.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    }
                    setupGauge();
                    ctrl.closeModal();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.speedometer.modal.saveErrorMsg", 2000);
                });
            }
            $state.go($state.current, {}, {reload: true});
        };

        function getLocations() {
            Location.queryCompact().then(function (response) {
                ctrl.locations = response.data;
            });
        }

        function setupSelectedEntity() {
            ctrl.rooms = [];
            var defer = $q.defer();
            if (ctrl.selectedLocationId !== null) {
                DashboardService.getRoomsByLocation(ctrl.selectedLocationId).then(function(rooms){
                    if (rooms.length > 0) {
                        for (var index = 0; index < rooms.length; index++) {
                            ctrl.rooms.push(Room.parseFromHtmlObject(rooms[index]));
                        }
                    }
                    if (ctrl.selectedDriverValueId !== null && ctrl.selectedDriverValueId !== undefined) {
                        var setting = $scope.widget.settings.filter(function (setting) {
                            return setting.key === "selectedDriverValueUniqueId"
                        });
                        ctrl.selectedDriverValueUniqueId = parseInt(setting[0].value);
                        ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, ctrl.selectedDriverValueUniqueId);
                    }
                    ctrl.canSave = inputValid();
                    defer.resolve();
                });
            }
            return defer.promise;
        }

 /*       function setupSelectedEntity() {
            ctrl.rooms = [];
            var defer = $q.defer();
            if (ctrl.selectedLocationId !== null) {
                $http.get('api/rooms/' + ctrl.selectedLocationId).then(function (rooms) {
                    if (rooms.data.length > 0) {
                        for (var index = 0; index < rooms.data.length; index++) {
                            ctrl.rooms.push(Room.parseFromHtmlObject(rooms.data[index]));
                        }
                    }
                    if (ctrl.selectedDriverValueId !== null && ctrl.selectedDriverValueId !== undefined) {
                        var setting = $scope.widget.settings.filter(function (setting) {
                            return setting.key === "selectedDriverValueUniqueId"
                        });
                        ctrl.selectedDriverValueUniqueId = parseInt(setting[0].value);
                        ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, ctrl.selectedDriverValueUniqueId);
                    }
                    ctrl.canSave = inputValid();
                    defer.resolve();
                });
            }
            return defer.promise;
        } */


        var searchChildToExpand = function (nodesToExpand, nodes, childId) {
            var travers = function (nodesToExpand, nodes, childId) {
                var newNodesToExpand = Tools.isDefinedNotNull(nodesToExpand) ? angular.copy(nodesToExpand) : [];
                var newNodes = angular.copy(nodes);
                for (var index = 0; index < newNodes.length; index++) {
                    newNodesToExpand = Tools.isDefinedNotNull(newNodesToExpand) ? newNodesToExpand : [];
                    var node = newNodes[index];
                    if (node.id === childId) {
                        return newNodesToExpand;
                    } else if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        newNodesToExpand.push(node);
                        newNodesToExpand = travers(newNodesToExpand, node.children, childId);
                        if (Tools.isDefinedNotNull(newNodesToExpand) && newNodesToExpand.length > 0) {
                            return newNodesToExpand;
                        }
                    }
                }
            };
            return travers(nodesToExpand, nodes, childId);
        };

        var setupGauge = function () {
            if (ctrl.selectedDriverValueId !== null) {
                $(document).ready(function () {
                    containerInformation = _computeSize({width: $scope.widget.width, height: $scope.widget.height});
                    loadSpeedometer();
                });
            }
        };


        var stopLiveDataQuery = function () {
            DashboardService.removeWidget($scope.$id);
        };

        var queryLiveData = function () {
            setupSelectedEntity().then(function () {
                $http.get("api/livedata/drivervalue/" + ctrl.selectedDriverValueId).then(function (response) {
                    ctrl.liveData = response.data[ctrl.selectedDriverValueId];
                    setLiveDataForChart();
                });
            });
        };

        ctrl.refresh = queryLiveData;

        var liveDataQueryInterval = null;

        var startLiveDataQuery = function () {
            DashboardService.queueWidget($scope.$id, ctrl.refresh);
        };

        var setLiveDataForChart = function () {
            if (ctrl.chart && ctrl.chart.renderer !== undefined && !ctrl.chart.renderer.forExport) {
                var point = ctrl.chart.series[0].points[0];
                var liveValue;
                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                    if(ctrl.selectedDriverValue.driver != null) {
                        if (ctrl.selectedDriverValue.driver.driverType === 5 && ctrl.selectedDriverValue.driver.registerValueType == 1) {
                            liveValue = ctrl.liveData > -2147483632 ? ctrl.liveData : null;
                        } else {
                            liveValue = ctrl.liveData > -2147483632 ? ctrl.liveData / 1000 : null;
                        }
                    }

                    ctrl.displayData = liveValue;
                    ctrl.previousLiveData = liveValue;
                    point.update(ctrl.displayData);
                    if(ctrl.calcPercent !== ((( liveValue - ctrl.startValue) / (ctrl.endValue - ctrl.startValue)) * 100).toFixed(1)) {
                        loadSpeedometer();
                    }
                }
            }
        };

        var getSubtitleSize = function () {
            var subTitleSize = 14;
            if (containerInformation.cellHeight < 3 || containerInformation.cellWidthPixel < 50 || containerInformation.cellHeightPixel < 50) subTitleSize = 11;
            return subTitleSize;
        };

        var getTitleSize = function () {
            var titleSize = 16;
            if (containerInformation.cellHeight < 3 || containerInformation.cellWidthPixel < 50 || containerInformation.cellHeightPixel < 50) titleSize = 13;
            return titleSize;
        };

        var getSubtitleOffsetY = function () {
            var posY = containerInformation.height / -2.5;
            if (ctrl.headline !== '' || containerInformation.cellHeight < 3) posY += 20;
            return posY;
        };

        var modifyChartForSize = function () {
            ctrl.chart.title.styles.fontSize = getTitleSize();
            ctrl.chart.userOptions.subtitle.style.fontSize = getSubtitleSize();
            ctrl.chart.userOptions.subtitle.y = getSubtitleOffsetY();
            ctrl.chart.userOptions.series[0].dataLabels.format = getFormattedDataLabelForSize();
            ctrl.chart.userOptions.plotOptions.solidgauge.dataLabels.y = getFormattedDataLabelPositionForSize();
        };
        var getFormattedDataLabelForSize = function () {

            var valueFontSize = 16;
            var unitFontSize = 15;
            ctrl.calcPercent = ((( ctrl.displayData - ctrl.startValue) / (ctrl.endValue - ctrl.startValue)) * 100).toFixed(1);

            if (containerInformation.cellHeight < 3 || containerInformation.cellHeightPixel < 50 || containerInformation.cellWidthPixel < 50) {
                valueFontSize = 12;
                unitFontSize = 11;
            }

            var str;
            if(ctrl.displayPercentageVal) {
                str = '<div style="text-align:center"><span class="highcharts-center-value" style="font-size: ' + valueFontSize + 'px">{y:.1f}</span>' +
                    '<span class="highcharts-center-unit" style="font-size: ' + unitFontSize + 'px">' + ctrl.unit + '</span><br/>' +
                    '<span" class="highcharts-center-value" style="font-size: ' + valueFontSize + 'px">' + ctrl.calcPercent + '%</span></div>';
            } else {
                str = '<div style="text-align:center"><span class="highcharts-center-value" style="font-size: ' + valueFontSize + 'px">{y:.1f}</span>' +
                    '<span class="highcharts-center-unit" style="font-size: ' + unitFontSize + 'px">' + ctrl.unit + '</span><br/></div>';
            }

            return str;
        };

        var getFormattedDataLabelPositionForSize = function () {
            var posY = -55;

            if (ctrl.headline !== '') posY += 10;
            return posY;
        };

        function loadSpeedometer(callback) {
            ctrl.chart = Highcharts.chart('speedometer' + $scope.widget.wid, {
                    chart: {
                        type: 'solidgauge'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ctrl.headline,
                        margin: 5,
                        floating: false,
                        style: {
                            fontSize: getTitleSize()
                        }
                    },
                    pane: {
                        center: ['50%', '50%'],
                        size: '100%',
                        startAngle: -90,
                        endAngle: 90,
                        background: [
                            {
                                backgroundColor: '#EEE',
                                innerRadius: '60%',
                                outerRadius: '100%',
                                shape: 'arc',
                                borderWidth: 1
                            }
                        ]
                    },
                    yAxis: {
                        min: ctrl.startValue,
                        max: ctrl.endValue,
                        stops: setupColorStops(ctrl.useGradient),
                        lineWidth: 1,
                        minorTickInterval: null,
                        tickPixelInterval: 400,
                        tickWidth: 1,
                        tickPositions: [ctrl.startValue, ctrl.endValue],
                        tickAmount: 1,
                        labels: {
                            y: 16
                        }
                    },
                    series: [{
                        name: ctrl.unitOfMeasurement,
                        data: [ctrl.displayData],
                        dataLabels: {
                            format: getFormattedDataLabelForSize()
                        },
                        tooltip: {
                            valueSuffix: ctrl.unit
                        }
                    }],
                    exporting: {
                        enabled: false
                    },
                    subtitle: {
                        floating: true,
                        align: 'center',
                        verticalAlign: 'bottom',
                        y: getSubtitleOffsetY(),
                        text: ctrl.unitOfMeasurement,
                        style: {
                            fontSize: getSubtitleSize()
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: getFormattedDataLabelPositionForSize(),
                                borderWidth: 0,
                                useHTML: true
                            }
                        }
                    }
                },
                function (chart) {
                    ctrl.chart = chart;
                    queryLiveData();
                    startLiveDataQuery();
                    chartReady = true;
                });
        }

        $scope.$on('$destroy', function () {
            stopLiveDataQuery();
            if (ctrl.chart !== undefined && ctrl.chart !== null) ctrl.chart.destroy();
            chartReady = false;
            $(window).off("resize", handleWindowResize);
        });
    }

    angular.module('emsv2App')
        .controller('WidgetSpeedometerController', WidgetSpeedometerController)

})
();