(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('password.forgot', {
                    parent: 'password',
                    url: '/forgot',
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/password/forgot/forgot.html',
                            controller: 'PasswordForgotController',
                            controllerAs: '$ctrl'
                        }
                    },
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                    }

                });
        });

})();
