(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name Device
     * @requires $http
     */
    angular.module('emsv2App').factory('Device', ['$http', function ($http) {
        return {
            'get': function (mac) {
                return $http.get('api/devices/' + mac);
            },
            'getVirtualDevices': function () {
                return $http.get('api/devices/getVirtualDevices');
            },
            'backup': function (mac) {
                return $http.put('api/devices/' + mac + '/backup');
            },
            'getAvailableNodes': function (locationId) {
                return $http.get('api/nodes/' + locationId);
            }
        };
    }]);

})();
