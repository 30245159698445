(function () {

    'use strict';

    function roomController(Location, LocationDetailService, $scope, $state, $stateParams, $log, $translate, $interval, $uibModal,
                            GenDialogService, MessageService, RoomService, LiveDataService, Buildings,
                            user, rooms, license, buildings) {
        var ctrl = this;
        ctrl.user = user;
        ctrl.buildings = buildings;
        ctrl.rooms = rooms;

        ctrl.buildingId = $stateParams.bid !== undefined && !isNaN($stateParams.bid) ? parseInt($stateParams.bid) : null;
        if (ctrl.buildingId === null && buildings.length > 0) ctrl.buildingId = buildings[0].id;

        ctrl.$onInit = function () {
            LocationDetailService.reset().setDefaultBackToOverview();
        };

        MessageService.subscribe("buildingIdChanged", function (msg, opts) {
            ctrl.rooms = rooms.filter(function (r) {return r.buildingId === opts.bid;});
            $state.go('location.rooms', {view: $stateParams.view, bid: opts.bid}, {notify: false});
        });

        MessageService.subscribe("deletedRoom", function (msg, opts) {
            RoomService.getRooms($stateParams.id).then(function (response) {
                rooms = [];
                for (var i in response.data) {
                    rooms.push(Room.parseFromHtmlObject(response.data[i]));
                    rooms[rooms.length - 1].showsTree = false;
                    rooms[rooms.length - 1].ffloor = response.data[i].floor;
                }
                Buildings.setupRooms(buildings, rooms);
                ctrl.rooms = rooms.filter(function (r) {return r.buildingId === opts.buildingId;});
            }, function (error) {
                $log.error("Error retrieving rooms after delete." + error);
            });
        });

        (function () {
            Buildings.setupRooms(buildings, rooms);
            if (buildings.length > 0) ctrl.rooms = rooms.filter(function (r) {return r.buildingId === ctrl.buildingId;});
        })();
    }

    angular
        .module('emsv2App')
        .controller('roomController', roomController);

})();
