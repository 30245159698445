(function () {
    'use strict';
    angular.module("emsv2App").directive("fileChange", function () {
        return {
            require: "ngModel",
            restrict: 'A',
            link: function (scope, elem, attrs, ngModel) {
                elem.bind('change', function (e) {
                    var file = (e.srcElement || e.target).files[0];
                    ngModel.$setViewValue(file);
                    scope.$apply();
                });
            }
        }
    });
})();
