(function () {

    'use strict';

    function tabsController($rootScope, $scope, $state, $stateParams, $uibModal, Location, license, LocationDetailService) {
        var ctrl = this;
        ctrl.activeTabState = $state.current.name;
        ctrl.activeTab = null;
        ctrl.activeTabIndex = null;
        ctrl.NameTo = null;
        ctrl.license = license;
        ctrl.tabs = angular.copy(Location.getAvailableTabs());
        ctrl.name = angular.copy(Location.getAvailableTabs());
        ctrl.locationDetailService = LocationDetailService;

        this.$onInit = function () {
            // get active tab
            for (var i = 0; i < ctrl.tabs.length; i++) {
                var tab = ctrl.tabs[i];
                var count = ctrl.activeTabState.split(".").length;
                var isState = false;
                if (count === 2) {
                    isState = ctrl.activeTabState === tab.state;
                } else {
                    isState = ctrl.activeTabState.startsWith(tab.state);
                }
                if (isState) {
                    ctrl.activeTab = ctrl.tabs[i];
                    ctrl.activeTabIndex = ctrl.activeTab.index;
                    break;
                }
            }
        };

        function updateActiveTabByIndex(inx) {
            for (var i = 0; i < ctrl.tabs.length; i++) {
                if (ctrl.tabs[i].index === inx) {
                    ctrl.activeTab = ctrl.tabs[i];
                    break;
                }
            }
        }

        ctrl.canSave = function () {
            if (LocationDetailService.canSave) {
                return LocationDetailService.canSave();
            }
            return true;
        };

        /**
         * workaround wenn man via bradcrumbs auf overview springen will und workaround für back button
         */

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
            // console.log("to state: "+toState.name);
            if (toState.redirectTo === "location.overview") {
                ctrl.stateChange(0, toState.redirectTo);
            }
            if (toState.name === "location.buildings" && fromState.name === "location.buildings.new") {
                // $state.go($state.current.parent, undefined, {reload: true});
                ctrl.stateChange(2, toState.name);
            }
            if (toState.name === "location.ebalance") {
                LocationDetailService.onSave = null;
                LocationDetailService.onEdit = null;
            }

            //todo regex
            var tmpRegex = new RegExp('location.alarm.');

            var matchNameTo = toState.name;
            // ctrl.NameTo is used in tabs.html to fix CGLT-470 History back button on mouse displayed the wrong page
            ctrl.NameTo = matchNameTo;
            var matchNameFrom = fromState.name;

            if (toState.name.match(tmpRegex)) {
                matchNameTo = 'location.alarm';
            }

            if (fromState.name.match(tmpRegex)) {
                matchNameFrom = "location.alarm";
            }

            var tmpTo = ctrl.tabs.filter(function (elem) {
                return elem.state === matchNameTo;
            });
            var tmpFrom = ctrl.tabs.filter(function (elem) {
                return elem.state === matchNameFrom;
            });

            if (tmpTo.length && tmpFrom.length) {
                ctrl.stateChange(tmpTo[0].index, tmpTo[0].state);
            }
        });

        ctrl.stateChange = function (index, state) {
            if (ctrl.activeTabIndex !== index) {
                ctrl.activeTabIndex = index;
                ctrl.activeTabState = state;
                updateActiveTabByIndex(ctrl.activeTabIndex);
                $state.go(state, {id: $stateParams.id, mode: 'view'}, {reload: false});
            } else {
                // $state.go(state, {id: $stateParams.id, mode: 'edit'}, {reload: true});
            }

        };

        $scope.$watch(function () { return LocationDetailService.canChange; }, function () {
            for (var i = 0; i < ctrl.tabs.length; i++) {
                var tab = ctrl.tabs[i];
                if (LocationDetailService.canChange === false) {
                    i === LocationDetailService.onlyActiveTab ? tab.disabled = false : tab.disabled = true;
                } else {
                    tab.disabled = false;
                }
            }
        });
    }

    angular
        .module('emsv2App')
        .controller('TabsController', tabsController);
})();
