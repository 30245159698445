(function () {

    'use strict';

    var getUser = ['$log', 'Principal', function ($log, Principal) {
        return Principal.identity().then(function (acc) {
            return acc;
        }, function (error) {
            $log.error("Could not get current user information\n" + error);
        });
    }];

    var getBuildings = ['$log', '$stateParams', 'Buildings', function ($log, $stateParams, Buildings) {
        return Buildings.getByLocation($stateParams.id).then(function (response) {
            var buildings = [];
            for (var i in response.data) {
                buildings.push(Building.parseFromHtmlObject(response.data[i]));
            }
            return buildings;
        }, function (error) {
            $log.error('Error retrieving building for location with id: ' + $stateParams.id + "\n" + error);
        });
    }];

    var getLicense = ['$log', "License", function ($log, License) {
        return License.get().then(function (response) {
            return response.data.data;
        }, function (error) {
            $log.error('Error retrieving license information\n' + error);
        });
    }];

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('location.livedata', {
                parent: 'location.detail',
                url: '/livedata',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.liveData',
                    license: 'livedata'
                },
                templateUrl: 'scripts/app/location/tabs/livedata/livedata.html',
                controller: 'livedataController',
                controllerAs: 'ctrl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('room');
                        $translatePartialLoader.addPart('livedata');
                        return $translate.refresh();
                    }],
                    user: getUser,
                    rooms: ['$q', '$log', '$stateParams', 'RoomService', function ($q, $log, $stateParams, RoomService) {
                        if ($stateParams.id === "") return $q.when(null);
                        return RoomService.getSimpleRooms($stateParams.id).then(function (response) {
                            var rooms = [];
                            for (var i in response.data) {
                                rooms.push(Room.parseFromHtmlObject(response.data[i]));
                                rooms[rooms.length - 1].showsTree = false;
                                rooms[rooms.length - 1].ffloor = response.data[i].floor;
                            }
                            return rooms;
                        }, function (error) {
                            $log.error("Error retrieving rooms for location with id: " + $stateParams.id + "\n" + error + "\n" + error.data);
                            return error;
                        });
                    }],
                    buildings: getBuildings,
                    license: getLicense,
                }
            })
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
