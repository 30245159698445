'use strict';

/**
 * @description Constructor for rack object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing the position to set
 * @param {object} size 3-dimensional vector object describing the size to set
 * @param {object} rot 3-dimensional vector object describing the rotation to set
 * @param {string} name name to set
 * @param {string} comment comment to set
 * @param {string} inventoryNumber inventory number to set
 * @param {string} serialNumber serial number to set
 * @param {number} type the type to set (1 or 2 doors )
 * @param {number} hu number of height units to set
 * @param {array} slots array of slot object to set
 * @param {string} floorPanelCustomPos
 * @param {number} partLibraryRackId id of part library object used to create this object
 * @param {string} url the url to set
 * @param {number} uid the unique id to setup
 * @param {number} pid the parent id to set (room id)
 * @param {number} weight the weight of the rack in kg
 * @param {number} maxWeight the maxWeight of the rack in kg
 * @param {number} totalWeight describes the total weight of all slots and the whole rack
 * @param {number} consumption the consumption of the rack in W
 * @constructor
 */
function Rack(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, type, hu, slots, floorPanelCustomPos
    , partLibraryRackId, url, uid, pid, weight, maxWeight, totalWeight, consumption, deepCopy) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.type = type !== undefined ? type : 0;
    this.heightUnits = hu !== undefined ? hu : 0;
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    // Next all controls for a deep copy of a rack (a copy with all objects and parameters inside of it)
    if (slots !== undefined && slots instanceof Array && deepCopy) {
        var rackId = angular.copy(Entity.getNewLocaleUniqueId());
        for (var slot = 0; slot < slots.length; slot++) {
            slots[slot].id = angular.copy(Entity.getNewLocaleUniqueId());
            slots[slot].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            slots[slot].rackId = rackId;
            if (slots[slot].blades.length > 0) {
                var slotId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var blade = 0; blade < slots[slot].blades.length; blade++) {
                    slots[slot].blades[blade].id = angular.copy(Entity.getNewLocaleUniqueId());
                    slots[slot].blades[blade].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    slots[slot].blades[blade].slotId = slotId;
                    if (slots[slot].blades[blade].cpus.length > 0) {
                        var bladeId = angular.copy(Entity.getNewLocaleUniqueId());
                        for (var cpu = 0; cpu < slots[slot].blades[blade].cpus.length; cpu++) {
                            slots[slot].blades[blade].cpus[cpu].id = angular.copy(Entity.getNewLocaleUniqueId());
                            slots[slot].blades[blade].cpus[cpu].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                            slots[slot].blades[blade].cpus[cpu].bladeId = bladeId;
                            if (slots[slot].blades[blade].cpus[cpu].driverValues.length > 0) {
                                var sensorId = angular.copy(Entity.getNewLocaleUniqueId());
                                for (var dv = 0; dv < slots[slot].blades[blade].cpus[cpu].driverValues.length; dv++) {
                                    slots[slot].blades[blade].cpus[cpu].driverValues[dv].id = angular.copy(Entity.getNewLocaleUniqueId());
                                    slots[slot].blades[blade].cpus[cpu].driverValues[dv].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                                    slots[slot].blades[blade].cpus[cpu].driverValues[dv].sensorId = sensorId;
                                    slots[slot].blades[blade].cpus[cpu].driverValues[dv].driver.id = angular.copy(Entity.getNewLocaleUniqueId());
                                    slots[slot].blades[blade].cpus[cpu].driverValues[dv].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                                    if (slots[slot].blades[blade].cpus[cpu].driverValues[dv].limits.length > 0) {
                                        var cpuDriverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                                        for (var limit = 0; limit < slots[slot].blades[blade].cpus[cpu].driverValues[dv].limits.length; limit++) {
                                            slots[slot].blades[blade].cpus[cpu].driverValues[dv].limits[limit].id = angular.copy(Entity.getNewLocaleUniqueId());
                                            slots[slot].blades[blade].cpus[cpu].driverValues[dv].limits[limit].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                                            slots[slot].blades[blade].cpus[cpu].driverValues[dv].limits[limit].driverValueId = cpuDriverValueId;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
            if (slots[slot].driverValues.length > 0) {
                var sensorId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var dv = 0; dv < slots[slot].driverValues.length; dv++) {
                    slots[slot].driverValues[dv].id = angular.copy(Entity.getNewLocaleUniqueId());
                    slots[slot].driverValues[dv].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    slots[slot].driverValues[dv].sensorId = sensorId;
                    slots[slot].driverValues[dv].driver.id = angular.copy(Entity.getNewLocaleUniqueId());
                    slots[slot].driverValues[dv].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    if (slots[slot].driverValues[dv].limits.length > 0) {
                        var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                        for (var limit = 0; limit < slots[slot].driverValues[dv].limits.length; limit ++) {
                            slots[slot].driverValues[dv].limits[limit].id = angular.copy(Entity.getNewLocaleUniqueId());
                            slots[slot].driverValues[dv].limits[limit].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                            slots[slot].driverValues[dv].limits[limit].driverValueId = driverValueId;
                        }
                    }
                }
            }
        }
        this.slots = slots;
    } else {
        this.slots = [];
    }
    this.roomId = pid !== undefined ? pid : null;
    this.floorPanelCustomPos = floorPanelCustomPos !== undefined ? floorPanelCustomPos : "";
    this.partLibraryRackId = partLibraryRackId !== undefined ? partLibraryRackId : null;
    this.stdFloorPos = null;
    this.floorPosition = "";
    this.isRack = true;
    this.allowDataPoints = false;
    this.url = url !== undefined ? url : "";
    this.weight = weight !== undefined && weight !== null && !isNaN(weight) ? weight : null;
    this.maxWeight = maxWeight !== undefined && maxWeight !== null && !isNaN(maxWeight) ? maxWeight : null;
    this.totalWeight = this.computeWeight().weight;
    this.consumption = consumption !== undefined && consumption !== null && !isNaN(consumption) ? consumption : null;
    this.maxConsumption = this.computeConsumption().maxConsumption;
}

Rack.prototype = Object.create(NamedEntity.prototype);
Rack.prototype.constructor = Rack;

/**
 * @description Function to test equality of this rack and the provided object
 * @param {object} cr object to compare with
 * @returns {boolean} returns true if provided object equals this rack
 */
Rack.prototype.equals = function (cr) {
    if (!(cr instanceof Rack)) return false;
    if (!this.equalsNamedEntity(cr)) return false;
    if (this.roomId !== cr.roomId) return false;
    if (this.inventoryNumber !== cr.inventoryNumber) return false;
    if (this.serialNumber !== cr.serialNumber) return false;
    // if(this.objectMac != cs.objectMac) return false;
    // if(this.url != cs.url) return false;
    if (this.floorPanelCustomPos !== cr.floorPanelCustomPos) return false;
    if (this.partLibraryRackId !== cr.partLibraryRackId) return false;
    //TODO compare driverValues if
    if (this.slots.length !== cr.slots.length) return false;

    if (this.consumption !== cr.consumption) return false;
    if (this.maxConsumption !== cr.maxConsumption) return false;
    if (this.maxWeight !== cr.maxWeight) return false;
    if (this.weight !== cr.weight) return false;

    for (var i = 0; i < this.slots.length; i++) {
        var slot = this.slots[i];
        var cs = cr.slots.filter(function (s) {return s.id === slot.id;})[0];
        if (!cs) return false;
        if (!slot.equals(cs)) return false;
    }
    return true;
};

/**
 * @description Function to find object defined by a unique id in this object (incl. child objects)
 * @param {number} uid the unique id to search for
 * @returns {*} returns object if found, otherwise null
 */
Rack.prototype.findObjectByUniqueId = function (uid) {
    if (this.uniqueId === uid) return this;
    for (var s = 0; s < this.slots.length; s++) {
        if (this.slots[s].uniqueId === uid) return this.slots[s];
        for (var b = 0; b < this.slots[s].blades.length; b++) {
            if (this.slots[s].blades[b].uniqueId === uid) return this.slots[s].blades[b];
            for (var c = 0; c < this.slots[s].blades[b].cpus.length; c++) {
                if (this.slots[s].blades[b].cpus[c].uniqueId === uid) return this.slots[s].blades[b].cpus[c];
            }
        }
    }
    return null;
};

/**
 * @description Function to search for object defined by type and id. this object and child objects
 * @param {string} type type of the object to search for
 * @param {number} id the id to search for
 * @returns {*} returns object if found, otherwise null
 */
Rack.prototype.findObjectByTypeAndId = function (type, id) {
    for (var s = 0; s < this.slots.length; s++) {
        if (type === "slot" && this.slots[s].id === id) return this.slots[s];
        for (var b = 0; b < this.slots[s].blades.length; b++) {
            if (type === "blade" && this.slots[s].blades[b].id === id) return this.slots[s].blades[b];
            for (var c = 0; c < this.slots[s].blades[b].cpus.length; c++) {
                if (type === "cpu" && this.slots[s].blades[b].cpus[c].id === id) return this.slots[s].blades[b].cpus[c];
            }
        }
    }
    return null;
};

/**
 * @description Function to find a cpu in this rack object by attribute name and value
 * @param {string} attrName the name of the attribute to use while searching
 * @param {object} attrValue the value to search for
 * @returns {*} returns object if found, otherwise null
 */
Rack.prototype.findCpuByAttribute = function (attrName, attrValue) {
    for (var j = 0; j < this.slots.length; j++) {
        for (var k = 0; k < this.slots[j].blades.length; k++) {
            for (var l = 0; l < this.slots[j].blades[k].cpus.length; l++) {
                var tattr = this.slots[j].blades[k].cpus[l][attrName];
                if (tattr && attrValue === tattr) return this.slots[j].blades[k].cpus[l];
            }
        }
    }
    return null;
};

/**
 *
 * @param pos
 * @returns {*}
 */
Rack.prototype.findSlotByPosition = function (pos) {
    for (var i = 0; i < this.slots.length; i++) {
        if (this.slots[i].pos.x === pos.x && this.slots[i].pos.y === pos.y && this.slots[i].pos.z === pos.z) return this.slots[i];
    }
    return null;
};

/**
 * @description Function to reset all ids and unique ids of this object and its child objects
 */
Rack.prototype.resetIds = function () {
    var i;
    if (this.id < 0) this.id = null;
    if (this.uniqueId < 0) this.uniqueId = null;
    if (this.hasOwnProperty("driverValues")) {
        for (i = 0; i < this.driverValues.length; i++) {
            if (this.driverValues[i].id < 0) this.driverValues[i].id = null;
            if (this.driverValues[i].uniqueId < 0) this.driverValues[i].uniqueId = null;
            if (this.driverValues[i].driver === null) continue;
            if (this.driverValues[i].driver.id < 0) this.driverValues[i].driver.id = null;
            if (this.driverValues[i].driver.uniqueId < 0) this.driverValues[i].driver.uniqueId = null;
        }
    }
    for (i = 0; i < this.slots.length; i++) this.slots[i].resetIds();
};

/**
 * @description Function to modify the object to work with backend (pos, size, rot)
 */
Rack.prototype.parseSizeRotPosForBackend = function () {
    this.rotX = this.rot.x;
    this.rotY = this.rot.y;
    this.rotZ = this.rot.z;

    this.posX = this.pos.x;
    this.posY = this.pos.y;
    this.posZ = this.pos.z;

    this.scaleX = this.size.x;
    this.scaleY = this.size.y;
    this.scaleZ = this.size.z;
    for (var i = 0; i < this.slots.length; i++) this.slots[i].parseSizeRotPosForBackend();
};

/**
 * @description Function to check if this object intersects other objects
 * @param {THREE.Object3D} obj3d_self the 3d object of this rack object
 * @param {THREE.Object3D} obj3d_room the 3d object of the room containing the rack
 * @param {object} pos 3-dimensional vector object describing the position to check collision for
 * @param {boolean} onlyWalls if set to true only basic collision checks with walls
 * @param {Room} roomObj the room object for the provided 3d room object
 * @returns {boolean} returns true if collision is detected, otherwise false
 */
Rack.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d_self.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    testObb.rotateY(this.rot.y * (Math.PI / 180));
    //TODO handle collide for slots
    //check point in room
    if (!roomObj.checkOBBInRoom(testObb, false)) return true;
    //check collide walls
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === "asset" || o.name === "ups" || o.name === "floortile")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d_self.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
};

/**
 * @description Function get possible parameters for the rack
 * @param {array} availableParameters array of parameters for rack objects
 * @returns parmaeters of the rack object
 */
Rack.prototype.setupParameters = function (availableParameters) {

    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = [];

        if (this.driverValues !== undefined) {
            param = this.driverValues.filter(function (elem) {
                return elem.parameter.id === availableParameters[ap].id;
            });
        }
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        } else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [[], []];
};

/**
 * @description Function to get the number of free height unit spaces in this rack
 * @returns {number} returns the number of free height unit spaces in this rack
 */
Rack.prototype.getFreeHU = function () {
    var h = 0;
    for (var i = 0; i < this.slots.length; i++) {
        h += this.slots[i].size.y;
    }
    return this.heightUnits - Math.ceil(h / 0.0424);
};

/**
 * @description Function to get the number of used height unit spaces in this rack
 * @returns {number} returns the number of used height unit spaces in this rack
 */

Rack.prototype.getUsedHU = function () {
    var usedHU = 0;
    for (var i = 0; i < this.slots.length; i++) {
        usedHU += this.slots[i].size.y;
    }
    // return Math.ceil(usedHU / 0.0424);
    return Math.ceil(usedHU / 0.0445);
};

/**
 * @description Function to get the number of height units in this rack
 * @returns {number} returns the number of height units in this rack
 */

Rack.prototype.getHUforRack = function () {
    return this.heightUnits;
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
Rack.prototype.validate = function () {
    var i, errorList = this.validateNamedEntity();
    if (this.type === undefined || this.type === null || this.type < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "type"));
    if (this.heightUnits === undefined || this.heightUnits === null || this.heightUnits <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "heightUnits"));
    if (this.partLibraryRackId === undefined || this.partLibraryRackId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "partLibraryRackId"));
    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));
    if (this.driverValues !== undefined && this.driverValues !== null) for (i = 0; i < this.driverValues.length; i++) errorList = errorList.concat(this.driverValues[i].validate());
    for (i = 0; i < this.slots.length; i++) errorList = errorList.concat(this.slots[i].validate());
    return errorList;
};

/**
 * @description Function to find object with specified unique id in this object or its child objects
 * @param {number} uniqueId the unique id to search for
 * @returns {*} returns array with found object and this parent object, if nothing is found an empty array will be returned
 */
Rack.prototype.findObjectPathByUniqueId = function (uniqueId) {
    var i, objs;
    if (this.uniqueId === uniqueId) return [this];
    for (i = 0; i < this.driverValues.length; i++) {
        objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    for (i = 0; i < this.slots.length; i++) {
        objs = this.slots[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    return [];
};

/**
 * @description Function to compute the consumption for this rack and all sub-objects
 * @returns {{consumption: number, fullyDefined: boolean}} returns object containing information about consumption and if all objects had a valid value
 */
Rack.prototype.computeConsumption = function () {
    var retObj = {
        slotConsumption: 0,
        maxConsumption: this.consumption !== undefined && this.consumption !== null && !isNaN(this.consumption) ? this.consumption : 0,
        fullyDefined: true,
        limitReached: false
    };
    if (this.maxConsumption !== null && this.maxConsumption > 0) {
        retObj.maxConsumption; //+= this.consumption
    }

    for (var i = 0; i < this.slots.length; i++) {
        if (this.slots[i].consumption !== null && this.slots[i].consumption > 0) {
            retObj.slotConsumption += this.slots[i].consumption;
        } else {
            retObj.fullyDefined = false;
        }
    }
    if (retObj.slotConsumption > retObj.maxConsumption) {
        retObj.limitReached = true;
    } else {
        retObj.limitReached = false;
    }
    return retObj;
};

/**
 * @description Function to compute the weight for this rack and all sub-objects
 * @returns {{weight: number, fullyDefined: boolean}} returns object containing information about weight and if all objects had a valid value
 */
Rack.prototype.computeWeight = function () {
    var retObj = {
        weight: 0,
        maxWeight: this.maxWeight !== undefined && this.maxWeight !== null && !isNaN(this.maxWeight) ? this.maxWeight : 0,
        fullyDefined: true,
        limitReached: false
    };
    if (this.weight !== null && this.weight > 0) {
        retObj.weight += this.weight;
    } else {
        retObj.fullyDefined = false;
    }
    for (var i = 0; i < this.slots.length; i++) {
        if (this.slots[i].weight !== null && this.slots[i].weight > 0) {
            retObj.weight += this.slots[i].weight;
        } else {
            retObj.fullyDefined = false;
        }
    }
    if (retObj.weight > retObj.maxWeight) {
        retObj.limitReached = true;
    } else {
        retObj.limitReached = false;
    }

    return retObj;
};

/**
 * @description Function to create a rack object from json data provided by backend
 * @param {object} obj the json object to parse
 * @param {number} pid the parent id to set
 * @param {boolean} simple if true only this object will be parsed without any child objects (slots etc.), otherwise full object will be parsed
 * @returns {Rack} returns rack object parsed from the provided object
 */
Rack.parseFromHtmlObject = function (obj, pid, simple) {
    var rack = new Rack(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.type, obj.heightUnits, undefined, obj.floorPanelCustomPos, obj.partLibraryRackId, obj.url, obj.uniqueId, pid, obj.weight, obj.maxWeight, undefined, obj.consumption);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, rack);
    rack.modifyDriverValueLimitsForFrontend();
    if (simple) return rack;
    if (obj.slots) {
        for (var s = 0; s < obj.slots.length; s++) {
            var slot = Slot.parseFromHtmlObject(obj.slots[s], rack.id);
            slot.computeHUPosition(rack);
            if (obj.slots[s].driverValues) slot.driverValues = obj.slots[s].driverValues;
            rack.slots.push(slot);
            if (obj.slots[s].blades) {
                for (var b = 0; b < obj.slots[s].blades.length; b++) {
                    var blade = Blade.parseFromHtmlObject(obj.slots[s].blades[b], slot.id);
                    slot.blades.push(blade);
                    if (obj.slots[s].blades[b].cpus) {
                        for (var c = 0; c < obj.slots[s].blades[b].cpus.length; c++) {
                            var cpu = Cpu.parseFromHtmlObject(obj.slots[s].blades[b].cpus[c], blade.id);
                            if (obj.slots[s].blades[b].cpus[c].driverValues) cpu.driverValues = obj.slots[s].blades[b].cpus[c].driverValues;
                            blade.cpus.push(cpu);
                        }
                    }
                }
            }
        }
    }
    return rack;
};

/**
 * @description Function to create a rack from a simple javascript object (copied in frontend)
 * @param {object} obj the object to use
 * @param {number} pid the parent id of the created rack
 * @param {boolean} simple if set to true no child objects will be parsed, otherwise full object will be parse to created rack
 * @returns {Rack} returns rack object created from provided js object
 */
Rack.parseFromSimpleObject = function (obj, pid, simple) {
    var rack = new Rack(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.type, obj.heightUnits, undefined, obj.floorPanelCustomPos, obj.partLibraryRackId, obj.url, obj.uniqueId, pid, obj.weight, obj.maxWeight, undefined, obj.consumption);
    if (obj.driverValues) rack.driverValues = obj.driverValues;
    if (simple) return rack;
    if (obj.slots) {
        for (var s = 0; s < obj.slots.length; s++) {
            var slot = Slot.parseFromSimpleObject(obj.slots[s], rack.id);
            slot.computeHUPosition(rack);
            if (obj.slots[s].driverValues) slot.driverValues = obj.slots[s].driverValues;
            rack.slots.push(slot);
            if (obj.slots[s].blades) {
                for (var b = 0; b < obj.slots[s].blades.length; b++) {
                    var blade = Blade.parseFromSimpleObject(obj.slots[s].blades[b], slot.id);
                    slot.blades.push(blade);
                    if (obj.slots[s].blades[b].cpus) {
                        for (var c = 0; c < obj.slots[s].blades[b].cpus.length; c++) {
                            var cpu = Cpu.parseFromSimpleObject(obj.slots[s].blades[b].cpus[c], blade.id);
                            if (obj.slots[s].blades[b].cpus[c].driverValues) cpu.driverValues = obj.slots[s].blades[b].cpus[c].driverValues;
                            blade.cpus.push(cpu);
                        }
                    }
                }
            }
        }
    }
    return rack;
};


