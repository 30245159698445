'use strict';

/**
 * @description Constructor to create asset object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing the objects position
 * @param {object} size 3-dimensional vector object describing the objects size
 * @param {object} rot 3-dimensional vector object describing the objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {string} inventoryNumber the inventory number to set
 * @param {string} serialNumber the serial number to set
 * @param {string} objectMac
 * @param {string} url the url to set
 * @param {string} floorPanelCustomPos the custom position set by user
 * @param {number} partLibraryAssetId the id of the part library object used to create this object
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set (room id)
 * @constructor
 */
function Asset(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, objectMac, url, floorPanelCustomPos, partLibraryAssetId, uid, pid, driverValues) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.objectMac = objectMac !== undefined ? objectMac : null;
    this.url = url !== undefined ? url : "";
    this.roomId = pid !== undefined ? pid : null;
    this.partLibraryAssetId = partLibraryAssetId !== undefined ? partLibraryAssetId : null;
    this.floorPanelCustomPos = floorPanelCustomPos !== undefined ? floorPanelCustomPos : "";
    this.stdFloorPos = null;
    // Next all controls for a deep copy of an asset (a copy with all parameters inside of it)
    if (driverValues !==null && driverValues !==undefined && driverValues.length > 0) {
        for (var i = 0; i < driverValues.length; i++) {
            driverValues[i].id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.id= angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (driverValues[i].limits.length > 0) {
                var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var l=0; l < driverValues[i].limits.length; l++) {
                    driverValues[i].limits[l].id = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].driverValueId = driverValueId;
                }
            }
        }
        this.driverValues = driverValues;
    } else {
        this.driverValues = [];
    }

    this.floorPosition = "";
    this.isAsset = true;
    this.allowDataPoints = true;
}

Asset.prototype = Object.create(NamedEntity.prototype);
Asset.prototype.constructor = Asset;

/**
 * @description Function to test equality of this object and the provided object
 * @param {object} ca the object to compare this object with
 * @returns {boolean} returns true if this object and the provided object are equal, otherwise false
 */
Asset.prototype.equals = function (ca) {
    if (!(ca instanceof Asset)) return false;
    if (!this.equalsNamedEntity(ca)) return false;
    if (this.roomId !== ca.roomId) return false;
    if (this.inventoryNumber !== ca.inventoryNumber) return false;
    if (this.serialNumber !== ca.serialNumber) return false;
    if (this.objectMac !== ca.objectMac) return false;
    if (this.url !== ca.url) return false;
    if (this.partLibraryAssetId !== ca.partLibraryAssetId) return false;
    if (this.floorPanelCustomPos !== ca.floorPanelCustomPos) return false;
    if (!this.compareDriverValues(ca.driverValues)) return false;
    return true;
};

/**
 * @description Function to get information about un/used parameters for this object
 * @param {DriverParameter[]} availableParameters
 * @returns array of length 2, containing arrays of used and unused parameters
 */
Asset.prototype.setupParameters = function (availableParameters) {

    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = this.driverValues.filter(function (elem) {
            return elem.parameter.id === availableParameters[ap].id;
        });
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        }
        else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to check if this object collides with another object
 * @param {THREE.Object3D} obj3d_self the 3d-object representing this object
 * @param {THREE.Object3D} obj3d_room the 3d-object representing this objects parent room
 * @param {THREE.Vector3} pos position to check collision for
 * @param {boolean} onlyWalls if set to true only collisions with walls will be tested
 * @param {Room} roomObj the room object to use
 * @returns {boolean} return true if objects collides with something, otherwise false
 */
Asset.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d_self.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    testObb.rotateY(this.rot.y * (Math.PI / 180));
    //check point in room
    if (!roomObj.checkOBBInRoom(testObb, false)) return true;
    //check collide walls
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === "asset"  || o.name === "ups" || o.name === "floortile")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d_self.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
};

/**
 * @description Function to get this objects part library id
 * @returns {*} returns this objects part library id
 */
Asset.prototype.getPartLibraryId = function () {
    return this.partLibraryAssetId;
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} array of error objects
 */
Asset.prototype.validate = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateNamedEntity());

    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));
    if (this.partLibraryAssetId === undefined || this.partLibraryAssetId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "partLibraryAssetId"));

    for (var i = 0; i < this.driverValues.length; i++) errorList = errorList.concat(this.driverValues[i].validate());
    return errorList;
};

Asset.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    for (var i = 0; i < this.driverValues.length; i++) {
        var objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }

    return [];
};

/**
 * @description Function to filter available drivers for assets
 * @param {object} allDrivers dict of all supported driver types
 * @returns {object} returns a dictionary(associative array) with all supported driver types for assets
 */
Asset.getAvailableDrivers = function (allDrivers) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "WIB8000") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to create a asset object from json object provided by backend
 * @param {object} obj the json object to use for creation of the asset
 * @param {number} pid the parent id of this object (room id)
 * @returns {Asset} returns the created asset
 */
Asset.parseFromHtmlObject = function (obj, pid) {
    var asset = new Asset(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.floorPanelCustomPos, obj.partLibraryAssetId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, asset);
    asset.modifyDriverValueLimitsForFrontend();
    return asset;
};

/**
 * @description Function to create a asset from provided javascript object
 * @param {object} obj the object to use for creating of asset
 * @param {number} pid the parent id to set (room id)
 * @returns {Asset} returns the created asset object
 */
Asset.parseFromSimpleObject = function (obj, pid) {
    var asset = new Asset(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.floorPanelCustomPos, obj.partLibraryAssetId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, asset);
    return asset;
};
