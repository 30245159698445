'use strict';

/**
 * @description Constructor to create cooling unit object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {string} inventoryNumber the inventory number to set
 * @param {string} serialNumber the serial number to set
 * @param {number} objectMac the object mac to set (not used anymore)
 * @param {string} url the url to set
 * @param {number} airFlowDirection air flow direction to set
 * @param {number} type the type to set
 * @param alertConfigType
 * @param {number} maxPower the max. power to set
 * @param {number} maxCooling the max. cooling capacity to set
 * @param {string} floorPanelCustomPos custom floor position to set
 * @param {number} partLibraryCoolingId the part library id to set
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set (room id)
 * @constructor
 */
function Cooling(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, objectMac, url, airFlowDirection, type, alertConfigType, maxPower, maxCooling, floorPanelCustomPos, partLibraryCoolingId, uid, pid, driverValues) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.objectMac = objectMac !== undefined ? objectMac : "";
    this.url = url !== undefined ? url : "";
    this.airFlowDirection = airFlowDirection !== undefined ? airFlowDirection : 0;
    this.type = type !== undefined ? type : 1;
    this.alertConfigType = alertConfigType !== undefined ? alertConfigType : ""; // TODO WTF?
    this.maxPower = maxPower !== undefined ? maxPower : 0;
    this.maxCooling = maxCooling !== undefined ? maxCooling : 0;
    this.floorPanelCustomPos = floorPanelCustomPos !== undefined ? floorPanelCustomPos : "";
    this.partLibraryCoolingId = partLibraryCoolingId !== undefined ? partLibraryCoolingId : null;
    this.wib8000Configs = [];
    this.stdFloorPos = null;
    this.floorPosition = "";
    // Next all controls for a deep copy of a CU (a copy with all parameters inside of it)
    if (driverValues !== null && driverValues !== undefined && driverValues.length > 0) {
        for (var i = 0; i < driverValues.length; i++) {
            driverValues[i].id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (driverValues[i].limits.length > 0) {
                var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var l = 0; l < driverValues[i].limits.length; l++) {
                    driverValues[i].limits[l].id = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].driverValueId = driverValueId;
                }
            }
        }
        this.driverValues = driverValues;
    } else {
        this.driverValues = [];
    }

    this.roomId = pid !== undefined ? pid : null;
    this.isCooling = true;
    this.allowDataPoints = true;
}

Cooling.prototype = Object.create(NamedEntity.prototype);
Cooling.prototype.constructor = Cooling;

/**
 * @description Function to test equality between this object and the provided object
 * @param {object} cc object to compare this with
 * @returns {boolean} returns true if this object and the provided object are equal, otherwise false
 */
Cooling.prototype.equals = function (cc) {
    if (!(cc instanceof Cooling)) return false;
    if (!this.equalsNamedEntity(cc)) return false;
    if (this.roomId !== cc.roomId) return false;
    if (this.inventoryNumber !== cc.inventoryNumber) return false;
    if (this.serialNumber !== cc.serialNumber) return false;
    if (this.objectMac !== cc.objectMac) return false;
    if (this.url !== cc.url) return false;
    if (this.partLibraryCoolingId !== cc.partLibraryCoolingId) return false;
    if (this.airFlowDirection !== cc.airFlowDirection) return false;
    if (this.type !== cc.type) return false;
    if (this.maxPower !== cc.maxPower) return false;
    if (this.maxCooling !== cc.maxCooling) return false;
    if (this.floorPanelCustomPos !== cc.floorPanelCustomPos) return false;
    if (!this.compareDriverValues(cc.driverValues)) return false;
    if (this.wib8000Configs.length !== cc.wib8000Configs.length) return false;
    if (this.wib8000Configs.length === cc.wib8000Configs.length && this.wib8000Configs.length > 0) return this.wib8000Configs[0].equals(cc.wib8000Configs[0]);
    return true;
};

/**
 * @description Function to test if this object is stulz cooling unit
 * @returns {boolean} returns true if unit is stulz unit, otherwise false
 */
Cooling.prototype.isStulz = function () {
    return this.type === 2 || this.type === 3;
};

/**
 * @description Function to get used/unused parameters for this object
 * @param {array} availableParameters array of possible parameters for cooling units
 * @returns array containing sub array for used parameters and unused parameters (used for frontend)
 */
Cooling.prototype.setupParameters = function (availableParameters) {

    var usedParams = [];
    var unusedParams = [];
    var param = [];

    for (var ap = 0; ap < availableParameters.length; ap++) {
        if (this.driverValues !== null && this.driverValues !== "") {
            param = this.driverValues.filter(function (elem) {
                return elem.parameter.id === availableParameters[ap].id;
            });
        }
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        } else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to test collision
 * @param {THREE.Object3D} obj3d_self the 3D object representing this object
 * @param {THREE.Object3D} obj3d_room the 3D object representing this objects parent room
 * @param {THREE.Vector3} pos the position to run the test for
 * @param {boolean} onlyWalls if true only walls will be used to test, otherwise every possible object will be tested
 * @param {Room} roomObj the room object (this objects parent object)
 * @returns {boolean} returns true if collision is detected, otherwise false
 */
Cooling.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d_self.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    testObb.rotateY(this.rot.y * (Math.PI / 180));
    //check point in room
    if (!roomObj.checkOBBInRoom(testObb, false)) return true;
    //check collide walls
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === "asset" || o.name === "ups" || o.name === "floortile")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d_self.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
};

/**
 * @description Function to get a list of driver types applicable for cooling units
 * @param {object} allDrivers object containing all supported driver types
 * @param physType
 * @param param
 * @returns {Object} returns object containing information about possible drivers for objects of this type
 */
Cooling.prototype.getAvailableDrivers = function (allDrivers, physType, param) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "D0" || d === "DBUS" || d === "MBUS" || d === "S0" || d === "WEBBUS") delete availableDrivers[d];
        if (this.isStulz() && param && param.oidId == null && d === "WIB8000") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to get the part library id of this object
 * @returns {*} returns the part library id used to create this object
 */
Cooling.prototype.getPartLibraryId = function () {
    return this.partLibraryCoolingId;
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
Cooling.prototype.validate = function () {
    var errorList = this.validateNamedEntity();

    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));
    if (this.partLibraryCoolingId === undefined || this.partLibraryCoolingId === null || this.partLibraryCoolingId < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "partLibraryCoolingId"));
    if (this.type === undefined || this.type === null || this.type < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "type"));
    if (this.maxPower === undefined || this.maxPower === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "maxPower"));
    if (this.maxCooling === undefined || this.maxCooling === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "maxCooling"));
    if (this.type > 1 && this.wib8000Configs.length) errorList = errorList.concat(this.wib8000Configs[0].validate());

    for (var i = 0; i < this.driverValues.length; i++) errorList = errorList.concat(this.driverValues[i].validate());
    return errorList;
};

Cooling.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    for (var i = 0; i < this.driverValues.length; i++) {
        var objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }

    return [];
};

/**
 * @description Function to create a new cooling object from json data object provided by backend
 * @param {object} obj the json data object
 * @param {number} pid the parent id (room id)
 * @returns {Cooling} returns the created cooling unit object
 */
Cooling.parseFromHtmlObject = function (obj, pid) {
    var cooling = new Cooling(obj.id,
        {x: obj.posX, y: obj.posY, z: obj.posZ}, {x: obj.scaleX, y: obj.scaleY, z: obj.scaleZ}, {
            x: obj.rotX,
            y: obj.rotY,
            z: obj.rotZ
        },
        obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.airFlowDirection,
        obj.type, obj.alertConfigType, obj.maxPower, obj.maxCooling, obj.floorPanelCustomPos, obj.partLibraryCoolingId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, cooling);
    cooling.modifyDriverValueLimitsForFrontend();
    return cooling;
};

/**
 * @description Function to create a new cooling unit object from javascript object
 * @param {object} obj the object to use
 * @param {number} pid the parent id to set (room id)
 * @returns {Cooling} returns the created cooling object
 */
Cooling.parseFromSimpleObject = function (obj, pid) {
    var cooling = new Cooling(obj.id,
        {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {x: obj.size.x, y: obj.size.y, z: obj.size.z}, {
            x: obj.rot.x,
            y: obj.rot.y,
            z: obj.rot.z
        },
        obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.airFlowDirection,
        obj.type, obj.alertConfigType, obj.maxPower, obj.maxCooling, obj.floorPanelCustomPos, obj.partLibraryCoolingId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, cooling);
    return cooling;
};
