(function(){
    angular.module("emsv2App").component("alarmIcon", {
        template:'<span ng-class="{\'text-info glyphicon glyphicon-info-sign\':$ctrl.priority >= 0 && $ctrl.priority < 75,\'text-warning fa fa-warning\': $ctrl.priority >= 75 && $ctrl.priority < 150,\'text-danger glyphicon glyphicon-remove-sign\':$ctrl.priority >= 150}"></span>',
        controller: function(){
            var ctrl = this;
            // console.log(ctrl.resolve.priority);
        },
        bindings:{
            priority:'<'
        }
    })
})();
