(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name roomExportModal
     */
    angular
        .module('emsv2App')
        .component('roomExportModal', {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&',
                currentRoomId: '<'
            },
            templateUrl: 'scripts/app/location/tabs/room/room.export.modal.html',
            controller: ['$log', '$http', 'RoomService',
                function ($log, $http, RoomService) {
                    var ctrl = this;
                    ctrl.roomWithoutLiveData = [];
                    ctrl.selection = {
                        option: 2 // onlyroom, entities, configurations
                    };

                    ctrl.cancel = function () {
                        ctrl.exportInProgress = false;
                        ctrl.dismiss();
                    };

                    ctrl.$onInit = function () {
                        ctrl.buildingId = ctrl.resolve.buildingId;
                        ctrl.availableRooms = ctrl.resolve.availableRooms;
                        ctrl.selectedRoomId = ctrl.availableRooms !== undefined && ctrl.availableRooms.length > 0 && !ctrl.currentRoomId ? ctrl.availableRooms[0].id : ctrl.currentRoomId;
                        getRooms(ctrl.buildingId);
                    };
                    ctrl.$onDestroy = function () {
                        ctrl.dismiss();
                    };

                    ctrl.closeModal = function () {
                        ctrl.dismiss();
                    };

                    var getRooms = function (buildingId) {
                        RoomService.getSimpleRoomsByBuilding(buildingId).then(function (response) {
                            ctrl.roomWithoutLiveData = response.data;
                        })
                    };

                    ctrl.exportRoom = function () {
                        for (var i in ctrl.roomWithoutLiveData) {
                            if (ctrl.roomWithoutLiveData[i].id === ctrl.selectedRoomId) {
                                var roomexported = angular.copy(ctrl.roomWithoutLiveData[i]);
                                if (parseInt(ctrl.selection.option) === 0) {
                                    roomexported.racks = [];
                                    roomexported.coolings = [];
                                    roomexported.assets = [];
                                    roomexported.sensors = [];
                                    roomexported.floorTiles = [];
                                    roomexported.ventducts = [];
                                    roomexported.ups = [];
                                    roomexported.driverValueGroups = [];
                                } else if (parseInt(ctrl.selection.option) === 1) {
                                    if (roomexported.racks.length > 0) {
                                        for (var r = 0; r < roomexported.racks.length; r++) {
                                            if (roomexported.racks[r].slots.length > 0) {
                                                for (var s = 0; s < roomexported.racks[r].slots.length; s++) {
                                                    roomexported.racks[r].slots[s].driverValues = [];
                                                    if (roomexported.racks[r].slots[s].blades.length > 0) {
                                                        for (var b = 0; b < roomexported.racks[r].slots[s].blades.length; b++) {
                                                            roomexported.racks[r].slots[s].blades[b].driverValues = [];
                                                            if (roomexported.racks[r].slots[s].blades[b].cpus.length > 0) {
                                                                for (var c = 0; c < roomexported.racks[r].slots[s].blades[b].cpus.length; c++) {
                                                                    roomexported.racks[r].slots[s].blades[b].cpus[c].driverValues = [];
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (roomexported.coolings.length > 0) {
                                        for (var c = 0; c < roomexported.coolings.length; c++) {
                                            roomexported.coolings[c].driverValues = [];
                                        }
                                    }
                                    if (roomexported.assets.length > 0) {
                                        for (var a = 0; a < roomexported.assets.length; a++) {
                                            roomexported.assets[a].driverValues = [];
                                        }
                                    }
                                    if (roomexported.sensors.length > 0) {
                                        for (var s = 0; s < roomexported.sensors.length; s++) {
                                            roomexported.sensors[s].driverValues = [];
                                        }
                                    }
                                    if (roomexported.floorTiles.length > 0) {
                                        for (var f = 0; f < roomexported.floorTiles.length; f++) {
                                            roomexported.floorTiles[f].driverValues = [];
                                        }
                                    }
                                    if (roomexported.ups.length > 0) {
                                        for (var u = 0; u < roomexported.ups.length; u++) {
                                            roomexported.ups[u].driverValues = [];
                                        }
                                    }
                                    roomexported.driverValueGroups = [];
                                } else {
                                    // var test = recursiveSearch(roomexported,'deviceMac');
                                    if (roomexported.racks.length > 0) {
                                        for (var r = 0; r < roomexported.racks.length; r++) {
                                            if (roomexported.racks[r].slots.length > 0) {
                                                for (var s = 0; s < roomexported.racks[r].slots.length; s++) {
                                                    for (var sdv = 0; sdv < roomexported.racks[r].slots[s].driverValues.length; sdv++) {
                                                        roomexported.racks[r].slots[s].driverValues[sdv].deviceMac = null;
                                                    }

                                                    if (roomexported.racks[r].slots[s].blades.length > 0) {
                                                        for (var b = 0; b < roomexported.racks[r].slots[s].blades.length; b++) {
                                                            roomexported.racks[r].slots[s].blades[b].driverValues = [];
                                                            if (roomexported.racks[r].slots[s].blades[b].cpus.length > 0) {
                                                                for (var c = 0; c < roomexported.racks[r].slots[s].blades[b].cpus.length; c++) {
                                                                    if (roomexported.racks[r].slots[s].blades[b].cpus[c].driverValues.length > 0) {
                                                                        for (var dv = 0; dv < roomexported.racks[r].slots[s].blades[b].cpus[c].driverValues.length; dv++) {
                                                                            roomexported.racks[r].slots[s].blades[b].cpus[c].driverValues[dv].deviceMac = null;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (roomexported.coolings.length > 0) {
                                        for (var c = 0; c < roomexported.coolings.length; c++) {
                                            if (roomexported.coolings[c].driverValues.length > 0) {
                                                for (var dv = 0; dv < roomexported.coolings[c].driverValues.length; dv++) {
                                                    roomexported.coolings[c].driverValues[dv].deviceMac = null;
                                                }

                                            }
                                        }
                                    }
                                    if (roomexported.assets.length > 0) {
                                        for (var a = 0; a < roomexported.assets.length; a++) {
                                            if (roomexported.assets[a].driverValues.length > 0) {
                                                for (var dv = 0; dv < roomexported.assets[a].driverValues.length; dv++) {
                                                    roomexported.assets[a].driverValues[dv].deviceMac = null;
                                                }
                                            }
                                        }
                                    }
                                    if (roomexported.sensors.length > 0) {
                                        for (var s = 0; s < roomexported.sensors.length; s++) {
                                            if (roomexported.sensors[s].driverValues.length > 0) {
                                                for (var dv = 0; dv < roomexported.sensors[s].driverValues.length; dv++) {
                                                    roomexported.sensors[s].driverValues[dv].deviceMac = null;
                                                }
                                            }
                                        }
                                    }
                                    if (roomexported.floorTiles.length > 0) {
                                        for (var f = 0; f < roomexported.floorTiles.length; f++) {
                                            if (roomexported.floorTiles[f].driverValues.length > 0) {
                                                for (var dv = 0; dv < roomexported.floorTiles[f].driverValues.length; dv++) {
                                                    roomexported.floorTiles[f].driverValues[dv].deviceMac = null;
                                                }
                                            }
                                        }
                                    }
                                    if (roomexported.ups.length > 0) {
                                        for (var u = 0; u < roomexported.ups.length; u++) {
                                            if (roomexported.ups[u].driverValues.length > 0) {
                                                for (var dv = 0; dv < roomexported.ups[u].driverValues.length; dv++) {
                                                    roomexported.ups[u].driverValues[dv].deviceMac = null;
                                                }
                                            }
                                        }
                                    }
                                    if (roomexported.driverValueGroups.length > 0) {
                                        for (var dvg = 0; dvg < roomexported.driverValueGroups.length; dvg++) {
                                            roomexported.driverValueGroups[dvg].deviceMac = null;
                                        }
                                    }
                                }


                                var json = JSON.stringify(roomexported);
                                var jsonfile = new Blob([json], {type: "text/plain;charset=utf-8"});
                                var today = String(new Date().getDate()).padStart(2, '0') + '_' + String(new Date().getMonth() + 1).padStart(2, '0') + '_' + new Date().getFullYear();
                                var filename = roomexported.name + '_' + today + '.json';
                                var a = document.createElement("a");
                                a.href = URL.createObjectURL(jsonfile);
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                            }
                        }
                        ctrl.closeModal();
                    }
                }]
        });

})();


