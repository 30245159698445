(function () {

    'use strict';

    function analysisService($http) {

        return {
            findAllExcludeDatapoints: function () {
                return $http.get('api/analysis/all/excludedatapoints');
            },
            anyRoomDpAttachedToAnalysis: function (roomId, locId) {
                return $http.get('api/analysis/anyRoomDpAttachedToAnalysis/' + roomId + '/' + locId);
            },
            getAllByLocationExcludeDatapoints: function (locationid) {
                return $http.get('api/analysis/allbylocation/' + locationid);
            },
            save: function (analysis) {
                return $http.post('api/analysis', analysis);
            },
            load: function (id) {
                return $http.get('api/analysis/' + id);
            },
            remove: function (id) {
                return $http.delete('api/analysis/' + id);
            },
            deleteSelection: function (idlist) {
                return $http.post('api/analysis/delete', idlist);
            },
            rsql: function (page, query) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http({url: 'api/analysis/query/' + page + '/20/' + query});
            },
            getDatapointLimit: function () {
                return $http.get('api/uiRestrictions/getAnalysisDatapointLimit');
            },
            getAnWidgetLimit: function () {
                return $http.get('api/uiRestrictions/getAnalysisWidgetLimit');
            }
        };
    }

    angular.module('emsv2App').service('Analysis', analysisService);

})();
