(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('LoginController', function ($rootScope, $scope, $state, $timeout, $window, $sanitize, $log, $stateParams,
                                                 License, Language, Auth, Tools, EMSConstants, Principal, $translate) {
            var ctrl = this;
            ctrl.currentYear = Tools.isDefinedNotNull(new Date().getFullYear()) && new Date().getFullYear() > 2014 ?
                new Date().getFullYear() : $translate.instant("global.currentDate");
            ctrl.login = {
                username: '',
                password: '',
                error: false,
                rememberMe: true,
                do: _doLogin
            };
            ctrl.statusHostedPublicly = false;
            ctrl.pricelist = "";
            ctrl.specification = "";
            ctrl.technicalAndOrganizationalMeasures = "";

            ctrl.$onInit = function () {
                License.get().then(function (response) {
                    ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
                    Language.getCurrent().then(function (language) {
                        ctrl.language = language;
                        if (ctrl.language === "de") {
                            ctrl.specification = $window.location.origin + "/legal/Leistungsverzeichnis.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/Preisliste.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnischeUndOrganisatorischeMassnahmen.pdf";
                        } else {
                            ctrl.specification = $window.location.origin + "/legal/Specification.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/PriceList.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnicalAndOrganizationalMeasures.pdf";
                        }
                    });
                })
            }

            function _doLogin() {
                var credentials = {
                    username: encodeURIComponent(ctrl.login.username),
                    password: encodeURIComponent(ctrl.login.password),
                    rememberMe: ctrl.login.rememberMe
                };
                Auth.login(credentials).then(function () {
                    ctrl.login.error = false;
                    window.dcSidebarInitialAlarmNotification = true;
                    EMSConstants.init();
                    if (Principal.showChangePasswordSite()) {
                        Principal.showChangePasswordState();
                    } else if (Tools.isDefinedNotNull($stateParams.referrer)) {
                        $window.location.href = 'http://' + $stateParams.referrer;
                    } else {
                        if ($rootScope.previousStateName === 'account.delete' || Tools.isStateWithoutAuthReq($rootScope.previousStateName)) {
                            $state.go('home');
                        } else {
                            if (Tools.isDefinedNotNull($rootScope.previousStateName) && $rootScope.previousStateName !== "") {
                                if ($rootScope.previousStateName === "logout" && Tools.isDefinedNotNull($rootScope.pre_previousStateName)) {
                                    $state.go('home');
                                } else {
                                    $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                                }
                            } else {
                                $state.go('home');
                            }
                        }
                    }
                }).catch(function () {
                    ctrl.login.password = '';
                    ctrl.login.error = true;
                });
            }

        });

})();
