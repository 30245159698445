'use strict';

/**
 * @description Constructor to create new SmartEventSnmp object
 * @constructor
 */
function SmartEventSnmp(smartEventSnmp) {
    if (smartEventSnmp !== undefined) {
        this.id = smartEventSnmp.id !== undefined ? smartEventSnmp.id : null;
        this.errorConfigurationId = smartEventSnmp.errorConfigurationId !== undefined ? smartEventSnmp.errorConfigurationId : null;
        this.protocol = smartEventSnmp.protocol !== undefined && smartEventSnmp.protocol !== null ? smartEventSnmp.protocol : null;
        this.activeEventValue = smartEventSnmp.activeEventValue !== undefined ? smartEventSnmp.activeEventValue : null;
        this.inactiveEventValue = smartEventSnmp.inactiveEventValue !== undefined ? smartEventSnmp.inactiveEventValue : null;
    } else {
        this.id = null;
        this.errorConfigurationId = null;
        this.protocol = null;
        this.activeEventValue = null;
        this.inactiveEventValue = null;
    }
}

SmartEventSnmp.prototype = {};
SmartEventSnmp.prototype.constructor = SmartEventSnmp;
