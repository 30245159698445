(function () {

    'use strict';

    function stateConfiguration($stateProvider) {

        var translatePartialLoader = ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('contacts');
            $translatePartialLoader.addPart('rsqltable');
            $translatePartialLoader.addPart('user');
            return $translate.refresh();
        }];

        var currentContact = ['$log', '$stateParams', '$state', 'Contacts', function ($log, $stateParams, $state, Contacts) {
            return Contacts.getOne($stateParams.id).then(function (response) {
                $log.debug("+ Contact: ", response.data);
                var fullName = response.data.firstname.concat(' ').concat(response.data.lastname).trim();
                response.data.breadcrumb = response.data.id + (fullName.length ? ' - '.concat(fullName) : '');
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t load contact: ' + $stateParams.id, error);
                $state.go('notfound');
            });
        }];

        var newContact = ['$q', function ($q) {
            return $q.when({
                company: null,
                country: null,
                email: null,
                fax: "",
                firstname: "",
                gender: 0,
                id: null,
                lastname: "",
                location: null,
                mobile: null,
                phone: null,
                street: null,
                zip: null,
                single: true,
                secEmail: null
            });
        }];

        var countries = ['$log', 'Country', function ($log, Country) {
            return Country.getAll().then(function (response) {
                $log.debug("+ Countries: ", response);
                return response;
            }, function (error) {
                $log.error("Couldn't load countries!", error);
                return [];
            });
        }];

        var prefix = 'CyberHub |';

        $stateProvider
            .state('contacts', {
                parent: 'site',
                url: '/contacts',
                data: {
                    roles: ['ROLE_CONTACT', 'ROLE_ADMIN'],
                    pageTitle: 'contacts.pageTitle',
                    breadcrumbsTitle: 'contacts.title'
                },
                views: {
                    'content@': {
                        template: '<contacts-overview></contacts-overview>'
                    }
                },
                resolve: {
                    translatePartialLoader: translatePartialLoader,
                    countries: countries,
                    currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                        return Principal.identity().then(function (user) {
                            $log.debug('Loaded userInfo: ', user);
                            if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_CONTACT')) {
                                return user;
                            }
                            else {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $state.go("notfound");
                            $log.error('Couldn\'t load user: ', error);
                        });
                    }]
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('contacts-create', {
                parent: 'contacts',
                url: '/create',
                data: {
                    roles: ['ROLE_CONTACT', 'ROLE_ADMIN'],
                    pageTitle: 'contacts.pageTitle',
                    breadcrumbsTitle: 'contacts.create.title'
                },
                views: {
                    'content@': {
                        template: '<contacts-edit current-contact="$resolve.currentContact" countries="$resolve.countries"></contacts-edit>'
                    }
                },
                resolve: {
                    currentContact: newContact
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('contacts-loaded', {
                parent: 'contacts',
                url: '/:id',
                abstract: false,
                redirectTo: 'contacts-edit',
                data: {
                    roles: ['ROLE_CONTACT', 'ROLE_ADMIN'],
                    pageTitle: 'contacts.pageTitle',
                    breadcrumbsTitle: '{{currentContact.breadcrumb}}'
                },
                resolve: {
                    currentContact: currentContact
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('contacts-view', {
                parent: 'contacts-loaded',
                url: '/view',
                data: {
                    roles: ['ROLE_CONTACT', 'ROLE_ADMIN'],
                    ppageTitle: 'contacts.pageTitle',
                    breadcrumbsTitle: 'contacts.view.title'
                },
                views: {
                    'content@': {
                        template: '<contacts-view current-contact="$resolve.currentContact" countries="$resolve.countries"></contacts-view>'
                    }
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('contacts-edit', {
                parent: 'contacts-loaded',
                url: '/edit',
                data: {
                    roles: ['ROLE_CONTACT', 'ROLE_ADMIN'],
                    pageTitle: 'contacts.pageTitle',
                    breadcrumbsTitle: 'contacts.edit.title'
                },
                views: {
                    'content@': {
                        template: '<contacts-edit current-contact="$resolve.currentContact" countries="$resolve.countries"></contacts-edit>'
                    }
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('contacts-delete', {
                parent: 'contacts-loaded',
                url: '/delete',
                data: {
                    roles: ['ROLE_CONTACT', 'ROLE_ADMIN'],
                    pageTitle: 'contacts.pageTitle',
                    breadcrumbsTitle: 'contacts.delete.title'
                },
                views: {
                    'content@': {
                        template: '<contacts-delete current-contact="$resolve.currentContact" countries="$resolve.countries"></contacts-delete>'
                    }
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            });
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
