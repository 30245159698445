(function () {
    "use strict";
    var myApp = angular.module('emsv2App');
    myApp.service('Parameters', function ($http) {

        function put(parameter) {
            return $http.put("/api/parameters/save", parameter);
        }

        function get(paramId) {
            return $http.get("/api/parameters/get/" + paramId);
        }

        function deleteParam(paramId) {
            return $http.delete("/api/parameters/delete/" + paramId);
        }

        function getUserInfo(groupId) {
            return $http.get("/api/groups/users/info/" + groupId);
        }

        function getAllSelectableForEntityType(entityType) {
            return $http.get("/api/parameters/getAllSelectableForEntity/" + entityType);
        }

        function getAllForEntityType(entityType) {
            return $http.get("/api/parameters/getAllForEntity/" + entityType);
        }

        function getAllExceptEntityTypes(entityTypes) {
            return $http.put("/api/parameters/getAllExceptForEntities/", entityTypes);
        }

        function getAll() {
            return $http.get("/api/parameters/getAll");
        }

        function checkIfUsed(paramId) {
            return $http.get("/api/parameters/checkIfUsed/" + paramId);
        }

        return {
            put: put,
            get: get,
            deleteParam: deleteParam,
            getUserInfo: getUserInfo,
            getAllForEntityType: getAllForEntityType,
            getAllSelectableForEntityType: getAllSelectableForEntityType,
            getAllExceptEntityTypes: getAllExceptEntityTypes,
            getAll: getAll,
            checkIfUsed: checkIfUsed
        }
    });
})();
