(function () {
    'use strict';
    var app = angular.module('emsv2App');
    app.filter('convertToTimeString', function () {
            return function (value) {
                var ms = value;
                var x = parseInt(ms / 1000);
                var seconds = x % 60;
                x = parseInt(x / 60);
                var minutes = x % 60;
                x = parseInt(x / 60);
                var hours = x % 24;
                x = parseInt(x / 24);
                var days = x;

                if (days < 10) {
                    days = "0" + days;
                }
                if (hours < 10) {
                    hours = "0" + hours;
                }
                if (minutes < 10) {
                    minutes = "0" + minutes;
                }
                if (seconds < 10) {
                    seconds = "0" + seconds;
                }
                // return days + ":" + hours + ":" + minutes + ":" + seconds;
                return days + ":" + hours + ":" + minutes;
            };
        }
    );
})();
