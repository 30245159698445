(function () {

    'use strict';

    function reportDetailController($state, $timeout, $interval, Report, reportData) {
        var ctrl = this;
        ctrl.report = reportData;
        // ctrl.options = {
        //     isDelete: $state.current.name === 'reports.delete',
        //     deleteBtn: 'init',
        //     cancelBtnEnabled: true
        // };
        ctrl.dashboard = {
            options: {
                full: {
                    cellHeight: 100,
                    cellWidth: 200,
                    width: 10,
                    height: 8,
                    verticalMargin: 15,
                    float: true,
                    animate: false,
                    disableResize: true,
                    disableDrag: true,
                    staticGrid: true
                },
                half: {
                    cellHeight: 100,
                    cellWidth: 200,
                    width: 6,
                    height: 12,
                    verticalMargin: 15,
                    float: true,
                    animate: false,
                    disableResize: true,
                    disableDrag: true,
                    staticGrid: true
                }
            }
        };

        ctrl.$onInit = function () {
            var iv = null;
            iv = $interval(function () {
                var gs = $('.grid-stack');
                if (gs.length !== 0) {
                    gs.data('gridstack').enableMove(false, true);
                    gs.data('gridstack').enableResize(false, true);
                    $interval.cancel(iv);
                }
            });
        };

        // ctrl.delete = function () {
        //     ctrl.options.deleteBtn = 'load';
        //     ctrl.options.cancelBtnEnabled = false;
        //     Report.delete(ctrl.report.id).then(function () {
        //         ctrl.options.deleteBtn = 'success';
        //         $timeout(function () {
        //             $state.go('reports', {}, {reload: true});
        //         }, 2000);
        //     }, function (error) {
        //         ctrl.options.deleteBtn = 'error';
        //         ctrl.options.cancelBtnEnabled = true;
        //         console.error('Couldn\'t delete report: ', error);
        //     });
        // };
    }

    angular
        .module('emsv2App')
        .controller('ReportDetailController', reportDetailController);

})();
