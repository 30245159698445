(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('error', {
                    parent: 'site',
                    url: '/error',
                    data: {
                        roles: [],
                        pageTitle: 'error.pageTitle',
                        breadcrumbsTitle: 'errors.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/error/error.html'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }]
                    }
                })
                .state('accessdenied', {
                    parent: 'site',
                    url: '/accessdenied',
                    data: {
                        roles: [],
                        pageTitle: 'error.pageTitle',
                        breadcrumbsTitle: '{{\'errors.title\'|translate}} - 403'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/error/403.html'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }]
                    }
                })
                .state('notfound', {
                    parent: 'site',
                    url: '/notfound',
                    data: {
                        roles: [],
                        pageTitle: 'error.pageTitle',
                        breadcrumbsTitle: '{{\'errors.title\'|translate}} - 404'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/error/404.html',
                            controller: function () {},
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }]
                    }
                })
                .state('notfound.withpath', {
                    parent: 'site',
                    url: '/notfound/{path:.*}',
                    data: {
                        roles: [],
                        pageTitle: '{{\'errors.title\'|translate}} - 404'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/error/404.html',
                            controller: function () {},
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            return $translate.refresh();
                        }]
                    }
                })
        });

})();
