(function () {

    'use strict';

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('location.detail', {
                abstract: true,
                parent: 'location',
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/location/tabs/tabs.html',
                        controller: 'TabsController',
                        controllerAs: 'tctrl'
                    }
                },
                redirectTo: 'location.overview'
            });
    }
    angular
        .module('emsv2App')
        .config(stateConfiguration);
})();
