(function () {

    'use strict';

    function logsService($http) {
        return {
            listLogs: function () {
                return $http.get('api/logs/available');
            },
            getLogs: function (file) {
                return $http.get('api/logs/' + file);
            },
            getMoreLogs: function (file, size) {
                return $http.get('api/logs/' + file + '/' + size);
            }
        };
    }

    angular.module('emsv2App').service('LogsService', logsService);

})();
