/**
 * SmtpEmailServer
 * @ngdoc service
 * @name SmtpEmailServerConfigurationService
 * @requires $http, $log, $q
 */
angular.module('emsv2App').service('SmtpEmailServerConfigurationService', ['$http', '$log', '$q', function ($http) {
    return {
        get: function () {
            return $http.get('/api/smtpEmailServerConfiguration/get');
        },
        test: function (settings) {
            return $http.post('/api/smtpEmailServerConfiguration/test', settings);
        },
        save: function (settings) {
            return $http.post('/api/smtpEmailServerConfiguration/save', settings);
        },
    }
}]);