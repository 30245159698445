/**
 * Created by dk on 30.03.2016.
 */

'use strict';

function AlarmCalendarController($scope, $log, $interval, $uibModal, $q, $window, $translate, Tools, AlarmService, IntervalService, Device, Alarm, user, Notify, calendarConfig, moment, DateConstants, CustomEventService, EventTypeService) {
    var ctrl = this;
    calendarConfig.templates.calendarMonthCell = 'groupedMonthEvents.html';
    var currentModal = null;
    var warnModal = null;
    ctrl.currentDevices = [];
    ctrl.quitReason = "";
    var timeoutId = null;
    var eventType = [];
    var currentLabel = $translate.instant('alarm.calendar.currentAlarm');
    var historicalLabel = $translate.instant('alarm.calendar.historicalAlarm');
    var customEventLabel = $translate.instant('alarm.calendar.customEvent.heading');

    ctrl.today = $translate.instant('alarm.calendar.today');
    // Table loading/sorting
    ctrl.data = [];
    ctrl.restriction = '';
    ctrl.page = 0;
    ctrl.filterQuery = '';
    ctrl.itemsPerPage = 50;
    ctrl.cellClicked = false;
    ctrl.dataPopulated = false;
    ctrl.eventEdit = false;
    ctrl.timeInvalid = false;
    ctrl.doQuery = function () {
        return _query(false, false, true);
    };
    ctrl.hasMore = true;
    ctrl.tableModel = {
        orderBy: 'stamp',
        orderDir: true,
        tableName: 'alarm_view',
        translation: 'alarm.rsql',
        head: [{name: 'priority', translation: 'alarm.priority', sortable: true}, {
            name: 'location',
            translation: 'alarm.location',
            sortable: true
        }, {name: 'device_name', translation: 'alarm.device_name', sortable: true}, {
            name: 'stamp',
            translation: 'alarm.stamp'
        }, {name: 'hash_text', translation: 'alarm.hash_text', sortable: false}, {
            name: 'active',
            translation: 'alarm.active',
            sortable: true
        }, {name: 'autoQuit', translation: 'alarm.autoquit', sortable: true}, {
            name: 'mailSent',
            translation: 'alarm.mailsent',
            sortable: true
        }, {name: 'quit', translation: 'alarm.quit', sortable: true}]
    };

    ctrl.currentPrio = 'reset';
    ctrl.currentAlarm = null;
    ctrl.counts = {
        info: 0, warning: 0, error: 0
    };

    //START - Calendar Section
    ctrl.calendarView = 'month';
    ctrl.viewDate = new Date();
    ctrl.events = [];
    ctrl.eventsMonthlyCountFunction = [];
    ctrl.eventsDateSpecificFunction = [];
    ctrl.cellIsOpen = false;
    ctrl.newCustomEvent = [];
    ctrl.eventInEdit = [];

    ctrl.dateranges = {
        list: [],
        fixed: true,
        dynamicRange: {
            value: 1,
            range: 1,
            scale: 0,
            rangeValues: [{key: 0, value: 'day', enabled: true, label: "analysis.groupBy.day"}, {
                key: 1,
                value: 'week',
                enabled: true,
                label: "analysis.groupBy.week"
            }, {key: 2, value: 'month', enabled: true, label: "analysis.groupBy.month"}, {
                key: 3,
                value: 'year',
                enabled: true,
                label: "analysis.groupBy.year"
            }],
            scaleValues: [{key: 0, value: 'day', enabled: true, label: "analysis.groupBy.day"}, {
                key: 1,
                value: 'week',
                enabled: true,
                label: "analysis.groupBy.week"
            }, {key: 2, value: 'month', enabled: true, label: "analysis.groupBy.month"}, {
                key: 3,
                value: 'year',
                enabled: true,
                label: "analysis.groupBy.year"
            }]
        }, // Date range picker customization (From - To)
        options: {
            singleDatePicker: true,
            timePicker: true,
            timePicker24Hour: true,
            autoUpdateInput: true,
            autoApply: false,
            applyClass: 'btn-primary',
            eventHandlers : {
                'apply.daterangepicker' : function() {
                    if(moment(ctrl.newCustomEvent.end).isBefore(ctrl.newCustomEvent.start)) {
                        ctrl.timeInvalid = true;
                    } else {
                        ctrl.timeInvalid = false;
                    }
                },
                'hide.daterangepicker' : function() {
                    if(moment(ctrl.newCustomEvent.end).isBefore(ctrl.newCustomEvent.start)) {
                        ctrl.timeInvalid = true;
                    } else {
                        ctrl.timeInvalid = false;
                    }
                }
            },
            locale: {
                format: $translate.instant('global.calendarFormat'),
                daysOfWeek: DateConstants.getDaysOfWeek(),
                monthNames: DateConstants.getMonthNames()
            },
            showDropdowns: true
        },
        split: _splitFixedDateRange,
        canSplit: _canSplitFixedDateRange,
        toggleRangeStyle: _toggleRangeStyle,
        setDynamicRange: _setDynamicRange
    };

    function _toggleRangeStyle() {
        ctrl.dateranges.list = [];
        if (!ctrl.dateranges.fixed) {
            ctrl.dateranges.dynamicRange.range = 1;
            ctrl.dateranges.dynamicRange.value = 1;
            _setDynamicRange();
        } else {
            ctrl.dateranges.dynamicRange.range = null;
            ctrl.dateranges.dynamicRange.value = null;
            ctrl.dateranges.dynamicRange.scale = null;
            _addDateRange();
        }
    }

    function _setDynamicRange() {
        switch (ctrl.dateranges.dynamicRange.range) {
            case 1:
                _enabledRangeValues(true, true, false, false);
                ctrl.dateranges.dynamicRange.scale = 0;
                ctrl.maxLast = 56;
                break;
            case 2:
                _enabledRangeValues(false, true, true, false);
                ctrl.dateranges.dynamicRange.scale = 1;
                ctrl.maxLast = 12;
                break;
            case 3:
                _enabledRangeValues(false, false, true, true);
                ctrl.dateranges.dynamicRange.scale = 2;
                ctrl.maxLast = 730;
                break;
            case 0:
            default:
                _enabledRangeValues(true, false, false, false);
                ctrl.dateranges.dynamicRange.scale = 0;
                ctrl.maxLast = 365;
        }

        function _enabledRangeValues(day, week, month, year) {
            ctrl.dateranges.dynamicRange.scaleValues[0].enabled = day;
            ctrl.dateranges.dynamicRange.scaleValues[1].enabled = week;
            ctrl.dateranges.dynamicRange.scaleValues[2].enabled = month;
            ctrl.dateranges.dynamicRange.scaleValues[3].enabled = year;
        }
    }

    function _canSplitFixedDateRange(start, end) {
        var diff = Math.abs(moment(start).diff(end));
        return (diff - 1) > (24 * 60 * 60 * 1000) && moment(start).isBefore(end); // 1ms tolerance
    }

    function _splitFixedDateRange(start, end, value) {
        var newValues = [];

        while (start.diff(end) < 0) {
            var _start = moment(start);
            var _end = moment(start).add(1, value);
            newValues.push({
                start: _start, end: _end
            });
            start.add(1, value);
        }

        ctrl.dateranges.list = newValues;
    }

    ctrl.eventClicked = function (event) {
        if (event.alarmUniqueId === undefined || event.alarmUniqueId === "") {
            return;
        } else {
            var isSDCIMDevice = false;
            var isCyberHubNodeDevice = false;
            var isVirtualSNMP = false;
            $log.debug("data:", event);

            getDevice(event.mac).then(function (requestedDevice) {
                if (requestedDevice !== null) {
                    if (requestedDevice.hwtyp.id === 8) {
                        isSDCIMDevice = true;
                    }
                    if (requestedDevice.hwtyp.id === 12) {
                        isCyberHubNodeDevice = true;
                    }
                    if (requestedDevice.hwtyp.id === 20) {
                        isVirtualSNMP = true;
                    }
                    ctrl.closeModal();
                    currentModal = $uibModal.open({
                        animation: false,
                        component: 'alarmModal',
                        windowClass: 'animated fadeInDown',
                        size: ((isSDCIMDevice && requestedDevice.online) || isVirtualSNMP || isCyberHubNodeDevice) ? 'lg' : '',
                        resolve: {
                            currentDevice: function () {
                                return requestedDevice;
                            }, currentAlarm: function () {
                                return event;
                            }, isSDCIMDevice: function () {
                                return isSDCIMDevice;
                            }, isCyberHubNodeDevice: function () {
                                return isCyberHubNodeDevice;
                            }, isVirtualSNMP: function () {
                                return isVirtualSNMP;
                            }, isCurrentAlarm: function () {
                                if (event.label === currentLabel) return true; else return false;
                            }, stamp: function () {
                                return event.startsAt;
                            }, user: user
                        }
                    });
                    // Callback for modal close action
                    currentModal.result.then(function (quitAlarm) {
                        ctrl.markAsQuit(quitAlarm.idAlarm);
                    }, function (dismissReason) {

                    });
                }
            }, function () {
                $log.error('Could not get Device by Mac');
            });
        }
    };

    ctrl.addCustomEvent = function () {
        ctrl.eventEdit = false;
        if (currentModal) currentModal.close();
        ctrl.newCustomEvent = {
            id: -1, title: '', message: '', start: moment().format('YYYY-MM-DD HH:mm'), end: moment().format('YYYY-MM-DD HH:mm'),
        };
        currentModal = $uibModal.open({
            templateUrl: 'scripts/app/alarm/newCustomEvent.modal.html',
            animation: true,
            windowClass: 'animated fadeInDown',
            scope: $scope,
            size: 'md',
            backdrop: 'static'
        });
    }

    ctrl.editCustomEvent = function (calendarEvent) {
        ctrl.eventEdit = true;
        currentModal = $uibModal.open({
            templateUrl: 'scripts/app/alarm/newCustomEvent.modal.html',
            animation: true,
            windowClass: 'animated fadeInDown',
            scope: $scope,
            size: 'md',
            backdrop: 'static'
        });
        ctrl.newCustomEvent.id = calendarEvent.id;

        var value = calendarEvent.title.split(":");
        var data = value[1].split("/");

        ctrl.newCustomEvent.title = data[0].trim();
        ctrl.newCustomEvent.message = data[1].trim();
        ctrl.newCustomEvent.start = calendarEvent.startsAt;
        ctrl.newCustomEvent.end = calendarEvent.endsAt;
    }

    ctrl.openCustomEventDeleteModal = function (calendarEvent) {
        currentModal = $uibModal.open({
            templateUrl: 'scripts/app/alarm/delete.customEvent.modal.html',
            animation: true,
            windowClass: 'animated fadeInDown',
            scope: $scope,
            size: 'md',
            backdrop: 'static'
        });
        ctrl.eventInEdit = calendarEvent;
    }

    ctrl.deleteCustomEvent = function (event) {
        CustomEventService.deleteById(event.id).then(function (response) {
            if (response === true) {
                ctrl.dataPopulated = false;
                Notify.success("global.notification.success.title", "alarm.calendar.customEvent.notify.deleteSuccess", 3000);
                fetchRecords();
            } else {
                Notify.error("global.notification.error.title", "alarm.calendar.customEvent.notify.deleteFail", 3000);
            }
        });
        ctrl.closeModal();
    }

    ctrl.closeModal = function () {
        if (currentModal) currentModal.close();
    };

    ctrl.saveCustomEvent = function () {
        ctrl.data = {
            id: ctrl.newCustomEvent.id,
            title: ctrl.newCustomEvent.title,
            message: ctrl.newCustomEvent.message,
            start: moment(ctrl.newCustomEvent.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(ctrl.newCustomEvent.end).format('YYYY-MM-DD HH:mm:ss')
        };

        CustomEventService.save(ctrl.data).then(function (response) {
            if (response) {
                ctrl.dataPopulated = false;
                Notify.success("global.notification.success.title", "alarm.calendar.customEvent.notify.saveSuccess", 3000);
                fetchRecords();
            } else {
                Notify.error("global.notification.error.title", "alarm.calendar.customEvent.notify.saveFail", 3000);
            }
        });
        ctrl.closeModal();
    }

    ctrl.checkTimeValidity = function (start, end) {
        if(moment(end).isBefore(start)) {
            ctrl.timeInvalid = true;
        } else {
            ctrl.timeInvalid = false;
        }
    }

    ctrl.eventTimesChanged = function (event) {
        alert.show('Dropped or resized', event);
    };

    ctrl.monthChanged = function () {
        ctrl.cellIsOpen = false;
        ctrl.cellClicked = false;
        ctrl.dataPopulated = false;
        getDataByEventType();
    };

    ctrl.toggle = function ($event, field, event) {
        $event.preventDefault();
        $event.stopPropagation();
        event[field] = !event[field];
    };

    ctrl.timespanClicked = function (date, cell) {
        ctrl.cellClicked = true;

        if (ctrl.calendarView === 'month') {
            if ((ctrl.cellIsOpen && moment(date).startOf('day').isSame(moment(ctrl.viewDate).startOf('day'))) || cell.events.length === 0 || !cell.inMonth) {
                ctrl.cellIsOpen = false;
                ctrl.cellClicked = false;
            } else {
                ctrl.datewiseAlarm(date).then(val => {
                    ctrl.dataPopulated = true;
                    ctrl.cellIsOpen = true;
                    ctrl.viewDate = date;
                })
            }
        } else if (ctrl.calendarView === 'year') {
            if ((ctrl.cellIsOpen && moment(date).startOf('month').isSame(moment(ctrl.viewDate).startOf('month'))) || cell.events.length === 0) {
                ctrl.cellIsOpen = false;
                ctrl.cellClicked = false;
                ctrl.clicked = false;
            } else {
                ctrl.cellIsOpen = true;
                ctrl.cellClicked = true;
                ctrl.viewDate = date;
            }
        }
    };

    //END - Calender Section

    /**
     * Initialize function
     */
    ctrl.$onInit = function () {
     getDataByEventType();
    };

    function getDataByEventType() {
        EventTypeService.getEventType().then(function (response) {
            eventType = response.data;
            fetchRecords();
        });
    }

    function fetchRecords() {
        // _query(true, true, true, false).then(function () {
        ctrl.datewiseAlarm(ctrl.viewDate).then(val => {
            if (val.length === 0) {
                 ctrl.cellIsOpen = false;
             }
            ctrl.dataPopulated = true;
        });
        // });
    }

    $scope.$on('$destroy', function () {
        $interval.cancel(timeoutId);
        calendarConfig.templates.calendarMonthCell = 'mwl/calendarMonthCell.html';
    });

    $scope.$on("$stateChangeStart", function (event, next, current) {
        AlarmService.killRenderer();
    });

    /**
     * Sorting function
     * @param sortField represent the "orderBy"
     * @param sortReverse represent the "orderDir"
     */
    ctrl.doSort = function (sortField, sortReverse) {
        ctrl.tableModel.orderBy = sortField;
        ctrl.tableModel.orderDir = sortReverse;
        _query(true, false, true);
    };

    ctrl.doFilter = function (params, textSearch) {
        ctrl.filterQuery = params.split("?orderby")[0] || "";
        var input = angular.element('[ng-model="rsqlSearchText"')[0].value;
        _query(true, true, true, Tools.isDefinedNotNull(input) && input !== "" && textSearch);
    };

    function _query(reset, isUpdate, flush, fullTextSearch) {
        isUpdate = isUpdate === undefined ? false : isUpdate;
        flush = flush === undefined ? false : flush;
        var pageMultiplication = _preparePageMultiplication(ctrl.page, reset, isUpdate);
        var query = _buildQuery();
        var params = getSorting();
        fullTextSearch = Tools.isDefinedNotNull(fullTextSearch) && typeof fullTextSearch === 'boolean' ? fullTextSearch : false;
        if (params === "orderby=stamp&direction=asc") fullTextSearch = false;
        var month = ctrl.viewDate.getMonth() + 1;
        var year = ctrl.viewDate.getFullYear();
        ctrl.alarmPromise = ctrl.alarmMonthCount(month, year, 0, query + '?' + params, pageMultiplication * ctrl.itemsPerPage, fullTextSearch).then(function (value) {
            return value;
        })
        ctrl.errorPromise = ctrl.historicalMonthCount(month, year, 0, query + '?' + params, pageMultiplication * ctrl.itemsPerPage, fullTextSearch).then(function (value) {
            return value;
        })
        ctrl.customEventPromise = ctrl.customEventMonthlyCount(month, year, 0, query + '?' + params, pageMultiplication * ctrl.itemsPerPage, fullTextSearch).then(function (value) {
            return value;
        })
        ctrl.promiseData = $q.all([ctrl.alarmPromise, ctrl.errorPromise, ctrl.customEventPromise]).then(function (result) {
            ctrl.events = [];
            for (let i = 0; i < result.length; i++) {
                ctrl.events.push(result[i]);
                ctrl.events = [].concat.apply([], ctrl.events);
            }
            return ctrl.events;
        }).catch(function (error) {
            $log.log(error)
        })
        return ctrl.promiseData;
    }

    ctrl.historicalMonthCount = function (month, year, p, a, r, am) {
        let defer = $q.defer();
        Alarm.monthlyErrorCount(month, year, p, a, r, am).then(function (res) {
            let data = res.data;
            ctrl.historicalData = [];
            let color, label, alarmId, type, historicalAlarmId;
            if (data.length > 0) {
                var i = 0, len = data.length
                while (i < len) {
                    var stamp = parseDate(data[i]);
                    color = ctrl.getEventColor(-75);
                    label = historicalLabel;
                    type = "historical";
                    historicalAlarmId = data[i].idErrorLog;

                    ctrl.historicalData.push({
                        startsAt: stamp ? stamp : "",
                        color: color ? color : "",
                        type: type ? type : "",
                        idErrorLog: historicalAlarmId ? historicalAlarmId : null,
                        alarmUniqueId: data[i].uniqueId ? data[i].uniqueId : "",
                        idAlarm: alarmId ? alarmId : "",
                        label: label ? label : ""
                    })
                    i++
                }
                defer.resolve(ctrl.historicalData);
            } else {
                defer.resolve([])
            }

        })
        return defer.promise;
    }
    ctrl.alarmMonthCount = function (month, year, p, a, r, am) {
        let defer = $q.defer();
        Alarm.monthlyAlarmCount(month, year, p, a, r, am).then(function (res) {
            let data = res.data;
            ctrl.alarmCount = [];
            var stamp
            let color, label, alarmId, type, historicalAlarmId;
            if (data.length > 0) {
                for (i = 0; i < data.length; i++) {
                    if (Tools.isDefinedNotNull(data[i])) {
                        stamp = parseDate(data[i]);
                        color = ctrl.getEventColor(data[i].priority);
                        label = currentLabel;
                        alarmId = data[i].idAlarm;
                        if (data[i].priority < 75) {
                            type = 'info'
                        }
                        if (data[i].priority >= 75 && data[i].priority <= 149) {
                            type = 'warning'

                        }
                        if (data[i].priority > 149) {
                            type = 'danger'

                        }
                        ctrl.alarmCount.push({
                            startsAt: stamp ? stamp : '',
                            color: color ? color : "",
                            type: type ? type : "",
                            idErrorLog: historicalAlarmId ? historicalAlarmId : null,
                            alarmUniqueId: data[i].uniqueId ? data[i].uniqueId : "",
                            idAlarm: alarmId ? alarmId : "",
                            label: label ? label : ""
                        });
                    }
                }
                defer.resolve(ctrl.alarmCount);

            } else {
                defer.resolve([])
            }
        })
        return defer.promise;

    }

    ctrl.customEventMonthlyCount = function (month, year, p, a, r, am) {
        let defer = $q.defer();
        CustomEventService.customEventMonthlyCount(month, year, p, a, r, am).then(function (res) {
            let data = res.data;
            ctrl.customEvent = [];
            let customEventId;
            if (data.length > 0) {
                var i = 0, len = data.length
                while (i < len) {
                    customEventId = data[i].id;
                    ctrl.customEvent.push({
                        customEventId: data[i].id,
                        startsAt: new Date(data[i].start),
                        endsAt: new Date(data[i].end),
                        // color: ctrl.getEventColor(2),
                        color: {primary: '#fff', secondary: '#d1dade'},
                        title: customEventLabel
                    })
                    i++
                }
                defer.resolve(ctrl.customEvent);
            } else {
                defer.resolve([])
            }
        })
        return defer.promise;
    }

    ctrl.groupEvents = function (cell) {
        cell.groups = {}
        cell.events.forEach(function (event) {
            cell.groups[event.type] = cell.groups[event.type] || [];
            cell.groups[event.type].push(event)

        })
    }

    function _preparePageMultiplication(page, reset, isUpdate) {
        if (!isUpdate) {
            ctrl.page = reset ? 0 : ctrl.page + 1;
        }
        return ctrl.page + 1;
    }

    function _buildQuery() {
        var tmp = [];
        if (ctrl.restriction) {
            tmp.push(ctrl.restriction);
        }
        if (ctrl.filterQuery) {
            tmp.push(ctrl.filterQuery);
        }
        var query = tmp.join(" and ");
        query = query.length ? '/' + query : query;
        return _prepareQuery(query);
    }

    function _prepareQuery(query) {
        return query.replace(/mac_hex==([\w:\-]+)/g, function (old, mac) {
            var plainHex = mac.replace(/[:\-]/g, '');
            if (/^[0-9A-Fa-f]*$/.test(plainHex)) {
                return 'mac_hex==' + plainHex;
            }
            return old;
        });
    }

    function getSorting() {
        return 'orderby=' + ctrl.tableModel.orderBy + '&direction=' + (ctrl.tableModel.orderBy ? (ctrl.tableModel.orderDir ? 'asc' : 'desc') : '');
    }

    function initializeDevices(data) {
        var passed = [];
        for (var i in data) {
            if (data[i].mac !== undefined) {
                var mac = data[i].mac;
                if (passed.indexOf(mac) !== -1) continue;
                getDevice(mac);
                passed.push(mac);
            }
        }
    }

    var parseDate = function (value) {
        /**
         * Date formater ::
         * [date, month, year].join('/') + ' ' + [hour, minute, second].join(':');
         */

        var date = [value.stamp[2], value.stamp[1], value.stamp[0]].join('/') + ' ' +
            [value.stamp[3], value.stamp[4], value.stamp[5]].join(':');
        return new Date(date);
    }

    ctrl.getAlarmIcon = function (value) {
        var level = parseInt(value / 75);
        switch (level) {
            case 0 :
                return "text-info glyphicon glyphicon-info-sign"; //fa fa-info-circle
            case 1 :
                return "text-warning fa fa-warning";
            default:
                return "text-danger glyphicon glyphicon-remove-sign"; //fa fa-bell
        }
    };

    ctrl.getEventColor = function (priority) {
        switch (IntervalService.getPriorityLevel(priority)) {
            case -1:
                return calendarConfig.colorTypes.none;
            case 0 :
                return calendarConfig.colorTypes.info;
            case 1 :
                return calendarConfig.colorTypes.warning;
            default:
                return calendarConfig.colorTypes.important;
        }
    };

    ctrl.quit = function (alarm) {
        if (alarm.version === 4 || alarm.version === 5) {
            ctrl.alarmToQuit = alarm;
            warnModal = $uibModal.open({
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope,
                templateUrl: 'scripts/app/alarm/alarm.modal.quickQuit.html'
            });
        } else if (alarm.version <= 3 && alarm.version > 0) {
            ctrl.alarmToQuit = alarm;
            warnModal = $uibModal.open({
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope,
                templateUrl: 'scripts/app/alarm/alarm.quit.all.warn.html'
            });
        } else {
            Notify.error("global.notification.error.title", "alarm.notifications.noQuit", 2000);
        }
    };

    ctrl.sendQuit = function (alarmId, message) {
        AlarmService.quitAlarm(alarmId, message).then(function (response) {
            if (response.data) {
                if (response.data === true) {
                    ctrl.markAsQuit(alarmId);
                }
            }
        });
    };

    ctrl.quitV3 = function () {
        ctrl.closeWarnModal();
        ctrl.sendQuit(ctrl.alarmToQuit.idAlarm, "");
        ctrl.alarmToQuit = null;
    };

    ctrl.quitWithReason = function () {
        if (_vaildateQuitMessage(ctrl.quitReason)) {
            ctrl.closeWarnModal();
            ctrl.sendQuit(ctrl.alarmToQuit.idAlarm, ctrl.quitReason);
            ctrl.alarmToQuit = null;
            ctrl.quitReason = "";
        }
    };

    function _vaildateQuitMessage(message) {
        var expression = new RegExp("\\w{1,}");
        if (!expression.exec(message)) {
            Notify.error("global.notification.error.title", "alarm.notifications.noReasonProvided", 2000);
            return false;
        }
        return true;
    }

    ctrl.updateQueryPrio = function (prio) {
        ctrl.currentPrio = prio;
        if (prio === 'error') {
            ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().errorPrioStart + ')';
        } else if (prio === 'warn') {
            ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().warnPrioStart + ')' + ' and priority<=(' + IntervalService.getPriorityInterval().warnPrioEnd + ')';
        } else if (prio === 'info') {
            ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().infoPrioStart + ')' + ' and priority<=(' + IntervalService.getPriorityInterval().infoPrioEnd + ')';
        } else if (prio === 'reset') {
            ctrl.restriction = '';
        }
        _query(true, false, true);
    };

    ctrl.closeModal = function () {
        if (currentModal) currentModal.close();
        AlarmService.killRenderer();
    };

    ctrl.closeWarnModal = function () {
        if (warnModal) warnModal.close();
    };

// Cache devices for this controller session
    function getDevice(mac) {
        var device = ctrl.currentDevices[mac];
        if (device !== null && device !== undefined) {
            return $q.when(device);
        } else {
            return Device.get(mac).then(function (response) {
                ctrl.currentDevices[response.data.mac] = response.data;
                return response.data;
            });
        }
    }

    ctrl.datewiseAlarm = function (date) {
        let defer = $q.defer();
        let reqDate = new Date(date)
        let year = reqDate.getFullYear();
        let month = reqDate.getMonth() + 1;
        if (ctrl.dataPopulated === true) {
            defer.resolve(ctrl.events);
            return defer.promise;
        }
        Alarm.dateSpecificAlarmList(year, month).then(function (res) {
                ctrl.eventsDateSpecificFunction = [];
                ctrl.data = res.data;
                initializeDevices(ctrl.data);
                for (i = 0; i < ctrl.data.length; i++) {
                    if (Tools.isDefinedNotNull(ctrl.data[i])) {
                        let id, text, label, type, color, action;
                        let isIncrement = false;
                        let start, end;
                        if (eventType[0].type === ctrl.data[i].eventType) {
                            if (ctrl.data[i].active) {
                                isIncrement = true;
                            }
                            if (ctrl.data[i].priority < 75) {
                                type = 'info'
                            }
                            if (ctrl.data[i].priority >= 75 && ctrl.data[i].priority <= 149) {
                                type = 'warning'
                            }
                            if (ctrl.data[i].priority > 149) {
                                type = 'danger'
                                //to get red color changing name of error to danger
                            }
                            start = new Date(ctrl.data[i].stamp);
                            color = ctrl.getEventColor(ctrl.data[i].priority) ? ctrl.getEventColor(ctrl.data[i].priority) : "";
                            text = '<span class="text"><span>' + currentLabel + ' ' + ctrl.data[i].text + '</span> / <span>' + ctrl.data[i].locationName + '</span></span>';
                            label = currentLabel ? currentLabel : "";
                            action = ctrl.data[i].action
                            id = ctrl.data[i].idAlarm;
                        } else if (eventType[1].type === ctrl.data[i].eventType) {
                            color = ctrl.getEventColor(-75) ? ctrl.getEventColor(-75) : "";
                            text = '<span class="text"><span>' + historicalLabel + ' ' + ctrl.data[i].text + '</span> / <span>' + ctrl.data[i].locationName + '</span></span>';
                            label = historicalLabel ? historicalLabel : "";
                            start = new Date(ctrl.data[i].stamp);
                            action = ctrl.data[i].action;
                            id = ctrl.data[i].idErrorLog;
                        } else if (eventType[2].type === ctrl.data[i].eventType) {
                            id = ctrl.data[i].id;
                            color = {primary: '#fff', secondary: '#d1dade'}
                            text = customEventLabel + ' ' + ctrl.data[i].title + ' / ' + ctrl.data[i].message;
                            label = customEventLabel ? customEventLabel : "";
                            start = new Date(ctrl.data[i].start);
                            end = new Date(ctrl.data[i].end);
                            type = 'custom';
                            action = [{
                                label: '<i class=\'glyphicon glyphicon-pencil\'></i>', onClick: function (args) {
                                    ctrl.editCustomEvent(args.calendarEvent);
                                }
                            }, {
                                label: '<i class=\'glyphicon glyphicon-remove\'></i>', onClick: function (args) {
                                    ctrl.openCustomEventDeleteModal(args.calendarEvent);
                                }
                            }];
                        }
                        ctrl.eventsDateSpecificFunction.push({
                            title: text ? text : "",
                            startsAt: start ? start : "",
                            endsAt: end ? end : "",
                            color: color ? color : "",
                            type: type ? type : "",
                            id: id,
                            mac: ctrl.data[i].mac ? ctrl.data[i].mac : "",
                            actions: action,
                            active: ctrl.data[i].active ? ctrl.data[i].active : "",
                            alarmUniqueId: ctrl.data[i].alarmUniqueId ? ctrl.data[i].alarmUniqueId : "",
                            autoQuit: ctrl.data[i].autoQuit ? ctrl.data[i].autoQuit : "",
                            device_name: ctrl.data[i].device_name ? ctrl.data[i].device_name : "",
                            errorCode: ctrl.data[i].errorCode ? ctrl.data[i].errorCode : "",
                            hash: ctrl.data[i].hash ? ctrl.data[i].hash : "",
                            locationId: ctrl.data[i].locationId ? ctrl.data[i].locationId : "",
                            locationName: ctrl.data[i].locationName ? ctrl.data[i].locationName : "",
                            mailSent: ctrl.data[i].mailSent ? ctrl.data[i].mailSent : "",
                            origin: ctrl.data[i].origin ? ctrl.data[i].origin : "",
                            quit: ctrl.data[i].quit ? ctrl.data[i].quit : "",
                            version: ctrl.data[i].version ? ctrl.data[i].version : "",
                            text: ctrl.data[i].text ? ctrl.data[i].text : "",
                            priority: ctrl.data[i].priority ? ctrl.data[i].priority : "",
                            incrementsBadgeTotal: isIncrement ? isIncrement : "",
                            label: label ? label : ""
                        });
                    }
                }
                ctrl.events = ctrl.eventsDateSpecificFunction;
                defer.resolve(ctrl.events);
            }, function (error) {
                $log.error('Couldn\'t load alarms!', error);
                ctrl.data = [];
            }
        )
        return defer.promise;
    }


    ctrl.markAsQuit = function (idAlarm) {
        for (var alarmIndex in ctrl.data) {
            if (ctrl.data[alarmIndex].idAlarm === idAlarm) {
                ctrl.data[alarmIndex].quit = true;
                break;
            }
        }
    };
}

angular.module('emsv2App').controller('AlarmCalendarController', AlarmCalendarController);

