(function () {

    'use strict';

    function AlarmHistoryTabController($scope, $log, $interval, $uibModal, $q, LocationDetailService, Alarm, IntervalService,
                                       Device, AlarmService, currentLocation, userInfo, Principal, Notify, Tools) {
        var ctrl = this;
        var currentModal = null;
        var warnModal = null;
        var timeoutId;
        ctrl.currentDevices = [];
        ctrl.isCyberhub = false;

        ctrl.isAdmin = function () {
            return Principal.isInRole('ROLE_ADMIN');
        };

        ctrl.hasEditRight = function () {
            return Principal.isInRole("ROLE_LOCATION_EDIT");
        };
        ctrl.enableQuitButton = false;
        ctrl.data = [];
        ctrl.query = '';
        ctrl.page = 0;
        ctrl.itemsPerPage = 50;
        ctrl.doQuery = function () {
            return _query(false);
        };
        ctrl.hasMore = true;
        ctrl.tableModel = {
            orderBy: 'stamp',
            orderDir: false,
            tableName: 'alarm_view',
            translation: 'alarm.rsql',
            head: [
                {name: 'priority', translation: 'location.errorlog.priority'},
                {name: 'stamp', translation: 'location.errorlog.time'},
                // {name: 'device_name', translation: 'location.errorlog.device', sortable: true},
                {name: 'hash_text', translation: 'location.errorlog.text', sortable: false},
                {name: 'active', translation: 'location.errorlog.active', sortable: true},
                {name: 'auto_quit', translation: 'location.errorlog.auto_quit', sortable: true},
                {name: 'mail_sent', translation: 'location.errorlog.mail_sent', sortable: true},
                {name: 'sms_sent', translation: 'alarm.smsSent', sortable: true},
                {name: 'quit', translation: 'location.errorlog.quit', sortable: true},
                ctrl.enableQuitButton ? {name: 'quitButton', translation: '', sortable: true} : null
            ],
            disabledDefinitions: [
                'location_id',
                'location_name'
            ]
        };

        ctrl.currentPrio = 'reset';
        ctrl.counts = {
            info: 0,
            warning: 0,
            error: 0
        };

        ctrl.$onInit = function () {
            ctrl.enableQuitButton = isAdmin() || hasEditRight();
            LocationDetailService.reset().setDefaultBackToOverview();
            timeoutId = $interval(function () {
                var input = angular.element('[ng-model="rsqlSearchText"]')[0].value;
                _query(true, Tools.isDefinedNotNull(input) && input !== "");
            }, 5000);
        };

        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
        });

        ctrl.quitWithReason = function () {
            ctrl.closeWarnModal();
            ctrl.sendQuit(ctrl.alarmToQuit.idAlarm, ctrl.quitReason);
            ctrl.alarmToQuit = null;
            ctrl.quitReason = "";
        };

        ctrl.quit = function (alarm) {
            if (alarm.version === 4 || alarm.version === 5) {
                ctrl.alarmToQuit = alarm;
                warnModal = $uibModal.open({
                    animation: false,
                    windowClass: 'animated fadeInDown',
                    scope: $scope,
                    templateUrl: 'scripts/app/location/tabs/alarm/alarmhistory/alarm.modal.quickQuit.html'
                });
            } else if (alarm.version <= 3 && alarm.version > 0) {
                ctrl.alarmToQuit = alarm;
                warnModal = $uibModal.open({
                    animation: false,
                    windowClass: 'animated fadeInDown',
                    scope: $scope,
                    templateUrl: 'scripts/app/location/tabs/alarm/alarmhistory/alarm.quit.all.warn.html'
                });
            } else {
                Notify.error("global.notification.error.title", "alarm.notifications.noQuit", 2000);
            }
        };

        ctrl.closeWarnModal = function () {
            if (warnModal) warnModal.close();
        };

        ctrl.sendQuit = function (alarmId, message) {
            AlarmService.quitAlarm(alarmId, message).then(function (response) {
                if (response.data) {
                    if (response.data === true) {
                        ctrl.markAsQuit(alarmId);
                    }
                }
            });
        };

        ctrl.quitV3 = function () {
            ctrl.closeWarnModal();
            if (ctrl.v3alarm) {
                ctrl.sendQuit(ctrl.v3alarm.idAlarm, "");
            }
            ctrl.v3alarm = null;
        };

        ctrl.markAsQuit = function (idAlarm) {
            for (var alarmIndex in ctrl.data) {
                if (ctrl.data[alarmIndex].idAlarm === idAlarm) {
                    ctrl.data[alarmIndex].quit = true;
                    break;
                }
            }
        };

        $scope.$on("$stateChangeStart", function (event, next, current) {
            AlarmService.killRenderer();
        });

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderBy = sortField;
            ctrl.tableModel.orderDir = sortReverse;
            _query(true);
        };

        ctrl.doFilter = function (params, fullTextSearch) {
            ctrl.query = params;
            _query(true, fullTextSearch);
        };

        function isAdmin() {
            var isAdmin = Principal.isInRole('ROLE_ADMIN');
            return isAdmin;
        }

        function hasEditRight() {
            var hasEditRight = Principal.isInRole("ROLE_LOCATION_EDIT");
            return hasEditRight;
        }

        function _query(reset, fullTextSearch) {
            ctrl.page = reset ? 0 : ctrl.page + 1;
            ctrl.query = _prepareQuery(ctrl.query);
            fullTextSearch = Tools.isDefinedNotNull(fullTextSearch) && typeof fullTextSearch === 'boolean' ? fullTextSearch : false;
            var query = ctrl.query;
            if (!query.length || query.indexOf('?') === 0) {
                fullTextSearch = false;
            }else{
                query = '/' + query;
            }
            return Alarm.locationAlarmRsql(currentLocation.id, ctrl.page, query, ctrl.itemsPerPage, fullTextSearch).then(function (response) {
                ctrl.hasMore = response.data.length === ctrl.itemsPerPage;
                if (!reset) {
                    for (var i = 0; i < response.data.length; i++) {
                        ctrl.data.push(response.data[i]);
                    }
                } else {
                    ctrl.data = response.data;
                    initializeDevices(ctrl.data);
                }
            }, function (error) {
                $log.error('Couldn\'t load alarms!', error);
                ctrl.data = [];
            });
        }

        function _prepareQuery(query) {
            return query.replace(/mac_hex==([\w:\-]+)/g, function (old, mac) {
                var plainHex = mac.replace(/[:\-]/g, '');
                if (/^[0-9A-Fa-f]*$/.test(plainHex)) {
                    return 'mac_hex==' + plainHex;
                }
                return old;
            });
        }

        function getSorting() {
            return 'orderby=' + ctrl.tableModel.orderBy
                + '&direction=' + (ctrl.tableModel.orderBy ? (ctrl.tableModel.orderDir ? 'asc' : 'desc') : '');
        }

        function initializeDevices(data) {
            var passed = [];
            for (var i in data) {
                var mac = data[i].mac;
                if (passed.indexOf(mac) !== -1) continue;
                getDevice(mac);
                passed.push(mac);
            }
        }

        ctrl.getAlarmIcon = function (value) {
            var level = parseInt(value / 75);
            switch (level) {
                case 0 :
                    return "text-info glyphicon glyphicon-info-sign"; //fa fa-info-circle
                case 1 :
                    return "text-warning fa fa-warning";
                default:
                    return "text-danger glyphicon glyphicon-remove-sign"; //fa fa-bell
            }
        };

        ctrl.getRowDecoration = function (priority) {
            switch (IntervalService.getPriorityLevel(priority)) {
                case 0 :
                    return "row-info";
                case 1 :
                    return "row-warning";
                default:
                    return "row-danger";
            }
        };

        ctrl.updateQueryPrio = function (prio) {
            ctrl.currentPrio = prio;
            if (prio === 'error') {
                ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().errorPrioStart + ')';
            } else if (prio === 'warn') {
                ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().warnPrioStart + ')' +
                    ' and priority<=(' + IntervalService.getPriorityInterval().warnPrioEnd + ')';
            } else if (prio === 'info') {
                ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().infoPrioStart + ')' +
                    ' and priority<=(' + IntervalService.getPriorityInterval().infoPrioEnd + ')';
            } else if (prio === 'reset') {
                ctrl.restriction = '';
            }
            _query(true);
        };

        ctrl.closeModal = function () {
            if (currentModal) currentModal.close();
            AlarmService.killRenderer();
        };

        // Cache devices for this controller session
        function getDevice(mac) {
            var device = ctrl.currentDevices[mac];
            if (device !== null && device !== undefined) {
                return $q.when(device);
            } else {
                return Device.get(mac).then(function (response) {
                    ctrl.currentDevices[response.data.mac] = response.data;
                    return response.data;
                });
            }
        }

        ctrl.showDetails = function (data) {
            var isSDCIMDevice = false;
            var isCyberHubNodeDevice = false;
            var isVirtualSNMP = false;
            $log.debug("data:", data);

            getDevice(data.mac).then(function (requestedDevice) {
                if (requestedDevice !== null) {
                    if (requestedDevice.hwtyp.id === 8) {
                        isSDCIMDevice = true;
                    }
                    if (requestedDevice.hwtyp.id === 12) {
                        isCyberHubNodeDevice = true;
                    }
                    if (requestedDevice.hwtyp.id === 20) {
                        isVirtualSNMP = true;
                    }
                    ctrl.closeModal();
                    currentModal = $uibModal.open({
                        animation: false,
                        component: 'alarmModal',
                        windowClass: 'animated fadeInDown',
                        size: ((isSDCIMDevice && requestedDevice.online) || isVirtualSNMP || isCyberHubNodeDevice) ? 'lg' : '',
                        resolve: {
                            currentDevice: function () {
                                return requestedDevice;
                            },
                            currentAlarm: function () {
                                return data;
                            },
                            isSDCIMDevice: function () {
                                return isSDCIMDevice;
                            },
                            isCyberHubNodeDevice: function () {
                                return isCyberHubNodeDevice;
                            },
                            isVirtualSNMP: function () {
                                return isVirtualSNMP;
                            },
                            stamp: function () {
                                return data.stamp;
                            },
                            user: userInfo
                        }
                    });
                    currentModal.result.then(function (quitAlarm) {
                        for (var alarmIndex in ctrl.data) {
                            if (ctrl.data[alarmIndex].idAlarm === quitAlarm.idAlarm) {
                                ctrl.data[alarmIndex].quit = true;
                                break;
                            }
                        }
                    }, function (dismissReason) {

                    });
                }
            }, function () {
                $log.error('Could not get Device by Mac');
            });
        };
    }

    angular
        .module('emsv2App')
        .controller('AlarmHistoryTabController', AlarmHistoryTabController);

})();
