(function () {
    'use strict';

    function databaseController($scope, $state, $http,$window, $log, $interval, dbBackup, Notify, Tools) {

        var ctrl = this;
        var timeoutId;
        var OperatingSystem = null;

        ctrl.database = {
            size: "",
        };
        ctrl.selectedDeviceForBackup = null;
        ctrl.spaceInsufficient = false;
        ctrl.generateFile = null;
        ctrl.taskStatus = null;
        ctrl.status = null;
        ctrl.backupInProgress = false;
        ctrl.history = null;
        ctrl.fixedPath = "/opt/cyberhub/backup"
        ctrl.diskSpace = [];
        ctrl.parser = new UAParser();
        ctrl.$onInit = function () {
            ctrl.getDBSize();
            _getStatus();
        };

        ctrl.getDBSize = function () {

            dbBackup.getDatabaseSize().then(function (response) {
                ctrl.database.size = response.data
            });
            dbBackup.getDiskSpaceInfo().then(function (response) {
                ctrl.availableDevices = response.data;
            });
        };

        ctrl.refreshAvailableDevices = function () {
            dbBackup.getDiskSpaceInfo().then(function (response) {
                ctrl.availableDevices = response.data;
            });
        };

        ctrl.compareDiskSpace = function (available, size) {
            if (parseInt(available) < parseInt(size)) {
                return {"insufficientSpace": true};
            }
        };
        ctrl.statusSuccessOrFail = function (status) {
            if (!status) {
                return {"insufficientSpace": true};
            }
        };

        ctrl.createBackup = function () {
            ctrl.status = null;
            dbBackup.startBackup(ctrl.fixedPath).then(function () {
                Notify.success("database.notification.generate.success.title", "database.notification.generate.success.text", 3000);
            }, function (error) {
                $log.error('Couldn\'t create dump!', error);

                switch(error.status){
                    case 501: Notify.error("database.notification.generate.error.501.title", "database.notification.generate.error.501.text", 3000); break;
                    default: Notify.error("database.notification.generate.error.title", "database.notification.generate.error.title", 3000); break;
                }
            });
            _getStatus();
        };

        ctrl.reload = function () {
            $window.location.reload();
        };

        var _getStatus = function () {
            timeoutId = $interval(function () {
                dbBackup.getTaskStatus().then(function (response) {
                    ctrl.taskStatus = response.data;
                    ctrl.getHistory();
                    if (ctrl.taskStatus === -2) {
                        ctrl.backupInProgress = false;
                    } else if (ctrl.taskStatus === -1) {
                        ctrl.backupInProgress = false;
                    } else if (ctrl.taskStatus === 0) {
                        ctrl.backupInProgress = true;
                    } else if (ctrl.taskStatus === 1) {
                        ctrl.backupInProgress = true;
                    }else if (ctrl.taskStatus === 2) {
                        ctrl.backupInProgress = false;
                    }else if (ctrl.taskStatus === 3) {
                        ctrl.backupInProgress = false;
                    }
                });
            }, 250);
        }

        ctrl.formatBytes = function (bytes, decimals) {
            if (bytes === 0) return '0 Bytes';

            var k = 1024;
            var dm = decimals < 0 ? 0 : decimals;
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            var i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        ctrl.getHistory = function() {
            dbBackup.getHistory().then(function(response){
               ctrl.history = response.data;
            });
        }

    }

    angular
        .module('emsv2App')
        .controller('databaseController', databaseController);
})();