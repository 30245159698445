'use strict';

/**
 * @description Constructor for limit object
 * @param {number} id the id to set
 * @param {number} uniqueId the uniqueId to set
 * @param {string} name the name to set
 * @param {number} type the type to set
 * @param {number} priority the priority to set
 * @param {number} delay the delay to set
 * @param {number} value the value to set
 * @constructor
 */
function Limit(id, uniqueId, name, type, priority, delay, value) {
    this.id = id !== undefined ? id : -1;
    this.uniqueId = uniqueId !== undefined ? uniqueId : -1;
    this.name = name !== undefined ? name : "";
    this.type = type !== undefined ? type : 0;
    this.priority = priority !== undefined ? priority : 74;
    this.delay = delay !== undefined ? delay : 10;
    this.value = value;
    this.driverValueId = null;
}

Limit.prototype = {};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
Limit.prototype.validate = function () {
    var errorList = [];

    if (this.id === undefined || this.id === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "id"));
    if (this.uniqueId === undefined || this.uniqueId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "uniqueId"));
    if (this.name === undefined || this.name === null || this.name === "") errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "name"));
    if (this.type === undefined || this.type === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "type"));
    if (this.priority === undefined || this.priority === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "priority"));
    if (this.delay === undefined || this.delay === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "delay"));
    if (this.value === undefined || this.value === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "value"));

    if (this.driverValueId === undefined || this.driverValueId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "driverValueId"));

    return errorList;
};

/**
 * @description Function to validate provided object as limit
 * @param {object} obj the object to validate as limit obj
 * @returns {ErrorObject[]} array of error objects
 */
Limit.validateGeneral = function (obj) {
    var errorList = [];
    var tempLimit = Limit.parseFromHtmlObject(obj);
    errorList = errorList.concat(tempLimit.validate());
    return errorList;
};

/**
 * @description Function to create new limit object from json data object
 * @param {object} obj json data object to use
 * @returns {Limit} returns created limit object
 */
Limit.parseFromHtmlObject = function (obj) {
    var ret = new Limit(obj.id, obj.uniqueId, obj.name, obj.type, obj.priority, obj.delay, obj.value);
    ret.driverValueId = obj.driverValueId;
    return ret;
};

/**
 * @description Function to create new limit object from javascript object
 * @param {object} obj the javascript object to use
 * @returns {Limit} returns created limit object
 */
Limit.parseFromSimpleObject = function (obj) {
    var ret = new Limit(obj.id, obj.uniqueId, obj.name, obj.type, obj.priority, obj.delay, obj.value);
    ret.driverValueId = obj.driverValueId;
    return ret;
};
