(function () {

    'use strict';

    function endPointService($http) {
        return {
            get: function () {
                return $http.get('api/endPoint/');
            }
        };
    }

    angular.module('emsv2App').service('EndPoint', endPointService);
})();

