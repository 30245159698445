(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name roomImportModal
     */
    angular
        .module('emsv2App')
        .component('roomImportModal', {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/location/tabs/room/room.import.modal.html',
            controller: ['$log', '$http',
                function ($log, $http) {
                    var ctrl = this;
                    ctrl.input = {
                        selectedfile: undefined,
                        roomName: null,
                        oldroomName: null
                    };

                    ctrl.selection = {
                        option: null, // onlyroom = 0, entities = 1, configurations =2
                        disabled: ''
                    };


                    var result = null;
                    var formatted = null;

                    ctrl.deepsearch = function(driverValueFound) {
                        if (driverValueFound.racks.length > 0) {
                            for (var r = 0; r < driverValueFound.racks.length; r++) {
                                if (driverValueFound.racks[r].slots.length > 0) {
                                    for (var s = 0; s < driverValueFound.racks[r].slots.length; s++) {
                                        if (driverValueFound.racks[r].slots[s].driverValues.length > 0) return true;
                                        if (driverValueFound.racks[r].slots[s].blades.length > 0) {
                                            for (var b = 0; b < driverValueFound.racks[r].slots[s].blades.length; b++) {
                                                if (driverValueFound.racks[r].slots[s].blades[b].cpus.length > 0) {
                                                    for (var c = 0; c < driverValueFound.racks[r].slots[s].blades[b].cpus.length; c++) {
                                                        if (driverValueFound.racks[r].slots[s].blades[b].cpus[c].driverValues.length > 0) return true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else if (driverValueFound.coolings.length > 0) {
                            for (var c = 0; c < driverValueFound.coolings.length; c++) {
                                if (driverValueFound.coolings[c].driverValues.length > 0) return true;
                            }
                        }
                        else if (driverValueFound.assets.length > 0) {
                            for (var a = 0; a < driverValueFound.assets.length; a++) {
                                if (driverValueFound.assets[a].driverValues.length > 0) return true;
                            }
                        }
                        else if (driverValueFound.sensors.length > 0) {
                            for (var s = 0; s < driverValueFound.sensors.length; s++) {
                                if (driverValueFound.sensors[s].driverValues.length > 0) return true;
                            }
                        }
                        else if (driverValueFound.floorTiles.length > 0) {
                            for (var f = 0; f < driverValueFound.floorTiles.length; f++) {
                                if (driverValueFound.floorTiles[f].driverValues.length > 0) return true;
                            }
                        }
                        else if (driverValueFound.ups.length > 0) {
                            for (var u = 0; u < driverValueFound.ups.length; u++) {
                                if (driverValueFound.ups[u].driverValues.length > 0) return true;
                            }
                        }
                    return false;
                    };

                    document.getElementById('jsonroom').onchange = function() {
                        ctrl.selection.disabled = '';
                        ctrl.selection.option = null;
                        ctrl.input.roomName = "";

                        var files = document.getElementById('jsonroom').files;
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            // var test = e.target.result;
                            // ctrl.selection.option === null;
                            // ctrl.input.roomName === "";
                            result = JSON.parse(e.target.result);
                            ctrl.input.roomName = result.name;
                            ctrl.input.oldroomName = result.name;
                            if (result.driverValueGroups.length === 0) {
                                if (result.racks.length === 0 && result.coolings.length === 0 && result.assets.length === 0 && result.sensors.length === 0 && result.floorTiles.length === 0 && result.ups.length === 0 ) {
                                    ctrl.selection.option = 0;
                                    ctrl.selection.disabled = 'onlyroom';
                                }  else if (result.racks.length > 0 || result.coolings.length > 0 || result.assets.length > 0 || result.sensors.length > 0 || result.floorTiles.length > 0 || result.ups.length > 0) {
                                    if (!ctrl.deepsearch(result)) {
                                        ctrl.selection.disabled = 'entities';
                                    } else {
                                        ctrl.selection.disabled = 'configurations';
                                    }
                                    ctrl.selection.option = 0;
                                }
                                else {
                                    ctrl.selection.option = 0;
                                    ctrl.selection.disabled = 'entities';
                                }
                            } else {
                                ctrl.selection.option = 0;
                                ctrl.selection.disabled = 'configurations';
                            }
                        }
                        reader.readAsText(files.item(0));
                    }

                    ctrl.cancel = function () {
                        ctrl.dismiss();
                    };

                    ctrl.$onDestroy = function () {
                        ctrl.dismiss();
                    };

                    ctrl.closeModal = function () {
                        ctrl.dismiss();
                    };

                    ctrl.importRoom = function () {
                        if ((ctrl.input.roomName !== ctrl.input.oldroomName) && (ctrl.input.roomName !== null && ctrl.input.roomName !== undefined && ctrl.input.roomName !== "")) {
                            result.name = ctrl.input.roomName;
                            formatted = JSON.stringify(result);
                            var jsonfile = new Blob([formatted], {type: "text/plain;charset=utf-8"});
                            ctrl.input.selectedfile = jsonfile;
                        }
                        ctrl.close({
                            $value: {
                                selectedFile: ctrl.input.selectedfile,
                                selectedOption: ctrl.selection.option
                            }
                        });
                    }
                }]
        });
})();


