(function () {

    'use strict';

    /**
     * @ngdoc directive
     * @name sortableTable
     * @param {Object[]} model - Tablemodel [{name|translation|sortable}]
     * @param {Function} onSort - Function to call on sort
     * @param {string} sortField - Field to sort with
     * @param {boolean} sortReverse - Order by 'desc'/'asc'
     */
    angular.module('emsv2App')
        .directive('sortableTable', [function () {
            return {
                restrict: 'A',
                scope: {},
                bindToController: {
                    model: '=',
                    onSort: '=',
                    sortField: '=',
                    sortReverse: '='
                },
                templateUrl: 'scripts/components/rsql/sortableTable.html',
                controllerAs: '$ctrl',
                controller: function () {
                    var ctrl = this;

                    ctrl.$onInit = function () {
                        if (ctrl.onSort !== undefined && ctrl.sortField !== undefined && ctrl.sortField !== null) {
                            if (ctrl.sortReverse === null || ctrl.sortReverse === undefined) {
                                ctrl.sortReverse = false;
                            }
                            ctrl.onSort(ctrl.sortField, ctrl.sortReverse);
                        }
                    };

                    ctrl.orderBy = function (field) {
                        if (field.sortable === undefined || field.sortable === null || field.sortable === true) {
                            if (ctrl.sortField === null || ctrl.sortField === undefined || ctrl.sortField === '' || ctrl.sortField !== field.name) {
                                ctrl.sortField = field.name;
                                ctrl.sortReverse = false;
                            }
                            else {
                                if (!ctrl.sortReverse || ctrl.sortReverse === false) {
                                    ctrl.sortReverse = true;
                                }
                                else {
                                    //  ctrl.sortField = '';
                                    ctrl.sortField = field.name;
                                    ctrl.sortReverse = false;
                                }
                            }
                            if (ctrl.onSort !== undefined) {
                                ctrl.onSort(ctrl.sortField, ctrl.sortReverse);
                            }
                        }
                    };
                }
            };
        }]);
})();
