(function () {

    'use strict';

    function tokenService($http) {
        return {
            listAllToken: function (){
                return $http.get('api/token/listall');
            },
            generateToken:function (tokenData){
                return $http.post('api/token/generate',tokenData);
            },
            revokeToken: function (tokenObj){
                return $http.post('api/token/revoke',tokenObj);
            },
            deleteToken: function (tokenObj){
                return $http.post('api/token/delete',tokenObj);
            },
            getCurrentLoginUser: function (){
                return $http.get('api/token/getlogin');
            },
            rsql: function (page, query) {
                return $http.get('api/token/query/' + page + '/20/' + query);
            }
        };
    }

    angular.module('emsv2App').service('TokenService', tokenService);

})();
