(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name ibox
     * @param {string} heading - Title of the panel
     * @param {string} css - Additional css
     */
    angular.module('emsv2App').component('ibox', {
        bindings: {
            heading: '@',
            css: '@'
        },
        transclude: {
            content: 'iboxContent',
            toolbox: '?iboxToolbox'
        },
        template: '<div class="ibox {{$ctrl.css}}"><div class="ibox-title"><h5 ng-bind="$ctrl.heading"></h5><div class="ibox-tools" ng-transclude="toolbox"></div></div><div class="ibox-content" ng-transclude="content"></div></div>'
    });

})();
