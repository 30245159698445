(function () {

    'use strict';

    function overviewController(userInfo, $state, $scope, $window, $stateParams, $log, $q, $timeout,
                                Tools, Location, EMSConstants, leafletData, LocationDetailService,
                                currentLocation, userList, groupList, Notify, license, $translate, GenDialogService) {
        var ctrl = this;
        var initLocation = null;
        ctrl.license = license;
        ctrl.user = userInfo;
        ctrl.editMode = $stateParams.mode !== 'view';
        ctrl.location = currentLocation;
        ctrl.userList = userList; // List of selectable users
        ctrl.groupList = groupList;
        ctrl.isAdmin = false;
        ctrl.hasViewRight = false;
        ctrl.hasEditRight = false;
        ctrl.hasCreateRight = false;
        ctrl.hasDeleteRight = false;
        ctrl.placeholder = $translate.instant('global.form.select');

        ctrl.timeZones = [];
        ctrl.selectedTimezone = null;
        ctrl.vars = {
            edit: true,
            showImageSelect: false,
            showLogs: false,
            isAdmin: false
        };
        ctrl.map = {
            defaults: {
                scrollWheelZoom: true
            }
        };
        ctrl.alerts = [];

        var iconOk = L.icon({
            iconUrl: 'assets/ci/cyberhub-light/images/icon_map_location_ok.png',
            shadowUrl: 'assets/ci/cyberhub-light/images/marker-shadow.png',
            iconSite: [25, 41],
            shadowSize: [41, 41],
            iconAnchor: [12, 41],
            shadowAnchor: [12, 41],
            popupAnchor: [0, -41]
        });
        var currentMap;

        ctrl.$onInit = function () {
            if (userInfo !== null) {
                if (userInfo.hasOwnProperty("roles")) {
                    if (userInfo.roles.indexOf("ROLE_ADMIN") !== -1) ctrl.isAdmin = true;
                    if (userInfo.roles.indexOf("ROLE_LOCATION_CREATE") !== -1) ctrl.hasCreateRight = true;
                    if (userInfo.roles.indexOf("ROLE_LOCATION_DELETE") !== -1) ctrl.hasDeleteRight = true;
                    if (userInfo.roles.indexOf("ROLE_LOCATION_EDIT") !== -1) ctrl.hasEditRight = true;
                    if (userInfo.roles.indexOf("ROLE_LOCATION_VIEW") !== -1) ctrl.hasViewRight = true;
                }
            }
            else {
                ctrl.isAdmin = false;
                ctrl.hasEditRight = false;
                ctrl.hasViewRight = false;
                ctrl.hasCreateRight = false;
                ctrl.hasDeleteRight = false;
            }

            Location.getTimeZones().then(function (response) {
                if (response !== null && response.data !== null) {
                    ctrl.timeZones = response.data;
                    if (ctrl.location !== null && ctrl.location.timezone !== null) {
                        for (var i = 0; i < ctrl.timeZones.length; i++) {
                            if (ctrl.timeZones[i].id === ctrl.location.timezone) {
                                ctrl.selectedTimezone = ctrl.timeZones[i];
                            }
                        }
                    }
                }
            });
            L.Icon.Default.imagePath = 'assets/ci/cyberhub-light/images/';
            leafletData.getMap('locationmap').then(function (map) {
                currentMap = map;
                // Doesn't work (here) if immediately set. Works in map widget though..
                $timeout(function () {
                    _loadLocationMarker();
                }, 50);
            }, function (error) {
                $log.error('Couldn\'t get map instance!', error);
            });

            LocationDetailService.reset();
            if (ctrl.editMode && (ctrl.hasEditRight || ctrl.isAdmin)) {
                LocationDetailService.onSave = ctrl.update;
            }

            if (ctrl.editMode && (ctrl.hasEditRight || ctrl.isAdmin)) {
                LocationDetailService.canSave = ctrl.canSave;
            }

            if (!ctrl.editMode && (ctrl.hasEditRight || ctrl.isAdmin)) {
                LocationDetailService.onEdit = function () {
                    $state.go("location.overview", {mode: 'edit'}, {reload: true});
                }
            }

            initLocation = angular.copy(ctrl.location);
        };
        ctrl.canSave = function () {
            if(Tools.isDefinedNotNull(ctrl.editForm)) {
                return !ctrl.editForm.$invalid;
            }
        };

        function _loadLocationMarker() {
            var markers = new L.FeatureGroup();
            if (ctrl.location.pos_lat !== null && ctrl.location.pos_lng !== null) {
                // Set map location
                var latlng = L.latLng(ctrl.location.pos_lat, ctrl.location.pos_lng);
                var marker = L.marker(latlng, {focus: true, draggable: false});
                marker.setIcon(iconOk);
                markers.addLayer(marker);
                currentMap.addLayer(markers);
                currentMap.invalidateSize();
                currentMap.setView(latlng, 18);
            }
        }

        ctrl.hasRole = function (role) {
            return ctrl.user !== null
                && ctrl.user !== undefined
                && ctrl.user.hasOwnProperty('roles')
                && ctrl.user.roles.indexOf('ROLE_' + role) !== -1;
        };

        ctrl.onAutoPositionChange = function () {
            if (!ctrl.validateAutoValue()) return;
            var split = String(ctrl.tmp.position.autoValue).split(';');
            ctrl.location.pos_lat = (split.length > 2) ? split[1] : 0;
            ctrl.location.pos_lng = (split.length > 2) ? split[0] : 0;
            ctrl.location.pos_alt = (split.length > 2) ? split[2] : 0;
        };

        ctrl.manualPosition = function () {
            ctrl.tmp.position.select = 'manual';
            ctrl.tmp.position.autoValue = "";
            ctrl.editForm.autoValue.$setValidity("autoValue", true);
        }

        // Concat the URL to the selected device
        ctrl.getFindLocationUrl = function () {
            var url = EMSConstants.constants.mapUrl + '/?';
            var result = 'address=';
            if (Tools.isDefinedNotNull(ctrl.location.street)) {
                result += ctrl.location.street;
            }
            if (Tools.isDefinedNotNull(ctrl.location.plz)) {
                if (Tools.isDefinedNotNull(ctrl.location.street)) {
                    result += ',';
                }
                result += ctrl.location.plz;
            }
            if (Tools.isDefinedNotNull(ctrl.location.place)) {
                if (Tools.isDefinedNotNull(ctrl.location.plz)) {
                    result += ' ' + ctrl.location.place;
                }
                else {
                    result += ',' + ctrl.location.place;
                }
            }
            return url + result;
        };

        // Save the changes for the current location
        ctrl.update = function () {
            var deferred = $q.defer();
            if (ctrl.location.name == undefined) {
                return;
            }
            var location = angular.copy(ctrl.location);
            location.id === "" ? location.id = location.id : null;
            var users = location.users ? angular.copy(location.users) : [];
            var groups = location.groups ? angular.copy(location.groups) : [];
            delete location.users;

            Location.update(location).then(function () {
                var promises = [];
                // Save permissions
                promises.push(Location.setUsers(location.id, users).catch(function (error) {
                    $log.error('Could not save user permission for location: ' + location.id, error);
                }));
                // Save groups
                promises.push(Location.setGroups(location.id, groups).catch(function (error) {
                    $log.error('Could not save group permission for location: ' + location.id, error);
                }));

                $q.all(promises).then(function () {
                    deferred.resolve();
                    Notify.success("global.notification.success.title", "location.notification.locationSaved", 2000);
                    $state.reload();
                });
                LocationDetailService.canChange = true;
            }, function (error) {
                deferred.reject(error);
                $log.error('Couldn\'t update location!', error);
                Notify.error("global.notification.error.title", "location.notification.locationSaveFailed", 2000);
            });
            return deferred.promise;
        };

        ctrl.setTimeZone = function () {
            if (ctrl.selectedTimezone) {
                ctrl.location.timezone = ctrl.selectedTimezone.id;
                ctrl.isChanged();
            }
        }

        //Edit or delete location image
        ctrl.imageSelect = function () {
            ctrl.vars.showImageSelect = true;
            ctrl.isChanged();
        };

        ctrl.imageDelete = function () {
            ctrl.location.image = null;
            ctrl.isChanged();
        };

        ctrl.validateAutoValue = function () {
            if (ctrl.tmp.position.autoValue === "") {
                ctrl.editForm.autoValue.$setValidity("autoValue", true);
                return true;
            }
            else {
                var isValid = /^-?\d+.\d+;-?\d+.\d+;-?\d+.\d+$/.test(String(ctrl.tmp.position.autoValue));
                ctrl.editForm.autoValue.$setValidity("autoValue", isValid);
                return isValid;
            }
        }

        ctrl.validateLatitude = function () {
            if (ctrl.location.pos_lat === "") {
                ctrl.editForm.latitude.$setValidity("latitude", true)
            }
            else {
                var isValid = /^-?\d+(((\.?,?){1}\d+){1,})*$/.test(String(ctrl.location.pos_lat));
                ctrl.editForm.latitude.$setValidity("latitude", isValid);
            }
            ctrl.isChanged();
        }
        ctrl.validateLongitude = function () {
            if (ctrl.location.pos_lng === "") {
                ctrl.editForm.longitude.$setValidity("longitude", true)
            }
            else {
                var isValid = /^-?\d+(((\.?,?){1}\d+){1,})*$/.test(String(ctrl.location.pos_lng));
                ctrl.editForm.longitude.$setValidity("longitude", isValid);
            }
            ctrl.isChanged();
        }
        ctrl.validateAltitude = function () {
            if (ctrl.location.pos_alt === "") {
                ctrl.editForm.altitude.$setValidity("altitude", true)
            }
            else {
                var isValid = /^-?\d+(((\.?,?){1}\d+){1,})*$/.test(String(ctrl.location.pos_alt));
                ctrl.editForm.altitude.$setValidity("altitude", isValid);
            }
            ctrl.isChanged();
        }

        ctrl.compareLocationValues = function (locationA, locationB) {

            var aProps = Object.getOwnPropertyNames(locationA);
            var bProps = Object.getOwnPropertyNames(locationB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            if (locationA["id"] !== locationB["id"]) return true;
            // It's similar to Database query condition: nvl(val1,"") = nvl(val2,"") for null values
          //  if ((locationA["customId"] !== null ? locationA["customId"] : "") !== (locationB["customId"] !== null ? locationB["customId"] : "")) return true;
            if ((locationA["name"] !== null ? locationA["name"] : "") !== (locationB["name"] !== null ? locationB["name"] : "")) return true;
            if ((locationA["street"] !== null ? locationA["street"] : "") !== (locationB["street"] !== null ? locationB["street"] : "")) return true;
            if ((locationA["plz"] !== null ? locationA["plz"] : "") !== (locationB["plz"] !== null ? locationB["plz"] : "")) return true;
            if ((locationA["place"] !== null ? locationA["place"] : "") !== (locationB["place"] !== null ? locationB["place"] : "")) return true;
            if ((locationA["country"] !== null ? locationA["country"] : "") !== (locationB["country"] !== null ? locationB["country"] : "")) return true;
            if ((locationA["state"] !== null ? locationA["state"] : "") !== (locationB["state"] !== null ? locationB["state"] : "")) return true;
            if ((locationA["timezone"] !== null ? locationA["timezone"] : "") !== (locationB["timezone"] !== null ? locationB["timezone"] : "")) return true;
            if ((locationA["image"] !== null ? locationA["image"] : "") !== (locationB["image"] !== null ? locationB["image"] : "")) return true;
            if ((locationA["pos_lat"] !== null ? locationA["pos_lat"] : "") !== (locationB["pos_lat"] !== null ? locationB["pos_lat"] : "")) return true;
            if ((locationA["pos_lng"] !== null ? locationA["pos_lng"] : "") !== (locationB["pos_lng"] !== null ? locationB["pos_lng"] : "")) return true;
            if ((locationA["pos_alt"] !== null ? locationA["pos_alt"] : "") !== (locationB["pos_alt"] !== null ? locationB["pos_alt"] : "")) return true;

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalLocationChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalLocationChange = function () {
            var currLocation = angular.copy(ctrl.location);
            if (ctrl.compareLocationValues(initLocation, currLocation)) return true
        };

        ctrl.isChanged = function ()  {
            if (ctrl.evalLocationChange()) {
                LocationDetailService.canChange = false;
                LocationDetailService.onlyActiveTab = 0;
            } else {
                LocationDetailService.canChange = true;
                LocationDetailService.onlyActiveTab = null;
            }
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!ignoreNextStateChange && ctrl.evalLocationChange() && toState.name !== "location.overview") {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('location.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('location.modal.empty.yes'),
                    textButton0: $translate.instant('location.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initLocation = null;
                        LocationDetailService.canChange = true;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });
    }

    angular
        .module('emsv2App')
        .controller('overviewController', overviewController);

})();


