(function () {

    'use strict';

    // Controller für das Editiermenü einer einzelnen Eskalationsstufe
    function escalationLevelEditController($log, $scope, $state, $window, $translate, $stateParams, EscalationLevelService, escalationLevel,
                                           Notify, LocationDetailService, Tools, GenDialogService, Contacts, SmtpSMSServerConfigurationService) {
        var ctrl = this;
        var escalationLevelSaved = false;

        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.addEscalationForm = {};
        ctrl.contacts = [];
        ctrl.canSave = false;
        ctrl.editMode = $stateParams.mode === "edit";
        ctrl.smsGatewayConfigured = false;
        ctrl.isLoadedEscalationLevel = escalationLevel != null ? true : false;

        ctrl.escalationLevel = new EscalationLevel(escalationLevel);
        var initEscalationLevel = new EscalationLevel(ctrl.escalationLevel);

        ctrl.$onInit = function () {
            Contacts.getAll().then(function (response) {
                ctrl.contacts = response.data;
            });
            SmtpSMSServerConfigurationService.get().then(function (response) {
                if (response.data !== undefined || response.data !== "") {
                    ctrl.smsGatewayConfigured = true;
                }
            });
            LocationDetailService.reset();
            LocationDetailService.onBack = function () {
                $state.go("location.alarm.escalationlevel.list");
            };
            if (ctrl.editMode) {
                LocationDetailService.onSave = ctrl.saveEscalation;
                LocationDetailService.canSave = function () {
                    return ctrl.canSave;
                };
            } else {
                LocationDetailService.onEdit = function () {
                    $state.go($state.current, {mode: 'edit'}, {reload: true});
                };
            }
            ctrl.checkForMobile(ctrl.escalationLevel.contacts);
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalELChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalELChange = function () {
            if (EscalationLevel.compareValues(initEscalationLevel, ctrl.escalationLevel)) return true;
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (ctrl.evalELChange() && !ignoreNextStateChange && !escalationLevelSaved) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('location.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('location.modal.empty.yes'),
                    textButton0: $translate.instant('location.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        ctrl.checkForMobile = function (contacts) {
            ctrl.escalationLevel = EscalationLevel.checkForMobile(ctrl.escalationLevel, contacts);
            if (ctrl.escalationLevel.contacts.length === 0) {
                LocationDetailService.canSave = function () {
                    if(ctrl.escalationLevel.isSendMail === true || ctrl.escalationLevel.isSendSms === true){
                        return ctrl.escalationLevel.contacts.length !== 0;
                    }
                }
            }
        };

        ctrl.saveEscalation = function () {
            if (ctrl.escalationLevel.isSendMail && !ctrl.escalationLevel.contacts.length) {
                Notify.error("location.alarms.noRecipentsError.title", "location.alarms.noRecipentsError.text", 3000);
            } else {
                escalationLevelSaved = true;
                ctrl.escalationLevel.locationId = $stateParams.id;
                EscalationLevelService.saveEscalation(ctrl.escalationLevel).then(function () {
                    Notify.defaultSuccess();
                    $state.go("location.alarm.escalationlevel.list");
                }, function () {
                    Notify.error("global.notification.error.title", "global.notification.error.text", 3000);
                });
            }
        };

        ctrl.updateTables = function () {
            $scope.$broadcast('rsqltable-refresh');
        };

        $scope.$watch(function () {
                return ctrl.escalationLevel;
            },
            function () {
                var isChanged = false;
                LocationDetailService.canChange = true;
                LocationDetailService.onlyActiveTab = null;
                // geöffnetes Element
                if (ctrl.isLoadedEscalationLevel) {
                     if(ctrl.escalationLevel.contacts.length !==0 &&
                        (ctrl.escalationLevel.isSendMail === true || ctrl.escalationLevel.isSendSms === true) ||
                        (ctrl.escalationLevel.isSendMail === true && ctrl.escalationLevel.isSendMail !== true) ||
                        (ctrl.escalationLevel.isSendMail !== true && ctrl.escalationLevel.isSendSms === true)){
                        isChanged = true;
                        LocationDetailService.canChange = false;
                        LocationDetailService.onlyActiveTab = -1;
                        LocationDetailService.alarmsOnlyActiveTab = 1;
                    }
                    else{
                        isChanged = false;
                    }

                }
                // neues Element
                else {
                    if (ctrl.addEscalationForm.$dirty || ctrl.addEscalationForm.$invalid) {
                        isChanged = true;
                        LocationDetailService.canChange = false;
                        LocationDetailService.onlyActiveTab = -1;
                        LocationDetailService.alarmsOnlyActiveTab = 1;
                    }
                }
                ctrl.canSave = isChanged;
            }, true
        );
    }

    angular.module('emsv2App')
        .controller('escalationLevelEditController', escalationLevelEditController);
})();
