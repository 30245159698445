(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('PasswordForgotController', function ($rootScope, $log, $scope, $timeout, Auth) {
            var ctrl = this;
            ctrl.email = "";
            ctrl.username = "";
            ctrl.wasSend = false;
            ctrl.error = false;
            ctrl.smtperror = false;
            ctrl.state = 'init';

            ctrl.send = function () {
                ctrl.error = false;
                ctrl.state = 'load';
                var isEmail = ctrl.email !== '';
                Auth.forgotPassword(isEmail ? ctrl.email : ctrl.username, isEmail).then(function () {
                    ctrl.wasSend = true;
                    ctrl.state = 'success';
                }, function (e) {
                    if (e.status === 503) {
                        ctrl.smtperror = true;
                    } else {
                        ctrl.error = true;
                    }
                    ctrl.state = 'error';
                    $log.error("Forget Password request failed: ", e);
                    $timeout(function () {
                        ctrl.state = 'init';
                    }, 2000);
                });
            }
        });

})();
