(function () {

    'use strict';

    function ErrorHistoryTabController($log, Alarm, currentLocation, LocationDetailService) {
        var ctrl = this;

        ctrl.data = [];
        ctrl.query = '';
        ctrl.page = 0;
        ctrl.itemsPerPage = 50;
        ctrl.doQuery = function () {
            return _query(false);
        };
        ctrl.hasMore = true;
        ctrl.tableModel = {
            orderBy: 'stamp',
            orderDir: false,
            tableName: 'errorlog',
            translation: 'alarm.rsql',
            head: [
                {name: 'stamp', translation: 'location.errorlog.time'},
                {name: 'error_code', translation: 'location.errorlog.errorCode'},
                // {name: 'device_name', translation: 'location.errorlog.device', sortable: true},
                {name: 'hash_text', translation: 'location.errorlog.text', sortable: false}
            ]
        };

        ctrl.$onInit = function () {
            LocationDetailService.reset().setDefaultBackToOverview();
        };

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderBy = sortField;
            ctrl.tableModel.orderDir = sortReverse;
            _query(true);
        };

        ctrl.doFilter = function (params) {
            ctrl.query = params;
            _query(true);
        };

        function _query(reset) {
            ctrl.page = reset ? 0 : ctrl.page + 1;

            var query = ctrl.query;
            if (query.length && query.indexOf('?') !== 0) {
                query = '/' + query;
            }

            return Alarm.errorLogRsql(currentLocation.id, ctrl.page, query, ctrl.itemsPerPage + 1).then(function (response) {
                ctrl.hasMore = response.data.length > ctrl.itemsPerPage;
                var count = response.data.length > ctrl.itemsPerPage ? ctrl.itemsPerPage : response.data.length;
                if (!reset) {
                    for (var i = 0; i < count; i++) {
                        ctrl.data.push(response.data[i]);
                    }
                }
                else {
                    ctrl.data = response.data.splice(0, ctrl.itemsPerPage);
                }
            }, function (error) {
                $log.error('Couldn\'t load errorlogs!', error);
                ctrl.data = [];
            });
        }

        function getSorting() {
            return 'orderby=' + ctrl.tableModel.orderBy
                + '&direction=' + (ctrl.tableModel.orderBy ? (ctrl.tableModel.orderDir ? 'asc' : 'desc') : '');
        }
    }

    angular
        .module('emsv2App')
        .controller('ErrorHistoryTabController', ErrorHistoryTabController);

})();
