(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('register', {
                    parent: 'account',
                    url: '/register',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'register.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/register/register.html',
                            controller: 'RegisterController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('register');
                            $translatePartialLoader.addPart('legal');
                            return $translate.refresh();
                        }],
                        countries: ['$log', 'Country', function ($log, Country) {
                            return Country.getAll().then(function (countries) {
                                return countries;
                            }, function (error) {
                                $log.error("Couldn't load countries!", error);
                                return [];
                            });
                        }],
                        canRegister: ['$state', '$log', 'Auth', function ($state, $log, Auth) {
                            return Auth.registerAllowed().then(function (result) {
                                if (result.data !== true) {
                                    $state.go('login');
                                }
                            }, function (error) {
                                $log.error('Couldn\'t retrive registration info!', error);
                                return false;
                            });
                        }]
                    }
                }).state('register.defaultadmin', {
                    parent: 'register',
                    url: '/defaultadmin',
                    params: {defaultAdminAvailable:true},
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'register.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/register/register.html',
                            controller: 'RegisterController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('register');
                            $translatePartialLoader.addPart('legal');
                            return $translate.refresh();
                        }],
                        countries: ['$log', 'Country', function ($log, Country) {
                            return Country.getAll().then(function (countries) {
                                return countries;
                            }, function (error) {
                                $log.error("Couldn't load countries!", error);
                                return [];
                            });
                        }],
                        adminCheck: ['$state', '$stateParams', function ($state, $stateParams) {
                            return $stateParams.defaultAdminAvailable;
                        }]
                    }
                });
        });

})();
