(function () {

    'use strict';

    var isLicensed = function (license, module) {
        if (license !== null
            && license !== undefined
            && license.data !== null
            && license.data !== undefined) {
            if (module.indexOf('*') !== -1) {
                var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                var keys = Object.keys(license.data);
                for (var i in keys) {
                    if (regex.test(keys[i]) && keys[i]) return true;
                }
            }
            else if (license.data[module] !== null) {
                return license.data[module];
            }
        }
        return false;
    };

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('parameters', {
                    parent: 'admin',
                    url: '/parameters',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'parameters.title',
                        license: '3droomedit'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/parameters/parameter_template.html',
                            controller: 'parameterController',
                            controllerAs: 'pctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('rsqltable');
                            $translatePartialLoader.addPart('parameters');
                            return $translate.refresh();
                        }],
                        parameters: ['$log', 'Parameters', function ($log, Parameters) {
                            return Parameters.getAll().then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error("Error retrieving parameters\n" + error);
                                return error;
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                            return Principal.identity().then(function (user) {
                                $log.debug('Loaded userInfo: ', user);
                                if (user.hasRole('ROLE_LOCATION_EDIT') || user.hasRole('ROLE_LOCATION_CREATE')) {
                                    License.get().then(function (response) {
                                        var license = response.data;
                                        if (isLicensed(license, '3droomedit')) {
                                            return user;
                                        }
                                        else {
                                            $state.go("accessdenied");
                                        }
                                    }, function (error) {
                                        $log.error('Couldn\'t load license!', error);
                                    });
                                }
                                else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error("Couldn't load user ", error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('parameters.view', {
                    parent: 'parameters',
                    url: '/view/{parameterId}',
                    data: {
                        roles: ['ROLE_LOCATION_VIEW'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'parameters.titleView'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/parameters/create.edit/create.edit.parameter.html',
                            controller: 'CreateEditParamController',
                            controllerAs: 'pctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('parameters');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('parameters.edit', {
                    parent: 'parameters',
                    url: '/edit/{parameterId}',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'parameters.titleEdit'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/parameters/create.edit/create.edit.parameter.html',
                            controller: 'CreateEditParamController',
                            controllerAs: 'pctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('parameters');
                            $translatePartialLoader.addPart('location');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('parameters.create', {
                    parent: 'parameters',
                    url: '/create/{parameterId}',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'parameters.titleCreate'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/parameters/create.edit/create.edit.parameter.html',
                            controller: 'CreateEditParamController',
                            controllerAs: 'pctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('parameters');
                            $translatePartialLoader.addPart('location');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
        });

})();
