(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('ReportOverviewController', ['$scope','$log','$interval', 'notify','$uibModal', 'Report', 'Tools', 'canCreate', 'user', 'isAdmin', 'cache', 'License', 'GenDialogService', '$state', '$translate', '$timeout', 'Notify',
            function ($scope, $log, $interval, notify,$uibModal, Report, Tools, canCreate, user, isAdmin, cache, License, GenDialogService, $state, $translate, $timeout, Notify) {
                var ctrl = this;
                ctrl.moment = function (date) {
                    return moment(date);
                };
                ctrl.canCreate = canCreate;
                ctrl.user = user;
                ctrl.isAdmin = isAdmin;
                ctrl.page = 0;
                ctrl.doQuery = function () {
                    return _query(false);
                };
                ctrl.hasMore = true;
                ctrl.data = [];
                ctrl.cache = cache;
                ctrl.query = '';
                ctrl.tableModel = {
                    tableName: 'report',
                    translation: 'reports.overview.table',
                    orderBy: 'id',
                    orderDir: false,
                    doFilter: _doFilter,
                    head: [
                        {name: 'checkreport', translation: 'reports.overview.table.checkreport'},
                        {name: 'title', translation: 'reports.overview.table.title'},
                        {name: 'format', translation: 'reports.overview.table.format', sortable: false},
                        {name: 'orientation', translation: 'reports.overview.table.orientation', sortable: false},
                        {name: 'timers', translation: 'reports.overview.table.timers', sortable: false},
                        {empty: true}
                    ]
                };
                var currentInterval = null;

                ctrl.limitReached = false;
                ctrl.deletionInProgress = false;
                ctrl.generateInProgress = false;

                ctrl.$onInit = function () {
                    ctrl.LicenseExpired = false;
                    ctrl.isFreeVersion = false;

                    License.getExpired().then(function (response) {
                        ctrl.LicenseExpired = response.data;
                    });
                    License.getIsFreeVersion().then(function (response) {
                        if (response.data === "freeVersion") {
                            ctrl.isFreeVersion = true;
                        } else {
                            ctrl.isFreeVersion = false;
                        }
                    });

                    for (var key in ctrl.cache) {
                        if (ctrl.cache.hasOwnProperty(key)) {
                            if (currentInterval === null && (ctrl.cache[key].filename === null || ctrl.cache[key].filename === undefined)) {
                                currentInterval = $interval(_checkReportCaches, 5000);
                            }
                        }
                    }
                };

                function getReportById(id) {
                    return ctrl.data.find(function (el) {
                        return el.id === id;
                    });
                }

                function _checkReportCaches() {
                    for (var key in ctrl.cache) {
                        if (ctrl.cache.hasOwnProperty(key)) {
                            var job = ctrl.cache[key];
                            if (job.filename === null || job.filename === undefined) {
                                _checkReport(job.fkReport);
                            }
                        }
                    }
                    _checkStopInterval();
                }

                function _checkReport(reportId) {
                    Report.check(reportId).then(function (response) {
                        var cache = response.data;
                        if (cache.value1) {
                            var fjob = cache.value2;
                            ctrl.cache[fjob.fkReport] = fjob;
                            var report = getReportById(fjob.fkReport);
                            if (report) {
                                notify({
                                    messageTemplate: '<strong translate="reports.notification.title"></strong><br>' +
                                        '<span translate="reports.notification.finished" translate-values="{value: \'' + report.title + '\'}"></span>' +
                                        '<br><a ui-sref="reports" ><span>&raquo;</span> <span translate="reports.notification.gotoreports"></span></a>',
                                    classes: 'alert-success',
                                    duration: 5000
                                });
                                _checkTimestamps();
                                ctrl.generateInProgress = false;
                            }
                        }
                    }, function (error) {
                        $log.error('Couldn\'t check status of report ' + reportId, error);
                    });
                }

                function _checkStopInterval() {
                    var canStop = true;
                    for (var key in ctrl.cache) {
                        if (ctrl.cache.hasOwnProperty(key)) {
                            if (ctrl.cache[key].filename === null || ctrl.cache[key].filename === undefined) {
                                canStop = false;
                            }
                        }
                    }
                    if (canStop) {
                        $interval.cancel(currentInterval);
                        currentInterval = null;
                    }
                }

                function _doFilter(params) {
                    ctrl.query = params;
                    _query(true);
                }

                function _query(reset) {
                    var query = ctrl.query;
                    ctrl.page = reset ? 0 : ctrl.page + 1;

                    return Report.rsql(ctrl.page, query).then(function (response) {
                        ctrl.hasMore = response.data.length === 20;
                        if (!reset) {
                            for (var i = 0; i < response.data.length; i++) {
                                ctrl.data.push(response.data[i]);
                            }
                            ctrl.checkLicense();
                        } else {
                            ctrl.data = response.data;
                            ctrl.checkLicense();
                        }
                        _checkTimestamps();
                    }, function (error) {
                        $log.error("Couldn't load reports!", error);
                        ctrl.data = [];
                    });
                }

                function _checkTimestamps() {
                    for (var idx in ctrl.data) {
                        if (ctrl.data.hasOwnProperty(idx)) {
                            var editTS = moment(ctrl.data[idx].stamp || 0);
                            if (Tools.isDefinedNotNull(ctrl.cache[ctrl.data[idx].id])) {
                                var genTS = moment(ctrl.cache[ctrl.data[idx].id].generatedOn)
                            }
                            if (!isNaN(genTS)) {
                                ctrl.data[idx].downloadable = editTS <= genTS;
                            }
                        }
                    }
                }

                /**
                 * @description function to check license
                 */

                ctrl.checkLicense = function () {
                    if (ctrl.isFreeVersion) {
                        if (ctrl.data.length >= 1) {
                            ctrl.limitReached = true;
                            setTimeout(function () {
                                var button = document.getElementById("addReportButton");
                                var tooltip = $translate.instant('global.btn.freeVersion');
                                button.setAttribute("title", tooltip);
                            }, 300);
                        }
                    }
                }

                ctrl.generate = function (id) {
                    ctrl.generateInProgress = true;
                    Report.generate(id).then(function (response) {
                        ctrl.cache[response.data.fkReport] = response.data;
                        if (currentInterval === null || currentInterval === undefined) {
                            currentInterval = $interval(_checkReportCaches, 5000);
                        }
                    }, function (error) {
                        $log.error('Error submitting report job!', error);
                    });
                };


                ctrl.checkAll = function () {
                    angular.forEach(ctrl.data, function (x) {
                        x.isChecked = ctrl.selectAll;
                    });
                };

                ctrl.checkedCount = function () {
                    return ctrl.data.filter(function (report) {
                        return report.isChecked;
                    }).length;
                };

                ctrl.deleteSelectionModal = function () {
                    $translate(['reports.overview.delselection', 'reports.overview.action.delSelectQuest', 'global.btn.cancel', 'global.btn.delete']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['reports.overview.delselection'],
                            // messageText: $translate.instant('analysis.action.askDelete',{value: analysisName}),
                            messageText: trans['reports.overview.action.delSelectQuest'],
                            showClose: false,
                            textButton0: trans['global.btn.cancel'],
                            iconButton0: 'glyphicon glyphicon-remove',
                            classButton0: 'btn-close',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            },
                            textButton1: trans['global.btn.delete'],
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            classButton1: 'btn-delete',
                            callbackButton1: function () {
                                GenDialogService.hideDialog();
                                var idList = [];
                                for (var i = 0; i < ctrl.data.length; i++) {
                                    if (ctrl.data[i].isChecked) {
                                        idList.push(ctrl.data[i].id);
                                    }
                                }
                                _delete(idList);
                            }
                        });
                    })
                };
                ctrl.deleteReport = function (report) {
                    $translate(['reports.overview.delreport', 'reports.overview.delreportmessage', 'global.btn.cancel', 'global.btn.delete']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['reports.overview.delreport'],
                            // messageText: $translate.instant('analysis.action.askDelete',{value: analysisName}),
                            messageText: trans['reports.overview.delreportmessage']+` ${report.title} ?`,
                            showClose: false,
                            textButton0: trans['global.btn.cancel'],
                            iconButton0: 'glyphicon glyphicon-remove',
                            classButton0: 'btn-close',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            },
                            textButton1: trans['global.btn.delete'],
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            classButton1: 'btn-delete',
                            callbackButton1: function () {

                                GenDialogService.hideDialog();
                                ctrl.deletedParam = report
                                ctrl.delete(ctrl.deletedParam);
                            }
                        });
                    })
                };
                ctrl.delete = function (data) {
                    ctrl.deletedParam = data;
                    ctrl.deletionInProgress = true;
                    if (ctrl.deletedParam !== null) {
                        Report.delete(ctrl.deletedParam.id).then(function (response) {
                            // $timeout(function () {
                                _query(true);
                                Notify.success("global.notification.success.title", "reports.notification.success.deleted", 3000);
                                ctrl.selectAll = false;
                                ctrl.deletionInProgress = false;
                                $state.go('reports', {}, {reload: true});
                            // });
                        }, function (error) {
                            // $timeout(function () {
                                Notify.error("global.notification.error.title", "reports.notification.error.deleted", 3000);
                                ctrl.deletionInProgress = false;
                            // });
                            $log.error('Couldn\'t delete current selection of reports', error);                        })
                    }
                };

                function _delete(idList) {
                    ctrl.deletionInProgress = true;
                    Report.deleteSelection(idList).then(function () {
                        $timeout(function () {
                            _query(true);
                            Notify.success("global.notification.success.title", "reports.notification.success.deleted", 3000);
                            ctrl.selectAll = false;
                            ctrl.deletionInProgress = false;
                            $state.go('reports', {}, {reload: true});
                        }, 3000);
                    }, function (error) {
                        $timeout(function () {
                            Notify.error("global.notification.error.title", "reports.notification.error.deleted", 3000);
                            ctrl.deletionInProgress = false;
                        }, 3000);
                        $log.error('Couldn\'t delete current selection of reports', error);
                    })
                };

                ctrl.download = function (id) {

                    var cached = ctrl.cache[id];
                    $('<iframe/>').attr({
                        src: 'api/reports/download/' + cached.id,
                        style: 'visibility:hidden;display:none'
                    }).appendTo($('#download-report'));
                };

                ctrl.getShedulerType = function (sh) {
                    if (sh === null || sh.length === 0) {
                        return 'reports.settings.disabled';
                    }
                    if (sh[0].repeatValue) {
                        return 'reports.scheduler.repeat';
                    } else {
                        return 'reports.scheduler.unique';
                    }
                };

                ctrl.isTemplate = function (report) {
                    return !Tools.isDefinedNotNull(report.fkUser);
                };
            }
        ]);

})();