(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name HwTyp
     * @requires $http, $q
     */
    angular.module('emsv2App')
        .service('HwTyp', function ($http, $q) {
            var types = null;
            var typesMapped = null;

            return {
                getAll: function () {
                    if (!types) {
                        return $http.get('api/hwtyp').then(function (response) {
                            types = response;
                            return response;
                        });
                    }
                    else {
                        return $q.when(types);
                    }
                },
                getAllMapped: function () {
                    if (!typesMapped) {
                        return $http.get('api/hwtyp/mapped').then(function (response) {
                            typesMapped = response;
                            return response;
                        });
                    }
                    else {
                        return $q.when(typesMapped);
                    }
                }
            };
        });

})();
