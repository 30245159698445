'use strict';

/**
 * @description Constructor for room object
 * @param {number} id id of the room object
 * @param {object} pos 3-dimensional vector object describing the rooms position in global space
 * @param {object} size 3-dimensional vector object describing the rooms overall size property (y-component for room height)
 * @param {object} rot 3-dimensional vector object describing the rooms rotation
 * @param {string} name the rooms name
 * @param {string} comment the comment for the room
 * @param {number} floor floor number for this room, negative values for underground floors, positive values for above ground floor levels, 0 ground level
 * @param {number} wallThickness the depth of all outer walls
 * @param {Object} roomConfigurations object of roomConfigurations
 * @param {Array} corner array of 3-dimensional points for corners
 * @param {Array} roomObjs array of room objects to set for this room
 * @param {Array} racks array of rack objects to set for this room
 * @param {Array} coolings array of cooling unit objects for this room
 * @param {Array} assets array of asset objects for this room
 * @param {Array} sensors array of sensor objects for this room
 * @param {FloorPanel} floorPanel floor panel object to set for this room
 * @param {Array} floorTiles array of floor tile objects
 * @param {number} uid the unique id to set
 * @param {number} locationId the location id to set
 * @param {number} buildingId the building id to set
 * @param {String} json the room object in string
 * @constructor
 */
function Room(id, pos, size, rot, name, comment, floor, wallThickness, corner, roomObjs, roomConfigurations, racks, coolings, assets, sensors, floorPanel, floorTiles, uid, locationId, buildingId, json) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.corner = (corner !== undefined && corner instanceof Array) ? corner : [];
    this.floor = floor !== undefined ? Math.abs(floor) : 0;
    this.floorType = floor === undefined ? 0 : floor < 0 ? -1 : floor === 0 ? 0 : 1;
    this.floorValue = floor;
    this.thickness = wallThickness !== undefined ? wallThickness : 0.1;
    this.locationId = locationId !== undefined ? locationId : -1;

    this.innerWalls = [];
    this.outerWalls = [];
    this.roomConfigurations = new RoomConfiguration(roomConfigurations);

    this.floorPanel = floorPanel !== undefined ? floorPanel : null;

    this.usesRaisedFloorIdents = !(this.floorPanel === null);

    this.roomObjs = (roomObjs !== undefined && roomObjs instanceof Array) ? roomObjs : [];
    this.racks = (racks !== undefined && racks instanceof Array) ? racks : [];
    this.coolings = (coolings !== undefined && coolings instanceof Array) ? coolings : [];
    this.assets = (assets !== undefined && assets instanceof Array) ? assets : [];
    this.sensors = (sensors !== undefined && sensors instanceof Array) ? sensors : [];
    this.floorTiles = (floorTiles !== undefined && floorTiles instanceof Array) ? floorTiles : [];

    //TODO handle this
    this.ventducts = [];
    this.ups = [];

    this.raisedFloor = null;
    this.isRoom = true;

    this.buildingId = buildingId !== undefined ? buildingId : null;
    this.driverValueGroups = [];
    this.json = json;
}

Room.prototype = Object.create(NamedEntity.prototype);
Room.prototype.constructor = Room;

/**
 * @description Function to check equality of this room with the provided object
 * @param {object} cr object to compare this room with
 * @returns {boolean} returns true if provided object equals this room, otherwise false
 */
Room.prototype.equals = function (cr) {
    if (!(cr instanceof Room)) return false;
    if (!this.equalsNamedEntity(cr)) return false;

    if (this.locationId !== cr.locationId) return false;
    if (this.thickness !== cr.thickness) return false;
    if (this.corner.length !== cr.corner.length) return false;
    for (var i = 0; i < this.corner.length; i++) {
        if (this.corner[i].x !== cr.corner[i].x || this.corner[i].y !== cr.corner[i].y || this.corner[i].z !== cr.corner[i].z) return false;
    }
    if (this.outerWalls.length !== cr.outerWalls.length) return false;
    for (var j = 0; j < this.outerWalls.length; j++) {
        if (this.outerWalls[j].x !== cr.outerWalls[j].x || this.outerWalls[j].y !== cr.outerWalls[j].y || this.outerWalls[j].z !== cr.outerWalls[j].z) return false;
    }

    var compareArray = function (tarr, carr) {
        if (tarr.length !== carr.length) return false;
        for (var i = 0; i < tarr.length; i++) {
            var to = tarr[i];
            var co = carr.filter(function (o) {
                return o.id === to.id;
            })[0];
            if (!co) return false;
            if (!to.equals(co)) return false;
        }
        return true;
    };

    if (!compareArray(this.innerWalls, cr.innerWalls)) return false;
    if (!compareArray(this.assets, cr.assets)) return false;
    if (!compareArray(this.coolings, cr.coolings)) return false;
    if (!compareArray(this.floorTiles, cr.floorTiles)) return false;
    if (!compareArray(this.sensors, cr.sensors)) return false;
    if (!compareArray(this.racks, cr.racks)) return false;
    if (!compareArray(this.ups, cr.ups)) return false;
    if (!compareArray(this.roomObjs, cr.roomObjs)) return false;

    return true;
};
/**
 * @description Function to check if the room is empty
 * @returns {boolean} true if the room does not contain any entities which are able to contain driver values.
 */
Room.prototype.isEmpty = function () {
    if (this.racks.length !== 0) return false;
    if (this.assets.length !== 0) return false;
    if (this.sensors.length !== 0) return false;
    if (this.floorTiles.length !== 0) return false;
    if (this.coolings.length !== 0) return false;
    return this.ups.length == 0;
};

/**
 * @description Function to check this room corner order
 * @returns {number} returns 1 if corners are ordered counter-clockwise, -1 if corners are ordered clockwise, 0 if less than 3 corners or invalid polygon
 */
Room.prototype.checkCornerOrder = function () {
    return Room.checkCornerOrder(this.corner);
};

/**
 * @description Function to get corner points in defined dimension
 * @param {number} dim the dimension to create points for 2/3
 * @returns {Array} returns array of points of specified dimension
 */
Room.prototype.getCornerPoints = function (dim) {
    var ret = [];
    for (var i = 0; i < this.corner.length; i++) {
        if (dim === 2) ret.push({x: this.corner[i].x, y: this.corner[i].z});
        if (dim === 3) ret.push({x: this.corner[i].x, y: 0, z: this.corner[i].z});
    }
    return ret;
};

/**
 * @description Function to get corner points from this rooms outer wall array
 * @param {number} dim the dimension to create points (2,3)
 * @returns {Array} returns array of 2/3-dimensional corner points
 */
Room.prototype.getCornersFromOuterWalls = function (dim) {
    var ret = [];
    for (var i = 0; i < this.outerWalls.length; i++) {
        if (dim === 2) ret.push({x: this.outerWalls[i].start.x, y: this.outerWalls[i].start.z});
        if (dim === 3) ret.push({x: this.outerWalls[i].start.x, y: 0, z: this.outerWalls[i].start.z});
    }
    return ret;
};

/**
 * @description Function to get the raised floor object of this room
 * @returns {*} returns raised floor object if possible, otherwise null
 */
Room.prototype.getRaisedFloor = function () {
    if (this.raisedFloor) return this.raisedFloor;
    for (var i = 0; i < this.roomObjs.length; i++) {
        if (RaisedFloor.isRaisedFloor(this.roomObjs[i].type)) {
            this.raisedFloor = this.roomObjs[i];
            return this.roomObjs[i];
        }
    }
    return null;
};

/**
 * @description Function to get doors of this for a wall
 * @param {object} wall wall object to find doors for
 * @returns {Array} returns array with doors for provided wall
 */
Room.prototype.getDoorsForWall = function (wall) {
    var w = {s: null, e: null};
    if (wall instanceof THREE.Object3D) {
        w.s = wall.userData.s;
        w.e = wall.userData.e;
    } else if (wall instanceof InnerWall || wall instanceof OuterWall || (wall instanceof Object && wall.hasOwnProperty("start") && wall.hasOwnProperty("end"))) {
        w.s = wall.start.clone();
        w.e = wall.end.clone();
    } else {
        w = wall;
    }
    var ret = [];
    for (var i = 0; i < this.roomObjs.length; i++) {
        if (Door.isDoor(this.roomObjs[i].type)) {
            if (Room.checkPointOnWall(w.s, w.e, new THREE.Vector3(this.roomObjs[i].pos.x, this.roomObjs[i].pos.y, this.roomObjs[i].pos.z))) ret.push(this.roomObjs[i]);
        }
    }
    return ret;
};

/**
 * @description Function to get windows of this for a wall
 * @param {object} wall wall object to find windows for
 * @returns {Array} returns array with windows for provided wall
 */
Room.prototype.getWindowsForWall = function (wall) {
    var w = {s: null, e: null};
    if (wall instanceof THREE.Object3D) {
        w.s = wall.userData.s;
        w.e = wall.userData.e;
    } else if (wall instanceof InnerWall || wall instanceof OuterWall || (wall instanceof Object && wall.hasOwnProperty("start") && wall.hasOwnProperty("end"))) {
        w.s = wall.start.clone();
        w.e = wall.end.clone();
    } else {
        w = wall;
    }
    var ret = [];
    for (var i = 0; i < this.roomObjs.length; i++) {
        if (Window.isWindow(this.roomObjs[i].type)) {
            if (Room.checkPointOnWall(w.s, w.e, new THREE.Vector3(this.roomObjs[i].pos.x, this.roomObjs[i].pos.y, this.roomObjs[i].pos.z))) ret.push(this.roomObjs[i]);
        }
    }
    return ret;
};

/**
 * @description Function to retrieve all windows and doors located on outer walls of this room
 * @returns {Array} returns array of found windows/doors
 */
Room.prototype.getOuterWallObjects = function () {
    var ret = [];
    for (var i = 0; i < this.roomObjs.length; i++) {
        if (Window.isWindow(this.roomObjs[i].type) || Door.isDoor(this.roomObjs[i].type)) {
            if (this.checkPointOnOuterWall(new THREE.Vector3(this.roomObjs[i].pos.x, this.roomObjs[i].pos.y, this.roomObjs[i].pos.z))) {
                ret.push(this.roomObjs[i]);
            }
        }
    }
    return ret;
};

/**
 * @description Function to test if provided point is located on a outer wall
 * @param {THREE.Vector3} point the point to run test for
 * @returns {boolean} returns true if point is located on a outer wall
 */
Room.prototype.checkPointOnOuterWall = function (point) {
    for (var i = 0; i < this.outerWalls.length; i++) {
        if (Room.checkPointOnWall(this.outerWalls[i].start, this.outerWalls[i].end, point)) return true;
    }
    return false;
};

/**
 * @description Function to test if provided point is located on a inner wall
 * @param {THREE.Vector3} point the point to run test for
 * @returns {boolean} returns true if point is located on inner wall
 */
Room.prototype.checkPointOnInnerWall = function (point) {
    for (var i = 0; i < this.innerWalls.length; i++) {
        if (Room.checkPointOnWall(this.innerWalls[i].start, this.innerWalls[i].end, point)) return true;
    }
    return false;
};

/**
 * @description Function to check if provided OBB is inside this rooms bounding box
 * @param {OBB} obb the obb to test
 * @param {boolean} ignoreY if set to true, y-values(height) will be ignored
 * @returns {boolean=} returns true if obb is inside this rooms bounding box, otherwise false
 */
Room.prototype.checkOBBInRoom = function (obb, ignoreY) {
    if (!ignoreY && obb.c.y + obb.e.y > this.size.y) return false;
    if (!ignoreY && obb.c.y - obb.e.y < 0) return false;
    return Room.checkPointInPolygon(obb.c.clone(), this.corner);
};

/**
 * @description Function to find a object defined by its unique id
 * @param {number} uid the unique id of the object to search for
 * @returns {*} returns object if found, otherwise null
 */
Room.prototype.findObjectByUniqueID = function (uid) {
    uid = parseInt(uid);
    if (uid === this.uniqueId) return this;
    var searchResult, d;
    var searchArray = function (arr) {
        for (var i = 0; i < arr.length; i++) {

            if (arr[i].uniqueId === uid) {
                return arr[i];
            }
            if (arr[i].driverValues && arr[i].driverValues.length > 0) {
                for (var dv = 0; dv < arr[i].driverValues.length; dv++) {
                    if (arr[i].driverValues[dv] !== undefined &&
                        arr[i].driverValues[dv].uniqueId !== undefined &&
                        arr[i].driverValues[dv].uniqueId === uid) return arr[i].driverValues[dv];
                }
            }
        }
        return null;
    };

    searchResult = searchArray(this.assets);
    if (searchResult !== null) return searchResult;

    searchResult = searchArray(this.sensors);
    if (searchResult !== null) return searchResult;

    searchResult = searchArray(this.coolings);
    if (searchResult !== null) return searchResult;

    searchResult = searchArray(this.roomObjs);
    if (searchResult !== null) return searchResult;

    searchResult = searchArray(this.floorTiles);
    if (searchResult !== null) return searchResult;

    searchResult = searchArray(this.ups);
    if (searchResult !== null) return searchResult;

    for (var i = 0; i < this.racks.length; i++) {
        if (this.racks[i].uniqueId === uid) return this.racks[i];
        for (var j = 0; j < this.racks[i].slots.length; j++) {
            if (this.racks[i].slots[j].uniqueId === uid) return this.racks[i].slots[j];
            if (this.racks[i].slots[j].driverValues) {
                for (d = 0; d < this.racks[i].slots[j].driverValues.length; d++) {
                    if (this.racks[i].slots[j].driverValues[d] !== undefined &&
                        this.racks[i].slots[j].driverValues[d].uniqueId !== undefined &&
                        this.racks[i].slots[j].driverValues[d].uniqueId === uid) return this.racks[i].slots[j].driverValues[d];
                }
            }
            if (this.racks[i].slots[j].blades) {
                for (var k = 0; k < this.racks[i].slots[j].blades.length; k++) {
                    if (this.racks[i].slots[j].blades[k].uniqueId === uid) return this.racks[i].slots[j].blades[k];
                    for (var c = 0; c < this.racks[i].slots[j].blades[k].cpus.length; c++) {
                        if (this.racks[i].slots[j].blades[k].cpus[c].uniqueId === uid) return this.racks[i].slots[j].blades[k].cpus[c];
                        for (d = 0; d < this.racks[i].slots[j].blades[k].cpus[c].driverValues.length; d++) {
                            if (this.racks[i].slots[j].blades[k].cpus[c].driverValues[d] !== undefined &&
                                this.racks[i].slots[j].blades[k].cpus[c].driverValues[d].uniqueId !== undefined &&
                                this.racks[i].slots[j].blades[k].cpus[c].driverValues[d].uniqueId === uid) return this.racks[i].slots[j].blades[k].cpus[c].driverValues[d];
                        }
                    }
                }
            }

        }
    }
    return null;
};

/**
 * @description Function to get the index of the containing array of the searched object defined by type and id
 * @param {string} type type of the object to search for
 * @param {number} id id of the object to search for
 * @returns {*} returns array with index information if object is found, otherwise empty array will be returned
 */
Room.prototype.getIndexByTypeAndID = function (type, id) {
    var ra, sl, bl, c;
    switch (type) {
        case "asset" :
            for (var a = 0; a < this.assets.length; a++) {
                if (this.assets[a].id === id) return [a];
            }
            break;
        case "blade":
            for (ra = 0; ra < this.racks.length; ra++) {
                for (sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    for (bl = 0; bl < this.racks[ra].slots[sl].blades.length; bl++) {
                        if (this.racks[ra].slots[sl].blades[bl].id === id) return [ra, sl, bl];
                    }
                }
            }
            break;
        case "cooling" :
            for (c = 0; c < this.coolings.length; c++) {
                if (this.coolings[c].id === id) return [c];
            }
            break;
        case "cpu" :
            for (ra = 0; ra < this.racks.length; ra++) {
                for (sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    for (bl = 0; bl < this.racks[ra].slots[sl].blades.length; bl++) {
                        for (c = 0; c < this.racks[ra].slots[sl].blades[bl].cpus.length; c++) {
                            if (this.racks[ra].slots[sl].blades[bl].cpus[c].id === id) return [ra, sl, bl, c];
                        }
                    }
                }
            }
            break;
        case "floortile":
            for (var ft = 0; ft < this.floorTiles.length; ft++) {
                if (this.floorTiles[ft].id === id) return [ft];
            }
            break;
        case "rack" :
            for (ra = 0; ra < this.racks.length; ra++) {
                if (this.racks[ra].id === id) return [ra];
            }
            break;
        case "sensor" :
            for (var s = 0; s < this.sensors.length; s++) {
                if (this.sensors[s].id === id) return [s];
            }
            break;
        case "slot":
            for (ra = 0; ra < this.racks.length; ra++) {
                for (sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    if (this.racks[ra].slots[sl].id === id) return [ra, sl];
                }
            }
            break;
        case "ventduct":
            for (var v = 0; v < this.ventducts.length; v++) {
                if (this.ventducts[v].id === id) return [v];
            }
            break;
        case "ups":
            for (var u = 0; u < this.ups.length; u++) {
                if (this.ups[u].id === id) return [u];
            }
            break;
    }

    return [];
};
/**
 * @description Function to find a object in this room object by its type and id
 * @param {string} type type of object
 * @param {number} id id of the object to search for
 * @returns {*} returns object if found, otherwise 'null'
 */
Room.prototype.findObjectByTypeAndID = function (type, id) {
    var ra, sl, bl, c;
    switch (type) {
        case "asset" :
            for (var a = 0; a < this.assets.length; a++) {
                if (this.assets[a].id === id) return this.assets[a];
            }
            break;
        case "blade":
            for (ra = 0; ra < this.racks.length; ra++) {
                for (sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    for (bl = 0; bl < this.racks[ra].slots[sl].blades.length; bl++) {
                        if (this.racks[ra].slots[sl].blades[bl].id === id) return this.racks[ra].slots[sl].blades[bl];
                    }
                }
            }
            break;
        case "cooling" :
            for (c = 0; c < this.coolings.length; c++) {
                if (this.coolings[c].id === id) return this.coolings[c];
            }
            break;
        case "cpu" :
            for (ra = 0; ra < this.racks.length; ra++) {
                for (sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    for (bl = 0; bl < this.racks[ra].slots[sl].blades.length; bl++) {
                        for (c = 0; c < this.racks[ra].slots[sl].blades[bl].cpus.length; c++) {
                            if (this.racks[ra].slots[sl].blades[bl].cpus[c].id === id) return this.racks[ra].slots[sl].blades[bl].cpus[c];
                        }
                    }
                }
            }
            break;
        case "floortile":
            for (var ft = 0; ft < this.floorTiles.length; ft++) {
                if (this.floorTiles[ft].id === id) return this.floorTiles[ft];
            }
            break;
        case "rack" :
            for (ra = 0; ra < this.racks.length; ra++) {
                if (this.racks[ra].id === id) return this.racks[ra];
            }
            break;
        case "sensor" :
            for (var s = 0; s < this.sensors.length; s++) {
                if (this.sensors[s].id === id) return this.sensors[s];
            }
            break;
        case "slot":
            for (ra = 0; ra < this.racks.length; ra++) {
                for (sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    if (this.racks[ra].slots[sl].id === id) return this.racks[ra].slots[sl];
                }
            }
            break;
        case "ventduct":
            for (var v = 0; v < this.ventducts.length; v++) {
                if (this.ventducts[v].id === id) return this.ventducts[v];
            }
            break;
        case "ups":
            for (var u = 0; u < this.ups.length; u++) {
                if (this.ups[u].id === id) return this.ups[u];
            }
            break;
        case "containment":
            for (var r = 0; r < this.roomObjs.length; r++) {
                if (this.roomObjs[r].id === id) return this.roomObjs[r];
            }
            break;
    }
    return null;
};

/**
 * @description Function to find a object contained in this room by type and a provided posiiton
 * @param {string} type type of object to search for
 * @param {object} pos position to find object for
 * @returns {*} returns object if found, otherwise returns 'null'
 */
Room.prototype.findObjectByTypeAndPos = function (type, pos) {
    var compareFloats = function (a, b) {
        return Math.abs(a - b) < 0.00001;
    };
    var ra;
    switch (type) {
        case "asset" :
            for (var a = 0; a < this.assets.length; a++) {
                if (compareFloats(this.assets[a].pos.x, pos.x) && compareFloats(this.assets[a].pos.y, pos.y) && compareFloats(this.assets[a].pos.z, pos.z)) return this.assets[a];
            }
            break;
        case "cooling" :
            for (var c = 0; c < this.coolings.length; c++) {
                if (compareFloats(this.coolings[c].pos.x, pos.x) && compareFloats(this.coolings[c].pos.y, pos.y) && compareFloats(this.coolings[c].pos.z, pos.z)) return this.coolings[c];
            }
            break;
        case "floortile":
            for (var ft = 0; ft < this.floorTiles.length; ft++) {
                if (compareFloats(this.floorTiles[ft].pos.x, pos.x) && compareFloats(this.floorTiles[ft].pos.y, pos.y) && compareFloats(this.floorTiles[ft].pos.z, pos.z)) return this.floorTiles[ft];
            }
            break;
        case "rack" :
            for (ra = 0; ra < this.racks.length; ra++) {
                if (compareFloats(this.racks[ra].pos.x, pos.x) && compareFloats(this.racks[ra].pos.y, pos.y) && compareFloats(this.racks[ra].pos.z, pos.z)) return this.racks[ra];
            }
            break;
        case "sensor" :
            for (var s = 0; s < this.sensors.length; s++) {
                if (compareFloats(this.sensors[s].pos.x, pos.x) && compareFloats(this.sensors[s].pos.y, pos.y) && compareFloats(this.sensors[s].pos.z, pos.z)) return this.sensors[s];
            }
            break;
        case "slot":
            for (ra = 0; ra < this.racks.length; ra++) {
                for (var sl = 0; sl < this.racks[ra].slots.length; sl++) {
                    if (compareFloats(this.racks[ra].slots[sl].pos.x, pos.x) && compareFloats(this.racks[ra].slots[sl].pos.y, pos.y) && compareFloats(this.racks[ra].slots[sl].pos.z, pos.z)) return this.racks[ra].slots[sl];
                }
            }
            break;
        case "ups":
            for (var u = 0; u < this.ups.length; u++) {
                if (compareFloats(this.ups[u].pos.x, pos.x) && compareFloats(this.ups[u].pos.y, pos.y) && compareFloats(this.ups[u].pos.z, pos.z)) return this.ups[u];
            }
            break;
    }
    return null;
};

/**
 * @description Function to setup the corner points of this room object from the provided array of wall objects
 * @param {array} walls array of wall objects to use
 */
Room.prototype.updateCornersFromWalls = function (walls) {
    if (walls) this.walls = walls;
    this.corner = [];
    for (var w = 0; w < this.walls.length; w++) {
        this.corner.push(this.walls[w].start.clone());
    }
    if (this.checkCornerOrder() === 1) {
        this.corner.reverse();
    }
};

/**
 * @description Function to setup this rooms axis aligned bounding box from its corner points
 */
Room.prototype.computeBoundingBox = function () {
    this.bbox = new THREE.Box3().setFromPoints(this.corner);
    this.bbox.max.y = this.size.y;
};

Room.prototype.computeStdFloorPanelPosition = function (object) {
    if (this.raisedFloor === null) this.raisedFloor = this.getRaisedFloor();
    var relPos = new THREE.Vector3(object.relX, object.relY, object.relZ);

    //x
    var xIdx = Math.floor(relPos.x / this.raisedFloor.size.x);
    if (this.raisedFloor.pos.x !== 0 && relPos.x > this.raisedFloor.pos.x) xIdx++;
    var xName = this.floorPanel.xNames[xIdx];

    //z
    var zIdx = Math.floor(relPos.z / this.raisedFloor.size.z);
    if (this.raisedFloor.pos.z !== 0 && relPos.z > this.raisedFloor.pos.z) zIdx++;
    var zName = this.floorPanel.yNames[zIdx];

    object.stdFloorPos = xName + " " + zName;
};

/**
 * @description Function to get aligned position for the provided point
 * @param {THREE.Vector3} relPos position to get aligned value for
 * @returns {THREE.Vector3} returns aligned position
 */
Room.prototype.computeAlignedFloorPosition = function (relPos) {
    if (this.raisedFloor == null) this.raisedFloor = this.getRaisedFloor();
    var xIdx = Math.floor(relPos.x / this.raisedFloor.size.x);
    if (this.raisedFloor.pos.x !== 0 && relPos.x > this.raisedFloor.pos.x) xIdx++;
    var zIdx = Math.floor(relPos.z / this.raisedFloor.size.z);
    if (this.raisedFloor.pos.z !== 0 && relPos.z > this.raisedFloor.pos.z) zIdx++;
    var newPos = new THREE.Vector3((xIdx * this.raisedFloor.size.x) + this.raisedFloor.size.x / 2, 0, (zIdx * this.raisedFloor.size.z) + this.raisedFloor.size.z / 2);
    return newPos;
};
/**
 * @description Function to determine if this room object has containment objects
 * @returns {boolean} return true if this room contains Containment objects, otherwise false
 */
Room.prototype.hasContainments = function () {
    for (var ro = 0; ro < this.roomObjs.length; ro++) if (Containment.isContainment(this.roomObjs[ro].type)) return true;
    return false;
};

Room.prototype.getWallPointPairs = function () {
    var walls = [];
    for (var i = 0; i < this.corner.length; i++) {
        if (i === this.corner.length - 1) {
            walls.push({
                s: new THREE.Vector3(this.corner[i].x, this.corner[i].y, this.corner[i].z),
                e: new THREE.Vector3(this.corner[0].x, this.corner[0].y, this.corner[0].z)
            });
        } else {
            walls.push({
                s: new THREE.Vector3(this.corner[i].x, this.corner[i].y, this.corner[i].z),
                e: new THREE.Vector3(this.corner[i + 1].x, this.corner[i + 1].y, this.corner[i + 1].z)
            });
        }
    }
    return walls;
};

/**
 * @description Function to delete an object from this room object
 * @param {object} object object to delete
 */
Room.prototype.deleteObject = function (object) {
    var idx, c;
    if (object instanceof Asset) {
        c = this.assets.filter(function (el) {
            return el.id === object.id;
        })[0];
        if (c) {
            idx = this.assets.indexOf(c);
            this.assets.splice(idx, 1);
        }
    }
    if (object instanceof Cooling) {
        c = this.coolings.filter(function (el) {
            return el.id === object.id;
        })[0];
        if (c) {
            idx = this.coolings.indexOf(c);
            this.coolings.splice(idx, 1);
        }
    }
    if (object instanceof Rack) {
        if (this.racks.length > 0) {
            c = this.racks.filter(function (el) {
                return el.id === object.id;
            })[0];
            if (c) {
                idx = this.racks.indexOf(c);
                this.racks.splice(idx, 1);
            }
        }
    }
    if (object instanceof Sensor) {
        c = this.sensors.filter(function (el) {
            return el.id === object.id;
        })[0];
        if (c) {
            idx = this.sensors.indexOf(c);
            this.sensors.splice(idx, 1);
        }
    }
    if (object instanceof Ups) {
        c = this.ups.filter(function (el) {
            return el.id === object.id;
        })[0];
        if (c) {
            idx = this.ups.indexOf(c);
            this.ups.splice(idx, 1);
        }
    }
    if (object instanceof FloorTile) {
        c = this.floorTiles.filter(function (el) {
            return el.id === object.id;
        })[0];
        if (c) {
            idx = this.floorTiles.indexOf(c);
            this.floorTiles.splice(idx, 1);
        }
    }
    if (object instanceof Slot) {
        var rack = this.racks.filter(function (el) {
            return el.id === object.rackId;
        })[0];
        if (rack) {
            var slot = rack.slots.filter(function (el) {
                return el.id === object.id;
            })[0];
            if (slot) {
                idx = rack.slots.indexOf(slot);
                rack.slots.splice(idx, 1);
            }
        }
    }
    if (object instanceof Cpu) {
        var blade = this.findObjectByTypeAndID("blade", object.bladeId);
        if (blade) {
            var cpu = blade.cpus.filter(function (el) {
                return el.id === object.id;
            })[0];
            if (cpu) {
                idx = blade.cpus.indexOf(cpu);
                blade.cpus.splice(idx, 1);
            }
        }
    }
    if (object instanceof Containment) {
        var con = this.findObjectByTypeAndID("containment", object.id);
        if (con) {
            idx = this.roomObjs.indexOf(con);
            this.roomObjs.splice(idx, 1);
        }
    }
};

Room.prototype.setupWalls = function () {
    for (var i = 0; i < this.corner.length; i++) {
        var next = (i + 1) % this.corner.length;
        var wall = {
            s: new THREE.Vector3(this.corner[i].x, this.corner[i].y, this.corner[i].z),
            e: new THREE.Vector3(this.corner[next].x, this.corner[next].y, this.corner[next].z)
        };
        if (this.walls === undefined) this.walls = [];
        this.walls.push(wall);
    }
};

/**
 * @description Returns true if current outer wall array describes a valid room polygon (without holes)
 * @param {boolean=} allowOrientationReorder optional if set to true, walls will be rotated to editor native state
 * @return {boolean} returns true if current outer wall array describes a valid room polygon (without holes)
 */
Room.prototype.checkOuterWallsClosed = function (allowOrientationReorder) {
    var ret = Room.checkWallsClosed(this.outerWalls);
    if (ret === false) {
        return ret;
    } else {
        if (allowOrientationReorder) {
            var pts = [];
            for (var i = 0; i < ret.length; i++) {
                pts.push(ret[i][0].clone().setY(0));
            }
            if (Room.checkCornerOrder(pts) === 1) pts.reverse();
            this.outerWalls = Room.buildOuterWallArrayFromRoomCorners({
                corner: pts,
                thickness: this.thickness,
                size: {y: this.size.y}
            });

        }
        return true;
    }
};

/**
 * @description Function to check if outerWalls intersect
 * @returns {boolean} returns true if walls intersect otherwise false
 */
Room.prototype.checkOuterWallIntersect = function () {
    for (var i = 0; i < this.outerWalls.length; i++) {
        for (var j = 0; j < this.outerWalls.length; j++) {
            if (j === i) continue;
            if (this.outerWalls[i].checkIntersection(this.outerWalls[j], true)) return true;
        }
    }
    return false;
};

/**
 * @description Function to check if polygon defined by corner order is not intersecting
 * @returns {boolean} returns true if polygon is non-intersecting, otherwise false
 */
Room.prototype.isCornerPolygonSimple = function () {
    for (var i = 0; i < this.corner.length; i++) {
        var p0 = new THREE.Vector2(this.corner[i].x, this.corner[i].z);
        var p1 = new THREE.Vector2(this.corner[(i + 1) % this.corner.length].x, this.corner[(i + 1) % this.corner.length].z);
        for (var j = 0; j < this.corner.length; j++) {
            if (j === i) continue;
            var _p0 = new THREE.Vector2(this.corner[j].x, this.corner[i].z);
            var _p1 = new THREE.Vector2(this.corner[(j + 1) % this.corner.length].x, this.corner[(j + 1) % this.corner.length].z);

            var _t0 = (p0.x - _p0.x) * (_p0.y - _p1.y) - (p0.y - _p0.y) * (_p0.x - _p1.x);
            var _t1 = (p0.x - p1.x) * (_p0.y - _p1.y) - (p0.y - p1.y) * (_p0.x - _p1.x);
            var t = _t0 / _t1;

            var _u0 = (p0.x - p1.x) * (p0.y - _p0.y) - (p0.y - p1.y) * (p1.x - _p0.x);
            var _u1 = (p0.x - p1.x) * (_p0.y - _p1.y) - (p0.y - p1.y) * (_p0.x - _p1.x);
            var u = (_u0 / _u1) * -1;

            if (t > 0 && t < 1.0 && u > 0 && u < 1.0) return false;
        }
    }
    return true;
};

/**
 * @description Function to validate this room object and its children
 * @returns {ErrorObject[]} returns array of error objects
 */
Room.prototype.validate = function () {
    var i, j, errorList = [];
    if (this.id < 0 || Tools.isDefinedNotNull(this.id)) {
        if (this.outerWalls.length) {
            Room.buildCornerArrayFromOuterWalls(this, true);
        }
    }
    if (this.checkOuterWallIntersect()) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "outerWalls", {wallError: "intersection"}));
    if (errorList.length === 0 && !this.checkOuterWallsClosed(true)) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "outerWalls", {wallError: "notClosed"}));
    if (this.corner.length <= 2) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "corner", {cornerError: "length"}));
    if (this.corner.length >= 3) {
        for (i = 0; i < this.corner.length; i++) {
            for (j = 0; j < this.corner.length; j++) {
                if (j === i) continue;
                if (this.corner[i].x === this.corner[j].x && this.corner[i].y === this.corner[j].y && this.corner[i].z === this.corner[j].z) {
                    errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY_CORNER_DUPLICATED, this.uniqueId, "corner"));
                    break;
                }
            }
        }
        if (errorList.length === 0) {
            // if (!this.isCornerPolygonSimple()) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "corner", {cornerError:"polygon"}));
        }
    }

    if (this.name === "" || this.name === null || this.name === undefined) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "name"));
    if (this.floor === undefined || this.floor === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "floor"));
    if (this.thickness === undefined || this.thickness === null || this.thickness <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "thickness"));
    if (this.locationId === undefined || this.locationId === null || this.locationId <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "locationId"));
    if (this.buildingId === undefined || this.buildingId === null || this.buildingId <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "buildingId"));

    for (i = 0; i < this.assets.length; i++) errorList = errorList.concat(this.assets[i].validate());
    for (i = 0; i < this.coolings.length; i++) errorList = errorList.concat(this.coolings[i].validate());
    for (i = 0; i < this.floorTiles.length; i++) errorList = errorList.concat(this.floorTiles[i].validate());
    for (i = 0; i < this.racks.length; i++) errorList = errorList.concat(this.racks[i].validate());
    for (i = 0; i < this.sensors.length; i++) errorList = errorList.concat(this.sensors[i].validate());
    for (i = 0; i < this.ups.length; i++) errorList = errorList.concat(this.ups[i].validate());
    for (i = 0; i < this.roomObjs.length; i++) errorList = errorList.concat(this.roomObjs[i].validate());
    for (i = 0; i < this.innerWalls.length; i++) errorList = errorList.concat(this.innerWalls[i].validate(this));

    return errorList;
};

Room.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [];
    var i, path;
    for (i = 0; i < this.assets.length; i++) {
        path = this.assets[i].findObjectPathByUniqueId(uniqueId);
        if (path.length > 0) return path;
    }
    for (i = 0; i < this.coolings.length; i++) {
        path = this.coolings[i].findObjectPathByUniqueId(uniqueId);
        if (path.length > 0) return path;
    }
    for (i = 0; i < this.floorTiles.length; i++) {
        path = this.floorTiles[i].findObjectPathByUniqueId(uniqueId);
        if (path.length > 0) return path;
    }
    for (i = 0; i < this.racks.length; i++) {
        path = this.racks[i].findObjectPathByUniqueId(uniqueId);
        if (path.length > 0) return path;
    }
    for (i = 0; i < this.sensors.length; i++) {
        path = this.sensors[i].findObjectPathByUniqueId(uniqueId);
        if (path.length > 0) return path;
    }
    for (i = 0; i < this.ups.length; i++) {
        path = this.ups[i].findObjectPathByUniqueId(uniqueId);
        if (path.length > 0) return path;
    }
    for (i = 0; i < this.roomObjs.length; i++) {
        if (this.roomObjs[i].uniqueId === uniqueId) return [this.roomObjs[i]];
    }
    for (i = 0; i < this.innerWalls.length; i++) {
        if (this.innerWalls[i].uniqueId === uniqueId) return [this.innerWalls[i]];
    }
    return [];
};

/**
 * @description Function to check if the provided wall define a valid room shape
 * @param {array} walls array of wall objects to test
 * @returns {*} returns false if walls don't setup a valid room shape, otherwise returns sorted/ordered wall array
 */
Room.checkWallsClosed = function (walls) {
    if (walls instanceof Array && walls.length > 2) {
        var wallArray = [];
        for (var i = 0; i < walls.length; i++) {
            wallArray.push([walls[i].start.clone().setY(0), walls[i].end.clone().setY(0)]);
        }
        var retArray = Room.sortWalls(wallArray);
        if (retArray.length === 0) {
            return false;
        } else if (retArray.length > 2) return retArray;
        return false;
    } else {
        return false;
    }
};

/**
 * @description Function to get sorted array of provided walls
 * @param {array} walls wall objects to sort
 * @returns {Array} returns array of sorted walls if possible, otherwise empty array
 */
Room.sortWalls = function (walls) {
    var sorted = [];
    if (walls.length <= 2) {
        return [];
    } else {
        sorted.push(walls.shift());
    }
    while (true) {
        var foundAt = [];
        for (var i = 0; i < walls.length; i++) {
            if (walls[i][0].equals(sorted[sorted.length - 1][1])) foundAt.push(i);
            if (walls[i][1].equals(sorted[sorted.length - 1][1])) {
                var tmp = walls[i][1];
                walls[i][1] = walls[i][0];
                walls[i][0] = tmp;
                foundAt.push(i);
            }
        }
        if (foundAt.length > 1) return [];
        if (foundAt.length === 1) {
            sorted.push(walls[foundAt[0]]);
            walls.splice(foundAt[0], 1);
        }
        if (foundAt.length < 1) {
            if (walls.length !== 0) return [];
            if (sorted[0][0].equals(sorted[sorted.length - 1][1])) {
                return sorted;
            } else {
                return [];
            }
        }
    }
};

/**
 * @description Function to check if provided corner points create a rectangular shaped room
 * @param {array} corner array of corner points, points 3-dimensional
 * @returns {boolean} returns true if provided corner points define a rectangular room, otherwise false
 */
Room.checkRectRoom = function (corner) {
    var lensq = function (v) {
        return v.x * v.x + v.y * v.y + v.z * v.z;
    };
    if (corner.length !== 4) return false;
    var diag0 = {x: corner[2].x - corner[0].x, y: 0, z: corner[2].z - corner[0].z};
    var diag1 = {x: corner[3].x - corner[1].x, y: 0, z: corner[3].z - corner[1].z};
    if (lensq(diag0) !== lensq(diag1)) return false;
    var l0 = {x: corner[1].x - corner[0].x, y: corner[1].y - corner[0].y, z: corner[1].z - corner[0].z};
    var l1 = {x: corner[2].x - corner[1].x, y: corner[2].y - corner[1].y, z: corner[2].z - corner[1].z};
    var l2 = {x: corner[3].x - corner[2].x, y: corner[3].y - corner[2].y, z: corner[3].z - corner[2].z};
    var l3 = {x: corner[0].x - corner[3].x, y: corner[0].y - corner[3].y, z: corner[0].z - corner[3].z};
    if (lensq(l0) !== lensq(l2) || lensq(l1) !== lensq(l3)) return false;
    return true;
};

/**
 * @description Function to check if point is on wall line (epsilon 0.005m floating point ..)
 * @param {THREE.Vector3} s start point for the wall line
 * @param {THREE.Vector3} e end point for the wall line
 * @param {THREE.Vector3} p point to test
 * @returns {boolean} returns true if point is located on the wall line otherwise false.
 */
Room.checkPointOnWall = function (s, e, p) {
    s.y = e.y = p.y = 0;
    var d0 = p.clone().sub(s).length();
    var d1 = p.clone().sub(e).length();
    var d = s.clone().sub(e).length();
    return Math.abs(d - (d0 + d1)) < 0.005;
};

/**
 * @description Function to check if point is inside of the polygon defined by provided points
 * @param {object} point is the point to check (3-dimensional vector)
 * @param {array} polyPts points defining the polygon each point 3-dimensional vector
 * @param {boolean} ignoreCollisionWithOuterWall is a boolean only for inner walls if the are on the outer wall
 * @returns {boolean} returns true if point is in polygon otherwise false
 */
Room.checkPointInPolygon = function (point, polyPts, ignoreCollisionWithOuterWall) {
    if (ignoreCollisionWithOuterWall === undefined || ignoreCollisionWithOuterWall === null) ignoreCollisionWithOuterWall = false;
    if (ignoreCollisionWithOuterWall && pointAtWall(point, polyPts)) {
        return true;
    }
    var x = point.x;
    var z = point.z;
    var inside = false;
    for (var i = 0, j = polyPts.length - 1; i < polyPts.length; j = i++) {
        var xi = polyPts[i].x, zi = polyPts[i].z;
        var xj = polyPts[j].x, zj = polyPts[j].z;
        var intersect = ((zi > z) != (zj > z)) && (x < (xj - xi) * (z - zi) / (zj - zi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};
/**
 * Checks if a point is betweent to points (walls)
 * @param {THREE.Vector3} point is the point to check
 * @param {array }polyPts is a array of coordiantes
 * @returns {boolean}
 */
var pointAtWall = function (point, polyPts) {
    var isPointOnLine = function (pointA, pointB, pointToCheck) {
        var pointAlpha = new THREE.Vector3(pointA.x, pointA.y, pointA.z);
        var pointBravo = new THREE.Vector3(pointB.x, pointB.y, pointB.z);
        var c = new THREE.Vector3();
        c.crossVectors(pointAlpha.clone().sub(pointToCheck), pointBravo.clone().sub(pointToCheck));
        return !c.length();
    };

    var isPointOnLineAndBetweenPoints = function (pointA, pointB, pointToCheck) {
        if (!isPointOnLine(pointA, pointB, pointToCheck)) {
            return false;
        }
        var dx = pointB.x - pointA.x;
        var dz = pointB.z - pointA.z;
        if (Math.abs(dx) >= Math.abs(dz)) {
            if (dx > 0) {
                return pointA.x <= pointToCheck.x && pointToCheck.x <= pointB.x;
            } else {
                return pointB.x <= pointToCheck.x && pointToCheck.x <= pointA.x;
            }
        } else {
            if (dz > 0) {
                return pointA.z <= pointToCheck.z && pointToCheck.z <= pointB.z;
            } else {
                return pointB.z <= pointToCheck.z && pointToCheck.z <= pointA.z;
            }
        }
    };
    for (var i = 0; i < polyPts.length; i++) {
        if (isPointOnLineAndBetweenPoints(polyPts[i], polyPts[i === polyPts.length - 1 ? 0 : i + 1], point)) return true;
        if (isPointOnLineAndBetweenPoints(polyPts[i], polyPts[i === 0 ? polyPts.length - 1 : i - 1], point)) return true;
    }
    return false;
};

/**
 * @description Builds outer wall object array from backend corner information
 * @param {Object} room room object containing corner array
 * @param {boolean=} alterObject flag wether or not to update the provided room object
 * @return {Array} outer wall array
 */
Room.buildOuterWallArrayFromRoomCorners = function (room, alterObject) {
    var walls = [];
    var cornerOrder = Room.checkCornerOrder(room.corner);
    if (cornerOrder === 1) room.corner.reverse();
    for (var i = 0; i < room.corner.length; i++) {
        var nextIdx = (i + 1) % room.corner.length;
        var start = room.corner[i];
        var end = room.corner[nextIdx];
        walls.push(new OuterWall(i, start.x, start.y, start.z, end.x, end.y, end.z, room.thickness, room.size.y));
    }
    if (alterObject) room.outerWalls = walls;
    return walls;
};

/**
 * @description Create a array of room corner points for backend
 * @param {Object} room room object
 * @param {boolean=} alterObject if true - room corner property will be updated with point array
 * @return {Array}
 */
Room.buildCornerArrayFromOuterWalls = function (room, alterObject) {
    var corners = [];
    for (var i = 0; i < room.outerWalls.length; i++) {
        corners.push({id: i * -1, x: room.outerWalls[i].start.x, y: 0, z: room.outerWalls[i].start.z})
    }
    if (alterObject) room.corner = corners;
    return corners;
};
/**
 * @description Checks order of corner polygon points
 * @param corner array of vertices with x,y,z component
 * @return {number} 0 if less than 3 points or invalid points(polygon, 1 for counter-clockwise , -1 for clockwise ordering
 */
Room.checkCornerOrder = function (corner) {
    var sum = 0;
    if (corner.length < 3) {
        return 0;
    }
    for (var i = 0; i < corner.length - 1; i++) {
        sum += (corner[i + 1].x - corner[i].x) * (corner[i + 1].z + corner[i].z);
    }
    sum += (corner[0].x - corner[corner.length - 1].x) * (corner[0].z + corner[corner.length - 1].z);
    if (sum > 0) {
        return 1;
    } else if (sum < 0) {
        return -1;
    } else {
        return 0;
    }
};

/**
 * @description Function to build a room object from backend json data
 * @param {object} r the json object to use for building the room object
 * @returns {Room} returns the created room object
 */
Room.parseFromHtmlObject = function (obj) {
    var j, c;

    if (obj !== undefined && obj !== null) {
        var r = (obj.jsonData !== undefined && obj.jsonData !== null && obj.jsonData !== "") ? JSON.parse(obj.jsonData) : obj;

        var room = new Room(
            r.id, // id
            {x: r.posX, y: r.posY, z: r.posZ}, // pos
            {x: r.scaleX, y: r.scaleY, z: r.scaleZ}, // size
            { x: r.rotX, y: r.rotY, z: r.rotZ}, // rot
            r.name, // name
            r.comment, // coment
            r.floor, // floor
            r.thickness, // thickness
            undefined, // corner
            undefined, // roomObjs
            r.roomConfigurations, // roomConf
            undefined, // racks
            undefined, // coolings
            undefined, // assets
            undefined, // sensors
            r.floorPanel, // floorpanels
            undefined, // floorTiles
            r.uniqueId, // unique Id
            r.locationId, // locationId
            r.buildingId, // buildingId
            obj.jsonData); // jsonData

        if (r !== "" || r !== null) {
            for (j = 0; j < r.corner.length; j++) {
                c = r.corner[j];
                room.corner.push({id: c.id, roomId: c.roomId, x: c.x, y: c.y, z: c.z, uniqueId: c.uniqueId});
            }
            if (room.checkCornerOrder() === 1) {
                room.corner.reverse();
            }
            room.driverValueGroups = r.driverValueGroups;
            room.computeBoundingBox();
            room.outerWalls = Room.buildOuterWallArrayFromRoomCorners(room);
            for (j = 0; j < r.roomObjs.length; j++) {
                var o = r.roomObjs[j];
                if (Door.isDoor(o.type)) {
                    room.roomObjs.push(new Door(o.id, {x: o.posX, y: o.posY, z: o.posZ}, {
                        x: o.scaleX,
                        y: o.scaleY,
                        z: o.scaleZ
                    }, {x: o.rotX, y: o.rotY, z: o.rotZ}, o.name, o.comment, o.type, o.uniqueId, room.id));
                }
                if (Window.isWindow(o.type)) {
                    room.roomObjs.push(new Window(o.id, {
                        x: o.posX,
                        y: o.posY,
                        z: o.posZ
                    }, {x: o.scaleX, y: o.scaleY, z: o.scaleZ}, {
                        x: o.rotX,
                        y: o.rotY,
                        z: o.rotZ
                    }, o.name, o.comment, o.type, o.uniqueId, room.id, true));
                }
                if (RaisedFloor.isRaisedFloor(o.type)) {
                    room.hasRaisedFloor = true;
                    room.roomObjs.push(new RaisedFloor(o.id, {x: o.posX, y: o.posY, z: o.posZ}, {
                        x: o.scaleX,
                        y: o.scaleY,
                        z: o.scaleZ
                    }, {x: o.rotX, y: o.rotY, z: o.rotZ}, o.name, o.comment, o.type, o.uniqueId, room.id));
                }
                if (Pillar.isPillar(o.type)) {
                    room.roomObjs.push(new Pillar(o.id, {
                        x: o.posX,
                        y: o.posY,
                        z: o.posZ
                    }, {x: o.scaleX, y: o.scaleY, z: o.scaleZ}, {
                        x: o.rotX,
                        y: o.rotY,
                        z: o.rotZ
                    }, o.name, o.comment, o.type, o.uniqueId, room.id));
                }
                if (Containment.isContainment(o.type)) {
                    var containment = new Containment(o.id, {
                        x: o.posX,
                        y: o.posY,
                        z: o.posZ
                    }, {x: o.scaleX, y: o.scaleY, z: o.scaleZ}, {
                        x: o.rotX,
                        y: o.rotY,
                        z: o.rotZ
                    }, o.name, o.comment, o.type, o.uniqueId, room.id);
                    containment.genRelativePositions(room.bbox.min);
                    room.roomObjs.push(containment);
                }
                //TODO complete me
                // room.roomObjs.push(new RoomObj(o.id, {x:o.posX, y:o.posY, z:o.posZ}, {x:o.scaleX,y:o.scaleY,z:o.scaleZ}, {x:o.rotX,y:o.rotY,z:o.rotZ}, o.name, o.comment, o.type));
            }

            for (j = 0; j < r.innerWalls.length; j++) {
                var w = r.innerWalls[j];
                room.innerWalls.push(new InnerWall(w.id, w.name, w.comment, w.inventoryNumber, w.serialNumber, w.type, w.posType, w.thickness, w.height, w.startX, w.startY, w.startZ, w.endX, w.endY, w.endZ, w.uniqueId));
            }

            for (j = 0; j < r.racks.length; j++) {
                var rack = Rack.parseFromHtmlObject(r.racks[j], room.id, true);
                rack.genRelativePositions(room.bbox.min);
                room.racks.push(rack);
                for (var k = 0; k < r.racks[j].slots.length; k++) {
                    var slot = Slot.parseFromHtmlObject(r.racks[j].slots[k], rack.id);
                    slot.computeHUPosition(rack);
                    room.racks[j].slots.push(slot);
                    for (var b = 0; b < r.racks[j].slots[k].blades.length; b++) {
                        var blade = Blade.parseFromHtmlObject(r.racks[j].slots[k].blades[b], slot.id);
                        room.racks[j].slots[k].blades.push(blade);
                        for (c = 0; c < r.racks[j].slots[k].blades[b].cpus.length; c++) {
                            var cpu = Cpu.parseFromHtmlObject(r.racks[j].slots[k].blades[b].cpus[c], blade.id);
                            room.racks[j].slots[k].blades[b].cpus.push(cpu);
                        }
                    }
                }
            }
            for (j = 0; j < r.assets.length; j++) {
                var asset = Asset.parseFromHtmlObject(r.assets[j], room.id);
                asset.genRelativePositions(room.bbox.min);
                room.assets.push(asset);
            }
            for (j = 0; j < r.sensors.length; j++) {
                var sensor = Sensor.parseFromHtmlObject(r.sensors[j], room.id);
                sensor.genRelativePositions(room.bbox.min);
                room.sensors.push(sensor);
            }
            for (j = 0; j < r.coolings.length; j++) {
                var cooling = Cooling.parseFromHtmlObject(r.coolings[j], room.id);
                cooling.genRelativePositions(room.bbox.min);
                room.coolings.push(cooling);
            }
            for (j = 0; j < r.floorTiles.length; j++) {
                var tile = FloorTile.parseFromHtmlObject(r.floorTiles[j], room.id);
                tile.genRelativePositions(room.bbox.min);
                room.floorTiles.push(tile)
            }
            for (j = 0; j < r.ups.length; j++) {
                var ups = Ups.parseFromHtmlObject(r.ups[j], room.id);
                ups.genRelativePositions(room.bbox.min);
                room.ups.push(ups);
            }
        }
    }
    return room;
};

/**
 * @description Function to build room object from basic js object
 * @param {object} r the object to build room object from
 * @returns {Room} returns built room object
 */
Room.parseFromSimpleObject = function (obj) {
    var j, i, c;
    var r = (obj.jsonData !== undefined && obj.jsonData !== null) ? JSON.parse(obj.jsonData) : obj;

    var room = new Room(r.id, {x: r.pos.x, y: r.pos.y, z: r.pos.z}, {x: r.size.x, y: r.size.y, z: r.size.z}, {
        x: 0,
        y: 0,
        z: 0
    }, r.name, r.comment, r.floor, r.thickness, undefined, undefined, r.roomConfigurations, undefined, undefined, undefined, r.floorPanel, undefined, r.uniqueId, r.locationId, r.buildingId, obj.jsonData);
    room.floorType = r.floorType;
    for (i = 0; i < r.corner.length; i++) {
        c = r.corner[i];
        room.corner.push({id: c.id, roomId: c.roomId, x: c.x, y: c.y, z: c.z, uniqueId: c.uniqueId});
    }
    room.driverValueGroups = r.driverValueGroups;
    if (room.checkCornerOrder() === 1) {
        room.corner.reverse();
    }
    room.computeBoundingBox();
    room.outerWalls = Room.buildOuterWallArrayFromRoomCorners(room);
    for (j = 0; j < r.roomObjs.length; j++) {
        var o = r.roomObjs[j];
        if (Door.isDoor(o.type)) {
            room.roomObjs.push(new Door(o.id, {x: o.pos.x, y: o.pos.y, z: o.pos.z}, {
                x: o.size.x,
                y: o.size.y,
                z: o.size.z
            }, {x: o.rot.x, y: o.rot.y, z: o.rot.z}, o.name, o.comment, o.type, o.uniqueId, room.id));
        }
        if (Window.isWindow(o.type)) {
            room.roomObjs.push(new Window(o.id, {
                x: o.pos.x,
                y: o.pos.y,
                z: o.pos.z
            }, {x: o.size.x, y: o.size.y, z: o.size.z}, {
                x: o.rot.x,
                y: o.rot.y,
                z: o.rot.z
            }, o.name, o.comment, o.type, o.uniqueId, room.id, true));
        }
        if (RaisedFloor.isRaisedFloor(o.type)) {
            room.hasRaisedFloor = true;
            room.roomObjs.push(new RaisedFloor(o.id, {x: o.pos.x, y: o.pos.y, z: o.pos.z}, {
                x: o.size.x,
                y: o.size.y,
                z: o.size.z
            }, {x: o.rot.x, y: o.rot.y, z: o.rot.z}, o.name, o.comment, o.type, o.uniqueId, room.id));
        }
        if (Pillar.isPillar(o.type)) {
            room.roomObjs.push(new Pillar(o.id, {
                x: o.pos.x,
                y: o.pos.y,
                z: o.pos.z
            }, {x: o.size.x, y: o.size.y, z: o.size.z}, {
                x: o.rot.x,
                y: o.rot.y,
                z: o.rot.z
            }, o.name, o.comment, o.type, o.uniqueId, room.id));
        }
        if (Containment.isContainment(o.type)) {
            var containment = new Containment(o.id, {
                x: o.pos.x,
                y: o.pos.y,
                z: o.pos.z
            }, {x: o.size.x, y: o.size.y, z: o.size.z}, {
                x: o.rot.x,
                y: o.rot.y,
                z: o.rot.z
            }, o.name, o.comment, o.type, o.uniqueId, room.id);
            containment.genRelativePositions(room.bbox.min);
            room.roomObjs.push(containment);
        }
    }
    for (j = 0; j < r.innerWalls.length; j++) {
        var w = r.innerWalls[j];
        room.innerWalls.push(new InnerWall(w.id, w.name, w.comment, w.inventoryNumber, w.serialNumber, w.type, w.posType, w.thickness, w.height, w.start.x, w.start.y, w.start.z, w.end.x, w.end.y, w.end.z, w.uniqueId));
    }
    for (j = 0; j < r.racks.length; j++) {
        var rack = Rack.parseFromSimpleObject(r.racks[j], room.id, true);
        rack.genRelativePositions(room.bbox.min);
        room.racks.push(rack);
        for (var k = 0; k < r.racks[j].slots.length; k++) {
            var slot = Slot.parseFromSimpleObject(r.racks[j].slots[k], rack.id);
            room.racks[j].slots.push(slot);
            for (var b = 0; b < r.racks[j].slots[k].blades.length; b++) {
                var blade = Blade.parseFromSimpleObject(r.racks[j].slots[k].blades[b], slot.id);
                room.racks[j].slots[k].blades.push(blade);
                for (c = 0; c < r.racks[j].slots[k].blades[b].cpus.length; c++) {
                    var cpu = Cpu.parseFromSimpleObject(r.racks[j].slots[k].blades[b].cpus[c], blade.id);
                    room.racks[j].slots[k].blades[b].cpus.push(cpu);
                }
            }
        }
    }
    for (j = 0; j < r.assets.length; j++) {
        var asset = Asset.parseFromSimpleObject(r.assets[j], room.id);
        asset.genRelativePositions(room.bbox.min);
        room.assets.push(asset);
    }
    for (j = 0; j < r.sensors.length; j++) {
        var sensor = Sensor.parseFromSimpleObject(r.sensors[j], room.id);
        sensor.genRelativePositions(room.bbox.min);
        room.sensors.push(sensor);
    }
    for (j = 0; j < r.coolings.length; j++) {
        var cooling = Cooling.parseFromSimpleObject(r.coolings[j], room.id);
        cooling.genRelativePositions(room.bbox.min);
        room.coolings.push(cooling);
    }
    for (j = 0; j < r.floorTiles.length; j++) {
        var tile = FloorTile.parseFromSimpleObject(r.floorTiles[j], room.id);
        tile.genRelativePositions(room.bbox.min);
        room.floorTiles.push(tile)
    }
    for (j = 0; j < r.ups.length; j++) {
        var ups = Ups.parseFromSimpleObject(r.ups[j], room.id);
        ups.genRelativePositions(room.bbox.min);
        room.ups.push(ups);
    }
    return room;
};
