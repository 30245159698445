(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name loadMoreBtn
     * @param {method} nextpage - Method to call for next page
     * @param {boolean} show - Show/hide this button
     */
    angular.module('emsv2App')
        .component('loadMoreBtn', {
            templateUrl: 'scripts/components/loadmore/loadmorebtn.html',
            bindings: {
                nextpage: '<',
                show: '='
            },
            controller: function () {
                this.showSpinner = false;
                this.click = function () {
                    var ctrl = this;
                    var result = ctrl.nextpage();
                    if (result instanceof Object) {
                        ctrl.showSpinner = true;
                        result.finally(function () {
                            ctrl.showSpinner = false;
                        });
                    }
                };
            }
        });

})();
