(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name buildingTab
     * @param {Object} location - The current location
     * @param {Object[]} buildings - Array of currently available buildings for this location
     * @param {Object} license - Current license
     * @param {Object[]} devices - All known devices for this location
     * @param {Object[]} rooms - All rooms for this location
     * @param {Object} user - Information for current user
     * @requires $state, $stateParams, $log, $timeout, notify, leafletData, Location, Buildings, Tools
     */
    angular
        .module('emsv2App')
        .component('buildingTab', {
            bindings: {
                location: '=',
                buildings: '=',
                license: '=',
                devices: '=',
                rooms: '=',
                user: '='
            },
            templateUrl: 'scripts/app/location/tabs/building/building-tab.component.html',
            controller: ['$state', '$stateParams', '$log', '$timeout', '$translate', 'Notify', 'leafletData'
                , 'Location', 'Buildings', 'Tools', 'Principal', 'GenDialogService', 'RoomService',
                function ($state, $stateParams, $log, $timeout, $translate, Notify, leafletData, Location
                    , Buildings, Tools, Principal, GenDialogService, RoomService) {
                    var ctrl = this;
                    ctrl.map = {
                        defaults: {
                            scrollWheelZoom: true
                        }
                    };
                    ctrl.editMode = false;
                    ctrl.deleteMode = false;
                    var currentMap;
                    var markerLayer;

                    ctrl.$onInit = function () {
                        L.Icon.Default.imagePath = '/assets/ci/cyberhub-light/images/';
                        leafletData.getMap('buildingmap').then(function (map) {
                            currentMap = map;
                            // Doesn't work (here) if immediately set. Works in map widget though..
                            $timeout(function () {
                                markerLayer = Buildings.setupBuildingMarkers(ctrl.location, ctrl.buildings, currentMap, markerLayer, _onMarkerClick, undefined);
                            }, 50);
                        }, function (error) {
                            $log.error('Couldn\'t get map instance!', error);
                        });
                    };

                    ctrl.allowEdit = (ctrl.user.roles.indexOf("ROLE_LOCATION_EDIT") !== -1);
                    ctrl.allowDelete = (ctrl.user.roles.indexOf("ROLE_LOCATION_DELETE") !== -1);
                    ctrl.allowCreate = (ctrl.user.roles.indexOf("ROLE_LOCATION_CREATE") !== -1);

                    ctrl.deleteBuilding = function (building) {
                        RoomService.getSimpleRoomsByBuilding(building.id).then(function (response) {
                            if (Tools.isDefinedNotNull(response.data) && response.data.length > 0) {
                                $translate(['global.dialog.head.warning', 'building.dialog.deleteBuildingRoomErrorMessage', 'global.btn.close']).then(function (trans) {
                                    GenDialogService.showDialog(false, {
                                        headText: trans['global.dialog.head.warning'],
                                        headIcon: 'glyphicon glyphicon-warning-sign',
                                        messageText: trans['building.dialog.deleteBuildingRoomErrorMessage'].replace('%obj', building.name),
                                        showClose: true,
                                        textButton0: trans['global.btn.close'],
                                        iconButton0: 'glyphicon glyphicon-remove',
                                        classButton0: 'btn-default',
                                        callbackButton0: function () {
                                            GenDialogService.hideDialog();
                                        }
                                    });
                                });
                            }
                            else {
                                $translate(['global.dialog.head.warning', 'building.dialog.deleteBuildingMessage', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                                    GenDialogService.showDialog(false, {
                                        headText: trans['global.dialog.head.warning'],
                                        headIcon: 'glyphicon glyphicon-warning-sign',
                                        messageText: trans['building.dialog.deleteBuildingMessage'].replace('%obj', building.name),
                                        showClose: true,
                                        textButton0: trans['global.btn.cancel'],
                                        iconButton0: 'glyphicon glyphicon-remove',
                                        classButton0: 'btn-default',
                                        textButton1: trans['global.btn.delete'],
                                        iconButton1: 'glyphicon glyphicon-remove-circle',
                                        classButton1: 'btn-delete',
                                        callbackButton0: function () {
                                            GenDialogService.hideDialog();
                                        },
                                        callbackButton1: function () {
                                            Buildings.delete(building.id).then(function (response) {
                                                GenDialogService.hideDialog();
                                                Notify.success("global.notification.success.title", "building.notifications.deleteSuccess", 2000);
                                                var idx = null;
                                                for (var i in ctrl.buildings) {
                                                    if (ctrl.buildings[i].id == building.id) {
                                                        idx = i;
                                                        break;
                                                    }
                                                }
                                                ctrl.buildings.splice(idx, 1);
                                                markerLayer = Buildings.setupBuildingMarkers(ctrl.location, ctrl.buildings, currentMap, markerLayer, _onMarkerClick, undefined);
                                            }, function (error) {
                                                $log.error("Error deleting building with id: " + building.id + "\n" + error);
                                                GenDialogService.hideDialog();
                                                Notify.error("global.notification.error.title", "building.notifications.deleteError", 2000);
                                            });
                                        }
                                    });
                                });
                            }
                        });
                    };

                    ctrl.addBuilding = function () {
                        $state.go('location.buildings.new');
                    };

                    ctrl.select = function (building, mode) {
                        $state.go('location.buildings.detail', {bid: building.id, bmode: mode});
                    };

                    ctrl.cancelSelection = function () {
                        if (ctrl.selectedCopy !== null) {
                            for (var i = 0; i < ctrl.buildings.length; i++) {
                                if (ctrl.buildings[i].id === ctrl.selectedCopy.id) {
                                    ctrl.buildings[i] = ctrl.selectedCopy;
                                }
                            }
                        }
                        ctrl.selectedBuilding = null;
                        ctrl.selectedCopy = null;
                        ctrl.deleteMode = false;
                        ctrl.editMode = false;
                        markerLayer = Buildings.setupBuildingMarkers(ctrl.location, ctrl.buildings, currentMap, markerLayer, _onMarkerClick, undefined);
                    };

                    ctrl.showPreview = function (building) {
                        if (markerLayer !== undefined && markerLayer !== null) {
                            markerLayer.eachLayer(function (layer) {
                                if (layer.buildingId === building.id) {
                                    layer.setIcon(Buildings.getIconOk());
                                }
                            });
                        }
                    };

                    ctrl.hidePreview = function () {
                        _resetMarkerIcons();
                    };

                    function _selectBuilding(buildingId, edit) {
                        for (var i = 0; i < ctrl.buildings.length; i++) {
                            if (ctrl.buildings[i].id === buildingId) {
                                ctrl.selectedBuilding = ctrl.buildings[i];
                                ctrl.selectedCopy = angular.copy(ctrl.buildings[i]);
                                ctrl.selectedBuilding.edit = edit;
                            }
                        }
                    }

                    // Update map after changes
                    function _updateMarkerLocation(buildingId, latlon) {
                        for (var i = 0; i < ctrl.buildings.length; i++) {
                            if (ctrl.buildings[i].id === buildingId) {
                                ctrl.buildings[i].edit = true;
                                ctrl.buildings[i].lat = latlon.lat;
                                ctrl.buildings[i].lon = latlon.lng;
                            }
                        }
                    }

                    // Update coordinates after drag event
                    function _onMarkerDragEnd() {
                        _updateMarkerLocation(this.buildingId, this.getLatLng());
                    }

                    // Deselect all other markers before selecting anything
                    function _resetMarkerIcons() {
                        var selected = null;
                        if (markerLayer !== undefined && markerLayer !== null) {
                            markerLayer.eachLayer(function (layer) {
                                if (layer.dragging.enabled()) {
                                    selected = layer.buildingId;
                                }
                                layer.setIcon(Buildings.getDefaultIcon());
                                layer.dragging.disable();
                            });
                        }
                        return selected;
                    }

                    // (De)select marker
                    function _onMarkerClick() {
                        var lastSelected = _resetMarkerIcons();
                        if (lastSelected !== this.buildingId) {
                            this.setIcon(Buildings.getIconOk());
                            // this.dragging.enable();
                            _selectBuilding(this.buildingId, false);
                        }
                        else {
                            ctrl.cancelSelection();
                        }
                    }

                }]
        });

})();


