(function () {
    'use strict';

    /**
     * @description Service to handle detail pictures in detail modal (room editor)
     */
    angular.module("emsv2App").service("DetailPictureService", function (EMSConstants) {
        //data for available pictures
        var imageInfo = [
            {
                name: "ALD_412_GE",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.34375, y: 0.42578},
                        br: {x: 0.44336, y: 0.61523},
                        compID: 5
                    },
                    "comp 2": {
                        tl: {x: 0.54883, y: 0.42578},
                        br: {x: 0.64258, y: 0.61523},
                        compID: 6
                    },
                    "humidifier": {
                        tl: {x: 0.07031, y: 0.65625},
                        br: {x: 0.1582, y: 0.8125},
                        compID: 35
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.752, y: 0.6816},
                        br: {x: 0.8379, y: 0.8125},
                        compID: 14
                    },
                    "ge/cw valve 2": {
                        tl: {x: 0.7969, y: 0.6602},
                        br: {x: 0.8809, y: 0.7754},
                        compID: 15
                    },
                    "fan": {
                        tl0: {x: 0.2305, y: 0.6348},
                        br0: {x: 0.4766, y: 0.8691},
                        tl1: {x: 0.4453, y: 0.6348},
                        br1: {x: 0.7656, y: 0.8691},
                        compID: 25
                    }
                },
                applicableForSuffix: "GE",
                applicableForMaxSize: 5,
                applicableForCircuitCount: 2,
                applicableForFamily: "A",
                applicableForVersion: "L",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ALD_421_GE",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.5273, y: 0.3750},
                        br: {x: 0.6562, y: 0.6250},
                        compID: 5
                    },
                    "fan": {
                        tl0: {x: 0.2305, y: 0.6348},
                        br0: {x: 0.4766, y: 0.8691},
                        tl1: {x: 0.5664, y: 0.6348},
                        br1: {x: 0.7519, y: 0.8691},
                        compID: 25
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.752, y: 0.6816},
                        br: {x: 0.8379, y: 0.8125},
                        compID: 14
                    },
                    "ge/cw valve 2": {
                        tl: {x: 0.7969, y: 0.6602},
                        br: {x: 0.8809, y: 0.7754},
                        compID: 15
                    }
                },
                applicableForSuffix: "GE",
                applicableForMaxSize: 5,
                applicableForCircuitCount: 1,
                applicableForFamily: "A",
                applicableForVersion: "L",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_412_A",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.3262, y: 0.4180},
                        br: {x: 0.4277, y: 0.6191},
                        compID: 5
                    },
                    "comp 2": {
                        tl: {x: 0.4921, y: 0.4921},
                        br: {x: 0.5957, y: 0.6191},
                        compID: 6
                    },
                    "fan": {
                        tl0: {x: 0.2305, y: 0.6348},
                        br0: {x: 0.4375, y: 0.8593},
                        tl1: {x: 0.5703, y: 0.6348},
                        br1: {x: 0.7422, y: 0.8593},
                        compID: 25
                    }
                },
                applicableForSuffix: "A",
                applicableForMaxSize: 2,
                applicableForCircuitCount: 2,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_412_ACW",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.3086, y: 0.4180},
                        br: {x: 0.4258, y: 0.6289},
                        compID: 5
                    },
                    "comp 2": {
                        tl: {x: 0.4922, y: 0.4180},
                        br: {x: 0.5996, y: 0.6289},
                        compID: 6
                    },
                    "humi": {
                        tl: {x: 0.1348, y: 0.6602},
                        br: {x: 0.2266, y: 0.8457},
                        compID: 35
                    },
                    "fan": {
                        tl0: {x: 0.1914, y: 0.6484},
                        br0: {x: 0.4414, y: 0.8867},
                        tl1: {x: 0.5527, y: 0.6484},
                        br1: {x: 0.8867, y: 0.8867},
                        compID: 25
                    }
                },
                applicableForSuffix: "ACW",
                applicableForMaxSize: 5,
                applicableForCircuitCount: 2,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_412_G",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.3047, y: 0.4180},
                        br: {x: 0.4258, y: 0.6289},
                        compID: 5
                    },
                    "comp 2": {
                        tl: {x: 0.4922, y: 0.4180},
                        br: {x: 0.6172, y: 0.6289},
                        compID: 6
                    },
                    "fan": {
                        tl0: {x: 0.1992, y: 0.6484},
                        br0: {x: 0.4531, y: 0.8984},
                        tl1: {x: 0.5586, y: 0.6484},
                        br1: {x: 0.7891, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "G",
                applicableForCircuitCount: 2,
                applicableForMaxSize: 5,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_412_GCW",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.3047, y: 0.4102},
                        br: {x: 0.4258, y: 0.6289},
                        compID: 5
                    },
                    "comp 2": {
                        tl: {x: 0.4922, y: 0.4102},
                        br: {x: 0.6035, y: 0.6289},
                        compID: 6
                    },
                    "humi": {
                        tl: {x: 0.1270, y: 0.6562},
                        br: {x: 0.2305, y: 0.3086},
                        compID: 35
                    },
                    "fan": {
                        tl0: {x: 0.1875, y: 0.6445},
                        br0: {x: 0.4453, y: 0.8984},
                        tl1: {x: 0.5430, y: 0.6445},
                        br1: {x: 0.8047, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "GCW",
                applicableForMaxSize: 5,
                applicableForCircuitCount: 2,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_421_A",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.4277, y: 0.3632},
                        br: {x: 0.5703, y: 0.6289},
                        compID: 5
                    },
                    "humi": {
                        tl: {x: 0.1172, y: 0.6563},
                        br: {x: 0.2324, y: 0.8535},
                        compID: 35
                    },
                    "fan": {
                        tl0: {x: 0.1973, y: 0.6484},
                        br0: {x: 0.4414, y: 0.8984},
                        tl1: {x: 0.5469, y: 0.6484},
                        br1: {x: 0.8086, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "A",
                applicableForMaxSize: 8,
                applicableForCircuitCount: 1,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_421_ACW",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.4316, y: 0.3633},
                        br: {x: 0.5684, y: 0.6289},
                        compID: 5
                    },
                    "humi": {
                        tl: {x: 0.1250, y: 0.6602},
                        br: {x: 0.2305, y: 0.8633},
                        compID: 35
                    },
                    "fan": {
                        tl0: {x: 0.1797, y: 0.6484},
                        br0: {x: 0.4375, y: 0.8984},
                        tl1: {x: 0.5469, y: 0.6484},
                        br1: {x: 0.7969, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "ACW",
                applicableForMaxSize: 8,
                applicableForCircuitCount: 1,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_421_G",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.4258, y: 0.3633},
                        br: {x: 0.5742, y: 0.6289},
                        compID: 5
                    },
                    "humi": {
                        tl: {x: 0.1250, y: 0.6602},
                        br: {x: 0.2305, y: 0.8555},
                        compID: 35
                    },
                    "fan": {
                        tl0: {x: 0.1973, y: 0.6484},
                        br0: {x: 0.4375, y: 0.8984},
                        tl1: {x: 0.5430, y: 0.6484},
                        br1: {x: 0.8008, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "G",
                applicableForCircuitCount: 1,
                applicableForMaxSize: 8,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_421_GCW",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.4258, y: 0.3633},
                        br: {x: 0.5742, y: 0.6289},
                        compID: 5
                    },
                    "humi": {
                        tl: {x: 0.1250, y: 0.6602},
                        br: {x: 0.2305, y: 0.8555},
                        compID: 35
                    },
                    "fan": {
                        tl0: {x: 0.1973, y: 0.6484},
                        br0: {x: 0.4375, y: 0.8984},
                        tl1: {x: 0.5430, y: 0.6484},
                        br1: {x: 0.8008, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "GCW",
                applicableForCircuitCount: 1,
                applicableForMaxSize: 8,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_670_CW2",
                type: "png",
                positionInfo: {
                    "humi": {
                        tl: {x: 0.1250, y: 0.0977},
                        br: {x: 0.2617, y: 0.3086},
                        compID: 35
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.2090, y: 0.5664},
                        br: {x: 0.3281, y: 0.6406},
                        compID: 14
                    },
                    "ge/cw valve 2": {
                        tl: {x: 0.3867, y: 0.5762},
                        br: {x: 0.5117, y: 0.6914},
                        compID: 15
                    },
                    "fan": {
                        tl0: {x: 0.2109, y: 0.6895},
                        br0: {x: 0.5039, y: 0.8828},
                        tl1: {x: 0.5117, y: 0.6895},
                        br1: {x: 0.8008, y: 0.8828},
                        compID: 25
                    }
                },
                applicableForSuffix: "CW2",
                applicableForCircuitCount: 0,
                applicableForMaxSize: 8,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "ASD_940_CW",
                type: "png",
                positionInfo: {
                    "humi": {
                        tl: {x: 0.1250, y: 0.1074},
                        br: {x: 0.2617, y: 0.3047},
                        compID: 35
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.1660, y: 0.5684},
                        br: {x: 0.2363, y: 0.7168},
                        compID: 14
                    },
                    "fan": {
                        tl0: {x: 0.2031, y: 0.7305},
                        br0: {x: 0.4980, y: 0.8984},
                        tl1: {x: 0.5293, y: 0.7305},
                        br1: {x: 0.8203, y: 0.8984},
                        compID: 25
                    }
                },
                applicableForSuffix: "CW",
                applicableForCircuitCount: 0,
                applicableForMaxSize: 8,
                applicableForFamily: "A",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 512
            },
            {
                name: "CRS_210_CW",
                type: "png",
                positionInfo: {
                    "ge/cw valve 1": {
                        tl: {x: 0.3672, y: 0.7214},
                        br: {x: 0.5020, y: 0.8385},
                        compID: 14
                    },
                    "fan 1": {
                        tl: {x: 0.7969, y: 0.0807},
                        br: {x: 0.8945, y: 0.3581},
                        compID: 25
                    },
                    "fan 2": {
                        tl: {x: 0.7969, y: 0.3958},
                        br: {x: 0.8945, y: 0.5599},
                        compID: 25
                    },
                    "fan 3": {
                        tl: {x: 0.7969, y: 0.5729},
                        br: {x: 0.8945, y: 0.8568},
                        compID: 25
                    }
                },
                applicableForSuffix: "CW",
                applicableForCircuitCount: 0,
                applicableForMaxSize: 1,
                applicableForFamily: "CR",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 768
            },
            {
                name: "CRS_211_AS",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.3262, y: 0.6849},
                        br: {x: 0.4922, y: 0.9089},
                        compID: 5
                    },
                    "fan 1": {
                        tl: {x: 0.7969, y: 0.0807},
                        br: {x: 0.8945, y: 0.3581},
                        compID: 25
                    },
                    "fan 2": {
                        tl: {x: 0.7969, y: 0.3958},
                        br: {x: 0.8945, y: 0.5599},
                        compID: 25
                    },
                    "fan 3": {
                        tl: {x: 0.7969, y: 0.5729},
                        br: {x: 0.8945, y: 0.8568},
                        compID: 25
                    }
                },
                applicableForSuffix: "A",
                applicableForCircuitCount: 1,
                applicableForMaxSize: 1,
                applicableForFamily: "CR",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 768
            },
            {
                name: "CRS_251_AS",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.6719, y: 0.7031},
                        br: {x: 0.8359, y: 0.9310},
                        compID: 5
                    },
                    "humi": {
                        tl: {x: 0.1289, y: 0.7526},
                        br: {x: 0.2227, y: 0.8880},
                        compID: 35
                    },
                    "fan 1": {
                        tl: {x: 0.1074, y: 0.0859},
                        br: {x: 0.2187, y: 0.2760},
                        compID: 25
                    },
                    "fan 2": {
                        tl: {x: 0.1074, y: 0.3164},
                        br: {x: 0.2187, y: 0.5156},
                        compID: 25
                    },
                    "fan 3": {
                        tl: {x: 0.1074, y: 0.5482},
                        br: {x: 0.2187, y: 0.7474},
                        compID: 25
                    }
                },
                applicableForSuffix: "A",
                applicableForCircuitCount: 1,
                applicableForMaxSize: 3,
                applicableForFamily: "CR",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 768
            },
            {
                name: "CRS_251_GES",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.6777, y: 0.7031},
                        br: {x: 0.8203, y: 0.9154},
                        compID: 5
                    },
                    "humi": {
                        tl: {x: 0.1309, y: 0.7552},
                        br: {x: 0.2285, y: 0.8763},
                        compID: 35
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.5469, y: 0.6745},
                        br: {x: 0.6484, y: 0.7943},
                        compID: 14
                    },
                    "fan 1": {
                        tl: {x: 0.1172, y: 0.0833},
                        br: {x: 0.2383, y: 0.2917},
                        compID: 25
                    },
                    "fan 2": {
                        tl: {x: 0.1172, y: 0.3346},
                        br: {x: 0.2383, y: 0.5026},
                        compID: 25
                    },
                    "fan 3": {
                        tl: {x: 0.1172, y: 0.5612},
                        br: {x: 0.2383, y: 0.7409},
                        compID: 25
                    }
                },
                applicableForSuffix: "GES",
                applicableForCircuitCount: 1,
                applicableForMaxSize: 3,
                applicableForFamily: "CR",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 768
            },
            {
                name: "CRS_251_GS",
                type: "png",
                positionInfo: {
                    "comp 1": {
                        tl: {x: 0.6660, y: 0.7005},
                        br: {x: 0.8301, y: 0.9076},
                        compID: 5
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.5977, y: 0.4896},
                        br: {x: 0.7402, y: 0.6081},
                        compID: 14
                    },
                    "fan 1": {
                        tl: {x: 0.1172, y: 0.1003},
                        br: {x: 0.2344, y: 0.2799},
                        compID: 25
                    },
                    "fan 2": {
                        tl: {x: 0.1172, y: 0.3438},
                        br: {x: 0.2344, y: 0.5065},
                        compID: 25
                    },
                    "fan 3": {
                        tl: {x: 0.1172, y: 0.5651},
                        br: {x: 0.2344, y: 0.7357},
                        compID: 25
                    }
                },
                applicableForSuffix: "GS",
                applicableForCircuitCount: 1,
                applicableForMaxSize: 3,
                applicableForFamily: "CR",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 768
            },
            {
                name: "CRS_320_CW",
                type: "png",
                positionInfo: {
                    "humi": {
                        tl: {x: 0.1289, y: 0.7552},
                        br: {x: 0.2227, y: 0.8776},
                        compID: 35
                    },
                    "ge/cw valve 1": {
                        tl: {x: 0.6016, y: 0.7526},
                        br: {x: 0.7520, y: 0.8776},
                        compID: 14
                    },
                    "fan 1": {
                        tl: {x: 0.1016, y: 0.0911},
                        br: {x: 0.2266, y: 0.2708},
                        compID: 25
                    },
                    "fan 2": {
                        tl: {x: 0.1016, y: 0.3307},
                        br: {x: 0.2266, y: 0.5039},
                        compID: 25
                    },
                    "fan 3": {
                        tl: {x: 0.1016, y: 0.8457},
                        br: {x: 0.2266, y: 0.7396},
                        compID: 25
                    }
                },
                applicableForSuffix: "CW",
                applicableForCircuitCount: 0,
                applicableForMaxSize: 3,
                applicableForFamily: "CR",
                applicableForVersion: "S",
                picSizeX: 512,
                picSizeY: 768
            }
        ];
        var canvas = null;
        var backColor = null;
        var obj = null;
        var borderColor = "#e30613";

        /**
         * @description function to create the basic canvas to display the detail picture with overlay
         */
        var createCanvas = function () {
            canvas = document.createElement("canvas");
            canvas.id = "maskCanvas";
            canvas.style.position = "absolute";
            canvas.style.display = "none";
            canvas.style.left = $('#detailPicContainer').position().left + "px";
            canvas.style.top = $('#detailPicContainer').position().top + "px";
            document.getElementById("detailPicContainer").appendChild(canvas);
        };

        /**
         * @description function to get the appropriate picture for the provided part information
         * @param {object} partInfo part information object to use
         * @returns {*} returns false if no picture was found, otherwise the image information for the picture is returned
         */
        var getInfoObjectByPartInfo = function (partInfo) {
            var cSystem = partInfo.cSystem;
            if (partInfo.family !== 'CR') {
                if (cSystem === "GE1" || cSystem === "GE2" || cSystem === "GES") cSystem = "GE";
                if (cSystem === "GS") cSystem = "G";
            }
            if (cSystem === "GSCW") cSystem = "GCW";
            if (cSystem === "ASCW") cSystem = "ACW";
            if (cSystem === "AS") cSystem = "A";
            var obj = imageInfo.filter(function (el) {
                return el.applicableForFamily === partInfo.family
                    && el.applicableForSuffix === cSystem
                    && el.applicableForCircuitCount === partInfo.circuits;
                // && el.applicableForMaxSize >= partInfo.size;
            });
            if (partInfo.family === "CR") {
                if (obj.length === 0) return false;
                if (obj.length === 1) return obj[0];
                if (obj.length > 1) {
                    var obj2 = obj.filter(function (el) {
                        return el.applicableForMaxSize >= partInfo.size;
                    });
                    if (obj2.length) {
                        return obj2[0];
                    }
                    else {
                        return false;
                    }
                }
            }
            else {
                if (obj.length > 0) {
                    return obj[0];
                }
                else {
                    return false;
                }
            }
        };

        /**
         * @description function to update the detail picture canvas with a picture suitable for the provided part information object
         * @param {object} partInfo part information object to use
         */
        var buildDetailView = function (partInfo) {
            var info = getInfoObjectByPartInfo(partInfo);
            if (info) {
                obj = info;
                updateCanvas(info);
                updatePic(info);
            }
        };

        /**
         * @description function to build the mask overlay
         * @param {object} posInfo object describing the mask position
         */
        var buildMask = function (posInfo) {
            var w = $('#detailPic').width();
            var h = $('#detailPic').height();
            canvas.width = w;
            canvas.height = h;
            var ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, w, h);
            ctx.fillStyle = "rgba(" + backColor[0] + "," + backColor[1] + "," + backColor[2] + ",0.7)";
            ctx.fillRect(0, 0, w, h);
            if (posInfo.hasOwnProperty("tl")) {
                clearRect(posInfo.tl, posInfo.br, ctx, w, h);
            }
            else {
                var keys = Object.keys(posInfo);
                var objectCount = 0;
                for (var i = 0; i < keys.length; i++) {
                    if (keys[i].indexOf("tl") !== -1) {
                        objectCount++;
                    }
                }
                for (var i = 0; i < objectCount; i++) {
                    clearRect(posInfo["tl" + i], posInfo["br" + i], ctx, w, h);
                }
            }
        };

        /**
         * @description function update the detail picture
         * @param {object} inf image information object
         */
        var updatePic = function (inf) {
            $('#detailPic').attr("src", "./assets/images/detailview/" + inf.name + "." + inf.type);
            $('#detailPic').on("load", function (e) {
                $('#detailPicContainer > canvas').remove();
                getBackColor();
                createCanvas(inf);
            });
        };

        /**
         * @description function to update the canvas to fit the picture described by provided information object
         * @param {object} info detail picture information object
         */
        var updateCanvas = function (info) {
            var width = $('#outerPictureContainer').width() - 2;
            if (info.picSizeX == info.picSizeY) {
                $('#detailPicContainer').css("height", width + "px");
            }
            else {
                $('#detailPicContainer').css("height", (width * 1.5) + "px");
            }
        };

        /**
         * @description function to clear rectangular area in mask overlay
         * @param {object} tl 2-dimensional vector object describing the top left corner position for the area to clear
         * @param {object} br 2-dimensional vector object describing the bottom right corner position for the area to clear
         * @param {object} ctx the canvas context to use
         * @param {number} w the width of the detail picture that is currently used
         * @param {number} h the height of the detail picture that is currently used
         */
        var clearRect = function (tl, br, ctx, w, h) {
            var minPixX = Math.round(w * tl.x);
            var minPixY = Math.round(h * tl.y);
            var maxPixX = Math.round(w * br.x);
            var maxPixY = Math.round(h * br.y);
            var deltaPixX = maxPixX - minPixX;
            var deltaPixY = maxPixY - minPixY;
            ctx.clearRect(minPixX, minPixY, deltaPixX, deltaPixY);
            ctx.strokeStyle = borderColor;
            ctx.rect(minPixX, minPixY, deltaPixX, deltaPixY);
            ctx.lineWidth = 3;
            ctx.stroke();
        };

        /**
         * @description function to set the background color of the detail picture modal
         */
        var getBackColor = function () {
            backColor = $('#detailPicContainer').css("background-color");
            var ii = backColor.substring(backColor.indexOf("(") + 1);
            ii = ii.substring(0, ii.indexOf(")"));
            ii = ii.split(",");
            backColor = ii;
        };

        /**
         * @description function to show the overlay mask canvas
         */
        var showMaskCanvas = function () {
            $('#maskCanvas').show();
        };

        /**
         * @description function to hide the overlay mask canvas
         */
        var hideMaskCanvas = function () {
            $('#maskCanvas').hide();
        };

        /**
         * @description function to remove the mask canvas
         */
        var clearMaskCanvas = function () {
            $('#maskCanvas').remove();
            canvas = null;
        };

        /**
         * @description function to mark a component in the detail picture
         * @param {number} component the component id describing the component to mark
         */
        var markComponent = function (component) {
            var posInfo = undefined;
            if (obj !== null) {
                for (var i in obj.positionInfo) {
                    if (obj.positionInfo[i].compID == component) {
                        posInfo = obj.positionInfo[i];
                        break;
                    }
                }
            }
            if (posInfo !== undefined && posInfo) {
                buildMask(posInfo);
                showMaskCanvas();
            }
        };

        /**
         * @description function to undo the marking of a specific component
         */
        var unmarkComponent = function () {
            hideMaskCanvas();
        };

        return {
            showMaskCanvas: showMaskCanvas,
            hideMaskCanvas: hideMaskCanvas,
            markComponent: markComponent,
            unmarkComponent: unmarkComponent,
            clearMaskCanvas: clearMaskCanvas,
            buildDetailView: buildDetailView,
            getInfoObject: getInfoObjectByPartInfo
        };
    });
})();
