(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name alarmModal
     */
    angular
        .module('emsv2App')
        .component('locationEmptyModal', {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/location/locationEmptyModal.html',
            controller: ['$log', 'Location', '$state',
                function ($log, Location, $state) {
                    var ctrl = this;
                    ctrl.chosenLocation = {};

                    ctrl.cancel = function () {
                        ctrl.dismiss();
                    };

                    ctrl.$onInit = function () {
                        ctrl.locationId = ctrl.resolve.locationId;

                    };
                    ctrl.$onDestroy = function () {
                        ctrl.dismiss();
                    };
                    ctrl.deleteLocation = function () {
                        if (ctrl.locationId) {
                            Location.delete(ctrl.locationId).then(function (response) {
                                if (response.data && response.data === true) {
                                    // $state.go('locations');
                                    ctrl.dismiss();
                                }
                                else {
                                    ctrl.dismiss();
                                }
                            });

                        }
                    }
                }]
        });

})();


