(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name Tools
     * @requires $http, EMSConstants
     */
    angular.module('emsv2App')
        .service('Tools', ['$http', 'EMSConstants', function ($http, EMSConstants) {
            var tempSettingUser = null;

            function _isDefinedNotNull(val) {
                return angular.isDefined(val) && val !== null;
            }

            function _isEquals(obj1, obj2){
                return angular.equals(obj1, obj2);
            }

            function _calcTempUnit(dataPoint, tempSettings) {
                if (tempSettings === 0 || !_isDefinedNotNull(tempSettings)) return dataPoint;
                var dataPointArray = dataPoint.split(" ");
                var numString = dataPointArray[0].replace(",", ".");
                var decimals = calcDecimals(numString);
                var factor = calcFactor(decimals);
                var float = parseFloat(numString);
                if (_isDefinedNotNull(float) && !isNaN(float)) {
                    var newTemp;
                    if (tempSettings === 1) {
                        newTemp = (Math.round((float * 1.8 + 32) * factor) / factor).toFixed(decimals);
                        return (newTemp + " °F").replace('.', ',');
                    } else if (tempSettings === 2) {
                        newTemp = (Math.round((float + 273.15) * factor) / factor).toFixed(decimals);
                        return (newTemp + " K").replace('.', ',');
                    }
                } else {
                    return dataPoint;
                }
            }

            function calcDecimals(number) {
                var array = number.split('.');
                return array[1].length;
            }

            function calcFactor(decimals) {
                var factor = "";
                for (var iteration = 0; iteration < decimals; iteration++) {
                    factor = factor + "0"
                }
                return parseInt("1" + factor);
            }

            return {
                calcTempUnit: function (value, temp) {
                    return _calcTempUnit(value, temp);
                },
                isDefinedNotNull: function (value) {
                    return _isDefinedNotNull(value);
                },
                isEquals: function(obj1, obj2){
                    return _isEquals(obj1,obj2);
                },
                getHwTyp: function (hwtyp) {
                    switch (hwtyp) {
                        case 1:
                            return 'DigiENERGY';
                        case 2:
                            return 'Bluebox';
                        case 3:
                            return 'LAN Gateway';
                        case 4:
                            return 'CompTrol Interface 4WEB';
                        case 5:
                            return 'DigiWEB CT';
                        case 6:
                            return 'Other';
                        case 7:
                            return 'Varcon 380';
                        case 8:
                            return 'SDCIM';
                    }
                    return 'Unknown';
                },
                intToHex: function (int) {
                    return ('000000000000' + parseInt(int).toString(16)).substr(-12);
                },
                hexToInt: function (hex) {
                    return parseInt(hex, 16);
                },
                formatMac: function (mac) {
                    return mac.match(/.{1,2}/g).join(':');
                },
                escapeUrl: function (url) {
                    if (this.isDefinedNotNull(url)) {
                        url = String(url).replace('/', '%2F');
                    }
                    return url;
                },
                checkMac: function (mac) {
                    var macPattern = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
                    return macPattern.test(mac);
                },
                checkIp: function (ip) {
                    var ipPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
                    return ipPattern.test(ip);
                },
                getBaseUrl: function () {
                    return $http.get('api/baseurl');
                },
                isStateWithoutAuthReq: function (stateName) {
                    return stateName === 'register' ||
                        stateName === 'login' ||
                        stateName === 'imprint' ||
                        stateName === 'terms' ||
                        stateName === 'password.forgot' ||
                        stateName === 'password.reset' ||
                        stateName === 'privacy' ||
                        stateName === 'maintenance' ||
                        stateName === 'data' ||
                        stateName === 'activate';
                },
                /** Concat URL for coordination lookup service. */
                getFindLocationUrl: function (street, zip, city) {
                    var url = EMSConstants.constants.mapUrl + '/?';
                    var result = 'address=';
                    if (this.isDefinedNotNull(street)) {
                        result += street;
                    }
                    if (this.isDefinedNotNull(zip)) {
                        if (this.isDefinedNotNull(street)) {
                            result += ',';
                        }
                        result += zip;
                    }
                    if (this.isDefinedNotNull(city)) {
                        if (this.isDefinedNotNull(zip)) {
                            result += ' ' + city;
                        } else {
                            result += ',' + city;
                        }
                    }
                    return url + result;
                },
                formatCustomValue: function (suffixes, num) {
                    var customLabelList = suffixes.split("|");
                    var result = customLabelList.filter(
                        function (value) {
                            var string = value.substring(0, value.indexOf("="));
                            if (num.toString() == string) {
                                return true
                            }
                        }
                    );
                    if (result && result.length > 0) {
                        var resultString = result[0].substring(result[0].indexOf("=") + 1);
                        return resultString;
                    }
                    return "";

                },
                // Digitronic Anitiny formatter
                // https://git.digitronic.com/lib_js/anitiny/blob/master/format.js
                formatLiveValue: function (fmt, num, tempSetting) {
                    function _getFormatInfos(format) {
                        var ret = new Object();
                        ret.komma = 0;
                        ret.format = format;
                        var help = ret.format.match(/#/g);
                        ret.anDigits = help ? help.length : 0;
                        help = ret.format.match(/(#[\*BXD])([#]*)/);
                        if (help) {
                            switch (help[1]) {
                                case "#*":
                                    ret.komma = -1;
                                    help[1] = "#\\*";
                                    break;
                                case "#B":
                                    ret.komma = -2;
                                    break;
                                case "#X":
                                    ret.komma = -3;
                                    break;
                                case "#D":
                                    ret.komma = -4;
                                    break;
                            }
                            ret.format = ret.format.replace(new RegExp(help[1] + "#*", "g"), "--#--");
                            ret.anDigits--;
                            return ret;
                        } else {
                            help = ret.format.match(/[\.,][#]*/);
                            if (help && help[0].length) {
                                ret.komma = help[0].length - 1;
                            }
                            return ret;
                        }
                    }

                    if (this.isDefinedNotNull(tempSetting)) {
                        tempSettingUser = tempSetting;
                        return buildData();
                    } else {
                        return buildData();
                    }

                    function buildData() {
                        num = parseInt(num);
                        var ret = "";
                        var vz = 0;
                        var fmtInfos = _getFormatInfos(fmt);
                        var format = fmtInfos.format;

                        if (_isDefinedNotNull(fmtInfos) && _isDefinedNotNull(fmtInfos.komma)
                            && fmtInfos.komma === 0 && fmtInfos.format === "###") {
                            var indValue = num / 10;
                            return indValue.toString();
                        }

                        if (fmtInfos.komma < 0) {
                            switch (fmtInfos.komma) {
                                case -1:
                                    ret = num.toString();
                                    break;
                                case -2:
                                    if (num < 0) {
                                        num = 0x100000000 - (-num);
                                    }
                                    ret = (parseInt(num).toString(2));
                                    break;
                                case -3:
                                    if (num < 0) {
                                        num = 0x100000000 - (-num);
                                    }
                                    ret = (parseInt(num).toString(16)).toUpperCase();
                                    break;
                                case -4:
                                    if (num < 0 && fmtInfos.anDigits) {
                                        vz++;
                                        num *= -1;
                                    }
                                    ret = (parseInt(num).toString(10));
                                    break;
                            }
                            if (fmtInfos.anDigits) {
                                ret = "00000000000000000000000000000000" + ret;
                                ret = ret.substr(ret.length - fmtInfos.anDigits);
                                if (vz) {
                                    ret = "-" + ret.substr(1);
                                }
                            }
                            return format.replace(/--#--/, ret);
                        }
                        if (format === "") {
                            return "" + num;
                        }
                        if (!fmtInfos.anDigits || num === "") {
                            return format.replace(/#/g, "");
                        }

                        var strNum = num.toString();
                        if (_isDefinedNotNull(fmtInfos) && _isDefinedNotNull(fmtInfos.komma)
                            && fmtInfos.komma > 0) {
                            var kommaIndex = strNum.length - fmtInfos.komma;
                            ret = strNum.substring(0, kommaIndex) + "," + strNum.substring(kommaIndex, strNum.length);
                            if (kommaIndex <= 0) {
                                ret = 0 + ret;
                            }
                        } else {
                            ret = strNum;
                        }
                        var unitMatch = format.match(/\s.*/);
                        if (unitMatch && unitMatch[0].length > 0) {
                            ret += unitMatch[0];
                        }
                        if (fmtInfos.format.indexOf("°C") !== -1) {
                            return _calcTempUnit(ret, tempSettingUser);
                        } else {
                            return ret;
                        }
                    }
                }
            };
        }])

})();
