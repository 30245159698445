(function () {
    'use strict';
    /**
     * @function ThemeService
     * @description Service to handle themes
     */

    angular.module("emsv2App").service('ThemeService', function ($window, $timeout, Tools,  localStorageService) {

        /**
         * @description Function to switch between themes without reloading (currently not used)
         * @param theme
         * changed cookies to localstorage--> Fix for DC-1481
         */
        var changeTheme = function (theme) {
            if (theme === undefined) {
               if (parseInt(localStorageService.get('THEME')).get("THEME") === "0") {
                    $('#themecss').attr('href', '/ci/css/main-dark.css');
                    $('#themeinspina').attr('href', '/ci/css/inspinia-dark.css');
                     localStorageService.put("THEME", "1");
                }
              else {
                    $('#themecss').attr('href', '/ci/css/main.css');
                    $('#themeinspina').attr('href', '/ci/css/inspinia.css');
                   localStorageService.put("THEME", "0");
               }
            }
            else {
                if (theme === "0") {
                    $('#themecss').attr('href', '/ci/css/main-light.css');
                    $('#themeinspina').attr('href', '/ci/css/inspinia-light.css');
                }
                if (theme === "1") {
                    $('#themecss').attr('href', '/ci/css/main-dark.css');
                    $('#themeinspina').attr('href', '/ci/css/inspinia-dark.css');
                }
            }
        };

        /**
         * @description Function to set theme data
         */
        var reloadPage = function () {
            if (localStorageService.get('THEME') === 0) {
                $('#themecss').attr('href', 'assets/ci/cyberhub-light/css/main.css');
                $('#themeinspina').attr('href', 'assets/ci/cyberhub-light/css/inspinia.css');
            }
            if (localStorageService.get('THEME') === 1) {
                $('#themecss').attr('href', 'assets/ci/cyberhub-light/css/main-dark.css');
                $('#themeinspina').attr('href', 'assets/ci/cyberhub-light/css/inspinia-dark.css');
            }
        };

        /**
         * @description Function to handle user theme information
         * @param {Object} account object containing current users account information
         */
        var check = function (account) {
            if (Tools.isDefinedNotNull(account) && Tools.isDefinedNotNull(account.settings)) {
                angular.forEach(account.settings, function (s) {
                    if (s.key === "theme") {
                         if (!Tools.isDefinedNotNull(parseInt(localStorageService.get('THEME'))) ||
                            (Tools.isDefinedNotNull(parseInt(localStorageService.get('THEME'))) && parseInt(localStorageService.get('THEME')) !== parseInt(s.value))) {
                            localStorageService.set('THEME', parseInt(s.value));
                            reloadPage();
                        }
                    }
                });
            }
        };

        return {
            changeTheme: changeTheme,
            reloadPage: reloadPage,
            check: check
        }
    });
})();
