function QuickbarSettings(id, userId, settings) {
    this.id = id !== undefined ? id : null;
    this.userId = userId !== undefined ? userId : null;
    this.settings = settings !== undefined ? settings : null;
}

QuickbarSettings.prototype = {};
QuickbarSettings.prototype.constructor = QuickbarSettings;
QuickbarSettings.prototype.validate = function () {
    if (this.id === undefined || this.id === null) return false;
    if (this.userId === undefined || this.userId === null) return false;
    if (this.settings === undefined || this.settings === null || this.settings === '') return false;
    return true;
};
QuickbarSettings.prototype.parseForBackend = function () {
    this.settings = JSON.stringify(this.settings);
};

QuickbarSettings.parseFromJSON = function (obj) {
    if (obj !== null && obj !== undefined) {
        var settingsObj = new QuickbarSettings(obj.id, obj.userId, obj.settings);
        settingsObj.settings = JSON.parse(obj.settings);
        return settingsObj;
    }
};

