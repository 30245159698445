(function () {
    'use strict';

    /**
     * @function FileReaderService
     * @description Service to read images from client (used for room editor)
     */
    angular.module("emsv2App").service("FileReaderService", function () {
        var reader = new FileReader();
        /**
         * Function to read images from client. Accepts gif, png, jpeg, jpg, tiff files.
         * @param file path to file
         * @param progress progress event callback
         * @param loaded finish load event callback - important callback, handle image result here
         * @param error error load event callback
         **/
        var readImage = function (file, progress, loaded, error) {
            var filter = /^(image\/gif|image\/png|image\/jpeg|image\/jpg|image\/tiff)$/i;
            if (!loaded) return -2;
            if (!filter.test(file.type)) return -1;
            if (progress) reader.onprogress = progress;
            if (loaded) reader.onload = loaded;
            if (error) reader.onerror = error;
            reader.readAsDataURL(file);
        };

        return {
            readImage: readImage
        }
    });
})();
