(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name EMSConstants
     * @requires $http, $log, $q
     */
    angular.module('emsv2App').service("EMSConstants", function ($http, $log, $q) {
        var ctrl = this;
        var constants = {};

        ctrl.$onInit = function () {
            _init();
        };

        function _init() {
            return $http.get('/api/constants').then(function (response) {
                angular.merge(constants, response.data);
                return constants;
            }, function (error) {
                $log.error('Couldn\'t load constants!', error);
                return $q.reject(error);
            });
        }

        return {
            constants: constants,
            init: _init
        }
    });
})();
