/**
 * Created by tp on 04.07.17.
 */
(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetgauge', function () {
           return {
            restrict: 'E',
            controller: 'WidgetGaugeController',
            controllerAs: 'gaugeCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/gauge/widget.gauge.template.html'
        };
    });
})();
