(function () {
    'use strict';

    function sidebarMinimalizer() {
        return {
            restrict: 'AE',
            template: '<a class="sidebar-minimalizer navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',
            controller: function ($scope, sidebarToggler, MessageService) {
                $scope.minimalize = function () {
                    sidebarToggler.close(MessageService);
                };
            }
        };
    }

    function sidebarToggler() {
        return {
            close: function (msg) {
                var body = angular.element('body');
                var sideMenu = angular.element('#side-menu');

                body.toggleClass('mini-navbar');
                if (!body.hasClass('mini-navbar') || body.hasClass('body-small')) {
                    // Hide menu in order to smoothly turn on when maximize menu
                    sideMenu.hide();
                    // For smoothly turn on menu
                    setTimeout(function () {
                        sideMenu.fadeIn(500, function () {
                            msg.publish("sidebar-change");
                        });
                    }, 100);
                }
                else if (body.hasClass('fixed-sidebar')) {
                    sideMenu.hide();
                    setTimeout(function () {
                        sideMenu.fadeIn(500, function () {
                            msg.publish("sidebar-change");
                        });
                    }, 300);
                }
                else {
                    // Remove all inline style from jquery fadeIn function to reset menu state
                    sideMenu.removeAttr('style');
                    setTimeout(function () {
                        msg.publish("sidebar-change");
                    }, 300);
                }

            }
        };
    }

    angular
        .module('emsv2App')
        .service('sidebarToggler', sidebarToggler)
        .directive('sidebarMinimalizer', sidebarMinimalizer);

})();
