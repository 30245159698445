(function () {

    'use strict';

    angular.module('emsv2App')
        .service('Account', function Account($http) {
            function getAccount() {
                return $http.get("api/account");
            }

            function updateAccount(currentUser) {
                return $http.post("api/account", currentUser);
            }

            return {
                getAccount: getAccount,
                updateAccount: updateAccount
            };
        });

})();
