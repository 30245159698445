(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Buildings
     * @requires $http
     */
    angular.module('emsv2App').service('Buildings', function ($http, Tools) {

        var _setupDevice = function (buildings, device) {
            for (var i = 0; i < buildings.length; i++) {
                for (var j = 0; j < buildings[i].devices.length; j++) {
                    buildings[i].devices[j].macStr = Tools.formatMac(Tools.intToHex(buildings[i].devices[j].mac));
                    if (device.mac === buildings[i].devices[j].mac) {
                        // Copy meta information to temp object
                        buildings[i].devices[j].hwtyp = angular.copy(device.hwtyp);
                        buildings[i].devices[j].name = device.name;
                        return buildings[i];
                    }
                }
            }
            return null;
        };

        var _setupRoom = function (buildings, room) {
            for (var i in buildings) {
                if (!buildings[i].hasOwnProperty('roomsFull')) buildings[i].roomsFull = [];
                for (var j in buildings[i].rooms) {
                    if (room.id === buildings[i].rooms[j].fkRoom) {
                        return buildings[i];
                    }
                }
            }
            return null;
        };

        var iconOk = L.icon({
            iconUrl: 'assets/ci/cyberhub-light/images/icon_map_location_ok.png',
            shadowUrl: 'assets/ci/cyberhub-light/images/marker-shadow.png',
            iconSize: [25, 41],
            shadowSize: [41, 41],
            iconAnchor: [12, 41],
            shadowAnchor: [12, 41],
            popupAnchor: [0, -41]
        });

        var iconDefault = new L.Icon.Default();

        return {
            getByLocation: function (locationId) {
                return $http.get('api/building/bylocation/' + locationId);
            },
            getBuilding: function (buildingId) {
                return $http.get('api/building/' + buildingId);
            },
            getBuildingName: function (buildingId) {
                return $http.get('api/building/name/' + buildingId);
            },
            getBuildingsForLocations: function (locIds) {
                return $http.post('api/building/byLocationIds', locIds);
            },
            save: function (building) {
                return $http.post('api/building', building);
            },
            getAll: function () {
                return $http.get('api/building');
            },
            delete: function (buildingId) {
                return $http.delete('api/building/byid/' + buildingId);
            },
            addDevice: function (buildingDevice) {
                return $http.post('api/building/device', buildingDevice);
            },
            deleteDevice: function (buildingDevice) {
                return $http.delete('api/building/byid/' + buildingDevice.fkBuilding + '/' + buildingDevice.mac);
            },
            setupDevices: function (buildings, devices) {
                for (var i = 0; i < devices.length; i++) {
                    var building = _setupDevice(buildings, devices[i]);
                    devices[i].macStr = Tools.formatMac(Tools.intToHex(devices[i].mac));
                    devices[i].buildingId = (building !== null) ? building.id : null;
                }
            },
            setupRooms: function (buildings, rooms) {
                for (var i in rooms) {
                    var building = _setupRoom(buildings, rooms[i]);
                    rooms[i].buildingId = (building !== null) ? building.id : null;
                    if (building !== null) {
                        if (!building.hasOwnProperty("roomsFull")) buildings.roomsFull = [];
                        building.roomsFull.push(rooms[i]);
                    }
                }
            },
            addRoom: function (building, room) {
                if (!building.hasOwnProperty('rooms')) building.rooms = [];
                building.rooms.push(new BuildingRoom(room.id, building.id));
                if (!building.hasOwnProperty('roomsFull')) building.roomsFull = [];
                building.roomsFull.push(room);
                room.buildingId = building.id;
            },
            removeRoom: function (building, room) {
                for (var i in building.rooms) {
                    if (building.rooms[i].fkRoom === room.id) {
                        building.rooms.splice(i, 1);
                        break;
                    }
                }
                for (var i in building.roomsFull) {
                    if (building.roomsFull[i].id === room.id) {
                        building.roomsFull.splice(i, 1);
                        break;
                    }
                }
                room.buildingId = null;
            },
            setupBuildingMarkers: function (location, buildings, currentMap, markerLayer, _onMarkerClick, _onMarkerDragEnd) {
                if (markerLayer !== undefined && markerLayer !== null) {
                    currentMap.removeLayer(markerLayer);
                }
                markerLayer = new L.FeatureGroup();
                var bounds = [];
                for (var i = 0; i < buildings.length; i++) {
                    var building = buildings[i];
                    if (building.lat !== null && building.lon !== null) {
                        // Set map location
                        var latlng = L.latLng(building.lat, building.lon);
                        bounds.push(latlng);
                        var marker = L.marker(latlng, {
                            focus: true,
                            draggable: false,
                            alt: building.name,
                            title: building.name
                        });
                        if (_onMarkerClick !== undefined) marker.on('click', _onMarkerClick);
                        if (_onMarkerDragEnd !== undefined) marker.on('dragend', _onMarkerDragEnd);
                        marker.buildingId = building.id;
                        // marker.setIcon(iconOk);
                        markerLayer.addLayer(marker);
                    }
                }
                currentMap.addLayer(markerLayer);
                currentMap.invalidateSize();
                if (bounds.length > 0) {
                    currentMap.fitBounds(bounds);
                }
                else if (location.pos_lat !== undefined && location.pos_lat !== null && location.pos_lat !== 0
                    && location.pos_lng !== undefined && location.pos_lng !== null && location.pos_lng !== 0) {
                    // If no building was found, center on the location coordinates, if available
                    var locationPos = [location.pos_lat, location.pos_lng];
                    currentMap.setView(locationPos, 18);
                }
                return markerLayer;
            },
            resetBuildingMarkers: function (markerLayer) {
                var selected = null;
                if (markerLayer !== undefined && markerLayer !== null) {
                    markerLayer.eachLayer(function (layer) {
                        if (layer.dragging.enabled()) {
                            selected = layer.buildingId;
                        }
                        layer.setIcon(iconDefault);
                        layer.dragging.disable();
                    });
                }
                return selected;
            },
            markOnMap: function (markerLayer, building, enableDrag) {
                if (markerLayer !== undefined && markerLayer !== null) {
                    var marker = null;
                    markerLayer.eachLayer(function (layer) {
                        if (layer.buildingId === building.id) {
                            layer.setIcon(iconOk);
                            if (enableDrag) {
                                layer.dragging.enable();
                            }
                            else {
                                layer.dragging.disable();
                            }
                            marker = layer;
                        }
                        else {
                            layer.setIcon(iconDefault);
                            layer.dragging.disable();
                        }
                    });
                    if (marker !== null) return marker;
                }
            },
            getIconOk: function () {
                return iconOk;
            },
            getDefaultIcon: function () {
                return iconDefault;
            }
        };
    });

})();
