(function () {
    'use strict';
    angular.module('emsv2App').directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return isNaN(val) ? null : parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    var ret = isNaN(val) ? null : '' + val;
                    return ret;
                });
            }
        }
    });
    angular.module('emsv2App').directive('convertToNumberx', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    });
    angular.module('emsv2App').directive('convertToColor', function () {
        var reg = /^#?(?:[0-9a-fA-F]{3}){1,2}$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    if(!reg.test(val)) return "#000000";
                    if (val.length === 6) {
                        return '#' + val;
                    }
                    if (val.length === 3) {
                        return '#' + val[0] + val[0] + val[1] + val[1] + val[2] + val[2];
                    }
                    if (val.length === 7 && val[0] === "#") {
                        return val;
                    }
                    return '#000000';
                });
                ngModel.$formatters.push(function (val) {
                    if (val.length === 7 && val[0] === '#') {
                        return val.substring(1);
                    }
                    return val;
                });
            }
        }
    });
})();
