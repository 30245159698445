(function () {
    'use strict';

    function buildingNewController($state, $scope, $window, $stateParams, $translate, $log, Notify, Location, LocationDetailService, Buildings, MessageService, devices, buildings, license, rooms, user, GenDialogService) {
        var ctrl = this;
        var initBuilding = null;
        var buildingSaved = false;
        ctrl.user = user;
        ctrl.license = license;
        ctrl.buildings = buildings;
        ctrl.devices = devices;

        ctrl.selectedBuilding = null;
        ctrl.allowRooms = license !== undefined && license.data['3droomedit'] === true;
        ctrl.allowCreate = (ctrl.user.roles.indexOf('ROLE_LOCATION_CREATE') !== -1);

        ctrl.$onInit = function () {
            if (!ctrl.allowCreate) {
                return $state.go("accessdenied");
            }
            else {
                ctrl.selectedBuilding = new Building(parseInt($stateParams.id));
                ctrl.selectedBuilding.roomsFull = [];
                ctrl.selectedBuilding.id = -1;
                ctrl.selectedBuilding.edit = true;
                LocationDetailService.reset();
                LocationDetailService.onBack = function () {
                    $state.go($state.current.parent, undefined, {reload: true});
                };

                LocationDetailService.onSave = ctrl.save;
                LocationDetailService.canSave = function () {
                    return ctrl.selectedBuilding.name !== '';
                };
            }
            initBuilding = angular.copy(ctrl.selectedBuilding);
        };

        ctrl.compareBuildingValues = function (buildingA, buildingB) {

            if (buildingA["name"] !== buildingB["name"]) return true;
            if (buildingA["street"] !== buildingB["street"]) return true;
            if (buildingA["lat"] !== buildingB["lat"]) return true;
            if (buildingA["lon"] !== buildingB["lon"]) return true;
            if (buildingA.devices.length !== buildingB.devices.length) return true;
            for (var i in buildingA.devices){
                if(buildingB.devices.filter(function(building){
                    return buildingA.devices[i].name === building.name;
                }).length === 0){
                    return true
                }
            }

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalBuildingChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalBuildingChange = function () {
            var currBuilding = angular.copy(ctrl.selectedBuilding);
            if (ctrl.compareBuildingValues(initBuilding, currBuilding)) return true
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!ignoreNextStateChange && ctrl.evalBuildingChange() && !buildingSaved) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('location.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('location.modal.empty.yes'),
                    textButton0: $translate.instant('location.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-remove-circle',
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initBuilding = null;
                        LocationDetailService.canChange = true;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        ctrl.save = function () {
            if (ctrl.selectedBuilding.name === "") {
                Notify.error("global.notification.error.title", "building.notifications.nameNecessary", 2000);
                return;
            }

            MessageService.publish('saveBuildingStart');
            var building = angular.copy(ctrl.selectedBuilding);
            buildingSaved = true;
            delete building.positionSelect;
            delete building.edit;
            delete building.roomsFull;
            delete building.autoValue;

            return Buildings.save(building).then(function (response) {
                Notify.success("global.notification.success.title", "building.notifications.createSuccess", 2000);
                $log.debug('Building saved!', response);
                $state.go("location.buildings.detail", {bid: response.data.id, bmode: 'edit'}, {reload: true});
                // MessageService.publish("saveSuccess");
                LocationDetailService.canChange = true;
                return response;
            }, function (error) {
                Notify.error("global.notification.error.title", "building.notifications.createError", 2000);
                MessageService.publish("saveError");
                $log.error('Couldn\'t save building!', error);
            });
        };

    }

    angular
        .module('emsv2App')
        .controller('BuildingNewController', buildingNewController);

})();
