(function () {
    'use strict';

    angular.module("emsv2App").service("WebWorkerService", function () {

        var MAXWORKERS = 4;

        var workers = {};

        var getWorkerCount = function () {
            var count = 0;
            for (var i in workers) {
                count += workers[i] instanceof Array ? workers[i].length : 0;
            }
            return count;
        };

        //public methods
        /**
         * Spawns a new web-worker. Returns false if max worker count is already reached, otherwise custom workerhandle
         * @param workerSourceFile - the path to the sourceFile for the worker
         * @param configObject - object containing all necessary information to properly handle the worker
         * @description configObject must be build like below
         * @description {
         * @description initMsg: required object which is passed to worker on start up
         * @description messageHandler: required callback function - onmessage callback gets event object
         * @description errorHandler: optional callback function - onerror callback for error handling gets ErrorEvent object
         * @description }
         * @description more info for webworkers: https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers
         * @returns {*} false if max worker count is already reached, otherwise custom workerhandle
         */
        var startWorker = function (workerSourceFile, configObject) {
            if (getWorkerCount() == MAXWORKERS) return false;
            if (workers[workerSourceFile] === undefined) {
                workers[workerSourceFile] = [];
            }
            var w = new Worker(workerSourceFile);
            w.onmessage = configObject.messageHandler;
            if (configObject.errorHandler) w.onerror = configObject.errorHandler;
            w.postMessage(configObject.initMsg);
            workers[workerSourceFile].push(w);
            return workerSourceFile + ":" + (workers[workerSourceFile].length - 1);
        };
        /**
         * Kills a specific worker identified by workerhandle
         * @param workerHandle - the workerhandle returned by startWorker function
         */
        var killWorker = function (workerHandle) {
            var temp = workerHandle.split(":");
            workers[temp[0]][temp[1]].terminate();
            workers[temp[0]][temp[1]] = null;
            workers[temp[0]].splice(temp[1], 1);
        };
        /**
         * Gets the worker identified by workerhandle
         * @param workerHandle - the workerhandle returned by startWorker function
         * @returns {*}
         */
        var getWorker = function (workerHandle) {
            var temp = workerHandle.split(":");
            return workers[temp[0]][temp[1]];
        };

        return {
            startWorker: startWorker,
            killWorker: killWorker,
            getWorker: getWorker
        };
    });
})();
