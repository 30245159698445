(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Group
     * @requires $http
     */
    angular.module('emsv2App').service('Group', ['$http', function ($http) {
        return {
            getAll: function () {
                return $http.get('api/groups');
            },
            getAllFlat: function () {
                return $http.get('api/groups/flat');
            },
            getAllUserGroup: function () {
                return $http.get('api/groups/userGroup');
            },
            add: function (group) {
                return $http.post('api/groups', group);
            },
            get: function (groupid) {
                return $http.get('api/groups/' + groupid);
            },
            update: function (group) {
                return $http.put('api/groups', group);
            },
            delete: function (groupid) {
                return $http.delete('api/groups/' + groupid);
            },
            exists: function (groupname) {
                return $http.get('api/groups/exists/' + groupname);
            },
            rsql: function (page, query) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/groups/query/' + page + '/20/' + query);
            }
        };
    }]);

})();
