(function () {
    'use strict';

    angular.module("emsv2App").filter('floortype', function ($translate) {
        return function (input) {
            var transID = "";
            switch (input) {
                case -1 :
                    transID = "room.edit.floors.basement";
                    break;
                case  0 :
                    transID = "room.edit.floors.ground";
                    break;
                case  1 :
                    transID = "room.edit.floors.floor";
                    break;
                default :
                    transID = "room.edit.floors.ground";
            }
            return $translate.instant([transID])[transID];
        }
    });

    angular.module("emsv2App").filter('floortype2', function ($translate) {
        return function (input) {
            input = parseInt(input);
            var ret = Math.abs(input);
            if (input < 0) return ret + " - " + $translate.instant(["room.edit.floors.basement"])["room.edit.floors.basement"];//transID = "room.edit.floors.basement";
            if (input > 0) return ret + " - " + $translate.instant(["room.edit.floors.floor"])["room.edit.floors.floor"];
            if (input === 0) return $translate.instant(["room.edit.floors.ground"])["room.edit.floors.ground"];
        }
    });
})();
