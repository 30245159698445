(function () {

    'use strict';

    angular.module('emsv2App').service('Session', function () {
        var data = [];

        function _get(key) {
            return data[key];
        }

        function _put(key, value) {
            data[key] = value;
        }

        function _pop(key) {
            var result = _get(key);
            delete data[key];
            return result;
        }

        return {
            'put': _put,
            'get': _get,
            'pop': _pop
        };
    });

})();
