(function () {
    'use strict';
    angular.module('emsv2App').service('QuickBarSettingsService', function ($log, $http, Tools) {

        var _save = function (settings) {
            return $http.post('/api/quickbar', settings).then(
                function (response) {
                    var savedQBSettings = QuickbarSettings.parseFromJSON(response.data);
                    return savedQBSettings;
                },
                function (error) {
                    $log.error('error while saving QB settings', error);
                }
            )
        };

        var _get = function (userId) {
            return $http.get('/api/quickbar/' + userId).then(
                function (response) {
                    if (Tools.isDefinedNotNull(response.data) && response.data !== "") {
                        var qbs = QuickbarSettings.parseFromJSON(response.data);
                        return qbs;
                    }
                    return null;
                },
                function (error) {
                    $log.error('error retrieving quickbar settings for user with ID: ' + userId, error);
                }
            )
        };

        var _delete = function (userId) {
            return $http.delete('/api/quickbar/' + userId).then(
                function (response) {
                    return response.data;
                },
                function (error) {
                    $log.error('could not delete quickbar settings for User: ' + userId, error);
                    return error.data;
                }
            )
        };

        return {
            save: _save,
            get: _get,
            delete: _delete
        }
    })
})();

