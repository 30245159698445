(function () {
    'use strict';
    angular.module('emsv2App').service('PushToDeviceService', function ($http) {
        var pushDevice = function (request) {
            return $http.put("/api/pushtodevice", request);
        };
        var checkActivePushProcess = function (processHashId) {
            return $http.get("/api/pushtodevice/activePushProcess/" + processHashId);
        };
        return {
            pushDevice: pushDevice,
            checkActivePushProcess: checkActivePushProcess
        }
    });
})();
