(function () {

    'use strict';

    function paramController($state, Parameters, $scope, $interval, $uibModal, Principal, $translate, ChangeService, $log,
                             PhysicalTypeService, EMSConstants, parameters, Notify) {

        var pctrl = this;
        var modalInstance = null;

        pctrl.entityType = {};
        pctrl.physicalTypes = [];
        pctrl.userData = [];
        pctrl.update = false;
        pctrl.assetParams = null;
        pctrl.coolingParams = null;
        pctrl.otherParams = null;
        pctrl.EntityTranslation = null;
        pctrl.activeTab = 0;
        pctrl.date = null;
        pctrl.alerts = [];
        pctrl.timeoutId = null;
        pctrl.timeoutInterval = 3000;
        pctrl.tableModelAssets = {
            head: [
                {name: 'name', translation: 'parameters.table.head.name'},
                {name: 'entityType', translation: 'parameters.table.head.type', sortable: false},
                {name: 'physType', translation: 'parameters.table.head.physType'},
                {empty: true}
            ],
            table: pctrl.assetParams,
            orderBy: 'name',
            orderDir: true
        };
        pctrl.tableModelCoolings = {
            head: [
                {name: 'name', translation: 'parameters.table.head.name'},
                {name: 'entityType', translation: 'parameters.table.head.type', sortable: false},
                {name: 'physType', translation: 'parameters.table.head.physType'},
                {empty: true}
            ],
            table: pctrl.coolingParams,
            orderBy: 'name',
            orderDir: true
        };
        pctrl.tableModelOther = {
            head: [
                {name: 'name', translation: 'parameters.table.head.name'},
                {name: 'entityType', translation: 'parameters.table.head.type'},
                {name: 'physType', translation: 'parameters.table.head.physType'},
                {empty: true}
            ],
            table: pctrl.otherParams,
            orderBy: 'name',
            orderDir: true
        };

        var NOTIFY_TIMEOUT = 4000;

        pctrl.doSortAssets = function (sortField, sortReverse) {
            pctrl.tableModelAssets.orderBy = sortField;
            pctrl.tableModelAssets.orderDir = sortReverse;
            _sortAssets(sortField, sortReverse);
        };
        pctrl.doSortCoolings = function (sortField, sortReverse) {
            pctrl.tableModelCoolings.orderBy = sortField;
            pctrl.tableModelCoolings.orderDir = sortReverse;
            _sortCoolings(sortField, sortReverse);
        };
        pctrl.doSortOther = function (sortField, sortReverse) {
            pctrl.tableModelOther.orderBy = sortField;
            pctrl.tableModelOther.orderDir = sortReverse;
            _sortOther(sortField, sortReverse);
        };

        function _sortAssets(sortField, sortReverse) {
            if (sortReverse) {
                pctrl.assetParams.sort(function (a, b) {
                    if (sortField === 'name') {
                        return (a[sortField]).localeCompare(b[sortField]);
                    } else {
                        return (a[sortField]) - (b[sortField]);
                    }
                })
            } else {
                pctrl.assetParams.sort(function (a, b) {
                    if (sortField === 'name') {
                        return (b[sortField]).localeCompare(a[sortField]);
                    } else {
                        return (b[sortField]) - (a[sortField]);
                    }
                })
            }
        }

        function _sortCoolings(sortField, sortReverse) {
            if (sortReverse) {
                pctrl.coolingParams.sort(function (a, b) {
                    if (sortField === 'name') {
                        return (a[sortField]).localeCompare(b[sortField]);
                    } else {
                        return (a[sortField]) - (b[sortField]);
                    }
                })
            } else {
                pctrl.coolingParams.sort(function (a, b) {
                    if (sortField === 'name') {
                        return (b[sortField]).localeCompare(a[sortField]);
                    } else {
                        return (b[sortField]) - (a[sortField]);
                    }
                })
            }
        }

        function _sortOther(sortField, sortReverse) {
            if (sortReverse) {
                pctrl.otherParams.sort(function (a, b) {
                    if (sortField === 'name') {
                        return (a[sortField]).localeCompare(b[sortField]);
                    } else {
                        return (a[sortField]) - (b[sortField]);
                    }
                })
            } else {
                pctrl.otherParams.sort(function (a, b) {
                    if (sortField === 'name') {
                        return (b[sortField]).localeCompare(a[sortField]);
                    } else {
                        return (b[sortField]) - (a[sortField]);
                    }
                })
            }
        }

        pctrl.goToState = function (stateString, paramId) {
            $state.go('parameters.' + stateString, {actionType: stateString, parameterId: paramId});
        };

        pctrl.viewParamUsers = function (data) {
            if (data.groupId != null && angular.isDefined(data.groupId)) {
                Parameters.getUserInfo(data.groupId).then(function (response) {
                    if (response.data != null && response.data.length > 0) {
                        pctrl.userData = response.data;
                        modalInstance = $uibModal.open({
                            templateUrl: 'scripts/app/parameters/user.info.modal.html',
                            animation: true,
                            windowClass: 'animated bounceInTop',
                            scope: $scope
                        });
                    } else {
                        pctrl.noUsersInGroupNotify();
                    }
                });
            } else {
                pctrl.addInfo();
                pctrl.noUsersInGroupNotify();
            }
        };

        pctrl.closeModal = function () {
            modalInstance.close();
        };

        pctrl.editParam = function (data) {
            if (data != null) {
                pctrl.goToState("edit", data.id);
            }
        };

        pctrl.viewParam = function (data) {
            if (data != null) {
                pctrl.goToState("view", data.id);
            }
        };

        pctrl.deleteParam = function (data) {
            pctrl.deletedParam = data;
            if (modalInstance) modalInstance.close();
            modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/parameters/parameter.delete.modal.html',
                animation: true,
                windowClass: 'animated bounceInTop',
                scope: $scope
            });
        };

        pctrl.delete = function () {
            if (modalInstance) modalInstance.close();
            if (pctrl.deletedParam !== null) {
                Parameters.deleteParam(pctrl.deletedParam.id).then(function (response) {
                    if (response.data === -3) {
                        Notify.error("global.notification.error.title", "global.notification.error.nullParameterId", 4000);
                    } else if (response.data === -2) {
                        Notify.error("global.notification.error.title", "global.notification.error.notFoundParameter", 4000);
                    } else if (response.data === -1) {
                        pctrl.parameterInUseNotify();
                    } else if (response.data === 0) {
                        Notify.error("global.notification.error.title", "global.notification.error.deleteParameter", 4000);
                    } else if (response != null && response.data === 1) {
                        Notify.success("global.notification.success.title", "global.notification.success.deleteParameter", 4000);
                        pctrl.reloadParams();
                        pctrl.deletedParam = null;
                        $state.go('parameters', {activeTab: 0}, {reload: true});
                    }
                }, function (error) {
                    pctrl.parameterInUseNotify();
                })
            }
        };

        pctrl.hasRole = function (role) {
            var roles = Principal.isInRole('ROLE_' + role);
            return roles;
        };
        pctrl.addInfo = function () {
            pctrl.alerts.push({
                type: 'info',
                msg: $translate.instant(["parameters.tooltip.paramNoGroup"])["parameters.tooltip.paramNoGroup"]
            });
        };

        pctrl.closeAlert = function (index) {
            pctrl.alerts.splice(index, 1);
        };

        pctrl.toggleUpdate = function () {
            if (angular.isDefined(pctrl.timeoutId) && pctrl.timeoutId != null) {
                $interval.cancel(pctrl.timeoutId);
            } else {
                pctrl.timeoutId = $interval(function () {
                    pctrl.continuouslyUpdateParamData();
                }, pctrl.timeoutInterval);
            }
        };

        pctrl.deleteUniqueId = function (uniqueId) {
            var found = false;
            for (var asset in pctrl.assetParams) {
                if (pctrl.assetParams[asset].uniqueId === uniqueId) {
                    pctrl.assetParams.splice(asset, 1);
                    found = true;
                }
            }
            if (!found) {
                for (var cooling in pctrl.coolingParams) {
                    if (pctrl.coolingParams[cooling].uniqueId === uniqueId) {
                        pctrl.coolingParams.splice(cooling, 1);
                        found = true;
                    }
                }
            }
            if (!found) {
                for (var other in pctrl.otherParams) {
                    if (pctrl.otherParams[other].uniqueId === uniqueId) {
                        pctrl.otherParams.splice(other, 1);
                        found = true;
                    }
                }
            }
            if (found) {
                pctrl.reloadParams();
            }
            return found;
        };

        pctrl.continuouslyUpdateParamData = function () {
            var uidList = [];
            pctrl.activeParamList = null;

            if (pctrl.activeTab == 0) {
                angular.forEach(pctrl.assetParams, function (value, key) {
                    uidList.push(value.uniqueId);
                });
            } else if (pctrl.activeTab == 1) {
                angular.forEach(pctrl.coolingParams, function (value, key) {
                    uidList.push(value.uniqueId);
                });
            } else if (pctrl.activeTab == 2) {
                angular.forEach(pctrl.otherParams, function (value, key) {
                    uidList.push(value.uniqueId);
                });
            }
            //Data Type 11
            ChangeService.getListOfOjects(11, uidList, pctrl.date.getTime()).then(function (response) {
                if (angular.isDefined(response) && response != null && response.length > 0) {
                    $log.info("List of Objects: ", response);
                    for (var newParam in response) {
                        var found = false;
                        if (response[newParam].hasOwnProperty('deleted')) {
                            pctrl.deleteUniqueId(response[newParam].uniqueId);
                            continue;
                        }
                        if (response[newParam].entityType == pctrl.entityType.ASSET) { //FIXME
                            pctrl.activeParamList = pctrl.assetParams;
                        } else if (response[newParam].entityType == pctrl.entityType.COOLING) { //FIXME
                            pctrl.activeParamList = pctrl.coolingParams;
                        } else {
                            pctrl.activeParamList = pctrl.otherParams;
                        }

                        for (var oldParam in pctrl.activeParamList) {
                            if (response[newParam].uniqueId === pctrl.activeParamList[oldParam].uniqueId) {
                                pctrl.activeParamList[oldParam] = response[newParam];
                                $log.info("Replaced old Parameter. new Data -> ", response[newParam]);
                                found = true;
                            }
                        }
                        if (!found && !response[newParam].hasOwnProperty('deleted')) {
                            pctrl.activeParamList.push(response[newParam]);
                        }
                    }
                    pctrl.date = new Date();
                } else {
                    $log.info("Params are uptodate.");
                }
            }, function (error) {
                $log.error("Parameters: Could not get changed object:\n", response);
            });
        };

        pctrl.reloadParams = function () {
            Parameters.getAll().then(function (response) {
                updateParameterLists(response.data);
            }, function (error) {
                $log.error("Error retrieving parameters\n" + error);
                return error;
            });
        };

        pctrl.getAssetParams = function () {
            Parameters.getAllForEntityType(pctrl.entityType.ASSET).then(function (response) {
                if (response.data != null) {
                    pctrl.assetParams = response.data;
                }
            });
        };

        pctrl.getCoolingParams = function () {
            Parameters.getAllForEntityType(pctrl.entityType.COOLING).then(function (response) {
                if (response.data != null) {
                    pctrl.coolingParams = response.data;
                }
            });
        };

        pctrl.getOtherParams = function () {
            Parameters.getAllExceptEntityTypes([pctrl.entityType.ASSET, pctrl.entityType.COOLING]).then(function (response) {
                if (response.data != null) {
                    pctrl.otherParams = response.data;
                }
            });
        };

        pctrl.translatePhysicalType = function (physTypeId) {
            var physType = pctrl.physicalTypes.filter(function (pt) {
                return pt.id === physTypeId;
            });
            if (physType.length > 0) {
                return $translate.instant(physType[0].name);
            } else {
                return "";
            }
        };

        pctrl.translateType = function (typeId) {
            return $translate.instant([pctrl.entityTranslation[typeId]])[pctrl.entityTranslation[typeId]];
        };

        pctrl.noUsersInGroupNotify = function () {
            Notify.warning("global.notification.warning.info", "global.notification.warning.noUsersInGroup", 4000);
        };

        pctrl.parameterInUseNotify = function () {
            Notify.error("global.notification.error.title", "global.notification.warning.parameterInUse", 4000);
        };

        // merkt sich den aktiven Tab
        pctrl.setActiveTab = function (index) {
            pctrl.activeTab = index;
        };

        function updateParameterLists(parameterList) {
            pctrl.assetParams = parameterList.filter(function (p) {
                return p.customEntity === true && p.entityType === EMSConstants.constants.EntityType['ASSET'];
            });
            pctrl.coolingParams = parameterList.filter(function (p) {
                return p.customEntity === true && p.entityType === EMSConstants.constants.EntityType['COOLING'];
            });
            pctrl.otherParams = parameterList.filter(function (p) {
                return p.customEntity === true && p.entityType !== pctrl.entityType['ASSET'] && p.entityType !== pctrl.entityType['COOLING'];
            });
        }

        pctrl.$onInit = function () {
            pctrl.entityType = EMSConstants.constants.EntityType;
            pctrl.date = new Date();
            updateParameterLists(parameters);
            PhysicalTypeService.getPhysicalTypes().then(function (response) {
                if (response != null && response.length > 0) pctrl.physicalTypes = response;
            });

            pctrl.entityTranslation = angular.copy(EMSConstants.constants.EntityTranslation);
        };

        $scope.$on('$destroy', function () {
            if (pctrl.timeoutId !== null) $interval.cancel(pctrl.timeoutId);
        });
    }

    angular.module('emsv2App')
        .controller('parameterController', paramController);
})();
