(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('admin', {
                    abstract: false,
                    parent: 'site',
                    url: '/admin',
                    redirectTo: 'userOverview',
                    data: {
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'global.menu.admin.main'
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('admin-dev', {
                    abstract: false,
                    parent: 'admin',
                    url: '/dev',
                    resolve: {
                        devMode: ['$log', '$state', 'localStorageService', function ($log, $state, localStorageService) {
                            var devMode = localStorageService.get('devmode');
                            if (devMode !== undefined && devMode) {
                                localStorageService.remove('devmode');
                                $log.info('devmode disabled');
                            }
                            else {
                                localStorageService.set('devmode', true);
                                $log.info('devmode enabled');
                            }
                            $state.go('userOverview');
                        }]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });

})();
