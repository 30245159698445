(function () {

    'use strict';

    angular.module('emsv2App')
        .directive('filelist', ['$log', 'Files', 'Tools', function ($log, Files, Tools) {

            function _controller($scope) {
                $scope.list = [];
                $scope.path = Tools.isDefinedNotNull($scope.path) ? $scope.path : '/';

                $scope.lvlUp = function () {
                    $scope.path = String($scope.path).substr(0, String($scope.path).lastIndexOf('/'));
                    if ($scope.path === '') {
                        $scope.path = '/';
                    }
                    $scope.loadFiles();
                };

                $scope.open = function (file) {
                    if (file.type === 0) {
                        if (String($scope.path).length > 1) {
                            $scope.path = $scope.path + '/';
                        }
                        $scope.path = $scope.path + file.name;
                        $scope.loadFiles();
                    }
                };

                $scope.select = function (file) {
                    if (!file.selected) {
                        file.selected = true;
                        $scope.selected.push(file);
                    }
                    else {
                        if (file.selected) {
                            for (var i = 0; i < $scope.selected.length; i++) {
                                if ($scope.selected[i].name === file.name) {
                                    $scope.selected.splice(i, 1);
                                }
                            }
                        }
                        else {
                            $scope.selected.push(file);
                        }
                        file.selected = !file.selected;
                    }
                };

                $scope.formatTime = function (file) {
                    return moment(file.changed, 'x').format('DD.MM.YYYY HH:mm:ss');
                };

                $scope.formatSize = function (file) {
                    var result;
                    if (file.size >= 1073741824) { result = Math.floor(file.size / 1073741824) + ' Gb'; }
                    else if (file.size >= 1048576) { result = Math.floor(file.size / 1048576) + ' Mb'; }
                    else if (file.size >= 1024) { result = Math.floor(file.size / 1024) + ' Kb'; }
                    else if (file.size > 1) { result = file.size + ' B'; }
                    else if (file.size === 1) { result = file.size + ' B'; }
                    else { result = '0 B'; }
                    return result;
                };

                $scope.loadFiles = function () {
                    if ($scope.path.contains('.')) {
                        $scope.path = $scope.path.replaceAll('.', '');
                    }
                    if (Tools.isDefinedNotNull($scope.root) && $scope.root !== '' && $scope.root !== '/') {
                        Files.get({path: $scope.root + $scope.path, root: $scope.module}).then(function (response) {
                            $scope.list = response.data;
                            $scope.selected = []; // Empty selection array
                        }, function (error) {
                            $log.error(error);
                        });
                    }
                };

                $scope.$watch('root', function () {
                    $scope.loadFiles();
                });

                // Refresh on demand
                $scope.$on('filelist-refresh', function () {
                    $scope.loadFiles();
                });

                (function () {
                    if (Tools.isDefinedNotNull($scope.root) && Tools.isDefinedNotNull($scope.path)) {
                        $scope.loadFiles();
                    }
                })();

            }

            return {
                restrict: 'E',
                scope: {
                    root: '=',      // root path of this explorer
                    path: '=',      // current path
                    showHidden: '=',
                    selected: '=',
                    module: '='     // Module which is using this directive, eg. updates, backup, etc.
                },
                templateUrl: 'scripts/components/filelist/filelist.html',
                controller: _controller,
                controllerAs: 'fileListCtrl'
            };
        }]);

})();
