(function () {

    'use strict';

    function widgetPUEController($scope, $uibModal, $log, WidgetData, $translate, $http, Tools, Notify, $q, $interval) {

        var ctrl = this;
        var currentModal;

        var LIVE_DATA_INTERVAL = 5000;
        var LIVE_DATA_PIL_DIVIDER = 1000;
        var TO_FIXED_PARAM = 3;

        var updateInterval = null;
        var DIFF_A = "DiffA";
        var DIFF_B = "DiffB";
        ctrl.placeholder = $translate.instant('global.form.select');

        ctrl.pueData = 0.00;
        ctrl.facilityConsumers = null;
        ctrl.facilityConsumersSum = null;
        ctrl.itConsumers = null;
        ctrl.itConsumersSum = null;
        ctrl.sumOfAll = 0.00;

        ctrl.allLocations = null;
        ctrl.allConsumer = [];

        ctrl.tempAllConsumer = [];
        ctrl.allBuildings = null;
        ctrl.selected = {
            location: null,
            building: null,
            facilityList: [],
            itList: []
        };
        /**
         * Listener from angular to listen if the this controller will be "destroyed"
         */
        $scope.$on("$destroy", function () {
            _destroy();
        });

        /**
         * The method to destroy all objects
         * @private
         */
        function _destroy() {
            if (angular.isDefined(updateInterval)) {
                $interval.cancel(updateInterval);
            }
            ctrl.allLocations = null;
            ctrl.allConsumer = [];
            ctrl.tempAllConsumer = [];
            ctrl.allBuildings = null;
            ctrl.selected = {
                location: null,
                building: null,
                facilityList: [],
                itList: []
            };
        }

        /**
         * Angulars init function
         */
        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl.configure);
            _loadSettings();
        };

        /**
         * Function to open the configuration modal
         */
        ctrl.configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/pue/widget.pue.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        };

        /**
         * To close the modal
         */
        ctrl.closeModal = function () {
            if (currentModal) currentModal.close();
        };

        /**
         * Function for the "cancel" button
         */
        ctrl.cancelModal = function () {
            ctrl.closeModal();
        };

        /**
         * Starts the interval to get the live data from backend for each consumer
         * @private
         */
        function _startInterval() {
            if (angular.isDefined(updateInterval)) {
                $interval.cancel(updateInterval);
            }
            //executing request on startup to get data immediately interval will take care of all following requests
            _getLiveData();
            updateInterval = $interval(function () {
                _getLiveData();
            }, LIVE_DATA_INTERVAL);
        }

        function _getLiveData() {
            var promises = [];
            angular.forEach(ctrl.allConsumer, function (consumer) {
                if (!isNaN(consumer.id)) {
                    promises.push($http.get("/api/pue/liveData/" + consumer.id + "/" + ctrl.selected.building.id));
                }

            });
            $q.all(promises).then( function (resolves) {
                angular.forEach(resolves, function (data) {
                    //backend returns type Tuple2. Member "value1" = consumerId, "value2" = actual sensor reading
                    var matchingCons = ctrl.allConsumer.filter(function (cons) { return cons.id === data.data.value1 });
                    //assigning values to "first hit". MatchingCons will contain only one entry
                    if(matchingCons[0] !== undefined ){
                        matchingCons[0].liveData = data.data.value2 / LIVE_DATA_PIL_DIVIDER;
                    }
                });
                _loadPUE();
            });
        }

        /**
         * Checks the consumer and map him with a list (Facility/IT)
         * @param consumer is the consumer to check
         * @private
         */
        function _preparePUE(consumer) {
            if (consumer.checkedL) {
                ctrl.facilityConsumers = ctrl.allConsumer.filter(function (con) {
                    if (consumer.id === "A") {
                        return con.id !== "A"
                            && con.id !== DIFF_A
                            && con.id !== DIFF_B
                    }
                    else {
                        return con.checkedL
                            && con.id !== "A"
                            && con.id !== DIFF_A
                            && con.id !== DIFF_B
                    }
                })
            }
            if (consumer.checkedR) {
                ctrl.itConsumers = ctrl.allConsumer.filter(function (con) {
                    if (consumer.id === "A") {
                        return con.id !== "A"
                            && con.id !== DIFF_A
                            && con.id !== DIFF_B
                    }
                    else {
                        return con.checkedR
                            && con.id !== "A"
                            && con.id !== DIFF_A
                            && con.id !== DIFF_B
                    }
                })
            }
        }

        /**
         * Calculates the PUE and all sums
         * @private
         */
        function _loadPUE() {
            angular.forEach(ctrl.allConsumer, function (consumer) {
                if (consumer.checkedL || consumer.checkedR) {
                    if (consumer.id === "A") {
                        _preparePUE(consumer);
                    }
                    if (consumer.id !== "A" && consumer.id !== DIFF_A && consumer.id !== DIFF_B) {
                        _preparePUE(consumer);
                    }
                }
            });
            var sumOfFacility = parseFloat((Tools.isDefinedNotNull(ctrl.facilityConsumers) &&
                ctrl.facilityConsumers.length === 0) ? 0 :
                _calcSumForConsumer(ctrl.facilityConsumers, true));
            var sumOfIT = parseFloat((Tools.isDefinedNotNull(ctrl.itConsumers) && ctrl.itConsumers.length === 0) ? 0 :
                _calcSumForConsumer(ctrl.itConsumers, false));
            var sumOfAll = _calcLiveDataSum();
            var data = isNaN((sumOfFacility) / (sumOfIT)) ? 0 : (sumOfFacility) / (sumOfIT);

            ctrl.facilityConsumersSum = sumOfFacility.toFixed(TO_FIXED_PARAM);
            ctrl.itConsumersSum = sumOfIT.toFixed(TO_FIXED_PARAM);
            ctrl.sumOfAll = sumOfAll.toFixed(TO_FIXED_PARAM);
            if (data !== Infinity) {
                ctrl.pueData = data.toFixed(TO_FIXED_PARAM);
            }
            else {
                ctrl.pueData = data;
            }
        }

        /**
         * Helper function to calculate the sum of a list
         * @param list is the list to calculate the sum
         * @param bool defines the list. If {@param bool} === {@code true} -> left list (Facility), if {@param bool} === {@code false} -> right list (IT)
         * @returns {number} the sum
         * @private
         */
        function _calcSumForConsumer(list, bool) {
            var sum = 0;
            for (var index in list) { // step 1: sum the energy of consumer
                if (list[index].checkedL && bool) {
                    if (list[index].isConsumer) {
                        sum = sum + parseFloat(list[index].liveData);
                    }
                }
                if (list[index].checkedR && !bool) {
                    if (list[index].isConsumer){
                        sum = sum + parseFloat(list[index].liveData);
                    }
                }
            }
            for (var i in list){ //  step 2: substract the energy of producer from sum
                if (list[i].checkedL && bool){ // element of facility list got checked
                    if (list[i].isConsumer == false){
                        sum = sum - parseFloat(list[i].liveData); //sum substracts the energy of producer
                    }
                }
                if(list[i].checkedR && !bool) {  //element of IT list got checked
                    if (list[i].isConsumer == false) {
                        sum = sum - parseFloat(list[i].liveData);
                    }
                }
            }
            angular.forEach(ctrl.allConsumer, function (consumer) { // step 3: diff factors handling
                if (consumer.id === DIFF_A || consumer.id === DIFF_B) {
                    if (bool && consumer.checkedL) {  // accumulation of facility used energy
                        sum = sum + parseFloat(consumer.diff);
                    }
                    else if (!bool && consumer.checkedR) { // accumulation of IT used energy
                        sum = sum + parseFloat(consumer.diff);
                    }
                }
            });
            return isNaN(sum) ? 0 : sum;
        }

        /**
         * Helper function to calculate the sum of all consumers
         * @returns {number} the sum
         * @private
         */
        function _calcLiveDataSum() {
            var sum = 0;
            for (var i in ctrl.allConsumer) {
                if (!isNaN(ctrl.allConsumer[i].id)) {
                    if (ctrl.allConsumer[i].isConsumer == true){
                        sum = sum + ctrl.allConsumer[i].liveData; // sum the energy of consumer
                    }
                    else {
                        sum = sum - ctrl.allConsumer[i].liveData; // substract the energy of producer
                    }
                }
            }
            return isNaN(sum) ? 0 : sum;
        }

        /**
         * Helper function to merge the available consumer withe "special" ones like "ALL", "Difference A" and "Difference B"
         * {@see ctrl.loadAllConsumer()}
         * @private
         */
        function _mergeConsumer() {
            $http.get("/api/pue/byBuilding/" + ctrl.selected.building.id).then(function (response) {
                if (Tools.isDefinedNotNull(response.data)) {
                    var consumer = ctrl.tempAllConsumer.filter(function (cons) {
                        for (var i = 0; i < response.data.length; i++) {
                            if (parseInt(cons.id) === response.data[i].id) {
                                cons.id = response.data[i].id;
                                cons.name = response.data[i].name;
                                cons.isConsumer=response.data[i].consumer; //get the isConsumer flag
                                return true;
                            }
                        }
                    });
                    for (var i in consumer) { //update the ctrl.allConsumer with the help of ctrl.tempAllConsumer
                        ctrl.allConsumer.splice(ctrl.allConsumer.length - 2, 0, _buildConsumer( consumer[i].id
                            , consumer[i].name
                            , consumer[i].uniqueId
                            , true
                            , consumer[i].checkedL
                            , consumer[i].checkedR
                            , null, consumer[i].liveData, consumer[i].isConsumer ));
                    }
                }
                else {
                    _destroy();
                }
                _loadIntervalFromConfig().then(function (response) {
                    if (Tools.isDefinedNotNull(response.data) && response.data > 0) {
                        LIVE_DATA_INTERVAL = response.data * 1000; // Multiplication to get the milli seconds
                    }
                    _startInterval(); // load the live data
                }, function (error) {
                    // _startInterval();
                })
            });
        }

        function _loadSettings() {
            _loadAllLocations().then(function (response) {
                var promises = [];
                ctrl.allLocations = response.data;
                if ($scope.widget.settings !== null) {
                    for (var i in $scope.widget.settings) {
                        switch ($scope.widget.settings[i].key) {
                            case 'locationId':
                                if (!_checkLocationForUser($scope.widget.settings[i].value)) {
                                    Notify.warning("global.notification.warning.info", "dashboard.widget.pue.modal.notification.locationNotAvailable", 8000);
                                }
                                else { ctrl.selected.location = _getLocation($scope.widget.settings[i].value); }
                                break;
                            case 'buildingId':
                                var id = $scope.widget.settings[i].value;
                                if (ctrl.selected.location !== null) {
                                    promises.push(_loadAllBuildings(ctrl.selected.location.id).then(function (response) {
                                        ctrl.allBuildings = response.data;
                                        if (!_checkBuilding(id)) {
                                            Notify.warning("global.notification.warning.info", "dashboard.widget.pue.modal.notification.buildingNotAvailable", 8000);
                                        }
                                        else { ctrl.selected.building = _getBuilding(id); }
                                    }));
                                }
                                break;
                            default:
                                _loadConsumerFromSettings($scope.widget.settings[i]);
                                break;
                        }
                    }
                    $q.all(promises).then(function () {
                        if (Tools.isDefinedNotNull(ctrl.selected.building)) _mergeConsumer();
                    });
                }
                else { $log.error('Couln\'t load pue widget settings!'); }
            });
        }

        function _loadConsumerFromSettings(settings) {
            var stringArray = _splitString(settings.value);
            var diffObj = {};
            if (stringArray.length === 4) {
                diffObj = {
                    id: stringArray[0],
                    checkedL: (stringArray[1] == 'true'),
                    checkedR: (stringArray[2] == 'true'),
                    diff: parseFloat(stringArray[3])
                };
                if (diffObj.id === DIFF_A) {
                    ctrl.allConsumer.push(_buildConsumer( diffObj.id, $translate.instant("dashboard.widget.pue.modal.table.diffA"), null, false, diffObj.checkedL, diffObj.checkedR, diffObj.diff));
                }
                else {
                    ctrl.allConsumer.push(_buildConsumer( diffObj.id, $translate.instant("dashboard.widget.pue.modal.table.diffB"), null, false, diffObj.checkedL, diffObj.checkedR, diffObj.diff));
                }
            }
            else if (stringArray.length === 3) {
                diffObj = {
                    id: stringArray[0],
                    checkedL: (stringArray[1] == 'true'),
                    checkedR: (stringArray[2] == 'true')
                };
                if (diffObj.id === "A") {
                    ctrl.allConsumer.push(_buildConsumer( diffObj.id, $translate.instant("dashboard.widget.pue.modal.table.all"), null, false, diffObj.checkedL, diffObj.checkedR, null));
                }
                else {
                    ctrl.tempAllConsumer.push(_buildConsumer( diffObj.id, diffObj.id, null, true, diffObj.checkedL, diffObj.checkedR, null));
                }
            }
        }

        function _checkLocationForUser(id) {
            for (var i in ctrl.allLocations) {
                if (ctrl.allLocations[i].id === parseInt(id)) {
                    return true;
                }
            }
            return false;
        }

        function _getLocation(id) {
            for (var i in ctrl.allLocations) {
                if (ctrl.allLocations[i].id === parseInt(id)) {
                    return ctrl.allLocations[i];
                }
            }
            return null;
        }

        function _checkBuilding(id) {
            for (var i in ctrl.allBuildings) {
                if (ctrl.allBuildings[i].id === parseInt(id)) {
                    return true;
                }
            }
            return false;
        }

        function _getBuilding(id) {
            for (var i in ctrl.allBuildings) {
                if (ctrl.allBuildings[i].id === parseInt(id)) {
                    return ctrl.allBuildings[i];
                }
            }
            return null;
        }

        ctrl.saveSettings = function () {
            if (Tools.isDefinedNotNull(ctrl.selected.building)
                && Tools.isDefinedNotNull(ctrl.selected.location)) {
                var newSettings = [];
                newSettings.push({
                    key: "locationId",
                    value: ctrl.selected.location.id
                }, {
                    key: "buildingId",
                    value: ctrl.selected.building.id
                });
                angular.forEach(ctrl.allConsumer, function (consumer) {
                    if (Tools.isDefinedNotNull(consumer.id)) {
                        if (consumer.id === DIFF_A || consumer.id === DIFF_B) {
                            newSettings.push({
                                key: consumer.id,
                                value: "" + consumer.id + ";" + consumer.checkedL + ";" + consumer.checkedR + ";" + consumer.diff
                            });
                        }
                        else {
                            newSettings.push({
                                key: consumer.id,
                                value: "" + consumer.id + ";" + consumer.checkedL + ";" + consumer.checkedR
                            });
                        }
                    }
                });
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
                if ($scope.isdashboardwidget) {
                    WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                        Notify.defaultSuccess();
                        _destroy();
                        _loadSettings();
                        ctrl.closeModal();
                    }, function (error) {
                        Notify.defaultError();
                    });
                }
                ctrl.closeModal();
            }
            else {
                Notify.warning("global.notification.warning.info", "dashboard.widget.pue.modal.notification.canNotBeSaved", 8000);
            }
        };

        function _loadAllLocations() {
            return $http.get('api/locations/forUser');
        }

        function _loadAllBuildings(id) {
            return $http.get('api/building/bylocation/' + id);
        }

        function _loadIntervalFromConfig() {
            return $http.get("api/pue/interval");
        }

        ctrl.loadAllBuildings = function () {
            _loadAllBuildings(ctrl.selected.location.id).then(function (response) {
                ctrl.allBuildings = response.data;
            });
        };

        ctrl.loadAllConsumer = function () {
            ctrl.allConsumer = [];
            if (Tools.isDefinedNotNull(ctrl.selected.building)) {
                $http.get("/api/pue/byBuilding/" + ctrl.selected.building.id).then(function (response) {
                    if (Tools.isDefinedNotNull(response.data) && response.data.length > 0) {
                        ctrl.allConsumer.push(_buildConsumer("A", null, null, null, null, null, null, ctrl.sumOfAll));
                        angular.forEach(response.data, function (consumer) {
                            ctrl.allConsumer.push(_buildConsumer(consumer.id, consumer.name, consumer.uniqueId, true, null, null, null, null, consumer.consumer));
                        });
                        ctrl.allConsumer.push(_buildConsumer( DIFF_A, $translate.instant("dashboard.widget.pue.modal.table.diffA"), null, false, null, null, null));
                        ctrl.allConsumer.push(_buildConsumer( DIFF_B, $translate.instant("dashboard.widget.pue.modal.table.diffB"), null, false, null, null, null));
                    }
                })
                _mergeConsumer(); // pack the livedata into, as soon as the structure of of allConsumer ready
            }
        };

        function _buildConsumer(id, name, uniqueId, fieldDisabled, checkedL, checkedR, diff, liveData,isConsumer) {
            return {
                id: Tools.isDefinedNotNull(id) ? id : 0,
                name: Tools.isDefinedNotNull(name) ? name : $translate.instant("dashboard.widget.pue.modal.table.all"),
                uniqueId: Tools.isDefinedNotNull(uniqueId) ? uniqueId : 0,
                fieldDisabled: Tools.isDefinedNotNull(fieldDisabled) ? fieldDisabled : true,
                checkedL: Tools.isDefinedNotNull(checkedL) ? checkedL : false,
                checkedR: Tools.isDefinedNotNull(checkedR) ? checkedR : false,
                diff: Tools.isDefinedNotNull(diff) ? diff : 0,
                liveData: Tools.isDefinedNotNull(liveData) ? liveData : null,
                isConsumer: Tools.isDefinedNotNull(isConsumer) ? isConsumer : false //add a flag (bool) into this constructor
            };
        }

        ctrl.updateList = function (checkedL, checkedR, list, index) {
            var consumer = _findConsumer(index);
            consumer.checkedL = checkedL;
            consumer.checkedR = checkedR;
            list.push(consumer);
        };

        function _findConsumer(index) {
            for (var i = 0; i < ctrl.allConsumer.length; i++) {
                if (i === index) {
                    return ctrl.allConsumer[index];
                }
            }
            return null;
        }

        function _splitString(string) {
            return string.split(';');
        }
    }

    angular.module('emsv2App')
        .controller('widgetPUEController', widgetPUEController)
})
();
