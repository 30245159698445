(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('password', {
                    abstract: false,
                    parent: 'site',
                    url: '/password',
                    redirectTo: 'password.forgot',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('password');
                            return $translate.refresh();
                        }]
                    }
                });
        });

})();
