(function () {
    'use strict';

    /**
     * @description Service to build 3d-objects to display in 3d-context
     */
    angular.module("emsv2App").factory('Object3DFactory', function ($translate, AssetService, MathService,
                                                                    NumberService, ShaderBuilder, Tools) {

        var colors = {
            flatWall: {
                plane: 0x1e8961,
                outline: 0x000000,
                outlineCage: 0x0000ff
            },
            floor: {
                simple: 0xaaaaaa,
                dummy: 0xe1e1e1
            },
            pointmarker: {
                point: 0x000000,
                xaxis: 0xff0000,
                zaxis: 0x0000ff,
                length: 0xffaa00,
                center: 0xffd500
            },
            cage: {
                outer: 0x666666
            },
            letterColor: {
                color: 0x00adba,
                mark: 0xe30613
            },
            rack: {
                sceleton: 0xaaaaaa
            },
            rectmark: {
                color: 0x00ff00,
                xarrow: 0xff0000,
                zarrow: 0x0000ff
            }
        };

        var opacities = {
            frontwall: 0.3,
            rectmark: 0.3
        };

        var stdDir = new THREE.Vector3(1, 0, 0);

        var getSimpleObject = function () {};
        var getNormalObject = function () {};

        /**
         * @description function to build the editor grid
         * @param {number} size the overall size
         * @param {number} gap the cell size
         * @param {number} color the primary color
         * @param {boolean} dashed if true lines will be drawn dashed, otherwise lines will be drawn nornal
         * @returns {THREE.LineSegments} returns LineSegment object for use in 3d-context
         */
        var buildGrid = function (size, gap, color, dashed) {
            var geometry = new THREE.Geometry();
            var material = dashed ? new THREE.LineDashedMaterial({
                color: color, dashSize: 0.2, gridGap: 0.1
            }) : new THREE.LineBasicMaterial({color: color});
            //center lines
            var i;
            geometry.vertices.push(
                new THREE.Vector3(-size, 0, 0),
                new THREE.Vector3(size, 0, 0),
                new THREE.Vector3(0, 0, -size),
                new THREE.Vector3(0, 0, size)
            );
            for (i = gap; i <= size; i += gap) {
                geometry.vertices.push(
                    new THREE.Vector3(i, 0, -size),
                    new THREE.Vector3(i, 0, size),
                    new THREE.Vector3(-size, 0, i),
                    new THREE.Vector3(size, 0, i)
                );
            }
            for (i = -gap; i >= -size; i -= gap) {
                geometry.vertices.push(
                    new THREE.Vector3(i, 0, -size),
                    new THREE.Vector3(i, 0, size),
                    new THREE.Vector3(-size, 0, i),
                    new THREE.Vector3(size, 0, i)
                );
            }
            var grid = new THREE.LineSegments(geometry, material);
            grid.name = "grid";
            return grid;
        };

        /**
         * @description function to build the mesh for the editor marker
         * @returns {THREE.Mesh} returns mesh for editor marker
         */
        var buildEditorMarker = function () {
            var markerGeo = AssetService.getAssetData('model', 'arrow_tool');
            var markerTex = new THREE.Texture();
            markerTex.antisotropy = 4;
            markerTex.needsUpdate = true;
            var mat = ShaderBuilder.buildMarkerShaderMaterial();
            mat.uniforms.map.value = markerTex;
            mat.uniforms.diffuse.value = new THREE.Color(0x000000);
            mat.map = true;
            var marker = new THREE.Mesh(markerGeo, mat);
            // marker.geometry.computeTangents();
            marker.name = "roomeditMarker";
            modMarkerTex(-1, marker);
            return marker;
        };

        /**
         * @description function to modify the marker texture according to the given state
         * @param {number} state the state described by a number
         * @param {THREE.Mesh} marker the marker mesh to modify
         */
        var modMarkerTex = function (state, marker) {
            var objs = {
                "-1": "icon-stulz",
                "0": "icon-bulldozer",
                "1": "icon-room-rect",
                "2": "icon-wall",
                "3": "icon-interior-wall",
                "4": "icon-door",
                "5": "icon-window",
                "6": "icon-pillar",
                "7": "icon-floor-raised",
                "9": "icon-import-template"
            };
            var inum = fontelloIconCodes[objs[state]];
            var markerCanvas = document.createElement("canvas");
            markerCanvas.width = markerCanvas.height = 128;
            var markerContext = markerCanvas.getContext("2d");
            drawBackground(markerContext);
            markerContext.font = 128 + "px 'fontello'";
            markerContext.fillStyle = "rgba(0,0,0,1)";
            markerContext.fillText(inum, 0, 128 - 18);
            var markerTex = new THREE.Texture(markerCanvas);
            markerTex.anisotropy = 4;
            markerTex.needsUpdate = true;
            marker.material.uniforms.map.value = markerTex;
            marker.material.needsUpdate = true;
        };

        /**
         * @description function to draw background for marker icon
         * @param {object} ctx the context for the created marker texture canvas
         */
        var drawBackground = function (ctx) {
            var bordPx = 10;
            var t0 = 127;
            var t1 = 118;
            ctx.fillStyle = "rgba(255,255,255,1)";
            ctx.moveTo(bordPx, 1);
            ctx.lineTo(t1, 1);
            ctx.quadraticCurveTo(t0, 1, t0, bordPx);
            ctx.lineTo(t0, t1);
            ctx.quadraticCurveTo(t0, t0, t1, t0);
            ctx.lineTo(bordPx, t0);
            ctx.quadraticCurveTo(1, t0, 1, t1);
            ctx.lineTo(1, bordPx);
            ctx.quadraticCurveTo(1, 1, bordPx, 1);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
        };

        /**
         * @description function to build marked plane
         * @param {number} sizeX the width of the plane
         * @param {number} sizeY the height/depth of the plane
         * @param {} color
         * @returns {THREE.Mesh}
         */
        var buildMarkingPlane = function (sizeX, sizeY, color) {
            return new THREE.Mesh(new THREE.PlaneBufferGeometry(sizeX, sizeY, 1, 1), new THREE.MeshPhongMaterial({
                color: color,
                transparent: true,
                opacity: 0.4
            }));
        };

        /**
         * @description function to traverse the parent object and search for a child matching the provided objects id
         * @param {THREE.Object3D} parent the parent object to search in
         * @param {Asset|Cooling|FloorTile|Rack|Sensor|Ups|Window|Door} obj the object to search for
         * @returns {*}
         */
        var findObjectByIdInParent = function (parent, obj) {
            var retObj = null;
            parent.traverse(function (o) {
                if (o instanceof THREE.Object3D) {
                    if (obj instanceof Window && o.name === "window_obj" && o.userData.id === obj.id) { retObj = o; }
                    if (obj instanceof Door && o.name === "door" && o.userData.id === obj.id) { retObj = o; }
                    if (obj instanceof Rack && o.name === "rack" && o.userData.id === obj.id) { retObj = o; }
                    if (obj instanceof Cooling && o.name === "cooling" && o.userData.id === obj.id) { retObj = o; }
                    if (obj instanceof Sensor && o.name === "sensor" && o.userData.id === obj.id) { retObj = o; }
                    if (obj instanceof Asset && o.name === "asset" && o.userData.id === obj.id) { retObj = o; }
                    if (obj instanceof Ups && o.name === "ups" && o.userData.id === obj.id) {retObj = o;}
                    if (obj instanceof FloorTile && o.name === "floortile" && o.userData.id === obj.id) {retObj = o;}
                }
            });
            return retObj;
        };

        /**
         * @description function to find object by name in parent object
         * @param {THREE.Object3D} parent the parent object to search in
         * @param {string} name the name to search for
         * @returns {*} if object with given name was found the object will be returned, otherwise null
         */
        var findObjectByNameInParent = function (parent, name) {
            var retObj = null;
            parent.traverse(function (o) {
                if (o instanceof THREE.Object3D) {
                    if (o.name === name) { retObj = o; }
                }
            });
            return retObj;
        };

        /**
         * @description function to build simple coordinate axes
         * @param {number} len the length for each axis
         * @param {THREE.Vector3} pos the position for the origin
         * @returns {THREE.LineSegments} returns created axes object
         */
        var getAxes = function (len, pos) {
            var geo = new THREE.Geometry();
            var xColor = new THREE.Color(0xff0000);
            var yColor = new THREE.Color(0x00ff00);
            var zColor = new THREE.Color(0x0000ff);
            geo.vertices.push(pos, pos.clone().add(new THREE.Vector3(1, 0, 0).multiplyScalar(len)));
            geo.colors.push(xColor, xColor);
            geo.vertices.push(pos, pos.clone().add(new THREE.Vector3(0, 1, 0).multiplyScalar(len)));
            geo.colors.push(yColor, yColor);
            geo.vertices.push(pos, pos.clone().add(new THREE.Vector3(0, 0, 1).multiplyScalar(len)));
            geo.colors.push(zColor, zColor);
            return new THREE.LineSegments(geo, new THREE.LineBasicMaterial({vertexColors: THREE.VertexColors}));
        };
        //region Room build
        /**
         * @description function to build 3d-object for given room
         * @param {Room} room the room object to create 3d-object for
         * @param {string} viewtype view type string , 'full' or 'simple'
         * @param {boolean} onlyEditorObjects if set to true only pillars, doors, windows, raised floor and basics will
         *                  be visible, otherwise all objects in room will be displayed
         * @returns {THREE.Object3D}
         */
        var buildRoom = function (room, viewtype, onlyEditorObjects) {
            var obj = new THREE.Object3D();
            obj.name = "roomObj";
            obj.userData.id = room.id;
            var robj, i;
            // build room objs
            for (var r = 0; r < room.roomObjs.length; r++) {
                if (room.roomObjs[r].shadowDeleted) continue;
                robj = buildRoomObject(room.roomObjs[r], viewtype, room);
                if (robj) { obj.add(robj); }
                if (onlyEditorObjects && room.roomObjs[r] instanceof Containment) { robj.visible = false; }
            }
            // build walls
            buildWalls(room, viewtype, obj);

            // build dummy walls for measure service
            if (!onlyEditorObjects) { buildDummyWalls(room, obj); }

            // build floor
            if (!room.hasRaisedFloor) { buildFloor(room, viewtype, obj); }

            // build other
            // if(onlyEditorObjects) {
            for (i = 0; i < room.racks.length; i++) {
                robj = buildRack(room.racks[i], viewtype);
                obj.add(robj);
                if (onlyEditorObjects) { robj.visible = false; }
            }

            for (i = 0; i < room.assets.length; i++) {
                var aobj = buildAsset(room.assets[i], viewtype);
                obj.add(aobj);
                if (onlyEditorObjects) { aobj.visible = false; }
            }

            for (i = 0; i < room.sensors.length; i++) {
                var sobj = buildSensor(room.sensors[i], viewtype);
                obj.add(sobj);
                if (onlyEditorObjects) { sobj.visible = false; }
            }

            for (i = 0; i < room.floorTiles.length; i++) {
                var tobj = buildFloorTile(room.floorTiles[i], viewtype, obj, room);
                obj.add(tobj);
                if (onlyEditorObjects) { tobj.visible = false; }
            }

            for (i = 0; i < room.coolings.length; i++) {
                var cobj = buildCooling(room.coolings[i], viewtype);
                obj.add(cobj);
                if (onlyEditorObjects) { cobj.visible = false; }
            }

            for (i = 0; i < room.ups.length; i++) {
                var uobj = buildUps(room.ups[i], viewtype);
                obj.add(uobj);
                if (onlyEditorObjects) { uobj.visible = false; }
            }
            // }
            return obj;
        };

        /**
         * @description function to build dummy objects for assets, cooling units, floor tiles, racks, sensors, ups and containment aisles
         * @param {Room} room the room object containing the objects to create dummys for
         * @returns {THREE.Object3D} returns object containing dummy objects for assets ...
         */
        var buildDummys = function (room) {
            var obj = new THREE.Object3D();
            obj.name = "dummys";
            var mesh, i;
            for (i = 0; i < room.assets.length; i++) {
                mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(room.assets[i].size.x, room.assets[i].size.y, room.assets[i].size.z), new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    transparent: true,
                    opacity: 0.3
                }));
                mesh.position.set(room.assets[i].pos.x, room.assets[i].pos.y, room.assets[i].pos.z);
                mesh.rotation.y = MathService.degToRad(room.assets[i].rot.y * -1);
                mesh.userData.uid = room.assets[i].uniqueId;
                obj.add(mesh);
            }

            for (i = 0; i < room.coolings.length; i++) {
                mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(room.coolings[i].size.x, room.coolings[i].size.y, room.coolings[i].size.z), new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    transparent: true,
                    opacity: 0.3
                }));
                mesh.position.set(room.coolings[i].pos.x, room.coolings[i].pos.y, room.coolings[i].pos.z);
                mesh.rotation.y = MathService.degToRad(room.coolings[i].rot.y * -1);
                mesh.userData.uid = room.coolings[i].uniqueId;
                obj.add(mesh);
            }

            for (i = 0; i < room.sensors.length; i++) {
                mesh = new THREE.Mesh(sensorGeo, new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    transparent: true,
                    opacity: 0.3
                }));
                mesh.position.set(room.sensors[i].pos.x, room.sensors[i].pos.y, room.sensors[i].pos.z);
                mesh.scale.set(0.1, 0.1, 0.1);
                mesh.userData.uid = room.sensors[i].uniqueId;
                obj.add(mesh);
            }

            for (i = 0; i < room.racks.length; i++) {
                mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(room.racks[i].size.x, room.racks[i].size.y, room.racks[i].size.z), new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    transparent: true,
                    opacity: 0.3
                }));
                mesh.position.set(room.racks[i].pos.x, room.racks[i].pos.y, room.racks[i].pos.z);
                mesh.rotation.y = MathService.degToRad(room.racks[i].rot.y * -1);
                mesh.userData.uid = room.racks[i].uniqueId;
                obj.add(mesh);
            }

            for (i = 0; i < room.ups.length; i++) {
                mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(room.ups[i].size.x, room.ups[i].size.y, room.ups[i].size.z), new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    transparent: true,
                    opacity: 0.3
                }));
                mesh.position.set(room.ups[i].pos.x, room.ups[i].pos.y, room.ups[i].pos.z);
                mesh.rotation.y = MathService.degToRad(room.ups[i].rot.y * -1);
                mesh.userData.uid = room.ups[i].uniqueId;
                obj.add(mesh);
            }

            for (i = 0; i < room.floorTiles.length; i++) {
                mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(room.floorTiles[i].size.x, room.floorTiles[i].size.y, room.floorTiles[i].size.z), new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    transparent: true,
                    opacity: 0.3
                }));
                mesh.position.set(room.floorTiles[i].pos.x, room.getRaisedFloor().size.y - room.floorTiles[i].size.y / 2 + 0.01, room.floorTiles[i].pos.z);
                mesh.rotation.y = MathService.degToRad(room.floorTiles[i].rot.y * -1);
                mesh.name = "dummyTile";
                mesh.userData.uid = room.floorTiles[i].uniqueId;
                obj.add(mesh);
            }

            for (i = 0; i < room.roomObjs.length; i++) {
                if (room.roomObjs[i] instanceof Containment) {
                    mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(room.roomObjs[i].size.x, room.roomObjs[i].size.y, room.roomObjs[i].size.z), new THREE.MeshBasicMaterial({
                        color: 0x0000ff,
                        transparent: true,
                        opacity: 0.3
                    }));
                    mesh.position.set(room.roomObjs[i].pos.x, room.roomObjs[i].pos.y, room.roomObjs[i].pos.z);
                    mesh.rotation.y = MathService.degToRad(room.roomObjs[i].rot.y * -1);
                    mesh.userData.uid = room.roomObjs[i].uniqueId;
                    obj.add(mesh);
                }
            }

            return obj;
        };

        /**
         * @description function to build room object (Door, Window, Pillar, RaisedFloor, Containments)
         * @param {Door|Window|Pillar|RaisedFloor|Containment} obj
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @param {Room} room the room object containing the provided object
         * @returns {*} returns created object
         */
        var buildRoomObject = function (obj, viewtype, room) {
            if (obj instanceof Door) { return buildDoor(obj, viewtype, room); }
            if (obj instanceof Window) { return buildWindow(obj, viewtype); }
            if (obj instanceof Pillar) { return buildPillar(obj, viewtype, room); }
            if (obj instanceof RaisedFloor) {
                room.hasRaisedFloor = true;
                return buildRaisedFloor(obj, viewtype, room);
            }
            if (obj instanceof Containment) { return buildContainment(obj, viewtype, room); }
        };

        /**
         * @description function to build door 3d-object
         * @param {Door} obj the door object to build 3d-object from
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @param {Room} room is the current room
         * @returns {THREE.Mesh} returns created 3d-object
         */
        var buildDoor = function (obj, viewtype, room) {
            var geo = obj.type === 0 ? AssetService.getAssetData('model', 'door') : AssetService.getAssetData('model', "door_slide");
            var mat = viewtype === 'simple' ? new THREE.MeshBasicMaterial({color: 0xdddddd}) : new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData('texture', 'door', 'diff'),
                normalMap: AssetService.getAssetData('texture', 'door', 'normal')
            });
            var offset = obj.pos.y;
            if (Tools.isDefinedNotNull(room) && room.hasRaisedFloor) {
                for (var index = 0; index < room.roomObjs.length; index++) {
                    if (room.roomObjs[index] instanceof RaisedFloor && (obj.pos.y - 1) < room.roomObjs[index].size.y) {
                        offset += room.roomObjs[index].size.y;
                        obj.pos.y = offset;
                    }
                }
            }
            var m = new THREE.Mesh(geo, mat);
            m.scale.set(obj.size.x / 1, obj.size.y / 1, obj.size.z / 1);
            m.rotation.y = -MathService.degToRad(obj.rot.y);
            m.position.set(obj.pos.x, offset, obj.pos.z);
            m.name = "door";
            m.userData.id = obj.id;
            m.userData.uid = obj.uniqueId;
            m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(obj.size.x / 2, obj.size.y / 2, obj.size.z / 2), m.rotation.clone());

            //measure planes
            var mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1), new THREE.MeshBasicMaterial({
                color: 0xff0000,
                side: THREE.DoubleSide,
                visible: false
            }));
            mesh.position.z = 0.5;
            m.add(mesh);

            return m;
        };

        /**
         * @description function to build 3d-object for provided window object
         * @param {Window} obj the window object to build 3d-object from
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @returns {THREE.Object3D} returns created 3d-object
         */
        var buildWindow = function (obj, viewtype) {
            var o = new THREE.Object3D();
            o.name = "window_obj";
            var mat = viewtype === 'simple' ? new THREE.MeshLambertMaterial({color: 0xdddddd}) : new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData('texture', 'window', 'diff'),
                normalMap: AssetService.getAssetData('texture', 'window', 'normal')
            });
            var geo = AssetService.getAssetData('model', 'window_frame');
            var m = new THREE.Mesh(geo, mat);
            m.name = "window";
            m.userData.id = obj.id;
            m.scale.set(obj.size.x, obj.size.y, obj.size.z);
            o.rotation.y = -MathService.degToRad(obj.rot.y);
            o.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
            o.add(m);

            var meshGhost = new THREE.Mesh(new THREE.BoxBufferGeometry(obj.size.x, obj.size.y, 1), new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false
            }));
            meshGhost.name = "window";
            o.add(meshGhost);
            o.userData.id = obj.id;
            o.userData.uid = obj.uniqueId;
            o.userData.obb = new OBB(o.position.clone(), new THREE.Vector3(obj.size.x / 2, obj.size.y / 2, obj.size.z / 2), o.rotation.clone());
            var meshGlass = new THREE.Mesh(new THREE.PlaneBufferGeometry(obj.size.x, obj.size.y), new THREE.MeshBasicMaterial({
                color: 0xff0000,
                visible: false
            }));
            meshGlass.position.z += obj.size.z / 4;
            meshGlass.name = "wind_glass";
            o.add(meshGlass);
            return o;
        };

        /**
         * @description function to build 3d-object for provided pillar object
         * @param {Pillar} obj the pillar object to build 3d-object from
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @param {Room} room is the current room
         * @returns {THREE.Mesh} returns created 3d-object
         */
        var buildPillar = function (obj, viewtype, room) {
            var mat = viewtype === 'simple' ? new THREE.MeshLambertMaterial({
                color: 0xcdcdcd,
                transparent: false
            }) : new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData('texture', 'wall', 'diff'),
                transparent: false
            });
            // var offset = obj.pos.y;
            // var substractRFHight = obj.size.y;
            // if (Tools.isDefinedNotNull(room) && room.hasRaisedFloor) {
            //     for (var index = 0; index < room.roomObjs.length; index++) {
            //         if (room.roomObjs[index] instanceof RaisedFloor) {
            //             offset += room.roomObjs[index].size.y;
            //             substractRFHight -= room.roomObjs[index].size.y;
            //         }
            //     }
            // }
            var geo = new THREE.BoxBufferGeometry(1, 1, 1, 1, 1, 1);
            var m = new THREE.Mesh(geo, mat);
            m.name = "pillar";
            m.scale.set(obj.size.x, obj.size.y, obj.size.z);
            m.rotation.y = -MathService.degToRad(obj.rot.y);
            m.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
            m.userData.id = obj.id;
            m.userData.uid = obj.uniqueId;
            m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(obj.size.x / 2, obj.size.y / 2, obj.size.z / 2), m.rotation.clone());
            return m;
        };

        /**
         * @description function to build 3d-object for provided containment object
         * @param {Containment} obj the containment object to build 3d-object from
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @param {Room} room is the current room
         * @returns {THREE.Object3D} returns created 3d-object
         */
        var buildContainment = function (obj, viewtype, room) {
            var retObj = new THREE.Object3D();
            //build transparent sides
            var transMat = viewtype === "simple" ?
                new THREE.MeshLambertMaterial({color: 0x000000, transparent: true, opacity: 0.3}) :
                new THREE.MeshPhongMaterial({color: 0x000000, transparent: true, opacity: 0.3});
            var offset = obj.pos.y;
            if (Tools.isDefinedNotNull(room) && room.hasRaisedFloor) {
                for (var index = 0; index < room.roomObjs.lenght; index++) {
                    if (room.roomObjs[index] instanceof RaisedFloor) offset = room.roomObjs[index].size.y;
                }
            }
            var geo = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
            var geoDoorGlass = new THREE.PlaneBufferGeometry(1, 1, 1, 1);

            var topMesh = new THREE.Mesh(geo, transMat);
            topMesh.scale.set(obj.size.x, obj.size.z, 1);
            topMesh.rotation.x = Math.PI / -2;
            topMesh.position.y = obj.size.y / 2;
            topMesh.name = "containment_plane";
            topMesh.userData.maxOpacity = 0.3;
            topMesh.userData.type = "t";
            topMesh.userData.obb = new OBB(topMesh.position.clone(), new THREE.Vector3(obj.size.x / 2, obj.size.y / 2, obj.size.z / 2), new THREE.Vector3(0, topMesh.rotation.y, 0));

            var sideRightMesh = new THREE.Mesh(geo, transMat);
            sideRightMesh.scale.set(obj.size.x, obj.size.y, 1);
            sideRightMesh.position.set(0, 0, obj.size.z / 2);
            sideRightMesh.name = "containment_plane";
            sideRightMesh.userData.maxOpacity = 0.3;
            sideRightMesh.userData.type = "s";
            var sideLeftMesh = sideRightMesh.clone();
            sideLeftMesh.position.setZ(obj.size.z / -2);
            sideLeftMesh.rotation.y = Math.PI;
            retObj.add(topMesh);
            retObj.add(sideLeftMesh);
            retObj.add(sideRightMesh);
            if (viewtype !== "simple") {
                var doorMeshLeft = new THREE.Mesh(geoDoorGlass, transMat);
                doorMeshLeft.scale.set(obj.size.z, obj.size.y, 1);
                doorMeshLeft.rotation.y = Math.PI / -2;
                doorMeshLeft.position.set(obj.size.x / -2, 0, 0);
                doorMeshLeft.name = "containment_plane";
                doorMeshLeft.userData.maxOpacity = 0.3;
                doorMeshLeft.userData.type = "d";
                var doorMeshRight = doorMeshLeft.clone();
                doorMeshRight.rotation.y = Math.PI / 2;
                doorMeshRight.position.set(obj.size.x / 2, 0, 0);
                doorMeshRight.name = "containment_plane";
                doorMeshRight.userData.maxOpacity = 0.3;
                retObj.add(doorMeshLeft);
                retObj.add(doorMeshRight);
            }

            //build doors
            var geoDoor = viewtype !== "simple" ? AssetService.getAssetData("model", "door_slide") : new THREE.PlaneBufferGeometry(1, 1);
            var mat = viewtype !== "simple" ? new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData("texture", "door", "diff"),
                normalMap: AssetService.getAssetData("texture", "door", "normal"),
                transparent: false
            }) : transMat;
            var doorMesh0 = new THREE.Mesh(geoDoor, mat);
            doorMesh0.scale.set(obj.size.z, obj.size.y, 0.1);
            doorMesh0.rotation.y = Math.PI / 2;
            doorMesh0.name = "containment_door";
            var offsetEnds = viewtype !== "simple" ? 0.05 : 0;
            doorMesh0.position.setX(obj.size.x / 2 + offsetEnds);
            var doorMesh1 = doorMesh0.clone();
            doorMesh1.position.setX(obj.size.x / -2 - offsetEnds);
            doorMesh1.rotation.y *= -1;
            retObj.add(doorMesh0);
            retObj.add(doorMesh1);

            retObj.position.set(obj.pos.x, offset, obj.pos.z);
            retObj.rotation.y = MathService.degToRad(obj.rot.y);

            retObj.name = "containment";
            retObj.userData.id = obj.id;
            retObj.userData.uid = obj.uniqueId;
            retObj.userData.obb = new OBB(retObj.position.clone(), new THREE.Vector3(obj.size.x / 2, obj.size.y / 2, obj.size.z / 2), new THREE.Vector3(0, retObj.rotation.y, 0));
            return retObj;
        };

        /**
         * @description function to build 3d-objects for walls of provided room
         * @param {Room} room the room object to build walls from
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @param {THREE.Object3D} parentObj the parent 3d-object to add created wall 3d-objects to
         */
        var buildWalls = function (room, viewtype, parentObj) {
            var wall, i;
            var walls = [];
            for (i = 0; i < room.outerWalls.length; i++) {
                wall = buildWall(room.outerWalls[i].start, room.outerWalls[i].end, room.outerWalls[i].height, room.outerWalls[i].thickness, viewtype);
                parentObj.add(wall[0]);
                walls.push(wall[0]);
            }
            modWalls(walls);
            cutWalls(walls, room);

            var innerWall;
            var iWalls = [];
            for (i = 0; i < room.innerWalls.length; i++) {
                innerWall = buildInnerWall(room.innerWalls[i].start, room.innerWalls[i].end, room.innerWalls[i].height, room.innerWalls[i].thickness, room.innerWalls[i].type, room.innerWalls[i].posType, viewtype, room, parentObj);
                parentObj.add(innerWall);
                iWalls.push(innerWall);
            }
            // modWalls(iWalls);
            cutWalls(iWalls, room);

        };

        var buildDummyWalls = function (room, obj) {
            var wall, i;
        };

        /**
         * @description function to modify geometry of provided walls
         * @param {array} walls array of wall objects
         */
        var modWalls = function (walls) {
            for (var i = 0; i < walls.length; i++) {
                var prev, next;
                if (i === 0) {
                    prev = walls[walls.length - 1];
                }
                else {
                    prev = walls[i - 1];
                }
                if (i === walls.length - 1) {
                    next = walls[0];
                }
                else {
                    next = walls[i + 1];
                }
                modWallGeo(walls[i], prev, next);
            }
        };

        /**
         * @description function to modify geometry of provided wall
         * @param {THREE.Mesh} wallMesh the wall mesh to modify
         * @param {THREE.Mesh} prev wall object connected to start of wall
         * @param {THREE.Mesh} next wall object connected to end of wall
         */
        var modWallGeo = function (wallMesh, prev, next) {
            wallMesh.parent.updateMatrixWorld();
            wallMesh.updateMatrixWorld();
            prev.updateMatrixWorld();
            next.updateMatrixWorld();
            var topLeftOuterPos = wallMesh.geometry.vertices[4].clone().applyMatrix4(wallMesh.matrixWorld);
            var topLeftPos2D = new THREE.Vector2(topLeftOuterPos.x, topLeftOuterPos.z);
            var topRightOuterPos = wallMesh.geometry.vertices[1].clone().applyMatrix4(wallMesh.matrixWorld);
            var topRightPos2D = new THREE.Vector2(topRightOuterPos.x, topRightOuterPos.z);
            var dirWall = topRightOuterPos.clone().sub(topLeftOuterPos).normalize();
            var prevPosLeft = prev.geometry.vertices[4].clone().applyMatrix4(prev.matrixWorld);
            var prevPosRight = prev.geometry.vertices[1].clone().applyMatrix4(prev.matrixWorld);
            var prevDir = prevPosLeft.clone().sub(prevPosRight).normalize();
            var nextPosLeft = next.geometry.vertices[4].clone().applyMatrix4(next.matrixWorld);
            var nextPosRight = next.geometry.vertices[1].clone().applyMatrix4(next.matrixWorld);
            var nextDir = nextPosRight.clone().sub(nextPosLeft).normalize();
            var prevIntersect = MathService.intersectLineLine(topLeftPos2D, new THREE.Vector2(topLeftPos2D.x + dirWall.x, topLeftPos2D.y + dirWall.z), new THREE.Vector2(prevPosRight.x, prevPosRight.z), new THREE.Vector2(prevPosRight.x + prevDir.x, prevPosRight.z + prevDir.z));
            var nextIntersect = MathService.intersectLineLine(topRightPos2D, new THREE.Vector2(topRightPos2D.x + dirWall.x, topRightPos2D.y + dirWall.z), new THREE.Vector2(nextPosLeft.x, nextPosLeft.z), new THREE.Vector2(nextPosLeft.x + nextDir.x, nextPosLeft.z + nextDir.z));
            if (prevIntersect === null || nextIntersect === null) {
                return;
            }
            var t0 = new THREE.Vector3(nextIntersect.x, topRightOuterPos.y, nextIntersect.y);
            var t1 = new THREE.Vector3(prevIntersect.x, topLeftOuterPos.y, prevIntersect.y);
            var m1 = new THREE.Matrix4().getInverse(wallMesh.matrixWorld);
            t0.applyMatrix4(m1);
            t1.applyMatrix4(m1);
            wallMesh.geometry.vertices[1].x = t0.x;
            wallMesh.geometry.vertices[1].z = t0.z;
            wallMesh.geometry.vertices[3].x = t0.x;
            wallMesh.geometry.vertices[3].z = t0.z;
            wallMesh.geometry.vertices[4].x = t1.x;
            wallMesh.geometry.vertices[4].z = t1.z;
            wallMesh.geometry.vertices[6].x = t1.x;
            wallMesh.geometry.vertices[6].z = t1.z;
            wallMesh.geometry.verticesNeedUpdate = true;
            wallMesh.updateMatrixWorld();
            wallMesh.userData.maxOuterLength = t0.clone().sub(t1).length();

        };

        /**
         * @description function to cut holes into provided walls for windows/doors
         * @param {THREE.Mesh[]} walls array of wall meshes
         * @param {Room} room the room object to build wall for
         */
        var cutWalls = function (walls, room) {
            for (var i = 0; i < walls.length; i++) {
                // if(walls[i].userData.type == 1) continue;
                var parent = walls[i].parent;
                var objs = room.getDoorsForWall(walls[i]);
                objs = objs.concat(room.getWindowsForWall(walls[i]));
                objs = objs.filter(function (el) {return !el.shadowDeleted;});
                if (objs.length > 0) {
                    var temp = cutMultiObject(walls[i], objs, room);
                    parent.remove(walls[i]);
                    parent.add(temp);
                    walls[i] = temp;
                    if (walls[i].name !== "innerwall") {
                        for (var j = 0; j < objs.length; j++) {
                            var o = findObjectByIdInParent(parent, objs[j]);
                            if (objs[j] instanceof Window) { o.position.sub(walls[i].userData.normal.clone().normalize().multiplyScalar(walls[i].userData.d / 2)); }
                            if (objs[j] instanceof Door) { o.position.sub(walls[i].userData.normal.clone().normalize().multiplyScalar(objs[j].size.z / 2)); }
                        }
                    }
                }
                else {
                    if (walls[i].name === "innerwall") { continue; }
                    parent.remove(walls[i]);
                    var temp = buildWallCSG(walls[i], room);
                    walls[i] = temp;
                    parent.add(temp);
                }
            }
        };

        /**
         * @description function build outer wall mesh
         * @param {object} s 3d vector defining start point of wall
         * @param {object} e 3d vector defining end point of wall
         * @param {number} h height of wall
         * @param {number} d depth of wall
         * @param {string} viewtype 'simple'/'full' - shading quality
         * @param {boolean} flat flag, true if wall should be build as plane otherwise wall will be build from solid quad
         * @param {string} objName name to be set for the name property of returned mesh
         * @param {object} typeInfo additional information which will be set for hover object
         * @returns {*} returns array of THREE.Object3D, if wall is not flat a 2 dimensional array will be returned,
         *              if flat flag is true, the returned array contains 3 THREE.Object3D, 1st is the plane mesh, 2nd outline line-stripe object, 3rd hover object
         */
        var buildWall = function (s, e, h, d, viewtype, flat, objName, typeInfo) {
            var _s = s instanceof THREE.Vector3 ? s.clone() : new THREE.Vector3(s.x, s.y, s.z);
            var _e = e instanceof THREE.Vector3 ? e.clone() : new THREE.Vector3(e.x, e.y, e.z);

            // _s = NumberService.roundToPrecisionV3(_s, 3);
            // _e = NumberService.roundToPrecisionV3(_e, 3);

            var dir = _e.clone().sub(_s);
            var len = dir.length();
            var a = dir.clone().normalize().angleTo(stdDir);
            if (dir.z > 0) { a *= -1; }
            var geo, mat;
            var mesh;
            var perp = dir.clone().normalize().applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / -2);

            if (!flat) {
                geo = new THREE.BoxGeometry(len, h, d, 1, 1, 1);
                mat = viewtype === 'simple' ?
                    new THREE.MeshPhongMaterial({color: 0xcdcdcd, shading: THREE.FlatShading, transparent: false})
                    : new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'wall', 'diff'),
                        wireframe: false,
                        shading: THREE.FlatShading,
                        transparent: false
                    });
                // mat = ShaderBuilder.getUVDebugMaterial();
                mesh = new THREE.Mesh(geo, mat);
                mesh.position.copy(_s.clone().add(dir.clone().multiplyScalar(0.5)));
                mesh.position.setY(h / 2);
                mesh.position.sub(perp.clone().multiplyScalar(d / 2));
                mesh.rotation.y = a;
                mesh.name = "outerwall";
                mesh.userData.s = _s.clone();
                mesh.userData.e = _e.clone();
                mesh.userData.h = h;
                mesh.userData.d = d;
                mesh.userData.w = len;
                mesh.userData.normal = perp.clone();
                mesh.userData.obb = new OBB();
                mesh.userData.obb.c = mesh.position.clone();
                mesh.userData.obb.e.set(len / 2, h / 2, d / 2);
                mesh.userData.obb.rotateY(a);
                return [mesh, undefined];
            }
            else {
                geo = new THREE.PlaneBufferGeometry(len, h, 1, 1);
                mat = new THREE.MeshBasicMaterial({
                    color: colors.flatWall.plane,
                    transparent: true,
                    opacity: 0.6,
                    side: THREE.DoubleSide
                });
                mesh = new THREE.Mesh(geo, mat);
                mesh.position.copy(_s.clone().add(dir.clone().multiplyScalar(0.5)));
                mesh.position.setY(h / 2);
                mesh.rotation.y = a;
                mesh.name = objName !== undefined ? objName : "outerwallSingle";
                mesh.userData.s = _s.clone();
                mesh.userData.e = _e.clone();
                mesh.userData.h = h;
                mesh.userData.d = d;
                mesh.userData.flatLine = true;
                var outlineGeo = new THREE.Geometry();
                outlineGeo.vertices.push(_s.clone(), _s.clone().add(new THREE.Vector3(0, h, 0)), _e.clone().add(new THREE.Vector3(0, h, 0)), _e.clone());
                var outlineMat = new THREE.LineBasicMaterial({color: colors.flatWall.outline});
                var outline = new THREE.Line(outlineGeo, outlineMat, THREE.LineStrip);
                mesh.userData.outline = outline;
                var hoverMesh = new THREE.Mesh(new THREE.BoxBufferGeometry(len, h, d), new THREE.MeshBasicMaterial({
                    color: 0x0000ff,
                    wireframe: true,
                    visible: false
                }));
                hoverMesh.position.copy(mesh.position);
                hoverMesh.rotation.y = a;
                hoverMesh.name = "hoverWall";
                mesh.userData.hoverObj = hoverMesh;
                hoverMesh.userData.parent = mesh;
                if (typeInfo) hoverMesh.userData.type = typeInfo;
                return [mesh, outline, hoverMesh];
            }
        };

        /**
         * @description builds inner wall mesh
         * @param {object} s start point inner wall - 3d vector
         * @param {object} e end point inner wall - 3d vector
         * @param {number} h height of inner wall
         * @param {number} d depth of inner wall
         * @param {number} type type of wall, 0 - normal wall, 1 - cage wall
         * @param {number} pos position type
         * @param {string} viewType simple/full shading
         * @param {object} room room object
         * @param roomObj
         * @returns {THREE.Mesh} inner wall mesh
         */
        var buildInnerWall = function (s, e, h, d, type, pos, viewType, room, roomObj) {
            var _s = s instanceof THREE.Vector3 ? s.clone() : new THREE.Vector3(s.x, s.y, s.z);
            var _e = e instanceof THREE.Vector3 ? e.clone() : new THREE.Vector3(e.x, e.y, e.z);
            var dir = _e.clone().sub(_s);
            var len = dir.length();

            var a = dir.clone().normalize().angleTo(stdDir);
            if (dir.z > 0) a *= -1;

            var geo, mat, mesh;
            var perp = dir.clone().normalize().applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / -2);
            geo = new THREE.BoxGeometry(len, h, d, 1, 1, 1);

            if (type === 0) {
                mat = viewType === 'simple' ?
                    new THREE.MeshPhongMaterial({color: 0xcdcdcd, shading: THREE.FlatShading, transparent: false}) :
                    new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'wall', 'diff'),
                        shading: THREE.FlatShading,
                        transparent: false
                    });
            }
            else {
                mat = viewType === 'simple' ?
                    new THREE.MeshPhongMaterial({color: 0x666666, transparent: false}) :
                    new THREE.MeshPhongMaterial({color: 0x666666, transparent: false});
            }
            mesh = new THREE.Mesh(geo, mat);
            if (type === 1) {
                var cutobj = new THREE.Mesh(new THREE.BoxGeometry(len - 0.2, h - 0.2, d + 2, 1, 1, 1), new THREE.MeshBasicMaterial({color: 0xff0000}));
                mesh = cutObject(mesh, cutobj, roomObj);
                var tex = AssetService.getAssetData('texture', 'cage', 'diff');
                tex.wrapS = tex.wrapT = THREE.RepeatWrapping;
                tex.repeat.set(10 * len, 10 * h);
                var meshLattice = new THREE.Mesh(new THREE.PlaneGeometry(len - 0.1, h - 0.1, 1, 1), new THREE.MeshPhongMaterial({
                    side: THREE.DoubleSide,
                    map: tex,
                    transparent: true,
                    color: 0x666666
                }));
                meshLattice.name = "lattice";
                mesh.add(meshLattice);
            }

            mesh.name = "innerwall";
            mesh.userData.s = _s.clone();
            mesh.userData.e = _e.clone();
            mesh.userData.h = h;
            mesh.userData.d = d;
            mesh.userData.w = len;
            mesh.userData.type = type;
            mesh.userData.normal = perp.clone();
            mesh.userData.obb = new OBB();
            mesh.userData.obb.c = mesh.position.clone();
            mesh.userData.obb.e.set(len / 2, h / 2, d / 2);
            mesh.userData.obb.rotateY(a);

            mesh.position.copy(_s.clone().add(dir.clone().multiplyScalar(0.5)));
            mesh.rotation.y = a;

            mesh.position.y = h / 2;
            if (pos === 3) {
                if (room.hasRaisedFloor) mesh.position.y += room.getRaisedFloor().size.y;
            }
            mesh.userData.obb = new OBB();
            mesh.userData.obb.c = mesh.position.clone();
            mesh.userData.obb.e.set(len / 2, h / 2, d / 2);
            mesh.userData.obb.rotateY(a);

            return mesh;
        };

        // var buildPtsArrayForFloor = function(room){
        //     var pts = [];
        //     for (var i = 0; i < room.corner.length; i++) {
        //         pts.push(new THREE.Vector2(room.corner[i].x, room.corner[i].z));
        //     }
        //     return pts;
        // };

        /**
         * Generates 2d point array - describing basic room polygon - from room outer wall array
         * @param room room object
         * @return {Array} returns array of THREE.Vector2 points describing basic room polygon
         */
        var buildPtsArrayForFloor = function (room) {
            var pts = [];
            for (var i = 0; i < room.outerWalls.length; i++) {
                pts.push(new THREE.Vector2(room.outerWalls[i].start.x, room.outerWalls[i].start.z));
            }
            return pts;
        };

        /**
         * Creates floor mesh
         * @param room room object
         * @param viewtype view type 'simple' for less resource consuming rendering, 'full' for textured and 'phong'-shaded objects
         * @param obj optional THREE.Object3D, if provided return mesh will be added to provided object
         * @return {THREE.Mesh} return THREE.Mesh object of the floor for the provided room
         */
        var buildFloor = function (room, viewtype, obj) {
            var pts = buildPtsArrayForFloor(room);
            var shape = new THREE.Shape(pts);
            var shape3D = new THREE.ExtrudeGeometry(shape, {amount: 0.1, bevelEnabled: false});
            var td = AssetService.getAssetData("texture", "floor", "diff");
            td.wrapS = td.wrapT = THREE.RepeatWrapping;
            td.repeat.set(1, 1);
            var tn = AssetService.getAssetData("texture", "floor", "normal");
            tn.wrapS = tn.wrapT = THREE.RepeatWrapping;
            tn.repeat.set(1, 1);
            var shading = viewtype === "simple" ? THREE.FlatShading : THREE.FlatShading;
            var mat = viewtype === "simple" ? new THREE.MeshLambertMaterial({
                color: colors.floor.simple,
                shading: shading
            }) : new THREE.MeshPhongMaterial({map: td, shading: shading});
            var mesh = new THREE.Mesh(shape3D, mat);
            mesh.rotation.x = Math.PI / 2;
            mesh.name = "floor";
            mesh.geometry.computeFaceNormals();
            mesh.geometry.computeVertexNormals();
            mesh.geometry.elementsNeedUpdate = true;
            if (obj) obj.add(mesh);
            return mesh;
        };

        /**
         * Creates raised floor mesh
         * @param obj raised floor object
         * @param viewtype view type 'simple' for less resource consuming rendering, 'full' for textured and 'phong'-shaded objects
         * @param room room object
         * @return {THREE.Mesh} return THREE.Mesh object for the provided raised floor object (RaisedFloor)
         */
        var buildRaisedFloor = function (obj, viewtype, room) {
            var pts = buildPtsArrayForFloor(room);
            var shape = new THREE.Shape(pts);
            var shape3D = new THREE.ExtrudeGeometry(shape, {amount: obj.size.y, bevelEnabled: false});
            var rp_x = 1 / obj.size.x;
            var rp_z = 1 / obj.size.z;
            var roomBB = new THREE.Box3().setFromPoints(room.corner);
            var offX = obj.pos.x !== undefined && obj.pos.x !== 0 ? obj.pos.x : null;
            var offZ = obj.pos.z !== undefined && obj.pos.z !== 0 ? obj.pos.z : null;
            var oo;
            if (offX !== null || offZ !== null) {
                oo = RaisedFloor.calcCustomOffset(roomBB, obj.size.x, obj.size.z, obj.pos.x, obj.pos.z);
                offX = oo[0];
                offZ = oo[1];
            }
            else {
                oo = RaisedFloor.calcStdOffset(roomBB, obj.size.x, obj.size.z);
                offX = oo[0];
                offZ = oo[1];
            }

            var td = AssetService.getAssetData("texture", "floor", "diff");
            td.wrapS = td.wrapT = THREE.RepeatWrapping;
            td.repeat.set(rp_x, rp_z);
            td.offset.set(offX, offZ);
            var tn = AssetService.getAssetData("texture", "floor", "normal");
            tn.wrapS = tn.wrapT = THREE.RepeatWrapping;
            tn.repeat.set(rp_x, rp_z);
            tn.offset.set(offX, offZ);
            var shading = viewtype === "simple" ? THREE.FlatShading : THREE.FlatShading;
            var mat = viewtype === "simple" ? new THREE.MeshLambertMaterial({
                color: 0xaaaaaa,
                shading: shading,
                transparent: false
            }) : new THREE.MeshPhongMaterial({map: td, shading: shading, transparent: false});
            var mesh = new THREE.Mesh(shape3D, mat);
            mesh.rotation.x = Math.PI / 2;
            mesh.name = "raisedFloor";
            mesh.geometry.computeFaceNormals();
            mesh.geometry.computeVertexNormals();

            mesh.geometry.elementsNeedUpdate = true;
            mesh.userData.uid = obj.uniqueId;
            mesh.userData.id = obj.id;
            mesh.userData.w = obj.size.x;
            mesh.userData.h = obj.size.y;
            mesh.userData.d = obj.size.z;
            mesh.userData.ox = obj.pos.x;
            mesh.userData.oz = obj.pos.z;
            mesh.position.add(new THREE.Vector3(0, obj.size.y, 0));
            return mesh;
        };

        /**
         * @description function to build dummy object for row/col of raised floor tiles
         * @param {RaisedFloor} raisedFloor the raised floor object to use
         * @param {Room} room the room object containing the raised floor
         * @returns {THREE.Object3D} returns object containing dummy objects for floor tile row/col names
         */
        var buildTileNameDummy = function (raisedFloor, room) {
            var bb = new THREE.Box3().setFromPoints(room.corner);
            var sizeX = bb.max.x - bb.min.x;
            var sizeZ = bb.max.z - bb.min.z;
            var xCount = Math.ceil(sizeX / raisedFloor.size.x);
            var zCount = Math.ceil(sizeZ / raisedFloor.size.z);
            if (raisedFloor.pos.x && testOffset(raisedFloor.pos.x, sizeX, raisedFloor.size.x)) xCount++;
            if (raisedFloor.pos.z && testOffset(raisedFloor.pos.z, sizeZ, raisedFloor.size.z)) zCount++;
            var geoXCol = new THREE.Geometry();
            geoXCol.vertices.push(new THREE.Vector3(0, 0, sizeZ / -2 - 1), new THREE.Vector3(0, 0, sizeZ / 2 + 1));
            var geoZCol = new THREE.Geometry();
            geoZCol.vertices.push(new THREE.Vector3(sizeX / -2 - 1, 0, 0), new THREE.Vector3(sizeX / 2 + 1, 0, 0));
            var meshXCol = new THREE.LineSegments(geoXCol, new THREE.LineBasicMaterial({color: 0x000000}));
            var meshZCol = new THREE.LineSegments(geoZCol, new THREE.LineBasicMaterial({color: 0x000000}));
            var obj = new THREE.Object3D();
            obj.name = "tileDummyObject";
            var c = bb.getCenter();
            var px = new THREE.Vector3().copy(bb.min).setZ(-1);
            px.add(new THREE.Vector3(raisedFloor.pos.x - c.x, 0, 0));
            var i, o;
            for (i = 0; i < xCount; i++) {
                o = meshXCol.clone();
                o.position.copy(c.clone().add(px));
                if (o.position.x > bb.max.x) continue;
                obj.add(o);
                px.add(new THREE.Vector3(raisedFloor.size.x, 0, 0));
            }
            var pz = new THREE.Vector3().copy(bb.min).setX(-1);
            pz.add(new THREE.Vector3(0, 0, raisedFloor.pos.z - c.z));
            for (i = 0; i < zCount; i++) {
                o = meshZCol.clone();
                o.position.copy(c.clone().add(pz));
                if (o.position.z > bb.max.z) continue;
                obj.add(o);
                pz.add(new THREE.Vector3(0, 0, raisedFloor.size.z));
            }
            obj.position.y = raisedFloor.size.y + 0.5;
            return obj;
        };

        /**
         * @description function to get letter id for letter texture
         * @param {string} letter the letter to get the id for
         * @returns {number} returns letter id for given letter
         */
        var getLetterId = function (letter) {
            var l = letter;
            var code = l.charCodeAt(0);
            if (isNaN(l)) {
                if (code < 97) {
                    code = code - 65;
                }
                else {
                    code = code - 71;
                }
            }
            else {
                code = code - 48 + 52;
            }
            return code;
        };

        /**
         * @description function to get letter ids for given string
         * @param {string} str the string to get letter ids for
         * @returns {Array} returns array of letter ids
         */
        var getLetterArray = function (str) {
            var ret = [];
            var i;
            for (i = 0; i < str.length; i++) {
                ret.push(getLetterId(str[i]));
            }
            for (i = ret.length; i < 5; i++) {
                ret.push(-1);
            }
            return ret;
        };

        /**
         * @description function to get the letter count for given letter id array
         * @param {number[]} arr array containing letter ids
         * @returns {number} returns the length for the given letter array
         */
        var getLetterCount = function (arr) {
            var ret = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i] === -1) break;
                ret++;
            }
            return ret;
        };

        /**
         * @description function to test whether to given offset is suitable for given room and tile size
         * @param {number} offset the offset to test
         * @param {number} roomSize the room size to use for test
         * @param {number} tileSize the tile size to use for test
         * @returns {boolean} returns true if offset is suitable, otherwise false
         */
        var testOffset = function (offset, roomSize, tileSize) {
            var test = NumberService.roundToPrecision((roomSize - Math.floor(roomSize / tileSize) * tileSize), 3);
            return offset < test;
        };

        /**
         * @description function to reverse the given string
         * @param {string} str the string to reverse
         * @returns {*} returns the reversed string
         */
        var reverseString = function (str) {
            return str.split("").reverse().join("");
        };

        /**
         * @description function to fill empty custom name tile names
         * @param {array} array array of tile names
         * @param {number} len the length to fill the provided array with
         */
        var fillCustomNames = function (array, len) {
            for (var i = 0; i < len; i++) {
                if (array[i] === undefined) array.push("xxx");
            }
        };

        /**
         * @description function to build tile name objects
         * @param {string} axis the axis to build name objects for, 'x' or 'z'
         * @param {number} patternX the pattern to use for the x-axis
         * @param {number} patternZ the pattern to use for the z-axis
         * @param {boolean} xLowerCase if set to true all letters for the x-axis will be displayed in lower case letters, otherwise normal letters will be used
         * @param {boolean} zLowerCase if set to true all letters for the z-axis will be displayed in lower case letters, otherwise normal letters will be used
         * @param {RaisedFloor} raisedFloor raised floor object to build tile name objects for
         * @param {Room} room the room object containing the provided raised floor
         * @param {THREE.Object3D} dummy the dummy object to add tile name objects to
         * @param {string[]} customXNames array of custom names for x-axis tiles
         * @param {string[]} customZNames array of custom names for z-axis tiles
         * @returns array of length 2, 1st element names for x-axis, 2nd element names for z-axis
         */
        var buildTileNameObjects = function (axis, patternX, patternZ, xLowerCase, zLowerCase, raisedFloor, room, dummy, customXNames, customZNames) {
            var bb = new THREE.Box3().setFromPoints(room.corner);
            var sizeX = bb.max.x - bb.min.x;
            var sizeZ = bb.max.z - bb.min.z;
            var xCount = Math.ceil(sizeX / raisedFloor.size.x);
            var zCount = Math.ceil(sizeZ / raisedFloor.size.z);
            if (raisedFloor.pos.x && testOffset(raisedFloor.pos.x, sizeX, raisedFloor.size.x)) xCount++;
            if (raisedFloor.pos.z && testOffset(raisedFloor.pos.z, sizeZ, raisedFloor.size.z)) zCount++;
            var geoTile = new THREE.PlaneBufferGeometry(1.5, raisedFloor.size.x, 1, 1);
            var namesX = RaisedFloor.buildTileNamePattern(xCount, patternX, xLowerCase);
            var namesZ = RaisedFloor.buildTileNamePattern(zCount, patternZ, zLowerCase);
            var letterTex = AssetService.getAssetData('texture', 'lettersheet8', "diff");
            if (patternX === 5 && customXNames) {
                if (namesX.length > customXNames.length) fillCustomNames(customXNames, namesX.length);
                namesX = customXNames;
            }
            if (patternZ === 5 && customZNames) {
                if (namesZ.length > customZNames.length) fillCustomNames(customZNames, namesZ.length);
                namesZ = customZNames;
            }
            var t, i, p;
            if (axis === "x") {
                p = bb.min.clone().add(new THREE.Vector3(raisedFloor.size.x / -2, 0, -2 + raisedFloor.size.z));
                if (raisedFloor.pos.x > 0) p.x -= raisedFloor.size.x - raisedFloor.pos.x;
                for (i = 0; i < xCount; i++) {
                    t = new THREE.Mesh(new THREE.PlaneBufferGeometry(raisedFloor.size.x, 1.5, 1, 1), ShaderBuilder.buildLetterZShaderMaterial(letterTex));
                    t.rotation.x = Math.PI / -2;
                    t.material.uniforms.color.value = new THREE.Color(colors.letterColor.color);
                    t.material.uniforms.ratio.value = 1.5 / raisedFloor.size.x;
                    t.material.uniforms.opacity.value = 0.4;
                    p.add(new THREE.Vector3(raisedFloor.size.x, 0, 0));
                    t.position.copy(p);
                    t.name = "tileObjX";
                    if (!namesX[i]) continue;
                    t.userData.tileId = namesX[i];
                    t.userData.tileNum = "x_" + i;
                    t.material.uniforms.letters.value = getLetterArray(reverseString(namesX[i]));
                    t.material.uniforms.letterCount.value = getLetterCount(namesX[i]);
                    t.userData.custom = patternX === 5;
                    t.material.needsUpdate = true;
                    t.needsUpdate = true;
                    dummy.add(t);
                }
            }
            if (axis === "z") {
                p = bb.min.clone().add(new THREE.Vector3(-2 + raisedFloor.size.x, 0, raisedFloor.size.z / -2));
                if (raisedFloor.pos.z > 0) p.z -= raisedFloor.size.z - raisedFloor.pos.z;
                for (i = 0; i < zCount; i++) {
                    t = new THREE.Mesh(geoTile, ShaderBuilder.buildLetterXShaderMaterial(letterTex));
                    t.rotation.x = Math.PI / -2;
                    t.material.uniforms.color.value = new THREE.Color(colors.letterColor.color);
                    t.material.uniforms.ratio.value = 1.5 / raisedFloor.size.x;
                    t.material.uniforms.opacity.value = 0.4;
                    p.add(new THREE.Vector3(0, 0, raisedFloor.size.z));
                    t.position.copy(p);
                    t.name = "tileObjZ";
                    if (!namesZ[i]) continue;
                    t.userData.tileId = namesZ[i];
                    t.userData.tileNum = "z_" + i;
                    t.material.uniforms.letters.value = getLetterArray(namesZ[i]);
                    t.material.uniforms.letterCount.value = getLetterCount(namesZ[i]);
                    t.userData.custom = patternZ === 5;
                    t.material.needsUpdate = true;
                    t.needsUpdate = true;
                    dummy.add(t);
                }
            }
            return [namesX, namesZ];
        };

        /**
         * @description function to cut holes for multiple objects into provided object to cut
         * @param {THREE.Mesh} objectToCut the mesh to cut holes into
         * @param {array} objs array of objects to cut holes for
         * @param {Room} room the room object containing the object to cut
         */
        var cutMultiObject = function (objectToCut, objs, room) {
            if (objectToCut.name === "innerwall") {
                var TOLERANCE = objectToCut.userData.d;
            }
            else {
                var TOLERANCE = 1;
            }
            objectToCut.updateMatrixWorld();
            var dummyMat = new THREE.MeshBasicMaterial({color: 0xff0000});
            var invW = new THREE.Matrix4().getInverse(objectToCut.matrixWorld);
            var objToCutCSG = new ThreeBSP(new THREE.Mesh(objectToCut.geometry.clone(), dummyMat));
            var i, defaultWallLength = 0.2;
            for (i = 0; i < objs.length; i++) {
                // The 'TOLERANCE' is there to avoid over rendering the walls over the objects
                var mesh = new THREE.Mesh(new THREE.BoxGeometry(objs[i].size.x, objs[i].size.y, room.thickness * 2 + TOLERANCE, 1, 1, 1), dummyMat);
                mesh.position.set(objs[i].pos.x, objs[i].pos.y, objs[i].pos.z);
                mesh.position.applyMatrix4(invW);
                // objectToCut.parent.add(mesh);
                objToCutCSG = objToCutCSG.subtract(new ThreeBSP(mesh));
            }
            var retMesh = objToCutCSG.toMesh(objectToCut.material.clone());
            retMesh.geometry.computeVertexNormals();
            if (retMesh.userData.maxOuterLength) defaultWallLength = retMesh.userData.maxOuterLength;
            setWallUvs(retMesh.geometry, defaultWallLength, room.size.y, room.thickness, new THREE.Vector2(0.98, 0.98));
            retMesh.position.applyMatrix4(new THREE.Matrix4().getInverse(invW));
            retMesh.rotation.y = objectToCut.rotation.y;
            retMesh.name = objectToCut.name;
            retMesh.userData.s = objectToCut.userData.s.clone();
            retMesh.userData.e = objectToCut.userData.e.clone();
            retMesh.userData.normal = objectToCut.userData.normal.clone();
            retMesh.userData.h = objectToCut.userData.h;
            retMesh.userData.w = objectToCut.userData.w;
            retMesh.userData.d = objectToCut.userData.d;
            if (objectToCut.userData.hasOwnProperty("obb")) retMesh.userData.obb = objectToCut.userData.obb.clone();
            if (objectToCut.children.length) {
                for (i = 0; i < objectToCut.children.length; i++) {

                    var o = objectToCut.children[i];
                    objectToCut.remove(o);
                    retMesh.add(buildFlatShape(o, objs));
                }
                retMesh.userData.type = objectToCut.userData.type;
            }
            return retMesh;
        };

        /**
         * @description function to build cleaned wall mesh from original wall geometry
         * @param wallObj the original wall object
         * @param room the room object for this wall
         */
        var buildWallCSG = function (wallObj, room) {
            var defaultWallLength = 0.2;
            wallObj.updateMatrixWorld();
            var dummy = new ThreeBSP(new THREE.Mesh(wallObj.geometry.clone(), new THREE.MeshBasicMaterial({color: 0xff0000})));
            var retMesh = dummy.toMesh(wallObj.material.clone());
            retMesh.geometry.computeVertexNormals();
            if (wallObj.userData.maxOuterLength) defaultWallLength = wallObj.userData.maxOuterLength;
            setWallUvs(retMesh.geometry, defaultWallLength, room.size.y, room.thickness, new THREE.Vector2(0.98, 0.98));
            retMesh.position.copy(wallObj.position);
            retMesh.rotation.y = wallObj.rotation.y;
            retMesh.name = wallObj.name;
            retMesh.userData.s = wallObj.userData.s.clone();
            retMesh.userData.e = wallObj.userData.e.clone();
            retMesh.userData.normal = wallObj.userData.normal.clone();
            retMesh.userData.h = wallObj.userData.h;
            retMesh.userData.w = wallObj.userData.w;
            retMesh.userData.d = wallObj.userData.d;
            retMesh.userData.type = wallObj.userData.type;
            if (wallObj.userData.hasOwnProperty("obb")) retMesh.userData.obb = wallObj.userData.obb.clone();
            if (wallObj.userData.hasOwnProperty("maxOuterLength")) retMesh.userData.maxOuterLength = wallObj.userData.maxOuterLength;
            return retMesh;
        };

        /**
         * @description function to build flat shape for object (used to create the lattice for cage inner wall)
         * @param {THREE.Mesh} obj the object to build a flat shape for
         * @returns {THREE.Mesh} returns created lattice mesh object
         */
        var buildFlatShape = function (obj) {
            var box2 = new THREE.Box2().setFromPoints(obj.geometry.vertices);
            var lenX = box2.max.x - box2.min.x;
            var lenY = box2.max.y - box2.min.y;
            var pts = [
                new THREE.Vector2(lenX / 2, lenY / 2),
                new THREE.Vector2(lenX / -2, lenY / 2),
                new THREE.Vector2(lenX / -2, lenY / -2),
                new THREE.Vector2(lenX / 2, lenY / -2)
            ];
            var shape = new THREE.Shape(pts);
            var geo = new THREE.ShapeGeometry(shape);
            setWallUvs(geo, lenX, lenY, 0, new THREE.Vector2(0.98, 0.98));
            var mesh = new THREE.Mesh(geo, obj.material);
            mesh.name = obj.name;
            return mesh;
        };

        /**
         * @description function to cut a hole into the provided mesh based on the provided object
         * @param {THREE.Mesh} objToCutFrom the object to cut
         * @param {THREE.Mesh} obj the object to cut a hole for
         */
        var cutObject = function (objToCutFrom, obj) {
            // var invW = new THREE.Matrix4().getInverse(objToCutFrom.matrixWorld);
            var objCutFromCsg = new ThreeBSP(objToCutFrom);
            var objCsg = new ThreeBSP(obj);
            objCutFromCsg = objCutFromCsg.subtract(objCsg);
            var resultMesh = objCutFromCsg.toMesh(objToCutFrom.material.clone());
            return resultMesh;
        };

        /**
         * setWallUVs
         * @description function to set uv-mapping for provided geometry
         * @param geo geo to set uv-mapping for
         * @param x width of geometry
         * @param y height of geometry
         * @param z depth of geometry
         * @param yValue
         */
        var setWallUvs = function (geo, x, y, z, yValue) {
            var hx = x / 2;
            var hy = y / 2;
            var hz = z / 2;
            var epsi = 0.5;

            var negzmin = new THREE.Vector3(Infinity, 0, 0);
            var negzmax = new THREE.Vector3(-Infinity, 0, 0);

            var poszmin = new THREE.Vector3(Infinity, 0, 0);
            var poszmax = new THREE.Vector3(-Infinity, 0, 0);

            for (var i = 0; i < geo.faces.length; i++) {
                if (geo.faces[i].normal.z !== 0 && geo.faces[i].normal.x === 0) {
                    var a = geo.vertices[geo.faces[i].a];
                    var b = geo.vertices[geo.faces[i].b];
                    var c = geo.vertices[geo.faces[i].c];
                    if (geo.faces[i].normal.z > 0) {
                        if (a.x < poszmin.x) poszmin.x = a.x;
                        if (b.x < poszmin.x) poszmin.x = b.x;
                        if (c.x < poszmin.x) poszmin.x = c.x;
                        if (a.x > poszmax.x) poszmax.x = a.x;
                        if (b.x > poszmax.x) poszmax.x = b.x;
                        if (c.x > poszmax.x) poszmax.x = c.x;
                    }
                    if (geo.faces[i].normal.z < 0) {
                        if (a.x < negzmin.x) negzmin.x = a.x;
                        if (b.x < negzmin.x) negzmin.x = b.x;
                        if (c.x < negzmin.x) negzmin.x = c.x;
                        if (a.x > negzmax.x) negzmax.x = a.x;
                        if (b.x > negzmax.x) negzmax.x = b.x;
                        if (c.x > negzmax.x) negzmax.x = c.x;
                    }
                }
            }

            for (var i = 0; i < geo.faces.length; i++) {
                var uva, uvb, uvc;
                if (Math.abs(geo.faces[i].normal.y) > 0.0001 && yValue) {
                    uva = yValue.clone();
                    uvb = yValue.clone();
                    uvc = yValue.clone();
                    geo.faceVertexUvs[0][i] = [];
                    geo.faceVertexUvs[0][i][0] = uva;
                    geo.faceVertexUvs[0][i][1] = uvb;
                    geo.faceVertexUvs[0][i][2] = uvc;
                }
                if (Math.abs(geo.faces[i].normal.z) > 0.0001 && geo.faces[i].normal.x === 0) {
                    if (geo.faces[i].normal.z > 0) hx = (poszmax.x - poszmin.x) / 2;
                    if (geo.faces[i].normal.z < 0) hx = (negzmax.x - negzmin.x) / 2;

                    var a = geo.vertices[geo.faces[i].a];
                    uva = getUV4Pos(a, hx, hy, hz, geo.faces[i].normal, epsi);
                    var b = geo.vertices[geo.faces[i].b];
                    uvb = getUV4Pos(b, hx, hy, hz, geo.faces[i].normal, epsi);
                    var c = geo.vertices[geo.faces[i].c];
                    uvc = getUV4Pos(c, hx, hy, hz, geo.faces[i].normal, epsi);
                    geo.faceVertexUvs[0][i] = [];
                    geo.faceVertexUvs[0][i][0] = uva;
                    geo.faceVertexUvs[0][i][1] = uvb;
                    geo.faceVertexUvs[0][i][2] = uvc;
                }
            }
        };

        /**
         * @description function to get a uv-coordinates for given point
         * @param {THREE.Vector3} p the point to get uv coordinates for
         * @param {number} hx the half width of the object
         * @param {number} hy the half height of the object
         * @param {number} hz the half depth of the object
         * @param {THREE.Vector3} normal the normal for the given point
         * @param {number} epsi epsilon value
         * @returns {THREE.Vector2} teturns the uv coordinates for provided point
         */
        var getUV4Pos = function (p, hx, hy, hz, normal, epsi) {
            if (((Math.abs(p.x) - hx) < epsi) && ((Math.abs(p.y) - hy) < epsi) && ((Math.abs(p.z) - hz) < epsi)) {
                var x0 = p.x + hx;
                var y0 = p.y + hy;
                return new THREE.Vector2(x0 / (hx * 2), y0 / (hy * 2));
            }
            return new THREE.Vector2(0.98, 0.98);
        };

        /**
         * @description function to build layout object for floor plans
         * @param {number} w the width for the object
         * @param {number} d the depth for the object
         * @returns {THREE.Object3D} returns 3d-object containing control objects and plane for floor plan
         */
        var buildLayoutObject = function (w, d) {
            var obj = new THREE.Object3D();
            var imgMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(w, d, 1, 1), new THREE.MeshBasicMaterial({map: AssetService.getAssetData('texture', 'layout', 'diff')}));
            imgMesh.rotation.x = Math.PI / -2;
            imgMesh.name = "layoutplane";
            obj.add(imgMesh);
            obj.add(buildLayoutControls(w, d));
            return obj;
        };

        /**
         * @description function to build layout control objects
         * @param {number} w the width of the layout object
         * @param {number} d the depth of the layout object
         * @returns {THREE.Object3D} returns 3d-object with sub-objects to control layout objects position and rotation
         */
        var buildLayoutControls = function (w, d) {
            var layoutControls = new THREE.Object3D();
            layoutControls.name = "layoutControls";
            var rotationControls = new THREE.Object3D();
            rotationControls.name = "rotationControls";

            var geoScale = AssetService.getAssetData("model", "arrow");
            var geoRotate = AssetService.getAssetData("model", "arrow_bend");
            var geoClose = AssetService.getAssetData("model", "xclose");
            var hoverBoxGeo = new THREE.BoxBufferGeometry(1, 1, 1, 1, 1, 1);

            var xPosScale = new THREE.Mesh(geoScale, new THREE.MeshBasicMaterial({color: 0xe30613}));
            xPosScale.rotation.x = Math.PI / 2;
            xPosScale.rotation.z = Math.PI / 2;
            var xNegScale = new THREE.Mesh(geoScale, new THREE.MeshBasicMaterial({color: 0xe30613}));
            xNegScale.rotation.x = Math.PI / -2;
            xNegScale.rotation.z = Math.PI / -2;
            var xPosHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false
            }));
            xPosHover.name = "hover";
            var xNegHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false
            }));
            xNegHover.name = "hover";

            var zPosScale = new THREE.Mesh(geoScale, new THREE.MeshBasicMaterial({color: 0x006598}));
            zPosScale.rotation.x = Math.PI / -2;
            var zNegScale = new THREE.Mesh(geoScale, new THREE.MeshBasicMaterial({color: 0x006598}));
            zNegScale.rotation.x = Math.PI / 2;
            var zPosHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false
            }));
            zPosHover.name = "hover";
            var zNegHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false
            }));
            zNegHover.name = "hover";

            var topRotate = new THREE.Mesh(geoRotate, new THREE.MeshBasicMaterial({color: 0xffd500}));
            var botRotate = new THREE.Mesh(geoRotate, new THREE.MeshBasicMaterial({color: 0xffd500}));
            botRotate.rotation.y = Math.PI;
            var topRotateHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0x00ff00,
                wireframe: true,
                visible: false
            }));
            var botRotateHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0x00ff00,
                wireframe: true,
                visible: false
            }));
            var close = new THREE.Mesh(geoClose, new THREE.MeshBasicMaterial({color: 0x000000}));
            var closeHover = new THREE.Mesh(hoverBoxGeo, new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false
            }));
            botRotateHover.name = "hover";
            topRotateHover.name = "hover";
            closeHover.name = "hover";

            var xpSO = new THREE.Object3D();
            xpSO.name = "xpos_move";
            xpSO.add(xPosScale);
            xpSO.add(xPosHover);
            xpSO.position.set(w / 2, 0, 0);

            var xnSO = new THREE.Object3D();
            xnSO.name = "xneg_move";
            xnSO.add(xNegScale);
            xnSO.add(xNegHover);
            xnSO.position.set(-w / 2, 0, 0);

            var zpSO = new THREE.Object3D();
            zpSO.name = "zpos_move";
            zpSO.add(zPosScale);
            zpSO.add(zPosHover);
            zpSO.position.set(0, 0, d / 2);

            var znSO = new THREE.Object3D();
            znSO.name = "zneg_move";
            znSO.add(zNegScale);
            znSO.add(zNegHover);
            znSO.position.set(0, 0, d / -2);

            var bRO = new THREE.Object3D();
            bRO.name = "b_rotate";
            bRO.add(botRotate);
            bRO.add(botRotateHover);
            bRO.position.set(w / 2, 0, d / 2);

            var cO = new THREE.Object3D();
            cO.name = "close";
            cO.add(close);
            cO.add(closeHover);
            cO.position.set(w / 2, 0, -d / 2);

            layoutControls.add(xpSO);
            layoutControls.add(xnSO);
            layoutControls.add(zpSO);
            layoutControls.add(znSO);
            layoutControls.add(cO);
            rotationControls.add(bRO);
            layoutControls.add(rotationControls);
            return layoutControls;
        };

        /**
         * @description function to build scale measure object
         * @param {THREE.Vector3} start the start of the scale measure object
         * @param {THREE.Vector3} end the end of the scale measure object
         * @returns {THREE.Object3D} returns 3d-object for scale measure
         */
        var buildScaleMeasure = function (start, end) {
            var obj = new THREE.Object3D();
            obj.name = "scaleMeasure";

            var dir = end.clone().sub(start);
            var len = dir.length();
            var angle = new THREE.Vector3(1, 0, 0).angleTo(dir.clone().normalize());
            if (dir.z > 0) angle *= -1;
            obj.rotation.y = angle;

            var geo = new THREE.Geometry();
            geo.vertices.push(new THREE.Vector3(0, 0, -0.25), new THREE.Vector3(0, 0, 0.25));
            geo.vertices.push(new THREE.Vector3(0, 0, 0), new THREE.Vector3(len, 0, 0));
            geo.vertices.push(new THREE.Vector3(len, 0, -0.25), new THREE.Vector3(len, 0, 0.25));
            var lines = new THREE.LineSegments(geo, new THREE.LineBasicMaterial({color: 0x00ff00}));
            obj.add(lines);
            obj.position.copy(start);
            obj.position.setY(0.05);
            return obj;
        };

        /**
         * @description function to build 3d-object for rack
         * @param {Rack} rack the rack object to base 3d-object on
         * @param {string} viewType the view type, 'full' or 'simple'
         * @param {boolean} innerview if set to true the inner view will be built, otherwise outer shell will use
         * @returns {*} returns 3d-object for given rack
         */
        var buildRack = function (rack, viewType, innerview) {
            var geoCage, matCage;
            if (!innerview) {
                var geo = rack.type === 1 ? AssetService.getAssetData("model", "rack") : AssetService.getAssetData("model", "rack_double");
                var mat = viewType === "simple" ? new THREE.MeshBasicMaterial({color: 0x887766}) : new THREE.MeshPhongMaterial({
                    map: AssetService.getAssetData("texture", "rack", "diff"),
                    normalMap: AssetService.getAssetData("texture", "rack", "normal")
                });
                var m = new THREE.Mesh(geo, mat);
                m.name = "rack";
                m.userData.id = rack.id;
                m.userData.uid = rack.uniqueId;
                m.position.set(rack.pos.x, rack.pos.y, rack.pos.z);
                m.scale.set(rack.size.x, rack.size.y, rack.size.z);
                m.rotation.y = MathService.degToRad(rack.rot.y * -1);
                m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(rack.size.x / 2, rack.size.y / 2, rack.size.z / 2), new THREE.Vector3(0, m.rotation.y, 0));

                geoCage = AssetService.getAssetData("model", "rack_open");
                matCage = new THREE.MeshLambertMaterial({color: colors.rack.sceleton});

                var cageMesh = new THREE.Mesh(geoCage, matCage);
                cageMesh.name = "cage";
                cageMesh.visible = false;
                m.add(cageMesh);
                for (var i = 0; i < rack.slots.length; i++) {
                    var o = buildSlot(rack.slots[i], viewType);
                    o.scale.setX(o.scale.x * 1 / m.scale.x);
                    o.scale.setY(o.scale.y * 1 / m.scale.y);
                    o.scale.setZ(o.scale.z * 1 / m.scale.z);
                    o.position.y *= 1 / m.scale.y;
                    o.position.z *= 1 / m.scale.z;
                    m.add(o);
                }

                return m;
            }
            else {
                var obj = new THREE.Object3D();
                geoCage = AssetService.getAssetData("model", "rack_open");
                var geoRail = AssetService.getAssetData("model", "rails");
                var texDiff = AssetService.getAssetData("texture", "rails", "diff");
                var texNorm = AssetService.getAssetData("texture", "rails", "normal");
                texDiff.wrapT = THREE.RepeatWrapping;
                texDiff.wrapS = THREE.RepeatWrapping;
                texDiff.repeat.set(1, rack.heightUnits);
                texNorm.wrapT = THREE.RepeatWrapping;
                texNorm.wrapS = THREE.RepeatWrapping;
                texNorm.repeat.set(1, rack.heightUnits);
                matCage = new THREE.MeshPhongMaterial({color: colors.rack.sceleton});
                var matRail = new THREE.MeshPhongMaterial({map: texDiff, normalMap: texNorm});
                var meshCage = new THREE.Mesh(geoCage, matCage);
                meshCage.scale.set(rack.size.x, rack.size.y, rack.size.z);
                meshCage.name = "rackCage";
                // TODO fix me after DL finish
                var meshRail = new THREE.Mesh(geoRail, matRail);
                meshRail.scale.set(0.5, rack.heightUnits * 0.045, rack.size.z - 0.1);
                obj.add(meshCage);
                obj.add(meshRail);
                obj.name = "innerRack";
                obj.userData.hu = rack.heightUnits;
                return obj;
            }
        };

        /**
         * @description function to build slots 3d-object for given rack object
         * @param {Rack} rack the rack object with slot sub-objects to use
         * @param {THREE.Object3D} obj3d the 3d-object to add created slot 3d-objects to
         * @param {string} viewtype the view type, 'full' or 'simple'
         */
        var buildSlotsForRack = function (rack, obj3d, viewtype) {
            for (var s in rack.slots) {
                obj3d.add(buildSlot(rack.slots[s], viewtype));
            }
        };

        /**
         * @description function to build 3d-object for given cooling object
         * @param {Cooling} cooling the cooling object to build 3d-object for
         * @param {string} viewType the view type, 'full' or 'simple'
         * @returns {THREE.Mesh} returns the created 3d-object for the given cooling object
         */
        var buildCooling = function (cooling, viewType) {
            var geo = findCoolingModel(cooling, viewType);
            var mat = findCoolingMaterial(cooling, viewType);
            var m = new THREE.Mesh(geo, mat);
            m.name = "cooling";
            m.userData.id = cooling.id;
            m.userData.uid = cooling.uniqueId;
            m.scale.set(cooling.size.x, cooling.size.y, cooling.size.z);
            m.position.set(cooling.pos.x, cooling.pos.y, cooling.pos.z);
            m.rotation.y = MathService.degToRad(cooling.rot.y * -1);
            m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(cooling.size.x / 2, cooling.size.y / 2, cooling.size.z / 2), new THREE.Vector3(0, m.rotation.y, 0));
            return m;
        };

        /**
         * @description function to get model for given cooling object
         * @param {Cooling} cooling the cooling object to find a suitable model for
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @returns {*} returns THREE.Geometry model suitable for given cooling object
         */
        var findCoolingModel = function (cooling, viewtype) {
            if (viewtype === "simple") {
                return new THREE.BoxBufferGeometry(1, 1, 1);
            }
            if (cooling.type === 3) return AssetService.getAssetData("model", "cool_cyberrow");
            if (cooling.size.x <= 1) return AssetService.getAssetData("model", "cool");
            if (cooling.size.x < 2) return AssetService.getAssetData("model", "cool_2x");
            if (cooling.size.x < 2.25) return AssetService.getAssetData("model", "cool_3x");
            return AssetService.getAssetData("model", "cool_4x");
        };

        /**
         * @description function to get suitable material for given cooling object
         * @param {Cooling} cooling the cooling object to find texture for
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @returns {*} returns THREE.Material for provided cooling object
         */
        var findCoolingMaterial = function (cooling, viewtype) {
            if (viewtype === "simple") return new THREE.MeshLambertMaterial({color: 0x006598});
            if (cooling.isStulz()) {
                if (cooling.type === 3) {
                    return ShaderBuilder.buildMaskShaderMaterial(
                        AssetService.getAssetData("texture", "cool_cyberrow", "mask"),
                        AssetService.getAssetData("texture", "cool_cyberrow", "diff"),
                        AssetService.getAssetData("texture", "cool_cyberrow", "normal")
                    );
                }
                else {
                    return ShaderBuilder.buildMaskShaderMaterial(
                        AssetService.getAssetData("texture", "cool", "mask"),
                        AssetService.getAssetData("texture", "cool", "diff"),
                        AssetService.getAssetData("texture", "cool", "normal")
                    );
                }
            }
            else {
                return ShaderBuilder.buildMaskShaderMaterial(AssetService.getAssetData("texture", "cool", "mask"), AssetService.getAssetData("texture", "cool_std", "diff"), AssetService.getAssetData("texture", "cool", "normal"));
            }
        };

        /**
         * @description function to build 3d-object for provided asset object
         * @param {Asset} asset the asset object to use
         * @returns {THREE.Mesh} returns created 3d-object for provided asset object
         */
        var buildAsset = function (asset) {
            var geo = new THREE.BoxBufferGeometry(1, 1, 1, 1, 1, 1);
            var mat = new THREE.MeshPhongMaterial({color: 0xcdcdcd, transparent: false});
            var m = new THREE.Mesh(geo, mat);
            m.name = "asset";
            m.userData.id = asset.id;
            m.userData.uid = asset.uniqueId;
            m.scale.set(asset.size.x, asset.size.y, asset.size.z);
            m.rotation.y = MathService.degToRad(asset.rot.y * -1);
            m.position.set(asset.pos.x, asset.pos.y, asset.pos.z);
            m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(asset.size.x / 2, asset.size.y / 2, asset.size.z / 2), new THREE.Vector3(0, m.rotation.y, 0));

            return m;
        };

        //defined here since the geometry is always the same, reduces graphics load
        var sensorGeo = new THREE.IcosahedronGeometry(1, 3);

        /**
         * @description function to build 3d-object for given sensor object
         * @param {Sensor} sensor the sensor object to build 3d-object for
         * @returns {THREE.Mesh} returns the created 3d-object for provided sensor object
         */
        var buildSensor = function (sensor) {
            var mat = new THREE.MeshPhongMaterial({color: 0xffffff, transparent: false});
            var m = new THREE.Mesh(sensorGeo, mat);
            m.name = "sensor";
            m.userData.id = sensor.id;
            m.userData.uid = sensor.uniqueId;
            m.scale.set(0.1, 0.1, 0.1);
            m.position.set(sensor.pos.x, sensor.pos.y, sensor.pos.z);
            m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(0.01, 0.01, 0.01), new THREE.Vector3(0, 0, 0));
            return m;
        };

        /**
         * @description function to build 3d-object for given floor tile object
         * @param {FloorTile} obj the floor tile object to use
         * @param {string} viewType the view type, 'full' or 'simple'
         * @param {THREE.Object3D} room3dObj the 3d-object for the room containing the given floor tile object
         * @param {Room} room the room object containing the floor tile object
         * @returns {THREE.Mesh} returns created 3d-object for provided floor tile object
         */
        var buildFloorTile = function (obj, viewType, room3dObj, room) {
            var dummy = findObjectByNameInParent(room3dObj, "dummyTile");
            if (dummy === null) {
                var floorPanelHeight = room.getRaisedFloor().size.y;
                var d = new THREE.Mesh(new THREE.BoxGeometry(1000, floorPanelHeight, 1000), new THREE.MeshLambertMaterial({
                    color: 0xff0000,
                    wireframe: true,
                    transparent: false,
                    opacity: 0
                }));
                d.name = "dummyTile";
                d.visible = false;
                d.position.set(0, floorPanelHeight / 2, 0);
                d.updateMatrixWorld();
                d.geometry.computeVertexNormals();
                d.geometry.computeFaceNormals();
                d.geometry.verticesNeedUpdate = true;
                d.geometry.uvsNeedUpdate = true;
                d.geometry.normalsNeedUpdate = true;
                d.geometry.morphTargetsNeedUpdate = true;
                d.geometry.elementsNeedUpdate = true;
                dummy = d;
                room3dObj.add(d);
            }
            var p = new THREE.Vector3(0, obj.pos.y + 0.1, 0);
            // if(p.y <= 0) p.y = room.getRaisedFloor().size.y;
            p.y = room.getRaisedFloor().size.y + 0.1;
            var r = new THREE.Vector3(Math.PI / -2, -MathService.degToRad(obj.rot.y * -1), 0);
            var s = new THREE.Vector3(obj.size.x, obj.size.z, 1);
            var geo = new THREE.DecalGeometry(dummy, p, r, s, new THREE.Vector3(1, 1, 1));
            var mat = new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData('texture', 'floor_vent', 'diff'),
                normalMap: AssetService.getAssetData('texture', "floor_vent", "normal"),
                transparent: false,
                normalScale: new THREE.Vector2(0.15, 0.15),
                polygonOffset: true,
                polygonOffsetFactor: -4,
                depthTest: true,
                depthWrite: true
            });
            var mesh = new THREE.Mesh(geo, mat);
            mesh.name = "floortile";
            mesh.position.set(obj.pos.x, 0.005, obj.pos.z);
            mesh.userData.id = obj.id;
            mesh.userData.uid = obj.uniqueId;
            mesh.userData.obb = new OBB(new THREE.Vector3(obj.pos.x, room.getRaisedFloor().size.y, obj.pos.z), new THREE.Vector3((obj.size.x / 2) - 0.05, obj.size.y / 2, (obj.size.z / 2) - 0.05), new THREE.Vector3(0, obj.rot.y, 0));
            mesh.userData.size = new THREE.Vector3(obj.size.x, obj.size.y, obj.size.z);
            mesh.renderOrder = 99;
            return mesh;
        };

        /**
         * @description function to build 3d-object for marking arrow
         * @returns {THREE.Mesh} returns created 3d-object
         */
        var buildMarkArrow = function () {
            var mesh = new THREE.Mesh(AssetService.getAssetData('model', 'arrow'), new THREE.MeshLambertMaterial({
                color: 0xe30613,
                shading: THREE.SmoothShading
            }));
            mesh.name = "markArrow";
            mesh.scale.set(0.5, 0.5, 0.5);
            return mesh;
        };

        /**
         * @description function to build 3d-object for marking arrow
         * @returns {THREE.Mesh} returns created 3d-object
         */
        var buildAlarmMarkArrow = function () {
            var mesh = new THREE.Mesh(AssetService.getAssetData('model', 'arrow'), new THREE.MeshLambertMaterial({
                color: 0xe30613,
                shading: THREE.SmoothShading
            }));
            mesh.name = "markArrow";
            mesh.scale.set(1.5, 1.5, 1.5);
            return mesh;
        };

        /**
         * @description function to build 3d-object for provided slot object
         * @param {Slot} slot the slot object to base 3d-object on
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @returns {THREE.Object3D}
         */
        var buildSlot = function (slot, viewtype) {
            var geo, mat;
            switch (slot.type) {
                case 1:
                    geo = AssetService.getAssetData('model', 'slot');
                    mat = new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'slot', 'diff'),
                        normalMap: AssetService.getAssetData('texture', 'slot', 'normal')
                    });
                    break;
                case 2:
                    geo = AssetService.getAssetData('model', 'slot_server');
                    mat = new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'slot_server', 'diff'),
                        normalMap: AssetService.getAssetData('texture', 'slot_server', 'normal')
                    });
                    break;
                case 3:
                    geo = AssetService.getAssetData('model', 'bladecase');
                    mat = new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'blade', 'diff'),
                        normalMap: AssetService.getAssetData('texture', 'blade', 'normal')
                    });
                    break;
                case 4:
                    geo = AssetService.getAssetData('model', 'slot_switch');
                    mat = new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'slot_switch', 'diff'),
                        normalMap: AssetService.getAssetData('texture', 'slot_switch', 'normal')
                    });
                    break;
                case 5:
                    geo = AssetService.getAssetData('model', 'slot_ups');
                    mat = new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'slot_ups', 'diff'),
                        normalMap: AssetService.getAssetData('texture', 'slot_ups', 'normal')
                    });
                    break;
                default:
                    geo = AssetService.getAssetData('model', 'slot_server');
                    mat = new THREE.MeshPhongMaterial({
                        map: AssetService.getAssetData('texture', 'slot_server', 'diff'),
                        normalMap: AssetService.getAssetData('texture', 'slot_server', 'normal')
                    });
            }
            var obj3d = new THREE.Object3D();
            obj3d.name = "slotContainer";
            var meshSlot = new THREE.Mesh(geo, mat);
            meshSlot.scale.set(slot.size.x, slot.size.y, slot.size.z);
            meshSlot.name = "slot";
            meshSlot.userData.id = slot.id;
            meshSlot.userData.uid = slot.uniqueId;
            meshSlot.userData.type = slot.type;
            obj3d.add(meshSlot);
            obj3d.position.set(slot.pos.x, slot.pos.y, slot.pos.z);
            obj3d.rotation.y = slot.direction === 0 ? 0 : Math.PI;
            if (slot.type === 3) {
                buildBlades(slot, obj3d, viewtype, slot.size.x, slot.size.y);
            }
            return obj3d;
        };

        /**
         * @description function to build blade objects for blade enclosure
         * @param {Slot} slot the slot object to build blades for
         * @param {THREE.Object3D} meshParent the 3d-object for the blade enclosure
         * @param {string} viewtype the view type, 'full' or 'simple'
         * @param {number} sx the parent object width
         * @param {number} sy the parent object height
         */
        var buildBlades = function (slot, meshParent, viewtype, sx, sy) {
            var mat, geo;
            mat = viewtype === "full" ? new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData('texture', 'blade', 'diff'),
                normalMap: AssetService.getAssetData('texture', 'blade', 'normal'),
                side: THREE.DoubleSide
            }) : new THREE.MeshLambertMaterial({color: 0xcdcdcd}); // TODO CHANGE DEFAULT COLOR
            geo = AssetService.getAssetData('model', 'blade');

            var m = new THREE.Mesh(geo, mat);
            m.name = "blade";

            var offsetX = 0.05 * sx;
            var offsetY = 0.067 * sy;

            var innerHeight = slot.size.y - offsetY;
            var innerWidth = slot.size.x - offsetX;
            var rowHeight = innerHeight / slot.bladeRow;
            var ssy = rowHeight;
            var colWidth = innerWidth / slot.bladeCol;
            var ssx = colWidth;

            for (var i = 0; i < slot.bladeRow; i++) {
                var yPos = innerHeight / -2 + i * rowHeight + rowHeight / 2;
                for (var j = 0; j < slot.bladeCol; j++) {
                    var xPos = innerWidth / -2 + j * colWidth + colWidth / 2;
                    var m0 = m.clone();
                    m0.scale.set(ssx, ssy, 1);
                    m0.position.set(xPos, yPos, slot.size.z / 2);
                    meshParent.add(m0);
                }
            }
        };

        /**
         * @description function to create the semi transparent hover objects marking empty positions and add it to the provided hover object
         * @param {Rack} rack the rack object to build hover objects for
         * @param {THREE.Object3D} hoverObj the parent 3d-object to add to
         * @param {object} slotDims 3-dimensional vector object describing the slot object parts dimensions
         * @param {boolean} is3d if false hover objects will be created for backview of rack
         */
        var setupEmptySlotHovers = function (rack, hoverObj, slotDims, is3d) {
            var geo = new THREE.BoxBufferGeometry(0.445, 0.045, rack.size.z - 0.1);
            var mat = new THREE.MeshPhongMaterial({color: 0xcdcdcd, transparent: true, opacity: 0.5});

            var offsetBottom = (rack.size.y - rack.heightUnits * 0.045) / 2;
            var startPosY = rack.size.y / -2 + offsetBottom + 0.045 / 2;
            var box3 = new THREE.Box3();
            box3.min = new THREE.Vector3(-0.22, -0.0225, rack.size.z / -2);
            box3.max = new THREE.Vector3(0.22, 0.0225, rack.size.z / 2);
            for (var i = 0; i < rack.heightUnits; i++) {
                var testbox = box3.clone();
                testbox.min.add(new THREE.Vector3(0, startPosY + i * 0.045, 0));
                testbox.max.add(new THREE.Vector3(0, startPosY + i * 0.045, 0));
                if (!checkCollideAgainstSlots2(rack.slots, testbox)) {
                    var mesh = new THREE.Mesh(geo, mat.clone());
                    mesh.position.copy(testbox.getCenter());
                    mesh.name = "hoverSlot";
                    mesh.userData.slot = i + 1;
                    hoverObj.add(mesh);
                    if (!is3d) {
                        var tempMesh = mesh.clone();
                        tempMesh.position.x += rack.size.x * 1.5;
                        hoverObj.add(tempMesh);
                    }
                }
            }
        };
        //endregion
        //region Rack view
        /**
         * @description function to build the 2d rack view
         * @param {THREE.Object3D} innerRackObj the object for the frontal view
         * @returns array of length 2, 1st element created object, 2nd element is 3d-vector object
         *                desribing the overall size of the rackview
         */
        var buildRack2DView = function (innerRackObj) {
            var initCage = null;
            innerRackObj.traverse(function (o) {
                if (o.name === "rackCage") initCage = o;
            });
            var obj = new THREE.Object3D();
            obj.name = "backview";
            var backObj = innerRackObj.clone();
            backObj.rotation.y = Math.PI;
            backObj.position.x += initCage.scale.x * 1.5;
            obj.userData.offsetX = backObj.position.x;
            obj.add(backObj);

            var textureFront = buildTextTexture($translate.instant("room.rackview.front"));
            var textureBack = buildTextTexture($translate.instant("room.rackview.back"));

            var geoFrontText = new THREE.PlaneBufferGeometry(initCage.scale.x, initCage.scale.x * textureFront[1]);
            var geoBackText = new THREE.PlaneBufferGeometry(initCage.scale.x, initCage.scale.x * textureBack[1]);

            var meshFrontText = new THREE.Mesh(geoFrontText, new THREE.MeshPhongMaterial({
                map: textureFront[0],
                transparent: true
            }));
            meshFrontText.position.setY(initCage.scale.y / 2 + geoFrontText.parameters.height * 0.5);
            var meshBackText = new THREE.Mesh(geoBackText, new THREE.MeshPhongMaterial({
                map: textureBack[0],
                transparent: true
            }));
            meshBackText.position.setX(initCage.scale.x * 1.5).setY(initCage.scale.y / 2 + geoBackText.parameters.height * 0.5);

            //height units side
            var offsetBottom = (initCage.scale.y - innerRackObj.userData.hu * 0.045) / 2;
            var startPosY = initCage.scale.y / -2 + offsetBottom;
            var n1Obj = buildHeightUnitDisplay(backObj.position.x, initCage.scale.x / 2, startPosY, innerRackObj.userData.hu, 1);
            n1Obj.visible = false;
            n1Obj.position.setX(backObj.position.x / 2);
            obj.add(n1Obj);
            var n2Obj = buildHeightUnitDisplay(backObj.position.x, initCage.scale.x / 2, startPosY, innerRackObj.userData.hu, 2);
            n2Obj.position.setX(backObj.position.x / 2);
            obj.add(n2Obj);
            obj.add(meshFrontText);
            obj.add(meshBackText);

            return [obj, new THREE.Vector3(backObj.position.x / 2, geoFrontText.parameters.height / 2, initCage.scale.z)];
        };

        /**
         * @description function to build height unit display for 2d view of racks
         * @param {number} posX the x-component to set
         * @param {number} len the horizontal length to use
         * @param {number} startPosY the y-position to start from
         * @param {number} hu the number of height units
         * @param {number} n the step size
         * @returns {THREE.Object3D}
         */
        var buildHeightUnitDisplay = function (posX, len, startPosY, hu, n) {
            var obj = new THREE.Object3D();
            obj.name = "hudisp_" + n;
            var lineGeo = new THREE.Geometry();
            lineGeo.vertices.push(new THREE.Vector3(len / -2, startPosY, 0), new THREE.Vector3(len / 2, startPosY, 0));
            for (var i = 1 * n; i <= hu; i += n) {
                lineGeo.vertices.push(new THREE.Vector3(len / -2, startPosY + i * 0.045, 0), new THREE.Vector3(len / 2, startPosY + i * 0.045, 0));
                var tTex = buildTextTexture(i < 10 ? "0" + i.toString() : i.toString(), true);
                var tGeo = new THREE.PlaneBufferGeometry(0.045 * n, 0.045 * n);
                var tMesh = new THREE.Mesh(tGeo, new THREE.MeshBasicMaterial({map: tTex[0], transparent: true}));
                tMesh.position.setX(0).setY(startPosY + i * 0.045 - 0.0225 * n);
                obj.add(tMesh);
            }
            lineGeo.computeLineDistances();
            var lines = new THREE.LineSegments(lineGeo, new THREE.LineBasicMaterial({color: 0x000000}));
            obj.add(lines);
            return obj;
        };

        /**
         * @description function to create a texture with the provided text
         * @param {string} text the text to display on texture
         * @param {boolean} invertRatio if set to true the width to height ratio will be used, otherwise the height to width ratio will be used
         * @returns array of length 2, 1st element is the created texture, 2nd element is the ratio for the
         *                created texture to allow consumers of this function to display the texture without stretching.
         */
        var buildTextTexture = function (text, invertRatio) {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            ctx.font = "80px Helvetica Neue";
            var textLength = ctx.measureText(text);
            canvas.width = NumberService.getNextPowerOfTwo(textLength.width);
            canvas.height = 128;
            ctx = canvas.getContext("2d");
            ctx.fillStyle = "rgba(0,0,0,0)";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "rgba(0,0,0,1)";
            ctx.font = "80px Helvetica Neue";
            ctx.fillText(text, (canvas.width - textLength.width) / 2, 80);
            var texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;
            var ratio = invertRatio ? canvas.width / canvas.height : canvas.height / canvas.width;
            return [texture, ratio];
        };
        //endregion

        /**
         * @description function to check if provided box is intersecting with the bounding boxes of the provided slot objects
         * @param {Slot[]} slots array of slots to check intersection with
         * @param {THREE.Box3} box the AABB to check for
         * @returns {boolean} returns true if provided box intersects with any of the provided slot objects otherwise false
         */
        var checkCollideAgainstSlots2 = function (slots, box) {
            for (var i = 0; i < slots.length; i++) {
                var slotBox = new THREE.Box3();
                slotBox.min.set(slots[i].size.x / -2, slots[i].size.y / -2, slots[i].size.z / -2);
                slotBox.max.set(slots[i].size.x / 2, slots[i].size.y / 2, slots[i].size.z / 2);
                slotBox.min.add(new THREE.Vector3(slots[i].pos.x, slots[i].pos.y, slots[i].pos.z));
                slotBox.max.add(new THREE.Vector3(slots[i].pos.x, slots[i].pos.y, slots[i].pos.z));
                if (slotBox.intersectsBox(box)) return true;
            }
            return false;
        };

        /**
         * @description function to build 3d object for UPS
         * @param {Ups} usv ups object
         * @param {string} viewType the view type, 'simple' or 'full'
         * @returns {THREE.Mesh} returns the created 3d-object
         */
        var buildUps = function (usv, viewType) {
            var mat, geo;
            mat = viewType === "full" ? new THREE.MeshPhongMaterial({
                map: AssetService.getAssetData('texture', 'ups', 'diff'),
                normalMap: AssetService.getAssetData('texture', 'ups', 'normal')
            }) : new THREE.MeshLambertMaterial({color: 0x6c6c6c, transparent: false});
            geo = AssetService.getAssetData('model', 'ups');

            var m = new THREE.Mesh(geo, mat);
            m.position.set(usv.pos.x, usv.pos.y, usv.pos.z);
            m.scale.set(usv.size.x, usv.size.y, usv.size.z);
            m.rotation.y = -MathService.degToRad(usv.rot.y);
            m.name = "ups";
            m.userData.id = usv.id;
            m.userData.uid = usv.uniqueId;
            m.userData.obb = new OBB(m.position.clone(), new THREE.Vector3(usv.size.x / 2, usv.size.y / 2, usv.size.z / 2), new THREE.Vector3(0, m.rotation.y, 0));

            return m;
        };

        /**
         * @description function to build a container object containing 3d-object for given room
         * @param {Room} room the room object to use
         * @returns {THREE.Object3D} returns container object with room 3d-object as child
         */
        var buildSingleRoomContainer = function (room) {
            var obj = new THREE.Object3D();
            var size = room.bbox.getSize();
            obj.userData.roomId = room.id;
            obj.userData.roomName = room.name;
            obj.userData.roomSize = size;
            var room3d = buildRoom(room, "simple", false);
            room3d.position.sub(room.bbox.getCenter().setY(0));
            obj.add(room3d);
            return obj;
        };

        /**
         * @description function to build the 2d floor view
         * @param {Room[]} rooms array containing all rooms for this floor
         * @returns array of length 3, 1st element is the create floor view 3d-object, 2nd element is the
         *                middle point for the created object, 3rd element is the AABB for all rooms
         */
        var buildFloorView = function (rooms) {
            if (rooms.length === 0) return;
            var obj = new THREE.Object3D();
            var nextDistance = 2.0;
            var offsetX;
            var maxZ = -Infinity;
            var maxX = -Infinity;
            var maxZInclLabels;
            var allCorners = [];
            var i;
            for (i = 0; i < rooms.length; i++) {
                var rc = buildSingleRoomContainer(rooms[i]);
                for (var c = 0; c < rooms[i].corner.length; c++) allCorners.push(new THREE.Vector3(rooms[i].corner[c].x, rooms[i].corner[c].y, rooms[i].corner[c].z));
                if (rc.userData.roomSize.z > maxZ) maxZ = rc.userData.roomSize.z;
                if (rc.userData.roomSize.x > maxX) maxX = rc.userData.roomSize.x;
                obj.add(rc);
            }
            maxZInclLabels = maxZ;
            offsetX = maxX / 2;
            var maxLenNames = -Infinity;
            for (i = 1; i < obj.children.length; i++) {
                offsetX += maxX + nextDistance;
                obj.children[i].position.x = offsetX;
                if (obj.children[i].userData.roomName.length > maxLenNames) maxLenNames = obj.children[i].userData.roomName.length;
            }

            var textTextures = [];
            for (i = 0; i < obj.children.length; i++) {
                var roomName = obj.children[i].userData.roomName;
                if (roomName.length !== maxLenNames) {
                    var diff = Math.ceil((maxLenNames - roomName.length) / 2);
                    for (var j = 0; j < diff; j++) roomName = " " + roomName + " ";
                }
                var tt = buildTextTexture(roomName);
                textTextures.push(tt);
            }
            for (i = 0; i < obj.children.length; i++) {
                var px = maxX;
                var pz = maxX * textTextures[i][1];
                var tgeo = new THREE.PlaneBufferGeometry(px, pz);
                var tm = new THREE.Mesh(tgeo, new THREE.MeshBasicMaterial({map: textTextures[i][0]}));
                // var tm = new THREE.Mesh(tgeo, new THREE.MeshBasicMaterial({color:0xff0000, wireframe:true}));
                tm.rotation.x = Math.PI / -2;
                tm.position.set(0, 0, maxZ / 2 + tgeo.parameters.height);
                if ((maxZ + tgeo.parameters.height) > maxZInclLabels) maxZInclLabels = maxZ + tgeo.parameters.height;
                tm.name = "roomNamePlate";
                tm.userData.id = obj.children[i].roomId;
                obj.children[i].add(tm);
            }
            var bboxAllRooms = new THREE.Box3().setFromPoints(allCorners);
            bboxAllRooms.max.x = offsetX / 2;
            bboxAllRooms.max.z = maxZInclLabels / 2;
            bboxAllRooms.min.x = offsetX / -2;
            bboxAllRooms.min.z = maxZInclLabels / -2;
            return [obj, new THREE.Vector3(offsetX, 0, maxZInclLabels), bboxAllRooms];
        };

        /**
         * @description function builds 3d objects for point mode for given room and wall type
         * @param {object} room the room object
         * @param {boolean} outerWalls flag to determine if outer or inner walls should be used
         * @returns {THREE.Object3D} returns THREE.Object3D as parent for created objects
         */
        var buildPointLayer = function (room, outerWalls) {
            var obj = new THREE.Object3D();
            obj.name = "pointLayer";
            var wallEnds = [];
            var i, se, pe;
            var walls = outerWalls ? room.outerWalls : room.innerWalls;
            for (i = 0; i < walls.length; i++) {
                se = buildWallEnd(walls[i].start, room.size.y);
                se.userData.p = walls[i].start;
                pe = buildWallEnd(walls[i].end, room.size.y);
                pe.userData.p = walls[i].end;

                wallEnds.push(se, pe);
                obj.add(se);
                obj.add(pe);
            }
            for (i = 0; i < wallEnds.length; i++) {
                var testObj = wallEnds[i];
                for (var j = 0; j < wallEnds.length; j++) {
                    if (i === j) continue;
                    if (wallEnds[j].userData.p.equals(testObj.userData.p)) {
                        testObj.userData.connectedTo = wallEnds[j];
                        wallEnds[j].userData.connectedTo = testObj;
                    }
                }
            }
            return obj;
        };

        /**
         * @description function builds a 3d object (cylinder) for wall ends
         * @param {THREE.Vector3} pos the position of the wall end marker
         * @param {number} h height in m for the wall end marker
         * @returns {THREE.Mesh} returns 3d object for wall end
         */
        var buildWallEnd = function (pos, h) {
            var mesh = new THREE.Mesh(new THREE.CylinderGeometry(0.10, 0.10, h + 0.1, 16, 1, false, 0), new THREE.MeshBasicMaterial({color: colors.pointmarker.point}));
            mesh.position.copy(pos);
            mesh.position.y = (h + 0.1) / 2;
            mesh.name = "wallEndPoint";
            mesh.userData.connectedTo = null;
            return mesh;
        };

        /**
         * @description function to create 3d-object to handle point manipulation
         * @param {string} type the type of control object
         * @param {number} h the height for the point control objects
         * @param {THREE.Object3D} wall the wall to build controls for
         * @returns {THREE.Object3D} returns 3d-container object with point control objects within
         */
        var buildPointControls = function (type, h, wall) {
            var obj = new THREE.Object3D();
            obj.name = "pointControls";
            var centerObj = new THREE.Object3D();
            centerObj.name = "posControls";
            obj.add(centerObj);
            var meshXAxis0 = new THREE.Mesh(AssetService.getAssetData("model", 'arrow_move_big'), new THREE.MeshBasicMaterial({color: colors.pointmarker.xaxis}));
            meshXAxis0.scale.set(0.2, 0.2, 0.2);
            meshXAxis0.position.set(0.3, 0, 0);
            meshXAxis0.rotation.y = Math.PI / -2;
            meshXAxis0.name = "move_xpos";
            var meshXAxis1 = meshXAxis0.clone();
            meshXAxis1.name = "move_xneg";
            meshXAxis1.position.set(-0.3, 0, 0);
            meshXAxis1.rotation.y = Math.PI / 2;
            centerObj.add(meshXAxis0);
            centerObj.add(meshXAxis1);

            var meshZAxis0 = new THREE.Mesh(AssetService.getAssetData("model", 'arrow_move_big'), new THREE.MeshBasicMaterial({color: colors.pointmarker.zaxis}));
            meshZAxis0.scale.set(0.2, 0.2, 0.2);
            meshZAxis0.material.color.setHex(colors.pointmarker.zaxis);
            meshZAxis0.position.set(0, 0, -0.3);
            meshZAxis0.rotation.y = 0;
            meshZAxis0.name = "move_zneg";

            var meshZAxis1 = meshZAxis0.clone();
            meshZAxis1.position.set(0, 0, 0.3);
            meshZAxis1.rotation.y = Math.PI;
            meshZAxis1.name = "move_zpos";

            centerObj.add(meshZAxis0);
            centerObj.add(meshZAxis1);

            var meshCenter = new THREE.Mesh(new THREE.CylinderBufferGeometry(0.1, 0.1, h + 1, 16), new THREE.MeshBasicMaterial({color: colors.pointmarker.center}));
            meshCenter.name = "move_center";
            centerObj.add(meshCenter);

            if (type === "wall") {
                obj.name = "wallControls";
                var lenObj = new THREE.Object3D();
                lenObj.name = "lenControls";
                obj.add(lenObj);
                lenObj.rotation.y = wall.rotation.y;

                var meshLen0 = new THREE.Mesh(AssetService.getAssetData("model", "arrow_move_big"), new THREE.MeshBasicMaterial({color: colors.pointmarker.length}));
                meshLen0.scale.set(0.2, h + 1, 0.2);
                meshLen0.name = "move_len";
                var dir = wall.userData.parent.userData.e.clone().sub(wall.userData.parent.userData.s);
                var len = dir.length();
                meshLen0.position.set(len / 2, (h + 1) / 2, 0);
                meshLen0.rotation.y = Math.PI / -2;
                var meshLen1 = meshLen0.clone();
                meshLen1.position.set(len / -2, (h + 1) / 2, 0);
                meshLen1.rotation.y = Math.PI / 2;

                lenObj.add(meshLen0);
                lenObj.add(meshLen1);

            }
            return obj;
        };

        /**
         * @description function to create a heatmap plane based on the given room
         * @param {Room} room the room object to use
         * @param {Canvas} gradientCanvas the canvas object to use
         * @param {object} map the heatmap object to use
         * @returns {THREE.Mesh} returns created plane object
         */
        var buildHeatmapPlane = function (room, gradientCanvas, map) {
            var pts2d = room.getCornerPoints(2);
            var i;
            for (i = 0; i < pts2d.length; i++) pts2d[i] = new THREE.Vector2(pts2d[i].x, pts2d[i].y * -1);
            if (room.checkCornerOrder() === -1) pts2d.reverse();
            var bbox2 = new THREE.Box2().setFromPoints(pts2d);
            var sizeX = bbox2.max.x - bbox2.min.x;
            var sizeY = bbox2.max.y - bbox2.min.y;
            var roomShape = new THREE.Shape(pts2d);
            var roomGeo = new THREE.ShapeGeometry(roomShape);
            for (i = 0; i < roomGeo.faceVertexUvs[0].length; i++) {
                for (var f = 0; f < roomGeo.faceVertexUvs[0][i].length; f++) {
                    var uv = roomGeo.faceVertexUvs[0][i][f];

                    var x = ((bbox2.max.x - uv.x) - sizeX) / sizeX;
                    var y = ((bbox2.max.y - uv.y) - sizeY) / sizeY;

                    roomGeo.faceVertexUvs[0][i][f].x = Math.abs(x);
                    roomGeo.faceVertexUvs[0][i][f].y = Math.abs(y);

                }
            }
            roomGeo.needsUpdate = true;

            var bbox3 = new THREE.Box3();
            bbox3.min.set(bbox2.min.x, 0, bbox2.min.y);
            bbox3.max.set(bbox2.max.x, room.size.y, bbox2.max.y);

            var mesh = new THREE.Mesh(roomGeo, ShaderBuilder.buildHeatMapShaderMaterial(room, gradientCanvas, {
                box: bbox3,
                sizeX: sizeX,
                sizeZ: sizeY,
                sizeY: room.size.y
            }, map));
            mesh.rotation.x = Math.PI / -2;
            mesh.renderOrder = 80;
            mesh.name = "heatmapPlane";
            return mesh;
        };

        /**
         * @description function to create marking rect for containment creation
         * @param {object} obj the object to setup the marker for
         * @param {Room} room the room object to contain the cold aisle containment
         * @returns {THREE.Object3D} returns 3d-object
         */
        var buildContainmentMarker = function (obj, room) {
            var cobj = new THREE.Object3D();
            cobj.name = "rectmarkerObject";
            var info = getInitMarkConstraints(obj);
            cobj.add(buildRectMarkPlane(info, room));
            cobj.add(buildRectMarkMover(info, obj.userData.obb, obj.rotation.y, room, obj));
            return cobj;
        };

        /**
         * @description function to create the marker plane
         * @param {object} info information object return by getInitMarkConstraints()
         * @param {Room} room the room object
         * @returns {THREE.Mesh} returns 3d-object plane
         */
        var buildRectMarkPlane = function (info, room) {
            var geo = new THREE.PlaneBufferGeometry(1, 1);
            var mat = new THREE.MeshPhongMaterial({
                color: colors.rectmark.color,
                transparent: true,
                opacity: opacities.rectmark
            });
            var mesh = new THREE.Mesh(geo, mat);
            mesh.scale.setX(info.x).setY(info.z);
            mesh.name = "markrectplane";
            mesh.position.copy(info.dp);
            mesh.position.y = room.size.y;
            mesh.rotation.x = Math.PI / -2;
            mesh.rotation.z = info.rot;
            return mesh;
        };

        /**
         * @description function to create control elements for marker plane
         * @param {object} info information object return by getInitMarkConstraints()
         * @param {OBB} obb the obb of the object use as root object
         * @param {number} rot the rotation around the y-axis in radians
         * @param {Room} room the room object
         * @param {object} cobj the control object
         * @returns {THREE.Object3D}
         */
        var buildRectMarkMover = function (info, obb, rot, room, cobj) {
            var obj = new THREE.Object3D();
            var geo = AssetService.getAssetData("model", "arrow_move_big");
            var matX = new THREE.MeshPhongMaterial({color: colors.rectmark.xarrow});
            var matZ = new THREE.MeshPhongMaterial({color: colors.rectmark.zarrow});
            var posXMesh = new THREE.Mesh(geo, matX);
            posXMesh.name = "markrectmover";
            posXMesh.userData.dirt = "px";
            posXMesh.userData.dir = obb.u[0].clone();
            var negXMesh = new THREE.Mesh(geo, matX);
            negXMesh.name = "markrectmover";
            negXMesh.userData.dirt = "nx";
            negXMesh.userData.dir = obb.u[0].clone().multiplyScalar(-1);
            var posZMesh = new THREE.Mesh(geo, matZ);
            posZMesh.name = "markrectmover";
            posZMesh.userData.dirt = "pz";
            posZMesh.userData.dir = obb.u[2].clone();
            var negZMesh = new THREE.Mesh(geo, matZ);
            negZMesh.name = "markrectmover";
            negZMesh.userData.dirt = "nz";
            negZMesh.userData.dir = obb.u[2].clone().multiplyScalar(-1);
            posXMesh.position.copy(info.dp.clone().add(obb.u[0].clone().multiplyScalar(info.x / 2)));
            posXMesh.userData.op = posXMesh.position.clone();
            negXMesh.position.copy(info.dp.clone().sub(obb.u[0].clone().multiplyScalar(info.x / 2)));
            negXMesh.userData.op = negXMesh.position.clone();
            posZMesh.position.copy(info.dp.clone().add(obb.u[2].clone().multiplyScalar(info.z / 2)));
            posZMesh.userData.op = posZMesh.position.clone();
            negZMesh.position.copy(info.dp.clone().sub(obb.u[2].clone().multiplyScalar(info.z / 2)));
            negZMesh.userData.op = negZMesh.position.clone();
            posXMesh.rotation.y = Math.PI / -2 + rot;
            negXMesh.rotation.y = Math.PI / 2 + rot;
            posZMesh.rotation.y = Math.PI + rot;
            negZMesh.rotation.y = rot;
            posXMesh.position.y = negXMesh.position.y = posZMesh.position.y = negZMesh.position.y = room.size.y;
            posXMesh.scale.set(0.2, 0.2, 0.2);
            negXMesh.scale.copy(posXMesh.scale);
            posZMesh.scale.copy(posXMesh.scale);
            posXMesh.userData.cm = negXMesh;
            negXMesh.userData.cm = posXMesh;
            posZMesh.userData.cm = negZMesh;
            posXMesh.userData.cobj = negXMesh.userData.cobj = posZMesh.userData.cobj = cobj;

            obj.add(posXMesh);
            obj.add(negXMesh);
            obj.add(posZMesh);
            negZMesh.visible = false;
            obj.add(negZMesh);
            return obj;
        };

        /**
         * @description function to get constraints to create marking plane
         * @param {THREE.Object3D} obj the 3d-object use as main object
         * @returns {{x: number, y: number, z: number, dp: *, rot: *}}
         */
        var getInitMarkConstraints = function (obj) {
            return {
                x: obj.userData.obb.e.x * 2,
                y: obj.userData.obb.e.y * 2,
                z: obj.userData.obb.e.z * 2,
                dp: obj.position.clone().add(obj.userData.obb.u[2].clone().multiplyScalar(obj.userData.obb.e.z * 2)),
                rot: obj.rotation.y
            };
            // return info;
        };
        return {
            getSimpleObject: getSimpleObject,
            getNormalObject: getNormalObject,
            buildGrid: buildGrid,
            buildEditorMarker: buildEditorMarker,
            modMarkerTex: modMarkerTex,
            buildMarkingPlane: buildMarkingPlane,
            getAxes: getAxes,
            buildRoom: buildRoom,
            buildDummys: buildDummys,
            buildWall: buildWall,
            buildInnerWall: buildInnerWall,
            buildFloor: buildFloor,
            buildRaisedFloor: buildRaisedFloor, // TODO merge with func below maybe
            buildRoomObject: buildRoomObject,
            buildLayoutObject: buildLayoutObject,
            buildScaleMeasure: buildScaleMeasure,
            buildLayoutControls: buildLayoutControls,
            buildRack: buildRack,
            buildSlot: buildSlot,
            buildSlotsForRack: buildSlotsForRack,
            setupEmptySlotHovers: setupEmptySlotHovers,
            buildRack2DView: buildRack2DView,
            buildCooling: buildCooling,
            buildSensor: buildSensor,
            buildAsset: buildAsset,
            buildFloorTile: buildFloorTile,
            buildUps: buildUps,
            buildFloorView: buildFloorView,
            buildTileNameDummy: buildTileNameDummy,
            buildTileNameObjects: buildTileNameObjects,
            getLetterArray: getLetterArray,
            getLetterCount: getLetterCount,
            buildMarkArrow: buildMarkArrow,
            buildAlarmMarkArrow: buildAlarmMarkArrow,
            buildPointLayer: buildPointLayer,
            buildPointControls: buildPointControls,
            getLetterId: getLetterId,
            buildHeatmapPlane: buildHeatmapPlane,
            buildContainmentMarker: buildContainmentMarker,
            buildRectMarkPlane: buildRectMarkPlane,
            getInitMarkConstraints: getInitMarkConstraints,
            colors: colors,
            opacities: opacities
        };
    });
})();
