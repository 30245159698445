(function () {
    'use strict';

    /**
     * @function MathService
     * @description Service to encapsulate some math functions
     */
    angular.module('emsv2App').service('MathService', function () {

        /**
         * @description Function to convert angle in degrees to radians
         * @param {number} deg the angle in degrees
         * @returns {number} returns the provided angle in radians
         */
        var degToRad = function (deg) {
            return deg * (Math.PI / 180);
        };

        /**
         * @description Function to convert angle in radians to degrees
         * @param {number} rad the angle to convert in radians
         * @returns {number} returns the provided angle in degrees
         */
        var radToDeg = function (rad) {
            return (rad * 180) / Math.PI;
        };

        /**
         * @description Function to find point of intersection between 2 line in 2-dimensional space
         * @param {object} l1s 2-dimensional vector object describing 1st lines start point
         * @param {object} l1e 2-dimensional vector object describing 1st lines end point
         * @param {object} l2s 2-dimensional vector object describing 2nd lines start point
         * @param {object} l2e 2-dimensional vector object describing 2nd lines end point
         * @returns {*} returns 2-dimensional vector object describing point of intersection, otherwise null will be returned
         */
        var intersectLineLine = function (l1s, l1e, l2s, l2e) {
            var denom = (l1s.x - l1e.x) * (l2s.y - l2e.y) - (l1s.y - l1e.y) * (l2s.x - l2e.x);
            if (denom === 0) {
                return null;
            }
            else {
                var x = (l1s.x * l1e.y - l1s.y * l1e.x) * (l2s.x - l2e.x) - (l1s.x - l1e.x) * (l2s.x * l2e.y - l2s.y * l2e.x);
                var y = (l1s.x * l1e.y - l1s.y * l1e.x) * (l2s.y - l2e.y) - (l1s.y - l1e.y) * (l2s.x * l2e.y - l2s.y * l2e.x);
                x /= denom;
                y /= denom;
                return {x: x, y: y};
            }
        };

        /**
         * @description Function to check if point is in polygon
         * @param {object} point 3-dimensional vector object describing the point to test
         * @param {object[]} pts array of 3-dimensional vector objects describing the polygon to test
         * @returns {boolean} returns true if point is in polygon, otherwise false
         */
        var checkPointInPolygon = function (point, pts) {
            var t = -1;
            var p0 = {x: point.x, y: point.z};
            var testJordan = function (a, b, c) {
                if (a.y === b.y && b.y === c.y) {
                    if ((b.x <= a.x && a.x <= c.x) || (c.x <= a.x && a.x <= b.x)) {
                        return 0;
                    }
                    else {
                        return 1;
                    }
                }
                if (b.y > c.y) {
                    var t0 = c.x;
                    c.x = b.x;
                    b.x = t0;
                    t0 = c.y;
                    c.y = b.y;
                    b.y = t0;
                }
                if (a.y === b.y && a.x === b.x) {
                    return 0;
                }
                if (a.y <= b.y || a.y > c.y) {
                    return 1;
                }
                var d = (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
                if (d > 0) {
                    return -1;
                }
                else if (d < 0) {
                    return 1;
                }
                return 0;
            };
            var res = 0;
            for (var i = 0; i < pts.length - 1; i++) {
                res = testJordan(p0, {x: pts[i].x, y: pts[i].y}, {x: pts[i + 1].x, y: pts[i + 1].y});
                t *= res;
            }
            res = testJordan(p0, {x: pts[pts.length - 1].x, y: pts[pts.length - 1].y}, {x: pts[0].x, y: pts[0].y});
            t *= res;
            if (t === 1 || t === 0) {
                return true;
            }
            else {
                return false;
            }
        };

        /**
         * @description Function to test if provided float values are equal with respect to the provided epsilon value
         * @param {number} f0 the first float value to use
         * @param {number} f1 the second float value to use
         * @param {number=} e the epsilon value to use (if not provided e will be set to 0.000001)
         * @returns {boolean} returns true if both values are not farther apart then epsilon, otherwise false
         */
        var compareFloats = function (f0, f1, e) {
            if (!e) e = 0.000001;
            return Math.abs(f0 - f1) < e;
        };

        /**
         * @description Function to compare 2 3-dimensional vector objects with each other with respect to the provided epsilon value
         * @param {THREE.Vector3} v0 1st vector to use
         * @param {THREE.Vector3} v1 2nd vector to use
         * @param {number=} e the epsilon value, if not provided will be set to 0.0001
         * @returns {boolean} returns true if both vectors are equal with respect to epsilon
         */
        var compareVector3 = function (v0, v1, e) {
            if (!v0 || !v1) return false;
            if (!e) e = 0.0001;
            if (v0 instanceof THREE.Vector3 && v1 instanceof THREE.Vector3) {
                if (Math.abs(v0.x - v1.x) > e) return false;
                if (Math.abs(v0.y - v1.y) > e) return false;
                if (Math.abs(v0.z - v1.z) > e) return false;
                return true;
            }
            return false;
        };

        /**
         * @description Function to check equality of 2 OBBs
         * @param {OBB} obb0 1st obb to use
         * @param {OBB} obb1 2nd obb to use
         * @param {boolean} ignoreCenter if set to true position of obbs will be ignored, if false position will be used too
         * @returns {boolean} returns true if both obbs are equal otherwise false
         */
        var obbsEqual = function (obb0, obb1, ignoreCenter) {
            if (!ignoreCenter) {
                if (!compareVector3(obb0.c, obb1.c, 0.0001)) return false;
            }
            if (!compareVector3(obb0.e, obb1.e, 0.0001)) return false;
            if (!compareVector3(obb0.u[0], obb1.u[0])) return false;
            if (!compareVector3(obb0.u[1], obb1.u[1])) return false;
            if (!compareVector3(obb0.u[2], obb1.u[2])) return false;
            return true;
        };

        return {
            intersectLineLine: intersectLineLine,
            degToRad: degToRad,
            radToDeg: radToDeg,
            checkPointInPolygon: checkPointInPolygon,
            compareFloats: compareFloats,
            compareVector3: compareVector3,
            obbsEqual: obbsEqual
        }
    });
})();
