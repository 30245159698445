(function () {

    'use strict';

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('debug', {
                parent: 'site',
                url: '/debug',
                data: {
                    roles: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/debug/debug.html',
                        controller: 'DebugController',
                        controllerAs: '$ctrl'
                    }
                },
                onEnter: function($timeout, Principal){
                    'ngInject';
                    $timeout(function(){
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            });
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
