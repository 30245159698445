(function () {

    'use strict';

    /**
     * A label for tree nodes in analysis views.
     * @ngdoc component
     * @name analysisTreeNode
     * @requires $translate
     * @param {Object} node - Current node to use
     */
    angular.module('emsv2App')
        .component('analysisTreeNode', {
            template: '<span><span ng-bind="$ctrl.text"></span>&nbsp;<span ng-if="$ctrl.node.archived === true" class="icon-archived"></span></span>',
            bindings: {
                node: '<'
            },
            controller: ['$translate', function ($translate) {
                var ctrl = this;
                ctrl.text = 'loading..';
                ctrl.archived = false;
                ctrl.$onInit = function () {
                    if (ctrl.node.title !== null && ctrl.node.title !== '') {
                        ctrl.text = ctrl.node.title;
                    }
                    else if (ctrl.node.translation !== null && ctrl.node.translation !== '') {
                        ctrl.text = $translate.instant(ctrl.node.translation, ctrl.node.translationParams);
                    }
                };
            }]
        });
})();
