/**
 * Since the gridstack will only be used within the dashboard is has been organized below the dashboard itself.
 *
 * the files gridstack_directive, -controller and -item_directive are wrapping the library gridstack.js for angular usage.
 *
 * make sure that 'gridstack-angular' is injected into the main App
 *
 * dependency:
 * angular 1 (obviously)
 * gridstack.js (https://github.com/troolee/gridstack.js)
 * gridstack-angular (https://github.com/kdietrich/gridstack-angular)
 * lodash >= 3.5.0 (https://lodash.com/)
 * jquery >= 1.11.0 (https://jquery.com/)
 * jquery touch >= 1.11.0 (https://jquery.com/)
 * jquery touch-punch (https://github.com/furf/jquery-ui-touch-punch) -> for touch device support
 *
 * All packages are available at ether npm or bower consult provided links for further information.
 *
 */

(function () {
    'use strict';

    function gridstackController($scope, $rootScope, $timeout) {
        var ctrl = this;
        ctrl.gridstack = null;
        var lockedDashboard = false;

        this.init = function (element, options) {
            ctrl.gridstack = element.gridstack(options).data('gridstack');
            return ctrl.gridstack;
        };

        this.removeItem = function (element) {
            if (ctrl.gridstack) {
                return ctrl.gridstack.removeWidget(element, false);
            }
            return null;
        };

        this.addItem = function (element) {
            if (ctrl.gridstack) {
                ctrl.gridstack.makeWidget(element);
                ctrl.gridstack.movable('.grid-stack-item', !lockedDashboard);
                ctrl.gridstack.resizable('.grid-stack-item', !lockedDashboard);
                return element;
            }
            return null;
        };

        var newUpdateGridstackSettings = $rootScope.$on('toggleLockGridstack', function (event, args) {
            if (ctrl.gridstack) {
                $timeout(function () {
                    lockedDashboard = args.locked;
                    ctrl.gridstack.movable('.grid-stack-item', !lockedDashboard);
                    ctrl.gridstack.resizable('.grid-stack-item', !lockedDashboard);
                });
            }
        });
        $scope.$on('$destroy', newUpdateGridstackSettings);

        $scope.onResizeStop = function(event, ui) {
            $rootScope.$broadcast("widgetOnResizeStop", {event: event, ui: ui});
        };

    }

    var app = angular.module('emsv2App');

    /** @ngInject */
    app.controller('GridstackController', gridstackController);
})();
