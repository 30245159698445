(function () {

    'use strict';
    // Controller für Übersicht der Eskalations stufen + Parent des EscalationLevelEditController
    function escalationLevelController($state, $scope, $filter, $stateParams, EscalationLevelService, escalationLevels, $uibModal,
                                       isAdmin, hasViewRight, hasEditRight, hasCreateRight, hasDeleteRight, LocationDetailService) {
        var ctrl = this;
        ctrl.isAdmin = isAdmin;                     //Rechte für die Inputs und Buttons in der view ...
        ctrl.hasViewRight = hasViewRight;
        ctrl.hasEditRight = hasEditRight;
        ctrl.hasCreateRight = hasCreateRight;
        ctrl.hasDeleteRight = hasDeleteRight;
        ctrl.searchString = "";
        ctrl.tableHead = ['location.alarms.escalationManagementTable.date', 'location.alarms.escalationManagementTable.subject', 'location.alarms.escalationManagementTable.recipients'];

        ctrl.activeTableHead = ctrl.tableHead;
        ctrl.sortAsc = null;

        $scope.modal = { // Modal für das Löschen von Listenelementen
            view: null,
            obj: null,
            close: function () {
                modal.close();
            },
            deleteEscalationLevel: function () {
                EscalationLevelService.deleteEscalationLevel($scope.modal.obj.id).then(function (response) {
                    $state.go('location.alarm.errorConfig.list');
                    $state.reload();
                });
            }
        };

        var modal = null;

        ctrl.escalationLevels = escalationLevels;

        ctrl.viewEscalationLevel = function (obj) {
            $state.go('location.alarm.escalationlevel.edit', {editElement: obj.id, mode: "view"});
        };

        ctrl.addEscalation = function () {
            $state.go('location.alarm.escalationlevel.edit', {editElement: null, mode: "edit"});
        };

        ctrl.deleteEscalationLevel = function (obj) { // Modal method
            $scope.modal.obj = obj;
            modal = $uibModal.open({
                animation: false,
                templateUrl: "scripts/app/location/tabs/alarm/escalationlevel/escalationlevel.delete.html",
                windowClass: 'animated fadeInDown',
                scope: $scope,
                size: 'lg'
            });
        };
        ctrl.editEscalationLevel = function (obj) {
            $state.go('location.alarm.escalationlevel.edit', {editElement: obj.id, mode: "edit"});
        };

        ctrl.updateTables = function () {
            $scope.$broadcast('rsqltable-refresh');
        };

        ctrl.collapse = function (obj) { // collapse event für das anzeigen von mehreren E-Mails innerhalb der Liste
            if (obj.collapseState === false || obj.collapseState === undefined) {
                obj.collapseState = true;
            }
            else {
                obj.collapseState = false;
            }
        };

        ctrl.sortCols = function (col) { // Sortierkram

            var entityFieldName = getEntityFieldName(col);

            if (entityFieldName !== ctrl.currCol) {
                ctrl.sortAsc = true;
                ctrl.currCol = entityFieldName;
            }
            else if (ctrl.sortAsc === true && ctrl.currCol === entityFieldName) {
                ctrl.sortAsc = false;
            }
            else if (ctrl.sortAsc === false && ctrl.currCol === entityFieldName) {
                ctrl.sortAsc = true;
            }
        };

        ctrl.getIcon = function (col) {
            var entityFieldName = getEntityFieldName(col);
            if (entityFieldName === ctrl.currCol) {
                if (ctrl.sortAsc) {
                    return 'glyphicon glyphicon-sort-by-attributes-alt m-l';
                }
                else {
                    return 'glyphicon glyphicon-sort-by-attributes m-l';
                }
            }
            else {
                return 'glyphicon glyphicon-sort m-l';
            }
        };

        function getEntityFieldName(col) {
            switch (col) {
                case 'delayTime':
                    return 'delayTime';
                case 'subject':
                    return 'subject';
                case 'recipients':
                    return 'recipients';
            }
        }
        ctrl.$onInit = function () {
            ctrl.sortCols('delayTime');
            LocationDetailService.reset().setDefaultBackToOverview();
        }
    }

    angular.module('emsv2App')
        .controller('escalationLevelController', escalationLevelController);
})();
