(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name DriverValue
     * @requires $http
     */
    angular.module('emsv2App').factory('DriverValue', ['$http', function ($http) {
        return {
            'getById': function (id) {
                return $http.get('api/driverValue/getById/' + id);
            },
            'getOldConsumer': function (id) {
                return $http.get('api/driverValue/getOldConsumers' );
            }
        };
    }]);

})();
