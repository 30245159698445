(function () {
    'use strict';
    angular.module('emsv2App').service('snmpV3SecurityUtilsService', function ($log, $http) {

        var getAuthProtocols = function () {
            return $http.get('/api/snmpV3SecurityUtils/getAuthenticationProtocol').then(
                function (response) {
                    return response.data;
                },
                function (error) {
                    $log.error('error while loading SNMPv3 auth. protocols', error);
                }
            )
            // return $http.get('/api/snmpV3SecurityUtils/getAuthenticationProtocol');
        };

        var getPrivProtocols = function () {
            return $http.get('/api/snmpV3SecurityUtils/getPrivacyProtocol').then(
                function (response) {
                    return response.data;
                },
                function (error) {
                    $log.error('error while loading SNMPv3 priv. protocols', error);
                }
            )
            // return $http.get('/api/snmpV3SecurityUtils/getPrivacyProtocol');
        };

        return {
            getAuthProtocols: getAuthProtocols,
            getPrivProtocols: getPrivProtocols
        }
    })
})();

