(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name DashboardService
     */
    angular.module('emsv2App').service('DashboardService', function ($log, $timeout, $interval, RoomService) {

        const service = this;
        service.refreshRate = 5000;
        service.refreshIndex = 0;
        service.rooms = [];
        service.locations = [];
        service.refresh = [];
        service.widgets = [];
        service.wids = [];
        service.refreshInterval = null;
        service.refreshing = false;

        this.removeWidget = function(wId){
            const index = service.wids.indexOf(wId);
            if (index > -1) {
                delete service.widgets[wId];
                service.wids.splice(index, 1);
                service.schedule();
            }
        }

        this.queueWidget = function(wId, refreshFunction) {
            if (wId && !service.widgets[wId]) {
                service.widgets[wId] = refreshFunction;
                service.wids.push(wId);
                service.refreshRate = (5000 / service.widgets.length) < 350 ? 350 : (5000 / service.widgets.length);
                service.schedule();
            }
        }

        this.schedule = function(){

            if (service.refreshInterval !== null) $interval.cancel(service.refreshInterval);

            if(service.widgets.length > 0){
                service.refreshInterval = $interval(service.refreshNextWidget, service.refreshRate);
            }
        }

        this.stopWidgets = function(){
            if (service.refreshInterval !== null) {
                $interval.cancel(service.refreshInterval);
            }
            service.widgets = [];
            service.wids = [];
        }

        this.refreshNextWidget = function(){
            if(!service.refreshing){
                service.refreshing = true;
                if(service.wids[service.refreshIndex]){
                    const wid = service.wids[service.refreshIndex];

                    // refresh call of widget (e.g. widget.speedometer and widget.gauge)
                    service.widgets[wid]();
                }
                service.refreshIndex = service.refreshIndex + 1 < service.wids.length ? service.refreshIndex + 1 : 0;
                service.refreshing = false;
            }
        }

        this.loadRooms = function(locationId){

            return new Promise(function(resolve, reject) {

                RoomService.getRooms(locationId).then(function (response) {
                    var rooms = [];
                    for (var i in response.data) {
                        rooms.push(Room.parseFromHtmlObject(response.data[i]));
                    }
                    service.locations[locationId] = rooms;
                    resolve(rooms); // when successful

                }, function (error) {
                    reject("failed to load rooms via api");  // when error
                });

            });

        }

        this.getRoomsByLocation = function(locationId){

            return new Promise(function(resolve, reject) {
                if (!service.locations[locationId]) {
                   service.loadRooms(locationId).then(function(rooms){
                       resolve(rooms);
                   }, function (error) {
                       $log.error("Error retrieving rooms in DashboardService." + error);
                       reject("failed to load rooms via api");  // when error
                   });
                }else {
                    resolve(service.locations[locationId]); // when successful from cache
                }
            });

        }

        return {
            schedule: this.schedule,
            queueWidget: this.queueWidget,
            removeWidget: this.removeWidget,
            refreshNextWidget: this.refreshNextWidget,
            loadRooms: this.loadRooms,
            getRoomsByLocation: this.getRoomsByLocation,
            stopWidgets: this.stopWidgets
        };
    });

})();
