(function () {

    'use strict';

    angular.module('emsv2App')
        .directive('country', ['Country', function (Country) {
            return {
                restrict: 'A',
                bindToController: {
                    country: '<'
                },
                template: '<span>{{cty.formatted}}</span>',
                controllerAs: 'cty',
                controller: function ($scope, $translate, Country) {
                    var ctrl = this;
                    ctrl.formatted = '';
                    if (ctrl.country !== undefined && ctrl.country !== null && ctrl.country.length > 0) {
                        Country.get(ctrl.country).then(function (response) {
                            ctrl.formatted = response.name;
                        });
                    }
                }
            };
        }]);

})();
