(function () {
    'use strict';

    angular
        .module("emsv2App")
        .component("errorListComponent", {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/location/tabs/room/errorlist.component.html',
            controller: function ($log, $translate, Tools) {
                var ctrl = this;

                ctrl.errors = [];
                ctrl.room = null;

                ctrl.$onInit = function () {
                    ctrl.room = ctrl.resolve.room;
                    for (var index = 0; index < ctrl.resolve.errors.length; index++) {
                        var error = ctrl.resolve.errors[index];
                        var object = ctrl.resolve.room.findObjectByUniqueID(error.uniqueId);
                        var entityName = error.entityName === "" ? object.name : $translate.instant(error.entityName);
                        var descr;
                        if (object instanceof DriverValue) {
                            descr = buildDescrForDriver(object);
                            var objectName = object.name === "not provided" ? object.parameter.name : object.name;
                            var driverValueName = $translate.instant(objectName);
                            if (error.fieldName === "room.errorList.identifier"
                                || error.fieldName === "room.errorList.limit_name"
                                || error.fieldName === "room.errorList.limit_val") {
                                descr = $translate.instant("room.errorList.codes.complex." + error.code, {
                                    entity: entityName,
                                    dvName: driverValueName
                                });
                            } else if (Tools.isDefinedNotNull(descr)) {
                                descr = $translate.instant("room.errorList.codes.simple." + error.code, {
                                    entity: descr.name,
                                    dvName: driverValueName
                                });
                            }
                        } else if (Tools.isDefinedNotNull(object) && Tools.isDefinedNotNull(object.name) && object.name !== "") {
                            descr = $translate.instant("room.errorList.codes.complex." + error.code, {
                                entity: entityName,
                                name: object.name
                            });
                        } else if (error.fieldName === "room.errorList.classes.outerWalls") {
                            descr = $translate.instant("room.errorList.codes.simple." + error.code);
                        } else if (error.fieldName === "room.errorList.name" || error.fieldName === "room.errorList.comment") {
                            descr = $translate.instant("room.errorList.codes.complex." + error.code, {
                                entity: entityName,
                                name: $translate.instant(error.fieldName)
                            });
                        } else {
                            descr = $translate.instant("room.errorList.codes.simple." + error.code, {entity: entityName});
                        }
                        var fieldName = $translate.instant(error.fieldName);
                        var exists = ctrl.errors.filter(function (error) {
                            return error.description === descr;
                        });
                        if (exists.length > 0) {
                            var fields = exists[0].field.concat(", ").concat(fieldName);
                            ctrl.errors.pop({description: descr})
                            ctrl.errors.push({description: descr, field: fields})
                        } else {
                            ctrl.errors.push({description: descr, field: fieldName})
                        }
                    }
                };

                function buildDescrForDriver(object) {
                    var uid = parseInt(object.driver.uniqueId);
                    var searchResult;

                    var _searchArray = function (arr) {
                        for (var i = 0; i < arr.length; i++) {
                            if (arr[i].uniqueId === uid) return arr[i];
                            if (arr[i].driverValues && arr[i].driverValues.length > 0) {
                                for (var dv = 0; dv < arr[i].driverValues.length; dv++) {
                                    if (Tools.isDefinedNotNull(arr[i].driverValues[dv].driver) && arr[i].driverValues[dv].driver.uniqueId === uid) {
                                        return arr[i];
                                    } else if (arr[i].driverValues[dv] === uid) return arr[i];
                                }
                            }
                        }
                        return null;
                    };

                    var _searchArrayRacks = function (racks) {
                        var searchResult;
                        for (var indexOfRack = 0; indexOfRack < racks.length; indexOfRack++) {
                            if (racks[indexOfRack].uniqueId === uid) return racks[indexOfRack];
                            for (var indexOfSlot = 0; indexOfSlot < racks[indexOfRack].slots.length; indexOfSlot++) {
                                searchResult = _searchArray(racks[indexOfRack].slots);
                                if (searchResult !== null) return searchResult;
                                if (racks[indexOfRack].slots[indexOfSlot].uniqueId === uid) return racks[indexOfRack].slots[indexOfSlot];
                                if (racks[indexOfRack].slots[indexOfSlot].blades && racks[indexOfRack].slots[indexOfSlot].blades.length > 0) {
                                    for (var indexOfBlade = 0; indexOfBlade < racks[indexOfRack].slots[indexOfSlot].blades.length; indexOfBlade++) {
                                        if (racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus && racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus.length > 0) {
                                            searchResult = _searchArray(racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus);
                                            if (searchResult !== null) return searchResult;
                                        }
                                    }
                                }
                            }
                        }
                        return null;
                    };

                    searchResult = _searchArrayRacks(ctrl.room.racks);
                    if (searchResult !== null) return searchResult;
                    searchResult = _searchArray(ctrl.room.assets);
                    if (searchResult !== null) return searchResult;
                    searchResult = _searchArray(ctrl.room.sensors);
                    if (searchResult !== null) return searchResult;
                    searchResult = _searchArray(ctrl.room.coolings);
                    if (searchResult !== null) return searchResult;
                    searchResult = _searchArray(ctrl.room.roomObjs);
                    if (searchResult !== null) return searchResult;
                    searchResult = _searchArray(ctrl.room.floorTiles);
                    if (searchResult !== null) return searchResult;
                    searchResult = _searchArray(ctrl.room.ups);
                    if (searchResult !== null) return searchResult;
                }

                ctrl.$onDestroy = function () {
                    ctrl.errors = [];
                };

                ctrl.closeModal = function () {
                    ctrl.close();
                }
            },
            controllerAs: 'eLCtrl'
        });
})();
