'use strict';

/**
 * @description Constructor to create new SmtpEmailServerConfiguration object
 * @constructor
 */
function SmtpEmailServerConfiguration(smtpEmailServerConfiguration) {
    if (smtpEmailServerConfiguration !== undefined) {
        this.id = smtpEmailServerConfiguration.id !== undefined ? smtpEmailServerConfiguration.id : null;
        this.hostName = smtpEmailServerConfiguration.hostName !== undefined ? smtpEmailServerConfiguration.hostName : null;
        this.port = smtpEmailServerConfiguration.port !== undefined ? smtpEmailServerConfiguration.port : 25;
        this.senderEmailAddress = smtpEmailServerConfiguration.senderEmailAddress !== undefined &&
        smtpEmailServerConfiguration.senderEmailAddress !== null &&
        smtpEmailServerConfiguration.senderEmailAddress !== "" ?
            smtpEmailServerConfiguration.senderEmailAddress : "CyberhubECODC@localhost";
        this.userName = smtpEmailServerConfiguration.userName !== undefined ? smtpEmailServerConfiguration.userName : null;
        this.password = smtpEmailServerConfiguration.password !== undefined ? smtpEmailServerConfiguration.password : null;
        this.protocol = smtpEmailServerConfiguration.protocol !== undefined ? smtpEmailServerConfiguration.protocol : "SMTP";
        this.tls = smtpEmailServerConfiguration.tls !== undefined ? smtpEmailServerConfiguration.tls : false;
        this.auth = smtpEmailServerConfiguration.auth !== undefined ? smtpEmailServerConfiguration.auth : false;
    } else {
        this.id = null;
        this.hostName = null;
        this.port = 25;
        this.senderEmailAddress = "CyberhubECODC@localhost";
        this.userName = null;
        this.password = null;
        this.protocol = "SMTP";
        this.tls = false;
        this.auth = false;
    }
    this.inProgress = false;
    this.inEditMode = false;
}

SmtpEmailServerConfiguration.prototype = {};
SmtpEmailServerConfiguration.prototype.constructor = SmtpEmailServerConfiguration;

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
SmtpEmailServerConfiguration.validate = function (entity) {
    if (entity.hostName === undefined || entity.hostName === null || entity.hostName === "") return false;
    if (entity.port === undefined || entity.port === null || entity.port === "") return false;
    if (entity.senderEmailAddress === undefined || entity.senderEmailAddress === null || entity.senderEmailAddress === "") return false;

    return true;
};

SmtpEmailServerConfiguration.compareValues = function (initialEntity, currentEntity) {
    if (initialEntity !== null) {
        var aProps = Object.getOwnPropertyNames(initialEntity);
        var bProps = Object.getOwnPropertyNames(currentEntity);

        if (aProps.length !== bProps.length) {
            return true;
        }

        if (initialEntity.hostName !== currentEntity.hostName) return true;
        if (initialEntity.port !== currentEntity.port) return true;
        if (initialEntity.senderEmailAddress !== currentEntity.senderEmailAddress) return true;
        if (initialEntity.userName !== currentEntity.userName) return true;
        if (initialEntity.password !== currentEntity.password) return true;
        if (initialEntity.tls !== currentEntity.tls) return true;
        if (initialEntity.auth !== currentEntity.auth) return true;

        return false;
    }
};
