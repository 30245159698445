(function () {
    'use strict';

    /**
     * @description Service to handle heatmap for rooms
     */
    angular.module("emsv2App").service("HeatMapGLService", function (HeatmapService, Object3DFactory, WebGLService, ShaderBuilder) {

        var _room = null;
        var _roomObj = null;
        var map = null;
        var heatmapPlane = null;

        /**
         * @description function to add a heatmap to a room
         * @param {Room} room the room object to build a heatmap for
         * @param {THREE.Object3D} roomObj threejs object containing objects for a room
         * @param {object} heatmap the heatmap to use
         * @param {number} heatmapPos the vertical position for the heatmap between 0 .. 1
         * @param {number} heatmapTransparency the transparency value to set for the heatmap
         */
        var addHeatmapToRoom = function (room, roomObj, heatmap, heatmapPos, heatmapTransparency) {
            _room = room;
            _roomObj = roomObj;
            map = heatmap;
            heatmapPlane = Object3DFactory.buildHeatmapPlane(_room, HeatmapService.createGradient(map, 512, 512), map);
            heatmapPlane.position.y = _room.size.y * heatmapPos;
            heatmapPlane.material.uniforms.opacity.value = heatmapTransparency;
            heatmapPlane.material.uniforms.planePos.value = heatmapPos;
            heatmapPlane.material.needsUpdate = true;
            WebGLService.add3DObjectToContent(heatmapPlane);
        };

        /**
         * @description function to remove current heatmap from 3d-context
         */
        var removeHeatmapFromRoom = function () {
            if (heatmapPlane !== null && heatmapPlane.parent !== null) heatmapPlane.parent.remove(heatmapPlane);
        };

        /**
         * @description function to update vertical heatmap position
         * @param {number} pos position of the heatmap between 0 ... 1. 0 - bottom of room, 1 ceiling of room
         */
        var updateHeatmapPos = function (pos) {
            heatmapPlane.position.y = _room.size.y * pos;
            heatmapPlane.material.uniforms.planePos.value = pos;
        };

        /**
         * @description function to update the transparency for the current heatmap
         * @param {number} opacity the opacity to set, between 0 ... 1
         */
        var updateHeatmapTransparency = function (opacity) {
            heatmapPlane.material.uniforms.opacity.value = opacity;
        };

        /**
         * @description function to update current heatmap with new data
         * @param {Room} obj the room object to use
         * @param {object} _map heatmap object describing current heatmap configuration
         */
        var updateValues = function (obj, _map) {
            if (_map.physicalTypeId != map.physicalTypeId) {
                map = _map;
                var gtex = new THREE.Texture(HeatmapService.createGradient(map, 512, 512));
                gtex.needsUpdate = true;
                heatmapPlane.material.uniforms.gradient.value = gtex;
            }
            heatmapPlane.material.uniforms.vtex.value = ShaderBuilder.createValueTexture(obj, map.physicalTypeId);
            heatmapPlane.material.needsUpdate = true;
        };

        /**
         * @description function to get 3d-object for heatmap from 3d-context
         * @returns {*} returns THREE.Mesh
         */
        var getHeatMapPlane = function(){
            return heatmapPlane;
        };

        return {
            addHeatmapToRoom: addHeatmapToRoom,
            removeHeatmapFromRoom: removeHeatmapFromRoom,
            updateHeatmapPos: updateHeatmapPos,
            updateHeatmapTransparency: updateHeatmapTransparency,
            updateValues: updateValues,
            getHeatMapPlane: getHeatMapPlane
        };
    });

})();
