(function () {

    'use strict';

    function WidgetIpWebcamController($scope, $uibModal, $log, WidgetData, $translate, $http, Tools, Notify, $interval, $state) {

        var ctrl = this;
        var newSettings = [];
        var currentModal;
        var initIp = "";
        var initCustomurl = "";
        var initUsername = "";
        var initPassword = "";
        var initProtocol = "";
        var initIntervalInSec = null;
        var initCammodel = "";

        ctrl.cammodel = "Reolink410";
        ctrl.ip = "";
        ctrl.base64Data = null;
        ctrl.videoTagId = 'imageTag' + $scope.widget.wid;
        ctrl.videoErrorSpanId = 'errorSpan' + $scope.widget.wid;
        ctrl.error = $translate.instant("dashboard.widget.ipwebcam.error.streamTimeout");
        ctrl.username = "";
        ctrl.password = "";
        ctrl.completeurl = "";
        ctrl.intervalInSec = null;
        ctrl.intervalInMs = null;
        ctrl.intervalGetImage = null;
        ctrl.intervalWatchError = null;
        ctrl.selectedOption = null;
        ctrl.canSave = null;
        ctrl.connectionLost = false;
        ctrl.protocol = "http://";
        ctrl.customUrl = "";

        ctrl.brokenUrl = false;

        ctrl.$onInit = function () {
            if (angular.isDefined(ctrl.intervalGetImage)) {
                $interval.cancel(ctrl.intervalGetImage);
            }
            if (angular.isDefined(ctrl.intervalWatchError)) {
                $interval.cancel(ctrl.intervalWatchError);
            }
            $scope.$parent.setConfigMethod(ctrl._configure);
            ctrl.completeurl ="";
            _loadSettings();
            ctrl.intervalInMs = ctrl.intervalInSec * 1000;
            ctrl.getStreamImage();
        };

        ctrl._configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/ipwebcam/widget.ipwebcam.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        };
        ctrl.closeModal = function () {
            if (currentModal) currentModal.close();
        };

        ctrl.cancelModal = function () {
            if (ctrl.ip !== initIp) {
                ctrl.ip = initIp;
            }
            if (ctrl.customUrl !== initCustomurl) {
                ctrl.customUrl = initCustomurl;
            }
            if (ctrl.username !== initUsername) {
                ctrl.username = initUsername;
            }
            if (ctrl.password !== initPassword) {
                ctrl.password = initPassword;
            }
            if (ctrl.protocol !== initProtocol) {
                ctrl.protocol = initProtocol;
            }
            if (ctrl.intervalInSec !== initIntervalInSec) {
                ctrl.intervalInSec = initIntervalInSec;
            }
            if (ctrl.cammodel !== initCammodel) {
                ctrl.cammodel = initCammodel;
            }
            ctrl.closeModal();
        };


        function _loadSettings() {
            if ($scope.widget.settings !== null) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch ($scope.widget.settings[i].key) {
                        case 'ip':
                            ctrl.ip = $scope.widget.settings[i].value;
                            initIp = angular.copy(ctrl.ip);
                            break;
                        case 'customurl':
                            ctrl.customUrl = $scope.widget.settings[i].value;
                            initCustomurl = angular.copy(ctrl.customUrl);
                            break;
                        case 'username':
                            ctrl.username = $scope.widget.settings[i].value;
                            initUsername = angular.copy(ctrl.username);
                            break;
                        case 'password':
                            ctrl.password = $scope.widget.settings[i].value;
                            initPassword = angular.copy(ctrl.password);
                            break;
                        case 'protocol':
                            ctrl.protocol = $scope.widget.settings[i].value;
                            initProtocol = angular.copy(ctrl.protocol);
                            break;
                        case 'completeurl':
                            ctrl.completeurl = $scope.widget.settings[i].value;
                            // initCompleteurl = angular.copy(ctrl.completeurl);
                            break;
                        case 'interval':
                            ctrl.intervalInSec = parseInt($scope.widget.settings[i].value);
                            initIntervalInSec = angular.copy(ctrl.intervalInSec);
                            break;
                        case 'cammodel':
                            ctrl.cammodel = $scope.widget.settings[i].value;
                            initCammodel = angular.copy(ctrl.cammodel);
                            break;
                    }
                }
            } else {
                $log.error('Couln\'t load webcam widget settings!');
                ctrl.intervalInSec = 2
            }
        }

            ctrl.saveSettings = function () {
            switch (ctrl.cammodel) {
                case "Reolink410":
                    if (ctrl.customUrl !== "") {
                        ctrl.customUrl = "";
                    }
                    ctrl.completeurl = ctrl.protocol + ctrl.ip + "/cgi-bin/api.cgi?cmd=Snap&channel=0" + "&rs=952700019N7P0KPZ&user=" + ctrl.username + "&password=" + ctrl.password;
                    ctrl.pushNewSettings();
                    break;
                case "DivioNBR525P":
                    // ctrl.completeurl = ctrl.protocol + ctrl.username + ":" + ctrl.password + "@" + ctrl.ip + "/cgi-bin/imgcap.jpg";
                    if (ctrl.customUrl !== "") {
                        ctrl.customUrl = "";
                    }
                    if (ctrl.username !== "" && ctrl.password !== "" && ctrl.ip !== "") {
                        getStreamFromBackend(ctrl.ip, ctrl.username, ctrl.password, ctrl.cammodel);
                        ctrl.pushNewSettings();
                    }
                    break;
                case "Custom":
                    if(ctrl.username !== "" || ctrl.password !== "" || ctrl.ip !== "") {
                        ctrl.username = "";
                        ctrl.password = "";
                        ctrl.ip = "";
                    }
                    ctrl.completeurl = ctrl.customUrl;
                    ctrl.pushNewSettings();
            }
        };

        ctrl.pushNewSettings = function () {
            newSettings.push({key: 'customurl', value: ctrl.customUrl});
            newSettings.push({key: 'ip', value: ctrl.ip});
            newSettings.push({key: 'username', value: ctrl.username});
            newSettings.push({key: 'password', value: ctrl.password});
            newSettings.push({key: 'protocol', value: ctrl.protocol});
            if (ctrl.cammodel !== "DivioNBR525P") {
                newSettings.push({key: 'completeurl', value: ctrl.completeurl});
            } else {
                newSettings.push({key: 'completeurl', value: ""});
            }
            newSettings.push({key: 'interval', value: ctrl.intervalInSec === null ? 2 : ctrl.intervalInSec});
            newSettings.push({key: 'cammodel', value: ctrl.cammodel});
            $scope.widget.settings = newSettings;
            $log.debug("Saving Settings:", $scope.widget.settings);
            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    _loadSettings();
                    $state.go($state.current, {}, {reload: true});
                    ctrl.closeModal();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.speedometer.modal.saveErrorMsg", 2000);
                });
            }
            ctrl.closeModal();
        }

        ctrl.tryReconnect = function () {
            ctrl.getStreamImage();
        }

        var getStreamFromBackend = function (ip, username, password, cammodel) {
            $http.get('api/ipwebcam/picture/' + ip + '/' + username+ '/' + password + '/' + cammodel).then(function (response) {
                ctrl.completeurl = response.data;
            }, function (error) {
                $log.error('Could not load Image!', error);
                ctrl.completeurl = "";
            });
        }

        var checkForStream = function () {
            var imgTag = document.getElementById('imageTag' + $scope.widget.wid);
            var errorSpan = document.getElementById('errorSpan' + $scope.widget.wid);
            if(imgTag && errorSpan && imgTag.style.display == "none" &&  errorSpan.style.display == "block") {
                if (angular.isDefined(ctrl.intervalGetImage)) {
                    $interval.cancel(ctrl.intervalGetImage);
                }
            }
        }

        var getStream = function () {
            if(ctrl.cammodel === initCammodel && ctrl.ip === initIp && ctrl.username === initUsername && ctrl.password === initPassword && ctrl.customUrl === initCustomurl) {
                switch (ctrl.cammodel) {
                    case "Reolink410":
                        ctrl.completeurl = ctrl.completeurl + "&" + new Date().getTime();
                        checkForStream();
                        break;
                    case "DivioNBR525P":
                        if (ctrl.username !== "" && ctrl.password !== "" && ctrl.ip !== "" && Tools.isDefinedNotNull(ctrl.intervalInSec)) {
                            getStreamFromBackend(ctrl.ip, ctrl.username, ctrl.password, ctrl.cammodel);
                            checkForStream();
                        }
                        break;
                    case "Custom":
                        //this is for custom link for webcam models like DivioNBR525P (e.g.: http://Admin:1234@10.53.49.81/cgi-bin/imgcap.jpg)
                        var format = /[@]+/;
                        if (format.test(ctrl.customUrl)) {
                            var searchIp = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
                            var ip = ctrl.customUrl.match(searchIp)[0];
                            var username = ctrl.customUrl.split('//').pop().split('@')[0].split(':')[0];
                            var password = ctrl.customUrl.split('//').pop().split('@')[0].split(':')[1];
                            if (Tools.isDefinedNotNull(username) && username !== "" &&
                                Tools.isDefinedNotNull(password) && password !== "" &&
                                Tools.isDefinedNotNull(ip) && ip !== "" && Tools.isDefinedNotNull(ctrl.intervalInSec)) {
                                getStreamFromBackend(ip, username, password, 'DivioNBR525P');
                            }
                        } else {
                            ctrl.completeurl = ctrl.customUrl + "&" + new Date().getTime();
                            checkForStream();
                        }

                }
            }
        }

        ctrl.getStreamImage = function () {
            // ctrl.connectionLost = false;
            getStream();
            if (ctrl.intervalInMs < 1000) ctrl.intervalInMs = 2000;
            ctrl.intervalGetImage = $interval(function () {
                getStream();
            }, ctrl.intervalInMs)

            // $.ajax({
            //     url: ctrl.updatedUrl,
            //     data: { }
            // }).done(function(r) {
            //     ctrl.connectionLost = false;

            // }).fail(function(xhr, t, err) {
            //     if (t === "timeout") {
            //         Notify.warning("global.notification.warning.warn", "dashboard.widget.ipwebcam.warning", 5000);
            //         ctrl.connectionLost = true;
            //         if (angular.isDefined(ctrl.intervalGetImage)) {
            //             $interval.cancel(ctrl.intervalGetImage);
            //         }
            //         if (angular.isDefined(ctrl.intervalWatchError)) {
            //             $interval.cancel(ctrl.intervalWatchError);
            //         }
            //     } else {
            //         Notify.warning("global.notification.warning.warn", "dashboard.widget.ipwebcam.warning", 5000);
            //         ctrl.connectionLost = true;
            //         if (angular.isDefined(ctrl.intervalGetImage)) {
            //             $interval.cancel(ctrl.intervalGetImage);
            //         }
            //         if (angular.isDefined(ctrl.intervalWatchError)) {
            //             $interval.cancel(ctrl.intervalWatchError);
            //         }
            //     }
            // });
        }

        $scope.$on("$destroy", function () {
            if (angular.isDefined(ctrl.intervalGetImage)) {
                $interval.cancel(ctrl.intervalGetImage);
            }
            if (angular.isDefined(ctrl.intervalWatchError)) {
                $interval.cancel(ctrl.intervalWatchError);
            }
        });
    }

    angular.module('emsv2App')
        .controller('widgetIpWebcamController', WidgetIpWebcamController)
})();
