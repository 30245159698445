(function () {

    'use strict';

    var translatePartialLoader = ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        $translatePartialLoader.addPart('legal');
        return $translate.refresh();
    }];

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('imprint', {
                    parent: 'account',
                    url: '/imprint',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.legal.imprint'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/legal/legal.html',
                            controller: 'LoginLegalController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
                .state('privacy', {
                    parent: 'account',
                    url: '/privacy',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.legal.privacy'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/legal/legal.html',
                            controller: 'LoginLegalController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
                .state('terms', {
                    parent: 'site',
                    url: '/terms',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.legal.terms'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/legal/legal.html',
                            controller: 'LoginLegalController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader
                    }
                })
                .state('data', {
                    parent: 'account',
                    url: '/data',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.legal.data'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/legal/legal.html',
                            controller: 'LoginLegalController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
                .state('maintenance', {
                    parent: 'account',
                    url: '/maintenance',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.legal.maintenance'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/legal/legal.html',
                            controller: 'LoginLegalController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
                .state('legalConfirm', {
                    parent: 'account',
                    url: '/legal/confirm',
                    data: {
                        roles: ['ROLE_USER'],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'login.legal.terms'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/legal/legal.confirm.html',
                            controller: 'LegalConfirmController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader,
                        currentUser: ['Principal', function (Principal) {
                            return Principal.identity().then(function (user) {
                                return user;
                            }, function (error) {
                                $log.error('Couldn\'t load user: ', error);
                            });
                        }]
                    }
                });
        });

})();
