'use strict';

/**
 * @description Constructor for sensor object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {string} inventoryNumber the inventory number to set
 * @param {string} serialNumber the serial number to set
 * @param objectMac deprecated
 * @param {string} url the url to set
 * @param {string} floorPanelCustomPos the custom floor panel position
 * @param {number} partLibrarySensorId the part library id of the part used to create this sensor
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set (room id)
 * @constructor
 */
function Sensor(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, objectMac, url, floorPanelCustomPos, partLibrarySensorId, uid, pid, driverValues) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.objectMac = objectMac !== undefined ? objectMac : "";
    this.url = url !== undefined && url !== null ? url : "";
    // Next all controls for a deep copy of a sensor (a copy with all parameters inside of it)
    if (driverValues !==null && driverValues !==undefined && driverValues.length > 0) {
        for (var i = 0; i < driverValues.length; i++) {
            driverValues[i].id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.id= angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (driverValues[i].limits.length > 0) {
                var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var l=0; l < driverValues[i].limits.length; l++) {
                    driverValues[i].limits[l].id = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].driverValueId = driverValueId;
                }
            }
        }
        this.driverValues = driverValues;
    } else {
        this.driverValues = [];
    }

    this.roomId = pid !== undefined ? pid : null;
    this.floorPanelCustomPos = floorPanelCustomPos !== undefined ? floorPanelCustomPos : "";
    this.partLibrarySensorId = partLibrarySensorId !== undefined ? partLibrarySensorId : null;
    this.stdFloorPos = null;
    this.floorPosition = "";
    this.allowDataPoints = true;
    this.isSensor = true;
}

Sensor.prototype = Object.create(NamedEntity.prototype);
Sensor.prototype.constructor = Sensor;

/**
 * @description Function to test equality between this object and the provided object
 * @param {object} cs the object to test equality for
 * @returns {boolean} returns true if this object equals the provided object, otherwise false
 */
Sensor.prototype.equals = function (cs) {
    if (!(cs instanceof Sensor)) return false;
    if (!this.equalsNamedEntity(cs)) return false;
    if (this.roomId !== cs.roomId) return false;
    if (this.inventoryNumber !== cs.inventoryNumber) return false;
    if (this.serialNumber !== cs.serialNumber) return false;
    if (this.objectMac !== cs.objectMac) return false;
    if (this.url !== cs.url) return false;
    if (this.floorPanelCustomPos !== cs.floorPanelCustomPos) return false;
    if (this.partLibrarySensorId !== cs.partLibrarySensorId) return false;
    if (!this.compareDriverValues(cs.driverValues)) return false;
    return true;
};

/**
 * @description Function to setup used/unused parameters for this object
 * @param {array} availableParameters array of all possible parameters
 * @returns array with sub-arrays for used and unused parameters
 */
Sensor.prototype.setupParameters = function (availableParameters) {
    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = this.driverValues.filter(function (elem) {
            return elem.parameter.id === availableParameters[ap].id;
        });
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        }
        else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to retrieve the value of a driver value specified by its physical type
 * @param {number} physType the physical type id to use
 * @returns {*} returns the value of driver value with specified physical type if found, otherwise null
 */
Sensor.prototype.getValueForPhysType = function (physType) {
    for (var i in this.driverValues) {
        if (this.driverValues[i].physicalType === physType) return this.driverValues[i].value;
    }
    return null;
};

/**
 * @description Function to test collisions
 * @param {THREE.Object3D} obj3d_self the 3d object representing this sensor
 * @param {THREE.Object3D} obj3d_room the 3d object representing this sensor parent room
 * @param {THREE.Vector3} pos the new position to run the collision test for, if not defined this objects position will be used
 * @param {boolean} onlyWalls if set to true collision detection is only run against walls
 * @param {Room} roomObj the room object containing this sensor
 * @returns {boolean} returns true if
 */
Sensor.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d_self.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    if (!roomObj.checkOBBInRoom(testObb, false)) return true;
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === 'asset' || o.name === "ups")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    return false;
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
Sensor.prototype.validate = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateNamedEntity());
    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));
    for (var i = 0; i < this.driverValues.length; i++) {
        errorList = errorList.concat(this.driverValues[i].validate());
    }
    return errorList;
};

Sensor.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    for (var i = 0; i < this.driverValues.length; i++) {
        var objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }

    return [];
};

/**
 * @description Function to get available driver types for sensor objects
 * @param {Object} allDrivers object containing all possible driver types
 * @returns {Object} returns object describing applicable driver types for sensor objects
 */
Sensor.getAvailableDrivers = function (allDrivers) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "WIB8000") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to create a new sensor object from provided json data object (from backend)
 * @param {Object} obj the json data object to use
 * @param {Number} pid the parent id to set (room id)
 * @returns {Sensor} returns the created sensor object
 */
Sensor.parseFromHtmlObject = function (obj, pid) {
    var sensor = new Sensor(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.floorPanelCustomPos, obj.partLibrarySensorId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, sensor);
    sensor.modifyDriverValueLimitsForFrontend();
    return sensor;
};

/**
 * @description Function to create a new sensor object from provided javascript object (dummy copy without object information)
 * @param {Object} obj the object to use
 * @param {Number} pid the parent id to set (room id)
 * @returns {Sensor} returns created sensor object
 */
Sensor.parseFromSimpleObject = function (obj, pid) {
    var sensor = new Sensor(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.objectMac, obj.url, obj.floorPanelCustomPos, obj.partLibrarySensorId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, sensor);
    return sensor;
};
