(function () {

    'use strict';

    function valueFormatter() {

        function _dec(value, places) {
            return parseFloat(Math.floor(value * 100) / 100).toFixed(places);
        }

        function _formatHour(value) {
            value = value / 60;
            var hours = Math.floor((value / 60)).toFixed(0);

            return hours + ':' + _dec((value % 60), 0) + ' (hh:mm)';
        }

        function _formatMinute(value) {
            return _dec((value / 60), 0) + ':' + _dec((value % 60), 0) + ' (mm:ss)';
        }

        function _formatDay(value) {
            var days = _dec((value / (3600 * 24)), 0);
            var hours = _formatHour(value % (3600 * 24));
            var daySuffix = (days >= 2) ? 'Tage' : 'Tag';
            return days + ' ' + daySuffix + ' ' + hours;
        }

        function formatPlain(value, format) {
            var result = _dec(value, 0);
            if (format && format.suffix) {
                result += ' ' + format.suffix;
            }
            return result;
        }

        function formatPlot103(value, format) {
            if (value < 1000) {
                value = _dec(value, 0) + ' ';
            }
            else if (value < 1000000) {
                value /= 1000;
                value = _dec(value, 2);
                value += ' k';
            }
            else if (value < 1000000000) {
                value /= 1000000;
                value = _dec(value, 2);
                value += ' M';
            }
            else {
                value /= 1000000000;
                value = _dec(value, 2);
                value += ' G';
            }

            return value + format.suffix;
        }

        function formatTime(value) {
            if (value < 60) {
                value += ' sec';
            }
            else if (value < 3600) {
                value = _formatMinute(value);
            }
            else if (value < (3600 * 24)) {
                value = _formatHour(value);
            }
            else {
                value = _formatDay(value);
            }
            return value;
        }

        function formatVolume(value) {
            if (value < 1000) {
                value = _dec(value, 0);
                value += 'l';
            }
            else {
                value /= 1000;
                value = _dec(value, 2);
                value += ' m3';
            }
            return value;
        }

        function formatTenth(value, format, temp, Tools) {
            value = _dec((value / 10), 2);
            if (format.suffix === "°C") { return Tools.calcTempUnit(value, temp); }
            return value + ' ' + format.suffix;
        }

        // Values with thousandth format are converted to Tenth in the backend
        function formatThousandth(value, format, temp, Tools) {
            value = _dec((value / 10), 2);
            if (format.suffix === "°C") { return Tools.calcTempUnit(value, temp); }
            return value + ' ' + format.suffix;
        }

        function formatIndividual(value, format) {
            if (value !== 0) {
                value /= 1000;
            }
            if (format === null || format === "") return value;
            return value + ' ' + format.suffix;
        }

        function formatSpecial(value, format) {
            var labels = format.suffix.split('|');
            for (var i = 0; i < labels.length; i++) {
                if (labels[i].charAt(0) == value) {
                    var label = labels[i].split('=');
                    return label[1];
                }
            }
            return '';
        }

        return {
            format: function (value, format, temp, parameter, Tools) {
                switch (format.typ) {
                    case 0:
                        return formatPlain(value, format);
                    case 1:
                        return formatPlot103(value, format);
                    case 2:
                        return formatTenth(value, format, temp, Tools);
                    case 3:
                        return formatTime(value);
                    case 4:
                        return formatVolume(value);
                    case 5:
                        return formatSpecial(value, format); //value + ' ' + format.suffix;// special
                    case 6:
                        return formatThousandth(value, format, temp, Tools);
                    case 7:
                        return formatIndividual(value, format);
                    default:
                        return value + ' ' + format.suffix;
                }
            }
        };
    }

    angular
        .module('emsv2App')
        .factory('ValueFormatter', valueFormatter);

})
();
