(function () {

    'use strict';

    function userService($http) {
        return {
            query: function () {
                return $http.get('api/users');
            },
            get: function (userId) {
                return $http.get('api/users/' + userId);
            },
            update: function (data) {
                return $http.put('api/users', data);
            },
            remove: function (userId) {
                return $http.delete('api/users/' + userId);
            },
            deviceCount: function () {
                return $http.get('api/users/current/devices/count');
            },
            devices: function () {
                return $http.get('api/users/current/devices');
            },
            getGroups: function () {
                return $http.get('api/groups');
            },
            rsql: function (page, query) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/users/query/' + page + '/20/' + query);
            }
        };
    }

    angular.module('emsv2App').factory('User', userService);

})();
