(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('password.reset', {
                    parent: 'password',
                    url: '/reset?token',
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/password/reset/reset.html',
                            controller: 'PasswordResetController',
                            controllerAs: '$ctrl'
                        }
                    },
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                    },
                    resolve: {
                        token: ['$log', 'Auth', '$state', '$stateParams', function ($log, Auth, $state, $stateParams) {
                            return Auth.checkToken($stateParams.token).then(function (ret) {
                                if (ret.data) {
                                    return $stateParams.token;
                                }
                                $state.go('login');
                            }, function (error) {
                                $log.error('Couln\'t load Token', error);
                                $state.go('login');
                            });
                        }]
                    }

                });
        });

})();
