(function () {
    'use strict';

    function buildingWrapperController($state, LocationDetailService) {
        var ctrl = this;

        ctrl.$onInit = function () {
            LocationDetailService.reset().setDefaultBackToOverview();
        };
    }

    angular
        .module('emsv2App')
        .controller('BuildingWrapperController', buildingWrapperController);

})();
