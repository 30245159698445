(function () {
    'use strict';

    /**
     * @description Service to handle room editor copy/paste actions
     */
    angular.module("emsv2App").service("ClipboardService", function () {
        var clipboardItem = null;

        /**
         * @description function to setup the provided object as clipboard item
         * @param {object} object the object to setup
         */
        var putItem = function (object) {
            clipboardItem = angular.copy(object);
        };

        /**
         * @description function to get the current clipboard item
         * @returns {*} returns the current clipboard item
         */
        var getItem = function () {
            return angular.copy(clipboardItem);
        };

        /**
         * @description function to reset the clipboard item
         */
        var clearClipboard = function () {
            clipboardItem = null;
        };

        /**
         * @description function to determine if there is a clipboard item present
         * @returns {boolean}
         */
        var hasItem = function () {
            return clipboardItem !== null;
        };
        return {
            putItem: putItem,
            getItem: getItem,
            clearClipboard: clearClipboard,
            hasItem: hasItem
        }
    });
})();
