(function () {
    'use strict';

    var heatmapInfo = ['$log', '$stateParams', 'HeatmapService', function ($log, $stateParams, HeatmapService) {
        return HeatmapService.getHeatmap($stateParams.id).then(function (res) {
            return res.data;
        }, function (error) {
            $log.error("Couldn't load heatmap!", error);
            return [];
        });
    }];
    var isLicensed = function (license, module) {
        if (license !== null
            && license !== undefined
            && license.data !== null
            && license.data !== undefined) {
            if (module.indexOf('*') !== -1) {
                var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                var keys = Object.keys(license.data);
                for (var i in keys) {
                    if (regex.test(keys[i]) && keys[i]) return true;
                }
            }
            else if (license.data[module] !== null) {
                return license.data[module];
            }
        }
        return false;
    };
    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('heatmap', {
                    parent: 'admin',
                    url: '/heatmap',
                    data: {
                        roles: ['ROLE_LOCATION_VIEW', 'ROLE_LOCATION_CREATE'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'heatmap.breadcrump.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/heatmap/heatmap.view.html',
                            controller: 'HeatmapController',
                            controllerAs: "ctrl"
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('hm');
                            $translatePartialLoader.addPart('room');
                            return $translate.refresh();
                        }],
                        currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                            return Principal.identity().then(function (user) {
                                $log.debug('Loaded userInfo: ', user);
                                if (user.hasRole('ROLE_LOCATION_CREATE') || user.hasRole('ROLE_LOCATION_EDIT')) {
                                    License.get().then(function (response) {
                                        var license = response.data;
                                        if (isLicensed(license, 'heatmap')) {
                                            return user;
                                        }
                                        else {
                                            $state.go("accessdenied");
                                        }
                                    }, function (error) {
                                        $log.error('Couldn\'t load license!', error);
                                    });
                                }
                                else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error("Couldn't load user ", error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('heatmap.create', {
                    parent: 'heatmap',
                    url: '/create',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'heatmap.breadcrump.create',
                        edit: true,
                        create: true
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/heatmap/edit/heatmap.edit.view.html',
                            controller: 'HeatmapEditController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('hm');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('heatmap.selected', {
                    parent: 'heatmap',
                    url: '/:id',
                    abstract: false,
                    redirectTo: 'heatmap.edit',
                    data: {
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: '{{heatmapInfo.name}}'
                    },
                    resolve: {
                        heatmapInfo: heatmapInfo
                    }
                })
                .state('heatmap.edit', {
                    parent: 'heatmap.selected',
                    url: '/edit',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'heatmap.breadcrump.edit',
                        edit: true,
                        create: false
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/heatmap/edit/heatmap.edit.view.html',
                            controller: 'HeatmapEditController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('hm');
                            $translatePartialLoader.addPart('room');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('heatmap.view', {
                    parent: "heatmap.selected",
                    url: '/view',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'heatmap.breadcrump.view',
                        edit: false,
                        create: false
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/heatmap/edit/heatmap.edit.view.html',
                            controller: 'HeatmapEditController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('hm');
                            return $translate.refresh();
                        }]
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });
})();
