'use strict';

/**
 * @description Constructor to create new driver parameter object
 * @param {Number} id the id to set
 * @param {Number} uniqueId the unique id to set
 * @param {Number} groupId the group id to set
 * @param {String} name the name to set
 * @param {Number} entityType the entity type to set
 * @param {Boolean} writePermission the write permission to set
 * @param {Number} physType the physical type to set
 * @param {Boolean} customEntity
 * @param {Number} oidId the oid id to set
 * @param {DriverParameterValue[]} parameterValues
 * @param {object} oidDataPoint
 * @constructor
 */
function DriverParameter(id, uniqueId, groupId, name, entityType, writePermission, physType, customEntity, oidId, parameterValues, oidDataPoint) {
    this.id = id !== undefined ? id : null;
    this.uniqueId = uniqueId !== undefined ? uniqueId : null;
    this.groupId = groupId !== undefined ? groupId : null;
    this.name = name !== undefined ? name : "";
    this.entityType = entityType !== undefined ? entityType : null;
    this.writePermission = writePermission !== undefined ? writePermission : false;
    this.physType = physType !== undefined ? physType : 0;
    this.customEntity = customEntity !== undefined ? customEntity : false;
    this.oidId = oidId !== undefined ? oidId : null;
    this.parameterValues = parameterValues !== undefined ? parameterValues : [];
    this.oidDataPoint = oidDataPoint !== undefined ? oidDataPoint : null;
}

DriverParameter.prototype.constructor = DriverParameter;

/**
 * @description Function to create driver parameter object from oid object
 * @param {Object} oidObject the oid object to use
 * @returns {DriverParameter} returns created driver parameter
 */
DriverParameter.generateFromOidObject = function (oidObject) {
    var dp = new DriverParameter();
    dp.name = oidObject.name;
    dp.entityType = 1;
    dp.writePermission = oidObject.readOnly;
    dp.physType = oidObject.physicalTypeId;
    dp.customEntity = false;
    dp.oidId = oidObject.id;
    for (var i in oidObject.values) {
        this.parameterValues.push(new DriverParameterValue(null, null, oidObject.values[i].description, oidObject.values[i].value));
    }
    return dp;
};
