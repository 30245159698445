(function () {

    'use strict';

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('location.overview', {
                parent: 'location.detail',
                url: '/overview',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.overview',
                    edit: false
                },
                templateUrl: 'scripts/app/location/tabs/overview/overview.html',
                controller: 'overviewController',
                controllerAs: 'octrl',
                resolve: {
                    security: ['$stateParams', '$state', '$q', 'userInfo', 'Location', function ($stateParams, $state, $q, userInfo) {
                        if ($stateParams.mode === 'edit') {
                            if (userInfo.roles.indexOf("ROLE_ADMIN") === -1 && userInfo.roles.indexOf("ROLE_LOCATION_EDIT") === -1) {
                                return $state.go('location.overview', {
                                    id: $stateParams.id,
                                    mode: 'view'
                                }, {reload: true});
                            }
                        }
                        else if ($stateParams.mode !== 'view') {
                            return $state.go('locations', {reload: true});
                        }
                    }]
                }
            });
    }
    angular
        .module('emsv2App')
        .config(stateConfiguration);
})();
