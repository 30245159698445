(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name PIL (Physical Interpretation Layer)
     */
    angular.module('emsv2App')
        .service('PIL', ["$http", function ($http) {

            return {
                getTempSetting: function () {
                    return $http.get("/api/pil/tempSettings");
                }
            }
        }]);
})();
