(function () {

    'use strict';

    function livedataController(Location, LocationDetailService, $state, $window, $stateParams, $log, EMSConstants, LiveDataService, $uibModal, GenDialogService,
                                MessageService, $translate, $q, $scope, user, license, $interval, Tools, Buildings, buildings,
                                localStorageService, RoomService, rooms) {
        var ctrl = this;
        ctrl.user = user;
        ctrl.buildings = buildings;
        ctrl.rooms = rooms;
        var httpCanceller = $q.defer();
        var isIntervalRequestIsInProgress = false;
        ctrl.tableLiveRecord = [];
        ctrl.livedata = ctrl.rooms;
        ctrl.tableModel = {
            head: [
                {name: 'favorite', translation: 'livedata.table.favorite', sortable: true},
                {name: 'name', translation: 'livedata.table.entityName'},
                {name: 'type', translation: 'livedata.table.type', sortable: true},
                {name: 'parameter', translation: 'livedata.table.parameter', sortable: true},
                {name: 'value', translation: 'livedata.table.value', sortable: false},
                {name: 'trend', translation: 'livedata.table.trend', sortable: false}
            ],
            table: ctrl.tableLiveRecord,
            translation: 'livedata.table',
            orderBy: 'name',
            orderDir: true,
        };
        ctrl.sortAsc = null;
        ctrl.searchit = '';
        ctrl.showList = $stateParams.view || parseInt(localStorageService.get("location-livedata-select-view")) || 0;

        ctrl.selectedBuildingId = ctrl.buildings !== undefined && ctrl.buildings.length > 0 && !ctrl.buildingId ? ctrl.buildings[0].id : ctrl.buildingId;

        if ((ctrl.buildingId === undefined || ctrl.buildingId === null) && buildings.length > 0) ctrl.buildingId = buildings[0].id;
        var timeoutId = null;

        ctrl.$onInit = function () {
            LocationDetailService.reset().setDefaultBackToOverview();
            ctrl.sortCols('favorite');
            if (ctrl.buildingId != undefined || ctrl.buildingId != null) {
                getLiveDataForBuilding(ctrl.buildingId);
                timeoutId = $interval(function () {
                    getLiveDataForBuilding(ctrl.buildingId);
                }, 10000);
            }
        };

        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
        });

        ctrl.sortCols = function (col) {

            var entityFieldName = getEntityFieldName(col);
            if (entityFieldName !== ctrl.currCol) {
                ctrl.sortAsc = true;
                ctrl.currCol = entityFieldName;
            } else if (ctrl.sortAsc === true && ctrl.currCol === entityFieldName) {
                ctrl.sortAsc = false;
            } else if (ctrl.sortAsc === false && ctrl.currCol === entityFieldName) {
                ctrl.sortAsc = true;
            }
        };

        function getEntityFieldName(col) {
            switch (col) {
                case 'favorite':
                    return 'favorite';
                case 'name':
                    return 'name';
                case 'type':
                    return 'type';
                case 'parameter':
                    return 'parameter';
            }
        }

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderBy = sortField;
            ctrl.tableModel.orderDir = sortReverse;
            _sortLivedata(sortField, sortReverse);
        };

        function _sortLivedata(sortField, sortReverse) {
            if (sortReverse) {
                ctrl.tableLiveRecord.sort(function (a, b) {

                    return (a[sortField]) - (b[sortField]);
                })
                ctrl.tableLiveRecord.sort(function (a, b) {
                    return (a[sortField]).localeCompare(b[sortField]);
                })
            } else {
                ctrl.tableLiveRecord.sort(function (a, b) {
                    return (b[sortField]) - (a[sortField]);
                })
                ctrl.tableLiveRecord.sort(function (a, b) {
                    return (b[sortField]).localeCompare(a[sortField]);
                })
            }
        }

        var getLiveDataForBuilding = function (buildingId) {
            if (!isIntervalRequestIsInProgress) {
                isIntervalRequestIsInProgress = true;
                LiveDataService.getLiveDataForBuilding(buildingId, ctrl.user.id, httpCanceller).then(function (response) {
                    ctrl.tableLiveRecord = response.data;
                    isIntervalRequestIsInProgress = false;
                }, function (error) {
                    $log.error("Error live data for building\n" + error + "\n" + error.data);
                    isIntervalRequestIsInProgress = false;
                });
            }
        };

        MessageService.subscribe("livedatabuildingIdChanged", function (msg, opts) {
            ctrl.rooms = rooms.filter(function (r) {
                return r.buildingId === opts.bid;
            });
            getLiveDataForBuilding(opts.bid);
            $state.go('location.livedata', {view: $stateParams.view, bid: opts.bid}, {notify: false});
        });

        ctrl.modMaxValue = function (value, physicalType, uniqueLabel, description) {
            var tempSettings = user.settings.filter(function (settings) {
                return settings.key === "temp";
            });
            if (Tools.isDefinedNotNull(uniqueLabel)) {
                return (value / 1000).toFixed(3) + " " + uniqueLabel;
            } else {
                switch (physicalType) {
                    case 0:
                        return Tools.isDefinedNotNull(description) ? description : (value / 1000).toFixed(3);
                    case 1:
                        return LiveDataService.convertTemp(value / 1000,
                            Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0",
                            1);
                    case 2:
                        return (value / 1000).toFixed(3) + " %rel. H.";
                    case 3:
                        return (value / 1000).toFixed(3) + " RPM";
                    case 4:
                        return (value / 1000).toFixed(3) + " %";
                    case 5:
                        return (value / 1000).toFixed(3) + " Pa";
                    case 6:
                        return Tools.isDefinedNotNull(description) ? description : (value / 1000).toFixed(3);
                    case 7:
                        return Tools.isDefinedNotNull(description) ? description : (value / 1000).toFixed(3);
                    case 8:
                        return (value / 1000).toFixed(3) + " V";
                    case 9:
                        return (value / 1000).toFixed(3) + " A";
                    case 10:
                        return (value / 1000).toFixed(3) + " W";
                    case 11:
                        return (value / 1000).toFixed(3) + " Hz";
                    case 12:
                        return (value / 1000).toFixed(3) + " Wh";
                    case 14:
                        return (value / 1000).toFixed(3) + " RPS";
                    case 15:
                        return (value / 1000).toFixed(3) + " m³/s";
                    case 16:
                        return (value / 1000).toFixed(3) + " s";
                    case 17:
                        return (value / 1000).toFixed(3) + " h";
                    case 18:
                        return (value / 1000).toFixed(3) + " min";
                    case 19:
                        return (value / 1000).toFixed(3) + " m";
                }
            }
            return "unknown";
        };

        MessageService.subscribe("deletedRoom", function (msg, opts) {
            RoomService.getRooms($stateParams.id).then(function (response) {
                rooms = [];
                for (var i in response.data) {
                    rooms.push(Room.parseFromHtmlObject(response.data[i]));
                    rooms[rooms.length - 1].showsTree = false;
                    rooms[rooms.length - 1].ffloor = response.data[i].floor;
                }
                Buildings.setupRooms(buildings, rooms);
                ctrl.rooms = rooms.filter(function (r) {
                    return r.buildingId === opts.buildingId;
                });
            }, function (error) {
                $log.error("Error retrieving rooms after delete." + error);
            });
        });

        ctrl.handleBuildingIdChange = function () {
            MessageService.publish("livedatabuildingIdChanged", {bid: ctrl.selectedBuildingId});
            ctrl.buildingId = ctrl.selectedBuildingId;
        };

        ctrl.addtofavorite = function (roomId, entityId, entityType) {
            LiveDataService.saveLiveDataFavourite(ctrl.user.id, roomId, entityId, $translate.instant(entityType)).then(function (response) {
                return response;
            }, function (error) {
                $log.error('Error while adding to favourite', error);

            });
            getLiveDataForBuilding(ctrl.buildingId);
        };

        (function () {
            Buildings.setupRooms(buildings, rooms);
            if (buildings.length > 0) ctrl.rooms = rooms.filter(function (r) {
                return r.buildingId === ctrl.buildingId;
            });
        })();
    }

    angular
        .module('emsv2App')
        .controller('livedataController', livedataController);
})();


