(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetfavourite', function () {

        return {
            restrict: "E",
            controller: 'WidgetFavouritesController',
            controllerAs: 'favCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/favourites/widget.favourites.template.html',
        };
    });
})();
