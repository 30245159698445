/**
 * Since the gridstack will only be used within the dashboard is has been organized below the dashboard itself.
 *
 * the files gridstack_directive, -controller and -item_directive are wrapping the library gridstack.js for angular usage.
 *
 * make sure that 'gridstack-angular' is injected into the main App
 *
 * dependency:
 * angular 1 (obviously)
 * gridstack.js (https://github.com/troolee/gridstack.js)
 * gridstack-angular (https://github.com/kdietrich/gridstack-angular)
 * lodash >= 3.5.0 (https://lodash.com/)
 * jquery >= 1.11.0 (https://jquery.com/)
 * jquery touch >= 1.11.0 (https://jquery.com/)
 * jquery touch-punch (https://github.com/furf/jquery-ui-touch-punch) -> for touch device support
 *
 * All packages are available at ether npm or bower consult provided links for further information.
 *
 */


(function () {
    'use strict';

    var app = angular.module('emsv2App');

    /** @ngInject */
    app.directive('gridstack', ['$timeout', function ($timeout) {

        return {
            restrict: 'A',
            controller: 'GridstackController',
            scope: {
                onChange: '&',
                onDragStart: '&',
                onDragStop: '&',
                onResizeStart: '&',
                onResizeStop: '&',
                gridstackHandler: '=?',
                options: '='
            },
            link: function(scope, element, attrs, controller, ngModel) {

                var gridstack = controller.init(element, scope.options);
                scope.gridstackHandler = gridstack;

                element.on('change', function(e, items) {
                    $timeout(function() {
                        scope.$apply();
                        scope.onChange({event: e, items: items});
                    });
                });

                element.on('dragstart', function(e, ui) {
                    scope.onDragStart({event: e, ui: ui});
                });

                element.on('dragstop', function(e, ui) {
                    $timeout(function() {
                        scope.$apply();
                        scope.onDragStop({event: e, ui: ui});
                    });
                });

                element.on('resizestart', function(e, ui) {
                    scope.onResizeStart({event: e, ui: ui});
                });

                element.on('resizestop', function(e, ui) {
                    $timeout(function() {
                        scope.$apply();
                        scope.onResizeStop({event: e, ui: ui});
                    });
                });

            }
        };

    }]);
})();
