(function () {

    'use strict';

    var labelController = ['$translate', function ($translate) {
        var ctrl = this;
        ctrl.label = "";

        ctrl.$onInit = function () {
            if (ctrl.model.translation !== undefined && ctrl.model.translation !== null && ctrl.model.translation !== '') {
                ctrl.label = $translate.instant(ctrl.model.translation, ctrl.model.translationParams);
            }
            else {
                ctrl.label = $translate.instant(ctrl.translateBase + '.' + ctrl.model.field);
            }
        }
    }];

    angular.module('emsv2App')
    /**
     * @ngdoc component
     * @name rsqlFilterText
     * @param {Object} model - Model of field
     * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
     */
        .component('rsqlFilterText', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterText.html',
            controller: labelController
        })
        /**
         * @ngdoc component
         * @name rsqlFilterNumber
         * @param {Object} model - Model of field
         * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
         */
        .component('rsqlFilterNumber', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterNumber.html',
            controller: labelController
        })
        /**
         * @ngdoc component
         * @name rsqlFilterBool
         * @param {Object} model - Model of field
         * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
         */
        .component('rsqlFilterBool', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterBool.html',
            controller: labelController
        })
        /**
         * @ngdoc component
         * @name rsqlFilterLong
         * @requires $scope, Tools
         * @param {Object} model - Model of field
         * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
         */
        .component('rsqlFilterLong', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterLong.html',
            controller: ['$scope', '$translate', 'Tools', function ($scope, $translate, Tools) {
                var ctrl = this;
                ctrl.tmpValue = '';
                ctrl.label = "";

                ctrl.$onInit = function () {
                    if (ctrl.model.translation !== undefined && ctrl.model.translation !== null && ctrl.model.translation !== '') {
                        ctrl.label = $translate.instant(ctrl.model.translation, ctrl.model.translationParams);
                    }
                    else {
                        ctrl.label = $translate.instant(ctrl.translateBase + '.' + ctrl.model.field);
                    }

                    if (Tools.isDefinedNotNull(ctrl.model.data)) {
                        ctrl.tmpValue = parseInt(ctrl.model.data, 16);
                    }

                    $scope.$watch(function () { return ctrl.tmpValue; }, function () {
                        ctrl.model.data = ctrl.tmpValue;
                    });
                }

            }]
        })
        /**
         * @ngdoc component
         * @name rsqlFilterList
         * @param {Object} model - Model of field
         * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
         */
        .component('rsqlFilterList', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterList.html',
            controller: ['$translate', function ($translate) {
                // Used for non-writeable fields to show their value
                var ctrl = this;
                ctrl.label = "";

                ctrl.$onInit = function () {
                    if (ctrl.model.translation !== undefined && ctrl.model.translation !== null && ctrl.model.translation !== '') {
                        ctrl.label = $translate.instant(ctrl.model.translation, ctrl.model.translationParams);
                    }
                    else {
                        ctrl.label = $translate.instant(ctrl.translateBase + '.' + ctrl.model.field);
                    }
                };

                ctrl.getValue = function (id) {
                    return ctrl.model.options.find(function (el) {
                        return el.key === id;
                    });
                };
            }]
        })
        /**
         * @ngdoc component
         * @name rsqlFilterContact
         * @param {Object} model - Model of field
         * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
         */
        .component('rsqlFilterContact', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterList.html',
            controller: ['$translate', '$log', 'Contacts', function ($translate, $log, Contacts) {
                // Used for non-writeable fields to show their value
                var ctrl = this;
                ctrl.label = "";

                ctrl.$onInit = function () {
                    Contacts.getCompactList().then(function (response) {
                        var result = [];
                        var i = 0;
                        for (; i < response.data.length; i++) {
                            result.push({key: response.data[i].value1, value: response.data[i].value2});
                        }
                        ctrl.model.options = result;
                    }, function (error) {
                        $log.error('Couldn\'t load contacts!', error);
                    });
                    // if (ctrl.model.translation !== undefined && ctrl.model.translation !== null && ctrl.model.translation !== '') {
                    //     ctrl.label = $translate.instant(ctrl.model.translation, ctrl.model.translationParams);
                    // }
                    // else {
                    //     ctrl.label = $translate.instant(ctrl.model.field);
                    // }
                };

                ctrl.getValue = function (id) {
                    return ctrl.model.options.find(function (el) {
                        return el.key === id;
                    });
                };
            }]
        })
        /**
         * @ngdoc component
         * @name rsqlFilterDate
         * @requires $scope, $translate, Tools
         * @param {Object} model - Model of field
         * @param {string} translateBase - Base translation string to translate the field (example: 'analysis.rsql')
         */
        .component('rsqlFilterDate', {
            bindings: {
                model: '=',
                translateBase: '='
            },
            templateUrl: 'scripts/components/rsql/rsqlFilterDate.html',
            controller: ['$scope', '$translate', 'DateConstants', 'Tools', function ($scope, $translate, DateConstants, Tools) {
                var ctrl = this;
                ctrl.label = "";
                ctrl.datePicker = moment();
                ctrl.datePickerOptions = {
                    singleDatePicker: true,
                    locale: {
                        format: $translate.instant('global.dateFormat'),
                        daysOfWeek: DateConstants.getDaysOfWeek(),
                        monthNames: DateConstants.getMonthNames()
                    },
                    showDropdowns: true
                };

                ctrl.$onInit = function () {
                    if (ctrl.model.translation !== undefined && ctrl.model.translation !== null && ctrl.model.translation !== '') {
                        ctrl.label = $translate.instant(ctrl.model.translation, ctrl.model.translationParams);
                    }
                    else {
                        ctrl.label = $translate.instant(ctrl.translateBase + '.' + ctrl.model.field);
                    }
                    // Watches the operator and changes between single- and multi-date selection
                    $scope.$watch(function () { return ctrl.model.operator; }, function () {
                        ctrl.datePickerOptions.singleDatePicker = !(ctrl.model.operator.operator === '=BW=' || ctrl.model.operator.operator === '=NBW=');
                        if (ctrl.datePickerOptions.singleDatePicker) {
                            ctrl.datePicker = moment();
                        }
                        else {
                            ctrl.datePicker = {
                                startDate: moment(),
                                endDate: moment()
                            };
                        }
                    });

                        $scope.$watch(function () { return ctrl.datePicker; }, function () {
                            var resultDate = null;

                            if (!Tools.isDefinedNotNull(ctrl.datePicker.startDate)) {
                                resultDate = ctrl.datePicker.format('x');
                            }
                            if (Tools.isDefinedNotNull(ctrl.datePicker.endDate)
                                && (ctrl.model.operator.operator === '=BW=' || ctrl.model.operator.operator === '=NBW=')) {
                                resultDate = ctrl.datePicker.startDate.format('x') + ',' + ctrl.datePicker.endDate.format('x');
                            }
                            ctrl.model.data = resultDate;
                        });

                        // Preset value
                        if (Tools.isDefinedNotNull(ctrl.model.data)) {
                            if (String(ctrl.model.data).indexOf(',') > -1) {
                                var parts = String(ctrl.model.data).split(',');
                                ctrl.datePicker.date = {
                                    startDate: moment(parts[0]),
                                    endDate: moment(parts[1])
                                };
                            }
                            else {
                                ctrl.datePicker = moment(ctrl.model.data, 'DDMMYYYY');
                            }
                        }
                    };
                }]
        });

})();
