(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('logout', {
                    parent: 'account',
                    url: '/logout',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'global.logout.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/logout/logout.html',
                            controller: 'LogoutController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('legal');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        });

})();
