'use strict';

/**
 * @description Constructor to create new SnmpIOConfigurationOfSmartEvent object
 * @constructor
 */
function SnmpIOConfigurationOfSmartEvent(snmpIOConfigurationOfSmartEvent) {
    if (snmpIOConfigurationOfSmartEvent !== undefined) {
        this.id = snmpIOConfigurationOfSmartEvent.id !== undefined ? snmpIOConfigurationOfSmartEvent.id : null;
        this.oid = snmpIOConfigurationOfSmartEvent.oid !== undefined ? snmpIOConfigurationOfSmartEvent.oid : null;
        this.snmpConfigurationOfSmartEventId = snmpIOConfigurationOfSmartEvent.snmpConfigurationOfSmartEventId !== undefined ? snmpIOConfigurationOfSmartEvent.snmpConfigurationOfSmartEventId : null;
    } else {
        this.id = null;
        this.oid = null;
        this.snmpConfigurationOfSmartEventId = null;
    }
}

SnmpIOConfigurationOfSmartEvent.prototype = {};
SnmpIOConfigurationOfSmartEvent.prototype.constructor = SnmpIOConfigurationOfSmartEvent;