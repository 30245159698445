(function () {

    'use strict';

    function WidgetImageController($scope, $uibModal, $log, WidgetData, $http, $translate, Notify, Tools, $state) {

        var ctrl = this;
        var currentModal;

        ctrl.url = "";
        ctrl.error = $translate.instant("dashboard.widget.image.error.emptyURL");
        ctrl.fileName = $translate.instant("global.btn.imageSelect");
        ctrl.fileUpload = $translate.instant("global.btn.imageSelect");
        ctrl.imageTagId = 'imageTag' + $scope.widget.wid;
        ctrl.imageErrorSpanId = 'errorSpan' + $scope.widget.wid;
        ctrl.state = $state.current.name;

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl._configure);
            _loadSettings();
        };

        ctrl._configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/image/widget.image.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });

            $scope.fileSelected = function(files) {
                if (files.length) {
                    ctrl.fileName = files[0].name;
                    $("#spanForRefreshIcon").removeClass('glyphicon-refresh-animate');
                }
            };
            $scope.afterClickOnUploadImage = function() {
                    $("#spanForRefreshIcon").addClass('glyphicon-refresh-animate');
            }
        };

        ctrl.closeModal = function () {
            if (currentModal) currentModal.close();
        };

        ctrl.cancelModal = function () {
            ctrl.closeModal();
        };

        function _loadSettings() {
            if ($scope.widget.settings !== null) {
                $scope.widget.settings.sort(function (a, b) {return a.id - b.id});
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    if ($scope.widget.settings[i].key === 'name') {
                        ctrl.fileName = $scope.widget.settings[i].value;
                    }
                    if ($scope.widget.settings[i].key === 'imageDataId') {
                        ctrl.url = '/api/image/' + $scope.widget.settings[i].value;
                    }
                }
            }
            else {
                $log.error('Couldn\'t load image widget\'s settings!');
            }
        }

        ctrl.saveSettings = function () {
            var settings = [];
            var file = document.querySelector('input[type=file]').files[0];
            if (Tools.isDefinedNotNull(file)) {
                ctrl.fileName = file.name;
                var reader = new FileReader();
                reader.onloadend = function () {
                    var currentWidgetId = $scope.widget.wid;

                    var obj = {
                        widgetId: parseInt(currentWidgetId),
                        base64code: reader.result,
                        filename: ctrl.fileName,
                        userId: $scope.widget.ownerId,
                        reportId: (!$scope.isreportwidget && !$scope.isdashboardwidget) ? "report" + Date.now() : null
                    };

                    settings.push({key: 'id', value: currentWidgetId});
                    settings.push({key: 'reportId', value: obj.reportId});
                    settings.push({key: 'name', value: ctrl.fileName});
                    $scope.widget.settings = settings;

                    $log.debug("Saving Settings:", $scope.widget.settings);
                    var imgFormat = reader.result.substring(reader.result.indexOf('/') + 1, reader.result.indexOf(';'));
                    var imgTag = document.getElementById('imageTag' + $scope.widget.wid);
                    var errorSpan = document.getElementById('errorSpan' + $scope.widget.wid);
                    if (imgFormat === 'jpeg' || imgFormat === 'png') {
                        if ($scope.isdashboardwidget) {
                            $http.post("/api/image", obj).then(function (response) {
                               if (response.data && response.data > 1!== null) {
                                   $scope.widget.settings.push({key: 'imageDataId', value: response.data});

                                   loadImage(reader.result);
                                   WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                                       Notify.defaultSuccess();
                                   }, function (error) {
                                       Notify.error("global.notification.error.title", "dashboard.widget.image.saveErrorMsg", 2000);
                                   });
                               } else {
                                   $log.error(response.data.error);
                               }
                           });
                        }else {
                            loadImage(reader.result);
                            $scope.widget.imageObject = obj;
                        }
                    }
                    else {
                        imgTag.style.display = 'none';
                        errorSpan.style.display = 'block';
                        ctrl.error = $translate.instant("dashboard.widget.image.error.wrongFormat");
                        ctrl.fileName = $translate.instant("global.btn.imageSelect");
                    }

                    ctrl.closeModal();
                };

                if (file) {
                    reader.readAsDataURL(file);
                }
            }
            else Notify.defaultError();
        };

        function loadImage(pic) {
            var errorSpanString = 'errorSpan' + $scope.widget.wid;
            var imageTagString = 'imageTag' + $scope.widget.wid;
            setTimeout(function () {
                var errorSpan = document.getElementById(errorSpanString);
                var imgTag = document.getElementById(imageTagString);
                imgTag.style.display = 'block';
                errorSpan.style.display = 'none';
                imgTag.src = pic;
            }, 300);
        }
    }

    angular.module('emsv2App')
        .controller('widgetImageController', WidgetImageController)
})();
