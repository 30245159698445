(function () {
    'use strict';
    angular.module('emsv2App').directive("heatmapGradient", ['Tools', 'HeatmapService','LiveDataService', function (Tools, HeatmapService, LiveDataService) {

        var config = {
            minValue: 0,
            maxValue: 0,
            unitSymbol: "",
            physType: 1,
            infoContainer: null,
            infoContainerSpanId: 'hmICV'
        };

        var buildContainer = function (id, parent) {
            config.infoContainer = document.createElement("div");
            config.infoContainer.id = "heatmapInfoContainer";
            config.infoContainer.style.backgroundColor = "rgba(0,0,0,0.8)";
            config.infoContainer.style.color = "#ffffff";
            config.infoContainer.style.display = "inline-block";
            config.infoContainer.style.position = "absolute";
            config.infoContainer.style.height = "50px";

            var headerContainer = document.createElement("div");
            headerContainer.style.width = "100%";
            headerContainer.style.height = "50%";
            headerContainer.style.padding = "2px 5px 2px 5px";
            headerContainer.innerHTML = "<span id='" + id + "'></span>";
            var botLeftContainer = document.createElement("div");
            botLeftContainer.style.width = "50%";
            botLeftContainer.style.height = "50%";
            var botRightContainer = document.createElement("div");
            botRightContainer.style.width = "50%";
            botRightContainer.style.height = "50%";

            config.infoContainer.appendChild(headerContainer);
            config.infoContainer.appendChild(botLeftContainer);
            config.infoContainer.appendChild(botRightContainer);

            parent.appendChild(config.infoContainer);
        };

        var handleMouseEnter = function (e, map) {

            // buildContainer(config.infoContainerSpanId, e.target.parentNode);
            config.infoContainer = document.createElement('div');
            config.infoContainer.id = "heatmapInfoContainer";
            config.infoContainer.innerHTML = "<span id='hmICV'></span>"; // TODO STYLE HERE PLS DL
            e.target.parentNode.appendChild(config.infoContainer);
            config.infoContainer.style.backgroundColor = "rgba(0,0,0,0.8)";
            config.infoContainer.style.color = "#ffffff";
            config.infoContainer.style.display = 'inline-block';
            config.infoContainer.style.position = 'absolute';

            setContainerPosition(e);
            var val = getValue(e, map);
            var displayVal = "";
            if (config.physType == 1 && config.tempUnit) {
                displayVal = LiveDataService.convertTemp(val, config.tempUnit, 1);
            }
            else {
                displayVal = val.toFixed(1) + " " + map.physicalType.physicalType;
            }
            $('#hmICV').html(displayVal);
        };
        var handleMouseLeave = function (e) {
            //console.log('mouse left');
            $('#heatmapInfoContainer').remove();
            config.infoContainer = null;
        };

        var setContainerPosition = function (e) {
            var pos = $(e.target).position();
            var sizeX = $(config.infoContainer).width();
            var sizeY = $(config.infoContainer).height();
            if (config.infoContainer) {
                config.infoContainer.style.left = (pos.left + e.offsetX - sizeX / 2) + "px";
                config.infoContainer.style.top = (pos.top - sizeY) + "px";
            }
        };

        var getValue = function (e, map) {
            return ((map.maxValue - map.minValue) * (e.offsetX / e.target.clientWidth)) + map.minValue;
        };

        var handleMouseMove = function (e, map) {
            //console.log('mouse move');
            setContainerPosition(e);
            var val = getValue(e, map);
            $('#hmICV').html(val.toFixed(1) + " " + map.physicalType.physicalType);
        };

        var addHeatmap = function (map, tempunit, element, sizex, sizey) {
            $(element).empty();
            if (map == undefined || map.heatmapValues === undefined || map.heatmapValues.length === 0) {
                return;
            }
            var ret = HeatmapService.createGradient(map, sizex, sizey);

            var canvas = ret[0];
            var min = ret[1];
            var max = ret[2];

            config.minValue = min;
            config.maxValue = max;
            config.unitSymbol = map.physicalType.physicalType;
            config.physType = map.physicalTypeId;
            config.tempUnit = tempunit;

            canvas.addEventListener('mouseenter', function (event) {handleMouseEnter(event, map);}, true);
            canvas.addEventListener('mousemove', function (event) {handleMouseMove(event, map);}, false);
            canvas.addEventListener('mouseleave', function (event) {handleMouseLeave(event, map);}, false);
            $(element).append(canvas);
        };
        return {
            scope: {
                map: "=map",
                tempunit: "=tempunit",
                sizex: "=sizex",
                sizey: "=sizey"
            },
            controller: function ($scope) {
                $scope.$watch('map', function (n, o) {
                    if ($scope.map !== undefined) {
                        addHeatmap($scope.map, $scope.tempunit, $scope.element, $scope.sizex, $scope.sizey);
                    }
                }, true);
                $scope.$watch("sizex", function(n,o){
                    if($scope.sizex !== undefined) {
                        addHeatmap($scope.map, $scope.tempunit, $scope.element, $scope.sizex, $scope.sizey);
                    }
                });
            },
            link: function (scope, element, attrs) {
                scope.element = element;
                if (scope.map === undefined) {
                    return;
                }
                addHeatmap(scope.map, scope.tempunit, element, scope.sizex, scope.sizey);
            }
        }

    }]);
})();
