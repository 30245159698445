(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name CustomEventService
     * @requires $http
     */
    angular.module('emsv2App').factory('CustomEventService', function ($http, Tools) {

        var save = function (entity) {
            return $http.post('api/customEvent', entity).then(function (response) {
                return response.data;
            });
        };

        var deleteById = function (id) {
            return $http.delete('api/customEvent/' + id).then(function (response) {
                return response.data;
            });
        };

        var customEventMonthlyCount = function (month, year, page, query, pageSize, fullTextSearch) {
            page = page !== undefined && page !== null ? page : 0;
            query = query !== undefined && query !== null ? query : '';
            pageSize = pageSize !== undefined && pageSize !== null ? pageSize : 20;
            if (Tools.isDefinedNotNull(fullTextSearch)
                && typeof fullTextSearch === 'boolean'
                && fullTextSearch) {
                return $http.get('api/customEvent/customEventCount/calendar/query/' + month + '/' + year + '/' + page + '/' + fullTextSearch + '/' + pageSize + query);
            } else {
                return $http.get('api/customEvent/customEventCount/calendar/query/' + month + '/' + year + '/' + page + '/' + pageSize + query);
            }
        };

        return {
            save: save,
            deleteById: deleteById,
            customEventMonthlyCount: customEventMonthlyCount
        };

    });
})();