(function () {
    "use strict";

    angular.module('emsv2App').service('NodeAttributeService', function ($http) {

        function setNodeAttributes(listOfAttributes) {
            return $http.put("/api/nodeattribute/put", listOfAttributes);
        }
        function getNodeAttributes(deviceId) {
            return $http.get("/api/nodeattribute/get/" + deviceId);
        }

        return {
            setNodeAttributes: setNodeAttributes,
            getNodeAttributes: getNodeAttributes
        }
    });
})();
