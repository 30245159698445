(function () {

    'use strict';

    angular.module('emsv2App').directive('glyphicon', function () {
        return {
            restrict: 'E',
            scope: {
                icon: '=icon'
            },
            template: '<span class="glyphicon glyphicon-{{icon}}"></span>'
        };
    });

})();
