(function () {

    'use strict';

    function authorityService($http) {

        return {
            getAll: function () {
                return $http.get('api/authorities');
            },
            // get: function (login) { TODO: Currently unused
            //     return $http.get('api/users/' + login + '/authorities');
            // },
            set: function (userId, data) {
                return $http.put('api/users/' + userId + '/authorities', data);
            },
            setUserLocations: function (userId, data) {
                return $http.put('api/users/' + userId + '/locations', data);
            },
            getUserLocations: function (userId) {
                return $http.get('api/users/' + userId + '/locations');
            },
            getGroupLocations: function (userId) {
                return $http.get('api/users/' + userId + '/locations/groups');
            },
            getDirectLocations: function (userId) {
                return $http.get('api/users/' + userId + '/locations/direct')
            },
            getGroups: function (userId) {
                return $http.get('api/users/' + userId + '/groups');
            }
        };
    }

    angular.module('emsv2App').service('Authority', authorityService);

})();
