/**
 * Created by DI on 08.06.21.
 */
(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetcontrol', function () {
           return {
            restrict: 'E',
            controller: 'WidgetControlController',
            controllerAs: 'controlCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/control/widget.control.template.html'
        };
    });
})();
