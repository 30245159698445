(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('home', {
                    parent: 'site',
                    url: '/',
                    data: {
                        roles: ['ROLE_USER']
                    },
                    redirectTo: 'dashboard',
                    onEnter: function($timeout, Principal){
                        'ngInject';
                        $timeout(function(){
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                    // views: {
                    //     'content@': {
                    //         templateUrl: '/scripts/app/main/main.html',
                    //         controller: 'MainController'
                    //     }
                    // },
                    // resolve: {
                    //     mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    //         $translatePartialLoader.addPart('main');
                    //         return $translate.refresh();
                    //     }]
                    // }
                });
        });

})();
