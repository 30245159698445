(function () {
    'use strict';

    angular.module("emsv2App").service("EnergyConsumerService", function ($http, $log, $translate, $q) {

        var getConsumer = function () {
            return $http.get('/api/consumer').then(function (response) {
                for (var i in response.data) {
                    response.data[i].name = $translate.instant(response.data[i].name);
                }
                return response;
            }, function (error) {
                $log.error("Error retrieving consumer\n" + error);
            });
        };

        var getConsumerById = function (id) {
            return $http.get('/api/consumer/' + id).then(function (response) {
                response.data.name = $translate.instant(response.data.name);
                return $q.resolve(response);
            }, function (error) {
                return $q.reject(error);
            });
        };

        var getEnergyByLocId = function (locId) {
            return $http.get('api/consumer/energy/' + locId).then(function (response) {
                return $q.resolve(response);
            }, function (error) {
                return $q.reject(error);
            });
        };

        var getAllConsumerByLocId = function (locationId) {
            return $http.get('api/consumer/' + locationId);
        };

        var saveConsumer = function (consumer) {
            return $http.post('/api/consumer', consumer);
        };

        var editConsumer = function (consumer) {
            return $http.post('/api/consumer/edit', consumer);
        };

        var deleteConsumer = function (id) {
            return $http.delete('/api/consumer/' + id);
        };

        var consumerNameExists = function (consumer) {
            return $http.post('/api/consumer/consumerNameExists', consumer);
        };

       var getOldParameterData = function () {
           return $http.get('api/consumer/oldConsumers').then(function (response) {
               return $q.resolve(response);
           });
       };

        var updateOldParameterData = function () {
            return $http.post('api/consumer/updateOldConsumers');

        };

        var updateOldParameterDataById = function (id) {
            return $http.post('api/consumer/updateOldConsumersById',id);

        };
        /**
         * @description translate needed strings to improve performance ('| translate' creates to many watchers)
         * @returns {*}
         */
        var getTranslations = function () {
            return $translate(['global.btn.next','ebalance.updateSuccess1']).then(function (trans) {
                return trans;
            });
        };

        // endregion

        return {
            getConsumer: getConsumer,
            getConsumerById: getConsumerById,
            saveConsumer: saveConsumer,
            editConsumer: editConsumer,
            deleteConsumer: deleteConsumer,
            consumerNameExists: consumerNameExists,
            getEnergyByLocId: getEnergyByLocId,
            getAllConsumerByLocId: getAllConsumerByLocId,
            getOldParameterData: getOldParameterData,
            updateOldParameterData: updateOldParameterData,
            updateOldParameterDataById: updateOldParameterDataById,
            getTranslations: getTranslations
        };
    })
})();
