(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name loadingButton
     * @param {string} text - Default text to show
     * @param {string} actionText - Text to show while working
     * @param {string} successText - Text to show on success
     * @param {string} errorText - Text to show on error
     * @param {string} icon - Icon class to use
     * @param {string} state - Set/get state of the button (init/load/success/error)
     * @param {string} css - CSS for the button
     * @param {boolean} disabled
     * @param {method} action - Callback on button press
     * @param {object} actionParam - Parameter for callback method
     * @param {boolean} responsive - Control responsive behaviour
     */
    angular.module('emsv2App')
        .component('loadingButton', {
            bindings: {
                text: '@',
                actionText: '@',
                successText: '@',
                errorText: '@',
                icon: '@',
                state: '=',
                css: '@',
                disabled: '=',
                action: '=',
                actionParam: '=',
                responsive: '='
            },
            templateUrl: 'scripts/components/loading-button/button.html',
            controller: ['$scope', '$timeout', 'Tools', function ($scope, $timeout, Tools) {
                var ctrl = this;
                ctrl.state = Tools.isDefinedNotNull(ctrl.state) && ctrl.state !== '' ? ctrl.state : 'init';
                ctrl.vars = {
                    icon: Tools.isDefinedNotNull(ctrl.icon) ? ctrl.icon : '',
                    css: Tools.isDefinedNotNull(ctrl.css) ? ctrl.css : ''
                };

                $scope.$watch(function () { return ctrl.state; }, function () {
                    if (ctrl.state === 'error') {
                        $timeout(function () {
                            ctrl.state = 'init';
                        }, 2000);
                    }
                });

                ctrl.click = function () {
                    if (!ctrl.disabled && ctrl.state === 'init') {
                        if (Tools.isDefinedNotNull(ctrl.actionParam)) {
                            if (Array.isArray(ctrl.actionParam)) {
                                ctrl.action.apply(ctrl, ctrl.actionParam);
                            }
                            else {
                                ctrl.action(ctrl.actionParam);
                            }
                        }
                        else {
                            ctrl.action();
                        }
                    }
                };
            }]
        });

})();
