(function () {
    'use strict';

    function userController($log, User, $translate) {
        var ctrl = this;
        ctrl.page = 0;
        ctrl.data = [];
        ctrl.query = '';
        ctrl.hasMore = true;
        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.doQuery = function () {
            return _query(false);
        };
        ctrl.tableModel = {
            tableName: 'jhi_user',
            translation: 'user.management',
            orderBy: 'id',
            orderDir: false,
            //pageTitle: $translate.instant('user.title'),
            doFilter: _doFilter,
            head: [
                {name: 'id', translation: 'user.management.id'},
                {name: 'login', translation: 'user.management.login'},
                {name: 'firstname', translation: 'user.management.firstname'},
                {name: 'lastname', translation: 'user.management.lastname'},
                {name: 'email', translation: 'user.management.email'},
                {name: 'country', translation: 'user.management.country'},
                {name: 'created', translation: 'user.management.created'},
                {empty: true}
            ]
        };

        function _query(reset) {
            ctrl.page = reset ? 0 : ctrl.page + 1;

            return User.rsql(ctrl.page, ctrl.query).then(function (response) {
                ctrl.hasMore = response.data.length === 20;
                if (!reset) {
                    for (var i = 0; i < response.data.length; i++) {
                        ctrl.data.push(response.data[i]);
                    }
                }
                else {
                    ctrl.data = response.data;
                }
            }, function (error) {
                $log.error("Couldn't load users!", error);
                ctrl.data = [];
            });
        }

        function _doFilter(params) {
            ctrl.query = params;
            _query(true);
        }
    }

    angular
        .module('emsv2App')
        .controller('UserController', userController);

})();
