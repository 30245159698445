(function () {
    'use strict';

    function viewGroupController($scope, selectedGroup) {
        $scope.deleteBtn = 'init';
        $scope.group = selectedGroup;
        $scope.isVirtual = typeof selectedGroup.virtual === "boolean" ? selectedGroup.virtual : false;
        $scope.isGenerated = typeof selectedGroup.generated === "boolean" ? selectedGroup.generated : false;
        $scope.isDetail = true;
    }

    angular
        .module('emsv2App')
        .controller('ViewGroupController', viewGroupController);
})();
