(function () {
    'use strict';

    /**
     * @function ShaderBuilder
     * @description Service to handle custom shader setup/creation
     */
    angular.module("emsv2App").service("ShaderBuilder", function (AssetService, NumberService, MathService, Tools) {
        var maskMapFragmentColor = [
            "#ifdef USE_MAP",
            "vec4 texelColor = texture2D(map, vUv);",
            "vec4 maskColor = texture2D(mask, vUv);",
            "#ifdef GAMMA_INPUT",
            "texelColor.xyz *= texelColor.xyz;",
            "#endif",
            "gl_FragColor.rgb = mix(gl_FragColor.rgb, texelColor.rgb, texelColor.a);",
            "vec3 cDiff = dispcolor;",
            "if(temp >= TEMPCRIT)",
            "cDiff.r = 1.0;",
            "if(temp < TEMPCRIT && temp >= TEMPWARN){",
            "cDiff.r = 1.0;cDiff.g=1.0;}",
            "if(temp < TEMPWARN && temp >= BROKEN){cDiff=vec3(0.0,1.0,0.0);}",
            "vec3 surfDiffuse = dispcolor;",
            "vec3 ca = vec3(0,0,0);",
            "surfDiffuse = mix(cDiff,vec3(1,1,1), maskColor.r);",
            "#else",
            "vec3 surfDiffuse = diffuseColor;",
            "vec3 ca = ambientColor;",
            "#endif"
        ].join("\n");

        var maskLightFragmentColor = THREE.ShaderChunk.lights_phong_fragment.replace(/\bdiffuseColor\b/gm, 'surfDiffuse');
        maskLightFragmentColor = maskLightFragmentColor.replace(/\bambientColor\b/gm, 'ca');
        var maskColorFragmentShader = [
            "#define TEMPCRIT 50.0",
            "#define TEMPWARN 40.0",
            "#define TEMPNORM 35.0",
            "#define BROKEN -280.0",
            "uniform vec3 diffuse;",
            "uniform vec3 dispcolor;",
            "uniform float opacity;",
            "uniform vec3 ambient;",
            "uniform vec3 emissive;",
            "uniform vec3 specular;",
            "uniform float shininess;",
            "uniform sampler2D mask;",
            "uniform float temp;",
            THREE.ShaderChunk["color_pars_fragment"],
            THREE.ShaderChunk["map_pars_fragment"],
            THREE.ShaderChunk["lightmap_pars_fragment"],
            THREE.ShaderChunk["envmap_pars_fragment"],
            THREE.ShaderChunk["fog_pars_fragment"],
            THREE.ShaderChunk["uv_pars_fragment"],
            THREE.ShaderChunk['common'],
            THREE.ShaderChunk['bsdfs'],
            THREE.ShaderChunk["lights_phong_pars_fragment"],
            THREE.ShaderChunk["shadowmap_pars_fragment"],
            THREE.ShaderChunk["bumpmap_pars_fragment"],
            THREE.ShaderChunk["normalmap_pars_fragment"],
            THREE.ShaderChunk["specularmap_pars_fragment"],
            "void main() {",
            "gl_FragColor = vec4( vec3 ( 1.0 ), opacity );",
            maskMapFragmentColor,
            THREE.ShaderChunk["alphatest_fragment"],
            THREE.ShaderChunk["specularmap_fragment"],
            // maskLightFragmentColor,
            "BlinnPhongMaterial material;",
            "material.diffuseColor = surfDiffuse.rgb;",
            "material.specularColor = specular;",
            "material.specularShininess = shininess;",
            "material.specularStrength = specularStrength;",
            THREE.ShaderChunk["lightmap_fragment"],
            THREE.ShaderChunk["color_fragment"],
            THREE.ShaderChunk["envmap_fragment"],
            THREE.ShaderChunk["shadowmap_fragment"],
            THREE.ShaderChunk["linear_to_gamma_fragment"],
            THREE.ShaderChunk["fog_fragment"],
            "}"
        ].join("\n");

        var maskColorVertexShader = THREE.ShaderLib.phong.vertexShader;

        /**
         * @description Function to build mask shader material (cooling units/racks)
         * @param {THREE.Texture} maskTex mask texture to use
         * @param {THREE.Texture} diffTex diffuse texture to use
         * @param {THREE.Texture} normalTex normal texture to use
         * @returns {THREE.ShaderMaterial} returns created shader material
         */
        var buildMaskShaderMaterial = function (maskTex, diffTex, normalTex) {
            var uni = THREE.UniformsUtils.clone(THREE.ShaderLib['phong'].uniforms);
            uni.map.value = diffTex;
            uni.mask = {type: "t", value: maskTex};
            if (normalTex !== undefined) uni.normalMap.value = normalTex;
            uni.diffuse.value = new THREE.Color(0x000000);
            uni.temp = {type: "f", value: -280.0};
            uni.dispcolor = {type: "c", value: new THREE.Color(0x000000)};
            var mat = new THREE.ShaderMaterial({
                uniforms: uni,
                vertexShader: maskColorVertexShader,
                fragmentShader: maskColorFragmentShader,
                lights: true,
                transparent: false
            });
            mat.map = true;
            if (normalTex !== undefined) mat.normalMap = true;
            return mat;
        };

        var markerMapFragment = [
            "#ifdef USE_MAP",
            "vec4 texelColor = texture2D( map, vUv ); /* NEWWW */",
            "#ifdef GAMMA_INPUT",
            "texelColor.xyz *= texelColor.xyz;",
            "#endif",
            "gl_FragColor.rgb = mix(gl_FragColor.rgb,texelColor.rgb,texelColor.a);",
            "vec3 surfDiffuse = mix(diffuse,vec3(1,1,1),texelColor.a);",
            "#else",
            "vec3 surfDiffuse = diffuse;",
            "#endif"].join("\n");
        var markerFragmentShader = [
            "uniform vec3 diffuse;",
            "uniform float opacity;",
            "uniform vec3 ambient;",
            "uniform vec3 emissive;",
            "uniform vec3 specular;",
            "uniform float shininess;",
            THREE.ShaderChunk["color_pars_fragment"],
            THREE.ShaderChunk["map_pars_fragment"],
            THREE.ShaderChunk["lightmap_pars_fragment"],
            THREE.ShaderChunk["envmap_pars_fragment"],
            THREE.ShaderChunk["fog_pars_fragment"],
            THREE.ShaderChunk["uv_pars_fragment"],
            THREE.ShaderChunk['common'],
            THREE.ShaderChunk['bsdfs'],
            THREE.ShaderChunk["lights_phong_pars_fragment"],
            THREE.ShaderChunk["shadowmap_pars_fragment"],
            THREE.ShaderChunk["bumpmap_pars_fragment"],
            THREE.ShaderChunk["normalmap_pars_fragment"],
            THREE.ShaderChunk["specularmap_pars_fragment"],
            "void main() {",
            "gl_FragColor = vec4( diffuse, opacity );",
            markerMapFragment,
            THREE.ShaderChunk["alphatest_fragment"],
            THREE.ShaderChunk["specularmap_fragment"],
            "BlinnPhongMaterial material;",
            "material.diffuseColor = surfDiffuse.rgb;",
            "material.specularColor = specular;",
            "material.specularShininess = shininess;",
            "material.specularStrength = specularStrength;",
            THREE.ShaderChunk["lightmap_fragment"],
            THREE.ShaderChunk["color_fragment"],
            THREE.ShaderChunk["envmap_fragment"],
            THREE.ShaderChunk["shadowmap_fragment"],
            THREE.ShaderChunk["linear_to_gamma_fragment"],
            THREE.ShaderChunk["fog_fragment"],
            "}"

        ].join("\n");

        /**
         * @description Function to build editor marker material
         * @returns {THREE.ShaderMaterial} returns created material
         */
        var buildMarkerShaderMaterial = function () {
            return new THREE.ShaderMaterial({
                uniforms: THREE.UniformsUtils.clone(THREE.ShaderLib['phong'].uniforms),
                vertexShader: THREE.ShaderLib.phong.vertexShader,
                fragmentShader: markerFragmentShader,
                lights: true
            });
        };

        var letterVertexShaderX = [
            "varying vec2 vUv;",
            "void main(){",
            "vUv = vec2(uv.x, uv.y);",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
            "}"
        ].join("\n");

        var letterVertexShaderZ = [
            "varying vec2 vUv;",
            "void main(){",
            "vUv = vec2(uv.x, uv.y);",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
            "}"
        ].join("\n");

        var letterFragmentShaderX = [
            "const float LS = 1.0/8.0;",
            "varying vec2 vUv;",
            "uniform vec3 color;",
            "uniform float opacity;",
            "uniform sampler2D letterMap;",
            "uniform float letters[5];",
            "uniform float letterCount;",
            "uniform float ratio;",
            "vec2 getLetterUV(float letter){",
            "letter += 8.0;",
            "vec2 ret = vec2(1.0);",
            "ret.x = mod(letter, 8.0) * LS;",
            "ret.y = 1.0 - (floor(letter/8.0) * LS);",
            "return ret;",
            "}",
            "float interpolate(float edge0, float edge1, float value){",
            "return clamp((value - edge0) / (edge1 - edge0),0.0,1.0);",
            "}",
            "vec4 getLetterTexel(vec2 uv, float spaceX, float spaceY, float hwX, float hwY){",
            "vec4 col = vec4(color, opacity);",
            "float letterSize = spaceX/letterCount + 0.001;",
            "float letterIDX = floor((uv.x - hwX)/letterSize);",
            "float startX = letterIDX * letterSize;",
            "float endX = (letterIDX + 1.0) * letterSize;",
            "float startY = hwY;",
            "float endY = hwY + spaceY;",
            "float x = uv.x -hwX;",
            "float y = uv.y;",
            "float resultX = interpolate(startX, endX, x);",
            "float resultY = interpolate(startY, endY, y);",
            "vec2 letteruv = getLetterUV(0.0);",

            "if(letterIDX < 1.0 && letters[0] > 0.0){",
            "letteruv = getLetterUV(letters[0]);",
            "}",
            "if(letterIDX < 2.0 && letterIDX >= 1.0 && letters[1] > 0.0){",
            "letteruv = getLetterUV(letters[1]);",
            "}",
            "if(letterIDX < 3.0 && letterIDX >= 2.0 && letters[2] > 0.0){",
            "letteruv = getLetterUV(letters[2]);",
            "}",
            "if(letterIDX < 4.0 && letterIDX >= 3.0 && letters[3] > 0.0){",
            "letteruv = getLetterUV(letters[3]);",
            "}",
            "if(letterIDX < 5.0 && letterIDX >= 4.0 && letters[4] > 0.0){",
            "letteruv = getLetterUV(letters[4]);",
            "}",

            "vec2 resultUV = vec2(resultX, resultY);",
            "resultUV *= LS;",
            "resultUV += letteruv;",

            "vec4 texelCol = texture2D(letterMap, resultUV);",
            // "if(texelCol.a > 0.0){",
            //     "col = vec4(0,0,0,texelCol.a);",
            // "}",
            // "col.a *= texelCol.a;",
            "return vec4(col.rgb, texelCol.a);",
            // // "color.r *= resultY;",
            // "return col;",

            "}",
            "bool checkUVBounds(vec2 uv, float useSpaceX, float useSpaceY, float hwX, float hwY){",
            "if(uv.x <= hwX || uv.x >= (1.0 - hwX)) return false;",
            "if(uv.y <= hwY || uv.y >= (1.0 - hwY)) return false;",
            "return true;",
            "}",
            "void main(){",
            "vec4 useColor = vec4(1.0);",
            "float test = 1.0/ratio*letterCount;",
            "float useSpaceX = test > 1.0 ? 1.0 : test;",
            "float useSpaceY = test <= 1.0 ? 1.0 : ratio/letterCount;",
            "float hwX = (1.0 - useSpaceX)/2.0;",
            "float hwY = (1.0 - useSpaceY)/2.0;",
            "vec4 texelCol = checkUVBounds(vUv, useSpaceX, useSpaceY, hwX, hwY) ? getLetterTexel(vUv, useSpaceX, useSpaceY, hwX, hwY) : vec4(color,0.0);",
            "gl_FragColor = texelCol;",
            "}"
        ].join("\n");

        var letterFragmentShaderZ = [
            "const float LS = 1.0/8.0;",
            "varying vec2 vUv;",
            "uniform vec3 color;",
            "uniform float opacity;",
            "uniform sampler2D letterMap;",
            "uniform float letters[5];",
            "uniform float letterCount;",
            "uniform float ratio;",
            "vec2 getLetterUV(float letter){",
            "letter += 8.0;",
            "vec2 ret = vec2(1.0);",
            "ret.x = mod(letter, 8.0) * LS;",
            "ret.y = 1.0 - (floor(letter/8.0) * LS);",
            "return ret;",
            "}",
            "float interpolate(float edge0, float edge1, float value){",
            "return clamp((value - edge0) / (edge1 - edge0),0.0,1.0);",
            "}",
            "vec4 getLetterTexel(vec2 uv, float spaceX, float spaceY, float hwX, float hwY){",
            "vec4 col = vec4(color, opacity);",
            "float letterSize = spaceY/letterCount + 0.001;",
            "float letterIDY = floor((uv.y - hwY)/letterSize);",
            "float startY = letterIDY * letterSize;",
            "float endY = (letterIDY + 1.0) * letterSize;",
            "float startX = hwX;",
            "float endX = hwX + spaceX;",
            "float x = uv.x;",
            "float y = uv.y - hwY;",
            "float resultX = interpolate(startX, endX, x);",
            "float resultY = interpolate(startY, endY, y);",
            "vec2 letteruv = getLetterUV(0.0);",

            "if(letterIDY < 1.0 && letters[0] > 0.0){",
            "letteruv = getLetterUV(letters[0]);",
            "}",
            "if(letterIDY < 2.0 && letterIDY >= 1.0 && letters[1] > 0.0){",
            "letteruv = getLetterUV(letters[1]);",
            "}",
            "if(letterIDY < 3.0 && letterIDY >= 2.0 && letters[2] > 0.0){",
            "letteruv = getLetterUV(letters[2]);",
            "}",
            "if(letterIDY < 4.0 && letterIDY >= 3.0 && letters[3] > 0.0){",
            "letteruv = getLetterUV(letters[3]);",
            "}",
            "if(letterIDY < 5.0 && letterIDY >= 4.0 && letters[4] > 0.0){",
            "letteruv = getLetterUV(letters[4]);",
            "}",

            "vec2 resultUV = vec2(resultX, resultY);",
            "resultUV *= LS;",
            "resultUV += letteruv;",

            "vec4 texelCol = texture2D(letterMap, resultUV);",
            // "if(texelCol.a > 0.0){",
            //     "col = vec4(0,0,0,texelCol.a);",
            // "}",
            // "col.a *= texelCol.a;",
            "return vec4(col.rgb, texelCol.a);",
            // // "color.r *= resultY;",
            // "return col;",

            "}",
            "bool checkUVBounds(vec2 uv, float useSpaceX, float useSpaceY, float hwX, float hwY){",
            "if(uv.x <= hwX || uv.x >= (1.0 - hwX)) return false;",
            "if(uv.y <= hwY || uv.y >= (1.0 - hwY)) return false;",
            "return true;",
            "}",
            "void main(){",
            "vec4 useColor = vec4(1.0);",
            "float test = 1.0/ratio*letterCount;",
            "float useSpaceY = test > 1.0 ? 1.0 : test;",
            "float useSpaceX = test <= 1.0 ? 1.0 : ratio/letterCount;",
            "float hwX = (1.0 - useSpaceX)/2.0;",
            "float hwY = (1.0 - useSpaceY)/2.0;",
            "vec4 texelCol = checkUVBounds(vUv, useSpaceX, useSpaceY, hwX, hwY) ? getLetterTexel(vUv, useSpaceX, useSpaceY, hwX, hwY) : vec4(color,0.0);",
            "gl_FragColor = texelCol;",
            "}"
        ].join("\n");

        /**
         * @description Function to build material for letter planes (x-axis) (editor raised floor)
         * @param {THREE.Texture} tex letter texture
         * @returns {THREE.ShaderMaterial} returns created material
         */
        var buildLetterXShaderMaterial = function (tex) {
            return new THREE.ShaderMaterial({
                uniforms: {
                    color: {type: "c", value: new THREE.Color(0.0, 0.0, 0.0)},
                    opacity: {type: "f", value: 0.4},
                    letters: {type: "fv1", value: [0.0, 1.0, 2.0, 3.0, -1.0]},
                    letterMap: {type: "t", value: tex},
                    letterCount: {type: "f", value: 1},
                    ratio: {type: "f", value: 1.0}
                },
                vertexShader: letterVertexShaderX,
                fragmentShader: letterFragmentShaderX,
                transparent: true
            });
        };

        /**
         * @description Function to build material for letter planes (z-axis) (editor raised floor)
         * @param {THREE.Texture} tex letter texture
         * @returns {THREE.ShaderMaterial} returns created material
         */
        var buildLetterZShaderMaterial = function (tex) {
            return new THREE.ShaderMaterial({
                uniforms: {
                    color: {type: "c", value: new THREE.Color(0.0, 0.0, 0.0)},
                    opacity: {type: "f", value: 0.4},
                    letters: {type: "fv1", value: [0.0, 1.0, 2.0, 3.0, -1.0]},
                    letterMap: {type: "t", value: tex},
                    letterCount: {type: "f", value: 1},
                    ratio: {type: "f", value: 1.0}
                },
                vertexShader: letterVertexShaderZ,
                fragmentShader: letterFragmentShaderZ,
                transparent: true
            });
        };

        var heatmapVertexShader = [
            "varying vec2 vUv;",
            "void main(){",
            "vUv = vec2(uv.x, 1.0 - uv.y);",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
            "}"
        ].join("\n");

        var heatmapFragmentShader = [
            "#define SENSORCOUNT %replaceCount",
            "#define CONTAINCOUNT %replaceContainCountC",
            "#define DEBUG abc",
            "#define TEXSIZE %replaceTexSize",
            "varying vec2 vUv;",
            "uniform float opacity;",
            "uniform sampler2D gradient;",
            "uniform sampler2D ptex;",
            "uniform sampler2D vtex;",
            "uniform sampler2D testUV;",
            "%replaceCPoints",
            "%replaceCHeights",
            "uniform vec3 roomSize;",
            "uniform float planePos;",
            "uniform float minValue;",
            "uniform float maxValue;",
            "uniform float raisedFloorHeight;",
            "uniform float influence;",
            "vec2 getSensorUV(int sensorIndex){",
                "float sidx = float(sensorIndex);",
                "float ts = float(TEXSIZE);",
                "float xpos = mod(sidx, ts)/ts;",
                "float ypos = floor(sidx/ts)/ts;",
                "return vec2(xpos, ypos);",
            "}",
            "vec3 getPosFromTexture(int sensorIndex){",
                "vec2 sensorUV = getSensorUV(sensorIndex);",
                "vec3 pos = vec3(texture2D(ptex, sensorUV).r,texture2D(ptex, sensorUV).g,texture2D(ptex, sensorUV).b);",
                "return pos;",
            "}",
            "float getValueFromTexture(int sensorIndex){",
                "vec2 sensorUV = getSensorUV(sensorIndex);",
                "return texture2D(vtex, sensorUV).r;",
            "}",
            "vec3 getColorForValue(float value){",
                "if(value < 0.0) return vec3(.1,.1,.1);",
                "float x = (value - minValue) / (maxValue - minValue);",
                "return texture2D(gradient, vec2(x, 0.5)).rgb;",
            "}",
            "float sideOfLine(vec2 a, vec2 b, vec2 p){",
                "return sign(((b.x - a.x)*(p.y - a.y)) - ((b.y - a.y)*(p.x - a.x)));",
            "}",
            "int checkPixelInContainment(vec3 pixelPos){",
                "int containment = -1;",
                "if(pixelPos.y < raisedFloorHeight) return -1;",
                "for(int i = 0; i < CONTAINCOUNT; i++){",
                    "float a = sideOfLine(cpoints[(i*4)].xy,     cpoints[((i*4)+1)].xy, pixelPos.xz);",
                    "float b = sideOfLine(cpoints[((i*4)+1)].xy, cpoints[((i*4)+2)].xy, pixelPos.xz);",
                    "float c = sideOfLine(cpoints[((i*4)+2)].xy, cpoints[((i*4)+3)].xy, pixelPos.xz);",
                    "float d = sideOfLine(cpoints[((i*4)+3)].xy, cpoints[((i*4))].xy,   pixelPos.xz);",
                    "if(a >= 0.0 && b >= 0.0 && c >= 0.0 && d >= 0.0 && pixelPos.y <= cheights[i]) return i;",
                "}",
                "return containment;",
            "}",
            "float computePixelValue(vec3 pixelPos){" +
                "float sumID = 0.0;",
                "float sumWID = 0.0;",
                "int containment = checkPixelInContainment(pixelPos);",
                "if(containment >= 0){",
                    "%replaceFuncsContain",
                    // "return 0.0;",
                "}",
                "else {",
                    "if(SENSORCOUNT == 0) return -1.0;",
                    "for(int i = 0; i < SENSORCOUNT; i++){",
                        "if(pixelPos.y < raisedFloorHeight){",
                            "vec3 pos = getPosFromTexture(i);",
                            "float value = getValueFromTexture(i);",
                            "if(pos.y < raisedFloorHeight && value > -2000.0){",
                                "float d = distance(pixelPos, getPosFromTexture(i));",
                                "float iwp = pow(d, influence);",
                                "sumWID += (value * iwp);",
                                "sumID += iwp;",
                            "}",
                        "}",
                        "else{",
                            "vec3 pos = getPosFromTexture(i);",
                            "float value = getValueFromTexture(i);",
                            "if(pos.y >= raisedFloorHeight && value > -2000.0){",
                                "float d = distance(pixelPos, getPosFromTexture(i));",
                                "float iwp = pow(d, influence);",
                                "sumWID += (value * iwp);",
                                "sumID += iwp;",
                            "}",
                        "}",
                    "}",
                "}",
                "return sumWID/sumID;",
            "}",
            "void main(){",
                "vec3 pixelPos = vec3(vUv.x * roomSize.x, planePos*roomSize.y, vUv.y*roomSize.z);",
                "float pixVal = 0.0;",
                "pixVal = computePixelValue(pixelPos);",
                "gl_FragColor = vec4(getColorForValue(pixVal),opacity);",
            "}"
        ].join("\n");

        /**
         * @description Function to build shader uniforms for heatmap plane
         * @param {Room} room the room to build heatmap plane for
         * @param gradient gradient to use
         * @param {object} info object containing rooms bounding box sizes
         * @param {number} texSize texture size
         * @param {object} map heatmap object to use
         * @param {array} containments array of containment objects
         * @returns {object} returns uniform object to use in heatmap shader
         */
        var buildHeatmapShaderUniforms = function (room, gradient, info, texSize, map, containments) {
            var gradientTexture = new THREE.Texture(gradient[0]);
            gradientTexture.needsUpdate = true;
            if (room.hasRaisedFloor) room.raisedFloor = room.getRaisedFloor();

            var uniforms = {
                opacity: {type: "f", value: 0.5},
                gradient: {type: "t", value: gradientTexture},
                roomSize: {type: "v3", value: new THREE.Vector3(info.sizeX, room.size.y, info.sizeZ)},
                ptex: {type: "t", value: createSensorPositionTexture(room, info, texSize)},
                vtex: {type: "t", value: createValueTexture(room, map.physicalTypeId)},
                testUV: {type: "t", value: AssetService.getAssetData('texture', 'uv', 'diff')},
                // ctex:{type:"t", value: createContainmentTexture(room, containments)},
                cpoints: {type: "v2v", value: getContainmentPoints(room, containments)},
                cheights: {type: "fv1", value:getContainmentHeights(room, containments)},
                planePos: {type: "f", value: 0.5},
                minValue: {type: "f", value: gradient[1]},
                maxValue: {type: "f", value: gradient[2]},
                raisedFloorHeight: {type: "f", value: room.hasRaisedFloor ? room.raisedFloor.size.y : 0.0},
                influence: {type: "f", value: -2.5}
            };
            return uniforms;
        };

        /**
         * @description Function to create data texture for sensor positions
         * @param {Room} room the room object to use
         * @param {object} info info object (currently not used)
         * @param {number} texSize the size of the texture to create
         * @returns {THREE.DataTexture} returns created data texture
         */
        var createSensorPositionTexture = function (room, info, texSize) {
            var data = new Float32Array(texSize * texSize * 3);
            var i = 0, j = 0;
            for (i = 0; i < room.sensors.length; i++, j += 3) {
                var pos = NamedEntity.getRelativePosition(room.bbox.min, new THREE.Vector3(room.sensors[i].pos.x, room.sensors[i].pos.y, room.sensors[i].pos.z));
                data[j] = pos.x;
                data[j + 1] = pos.y;
                data[j + 2] = pos.z;
            }
            for (i = room.sensors.length; i < texSize * texSize; i++, j += 3) {
                data[j] = 0;
                data[j + 1] = 0;
                data[j + 2] = 0;
            }
            var dtex = new THREE.DataTexture(data, texSize, texSize, THREE.RGBFormat, THREE.FloatType, THREE.UVMapping);
            dtex.needsUpdate = true;
            return dtex;
        };

        /**
         * @description Function to build data texture for sensor values
         * @param {Room} room the room object to use (drivervalue values set)
         * @param {number} physType the physical type to use (temp, humi, pressure)
         * @returns {THREE.DataTexture} returns created data texture
         */
        var createValueTexture = function (room, physType) {
            var texSize = NumberService.getNextPowerOfTwo(Math.ceil(Math.sqrt(room.sensors.length > 0 ? room.sensors.length : 1)));
            var data = new Float32Array(texSize * texSize * 3);
            var i = 0, j = 0;
            for (i = 0; i < room.sensors.length; i++, j += 3) {
                var value = room.sensors[i].getValueForPhysType(physType);
                data[j] = value !== null ? value : -2000;
                data[j + 1] = 0;
                data[j + 2] = 0;
            }
            for (i = room.sensors.length; i < texSize * texSize; i++, j += 3) {
                data[j] = 0;
                data[j + 1] = 0;
                data[j + 2] = 0;
            }
            var vtex = new THREE.DataTexture(data, texSize, texSize, THREE.RGBFormat, THREE.FloatType, THREE.UVMapping);
            vtex.needsUpdate = true;
            return vtex;
        };

        /**
         * @description Function to get bottom corner points for provided containment objects
         * @param {Room} room the room to use
         * @param {Containment[]} containments array of containment objects
         * @returns {THREE.Vector2[]} returns array of 2-dimensional vector objects
         */
        var getContainmentPoints = function(room, containments){
            var points = [];
            if(containments.length === 0) return [new THREE.Vector2()];
            for(var i = 0; i < containments.length; i++){
                var ps = containments[i].obb.getBottomPlanePoints(false);
                for(var k = 0; k < 4; k++){
                    var p = NamedEntity.getRelativePosition(room.bbox.min, ps[k]);
                    points.push(new THREE.Vector2(p.x, p.z));
                }
            }
            return points;
        };

        /**
         * @description Function to get height values for provided containment objects
         * @param {Room} room the room to use
         * @param {Containment[]} containments array of containment objects
         * @returns {number[]} returns array of height values for provided containment objects
         */
        var getContainmentHeights = function(room, containments){
            var heights = [];
            if(containments.length === 0) return [1.0];
            var offheight = room.hasRaisedFloor ? room.getRaisedFloor().size.y : 0;
            for(var i = 0; i < containments.length; i++) heights.push(containments[i].size.y + offheight);
            return heights;
        };

        /**
         * @description Function to set oriented bounding box for provided containment objects
         * @param {Containment[]} containments array of containment objects to set oriented bounding box for
         */
        var setContainmentOBB = function(containments){
            for(var i = 0; i < containments.length; i++){
                var obb = new OBB(  new THREE.Vector3(containments[i].pos.x, containments[i].pos.y, containments[i].pos.z),
                    new THREE.Vector3(containments[i].size.x/2, containments[i].size.y/2, containments[i].size.z/2),
                    new THREE.Vector3(0,MathService.degToRad(containments[i].rot.y),0));
                containments[i].obb = obb;
            }
        };

        /**
         * @description Function to refresh the sensor assignment to containments
         * @param {Sensor[]} sensors array of sensor objects to refresh
         */

        var refreshSensorAssignment = function (sensors) {
            for(var i = 0; i < sensors.length; i++){
                if(Tools.isDefinedNotNull(sensors[i].containId)){
                    delete sensors[i].containId;
                }
            }
        };

        /**
         * @description Function to setup information which sensor is contained in which containment
         * @param {Sensor[]} sensors array of sensor objects to assign
         * @param {Containment[]} containments array of containment objects
         */
        var assignSensorsToContainment = function(sensors, containments){
            for(var i = 0; i < sensors.length; i++){
                for(var j = 0; j < containments.length; j++){
                    if(containments[j].obb.containsPoint(sensors[i].pos)) {
                        sensors[i].containId = containments[j].id;
                        break;
                    }
                }
            }
        };

        /**
         * @description Function to order provided sensor array according to assigned containments and create information object to use later on
         * @param {Sensor[]} sensors array of sensor objects
         * @param {Containment[]} containments array of containment objects
         * @returns {Array} returns array of information objects to use in subsequent functions
         */
        var orderSensors = function(sensors, containments){
            sensors.sort(function(a,b){
                if(a.containId === undefined && b.containId !== undefined) return -1;
                if(a.containId !== undefined && b.containId === undefined) return 1;
                if(a.containId === undefined && b.containId === undefined) {
                    return a.id - b.id;
                }
                if(a.containId !== undefined && b.containId !== undefined) {
                    if(a.containId === b.containId){
                        return a.id - b.id;
                    }
                    else{
                        return a.containId - b.containId;
                    }
                }
            });
            var info = [];
            var count = 0;
            var lastContainId = undefined;
            var i;
            for(i = 0; i < sensors.length; i++){
                if(sensors[i].containId != lastContainId) {
                    info.push(count);
                    lastContainId = sensors[i].containId;
                }
                count++;
            }
            info.push(count);
            if(info.length !== (containments.length + 1)){
                for(i = 0; i < containments.length; i++){
                    var sensorCount = sensors.filter(function(s){
                        return s.containId !== undefined && s.containId === containments[i].id;
                    }).length;
                    info[i+1] = info[i] + sensorCount;
                }
            }
            return info;
        };

        /**
         * @description Function to create data texture for containment objects (not used)
         * @param {Room} room the room to use
         * @param {Containment[]} containments array of containment objects
         * @returns {THREE.DataTexture} returns created data texture
         */
        var createContainmentTexture = function(room, containments){
            var texSize = NumberService.getNextPowerOfTwo(Math.ceil(Math.sqrt(containments.length > 0 ? containments.length : 1)));
            var data = new Float32Array(texSize * texSize * 5 * 2);
            var i, j = 0;
            for(i = 0; i < containments.length; i++, j+=(5*2)){
                var obb = new OBB(  new THREE.Vector3(containments[i].pos.x, containments[i].pos.y, containments[i].pos.z),
                                    new THREE.Vector3(containments[i].size.x/2, containments[i].size.y/2, containments[i].size.z/2),
                                    new THREE.Vector3(0,MathService.degToRad(containments[i].rot.y*-1),0));
                var points = obb.getBottomPlanePoints(false);
                for(var k = 0; k < 4; k++){
                    var p = NamedEntity.getRelativePosition(room.bbox.min, points[k]);
                    data[j+(k*2)] = p.x;
                    data[j+(k*2)+1] = p.z;
                }
                data[j+8] = containments[i].size.y;
            }
            console.log(data);
            for(i = containments.length; i < texSize * texSize; i++, j+= 10){
                data[j] = -1.0;
                data[j+1] = -1.0;
                data[j+2] = -1.0;
                data[j+3] = -1.0;
                data[j+4] = -1.0;
                data[j+5] = -1.0;
                data[j+6] = -1.0;
                data[j+7] = -1.0;
                data[j+8] = -1.0;
                data[j+9] = -1.0;
            }
            var ctex = new THREE.DataTexture(data, texSize, texSize, THREE.RGBFormat, THREE.FloatType, THREE.UVMapping);
            ctex.needsUpdate = true;
            return ctex;
        };

        /**
         * @description Function to create shader functions (due to limitations in WebGL 1.0/OpenGL ES version)
         * @param {Containment[]} containments array of containment objects
         * @param {object[]} sensorInfo information object to use
         * @returns {string} returns shader src specific to provided containment objects and sensor information
         */
        var getContainmentFuncs = function(containments, sensorInfo){
            var strFunc = [
                "if(containment == %replaceContainIndex){",
                    "for(int i = %replaceSensorStartIndex; i < %replaceSensorEndIndex; i++){",
                        "vec3 pos = getPosFromTexture(i);",
                        "float value = getValueFromTexture(i);",
                        "if(value >= -2000.0){",
                            "float d = distance(pixelPos, pos);",
                            "float iwp = pow(d, influence);",
                            "sumWID += (value * iwp);",
                            "sumID += iwp;",
                        "}",
                    "}",
                "}"
            ].join("\n");
            var retString = "";
            for(var i = 0; i < containments.length; i++){
                var cStr = strFunc.substring(0);
                cStr = cStr
                    .replace("%replaceContainIndex",i)
                    .replace("%replaceSensorStartIndex", sensorInfo[i])
                    .replace("%replaceSensorEndIndex", sensorInfo[i+1]);
                retString += cStr;
            }
            return retString;
        };

        /**
         * @description Function to create shader material for heatmap planes
         * @param {Room} room the room object to create shader material for
         * @param {object} gradient gradient canvas
         * @param {object} info information object
         * @param {number} physType the physical type to use
         * @returns {THREE.ShaderMaterial} returns created shader material
         */
        var buildHeatMapShaderMaterial = function (room, gradient, info, physType) {
            var texSize = NumberService.getNextPowerOfTwo(Math.ceil(Math.sqrt(room.sensors.length > 0 ? room.sensors.length : 2)));
            var containments = getContainments(room);
            setContainmentOBB(containments);
            refreshSensorAssignment(room.sensors);
            assignSensorsToContainment(room.sensors, containments);
            var sensorInfo = orderSensors(room.sensors, containments);
            var mat =  new THREE.ShaderMaterial({
                uniforms: buildHeatmapShaderUniforms(room, gradient, info, texSize, physType, containments),
                vertexShader: heatmapVertexShader,
                fragmentShader: heatmapFragmentShader
                    .replace("%replaceCount", sensorInfo[0])
                    .replace("%replaceTexSize", texSize)
                    .replace(/\%replaceContainCountC/g, containments.length)
                    .replace("%replaceContainCountP", containments.length*4)
                    .replace("%replaceFuncsContain", getContainmentFuncs(containments, sensorInfo))
                    .replace("%replaceCPoints", containments.length > 0 ? "uniform vec2 cpoints["+(containments.length * 4)+"];" : "uniform vec2 cpoints[1];")
                    .replace("%replaceCHeights", containments.length > 0 ? "uniform float cheights["+containments.length+"];" : "uniform float cheights[1];"),
                transparent: true,
                side: THREE.DoubleSide
            });
            return mat;
        };

        var getSensorInfoString = function(info){
            var str = "const int idxs["+info.length+"] = int["+info.length+"](";
            for(var i = 0; i < info.length; i++){
                str += info[i]+",";
            }
            str = str.substring(0, str.length-1);
            str += ");";
            return str;
        };

        /**
         * @description Function to retrieve containment objects from provided room object
         * @param {Room} room the room to retrieve containment objects from
         * @returns {Array} returns array of containment objects
         */
        var getContainments = function(room){
            var containments = [];
            for(var i = 0; i < room.roomObjs.length; i++){
                if(Containment.isContainment(room.roomObjs[i].type)) containments.push(room.roomObjs[i]);
            }
            containments.sort(function(a,b){return a.id - b.id;});
            return containments;
        };

        /**
         * @description Function to create custom shader material to debug UV mapping
         * @returns {THREE.ShaderMaterial} returns created shader material
         */
        var getUVDebugMaterial = function(){
            return new THREE.ShaderMaterial({
                vertexShader:[
                    'varying vec2 vUv;',
                    'void main() {',
                        'vUv = uv;',
                        'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
                    '}'
                ].join("\n"),
                fragmentShader:[
                    'varying vec2 vUv;',
                    'void main() {',
                    'gl_FragColor = vec4(1.0 * vUv.x, 1.0*vUv.y,0.0,1.0);',
                    '}'
                ].join("\n"),
                uniforms:{},
                wireframe:true
            });
        };

        return {
            buildMaskShaderMaterial: buildMaskShaderMaterial,
            buildMarkerShaderMaterial: buildMarkerShaderMaterial,
            buildLetterXShaderMaterial: buildLetterXShaderMaterial,
            buildLetterZShaderMaterial: buildLetterZShaderMaterial,
            buildHeatMapShaderMaterial: buildHeatMapShaderMaterial,
            createValueTexture: createValueTexture,
            getUVDebugMaterial:getUVDebugMaterial
        };

    });
})();
