(function () {
    'use strict';

    function stateConfiguration($stateProvider) {

        var isAdmin = ['$log', 'Principal', 'userInfo', function ($log, Principal, userInfo) {
            return Principal.isInRole('ROLE_ADMIN');
        }];

        var hasViewRight = ['$log', 'Principal', 'userInfo', function ($log, Principal, userInfo) {
            return Principal.isInRole("ROLE_LOCATION_VIEW")
        }];

        var hasEditRight = ['$log', 'Principal', 'userInfo', function ($log, Principal, userInfo) {
            return Principal.isInRole("ROLE_LOCATION_EDIT");
        }];

        var hasCreateRight = ['$log', 'Principal', 'userInfo', function ($log, Principal, userInfo) {
            return Principal.isInRole("ROLE_LOCATION_CREATE");
        }];

        var hasDeleteRight = ['$log', 'Principal', 'userInfo', function ($log, Principal, userInfo) {
            return Principal.isInRole("ROLE_LOCATION_DELETE");
        }];

        var userSettings = ['$log', 'Principal', function ($log, Principal) {
            return Principal.identity();
        }];

        $stateProvider
            .state('location.alarm', {
                parent: 'location.detail',
                abstract: false,
                url: '/alarm',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.alarms.title'
                },
                redirectTo: 'location.alarm.alarmHistory', // AlarmHistory als defaultTab obwohl CustomErrorRules der erste Tab ist, (Digitronic hat/will keine CustomErrorRules)
                templateUrl: 'scripts/app/location/tabs/alarm/alarm.html',
                controller: 'AlarmTabController',
                controllerAs: 'actrl',
                resolve: {
                    isAdmin: isAdmin,
                    hasViewRight: hasViewRight,
                    hasEditRight: hasEditRight,
                    hasCreateRight: hasCreateRight,
                    hasDeleteRight: hasDeleteRight
                }
            })
            .state('location.alarm.errorHistory', { // errorHistory ist eigentlich nicht mehr wirklich History sondern nur noch Aktive Locationbezogene Fehler
                url: '/errorHistory',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.alarms.errorHistory'
                },
                views: {
                    'errorHistory': {
                        templateUrl: 'scripts/app/location/tabs/alarm/errorhistory/errorHistoryTab.html',
                        controller: 'ErrorHistoryTabController',
                        controllerAs: 'ctrl'
                    }
                }
            })
            .state('location.alarm.alarmHistory', {  // AlarmHisotry sind nicht mehr aktive und quittierte Fehler
                url: '/alarmHistory',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.alarms.alarmHistory'
                },
                views: {
                    'alarmHistory': {
                        templateUrl: 'scripts/app/location/tabs/alarm/alarmhistory/alarmHistoryTab.html',
                        controller: 'AlarmHistoryTabController',
                        controllerAs: 'ctrl'
                    }
                }
            })
            .state('location.alarm.errorConfig', {  // ErrorConfig ist benutzerdefinierte Fehler
                abstract: true,                     // der state location.alarm.errorConfig wird nie direkt benutzt
                data: {                             // nur Basisstate für substates .list und .edit
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.alarms.{{$stateParams.subTab}}'
                },
                redirectTo: 'location.alarm.errorConfig.list',
                views: {
                    'alarmconfig': {
                        template: ''
                    }

                }
            })
            .state('location.alarm.errorConfig.list', { // list = Übersicht für alle locationbezogene custom errors mit optionen VIEW,EDIT,DELETE
                parent: 'location.alarm.errorConfig',
                url: '/errorconfig',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.alarms.alarmConfig'
                },
                views: {
                    'alarmconfigView': {
                        controller: 'errorConfigController',
                        controllerAs: 'bctrl',
                        templateUrl: 'scripts/app/location/tabs/alarm/errorconfig/errorconfig.html'
                    }
                },
                resolve: {
                    'errorConfigurations': function (ErrorConfig, $stateParams) {
                        return ErrorConfig.getErrorConfig($stateParams.id);
                    },
                    isAdmin: isAdmin,                       //Adminrechte usw für den Controller vorher laden
                    hasViewRight: hasViewRight,
                    hasEditRight: hasEditRight,
                    hasCreateRight: hasCreateRight,
                    hasDeleteRight: hasDeleteRight
                }
            })
            .state('location.alarm.errorConfig.edit', { // edit state fürs editieren eines bestehenden oder neuen CustomError
                parent: 'location.alarm.errorConfig',
                url: '/errorconfig/:editElement/:mode',
                params: {editElement: null},
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.alarms.alarmConfig'
                },
                views: {
                    'alarmconfigEdit': {
                        controller: 'errorConfigEditController',
                        controllerAs: 'cctrl',
                        templateUrl: 'scripts/app/location/tabs/alarm/errorconfig/errorconfig.edit.html'
                    }
                },
                resolve: {
                    security: ['$stateParams', '$state', '$q', 'userInfo', 'Location', function ($stateParams, $state, $q, userInfo) {
                        if ($stateParams.mode === 'edit') {
                            if (userInfo.roles.indexOf("ROLE_ADMIN") === -1 && userInfo.roles.indexOf("ROLE_LOCATION_EDIT") === -1) {
                                return $state.go('location.alarm.' + $stateParams.editElement, {
                                    id: $stateParams.id,
                                    mode: 'view'
                                }, {reload: true});
                            }
                        }
                    }],
                    'errorConfiguration': function (ErrorConfig, $stateParams) {
                        if ($stateParams.editElement === null) {
                            return null;
                        }
                        return ErrorConfig.getErrorConfigById($stateParams.editElement);
                    },
                    'deviceOptions': function (Location, $stateParams) {
                        return Location.getDevices($stateParams.id).then(function (response) {
                            return response.data;
                        });
                    },
                    'errorChildOptions': function (ErrorConfig, $stateParams, errorConfiguration) {
                        if (errorConfiguration == null) {
                            return [];
                        }
                        return ErrorConfig.getErrorChildOptions($stateParams.id, errorConfiguration);
                    },
                    'errorConfigOptions': function (ErrorConfig, $stateParams, errorConfiguration) {
                        if (errorConfiguration == null) {
                            return [];
                        }
                        return ErrorConfig.getErrorConfigOptions(errorConfiguration.deviceMac)
                    },
                    'protocols': function (ProtocolService) {
                        return ProtocolService.getProtocols().then(function (response) {
                            return response.data;
                        });
                    },
                    'conditionalOperators': function (ConditionalOperatorService) {
                        return ConditionalOperatorService.getConditionalOperators().then(function (response) {
                            return response.data;
                        });
                    },
                    'errorConditionPeriodTypes': function(ErrorConfig){
                        return ErrorConfig.getErrorConditionPeriodTypes().then(function (response) {
                            return response;
                        });
                    },
                    userSettings: userSettings,    //Adminrechte usw für den Controller vorher laden
                    isAdmin: isAdmin,
                    hasViewRight: hasViewRight,
                    hasEditRight: hasEditRight,
                    hasCreateRight: hasCreateRight,
                    hasDeleteRight: hasDeleteRight
                }
            })

            .state('location.alarm.escalationlevel', { // BasisState für die Eskalationsstufen
                abstract: true,                        // der state location.alarm.escalationlevel wird nie direkt benutzt
                data: {                                // nur Basisstate für substates .list und .edit
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.alarms.escalationManagement'
                },
                views: {
                    'escalationlevel': {
                        template: ''
                    }
                },
                resolve: {
                    security: ['$stateParams', '$state', '$q', 'userInfo', 'Location', function ($stateParams, $state, $q, userInfo) {
                        if ($stateParams.mode === 'edit') {
                            if (userInfo.roles.indexOf("ROLE_ADMIN") === -1 && userInfo.roles.indexOf("ROLE_LOCATION_EDIT") === -1) {
                                return $state.go('location.alarm.' + 1, {
                                    id: $stateParams.id,
                                    mode: 'view'
                                }, {reload: true});
                            }
                        }
                    }]
                }
            })
            .state('location.alarm.escalationlevel.list', {         // State für Übersicht der locationbezogenen Eskalationsstufen
                parent: 'location.alarm.escalationlevel',
                url: '/escalationlevel',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.alarms.escalationManagement'
                },
                views: {
                    'escalationlevelView': {
                        controller: 'escalationLevelController',
                        controllerAs: 'ectrl',
                        templateUrl: 'scripts/app/location/tabs/alarm/escalationlevel/escalationlevel.html'
                    }
                },
                resolve: {
                    'escalationLevels': function (EscalationLevelService, $stateParams) {
                        return EscalationLevelService.getEscalationLevels($stateParams.id);
                    },
                    isAdmin: isAdmin,                           //Adminrechte usw für den Controller vorher laden
                    hasViewRight: hasViewRight,
                    hasEditRight: hasEditRight,
                    hasCreateRight: hasCreateRight,
                    hasDeleteRight: hasDeleteRight
                }
            })
            .state('location.alarm.escalationlevel.edit', {     // Edit state fürs editieren einer bestehenden oder neuen Eskalationsstufe
                parent: 'location.alarm.escalationlevel',
                params: {editElement: null},
                url: '/escalationlevel/:editElement/:mode',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.tab.alarms.escalationManagement'
                },
                views: {
                    'escalationlevelEdit': {
                        controller: 'escalationLevelEditController',
                        controllerAs: 'fctrl',
                        templateUrl: 'scripts/app/location/tabs/alarm/escalationlevel/escalationlevel.edit.html'
                    }
                },
                resolve: {
                    'escalationLevel': function (EscalationLevelService, $stateParams) {
                        if ($stateParams.editElement == null) {
                            return null;
                        }
                        return EscalationLevelService.getEscalationLevelById($stateParams.editElement);
                    },
                    isAdmin: isAdmin,                   //Adminrechte usw für den Controller vorher laden
                    hasViewRight: hasViewRight,
                    hasEditRight: hasEditRight,
                    hasCreateRight: hasCreateRight,
                    hasDeleteRight: hasDeleteRight
                }
            })
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
