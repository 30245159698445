(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('sideNavigation', ['$timeout', 'sidebarToggler','MessageService', function ($timeout, sidebarToggler, MessageService) {

        function link(scope, element, attrs, controller) {
            // // Call the metsiMenu plugin and plug it to sidebar navigation
            element.metisMenu();
            // Schließe das sidebar Menü auf Klick,
            // falls der Bildschirm nicht breit genug ist
            $timeout(function () {
                element.find('a').click(function () {
                    var isCategory = $(this).attr('ui-sref') === undefined;
                    if (!isCategory) {
                        var width = $(window).width();
                        if (width < 770) {
                            sidebarToggler.close(MessageService);
                        }
                    }
                });
            });
        }

        return {
            restrict: 'A',
            link: link
        };
    }]);
})();
