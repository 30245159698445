(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Timezones
     * @requires $http
     */
    angular.module('emsv2App').service('Timezones', ['$http', function ($http) {
        // Cached response
        var list = undefined;

        function _getAll() {
            if (list === undefined) {
                list = $http.get('api/timezones/all');
            }
            return list;
        }

        return {
            getAll: _getAll
        };
    }]);

})();
