(function () {
    'use strict';

    /**
     * @description stateful service to handle drag/drop objects better
     */
    angular.module('emsv2App').service("DragDropService", function () {

        // current drag/drop object
        var object = null;

        /**
         * @description function returns current drag/drop object
         * @returns {*} returns current drag/drop object
         */
        var getDragObject = function () {
            return object;
        };

        /**
         * @description function to set current drag/drop object
         * @param {object} obj the object to be set
         */
        var setDragObject = function (obj) {
            object = obj;
        };

        return {
            getDragObject: getDragObject,
            setDragObject: setDragObject
        };
    });

})();
