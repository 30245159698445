'use strict';
//global unique id counter to prevent double usage
var currentLocaleUniqueId = 0;

/**
 *  DO NOT CHANGE FILE NAME. REMOVING NUMBER FROM FILE NAME WILL RESULT IN FAULTY GULP BUILD
 */

/**
 * @description Constructor to create entity object (inheritance for actually used object)
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing the position
 * @param {object} size 3-dimensional vector object describing the objects size
 * @param {object} rot 3-dimensional vector object describing the objects rotation
 * @param {number} uid the unique id to set
 * @constructor
 */
function Entity(id, pos, size, rot, uid) {
    this.id = id !== undefined ? id : -1;
    this.pos = pos !== undefined ? pos : {x: 0, y: 0, z: 0};
    this.size = size !== undefined ? size : {x: 0, y: 0, z: 0};
    this.rot = rot !== undefined ? rot : {x: 0, y: 0, z: 0};
    this.uniqueId = uid !== undefined ? uid : Entity.getNewLocaleUniqueId();
}

Entity.prototype = {};

/**
 * @description Function to test equality of this object and the provided object
 * @param {object} ce the object to compare this object with
 * @returns {boolean} returns true if objects are equal, otherwise false
 */
Entity.prototype.equalsEntity = function (ce) {
    if (!(ce instanceof Entity)) return false;
    if (this.id !== ce.id) return false;
    if (this.uniqueId !== ce.uniqueId) return false;
    if (this.size.x !== ce.size.x || this.size.y !== ce.size.y || this.size.z !== ce.size.z) return false;
    if (this.rot.x !== ce.rot.x || this.rot.y !== ce.rot.y || this.rot.z !== ce.rot.z) return false;
    if (this.pos.x !== ce.pos.x || this.pos.y !== ce.pos.y || this.pos.z !== ce.pos.z) return false;
    return true;
};

/**
 * @description Function to retrieve new id for a object based on provided array
 * @param {array} array the array to create a new unique id for
 * @returns {number}
 */
Entity.getNewIdFromArray = function (array) {
    if (array.length === 0) {
        return -1;
    }
    else {
        return ((array.length + 1) * -1);
    }
};

/**
 * @description Function to get a new local(client) unique id
 * @returns {number} returns a new unique id
 */
Entity.getNewLocaleUniqueId = function () {
    return --currentLocaleUniqueId;
};

/**
 * @description Function to create new driver value objects from json data object
 * @param {array} dvs array of json data driver values
 * @param {object} obj the object to add the created driver values to
 */
Entity.parseDriverValueFromHtmlObject = function (dvs, obj) {
    if (!(obj instanceof Entity)) return;
    if (obj.driverValues === undefined || obj.driverValues === null) obj.driverValues = [];
    for (var i = 0; i < dvs.length; i++) {
        obj.driverValues.push(DriverValue.parseFromHtmlObject(dvs[i]));
    }
};

/**
 * @description Function to create new driver value objects from json data object
 * @param {array} dvs array of json data driver values
 * @param {object} obj the object to add the created driver values to
 */
Entity.parseDriverValueFromSimpleObject = function (dvs, obj) {
    if (!(obj instanceof Entity)) return;
    if (obj.driverValues === undefined || obj.driverValues === null) obj.driverValues = [];
    for (var i = 0; i < dvs.length; i++) {
        var dv = DriverValue.parseFromSimpleObject(dvs[i]);
        if (dv !== undefined) obj.driverValues.push(dv);
    }
};

/**
 * @description Function to modify pos, size and rot properties to work with backend
 */
Entity.prototype.parseSizeRotPosForBackend = function () {
    this.rotX = this.rot.x;
    this.rotY = this.rot.y;
    this.rotZ = this.rot.z;

    this.posX = this.pos.x;
    this.posY = this.pos.y;
    this.posZ = this.pos.z;

    this.scaleX = this.size.x;
    this.scaleY = this.size.y;
    this.scaleZ = this.size.z;
};

/**
 * @description Function to reset negative ids because backend can not handle this ...
 */
Entity.prototype.resetIds = function () {
    if (this.id < 0) this.id = null;
    if (this.uniqueId < 0) this.uniqueId = null;
    if (this.hasOwnProperty("driverValues")) {
        for (var i = 0; i < this.driverValues.length; i++) {
            if (this.driverValues[i].id < 0) this.driverValues[i].id = null;
            if (this.driverValues[i].uniqueId < 0) this.driverValues[i].uniqueId = null;
            if (this.driverValues[i].driver === null) continue;
            if (this.driverValues[i].driver.id < 0) this.driverValues[i].driver.id = null;
            if (this.driverValues[i].driver.uniqueId < 0) this.driverValues[i].driver.uniqueId = null;
        }
    }
};

/**
 * @description Function to modify driver value limit objects to work with backend
 * @deprecated
 * this is extremely error prone. Has been moved to backend since alterations of the value are needed only of limits get pushed to a device
 */
Entity.prototype.modifyDriverValueLimitsForBackend = function () {
    // if (!this.driverValues) return;
    // for (var i = 0; i < this.driverValues.length; i++) {
    //     for (var j = 0; j < this.driverValues[i].limits.length; j++) {
    //         if (this.driverValues[i].limits[j].uniqueId <= 0) this.driverValues[i].limits[j].uniqueId = null;
    //         if (this.driverValues[i].physicalType !== 6 && this.driverValues[i].physicalType !== 0) this.driverValues[i].limits[j].value = Math.round(this.driverValues[i].limits[j].value * 1000);
    //     }
    // }
};

/**
 * @description Function to modify driver value limit objects provided by backend
 * @deprecated
 * this is extremely error prone. Has been moved to backend since alterations of the value are needed only of limits get pushed to a device
 */
Entity.prototype.modifyDriverValueLimitsForFrontend = function () {
    // if (!this.driverValues) return;
    // for (var i = 0; i < this.driverValues.length; i++) {
    //     for (var j = 0; j < this.driverValues[i].limits.length; j++) {
    //         if (this.driverValues[i].physicalType !== 6 && this.driverValues[i].physicalType !== 0) this.driverValues[i].limits[j].value = this.driverValues[i].limits[j].value / 1000;
    //     }
    // }
};

/**
 * @description Function to compare this objects driver values with the provided list of driver values
 * @param {DriverValue[]} cdvs array of driver values to compare with this objects driver value array
 * @returns {boolean}
 */
Entity.prototype.compareDriverValues = function (cdvs) {
    if (this.driverValues.length !== cdvs.length) return false;
    for (var i = 0; i < this.driverValues.length; i++) {
        var tdv = this.driverValues[i];
        var cdv = cdvs[i];

        if (tdv.limits.length !== cdv.limits.length) return false;
        for (var j = 0; j < cdv.limits.length; j++) {
            if (tdv.limits[j].name !== cdv.limits[j].name) return false;
            if (tdv.limits[j].value !== cdv.limits[j].value) return false;
            if (tdv.limits[j].type !== cdv.limits[j].type) return false;
            if (tdv.limits[j].priority !== cdv.limits[j].priority) return false;
            if (tdv.limits[j].delay !== cdv.limits[j].delay) return false;
        }

        if (tdv.physicalType !== cdv.physicalType) return false;
        if (tdv.driver == null && cdv.driver != null && cdv.driver.driverType !== 0 || tdv.driver !== null && tdv.driver.driverType !== 0 && cdv.driver === null) return false;
        if (tdv.driver && cdv.driver && tdv.driver.driverType !== cdv.driver.driverType) return false;
        if (tdv.useHover !== cdv.useHover) return false;
        if (tdv.driver === null) continue;
        switch (tdv.driver.driverType) {
            case 1:
                if (tdv.driver.d0Address !== cdv.driver.d0Address) return false;
                break;
            case 2:
                if (tdv.driver.dbusAddress !== cdv.driver.dbusAddress) return false;
                if (tdv.driver.dbusIsSerial !== cdv.driver.dbusIsSerial) return false;
                break;
            case 3:
                if (tdv.driver.mbusAddress !== cdv.driver.mbusAddress) return false;
                if (tdv.driver.mbusIsSerial !== cdv.driver.mbusIsSerial) return false;
                break;
            case 4:
                if (tdv.driver.baudRate !== cdv.driver.baudRate) return false;
                if (tdv.driver.functionCode !== cdv.driver.functionCode) return false;
                if (tdv.driver.parity !== cdv.driver.parity) return false;
                if (tdv.driver.registerReadCount !== cdv.driver.registerReadCount) return false;
                if (tdv.driver.registerValueType !== cdv.driver.registerValueType) return false;
                if (tdv.driver.registerValueSign !== cdv.driver.registerValueSign) return false;
                if (tdv.driver.rtuRefreshTime !== cdv.driver.rtuRefreshTime) return false;
                if (tdv.driver.rtuRegisterAddress !== cdv.driver.rtuRegisterAddress) return false;
                if (tdv.driver.slaveAddress !== cdv.driver.slaveAddress) return false;
                if (tdv.driver.stopBits !== cdv.driver.stopBits) return false;
                break;
            case 5:
                if (tdv.driver.functionCode !== cdv.driver.functionCode) return false;
                if (tdv.driver.registerReadCount !== cdv.driver.registerReadCount) return false;
                if (tdv.driver.registerValueType !== cdv.driver.registerValueType) return false;
                if (tdv.driver.registerValueSign !== cdv.driver.registerValueSign) return false;
                if (tdv.driver.tcpDomain !== cdv.driver.tcpDomain) return false;
                if (tdv.driver.tcpPort !== cdv.driver.tcpPort) return false;
                if (tdv.driver.tcpRefreshTime !== cdv.driver.tcpRefreshTime) return false;
                if (tdv.driver.tcpRegisterAddress !== cdv.driver.tcpRegisterAddress) return false;
                break;
            case 6:
                if (tdv.driver.identifier !== cdv.driver.identifier) return false;
                break;
            case 7:
                if (tdv.driver.impulse !== cdv.driver.impulse) return false;
                if (tdv.driver.maxConsumption !== cdv.driver.maxConsumption) return false;
                if (tdv.driver.s0Channel !== cdv.driver.s0Channel) return false;
                if (tdv.driver.s0Slot !== cdv.driver.s0Slot) return false;
                break;
            case 8:
                if (tdv.driver.community !== cdv.driver.community) return false;
                if (tdv.driver.inMin !== cdv.driver.inMin) return false;
                if (tdv.driver.inMax !== cdv.driver.inMax) return false;
                if (tdv.driver.outMin !== cdv.driver.outMin) return false;
                if (tdv.driver.outMax !== cdv.driver.outMax) return false;
                if (tdv.driver.oid !== cdv.driver.oid) return false;
                if (tdv.driver.snmpDomain !== cdv.driver.snmpDomain) return false;
                if (tdv.driver.snmpPort !== cdv.driver.snmpPort) return false;
                if (tdv.driver.snmpRefreshTime !== cdv.driver.snmpRefreshTime) return false;
                if (tdv.driver.tolerance !== cdv.driver.tolerance) return false;
                break;
            case 9:
                if (tdv.driver.webbusChannel !== cdv.driver.webbusChannel) return false;
                if (tdv.driver.webbusChannelType !== cdv.driver.webbusChannelType) return false;
                if (tdv.driver.webbusSlot !== cdv.driver.webbusSlot) return false;
                break;
            case 10:
                if (tdv.driver.bus !== cdv.driver.bus) return false;
                if (tdv.driver.globalAddress !== cdv.driver.globalAddress) return false;
                if (tdv.driver.unit !== cdv.driver.unit) return false;
                if (tdv.driver.wib8000Domain !== cdv.driver.wib8000Domain) return false;
                if (tdv.driver.wib8000Port !== cdv.driver.wib8000Port) return false;
                break;
            case 11:
                if (tdv.driver.gatewayId !== cdv.driver.gatewayId) return false;
                if (tdv.driver.gatewaySensorId !== cdv.driver.gatewaySensorId) return false;
                break;

        }

    }
    return true;
};

/**
 * @description Function validates this object
 * @returns {ErrorObject[]} returns array of ErrorObjects, describing the validation errors
 */
Entity.prototype.validateEntity = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateIds());
    errorList = errorList.concat(this.validatePosition());
    errorList = errorList.concat(this.validateRotation());
    errorList = errorList.concat(this.validateSize());
    return errorList;
};

/**
 * @description Function to validate this objects id properties (id, unique id)
 * @returns {Array} returns array of error objects, describing validation errors
 */
Entity.prototype.validateIds = function () {
    var errorList = [];
    if (this.id === undefined || this.id === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "id"));
    if (this.uniqueId === undefined || this.uniqueId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "uniqueId"));
    return errorList;
};

/**
 * @description Function to validate this objects position property
 * @returns {Array} returns array of error objects, describing validation errors
 */
Entity.prototype.validatePosition = function () {
    var errorList = [];
    if (this.pos === undefined || this.pos === null) {
        errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "pos"));
    }
    else {
        if (this.pos.x === undefined || this.pos.x === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "pos.x"));
        if (this.pos.y === undefined || this.pos.y === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "pos.y"));
        if (this.pos.z === undefined || this.pos.z === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "pos.z"));
    }
    return errorList;
};

/**
 * @description Function to validate this objects rotation property
 * @returns {Array} returns array of error objects, describing validation errors
 */
Entity.prototype.validateRotation = function () {
    var errorList = [];
    if (this.rot === undefined || this.rot === null) {
        errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "rot"));
    }
    else {
        if (this.rot.x === undefined || this.rot.x === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "rot.x"));
        if (this.rot.y === undefined || this.rot.y === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "rot.y"));
        if (this.rot.z === undefined || this.rot.z === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "rot.z"));
    }
    return errorList;
};

/**
 * @description Function to validate this objects size property
 * @returns {Array} returns array of error objects, describing validation errors
 */
Entity.prototype.validateSize = function () {
    var errorList = [];
    if (this.size === undefined || this.size === null) {
        errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size"));
    }
    else {
        if (this.size.x === undefined || this.size.x === null || this.size.x <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size.x"));
        if (this.size.y === undefined || this.size.y === null || this.size.y <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size.y"));
        if (this.size.z === undefined || this.size.z === null || this.size.z <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size.z"));
    }
    return errorList;
};
