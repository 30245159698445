'use strict';

/**
 * @description Constructor to create blade object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {string} url the url to set
 * @param {string} inventoryNumber the inventory number to set
 * @param {string} serialNumber the serial number to set
 * @param {number} type the type to set
 * @param {array} cpus array of cpu objects to set
 * @param {number} partLibraryBladeId the part library id of the part used to create this object
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set (slot id)
 * @constructor
 */
function Blade(id, pos, size, rot, name, comment, url, inventoryNumber, serialNumber, type, cpus, partLibraryBladeId, uid, pid) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.url = url !== undefined ? url : "";
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.cpus = (cpus !== undefined && cpus instanceof Array) ? cpus : [];
    this.partLibraryBladeId = partLibraryBladeId !== undefined ? partLibraryBladeId : null;
    this.slotId = pid !== undefined ? pid : null;
    this.allowDataPoints = false;
    this.driverValues = [];
    this.isBlade = true;
}

Blade.prototype = Object.create(NamedEntity.prototype);
Blade.prototype.constructor = Blade;

/**
 * @description Function to test equality between this object and the provided object
 * @param {object} cb the object to use for equality test
 * @returns {boolean} returns true if this object equals the provided object, otherwise false
 */
Blade.prototype.equals = function (cb) {
    if (!(cb instanceof Blade)) return false;
    if (!this.equalsNamedEntity(cb)) return false;
    if (this.slotId !== cb.slotId) return false;
    if (this.inventoryNumber !== cb.inventoryNumber) return false;
    if (this.serialNumber !== cb.serialNumber) return false;
    // if(this.objectMac != cb.objectMac) return false;
    if (this.url !== cb.url) return false;
    if (this.partLibraryBladeId !== cb.partLibraryBladeId) return false;
    if (this.driverValues.length !== cb.driverValues.length) return false;
    //TODO compare driverValues
    if (this.cpus.length !== cb.cpus.length) return false;
    for (var i = 0; i < this.cpus.length; i++) {
        var cpu = this.cpus[i];
        var cc = cb.cpus.filter(function (c) {return c.id === cpu.id;})[0];
        if (!cc) return false;
        if (!cpu.equals(cc)) return false;
    }
    return true;
};

/**
 * @description Function to reset all ids of this object and its child objects (negative ids will be set to null)
 */
Blade.prototype.resetIds = function () {
    if (this.id < 0) this.id = null;
    if (this.uniqueId < 0) this.uniqueId = null;
    var i;
    if (this.hasOwnProperty("driverValues")) {
        for (i = 0; i < this.driverValues.length; i++) {
            if (this.driverValues[i].id < 0) this.driverValues[i].id = null;
            if (this.driverValues[i].uniqueId < 0) this.driverValues[i].uniqueId = null;
            if (this.driverValues[i].driver === null) continue;
            if (this.driverValues[i].driver.id < 0) this.driverValues[i].driver.id = null;
            if (this.driverValues[i].driver.uniqueId < 0) this.driverValues[i].driver.uniqueId = null;
        }
    }
    for (i = 0; i < this.cpus.length; i++) this.cpus[i].resetIds();
};

/**
 * @description Function to modify this object to work when sent to backend
 */
Blade.prototype.parseSizeRotPosForBackend = function () {
    this.rotX = this.rot.x;
    this.rotY = this.rot.y;
    this.rotZ = this.rot.z;

    this.posX = this.pos.x;
    this.posY = this.pos.y;
    this.posZ = this.pos.z;

    this.scaleX = this.size.x;
    this.scaleY = this.size.y;
    this.scaleZ = this.size.z;
    for (var i = 0; i < this.cpus.length; i++) this.cpus[i].parseSizeRotPosForBackend();
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
Blade.prototype.validate = function () {
    var i, errorList = [];
    // errorList = errorList.concat(this.validateNamedEntity());
    errorList = errorList.concat(this.validateIds());
    errorList = errorList.concat(this.validateNameComment());
    if (this.slotId === undefined || this.slotId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "slotId"));
    if (this.driverValues !== undefined && this.driverValues instanceof Array) {
        for (i = 0; i < this.driverValues.length; i++) errorList = errorList.concat(this.driverValues[i].validate());
    }
    for (i = 0; i < this.cpus.length; i++) errorList = errorList.concat(this.cpus[i].validate());

    return errorList;
};

Blade.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    var i, objs;
    for (i = 0; i < this.driverValues.length; i++) {
        objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    for (i = 0; i < this.cpus.length; i++) {
        objs = this.cpus[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    return [];
};

/**
 * @description Function to create blade object from json data from backend
 * @param {object} obj the json data object from backend
 * @param {number} pid the parent id to set (slot id)
 * @returns {Blade} returns the created blade object
 */
Blade.parseFromHtmlObject = function (obj, pid) {
    var blade = new Blade(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.url, obj.inventoryNumber, obj.serialNumber, obj.type, undefined, obj.partLibraryBladeId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, blade);
    blade.modifyDriverValueLimitsForFrontend();
    return blade;
};

/**
 * @description Function to create a blade object from javascript object
 * @param {object} obj the javascript object to use
 * @param {number} pid the parent objects id (slot id)
 * @returns {Blade} returns the created blade object
 */
Blade.parseFromSimpleObject = function (obj, pid) {
    var blade = new Blade(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.url, obj.inventoryNumber, obj.serialNumber, obj.type, undefined, obj.partLibraryBladeId, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, blade);
    return blade;
};
