(function () {
    'use strict';

    angular
        .module('emsv2App')
        .directive('file', function () {
            return {
                scope: {
                    file: '=',
                    fileChanged: '='
                },
                link: function (scope, el) {
                    el.bind('change', function (event) {
                        if (event.target.files[0]) {
                            scope.file = event.target.files[0];
                        }
                        if (!!scope.fileChanged) {
                            scope.fileChanged(event.target.files[0]);
                        }
                        scope.$apply();
                    });
                }
            };
        });

})();
