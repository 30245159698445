(function () {
    'use strict';

    function ReportsGenerateController($scope, $state, $window, $stateParams, $log, $translate, $timeout, escapeHtml, Report,
                                       Tools, WidgetData, Notify, DateConstants, user, groups, isAdmin, Contacts,
                                       schedulerEnabled, reportFormats, paperFormats, reportData, GenDialogService, License, $http, $q) {

        var ctrl = this;
        var initReport = null;
        var reportSaved = false;
        var isCreateable = true;
        var isEdit = Tools.isDefinedNotNull($stateParams.id) && $stateParams.id > -1;
        ctrl.contacts = [];
        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.tmp = {
            user: user,
            groups: groups,
            isAdmin: isAdmin,
            savefor: null,
            selectedGroup: {}
        };
        ctrl.report = {
            id: null,
            title: null,
            creator: null,
            userId: null,
            groupId: null,
            formatOutput: 'pdf',
            formatSize: 'A4',
            formatPagination: false,
            formatOrientation: 0,
            pages: [],
            schedulers: []
        };
        ctrl.dashboard = {
            options: {
                full: {
                    cellHeight: 100,
                    cellWidth: 200,
                    width: 10,
                    height: 8,
                    verticalMargin: 15,
                    float: true,
                    animate: false,
                    disableResize: false,
                    disableDrag: false,
                    draggable: {
                        handle: '.ibox-title'
                    }
                    // staticGrid: false
                    // acceptWidgets: '.grid-stack-item'
                },
                half: {
                    cellHeight: 100,
                    cellWidth: 200,
                    width: 6,
                    height: 12,
                    verticalMargin: 15,
                    float: true,
                    animate: false,
                    disableResize: false,
                    disableDrag: false,
                    draggable: {
                        handle: '.ibox-title'
                    }
                    // staticGrid: false
                    // acceptWidgets: '.grid-stack-item'
                }
            },
            widgets: [],
            addWidget: _addWidget,
            removePage: _removePage,
            addPage: _addPage
        };
        ctrl.forms = {};
        ctrl.options = {
            currentWidgetId: 0,
            getNextWidgetId: function () {
                return --ctrl.options.currentWidgetId;
            },
            saveBtn: 'init',
            splitValues: ['days', 'weeks', 'months', 'years'],
            availableReportFormats: reportFormats,
            paperFormats: paperFormats,
            orientation: [
                {
                    id: 0,
                    label: 'reports.settings.orientationPortrait'
                },
                {
                    id: 1,
                    label: 'reports.settings.orientationLandscape'
                }
            ],
            scheduler: {
                none: -1,
                unique: 0,
                repetitive: 1
            },
            schedulerEnabled: schedulerEnabled,
            pageSelected: null,
            repetitions: {
                daily: {id: 0, label: 'reports.scheduler.repitition.daily'},
                weekly: {id: 1, label: 'reports.scheduler.repitition.weekly'},
                monthly: {id: 2, label: 'reports.scheduler.repitition.monthly'},
                anually: {id: 3, label: 'reports.scheduler.repitition.annualy'}
            },
            endType: {
                never: 0,
                iteration: 1,
                date: 2
            },
            schedulerDateRange: {
                singleDatePicker: true,
                locale: {
                    format: $translate.instant('global.dateFormat'),
                    daysOfWeek: DateConstants.getDaysOfWeek(),
                    monthNames: DateConstants.getMonthNames()
                },
                minDate: moment(),
                maxDate: moment().add(1, 'years'),
                showDropdowns: true
            }
        };
        var schedulerTemplate = {
            id: null,
            mode: 0,   // delete before sending
            endType: 0, // delete before sending
            starts: moment(),
            repeat: 0,
            repeatValue: 1,
            receiver: '',
            endsAfter: null,
            endsOn: moment()
        };

        $scope.$on('closeWidget', function (event, args) {
            for (var j = 0; j < ctrl.report.pages.length; j++) {
                for (var i = 0; i < ctrl.report.pages[j].widgets.length; i++) {
                    if (ctrl.report.pages[j].widgets[i].wid === args.widget.wid) {
                        ctrl.report.pages[j].widgets.splice(i, 1);
                    }
                }
            }
        });

        ctrl.$onInit = function () {
            Contacts.getAll().then(function (response) {
                ctrl.contacts = response.data;
            });
            WidgetData.getAvailableWidgets().then(function (result) {
                for (var i = 0; i < result.length; i++) {
                    if (result[i].hasLicense === true) {
                        ctrl.dashboard.widgets.push(result[i]);
                    }
                }
            }, function (error) {
                $log.error('Couldn\'t load widgets!', error);
            });

            if (isEdit) {
                _loadReport();
            }
            initReport = angular.copy(ctrl.report);
            ctrl.LicenseExpired = false;

            License.getExpired().then(function (response) {
                ctrl.LicenseExpired = response.data;
            });
        };

        ctrl.compareListLength = function (reportA, reportB) {
            var aProps = Object.getOwnPropertyNames(reportA);
            var bProps = Object.getOwnPropertyNames(reportB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            return false;
        };

        ctrl.compareWidgetTypes = function (widgetsA, widgetsB) {
            for (var i = 0; i < widgetsB.length; i++) {

                if (widgetsA[i].type !== widgetsB[i].type) {
                    return true;
                }
            }
        };

        ctrl.comparePageContent = function (pageA, pageB) {

            for (var i = 0; i < pageB.length; i++) {
                if (pageB[i].widgets.length !== pageA[i].widgets.length) {
                    return true;
                }
                if (ctrl.compareWidgetTypes(pageA[i].widgets, pageB[i].widgets)) return true;
            }

            return false;
        };

        ctrl.compareReportValues = function (reportA, reportB) {
            if (!Tools.isDefinedNotNull(reportA)) return false;
            if (reportA["title"] !== reportB["title"]) return true;
            if (reportA["formatOrientation"] !== reportB["formatOrientation"]) return true;
            if (reportA["formatSize"] !== reportB["formatSize"]) return true;
            if (reportA["formatPagination"] !== reportB["formatPagination"]) return true;

            //Schedulers
            if (ctrl.compareListLength(reportA["schedulers"], reportB["schedulers"])) return true;

            //paperlength
            if (ctrl.compareListLength(reportA["pages"], reportB["pages"])) return true;

            //paper content
            if (ctrl.comparePageContent(reportA["pages"], reportB["pages"])) return true;

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalReportChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalReportChange = function () {
            var currReport = angular.copy(ctrl.report);
            if (ctrl.compareReportValues(initReport, currReport)) return true
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            // && toState.name !== "location.devices.edit"
            if (ctrl.evalReportChange() && !ignoreNextStateChange && !reportSaved) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('global.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('global.modal.empty.yes'),
                    textButton0: $translate.instant('global.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initReport = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        // Check if widgets are in bounds and don't overlap borders
        ctrl.widgetChange = function () {
            var page = ctrl.report.formatOrientation == 0 ? ctrl.dashboard.options.half : ctrl.dashboard.options.full;
            for (var p = 0; p < ctrl.report.pages.length; p++) {
                for (var w = 0; w < ctrl.report.pages[p].widgets.length; w++) {
                    var widget = ctrl.report.pages[p].widgets[w];
                    if (parseInt(widget.x) < 0
                        || parseInt(widget.x) >= page.width
                        || parseInt(widget.y) < 0
                        || parseInt(widget.y) >= page.height
                        || parseInt(widget.x) + parseInt(widget.width) > page.width
                        || parseInt(widget.y) + parseInt(widget.height) > page.height) {
                        ctrl.report.pages[p].widgets.splice(w, 1);
                    }
                }
            }
        };

        // TODO: Feature: Multiple schedulers!
        ctrl.addScheduler = function (schedulerType) {
            var receiver = '';
            if (Tools.isDefinedNotNull(ctrl.report.schedulers) && ctrl.report.schedulers.length > 0) {
                receiver = ctrl.report.schedulers[0].receiver;
                ctrl.report.schedulers = [];
            }
            if (schedulerType > ctrl.options.scheduler.none) {
                var scheduler = angular.copy(schedulerTemplate);
                scheduler.mode = schedulerType;
                scheduler.receiver = receiver;
                ctrl.report.schedulers.push(scheduler);
            }
        };

        function _addPage() {
            var page = {
                // formatOrientation: input.id,
                page: ctrl.report.pages.length,
                widgets: []
            };
            ctrl.report.pages.push(page);
        }

        function _removePage(pageId) {
            // Rearrange pages array
            var result = [];
            for (var i = 0; i < ctrl.report.pages.length; i++) {
                if (ctrl.report.pages[i].page !== pageId) {
                    var copy = angular.copy(ctrl.report.pages[i]);
                    copy.page = result.length;
                    result.push(copy);
                    // ctrl.report.pages.splice(i, 1);
                }
            }
            delete ctrl.report.pages;
            ctrl.report.pages = result;
        }

        ctrl.calcPageCount = function(page) {
           return  $translate.instant('reports.page') + (page +1);
        }

        ctrl.isValidReport = function () {
            if (ctrl.report.schedulers != null && ctrl.report.schedulers.length) {
                var shed = ctrl.report.schedulers[0];
                //if(shed.mode == ctrl.options.scheduler.unique){
                if (!shed.receiver) return false;
                if (shed.mode === 1 && (!Tools.isDefinedNotNull(shed.repeatValue) || shed.repeatValue <= 0)) return false;
                if (shed.endType === 1 && (!Tools.isDefinedNotNull(shed.endsAfter) || shed.endsAfter <= 0)) return false;
            }
            return ctrl.report.title !== null
                && ctrl.report.title !== ''
                && _hasPagesAndWidgets()
                && _onCreating();

            function _hasPagesAndWidgets() {
                var valid= true;
                if (ctrl.report.pages.length <= 0) {
                    return false;
                }
                for (var i = 0; i < ctrl.report.pages.length; i++) {
                    var page = ctrl.report.pages[i];
                    if (page.widgets.length <= 0) {
                        return false;
                    }
                    valid = valid && _allPageWidgetsValid(page);
                }
                return valid;
            }

            function _allPageWidgetsValid(page) {
                var valid = page != null;
                if (page && page.widgets) {
                    for (var i = 0; i < page.widgets.length > 0; i++) {
                        var widget = page.widgets[i];
                        valid = valid && _isValidImageWidget(widget);
                    }
                }
                return valid;
            }

            function _isValidImageWidget(widget) {
                if(!widget) {
                    return false;
                }
                if (widget.type == 'widgetimage') {

                    for (var i = 0; i < widget.settings.length > 0; i++) {
                        var settings = widget.settings[i];
                        if ("name" == settings.key && settings.value) {
                            return true;
                        }
                    }
                    return false;
                }
                return true;
            }

            function _onCreating() {
                return isCreateable;
            }
        };

        ctrl.createReport = function () {
            if (ctrl.isValidReport()) {
                handleImageWidgets().then(function () {
                    ctrl.save();
                });
            }
        };


        ctrl.save = function(){
            reportSaved = true;
            isCreateable = false;
            initReport = angular.copy(ctrl.report);
            var report = angular.copy(ctrl.report);
            report.title = escapeHtml(report.title);

            _prepareSchedulers(report);

            $log.debug("Saving report: ", report);
            if (isEdit) {
                Report.update(report).then(function () {
                    Notify.success("global.notification.success.title", "global.notification.success.text", 3000);

                    $state.reload();
                    $timeout(function (){
                        $state.go("reports.edit", {id: report.id});
                        isCreateable = true;
                    }, 2000);
                }, function (error) {
                    $log.error('Couldn\'t save report!', error);
                    ctrl.options.saveBtn = 'error';
                    Notify.defaultError();
                    $timeout(function () {
                    }, 2000);
                });
            } else {
                Report.create(report).then(function (response) {

                    Notify.success("global.notification.success.title", "global.notification.success.text", 3000);
                    $timeout(function () {
                        $state.go("reports.edit", {id: response.data})
                    }, 2000);
                }, function (error) {
                    $log.error('Couldn\'t create report!', error);
                    $timeout(function () {
                    }, 2000);
                });
            }
        };

        function handleImageWidgets() {
            return new Promise(function(resolve, reject) {

                var promises = [];
                if (ctrl.report.pages) {
                    var pages = ctrl.report.pages;
                    for (var i = 0; i < pages.length; i++) {
                        var page = pages[i];
                        if (page.widgets) {
                            var widgets = page.widgets;
                            for(var j = 0; j < widgets.length; j++){
                                var widget = widgets[j];
                                if(widget.type === 'widgetimage' && widget.imageObject){
                                    promises.push( $http.post("/api/image/report/" + widget.wid, widget.imageObject).then(function(response) {
                                        if (response.data) {
                                            assignReportWidgetImageIds(response.data);
                                        }
                                         //   widget.settings.push({key: 'imageDataId', value: response.data});
                                    }));

                                }
                            }
                        }
                    }
                }

                $q.all(promises).then(function(){
                    resolve();
                });
            });
        }

        function assignReportWidgetImageIds(widgetImageMap){
            for(var wi in widgetImageMap) {
                for (var pi in ctrl.report.pages) {
                    var pg = ctrl.report.pages[pi];
                    for (var pwi in pg.widgets) {
                        var w = pg.widgets[pwi];
                        if (w.wid === parseInt(wi, 10)) {
                            ctrl.report
                                .pages[pi]
                                .widgets[pwi]
                                .settings.push({
                                key: 'imageDataId',
                                value: widgetImageMap[wi]
                            });
                        }
                    }
                }
            }
        }

        // Set mode specific values so we can guess the mode & endType on load
        function _prepareSchedulers(report) {
            for (var i = 0; i < ctrl.report.schedulers.length; i++) {
                if (report.schedulers[i].mode === ctrl.options.scheduler.unique) {
                    report.schedulers[i].repeat = null;
                    report.schedulers[i].repeatValue = null;
                    report.schedulers[i].endsOn = null;
                    report.schedulers[i].endsAfter = null;
                } else if (report.schedulers[i].mode === ctrl.options.scheduler.repetitive) {
                    if (typeof report.schedulers[i].repeatValue === "undefined") {
                        report.schedulers[i].repeatValue = 1;
                    }
                    if (report.schedulers[i].endType === ctrl.options.endType.date) {
                        report.schedulers[i].endsAfter = null;
                    } else if (report.schedulers[i].endType === ctrl.options.endType.iteration) {
                        report.schedulers[i].endsOn = null;
                        if (typeof report.schedulers[i].endsAfter === 'undefined') {
                            report.schedulers[i].endsAfter = 1;
                        }
                    } else {
                        report.schedulers[i].endsOn = null;
                        report.schedulers[i].endsAfter = null;
                    }
                }
                delete report.schedulers[i].mode;
                delete report.schedulers[i].endType;
            }
        }

        ctrl.repetitionFromValue = function (value) {
            switch (Number(value)) {
                case 0:
                    return 'daily';
                case 1:
                    return 'weekly';
                case 2:
                    return 'monthly';
                case 3:
                    return 'annualy';
                default:
                    return 'error';
            }
        };

        function _addWidget(page, widget) {
            var widgetTitle = widget.title;
            var options = ctrl.report.formatOrientation === 0 ? ctrl.dashboard.options.half : ctrl.dashboard.options.full;
            if (widget.width > options.width) widget.width = options.width;
            if (widget.height > options.height) widget.height = options.height;
            var newWidget = {
                id: -1,
                wid: ctrl.options.getNextWidgetId(),
                ownerId: ctrl.tmp.user.id,
                x: 0,
                y: 0,
                width: widget.width,
                height: widget.height,
                type: widget.type,
                title: widgetTitle,
                reSize: widget.isresizable,
                settings: []
            };
            ctrl.report.pages[page].widgets.push(newWidget);
        }

        function _loadReport() {
            var report = reportData;
            // Check if scheduler is enabled and add/remove options
            if (report.schedulers === null) {
                report.schedulers = [];
            }
            for (var i = 0; i < report.schedulers.length; i++) {
                if (Tools.isDefinedNotNull(report.schedulers[i].receiver) && report.schedulers[i].receiver.length > 0) {
                    if (Tools.isDefinedNotNull(report.schedulers[i].repeatValue) && report.schedulers[i].repeatValue > 0) {
                        report.schedulers[i].mode = ctrl.options.scheduler.repetitive;
                    } else {
                        report.schedulers[i].mode = ctrl.options.scheduler.unique;
                        report.schedulers[i].endsOn = moment();
                    }
                }
                if (Tools.isDefinedNotNull(report.schedulers[i].endsAfter) && report.schedulers[i].endsAfter > 0) {
                    report.schedulers[i].endType = ctrl.options.endType.iteration;
                } else if (Tools.isDefinedNotNull(report.schedulers[i].endsOn)) {
                    report.schedulers[i].endType = ctrl.options.endType.date;
                } else {
                    report.schedulers[i].endType = ctrl.options.endType.never;
                }

                if (report.schedulers[i].endsOn === null) {
                    report.schedulers[i].endsOn = '';
                }
            }

            ctrl.report = report;
            ctrl.widgetChange();
            $log.debug("Report loaded: ", report);
        }
    }

    angular
        .module('emsv2App')
        .controller('ReportsGenerateController', ReportsGenerateController);
})();
