(function () {
    'use strict';

    angular.module('emsv2App')
        .directive('datePickerValidation', ['$translate', '$log', function ($translate, $log) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attr, ngModel) {
                    var fallback = scope.$eval(attr["ngModel"]);
                    var dateFormat = scope.$eval(attr["options"] + ".locale.format") || 'YYYY-MM-DD'; // default date-range-picker format
                    scope.$watch(function () {
                        return ngModel.$modelValue;
                    }, function (modelValue) {
                        if (moment.isMoment(modelValue) && modelValue.isValid()) {
                            fallback = modelValue;
                        }
                    });
                    element.change(function () {
                        $log.debug(fallback);
                        if (!moment.isMoment(ngModel.$modelValue) || !ngModel.$modelValue.isValid()) {
                            ngModel.$setViewValue(fallback.format(dateFormat), true);
                            ngModel.$render();
                        }
                    });
                }
            };
        }]);
})();
