(function () {
    'use strict';

    /**
     * @ngdoc factory
     * @name Auth
     * @requires $rootScope, $state, $q, $translate, $http, $log, Principal, AuthServerProvider, Tools
     */
    angular.module('emsv2App')
        .service('Auth', function Auth($rootScope, $state, $q, $translate, $http, $log, $cookies, Principal, AuthServerProvider, Tools, ThemeService, localStorageService) {

            function _checkRoles() {
                if (!$rootScope.toState.data.rolesMatch || $rootScope.toState.data.rolesMatch === 'any') {
                    return Principal.isInAnyRole($rootScope.toState.data.roles);
                } else {
                    for (var i = 0; i < $rootScope.toState.data.roles.length; i++) {
                        if (!Principal.isInRole($rootScope.toState.data.roles[i])) {
                            return false;
                        }
                    }
                    return true;
                }
            }

            function _logout() {
                localStorageService.remove("token");
                localStorageService.remove("THEME");
                Principal.setShowChangePasswordSite(false);
                $state.go('logout', {}, {reload: true});
            }

            return {
                login: function (credentials, callback) {
                    var cb = callback || angular.noop;
                    var deferred = $q.defer();

                    AuthServerProvider.login(credentials).then(function (data) {
                        // retrieve the logged account information
                        Principal.identity(true).then(function (account) {
                            //setup correct theme according to db
                            ThemeService.check(account);
                            // After the login the language will be changed to
                            // the language selected by the user during his registration
                            if (Tools.isDefinedNotNull(account) && Tools.isDefinedNotNull(account.langKey)) {
                                $translate.use(account.langKey).then(function () {
                                    deferred.resolve(data);
                                });
                            } else {
                                deferred.resolve(data);
                            }
                        });
                        return cb();
                    }).catch(function (err) {
                        // this.logout();
                        Principal.authenticate(null);
                        AuthServerProvider.logout();
                        deferred.reject(err);
                        return cb(err);
                    }.bind(this));

                    return deferred.promise;
                },
                logout: _logout,
                authorize: function (force) {
                    return Principal.identity(force)
                        .then(function () {
                            if ($rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !_checkRoles()) {
                                if (Principal.isAuthenticated()) {
                                    // user is signed in but not authorized for desired state
                                    $state.go('accessdenied');
                                } else if (Principal.showChangePasswordSite()) {
                                    // User wants to jump to a state without seeing the warning as default user
                                    Principal.showChangePasswordState();
                                } else {
                                    // user is not authenticated. stow the state they wanted before you
                                    // send them to the signin state, so you can return them when you're done
                                    $rootScope.returnToState = $rootScope.toState;
                                    $rootScope.returnToStateParams = $rootScope.toStateParams;

                                    // now, send them to the signin state so they can log in
                                    $state.go('login');
                                }
                            }
                        })
                        .catch(function (error) {
                            $log.debug('Authorization failed!', error);
                        });
                },
                checkSuperUser: function () {
                    return $http.get('api/account/check_superuser');
                },
                createSuperUser: function (account) {
                    return $http.post('api/account/register/create_superuser', account);
                },
                createAccount: function (account) {
                    return $http.post('api/account/register', account);
                },

                updateAccount: function (account) {
                    return $http.post('api/account', account);
                },

                activateAccount: function (key, ipAddress) {
                    return $http.get('api/account/activate/' + key + '/' + ipAddress);
                },

                deleteAccount: function (password) {
                    var transform = function (data) {
                        return $.param(data);
                    };
                    return $http.post('api/account/delete', {
                        currentPassword: password
                    }, {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        transformRequest: transform
                    });
                },

                changePasswordSecure: function (newPassword, currentPassword) {
                    var transform = function (data) {
                        return $.param(data);
                    };

                    return $http.post('api/account/change_password_secure', {
                        newPassword: newPassword,
                        currentPassword: currentPassword
                    }, {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        transformRequest: transform
                    });
                },

                changePassword: function (password) {
                    return $http({
                        url: 'api/account/change_password',
                        method: 'POST',
                        data: password,
                        headers: {
                            'Content-Type': 'text/plain'
                        }
                    });
                },
                registerAllowed: function () {
                    return $http.get('api/account/register');
                },
                checkLoginExists: function (login) {
                    return $http({
                        url: '/api/account/exists',
                        method: 'POST',
                        data: login,
                        headers: {
                            'Content-Type': 'text/plain'
                        }
                    });
                },
                checkEmailExists: function (email) {
                    return $http({
                        url: '/api/email/exists',
                        method: 'POST',
                        data: email,
                        headers: {
                            'Content-Type': 'text/plain'
                        }
                    });
                },
                forgotPassword: function (value, isEmail) {
                    return $http.get('api/account/password/forgot', {
                        params: {
                            value: value,
                            isEmail: !!isEmail
                        }
                    });
                },
                checkToken: function (token) {
                    return $http.get('api/account/checktoken/' + token);
                },
                resetPassword: function (password, token) {
                    var transform = function (data) {
                        return $.param(data);
                    };

                    return $http.post('api/account/password/reset', {
                        password: password,
                        token: token
                    }, {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        transformRequest: transform
                    });
                }
            };
        });
})();
