(function () {

    'use strict';

    function WidgetPueMonitorController($scope, WidgetData, Notify, $log) {

        var ctrl = this;
        ctrl.comparing = false;

        var RANGES_KEY = 'ranges';
        var COMPARE = 'compare';
        var LEGEND_CONFIG = 'legendConfig';
        var SELECTED_LOCATION_ID_KEY = 'selectedLocationId';

        ctrl.wicketId = $scope.widget.wid;

        ctrl.isPueSettingsOpen = false;

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl.openPueSettings);
            loadSettings();
        };

        ctrl.configure = function () {
        };

        ctrl.openPueSettings = function () {
            ctrl.isPueSettingsOpen = true;
        };


        ctrl.updateWidgetSettings = function($settings){
            ctrl.saveSettings($settings);
        }

        ctrl.saveSettings = function (data) {
            var newSettings = [];
            if (data && data.locationId) {

                newSettings.push({key: SELECTED_LOCATION_ID_KEY, value: data.locationId});

                if(data.ranges){
                    newSettings.push({key: RANGES_KEY, value: data.ranges});
                }

                if(data.compare){
                    newSettings.push({key: COMPARE, value: data.compare});
                }
                if(data.legendConfig){
                    newSettings.push({key: LEGEND_CONFIG, value: data.legendConfig});
                }

                $scope.widget.settings = newSettings;

            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                }, function (error) {
              //      Notify.error("global.notification.error.title", "dashboard.widget.speedometer.modal.saveErrorMsg", 2000);
                });
            }

            }
        }

        function loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch ($scope.widget.settings[i].key) {
                        case SELECTED_LOCATION_ID_KEY:
                            ctrl.selectedLocationId = parseInt($scope.widget.settings[i].value);
                            break;
                        case RANGES_KEY:
                            ctrl.ranges = JSON.parse($scope.widget.settings[i].value);
                            break;
                        case COMPARE:
                            ctrl.comparing = JSON.parse($scope.widget.settings[i].value);
                        case LEGEND_CONFIG:
                            ctrl.legendConfig = JSON.parse($scope.widget.settings[i].value);
                            break;
                        default: break;
                    }
                }
            }
        }

    }

    angular.module('emsv2App')
        .controller('widgetPueMonitorController', WidgetPueMonitorController)
})();
