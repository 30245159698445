'use strict';

/**
 * @description Constructor to create new SmtpSMSServerConfiguration object
 * @constructor
 */
function SmtpSMSServerConfiguration(smtpSMSServerConfiguration) {
    if (smtpSMSServerConfiguration !== undefined) {
        this.id = smtpSMSServerConfiguration.id !== undefined ? smtpSMSServerConfiguration.id : null;
        this.hostName = smtpSMSServerConfiguration.hostName !== undefined ? smtpSMSServerConfiguration.hostName : null;
        this.port = smtpSMSServerConfiguration.port !== undefined ? smtpSMSServerConfiguration.port : 25;
        this.userName = smtpSMSServerConfiguration.userName !== undefined ? smtpSMSServerConfiguration.userName : null;
        this.password = smtpSMSServerConfiguration.password !== undefined ? smtpSMSServerConfiguration.password : null;
        this.senderEmailAddress = smtpSMSServerConfiguration.senderEmailAddress !== undefined &&
        smtpSMSServerConfiguration.senderEmailAddress !== null &&
        smtpSMSServerConfiguration.senderEmailAddress !== "" ?
            smtpSMSServerConfiguration.senderEmailAddress : "CyberhubECODC@localhost";
        this.protocol = smtpSMSServerConfiguration.protocol !== undefined ? smtpSMSServerConfiguration.protocol : "SMTP";
        this.phoneNumber = smtpSMSServerConfiguration.phoneNumber !== undefined ? smtpSMSServerConfiguration.phoneNumber : null;
    } else {
        this.id = null;
        this.hostName = null;
        this.port = 25;
        this.userName = null;
        this.password = null;
        this.senderEmailAddress = "CyberhubECODC@localhost";
        this.protocol = "SMTP";
        this.phoneNumber = null;
    }
    this.inProgress = false;
    this.inEditMode = false;
}

SmtpSMSServerConfiguration.prototype = {};
SmtpSMSServerConfiguration.prototype.constructor = SmtpSMSServerConfiguration;

/**
 * @description Function to validate this object
 */
SmtpSMSServerConfiguration.validate = function (entity) {
    if (entity.hostName === undefined || entity.hostName === null || entity.hostName === "") return false;
    if (entity.port === undefined || entity.port === null || entity.port === "") return false;
    if (entity.phoneNumber === undefined || entity.phoneNumber === null || entity.phoneNumber === "") return false;
    if (entity.userName === undefined || entity.userName === null || entity.userName === "") return false;
    if (entity.password === undefined || entity.password === null || entity.password === "") return false;

    return true;
};

SmtpSMSServerConfiguration.compareValues = function (initialEntity, currentEntity) {
    if (initialEntity !== null) {
        var aProps = Object.getOwnPropertyNames(initialEntity);
        var bProps = Object.getOwnPropertyNames(currentEntity);

        if (aProps.length !== bProps.length) {
            return true;
        }

        if (initialEntity.hostName !== currentEntity.hostName) return true;
        if (initialEntity.port !== currentEntity.port) return true;
        if (initialEntity.userName !== currentEntity.userName) return true;
        if (initialEntity.password !== currentEntity.password) return true;
        if (initialEntity.phoneNumber !== currentEntity.phoneNumber) return true;

        return false;
    }
};
