(function () {

    'use strict';

    /**
     * A label that contains definable content.
     * @ngdoc component
     * @name dragLabel
     * @requires $element
     * @param {string} data - Text to drop
     * @param {string} css - CSS to use
     * @param {string} text - Label to show
     */
    angular.module('emsv2App')
        .component('dragLabel', {
            template: '<span class="label {{$ctrl.css}}" draggable="true" ng-bind="$ctrl.text"></span>',
            bindings: {
                data: '<',
                css: '<',
                text: '<'
            },
            controller: ['$element', function ($element) {
                var ctrl = this;
                ctrl.$onInit = function () {
                    $element.on('dragstart', function (jEvent) {
                        jEvent.originalEvent.dataTransfer.setData('text', ctrl.data);
                    });
                };
            }]
        });
})();
