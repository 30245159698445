(function () {

    'use strict';

    function rsqlFilterController($scope, $timeout, $log, $translate,
                                  Filter, Authority, Session, RsqlQuery, Principal, Tools, Rsql, Auth) {
        var ctrl = this;

        // Filters
        ctrl.filterModel = {
            selected: 0,                        // Count of selected filters
            showFilters: true,                  // Show selected filters by default
            currentSave: {},                    // If a filter was saved, he will be saved here for reference
            list: [],                           // List of available filters. Will be set at init()
            selection: null
        };

        // A list of named queries, retrieved from the db
        ctrl.savedQueries = [
            {
                fk_gid: null,
                fk_uid: undefined,
                id: undefined,
                name: undefined,
                query: undefined,
                table: undefined
            }
        ];

        // A list of all available groups
        ctrl.groups = [
            {
                id: undefined,
                locations: [],
                name: undefined,
                users: []
            }
        ];

        // Saving queries
        ctrl.saveModel = {
            savefor: 'me',                  // Save filter for user oder group
            wait: false,                    // Flags for filter save wait animation
            success: false,
            error: false,
            enabled: false,                  // Enable/disabled "save filter" fields
            group: null
        };
        // Enable advanced mode
        ctrl.advanced = {
            enabled: false,
            query: ''
        };

        $scope.$watch(function () { return ctrl.orderBy; }, _doFilter);
        $scope.$watch(function () { return ctrl.orderReverse; }, _doFilter);

        // Delete queries from query cache
        $scope.$on('rsql-delete-query', function (msg, value) {
            if (ctrl.table === value.table) {
                for (var i = 0; i < ctrl.savedQueries.length; i++) {
                    if (ctrl.savedQueries[i].id === value.id) {
                        ctrl.savedQueries.splice(i, 1);
                    }
                }
            }
        });

        $scope.$on('rsql-load-query', function (event, query) {
            if (ctrl.table === query.table
                && (ctrl.filterModel.currentSave === null || ctrl.filterModel.currentSave.id !== query.id)) {
                ctrl.loadFilter(query);
            }
        });

        function _resetSaveModel() {
            ctrl.saveModel = {
                savefor: 'me',                  // Save filter for user oder group
                wait: false,                    // Flags for filter save wait animation
                success: false,
                error: false,
                enabled: false,                  // Enable/disabled "save filter" fields
                group: null
            }
        }

        // Current query
        Object.defineProperty(ctrl, 'currentQuery', {
            _value: '',
            get: function () {
                var result = '';
                // Add the current filter if any
                if (Tools.isDefinedNotNull(this._value) && this._value !== '') {
                    if (result.length > 0) {
                        result += ';';
                    }
                    result += this._value;
                }
                // Always adds orderBy
                result += '?orderby=' + ctrl.orderBy;

                if (Tools.isDefinedNotNull(ctrl.orderReverse)) {
                    result += '&direction=' + (ctrl.orderReverse ? 'desc' : 'asc');
                }
                return result;
            },
            set: function (value) {
                if (angular.isDefined(value) && value !== null) {
                    this._value = value;
                }
            }
        });

        // Field definition
        ctrl.definition = [
            {
                field: null,
                fk: null,
                fkField: null,
                fkTable: null,
                hidden: false,
                operators: [],
                options: [],
                orderId: null,
                primary: false,
                required: false,
                searchable: false,
                filterable: false,
                writeable: false,
                type: null,
                data: null,
                selected: false
            }
        ];

        // Apply all the selected filters to the query
        ctrl.applyFilter = function () {
            ctrl.currentQuery = RsqlQuery.filterQuery(ctrl.definition);
            Session.put(ctrl.table + '_query', ctrl.advanced.enabled ? ctrl.advanced.query : ctrl.currentQuery); // Save current query to session
            Session.put(ctrl.table + '_advanced', ctrl.advanced.enabled); // Save advanced enabled state
            _doFilter(false);
        };

        // Search in all available Text fields
        ctrl.search = function (searchKey) {
            ctrl.currentQuery = RsqlQuery.searchQuery(ctrl.definition, searchKey);
            _doFilter(true);
        };

        // Save the current query to the database
        ctrl.saveQuery = function () {
            ctrl.saveModel.wait = true;
            ctrl.saveModel.success = false;
            ctrl.saveModel.error = false;
            ctrl.applyFilter();
            var currentFilter;
            if (Tools.isDefinedNotNull(ctrl.filterModel.currentSave) &&
                Tools.isDefinedNotNull(ctrl.filterModel.currentSave.name) &&
                (ctrl.filterModel.currentSave.name)) {
                currentFilter = ctrl.filterModel.currentSave;
                currentFilter.query = ctrl.currentQuery;
            }
            else {
                currentFilter = {
                    table: ctrl.table,
                    name: ctrl.saveModel.saveFilterName,
                    query: ctrl.currentQuery
                };
            }

            if (ctrl.saveModel.savefor === 'group' && Tools.isDefinedNotNull(ctrl.saveModel.group)) {
                currentFilter.fk_uid = null;
                currentFilter.fk_gid = ctrl.saveModel.group.id;
            }

            if (ctrl.saveModel.savefor === 'me') {
                currentFilter.fk_gid = null;
            }

            Filter.saveQuery(ctrl.table, currentFilter).then(function (response) {
                var savedQuery = response.data;
                ctrl.saveModel.wait = false;
                ctrl.saveModel.success = true;
                ctrl.filterModel.currentSave = savedQuery;

                _loadQueries();
                // Hide the save form after two seconds
                $timeout(function () {
                    ctrl.saveModel.success = false;
                    ctrl.filterModel.showFilters = false;
                }, 2000);
            }, function (error) {
                ctrl.saveModel.wait = false;
                ctrl.saveModel.error = true;
                $log.error('Couldn\'t save filter!', error);
            });
        };

        // Removes all values from the array and the objects from the container
        ctrl.removeAllFilter = function () {
            _resetSaveModel();
            ctrl.filterModel.showFilters = false;
            ctrl.filterModel.currentSave = null;
            ctrl.orderBy = '';
            ctrl.orderReverse = false;
            ctrl.advanced.enabled = false;
            ctrl.advanced.query = '';

            for (var i = 0; i < ctrl.definition.length; i++) {
                ctrl.definition[i].selected = false;
                ctrl.definition[i].data = undefined;
            }

            ctrl.countSelected();
            Session.put(ctrl.table + '_query', '');
            ctrl.search();
        };

        // Add a filter to the list
        ctrl.addFilter = function (param, operator, value) {
            if (!Tools.isDefinedNotNull(param)
                || !Tools.isDefinedNotNull(param.field)
                || param.field === '') {
                return;
            }

            $log.debug('Add filter: ', param);
            for (var i = 0; i < ctrl.definition.length; i++) {
                if (ctrl.definition[i].field === param.field) {
                    if (!ctrl.definition[i].selected) {
                        ctrl.definition[i].selected = true;

                        if (!Tools.isDefinedNotNull(operator) || operator === '') {
                            ctrl.definition[i].operator = ctrl.definition[i].operators[0];
                        }
                        else {
                            // find and set current operator
                            for (var o = 0; o < ctrl.definition[i].operators.length; o++) {
                                if (ctrl.definition[i].operators[o].operator === operator) {
                                    ctrl.definition[i].operator = ctrl.definition[i].operators[o];
                                }
                            }
                        }

                        if (!Tools.isDefinedNotNull(value)) {
                            ctrl.definition[i].value = null;
                        }
                        else {
                            if (ctrl.definition[i].type === 'num') {
                                if (String(value).indexOf('//.') > 0 || String(value).indexOf('//,') > 0) {
                                    value = parseFloat(value);
                                }
                                else {
                                    value = parseInt(value);
                                }
                            }

                            ctrl.definition[i].data = value;
                        }
                    }
                }
            }
            ctrl.filterModel.showFilters = (ctrl.countSelected() > 0);
        };

        // Delete a filter from the list
        ctrl.removeFilter = function (fieldName) {
            var defIndex = ctrl.definition.findIndex(function (el) { return el.field === fieldName; });
            if (defIndex > -1) {
                ctrl.definition[defIndex].selected = false;
                ctrl.applyFilter();
            }
            if (defIndex <= 0) {
                ctrl.filterModel.currentSave = null;
            }
        };

        // Load a saved filter from a link
        ctrl.loadFilter = function (filterToLoad) {
            ctrl.filterModel.currentSave = filterToLoad;
            ctrl.removeAllFilter();
            var split_query = filterToLoad.query.split("and");

            for (var i = 0; i < split_query.length; i++) {
                RsqlQuery.parse(split_query[i].trim(), ctrl.definition, function (orderField, orderDirection) {
                    ctrl.orderBy = orderField;
                    ctrl.orderReverse = orderDirection;
                }, function (definition, operator, value) {
                    ctrl.addFilter(definition, operator, value);
                });
            }
            ctrl.currentQuery = RsqlQuery.filterQuery(ctrl.definition);
            Session.put(ctrl.table + '_query', ctrl.currentQuery);
            ctrl.saveModel.saveFilterName = filterToLoad.name;
            ctrl.filterModel.showFilters = false;

            if (filterToLoad.fk_gid !== null) {
                ctrl.saveModel.savefor = 'group';
                ctrl.saveModel.group = ctrl.groups.find(function (group) { return group.id === filterToLoad.fk_gid; });
                ctrl.saveModel.enabled = true;
            }
            _doFilter();
        };

        // Count all selected filters and update the global reference
        ctrl.countSelected = function () {
            ctrl.filterModel.selected = ctrl.definition
                .filter(function (definition) { return definition.selected; })
                .length;
            return ctrl.filterModel.selected;
        };

        // Count all available filters
        ctrl.availableFilters = function () {
            return ctrl.definition
                .filter(function (def) {return !def.hidden && def.operators !== null && def.operators.length > 0;})
                .length;
        };

        // Search the array of saved queries for personal ones (which have a uid).
        ctrl.getPersonalSavedQueries = function (queries) {
            return queries.filter(function (query) {
                return Tools.isDefinedNotNull(query.fk_uid);
            });
        };

        // Search the array of saved queries for the supplied group id
        ctrl.getGroupQueries = function (queries, group) {
            return queries.filter(function (query) {
                return Tools.isDefinedNotNull(query.fk_gid) && query.fk_gid === group.id;
            });
        };

        /**
         * Initialize the process of filtering
         * @param fullTextSearch is a boolean to identify if the filter is a simple text search or an exact filter
         *
         *      !!! ATTENTION !!!
         * {@param fullTextSearch} is a feature for the alarmmanagement and if you modify this frontend logic
         * you also need to modify the backend.
         *      !!! ATTENTION !!!
         *
         * @private
         */
        function _doFilter(fullTextSearch) {
            if (!Tools.isDefinedNotNull(fullTextSearch) && !(fullTextSearch instanceof Boolean)) { fullTextSearch = null; }
            if (ctrl.onFilter !== undefined && ctrl.onFilter !== null) {
                if (!ctrl.advanced.enabled) {
                    if (ctrl.currentQuery.contains("mac_hex==\"")) {
                        ctrl.onFilter(ctrl.currentQuery.replace(/:/g, ""), fullTextSearch);
                    }
                    else {
                        ctrl.onFilter(ctrl.currentQuery, fullTextSearch);
                    }
                }
                else {
                    var result = RsqlQuery.parseOperators(ctrl.advanced.query);
                    result += '?orderby=' + ctrl.orderBy;

                    if (Tools.isDefinedNotNull(ctrl.orderReverse)) {
                        result += '&direction=' + (ctrl.orderReverse ? 'desc' : 'asc');
                    }
                    ctrl.onFilter(result, fullTextSearch);
                }
            }
        }

        ctrl.switchAdvanced = function () {
            ctrl.advanced.query = RsqlQuery.filterQuery(ctrl.definition);
            ctrl.advanced.enabled = !ctrl.advanced.enabled;

            for (var i = 0; i < ctrl.definition.length; i++) {
                ctrl.definition[i].selected = false;
                ctrl.definition[i].data = undefined;
            }
        };

        ctrl.advancedFilter = function () {
            _doFilter();
        };

        /**
         * Check if a filter/definition should be shown.
         */
        function checkDefinition(definition) {
            return !definition.hidden
                && definition.filterable
                && definition.operators !== null
                && definition.operators.length > 0
                && notDisabled(definition);

            function notDisabled(definition) {
                if (ctrl.disabledFilters !== undefined) {
                    for (var i = 0; i < ctrl.disabledFilters.length; i++) {
                        if (definition.field === ctrl.disabledFilters[i]) {
                            return false;
                        }
                    }
                }
                return true;
            }
        }

        // Init
        ctrl.$onInit = function () {
            var def = ctrl.rsqlv2
                ? Rsql.getAllDefinitions(ctrl.table)
                : Filter.getDefinitions(ctrl.table);
            // Load definitions & data if a cookie was found or a query in an url
            def.then(function (result) {
                ctrl.definition = result.data;
                ctrl.filterModel.list = [];
                for (var i = 0; i < ctrl.definition.length; i++) {
                    ctrl.definition[i].selected = false;
                    ctrl.definition[i].delete = ctrl.removeFilter;

                    if (checkDefinition(ctrl.definition[i])) {
                        ctrl.filterModel.list.push(ctrl.definition[i]);
                    }
                }

                var sessionQuery = Session.get(ctrl.table + '_query');
                var advancedEnabled = Session.get(ctrl.table + '_advanced');
                if (advancedEnabled) {
                    ctrl.advanced.enabled = true;
                    ctrl.advanced.query = sessionQuery;
                    ctrl.filterModel.showFilters = false;
                    _doFilter();
                }
                else {
                    if (Tools.isDefinedNotNull(sessionQuery)) {
                        var split_query = sessionQuery.split("and");
                        // Check if theres a session with a saved query

                        for (var i = 0; i < split_query.length; i++) {
                            RsqlQuery.parse(split_query[i].trim(), ctrl.definition, function (orderField, orderDirection) {
                                ctrl.orderBy = orderField;
                                ctrl.orderReverse = orderDirection;
                            }, function (definition, operator, value) {
                                ctrl.addFilter(definition, operator, value);
                            });
                        }

                        ctrl.currentQuery = RsqlQuery.filterQuery(ctrl.definition);
                        ctrl.filterModel.showFilters = false;
                        _doFilter();
                    }
                    else {
                        // Set empty query and load initial data
                        ctrl.currentQuery = '';
                        _doFilter();
                    }
                }
            }, function (error) {
                $log.error('Couldn\'t load definitions.', error, error.message);
            });
            _loadQueries();
            // Load groups
            Principal.identity().then(function (userInfo) {
                if (Tools.isDefinedNotNull(userInfo)) {
                    Authority.getGroups(userInfo.id).then(function (groupResponse) {
                        ctrl.groups = groupResponse.data;
                    }, function (error) {
                        $log.error('Couldn\'t load groups for "' + userInfo.login + '"!', error);
                    });
                }
                else { Auth.logout(); }
            }, function (error) {
                $log.error('Couldn\'t load account!', error);
            });
        };

        function _loadQueries() {
            // Retrieve previously saved queries
            Filter.getSavedQueries(ctrl.table).then(function (result) {
                ctrl.savedQueries = result.data;
            }, function (error) {
                $log.error('Couldn\'t load saved queries for table "' + ctrl.table + '"!', error);
            });
        }
    }

    /**
     * @ngdoc component
     * @name rsqlFilter
     * @requires $scope, $timeout, $log, $translate, Filter, Authority, Session, RsqlQuery, Principal, Tools
     * @param {string} table - Tablename
     * @param {string} translation - Translation prefix
     * @param {string} orderBy - Field to initial order by
     * @param {boolean} orderReverse - Initial reverse ordering
     * @param {function} onFilter - Callback when a filter is loaded/added
     * @param {string[]} disabledFilters - Definition fields to disable on this page
     * @param {Object[]} definitions - Set pre loaded (V2) definitions
     */
    angular.module('emsv2App').component('rsqlFilter', {
        bindings: {
            table: '<',
            translation: '<',
            orderBy: '=',
            orderReverse: '=',
            onFilter: '<',
            disabledFilters: '<',
            rsqlv2: '<'
        },
        templateUrl: 'scripts/components/rsql/rsqlFilter.component.html',
        controller: ['$scope', '$timeout', '$log', '$translate', 'Filter', 'Authority', 'Session', 'RsqlQuery', 'Principal', 'Tools', 'Rsql', 'Auth', rsqlFilterController]
    });

})();
