(function () {
    'use strict';

    angular.module("emsv2App").directive("editState", function () {
        return {
            restrict: 'A',
            require: "ngModel",
            link: function (scope, element, attr, ngModel) {
                var toEditState = function (value) {
                    if (value == 0) return false;
                    if (value == 1) return true;
                };
                var fromEditState = function (value) {
                    if (value == true) return 1;
                    if (value == false) return 0;
                };
                ngModel.$parsers.push(fromEditState);
                ngModel.$formatters.push(toEditState);
            }
        }
    });
})();
