(function () {
    'use strict';

    angular.module('emsv2App').controller('GroupController', ['$scope', '$state', '$stateParams', '$translate', '$log',
        'Group', 'GenDialogService', 'Notify',
        function ($scope, $state, $stateParams, $translate, $log, Group, GenDialogService, Notify) {
            var ctrl = this;
            ctrl.page = 0;
            ctrl.placeholder = $translate.instant('global.form.select');
            ctrl.doQuery = function () {
                return _query(false);
            };
            ctrl.hasMore = true;
            ctrl.data = [];
            ctrl.query = '';
            ctrl.tableModel = {
                tableName: 'group',
                translation: 'group.management',
                orderBy: 'id',
                orderDir: false,
                doFilter: _doFilter,
                head: [
                    {name: 'id', translation: 'group.management.id'},
                    {name: 'name', translation: 'group.management.name'},
                    {name: 'locations', translation: 'group.management.locations'},
                    {name: 'users', translation: 'group.management.users'},
                    {empty: true}
                ]
            };

            function _doFilter(params) {
                ctrl.query = params;
                _query(true);
            }

            function _query(reset) {
                var query = ctrl.query;
                ctrl.page = reset ? 0 : ctrl.page + 1;

                return Group.rsql(ctrl.page, query).then(function (response) {
                    ctrl.hasMore = response.data.length === 20;
                    if (!reset) {
                        for (var i = 0; i < response.data.length; i++) {
                            ctrl.data.push(response.data[i]);
                        }
                    }
                    else {
                        ctrl.data = response.data;
                    }
                }, function (error) {
                    $log.error("Couldn't load groups!", error);
                    ctrl.data = [];
                });
            }

            ctrl.deleteGroup = function(params) {
                $translate(['global.dialog.head.warning', 'group.action.delete', 'global.btn.delete', 'global.btn.cancel',
                    'group.management.delete.question2']).then(function (trans) {
                    GenDialogService.showDialog(false, {
                        headText: trans['global.dialog.head.warning'],
                        headIcon: 'glyphicon glyphicom-warning-sign',
                        messageText: $translate.instant('group.management.delete.question2', {groupname:params.name, userCount : params.userCount, locationCount: params.locationCount }),
                        showClose: true,
                        textButton1: trans['global.btn.delete'],
                        textButton0: trans['global.btn.cancel'],
                        iconButton1: 'glyphicon glyphicon-remove-circle',
                        iconButton0: 'glyphicon glyphicon-remove',
                        classButton1: 'btn-delete',
                        classButton0: 'btn-close',
                        callbackButton1: function () {
                            Group.delete(params.id).then(function (response) {
                                if(response.data == true){
                                    Notify.success("global.notification.success.title", "group.action.deletesuccess", 2000);
                                    _query(true)
                                }
                                else {
                                    Notify.error("global.notification.error.title", "global.notification.error.groupDelete", 4000);
                                    _query(true)
                                }
                            }, function (error) {
                                Notify.error("global.notification.error.title", "global.notification.error.groupDelete", 4000);
                            });
                            GenDialogService.hideDialog();
                        },
                        callbackButton0: function () {
                            GenDialogService.hideDialog();
                        }
                    });
                });
            }
    }]);
})();
