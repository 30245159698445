'use strict';

function RoomDeviceMapping(id, deviceId, roomId) {
    this.id = id !== undefined ? id : null;
    this.deviceId = deviceId !== undefined ? deviceId : null;
    this.roomId = roomId !== undefined ? roomId : null;
}

RoomDeviceMapping.prototype = {};
RoomDeviceMapping.prototype.constructor = RoomDeviceMapping;
