(function () {

    'use strict';

    function dashboardSaveModalController($scope, WidgetData, $log, $rootScope) {
        var ctrl = this;

        ctrl.save = function () {
            WidgetData.setWidgetDataForUser();
            $rootScope.$broadcast('closeSaveModal');
            $log.debug("Saving Widget Data");
        };

        ctrl.closeSaveModal = function () {
            $rootScope.$broadcast('closeSaveModal');
            $log.debug("closeSaveModal");
        };
    }

    angular.module('emsv2App')
        .controller('DashboardSaveModalController', dashboardSaveModalController);
})();
