(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('PasswordResetController', function ($rootScope, $state, $log, $scope, Auth, token) {
            var ctrl = this;
            ctrl.password = "";
            ctrl.confirmPassword = "";
            ctrl.error = false;

            ctrl.reset = function () {
                ctrl.error = false;
                Auth.resetPassword(ctrl.password, token).then(function () {
                    location.reload();
                }, function (e) {
                    ctrl.error = true;
                    $log.error("Could not reset password: ", e);
                });
            }
        });

})();
