/**
 * Created by RR on 15.02.22.
 */
(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name SmartEventSnmpService
     * @requires $http
     */
    angular.module('emsv2App').service('SmartEventSnmpService', function ($http) {

        var save = function (entity) {
            return $http.post('api/locations/errorConfig/event/snmp', entity).then(function (response) {
                return response.data;
            });
        };

        /************************************************
         *             FOREIGN KEY REFERENCE            *
         ************************************************/

        var getByFkId = function (errorConfigurationId) {
            return $http.get('api/locations/errorConfig/event/snmp/getByFkId/' + errorConfigurationId).then(function (response) {
                return response.data;
            });
        };

        var deleteByFkId = function (errorConfigurationId) {
            return $http.delete('api/locations/errorConfig/event/snmp/deleteByFkId/' + errorConfigurationId);
        };

        return {
            save: save,
            getByFkId: getByFkId,
            deleteByFkId: deleteByFkId
        };

    });
})();