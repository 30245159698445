(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Files
     * @requires $http
     */
    angular.module('emsv2App').service('Files', ['$http', function ($http) {
        return {
            get: function (root) {
                return $http.get('api/files', {params: root});
            },
            download: function (params) {
                return $http.post('api/files/download', params);
            },
            getToken: function () {
                return $http.get('api/files/token');
            }
        };
    }]);

})();
