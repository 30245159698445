(function () {
    'use strict';

    angular.module('emsv2App').service("GenDialogService", function (MessageService) {

        /**
         * Shows generic dialog
         * @param {boolean} allowBackdrop - allow click on backdrop to close dialog
         * @param {Object} params - configuration object - see documentation of configDialog for details
         */
        var showDialog = function (allowBackdrop, params) {
            if (params) configDialog(params);
            $('#genDialog').modal({
                show: true,
                backdrop: allowBackdrop === true ? true : 'static'
            });

        };
        /**
         * Closes the dialog
         */
        var hideDialog = function () {
            $('#genDialog').modal('hide');
        };
        /**
         * Configures the dialog - buttons/callbacks/texts/icons
         * @param {Object} params - configuration object
         *      configuration object must be build like this
         *      {
         *          headText: {string} - Text in header
         *          headIcon: {string} - Icon class for header
         *          messageText: {string} - Message to be displayed in content, will be compiled so html possible
         *          showClose: {boolean} - Show 'x' icon in upper right corner of head, closes dialog
         *          optional ---
         *          callbackButton0: {function} - callback function for left button, if not present button will not be visible
         *          textButton0: {string} - text for left button
         *          callbackButton1: {function} - callback function for middle button, if not present button will not be visible
         *          textButton1: {string} - text for middle button
         *          callbackButton2: {function} - callback function for right button, if not present button will not be visible
         *          textButton2: {string} - text for right button
         *      }
         */
        var configDialog = function (params) {
            MessageService.publish('configGenDialog', params);
        };

        return {
            showDialog: showDialog,
            hideDialog: hideDialog,
            configDialog: configDialog
        };
    });
})();
