(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('account.delete', {
                    parent: 'account',
                    url: '/account/delete',
                    data: {
                        roles: ['ROLE_USER'],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'global.menu.account.delete'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/delete/delete.html',
                            controller: 'AccountDeleteController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('settings');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        userInfo: ['$log', 'Principal', function ($log, Principal) {
                            return Principal.identity().then(function (account) {
                                return account;
                            }, function (error) {
                                $log.error('Couldn\'t load user!', error);
                            });
                        }]
                    },
                    onEnter: function($timeout, Principal){
                        'ngInject';
                        $timeout(function(){
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });

})();
