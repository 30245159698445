(function () {
    'use strict';

    function userValidator() {
        return {
            validate: function (user) {
                return typeof user.firstName === 'string' &&
                    user.firstName.trim() !== '' &&
                    typeof user.lastName === 'string' &&
                    user.lastName.trim() !== '' &&
                    typeof user.email === 'string' &&
                    user.email.trim() !== '' &&
                    typeof user.login === 'string' &&
                    user.login.trim() !== '';
            }
        };
    }

    angular
        .module('emsv2App')
        .service('UserValidator', userValidator);

})();
