(function () {
    'use strict';

    angular.module("emsv2App").service("LiveDataService", function ($log, $http, $translate, EMSConstants, OIDService,
                                                                    WebWorkerService, PhysicalTypeService, Tools) {

        var asyncWorkerHandle = null;
        var physicalTypes = [];
        PhysicalTypeService.getPhysTypeArray().then(function (response) {
            physicalTypes = response.data;
        });

        var getPysTypesForEntity = function () {
            PhysicalTypeService.getPhysTypeArray().then(function (response) {
                physicalTypes = response.data;
            });
        }

        var getLiveDataForLocation = function (locationId, rooms) {
            return $http.get("/api/livedata/location/" + locationId).then(function (response) {
                for (var i in rooms) {
                    console.log(rooms[i].name);
                }
                for (var i in response.data) {
                    var roomId = parseInt(i.split("_")[1]);
                    var room = rooms.filter(function (r) {
                        return r.id === roomId;
                    })[0];
                    for (var j in response.data[i]) {
                        var splitted = j.split("_");
                        var type = splitted[0];
                        var subId = parseInt(splitted[1]);
                        console.log(roomId + "_" + type + "_" + subId);
                        if (isNaN(type)) {
                            if (type !== "ups") type = type + "s";
                            var obj = room[type].filter(function (el) {
                                return el.id === subId;
                            })[0];
                            if (obj !== undefined) setLiveDataForObject(obj, response.data[i][j]);
                        }
                    }
                }
            }, function (error) {

            });
        };

        var setLiveDataForObject = function (obj, info) {
            for (var i in info) {
                if (i.indexOf("_") === -1) {
                    var dv = [];
                    if (obj.driverValues !== "") {
                        dv = obj.driverValues.filter(function (el) {
                            return el.uniqueId === parseInt(i)
                        });
                    }
                    if (dv && dv.length) {
                        // Test for ModbusTCP Function Code Read Coil value (CGLT-901)
                        if (dv[0].driver.driverType == 5 && dv[0].driver.registerValueType == 1 &&
                            dv[0].driver.inMin == 0 && dv[0].driver.inMax == 0 &&
                            dv[0].driver.outMin == 0 && dv[0].driver.outMax == 1000) {
                            dv[0].value = parseFloat(info[i]);
                        } else {
                            getValueForDV(dv[0], info[i]);
                        }
                    }
                    dv = null;
                } else {
                    var ti = i.split("_");
                    var type = ti[0];
                    var id = parseInt(ti[1]);
                    var oo = obj[type + "s"].filter(function (el) {
                        return el.id == id;
                    });
                    if (oo && oo.length) setLiveDataForObject(oo[0], info[i]);
                    oo = ti = type = id = null;
                }
            }
            if (obj instanceof Cooling && obj.isStulz()) {
                // detect features
                OIDService.setupStulzInfo(obj);
                OIDService.setupHiddenParams(obj);
            } else {
                for (var i in obj.driverValues) {
                    obj.driverValues[i].display = true;
                }
            }
        };

        var getValueForDV = function (dv, val) {
            if (isNaN(val)) {
                dv.value = val;
                return;
            }
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000000) {
                dv.value = val;
                return;
            }//PINERR_NOTEXISTS    @ PFAD f?r pinerrors sweb/lib/libDigitronic/libDigiweb/src/WebBus/WebBus.h ---> ab Zeile 57
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000008) {
                dv.value = val;
                return;
            }//PINERR_NOTREADY
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000007) {
                dv.value = val;
                return;
            }//PINERR_CALI
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000001) {
                dv.value = val;
                return;
            }//PINERR_CALI_O
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000002) {
                dv.value = val;
                return;
            }//PINERR_CALI_P
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000004) {
                dv.value = val;
                return;
            }//PINERR_CALI_N
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000010) {
                dv.value = val;
                return;
            }//PINERR_OPEN
            if (((val & 0xFFFFFFC0) >>> 0) == 0x80000020) {
                dv.value = val;
                return;
            }//PINERR_SHORT
            if (dv.physicalType == 0) {
                dv.value = parseFloat(val) / 1000;
            } else {
                dv.value = parseFloat(val) / 1000;
            }
        };

        var getMockedValueForDV = function (dv) {
            switch (dv.physicalType) {
                case 1:
                    return Math.random() * 100;
                case 2:
                    return Math.random() * 100;
                case 3:
                    return Math.random() * 4000;
                case 4:
                    return Math.random() * 100;
                case 5:
                    return Math.random() * 20;
                case 8:
                    return 230 + Math.random() * 20 * Math.random() < 0.5 ? -1 : 1;
                case 9:
                    return 2 * Math.random();
                case 10:
                    return 320 + Math.random() * 20;
                case 11:
                    return 50 * Math.random();
                case 12:
                    return Math.random() * 2000;
                case 14:
                    return Math.random() * 100;
                case 15:
                    return Math.random();
                case 16:
                    return Math.random() * 10;
                case 17:
                    return Math.random() * 5;
                case 18:
                    return Math.random() * 60;
                case 19:
                    return Math.random() * 12;
                case 6:
                    if (dv.parameter.oidDataPoint != null) {
                        var idx = Math.floor(dv.parameter.oidDataPoint.values.length * Math.random());
                        return parseInt(dv.parameter.oidDataPoint.values[idx].value);
                    } else {
                        return Math.random() < 0.5 ? 0 : 1;
                    }
                    break;
                default:
                    return 1.5;
            }
            console.log(dv);
        };

        var getRoomDataForLocation = function (locationId, physType) {
            return $http({
                url: 'api/livedata/tableView/location/' + locationId,
                method: 'GET',
                params: {physType: physType}
            })
        };

        var getEntityDataforLocation = function (locationId, physType) {
            return $http({
                url: 'api/livedata/tableView/all/' + locationId,
                method: 'GET',
                params: {physType: physType}
            })
        };

        var getEntityDataforRoom = function (roomId, physType, entityType) {
            return $http({
                url: 'api/livedata/tableView/room/' + roomId,
                method: 'GET',
                params: {physType: physType, entityType: entityType}
            })
        };

        var getEntitiesforRoom = function (roomId, physType, entityTypeArray) {
            if (!entityTypeArray instanceof Array) return;
            return $http({
                url: 'api/livedata/tableView/room/' + roomId,
                method: 'POST',
                data: entityTypeArray,
                params: {physType: physType}
            })
        };

        var saveLiveDataFavourite = function (userId, roomId, entityId, entityType) {
            return $http.post('api/livedata/favourite', {
                userId: userId.toString(),
                roomId: roomId.toString(),
                entityId: entityId.toString(),
                entityType: entityType
            }).then(function (response) {
                return response;
            }, function (error) {
                $log.error('Couldn\'t retrieve license!', error);
                return error;
            });
        };

        var getSlotViewForRacks = function (rackUniqueId, physType) {
            return $http({
                url: 'api/livedata/tableView/rack/' + rackUniqueId,
                method: 'GET',
                params: {physType: physType}
            });
        };

        var getSlotViewForSlots = function (slotUniqueId, physType) {
            return $http({
                url: 'api/livedata/tableView/slot/' + slotUniqueId,
                method: 'GET',
                params: {physType: physType}
            });
        };

        var getLiveDataForRoom = function (room, canceller) {
            return $http.get("/api/livedata/room/" + room.id, {timeout: canceller}).then(function (response) {
                var keys = Object.keys(response.data);
                var data = {};
                if (keys.length === 1 && parseInt(keys[0].split("_")[1]) === room.id) data = response.data[keys[0]];

                for (var i in data) {
                    var ti = i.split("_");
                    var type = ti[0];
                    if (isNaN(type)) {
                        var id = parseInt(ti[1]);
                        var value = data[i];
                        if (type === "coolingobject" || type === "floortile") type = "floorTile";
                        if (type !== "ups") type = type + "s";

                        var obj;
                        if (room[type] !== undefined) {
                            obj = room[type].filter(function (el) {
                                return el.id === id;
                            })[0];
                        }
                        if (obj !== null && obj !== undefined) setLiveDataForObject(obj, value);
                        value = null;
                        id = null;
                        obj = null;
                    }
                    type = null;
                    ti = null;
                }
                data = null;
                keys = null;
            }, function (error) {
                console.log(error);
            });
        };

        var getLiveDataForBuilding = function (buildingId, userId, canceller) {
            return $http.get("/api/livedata/buildingId/" + buildingId + "/" + userId, {timeout: canceller}).then(function (response) {
                return response;
            }, function (error) {
                console.log("Couldn't load Livedata: ", error);
            });
        };

        var getLiveDataForRack = function (rackId) {
            return $http.get("/api/livedata/entity", {type: "RACK", id: rackId});
        };

        var getLiveDataForEntity = function (room, object) {
            var type = EMSConstants.constants.EntityType[object.constructor.name.toUpperCase()];
            return $http.post("/api/livedata/entity", {
                type: EMSConstants.constants.EntityType[object.constructor.name.toUpperCase()],
                roomId: room.id,
                id: object.id
            }).then(function (response) {
                var keysR = Object.keys(response.data);
                if (keysR.length === 0) {
                    if (object instanceof Cooling && object.isStulz()) {
                        OIDService.setupStulzInfo(object);
                        OIDService.setupHiddenParams(object);
                    }
                    return null;
                }
                var roomId = parseInt(keysR[0].split("_")[1]);
                var keysE = Object.keys(response.data[keysR[0]]);
                var eId = parseInt(keysE[0].split("_")[1]);
                if (object.roomId !== roomId || object.id !== eId) return null;
                setLiveDataForObject(object, response.data[keysR][keysE]);
            }, function (error) {
                $log.error("Error retrieving live data for " + object.constructor.name + " with id " + object.id + "\n" + error.data);
                return error;
            });
        };

        var getLiveDataForOverview = function (locationId) {
            return $http.get("/api/livedata/overview/" + locationId).then(function (response) {

                for (var i in response.data) {
                    if (!response.data.hasOwnProperty(i)) continue;
                    var minTemp = Infinity;
                    var maxTemp = -Infinity;
                    var avgTemp = null;
                    var totalConsumption = null;
                    var totalPower = null;
                    var alarm = -1;

                    var roomCount = 0;
                    for (var j in response.data[i].rooms) {
                        if (!response.data[i].rooms.hasOwnProperty(j)) continue;
                        if (response.data[i].rooms[j].minTemp !== null && response.data[i].rooms[j].minTemp < minTemp) minTemp = response.data[i].rooms[j].minTemp;
                        if (response.data[i].rooms[j].maxTemp !== null && response.data[i].rooms[j].maxTemp > maxTemp) maxTemp = response.data[i].rooms[j].maxTemp;
                        if (response.data[i].rooms[j].avgTemp !== null && response.data[i].rooms[j].avgTemp !== undefined) {
                            roomCount++;
                            avgTemp += response.data[i].rooms[j].avgTemp;
                        }
                        if (response.data[i].rooms[j].totalConsumption !== null && response.data[i].rooms[j].totalConsumption !== undefined) totalConsumption += response.data[i].rooms[j].totalConsumption;
                        if (response.data[i].rooms[j].totalPower !== null && response.data[i].rooms[j].totalPower !== undefined) totalPower += response.data[i].rooms[j].totalPower;
                        if (response.data[i].rooms[j].alarm >= alarm) alarm = response.data[i].rooms[j].alarm;
                    }
                    if (avgTemp !== null) avgTemp /= roomCount;

                    response.data[i].minTemp = minTemp;
                    response.data[i].maxTemp = maxTemp;
                    response.data[i].avgTemp = avgTemp;
                    response.data[i].totalConsumption = totalConsumption;
                    response.data[i].totalPower = totalPower;
                    response.data[i].alarm = alarm;

                }
                return response;
            }, function (error) {
                $log.error("Error getting livedata for overview " + locationId + "\n" + error.data);
            });
        };

        //TODO needs implementation of worker.js, might be better way to get data since its not blocking the main thread
        var getLiveDataForObjectAsync = function (obj, callback, callbackError) {
            asyncWorkerHandle = WebWorkerService.startWorker("/scripts/components/livedata/worker.js", {
                initMsg: {dataFor: obj},
                messageHandler: function (event) {
                    callback(event.data);
                },
                errorHandler: function (errorEvent) {
                    if (callbackError) {
                        callbackError(errorEvent);
                    } else {
                        console.error(errorEvent);
                    }
                }
            });
        };

        var stopAsyncLiveDataRequest = function () {
            WebWorkerService.killWorker(asyncWorkerHandle);
        };

        var convertTemp = function (value, tempType, precision) {
            switch (tempType) {
                case "0":
                    return value.toFixed(3) + " °C";
                case "1":
                    return ((value * 9 / 5) + 32).toFixed(3) + " °F";
                case "2":
                    return (value + 273.15).toFixed(3) + " °K";
            }
        };

        var convertTempCelsius = function (value, precision) {
            return value.toFixed(3) + " °C";
        };

        var convertTempKelvin = function (value, precision) {
            return (value + 273.15).toFixed(3) + " °K";
        };

        var convertTempFahrenheit = function (value, precision) {
            return ((value * 9 / 5) + 32).toFixed(3) + " °F";
        };

        var getTempConverter = function (tempType) {
            if (tempType === "0") return convertTempCelsius;
            if (tempType === "1") return convertTempFahrenheit;
            if (tempType === "2") return convertTempKelvin;
        };

        var getMappedMeaning = function (dv) {
            var meaning = dv.parameter.parameterValues.filter(function (mm) {
                return mm.value == dv.value;
            })[0];
            return meaning !== undefined ? meaning.description : dv.value;
        };

        var modDVValue = function (dv, user) {
            if (dv.value === undefined || dv.value === null) return $translate.instant("room.driver.noValue");
            if (dv.value < -1000000) return $translate.instant("room.driver.errValue");
            if (dv.physicalType == 6) {
                return getMappedMeaning(dv);
            } else {
                if (dv.physicalType >= 0 && dv.physicalType <= 19) {
                    if (dv.parameter.uniqueLabel !== null && dv.parameter.uniqueLabel !== undefined) {
                        return dv.value.toFixed(3) + " " + dv.parameter.uniqueLabel;
                    }
                    if (dv.physicalType == 1) {
                        var tempSettings = user.settings.filter(function (settings) {
                            return settings.key === "temp";
                        });
                        return convertTemp(dv.value, Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0", 1);
                        if (dv.parameter.uniqueLabel !== null && dv.parameter.uniqueLabel !== undefined) {
                            return dv.value.toFixed(3) + " " + dv.parameter.uniqueLabel;
                        }
                        else {
                            return convertTemp(dv.value, Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0", 1);
                        }
                    }
                    else {
                        return dv.value.toFixed(3) + " " + physicalTypes.filter(function (e) {return e.id == dv.physicalType;})[0].physicalType;
                    }
                }
            }
        };

        var queryLiveDataForWidget = function (datapointId) {
            return $http.get('api/livedata/list/drivervalue/' + datapointId);
        };

        return {
            getLiveDataForLocation: getLiveDataForLocation,
            getSlotViewForRacks: getSlotViewForRacks,
            getRoomDataForLocation: getRoomDataForLocation,
            getSlotViewForSlots: getSlotViewForSlots,
            getEntityDataforRoom: getEntityDataforRoom,
            getLiveDataForRoom: getLiveDataForRoom,
            getLiveDataForRack: getLiveDataForRack,
            getLiveDataForEntity: getLiveDataForEntity,
            getLiveDataForOverview: getLiveDataForOverview,
            convertTemp: convertTemp,
            getTempConverter: getTempConverter,
            getMappedMeaning: getMappedMeaning,
            modDVValue: modDVValue,
            getEntityDataforLocation: getEntityDataforLocation,
            getEntitiesforRoom: getEntitiesforRoom,
            getValueForDV: getValueForDV,
            getLiveDataForBuilding: getLiveDataForBuilding,
            getPysTypesForEntity: getPysTypesForEntity,
            saveLiveDataFavourite: saveLiveDataFavourite,
            queryLiveDataForWidget: queryLiveDataForWidget
        };
    });
})();
