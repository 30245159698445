(function () {

    'use strict';

    function widgetRackController($scope, $log, $uibModal, $translate, $interval, WidgetRacksService, WidgetData, Location, Notify, $state) {
        var ctrl = this;
        var modalInstance;
        var updateInterval;
        ctrl.chosenDevice = null;
        ctrl.mac = null;
        ctrl.roomId = null;
        ctrl.availableDevices = null;
        ctrl.availableRooms = null;
        ctrl.chosenRoom = null;
        ctrl.maxAvailableHu = 0;
        ctrl.usedHu = 0;
        ctrl.plannedAvailableHu = 0;
        ctrl.plannedRacks = 0;
        ctrl.alertText = "";
        ctrl.rackData = null;
        ctrl.huPerPlannedRack = 42;
        ctrl.chosenLocation = null;
        ctrl.availableLocations = [];
        ctrl.state = $state.current.name;

        ctrl.$onInit = function () {
            // Set config method in parent
            $scope.$parent.setConfigMethod(_showOptions);
            _loadSettings();

            Location.queryCompact().then(function (response) {
                if (response.data !== null && response.data.length > 0) {
                    ctrl.availableLocations = response.data;
                    for (var j = 0; j < ctrl.availableLocations.length; j++) {
                        if (ctrl.availableLocations[j].id === ctrl.locationId) {
                            ctrl.chosenLocation = ctrl.availableLocations[j];
                        }
                    }
                    if (ctrl.plannedRacks !== null && ctrl.roomId !== null && ctrl.locationId !== null && ctrl.huPerPlannedRack !== null) {
                        if(_getValidRoomsForLoading() !== null){
                            _getValidRoomsForLoading().then(function (response) {
                                if (response !== null && response.data.length > 0) {
                                    ctrl.availableRooms = response.data;
                                    for (var j = 0; j < ctrl.availableRooms.length; j++) {
                                        if (ctrl.availableRooms[j].roomId === ctrl.roomId) {
                                            ctrl.chosenRoom = ctrl.availableRooms[j];
                                        }
                                    }
                                    if (ctrl.chosenRoom === null) {
                                        return;
                                    }
                                    ctrl.getRackData();
                                }
                            });
                        }
                    }
                }
            });


            if (!ctrl.isreportwidget) {
                updateInterval = $interval(function () {
                    ctrl.getRackData();
                }, 600000);
            }
            //Initial call so that the time will be displayed immediately
            ctrl.getRackData();
        };

        ctrl.$onDestroy = function () {
            if (updateInterval) {
                $interval.cancel(updateInterval);
            }
        };
        ctrl.typeChanged = function () {
            ctrl.chosenRoom = null;

            Location.queryCompact().then(function (response) {
                if (response !== null && response.data !== null && response.data.length > 0) {
                    ctrl.availableLocations = response.data;
                }
            });
        };

        ctrl.saveSettings = function () {
            var newSettings = [];
            if(ctrl.mac !== null) newSettings.push({key: "mac", value: ctrl.mac});
            newSettings.push({key: "roomId", value: ctrl.roomId});
            newSettings.push({key: "plannedRacks", value: ctrl.plannedRacks});
            newSettings.push({key: "huPerPlannedRack", value: ctrl.huPerPlannedRack});
            newSettings.push({key: "locationId", value: ctrl.locationId});
            $scope.widget.settings = newSettings;
            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.energyusage.saveErrorMsg", 2000);
                });
            }
            ctrl.closeModal();
        };

        function _loadSettings() {
            $log.debug("Loaded widget", $scope.widget);
            // ctrl.chosenRoom = $scope.$parent.getRoomBySettings($scope.widget.settings,  ctrl.listOfRooms);
            if ($scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    if ($scope.widget.settings[i].key === 'locationId') {
                        $log.debug('Racks loading locationId:', $scope.widget.settings[i].value);
                        ctrl.locationId = parseInt($scope.widget.settings[i].value);
                    }
                    if ($scope.widget.settings[i].key === 'plannedRacks') {
                        $log.debug('Racks loading plannedRacks:', $scope.widget.settings[i].value);
                        ctrl.plannedRacks = parseInt($scope.widget.settings[i].value);
                    }
                    if ($scope.widget.settings[i].key === 'roomId') {
                        $log.debug('Racks loading room:', $scope.widget.settings[i].value);
                        ctrl.roomId = parseInt($scope.widget.settings[i].value);
                    }
                    if ($scope.widget.settings[i].key === 'mac') {
                        $log.debug('Racks loading mac:', $scope.widget.settings[i].value);
                        ctrl.mac = parseInt($scope.widget.settings[i].value);
                    }
                    if ($scope.widget.settings[i].key === 'huPerPlannedRack') {
                        $log.debug('Racks loading HU:', $scope.widget.settings[i].value);
                        ctrl.huPerPlannedRack = parseInt($scope.widget.settings[i].value);
                    }
                }
            }
            else {
                $log.debug('settings null');
            }
            return null;
        }

        ctrl.closeModal = function () {
            if (modalInstance !== null) {
                modalInstance.close();
            }
        };

        function _showOptions() {
            _loadSettings();
            $log.debug("Opening modal!");
            modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/racks/widget.racks.settings.modal.html',
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }

        ctrl.getValidRooms = function () {

            ctrl.availableRooms = null;
            ctrl.chosenRoom = null;
            ctrl.rackData = null;

            if (ctrl.chosenLocation !== null && angular.isDefined(ctrl.chosenLocation)) {
                ctrl.locationId = ctrl.chosenLocation.id;
                WidgetRacksService.getAvailableRoomsForLocation(ctrl.locationId).then(function (response) {
                    if (response.data !== null && response.data.length > 0) {
                        _removeAlert();
                        ctrl.availableRooms = response.data;
                    }
                    else {
                        _addAlert();
                    }
                });
            }
        };

        function _getValidRoomsForLoading() {
            if (ctrl.chosenLocation !== null && angular.isDefined(ctrl.chosenLocation)) {
                ctrl.locationId = ctrl.chosenLocation.id;
                return WidgetRacksService.getAvailableRoomsForLocation(ctrl.locationId);
            } else{
                return null;
            }
        }

        ctrl.getRackData = function () {
            if (ctrl.chosenRoom !== null && angular.isDefined(ctrl.chosenRoom)) {
                ctrl.roomId = ctrl.chosenRoom.roomId;
                WidgetRacksService.getLocationRacksForRoom(ctrl.roomId).then(function (response) {
                    if (response !== null && angular.isDefined(response)) {
                        ctrl.rackData = response.data;
                        ctrl.plannedAvailableHu = /*ctrl.rackData.availableHu +*/ (ctrl.plannedRacks * ctrl.huPerPlannedRack);
                    }
                });
            }
        };

        ctrl.isNumber = function (value) {
            return !isNaN(parseFloat(value)) && isFinite(value);
        };

        function _addAlert() {
            ctrl.alertText = "dashboard.widget.racks.modal.noRoomsFound";
        }

        function _removeAlert() {
            ctrl.alertText = "";
        }
    }

    angular.module('emsv2App')
        .controller('WidgetRackController', widgetRackController)
})();


