(function () {
    'use strict';
    angular.module('emsv2App').service('ErrorConfig', function ($http, SmartEventService) {
        return {
            'getErrorConfig': function (locationId) {
                return $http.get('api/locations/errorConfig/getErrorConfigurationForLocation/' + locationId).then(function (response) {
                    return response.data;
                });
            },
            'getErrorConfigOptions': function (deviceId) {
                return $http.get('api/locations/errorConfig/getErrorConfigurationOptionsForDevice/' + deviceId).then(function (response) {
                    return response.data;
                });
            },
            'getErrorChildOptions': function (locationId, errorConfiguration) {
                return $http.get('api/locations/errorConfig/getPossibleChildren/' + errorConfiguration.deviceMac).then(function (response) {
                    var arr = response.data;
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i].id == errorConfiguration.id) {
                            arr.splice(i, 1);
                            i--;
                            continue;
                        }
                        for (var k = 0; k < arr[i].errorConfigurationChildren.length; k++) {
                            if (arr[i].errorConfigurationChildren[k].childId == errorConfiguration.id) {
                                arr.splice(i, 1);
                                i--;
                                break;
                            }
                        }
                    }
                    return arr;
                });
            },
            'getErrorConditionPeriodTypes':function () {
                return $http.get('api/locations/errorConfig/getErrorConditionPeriodTypes').then(function (response) {
                    return response.data;
                });
            },
            'saveErrorConfig': function (obj) {
                return $http.post('api/locations/errorConfig/save', obj).then(function (response) {
                    return response.data;
                });
            },
            'getErrorConfigById': function (id) {
                return $http.get('api/locations/errorConfig/getErrorConfig/' + id).then(function (response) {
                    return response.data;
                });
            },
            'deleteErrorConfiguration': function (id) {
                return $http.delete('api/locations/errorConfig/deleteErrorConfiguration/' + id);
            },
            'deleteErrorCondition': function (id) {
                return $http.delete('api/locations/errorConfig/deleteErrorCondition/' + id);
            },
            'deleteErrorConfigurationRelation': function (id) {
                return $http.delete('api/locations/errorConfig/deleteErrorConfigurationRelation/' + id);
            }
        };
    });
})();
