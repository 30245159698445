(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name contactsDelete
     * @requires $log, $timeout, $state, Contacts
     * @param {Object} currentContact - The contact to delete
     * @param {Object[]} countries - List of available countries
     */
    angular.module('emsv2App')
        .component('contactsDelete', {
            bindings: {
                currentContact: '=',
                countries: '='
            },
            templateUrl: 'scripts/app/contacts/delete/contacts-delete.component.html',
            controller: ['$log', '$timeout', '$state', 'Contacts', 'Notify',
                function ($log, $timeout, $state, Contacts, Notify) {
                    var ctrl = this;

                    ctrl.$onInit = function () {
                        if (!ctrl.currentContact.single) {
                            $state.go('contacts');
                        }
                    };

                    ctrl.deleteContact = function () {
                        return Contacts.delete(ctrl.currentContact.id).then(function () {
                            Notify.success("global.notification.success.title", "contacts.notification.deleted", 2000);
                            $state.go('contacts');
                        }, function (error) {
                            Notify.error("global.notification.error.title", "contacts.notification.cantdelete", 3000);
                            $log.error('Couldn\'t delete contact ' + ctrl.currentContact.id, error);
                        });
                    };
                }]
        });

})();
