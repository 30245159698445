(function () {
    'use strict';

    angular.module('emsv2App').controller("WebGLControlsController", function ($scope, WebGLService, Principal, GFXSettingsService, MessageService) {
        var glctrl = this;

        var viewObject = null;

        var loadUserInfo = function (callback) {
            Principal.identity().then(function (acc) {
                glctrl.account = acc;
                if (callback) callback();
            }, function (error) {
                $log.error("Could not retrieve user information!\n" + error);
            });
        };

        loadUserInfo();

        glctrl.isCam3D = function () {
            return WebGLService.getActiveCamera() instanceof THREE.PerspectiveCamera;
        };

        glctrl.showSettingsPanel = false;
        glctrl.showHeatmapPanel = false;
        glctrl.showVisibilityPanel = false;
        glctrl.allowHeatMapButton = false;
        glctrl.allowRackScaleButton = false;

        glctrl.toggleGFXSettings = function () {
            glctrl.showSettingsPanel = !glctrl.showSettingsPanel;
        };

        glctrl.toggleHeatmapPanel = function () {
            glctrl.showHeatmapPanel = !glctrl.showHeatmapPanel;
        };

        glctrl.toggleVisibilityPanel = function () {
            glctrl.showVisibilityPanel = !glctrl.showVisibilityPanel;
        };

        glctrl.toggleRackScale = function () {

        };

        glctrl.camLeft = function () {
            WebGLService.camLeft();
        };
        glctrl.camRight = function () {
            WebGLService.camRight();
        };
        glctrl.camForward = function () {
            WebGLService.camForward();
        };
        glctrl.camBackward = function () {
            WebGLService.camBackward();
        };
        glctrl.camCenter = function () {
            WebGLService.camCenter();
        };

        // overloaded functions to handle 2d rotate and 3d up/down
        glctrl.handleMoveUpClick = function () {
            if (!glctrl.isCam3D()) {
                WebGLService.getControls().rotateLeft(Math.PI / 2);
            }
        };
        glctrl.handleMoveDownClick = function () {
            if (!glctrl.isCam3D()) {
                WebGLService.getControls().rotateLeft(Math.PI / -2);
            }
        };

        glctrl.show3D = function () {
            if (!glctrl.isCam3D()) {
                WebGLService.switch3D();
                WebGLService.enableControlsRotate();
            }
        };

        glctrl.show2D = function () {
            if (glctrl.isCam3D()) {
                WebGLService.switch2D();
                // WebGLService.disableControlsRotate();
            }
        };

        $scope.$watch("glctrl.account.graphics", function (n, o) {
            if (n !== undefined && n !== null && o !== undefined) GFXSettingsService.setSettingsForUser(glctrl.account.login, glctrl.account.graphics);
        }, true);

        glctrl.handleAAChange = function () {
            WebGLService.setAntialias(glctrl.account.graphics.aa);
        };

        glctrl.handleZoomChange = function () {
            WebGLService.setZoomSpeed(glctrl.account.graphics.zoom);
        };

        glctrl.handleDisplayModeChange = function () {
            MessageService.publish("user:changeDisplayMode", glctrl.account.graphics.displayMode);
        };

        MessageService.subscribe("client:showObject", function (msg, opts) {
            viewObject = opts;
            if (opts == "room") {
                glctrl.allowHeatMapButton = true;
                glctrl.allowRackScaleButton = false;
            }
            if (opts == "rack") {
                glctrl.allowRackScaleButton = true;
                glctrl.allowHeatMapButton = false;
            }
        });

    });

})();
