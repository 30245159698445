(function () {

    'use strict';

    function WidgetDateController($scope, $log, $element, $uibModal, $http, WidgetData, Language, Notify) {
        var ctrl = this;
        var modalInstance;
        var InitFavs;
        ctrl.isSaved = false;
        ctrl.sortCol = 1;
        ctrl.sortColImpl = "name";
        ctrl.newFav = {};
        ctrl.favourites = [];

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(_showOptions);
            _loadSettings();
            Language.getCurrent().then(function (response) {
                ctrl.locale = response;
            });
        };

        ctrl.saveSettings = function () {
            var newSettings = [];

            newSettings.push({key: 'favourites', value: JSON.stringify(ctrl.favourites)});
            $scope.widget.settings = newSettings;
            $log.debug("Saving Settings:", $scope.widget.settings);

            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    ctrl.closeModal();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.favourites.saveErrorMsg", 2000);
                });
            }
            ctrl.isSaved = true;
            modalInstance.close();
        };

        function _loadSettings() {
            if ($scope.widget.settings !== null) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    if ($scope.widget.settings[i].key === 'favourites') {
                        $log.debug('Loading favourites settings', $scope.widget.settings[i].value);
                        ctrl.favourites = JSON.parse($scope.widget.settings[i].value);
                    }
                }
            } else {
                $log.error('Couln\'t load favourites widget settings!');
            }
            return null;
        }

        ctrl.addFav = function (newFav) {
            var favourite = angular.copy(newFav);
            if (ctrl.favourites.length < 5) {
                ctrl.favourites.push(favourite);
                ctrl.newFav = {};
            } else {
                Notify.error("global.notification.error.title", "dashboard.widget.favourites.favLimitReached", 2000);
            }
        };

        // ctrl.removeFav = function (favourite) {
        //     ctrl.favourites.splice(data.indexOf(favourite), 1)
        // };


        ctrl.newTab = function (type) {
            if (type) return "_blank";
        }

        ctrl.sortCols = function (col) {
            if (Math.abs(ctrl.sortCol) == col) {
                ctrl.sortCol *= -1;
            } else {
                ctrl.sortCol = col;
            }
            switch (Math.abs(ctrl.sortCol)) {
                case 1:
                    ctrl.sortColImpl = ctrl.sortCol < 0 ? "-name" : "name";
                    break;
                case 2:
                    ctrl.sortColImpl = ctrl.sortCol < 0 ? "-link" : "link";
                    break;
                case 3:
                    ctrl.sortColImpl = ctrl.sortCol < 0 ? "-type" : "type";
                    break;
            }
        };

        ctrl.closeModal = function () {
            if(!ctrl.isSaved){
                if(ctrl.favourites.length !== InitFavs.length){
                    ctrl.favourites = InitFavs;
                }
            }
            modalInstance.close();
        };

        function _showOptions() {
            InitFavs = angular.copy(ctrl.favourites);
            ctrl.isSaved = false;
            modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/favourites/widget.favourites.settings.modal.html',
                animation: false,
                showClose: false,
                backdrop: 'static',
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }
    }

    angular.module('emsv2App')
        .controller('WidgetFavouritesController', WidgetDateController);

})();
