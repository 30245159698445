(function () {
    'use strict';

    var getParts = ['$log', '$stateParams', 'PartlibService', function ($log, $stateParams, PartlibService) {
        return PartlibService.getAllParts().then(function (data) {
            return data;
        }, function (error) {
            $log.error("Error retrieving parts from Partlibrary\n" + error.data);
        })
    }];

    var getPart = ['$log', '$state', '$stateParams', '$q', 'PartlibService', function ($log, $state, $stateParams, $q, PartlibService) {
        if ($stateParams.create === "") return $q.when(null);
        var type = $stateParams.viewType ? $stateParams.viewType : $stateParams.editType;
        return PartlibService.getPartLibInfoByTypeAndId(type, $stateParams.id).then(function (response) {
            if (response === undefined || response === null) {
                $state.go("notfound");
            }
            else {
                return response;
            }
        }, function (error) {
            $log.error("Error retrieving part of type: " + type + " with id: " + $stateParams.id + "\n" + error);
            return error;
        });
    }];

    var getUser = ['$state', '$log', 'Principal', 'License', function ($state, $log, Principal, License) {
        return Principal.identity().then(function (user) {
            $log.debug('Loaded userInfo: ', user);
            if (user.hasRole('ROLE_USER') && user.hasRole('ROLE_LOCATION_EDIT')) {
                License.get().then(function (response) {
                    var license = response.data;
                    if (isLicensed(license, '3droomedit')) {
                        return user;
                    }
                    else {
                        $state.go("accessdenied");
                    }
                }, function (error) {
                    $log.error('Couldn\'t load license!', error);
                });
            }
            else {
                $state.go("accessdenied");
            }
        }, function (error) {
            $state.go("notfound");
            $log.error("Could not get current user information\n" + error);
        });
    }];

    var isLicensed = function (license, module) {
        if (license !== null
            && license !== undefined
            && license.data !== null
            && license.data !== undefined) {
            if (module.indexOf('*') !== -1) {
                var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                var keys = Object.keys(license.data);
                for (var i in keys) {
                    if (regex.test(keys[i]) && keys[i]) return true;
                }
            }
            else if (license.data[module] !== null) {
                return license.data[module];
            }
        }
        return false;
    };

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('partlib', {
                    parent: 'admin',
                    url: '/partlib?objectType',
                    data: {
                        roles: ['ROLE_USER','ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'partlib.head.title',
                        license: '3droomedit'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/partlib/partlib.view.html',
                            controller: 'PartlibController',
                            controllerAs: "ctrl"
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('partlib');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        parts: getParts,
                        user: getUser
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('partlib.edit', {
                    parent: 'partlib',
                    url: '/:editType/:id',
                    data: {
                        roles: ['ROLE_USER', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'partlib.head.edit'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/partlib/edit/partlib.edit.html',
                            controller: 'PartlibEditController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('partlib');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        part: getPart,
                        user: getUser
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('partlib.create', {
                    parent: 'partlib',
                    url: '/:create',
                    data: {
                        roles: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_EDIT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'partlib.head.create',
                        edit: true,
                        create: true
                    },
                    params: {
                        type: null
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/partlib/edit/partlib.edit.html',
                            controller: 'PartlibEditController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('partlib');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        part: getPart,
                        user: getUser
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });
})();
