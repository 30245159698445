(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetstulzcooling', function () {
        return {
            restrict: 'E',
            controller: 'WidgetStulzCoolingController',
            controllerAs: 'scctrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/stulzcooling/widget.stulzcooling.template.html'
        };
    });
})();
