(function () {
    'use strict';

    /**
     * @description Controller to handle dialog functionality
     */
    angular.module('emsv2App').controller('GenDialogController', function ($compile, $scope, $sanitize, MessageService) {
        var ctrl = this;

        ctrl.config = {
            headText: "",
            headIcon: "",
            messageText: "",
            showButton0: false,
            showButton1: false,
            showButton2: false,
            showClose: false,
            textButton0: "",
            textButton1: "",
            textButton2: ""
        };

        ctrl.handleButton0 = function () {
            if (callbackButton0 !== null) callbackButton0();
        };
        ctrl.handleButton1 = function () {
            if (callbackButton1 !== null) callbackButton1();
        };
        ctrl.handleButton2 = function () {
            if (callbackButton2 !== null) callbackButton2();
        };

        var callbackButton0 = null;
        var callbackButton1 = null;
        var callbackButton2 = null;

        // set initial state when for dialog
        MessageService.subscribe("configGenDialog", function (msg, opts) {
            ctrl.config.showClose = opts.showClose;
            ctrl.config.headText = opts.headText;
            ctrl.config.headIcon = opts.headIcon;
            ctrl.config.messageText = opts.messageText;

            if (opts.callbackButton0) {
                callbackButton0 = opts.callbackButton0;
                ctrl.config.showButton0 = true;
                ctrl.config.textButton0 = opts.textButton0;
                ctrl.config.classButton0 = opts.classButton0;
                ctrl.config.iconButton0 = opts.iconButton0;
            }
            else {
                ctrl.config.showButton0 = false;
                callbackButton0 = null;
                ctrl.config.textButton0 = "";
                ctrl.config.classButton0 = "";
                ctrl.config.iconButton0 = "";
            }
            if (opts.callbackButton1) {
                callbackButton1 = opts.callbackButton1;
                ctrl.config.showButton1 = true;
                ctrl.config.textButton1 = opts.textButton1;
                ctrl.config.classButton1 = opts.classButton1;
                ctrl.config.iconButton1 = opts.iconButton1;
            }
            else {
                ctrl.config.showButton1 = false;
                callbackButton1 = null;
                ctrl.config.textButton1 = "";
                ctrl.config.classButton1 = "";
                ctrl.config.iconButton1 = "";
            }
            if (opts.callbackButton2) {
                callbackButton2 = opts.callbackButton2;
                ctrl.config.showButton2 = true;
                ctrl.config.textButton2 = opts.textButton2;
                ctrl.config.classButton2 = opts.classButton2;
                ctrl.config.iconButton2 = opts.iconButton2;
            }
            else {
                ctrl.config.showButton2 = false;
                callbackButton2 = null;
                ctrl.config.textButton2 = "";
                ctrl.config.classButton2 = "";
                ctrl.config.iconButton2 = "";
            }
        });

    });
})();
