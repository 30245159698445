'use strict';

/**
 * @description Constructor to create new floor panel object
 * @param {Number} id the id to set
 * @param {Number} uniqueId the unique id to set
 * @param {Number} roomId the room id to set
 * @param {String[] | String} xNames array/string(comma-separated) of names to use for x-axis
 * @param {String[] | String} yNames array/string(comma-separated) of names to use for y-axis
 * @param {Number} xType type of names on x-axis
 * @param {Number} yType type of names on y-axis
 * @param {Boolean} xLower names lower case
 * @param {Boolean} yLower names lower case
 * @constructor
 */
function FloorPanel(id, uniqueId, roomId, xNames, yNames, xType, yType, xLower, yLower) {
    this.id = id !== undefined ? id : null;
    this.uniqueId = uniqueId !== undefined ? uniqueId : null;
    this.roomId = roomId !== undefined ? roomId : null;
    this.xNames = xNames !== undefined ? FloorPanel.parseNames(xNames) : [];
    this.yNames = yNames !== undefined ? FloorPanel.parseNames(yNames) : [];
    this.xType = xType !== undefined ? xType : 0;
    this.yType = yType !== undefined ? yType : 0;
    this.xLower = xLower !== undefined ? xLower : false;
    this.yLower = yLower !== undefined ? yLower : false;
};
FloorPanel.prototype = {};
FloorPanel.prototype.constructor = FloorPanel;

/**
 * @description Function to handle different types of name-objects provided
 * @param {String[] | String} names array/string(comma-separated) to use
 * @returns {*}
 */
FloorPanel.parseNames = function (names) {
    if (names instanceof Array) return names;
    if (names instanceof String) return names.split(",");
};
