(function () {
    'use strict';

    angular.module('emsv2App')
        .component('widgetalerts', {
            controller: 'WidgetAlarmController',
            controllerAs: '$ctrl',
            bindings: {
                index: '=',
                isreportwidget: '=',
                isprintwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/alarm/widget.alarm.template.html'
        });
})();
