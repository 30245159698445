'use strict';

/**
 * @description Constructor to create new error object
 * @param {Number} type the type of error to set
 * @param {Number} objectUID the unique id to set
 * @param {String} objectField the name of the erroneous field
 * @param {Object=} details optional field to add more information
 * @constructor
 */
var ErrorObject = function (code, name, field, id) {
    this.code = code;
    this.entityName = name;
    this.fieldName = field;
    this.uniqueId = id;
};
ErrorObject.prototype = {};
ErrorObject.prototype.constructor = ErrorObject;

ErrorObject.INVALID_GEOMETRY = 1;
ErrorObject.INVALID_GEOMETRY_CORNER_DUPLICATED = 2;
ErrorObject.INVALID_FIELD_VALUE = 3;
ErrorObject.INVALID_CONSTRAINT = 4;
