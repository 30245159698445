(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetpue', function () {
        return {
            restrict: 'E',
            controller: 'widgetPUEController',
            controllerAs: 'pueCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/pue/widget.pue.template.html'
        };
    });
})();
