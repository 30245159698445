(function () {

    'use strict';

    angular.module('emsv2App')
        .factory('Language', function ($q, $http, $translate, $window, LANGUAGES, Tools) {

            function _languageSupported(lang) {
                if (Tools.isDefinedNotNull(LANGUAGES)) {
                    for (var i = 0; i < LANGUAGES.length; i++) {
                        if (LANGUAGES[i] === lang) return true;
                    }
                }
                return false;
            }

            return {
                getCurrent: function () {
                    var deferred = $q.defer();
                    var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

                    if (angular.isUndefined(language)) {
                        // https://zzz.buzz/2016/01/13/detect-browser-language-in-javascript/
                        var lang = $window.navigator.userLanguage || $window.navigator.language;
                        if (Tools.isDefinedNotNull(lang)) {
                            if (lang.length > 2) {
                                lang = lang.substr(0, 2);
                            }
                            if (_languageSupported(lang)) {
                                language = lang;
                            }
                            else {
                                language = 'en';
                            }
                        }
                        else {
                            language = 'en';
                        }
                    }
                    $translate.storage().set('NG_TRANSLATE_LANG_KEY', language);
                    deferred.resolve(language);
                    return deferred.promise;
                },
                getAll: function () {
                    var deferred = $q.defer();
                    deferred.resolve(LANGUAGES);
                    return deferred.promise;
                }
            };
        })

        /*
         Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
         They are written in English to avoid character encoding issues (not a perfect solution)
         */
        .constant('LANGUAGES', [
                'en', 'de'
            ]
    // 'zh-cn' simplified chinese commented CGLT-1198
        );

})();
