/**
 * System Properties
 * @ngdoc service
 * @name SystemProps
 * @requires $http, $log, $q
 */
angular.module('emsv2App').service('SystemProps', ['$http', '$log', '$q', function ($http) {
    return {
        getDatabaseInfo: function () {
            return $http.get('api/systemProps/dbInfo');
        },
        getLivedataInfo: function () {
            return $http.get('api/systemProps/livedatainfo');
        },
        getServerTime: function () {
            return $http.get('api/systemProps/servertime');
        }
    }
}]);