(function () {
    'use strict';
    angular.module('emsv2App').service("HeatmapService", function ($http) {

        var getHeatmaps = function () {
            return $http.get("/api/heatmap/getall");
        };
        var getHeatmap = function (id) {
            return $http.get("/api/heatmap/" + id);
        };
        var saveHeatmap = function (map) {
            if (map.id === -1) {
                return $http.post("/api/heatmap", map);
            }
            else {
                return $http.put("/api/heatmap", map);
            }
        };
        var deleteHeatmap = function (map) {
            return $http.delete("/api/heatmap/" + map.id);
        };

        var createGradient = function (map, sizex, sizey) {
            var canvas = document.createElement("canvas");
            canvas.width = sizex;
            canvas.height = sizey;
            var ctx = canvas.getContext("2d");
            var grd = ctx.createLinearGradient(0, 0, sizex, 0);
            var min = Infinity;
            var max = -Infinity;

            for (var i = 0; i < map.heatmapValues.length; i++) {
                if (map.heatmapValues[i].key === null) continue;
                if (map.heatmapValues[i].key < min) min = map.heatmapValues[i].key;
                if (map.heatmapValues[i].key > max) max = map.heatmapValues[i].key;
            }

            for (var i = 0; i < map.heatmapValues.length; i++) {
                if (map.heatmapValues[i].key === null || min === max) {
                    continue;
                }
                grd.addColorStop((Math.floor(map.heatmapValues[i].key - min)) / Math.floor((max - min)), map.heatmapValues[i].color);
            }
            ctx.fillStyle = grd;
            ctx.fillRect(0, 0, sizex, sizey);
            ctx = null;
            grd = null;
            return [canvas, min, max];
        };

        var getColorForValue = function (canvas, min, max, value) {
            var tv = null;
            if (value - min < 0) {
                tv = 0;
            }
            else if (value >= max) {
                tv = 0.99;
            }
            else {
                tv = (value - min) / (max - min);
                if(tv > 0.99) tv = 0.99;
            }
            var ctx = canvas.getContext("2d");
            var pixel = Math.round(canvas.width * tv);
            var data = ctx.getImageData(pixel, 0, 1, 1).data;
            var ret = {r: data[0] / 255, g: data[1] / 255, b: data[2] / 255};
            data = null;
            ctx = null;
            return ret;
        };

        var generateFallbackHeatmaps = function(){
            return [
                generateFallbackTempHeatmap(),
                { id: -2, name: "Fallback Heatmap Humi", physicalTypeId: 2, physicalType:{physicalType: "°C"}, heatmapValues:[
                    {id: -1, key:0, color:"#0000ff"},
                    {id: -2, key:20, color: "#00ff00"},
                    {id: -3, key:30, color: "#ffff00"},
                    {id: -4, key:50, color: "#ffa000"},
                    {id: -5, key:100, color: "#ff0000"}
                ]}
            ];
        };

        var generateFallbackTempHeatmap = function() {
            return {id: -1, name: "Fallback Heatmap Temp", physicalTypeId: 1, physicalType:{physicalType: "°C"}, heatmapValues:[
                {id: -1, key:0, color:"#0000ff"},
                {id: -2, key:20, color: "#00ff00"},
                {id: -3, key:30, color: "#ffff00"},
                {id: -4, key:50, color: "#ffa000"},
                {id: -5, key:100, color: "#ff0000"}
            ]}
        };

        return {
            getHeatmaps: getHeatmaps,
            getHeatmap: getHeatmap,
            saveHeatmap: saveHeatmap,
            deleteHeatmap: deleteHeatmap,
            createGradient: createGradient,
            getColorForValue: getColorForValue,
            generateFallbackHeatmaps:generateFallbackHeatmaps,
            generateFallbackTempHeatmap:generateFallbackTempHeatmap
        }
    });
})();
