(function () {
    'use strict';

    function buildingDetailController($state, $scope, $window, $translate, selectedBuilding, $stateParams, $log, Notify, Location,
                                      LocationDetailService, Buildings, MessageService, devices, buildings,
                                      license, rooms, user, GenDialogService) {
        var ctrl = this;
        var initBuilding = null;
        var buildingSaved = false;
        ctrl.user = user;
        ctrl.license = license;
        ctrl.buildings = buildings;
        ctrl.devices = devices;
        ctrl.selectedBuilding = selectedBuilding;

        ctrl.allowRooms = license !== undefined && license.data['3droomedit'] === true;
        ctrl.allowEdit = (ctrl.user.roles.indexOf('ROLE_LOCATION_EDIT') !== -1);
        ctrl.editMode = ctrl.allowEdit && $stateParams.bmode === "edit";

        ctrl.$onInit = function () {
            if ($stateParams.bmode === "edit" && !ctrl.allowEdit) {
                return $state.go("accessdenied");
            }
            else {
                ctrl.selectedBuilding.edit = ctrl.editMode;
                LocationDetailService.reset();
                LocationDetailService.onBack = function () {
                    $state.go($state.current.parent, undefined, {reload: true});
                };
                if (ctrl.editMode) {
                    LocationDetailService.onSave = ctrl.save;
                    LocationDetailService.canSave = function () {
                        return ctrl.selectedBuilding.name !== '';
                    };
                }
                else {
                    if (ctrl.allowEdit) {
                        LocationDetailService.onEdit = function () {
                            $state.go('location.buildings.detail', {
                                bid: ctrl.selectedBuilding.id,
                                bmode: 'edit'
                            }, {reload: true});
                        };
                    }
                }
            }
            initBuilding = angular.copy(ctrl.selectedBuilding);
        };

        ctrl.compareBuildingValues = function (buildingA, buildingB) {

            if (buildingA["name"] !== buildingB["name"]) return true;
            if (buildingA["street"] !== buildingB["street"]) return true;
            if (buildingA["lat"] !== buildingB["lat"]) return true;
            if (buildingA["lon"] !== buildingB["lon"]) return true;

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalBuildingChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalBuildingChange = function () {
            var currBuilding = angular.copy(ctrl.selectedBuilding);
            if (ctrl.compareBuildingValues(initBuilding, currBuilding)) return true
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            // && toState.name !== "location.devices.edit"
            if (!ignoreNextStateChange && ctrl.evalBuildingChange() && !buildingSaved) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('location.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('location.modal.empty.yes'),
                    textButton0: $translate.instant('location.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-remove-circle',
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initBuilding = null;
                        LocationDetailService.canChange = true;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        ctrl.save = function () {
            if (ctrl.selectedBuilding.name === "") {
                Notify.error("global.notification.error.title", "building.notifications.nameNecessary", 2000);
                return;
            }

            MessageService.publish('saveBuildingStart');
            var building = angular.copy(ctrl.selectedBuilding);
            buildingSaved = true;
            delete building.positionSelect;
            delete building.edit;
            delete building.roomsFull;
            delete building.autoValue;

            return Buildings.save(building).then(function (response) {
                Notify.success("global.notification.success.title", "building.notifications.saveSuccess", 2000);
                for (var i = 0; i < ctrl.buildings.length; i++) {
                    if (ctrl.buildings[i].id === response.data.id) {
                        ctrl.buildings[i] = Building.parseFromHtmlObject(response.data);
                        ctrl.selectedBuilding = ctrl.buildings[i];
                    }
                }
                MessageService.publish("saveSuccess");
                LocationDetailService.canChange = true;
                return response;
            }, function (error) {
                Notify.error("global.notification.error.title", "building.notifications.saveError", 2000);
                MessageService.publish("saveError");
                $log.error('Couldn\'t save building!', error);
            });
        };
    }

    angular
        .module('emsv2App')
        .controller('BuildingDetailController', buildingDetailController);

})();
