(function () {
    'use strict';

    /**
     * @function GFCSettingsService
     * @description Service to handle gfx setting storage
     */
    angular.module('emsv2App').service("GFXSettingsService", function (localStorageService) {

        /**
         * @description Function to get gfx configuration from local storage
         * @returns {*} returns found gfx configuration object
         */
        var getSettingsObject = function () {
            return localStorageService.get("gfx_conf");
        };

        /**
         * @description Function to set gfx configuration object in local storage
         * @param {object} settings the gfx configuration object to set
         */
        var setSettingsObject = function (settings) {
            localStorageService.set("gfx_conf", settings);
        };

        /**
         * @description Function to store user specific gfx configuration object in local storage
         * @param {string} username the users name
         * @param {object} settings the gfx configuration for this user to save
         */
        var setSettingsForUser = function (username, settings) {
            var s = getSettingsObject();
            s[username] = settings;
            setSettingsObject(s);
        };

        /**
         * @description Function to get gfx configuration object for specified user
         * @param {string} username the username to get gfx configuration object for
         * @returns {*} returns gfx configuration object for user defined by its user name
         */
        var getSettingsForUser = function (username) {
            var s = getSettingsObject();
            if (!s) {
                s = {};
                setSettingsObject(s);
            }
            if (!s.hasOwnProperty(username)) {
                s[username] = {aa: "1", zoom: "1", displayMode: "0"};
                setSettingsObject(s);
            }
            return s[username];
        };

        return {
            getSettingsForUser: getSettingsForUser,
            setSettingsForUser: setSettingsForUser
        };
    });
})();
