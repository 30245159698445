
/**
 * Logfiles
 * @ngdoc service
 * @name Logfiles
 * @requires $http, $log, $q
 */
angular.module('emsv2App').service('Logfiles', ['$http', '$log', '$q', function ($http) {
    return {
        getAllLogfiles: function () {
            return $http.get('api/logs/available');
        },
        getLog: function (filename) {
            return $http.get('api/logs/' + filename);
        },
        downloadLog: function (file) {
        return $http.get('api/logs/download/' +file);
        },
        deleteLog: function (file) {
            return $http.delete('api/logs/delete/' +file);
        }
    }
}]);