(function () {
    'use strict';

    function viewGroupController($scope, $state, $window, $log, Group, Notify, locations, filters, users
        , selectedGroup, $translate, GenDialogService, Tools) {

        var initGroup = null;
        var ctrl = this;

        $scope.saveBtn = 'init';
        $scope.isDetail = false;
        $scope.isSaved = false;
        $scope.isVirtual = typeof selectedGroup.virtual === "boolean" ? selectedGroup.virtual : false;
        $scope.isGenerated = typeof selectedGroup.generated === "boolean" ? selectedGroup.generated : false;
        $scope.groupExists = false;
        $scope.group = selectedGroup;
        $scope.locationList = locations;
        $scope.filterList = filters;
        $scope.userList = users;
        $scope.originalName = selectedGroup.name;
        $scope.placeholder = $translate.instant('global.form.select');

        $scope.save = function () {
            $scope.saveBtn = 'load';
            $scope.isSaved = true;
            Group.update($scope.group).then(function (response) {
                $scope.saveBtn = 'success';
                Notify.defaultSuccess();
                // $state.go('groupOverview', {id: response.data.id});
            }, function (error) {
                Notify.defaultError();
                $scope.saveBtn = 'error';
                $log.debug('Could not save group: ', error);
                $log.debug('Data for group: ', $scope.group);
            });
        };

        $scope.onNameChanged = function (name) {
            $scope.groupExists = false;
            if (name !== $scope.originalName) {
                Group.exists(name).then(function (response) {
                    $scope.groupExists = response.data;
                });
            }
        };

        ctrl.$onInit = function () {
            initGroup = angular.copy($scope.group);
        };

        ctrl.compareGroupValues = function (groupA, groupB) {
            if (!Tools.isDefinedNotNull(groupA)) return false;
            var aProps = Object.getOwnPropertyNames(groupA);
            var bProps = Object.getOwnPropertyNames(groupB);
            if (aProps.length !== bProps.length) {
                return true;
            }
            if (groupA["name"] !== groupB["name"]) return true;
            if (groupA["users"].length !== groupB["users"].length) return true;
            if (groupA["locations"].length !== groupB["locations"].length) return true;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalGroupChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalGroupChange = function () {
            var currGroup = angular.copy($scope.group);
            if (ctrl.compareGroupValues(initGroup, currGroup)) return true;
            return false;
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (ctrl.evalGroupChange() && !ignoreNextStateChange && !$scope.isSaved) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('global.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('global.modal.empty.yes'),
                    textButton0: $translate.instant('global.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initGroup = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });
    }

    angular
        .module('emsv2App')
        .controller('EditGroupController', viewGroupController);
})();
