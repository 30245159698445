(function () {

    'use strict';

    angular.module('emsv2App')
        .filter('filterRoles', ['EMSConstants', function (EMSConstants) {
            return function (items) {
                var filtered = [];
                angular.forEach(items, function (item) {
                    if (!item.contains("ROOM_EDIT")
                        && !item.contains("DEBUG")
                        && item !== "ROLE_USER") {
                        filtered.push(item);
                    }
                });
                return filtered;
            };
        }])
})();
(function () {

    'use strict';

    angular.module('emsv2App')
        .filter('filterAdmin', ['EMSConstants', function (EMSConstants) {
            return function (items) {
                var filtered = [];
                angular.forEach(items, function (item) {
                    if (item === "ROLE_ADMIN") {
                        filtered.push(item);
                    }
                });
                return filtered;
            };
        }])
})();
