/**
 * object to simplify transportation of push requests to the backend
 *
 * @param objectId of the object that should be pushed to the device
 * @param pushState the state of this request defaults to PushState.PUSH_INIT
 * @param pushType determines the type of triggered push process defaults to PushType.ROOM
 *
 * @constructor
 */
function PushRequest(objectId, pushState, pushType) {
    this.objectId = objectId !== undefined ? objectId : null;
    this.pushState = pushState !== undefined ? pushState : PushState.PUSH_INIT;
    this.pushType = pushType !== undefined ? pushType : PushType.ROOM;
}

PushRequest.prototype = {};
PushRequest.prototype.constructor = PushRequest;

var PushType = {"ROOM": 0, "DEVICE": 1, "LOCATION": 2, "UNKNOWN": 3};
Object.freeze(PushType);

var PushState = {"PUSH_INIT" : 0, "PUSH_IN_PROGRESS" : 1, "PUSH_FAILED" : 2, "PUSH_SUCCEEDED" : 3, "UNKNOWN" : 4};
Object.freeze(PushState);
