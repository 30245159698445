(function () {

    'use strict';

    var app = angular.module("emsv2App");

    app.directive('widgetsysteminfo', function () {

        return {
            restrict: 'E',
            controller: 'widgetSysteminfoController',
            controllerAs: 'syinCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/systeminfo/widget.systeminfo.template.html'
        };
    });
})();
