(function () {

    'use strict';

    var isLicensed = function (license, module) {
        if (license !== null
            && license !== undefined
            && license.data !== null
            && license.data !== undefined) {
            if (module.indexOf('*') !== -1) {
                var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                var keys = Object.keys(license.data);
                for (var i in keys) {
                    if (regex.test(keys[i]) && keys[i]) return true;
                }
            } else if (license.data[module] !== null) {
                return license.data[module];
            }
        }
        return false;
    };

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('alarm', {
                    abstract: false,
                    parent: 'site',
                    url: '/alarm',
                    redirectTo: 'alarm.table',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_ALARM_MANAGEMENT'],
                        pageTitle: 'alarm.pageTitle',
                        breadcrumbsTitle: 'alarm.title',
                        license: 'alarms'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('alarm');
                            $translatePartialLoader.addPart('location');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        user: ['$log', 'Principal', function ($log, Principal) {
                            return Principal.identity().then(function (acc) {
                                return acc;
                            }, function (error) {
                                $log.error("Could not get current user information\n" + error);
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                            return Principal.identity().then(function (user) {
                                $log.debug('Loaded userInfo: ', user);
                                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_ALARM_MANAGEMENT')) {
                                    License.get().then(function (response) {
                                        var license = response.data;
                                        if (isLicensed(license, 'alarms')) {
                                            return user;
                                        } else {
                                            $state.go("accessdenied");
                                        }
                                    }, function (error) {
                                        $log.error('Couldn\'t load license!', error);
                                    });
                                } else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error('Couldn\'t load user: ', error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
                .state('alarm.table', {
                    parent: 'alarm',
                    url: '/tableView',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_ALARM_MANAGEMENT'],
                        pageTitle: 'alarm.pageTitle',
                        breadcrumbsTitle: 'global.menu.alarm.tableView',
                        license: 'alarms'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/alarm/alarm.table.html',
                            controller: 'AlarmController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('alarm');
                            $translatePartialLoader.addPart('location');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        user: ['$log', 'Principal', function ($log, Principal) {
                            return Principal.identity().then(function (acc) {
                                return acc;
                            }, function (error) {
                                $log.error("Could not get current user information\n" + error);
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                            return Principal.identity().then(function (user) {
                                $log.debug('Loaded userInfo: ', user);
                                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_ALARM_MANAGEMENT')) {
                                    License.get().then(function (response) {
                                        var license = response.data;
                                        if (isLicensed(license, 'alarms')) {
                                            return user;
                                        } else {
                                            $state.go("accessdenied");
                                        }
                                    }, function (error) {
                                        $log.error('Couldn\'t load license!', error);
                                    });
                                } else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error('Couldn\'t load user: ', error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
                .state('alarm.calendar', {
                    parent: 'alarm',
                    url: '/calendarView',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_ALARM_MANAGEMENT'],
                        pageTitle: 'alarm.pageTitle',
                        breadcrumbsTitle: 'global.menu.alarm.calendarView',
                        license: 'alarms'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/alarm/alarm.calendar.html',
                            controller: 'AlarmCalendarController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('alarm');
                            $translatePartialLoader.addPart('location');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        user: ['$log', 'Principal', function ($log, Principal) {
                            return Principal.identity().then(function (acc) {
                                return acc;
                            }, function (error) {
                                $log.error("Could not get current user information\n" + error);
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                            return Principal.identity().then(function (user) {
                                $log.debug('Loaded userInfo: ', user);
                                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_ALARM_MANAGEMENT')) {
                                    License.get().then(function (response) {
                                        var license = response.data;
                                        if (isLicensed(license, 'alarms')) {
                                            return user;
                                        } else {
                                            $state.go("accessdenied");
                                        }
                                    }, function (error) {
                                        $log.error('Couldn\'t load license!', error);
                                    });
                                } else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error('Couldn\'t load user: ', error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                });
        });

})();
