(function () {
    'use strict';

    /**
     * @function AssetService
     * @description Service to handle loading of texture and model data
     */
    angular.module('emsv2App').service('AssetService', function ($interval) {

        // object to store texture and model data
        var assets = {
            models: {
                //keys are file names without type ending
                arrow: null, // will lead to try to load arrow.json
                arrow_bend: null,
                arrow_move_big: null,
                arrow_tool: null,
                blade: null,
                bladecase: null,
                cool: null,
                cool_2x: null,
                cool_3x: null,
                cool_4x: null,
                cool_bottom: null,
                cool_cyberrow: null,
                // desk:null,
                door: null,
                door_slide: null,
                // office_chair:null,
                rack: null,
                rack_double: null,
                rack_open: null,
                rails: null,
                // shelf:null,
                slot: null,
                slot_server: null,
                slot_switch: null,
                slot_ups: null,
                ups: null,
                window_frame: null,
                // window_glass:null
                xclose: null
            },
            textures: {
                //keys are part of name, ending png
                //e.g. blade_diff.png, blade_normal.png
                blade: {
                    diff: null,
                    normal: null
                },
                cool_bottom: {
                    diff: null,
                    normal: null
                },
                cool_cyberrow: {
                    diff: null,
                    normal: null,
                    mask: null
                },
                cool: {
                    diff: null,
                    normal: null,
                    mask: null
                },
                cool_std: {
                    diff: null
                },
                // desk:{
                //     diff:null,
                //     normal:null
                // },
                door: {
                    diff: null,
                    normal: null
                },
                floor: {
                    diff: null,
                    normal: null
                },
                floor_vent: {
                    diff: null,
                    normal: null
                },
                // office_chair:{
                //     diff:null,
                //     normal:null
                // },
                rack: {
                    diff: null,
                    normal: null
                },
                rails: {
                    diff: null,
                    normal: null
                },
                // shelf:{
                //     diff:null,
                //     normal:null
                // },
                slot: {
                    diff: null,
                    normal: null
                },
                slot_server: {
                    diff: null,
                    normal: null
                },
                slot_switch: {
                    diff: null,
                    normal: null
                },
                slot_ups: {
                    diff: null,
                    normal: null
                },
                wall: {
                    diff: null,
                    normal: null
                },
                cage: {
                    diff: null,
                    normal: null
                },
                window: {
                    diff: null,
                    normal: null
                },
                layout: {
                    diff: null
                },
                // lettersheet:{
                //     diff:null,
                //     normal:null
                // },
                lettersheet8: {
                    diff: null,
                    normal: null
                },
                ups: {
                    diff: null,
                    normal: null
                }
                ,
                uv: {
                    diff: null
                }
            }
        };

        var modelsReady = false;
        var texturesReady = false;

        /**
         * @description Function to load asset data and trigger callback when done
         * @param {function} callback the function to call if all assets are loaded
         */
        var loadData = function (callback) {
            var textureLoader = new THREE.TextureLoader();
            var modelLoader = new THREE.JSONLoader();

            var checkModelsReady = function () {
                for (var i in assets.models) {
                    if (!assets.models.hasOwnProperty(i)) continue;
                    if (assets.models[i] === null) return false;
                }
                return true;
            };
            var checkTexturesReady = function () {
                for (var i in assets.textures) {
                    if (!assets.textures.hasOwnProperty(i)) continue;
                    if (assets.textures[i].diff === null) return false;
                    if (assets.textures[i].normal && assets.textures[i].normal === null) return false;

                }
                return true;
            };
            var loadTex = function (obj) {
                textureLoader.load("assets/textures/" + obj + "_diff.png", function (diff) {
                    assets.textures[obj].diff = diff;
                    texturesReady = checkTexturesReady();
                });
                if (assets.textures[i].normal !== undefined) {
                    textureLoader.load("assets/textures/" + obj + "_normal.png", function (normal) {
                        assets.textures[obj].normal = normal;
                    });
                }

            };
            var loadModels = function (obj) {
                modelLoader.load("assets/models/" + obj + ".json", function (geo) {
                    assets.models[obj] = geo;
                });
            };

            var i, key;

            for (i in assets.models) {
                key = i;
                loadModels(key);
            }
            for (i in assets.textures) {
                key = i;
                loadTex(key);
            }
            var intervalPromise = $interval(function () {
                var a = checkModelsReady();
                var b = checkTexturesReady();
                if (a && b) {
                    callback();
                    $interval.cancel(intervalPromise);
                }
            }, 150);
        };

        /**
         * @description Function to get asset data
         * @param {string} type the type of asset data to get ('texture' or 'model')
         * @param {string} name the name of the asset to get
         * @param {string} sub the sub-object name(only relevant for textures, e.g. "getAssetData('texture','blade','diff')" will return diffuse texture for blade)
         * @returns {*} returns found asset data, otherwise undefined
         */
        var getAssetData = function (type, name, sub) {
            if (type === "model") {
                return assets.models[name];
            }
            if (type === "texture") {
                return assets.textures[name][sub];
            }
        };

        /**
         * @description Function to check if asset data is loaded
         * @returns {boolean} returns true if asset data is loaded, otherwise false
         */
        var isReady = function () {
            return (modelsReady && texturesReady) ? true : false;
        };

        return {
            getAssetData: getAssetData,
            isReady: isReady,
            loadData: loadData
        }
    });
})();
