(function () {

    'use strict';

    function quickAnalysisController($q, $scope, $translate, $timeout, $state, $http, DateConstants, digiChart, highchart
        , Tools, Analysis, Session, Notify, locationsAndDevices, PIL) {
        var ctrl = this;
        ctrl.datapointLimit = null;
        ctrl.locationSelectionBox = null;
        ctrl.isLocationSelected = false;
        ctrl.spinnerDisplay = false;
        ctrl.rooms = [];
        ctrl.roomsAvailable = false;
        ctrl.entitySpinnerDisplay = false;
        ctrl.dpSpinnerDisplay = false;
        ctrl.validSearchTerm = false;
        ctrl.validDpSearchTerm = false;
        ctrl.searchTerm = undefined;
        ctrl.dpSearchTerm = undefined;

        ctrl.colors = {
            list: [
                '#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5',
                '#64E572', '#FF9655', '#FFF263', '#6AF9C4', '#F07979'
            ],
            nextColor: function (num) {
                if (!Tools.isDefinedNotNull(num)) num = ctrl.datapoints.list.length;
                return (num < 10)
                    ? ctrl.colors.list[num]
                    : ctrl.colors.nextColor(num - 10);
            }
        };
        ctrl.tmp = {
            loadLocation: null,
            loadRoom: null,
            loadDatapoint: null
        };

        ctrl.dateranges = {
            start: null,
            end: null,
            options: {
                singleDatePicker: true,
                locale: {
                    format: $translate.instant("global.dateFormat"),
                    daysOfWeek: DateConstants.getDaysOfWeek(),
                    monthNames: DateConstants.getMonthNames()
                },
                showDropdowns: true
            }
        };
        ctrl.locations = {
            available: locationsAndDevices.value1,
            devices: locationsAndDevices.value2,
            selected: {}
        };

        ctrl.options = {
            splitValues: [
                'day',
                'week',
                'month',
                'year'
            ]
        };

        ctrl.entityTree = {
            options: {
                multiSelection: false,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            entityModel: {
                entities: [],
                current: [],
                selected: [],
            }
        };

        ctrl.tree = {
            options: {
                multiSelection: true,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            model: {
                datapoints: [],
                current: [],
                selected: [],
                expanded: []
            }
        };
        ctrl.chart = {
            title: '',
            hidden: true,
            config: [],
            types: angular.copy(highchart.chartTypes),
            datapoints: [],
            canGenerate: _chartCanGenerate,
            generate: _chartGenerate
        };

        ctrl.$onInit = function () {
            var today = moment.utc().format('YYYY-MM-DD');
            ctrl.tmp.chartType = ctrl.chart.types[0];
            ctrl.dateranges.start = moment.utc(today).subtract(1, 'week');
            ctrl.dateranges.end = moment.utc(today).add({hours: 23, minutes: 59, seconds: 59});

            digiChart.getGroupingOptions().then(function (response) {
                ctrl.chart.groupingOptions = response.data;
                ctrl.tmp.grouping = ctrl.chart.groupingOptions[0];
            });

            digiChart.getAggregationOptions().then(function (response) {
                ctrl.chart.aggregationOptions = response.data;
                ctrl.tmp.aggregation = ctrl.chart.aggregationOptions[0];
            });

            var locationId = $state.params.locationId;
            if (Tools.isDefinedNotNull(locationId) && locationId > -1) {
                ctrl.tmp.loadLocation = ctrl.locations.available.find(function (el) {
                    return el.id === locationId;
                });
                // ctrl.loadLocationDataPoints();
            }
        };

        ctrl.gotoDetailed = function () {
            var datapoints = _buildDataPoints();
            $q.all(datapoints).then(function () {
                Session.put("analysis_datapoints", angular.copy(ctrl.chart.datapoints));
                Session.put("analysis_dateranges", [{start: ctrl.dateranges.start, end: ctrl.dateranges.end}]);
                $state.go('analysis.create');
            });
        };

        ctrl.entityFilterChange = function (searchTerm) {
            ctrl.getRoomEntities();
            if (searchTerm === undefined || searchTerm === null || searchTerm === "") {
                ctrl.validSearchTerm = false;
            } else {
                ctrl.validSearchTerm = true;
                ctrl.filterEntityNode(searchTerm);
            }
        }
        ctrl.filterEntityNode = function (searchTerm) {
            ctrl.entityTree.entityModel.entities = filter(ctrl.entityTree.entityModel.current, searchTerm.toLowerCase());
        }

        ctrl.dpFilterChange = function (dpSearchTerm) {
            ctrl.tree.model.datapoints = ctrl.searchReference;
            if (dpSearchTerm === undefined || dpSearchTerm === null || dpSearchTerm === "") {
                ctrl.validDpSearchTerm = false;
            } else {
                ctrl.validDpSearchTerm = true;
                ctrl.filterDpNode(dpSearchTerm);
            }
        }
        ctrl.filterDpNode = function (dpSearchTerm) {
            ctrl.tree.model.datapoints = filter(ctrl.tree.model.datapoints, dpSearchTerm.toLowerCase());
        }

        function filter(array, text) {
            var getNodes = function getFilteredNodes(result, object) {
                if (object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    return result;
                }
                if (Array.isArray(object.children)) {
                    var children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }

        ctrl.getRoomEntities = function () {
            ctrl.tree.model.datapoints = [];
            ctrl.isEntitySelected = false;
            ctrl.isRoomSelected = true;
            ctrl.entitySpinnerDisplay = true;
            if (Tools.isDefinedNotNull(ctrl.tmp.loadRoom)) {
                $http.get('api/rooms/getRoomEntitiesByRoomId/' + ctrl.tmp.loadRoom.id).then(function (response) {
                    ctrl.entityTree.entityModel.entities = response.data;
                    ctrl.entityTree.entityModel.current = response.data;
                });
            }
            ctrl.entitySpinnerDisplay = false;
        }

        ctrl.displayEntityDataPoints = function (node, selected) {
            ctrl.isEntitySelected = selected;
            if(selected) {
                ctrl.entityTree.entityModel.selected = node;
                ctrl.dpSearchTerm = undefined;
                ctrl.tree.model.datapoints = [];
                ctrl.selectedEntity = node;
                var currentRoom = ctrl.tmp.loadRoom;
                ctrl.dpSpinnerDisplay = true;
                if (ctrl.selectedEntity.key === "cpus") {
                    $http.post('api/rooms/getDriverValuesForCpu',
                        {
                            "roomId": currentRoom.id,
                            "rackId": ctrl.selectedEntity.rackId,
                            "slotId": ctrl.selectedEntity.slotId,
                            "cpuId": ctrl.selectedEntity.id,
                        }).then(function (response) {
                        ctrl.tree.model.datapoints = response.data;
                        ctrl.searchReference = ctrl.tree.model.datapoints;
                        for(var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                            ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                        }
                        _prepareDpSet();
                        ctrl.dpSpinnerDisplay = false;
                    });
                } else if (ctrl.selectedEntity.key === "slots") {
                    $http.post('api/rooms/getDriverValuesForSlot',
                        {
                            "roomId": currentRoom.id,
                            "rackId": ctrl.selectedEntity.rackId,
                            "slotId": ctrl.selectedEntity.id,
                        }).then(function (response) {
                        ctrl.tree.model.datapoints = response.data;
                        ctrl.searchReference = ctrl.tree.model.datapoints;
                        for(var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                            ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                        }
                        _prepareDpSet();
                        ctrl.dpSpinnerDisplay = false;
                    });
                } else {
                    $http.post('api/rooms/getDriverValuesForEntity',
                        {
                            "entityId": ctrl.selectedEntity.id,
                            "roomId": currentRoom.id,
                            "entityType": ctrl.selectedEntity.key,
                        }).then(function (response) {
                        ctrl.tree.model.datapoints = response.data;
                        ctrl.searchReference = ctrl.tree.model.datapoints;
                        for(var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                            ctrl.tree.model.datapoints[i].title = $translate.instant(ctrl.tree.model.datapoints[i].title);
                        }
                        _prepareDpSet();
                        ctrl.dpSpinnerDisplay = false;
                    });
                }
            } else {
                ctrl.tree.model.datapoints = [];
                ctrl.searchTerm = undefined;
                ctrl.dpSearchTerm = undefined;
            }
        }

        function _prepareDpSet() {
            var defer = $q.defer();
            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                ctrl.tree.model.current = ctrl.tree.model.datapoints[i];
            }
            defer.resolve();
        }

        ctrl.enableRoomSelection = function () {
            ctrl.searchTerm = undefined;
            ctrl.dpSearchTerm = undefined;
            ctrl.isEntitySelected = false;
            ctrl.isRoomSelected = false;
            ctrl.tmp.loadRoom = null;
            ctrl.tree.model.datapoints = [];
            ctrl.entityTree.entityModel.entities = []
            ctrl.rooms = [];
            if (Tools.isDefinedNotNull(ctrl.tmp.loadLocation)) {
                $http.get('api/rooms/getRoomById/' + ctrl.tmp.loadLocation.id).then(function (response) {
                    ctrl.rooms = response.data;
                    if (!ctrl.rooms.length) {
                        ctrl.roomsAvailable = false;
                        _selectDataPointSet(ctrl.tmp.loadLocation.id, undefined);
                    } else {
                        ctrl.roomsAvailable = true;
                    }
                });
            }
        }

        function updateTreeModel() {
            if (ctrl.locationSelectionBox !== null && ctrl.locationSelectionBox.disabled && ctrl.roomSelectionBox.disabled) {
                $timeout(function () {
                    $scope.$apply(function () {
                        ctrl.tree.model.current.datapoints;
                        ctrl.locationSelectionBox.disabled = false;
                        if (ctrl.roomsAvailable) {
                            ctrl.roomSelectionBox.disabled = false;
                        }
                        ctrl.isLocationSelected = true;
                        ctrl.spinnerDisplay = false;
                    });
                }, 1000);
            }
        }

        function _findLiveDatapoint(data, hash) {
            var i, match;
            if (Array.isArray(data)) {
                for (i = 0; i < data.length; i++) {
                    match = _findLiveDatapoint(data[i], hash);
                    if (match !== null) {
                        return match;
                    }
                }
            } else if (Array.isArray(data.children)) {
                for (i = 0; i < data.children.length; i++) {
                    match = _findLiveDatapoint(data.children[i], hash);
                    if (match !== null) {
                        match.parents.push(data);
                        return match;
                    }
                }
            } else {
                if (data.parameter !== undefined && data.paramter !== null
                    && data.parameter.HASH !== undefined && data.parameter.HASH !== null
                    && data.parameter.HASH === hash) {
                    return {
                        datapoint: data,
                        parents: []
                    }
                }
            }
            return null;
        }

        // Look in all available sets if they're already loaded and load them (if not)
        function _selectDataPointSet(locationId, roomId) {
            var defer = $q.defer();
            for (var i = 0; i < ctrl.tree.model.datapoints.length; i++) {
                if (ctrl.tree.model.datapoints[i].location === locationId) {
                    ctrl.tree.model.current = ctrl.tree.model.datapoints[i];
                    defer.resolve();
                }
            }
            if(Tools.isDefinedNotNull(roomId)) {
                digiChart.getAvailableSensorsbyRoom(locationId, roomId, true).then(function (response) {
                    var resultantData = [];
                    for (var i = 0; i < response.data.length; i++) {
                        resultantData.push(JSON.parse(response.data[i]));
                    }

                    var dpset = {
                        location: locationId,
                        datapoints: resultantData
                    };
                    ctrl.tree.model.datapoints.push(dpset);
                    ctrl.tree.model.current = dpset;
                    ctrl.tmp.loadDatapoint = resultantData;
                    defer.resolve();
                    updateTreeModel();
                });
                return defer.promise;
            } else {
                digiChart.getAvailableSensors(locationId, true).then(function (response) {
                    var resultantData = [];
                    for (var i = 0; i < response.data.length; i++) {
                        resultantData.push(JSON.parse(response.data[i]));
                    }

                    var dpset = {
                        location: locationId,
                        datapoints: resultantData
                    };
                    ctrl.tree.model.datapoints.push(dpset);
                    ctrl.tree.model.current = dpset;
                    ctrl.tmp.loadDatapoint = resultantData;
                    defer.resolve();
                    updateTreeModel();
                });
                return defer.promise;
            }
        }

        function _buildDataPoints() {
            var promises = [];
            ctrl.chart.datapoints = [];
            for (var j = 0; j < ctrl.tree.model.selected.length; j++) {
                var dp = angular.copy(ctrl.tree.model.selected[j]);
                delete dp.children;

                if (!Tools.isDefinedNotNull(dp.title)) {
                    promises.push(_translateAddDataPoint(dp));
                } else {
                    _addDataPoint(dp);
                }
            }
            return promises;

            function _translateAddDataPoint(dp) {
                var defer = $q.defer();
                $translate(dp.translation, dp.translationParams).then(function (result) {
                    dp.title = result;
                    _addDataPoint(dp);
                    defer.resolve();
                });
                return defer.promise;
            }

            function _addDataPoint(dp) {
                dp.locationName = ctrl.tmp.loadLocation.name;
                dp.locationId = ctrl.tmp.loadLocation.id;
                dp.aggregation = ctrl.tmp.aggregation;
                dp.grouping = ctrl.tmp.grouping;
                dp.type = ctrl.tmp.chartType;
                dp.color = ctrl.colors.nextColor(ctrl.chart.datapoints.length);
                ctrl.chart.datapoints.push(dp);
            }
        }

        // ctrl.chart.canGenerate()
        // Check if everything is set to generate the chart
        function _chartCanGenerate() {
            return Tools.isDefinedNotNull(ctrl.tree.model.selected)
                && Tools.isDefinedNotNull(ctrl.dateranges.start)
                && Tools.isDefinedNotNull(ctrl.dateranges.end)
                && ctrl.tree.model.selected.length > 0;
        }

        // ctrl.chart.generate()
        function _chartGenerate() {
            if (!ctrl.chart.canGenerate()) {
                return;
            }
            ctrl.chart.hidden = false;
            ctrl.chart.config = [];

            ctrl.chart.datapoints = [];
            var queue = _buildDataPoints();
            $q.all(queue).then(function () {
                // Change title here, not in _buildDataPoints(), so we can transfer the original name to the detailed analysis
                for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                    var dp = ctrl.chart.datapoints[i];
                    var path = (Tools.isDefinedNotNull(dp.path) && dp.path.length > 0) ? dp.path : '';
                    dp.title = '(' + dp.locationName + ') ' + path + dp.title;
                }
                PIL.getTempSetting().then(function (temp) {
                    var tempSettings = temp.data;
                    highchart.buildConfiguration(tempSettings, ctrl.chart.datapoints, {
                            dateRanges: [{start: ctrl.dateranges.start, end: ctrl.dateranges.end}],
                            options: {
                                title: {
                                    text: ctrl.chart.title
                                },
                                subtitle: {
                                    text: ctrl.dateranges.start.format($translate.instant("global.dateFormat")) + ' - ' + ctrl.dateranges.end.format($translate.instant("global.dateFormat"))
                                },
                                options: {
                                    chart: {
                                        height: 400,
                                        backgroundColor: '#ffffff'
                                    },
                                    navigation: {
                                        buttonOptions: {
                                            enabled: true
                                        }
                                    }
                                }
                            }
                        },
                        function (config, zoomed) {
                            ctrl.isZoomed = zoomed;
                            ctrl.chart.config[0] = config;

                        })
                });
            });
        }
    }

    angular.module('emsv2App')
        .controller('AnalysisQuickController', quickAnalysisController);

})();
