(function () {

    'use strict';

    function reportPrintController($scope, $state, $interval, $log,
                                   Auth, Tools, Report,
                                   reportData) {
        var ctrl = this;
        ctrl.report = reportData;
        ctrl.dashboard = {
            options: {
                full: {
                    cellHeight: 100,
                    cellWidth: 200,
                    width: 10,
                    height: 8,
                    verticalMargin: 15,
                    float: true,
                    animate: false,
                    disableResize: true,
                    disableDrag: true,
                    staticGrid: true
                },
                half: {
                    cellHeight: 100,
                    cellWidth: 200,
                    width: 6,
                    height: 8,
                    verticalMargin: 15,
                    float: true,
                    animate: false,
                    disableResize: true,
                    disableDrag: true,
                    staticGrid: true
                }
            }
        };
    }

    angular
        .module('emsv2App')
        .controller('ReportPrintController', reportPrintController);

})();
