(function () {
    'use strict';

    angular
        .module('emsv2App')
        .service('Converter', ['$timeout', '$q', '$translate', '$log', 'HwTyp', function ($timeout, $q, $translate, $log, HwTyp) {

            function _checkUrl(url, deferred) {
                var result = url;
                if (String(url).indexOf('^(http|https)://') === -1) {
                    result = 'http://' + url;
                }
                deferred.resolve(result);
            }

            function _formatMac(mac, deferred) {
                var result = '';
                mac = mac.toString(16);
                mac = '000000000000'.substring(mac.length) + mac;

                for (var i = 0; i < mac.length; i += 2) {
                    if (result.length) {
                        result += ':';
                    }
                    result += mac.substring(i, i + 2);
                }
                deferred.resolve(result);
            }

            function _stringToIp(ipString, deferred) {
                var ip = ipString.split('.');
                var result = 0;
                result += parseInt(ip[0]) << 24;
                result += parseInt(ip[1]) << 16;
                result += parseInt(ip[2]) << 8;
                result += parseInt(ip[3]);
                deferred.resolve(result);
            }

            function _ipToString(ipLong, deferred) {
                var result = (ipLong >>> 24) + '.' + (ipLong >> 16 & 255) + '.' + (ipLong >> 8 & 255) + '.' + (ipLong & 255);
                deferred.resolve(result);
            }

            function _formatHwTyp(typ, deferred) {
                return HwTyp.getAllMapped().then(function (response) {
                    return response.data[typ];
                }, function (error) {
                    deferred.reject('Couldn\'t load hwtypes');
                    $log.error('Couldn\'t load hwtypes.', error);
                });
            }

            function _stringToMac(value, deferred) {
                var concat = value.split(':').join('');
                var result = parseInt(concat, 16);
                deferred.resolve(result);
            }

            function _translate(value, deferred) {
                $translate(value).then(function (result) {
                    deferred.resolve(result);
                }, function (error) {
                    $log.log('Couldn\'t translate: ' + value, error);
                });
            }

            return {
                convertFrom: function (type, value) {
                    var deferred = $q.defer();
                    switch (type) {
                        case 'url':
                            deferred.resolve(value);
                            break;
                        case 'email':
                            deferred.resolve(value);
                            break;
                        case 'ip':
                            _stringToIp(value, deferred);
                            break;
                        case 'mac':
                            _stringToMac(value, deferred);
                            break;
                        default:
                            deferred.resolve(value);
                            break;
                    }
                    return deferred.promise;
                },
                convertTo: function (type, value) {
                    var deferred = $q.defer();
                    switch (type) {
                        case 'url':
                            _checkUrl(value, deferred);
                            break;
                        case 'email':
                            deferred.resolve(value);
                            break;
                        case 'ip':
                            _ipToString(value, deferred);
                            break;
                        case 'mac':
                            _formatMac(value, deferred);
                            break;
                        case 'hwtyp':
                            _formatHwTyp(value, deferred);
                            break;
                        case 'translate':
                            _translate(value, deferred);
                            break;
                        default:
                            deferred.resolve('??UNKNOWN??');
                            break;
                    }
                    return deferred.promise;
                }
            };

        }]);

})();
