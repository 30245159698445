(function () {
        'use strict';

        function errorConfigEditController($scope, $window, $stateParams, $timeout, $state, ErrorConfig, notify, Notify,
                                           $translatePartialLoader, $translate, $http, $uibModal, LocationDetailService,
                                           errorConfiguration, deviceOptions, errorChildOptions, errorConfigOptions,
                                           userSettings, GenDialogService, Tools, Contacts, Device, SmtpSMSServerConfigurationService,
                                           protocols, conditionalOperators, SmartEventService,  errorConditionPeriodTypes) {
            var lastSel = $("#device");
            var ctrl = this;
            ctrl.virtualDeviceMac = [];
            ctrl.placeholder = $translate.instant('global.form.select');
            ctrl.contacts = [];
            ctrl.selectedContacts = [];
            var initErrorConfig = null;
            $translatePartialLoader.addPart("room");
            $translate.refresh();
            ctrl.editMode = $stateParams.mode === "edit" || $stateParams.mode === "create";
            ctrl.addErrorForm = {};
            ctrl.userSettings = userSettings;
            ctrl.protocolTypes = protocols;
            ctrl.state = {};
            ctrl.xyz = [];
            ctrl.errorMessage;
            ctrl.optionVarInValidChar = ["'", "\""];
            ctrl.optionVarErrorMgs = $translate.instant("room.errorList.optionVarErrorMgs");
            ctrl.canSave = false;

            ctrl.smartEventSnmp = new SmartEventSnmp();
            ctrl.snmpConfigurationOfSmartEvent = new SnmpConfigurationOfSmartEvent();
            ctrl.snmpIOConfigurationOfSmartEvent = new SnmpIOConfigurationOfSmartEvent();
            $scope.modal = {
                view: null,
                obj: null,
                close: function () {
                    modal.close();
                },
                changeDeviceAbort: function () {
                    ctrl.editMenu.errorConfiguration.deviceMac = parseInt(modal.oldVal);
                    $timeout(function () {
                        lastSel.trigger("chosen:updated"); // fuck chosen
                    }, 500);
                    modal.close();
                },
                changeDeviceOk: function () {
                    ctrl.editMenu.errorConfiguration.errorConfigurationChildren = [];
                    ctrl.reloadOptions(ctrl.editMenu.errorConfiguration.deviceMac);
                    modal.close();
                }
            };
            var modal = null;
            ctrl.userSettings = userSettings;
            ctrl.state = {};
            ctrl.xyz = [];
            ctrl.rooms = [];
            ctrl.selectedRoom = null;
            ctrl.sendSNMP = false;
            ctrl.flagAddError = false;
            ctrl.periodTypeValues = errorConditionPeriodTypes;

            ctrl.changeIsCount = function (errorcondition) {

                errorcondition.count =1;
                errorcondition.period =1;
                errorcondition.periodType="MINUTES";
                errorcondition.periodEnable=false;
                ctrl.flagAddError =false;
                ctrl.flagAddError = ctrl.editMenu.errorConfiguration.errorConditions.filter(errorCondition =>  errorCondition.countEnable === true).length !== 0;
            }

            ctrl.reset = function (obj) {
                obj.period=1;     // To reset to default value
                obj.periodType="MINUTES";
            }

            ctrl.changeProtocol = function () {
                if (ctrl.smartEventSnmp.protocol === 1 || ctrl.smartEventSnmp.protocol === 2) {
                    ctrl.snmpConfigurationOfSmartEvent = new SnmpConfigurationOfSmartEvent();
                } else if (ctrl.smartEventSnmp.protocol === 3) {
                    ctrl.snmpConfigurationOfSmartEvent = new SnmpV3ConfigurationOfSmartEvent();
                }
            }

            ctrl.editMenu = { // Die Objektdefinition mit default Parametern (falls es sich um ein neues Objekt handelt)
                errorConfiguration: {
                    name: "",
                    isNameInvalid: false,
                    message: "",
                    inactiveMessage: "",
                    isMessageInvalid: false,
                    linkType: 0,
                    priority: 74,
                    autoQuit: true,
                    sendMail: false,
                    contacts: [],
                    errorConfigurationChildren: Tools.isDefinedNotNull(errorConfiguration) ? errorConfiguration.contacts : [],
                    errorConditions: [],
                    locationId: $stateParams.id,
                    deviceMac: "",
                    newVal: true,
                    objChanged: true,
                    sendSecondaryNotificationChannel: 0,
                    setSnmp: false,
                    sendSms: false,
                },
                deviceOptions: [], // DeviceOptions sind alle DigiWEBs innerhalb der Location
                errorConfigOptions: [], // ErrorConfigOptions sind die verfügbaren Datenpunkte die für das device das bei dem Parameter errorConfiguration.deviceMac angegeben wird
                errorChildOptions: []// ErrorChildOptions sind die anderen Fehlerkonfigurationen innerhalb der Location die mit dieser verknüpft werden können (HandleCombine)
            };

            ctrl.sendMailCheckedChange = function (errorConfiguration) {
                if (!errorConfiguration.sendMail) {
                    ctrl.editMenu.errorConfiguration.contacts = [];
                }
            };

            ctrl.addErrorCondition = function () { // ng-click event
                ctrl.editMenu.errorConfiguration.errorConditions.push(
                    {
                        path: "",
                        entityText: "",
                        driverValue: {name: ""},
                        errorConditionRule: 0,
                        value: 0,
                        countEnable: false,
                        count: 1,
                        periodEnable: false,
                        period: 1,
                        periodType: "MINUTES",
                        newVal: true,
                    }
                );
            };

            ctrl.addErrorConfigurationRelation = function () { // ng-click event
                ctrl.editMenu.errorConfiguration.errorConfigurationChildren.push({
                    parentId: null,
                    childId: null,
                    newVal: true,
                });
            };
            $scope.getDriverOptions = function (entityText) { // Methode für das Laden der driver in ng-options (benötigt für translations)
                for (var i = 0; i < ctrl.editMenu.errorConfigOptions.length; i++) {
                    if (ctrl.editMenu.errorConfigOptions[i].entityText === entityText) {
                        return ctrl.editMenu.errorConfigOptions[i].drivers;
                    }
                }
                return [];
            };

            ctrl.options = [ //obsolete
                {
                    driverLabel: "location.alarms.newAlarmConfigFormLabels.objectList"
                },
                {
                    driverLabel: "room.defaultParam.temp"
                }
            ];
            ctrl.operatorsANDOR = [  // und + oder Operator in den Optionen für die ErrorConditions + Übersetzungen
                {
                    value: 1,
                    name: $translate.instant("location.alarms.newAlarmConfigFormLabels.and")
                },
                {
                    value: 0,
                    name: $translate.instant("location.alarms.newAlarmConfigFormLabels.or")
                }
            ];
            ctrl.compOperators = conditionalOperators;
            ctrl.priorities = [ // Prioritätsstufen Info,Warning, Kritisch
                {
                    value: 74,
                    priority: $translate.instant("location.alarms.newAlarmConfigFormLabels.info")
                },
                {
                    value: 149,
                    priority: $translate.instant("location.alarms.newAlarmConfigFormLabels.warning")
                },
                {
                    value: 224,
                    priority: $translate.instant("location.alarms.newAlarmConfigFormLabels.critical")
                }
            ];

            ctrl.editMenu.deviceOptions = deviceOptions; // deviceOptions werden schon beim resolve geladen ( Die in der Location verfügbaren devices )
            if (errorConfiguration != null || errorConfiguration != undefined) {
                errorConfiguration.errorConditions.forEach(function (errorCondition) {
                    if(errorCondition.countEnable){
                        ctrl.flagAddError =true;
                    }
                });
                ctrl.editMenu.errorConfiguration = errorConfiguration; // Initialisierung des Hauptobjektes, falls es sich um ein bestehndes Objekt handelt das editert,geviewt wird
                ctrl.editMenu.errorChildOptions = errorChildOptions;  // Initialisierung der Optionen für andere Fehlerkonfigurationen (HandleCombine)
                ctrl.editMenu.errorConfigOptions = errorConfigOptions; // Initialisierung der Optionen für Fehlerbedingungen (ErrorConditions/Datenpunkte)
                ctrl.editMenu.errorConfiguration.isNameInvalid = false;
                ctrl.editMenu.errorConfiguration.isMessageInvalid = false;
            }

            ctrl.reloadOptions = function (mac) { // reloadOptions wird ausgeführt wenn die deviceMac sich ändert (Nach dem der User das über ein Modal bestätigt)
                ErrorConfig.getErrorConfigOptions(mac).then(function (data) {
                    ctrl.editMenu.errorConfigOptions = data;
                });
                ErrorConfig.getErrorChildOptions($stateParams.id, ctrl.editMenu.errorConfiguration).then(function (data) {
                    ctrl.editMenu.errorChildOptions = data;
                });
            };

            ctrl.deleteErrorCondition = function (index) {
                ctrl.editMenu.errorConfiguration.errorConditions.splice(index, 1);
            };

            ctrl.deleteErrorConfigurationRelation = function (index) {
                ctrl.editMenu.errorConfiguration.errorConfigurationChildren.splice(index, 1);
            };

            ctrl.save = function () {
                if (!inputsHaveErrors()) {
                    if (ctrl.editMenu.errorConfiguration.sendMail && !ctrl.editMenu.errorConfiguration.contacts.length) {
                        Notify.error("global.notification.error.title", "location.alarms.noRecipients", 5000);
                    } else {
                        if(ctrl.editMenu.errorConfiguration.setSnmp == null) {
                            ctrl.editMenu.errorConfiguration.setSnmp = false;
                        }
                        if (ctrl.editMenu.errorConfiguration.setSnmp && SNMPConfigErrors()) {
                            Notify.error("global.notification.error.title", "alarm.notifications.error.SnmpConfiguration", 5000);
                        } else {
                            ErrorConfig.saveErrorConfig(ctrl.editMenu.errorConfiguration).then(function (response) {
                                Notify.success("global.notification.success.title", "global.notification.success.text", 5000);
                                initErrorConfig = angular.copy(ctrl.editMenu["errorConfiguration"]);

                                if (ctrl.editMenu.errorConfiguration.setSnmp) {
                                    ctrl.smartEventSnmp.errorConfigurationId = response.id;
                                    SmartEventService.save(ctrl.smartEventSnmp, ctrl.snmpConfigurationOfSmartEvent, ctrl.snmpIOConfigurationOfSmartEvent).then(function (response) {
                                        ctrl.smartEventSnmp = response.smartEventSnmp;
                                        ctrl.snmpConfigurationOfSmartEvent = response.snmpConfigurationOfSmartEvent;
                                        ctrl.snmpIOConfigurationOfSmartEvent = response.snmpIOConfigurationOfSmartEvent;
                                    });
                                }
                                $state.go('location.alarm.errorConfig.list');
                            }, function () {
                                Notify.error("global.notification.error.title", "global.notification.error.text", 5000);
                            });
                        }
                    }
                } else {
                    Notify.error("alarm.notifications.error.title", ctrl.errorMessage, 5000);
                }
            };

            var inputsHaveErrors = function () {
                var input = null;
                var hasErrors = false;
                var errorConfig = ctrl.editMenu.errorConfiguration;

                if (errorConfig.name === null || errorConfig.name === "" || errorConfig.isNameInvalid) {
                    input = angular.element(document.querySelector('#errorConfigName'));
                    input.addClass("has-error");
                    hasErrors = true;
                } else {
                    input = angular.element(document.querySelector('#errorConfigName'));
                    input.removeClass("has-error");
                }

                if (errorConfig.message === null || errorConfig.message === "" || errorConfig.isMessageInvalid) {
                    input = angular.element(document.querySelector('#activeAlarmMessage'));
                    input.addClass("has-error");
                    hasErrors = true;
                }
                if (errorConfig.isMessageInvalid) {
                    input = angular.element(document.querySelector('#inactiveAlarmMessage'));
                    input.addClass("has-error");
                    hasErrors = true;
                } else {
                    input = angular.element(document.querySelector('#activeAlarmMessage'));
                    input = angular.element(document.querySelector('#inactiveAlarmMessage'));
                    input.removeClass("has-error");
                }

                if (hasErrors) {
                    ctrl.errorMessage = "alarm.notifications.error.text";
                    return hasErrors;
                }

                if (errorConfig.errorConditions.length === 0) hasErrors = true;

                for (var i = 0; i < errorConfig.errorConditions.length; i++) {
                    var errorCond = errorConfig.errorConditions[i];
                    if (ctrl.editMode && errorCond.driverValue === null) {
                        hasErrors = true;
                        break;
                    }
                    if (errorCond.entityText === null || errorCond.entityText === "" || errorCond.driverValue.id === undefined ||
                        errorCond.driverValue.id === null || errorCond.driverValue.id === "") {
                        hasErrors = true;
                    }
                }

                if (hasErrors) {
                    ctrl.errorMessage = "alarm.notifications.error.dataPointText";
                    return hasErrors;
                }

                if (errorConfig.errorConfigurationChildren.length > 0) {
                    for (var i = 0; i < errorConfig.errorConfigurationChildren.length; i++) {
                        if (errorConfig.errorConfigurationChildren[i].childId === null) {
                            hasErrors = true;
                        }
                    }
                }

                if (hasErrors) {
                    ctrl.errorMessage = "alarm.notifications.error.conbineAlarmText";
                    return hasErrors;
                }

                return hasErrors;
            };

            var SNMPConfigErrors = function () {
                var hasErrors = false;

                if (ctrl.smartEventSnmp.protocol === null || ctrl.smartEventSnmp.protocol === "") {
                    hasErrors = true;
                }
                if (ctrl.smartEventSnmp.protocol === 1 || ctrl.smartEventSnmp.protocol === 2) {
                    if (ctrl.snmpConfigurationOfSmartEvent.host === null || ctrl.snmpConfigurationOfSmartEvent.host === "" ||
                        ctrl.snmpConfigurationOfSmartEvent.port === null || ctrl.snmpConfigurationOfSmartEvent.port === "" ||
                        ctrl.snmpConfigurationOfSmartEvent.communityTarget === null || ctrl.snmpConfigurationOfSmartEvent.communityTarget === "" ||
                        ctrl.snmpIOConfigurationOfSmartEvent.oid === null || ctrl.snmpIOConfigurationOfSmartEvent.oid === "" ||
                        ctrl.smartEventSnmp.activeEventValue === null || ctrl.smartEventSnmp.activeEventValue === "" ||
                        ctrl.smartEventSnmp.inactiveEventValue === null || ctrl.smartEventSnmp.inactiveEventValue === "") {
                        hasErrors = true;
                    }
                } else if (ctrl.smartEventSnmp.protocol === 3) {
                    if (ctrl.snmpConfigurationOfSmartEvent.host === null || ctrl.snmpConfigurationOfSmartEvent.host === "" ||
                        ctrl.snmpConfigurationOfSmartEvent.port === null || ctrl.snmpConfigurationOfSmartEvent.port === "" ||
                        ctrl.snmpIOConfigurationOfSmartEvent.oid === null || ctrl.snmpIOConfigurationOfSmartEvent.oid === "" ||
                        !Tools.isDefinedNotNull(ctrl.snmpConfigurationOfSmartEvent.userName) || ctrl.snmpConfigurationOfSmartEvent.userName === "" ||
                        !Tools.isDefinedNotNull(ctrl.snmpConfigurationOfSmartEvent.authenticationProtocol) || ctrl.snmpConfigurationOfSmartEvent.authenticationProtocol === "" ||
                        !Tools.isDefinedNotNull(ctrl.snmpConfigurationOfSmartEvent.authenticationPassphrase) || ctrl.snmpConfigurationOfSmartEvent.authenticationPassphrase === "" ||
                        !Tools.isDefinedNotNull(ctrl.snmpConfigurationOfSmartEvent.privacyProtocol) || ctrl.snmpConfigurationOfSmartEvent.privacyProtocol === "" ||
                        !Tools.isDefinedNotNull(ctrl.snmpConfigurationOfSmartEvent.privacyPassphrase) || ctrl.snmpConfigurationOfSmartEvent.privacyPassphrase === "" ||
                        ctrl.smartEventSnmp.activeEventValue === null || ctrl.smartEventSnmp.activeEventValue === "" ||
                        ctrl.smartEventSnmp.inactiveEventValue === null || ctrl.smartEventSnmp.inactiveEventValue === "") {
                        hasErrors = true;
                    }
                }
                return hasErrors;
            }

            var fetchData = function () {
                if (ctrl.editMenu.errorConfiguration.setSnmp) {
                    SmartEventService.getByFkId(ctrl.editMenu.errorConfiguration.id).then(function (response) {
                        ctrl.smartEventSnmp = response.smartEventSnmp;
                        ctrl.snmpConfigurationOfSmartEvent = response.snmpConfigurationOfSmartEvent;
                        ctrl.snmpIOConfigurationOfSmartEvent = response.snmpIOConfigurationOfSmartEvent;
                    });
                }
            };

            $scope.filterByRoom = function (obj) { // Filtern der Liste
                if (ctrl.selectedRoom && (ctrl.selectedRoom.id !== null || ctrl.selectedRoom.id !== undefined)) {
                    if (obj.entityText != "") {
                        var x = parseInt(obj.entityText.split(" ")[1]);
                        if (ctrl.selectedRoom.id === x) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            };
            ctrl.checkForMobile = function (contacts) {
                for (var i = 0; i < contacts.length; i++) {
                    if (Tools.isDefinedNotNull(contacts[i].mobile)) {
                        ctrl.showMissingMobileInfo = false;
                    } else {
                        ctrl.showMissingMobileInfo = true;
                        break;
                    }
                }
                if (!contacts.length) {
                    ctrl.editMenu.errorConfiguration.sendMail = false;
                    ctrl.editMenu.errorConfiguration.sendSms = false;
                }
            }

            ctrl.$onInit = function () {
                SmtpSMSServerConfigurationService.get().then(function (response) {
                    if (response.data !== undefined || response.data !== "") {
                        ctrl.smsGatewayConfigured = true;
                    }
                });

                Device.getVirtualDevices().then(function (response) {
                    ctrl.virtualDeviceMac = response.data;
                });
                Contacts.getAll().then(function (response) {
                    ctrl.contacts = response.data;
                });

                $http.get('api/rooms/' + ctrl.editMenu.errorConfiguration.locationId).then(function (response) {
                    ctrl.rooms = response.data;
                });
                ctrl.editMenu.deviceOptions = deviceOptions; // deviceOptions werden schon beim resolve geladen ( Die in der Location verfügbaren devices )
                if (Tools.isDefinedNotNull(errorConfiguration)) {
                    ctrl.editMenu.errorConfiguration = errorConfiguration; // Initialisierung des Hauptobjektes, falls es sich um ein bestehndes Objekt handelt das editert,geviewt wird
                    ctrl.editMenu.errorChildOptions = errorChildOptions;  // Initialisierung der Optionen für andere Fehlerkonfigurationen (HandleCombine)
                    ctrl.editMenu.errorConfigOptions = errorConfigOptions;// Initialisierung der Optionen für Fehlerbedingungen (ErrorConditions/Datenpunkte)
                    if (!ctrl.editMenu.errorConfiguration.contacts.length) {
                        ctrl.editMenu.errorConfiguration.sendMail = false;
                        ctrl.editMenu.errorConfiguration.sendSms = false;
                    }
                } else {
                    ctrl.editMenu.errorConfiguration.deviceMac = ctrl.editMenu.deviceOptions[0].mac;
                    ctrl.reloadOptions(ctrl.editMenu.errorConfiguration.deviceMac);
                }
                LocationDetailService.reset();
                LocationDetailService.onBack = function () {
                    $state.go("location.alarm.errorConfig.list");
                };
                if (ctrl.editMode) {
                    LocationDetailService.onSave = ctrl.save;
                    LocationDetailService.canSave = function () {
                        var errorConfig = ctrl.editMenu.errorConfiguration;
                        if (errorConfig.sendMail && errorConfig.contacts.length <= 0) {
                            return ctrl.canSave;
                        }
                        return Tools.isDefinedNotNull(errorConfig) && Tools.isDefinedNotNull(errorConfig.deviceMac) &&
                            errorConfig.deviceMac !== "";
                    }
                } else {
                    LocationDetailService.onEdit = function () {
                        $state.go("location.alarm.errorConfig.edit", {mode: 'edit'}, {reload: true});
                    }
                }

                fetchData();
                initErrorConfig = angular.copy(ctrl.editMenu["errorConfiguration"]);
            };

            var compareDatapointsValues = function (errorConfigA, errorConfigB) {
                var aProps = Object.getOwnPropertyNames(errorConfigA);
                var bProps = Object.getOwnPropertyNames(errorConfigB);

                if (aProps.length !== bProps.length) {
                    return true;
                }
                for (var i = 0; i < errorConfigA.length; i++) {
                    var configA = errorConfigA[i];
                    var configB = errorConfigB[i];
                    if (configA.uniqueId !== configB.uniqueId) return true;
                    if (configA.deviceMac !== configB.deviceMac) return true;
                    if (configA.driverLabel !== configB.driverLabel) return true;
                    if (configA.entityText !== configB.entityText) return true;
                    if (configA.errorConditionRule !== configB.errorConditionRule) return true;
                    if (configA.id !== configB.id) return true;
                    if (configA.newVal !== configB.newVal) return true;
                    if (configA.path !== configB.path) return true;
                    if (configA.value !== configB.value) return true;
                    if (configA.driverValue !== null && configA.driverValue.length > 0) {
                        if (configA.driverValue.uniqueId !== configB.driverValue.uniqueId) return true;
                    }
                }
            };

            var compareEmailConfig = function (errorConfigContactsA, errorConfigContactsB) {
                if (errorConfigContactsA.length !== errorConfigContactsB.length) {
                    return true;
                }
                for (var i = 0; i < errorConfigContactsA.length; i++) {
                    if (errorConfigContactsA[i].id !== errorConfigContactsB[i].id) return true;
                }
            };

            ctrl.compareErrorConfigValues = function (errorConfigA, errorConfigB) {

                if (errorConfigA.name !== errorConfigB.name) return true;
                if (errorConfigA.message !== errorConfigB.message) return true;
                if (errorConfigA.inactiveMessage !== errorConfigB.inactiveMessage) return true;
                if (errorConfigA.deviceMac !== errorConfigB.deviceMac) return true;
                if (errorConfigA.linkType !== errorConfigB.linkType) return true;
                if (errorConfigA.priority !== errorConfigB.priority) return true;
                if (errorConfigA.autoQuit !== errorConfigB.autoQuit) return true;
                if (errorConfigA.sendMail !== errorConfigB.sendMail) return true;
                if (errorConfigA.sendSecondaryNotificationChannel !== errorConfigB.sendSecondaryNotificationChannel) return true;

                // error conditions
                if (compareDatapointsValues(errorConfigA.errorConditions, errorConfigB.errorConditions)) return true;
                if (compareEmailConfig(errorConfigA.contacts, errorConfigB.contacts)) return true;

                return false;
            };

            $window.onbeforeunload = function (e) {
                if (ctrl.evalErrorConfigChange()) {
                    e.preventDefault();
                    return $translate.instant('location.modal.changedValues');
                }
            };

            ctrl.evalErrorConfigChange = function () {
                var currentErrorConfig = angular.copy(ctrl.editMenu["errorConfiguration"]);
                if (ctrl.compareErrorConfigValues(initErrorConfig, currentErrorConfig)) return true
            };

            ctrl.isChanged = function () {
                if (ctrl.evalErrorConfigChange()) {
                    LocationDetailService.canChange = false;
                    LocationDetailService.onlyActiveTab = 0;
                } else {
                    LocationDetailService.canChange = true;
                    LocationDetailService.onlyActiveTab = null;
                }
            }

            var ignoreNextStateChange = false;
            $scope.$on("$stateChangeStart", function (event, toState, toParams) {
                if (!ignoreNextStateChange && ctrl.evalErrorConfigChange() && toState.name !== "location.alarm.errorConfig.edit") {
                    event.preventDefault();
                    GenDialogService.showDialog(false, {
                        headText: $translate.instant('global.dialog.head.warning'),
                        headIcon: 'glyphicon glyphicon-warning-sign',
                        messageText: $translate.instant('location.modal.changedValues'),
                        showClose: false,
                        textButton1: $translate.instant('location.modal.empty.yes'),
                        textButton0: $translate.instant('location.modal.empty.no'),
                        iconButton1: 'glyphicon glyphicon-trash',
                        iconButton0: 'glyphicon glyphicon-chevron-left',
                        classButton1: 'btn-danger',
                        classButton0: 'btn-default',
                        callbackButton1: function () {
                            GenDialogService.hideDialog();
                            ignoreNextStateChange = true;
                            initErrorConfig = null;
                            LocationDetailService.canChange = true;
                            $state.go(toState.name, toParams);
                        },
                        callbackButton0: function () {
                            GenDialogService.hideDialog();
                        }
                    });
                }
            });

            $scope.$watch(function () {
                    return ctrl.editMenu.errorConfiguration;
                },
                function () {
                    var isChanged = false;
                    LocationDetailService.canChange = true;
                    LocationDetailService.onlyActiveTab = null;
                    if (errorConfiguration != null) {
                        if (ctrl.editMenu.errorConfiguration.deviceMac !== initErrorConfig.deviceMac) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.name !== initErrorConfig.name) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.message !== initErrorConfig.message) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.inactiveMessage !== initErrorConfig.inactiveMessage) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.linkType !== initErrorConfig.linkType) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.priority !== initErrorConfig.priority) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.autoQuit !== initErrorConfig.autoQuit) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (ctrl.editMenu.errorConfiguration.sendMail !== initErrorConfig.sendMail) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (compareDatapointsValues(ctrl.editMenu.errorConfiguration.errorConditions, initErrorConfig.errorConditions)) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        } else if (compareEmailConfig(ctrl.editMenu.errorConfiguration.contacts, initErrorConfig.contacts)) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        }
                    }
                    // neues Element
                    else {
                        if (ctrl.addErrorForm.$dirty || ctrl.addErrorForm.$invalid) {
                            isChanged = true;
                            LocationDetailService.canChange = false;
                            LocationDetailService.onlyActiveTab = -1;
                            LocationDetailService.alarmsOnlyActiveTab = 0;
                        }
                    }
                    ctrl.canSave = isChanged;
                }, true
            );

        }

        angular.module('emsv2App').controller('errorConfigEditController', errorConfigEditController);
    }

)
();
