/**
 * Created by RR on 15.02.22.
 */
(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name SmartEventService
     * @requires $http
     */
    angular.module('emsv2App').service('SmartEventService', function (SmartEventSnmpService, SnmpV1ConfigurationOfSmartEventService,
                                                                      SnmpV2cConfigurationOfSmartEventService, SnmpIOConfigurationOfSmartEventService, SnmpV3ConfigurationOfSmartEventService) {

        var data = {
            smartEventSnmp: new SmartEventSnmp(),
            snmpConfigurationOfSmartEvent: new SnmpConfigurationOfSmartEvent(),
            snmpIOConfigurationOfSmartEvent: new SnmpIOConfigurationOfSmartEvent()
        };

        var saveIOConfiguration = function (snmpIOConfigurationOfSmartEvent) {
            return SnmpIOConfigurationOfSmartEventService.save(snmpIOConfigurationOfSmartEvent).then(function (responseSnmpIOConfigurationOfSmartEvent) {
                data.snmpIOConfigurationOfSmartEvent = new SnmpIOConfigurationOfSmartEvent(responseSnmpIOConfigurationOfSmartEvent);
                return data;
            });
        }

        var getIOConfigurationByFkId = function (responseSnmpConfigurationOfSmartEvent) {
            return SnmpIOConfigurationOfSmartEventService.getByFkId(responseSnmpConfigurationOfSmartEvent.id).then(function (responseSnmpIOConfigurationOfSmartEvent) {
                data.snmpIOConfigurationOfSmartEvent = new SnmpIOConfigurationOfSmartEvent(responseSnmpIOConfigurationOfSmartEvent);
                return data;
            });
        }

        var save = function (smartEventSnmp, snmpConfigurationOfSmartEvent, snmpIOConfigurationOfSmartEvent) {
            return SmartEventSnmpService.save(smartEventSnmp).then(function (responseSmartEventSnmp) {
                data.smartEventSnmp = new SmartEventSnmp(responseSmartEventSnmp);

                snmpConfigurationOfSmartEvent.smartEventSnmpId = responseSmartEventSnmp.id;

                if (data.smartEventSnmp.protocol === 1) {
                    return SnmpV1ConfigurationOfSmartEventService.save(snmpConfigurationOfSmartEvent).then(function (responseSnmpConfigurationOfSmartEvent) {
                        data.snmpConfigurationOfSmartEvent = new SnmpConfigurationOfSmartEvent(responseSnmpConfigurationOfSmartEvent);

                        snmpIOConfigurationOfSmartEvent.snmpConfigurationOfSmartEventId = responseSnmpConfigurationOfSmartEvent.id;

                        return saveIOConfiguration(snmpIOConfigurationOfSmartEvent);
                    });
                } else if (data.smartEventSnmp.protocol === 2) {
                    return SnmpV2cConfigurationOfSmartEventService.save(snmpConfigurationOfSmartEvent).then(function (responseSnmpConfigurationOfSmartEvent) {
                        data.snmpConfigurationOfSmartEvent = new SnmpConfigurationOfSmartEvent(responseSnmpConfigurationOfSmartEvent);

                        snmpIOConfigurationOfSmartEvent.snmpConfigurationOfSmartEventId = responseSnmpConfigurationOfSmartEvent.id;

                        return saveIOConfiguration(snmpIOConfigurationOfSmartEvent);
                    });
                } else if (data.smartEventSnmp.protocol === 3) {
                    return SnmpV3ConfigurationOfSmartEventService.save(snmpConfigurationOfSmartEvent).then(function (responseSnmpConfigurationOfSmartEvent) {
                        data.snmpConfigurationOfSmartEvent = new SnmpV3ConfigurationOfSmartEvent(responseSnmpConfigurationOfSmartEvent);

                        snmpIOConfigurationOfSmartEvent.snmpConfigurationOfSmartEventId = responseSnmpConfigurationOfSmartEvent.id;

                        return saveIOConfiguration(snmpIOConfigurationOfSmartEvent);
                    });
                }

                return data;
            });
        };

        var getByFkId = function (errorConfigurationId) {
            return SmartEventSnmpService.getByFkId(errorConfigurationId).then(function (responseSmartEventSnmp) {
                data.smartEventSnmp = new SmartEventSnmp(responseSmartEventSnmp);

                if (data.smartEventSnmp.protocol === 1) {
                    return SnmpV1ConfigurationOfSmartEventService.getByFkId(responseSmartEventSnmp.id).then(function (responseSnmpConfigurationOfSmartEvent) {
                        data.snmpConfigurationOfSmartEvent = new SnmpConfigurationOfSmartEvent(responseSnmpConfigurationOfSmartEvent);

                        return getIOConfigurationByFkId(responseSnmpConfigurationOfSmartEvent);

                    });
                } else if (data.smartEventSnmp.protocol === 2 ) {
                    return SnmpV2cConfigurationOfSmartEventService.getByFkId(responseSmartEventSnmp.id).then(function (responseSnmpConfigurationOfSmartEvent) {
                        data.snmpConfigurationOfSmartEvent = new SnmpConfigurationOfSmartEvent(responseSnmpConfigurationOfSmartEvent);

                        return getIOConfigurationByFkId(responseSnmpConfigurationOfSmartEvent);

                    });
                } else if (data.smartEventSnmp.protocol === 3) {
                    return SnmpV3ConfigurationOfSmartEventService.getByFkId(responseSmartEventSnmp.id).then(function (responseSnmpConfigurationOfSmartEvent) {
                        data.snmpConfigurationOfSmartEvent = new SnmpV3ConfigurationOfSmartEvent(responseSnmpConfigurationOfSmartEvent);

                        return getIOConfigurationByFkId(responseSnmpConfigurationOfSmartEvent);

                    });
                }

                return data;
            });
        };


        var deleteByFkId = function (errorConfigurationId) {
            return SmartEventSnmpService.deleteByFkId(errorConfigurationId);
        };


        /************************************************
         *             FOREIGN KEY REFERENCE            *
         ************************************************/


        return {
            save: save,
            getByFkId: getByFkId,
            deleteByFkId: deleteByFkId
        };

    });
})();