/**
 * container object to be added to the room object. Contains the mac of the device which will be associated with this object
 * as well as a list of DriverValueIds which will be assigned to this device mac.
 *
 * @param id long database id of this group
 * @param name of the group
 * @param mac long database id of the device
 * @param roomId long database id value of the room
 *
 * @constructor
 */
function DriverValueGroup(id, name, mac, roomId) {
    this.id = id !== undefined ? id : null;
    this.name = name !== undefined ? name : "default";
    this.deviceMac = mac !== undefined ? mac : null;
    this.roomId = roomId !== undefined ? roomId : null;
}

DriverValueGroup.prototype = {};
DriverValueGroup.prototype.constructor = DriverValueGroup;

