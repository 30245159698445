(function () {
    'use strict';

    /**
     * @description component to handle weight input fields
     */
    angular.module("emsv2App")
        .component('weightInput', {
            require: {
                ngModelCtrl: "ngModel"
            },
            bindings: {
                settings: '<',
                ngModel: '='
            },
            template:
                '<div class="form-group">' +
                '<label ng-if="$ctrl.weightType === 0" translate="room.edit.weightStd" class="col-sm-4 control-label"></label>' +
                '<label ng-if="$ctrl.weightType === 1" translate="room.edit.weightUS" class="col-sm-4 control-label"></label>' +
                '<div class="col-sm-8">' +
                '<input type="number" min="0.001" max="20000" step="0.001" ng-model="$ctrl.ngModel" class="form-control" weight-converter weight-type="$ctrl.weightType"/>' +
                '</div>' +
                '</div>',
            controller: [function () {
                var ctrl = this;
                ctrl.weightType = 0;
                ctrl.$onInit = function () {
                    if (ctrl.settings.length > 0) {
                        var ws = ctrl.settings.filter(function (el) {return el.key === "weight"});
                        if (ws.length > 0) ctrl.weightType = parseInt(ws[0].value);
                    }
                };
            }],
            controllerAs: '$ctrl'
        });

    angular.module("emsv2App")
        .component('maxWeightInput', {
            require: {
                ngModelCtrl: "ngModel"
            },
            bindings: {
                settings: '<',
                ngModel: '='
            },
            template:
                '<div class="form-group">' +
                '<label ng-if="$ctrl.weightType === 0" translate="room.edit.maxWeightStd" class="col-sm-4 control-label"></label>' +
                '<label ng-if="$ctrl.weightType === 1" translate="room.edit.maxWeightUS" class="col-sm-4 control-label"></label>' +
                '<div class="col-sm-8">' +
                '<input type="number" min="0.001" max="20000" step="0.001" ng-model="$ctrl.ngModel" class="form-control" weight-converter weight-type="$ctrl.weightType"/>' +
                '</div>' +
                '</div>',
            controller: [function () {
                var ctrl = this;
                ctrl.weightType = 0;
                ctrl.$onInit = function () {
                    if (ctrl.settings.length > 0) {
                        var ws = ctrl.settings.filter(function (el) {return el.key === "weight"});
                        if (ws.length > 0) ctrl.weightType = parseInt(ws[0].value);
                    }
                };
            }],
            controllerAs: '$ctrl'
        });

    angular.module("emsv2App")
        .component('totalWeightInput', {
            require: {
                ngModelCtrl: "ngModel"
            },
            bindings: {
                settings: '<',
                ngModel: '='
            },
            template:
                '<div class="form-group">' +
                '<label ng-if="$ctrl.weightType === 0" translate="room.edit.totalWeightStd" class="col-sm-4 control-label"></label>' +
                '<label ng-if="$ctrl.weightType === 1" translate="room.edit.totalWeightUS" class="col-sm-4 control-label"></label>' +
                '<div class="col-sm-8">' +
                '<input type="number" min="0.001" max="20000" step="0.001" readonly="true" ng-model="$ctrl.ngModel" class="form-control" weight-converter weight-type="$ctrl.weightType"/>' +
                '</div>' +
                '</div>',
            controller: [function () {
                var ctrl = this;
                ctrl.weightType = 0;
                ctrl.$onInit = function () {
                    if (ctrl.settings.length > 0) {
                        var ws = ctrl.settings.filter(function (el) {return el.key === "weight"});
                        if (ws.length > 0) ctrl.weightType = parseInt(ws[0].value);
                    }
                };
            }],
            controllerAs: '$ctrl'
        });

    angular.module("emsv2App")
        .component('totalWeightDisplay', {
            require: {
                ngModelCtrl: "ngModel"
            },
            bindings: {
                settings: '<',
                weight: '=',
                maxWeight: '=',
                limit: '=',
                fullyDefined: '=',
                ngModel: '=',
                object: '<',
            },
            template:
                '<div class="form-group">' +
                '<div ng-if="$ctrl.weightType === 0" class="col-sm-4 control-label" translate="room.edit.totalWeightStd"></div>' +
                '<div ng-if="$ctrl.weightType === 1" class="col-sm-4 control-label" translate="room.edit.totalWeightUS"></div>' +
                '<div class="col-sm-8">' +
                '<div class="form-control" ondragstart="return false;">' +
                '<span ng-class="{\'\':!$ctrl.limit, \'label-warning border-radius-label-warning\':$ctrl.limit}" ng-bind="{{$ctrl.weightType > 0 ? \'$ctrl.convertedWeight\':\'$ctrl.weight\'}}"></span>' +
                '&nbsp;' +
                '<span ng-show="!$ctrl.fullyDefined" class="icon-info text-info info-icon-background" title="{{\'room.edit.weightInfo\' | translate}}"></span>' +
                '<span>&nbsp;/&nbsp;</span>' +
                '<span ng-bind="{{$ctrl.weightType > 0 ? \'$ctrl.convertedMaxWeight\':\'$ctrl.maxWeight\'}}"></span>' +
                '&nbsp;' +
                '<span ng-if="$ctrl.weightType === 0" translate="room.edit.kg"></span>' +
                '<span ng-if="$ctrl.weightType === 1" translate="room.edit.lb"></span>' +
                '</div>' +
                '</div>' +
                '</div>'
            ,

            //todo make weight converting work, i can bind the weight/maxWeight value on the convertedWeight/convertedMaxWeight variables, but they wont refresh on the scope
            //todo when another rack is selected
            controller: [function () {
                var ctrl = this;
                ctrl.weightType = 0;
                ctrl.convertedWeight = null;
                ctrl.convertedMaxWeight = null;
                ctrl.$onInit = function () {
                    weightConversion();
                };

                ctrl.$onChanges = function (object) {
                    if (object) {
                        weightConversion();
                    }
                };

                var weightConversion = function () {
                    if (ctrl.settings.length > 0) {
                        var ws = ctrl.settings.filter(function (el) {return el.key === "weight"});
                        if (ws.length > 0) {
                            ctrl.weightType = parseInt(ws[0].value);
                        }
                    }
                    if (ctrl.weightType === 1) {
                        ctrl.convertedWeight = convertKilogramToPound(ctrl.weight);
                        ctrl.convertedMaxWeight = convertKilogramToPound(ctrl.maxWeight);
                    }
                };

                var convertKilogramToPound = function (value) {
                    return Math.round((value / .45359237) * 1000) / 1000;
                };
            }],
            controllerAs: '$ctrl'
        });

    angular.module("emsv2App")
        .directive("weightConverter", function () {

            var convertKilogramToPound = function (value) {
                return Math.round((value / .45359237) * 1000) / 1000;
            };

            var convertPoundToKilogramm = function (value) {
                return Math.round((value * .45359237) * 1000) / 1000;
            };

            return {
                require: 'ngModel',
                link: function ($scope, element, attrs, ctrl) {
                    ctrl.$formatters.push(function (modelValue) {
                        if ($scope.weightType === 1) {
                            return convertKilogramToPound(modelValue);
                        }
                        else {
                            return modelValue;
                        }
                    });

                    ctrl.$parsers.push(function (viewValue) {
                        if ($scope.weightType === 1) {
                            return convertPoundToKilogramm(viewValue);
                        }
                        else {
                            return viewValue;
                        }
                    });
                },
                scope: {
                    weightType: '='
                }
            }
        });
})();
