(function () {
    angular.module('emsv2App').directive('widgetweatherforecast', function ($interval) {
        function controller($q, $element, $scope, $log, $timeout, $translate, $uibModal, Principal,
                            Location, Weather, WidgetData, Notify, $interval, Tools, $state) {
            var ctrl = this;
            var modalInstance;
            var langKey = 'de';
            var pollInterval = null;
            var getWeatherInterval = null;

            var openPolls = [];

            ctrl.cache = {input: '', len: 0};
            ctrl.state = $state.current.name;
            ctrl.dashboardLocked = false;

            ctrl.input = '';
            ctrl.tempOutputType = 'chart';
            ctrl.outputType = 'chart';

            ctrl.responseText = $translate.instant('dashboard.widget.weatherforecast.modal.response.wait');
            ctrl.validInput = false;

            ctrl.entries = [];

            ctrl.chart = null;
            ctrl.series = [];
            ctrl.headers = [];

            ctrl.lat = null;
            ctrl.lon = null;

            $scope.map = {center: {latitude: 45, longitude: -73}, zoom: 8};
            ctrl.$onInit = function () {
                $scope.$parent.setConfigMethod(ctrl.configure);
                ctrl.loadSettings();
                Principal.identity().then(function (account) {
                    langKey = account.langKey;
                    if(localStorage.getItem("locked-dashboard-user-id-" + account.id) === "true") {
                        ctrl.dashboardLocked = true;
                    } else {
                        ctrl.dashboardLocked = false;
                    }
                });
            };

            ctrl.configure = function () {
                modalInstance = $uibModal.open({
                    templateUrl: 'scripts/app/dashboard/widget.templates/weatherforecast/widget.weatherforecast.settings.modal.html',
                    animation: false,
                    windowClass: 'animated fadeInDown',
                    scope: $scope
                });
            };

            ctrl.saveSettings = function () {
                if (ctrl.entries.length !== 0) {
                    var settings = [];
                    settings.push({key: 'entries', value: JSON.stringify(ctrl.entries)});
                    settings.push({key: 'outputType', value: ctrl.tempOutputType});
                    $scope.widget.settings = settings;
                    $log.debug("Saving Settings:", $scope.widget.settings);
                    if ($scope.isdashboardwidget) {
                        WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                            Notify.defaultSuccess();
                        }, function (error) {
                            Notify.error("global.notification.error.title", "dashboard.widget.weatherforecast.saveErrorMsg", 2000);
                        });
                    }
                    ctrl.outputType = ctrl.tempOutputType;
                    update();
                    ctrl.closeModal();
                }
                else{
                    var settings = [];
                    $scope.widget.settings = settings;
                    if ($scope.isdashboardwidget) {
                        WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                            Notify.defaultSuccess();
                        }, function (error) {
                            Notify.error("global.notification.error.title", "dashboard.widget.weatherforecast.saveErrorMsg", 2000);
                        });
                    }
                    update();
                    ctrl.closeModal();
                }
            };

            ctrl.loadSettings = function () {
                if ($scope.widget.settings !== null) {
                    for (var i = 0; i < $scope.widget.settings.length; i++) {
                        if ($scope.widget.settings[i].key === 'entries') {
                            ctrl.entries = JSON.parse($scope.widget.settings[i].value);
                        }
                        if ($scope.widget.settings[i].key === 'outputType') {
                            ctrl.tempOutputType = $scope.widget.settings[i].value;
                            ctrl.outputType = ctrl.tempOutputType;
                        }
                    }
                    $log.debug("Loaded settings for weather forecast", ctrl.entries);
                    $timeout(update, 750);
                }
                else {
                    $log.error("Couldn't load settings for weather forecast!");
                }
            };

            ctrl.closeModal = function () {
                if (modalInstance) {
                    modalInstance.close();
                }
            };

            ctrl.remove = function (deleteIndex) {
                var temp = [];
                for (var i = 0; i < ctrl.entries.length; i++) {
                    if (i === deleteIndex) continue;
                    temp.push(ctrl.entries[i]);
                }
                ctrl.entries = temp;
            };

            ctrl.add = function () {
                if (ctrl.validInput && ctrl.lat !== null && ctrl.lon !== null) {
                    var temp = [{
                        address: ctrl.responseText,
                        lat: ctrl.lat,
                        lon: ctrl.lon
                    }];
                    for (var i = 0; i < ctrl.entries.length; i++) {
                        temp.push(ctrl.entries[i]);
                    }
                    ctrl.entries = temp;

                    ctrl.input = '';
                    ctrl.responseText = $translate.instant('dashboard.widget.weatherforecast.modal.response.wait');
                    ctrl.lat = null;
                    ctrl.lon = null;
                }
            };

            ctrl.loadWeatherForecast = function () {
                update();
            };

            var searchTimeout = null;
            ctrl.updateSearch = function (evt) {
                if (evt.which === 13) {
                    ctrl.add();
                }
                else {
                    if (ctrl.cache.input !== ctrl.input) {
                        ctrl.cache.input = ctrl.input;
                        ctrl.responseText = $translate.instant('dashboard.widget.weatherforecast.modal.response.search');
                        ctrl.lon = null;
                        ctrl.lat = null;
                        ctrl.validInput = false;
                        clearTimeout(searchTimeout);
                        searchTimeout = setTimeout(function () {
                            if (ctrl.input.length !== 0) {
                                Location.geocode(ctrl.input, langKey).then(function (res) {
                                    if (res.status === 200) {
                                        if (res.data.length !== 0) {
                                            ctrl.responseText = res.data[0]['display_name'];
                                            ctrl.lat = res.data[0]['lat'];
                                            ctrl.lon = res.data[0]['lon'];
                                            ctrl.validInput = true;
                                        }
                                        else {
                                            ctrl.responseText = $translate.instant('dashboard.widget.weatherforecast.modal.response.notfound');
                                        }
                                    }
                                }, function (err) {
                                    ctrl.responseText = "Bei der Anfrage ist ein Fehler aufgetreten";
                                });
                            }
                            else {
                                ctrl.responseText = $translate.instant('dashboard.widget.weatherforecast.modal.response.noinput')
                            }
                        }, 500);
                    }
                }
            };

            function _preUpdate() {
                getWeatherData();
                if (!Tools.isDefinedNotNull(getWeatherInterval)) {
                    //update every 10 minutes
                    getWeatherInterval = $interval(getWeatherData, 6000000);
                }
            }

            /**
             * starts an interval which requests data every 30 seconds. The task itself
             */
            function getWeatherData() {
                var reqs = [];
                for (var i = 0; i < ctrl.entries.length; i++) {
                    reqs.push(Weather.requestForecast(ctrl.entries[i].address, ctrl.entries[i].lat, ctrl.entries[i].lon));
                }
                $q.all(reqs).then(function (result) {
                    openPolls = [];
                    for (var j = 0; j < result.length; j++) {
                        openPolls.push(result[j].data);
                    }
                    _pollWeatherData();
                });

            }

            function _pollWeatherData() {
                ctrl.series = [];
                ctrl.headers = [];
                if(!Tools.isDefinedNotNull(pollInterval)) {
                    pollInterval = $interval(_pollData, 2000);
                }
            }

            /**
             * cancels the interval that continuously polls until data is received
             */
            function cancelWeatherPoll() {
                if (Tools.isDefinedNotNull(pollInterval)) {
                    $interval.cancel(pollInterval);
                    pollInterval = null;
                }
            }

            /**
             * cancels the interval that requests new data every 30 seconds
             */
            function cancelGetWeatherData() {
                if (Tools.isDefinedNotNull(getWeatherInterval)) {
                    $interval.cancel(getWeatherInterval);
                    getWeatherInterval = null;
                }
            }

            $scope.$on("$destroy", function () {
                cancelWeatherPoll();
                cancelGetWeatherData();
            });

            function removePollId(id) {
                openPolls.splice(openPolls.indexOf(id), 1);
            }

            function _pollData() {
                var reqs = [];
                for (var i= 0; i < openPolls.length; i++) {
                    reqs.push(Weather.pollRequestId(openPolls[i]));
                }
                $q.all(reqs).then(function (ret) {
                    for (var j = 0; j < ret.length; j++) {
                        var weatherData = ret[j].data;
                        if (weatherData === null) {
                            // quite radical but I can not think of a better solution should not happen anyway.
                            openPolls = [];
                            cancelWeatherPoll();
                            Notify.error("global.notification.error.title", "dashboard.widget.weather.messages.noValidResponse", 2000);
                        } else if (weatherData.weatherState === 2) {
                            //an error occurred
                            removePollId(weatherData.requestId);
                            Notify.error("global.notification.error.title", "dashboard.widget.weather.messages." +weatherData.errorCode, 2000);
                        } else if (weatherData.weatherState === 3) {
                            removePollId(weatherData.requestId);
                            processWeatherData(weatherData.locationName, weatherData.forecast);
                            if (ctrl.outputType === 'chart') {
                                _generateChart();
                            }
                        } else if (weatherData.weatherState === 4) {
                            removePollId(weatherData.requestId);
                            // the system just deleted the request since its outdated. new value will be available with next request
                        }
                    }
                    if(openPolls.length === 0) {
                        cancelWeatherPoll();
                    }
                });
            }

            function processWeatherData(name, tempData) {
                function parseDate(date) {
                    var spl = date.split(/[\W\D?|T]/);
                    return new Date(spl[0], spl[1] - 1, spl[2], spl[3], spl[4], spl[5], spl[6]).getTime();
                }
                var data = [];
                for (var i = 0; i < tempData.length; i++) {
                    data.push([parseDate(tempData[i].dateTime), tempData[i].temperature]);
                }
                ctrl.series.push({
                    type: 'line',
                    name: name,
                    lineWidth: 1,
                    data: data
                });
                if (ctrl.headers.length === 0) {
                    for (var d in data) {
                        if (data.hasOwnProperty(d)) {
                            var date = new Date(data[d][0]);
                            var headerString = $translate.instant('global.dateTimeFormat')
                                .replace(/DD/, (date.getUTCDate()+'').replace(/^(\d)$/, '0$1'))
                                .replace(/MM/, ((date.getUTCMonth()+1)+'').replace(/^(\d)$/, '0$1'))
                                .replace(/YYYY/, ((date.getUTCFullYear())+''))
                                .replace('HH', date.getUTCHours().toString().replace(/^(\d)$/, '0$1'))
                                .replace('mm', date.getUTCMinutes().toString().replace(/^(\d)$/, '0$1'))
                                .replace('ss', date.getUTCSeconds().toString().replace(/^(\d)$/, '0$1'));
                            ctrl.headers.push(headerString);
                        }
                    }
                }
            }

            function update() {
                if (ctrl.entries.length === 0) return;
                if (ctrl.chart !== null) {
                    ctrl.chart.highcharts().destroy();
                    ctrl.chart = null;
                }
                _preUpdate();
            }

            function _generateChart() {
                ctrl.chart = $element.find(".chart-container").highcharts({
                    chart: {
                        zoomType: false,
                        height: $($element).parents().eq(2).outerHeight() - 100
                    },
                    title: {text: $translate.instant('dashboard.widget.weatherforecast.charts.title')},
                    xAxis: [
                        {
                            type: 'datetime',
                            labels: {
                                style: {
                                    fontWeight: 100
                                }
                            }
                        }
                    ],
                    yAxis: {
                        title: {
                            text: $translate.instant('dashboard.widget.weatherforecast.charts.y_title'),
                            style: {
                                fontWeight: 100
                            }
                        },
                        labels: {
                            formatter: function () {
                                return (this.value)
                                    .toFixed(1)
                                    .toString()
                                    .replace(/(\.)0$/g, '')
                                    .replace('.', ',')
                                    .concat(" °C");
                            },
                            style: {
                                fontWeight: 100
                            }
                        }
                    },
                    tooltip: {
                        style: {
                            fontWeight: 100
                        },
                        formatter: function () {
                            return '<b>$series</b><br/><span>$date: <b>$temp °C</b></span>'
                                .replace('$series', this.series.name)
                                .replace('$date', _toDateString(this.x))
                                .replace('$temp', (this.y).toFixed(1));
                        }
                    },
                    legend: {
                        itemStyle: {
                            fontWeight: '100'
                        }
                    },
                    plotOptions: {
                        line: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: ctrl.series
                });
            }

            function _toDateString(input) {
                var ds = new Date(input);
                return 'dd.MM.yyyy HH:mm:ss'
                    .replace('dd', ds.getUTCDate().toString().replace(/^(\d)$/, '0$1'))
                    .replace('MM', (ds.getUTCMonth() + 1).toString().replace(/^(\d)$/, '0$1'))
                    .replace('yyyy', ds.getUTCFullYear().toString())
                    .replace('HH', ds.getUTCHours().toString().replace(/^(\d)$/, '0$1'))
                    .replace('mm', ds.getUTCMinutes().toString().replace(/^(\d)$/, '0$1'))
                    .replace('ss', ds.getUTCSeconds().toString().replace(/^(\d)$/, '0$1'));
            }
        }

        function link(scope, element, attrs, controller) {
            var timeoutId;
            element.on('$destroy', function () {
                $interval.cancel(timeoutId);
            });

            timeoutId = $interval(function () {
                if (controller.chart !== null) {
                    var hc = controller.chart.highcharts();
                    var h = $(element).parents().eq(2).outerHeight() - 100;
                    if ((hc.chartWidth !== undefined && hc.chartWidth !== element.find('.chart-container').outerWidth()) ||
                        (hc.chartHeight !== undefined && hc.chartHeight !== h)) {
                        hc.chartWidth = element.find('.chart-container').outerWidth();
                        hc.chartHeight  = h;
                    }
                }
            }, 250);
        }

        return {
            restrict: 'E',
            controller: controller,
            controllerAs: '$ctrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/weatherforecast/widget.weatherforecast.template.html',
            link: link
        };
    });
})();
