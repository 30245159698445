/**
 * Widget to display location positions on a map.
 */

(function () {
    'use strict';

    var mapIndex = 0;
    angular.module('emsv2App').directive('widgetlocationmap', function () {

        function mapController($scope, $log, $element, $timeout, $q, $templateRequest, Location, Tools, leafletData, Alarm, Buildings) {
            var ctrl = this;
            ctrl.defaults = {
                scrollWheelZoom: $scope.active,
                dragging: $scope.active
            };
            var markerDefaultOptions = {
                draggable: false,
                clickable: true,
                title: ''
            };
            var currentMap; // Mapobject
            var bounds = []; // Array of all positions
            var locations = []; // All Locations
            var locationPopupTemplate = '<b>Error loading template</b>';
            var buildings = []; // All Buildings
            var buildingPopupTemplate = '<b>Error loading template</b>';
            var markerLayer = null; // Layer for markers
            var markers = []; // Markers

            var iconOk = L.icon({
                iconUrl: 'assets/ci/cyberhub-light/images/icon_map_location_ok.png',
                shadowUrl: 'assets/ci/cyberhub-light/images/marker-shadow.png',
                iconSize: [25, 41],
                shadowSize: [41, 41],
                iconAnchor: [12, 41],
                shadowAnchor: [12, 41],
                popupAnchor: [0, -41]
            });
            var iconError = L.icon({
                iconUrl: 'assets/ci/cyberhub-light/images/icon_map_location_error.png',
                shadowUrl: 'assets/ci/cyberhub-light/images/marker-shadow.png',
                iconSize: [25, 41],
                shadowSize: [41, 41],
                iconAnchor: [12, 41],
                shadowAnchor: [12, 41],
                popupAnchor: [0, -41]
            });

            (function () {
                $scope.mapId = ++mapIndex;
                if ($scope.isreportwidget) {
                    $timeout(function () {
                        var titleH = $($element).find('[ng-if="!active && isreportwidget"]').innerHeight();
                        titleH += parseInt($($element).find('[ng-if="!active && isreportwidget"]').css('margin-top'));
                        titleH += parseInt($($element).find('[ng-if="!active && isreportwidget"]').css('padding-top'));
                        titleH += parseInt($($element).find('[ng-if="!active && isreportwidget"]').css('margin-bottom'));
                        titleH += parseInt($($element).find('[ng-if="!active && isreportwidget"]').css('padding-bottom'));
                        var elMap = $($element).find('.angular-leaflet-map');
                        $(elMap).css("width", "100%").css("width", "-=2px");
                        $(elMap).css("height", "100%").css("height", "-=" + titleH + "px");
                        $(elMap).css("pointer-events", "none");

                        _refreshMap();

                        $timeout(function () {
                            leafletImage(currentMap, function (err, cnv) {
                                $(elMap).hide().before(cnv);
                            });
                        }, 500);
                    });
                }
            })();

            ctrl.$onInit = function () {
                L.Icon.Default.imagePath = 'assets/ci/cyberhub-light/images';

                var queue = [];
                queue.push($templateRequest('scripts/app/dashboard/widget.templates/locationmap/location.popup.html').then(function (response) {
                    locationPopupTemplate = response;
                }, function (error) {
                    $log.error('Couldn\'t load location popup template!', error);
                }));

                queue.push($templateRequest('scripts/app/dashboard/widget.templates/locationmap/building.popup.html').then(function (response) {
                    buildingPopupTemplate = response;
                }, function (error) {
                    $log.error('Couldn\'t load building popup template!', error);
                }));

                queue.push(leafletData.getMap('map' + $scope.mapId).then(function (map) {
                    currentMap = map;
                    // currentMap.on('zoom', _onZoom);
                }, function (error) {
                    $log.error('Couldn\'t get map instance!', error);
                }));

                $q.all(queue).then(function () {
                    _load();
                });

                var parent = $scope.$parent.$parent.$parent;
                if (parent) {
                    parent.onResizeStop = function () {
                        _refreshMap();
                    }
                }

                var abc = $($element).find('.angular-leaflet-map');
                if (!$scope.isreportwidget || !$scope.active) abc.css("position", "absolute");
            };

            ctrl.$onDestroy = function () {
                _cleanUp();
            };

            function _cleanUp() {
                bounds = [];
                markers = [];
                if (markerLayer !== undefined && markerLayer !== null) {
                    markerLayer.remove();
                }
                markerLayer = null;
            }

            ctrl.reloadLocations = function () {
                _cleanUp();
                _load();
            };

            function _refreshMap() {
                currentMap.invalidateSize();
                // Center map & zoom on bounds
                if (bounds.length > 0) {
                    currentMap.fitBounds(bounds);
                }
            }

            function _zoomMap(location) {
                var zoomBounds = [];
                if (location.hasBuildings) {
                    var locationBuildings = _getBuildings(location);
                    for (var i = 0; i < locationBuildings.length; i++) {
                        if (locationBuildings[i].lat !== null && locationBuildings[i].lat !== undefined
                            && locationBuildings[i].lat >= -90 && locationBuildings[i].lat <= 90
                            && locationBuildings[i].lon !== null && locationBuildings[i].lon !== undefined
                            && locationBuildings[i].lon >= -180 && locationBuildings[i].lon <= 180) {
                            zoomBounds.push(L.latLng(locationBuildings[i].lat, locationBuildings[i].lon));
                        }
                    }
                }
                if (zoomBounds.length === 0) {
                    zoomBounds.push(L.latLng(location.pos_lat, location.pos_lng));
                }
                currentMap.fitBounds(zoomBounds);
            }

            function _addLocationMarker(location) {
                // Push into 'bounds' for bounding box
                if(Tools.isDefinedNotNull(location.pos_lat) && Tools.isDefinedNotNull(location.pos_lng)){
                    var latlng = L.latLng(location.pos_lat, location.pos_lng);
                    bounds.push(latlng);
                }
                // Default marker options
                var options = angular.copy(markerDefaultOptions);
                var title = location.name;
                if (Tools.isDefinedNotNull(location.place)) {
                    title = title + ', ' + location.place;
                }
                options['title'] = title;
                // Create marker
                var marker = L.marker(latlng, options);
                var popup = L.popup.angular({
                    template: locationPopupTemplate,
                    controller: function () {},
                    controllerAs: '$ctrl'
                }).setContent({
                    'location': location,
                    'zoomIn': _zoomMap,
                    'map': currentMap
                });

                marker.bindPopup(popup);
                marker.data = location;
                marker.setIcon(location.alarmCount > 0 ? iconError : iconOk);

                markers.push(marker);
            }

            function _addBuildingMarker(building) {
                if (typeof building.lat === "number" && typeof building.lon === "number") {
                    var latlng = L.latLng(building.lat, building.lon);
                    bounds.push(latlng);
                    // Default marker options
                    var options = angular.copy(markerDefaultOptions);
                    var title = building.name;
                    var location = _getLocation(building.fkLocation);
                    if (Tools.isDefinedNotNull(location)) {
                        title = location.name + ', ' + title;
                    }

                    options.title = title;
                    var marker = L.marker(latlng, options);
                    var popup = L.popup.angular({
                        template: buildingPopupTemplate,
                        controller: function () {},
                        controllerAs: '$ctrl'
                    }).setContent({
                        'location': location,
                        'zoomIn': _zoomMap,
                        'building': building
                    });

                    marker.bindPopup(popup);
                    marker.data = building;
                    marker.setIcon(building.alarmCount > 0 ? iconError : iconOk);

                    markers.push(marker);
                }
            }

            function _buildLocationLayer() {
                // Create markers for every locations
                for (var i = 0; i < locations.length; i++) {
                    if (Tools.isDefinedNotNull(locations[i].pos_lat) && Tools.isDefinedNotNull(locations[i].pos_lng)) {
                        locations[i].hasBuildings = _checkBuildings(locations[i]);
                        if (!locations[i].hasBuildings) {
                            _addLocationMarker(locations[i]);
                        }
                    }
                }
            }

            function _buildBuildingLayer() {
                for (var i = 0; i < buildings.length; i++) {
                    _addBuildingMarker(buildings[i]);
                }
            }

            function _load() {
                var queue = [];
                queue.push(Location.query().then(function (response) {
                    locations = response.data;
                    $log.debug('Loaded locations: ', response.data);
                }, function (error) {
                    $log.error('Couldn\'t load locations!', error);
                }));

                queue.push(Buildings.getAll().then(function (response) {
                    buildings = response.data;
                    $log.debug('Loaded buildings: ', response.data);
                }, function (error) {
                    $log.error('Couldn\'t load buildings!', error);
                }));

                $q.all(queue).then(function () {
                    _buildLocationLayer();
                    _buildBuildingLayer();

                    markerLayer = L.layerGroup(markers);
                    markerLayer.addTo(currentMap);

                    currentMap.invalidateSize();
                });
            }

            // Check if a location contains buildings
            function _checkBuildings(location) {
                for (var i = 0; i < buildings.length; i++) {
                    if (buildings[i].fkLocation === location.id && typeof buildings[i].lat === "number" && typeof buildings[i].lon === "number") {
                        return true;
                    }
                }
                return false;
            }

            function _getBuildings(location) {
                var result = [];
                for (var i = 0; i < buildings.length; i++) {
                    if (buildings[i].fkLocation === location.id) {
                        result.push(buildings[i]);
                    }
                }
                return result;
            }

            function _getLocation(locationId) {
                for (var i = 0; i < locations.length; i++) {
                    if (locations[i].id === locationId) {
                        return locations[i];
                    }
                }
                return null;
            }
        }

        return {
            restrict: 'E',
            controller: ['$scope', '$log', '$element', '$timeout', '$q', '$templateRequest', 'Location', 'Tools', 'leafletData', 'Alarm', 'Buildings', mapController],
            controllerAs: '$ctrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                widget: '=',
                active: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/locationmap/widget.locationmap.template.html'
        };
    });
})();
