(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgetweather', ['$interval', 'dateFilter', '$log', function ($interval, $log) {

        function link(scope, element, attrs, controller) {
            var timeoutId;

            function updateWeather() {
                controller.updateWeather(angular.element(element));
            }

            element.on('$destroy', function () {
                $interval.cancel(timeoutId);
            });

            // start the UI update process; save the timeoutId for canceling
            timeoutId = $interval(function () {
                updateWeather();
            }, 30000);

            //Initial call so that the date will be displayed immediately
            updateWeather();
        }

        return {
            restrict: 'E',
            controller: 'WidgetWeatherController',
            controllerAs: 'weatherCtrl',
            scope: {
                index: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                widget: '='
            },
            templateUrl: 'scripts/app/dashboard/widget.templates/weather/widget.weather.template.html',
            link: link
        };
    }]);
})();
