'use strict';

/**
 * @description Constructor to create building-room object
 * @param {number} fkRoom the id of a room
 * @param {number} fkBuilding the id of a building
 * @constructor
 */
var BuildingRoom = function (fkRoom, fkBuilding) {
    this.fkRoom = fkRoom;
    this.fkBuilding = fkBuilding;
};
BuildingRoom.prototype.constructor = BuildingRoom;

/**
 * @description Function to create a building-room object from json data object provided by backend
 * @param {object} obj the json data object to use
 * @returns {BuildingRoom} returns the created building-room object
 */
BuildingRoom.parseFromHtmlObject = function (obj) {
    return new BuildingRoom(obj.fkRoom, obj.fkBuilding);
};
