(function () {
    'use strict';

    angular.module("emsv2App").controller("PartlibController", function ($state, $stateParams, $rootScope, $translate,
                                                                         PartlibService, GenDialogService, Notify, parts, user, Tools, Principal) {
        var ctrl = this;
        ctrl.activeTab = 0;

        var setupParts = function (cparts) {
            ctrl.assets = cparts.asset.filter(function (p) {return p.customEntity == true;});
            ctrl.racks = cparts.rack.filter(function (p) {return p.customEntity == true;});
            ctrl.coolings = cparts.cooling.filter(function (p) {return p.customEntity == true;});
            ctrl.upss = cparts.ups.filter(function (p) {return p.customEntity == true;});
            ctrl.slots = cparts.slot.filter(function (p) {return p.customEntity == true;});
            ctrl.tiles = cparts.floortile.filter(function (p) {return p.customEntity == true;});
        };

        ctrl.$onInit = function () {
            setupParts(parts);
            if (Tools.isDefinedNotNull($stateParams.objectType)) {
                $stateParams.objectType = parseInt($stateParams.objectType);
                ctrl.activeTab = PartlibService.convertTypeToTab($stateParams.objectType);
            }
        };

        ctrl.hasRole = function (role) {
            var roles = Principal.isInRole('ROLE_' + role);
            return roles;
        };

        ctrl.editPart = function (type, partId) {
            $state.go("partlib.edit", {editType: type, id: partId});
        };

        ctrl.deletePart = function (type, partId) {
            $translate(['global.dialog.head.warning', 'partlib.deleteQ', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                GenDialogService.showDialog(false, {
                    headText: trans['global.dialog.head.warning'],
                    headIcon: 'glyphicon glyphicom-warning-sign',
                    messageText: trans['partlib.deleteQ'],
                    showClose: true,
                    textButton1: trans['global.btn.delete'],
                    textButton0: trans['global.btn.cancel'],
                    iconButton1: 'glyphicon glyphicon-remove-circle',
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton1: 'btn-delete',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        PartlibService.deletePart(type, partId).then(function (response) {
                            Notify.success("global.notification.success.title", "partlib.notifications.deleteSuccess", 2000);
                            PartlibService.getAllParts().then(function (response) {
                                setupParts(response);
                            });
                        }, function (error) {
                            Notify.error("global.notification.error.title", "partlib.notifications.deleteError", 2000);
                        });
                        GenDialogService.hideDialog();
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            });

        };

        ctrl.newPart = function () {
            $state.go('partlib.create', {type: ctrl.activeTab});
        };

        ctrl.goBack = function () {
            $rootScope.back();
        };

    });
})();
