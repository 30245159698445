(function () {
    'use strict';

    function momentFormat() {
        return function (input, format) {
            return moment(input).format(format);
        };
    }

    angular
        .module('emsv2App')
        .filter('momentFormat', momentFormat);

})();
