(function () {
    'use strict';
    angular.module('emsv2App').controller('HeatmapEditController', function ($scope, $state, $translate, HeatmapService, PhysicalTypeService, $log, $uibModal, Notify, Principal) {
        var ctrl = this;
        var modalInstance;
        var gradientWidthFactor = 0.95;// DL change gradient width here

        ctrl.alerts = [];
        ctrl.selectedPhysType = null;
        ctrl.create = $state.current.data.create ? $state.current.data.create : false;
        ctrl.edit = $state.current.data.edit;
        ctrl.gradientWidth = $('#colorTable').width() * gradientWidthFactor;
        ctrl.mapOrg = null;
        ctrl.temperaturePhysType = "";

        ctrl.spOpts = {
            showInput: true,
            preferredFormat: 'hex',
            allowEmpty: false,
            clickoutFiresChanges: true,
            cancelText: $translate.instant("global.btn.cancel"),
            chooseText: $translate.instant("global.btn.choose")
        };

        if (ctrl.create) {
            ctrl.map = {name: "", physicalType: {}, minValue: 0, maxValue: 0, heatmapValues: [], id: -1};
            ctrl.mapOrg = $.extend(true, {}, ctrl.map);
        } else {
            HeatmapService.getHeatmap($state.params.id).then(function (response) {
                    ctrl.map = response.data;
                    orderValues();
                    ctrl.mapOrg = $.extend(true, {}, ctrl.map);
                    ctrl.selectedPhysType = ctrl.map.physicalType.id;
                },
                function (error) {
                    $state.go("notfound");
                });
        }
        ctrl.closeModal = function (ignoreChanges) {
            modalInstance.close();
            if (ignoreChanges && (toStateName !== null || true)) {
                ignoreNextStateChange = ignoreChanges;
                $state.go(toStateName);
            }
        };
        ctrl.openModal = function () {
            modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/admin/heatmap/edit/heatmap.modal.html',
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        };
        PhysicalTypeService.getPhysicalTypes().then(function (response) {
            ctrl.physTypes = angular.merge([], response);
            for (var i = ctrl.physTypes.length - 1; i >= 0; i--) {
                if (ctrl.physTypes[i].id != 1 && ctrl.physTypes[i].id != 2 && ctrl.physTypes[i].id != 5) {
                    ctrl.physTypes.splice(i, 1);
                }
            }
        }, function (error) {
            $log.error("Error retrieving phys types\n" + error);
        });
        Principal.identity().then(function (user) {
            $scope.user = user;
            if(user.settings[1] && user.settings[1] !== undefined) {
                if (user.settings[1].value === "0") {
                    ctrl.temperaturePhysType = "°C";
                } else if (user.settings[1].value === "1") {
                    ctrl.temperaturePhysType = "°F";
                } else if (user.settings[1].value === "2") {
                    ctrl.temperaturePhysType = "K";
                } else {
                    ctrl.temperaturePhysType = "°C";
                }
            }
        }, function (error) {
            $log.error('Couldn\'t load user: ', error);
        });
        ctrl.updateminmax = function () {
            var min = Infinity;
            var max = -Infinity;
            for (var i = 0; i < ctrl.map.heatmapValues.length; i++) {
                if (ctrl.map.heatmapValues[i].key > max) max = ctrl.map.heatmapValues[i].key;
                if (ctrl.map.heatmapValues[i].key < min) min = ctrl.map.heatmapValues[i].key;
            }
            ctrl.map.minValue = min;
            ctrl.map.maxValue = max;
            ctrl.checkChanges();
        };

        var orderValues = function () {
            ctrl.map.heatmapValues.sort(function (a, b) {
                return a.key - b.key;
            });
        };

        ctrl.handleValueKeyPressed = function (e) {
            if (e.keyCode == 13) orderValues();
        };

        ctrl.handleKeyValueBlur = function () {
            orderValues();
        };

        ctrl.updateMapUnit = function () {
            angular.forEach(ctrl.physTypes, function (value) {
                if (value.id === ctrl.selectedPhysType) {
                    ctrl.map.physicalType = value;
                    ctrl.map.physicalTypeId = value.id;
                }
            });
        };

        ctrl.addColor = function () {
            ctrl.map.heatmapValues.push({key: null, color: '#ffffff', id: -1});
            ctrl.checkChanges();
        };
        ctrl.deleteColor = function (color) {
            var index = -1;
            for (var i = 0; i < ctrl.map.heatmapValues.length; i++) {
                if (ctrl.map.heatmapValues[i].key == color.key && ctrl.map.heatmapValues[i].color == color.color) {
                    index = i;
                    break;
                }
            }
            ctrl.map.heatmapValues.splice(index, 1);
            ctrl.updateminmax();
        };
        ctrl.goBack = function () {
            $state.go('heatmap');
        };
        ctrl.activateEdit = function () {
            $state.go('heatmap.edit');
        };
        ctrl.closeAlert = function (index) {
            ctrl.alerts.splice(index, 1);
        };

        ctrl.saveHeatmap = function () {
            var hasErrors = _inputsHaveErrors();
            if (hasErrors.inputs) {
                Notify.error("global.notification.error.title", "global.notification.error.heatmap", 2000);
                return;
            }
            if (hasErrors.values) {
                Notify.error("global.notification.error.title", "global.notification.error.heatmapColors", 2000);
                return;
            }
            //TODO refactor to work with then
            HeatmapService.saveHeatmap(ctrl.map)
                .success(function (response) {
                    Notify.success("global.notification.success.title", "global.notification.success.heatmap", 2000);
                    ctrl.changesPresent = false;
                    $state.go('heatmap');
                })
                .error(function (response) {
                    $log.error("Error saving heatmap\n", response);
                    Notify.error("global.notification.error.title", "global.notification.error.heatmap", 2000);
                });

        };
        ctrl.heatmapToDeleteID = -1;
        ctrl.setHeatmapToDelete = function () {
            ctrl.heatmapToDeleteID = ctrl.map.id;
            $translate('heatmap.deleteMsg').then(function (value) {
                ctrl.deleteMsg = value;
                ctrl.deleteMsg = ctrl.deleteMsg.replace(/nameDummy/g, '<b class="text-danger text-">' + ctrl.map.name + '</b>');
                $('#heatmapEditModal').modal('show');
            }, function () {
            });
        };
        ctrl.deleteHeatmap = function () {
            //TODO refactor to work with then
            HeatmapService.deleteHeatmap(ctrl.map)
                .success(function (response) {
                    if (response == 1) {
                        HeatmapService.getHeatmaps()
                            .success(function (response) {
                                ctrl.heatmapToDeleteID = -1;
                                $('#heatmapEditModal').modal('hide').on('hidden.bs.modal', function () {
                                    $state.go('heatmap');
                                });
                            })
                            .error(function (response) {
                                $log.error("Error retrieving heatmap data\n" + response);
                            });
                    }
                })
                .error(function (response) {
                    $log.error("Error delete heatmap\n" + response);
                });
        };

        var _inputsHaveErrors = function () {
            var input = null;
            var hasErrors = {
                inputs: false,
                values: false
            };
            if (ctrl.map.name === null || ctrl.map.name === "") {
                input = angular.element(document.querySelector('#heatmapNameGroup'));
                input.addClass("has-error");
                hasErrors.inputs = true;
            } else {
                input = angular.element(document.querySelector('#heatmapNameGroup'));
                input.removeClass("has-error");
            }
            if (ctrl.selectedPhysType === null) {
                input = angular.element(document.querySelector('#heatmapTypeGroup'));
                input.addClass("has-error");
                hasErrors.inputs = true;
            } else {
                input = angular.element(document.querySelector('#heatmapTypeGroup'));
                input.removeClass("has-error");
            }
            if (ctrl.map.heatmapValues.length <= 0) hasErrors.values = true;
            for (var i = 0; i < ctrl.map.heatmapValues.length; i++) {
                var currentValue = ctrl.map.heatmapValues[i];
                if (!angular.isDefined(currentValue.color) || currentValue.color === null) {
                    hasErrors.values = true;
                }
                if (!angular.isDefined(currentValue.key) || currentValue.key === null) {
                    hasErrors.values = true;
                }
            }
            return hasErrors;
        };

        ctrl.changesPresent = false;
        ctrl.checkChanges = function () {
            ctrl.changesPresent = checkChangesPresent();
        };
        $scope.$watch('ctrl.map', function (n, o) {
            if (n !== undefined) {
                ctrl.checkChanges();
            }
        }, true);

        var ignoreNextStateChange = false;
        var toStateName;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (ctrl.changesPresent && !ignoreNextStateChange) {
                toStateName = toState.name;
                event.preventDefault();
                ctrl.openModal();
            }
        });

        var checkChangesPresent = function () {
            if (ctrl.map.name !== ctrl.mapOrg.name) return true;
            if (ctrl.map.physicalType.id !== ctrl.mapOrg.physicalType.id) return true;
            if (ctrl.map.heatmapValues.length !== ctrl.mapOrg.heatmapValues.length) return true;
            for (var i = 0; i < ctrl.map.heatmapValues.length; i++) {
                if (ctrl.map.heatmapValues[i].key !== ctrl.mapOrg.heatmapValues[i].key) return true;
                if (ctrl.map.heatmapValues[i].color !== ctrl.mapOrg.heatmapValues[i].color) return true;
            }
            return false;
        }

        $(window).on("resize", function () {
            ctrl.gradientWidth = $('#colorTable').width() * gradientWidthFactor;
            $scope.$apply();
        });
        ctrl.$onInit = function () {
            Principal.identity().then(function (user) {
                $scope.user = user;
                if(user.settings[1] && user.settings[1] !== undefined) {
                    if (user.settings[1].value === "0") {
                        ctrl.temperaturePhysType = "°C";
                    } else if (user.settings[1].value === "1") {
                        ctrl.temperaturePhysType = "°F";
                    } else if (user.settings[1].value === "2") {
                        ctrl.temperaturePhysType = "K";
                    } else {
                        ctrl.temperaturePhysType = "°C";
                    }
                }
            }, function (error) {
                $log.error('Couldn\'t load user: ', error);
            });
        };

        $scope.$on('$destroy', function () {
            $(window).off("resize");
        });


    });
})();
