(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name contactsOverview
     * @requires $log, Contacts
     */
    angular.module('emsv2App')
        .component('contactsOverview', {
            templateUrl: 'scripts/app/contacts/overview/contacts-overview.component.html',
            controller: ['$log', 'Contacts', 'GenDialogService', '$translate', '$timeout', 'Notify', function ($log, Contacts, GenDialogService, $translate, $timeout, Notify) {
                var ctrl = this;
                ctrl.page = 0;
                ctrl.data = [];
                ctrl.query = '';
                ctrl.hasMore = true;
                ctrl.doQuery = function () {
                    return _query(false);
                };
                ctrl.tableModel = {
                    tableName: 'contact',
                    translation: 'contacts.table',
                    orderBy: 'lastname',
                    orderDir: false,
                    doFilter: _doFilter,
                    head: [
                        {empty: true},
                        {empty: true},
                        {name: 'firstname', translation: 'contacts.table.firstname'},
                        {name: 'lastname', translation: 'contacts.table.lastname'},
                        {name: 'company', translation: 'contacts.table.company'},
                        {name: 'email', translation: 'contacts.table.email'},
                        {name: 'mobile', translation: 'contacts.table.mobile', sortable: false},
                        {name: 'zip', translation: 'contacts.table.zip'},
                        {name: 'location', translation: 'contacts.table.location'},
                        {empty: true}
                    ]
                };

                function _query(reset) {
                    ctrl.page = reset ? 0 : ctrl.page + 1;

                    return Contacts.rsql(ctrl.page, ctrl.query).then(function (response) {
                        ctrl.hasMore = response.data.length === 20;
                        if (!reset) {
                            for (var i = 0; i < response.data.length; i++) {
                                ctrl.data.push(response.data[i]);
                            }
                        }
                        else {
                            ctrl.data = response.data;
                        }
                    }, function (error) {
                        $log.error('Couldn\'t load contacts!', error);
                        ctrl.data = [];
                    });
                }

                function _doFilter(params) {
                    ctrl.query = params;
                    _query(true);
                }

                ctrl.checkedCount = function () {
                    return ctrl.data.filter(function (contact) {
                        return contact.isChecked;
                    }).length;
                };

                ctrl.uncheckAll = function () {
                    angular.forEach(ctrl.data, function (x) {
                        x.isChecked = false;
                    });
                };

                ctrl.deleteSelectionModal = function() {
                    $translate(['contacts.action.delselection', 'contacts.action.delSelectQuest', 'global.btn.cancel', 'global.btn.delete']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['contacts.action.delselection'],
                            messageText: trans['contacts.action.delSelectQuest'],
                            showClose: false,
                            textButton0: trans['global.btn.cancel'],
                            iconButton0: 'glyphicon glyphicon-remove',
                            classButton0: 'btn-close',
                            callbackButton0: function() {
                                GenDialogService.hideDialog();
                            },
                            textButton1: trans['global.btn.delete'],
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            classButton1: 'btn-delete',
                            callbackButton1: function() {
                                GenDialogService.hideDialog();
                                var idList = [];
                                for (var i = 0; i < ctrl.data.length; i++) {
                                    if (ctrl.data[i].isChecked) {
                                        idList.push(ctrl.data[i].id);
                                    }
                                }
                                _delete(idList);
                            }
                        });
                    })
                };
                function _delete(idList) {
                    Contacts.deleteSelection(idList).then(function () {
                        $timeout(function () {
                            _query(true);
                            Notify.success("global.notification.success.title", "contacts.notification.success.deleted", 3000);
                        }, 3000);
                    }, function (error) {
                        $timeout(function () {
                            Notify.error("global.notification.error.title","contacts.notification.error.deleted",3000);
                        }, 3000);
                        $log.error('Couldn\'t delete current selection of contacts', error);
                    })
                };
            }]
        });

})();
