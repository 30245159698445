'use strict';

/**
 * @description Constructor to create new inner wall object
 * @param {Number} id the id to set
 * @param {String} name the name to set
 * @param {String} comment the comment to set
 * @param {String} inventoryNumber the inventory number to set
 * @param {String} serialNumber the serial number to set
 * @param {Number} type the type to set (solid wall(0) or cage(1))
 * @param {Number} posType the position type to set (on floor or on raised floor)
 * @param {Number} thickness the thickness to set
 * @param {Number} height the height to set
 * @param {Number} startX the x-component of wall start point
 * @param {Number} startY the y-component of wall start point
 * @param {Number} startZ the z-component of wall start point
 * @param {Number} endX the x-component of wall end point
 * @param {Number} endY the y-component of wall end point
 * @param {Number} endZ the z-component of wall end point
 * @param {Number} uid the unique id to set
 * @constructor
 */
function InnerWall(id, name, comment, inventoryNumber, serialNumber, type, posType, thickness, height, startX, startY, startZ, endX, endY, endZ, uid) {
    this.id = id !== undefined ? id : -1;
    this.uniqueId = uid !== undefined ? uid : -1;
    this.name = name !== undefined ? name : "";
    this.comment = comment !== undefined ? comment : "";
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.type = type !== undefined ? type : 0;
    this.posType = posType !== undefined ? posType : 2;
    this.thickness = thickness !== undefined ? thickness : 0.1;
    this.height = height !== undefined ? height : 2.0;
    this.start = {
        x: (startX !== undefined ? startX : 0),
        y: (startY !== undefined ? startY : 0),
        z: (startZ !== undefined ? startZ : 0)
    };
    this.end = {
        x: (endX !== undefined ? endX : 0),
        y: (endY !== undefined ? endY : 0),
        z: (endZ !== undefined ? endZ : 0)
    };
    this.start = new THREE.Vector3(this.start.x, this.start.y, this.start.z);
    this.end = new THREE.Vector3(this.end.x, this.end.y, this.end.z);

}

InnerWall.prototype = {};
InnerWall.prototype.constructor = InnerWall;

/**
 * @description Function to set negative id values to null (since it is to hard for backend to do this)
 */
InnerWall.prototype.resetIds = function () {
    if (this.id < 0) this.id = null;
    if (this.uniqueId < 0) this.uniqueId = null;
};

/**
 * @description Function to setup properties to allow backend to handle this object correctly
 */
InnerWall.prototype.parseForBackend = function () {
    this.startX = this.start.x;
    this.startY = this.start.y;
    this.startZ = this.start.z;

    this.endX = this.end.x;
    this.endY = this.end.y;
    this.endZ = this.end.z;
};

/**
 * @description Function to test equality of this object with the provided object
 * @param {Object} ci the object to use
 * @returns {boolean} returns true if this object and the provided object are equal, otherwise false
 */
InnerWall.prototype.equals = function (ci) {
    if (!(ci instanceof InnerWall)) return false;
    if (!this.start.equals(ci.start)) return false;
    if (!this.end.equals(ci.end)) return false;
    if (this.thickness !== ci.thickness) return false;
    if (this.height !== ci.height) return false;
    if (this.type !== ci.type) return false;
    if (this.posType !== ci.posType) return false;
    return true;
};

/**
 * @description Function to test for collisions
 * @param {Room} room the room object containing this inner wall
 * @returns {boolean} returns true if collision is detected, otherwise false
 */
InnerWall.prototype.checkCollide = function (room) {
    if (this.height > room.size.y && !room.hasRaisedFloor) return true;
    if (this.posType === 1 && this.height > room.size.y) return true;
    if (this.posType === 3 && this.height + room.getRaisedFloor().size.y > room.size.y) return true;
    return false;
};

/**
 * @description Function to validate this object
 * @param {Room} room this objects parent room
 * @returns {ErrorObject[]} returns array of error objects
 */
InnerWall.prototype.validate = function (room) {
    var errorList = [];

    if (this.id === undefined || this.id === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "id"));
    if (this.uniqueId === undefined || this.uniqueId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "uniqueId"));
    // if (this.name === undefined || this.name === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "name"));
    // if (this.comment === undefined || this.comment === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "comment"));
    if (this.type === undefined || this.type === null || this.type < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "type"));
    if (this.posType === undefined || this.posType === null || this.posType < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "posType"));
    if (this.thickness === undefined || this.thickness === null || this.thickness <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "thickness"));
    if (this.height === undefined || this.height === null || this.height <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "height"));
    if (this.start.equals(this.end)) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "start_end"));

    var raisedFloor = room.getRaisedFloor();
    var rfHeight = raisedFloor !== null ? raisedFloor.size.y : 0;

    if (this.posType !== 3) {
        if (this.height > room.size.y) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "height", {msg: "collide"}));
    }

    if (this.posType === 3) {
        if (this.height > room.size.y - rfHeight) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "height", {msg: "collide"}));
    }

    return errorList;
};
