var myApp = angular.module('emsv2App');

myApp.service('OIDService', function ($http, EMSConstants) {

    //first part for stulz oids
    var oidCommonPart = '1.3.6.1.4.1.29462.10.2.';

    var stulzConfig = {
        coolingMode: "1787",
        dehumiRunning: "1757",
        humiRunning: "6402",
        heatingRunning: ["4803", "4903", "5003", "5103", "6202", "6302"],
        localStop: "1002",
        generalError: "1.4.2.1.1.8",
        commonAlarm: "1.4.4.1.1.1.1010"
    };

    /**
     * @description function to set stulz specific information for given object and driver value
     * @param {DriverValue} dv the driver value to use
     * @param {object} obj the object to set stulz infos for
     */
    var checkDriverValueForInfo = function (dv, obj) {
        var helper = function (dv, oidPart) {
            if (dv.parameter.oidDataPoint !== null) {
                return dv.parameter.oidDataPoint.oid.indexOf(oidPart) !== -1;
            }
        };
        if (dv.value !== null) {
            if (helper(dv, "1787")) {
                obj.stulzInfo.coolingMode = dv.value;
                dv.display = false;
            }
            if (helper(dv, "1757")) {
                obj.stulzInfo.dehumiRunning = dv.value === 1 ? true : false;
                dv.display = false;
            }
            if (helper(dv, "6402")) {
                obj.stulzInfo.humiRunning = dv.value === 1 ? true : false;
                dv.display = false;
            }
            if (helper(dv, "1002")) obj.stulzInfo.localStop = dv.value === 1 ? true : false;
            if (helper(dv, "1.4.4.1.1.1.1010")) obj.stulzInfo.commonAlarm = dv.value === 1 ? true : false;
            if (helper(dv, "1.4.2.1.1.8") !== -1) obj.stulzInfo.generalError = dv.value === 1 ? true : false;
            if (helper(dv, "4803") || helper(dv, "4903") || helper(dv, "5003"), helper(dv, "5103") || helper(dv, "6202") || helper(dv, "6302")) {
                if (obj.stulzInfo.hasOwnProperty("heatingRunning") && obj.stulzInfo.heatingRunning) return;
                obj.stulzInfo.heatingRunning = dv.value === 1 ? true : false;
                dv.display = false;
            }
        }
    };

    /**
     * @description function to setup all stulz specific datapoints for given cooling unit
     * @param {object} obj the object to setup stulz specific information for
     */
    var setupStulzInfo = function (obj) {
        if (obj instanceof Cooling && obj.isStulz()) {
            obj.stulzInfo = {
                coolingMode: null,
                dehumiRunning: false,
                humiRunning: false,
                heatingRunning: false,
                localStop: false,
                generalError: false,
                commonAlarm: false
            };
            if (obj.driverValues !== "") {
                for (var dvi in obj.driverValues) {
                    var dv = obj.driverValues[dvi];
                    if (dv.parameter.oidId !== undefined && dv.parameter.oidId != null) {
                        checkDriverValueForInfo(dv, obj);
                    }
                }
            }
        }
    };

    /**
     * @description function to setup hidden parameters for stulz units (not displayed in info label)
     * @param {object} obj cooling unit object
     */
    var setupHiddenParams = function (obj) {
        for (var i = 0; i < obj.driverValues.length; i++) {
            if (!obj.driverValues[i].parameter.oidId) {
                obj.driverValues[i].display = true;
                return;
            }
            if (obj.driverValues[i].parameter.oidDataPoint !== null) {
                if (obj.driverValues[i].parameter.oidDataPoint.isAlarm) {
                    obj.driverValues[i].display = false;
                } else if (obj.driverValues[i].parameter.oidDataPoint.manDetOid != '') {
                    var manDetDV = obj.driverValues.filter(function (elem) {
                        if (!elem.parameter.oidId) return false;
                        return elem.parameter.oidDataPoint.oid == obj.driverValues[i].parameter.oidDataPoint.manDetOid;
                    })[0];
                    var manValDV = obj.driverValues.filter(function (elem) {
                        if (!elem.parameter.oidId) return false;
                        return elem.parameter.oidDataPoint.oid == obj.driverValues[i].parameter.oidDataPoint.manDpOid;
                    })[0];
                    if (manDetDV) {
                        manDetDV.display = false;
                        obj.driverValues[i].manOpActive = !manDetDV.value ? false : true;
                    }
                    if (manValDV) {
                        manValDV.display = false;
                    }
                    obj.driverValues[i].display = true;
                }
            } else if (obj.driverValues[i].hasOwnProperty("display") && obj.driverValues[i].display == false) {

            } else {
                obj.driverValues[i].display = true;
            }
        }

    };

    /**
     * @description function to find used alert parameters for provided object
     * @param {array} alerts array of alert data points to search in
     * @param {object} object the object check for alert data points
     */
    var setupAlertsForObject = function (alerts, object) {
        for (var i in alerts) {
            var foundDriverParam = object.driverValues.filter(function (dv) {
                return dv.parameter.oidId && dv.parameter.oidId == alerts[i].id;
            });
            if (foundDriverParam.length) {
                alerts[i].used = true;
            } else {
                alerts[i].used = false;
            }
        }
    };

    /**
     * @description function to get all oids from backend
     * @returns {HttpPromise} returns promise, handle yourself
     */
    var getAllOids = function () {
        return $http.get('/api/oid/getAll');
    };
    var getUnusedOids = function () {
        return $http.get('/api/oid/getUnusedOids');
    };

    /**
     * @description function to get alarm oids from backend
     * @returns {*}
     */
    var getAlarmOids = function () {
        return $http.get('/api/oid/getAlarms').then(function (response) {
            return response.data;
        }, function (error) {
            return error;
        });
    };

    return {
        getAllOids: getAllOids,
        getUnusedOids: getUnusedOids,
        setupStulzInfo: setupStulzInfo,
        setupHiddenParams: setupHiddenParams,
        getAlarmOids: getAlarmOids,
        setupAlertsForObject: setupAlertsForObject,
        stulzConfig: stulzConfig,
        oidCommonPart: oidCommonPart
    };
});

