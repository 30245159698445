/**
 * Database Backup
 * @ngdoc service
 * @name dbBackup
 * @requires $http, $log, $q
 */

angular.module('emsv2App').service('dbBackup', ['$http', '$log', '$q', function ($http) {
    return {
        getDatabaseSize: function () {
            return $http.get('api/databasebackup/databasesize');
        },
        getDiskSpaceInfo: function () {
            return $http.get('api/databasebackup/diskspaceinfo');
        },
        startBackup: function (path) {
            return $http.post('api/databasebackup/startBackup', path);
        },
        getTaskStatus: function () {
            return $http.get('api/databasebackup/taskstatus');
        },
        getHistory: function () {
            return $http.get('api/databasebackup/get');
        },
    }
}]);