(function () {
    'use strict';
    function errorConfigController($state, $scope, $stateParams, $interval, ErrorConfig, Location, $uibModal, errorConfigurations,
                                   isAdmin, hasViewRight, hasEditRight, hasCreateRight, hasDeleteRight, LocationDetailService, $translate) {
        var ctrl = this;
        var timeoutId;
        ctrl.isAdmin = isAdmin;                             //Rechte für die Inputs und Buttons in der view ...
        ctrl.hasViewRight = hasViewRight;
        ctrl.hasEditRight = hasEditRight;
        ctrl.hasCreateRight = hasCreateRight;
        ctrl.hasDeleteRight = hasDeleteRight;

        ctrl.tableHead = ['location.alarms.alarmConfigTable.prio', 'location.alarms.alarmConfigTable.active', 'location.alarms.alarmConfigTable.name', 'location.alarms.alarmConfigTable.message'];
        ctrl.activeTableHead = ctrl.tableHead;
        ctrl.errorconfigsearch = '';
        ctrl.sortAsc = null;
        $scope.modal = {        // Modal (definition von Methoden close und deleteErrorConfig)
            view: null,
            obj: null,
            close: function () {
                modal.close();
            },
            deleteErrorConfig: function () {
                ErrorConfig.deleteErrorConfiguration($scope.modal.obj.id).then(function (response) {
                    $state.go('location.alarm.errorConfig.list');
                    $state.reload();
                });
            }
        };
        var modal = null;
        ctrl.errorConfigurations = errorConfigurations;
        //ctrl.errorConfigurations = [];
        ctrl.deleteErrorConfiguration = function (obj) {
            $scope.modal.obj = obj;
            modal = $uibModal.open({
                animation: false,
                templateUrl: "scripts/app/location/tabs/alarm/errorconfig/errorconfig.delete.html",
                windowClass: 'animated fadeInDown',
                scope: $scope, // scope wird hier übergeben, im template wird dann z.B. modal.deleteErrorConfig bei einem ng-click ausgeführt
                size: 'lg'
            });
        };
        // viewErrorConfig , editErrorConfig, addErrorConfig gehen jeweils auf den state location.alarm.errorConfig.edit mit unterschiedlichen Parametern
        // viewErrorConfig mit mode = "view"
        // editErrorConfig  und viewErrorConfig -> Id des Elements wird mitgegeben - alarm.js lädt in dem resolve das Element
        // miithilfe der Id nach und gibt es dem errorConfigEditController als Parameter mit
        // addErrorConfig -> der $stateParam editElement wird mit dem Wert null übergeben dem errorConfigEditController signalisiert das es sich hierbei
        // um ein neues Element handelt.
        ctrl.viewErrorConfig = function (obj) {
            $state.go('location.alarm.errorConfig.edit', {editElement: obj.id, mode: "view"});
        };
        ctrl.editErrorConfig = function (obj) {
            $state.go('location.alarm.errorConfig.edit', {editElement: obj.id, mode: "edit"});
        };
        ctrl.addErrorConfig = function () {
            $state.go('location.alarm.errorConfig.edit', {editElement: null, mode: "edit"});
        };

        ctrl.filterErrorConfig = function(obj){ // Filtern der Liste
            if(ctrl.errorconfigsearch.length>0){
                if (obj.priority.toString().toUpperCase().contains(ctrl.errorconfigsearch.toUpperCase())) return true;
                if (obj.name.toUpperCase().contains(ctrl.errorconfigsearch.toUpperCase())) return true;
                if (obj.message.toUpperCase().contains(ctrl.errorconfigsearch.toUpperCase())) return true;
            }else{
                return true;
            }
        };

        // Sortierfunktionen von MAS
        ctrl.sortCols = function (col) {
            var entityFieldName = getEntityFieldName(col);

            if (entityFieldName !== ctrl.currCol) {
                ctrl.sortAsc = true;
                ctrl.currCol = entityFieldName;
            }
            else if (ctrl.sortAsc === true && ctrl.currCol === entityFieldName) {
                ctrl.sortAsc = false;
            }
            else if (ctrl.sortAsc === false && ctrl.currCol === entityFieldName) {
                ctrl.sortAsc = true;
            }
        };

        ctrl.getIcon = function (col) {
            var entityFieldName = getEntityFieldName(col);
            if (entityFieldName === ctrl.currCol) {
                if (ctrl.sortAsc) {
                    return 'glyphicon glyphicon-sort-by-attributes-alt m-l';
                }
                else {
                    return 'glyphicon glyphicon-sort-by-attributes m-l';
                }
            }
            else {
                return 'glyphicon glyphicon-sort m-l';
            }
        };

        function getEntityFieldName(col) {
            switch (col) {
                case 'priority':
                    return 'priority';
                case 'active':
                    return 'active';
                case 'name':
                    return 'name';
                case 'message':
                    return 'message';
            }
        }
        ctrl.$onInit = function () {
            ctrl.sortCols('priority');
            LocationDetailService.reset().setDefaultBackToOverview();
            timeoutId = $interval(function () {
                ErrorConfig.getErrorConfig($state.params.id).then(function (response){
                    ctrl.errorConfigurations = response;
                });
            }, 5000);
        };

        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
        });
    }

    angular.module('emsv2App').controller('errorConfigController', errorConfigController);
})();
