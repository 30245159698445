(function () {
    'use strict';

    function newTokenController($translate, $state, $scope, $window, $log, DateConstants, TokenService, User, Notify) {
        var ctrl = this;
        ctrl.token = {
            start: "",
            end: ""
        };

        ctrl.tokenForm = {
            created: {},
            expiry: {},
            description: {},
            revoke:{},
            delete:{},
            token : ''
        };

        ctrl.options = {
            singleDatePicker: true,
            startDate: moment().add(30, 'days'),
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: true,
            autoUpdateInput: true,
            autoApply: true,
            applyClass: 'btn-primary',
            locale: {
                format: $translate.instant('global.dateFormat'),
                daysOfWeek: DateConstants.getDaysOfWeek(),
                monthNames: DateConstants.getMonthNames()
            },
            eventHandlers: {
                'apply.daterangepicker': function () {
                    if (moment(ctrl.token.end).isBefore(ctrl.token.start)) {
                        $log.log("INVALID TIME SELECTED");
                        ctrl.timeInvalid = true;
                    } else {
                        ctrl.timeInvalid = false;
                        ctrl.onChange(ctrl.token.end);
                    }
                },
                'hide.daterangepicker': function () {
                    if (moment(ctrl.token.end).isBefore(ctrl.token.start)) {
                        $log.log("INVALID TIME SELECTED");
                        ctrl.timeInvalid = true;
                    } else {
                        ctrl.timeInvalid = false;
                    }
                }
            },
        }

        ctrl.$onInit = function () {
            ctrl.token = {
                start: moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS"),
                end: moment().add(30, 'days').format("YYYY-MM-DD[T]HH:mm:ss.SSS")
            };
        };


        ctrl.onChange = function (selectedValue) {
            ctrl.token.end = moment(selectedValue).format("YYYY-MM-DD[T]HH:mm:ss.SSS");
        }

        ctrl.create = function () {

            ctrl.tokenForm.created = ctrl.token.start;
            ctrl.tokenForm.expiry = ctrl.token.end;
            ctrl.tokenForm.description = ctrl.tokenForm.description.$modelValue;
            ctrl.tokenForm.revoke = false;
            ctrl.tokenForm.delete = false;
            ctrl.tokenForm.token = "";


            TokenService.getCurrentLoginUser().then(function (response) {

                ctrl.tokenForm.userId = response.data.id;

                TokenService.generateToken(ctrl.tokenForm).then(function (response) {
                    $log.debug("Toke successully generated ", response);

                    ctrl.saveBtn = 'success';
                    Notify.defaultSuccess();
                    $state.go('tokenOverview');
                }, function (error) {
                    $log.error("Token Generation Failed !!", error);
                    Notify.defaultError();
                })

            }, function (error) {
                $log.log("ERROR in fetching Current Logged User", error);
            })
        }
    }

    angular
        .module('emsv2App')
        .controller('NewTokenController', newTokenController);

})();