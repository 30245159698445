(function () {

    function WidgetSysteminfoController($scope, $uibModal, $log, $translate, WidgetData, $http, Notify, $interval, Tools) {

        var ctrl = this;
        var currentModal;

        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.sysInfo = {
            coresPhysical: 0,
            coresLogic: 0
        };
        ctrl.idForWidget = $scope.widget.wid !== null ? $scope.widget.wid : null;
        ctrl.idForRam = $scope.widget.wid !== null ? "ram" + $scope.widget.wid : null;
        ctrl.idForCpu = $scope.widget.wid !== null ? "cpu" + $scope.widget.wid : null;
        ctrl.currentRamUtilization = null;
        ctrl.currentRamUtilizationDisplay = null;
        ctrl.currentRamLimit = null;
        ctrl.progressRamWidth = null;
        ctrl.currentRamUtilizationPrograssBarColor = null;
        ctrl.cpuUsage = null;
        ctrl.cpuUsageDisplay = null;
        ctrl.cpuUsagePrograssBarColor = "";
        ctrl.freeSpaceTemplate = [];
        ctrl.freeSpaceTemplateShowAlert = [];
        ctrl.freeSpaceModal = [];
        ctrl.freeSpaceModalShowAlert = [];
        ctrl.freeSpaceClone = [];
        ctrl.freeSpaceCloneTemplate = [];
        ctrl.selectedPartitions = [];
        ctrl.selectedInterface = null;
        ctrl.showPreview = false;
        ctrl.selectedOptions = [];
        ctrl.showOptions = [
            {
                name: $translate.instant('dashboard.widget.systeminfo.modal.os.os'),
                id: 1
            }, {
                name: $translate.instant('dashboard.widget.systeminfo.modal.cpu.cpu_name'),
                id: 2
            }, {
                name: $translate.instant('dashboard.widget.systeminfo.modal.cpu.cpu_cores'),
                id: 3
            }, {
                name: $translate.instant('dashboard.widget.systeminfo.modal.cpu.cpu_usage'),
                id: 4
            }, {
                name: $translate.instant('dashboard.widget.systeminfo.modal.ram'),
                id: 5
            }, {
                name: $translate.instant('dashboard.widget.systeminfo.modal.space.space'),
                id: 7
            }
        ];
        ctrl.sysInfoWidget = {
            os: false,
            cpuName: false,
            cpuCores: false,
            cpuUsage: false,
            ramUsage: false,
            space: false,
            interface: null
        };
        ctrl.showSelectedInterface = false;
        var intervals;

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl._configure);
            _loadSettings();
            _loadSysInfo();
        };

        ctrl._configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/systeminfo/widget.systeminfo.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
            _loadSettings();
            _loadSysInfo();
        };

        ctrl.closeModal = function () {
            if (currentModal) currentModal.dismiss('cancel');
        };

        ctrl.cancelModal = function () {
            ctrl.closeModal();
        };

        function _loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                ctrl.selectedOptions = [];
                for (var index = 0; index < $scope.widget.settings.length; index++) {
                    switch ($scope.widget.settings[index].key) {
                        case 'os':
                            if ($scope.widget.settings[index].value) {
                                ctrl.sysInfoWidget.os = true;
                                fillSelectedOptions(1);
                            }
                            break;
                        case 'cpuName':
                            if ($scope.widget.settings[index].value) {
                                ctrl.sysInfoWidget.cpuName = true;
                                fillSelectedOptions(2);
                            }
                            break;
                        case 'cpuCores':
                            if ($scope.widget.settings[index].value) {
                                ctrl.sysInfoWidget.cpuCores = true;
                                fillSelectedOptions(3);
                            }
                            break;
                        case 'cpuUsage':
                            if ($scope.widget.settings[index].value) {
                                ctrl.sysInfoWidget.cpuUsage = true;
                                fillSelectedOptions(4);
                            }
                            break;
                        case 'ram':
                            if ($scope.widget.settings[index].value) {
                                ctrl.sysInfoWidget.ramUsage = true;
                                fillSelectedOptions(5);
                            }
                            break;
                        case 'netInterfaceTitle':
                            if ($scope.widget.settings[index].value !== null) {
                                getInterface($scope.widget.settings[index].value);
                                fillSelectedOptions(6);
                            }
                            break;
                        case 'space':
                            if ($scope.widget.settings[index].value && ctrl.selectedPartitions.length !== 0) {
                                ctrl.sysInfoWidget.space = true;
                                fillSelectedOptions(7);
                            }
                            break;
                        case 'selectedPartition':
                            ctrl.selectedPartitions = JSON.parse($scope.widget.settings[index].value);
                            break;
                    }
                }
            }
        }

        function fillSelectedOptions(id) {
            ctrl.selectedOptions.push({id: id});
        }

        function getInterface(val) {
            $http.get('api/systeminfo').then(function (response) {
                for (var index = 0; index < response.data.interfaceList.length; index++) {
                    if (response.data.interfaceList[index].ip4 === val) {
                        ctrl.selectedInterface = response.data.interfaceList[index];
                        ctrl.showSelectedInterface = true;
                        ctrl.changeOptions();
                        break;
                    }
                }
            });
        }

        ctrl.changeOptions = function () {
            ctrl.showOptions = [];
            ctrl.showOptions.push({
                name: $translate.instant('dashboard.widget.systeminfo.modal.os.os'),
                id: 1
            });
            ctrl.showOptions.push({
                name: $translate.instant('dashboard.widget.systeminfo.modal.cpu.cpu_name'),
                id: 2
            });
            ctrl.showOptions.push({
                name: $translate.instant('dashboard.widget.systeminfo.modal.cpu.cpu_cores'),
                id: 3
            });
            ctrl.showOptions.push({
                name: $translate.instant('dashboard.widget.systeminfo.modal.cpu.cpu_usage'),
                id: 4
            });
            ctrl.showOptions.push({
                name: $translate.instant('dashboard.widget.systeminfo.modal.ram'),
                id: 5
            });

            if (ctrl.selectedInterface !== null) {
                ctrl.showPreview = true;

                ctrl.showOptions.push({
                    name: $translate.instant('dashboard.widget.systeminfo.modal.net_interface.net_interface_title'),
                    id: 6
                });

                ctrl.selectedOptions.push({
                    name: $translate.instant('dashboard.widget.systeminfo.modal.net_interface.net_interface_title'),
                    id: 6
                });

            } else {
                ctrl.showPreview = false;
                ctrl.showSelectedInterface = false;
            }

            ctrl.showOptions.push({
                name: $translate.instant('dashboard.widget.systeminfo.modal.space.space'),
                id: 7
            });
        };

        function _loadSysInfo() {
            $http.get('api/systeminfo').then(function (response) {
                ctrl.sysInfo = response.data;
                ctrl.currentRamLimit = ctrl.sysInfo.ram;
                if (angular.isDefined(intervals)) {
                    $interval.cancel(intervals);
                }
                intervals = $interval(function () {
                    if (!currentModal || (currentModal.result.$$state !== undefined && currentModal.result.$$state.value === "cancel")) {
                        _loadSettings();
                    }
                    _loadRam();
                    _loadCpu();
                    _loadSpaceModal();
                    _loadSpaceTemplate();
                }, 5000);
            });
        }

        function _loadRam() {
            $http.get("api/systeminfo/ram").then(function (response) {
                ctrl.currentRamUtilization = response.data;
                var elem = angular.element('.ram');
                var ram = elem[0];
                var elemModal = angular.element('#ram');
                var ramModal = elemModal[0];
               // var ramLimit = angular.copy(ctrl.currentRamLimit);
                ctrl.progressRamWidth = (100 / parseFloat(ctrl.currentRamLimit)) * ctrl.currentRamUtilization;
                if (Tools.isDefinedNotNull(ram)) {
                    ram.style.width = ctrl.progressRamWidth + "%";
                }
                if (Tools.isDefinedNotNull(ramModal)) {
                    ramModal.style.width = ctrl.progressRamWidth + "%";
                }
                displayProgress('#ram', ctrl.progressRamWidth);
                displayProgress('.ram', ctrl.progressRamWidth);
                ctrl.currentRamUtilizationDisplay = ctrl.currentRamUtilization + " / " + ctrl.currentRamLimit;
            });
        }

        function _loadSpaceTemplate() {
            $http.get("api/systeminfo/memory").then(function (response) {
                if (response.data) {
                    for (var index in response.data) {
                        if (response.data[index].estimatedTime[0] !== "" && response.data[index].estimatedTime[1] !== "") {
                            response.data[index].estimatedTime = String.fromCharCode(response.data[index].estimatedTime[0]) + " " +
                                response.data[index].estimatedTime[1] + " " + $translate.instant(response.data[index].estimatedTime[2]);
                        }
                        else {
                            response.data[index].estimatedTime = $translate.instant(response.data[index].estimatedTime[2]);
                        }
                    }
                    var isSame = JSON.stringify(response.data) === angular.toJson(ctrl.freeSpaceCloneTemplate);
                    if (ctrl.freeSpaceTemplate.length === 0 && ctrl.freeSpaceCloneTemplate.length === 0) isSame = false;
                    if (!isSame) {
                        ctrl.freeSpaceTemplate = [];
                        ctrl.freeSpaceCloneTemplate = [];
                        ctrl.freeSpaceTemplateShowAlert = [];
                        for (var index in response.data) {
                            var data = response.data[index];
                            var obj = {};
                            if (ctrl.selectedPartitions.length === 0 || ctrl.selectedPartitions.indexOf(data.partition) !== -1) {
                                if (data.estimatedTime === "") {
                                    ctrl.freeSpaceTemplateShowAlert.push({error: true});
                                }
                                else {
                                    var string = $translate.instant('dashboard.widget.systeminfo.modal.space.no_info');
                                    if (data.estimatedTime === string) {
                                        ctrl.freeSpaceTemplateShowAlert.push({error: true});
                                    }
                                    else {
                                        ctrl.freeSpaceTemplateShowAlert.push({error: false});
                                    }
                                }
                                obj = {
                                    partition: data.partition,
                                    leftSpace: data.leftSpace,
                                    estimatedTime: data.estimatedTime
                                };
                                ctrl.freeSpaceCloneTemplate.push(obj);
                                ctrl.freeSpaceTemplate.push(obj);
                            }
                        }
                    }
                }
            });
        }

        function _loadSpaceModal() {
            $http.get("api/systeminfo/memory").then(function (response) {
                if (response.data) {
                    for (var index in response.data) {
                        if (response.data[index].estimatedTime[0] !== "" && response.data[index].estimatedTime[1] !== "") {
                            response.data[index].estimatedTime = String.fromCharCode(response.data[index].estimatedTime[0]) + " " +
                                response.data[index].estimatedTime[1] + " " + $translate.instant(response.data[index].estimatedTime[2]);
                        }
                        else {
                            response.data[index].estimatedTime = $translate.instant(response.data[index].estimatedTime[2]);
                        }
                    }
                    var isSame = JSON.stringify(response.data) === angular.toJson(ctrl.freeSpaceClone);
                    if (ctrl.freeSpaceModal.length === 0 && ctrl.freeSpaceClone.length === 0) isSame = false;
                    if (!isSame) {
                        ctrl.freeSpaceModal = [];
                        ctrl.freeSpaceClone = [];
                        ctrl.freeSpaceModalShowAlert = [];
                        for (var index in response.data) {
                            var data = response.data[index];
                            var obj = {};
                            if (data.estimatedTime === "") {
                                ctrl.freeSpaceModalShowAlert.push({error: true});
                            }
                            else {
                                var string = $translate.instant('dashboard.widget.systeminfo.modal.space.no_info');
                                if (data.estimatedTime === string) {
                                    ctrl.freeSpaceModalShowAlert.push({error: true});
                                }
                                else {
                                    ctrl.freeSpaceModalShowAlert.push({error: false});
                                }
                            }
                            obj = {
                                partition: data.partition,
                                leftSpace: data.leftSpace,
                                estimatedTime: data.estimatedTime
                            };
                            ctrl.freeSpaceClone.push(obj);
                            ctrl.freeSpaceModal.push(obj);
                        }
                    }
                    if (ctrl.freeSpaceClone.length !== 0) {
                        for (var index in response.data) {
                            var checkBox = document.getElementById('inputFreeSpace' + $scope.widget.wid + index);
                            if (ctrl.selectedPartitions.indexOf(response.data[index].partition) !== -1) {
                                checkBox !== null ? checkBox.checked = true : null;
                            }
                        }
                    }
                }
            });
        }

        $scope.$on("$destroy", function () {
            if (angular.isDefined(intervals)) {
                $interval.cancel(intervals);
            }
        });

        ctrl.freeSpaceSelectedPartitions = function (partition, input) {
            if (input.target.checked) {
                if (ctrl.selectedPartitions.indexOf(partition) === -1) {
                    ctrl.selectedPartitions.push(partition);
                    ctrl.selectedOptions.push({
                        name: $translate.instant('dashboard.widget.systeminfo.modal.space.space'),
                        id: 7
                    });
                }
            }
            else {
                ctrl.selectedPartitions.splice(ctrl.selectedPartitions.indexOf(partition), 1);
                if (ctrl.selectedPartitions.length === 0) {
                    for (var index = 0; index <= ctrl.selectedOptions.length; index++) {
                        if (ctrl.selectedOptions.length > 0) {
                            if (index < ctrl.selectedOptions.length && ctrl.selectedOptions[index].id === 7) {
                                index = -1;
                                ctrl.selectedOptions.splice(ctrl.selectedOptions.indexOf(ctrl.selectedOptions[index]), 1);
                            }
                        }
                        else {
                            break;
                        }
                    }
                }
            }
            reloadModal();
        };

        function reloadModal() {
            ctrl.closeModal();
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/systeminfo/widget.systeminfo.modal.html',
                animation: false,
                scope: $scope
            });
        }

        function _loadCpu() {
            $http.get("api/systeminfo/cpuUsage").then(function (response) {
                ctrl.cpuUsage = response.data;
                var elem = angular.element('.cpu');
                var cpu = elem[0];
                var elemModal = angular.element('#cpu');
                var cpuModal = elemModal[0];
                displayProgress('.cpu', ctrl.cpuUsage);
                if (Tools.isDefinedNotNull(cpu)) cpu.style.width = ctrl.cpuUsage + "%";
                displayProgress('#cpu', ctrl.cpuUsage);
                cpuModal !== undefined ? cpuModal.style.width = ctrl.cpuUsage + "%" : null;
                ctrl.cpuUsageDisplay = ctrl.cpuUsage + "%";
            });
        }

        function displayProgress(selector, progress) {
            if (Tools.isDefinedNotNull(selector)) {
                if (progress < 70) {
                    if (!angular.element(selector).hasClass('progress-bar badge-information')) {
                        angular.element(selector).removeClass('progress-bar badge-danger');
                        angular.element(selector).addClass('progress-bar badge-information');
                    }
                }
                else {
                    if (!angular.element(selector).hasClass('progress-bar badge-danger')) {
                        angular.element(selector).removeClass('progress-bar badge-information');
                        angular.element(selector).addClass('progress-bar badge-danger');
                    }
                }
            }
        }

        ctrl.saveSettings = function () {
            var newSettings = [];
            if (ctrl.selectedOptions.length > 0) {
                for (var index = 0; index < ctrl.selectedOptions.length; index++) {
                    ctrl.sysInfoWidget = {
                        os: false,
                        cpuName: false,
                        cpuCores: false,
                        cpuUsage: false,
                        databaseUsage: false,
                        ramUsage: false,
                        space: false,
                        interface: null
                    };
                    ctrl.showSelectedInterface = false;
                    switch (ctrl.selectedOptions[index].id) {
                        case 1:
                            newSettings.push({key: 'os', value: true});
                            break;
                        case 2:
                            newSettings.push({key: 'cpuName', value: true});
                            break;
                        case 3:
                            newSettings.push({key: 'cpuCores', value: true});
                            break;
                        case 4:
                            newSettings.push({key: 'cpuUsage', value: true});
                            break;
                        case 5:
                            newSettings.push({key: 'ram', value: true});
                            break;
                        case 6:
                            ctrl.showSelectedInterface = true;
                            ctrl.selectedInterface !== null ? newSettings.push({
                                key: 'netInterfaceTitle',
                                value: ctrl.selectedInterface.ip4
                            }) : null;
                            break;
                        case 7:
                            if (ctrl.selectedPartitions.length !== 0) {
                                newSettings.push({
                                    key: 'selectedPartition',
                                    value: JSON.stringify(ctrl.selectedPartitions)
                                });
                                newSettings.push({key: 'space', value: true});
                            }
                            break;
                    }
                }
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
                if ($scope.isdashboardwidget) {
                    WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                        Notify.defaultSuccess();
                        ctrl.closeModal();
                        _loadSettings();
                    }, function (error) {
                        Notify.error("global.notification.error.title", "dashboard.widget.systeminfo.modal.saveErrorMsg", 2000);
                    });
                }
            }
        };
    }

    angular.module('emsv2App')
        .controller('widgetSysteminfoController', WidgetSysteminfoController)

})
();
