/**
 * Since the gridstack will only be used within the dashboard is has been organized below the dashboard itself.
 *
 * the files gridstack_directive, -controller and -item_directive are wrapping the library gridstack.js for angular usage.
 *
 * make sure that 'gridstack-angular' is injected into the main App
 *
 * dependency:
 * angular 1 (obviously)
 * gridstack.js (https://github.com/troolee/gridstack.js)
 * gridstack-angular (https://github.com/kdietrich/gridstack-angular)
 * lodash >= 3.5.0 (https://lodash.com/)
 * jquery >= 1.11.0 (https://jquery.com/)
 * jquery touch >= 1.11.0 (https://jquery.com/)
 * jquery touch-punch (https://github.com/furf/jquery-ui-touch-punch) -> for touch device support
 *
 * All packages are available at ether npm or bower. Consult provided links for further information.
 *
 */

(function () {
    'use strict';

    var app = angular.module('emsv2App');

    /** @ngInject */
    app.directive('gridstackItem', ['$timeout', '$rootScope','MessageService' , function ($timeout, $rootScope, MessageService) {

        return {
            restrict: 'A',
            controller: 'GridstackController',
            require: '^gridstack',
            scope: {
                gridstackItem: '=',
                onItemAdded: '&',
                onItemRemoved: '&',
                gsItemId: '=',
                gsItemX: '=',
                gsItemY: '=',
                gsItemWidth: '=',
                gsItemHeight: '=',
                gsItemAutopos: '=',
                gsItemResizable: '=',
                gsItemMinHeight: '=?',
                gsItemMaxHeight: '=?',
                gsItemMinWidth: '=?',
                gsItemMaxWidth: '=?'
            },
            link: function (scope, element, attrs, controller) {
                $(element).attr('data-gs-id', scope.gsItemId);
                $(element).attr('data-gs-x', scope.gsItemX);
                $(element).attr('data-gs-y', scope.gsItemY);
                $(element).attr('data-gs-width', scope.gsItemWidth);
                $(element).attr('data-gs-height', scope.gsItemHeight);
                if (!scope.gsItemResizable) {
                    $(element).attr('data-gs-no-resize', scope.gsItemResizable);
                    $(element).attr('data-gs-resize-handles', !scope.gsItemResizable);
                }
                $(element).attr('data-gs-auto-position', scope.gsItemAutopos);
                $(element).attr('data-gs-min-width', scope.gsItemMinWidth);
                $(element).attr('data-gs-min-height', scope.gsItemMinHeight);
                $(element).attr('data-gs-max-width', scope.gsItemMaxWidth);
                $(element).attr('data-gs-max-height', scope.gsItemMaxHeight);

                if (scope.gsItemId < 0) {
                    $(element).attr('data-gs-auto-position', 1);
                }
                else {
                    $(element).attr('data-gs-auto-position', 0);
                }

                var widget = controller.addItem(element);
                var item = element.data('_gridstack_node');
                $timeout(function() {
                    scope.onItemAdded({item: item});
                });

                var propertyChanged = function(newVal, oldVal) {
                    if(newVal != oldVal) {
                        controller.gridstack.update($(element), scope.gsItemX, scope.gsItemY, scope.gsItemWidth, scope.gsItemHeight);
                    }
                };

                scope.$watch(function() { return $(element).attr('data-gs-id'); }, function(val) {
                    scope.gsItemId = val;
                });

                scope.$watch(function() { return $(element).attr('data-gs-x'); }, function(val) {
                    scope.gsItemX = Number(val);
                });

                scope.$watch(function() { return $(element).attr('data-gs-y'); }, function(val) {
                    scope.gsItemY = Number(val);
                });

                scope.$watch(function() { return $(element).attr('data-gs-width'); }, function(val) {
                    scope.gsItemWidth = Number(val);
                    // MessageService.publish("widgetSizeChanged", {width:scope.gsItemWidth, height:scope.gsItemHeight, id:scope.gsItemId});
                    $rootScope.$broadcast("widgetSizeChanged", {width:scope.gsItemWidth, height:scope.gsItemHeight, id:scope.gsItemId});
                });

                scope.$watch(function() { return $(element).attr('data-gs-height'); }, function(val) {
                    scope.gsItemHeight = Number(val);
                    // MessageService.publish("widgetSizeChanged", {width:scope.gsItemWidth, height:scope.gsItemHeight, id:scope.gsItemId});
                    $rootScope.$broadcast("widgetSizeChanged", {width:scope.gsItemWidth, height:scope.gsItemHeight, id:scope.gsItemId});
                });

                scope.$watch('gsItemX', propertyChanged);
                scope.$watch('gsItemY', propertyChanged);
                scope.$watch('gsItemWidth', propertyChanged);
                scope.$watch('gsItemHeight', propertyChanged);

                element.bind('$destroy', function() {
                    var item = element.data('_gridstack_node');
                    scope.onItemRemoved({item: item});
                    controller.removeItem(element);
                });


            }

        };

    }]);
})();
