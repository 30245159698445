(function () {

    'use strict';

    function randomPassword() {
        var charset = {
            letter: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            special: '#&+!'
        };

        return {
            generate: function (len) {
                if (!angular.isNumber(len)) {
                    len = 8;
                }
                var chars = charset.letter + charset.special;
                var password = '';
                var rnd, rnd2, c, lastChar;

                while (len--) {
                    while (c === lastChar) {
                        rnd = Math.floor(Math.random() * chars.length);
                        rnd2 = Math.floor(Math.random() * chars.length);
                        c = rnd2 > chars.length / 2 ? chars[rnd].toUpperCase() : chars[rnd].toLowerCase();
                    }

                    lastChar = c;
                    password += c;
                }

                return password;
            }
        };
    }

    angular.module('emsv2App').service('RandomPassword', randomPassword);

})();
