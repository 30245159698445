(function () {
    'use strict';

    function logfilesDetailController($state, $stateParams, $window, Logfiles) {

        var ctrl = this;
        ctrl.logfile = null;

        ctrl.reload = function () {
            $window.location.reload();
        };

        ctrl.$onInit = function () {
            Logfiles.getLog($stateParams.filename).then(function (response) {
                ctrl.logfile = response.data;
            });
        };

        ctrl.formatTimestamp = function(timestamp) {
            var formatted = moment(timestamp).format('L');
            if(formatted != "Invalid date"){
                return formatted
            }
        };
    }

    angular
        .module('emsv2App')
        .controller('logfilesDetailController', logfilesDetailController);
})();
