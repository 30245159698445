(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name rsqlTableField
     * @param {Object} definition - Definition for this field
     * @param {Object} ngModel - Key of list item
     */
    angular.module('emsv2App')
        .component('rsqlTableField', {
            bindings: {
                definition: '<',
                ngModel: '<'
            },
            template: '<span>{{$ctrl.content}}</span>',
            controller: ['$translate', function ($translate) {
                var ctrl = this;
                ctrl.content = '';

                ctrl.$onInit = function () {
                    if (ctrl.ngModel !== undefined && ctrl.ngModel !== null) {
                        ctrl.content = ctrl.ngModel;
                    }
                    if (ctrl.definition !== undefined && ctrl.definition !== null) {
                        if (ctrl.definition.type === 'list' || ctrl.definition.type === 'multilist') {
                            if (ctrl.ngModel !== undefined && ctrl.ngModel !== null) {

                                if (ctrl.ngModel.startsWith('[')) {
                                    var ids = angular.fromJson(ctrl.ngModel);
                                    if (ids !== undefined && ids !== null && Array.isArray(ids)) {
                                        var text = '';
                                        var i = 0;
                                        for (; i < ids.length; i++) {
                                            var value = valueFromOptions(ids[i]);
                                            if (value !== undefined && value !== null) {
                                                if (text.length > 0) {
                                                    text += ', ';
                                                }
                                                text += value.value;
                                            }
                                        }
                                        ctrl.content = text;
                                    }
                                }
                                else {
                                    if (ctrl.definition.options !== undefined && ctrl.definition.options !== null && Array.isArray(ctrl.definition.options)) {
                                        var node = valueFromOptions(ctrl.ngModel);
                                        if (node !== undefined && node !== null) {
                                            ctrl.content = node.value;
                                        }
                                    }
                                }
                            }
                        }
                        else if (ctrl.definition.type === 'date') {
                            var dateFormat = $translate.instant('global.dateFormat');
                            if (ctrl.ngModel !== undefined && ctrl.ngModel !== null && ctrl.ngModel !== '' && dateFormat !== undefined && dateFormat !== null && dateFormat !== 'dateFormat') {
                                var theDate = moment(ctrl.ngModel);
                                if (theDate.isValid()) {
                                    ctrl.content = theDate.format(dateFormat);
                                }
                            }
                        }
                        else if (ctrl.definition.type === 'datetime') {
                            var dateTimeFormat = $translate.instant('global.dateTimeFormat');
                            if (ctrl.ngModel !== undefined && ctrl.ngModel !== null && ctrl.ngModel !== '' && dateTimeFormat !== undefined && dateTimeFormat !== null && dateTimeFormat !== 'dateFormat') {
                                var theDate = moment(ctrl.ngModel);
                                if (theDate.isValid()) {
                                    ctrl.content = theDate.format(dateTimeFormat);
                                }
                            }
                        }
                    }
                };

                function valueFromOptions(id) {
                    return ctrl.definition.options.find(function (item) {
                        // Key can be a number or a string or a mix of both..
                        return item.key == id;
                    });
                }
            }]
        });

})();
