(function () {

    'use strict';

    angular.module('emsv2App')
        .component('qrCodeModal', {
            templateUrl: 'scripts/components/qrcode/qrcode.html',
            bindings: {
                resolve: '<',
                close: '&'
            },
            controller: function () {
                var ctrl = this;
                ctrl.link = '';
                ctrl.data = ctrl.resolve.data;

                ctrl.$onInit = function () {
                    ctrl.updateQrLink();
                };

                ctrl.updateQrLink = function () {
                    var link = ctrl.resolve.baseUrl + '/PermanentLink?mac=';
                    link += ctrl.data.mac;
                    link += '&eauser=' + ctrl.data.user;
                    link += '&eapassword=' + ctrl.data.pwd;
                    link += '&user=' + ctrl.data.duser;
                    link += '&password=' + ctrl.data.dpwd;
                    link += '&page=' + _escapeUrl(ctrl.data.page);
                    ctrl.link = link;
                };

                function _escapeUrl(url) {
                    if (url !== undefined && url !== null) {
                        url = String(url).replace('/', '%2F');
                    }
                    return url;
                }
            }
        });
})();
