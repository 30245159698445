(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name promiseButton
     * @param {string} text Default text to show
     * @param {string} actionText Text to show while working
     * @param {string} successText Text to show on success
     * @param {string} errorText Text to show on error
     * @param {string} icon Icon class to use
     * @param {string} css CSS for the button
     * @param {boolean} disabled
     * @param {method} action Callback on button press
     * @param {boolean} responsive Control responsive behaviour
     */
    angular.module('emsv2App')
        .component('promiseButton', {
            bindings: {
                text: '@',
                actionText: '@',
                successText: '@',
                errorText: '@',
                icon: '@',
                css: '@',
                disabled: '=',
                action: '&',
                responsive: '='
            },
            templateUrl: 'scripts/components/loading-button/button.html',
            controller: ['$scope', '$timeout', 'Tools', function ($scope, $timeout, Tools) {
                var ctrl = this;
                ctrl.state = 'init';
                ctrl.vars = {
                    icon: Tools.isDefinedNotNull(ctrl.icon) ? ctrl.icon : '',
                    css: Tools.isDefinedNotNull(ctrl.css) ? ctrl.css : ''
                };

                ctrl.click = function () {
                    if (!ctrl.disabled && ctrl.state === 'init') {
                        var promise = ctrl.action();
                        if (promise !== null && promise !== undefined) {
                            ctrl.state = 'load';
                            promise.then(function () {
                                ctrl.state = 'success';
                                $timeout(function () {
                                    ctrl.state = 'init';
                                }, 2000);
                            }, function () {
                                ctrl.state = 'error';
                                $timeout(function () {
                                    ctrl.state = 'init';
                                }, 2000);
                            });
                        }
                    }
                };
            }]
        });

})();
