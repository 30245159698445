'use strict';

/**
 * @Constructor to create new floor tile object
 * @param {Number} id the id to set
 * @param {Object} pos 3-dimensional vector object describing the objects position
 * @param {Object} size 3-dimensional vector object describing the objects size
 * @param {Object} rot 3-dimensional vector object describing the objects rotation
 * @param {String} name the name to set
 * @param {String} comment the comment to set
 * @param {String} inventoryNumber the inventory number to set
 * @param {String} serialNumber the serial number to set
 * @param {String} floorPanelCustomPos the user defined floor position to set
 * @param {Number} partLibraryFloorPanelId the part library id of the used part library object
 * @param {Boolean} aligned if set to true object is aligned with raised floor pattern
 * @param {Number} uid the unique id to set
 * @param {Number} pid the parent id to set (room id)
 * @constructor
 */
function FloorTile(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, floorPanelCustomPos, partLibraryFloorPanelId, aligned, uid, pid, driverValues, url) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.url = url !== undefined ? url : "";
    this.roomId = pid !== undefined ? pid : null;
    this.floorPanelCustomPos = floorPanelCustomPos !== undefined ? floorPanelCustomPos : "";
    this.partLibraryFloorPanelId = partLibraryFloorPanelId !== undefined ? partLibraryFloorPanelId : null;
    this.stdFloorPos = null;
    this.floorPosition = "";
    this.isFloorTile = true;
    this.type = 1;
    // Next all controls for a deep copy of a floor tile (a copy with all parameters inside of it)
    if (driverValues !==null && driverValues !==undefined && driverValues.length > 0) {
        for (var i = 0; i < driverValues.length; i++) {
            driverValues[i].id = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.id= angular.copy(Entity.getNewLocaleUniqueId());
            driverValues[i].driver.uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
            if (driverValues[i].limits.length > 0) {
                var driverValueId = angular.copy(Entity.getNewLocaleUniqueId());
                for (var l=0; l < driverValues[i].limits.length; l++) {
                    driverValues[i].limits[l].id = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].uniqueId = angular.copy(Entity.getNewLocaleUniqueId());
                    driverValues[i].limits[l].driverValueId = driverValueId;
                }
            }
        }
        this.driverValues = driverValues;
    } else {
        this.driverValues = [];
    }

    this.allowDataPoints = true;
    this.aligned = aligned !== undefined ? aligned : false;
}

FloorTile.prototype = Object.create(NamedEntity.prototype);
FloorTile.prototype.constructor = FloorTile;

/**
 * @description Function to test equality of this object and the provided object
 * @param {object} cft the object to test equality with
 * @returns {boolean} returns true if this object and the provided object are equal, otherwise false
 */
FloorTile.prototype.equals = function (cft) {
    if (!(cft instanceof FloorTile)) return false;
    if (!this.equalsNamedEntity(cft)) return false;
    if (this.roomId !== cft.roomId) return false;
    if (this.inventoryNumber !== cft.inventoryNumber) return false;
    if (this.serialNumber !== cft.serialNumber) return false;
    if (this.partLibraryFloorPanelId !== cft.partLibraryFloorPanelId) return false;
    if (this.floorPanelCustomPos !== cft.floorPanelCustomPos) return false;
    if (this.type !== cft.type) return false;
    if (!this.compareDriverValues(cft.driverValues)) return false;
    return true;
};

/**
 * @description Function to validate this object
 * @returns {ErrorObject[]} returns array of error objects
 */
FloorTile.prototype.validate = function () {
    var errorList = this.validateNamedEntity();
    if (this.partLibraryFloorPanelId === undefined || this.partLibraryFloorPanelId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "partLibraryFloorPanelId"));
    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));

    // if (this.type === undefined || this.type === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "type"));
    for (var i = 0; i < this.driverValues.length; i++) {
        errorList = errorList.concat(this.driverValues[i].validate());
    }
    return errorList;
};

/**
 * @description Function to get used/unused parameters for this object
 * @param {array} availableParameters array of all applicable parameters for this object type
 * @returns array containing sub-arrays for used(index 0) and unused(index 1) parameters for this object
 */
FloorTile.prototype.setupParameters = function (availableParameters) {
    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = this.driverValues.filter(function (elem) {
            return elem.parameter.id === availableParameters[ap].id;
        });
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        }
        else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to check for collisions with other objects
 * @param {THREE.Object3D} obj3d_self the 3D representation for this object
 * @param {THREE.Object3D} obj3d_room the 3D representation for this objects parent room
 * @param {THREE.Vector3} pos the position to test collisions for
 * @param {boolean} onlyWalls if set to true only walls will be used for collision detection, otherwise all necessary objects will be tested against
 * @param {Room} roomObj the room object containing this object
 * @returns {boolean} returns true if a collision is detected otherwise false
 */
FloorTile.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    pos.y = roomObj.raisedFloor.size.y;
    var testObb = new OBB(new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z), new THREE.Vector3(this.size.x / 2, this.size.y / 2, this.size.z / 2), new THREE.Vector3(0, 0, 0));
    testObb.c.copy(pos);
    //check point in room
    if (!roomObj.checkOBBInRoom(testObb, true)) return true;
    //check collide walls
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === "asset" || o.name === "ups" || o.name === "floortile")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d_self.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
};

FloorTile.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    for (var i = 0; i < this.driverValues.length; i++) {
        var objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    return [];
};

/**
 * @description Function to get applicable driver types for this object type
 * @param {Object} allDrivers list of all supported driver types
 * @returns {Object} returns list of applicable driver types for this object type
 */
FloorTile.getAvailableDrivers = function (allDrivers) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "WIB8000") delete availableDrivers[d];
        if (d === "Wireless") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to create a new floor tile object from provided json data object
 * @param {Object} obj the json data object to use
 * @param {Number} pid the parent id to set (room id)
 * @returns {FloorTile} returns created floor tile object
 */
FloorTile.parseFromHtmlObject = function (obj, pid) {
    var tile = new FloorTile(obj.id, {x: obj.posX, y: obj.posY, z: obj.posZ}, {
        x: obj.scaleX,
        y: obj.scaleY,
        z: obj.scaleZ
    }, {
        x: obj.rotX,
        y: obj.rotY,
        z: obj.rotZ
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.floorPanelCustomPos, obj.partLibraryFloorPanelId, obj.aligned, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, tile);
    tile.modifyDriverValueLimitsForFrontend();
    return tile;
};

/**
 * @description Function to create a new floor tile object from copied javascript object
 * @param {Object} obj the copied object to use
 * @param {Number} pid the parent id to set (room id)
 * @returns {FloorTile} returns created floor tile object
 */
FloorTile.parseFromSimpleObject = function (obj, pid) {
    var tile = new FloorTile(obj.id, {x: obj.pos.x, y: obj.pos.y, z: obj.pos.z}, {
        x: obj.size.x,
        y: obj.size.y,
        z: obj.size.z
    }, {
        x: obj.rot.x,
        y: obj.rot.y,
        z: obj.rot.z
    }, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.floorPanelCustomPos, obj.partLibraryFloorPanelId, obj.aligned, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, tile);
    return tile;
};
