(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('LegalController', function ($state, Language, $window, License) {
            var ctrl = this;
            ctrl.pageTitle = $state.current.data.page;
            ctrl.statusHostedPublicly = false;
            ctrl.specification = "";
            ctrl.pricelist = "";
            ctrl.technicalAndOrganizationalMeasures = "";

            ctrl.$onInit = function () {
                License.get().then(function (response) {
                    ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
                    Language.getCurrent().then(function (language) {
                        ctrl.language = language;
                        if (ctrl.language === "de") {
                            ctrl.specification = $window.location.origin + "/legal/Leistungsverzeichnis.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/Preisliste.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnischeUndOrganisatorischeMassnahmen.pdf";
                        } else {
                            ctrl.specification = $window.location.origin + "/legal/Specification.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/PriceList.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnicalAndOrganizationalMeasures.pdf";
                        }
                    });
                })
            };

        });
})();
