(function () {

    'use strict';

    function stateConfiguration($stateProvider) {
        var groupList = ['$log', 'Group', function ($log, Group) {
            return Group.getAllFlat().then(function (response) {
                $log.debug('Loaded groupList: ', response.data);
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t load group list!', error);
                return [];
            });
        }];

        var userList = ['$log', 'User', function ($log, User) {
            return User.query().then(function (response) {
                $log.debug('Loaded userList: ', response.data);
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t load user list!', error);
                return [];
            });
        }];

        $stateProvider
            .state('locations', {
                parent: 'site',
                url: '/locations',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/location/locations.html',
                        controller: 'LocationController',
                        controllerAs: '$ctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('alarm');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('analysis');
                        return $translate.refresh();
                    }],
                    baseUrl: ['$log', 'Tools', function ($log, Tools) {
                        return Tools.getBaseUrl().then(function (response) {
                            $log.debug('Loaded baseUrl: ', response.data);
                            return response.data;
                        }, function (error) {
                            $log.debug('Couldn\'t retrieve baseUrl!', error);
                        });
                    }],
                    userInfo: ['$log', 'Principal', function ($log, Principal) {
                        return Principal.identity().then(function (user) {
                            $log.debug('Loaded userInfo: ', user);
                            return user;
                        }, function (error) {
                            $log.error('Couldn\'t load user: ', error);
                        });
                    }],
                    hwTypes: ['$log', 'HwTyp', function ($log, HwTyp) {
                        return HwTyp.getAllMapped().then(function (response) {
                            $log.debug("Loaded hwtyp map: ", response.data);
                            return response.data;
                        }, function (error) {
                            $log.error('Couldn\'t load Hwtypes!', error);
                        });
                    }],
                    license: ['$log', 'License', function ($log, License) {
                        return License.get().then(function (response) {
                            return response.data;
                        }, function (error) {
                            $log.error('Couldn\'t retrieve license!', error);
                        });
                    }],
                    definitionList: ['$log', 'Rsql', function ($log, Rsql) {
                        return Rsql.getAllDefinitions('location').then(function (response) {
                            return response.data;
                        }, function (error) {
                            $log.error('Couldn\'t load all definitions: ', error);
                        });
                    }],
                    currentLocale: ['$log', 'Language', function ($log, Language) {
                        return Language.getCurrent().then(function (response) {
                            return response;
                        }, function (error) {
                            $log.error('Couldn\'t load locale, backfall to english!', error);
                            return 'en';
                        });
                    }]
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('locations.query', {
                parent: 'locations',
                url: '/query/:query',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/location/locations.html',
                        controller: 'LocationController',
                        controllerAs: 'lctrl'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('location', {
                abstract: false,
                parent: 'locations',
                url: '/:id/:mode',
                data: {
                    roles: ['ROLE_USER'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: '{{currentLocation.name}}'
                },
                redirectTo: 'location.overview',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('location');
                        $translatePartialLoader.addPart('rsqltable');
                        return $translate.refresh();
                    }],
                    locationUsers: ['$log', '$stateParams', 'Location', function ($log, $stateParams, Location) {
                        return Location.getUsers($stateParams.id).then(function (response) {
                            $log.debug('Loaded locationUsers: ', response.data);
                            return response.data;
                        }, function (error) {
                            $log.error('Couldn\'t load users for location ' + $stateParams.id, error);
                            return [];
                        });
                    }],
                    locationGroups: ['$log', 'Location', '$stateParams', function ($log, Location, $stateParams) {
                        return Location.getGroups($stateParams.id).then(function (response) {
                            $log.debug('Loaded conntected groups: ', response.data);
                            return response.data;
                        }, function (error) {
                            $log.error('Can\'t load connected groups for location ' + $stateParams.id, error);
                            return [];
                        });
                    }],
                    currentLocation: ['$log', '$stateParams', 'Location', 'locationUsers', 'locationGroups', '$state',
                        function ($log, $stateParams, Location, locationUsers, locationGroups, $state) {
                            return Location.get($stateParams.id).then(function (response) {
                                var location = response.data;
                                location.users = locationUsers;
                                location.groups = locationGroups;
                                $log.debug('Loaded currentLocation: ', location);
                                return location;
                            }, function (error) {
                                $state.go("notfound");
                                $log.error('Couldn\'t load location!', error);
                            });
                        }],
                    userList: userList,
                    groupList: groupList
                },
                onEnter: function ($timeout, Principal, License, Tools, $state) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('location.create', {
                parent: 'locations',
                url: '/create',
                data: {
                    roles: ['ROLE_LOCATION_CREATE'],
                    pageTitle: 'location.home.pageTitle',
                    breadcrumbsTitle: 'location.home.createLocation',
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/location/new/location.new.html',
                        controller: 'LocationCreateController',
                        controllerAs: 'octrl'
                    }
                },
                resolve: {
                    userList: userList,
                    groupList: groupList,

                    license: ['$log', 'License', '$state', function ($log, License, $state) {
                        return License.getLocationLimitReached().then(function (response) {
                            if (response.data) {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $log.error('Couldn\'t retrieve license!', error);
                        });
                    }],

                    currentUser: ['$log', 'Principal', '$state', function ($log, Principal, $state) {
                        return Principal.identity().then(function (user) {
                            $log.debug('Loaded userInfo: ', user);
                            if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_LOCATION_CREATE')) {
                                return user;
                            }
                            else {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $state.go("notfound");
                            $log.error('Couldn\'t load user: ', error);
                        });
                    }]
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            });
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
