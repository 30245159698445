(function () {

    'use strict';

    function WidgetStulzCoolingController($http, $scope, $uibModal, $state, $q, $log, $translate, $interval, Location, Tools, Notify, WidgetData, DashboardService, LiveDataService) {

        var ctrl = this;
        var currentModal;
        var liveDataQueryInterval;
        var isIntervalRequestIsInProgress = false;
        var initLocation;
        var initRoom;
        var initCooling;
        var initRoot;

        ctrl.locations = [];
        ctrl.rooms = [];
        ctrl.roomCoolings = [];
        ctrl.coolingDriverValues = [];
        ctrl.datapoints = [];
        ctrl.customUnitList = [];

        ctrl.selectedLocationId = null;
        ctrl.selectedRoomId = null;
        ctrl.selectedCoolingId = null;

        ctrl.locationName = null;
        ctrl.roomName = null;
        ctrl.coolingName = null;
        ctrl.coolingRoot = null;
        ctrl.coolingComment = '';

        ctrl.noRooms = false;
        ctrl.noCoolings = false;
        ctrl.coolingChanged = false;


        ctrl.$onInit = function () {

            $scope.$parent.setConfigMethod(ctrl.openConfig);
            _loadSettings()

            initLocation = angular.copy(ctrl.selectedLocationId);
            initRoom = angular.copy(ctrl.selectedRoomId);
            initCooling = angular.copy(ctrl.selectedCoolingId);
            initRoot = angular.copy(ctrl.coolingRoot);

            setupSelectedCoolingDevice();

            liveDataQueryInterval = $interval(function () {
                loadCoolingLiveData();
            }, 10000);
        };

        ctrl.openConfig = function () {
            getLocations();
            getRoomsForLocation(ctrl.selectedLocationId);
            ctrl.openModal();
        };


        ctrl.locationSelection = function () {
            ctrl.locationName = ctrl.locations.find(x => x.id === ctrl.selectedLocationId).name;
            ctrl.rooms = [];
            ctrl.selectedRoomId = null;
            ctrl.noRooms = false;
            if (ctrl.selectedLocationId) {
                getRoomsForLocation(ctrl.selectedLocationId);
            }
        };

        ctrl.roomSelection = function () {
            ctrl.roomName = ctrl.rooms.find(x => x.id === ctrl.selectedRoomId).name;
            ctrl.noCoolings = false;
            if (ctrl.selectedRoomId) {
                getCoolingsForRoom(ctrl.selectedRoomId);
            }
        };

        ctrl.coolingSelection = function () {
            ctrl.coolingChanged = true;
            getCoolingMetaInfo(ctrl.selectedLocationId, ctrl.selectedRoomId, ctrl.selectedCoolingId);
            getDriverValues(ctrl.selectedCoolingId);
        };

        ctrl.resetSettings = function () {
            ctrl.selectedLocationId = null;
            ctrl.selectedRoomId = null;
            ctrl.selectedCoolingId = null;
            ctrl.noRooms = false;
            ctrl.noCoolings = false;
        };

        ctrl.canSave = function () {
            if (ctrl.selectedLocationId === null || ctrl.selectedRoomId === null || ctrl.selectedCoolingId === null) {
                return true
            }
            if (ctrl.selectedCoolingId === initCooling) {
                return true;
            } else {
                return false;
            }
        };

        ctrl.saveSettings = function () {
            ctrl.coolingRoot = ctrl.locationName + " / " + ctrl.roomName + " / " + ctrl.coolingName;

            var newSettings = [];

            if (Tools.isDefinedNotNull(ctrl.selectedLocationId && ctrl.selectedRoomId && ctrl.selectedCoolingId)) {
                newSettings.push({key: 'selectedLocationId', value: ctrl.selectedLocationId});
                newSettings.push({key: 'selectedRoomId', value: ctrl.selectedRoomId});
                newSettings.push({key: 'selectedCoolingId', value: ctrl.selectedCoolingId});
                newSettings.push({key: 'coolingRoot', value: ctrl.coolingRoot});
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
            } else {
                $scope.widget.settings = [];
            }

            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    ctrl.closeModal();
                    $state.go($state.current, {}, {reload: true});
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.stulzCooling.modal.saveErrorMsg", 2000);
                });
            }
        };

        //Settings modal related functions

        ctrl.openModal = function () {
            if (Tools.isDefinedNotNull(ctrl.selectedCoolingId)) {

            }
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/stulzcooling/widget.stulzcooling.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                backdrop: 'static',
                scope: $scope
            });
        };

        ctrl.closeModal = function () {
            if (currentModal) {
                if (ctrl.selectedLocationId !== initLocation) {
                    ctrl.selectedLocationId = initLocation;
                }
                if (ctrl.selectedRoomId !== initRoom) {
                    ctrl.selectedRoomId = initRoom;
                }
                if (ctrl.selectedCoolingId !== initCooling) {
                    ctrl.selectedCoolingId = initCooling;
                }
                if (ctrl.coolingRoot !== initRoot) {
                    ctrl.coolingRoot = initRoot;
                }
                ctrl.noRooms = false;
                ctrl.noCoolings = false;
                currentModal.close();
                $state.go($state.current, {}, {reload: true});
            }
        };

        ctrl.cancelModal = function () {
            ctrl.closeModal();
        };

        function _loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch ($scope.widget.settings[i].key) {
                        case 'selectedLocationId':
                            ctrl.selectedLocationId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedRoomId':
                            ctrl.selectedRoomId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedCoolingId':
                            ctrl.selectedCoolingId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'coolingRoot':
                            ctrl.coolingRoot = $scope.widget.settings[i].value;
                            break;
                    }
                }
            }
        }

        function getLocations() {
            Location.queryCompact().then(function (response) {
                ctrl.locations = response.data;
            });
        }

        function setupSelectedCoolingDevice() {
            if (Tools.isDefinedNotNull(ctrl.selectedLocationId)) {
                Location.get(ctrl.selectedLocationId).then(function (response) {
                    ctrl.locationName = response.data.name;
                    DashboardService.getRoomsByLocation(ctrl.selectedLocationId).then(function (rooms) {
                        if (rooms.length > 0) {
                            for (var j = 0; j < rooms.length; j++) {
                                if (rooms[j].id === ctrl.selectedRoomId) {
                                    ctrl.roomName = rooms[j].name;
                                    for (var i = 0; i < rooms[j].coolings.length; i++) {
                                        if (rooms[j].coolings[i].id === ctrl.selectedCoolingId) {
                                            ctrl.coolingDriverValues = rooms[j].coolings[i].driverValues;
                                            ctrl.coolingName = rooms[j].coolings[i].name;
                                            ctrl.coolingComment = rooms[j].coolings[i].comment;
                                            if (ctrl.coolingDriverValues.length) {
                                                createDPList(ctrl.coolingDriverValues);
                                                updateCoolingMetaInfo();
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
            }
        }

        function loadCoolingLiveData() {
            if (!isIntervalRequestIsInProgress) {
                isIntervalRequestIsInProgress = true;

                if (Tools.isDefinedNotNull(ctrl.datapoints)) {

                    if (ctrl.datapoints)

                        for (var i = 0; i < ctrl.datapoints.length; i++) {
                            var dp = angular.copy(ctrl.datapoints[i]);
                            queryLiveData(dp);
                        }
                    isIntervalRequestIsInProgress = false;
                }
            }
        }

        function queryLiveData(dp) {
            LiveDataService.queryLiveDataForWidget(dp.id).then(function (response) {
                    var latestLiveData = null;
                    if (response.status === 200) {

                        latestLiveData = fetchLatestLiveData(response.data, dp);
                        setLiveDataForChart(dp.id, latestLiveData);
                    }
                }, function
                    (error) {
                    $log.error("Error retrieving DriverValues!" + error);
                }
            )
        };

        function fetchLatestLiveData(value, dp) {
            var data;
            if (value[dp.id].latestData.length > 0) {
                if (dp.protocolType === 5 && dp.registerValueType === 1) {
                    data = parseFloat(value[dp.id].latestData);
                } else {
                    data = parseFloat((value[dp.id].latestData / 1000).toFixed(2));
                }
            }
            return data;
        };

        function setLiveDataForChart(dvId, latestLiveData) {
            for (var i = 0; i < ctrl.datapoints.length; i++) {
                if (ctrl.datapoints[i].id === dvId) {
                    if (ctrl.datapoints[i].physicalType === 6) {
                        ctrl.datapoints[i].latestLiveValue = returnDisplayValueIndividualPhyType(latestLiveData, ctrl.datapoints[i].unitOfMeasurement);
                    } else {
                        ctrl.datapoints[i].latestLiveValue = latestLiveData;
                    }

                    var container = "#container" + ctrl.datapoints[i].id;
                    var myobj = document.getElementById("container" + ctrl.datapoints[i].id);
                    if (myobj !== null) {
                        $(container).contents().remove();
                    }
                    break;
                }
            }
        };

        function getRoomsForLocation(locId) {
            if (Tools.isDefinedNotNull(locId)) {
                var defer = $q.defer();
                $http.get('api/rooms/' + locId).then(function (rooms) {
                    if (rooms.data.length > 0) {
                        for (var index = 0; index < rooms.data.length; index++) {
                            ctrl.rooms.push(Room.parseFromHtmlObject(rooms.data[index]));
                        }
                        getCoolingsForRoom(ctrl.selectedRoomId);
                    } else {
                        ctrl.noRooms = true;
                    }
                    defer.resolve();
                });
            }
        }

        function getCoolingsForRoom(roomId) {
            if (ctrl.rooms.length && roomId !== null) {
                ctrl.roomCoolings = ctrl.rooms.find(x => x.id === roomId).coolings;
            }
            if (!ctrl.roomCoolings.length) {
                ctrl.noCoolings = true;
            }
        }

        function getCoolingMetaInfo(locationId, roomId, coolingId) {
            ctrl.locationName = ctrl.locations.find(x => x.id === locationId).name
            ctrl.roomName = ctrl.rooms.find(x => x.id === roomId).name;
            ctrl.coolingName = ctrl.roomCoolings.find(x => x.id === coolingId).name;
            ctrl.coolingComment = ctrl.roomCoolings.find(x => x.id === coolingId).comment;
        }

        function updateCoolingMetaInfo() {
            ctrl.coolingRoot = ctrl.locationName + " / " + ctrl.roomName + " / " + ctrl.coolingName;
            ctrl.coolingComment = ctrl.coolingComment;
        }

        function getDriverValues(coolingId) {
            ctrl.coolingDriverValues = ctrl.roomCoolings.find(x => x.id === coolingId).driverValues;
            createDPList(ctrl.coolingDriverValues);
        }

        function createDPList(dv) {
            //create datapoint list based on cooling driver values
            if (Tools.isDefinedNotNull(dv)) {
                ctrl.datapoints = [];
                for (var i = 0; i < dv.length; i++) {
                    var dp = {};
                    dp.id = dv[i].id;
                    dp.unqId = dv[i].uniqueId;
                    dp.name = dv[i].name;
                    dp.protocolType = dv[i].driver.driverType;
                    dp.registerValueType = dv[i].driver.registerValueType;
                    dp.physicalType = dv[i].physicalType;
                    if (dv[i].physicalType !== 6) {
                        dp.unitOfMeasurement = getUnit(dv[i].physicalType);
                    } else {
                        dp.unitOfMeasurement = dv[i].parameter.parameterValues;
                    }

                    dp.liveValue = null;
                    ctrl.datapoints.push(dp);
                }

                setTimeout(function () {
                    loadCoolingLiveData();
                }, 500);
            }
        }

        function getUnit(physType) {
            switch (physType) {
                case 0:
                    return "";
                case 1:
                    return "°C";
                case 2:
                    return "% rel.H.";
                case 3:
                    return "RPM";
                case 4:
                    return "%";
                case 5:
                    return "Pa";
                case 8:
                    return "V";
                case 9:
                    return "A";
                case 10:
                    return "W";
                case 11:
                    return "Hz";
                case 12:
                    return "W";
                case 14:
                    return "RPS";
                case 15:
                    return "m³/s";
                case 16:
                    return "s";
                case 17:
                    return "h";
                case 18:
                    return "min";
                case 19:
                    return "m";
                default:
                    return "";
            }
        }

        function returnDisplayValueIndividualPhyType(value, parameterValues) {
            var displayVal;

            if (parameterValues === null) {
                return value;
            }

            for (var i = 0; i < parameterValues.length; i++) {
                if (parseFloat(parameterValues[i].value) === value) {
                    displayVal = parameterValues[i].description;
                } else {
                    displayVal = value;
                }
            }
            return displayVal
        };

        function stopLiveDataQuery() {
            if (liveDataQueryInterval !== null) $interval.cancel(liveDataQueryInterval);
        };


        $scope.$on('$destroy', function () {
            stopLiveDataQuery();
        });
    }

    angular.module('emsv2App')
        .controller('WidgetStulzCoolingController', WidgetStulzCoolingController)

})
();