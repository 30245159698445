'use strict';

/**
 * @description Constructor to create new room object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {number} type the type to set
 * @param {number} uid the unique to set
 * @param {number} pid the parent id to set (room id)
 * @constructor
 */
function RoomObj(id, pos, size, rot, name, comment, type, uid, pid) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.type = type !== undefined ? type : 0;
    this.roomId = pid !== undefined ? pid : null;
}

RoomObj.prototype = Object.create(NamedEntity.prototype);
RoomObj.prototype.constructor = RoomObj;

/**
 * @description Function to test equality between this object and the provided object
 * @param {object} cro the object to use
 * @returns {boolean} returns true if this object and the provided object are equal, otherwise false
 */
RoomObj.prototype.equals = function (cro) {
    if (!(cro instanceof RoomObj)) return false;
    if (!this.equalsNamedEntity(cro)) return false;
    if (this.roomId !== cro.roomId) return false;
    if (this.type !== cro.type) return false;
    return true;
};

/**
 * @description Function to validate room objects basic properties
 * @returns {ErrorObject[]} returns array of error objects
 */
RoomObj.prototype.validateRoomObj = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateEntity());
    if (this.type === undefined || this.type === null || this.type < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "type"));
    if (this.roomId === undefined || this.roomId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "roomId"));
    return errorList;
};

/**
 * @description Constructor to create new door object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing this objects position
 * @param {object} size 3-dimensional vector object describing this objects size
 * @param {object} rot 3-dimensional vector object describing this objects rotation
 * @param {string} name the name to set (not used)
 * @param {string} comment the comment to set (not used)
 * @param {number} type the type to set
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set (room id)
 * @constructor
 */
function Door(id, pos, size, rot, name, comment, type, uid, pid) {
    RoomObj.call(this, id, pos, size, rot, name, comment, type, uid, pid);
}

Door.prototype = Object.create(RoomObj.prototype);
Door.prototype.constructor = Door;
Door.TYPERANGE = [0, 999];

/**
 * Function to determine if provided type is in range of door types
 * @param {number} type the type to test
 * @returns {boolean} returns true if type is in 'door-range', otherwise false
 */
Door.isDoor = function (type) {
    return type >= Door.TYPERANGE[0] && type <= Door.TYPERANGE[1];
};

/**
 * @description Function to check collision with specified wall constraints
 * @param {THREE.Vector3} s the start point of the wall to run test for
 * @param {THREE.Vector3} e the end point of the wall to run test for
 * @param {number} h the height of the wall to run test for
 * @param {THREE.Vector3} p the position of this object to run test for
 * @param {number} offset offset to use for checks against wall height
 * @returns {*} returns array, first element is of type boolean, if false no collision is detected, if true collision is detected and a additional string is returned describing to collision type
 */
Door.prototype.checkWallCollide = function (s, e, h, p, offset) {
    if (this.size.y > h) return [true, "s_g"];
    if (this.size.y / 2 + p.y - offset > h) return [true, "s_t"];
    if (p.y - this.size.y / 2 < 0) return [true, "s_b"];
    var ds = p.clone().setY(0).sub(s).length();
    var de = p.clone().setY(0).sub(e).length();
    if (ds < this.size.x / 2 || de < this.size.x / 2) return [true, "s_e"];
    return [false];
};

/**
 * @description Function to validate this object
 * @param {Room=} room the objects parent room object, if provided additional checks will be run
 */
Door.prototype.validate = function (room) {
    var locatedOnWall = false;
    if (room !== undefined && room instanceof Room) {
        if (room.checkPointOnOuterWall(new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z))) locatedOnWall = true;
        if (room.checkPointOnInnerWall(new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z))) locatedOnWall = true;
        if (!locatedOnWall) return false
    }
    return locatedOnWall;
};

/**
 * @description Constructor to create a new window object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing object size
 * @param {object} rot 3-dimensional vector object describing object rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {number} type the type to set
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set
 * @param {boolean} alignedToWall the parent id to set
 * @constructor
 */
function Window(id, pos, size, rot, name, comment, type, uid, pid, alignedToWall) {
    this.alignedToWall = alignedToWall === null || alignedToWall === undefined ? false : alignedToWall;
    RoomObj.call(this, id, pos, size, rot, name, comment, type, uid, pid);
}

Window.prototype = Object.create(RoomObj.prototype);
Window.prototype.constructor = Window;
Window.TYPERANGE = [1000, 1999];

/**
 * @Function to check if provided type is in window type range
 * @param {number} type the type to check
 * @returns {boolean} returns true if provided type is in window type range, otherwise false
 */
Window.isWindow = function (type) {
    return type >= Window.TYPERANGE[0] && type <= Window.TYPERANGE[1];
};

/**
 * @description Function to check collision with provided wall
 * @param {THREE.Vector3} s the start point of the wall to use
 * @param {THREE.Vector3} e the end point of the wall to use
 * @param {number} h the height of the wall
 * @param {THREE.Vector3} p the position the check collision for
 * @returns {*}
 */
Window.prototype.checkWallCollide = function (s, e, h, p) {
    if (this.size.y > h) return [true, "s_g"];
    if (this.size.y / 2 + p.y > h) return [true, "s_t"];
    if (p.y - this.size.y / 2 < 0) return [true, "s_b"];
    if (this.checkWallEndCollides(s, e, p) !== 0) return [true, "s_e"];
    return [false];
};

/**
 * @description Function to check if this object with the provided position is within its walls constraints
 * @param {THREE.Vector3} s the start point of the wall to use
 * @param {THREE.Vector3} e the end point of the wall to use
 * @param {THREE.Vector3} p the position to use
 * @returns {number}
 */
Window.prototype.checkWallEndCollides = function (s, e, p) {
    var ds = p.clone().setY(0).sub(s).length();
    var de = p.clone().setY(0).sub(e).length();
    if (ds < this.size.x / 2) return -1;
    if (de < this.size.x / 2) return 1;
    return 0;
};

/**
 * @description Function to validate this object
 * @param {Room} room this objects parent Room object
 * @param {object} room3D this objects parent 3D Room object
 * @param {service} roomEditorService is the service with some functionality
 * @returns {boolean}
 */
Window.prototype.validate = function (room, room3D, roomEditorService) {
    if (room !== undefined && room instanceof Room) {
        var countAll = 0;
        var countWindows = 0;
        room.roomObjs.forEach(function (obj) {
            if (obj instanceof Window) {
                countAll++;
            }
        });
        var wall3D;
        for (var i = 0; i < room.outerWalls.length; i++) {
            wall3D = roomEditorService.get3DOuterWall(room3D, room.outerWalls[i].start, room.outerWalls[i].end);
            countWindows += room.getWindowsForWall(wall3D).length;
        }
        for (var j = 0; j < room.innerWalls.length; j++) {
            wall3D = roomEditorService.get3DOuterWall(room3D, room.innerWalls[j].start, room.innerWalls[j].end);
            countWindows += room.getWindowsForWall(wall3D).length;
        }
        if (countAll === countWindows) return true;
    }
    return false;
};

/**
 * @description Constructor to create new pillar object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {number} type the type to set
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set
 * @constructor
 */
function Pillar(id, pos, size, rot, name, comment, type, uid, pid) {
    RoomObj.call(this, id, pos, size, rot, name, comment, type, uid, pid);
}

Pillar.prototype = Object.create(RoomObj.prototype);
Pillar.prototype.constructor = Pillar;
Pillar.TYPERANGE = [2000, 2999];

/**
 * @description Function to validate this object
 * @param {Room=} room this objects parent room, if provided additional tests will be run
 * @returns {ErrorObject[]} returns array of error objects
 */
Pillar.prototype.validate = function (room) {
    var errorList = this.validateRoomObj();
    if (room !== undefined && room instanceof Room) {
        if (!Room.checkPointInPolygon(new THREE.Vector3(), room.getCornerPoints(3))) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "pos", {msg: "obj not in room"}));
    }
    return errorList;
};

/**
 * @description Function to check if provided type is within pillar objects type range
 * @param {number} type the type to check
 * @returns {boolean} returns true if provided type is within pillar objects type range, otherwise false
 */
Pillar.isPillar = function (type) {
    return type >= Pillar.TYPERANGE[0] && type <= Pillar.TYPERANGE[1];
};

/**
 * @description Function to check if this object collides with another object
 * @param {THREE.Object3D} obj3d_self the 3d-object representing this object
 * @param {THREE.Object3D} obj3d_room the 3d-object representing this objects parent room
 * @param {THREE.Vector3} pos position to check collision for
 * @param {boolean} onlyWalls if set to true only collisions with walls will be tested
 * @param {Room} roomObj the room object to use
 * @returns {boolean} return true if objects collides with something, otherwise false
 */
Pillar.prototype.checkCollision = function (obj3d_self, obj3d_room, pos, onlyWalls, roomObj) {
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d_self.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    testObb.rotateY(this.rot.y * (Math.PI / 180));
    //check point in room
    if (!roomObj.checkOBBInRoom(testObb, false)) return true;
    //check collide walls
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        if (!onlyWalls && (o.name === "rack" || o.name === "cooling" || o.name === "pillar" || o.name === "asset" || o.name === "ups" || o.name === "floortile")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d_self.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
}

/**
 * @description Constructor to create a new raised floor object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {number} type the type to set
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set
 * @constructor
 */
function RaisedFloor(id, pos, size, rot, name, comment, type, uid, pid) {
    RoomObj.call(this, id, pos, size, rot, name, comment, type, uid, pid);
}

RaisedFloor.prototype = Object.create(RoomObj.prototype);
RaisedFloor.prototype.constructor = RaisedFloor;

RaisedFloor.prototype.validate = function () {
    return this.validateRoomObj();
};

RaisedFloor.TYPERANGE = [12000, 12999];

/**
 * @description Function to check if provided type is in raised floor type range
 * @param {number} type the type to check
 * @returns {boolean} returns true if provided type is within raised floor type range, otherwise false
 */
RaisedFloor.isRaisedFloor = function (type) {
    return type >= RaisedFloor.TYPERANGE[0] && type <= RaisedFloor.TYPERANGE[1];
};

/**
 * @description Function to get the offset for texture alignment
 * @param {THREE.Box3} roomBB this objects parent room bounding box
 * @param {number} w the width of the raised floor tiles
 * @param {number} d the depth of the raised floor tiles
 * @returns array of length 2, first element offset for x-axis, second element offset for z-axis
 */
RaisedFloor.calcStdOffset = function (roomBB, w, d) {
    var xOff = (Math.abs(roomBB.min.x)) / w;
    var zOff = (Math.abs(roomBB.min.z)) / d;
    xOff -= Math.floor(xOff);
    zOff -= Math.floor(zOff);
    return [xOff, zOff];
};

/**
 * @description Function to get the offset for texture alignment for user defined offsets
 * @param {THREE.Box3} roomBB
 * @param {number} w the width of the raised floor tiles
 * @param {number} d the depth of the raised floor tiles
 * @param {number} ox the user defined offset for the x-axis
 * @param {number} oz the user defined offset for the z-axis
 * @returns array of length 2, 1st element offset for x-axis, 2nd element offset for z-axis
 */
RaisedFloor.calcCustomOffset = function (roomBB, w, d, ox, oz) {
    var xOff = (Math.abs(roomBB.min.x)) / w;
    var zOff = (Math.abs(roomBB.min.z)) / d;
    xOff -= ox / w;
    zOff -= oz / d;
    xOff -= Math.floor(xOff);
    zOff -= Math.floor(zOff);
    return [xOff, zOff];
};

/**
 * @description Function to create tile name patterns based
 * @param {number} count the number of tiles/names to create
 * @param {number} pattern the pattern type to use
 * @param {boolean} lowerCase set to true to use lower case, otherwise set to false
 * @returns {array} returns array of names for tiles based on provided information
 */
RaisedFloor.buildTileNamePattern = function (count, pattern, lowerCase) {
    var j;
    var fillLeadingZero = function (number, maxLen) {
        var ret = number + "";
        while (ret.length < maxLen) ret = "0" + ret;
        return ret;
    };
    var getStdLetterPattern = function (count, lowerCase) {
        var ret = [];
        for (var i = 0; i < count; i++) {
            var endLetter = String.fromCharCode((i % 26) + 65);
            if (count > 26 && i >= 26) endLetter = String.fromCharCode((Math.floor(i / 26) - 1) % 26 + 65) + endLetter;
            if (count > (26 * 26) + 26 && i >= (26 * 26) + 26) endLetter = String.fromCharCode((Math.floor(i / (26 * 26)) - 1) % 26 + 65) + endLetter;
            if (lowerCase) endLetter = endLetter.toLowerCase();
            ret.push(endLetter);
        }
        return ret;
    };
    var getFilledLetterPattern = function (count, lowerCase) {
        var ret = [];
        for (var i = 0; i < count; i++) {
            var prefix = "";
            if (count > (26 * 26)) prefix += String.fromCharCode(Math.floor(i / (26 * 26)) % 26 + 65);
            if (count > 26) prefix += String.fromCharCode(Math.floor(i / 26) % 26 + 65);
            var endLetter = prefix + String.fromCharCode((i % 26) + 65);
            if (lowerCase) endLetter = endLetter.toLowerCase();
            ret.push(endLetter);
        }
        return ret;
    };
    var names = [];
    switch (pattern) {
        case 1:
            for (j = 1; j <= count; j++) names.push(j.toString());
            break;
        case 2:
            var maxLen = count.toString().length;
            for (j = 1; j <= count; j++) names.push(fillLeadingZero(j, maxLen));
            break;
        case 3:
            return getStdLetterPattern(count, lowerCase);
        case 4:
            return getFilledLetterPattern(count, lowerCase);
        case 5:
            for (j = 0; j < count; j++) names.push("xxx");
            break;
    }
    return names;
};

/**
 * @description Function to check if provided letter is usable
 * @param {string} letter the check
 * @returns {boolean} returns true if provided letter is usable, otherwise false
 */
RaisedFloor.checkLetter = function (letter) {
    var code = letter.charCodeAt(0);
    if (code >= 48 && code <= 57) return true;
    if (code >= 65 && code <= 90) return true;
    if (code >= 97 && code <= 122) return true;
    return false;
};

/**
 * @description Function to check collision
 * @returns {boolean} returns true if a collision is detected, otherwise false
 */
RaisedFloor.prototype.checkCollision = function () {
    return false;
};

/**
 * @description Constructor to create new containment object
 * @param {number} id the id to set
 * @param {object} pos 3-dimensional vector object describing objects position
 * @param {object} size 3-dimensional vector object describing objects size
 * @param {object} rot 3-dimensional vector object describing objects rotation
 * @param {string} name the name to set
 * @param {string} comment the comment to set
 * @param {number} type the type to set
 * @param {number} uid the unique id to set
 * @param {number} pid the parent id to set
 * @constructor
 */
function Containment(id, pos, size, rot, name, comment, type, uid, pid) {
    RoomObj.call(this, id, pos, size, rot, name, comment, type, uid, pid);
    this.isContainment = true;
}

Containment.prototype = Object.create(RoomObj.prototype);
Containment.prototype.constructor = Containment;
Containment.TYPERANGE = [10000, 10999];

/**
 * @description Function to check if provided type is within containment type range
 * @param {number} type the type to check
 * @returns {boolean} returns true if provided type in in containment type range, otherwise false
 */
Containment.isContainment = function (type) {
    return type >= Containment.TYPERANGE[0] && type <= Containment.TYPERANGE[1];
};

/**
 * @description Function to check collision
 * @param {THREE.Object3D} obj3d this objects corresponding 3d object
 * @param {Object} obj3d_room is a 3d room object that containing the containment
 * @param {THREE.Vector3} pos the position to run check for
 * @param {boolean} onlyWalls if set to true only walls will be used for checks, else anything relevant
 * @param {Room} room this objects parent room object
 * @returns {boolean} returns true if a collision is detected, otherwise false
 */
Containment.prototype.checkCollision = function (obj3d, obj3d_room, pos, onlyWalls, room) {
    var offset = room.hasRaisedFloor ? room.getRaisedFloor().size.y : 0;
    if (this.size.y + offset > room.size.y) return true;
    if (pos === undefined) pos = new THREE.Vector3(this.pos.x, this.pos.y, this.pos.z);
    var testObb = obj3d.userData.obb.clone();
    testObb.c.copy(pos);
    testObb.e.set(this.size.x / 2, this.size.y / 2, this.size.z / 2);
    testObb.rotateY(this.rot.y * (Math.PI / 180));
    if (!room.checkOBBInRoom(testObb, false)) return true;
    var testWalls = [];
    var collideObjects = [];
    var i;
    obj3d_room.traverse(function (o) {
        if (o.name === "outerwall" || o.name === "innerwall") testWalls.push(o);
        // if (!onlyWalls && (o.name === "pillar" || o.name === "asset" || o.name === "containment")) collideObjects.push(o);
        if (!onlyWalls && (o.name === "pillar" || o.name === "containment")) collideObjects.push(o);
    });
    for (i = 0; i < testWalls.length; i++) {
        if (testWalls[i].userData.obb.isIntersectionBox(testObb)) return true;
    }
    if (onlyWalls) return false;
    for (i = 0; i < collideObjects.length; i++) {
        if (collideObjects[i].userData.uid !== obj3d.userData.uid) {
            if (collideObjects[i].userData.obb.isIntersectionBox(testObb)) return true;
        }
    }
    return false;
};

/**
 * @description Function to validate this object
 * @param {Room=} room this objects parent room object, if provided additional test will be run
 * @returns {ErrorObject[]} array of error objects
 */
Containment.prototype.validate = function (room) {
    var errorList = this.validateRoomObj();
    if (room !== undefined && room instanceof Room) {
        var offset = room.hasRaisedFloor ? room.getRaisedFloor().size.y : 0;
        if ((offset + this.size.y) > room.size.y) errorList.push(new ErrorObject(ErrorObject.INVALID_GEOMETRY, this.uniqueId, "size", {msg: "containment to high"}));
    }
    return errorList;
};
