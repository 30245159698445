/**
 * mk 11.07.2017
 * Component to display rooms as list or as tiles for each floor
 */

(function () {
    'use strict';
    /**
     * @ngdoc component
     * @name roomsListTiles
     * @param {Array} rooms - Array of rooms
     * @param {Object} viewMode - type of display (tile - 0, list - 1)
     * @requires $log, $interval, $state, $stateParams, RoomService, LiveDataService, GenDialogService
     */
    angular
        .module('emsv2App')
        .component('roomsoverview', {
            bindings: {
                rooms: '<',
                user: '=',
                building: '=',
                edit: '=',
                buildings: '<',
                currentBuildingId: '<',
                currentLocationId: '<'
            },
            templateUrl: 'scripts/components/rooms/roomsoverview.component.html',
            controller: ['localStorageService', '$log', '$interval', '$state', '$stateParams', '$scope', '$translate'
                , '$uibModal', 'RoomService', 'LiveDataService', 'Location', 'GenDialogService', 'MessageService'
                , 'Buildings', 'Tools', '$http', 'Notify', 'Analysis', 'WidgetData', 'License', '$timeout',
                function (localStorageService, $log, $interval, $state, $stateParams, $scope, $translate, $uibModal
                    , RoomService, LiveDataService, Location, GenDialogService, MessageService, Buildings
                    , Tools, $http, Notify, Analysis, WidgetData, License, $timeout) {
                    var ctrl = this;

                    var oldRooms = null;
                    var isIntervalRequestIsInProgress = false;
                    ctrl.allowView = (ctrl.user.roles.indexOf('ROLE_LOCATION_VIEW') !== -1);
                    ctrl.allowEdit = (ctrl.user.roles.indexOf('ROLE_LOCATION_EDIT') !== -1);
                    ctrl.allowDelete = (ctrl.user.roles.indexOf('ROLE_LOCATION_DELETE') !== -1);
                    ctrl.allowCreate = (ctrl.user.roles.indexOf('ROLE_LOCATION_CREATE') !== -1);
                    ctrl.isAdmin = (ctrl.user.roles.indexOf('ROLE_ADMIN') !== -1);

                    ctrl.buildingButtons = (ctrl.building !== false);

                    ctrl.selectedBuildingId = ctrl.buildings !== undefined && ctrl.buildings.length > 0 && !ctrl.currentBuildingId ? ctrl.buildings[0].id : ctrl.currentBuildingId;
                    if(ctrl.currentLocationId !== undefined){
                        ctrl.currentLocationId = ctrl.buildings !== undefined && ctrl.buildings.length > 0 && !ctrl.currentBuildingId ? ctrl.buildings[0].fkLocation : ctrl.currentLocationId;
                    }
                    else{
                        ctrl.currentLocationId = ctrl.buildings !== undefined && ctrl.buildings.length > 0 && ctrl.buildings[0].fkLocation;
                    }
                    ctrl.sortedRooms = {};
                    ctrl.showList = $stateParams.view || parseInt(localStorageService.get("location-room-view")) || 0;
                    ctrl.toggle = true;
                    ctrl.isFreeVersion = false;
                    ctrl.LicenseExpired = false;
                    ctrl.limitReached = false;
                    ctrl.importInProgress = false;
                    ctrl.exportInProgress = false;
                    ctrl.deleteInProgress = false;

                    ctrl.$onInit = function () {
                        License.getIsFreeVersion().then(function (response) {
                            if (response.data === "freeVersion") {
                                ctrl.isFreeVersion = true;
                                ctrl.checkLicense();
                            } else {
                                ctrl.isFreeVersion = false;
                                ctrl.checkLicense();
                            }
                        });
                        License.getExpired().then(function (response) {
                            ctrl.LicenseExpired = response.data;
                        });
                        oldRooms = angular.copy(ctrl.rooms);
                        sortRoomsByFloor();
                        var tmpState = localStorageService.get("location-room-view");
                        if (Tools.isDefinedNotNull(tmpState)) {
                            ctrl.showList = parseInt(tmpState);
                        } else {
                            ctrl.showList = 0;
                        }
                    };

                    ctrl.$onDestroy = function () {
                        stopLiveDataQuery();
                    };

                    ctrl.$doCheck = function () {
                        if (roomsChanged()) {
                            oldRooms = angular.copy(ctrl.rooms);
                            sortRoomsByFloor();
                            stopLiveDataQuery();
                            queryLiveData();
                            startLiveDataQuery();
                        }
                    };

                    /**
                     * @description function to check whether rooms array has been changed
                     * @returns {boolean} returns true if rooms array has changed otherwise false
                     */
                    var roomsChanged = function () {
                        if (oldRooms.length !== ctrl.rooms.length) return true;
                        for (var i in oldRooms) {
                            if (oldRooms[i].id !== ctrl.rooms[i].id) return true;
                        }
                        return false;
                    };

                    var currentModal = null;
                    var liveDataInterval = null;

                    /**
                     * @description function to sort rooms by floor
                     */
                    var sortRoomsByFloor = function () {
                        ctrl.sortedRooms = {};
                        for (var i in ctrl.rooms) {
                            var floor = ctrl.rooms[i].ffloor;
                            if (ctrl.sortedRooms.hasOwnProperty(floor)) {
                                ctrl.sortedRooms[floor].rooms.push(ctrl.rooms[i]);
                            } else {
                                ctrl.sortedRooms[floor] = {};
                                ctrl.sortedRooms[floor].floor = floor;
                                ctrl.sortedRooms[floor].rooms = [ctrl.rooms[i]];
                            }
                        }
                    };

                    /**
                     * @description function to query live data (min, max, avg temp etc.)
                     */
                    var queryLiveData = function () {
                        if (!isIntervalRequestIsInProgress) {
                            isIntervalRequestIsInProgress = true;
                            LiveDataService.getLiveDataForOverview($state.params.id).then(function (response) {
                                if (response !== undefined && response.data !== null) {
                                    var noValueTranslate = $translate.instant(["livedata.novalue"])["livedata.novalue"];
                                    response = response.data;
                                    for (var i in response) {
                                        if (ctrl.sortedRooms[i] === undefined) {
                                            continue;
                                        }
                                        handleTempObject(ctrl.sortedRooms[i], response[i], noValueTranslate);
                                        for (var r in response[i].rooms) {
                                            var tempRoom = ctrl.sortedRooms[i].rooms.filter(function (item) {
                                                return item.uniqueId === parseInt(r);
                                            })[0];
                                            if (tempRoom !== undefined) handleTempObject(tempRoom, response[i].rooms[r], noValueTranslate);
                                        }
                                    }
                                }
                                isIntervalRequestIsInProgress = false;
                            }, function (error) {
                                $log.error("Error live data overview\n" + error + "\n" + error.data);
                                isIntervalRequestIsInProgress = false;
                            });
                        }
                    };

                    /**
                     * @description function to start live data query
                     */
                    var startLiveDataQuery = function () {
                        if (liveDataInterval === null) liveDataInterval = $interval(queryLiveData, 10000);
                    };

                    /**
                     * @description function to stop live data query
                     */
                    var stopLiveDataQuery = function () {
                        if (liveDataInterval !== null) {
                            $interval.cancel(liveDataInterval);
                            liveDataInterval = null;
                        }
                    };

                    queryLiveData();
                    startLiveDataQuery();

                    /**
                     * @description function to process received live data information
                     * @param {object} target the target object to set live data values for
                     * @param {object} src the source object to get live data values from
                     * @param {string} noValueTranslate the translate string to show if no value is present
                     */
                    var handleTempObject = function (target, src, noValueTranslate) {
                        var tempSettings = ctrl.user.settings.filter(function (settings) {
                            return settings.key === "temp";
                        });
                        var tempValue = Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0";
                        if (src.avgTemp !== null && src.avgTemp > -2000000) {
                            target.avgTemp = LiveDataService.convertTemp(src.avgTemp / 1000, tempValue, 1);
                            target.minTemp = LiveDataService.convertTemp(src.minTemp / 1000, tempValue, 1);
                            target.maxTemp = LiveDataService.convertTemp(src.maxTemp / 1000, tempValue, 1);
                        } else {
                            target.avgTemp = noValueTranslate;
                            target.minTemp = noValueTranslate;
                            target.maxTemp = noValueTranslate;
                        }
                        if (src.totalConsumption !== null && !isNaN(src.totalConsumption) && src.totalConsumption > -2000000) {
                            target.totalConsumption = (src.totalConsumption / 1000).toFixed(1) + ' kWh';
                        } else {
                            target.totalConsumption = noValueTranslate;
                        }
                        if (src.totalPower !== null && !isNaN(src.totalPower) && src.totalPower > -2000000) {
                            target.totalPower = (src.totalPower / 1000).toFixed(2) + ' W';
                        } else {
                            target.totalPower = noValueTranslate;
                        }
                        target.alarm = src.alarm;
                    };
                    /**
                     * @description function to check license
                     */
                    ctrl.checkLicense = function () {
                        if (ctrl.isFreeVersion) {
                            setTimeout(function () {
                                var buttonImpExp = Array.from(document.querySelectorAll(".import-export"));
                                var tooltipImpExp = $translate.instant('global.btn.disabledOnFreLicense');
                                if (Tools.isDefinedNotNull(buttonImpExp)) {
                                    buttonImpExp.forEach(function (singleButton) {
                                            singleButton.setAttribute("title", tooltipImpExp);
                                        }
                                    );
                                }
                            }, 100);
                            License.getRoomLimitReached().then(function (response) {
                                if (response.data) {
                                    ctrl.limitReached = true;
                                    setTimeout(function () {
                                        var button = document.getElementById("addRoomButton");
                                        var tooltip = $translate.instant('global.btn.freeVersion');
                                        if (Tools.isDefinedNotNull(button)) button.setAttribute("title", tooltip);
                                    }, 100);
                                } else {
                                    setTimeout(function () {
                                        ctrl.limitReached = false;
                                        var button = document.getElementById("addRoomButton");
                                        if (Tools.isDefinedNotNull(button)) button.removeAttribute("title");
                                    }, 100);
                                }
                            });
                        }
                    }

                    /**
                     * @description function to trigger changes for changed selected building
                     */
                    ctrl.handleBuildingIdChange = function () {
                        MessageService.publish("buildingIdChanged", {bid: ctrl.selectedBuildingId});
                    };

                    /**
                     * @description function to open floor view (click on row header)
                     * @param {number} floor the floor number
                     */
                    ctrl.showFloor = function (floor) {
                        $state.go("location.rooms.floorview", {floorid: floor, bid: ctrl.selectedBuildingId});
                    };

                    /**
                     * @description function to open room view for room specified by room id
                     * @param {number} roomId the room id to use
                     */
                    ctrl.showRoom = function (roomId) {
                        var rm = 'view';
                        $state.go('location.rooms.view', {roomid: roomId, roomMode: rm});
                    };

                    /**
                     * @description function to switch between list/table view and tiled view
                     */
                    ctrl.toggleView = function () {
                        ctrl.showList = !ctrl.showList;
                        var params = angular.copy($state.params);
                        params.view = ctrl.showList ? 1 : 0;
                        localStorageService.set('location-room-view', params.view);
                        $state.go($state.current.name, params, {notify: false});
                    };

                    /**
                     * @description function to show/hide room tree for specified room (in list view)
                     * @param {object} room the room object to show/hide tree for
                     */
                    ctrl.showRoomTree = function (room) {
                        room.showsTree = !room.showsTree;
                    };

                    /**
                     * @description function to handle click on 'Add room' button
                     */
                    ctrl.addRoom = function () {
                        if (!ctrl.building && ctrl.buildings.length === 0) {
                            $translate(['global.btn.close', 'location.action.addBuilding', 'global.dialog.head.warning', 'room.overview.noBuildingWarning']).then(function (trans) {
                                GenDialogService.showDialog(true, {
                                    textButton0: trans['global.btn.close'],
                                    textButton1: trans['location.action.addBuilding'],
                                    iconButton0: 'glyphicon glyphicon-remove',
                                    iconButton1: 'glyphicon glyphicon-plus',
                                    classButton0: 'btn btn-default',
                                    classButton1: 'btn btn-create',
                                    headText: trans['global.dialog.head.warning'],
                                    headIcon: 'glyphicon glyphicon-warn-sign',
                                    messageText: trans['room.overview.noBuildingWarning'],
                                    showClose: true,
                                    callbackButton1: function () {
                                        GenDialogService.hideDialog();
                                        $state.go('location.buildings.new', {}, {reload: true});
                                    },
                                    callbackButton0: function () {
                                        GenDialogService.hideDialog();
                                    }
                                });
                            });

                        } else {
                            if (!ctrl.building) {
                                $state.go('location.rooms.create', {
                                    roomMode: "edit",
                                    bid: ctrl.selectedBuildingId
                                });
                            } else {
                                $state.go('location.rooms.create', {roomMode: "edit", bid: ctrl.currentBuildingId});
                            }
                        }
                    };

                    /**
                     * @description function to open view mode for specified room by its id (click on view button in list view)
                     * @param {number} roomId the room id to use
                     */
                    ctrl.viewRoom = function (roomId) {
                        $state.go('location.rooms.view', {roomid: roomId, roomMode: "view"});
                    };

                    /**
                     * @description function to open edit view mode for specified room by its id (click on edit button in list view)
                     * @param {number} roomId the room id to use
                     */
                    ctrl.editRoom = function (roomId) {
                        $state.go('location.rooms.edit', {roomid: roomId, roomMode: "edit"});
                    };

                    var searchForDriverValues = function (room) {
                        var searchResult = {
                            driverValuesIds: [],
                            driverValuesUnqIds: []
                        }, d = 0;
                        var searchArray = function (arr) {
                            for (var i = 0; i < arr.length; i++) {
                                if (arr[i].driverValues && arr[i].driverValues.length > 0) {
                                    for (var dv = 0; dv < arr[i].driverValues.length; dv++) {
                                        searchResult.driverValuesIds.push(arr[i].driverValues[dv].id);
                                        if (arr[i].driverValues[dv].driver.driverType === 5) searchResult.driverValuesUnqIds.push(arr[i].driverValues[dv].uniqueId);
                                    }
                                }
                            }
                        };
                        searchArray(room.assets);
                        searchArray(room.sensors);
                        searchArray(room.coolings);
                        searchArray(room.roomObjs);
                        searchArray(room.floorTiles);
                        searchArray(room.ups);
                        for (var i = 0; i < room.racks.length; i++) {
                            if (room.racks[i].driverValues) {
                                for (d = 0; d < room.racks[i].driverValues.length; d++) {
                                    searchResult.driverValuesIds.push(room.racks[i].driverValues[d].id);
                                    if (room.racks[i].driverValues[d].driver.driverType === 5) searchResult.driverValuesUnqIds.push(room.racks[i].driverValues[d].uniqueId);
                                }
                            }
                            for (var j = 0; j < room.racks[i].slots.length; j++) {
                                for (d = 0; d < room.racks[i].slots[j].driverValues.length; d++) {
                                    searchResult.driverValuesIds.push(room.racks[i].slots[j].driverValues[d].id);
                                    if (room.racks[i].slots[j].driverValues[d].driver.driverType === 5) searchResult.driverValuesUnqIds.push(room.racks[i].slots[j].driverValues[d].uniqueId);
                                }
                                for (var k = 0; k < room.racks[i].slots[j].blades.length; k++) {
                                    for (d = 0; d < room.racks[i].slots[j].blades[k].driverValues.length; d++) {
                                        searchResult.driverValuesIds.push(room.racks[i].slots[j].blades[k].driverValues[d].id);
                                        if (room.racks[i].slots[j].blades[k].driverValues[d].driver.driverType === 5) searchResult.driverValuesUnqIds.push(room.racks[i].slots[j].blades[k].driverValues[d].uniqueId);
                                    }
                                    searchArray(room.racks[i].slots[j].blades[k].cpus);
                                }
                            }
                        }
                        return searchResult;
                    };

                    /**
                     * @description function to handle click on delete button in list view
                     * @param {number} roomId the room id for the room to delete
                     */
                    ctrl.deleteRoom = function (roomId) {
                        var ids = [], driverValueModbusUids = [];
                        for (var i = 0; i < ctrl.rooms.length; i++) {
                            if (ctrl.rooms[i].id === roomId) {
                                var searchResult = searchForDriverValues(ctrl.rooms[i]);
                                ids = searchResult.driverValuesIds;
                                driverValueModbusUids = searchResult.driverValuesUnqIds;

                                $http.post('api/locations/errorConfig/checkDriverIsInUse', ids).then(function (response) {
                                    if (response.data) {
                                        var message = "room.edit.dialog.messages.driverIsInUseInRoom";
                                        $translate(['global.dialog.head.warning', message, 'global.btn.ok']).then(function (trans) {
                                            GenDialogService.showDialog(true, {
                                                headText: trans['global.dialog.head.warning'],
                                                headIcon: "glyphicon glyphicon-warning-sign",
                                                messageText: trans[message] + "<br/><br/>",
                                                showClose: true,
                                                textButton0: trans['global.btn.ok'],
                                                iconButton0: 'glyphicon glyphicon-ok',
                                                classButton0: 'btn-default',
                                                callbackButton0: function () {
                                                    GenDialogService.hideDialog();
                                                }
                                            });
                                        });
                                    } else {
                                        GenDialogService.showDialog(false, {
                                            callbackButton1: function () {
                                                ctrl.deleteInProgress = true;
                                                GenDialogService.hideDialog();

                                                RoomService.deleteAllReferenceForDeletedDriverValueModbusUids(driverValueModbusUids);

                                                RoomService.deleteRoom(roomId).then(function (response) {
                                                    ctrl.deleteInProgress = false;
                                                    MessageService.publish("deletedRoom", {
                                                        roomid: roomId,
                                                        buildingId: ctrl.selectedBuildingId
                                                    });

                                                }, function (response) {
                                                    $log.error("Error delete room with id: " + roomId + "\n" + response);
                                                });
                                                setTimeout(function () {
                                                    ctrl.checkLicense();
                                                }, 100);
                                            },
                                            callbackButton0: function () {
                                                ctrl.deleteInProgress = false;
                                                GenDialogService.hideDialog();
                                            },
                                            textButton1: $translate.instant(['global.btn.delete'])['global.btn.delete'],
                                            textButton0: $translate.instant(['global.btn.cancel'])['global.btn.cancel'],
                                            iconButton1: 'glyphicon glyphicon-remove-circle',
                                            iconButton0: 'glyphicon glyphicon-remove',
                                            classButton1: 'btn-delete',
                                            classButton0: 'btn-default',
                                            headText: $translate.instant(['room.overview.dialogDeleteHead'])['room.overview.dialogDeleteHead'],
                                            headIcon: "glyphicon glyphicon-remove-circle",
                                            messageText: "<div>" + getDeleteQuestion(roomId) + "</div>",
                                            showClose: false
                                        });
                                    }
                                });
                                break;
                            }
                        }
                    };

                    ctrl.removeBuildingAssociation = function (room) {
                        MessageService.publish("removeBuildingAssociation", room);
                    };

                    /**
                     * @description function to create translated string for modal asking for confirmation of delete action
                     * @param {number} roomId the room id for the soon to be deleted room
                     * @returns {string}
                     */
                    var getDeleteQuestion = function (roomId) {
                        var str = $translate.instant(['room.overview.dialogDeleteMessage'])['room.overview.dialogDeleteMessage'];
                        var roomname = ctrl.rooms.filter(function (r) {
                            return r.id === roomId
                        })[0].name;
                        str = str.replace(/%obj/g, roomname);
                        return str;
                    };

                    /**
                     * @description function to get data for move room modal
                     * @param {object} room the room object that should be moved
                     * @private
                     */
                    var _getAvailableLocations = function (room, isLocationFkExist) {
                        Location.queryAll().then(function (response) {
                            if (response.data !== null) {
                                var locIds = [];
                                for (var i in response.data) {
                                    locIds.push(response.data[i].id);
                                }
                                var locations = response.data;

                                Buildings.getBuildingsForLocations(locIds).then(function (responseInner) {

                                    for (var locId in responseInner.data) {
                                        var loc = locations.filter(function (l) {
                                            return l.id === parseInt(locId);
                                        });
                                        if (loc !== undefined && loc.length > 0) {
                                            loc[0].buildings = responseInner.data[locId];
                                        }
                                    }

                                    locations = locations.filter(function (l) {
                                        return l.buildings !== undefined && l.buildings.length > 0;
                                    });

                                    currentModal = $uibModal.open({
                                        animation: false,
                                        component: 'roomMoveModal',
                                        windowClass: 'animated fadeInDown',
                                        resolve: {
                                            availableLocations: function () {
                                                return locations;
                                            },
                                            roomId: function () {
                                                return room.id;
                                            },
                                            locationId: function () {
                                                return room.locationId;
                                            },
                                            buildingId: function () {
                                                return room.buildingId;
                                            },
                                            isLocationFkExist: function () {
                                                return isLocationFkExist;
                                            }
                                        }
                                    });
                                }, function (errorInner) {
                                    $log.error("Error retrieving buildings for locations\n" + errorInner);
                                });

                            }
                        });
                    };

                    /**
                     * @description function to check if location is empty after room was moved, if so a modal will be displayed,
                     *              offering the option to delete the now empty location
                     * @private
                     */
                    var _checkLocation = function () {
                        Location.isEmpty($stateParams.id).then(function (response) {
                            if (response.data && response.data === true) {
                                if (currentModal) currentModal.close();
                                currentModal = $uibModal.open({
                                    animation: false,
                                    component: 'locationEmptyModal',
                                    windowClass: 'animated fadeInDown',
                                    resolve: {
                                        locationId: function () {
                                            return $stateParams.id;
                                        }
                                    }
                                });
                            }
                        });
                    };

                    var _getAllAvailableRooms = function (buildingId) {
                        currentModal = $uibModal.open({
                            animation: false,
                            component: 'roomExportModal',
                            windowClass: 'animated fadeInDown',
                            resolve: {
                                availableRooms: function () {
                                    // return ctrl.roomWithoutLiveData;
                                    return ctrl.rooms;
                                },
                                buildingId: function () {
                                    return buildingId;
                                }
                            }
                        });

                    };

                    ctrl.closeModal = function () {
                        if (currentModal) currentModal.close();
                    };

                    ctrl.openMoveModal = function (room) {
                        ctrl.closeModal();
                        Analysis.anyRoomDpAttachedToAnalysis(room.id, room.locationId).then(function (responceForAnalysisAttachment) {
                            WidgetData.isWidgetConfiguredWithLocation(room.id, room.locationId).then(function (widgetResponse) {
                                var isLocationFkExist = widgetResponse.data || responceForAnalysisAttachment.data ? true : false;
                                _getAvailableLocations(room, isLocationFkExist);
                            });
                        });
                    };

                    ctrl.openExportModal = function (buildingId) {
                        ctrl.exportInProgress = true;
                        ctrl.closeModal();
                        _getAllAvailableRooms(buildingId);
                    };

                    ctrl.openImportModal = function () {
                        if (!ctrl.building && ctrl.buildings.length === 0) {
                            $translate(['global.btn.close', 'location.action.addBuilding', 'global.dialog.head.warning', 'room.overview.noBuildingWarning']).then(function (trans) {
                                GenDialogService.showDialog(true, {
                                    textButton0: trans['global.btn.close'],
                                    textButton1: trans['location.action.addBuilding'],
                                    iconButton0: 'glyphicon glyphicon-remove',
                                    iconButton1: 'glyphicon glyphicon-plus',
                                    classButton0: 'btn btn-default',
                                    classButton1: 'btn btn-create',
                                    headText: trans['global.dialog.head.warning'],
                                    headIcon: 'glyphicon glyphicon-warn-sign',
                                    messageText: trans['room.overview.noBuildingWarning'],
                                    showClose: true,
                                    callbackButton1: function () {
                                        GenDialogService.hideDialog();
                                        $state.go('location.buildings.new', {}, {reload: true});
                                    },
                                    callbackButton0: function () {
                                        GenDialogService.hideDialog();
                                    }
                                });
                            });
                        } else {
                            ctrl.closeModal();
                            currentModal = $uibModal.open({
                                animation: false,
                                component: 'roomImportModal',
                                windowClass: 'animated fadeInDown',
                                resolve: {
                                    availableRooms: function () {
                                        return ctrl.rooms;
                                    }
                                }
                            });
                            // Callback for import modal close action
                            currentModal.result.then(function (impRoomInfo) {
                                ctrl.importInProgress = true;
                                var data = new FormData();
                                data.append('file', impRoomInfo.selectedFile);
                                data.append('fileName', impRoomInfo.selectedFile.name);
                                data.append('fileSize', impRoomInfo.selectedFile.size);
                                data.append('fileType', impRoomInfo.selectedFile.type);
                                RoomService.importRoom(data, impRoomInfo.selectedOption, ctrl.currentLocationId, ctrl.selectedBuildingId).then(function (response) {
                                        if (response && response.data === true) {
                                            ctrl.importInProgress = false;
                                            Notify.success("global.notification.success.title", "room.modal.importSuccessfull", 3000);
                                            $timeout(function () {
                                                $state.reload();
                                            }, 3000);
                                        } else {
                                            ctrl.importInProgress = false;
                                            Notify.error("global.notification.error.title", "room.modal.importFailed", 3000);
                                        }
                                    }
                                );
                            }, function (dismissReason) {
                                ctrl.importInProgress = false;
                                //nothing to to on dismiss for mow
                            });
                        }
                    };
                }],
            controllerAs: '$ctrl'
        });
})();
