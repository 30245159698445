(function () {
    'use strict';

    angular.module('emsv2App')
        .component('pueMonitor', {
            controller: 'PueMonitorController',
            controllerAs: 'ctrl',
            bindings: {
                idSuffix: '<',
                locationId: '<',
                ranges: '<',
                compare: '<',
                legendConfig: '<',
                showSettings: '<',
                onCloseSettings: '&',
                onSettingsChanged: '&',
                selectLocationEnabled: '<'
            },
            templateUrl: 'scripts/components/energy/pue/pue-monitor.html'
        });
})();