(function () {
    'use strict';

    function newUserController($translate, $state, $scope, $window, $log, $timeout, $q, ArrayHelper, Authority, RandomPassword, Auth, notify,
                               roles, countries, locations, currentUser, Notify, GenDialogService, Tools) {
        var ctrl = this;
        const EDIT_LOCATION_RIGHT = "ROLE_LOCATION_EDIT";
        const CREATE_LOCATION_RIGHT = "ROLE_LOCATION_CREATE";
        var initUser = null;
        ctrl.state = $state;
        ctrl.saveBtn = 'init';
        ctrl.forms = {};
        ctrl.roles = roles.filter(function (value) { return value !== "ROLE_LOCATION_VIEW"; });
        ctrl.countries = countries;
        ctrl.locationList = locations;
        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.user = {
            login: '',
            password: RandomPassword.generate(10),
            notify: false,
            roles: ['ROLE_LOCATION_VIEW'],
            locations: [],
            langKey: $translate.use(),
            sendActivationMail: false,
            contact: {
                gender: 0,
                firstname: '',
                lastname: '',
                company: '',
                email: '',
                secEmail: '',
                street: '',
                zip: '',
                location: '',
                country: null,
                phone: '',
                fax: ''
            }
        };
        ctrl.PresetRole = null;
        ctrl.roleAdmin = ctrl.user.roles.filter(function (value) { return value === "ROLE_ADMIN"; });
        ctrl.isAdminRole = false;

        if (ctrl.roleAdmin[0] === "ROLE_ADMIN") {
            ctrl.isEnabledAdmin = true;
        } else {
            ctrl.isEnabledAdmin = false;
        }

        ctrl.$onInit = function () {
            initUser = angular.copy(ctrl.user);
            if (currentUser !== null) {
                for (var i = 0; i < currentUser.roles.length; i++) {
                    if (currentUser.roles[i] === "ROLE_ADMIN") {
                        ctrl.isAdminRole = true;
                    }
                }
            }
        };

        ctrl.compareContactValues = function (userA, userB) {

            var aProps = Object.getOwnPropertyNames(userA);
            var bProps = Object.getOwnPropertyNames(userB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            if (userA["company"] !== userB["company"]) return true;
            if (userA["country"] !== userB["country"]) return true;
            if (userA["email"] !== userB["email"]) return true;
            if (userA["secEmail"] !== userB["secEmail"]) return true;
            if (userA["fax"] !== userB["fax"]) return true;
            if (userA["firstname"] !== userB["firstname"]) return true;
            if (userA["gender"] !== userB["gender"]) return true;
            if (userA["lastname"] !== userB["lastname"]) return true;
            if (userA["location"] !== userB["location"]) return true;
            if (userA["phone"] !== userB["phone"]) return true;
            if (userA["mobile"] !== userB["mobile"]) return true;
            if (userA["street"] !== userB["street"]) return true;
            if (userA["zip"] !== userB["zip"]) return true;
        };

        ctrl.compareRoleValues = function (userA, userB) {
            var aProps = Object.getOwnPropertyNames(userA);
            var bProps = Object.getOwnPropertyNames(userB);
            if (aProps.length !== bProps.length) {
                return true;
            }
        };

        ctrl.compareUserValues = function (userA, userB) {
            var aProps = Object.getOwnPropertyNames(userA);
            var bProps = Object.getOwnPropertyNames(userB);
            if (aProps.length !== bProps.length) {
                return true;
            }
            if (userA["login"] !== userB["login"]) return true;
            if (ctrl.compareContactValues(userA["contact"], userB["contact"])) return true;
            if (ctrl.compareRoleValues(userA["roles"], userB["roles"])) return true;

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalUserChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalUserChange = function () {
            var currUser = angular.copy(ctrl.user);
            if (ctrl.compareUserValues(initUser, currUser)) return true;
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!ignoreNextStateChange && ctrl.evalUserChange() && toState.name !== "editUser") {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('global.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('global.modal.empty.yes'),
                    textButton0: $translate.instant('global.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initUser = null;
                        currentUser = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        var toggleAll = function (adminChecked) {
            ctrl.roles = roles.filter(function (value) { return value !== "ROLE_LOCATION_VIEW"; });
            for (var i = 0; i < ctrl.roles.length; i++) {
                var roleId = "input-" + ctrl.roles[i];
                var domElement = document.getElementById(roleId);
                if (adminChecked) {
                    if( roleId !== "input-ROLE_REMOTE_CONTROL" && Tools.isDefinedNotNull(domElement)){
                        domElement.checked = true;
                        ArrayHelper.addValue(ctrl.user.roles, ctrl.roles[i]);
                    }
                    if( roleId === "input-ROLE_REMOTE_CONTROL"  && Tools.isDefinedNotNull(domElement)){
                        domElement.checked = false;
                        ArrayHelper.removeValue(ctrl.user.roles, ctrl.roles[i]);
                    }
                }
                else {
                    ArrayHelper.removeValue(ctrl.user.roles, ctrl.roles[i]);
                }
            }
        };

        var switchToTechnical = function () {
            for (var i = 0; i < ctrl.roles.length; i++) {
                var roleId = "input-" + ctrl.roles[i];
                var domElement = document.getElementById(roleId);
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_ADMIN") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_ADMIN");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_ALARM_MANAGEMENT") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_ALARM_MANAGEMENT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_ANALYSIS") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_ANALYSIS");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_AUTO_LOGIN") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_AUTO_LOGIN");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_CONTACT") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_CONTACT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_DASHBOARD") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_DASHBOARD");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_GROUP") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_GROUP");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LEGALS") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LEGALS");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LICENSING") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LICENSING");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOCATION_CREATE") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LOCATION_CREATE");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOCATION_DELETE") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LOCATION_DELETE");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOCATION_EDIT") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LOCATION_EDIT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOGS") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LOGS");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_REPORT") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_REPORT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_UPDATE_ASSIGN") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_UPDATE_ASSIGN");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_UPDATE_MANAGEMENT") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_UPDATE_MANAGEMENT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_USER_MANAGEMENT") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_USER_MANAGEMENT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_REMOTE_CONTROL") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_REMOTE_CONTROL");
                }
            }
        };

        var switchToOperator = function () {
            for (var i = 0; i < ctrl.roles.length; i++) {
                var roleId = "input-" + ctrl.roles[i];
                var domElement = document.getElementById(roleId);
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_ADMIN") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_ADMIN");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_ALARM_MANAGEMENT") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_ALARM_MANAGEMENT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_ANALYSIS") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_ANALYSIS");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_AUTO_LOGIN") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_AUTO_LOGIN");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_CONTACT") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_CONTACT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_DASHBOARD") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_DASHBOARD");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_GROUP") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_GROUP");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LEGALS") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LEGALS");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LICENSING") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LICENSING");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOCATION_CREATE") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_LOCATION_CREATE");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOCATION_DELETE") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_LOCATION_DELETE");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOCATION_EDIT") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_LOCATION_EDIT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_LOGS") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_LOGS");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_REPORT") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_REPORT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_UPDATE_ASSIGN") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_UPDATE_ASSIGN");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_UPDATE_MANAGEMENT") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_UPDATE_MANAGEMENT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_USER_MANAGEMENT") {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, "ROLE_USER_MANAGEMENT");
                }
                if (Tools.isDefinedNotNull(domElement) && roleId === "input-ROLE_REMOTE_CONTROL") {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, "ROLE_REMOTE_CONTROL");
                }
            }
        };


        var checkPresetRole = function (roles) {
            if (ctrl.isEnabledAdmin) return ctrl.PresetRole = "0";
                var filteredroles = [];
                angular.forEach(roles, function (role) {
                    if (!role.contains("ROOM_EDIT") && !role.contains("ROLE_LOCATION_VIEW")  && !role.contains("DEBUG")
                        && role !== "ROLE_USER") {
                        filteredroles.push(role);
                    }

                })
                var administratorValues = [ "ROLE_ADMIN",
                                            "ROLE_ALARM_MANAGEMENT",
                                            "ROLE_ANALYSIS",
                                            "ROLE_CONTACT",
                                            "ROLE_DASHBOARD",
                                            "ROLE_GROUP",
                                            "ROLE_LEGALS",
                                            "ROLE_LICENSING",
                                            "ROLE_LOCATION_CREATE",
                                            "ROLE_LOCATION_DELETE",
                                            "ROLE_LOCATION_EDIT",
                                            "ROLE_LOGS",
                                            "ROLE_REPORT",
                                            "ROLE_UPDATE_MANAGEMENT",
                                            "ROLE_USER_MANAGEMENT" ];

                var technicalValues = [ "ROLE_ALARM_MANAGEMENT",
                                        "ROLE_ANALYSIS",
                                        "ROLE_DASHBOARD",
                                        "ROLE_REPORT" ];

                var operatorValues =  [ "ROLE_ALARM_MANAGEMENT",
                                        "ROLE_ANALYSIS",
                                        "ROLE_DASHBOARD",
                                        "ROLE_LOCATION_CREATE",
                                        "ROLE_LOCATION_DELETE",
                                        "ROLE_LOCATION_EDIT",
                                        "ROLE_REPORT",
                                        "ROLE_USER_MANAGEMENT" ];
                //easy way to check if 2 arrays (string or number elements) are equals

            if(filteredroles.sort().join(',') === administratorValues.sort().join(',')) return ctrl.PresetRole = "0";
            if(filteredroles.sort().join(',') === technicalValues.sort().join(',')) return ctrl.PresetRole = "1";
            if (filteredroles.sort().join(',') === operatorValues.sort().join(',')) return ctrl.PresetRole = "2";

            ctrl.PresetRole = null;

        } ;

        ctrl.toggleAuthority = function (value) {
            if (value === "ROLE_ADMIN") {
                if(ctrl.user.roles.includes("ROLE_ADMIN",0)){
                    ArrayHelper.toggleValue(ctrl.user.roles, "ROLE_ADMIN");
                    ctrl.isEnabledAdmin = false;
                    checkPresetRole(ctrl.user.roles);
                }
                else{
                    ArrayHelper.toggleValue(ctrl.user.roles, "ROLE_ADMIN");
                    checkPresetRole(ctrl.user.roles);
                    if(ctrl.PresetRole === "0"){
                        ctrl.isEnabledAdmin = true;
                    }
                }

                return;
            }
            if (value === EDIT_LOCATION_RIGHT) {
                ArrayHelper.toggleValue(ctrl.user.roles, CREATE_LOCATION_RIGHT);
            }
            else if (value === CREATE_LOCATION_RIGHT) {
                ArrayHelper.toggleValue(ctrl.user.roles, EDIT_LOCATION_RIGHT);
            }
            if(value === "ROLE_REMOTE_CONTROL"){
                if(ctrl.user.roles.includes("ROLE_REMOTE_CONTROL",0)){
                    ArrayHelper.toggleValue(ctrl.user.roles, value);
                    checkPresetRole(ctrl.user.roles);
                    if(ctrl.PresetRole === "0"){
                        ctrl.isEnabledAdmin = true;
                    }
                }
                else{
                    ctrl.isEnabledAdmin = false;
                    ArrayHelper.toggleValue(ctrl.user.roles, value);
                    checkPresetRole(ctrl.user.roles);
                }
            }
            else{
                ctrl.isEnabledAdmin = false;
                ArrayHelper.toggleValue(ctrl.user.roles, value);
                checkPresetRole(ctrl.user.roles);
            }
        };

        ctrl.changePresetRole = function () {
            switch (ctrl.PresetRole) {
                case "0":
                    ctrl.isEnabledAdmin = true;
                    toggleAll(true);
                    break;

                case "1":
                    ctrl.isEnabledAdmin = false;
                    switchToTechnical();
                    break;

                case "2":
                    ctrl.isEnabledAdmin = false;
                    switchToOperator();
                    break;

            }
        };

        ctrl.checkPwd = function () {
            if (ctrl.pwdForm.password !== ''
                && ctrl.pwdForm.confirmPassword !== ''
                && ctrl.pwdForm.password !== ctrl.pwdForm.confirmPassword) {
                ctrl.pwdForm.noMatch = 'noMatch';
                return;
            }
            ctrl.pwdForm.noMatch = '';
        };

        ctrl.onLoginChanged = function (login) {
            ctrl.loginExists = false;
            if (!login || login.length < 2) {
                return;
            }

            Auth.checkLoginExists(login).then(function (exists) {
                ctrl.loginExists = exists.data;
            }, function (error) {
                $log.error('Couldn\'t check login.', error);
            });
        };

        ctrl.hasAuthority = function (authority) {
            if (currentUser !== null) {
                for (var i = 0; i < currentUser.roles.length; i++) {
                    if (currentUser.roles[i] === authority || currentUser.roles[i] === "ROLE_ADMIN") {
                        return true;
                    }
                }
            }
            return false;
        };

        ctrl.create = function () {
            var user = ctrl.user;
            ctrl.saveBtn = 'load';
            var locations = angular.copy(ctrl.userLocations);
            var roles = angular.copy(user.roles);
            user.sendActivationMail = false; // Activationmail is always false with admin created accounts
            user.ldap = false;
            user.password = ctrl.pwdForm.password;
            Auth.createAccount(user).then(function (response) {
                var newUser = response.data;
                var promises = [];
                promises.push(Authority.setUserLocations(newUser.id, locations).catch(function (error) {
                    $log.debug('Couldnt save location permissions for: ' + newUser.login, error);
                }));
                promises.push(Authority.set(newUser.id, roles).catch(function (error) {
                    $log.debug('Couldnt save authorities for: ' + newUser.login, error);
                }));
                $q.all(promises).then(function () {
                    ctrl.saveBtn = 'success';
                    Notify.defaultSuccess();
                    $state.go('editUser', {userId: newUser.id});
                });
            }, function (error) {
                ctrl.saveBtn = 'error';
                $log.warn(error);
                $timeout(function () {
                    ctrl.saveBtn = 'init';
                }, 2000);
            });
        };
    }

    angular
        .module('emsv2App')
        .controller('NewUserController', newUserController);

})();
