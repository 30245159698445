(function () {
        'use strict';

        function mailsettingsController($state, $log, $window, Notify, SmtpEmailServerConfigurationService,
                                        SmtpSMSServerConfigurationService, GenDialogService, $scope, $translate) {

            var ctrl = this;
            var initMailSettings = null;
            var initSMSServerConfiguration = null;
            ctrl.phoneNumberValidationRegex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
            ctrl.mailsettings = new SmtpEmailServerConfiguration();
            ctrl.smtpSMSServerConfiguration = new SmtpSMSServerConfiguration();

            ctrl.$onInit = function () {
                getSmtpEmailServerSettings();
                getSmtpSMSServerSettings();
            };

            $window.onbeforeunload = function (e) {
                if (ctrl.evalMailSettingsChange()) {
                    e.preventDefault();
                    return $translate.instant('location.modal.changedValues');
                }
            };

            ctrl.evalMailSettingsChange = function () {
                if (SmtpEmailServerConfiguration.compareValues(initMailSettings, ctrl.mailsettings)) return true;
                if (SmtpSMSServerConfiguration.compareValues(initSMSServerConfiguration, ctrl.smtpSMSServerConfiguration)) return true;
            };

            var ignoreNextStateChange = false;
            $scope.$on("$stateChangeStart", function (event, toState, toParams) {
                if (ctrl.evalMailSettingsChange() && !ignoreNextStateChange) {
                    event.preventDefault();
                    GenDialogService.showDialog(false, {
                        headText: $translate.instant('global.dialog.head.warning'),
                        headIcon: 'glyphicon glyphicon-warning-sign',
                        messageText: $translate.instant('global.modal.changedValues'),
                        showClose: false,
                        textButton1: $translate.instant('global.modal.empty.yes'),
                        textButton0: $translate.instant('global.modal.empty.no'),
                        iconButton1: 'glyphicon glyphicon-trash',
                        iconButton0: 'glyphicon glyphicon-chevron-left',
                        classButton1: 'btn-danger',
                        classButton0: 'btn-default',
                        callbackButton1: function () {
                            GenDialogService.hideDialog();
                            ignoreNextStateChange = true;
                            $state.go(toState.name, toParams);
                        },
                        callbackButton0: function () {
                            GenDialogService.hideDialog();
                        }
                    });
                }
            });

            ctrl.testMailServer = function () {
                if (checkForSmtpMailServerValidation()) {
                    ctrl.mailsettings.inProgress = true;
                    SmtpEmailServerConfigurationService.test(ctrl.mailsettings).then(function (response) {
                            if (response.data === false) {
                                Notify.error("global.notification.error.title", 'mailsettings.email.validate.invalid', 5000);
                            } else {
                                Notify.success("global.notification.success.title", 'mailsettings.email.validate.valid', 5000);
                            }
                            ctrl.mailsettings.inProgress = false;
                        }, function () {
                            Notify.error("global.notification.error.title", 'mailsettings.email.validate.invalid', 5000);
                            ctrl.mailsettings.inProgress = false;
                        }
                    );
                }
            };

            ctrl.testSmtpSMSServer = function () {
                if (checkForSmtpSMSServerValidation()) {
                    ctrl.smtpSMSServerConfiguration.inProgress = true;
                    SmtpSMSServerConfigurationService.test(ctrl.smtpSMSServerConfiguration).then(function (response) {
                            if (response.data === false) {
                                Notify.error("global.notification.error.title", 'mailsettings.sms.validate.invalid', 5000);
                            } else {
                                Notify.success("global.notification.success.title", 'mailsettings.sms.validate.valid', 5000);
                            }
                            ctrl.smtpSMSServerConfiguration.inProgress = false;
                        }, function () {
                            Notify.error("global.notification.error.title", 'mailsettings.sms.validate.invalid', 5000);
                            ctrl.smtpSMSServerConfiguration.inProgress = false;
                        }
                    );
                }
            };

            ctrl.saveMailServerSettings = function () {
                if (checkForSmtpMailServerValidation()) {
                    SmtpEmailServerConfigurationService.save(ctrl.mailsettings).then(function (response) {
                        if (response.status === 200) {
                            Notify.success("global.notification.success.title", 'mailsettings.email.saved', 5000);
                            ctrl.mailsettings = new SmtpEmailServerConfiguration(response.data);
                            initMailSettings = new SmtpEmailServerConfiguration(response.data);
                            ctrl.mailsettings.inEditMode = true;
                        } else {
                            Notify.error("global.notification.error.title", 'mailsettings.email.notSaved', 5000);
                        }
                    }, function () {
                        Notify.error("global.notification.error.title", 'mailsettings.email.notSaved', 5000);
                    });
                }
            };

            function checkForSmtpSMSServerValidation() {
                if (!SmtpSMSServerConfiguration.validate(ctrl.smtpSMSServerConfiguration)) {
                    Notify.error("global.notification.error.title", 'mailsettings.sms.validate.invalid', 5000);
                    return false;
                }

                return true;
            }

            function checkForSmtpMailServerValidation() {
                if (!SmtpEmailServerConfiguration.validate(ctrl.mailsettings)) {
                    Notify.error("global.notification.error.title", 'mailsettings.email.validate.invalid', 5000);
                    return false;
                }
                return true;
            }

            ctrl.saveSmtpSMSServerSettings = function () {
                if (checkForSmtpSMSServerValidation()) {
                    SmtpSMSServerConfigurationService.save(ctrl.smtpSMSServerConfiguration).then(function (response) {
                        if (response.status === 200) {
                            Notify.success("global.notification.success.title", 'mailsettings.sms.saved', 5000);
                            ctrl.smtpSMSServerConfiguration = new SmtpSMSServerConfiguration(response.data);
                            initSMSServerConfiguration = new SmtpSMSServerConfiguration(response.data);
                            ctrl.smtpSMSServerConfiguration.inEditMode = true;
                        } else {
                            Notify.error("global.notification.error.title", 'mailsettings.sms.notSaved', 5000);
                        }
                    }, function () {
                        Notify.error("global.notification.error.title", 'mailsettings.sms.notSaved', 5000);
                    });
                }
            };

            function getSmtpEmailServerSettings() {
                SmtpEmailServerConfigurationService.get().then(function (response) {
                    if (response.status === 200) {
                        ctrl.mailsettings = new SmtpEmailServerConfiguration(response.data);
                        initMailSettings = new SmtpEmailServerConfiguration(response.data);
                    }
                }, function () {
                    Notify.error("global.notification.error.title", 'mailsettings.notSaved', 5000);
                });
            }

            function getSmtpSMSServerSettings() {
                SmtpSMSServerConfigurationService.get().then(function (response) {
                    if (response.status === 200) {
                        ctrl.smtpSMSServerConfiguration = new SmtpSMSServerConfiguration(response.data);
                        initSMSServerConfiguration = new SmtpSMSServerConfiguration(response.data);
                    }
                }, function () {
                    Notify.error("global.notification.error.title", 'mailsettings.notSaved', 5000);
                });
            }
        }

        angular
            .module('emsv2App')
            .controller('MailsettingsController', mailsettingsController);
    }
)();
