'use strict';

/**
 * @description Constructor to create new EscalationLevel object
 * @constructor
 */
function EscalationLevel(escalationLevel) {
    if (escalationLevel !== undefined && escalationLevel !== null) {
        this.id = escalationLevel.id !== undefined ? escalationLevel.id : null;
        this.uniqueId = escalationLevel.uniqueId !== undefined ? escalationLevel.uniqueId : null;
        this.subject = escalationLevel.subject !== undefined ? escalationLevel.subject : "";
        this.delayTime = escalationLevel.delayTime !== undefined ? escalationLevel.delayTime : 0;
        this.interval = escalationLevel.interval !== undefined ? escalationLevel.interval : 0;
        this.isSendMail = escalationLevel.isSendMail !== undefined ? escalationLevel.isSendMail : false;
        this.isSendSms = escalationLevel.isSendSms !== undefined ? escalationLevel.isSendSms : false;
        this.contacts = escalationLevel.contacts !== undefined ? escalationLevel.contacts : [];
        this.locationId = escalationLevel.locationId !== undefined ? escalationLevel.locationId : null;
        this.showMissingMobileInfo = escalationLevel.showMissingMobileInfo !== undefined ? escalationLevel.showMissingMobileInfo : null;
    } else {
        this.id = null;
        this.uniqueId = null;
        this.subject = "";
        this.delayTime = 0;
        this.interval = 0;
        this.isSendMail = false;
        this.isSendSms = false;
        this.contacts = [];
        this.locationId = null;
        this.showMissingMobileInfo = false;
    }
}

EscalationLevel.prototype = {};
EscalationLevel.prototype.constructor = EscalationLevel;

EscalationLevel.compareValues = function (initialEntity, currentEntity) {

    if (initialEntity.delayTime !== currentEntity.delayTime) return true;
    if (initialEntity.interval !== currentEntity.interval) return true;
    if (initialEntity.subject !== currentEntity.subject) return true;
    if (initialEntity.isSendMail !== currentEntity.isSendMail) return true;
    if (initialEntity.isSendSms !== currentEntity.isSendSms) return true;
    if (initialEntity.contacts.length !== currentEntity.contacts.length) return true;
    for (var i = 0; i < initialEntity.contacts.length; i++) {
        if (initialEntity.contacts[i].id !== currentEntity.contacts[i].id) return true;
    }

    return false;
};

EscalationLevel.checkForMobile = function (entity, contacts) {

    if (contacts.length > 0) {
        for (var i = 0; i < contacts.length; i++) {
            if (contacts[i].mobile === undefined || contacts[i].mobile === null || contacts[i].mobile === "") {
                entity.showMissingMobileInfo = true;
                break;
            }
            entity.showMissingMobileInfo = false;
        }
    } else {
        entity.isSendMail = false;
        entity.isSendSms = false;
        entity.showMissingMobileInfo = false;
    }

    return entity;
};