(function () {
    'use strict';

    /**
     * @description component to handle OptionVar input fields
     */
    angular.module("emsv2App")
        .component('validateOptionvarInput', {
            require: {
                ngModelCtrl: "ngModel"
            },
            bindings: {
                validationList: '<',
                ngModel: '=',
                isInvalid: '=',
                errorMgs: '=',
                isEditMode: '='
            },
            template:
                '<input class="form-control" type="text" ng-model="$ctrl.ngModel" ondragstart="return false;"' +
                'ng-class="{\'has-error\': !$ctrl.isInvalid}" ng-readonly="!$ctrl.isEditMode" ng-change="$ctrl.changeName($ctrl.ngModel)" />' +
                '<span ng-show="$ctrl.isInvalid" >{{$ctrl.errorMgs}}</span>',
            controller: [function () {
                var ctrl = this;
                var inputValue = ctrl.ngModel;

                ctrl.$onInit = function () {
                    if (inputValue !== null && inputValue !== undefined) {
                        checkInput();
                    }
                };

                ctrl.changeName = function (object) {
                    inputValue = object;
                    if (inputValue !== null && inputValue !== undefined) {
                        checkInput();
                    }
                };

                var checkInput = function () {
                    if (ctrl.validationList.length > 0) {
                        for (var i = 0; i < ctrl.validationList.length; i++) {
                            if (inputValue.match(ctrl.validationList[i])) {
                                ctrl.isInvalid = true;
                                return;
                            }
                            else {
                                ctrl.isInvalid = false;
                            }
                        }
                    }
                };

            }],
            controllerAs: '$ctrl'
        });

    /**
     * @description component to handle OptionVar description input fields that having placeholder
     */
    angular.module("emsv2App")
        .component('validateOptionvarDescInput', {
            require: {
                ngModelCtrl: "ngModel"
            },
            bindings: {
                validationList: '<',
                ngModel: '=',
                isInvalid: '=',
                errorMgs: '=',
                isEditMode: '=',
                placeHolder: '='
            },
            template:
                '<input class="form-control" type="text" ng-model="$ctrl.ngModel" placeholder="{{$ctrl.placeHolder}}" ' +
                'ng-class="{\'has-error\': !$ctrl.isInvalid}" ng-readonly="!$ctrl.isEditMode" ng-change="$ctrl.changeName($ctrl.ngModel)" />' +
                '<span ng-show="$ctrl.isInvalid" >{{$ctrl.errorMgs}}</span>',
            controller: [function () {
                var ctrl = this;
                var inputValue = ctrl.ngModel;

                ctrl.$onInit = function () {
                    if (inputValue !== null && inputValue !== undefined) {
                        checkInput();
                    }
                };

                ctrl.changeName = function (object) {
                    inputValue = object;
                    if (inputValue !== null && inputValue !== undefined) {
                        checkInput();
                    }
                };

                var checkInput = function () {
                    if (ctrl.validationList.length > 0) {
                        for (var i = 0; i < ctrl.validationList.length; i++) {
                            if (inputValue.match(ctrl.validationList[i])) {
                                ctrl.isInvalid = true;
                                return;
                            }
                            else {
                                ctrl.isInvalid = false;
                            }
                        }
                    }
                };

            }],
            controllerAs: '$ctrl'
        });

})();
