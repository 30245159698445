(function () {
    'use strict';

    /**
     * @function NumberService
     * @description Service providing functions to work with numbers
     */
    angular.module('emsv2App').service("NumberService", function () {

        /**
         * @description Function to round value to specified number of decimal places
         * @param {Number} val the value to round
         * @param {Number} prec the number of decimal places
         * @returns {number} returns rounded value
         */
        var roundToPrecision = function (val, prec) {
            if (Math.abs(val) < 0.0001) return 0;
            if (prec === 0) return Math.round(val);
            if (prec === 1) return Math.round(val * 10) / 10;
            if (prec === 2) return Math.round(val * 100) / 100;
            if (prec === 3) return Math.round(val * 1000) / 1000;
            var _prec = Math.pow(10, prec);
            return Math.round(val * _prec) / _prec;
        };

        /**
         * @description Function to round provided vector components to specified decimal places
         * @param {THREE.Vector3} val the vector to round component values of
         * @param {Number} prec number of decimal places
         * @returns {*} returns null if val is not of type THREE.Vector3, otherwise rounded vector will be returned
         */
        var roundToPrecisionV3 = function (val, prec) {
            if (!(val instanceof THREE.Vector3)) return null;
            val.x = roundToPrecision(val.x, prec);
            val.y = roundToPrecision(val.y, prec);
            val.z = roundToPrecision(val.z, prec);
            return val;
        };

        /**
         * @description Function to next power of 2 value for provided value (250 -> 256, 257 -> 512)
         * @param {Number} value the number to find next bigger power of 2 value for
         * @returns {number} returns next bigger power of 2 value
         */
        var getNextPowerOfTwo = function (value) {
            return Math.pow(2, Math.ceil(Math.log(value) / Math.log(2)));
        };

        /**
         * @description Function to convert float value to Uint8Array of length 4
         * @param {Number} value the float value to convert
         * @returns {Uint8Array} returns converted Uint9Array for provided value
         */
        var convertFloatTo4Byte = function (value) {
            var f = new Float32Array(1);
            f[0] = value;
            return new Uint8Array(f.buffer);
        };

        /**
         * @description Function to convert float value to Uint8Array of length 3
         * @param {Number} value the float value to convert
         * @returns {Uint8Array} returns converted Uint9Array for provided value
         */
        var convertFloatTo3Byte = function (value) {
            var r, g, b;
            b = Math.floor(value / 256.0 / 256.0);
            g = Math.floor((value - b * 256.0 * 256.0) / 256.0);
            r = Math.floor(value - b * 256.0 * 256.0 - g * 256.0);
            return [r, g, b];
        };

        /**
         * @description Function to convert Uint8Array of length 4 to float value
         * @param {Uint8Array} byteArray the Uint8Array to convert
         * @returns {Number} returns converted float value for provided byteArray
         */
        var convert4ByteToFloat = function (byteArray) {
            var val = byteArray[0] * 1.0 + byteArray[1] * 1.0 / (255) + byteArray[2] * 1.0 / (255 * 255) + byteArray[3] * 1.0 / (255 * 255 * 255);
            return val;
        };

        return {
            roundToPrecision: roundToPrecision,
            roundToPrecisionV3: roundToPrecisionV3,
            getNextPowerOfTwo: getNextPowerOfTwo,
            convertFloatTo4Byte: convertFloatTo4Byte,
            convertFloatTo3Byte: convertFloatTo3Byte,
            convert4ByteToFloat: convert4ByteToFloat
        }
    });
})();
