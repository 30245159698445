(function () {
    'use strict';

    function stateConfiguration($stateProvider) {

        var translatePartialLoader = ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('reports');
            $translatePartialLoader.addPart('dashboard');
            $translatePartialLoader.addPart('rsqltable');
            return $translate.refresh();
        }];

        var loadReportData = ['$log', '$stateParams', 'Report', '$state', function ($log, $stateParams, Report, $state) {
            return Report.get($stateParams.id).then(function (response) {
                response.data.pages.sort(function (a, b) {
                    return (a.page > b.page ? 1 : (a.page < b.page ? -1 : 0));
                });
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t load report: ', error);
                $state.go("notfound");
            });
        }];

        var emptyReport = ['$q', function ($q) {
            return $q.when({
                id: null,
                title: null,
                creator: null,
                userId: null,
                groupId: null,
                formatOutput: 'pdf',
                formatSize: 'A4',
                formatPagination: false,
                formatOrientation: 0,
                pages: [],
                schedulers: []
            });
        }];

        var user = ['$log', 'Principal', function ($log, Principal) {
            return Principal.identity().then(function (response) {
                return response;
            }, function (error) {
                $log.error('Couldn\'t load current user: ', error);
            });
        }];

        var checkAdmin = ['Principal', 'currentUser', function (Principal, currentUser) {
            // Resolve currentUser first so it's loaded
            return Principal.isInRole('ROLE_ADMIN');
        }];

        // Check for admin status and load groups or directly return an empty array
        var loadGroups = ['$log', 'Group', 'isAdmin', function ($log, Group, isAdmin) {
            if (isAdmin) {
                Group.getAllFlat().then(function (response) {
                    return response.data;
                }, function (error) {
                    $log.error('Couldn\'t load groups: ', error);
                    return [];
                });
            }
            else {
                return [];
            }
        }];

        // Check if the scheduling of reports should be enabled
        var checkScheduler = ['$log', 'digiChart', function ($log, digiChart) {
            return digiChart.getSchedulerEnabled().then(function (response) {
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t get scheduler state: ', error);
                return false;
            });
        }];

        var loadReportFormats = ['$log', 'digiChart', function ($log, digiChart) {
            return digiChart.getReportFormats().then(function (response) {
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t load report formats: ', error);
                return [];
            });
        }];

        var loadPaperFormats = ['$log', 'digiChart', function ($log, digiChart) {
            return digiChart.getPaperFormats().then(function (response) {
                return response.data;
            }, function (error) {
                $log.error('Couldn\'t load paper formats: ', error);
                return [];
            });
        }];

        var loadReportCache = ['$log', 'Report', function ($log, Report) {
            return Report.getCache().then(function (response) {
                return response.data;
            }, function (error) {
                $log.error('Couln\'t load report cache!', error);
                return [];
            });
        }];

        var isLicensed = function (license, module) {
            if (license !== null
                && license !== undefined
                && license.data !== null
                && license.data !== undefined) {
                if (module.indexOf('*') !== -1) {
                    var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                    var keys = Object.keys(license.data);
                    for (var i in keys) {
                        if (regex.test(keys[i]) && keys[i]) return true;
                    }
                }
                else if (license.data[module] !== null) {
                    return license.data[module];
                }
            }
            return false;
        };

        var currentUser = ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
            return Principal.identity().then(function (user) {
                $log.debug('Loaded userInfo: ', user);
                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_REPORT')) {
                    License.get().then(function (response) {
                        var license = response.data;
                        if (isLicensed(license, 'report')) {
                            return user;
                        }
                        else {
                            $state.go("accessdenied");
                        }
                    }, function (error) {
                        $log.error('Couldn\'t load license!', error);
                    });
                }
                else {
                    $state.go("accessdenied");
                }
            }, function (error) {
                $state.go("notfound");
                $log.error('Couldn\'t load user: ', error);
            });
        }];

        $stateProvider
            .state('reports', {
                parent: 'site',
                url: '/reports',
                data: {
                    roles: ['ROLE_REPORT', 'ROLE_ADMIN'],
                    pageTitle: 'reports.pageTitle',
                    breadcrumbsTitle: 'reports.routetitle',
                    license: 'report'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/overview/overview.html',
                        controller: 'ReportOverviewController',
                        controllerAs: 'rctrl'
                    }
                },
                resolve: {
                    translatePartialLoader: translatePartialLoader,
                    canCreate: ['$q', '$log', 'Report', function ($q, $log, Report) {
                        var deferred = $q.defer();
                        Report.canCreate().then(function (response) {
                            deferred.resolve(response.data);
                        }, function (error) {
                            $log.error("Can't check report create level!", error);
                            deferred.reject(error);
                        });
                        return deferred.promise;
                    }],
                    user: user,
                    isAdmin: checkAdmin,
                    cache: loadReportCache,
                    currentUser: currentUser
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('reports.create', {
                parent: 'reports',
                url: '/create',
                data: {
                    pageTitle: 'reports.pageTitle',
                    breadcrumbsTitle: 'reports.generate.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/generate/generate.html',
                        controller: 'ReportsGenerateController',
                        controllerAs: 'rctrl'
                    }
                },
                resolve: {
                    user: user,
                    isAdmin: checkAdmin,
                    groups: loadGroups,
                    schedulerEnabled: checkScheduler,
                    reportFormats: loadReportFormats,
                    paperFormats: loadPaperFormats,
                    reportData: emptyReport,
                    license: ['$log', 'License', '$state', function ($log, License, $state) {
                        return License.getReportLimitReached().then(function (response) {
                            if (response.data) {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $log.error('Couldn\'t retrieve license!', error);
                        });
                    }]
                }
            })
            .state('reports.loaded', {
                parent: 'reports',
                url: '/:id',
                abstract: false,
                redirectTo: 'reports.detail',
                data: {
                    pageTitle: 'reports.pageTitle',
                    breadcrumbsTitle: '{{reportData.title}}'
                },
                resolve: {
                    reportData: loadReportData
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('reports.detail', {
                parent: 'reports.loaded',
                url: '/details',
                data: {
                    css: 'ci/css/report.css',
                    pageTitle: 'reports.pageTitle',
                    breadcrumbsTitle: 'reports.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/detail/detail.html',
                        controller: 'ReportDetailController',
                        controllerAs: 'rctrl'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('reports.edit', {
                parent: 'reports.loaded',
                url: '/edit',
                data: {
                    pageTitle: 'reports.pageTitle',
                    breadcrumbsTitle: 'reports.edit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/generate/generate.html',
                        controller: 'ReportsGenerateController',
                        controllerAs: 'rctrl'
                    }
                },
                resolve: {
                    user: user,
                    isAdmin: checkAdmin,
                    groups: loadGroups,
                    schedulerEnabled: checkScheduler,
                    reportFormats: loadReportFormats,
                    paperFormats: loadPaperFormats
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('reports.print', {
                parent: 'reports.loaded',
                url: '/print',
                data: {
                    pageTitle: 'reports.pageTitle',
                    breadcrumbsTitle: 'reports.print.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/reports/print/content.html',
                        controller: 'ReportPrintController',
                        controllerAs: 'rctrl'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            });
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
