(function () {

    'use strict';

    var runApp = function ($rootScope, $window, $log, $state, $stateParams, $translate, $timeout, $templateCache, $http,
                           Auth, Principal, Language, EMSConstants, $cookies, ThemeService, notify, Tools, License,
                           localStorageService, $document) {

        // Update the language
        Language.getCurrent().then(function (language) {
            $translate.use(language);
            _setHighchartsTranslation(language);
        });

        $rootScope.$on('$translateChangeStart', function (event, data) {
            _setHighchartsTranslation(data.language);
        });

        function _setHighchartsTranslation(lang) {
            if (lang === 'de') {
                Highcharts.setOptions({
                    lang: {
                        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                        contextButtonTitle: 'Diagramm Kontextmenü',
                        downloadJPEG: 'Download JPEG Grafik',
                        downloadPDF: 'Download PDF Dokument',
                        downloadPNG: 'Download PNG Grafik',
                        downloadSVG: 'Download SVG Vektorgrafik',
                        drillUpText: 'Zurück zur {series.name}',
                        noData: 'Keine Daten zum Anzeigen',
                        printChart: 'Diagramm drucken',
                        resetZoom: 'Reset Zoom',
                        resetZoomTitle: 'Reset Zoom Level 1:1',
                        shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
                    }
                });
            } else if (lang === 'en') {
                Highcharts.setOptions({
                    lang: {
                        months: ['January', 'February', 'March', 'April', 'May', 'June', 'Juli', 'August', 'September', 'October', 'November', 'December'],
                        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                        contextButtonTitle: 'Diagram Menu',
                        downloadJPEG: 'Download JPEG Image',
                        downloadPDF: 'Download PDF Document',
                        downloadPNG: 'Download PNG Image',
                        downloadSVG: 'Download SVG Vector Image',
                        drillUpText: 'back to {series.name}',
                        noData: 'No data available',
                        printChart: 'Print chart',
                        resetZoom: 'Reset Zoom',
                        resetZoomTitle: 'Reset Zoom Level 1:1',
                        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    }
                });
            }

        }

        function _preloadTemplate(path) {
            $http.get(path).then(function (response) {
                $templateCache.put(path, response.data);
            }, function (error) {
                $log.error("Could not preload template: " + path);
            });
        }

        // implementiert die `redirectTo` Anweisung für UI Router States (siehe main.js zB)
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
            $rootScope.previousState = $rootScope.toState;
            $rootScope.toState = toState;
            $rootScope.toStateParams = toParams;

            if ($rootScope.previousState === undefined) {
                ThemeService.reloadPage();
            }
            if (Tools.isDefinedNotNull(toState) &&
                Tools.isDefinedNotNull($rootScope.previousState) &&
                toState.name === $rootScope.previousState.name) {
                return;
            }

            //if the site has been reloaded identity gets deleted therefore we need to fetch it again.
            if (!Principal.isIdentityResolved() && !Tools.isStateWithoutAuthReq(toState.name)) {
                Auth.authorize().then(function () {
                    if ((!Principal.isAuthenticated())) {
                        event.preventDefault();
                        if (Tools.isStateWithoutAuthReq(toState.name)) {
                            $state.go(toState.name, toParams);
                        } else {
                            $state.go('login');
                        }
                    } else {
                        _handleState(toState, event, toParams);
                    }
                });
            } else if (Principal.isAuthenticated()) {
                _handleState(toState, event, toParams);

            } else if (Tools.isStateWithoutAuthReq(toState.name)) {
                event.preventDefault();
                $state.go(toState.name, toParams);
            } else {
                event.preventDefault();
                $state.go('login');
            }
        });

        function _handleState(toState, event, toParams) {
            if (Principal.showChangePasswordSite()) {
                Principal.showChangePasswordState();
            } else {
                if (toState.name === 'home' &&
                    Tools.isDefinedNotNull(localStorageService.get('token'))) {
                    event.preventDefault();
                    Principal.redirectToHomepage();
                }
                if (Tools.isStateWithoutAuthReq(toState.name)) {
                    event.preventDefault();
                    $state.go('home');
                }
                if (toState.name === 'location.rooms') {
                    event.preventDefault();
                    $state.go('location.rooms', toParams, {reload: true});
                }
                if (toState.redirectTo) {
                    if (typeof toState.redirectTo === 'object') {
                        event.preventDefault();
                        var mergedParams = angular.copy(toParams);
                        angular.merge(mergedParams, toState.redirectTo.params);
                        $state.go(toState.redirectTo.state, mergedParams);
                    } else {
                        event.preventDefault();
                        $state.go(toState.redirectTo, toParams);
                    }
                }
            }
        }

        $rootScope.$on('$stateChangeStart', function (event, toState) {
            if (toState.data.license && toState.data.license !== '' && Principal.isAuthenticated()) {
                License.get().then(function (response) {
                    var licensed = response.data.data[toState.data.license] || false;
                    if (!Tools.isDefinedNotNull(response.data.address) && !Tools.isDefinedNotNull(response.data.company)) {
                        $state.go('unlicensed');
                    } else if (!licensed) {
                        $state.go('license');
                    }
                });
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            var titleKey = 'global.title';
            if (angular.isDefined($rootScope.previousStateName)) $rootScope.pre_previousStateName = $rootScope.previousStateName;
            if (angular.isDefined($rootScope.previousStateParams)) $rootScope.pre_previousStateParams = $rootScope.previousStateParams;

            $rootScope.previousStateName = fromState.name;
            $rootScope.previousStateParams = fromParams;

            // Set the page title key to the one configured in state or use default one
            if (toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }
            $translate(titleKey).then(function (title) {
                // Change window title with translated one
                $window.document.title = title;
            });
        });

        $rootScope.back = function () {
            $window.history.back();
        };

        notify.config({
            position: 'right',
            templateUrl: 'scripts/components/notify/notify.html'
        });

        EMSConstants.init();


        $document.on('click',function(){
           window.userInteraction = true;
        });

        _preloadTemplate("scripts/app/account/login/login.html");
        _preloadTemplate("scripts/app/account/logout/logout.html");
    };

    var authInterceptor = function ($injector) {
        return {
            // Add authorization token to headers
            request: function (config) {
                config.headers = config.headers || {};
                if (config.url !== 'login' && config.url.contains("api/")) {
                    var localStorageService = $injector.get('localStorageService');
                    if (!angular.isDefined(localStorageService) && val !== localStorageService) return;
                    var jwtToken = localStorageService.get('token');
                    if (jwtToken !== undefined && jwtToken !== null && jwtToken !== "") {
                        config.headers.Authorization = jwtToken;
                        return config;
                    } else {
                        localStorageService.remove('token');
                    }
                }
                return config;
            }
        };
    };

    var authExpiredInterceptor = function ($q,localStorageService) {
        return {
            // On each response success
            response: function(response) {
                // Update the token in local storage if it has changed

                if(response.headers("authorization") !== null && response.headers("authorization") !== undefined && response.headers("authorization") !== localStorageService.get('token')){
                    localStorageService.set('token', response.headers("authorization"));
                }
                // Return the response or response data
                return response;
            },
            responseError: function (response) {
                // token has expired
                if (response.status === 401) {
                    return $q.reject(response);
                }
                return $q.reject("User logged out.");
            }
        };
    };

    var configureApp = function ($stateProvider, $urlRouterProvider, $httpProvider, $translateProvider,
                                 /*tmhDynamicLocaleProvider,*/ httpRequestInterceptorCacheBusterProvider, $logProvider) {

        $logProvider.debugEnabled(false); // TODO: set to false for production
        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');

        $stateProvider
            .state('site', {
                abstract: true,
                views: {
                    'sidebar': {
                        templateUrl: '/scripts/components/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: '$ctrl'
                    },
                    'content': {
                        templateUrl: '/scripts/app/main/main.html'
                    },
                    'navbar': {
                        templateUrl: '/scripts/components/navbar/navbar.html',
                        controller: 'NavbarController'
                    },
                    'footer': {
                        templateUrl: '/scripts/components/footer/footer.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', 'Principal', function ($translate, $translatePartialLoader, Principal) {
                        $translatePartialLoader.addPart('global');
                        if (!Principal.isAuthenticated()) {
                            $translatePartialLoader.addPart('login');
                        }
                        $translatePartialLoader.addPart('language');
                        return $translate.refresh();
                    }],
                    notNull: [function () {
                        return function (variable) {
                            return typeof variable !== 'undefined' && variable !== null;
                        }
                    }]
                }
            });
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        // $translateProvider.preferredLanguage('en');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escape'); // sanitize
    };

    var indexController = function ($scope, $state, $interval, $log, Principal, License, User, SmtpEmailServerConfigurationService) {
        $scope.licensed = true;
        $scope.isMailServerConfigured = false;
        $scope.isLicenseExpire = false;
        $scope.isFreeVersion = false;
        $scope.contactMail = '';
        $scope.daysExpired = 0;
        $scope.isAuthenticated = Principal.isAuthenticated;
        $scope.state = $state;
        $scope.nodevices = false;

        (function () {

            if (Principal.isAuthenticated()) {
                License.get().then(function (response) {
                    $scope.licensed = !response.data.expired;
                });

                User.deviceCount().then(function (result) {
                    Principal.identity().then(function () {
                        if (result.data === 0 && !Principal.isInRole('ROLE_ADMIN')) {
                            $scope.nodevices = true;
                        }
                    }, function (error) {
                        $log.debug('Couldn\'t load user: ', error);
                    });
                }, function (error) {
                    $log.debug('Couldn\'t load deviceCount!', error);
                });
            }

            License.getExpired().then(function (response) {
                $scope.isLicenseExpire = response.data;
            });

            License.getDaysExpired().then(function (response) {
                $scope.daysExpired = response.data;
                if(response.data){
                    $scope.absDaysExpired = Math.abs(response.data);
                    $scope.expiryDate = moment().add('days', response.data).format('DD.MM.YYYY');
                }
            });

            License.getContactMail().then(function (response) {
                $scope.contactMail = response.data;
            });

            License.getIsFreeVersion().then(function (response) {
                if (response.data === "freeVersion") {
                    $scope.isFreeVersion = true;
                } else {
                    $scope.isFreeVersion = false;
                }
            });

            SmtpEmailServerConfigurationService.get().then(function (response) {
                if (response.data !== null && response.data !== "") {
                    $scope.isMailServerConfigured = true;
                } else {
                    $scope.isMailServerConfigured = false;
                }
            });
        })();
    };

    angular.module('emsv2App', ['LocalStorageModule',
        'ngResource', 'ui.router', 'ngCookies', 'pascalprecht.translate',
        'ngCacheBuster', 'treeControl', 'daterangepicker',
        'highcharts-ng', 'ui.bootstrap', 'localytics.directives', 'ngFileUpload',
        'ngMessages', 'angular-img-cropper', 'ui-leaflet', 'ja.qr', 'ui.mask', 'focus-if',
        'ngclipboard', 'ngSanitize', 'angularSpectrumColorpicker', 'angularUtils.directives.ui-breadcrumbs', 'cgNotify',
        'moment-picker', 'uiRouterStyles', 'mwl.calendar', 'ui.bootstrap'
    ]);

    //angular.module('emsv2App').factory('checkForAdminUser', checkForAdminUser);
    angular.module('emsv2App').factory('authInterceptor', authInterceptor);
    angular.module('emsv2App').factory('authExpiredInterceptor', authExpiredInterceptor);
    angular.module('emsv2App').controller('IndexController', indexController);
    angular.module('emsv2App').config(configureApp);
    angular.module('emsv2App').run(runApp);

})();
