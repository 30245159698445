'use strict';

/**
 * @description Constructor for building object
 * @param {number} locationId the location id to set
 * @constructor
 */
var Building = function (locationId) {
    this.id = null;
    this.name = "";
    this.street = "";
    this.lon = null;
    this.lat = null;
    this.alt = null;
    this.fkLocation = locationId !== undefined ? locationId : null;
    this.edit = true;

    this.devices = [];
    this.rooms = [];
    this.roomsFull = [];

};
Building.prototype.constructor = Building;

/**
 * @description Function to clone this object
 * @returns {Building} returns cloned object
 */
Building.prototype.clone = function () {
    var _clone = new Building(this.fkLocation);
    _clone.id = this.id;
    _clone.name = this.name;
    _clone.street = this.street;
    _clone.lon = this.lon;
    _clone.lat = this.lat;
    _clone.alt = this.alt;
    _clone.edit = this.edit;
    return _clone;
};

/**
 * @description Function to test equality of this object and the provided object
 * @param {object} obj the object to compare with
 * @returns {boolean} returns true if this object equals the provided object
 */
Building.prototype.equals = function (obj) {
    if (!(obj instanceof Building)) return false;
    if (obj.id !== this.id || obj.name !== this.name || obj.street !== this.street || obj.lon !== this.lon || obj.lat !== this.lat || obj.alt !== this.lat || obj.fkLocation !== this.fkLocation) return false;
    return true;
};

/**
 * @description Function to check if geo location data equals the data in the provided object
 * @param {object} obj the object to compare geo location data with
 * @returns {boolean} returns true if geo location data equals the data of the provided object, otherwise false
 */
Building.prototype.geoDataEqual = function (obj) {
    if (!(obj instanceof Building)) return false;
    if (obj.lon !== this.lon || obj.lat !== this.lat || obj.alt !== this.alt) return false;
    return true;
};

/**
 * @description Function to check if geo location data is valid
 * @returns {boolean} returns true if geo data is valid, otherwise false
 */
Building.prototype.geoDataValid = function () {
    if (this.alt === null || this.lat === null || this.lon === null) return false;
    //TODO check if lat/lon are within valid value range
    return true;
};

/**
 * @description Function to create a building object from json data object provided by backend
 * @param {object} obj the json data object
 * @returns {Building} returns the created building object
 */
Building.parseFromHtmlObject = function (obj) {
    var building = new Building(obj.fkLocation);
    var i;
    building.id = obj.id;
    building.alt = obj.alt;
    building.lon = obj.lon;
    building.lat = obj.lat;
    building.alarmCount = obj.alarmCount;
    building.name = obj.name;
    building.street = obj.street;
    building.devices = [];
    building.rooms = [];

    for (i = 0; i < obj.devices.length; i++) {
        building.devices.push(BuildingDevice.parseFromHtmlObject(obj.devices[i]));
    }

    for (i = 0; i < obj.rooms.length; i++) {
        building.rooms.push(BuildingRoom.parseFromHtmlObject(obj.rooms[i]));
    }

    return building;
};
