(function () {

    angular.module('emsv2App').factory('Weather', function ($http) {

        return {
            'requestForLocation': function (locationId) {
                return $http.get('api/weather/location/' + locationId);
            },
            'pollRequestId': function (requestId) {
                return $http.get('api/weather/location/poll/' + requestId);
            },
            'requestForecast': function (name,lat, lon) {
                return $http.get('api/weather/forecast/'+ name +'/' + lat + '/' + lon);
            }
        };
    });
})();
