(function () {

    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('settings', {
                    parent: 'account',
                    url: '/account/settings',
                    runGuardsAndResolvers: 'always',
                    data: {
                        roles: ['ROLE_USER'],
                        pageTitle: 'global.menu.account.pageTitle',
                        breadcrumbsTitle: 'global.menu.account.settings'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/account/settings/settings.html',
                            controller: 'SettingsController',
                            controllerAs: 'sctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('settings');
                            $translatePartialLoader.addPart('language');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('location');
                            $translatePartialLoader.addPart('user');
                            return $translate.refresh();
                        }],
                        selectedLanguage: ['$log', 'Language', function ($log, Language) {
                            return Language.getCurrent().then(function (response) {
                                return response;
                            }, function (error) {
                                $log.error('Couln\'t load current language!', error);
                                return 'en';
                            });
                        }],
                        userInfo: ['$log', 'Principal', function ($log, Principal) {
                            return Principal.identity().then(function (account) {
                                return account;
                            }, function (error) {
                                $log.error('Couldn\'t load user!', error);
                            });
                        }],
                        rsqlQueries: ['$log', 'Filter', function ($log, Filter) {
                            return Filter.getSavedQueries('location').then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error('Couldn\'t load saved queries!', error);
                            });
                        }]

                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });

})();
