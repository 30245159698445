(function () {
    'use strict';
    angular.module('emsv2App').service('EscalationLevelService', function ($http, $rootScope) {
        return {
            'getEscalationLevels': function (lid) {
                return $http.get('api/locations/escalation/getByLocation/' + lid).then(function (response) {
                    return response.data;
                });
            },
            'getEscalationLevelById': function (id) {
                return $http.get("api/locations/escalation/get/" + id).then(function (response) {
                    return response.data;
                });
            },
            'saveEscalation': function (obj) {
                return $http.post('api/locations/escalation/save', obj);
            },
            'deleteEscalationLevel': function (escalationLevelId) {
                return $http.delete('api/locations/escalation/delete/' + escalationLevelId);
            }
        };
    });
})();
