(function () {

    'use strict';

    function widgetModalController($scope, $rootScope, WidgetData) {
        var ctrl = this;

        ctrl.availableWidgets = [];

        ctrl.closeModal = function () {
            $rootScope.$broadcast('closeModal');
        };
        ctrl.addWidget = function (width, height, type, title, reSize, minWidth, minHeight, maxWidth, maxHeight) {
            $rootScope.$broadcast('newWidget', {
                width: width,
                height: height,
                type: type,
                title: title,
                reSize: reSize,
                minWidth:minWidth,
                minHeight:minHeight,
                maxWidth:maxWidth,
                maxHeight:maxHeight
            });
        };

      /*  var htmlTemplate = document.getElementById("my canvas");
        var blob = new Blob([htmlTemplate.outerHTML], {
            type: 'text/html'
        });
        var reader = new FileReader();

        reader.readAsImage(blob);*/

        /*var canvas = document.getElementById("mycanvas").getContext("2d");
        var context =  canvas.;
        var img    = document.getElementById("mycanvas");
        context.drawImage(img,10,10);*/

        /*document.getElementById('dynamicBtn').open();
        document.write('<img src="'+img+'"/>');
        document.getElementById('dynamicBtn').close();*/
        ctrl.$onInit = function () {
            WidgetData.getAvailableWidgets().then(function (response) {
                if (response !== null) {
                    ctrl.availableWidgets = [];
                    for (var i = 0; i < response.length; i++){
                        if (response[i].hasLicense === true) {
                            ctrl.availableWidgets.push(response[i]);
                        }
                    }
                }
            });
        };
    }

    angular.module('emsv2App')
        .controller('WidgetModalController', widgetModalController);
})();
