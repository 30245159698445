(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name RoomEditorService
     * @description Helper service for room editor controller
     */


    angular.module('emsv2App')
        .service("RoomEditorService", function ($state, $location, $translate, $document, EMSConstants, MathService
                , Object3DFactory, WebGLService, TrackingDivLabelFactory
                , LiveDataService, HeatMapGLService, GenDialogService, NumberService
                , Tools, $log) {

                var SPACE_TO_AVOID_COLLISION = 0.002;

                /**
                 * @ngdoc function
                 * @description Function to redraw walls as simple lines for outer/inner walls
                 * @param {Object} room room object to redraw walls for
                 * @param {boolean=} innerWalls if true redraw innerwalls, else redraw outer walls
                 */
                var redrawWalls = function (room, innerWalls) {
                    var w, w0, wa;
                    if (!innerWalls) {
                        room.outerWalls = normalizeWallPoints(room.outerWalls);
                        if (room.outerWalls.length === 0) return;
                        WebGLService.cleanDummyLineHover();
                        for (w = 0; w < room.outerWalls.length; w++) {
                            w0 = room.outerWalls[w];
                            wa = Object3DFactory.buildWall(w0.start, w0.end, w0.height, w0.thickness, "flat", true);
                            addFlatWallToScene(wa);
                        }
                    } else {
                        WebGLService.cleanDummyLineHover();
                        for (w = 0; w < room.innerWalls.length; w++) {
                            w0 = room.innerWalls[w];
                            wa = Object3DFactory.buildWall(w0.start, w0.end, w0.height, w0.thickness, "flat", true);
                            if (room.innerWalls[w].type) wa[1].material.color.setHex(Object3DFactory.colors.flatWall.outlineCage);
                            addFlatWallToScene(wa);
                        }
                    }
                };

                /**
                 * @description function to find index of room object defined by provided object
                 * @param {object} room is the room where to search in
                 * @param {object} obj the object to find index for (id property needed)
                 * @returns {number} returns the index of the found object, if nothing is found -1 will be returned
                 */
                var getIndexOfRoomObj = function (room, obj) {
                    for (var i = 0; i < room.roomObjs.length; i++) {
                        if (room.roomObjs[i].id == obj.userData.id) return i;
                    }
                    return -1;
                };

                var _removeRoomObject = function (array, room, room3dObject) {
                    for (var index = 0; index < array.length; index++) {
                        var object3d = WebGLService.findObjectByUniqueId(array[index].uniqueId, room3dObject);
                        var idx = getIndexOfRoomObj(room, object3d);
                        if (Tools.isDefinedNotNull(idx)) {
                            room.roomObjs.splice(idx, 1);
                            WebGLService.remove3DObjectFromContent(object3d);
                            WebGLService.remove3DObjectFromScene(object3d);
                            object3d.parent.remove(object3d);
                        }
                    }
                };

                /**
                 * @description function to find 3d outerwall defined by provided start and end point
                 * @param {object} room3DObject is the 3D room
                 * @param {THREE.Vector3} s the start point of the wall
                 * @param {THREE.Vector3} e the end point of the wall
                 * @returns {number} returns the 3d outer wall or null
                 */
                var get3DOuterWall = function (room3DObject, s, e) {
                    if (room3DObject !== undefined && room3DObject instanceof THREE.Object3D) {
                        for (var index = 0; index < room3DObject.children.length; index++) {
                            var object = room3DObject.children[index];
                            if (object.userData
                                && object.userData.hasOwnProperty("s")
                                && object.userData.hasOwnProperty("e")
                                && object.userData.s.equals(s)
                                && object.userData.e.equals(e)) {
                                return object;
                            }
                        }
                    }
                    return null;
                };

                function _rebuildRoomObjectWindows(windowsOnWall, room, room3dObjectCopy, room3dObject, wall) {
                    for (var i = 0; i < windowsOnWall.length; i++) {
                        var object3d = WebGLService.findObjectByUniqueId(windowsOnWall[i].uniqueId, room3dObjectCopy);
                        var wall3d = get3DOuterWall(room3dObjectCopy, wall.start, wall.end);
                        object3d.userData.obb.c.z = wall3d.userData.obb.c.z;
                        var rotation = wall3d.rotation.y * -1;
                        var w = new Window(windowsOnWall[i].id, object3d.userData.obb.c, {
                            x: windowsOnWall[i].size.x,
                            y: windowsOnWall[i].size.y,
                            z: 0.01
                        }, {
                            x: 0,
                            y: rotation,
                            z: 0
                        }, "", "", 1000, windowsOnWall[i].uniqueId, room.id, true);
                        room.roomObjs.push(w);
                        var window = Object3DFactory.buildRoomObject(w, 'full');
                        room3dObject.add(window);
                    }
                }

                var rebuildObjectsOnWall = function (room, room3dObject) {
                    if (Tools.isDefinedNotNull(room)
                        && Tools.isDefinedNotNull(room.outerWalls)
                        && room.outerWalls.length > 2) {
                        for (var index = 0; index < room.outerWalls.length; index++) {
                            var wall = room.outerWalls[index];
                            // Checks all windows for the wall. Doors are working
                            var filteredList = room.getWindowsForWall(wall).filter(function (window) {
                                return !window.alignedToWall;
                            });
                            if (filteredList.length > 0) {
                                var object3DCopy = angular.copy(room3dObject);
                                _removeRoomObject(filteredList, room, room3dObject);
                                _rebuildRoomObjectWindows(filteredList, room, object3DCopy, room3dObject, wall);
                            } else {
                                // Do nothin'. There are no objects on the wall
                            }
                        }
                    }
                };

                /**
                 * @description Adds given dummy wall objects to respective scene sub-objects
                 * @param {array} wa array with dummy, line and hover objects (THREE.Mesh/THREE.Line)
                 */
                var addFlatWallToScene = function (wa) {
                    if (wa instanceof Array && wa.length === 3) {
                        WebGLService.add3DObjectToDummyObj(wa[0]);
                        WebGLService.add3DObjectToLineObj(wa[1]);
                        WebGLService.add3DObjectToHoverObj(wa[2]);
                    } else {
                        $log.debug("Error adding flat wall objects to scene", wa);
                    }
                };

                /**
                 * @description function to normalize/reorder the wall array to allow the transformation to a point list without problems
                 * @param {Array} walls array of walls to run the normalisation on
                 * @returns {*} returns the changed wall array
                 */
                var normalizeWallPoints = function (walls) {
                    var ws = walls.slice(0);
                    for (var i = 0; i < ws.length; i++) {
                        if (ws[i].visited) continue;
                        ws[i].visited = true;
                        var cws = findConnectedWall(ws[i], ws);
                        for (var j in cws) {
                            if (ws[i].start.equals(cws[j].start) || ws[i].end.equals(cws[j].end) && !ws[i].hit) {
                                var t = ws[i].start.clone();
                                ws[i].start.copy(ws[i].end);
                                ws[i].end.copy(t);
                                ws[i].hit = true;
                                i = 0;
                                break;
                            }
                        }
                    }
                    return ws;
                };

                var normalizeInnerWallPoints = function (walls) {
                    var ws = walls.slice(0);
                    for (var i = 0; i < ws.length; i++) {
                        if (ws[i].visited) continue;
                        ws[i].visited = true;
                        var cws = findConnectedInnerWall(ws[i], ws);
                        for (var j in cws) {
                            if (ws[i].start.equals(cws[j].start) || ws[i].end.equals(cws[j].end) && !ws[i].hit) {
                                var t = ws[i].start.clone();
                                ws[i].start.copy(ws[i].end);
                                ws[i].end.copy(t);
                                ws[i].hit = true;
                                i = 0;
                                break;
                            }
                        }
                    }
                    return ws;
                };

                /**
                 * @ngdoc function
                 * @description Function to find connected walls for provided wall
                 * @param wall {Object} wall object to find connected walls for
                 * @param walls {Array} wall array to find connected walls in
                 * @returns {Array}
                 */
                var findConnectedWall = function (wall, walls) {
                    var ret = [];
                    for (var i = 0; i < walls.length; i++) {
                        if (wallEquals(wall, walls[i])) continue;
                        if (wall.start.equals(walls[i].start)) ret.push(walls[i]);
                        if (wall.end.equals(walls[i].end)) ret.push(walls[i]);
                    }
                    return ret;
                };

                /**
                 * @ngdoc function
                 * @description Function to find connected inner walls
                 * @param wall {Object} wall object to find connected inner walls for
                 * @param walls {Array} inner wall array to find connected inner walls in
                 * @returns {Array}
                 */
                var findConnectedInnerWall = function (wall, walls) {
                    var ret = [];
                    for (var i = 0; i < walls.length; i++) {
                        if (wallEquals(wall, walls[i])) continue;
                        if (wall.s.equals(walls[i].s)) ret.push(walls[i]);
                        if (wall.e.equals(walls[i].e)) ret.push(walls[i]);
                    }
                    return ret;
                };

                /**
                 * @ngdoc function
                 * @description Function to compare 2 wall objects ()
                 * @param w0 {Object} wall object 1
                 * @param w1 {Object} wall object 2
                 * @returns {Boolean} true if walls equal each other, otherwise false
                 */
                var wallEquals = function (w0, w1) {
                    return (w0.start.equals(w1.start) && w0.end.equals(w1.end)) || (w0.start.equals(w1.end) && w0.end.equals(w1.start));
                };

                /**
                 * @description Function to build a dummy floor (simple color etc) and add it to the dummy sub object of the current scene
                 * @param {Room} room the room object to build the dummy floor for
                 */
                var buildDummyFloor = function (room) {
                    var dFloor = Object3DFactory.buildFloor(room, "simple");
                    dFloor.material.color.setHex(Object3DFactory.colors.floor.dummy);
                    dFloor.position.y -= 0.05;
                    WebGLService.add3DObjectToDummyObj(dFloor);
                };

                /**
                 * @description Function to build dummy objects for the room editor (innerwall and pillar dummys)
                 * @param {Room} room the room object containing information for pillars/inner walls
                 * @param {boolean} innerwall if set to true dummy objects for inner walls will be generated
                 * @param {boolean} pillar if set to true dummy objects for pillars will be generated
                 * @param {THREE.Object3D} obj the container object to add the generated dummy objects to
                 */
                var buildDummyCurrentObjs = function (room, innerwall, pillar, obj) {
                    var geo = new THREE.BoxBufferGeometry(1, 1, 1);
                    var mat = new THREE.MeshBasicMaterial({
                        color: 0xffa500,
                        transparent: true,
                        opacity: 0.6
                    });
                    var mesh;

                    if (innerwall) {
                        var stdDir = new THREE.Vector3(1, 0, 0);
                        for (var i = 0; i < room.innerWalls.length; i++) {
                            mesh = new THREE.Mesh(geo, mat);
                            var _s = room.innerWalls[i].start instanceof THREE.Vector3 ? room.innerWalls[i].start.clone() : new THREE.Vector3(room.innerWalls[i].start.x, room.innerWalls[i].start.y, room.innerWalls[i].start.z);
                            var _e = room.innerWalls[i].end instanceof THREE.Vector3 ? room.innerWalls[i].end.clone() : new THREE.Vector3(room.innerWalls[i].end.x, room.innerWalls[i].end.y, room.innerWalls[i].end.z);
                            var dir = _e.clone().sub(_s);
                            var len = dir.length();

                            var a = dir.clone().normalize().angleTo(stdDir);
                            if (dir.z > 0) a *= -1;
                            mesh.name = "dInnerWall";
                            mesh.scale.set(len, room.innerWalls[i].height, room.innerWalls[i].thickness);
                            mesh.position.copy(_s.clone().add(dir.clone().multiplyScalar(0.5)));
                            mesh.position.y = room.innerWalls[i].height / 2;
                            if (room.innerWalls[i].posType === 3) {
                                if (room.hasRaisedFloor) mesh.position.y += room.getRaisedFloor().size.y;
                            }
                            mesh.rotation.y = a;
                            obj.add(mesh);
                        }
                    }
                    if (pillar) {
                        var pillars = room.roomObjs.filter(function (el) {
                            return Pillar.isPillar(el.type);
                        });
                        if (pillars.length) {
                            for (var j = 0; j < pillars.length; j++) {
                                mesh = new THREE.Mesh(geo, mat);
                                mesh.scale.set(pillars[j].size.x, pillars[j].size.y, pillars[j].size.z);
                                mesh.rotation.y = MathService.degToRad(pillars[j].rot.y * -1);
                                mesh.position.set(pillars[j].pos.x, pillars[j].pos.y, pillars[j].pos.z);
                                mesh.name = "dPillar";
                                obj.add(mesh);
                            }
                        }
                    }
                };

                /**
                 * @description Function to remove dummy objects from provided container object
                 * @param {THREE.Object3D} obj the object to remove dummy objects from
                 * @param {boolean} innerwall if set to true - innerwall dummy objects will be removed
                 * @param {boolean} pillar if set to true - pillar dummy objects will be removed
                 */
                var removeDummyCurrentObjs = function (obj, innerwall, pillar) {
                    if (!obj) return;
                    var objs = [];
                    obj.traverse(function (o) {
                        if (innerwall && o.name === "dInnerWall") objs.push(o);
                        if (pillar && o.name === "dPillar") objs.push(o);
                    });
                    for (var i = 0; i < objs.length; i++) {
                        obj.remove(objs[i]);
                    }
                };

                /**
                 * @description Function to check if walls in array do intersect
                 * @param {Array} walls array of walls to test for intersections
                 * @returns {boolean} returns true if any wall intersects another one, otherwise false
                 */
                var checkWallIntersection = function (walls) {
                    for (var i = 0; i < walls.length; i++) {
                        for (var j = 0; j < walls.length; j++) {
                            if (i === j) continue;
                            var result = MathService.intersectLineLine({x: walls[i].s.x, y: walls[i].s.z}, {
                                x: walls[i].e.x,
                                y: walls[i].e.z
                            }, {x: walls[j].s.x, y: walls[j].s.z}, {x: walls[j].e.x, y: walls[j].e.z});
                            if (
                                (MathService.compareFloats(result.x, walls[i].s.x) && MathService.compareFloats(result.y, walls[i].s.z))
                                ||
                                (MathService.compareFloats(result.x, walls[i].e.x) && MathService.compareFloats(result.y, walls[i].e.z))
                            ) {
                                continue;
                            }
                            if (result) return true;
                        }
                    }
                    return false;
                };

                /**
                 * @description Function to handle merge of 2 walls/removal of a corner points
                 * @param {Room} room the room object containing the walls to modify
                 * @param {Array} connectedWalls array of
                 * @param {boolean} innerWalls - true run on inner walls, false run on outer walls
                 */
                var mergeWall = function (room, connectedWalls, innerWalls) {
                    var target = innerWalls ? 'innerWalls' : 'outerWalls';
                    var w0 = connectedWalls[0] !== null ? room[target][connectedWalls[0]] : null;
                    var w1 = connectedWalls[1] !== null ? room[target][connectedWalls[1]] : null;
                    var nw = null;
                    if (connectedWalls[0] !== null && connectedWalls[1] !== null) {
                        if (!innerWalls) {
                            if (w0.end.equals(w1.start)) nw = new OuterWall(Entity.getNewIdFromArray(room.outerWalls), w0.start.x, 0, w0.start.z, w1.end.x, 0, w1.end.z);
                            if (w0.end.equals(w1.end)) nw = new OuterWall(Entity.getNewIdFromArray(room.outerWalls), w0.start.x, 0, w0.start.z, w1.start.x, 0, w1.start.z);
                            if (w0.start.equals(w1.start)) nw = new OuterWall(Entity.getNewIdFromArray(room.outerWalls), w0.end.x, 0, w0.end.z, w1.start.x, 0, w1.start.z);
                        } else {
                            if (w0.end.equals(w1.start)) nw = new InnerWall(Entity.getNewIdFromArray(room.innerWalls), '', '', '', '', w0.type, w0.posType, w0.thickness, w0.height, w0.start.x, w0.start.y, w0.start.z, w1.end.x, w1.end.y, w1.end.z, Entity.getNewLocaleUniqueId());
                            if (w0.end.equals(w1.end)) nw = new InnerWall(Entity.getNewIdFromArray(room.innerWalls), '', '', '', '', w0.type, w0.posType, w0.thickness, w0.height, w0.start.x, w0.start.y, w0.start.z, w1.start.x, w1.start.y, w1.start.z, Entity.getNewLocaleUniqueId());
                            if (w0.start.equals(w1.start)) nw = new InnerWall(Entity.getNewIdFromArray(room.innerWalls), '', '', '', '', w0.type, w0.posType, w0.thickness, w0.height, w0.end.x, w0.end.y, w0.end.z, w1.end.x, w1.end.y, w1.end.z, Entity.getNewLocaleUniqueId());
                        }
                        if (connectedWalls[0] > connectedWalls[1]) {
                            room[target].splice(connectedWalls[0], 1);
                            room[target].splice(connectedWalls[1], 1);
                        }
                        if (connectedWalls[0] < connectedWalls[1]) {
                            room[target].splice(connectedWalls[1], 1);
                            room[target].splice(connectedWalls[0], 1);
                        }
                        room[target].push(nw);
                    } else if (connectedWalls[0] !== null) {
                        room[target].splice(connectedWalls[0], 1);
                    } else if (connectedWalls[1] !== null) {
                        room[target].splice(connectedWalls[1], 1);
                    }
                };

                /**
                 * @description Function to handle the split operation on a outer wall resulting in 2 new walls
                 * @param {Room} room the room object containing the soon to be divided wall
                 * @param {object} wall object describing start and end points for the wall
                 */
                var splitWall = function (room, wall) {
                    var w = findWallByPoints(room.outerWalls, wall.start, wall.end);
                    room.outerWalls.splice(w[1], 1);
                    var middle = wall.start.clone().add(wall.end.clone().sub(wall.start).multiplyScalar(0.5));
                    middle = NumberService.roundToPrecisionV3(middle, 3);
                    room.outerWalls.splice(
                        w[1],
                        0,
                        new OuterWall(Entity.getNewIdFromArray(room.outerWalls), wall.start.x, 0, wall.start.z, middle.x, 0, middle.z, room.thickness, room.size.y),
                        new OuterWall(Entity.getNewIdFromArray(room.outerWalls), middle.x, 0, middle.z, wall.end.x, 0, wall.end.z, room.thickness, room.size.y)
                    );
                };

                /**
                 * @description Function to handle the split operation on a inner wall resulting in 2 new inner walls
                 * @param {Room} room the room object containing the soon to be divided wall
                 * @param {object} wall object describing start and end points for the wall
                 */
                var splitInnerWall = function (room, wall) {
                    var w = findWallByPoints(room.innerWalls, wall.start, wall.end);
                    var oldWall = room.innerWalls.splice(w[1], 1);
                    if (oldWall.length) oldWall = oldWall[0];
                    var middle = wall.start.clone().add(wall.end.clone().sub(wall.start).multiplyScalar(0.5));

                    room.innerWalls.splice(w[1],
                        0,
                        new InnerWall(Entity.getNewIdFromArray(room.innerWalls), '', '', '', '', oldWall.type, oldWall.posType, oldWall.thickness, oldWall.height, oldWall.start.x, oldWall.start.y, oldWall.start.z, middle.x, oldWall.start.y, middle.z, Entity.getNewLocaleUniqueId()),
                        new InnerWall(Entity.getNewIdFromArray(room.innerWalls) - 1, '', '', '', '', oldWall.type, oldWall.posType, oldWall.thickness, oldWall.height, middle.x, oldWall.end.y, middle.z, oldWall.end.x, oldWall.end.y, oldWall.end.z, Entity.getNewLocaleUniqueId())
                    );
                };

                /**
                 * @description Function to find a specific wall by start and end point
                 * @param {Array} walls array of walls to search in
                 * @param {object} s start of the wall to be found
                 * @param {object} e end of the wall to be found
                 * @returns {*} returns 'null' if no wall is found, otherwise returns an array of length 2, first element is the wall, second element is the index the wall was found at
                 */
                var findWallByPoints = function (walls, s, e) {
                    for (var w = 0; w < walls.length; w++) {
                        var tw = walls[w];
                        if ((tw.start.equals(s) && tw.end.equals(e)) || (tw.start.equals(e) && tw.end.equals(s))) return [tw, w];
                    }
                    return null;
                };

                /**
                 * @description Function to get the translate limit types
                 * @returns {Array} array of limit types with translated name property
                 */
                var getStdLimits = function () {
                    var ret = [];
                    for (var i in EMSConstants.constants.LimitTypeStd) {
                        ret.push(EMSConstants.constants.LimitTypeStd[i]);
                        ret[ret.length - 1].name = $translate.instant(ret[ret.length - 1].name);
                    }
                    return ret;
                };

                /**
                 * @description Function to get possible air flow directions with translated name properties
                 * @returns a array containing information about the possible air flow directions
                 */
                var getAirFlowDirections = function () {
                    return [
                        {id: 0, name: $translate.instant('room.edit.airflow.none'), type: 0},
                        {id: 1, name: $translate.instant('room.edit.airflow.down'), type: 2},
                        {id: 2, name: $translate.instant('room.edit.airflow.up'), type: 2},
                        {id: 3, name: $translate.instant('room.edit.airflow.left'), type: 3},
                        {id: 4, name: $translate.instant('room.edit.airflow.right'), type: 3},
                        {id: 5, name: $translate.instant('room.edit.airflow.both'), type: 3}
                    ];
                };

                /**
                 * @description Function to retrieve airflow direction id from translate string
                 * @param {string} info the translate string
                 * @returns {number} returns the id for the respective air flow direction
                 */
                var getAirFlowDirectionFromPartInfo = function (info) {
                    if (info.indexOf("down") !== -1) return 1;
                    if (info.indexOf("up") !== -1) return 2;
                    if (info.indexOf("left") !== -1) return 3;
                    if (info.indexOf("right") !== -1) return 4;
                    if (info.indexOf("both") !== -1) return 5;
                    return 0;
                };

                /**
                 * @description Function to find the mesh for a slot in 2d rack views 2nd object (back view)
                 * @param {Slot} slot the slot object to find the mesh for
                 * @returns {*} if a mesh is found this mesh will be returned otherwise null
                 */
                var findBackObjectForSlot = function (slot) {
                    var ret = null;
                    var obj = WebGLService.findObjectByName("backview", WebGLService.getSceneObject());
                    if (obj) {
                        obj.traverse(function (o) {
                            if (o.name === "slot" && o.userData.id === slot.id) {
                                ret = o;
                            }
                        });
                    }
                    return ret;
                };

                /**
                 * @description Function to find a vector to align an object (defined by its points) with the given plane
                 * @param {THREE.Plane} plane the plane to align with
                 * @param {Array} points the points describing the object (THREE.Vector3)
                 * @param {boolean} back true to align with the back of the plane otherwise aligns with the front (normal)
                 * @returns {*} returns vector to align object
                 */
                var findMoveVector = function (plane, points, back) {
                    var pds = [];
                    for (var i = 0; i < points.length; i++) {
                        pds.push(plane.distanceToPoint(points[i]));
                    }
                    var min = Math.min.apply(null, pds);
                    var max = Math.max.apply(null, pds);
                    var fac = 0;
                    if (min < 0 && max < 0) {
                        fac = back ? min : max;
                    }
                    if (min > 0 && max > 0) {
                        fac = back ? min : max;
                    }
                    if (min < 0 && max > 0) {
                        fac = back ? findSmallestNegValue(pds) : findBiggestPosValue(pds);
                    }
                    return plane.normal.clone().normalize().multiplyScalar(fac * -1);
                };

                /**
                 * @description Function to find biggest positive value in a given array
                 * @param {Array} arr the array to search in
                 * @returns {number} returns the biggest positive value in the array
                 */
                var findBiggestPosValue = function (arr) {
                    var ret = 0;
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i] > ret) {
                            ret = arr[i];
                        }
                    }
                    return ret;
                };

                /**
                 * @description Function to the smallest negative value in a given array
                 * @param {Array} arr the array to search in
                 * @returns {number} return the smallest negative value
                 */
                var findSmallestNegValue = function (arr) {
                    var ret = 0;
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i] < ret) {
                            ret = arr[i];
                        }
                    }
                    return ret;
                };

                //region aligner

                var selectedObjects = [];

                /**
                 * @description Function to retrieve the count of the currently selected objects
                 * @returns {number} returns the number of selected objects (multi select in browser)
                 */
                var getSelectedObjectsLength = function () {
                    return selectedObjects.length;
                };

                /**
                 * @description Function to retrieve the currently selected objects
                 * @returns {Array} returns the selected objects (multi select in browser)
                 */
                var getSelectedObjects = function () {
                    return selectedObjects;
                };

                /**
                 * @description Function to clear list of selected objects
                 */
                var clearSelectedObjects = function () {
                    selectedObjects = [];
                };

                /**
                 * @description Function to help with marking of multi selected objects
                 */
                var markingHelper = function () {
                    for (var i = 0; i < selectedObjects.length; i++) {
                        WebGLService.unmarkObjectMulti(selectedObjects[i]);
                        i === 0 ? WebGLService.markObjectMulti(selectedObjects[i], 'first') : WebGLService.markObjectMulti(selectedObjects[i]);
                    }
                };

                /**
                 * @description Function to a object to the list of selected objects
                 * @param {THREE.Object3D} obj the object to add to selection
                 * @param {boolean} ignoreStep if set to true the action of adding the object to the selection will be ignore for undo/redo functionality
                 */
                var addObjectToSelection = function (obj, ignoreStep) {
                    WebGLService.unmarkObject();
                    if (obj.name === "containment_plane") {
                        return;
                    }
                    selectedObjects.push(obj);
                    if (selectedObjects.length === 1) {
                        WebGLService.markObjectMulti(obj, 'first', true);
                    } else {
                        WebGLService.markObjectMulti(obj, undefined, true);
                    }
                    if (!ignoreStep) addStep('add', obj);
                };

                /**
                 * @description Function to remove an object from the list of selected objects
                 * @param {THREE.Object3D} obj the object to remove from selection
                 * @param {boolean} ignoreStep if set to true the action will be ignored for undo/redo functionality
                 * @returns {boolean} returns true if object was found in and removed from selection
                 */
                var removeObjectFromSelection = function (obj, ignoreStep) {
                    var idx = selectedObjects.indexOf(obj);
                    if (idx !== -1) {
                        selectedObjects.splice(idx, 1);
                        WebGLService.unmarkObjectMulti(obj);
                        if (idx === 0 && selectedObjects.length > 0) {
                            WebGLService.unmarkObjectMulti(selectedObjects[0]);
                            WebGLService.markObjectMulti(selectedObjects[0], 'first', true);
                        }
                        if (!ignoreStep) addStep('remove', obj);
                        return true;
                    }
                    return false;
                };

                /**
                 * @description Function to check if the provided item is already selected
                 * @param {THREE.Object3D} item the object to search for
                 * @returns {boolean} returns true if item is in selection otherwise false
                 */
                var checkItemAlreadySelected = function (item) {
                    for (var i in selectedObjects) {
                        if (selectedObjects[i].userData.uid === item.userData.uid) return true;
                    }
                    return false;
                };

                //array to hold information to undo/redo alignment steps
                var steps = [];
                //index of current step
                var currentStep = -1;

                /**
                 * @description Function to clear undo/redo information
                 */
                var clearSteps = function () {
                    steps = [];
                    currentStep = -1;
                };

                /**
                 * @description Function to get number of undo/redo steps
                 * @returns {number} the number of undo/redo steps
                 */
                var getStepLength = function () {
                    return steps.length;
                };

                /**
                 * @description Function to get current step index
                 * @returns {number} the index of the current step in the array
                 */
                var getCurrentStep = function () {
                    return currentStep;
                };

                /**
                 * @description Function to add a action to the list of performed alignment actions
                 * @param {string} type the type of the action (add item, remove item, align items, close gaps between items)
                 * @param {Array} objects
                 */
                    //TODO finish doc
                var addStep = function (type, objects) {
                        if (!(objects instanceof Array)) objects = [objects];
                        var infoObj = {type: type, info: []};
                        if (type === 'add' || type === 'remove') {
                            infoObj.info[0] = objects[0];
                        }
                        if (type === 'align' || type === 'close') {
                            infoObj = objects[0];
                        }
                        if (currentStep === steps.length - 1) {
                            steps.push(infoObj);
                        } else {
                            steps.splice(currentStep + 1);
                            steps.push(infoObj);
                        }
                        currentStep++;
                    };

                /**
                 * @description Function to build/update the object describing a multi selection action
                 * @param {string} type the string describing the action
                 * @param {Array} objects list of affected objects
                 * @param {object=} infoObj object to update
                 * @returns {*} returns the created/updated info object
                 */
                var buildInfoObj = function (type, objects, infoObj) {
                    var create = false;

                    if (!infoObj) {
                        infoObj = {type: type, info: {}};
                        create = true;
                    }

                    for (var i = 0; i < objects.length; i++) {
                        if (create) {
                            infoObj.info[objects[i].userData.uid] = {oldPos: objects[i].position.clone()};
                        } else {
                            infoObj.info[objects[i].userData.uid].newPos = objects[i].position.clone();
                        }
                    }
                    return infoObj;
                };

                /**
                 * @description Function to undo a align or close gap action
                 * @param {object} step object describing the action to undo
                 * @param {Room} room the room object containing the objects affected by the action to be undone
                 */
                var undoAlignCloseStep = function (step, room) {
                    helperDo(step, room, 'oldPos');
                };

                /**
                 * @description Function to redo a align or close gap action
                 * @param {object} step object describing the action to redo
                 * @param {Room} room the room object containing the objects affected by the action to be redone
                 */
                var redoAlignCloseStep = function (step, room) {
                    helperDo(step, room, 'newPos');
                };

                /**
                 * @description Function to help undo/redo a action
                 * @param {object} step the object containing the information about the action to be re/un -done
                 * @param {Room} room the room object containing the objects affected by the action
                 * @param {string} field string describing the field from which to pull information from
                 */
                var helperDo = function (step, room, field) {
                    for (var i in step.info) {
                        if (!step.info.hasOwnProperty(i)) continue;
                        var o3 = WebGLService.findObjectByUniqueId(i, WebGLService.getSceneObject());
                        var oe = room.findObjectByUniqueID(i);

                        oe.pos.x = step.info[i][field].x;
                        oe.pos.y = step.info[i][field].y;
                        oe.pos.z = step.info[i][field].z;

                        o3.position.copy(step.info[i][field]);
                        o3.userData.obb.c.copy(o3.position);
                    }
                    markingHelper();
                };

                /**
                 * @description Function to undo the current step
                 * @param {Room} room the room object containing the objects affected by the action
                 */
                var undoStep = function (room) {
                    if (currentStep < 0) return;
                    var step = steps[currentStep];
                    switch (step.type) {
                        case 'add':
                            removeObjectFromSelection(step.info[0], true);
                            break;
                        case 'remove':
                            addObjectToSelection(step.info[0], true);
                            break;
                        default:
                            undoAlignCloseStep(step, room);
                    }
                    currentStep--;
                };

                /**
                 * @description Function to handle redo of a action
                 * @param {Room} room the room object containing the objects affected by the redo of an action
                 */
                var redoStep = function (room) {
                    // if (currentStep >= (steps.length - 1)) return;
                    var step = steps[currentStep + 1];
                    switch (step.type) {
                        case 'add':
                            addObjectToSelection(step.info[0], true);
                            break;
                        case 'remove':
                            removeObjectFromSelection(step.info[0], true);
                            break;
                        default:
                            redoAlignCloseStep(step, room);
                    }
                    // console.log(step.type + "||" + currentStep);
                    currentStep++;
                };

                /**
                 * @description Function to align objects
                 * @param {boolean} back if true the "back" of the object will be used otherwise the front
                 * @param {string} axis the axis on which the alignment should happen
                 * @param {Room} room the room object containing the to-be aligned objects
                 * @returns {boolean}
                 */
                var alignObjects = function (back, axis, room) {
                    var objects = selectedObjects;
                    var mainObj = objects[0];
                    mainObj.aligned = true;
                    var axisIndex = 0, axisInvertedIndex = 2;
                    if (axis && axis === "z") {
                        axisIndex = 2;
                        axisInvertedIndex = 0;
                    }
                    var dir = mainObj.userData.obb.u[axisIndex].clone();
                    var direction = "z";
                    // direction: z = vertical movement, x = horizontal movement (easier to see in a 2d view)
                    if (Math.abs(dir.x) == 1) {
                        direction = "x";
                    }

                    var point = mainObj.userData.obb.getMidPointAxis(axisIndex, back);
                    var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(dir, point);
                    var alignObject = function (object) {
                        var oobj = room.findObjectByUniqueID(object.userData.uid);
                        if (object.userData.uid !== mainObj.userData.uid) {
                            var points = object.userData.obb.getBottomPlanePoints();
                            var moveVec = findMoveVector(plane, points, back);
                            if (oobj instanceof FloorTile) {
                                console.log("implement me");
                            } else {
                                var posx;
                                var posz;
                                // userData.obb.e.x is the half width size of the object
                                // userData.obb.e.z is the half depth size of the object

                                // align objects to right
                                if (axis === "x" && !back) {
                                    if (direction === "x") {
                                        // simulation of the new position with horizontal movement
                                        object.userData.obb.c.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // simulation of the new position with vertical movement
                                        object.userData.obb.c.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }
                                    // check if there is a collision with the First object and the current object
                                    if (WebGLService.checkCollision(mainObj, object, room)) {
                                        console.log("collision detect with the first object");
                                        if (moveVec.x > 0 || moveVec.z > 0) {
                                            posx = moveVec.x - (mainObj.userData.obb.e.x + mainObj.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                            posz = moveVec.z - (mainObj.userData.obb.e.x + mainObj.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                        } else {
                                            posx = moveVec.x + (object.userData.obb.e.x + object.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                            posz = moveVec.z + (object.userData.obb.e.x + object.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                        }
                                    } else {
                                        posx = moveVec.x + SPACE_TO_AVOID_COLLISION;
                                        posz = moveVec.z + SPACE_TO_AVOID_COLLISION;
                                    }

                                    moveVec.x = posx;
                                    moveVec.z = posz;
                                    if (direction === "x") {
                                        // horizontal movement
                                        object.position.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // vertical movement
                                        object.position.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }
                                }

                                // align objects to left
                                else if (axis === "x" && back) {
                                    if (direction === "x") {
                                        // simulation of the new position with horizontal movement
                                        object.userData.obb.c.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // simulation of the new position with vertical movement
                                        object.userData.obb.c.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }
                                    // check if there is a collision with the First object and the current object
                                    if (WebGLService.checkCollision(mainObj, object, room)) {
                                        console.log("collision detect with the first object");
                                        if (moveVec.x < 0 || moveVec.z < 0) {
                                            posx = moveVec.x + (mainObj.userData.obb.e.x + mainObj.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                            posz = moveVec.z + (mainObj.userData.obb.e.x + mainObj.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                        } else {
                                            posx = moveVec.x - (object.userData.obb.e.x + object.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                            posz = moveVec.z - (object.userData.obb.e.x + object.userData.obb.e.x + SPACE_TO_AVOID_COLLISION);
                                        }
                                    } else {
                                        posx = moveVec.x + SPACE_TO_AVOID_COLLISION;
                                        posz = moveVec.z + SPACE_TO_AVOID_COLLISION;
                                    }

                                    moveVec.x = posx;
                                    moveVec.z = posz;
                                    if (direction === "x") {
                                        // horizontal movement
                                        object.position.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // vertical movement
                                        object.position.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }
                                }

                                // align objects to front
                                else if (axis === "z" && !back) {

                                    if (direction === "x") {
                                        // simulation of the new position with horizontal movement
                                        object.userData.obb.c.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // simulation of the new position with vertical movement
                                        object.userData.obb.c.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }

                                    for (var j = 0; j < objects.length; j++) {
                                        if (objects[j].userData.uid !== object.userData.uid) {
                                            // check if there is a collision with the last and the current object
                                            if (WebGLService.checkCollision(objects[j], object, room)) {
                                                console.log("collision detect with last object");
                                                if (objects[j].userData.obb.e.z >= object.userData.obb.e.z) {
                                                    // if the depth size of the last object is greater than or equal to current object
                                                    (moveVec.x > 0) ? posx = moveVec.x - (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posx = moveVec.x + (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                    //check if there is a positive or negative movement both in horizontal and vertical way to avoid objects interpenetration
                                                    (moveVec.z > 0) ? posz = moveVec.z - (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posz = moveVec.z + (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                } else {
                                                    // if the depth size of the last object is less than the current object
                                                    (moveVec.x > 0) ? posx = moveVec.x - (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posx = moveVec.x + (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                    (moveVec.z > 0) ? posz = moveVec.z - (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posz = moveVec.z + (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                }
                                                moveVec.x = posx;
                                                moveVec.z = posz;
                                            }
                                        } else {
                                            break;
                                        }
                                    }
                                    if (direction === "x") {
                                        // new horizontal position of the object
                                        object.position.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // new vertical position of the object
                                        object.position.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }
                                }

                                // align objects to back
                                else if (axis === "z" && back) {

                                    if (direction === "x") {
                                        // simulation of the new position with horizontal movement
                                        object.userData.obb.c.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // simulation of the new position with vertical movement
                                        object.userData.obb.c.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }

                                    for (var j = 0; j < objects.length; j++) {
                                        if (objects[j].userData.uid !== object.userData.uid) {
                                            // check if there is a collision with the last and the current object
                                            if (WebGLService.checkCollision(objects[j], object, room)) {
                                                console.log("collision detect with last object");
                                                if (objects[j].userData.obb.e.z >= object.userData.obb.e.z) {
                                                    // if the depth size of the last object is greater than or equal to current object
                                                    (moveVec.x > 0) ? posx = moveVec.x - (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posx = moveVec.x + (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                    //check if there is a positive or negative movement both in horizontal and vertical way to avoid objects interpenetration
                                                    (moveVec.z > 0) ? posz = moveVec.z - (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posz = moveVec.z + (objects[j].userData.obb.e.z + objects[j].userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                } else {
                                                    // if the depth size of the last object is less than the current object
                                                    (moveVec.x > 0) ? posx = moveVec.x - (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posx = moveVec.x + (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                    (moveVec.z > 0) ? posz = moveVec.z - (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION) : posz = moveVec.z + (object.userData.obb.e.z + object.userData.obb.e.z + SPACE_TO_AVOID_COLLISION);
                                                }
                                                moveVec.x = posx;
                                                moveVec.z = posz;
                                            }
                                        } else {
                                            break;
                                        }
                                    }
                                    if (direction === "x") {
                                        // new horizontal position of the object
                                        object.position.add(new THREE.Vector3(moveVec.x, 0, 0));
                                    } else {
                                        // new vertical position of the object
                                        object.position.add(new THREE.Vector3(0, 0, moveVec.z));
                                    }
                                }

                                oobj.pos.x = object.position.x;
                                oobj.pos.z = object.position.z;

                                object.userData.obb.c.copy(object.position);
                                object.aligned = true;
                                object.posChanged = true;
                                object.alignAxisIndex = axisInvertedIndex;
                            }
                        }
                    };

                    var infoObj = buildInfoObj('align', objects);

                    for (var i = 0; i < objects.length; i++) {
                        alignObject(objects[i]);
                    }
                    buildInfoObj('align', objects, infoObj);
                    addStep('align', infoObj);
                    return true;
                };

                /**
                 * @description Function to find the vector to close the gap between object defined by the provided plane
                 * @param {THREE.Plane} plane the plane close the gap to
                 * @param {Array} points list of points (corners of OBB)
                 * @returns {*} returns a vector to apply to close the gap
                 */
                var findCloseGapVector = function (plane, points) {
                    var pds = [];
                    for (var i = 0; i < points.length; i++) {
                        pds.push(plane.distanceToPoint(points[i]));
                    }
                    var min = Math.min.apply(null, pds);
                    var max = Math.max.apply(null, pds);
                    var fac = 0;
                    if (min < 0 && max < 0) {
                        fac = max;
                    }
                    if (min > 0 && max > 0) {
                        fac = min;
                    }
                    if (min < 0 && max > 0) {
                        fac = findSmallestNegValue(pds);
                    }
                    return plane.normal.clone().normalize().multiplyScalar(fac * -1);
                };

                var movePositive = function (objPos, objUserData, moveVec, prevObj, isXAxis) {
                    var tempMoveVec = moveVec.clone();
                    isXAxis ? tempMoveVec.x = tempMoveVec.x + SPACE_TO_AVOID_COLLISION
                        : tempMoveVec.z = tempMoveVec.z + SPACE_TO_AVOID_COLLISION;

                    var tempPos = objPos.clone();
                    var tempUserData = angular.copy(objUserData);
                    tempPos.add(tempMoveVec);
                    tempUserData.obb.c.copy(tempPos);
                    if (prevObj.userData.obb.isIntersectionBox(tempUserData.obb.clone())) {
                        isXAxis ? tempMoveVec.x = tempMoveVec.x - (SPACE_TO_AVOID_COLLISION * 2)
                            : tempMoveVec.z = tempMoveVec.z - (SPACE_TO_AVOID_COLLISION * 2);

                        tempPos = objPos.clone();
                        tempUserData = angular.copy(objUserData);
                        tempPos.add(tempMoveVec);
                        tempUserData.obb.c.copy(tempPos);
                        if (prevObj.userData.obb.isIntersectionBox(tempUserData.obb.clone())) {
                            tempMoveVec = moveNegative(objPos, objUserData, moveVec, prevObj, isXAxis);
                        }
                    }
                    return tempMoveVec;
                };

                var moveNegative = function (objPos, objUserData, moveVec, prevObj, isXAxis) {
                    var tempMoveVec = moveVec.clone();
                    isXAxis ? tempMoveVec.x = tempMoveVec.x - SPACE_TO_AVOID_COLLISION
                        : tempMoveVec.z = tempMoveVec.z - SPACE_TO_AVOID_COLLISION;

                    var tempPos = objPos.clone();
                    var tempUserData = angular.copy(objUserData);
                    tempPos.add(tempMoveVec);
                    tempUserData.obb.c.copy(tempPos);
                    if (prevObj.userData.obb.isIntersectionBox(tempUserData.obb.clone())) {
                        isXAxis ? tempMoveVec.x = tempMoveVec.x + (SPACE_TO_AVOID_COLLISION * 2)
                            : tempMoveVec.z = tempMoveVec.z + (SPACE_TO_AVOID_COLLISION * 2);

                        tempPos = objPos.clone();
                        tempUserData = angular.copy(objUserData);
                        tempPos.add(tempMoveVec);
                        tempUserData.obb.c.copy(tempPos);
                        if (prevObj.userData.obb.isIntersectionBox(tempUserData.obb.clone())) {
                            tempMoveVec = movePositive(objPos, objUserData, moveVec, prevObj, isXAxis);
                        }
                    }
                    return tempMoveVec;
                };

                /**
                 * @description Function to close the gaps between selected objects
                 * @param {string} axis the axis on which to close the gaps
                 * @param {Room} room the room object containing the objects to close the gaps for
                 * @returns {boolean}
                 */
                var closeGaps = function (axis, room) {
                    var objects = selectedObjects;
                    var mainObj = objects[0];
                    var axisIndex = (axis && axis === "z") ? 2 : 0;
                    var dir = mainObj.userData.obb.u[axisIndex].clone();
                    var nxObjs = new DistanceList(objects.length);
                    var pxObjs = new DistanceList(objects.length);
                    var mainObjectPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(dir, mainObj.userData.obb.c);
                    var mp = mainObj.userData.obb.c.clone();
                    for (var i = 0; i < objects.length; i++) {
                        var dist = mainObjectPlane.distanceToPoint(objects[i].userData.obb.c);
                        if (dist < 0) {
                            nxObjs.addObject(dist, objects[i]);
                        } else if (dist > 0) {
                            pxObjs.addObject(dist, objects[i]);
                        }
                    }
                    var infoObj = buildInfoObj('close', objects);

                    var offleft = axisIndex ? (mainObj.userData.obb.e.z * -1) - SPACE_TO_AVOID_COLLISION : (mainObj.userData.obb.e.x * -1) - SPACE_TO_AVOID_COLLISION;
                    nxObjs.reverse();
                    for (var j = 0; j < nxObjs.list.length; j++) {
                        var obj = nxObjs.list[j];
                        var prevObj = j >= 1 ? nxObjs.list[j - 1].obj : mainObj;
                        var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(dir.clone().multiplyScalar(-1)
                            , mp.clone().add(dir.clone().normalize().multiplyScalar(offleft)));
                        moveObjects(axis, room, plane, obj.obj, prevObj);
                        offleft -= obj.obj.userData.obb.getProjectedLengthOnAxis(dir.clone().multiplyScalar(-1)) + SPACE_TO_AVOID_COLLISION;
                    }

                    var offright = axisIndex ? mainObj.userData.obb.e.z + SPACE_TO_AVOID_COLLISION : mainObj.userData.obb.e.x + SPACE_TO_AVOID_COLLISION;
                    for (var k = 0; k < pxObjs.list.length; k++) {
                        var obj = pxObjs.list[k];
                        var prevObj = k >= 1 ? pxObjs.list[k - 1].obj : mainObj;
                        var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(dir.clone()
                            , mp.clone().add(dir.clone().normalize().multiplyScalar(offright)));
                        moveObjects(axis, room, plane, obj.obj, prevObj);
                        offright += obj.obj.userData.obb.getProjectedLengthOnAxis(dir.clone()) + SPACE_TO_AVOID_COLLISION;
                    }

                    buildInfoObj('close', objects, infoObj);
                    addStep('close', infoObj);
                    return true;
                };

                var moveObjects = function (axis, room, plane, obj, prevObj) {
                    var oobj = room.findObjectByUniqueID(obj.userData.uid);
                    var moveVec = findCloseGapVector(plane, obj.userData.obb.getBottomPlanePoints());
                    if (oobj instanceof FloorTile) {
                        console.log("TODO");
                    } else {
                        if (Tools.isDefinedNotNull(moveVec)) {
                            if (axis === "x") {
                                moveVec = movePositive(angular.copy(obj.position)
                                    , angular.copy(obj.userData)
                                    , moveVec.clone()
                                    , prevObj
                                    , true);
                            }
                            if (axis === "z") {
                                moveVec = moveNegative(angular.copy(obj.position)
                                    , angular.copy(obj.userData)
                                    , moveVec.clone()
                                    , prevObj
                                    , false);
                            }
                            obj.position.add(moveVec);
                            obj.userData.obb.c.copy(obj.position);
                            oobj.pos.x = obj.position.x;
                            oobj.pos.z = obj.position.z;
                        }
                    }
                };

                //endregion
                //TODO this should not be needed anymore be gone thot?
                var findContainmentForObjects = function () {
                    var objects = selectedObjects;
                    var p = null;
                    var i;
                    var main = objects[0];
                    var w = 0, h = main.scale.y, t = 0, r = main.rotation.y;
                    var counter = null;
                    var mainSidePosis = [];
                    var ray = new THREE.Ray();

                    ray.set(main.position.clone(), main.userData.obb.u[2].clone());
                    for (i = 0; i < objects.length; i++) {
                        if (ray.distanceSqToPoint(objects[i].position) < 0.001 && objects[i].userData.uid !== main.userData.uid) {
                            counter = objects[i];
                            w = objects[i].position.clone().sub(main.position).length();
                            w -= (objects[i].userData.obb.e.z + main.userData.obb.e.z);
                            break;
                        }
                    }
                    for (i = 0; i < objects.length; i++) {
                        if (objects[i].rotation.y === main.rotation.y) {
                            t += objects[i].scale.x;
                            mainSidePosis.push(objects[i].position.clone());
                        }
                    }
                    var p0 = new THREE.Vector3();
                    for (i = 0; i < mainSidePosis.length; i++) {
                        p0.add(mainSidePosis[i]);
                    }
                    p0.multiplyScalar(1 / mainSidePosis.length);
                    p0.add(main.userData.obb.u[2].clone().normalize().multiplyScalar(main.userData.obb.e.z + w / 2));
                    p = p0.clone();
                    if (w > 0 && h > 0 && t > 0 && p !== null) {
                        var c = new Containment();
                        c.pos.x = p.x;
                        c.pos.y = p.y;
                        c.pos.z = p.z;
                        c.size.x = t;
                        c.size.y = h;
                        c.size.z = w;
                        c.rot.y = MathService.radToDeg(r);
                        c.type = Containment.TYPERANGE[0];
                        return c;

                    } else {
                        return false;
                    }
                };

                // region visibility and opacity modification
                // datastructure holding information to build content for visibility panel in room editors 'edit view'
                var visibilityOptsRoomEdit = [
                    {
                        id: 1,
                        name: $translate.instant("room.glCtrl.additionalVisibility.walls"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "wall"
                    },
                    {
                        id: 2,
                        name: $translate.instant("room.glCtrl.additionalVisibility.pillars"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "pillar"
                    },
                    {
                        id: 3,
                        name: $translate.instant("room.glCtrl.additionalVisibility.doors"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "door"
                    },
                    {
                        id: 4,
                        name: $translate.instant("room.glCtrl.additionalVisibility.windows"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "window"
                    },
                    {
                        id: 5,
                        name: $translate.instant("room.glCtrl.additionalVisibility.raisedFloor"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "raisedFloor"
                    },
                    {
                        id: 12,
                        name: $translate.instant("room.glCtrl.additionalVisibility.floor"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "floor"
                    },
                ];

                //datastructure holding information to build content for visibility panel in room editors 'device view'
                var visibilityOptsRoomView = [
                    {
                        id: 1,
                        name: 'room.tree.rackFolder',
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "rack"
                    },
                    {
                        id: 2,
                        name: 'room.tree.coolingFolder',
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "cooling"
                    },
                    {
                        id: 3,
                        name: "room.tree.sensorFolder",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "sensor"
                    },
                    {
                        id: 4,
                        name: "room.tree.assetFolder",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "asset"
                    },
                    {
                        id: 5,
                        name: "room.tree.floorTileFolder",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "floortile"
                    },
                    {
                        id: 6,
                        name: "room.glCtrl.additionalVisibility.pillars",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "pillar"
                    },
                    {
                        id: 7,
                        name: "room.glCtrl.additionalVisibility.raisedFloor",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "raisedFloor"
                    },
                    {
                        id: 8,
                        name: "room.glCtrl.additionalVisibility.containments",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "containment"
                    },
                    {
                        id: 9,
                        name: "room.glCtrl.additionalVisibility.wallsfacing",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "wall_front"
                    },
                    {
                        id: 10,
                        name: "room.glCtrl.additionalVisibility.wallsaverted",
                        visible: true,
                        opacity: Object3DFactory.opacities.frontwall,
                        maxOpacity: Object3DFactory.opacities.frontwall,
                        objname: "wall_back"
                    },
                    {
                        id: 11,
                        name: "room.glCtrl.additionalVisibility.wallsinner",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "innerwall"
                    },
                    {
                        id: 12,
                        name: $translate.instant("room.glCtrl.additionalVisibility.floor"),
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "floor"
                    },
                    {
                        id: 13,
                        name: "room.tree.upsFolder",
                        visible: true,
                        opacity: 1.0,
                        maxOpacity: 1.0,
                        objname: "ups"
                    }
                ];

                /**
                 * @description Function to get the visibility options for the respective view
                 * @param {string} viewType string describing which view type to retrieve information for
                 * @returns {*} returns array to setup the sliders/checkboxes for the respective view
                 */
                var getVisibilityOpts = function (viewType) {
                    if (viewType === "roomedit") return angular.copy(visibilityOptsRoomEdit);
                    if (viewType === "roomview") return angular.copy(visibilityOptsRoomView);
                };

                /**
                 * @description Function to update the visiblity of objects in 3d context
                 * @param {THREE.Object3D} roomObj the container object with all meshes in it
                 * @param {string} objname string describing the type of objects to be modified
                 * @param {boolean} visible sets the visibility option for the objects defined by the provided objname
                 * @param {Room} room the room object containing the objects to be modified
                 */
                var updateVisibility = function (roomObj, objname, visible, room) {
                    var front = true;
                    if (objname === "wall_back") front = false;
                    if (objname === "wall_front" || objname === "wall_back") objname = "outerwall";
                    var camPos = WebGLService.getActiveCamera().position.clone();
                    if (Tools.isDefinedNotNull(roomObj)) {
                        roomObj.traverse(function (o) {
                            if (o.name === objname) {
                                if (objname === "outerwall") {
                                    var a = room.bbox.getCenter().setY(0).sub(camPos.setY(0)).angleTo(o.userData.normal);
                                    if (a < Math.PI / 2 && !front) o.visible = visible;
                                    if (a > Math.PI / 2 && front) o.visible = visible;
                                } else {
                                    o.visible = visible;
                                }
                            }
                            if (objname === "wall" && o.name === "outerwall") {
                                o.visible = visible;
                            }
                        });
                    }
                };

                /**
                 * @description Function to update visibility and opacity of displayed objects
                 * @param {object} opts object containing visibility and opacity data for possible object types
                 * @param {Room} room the room object containing the objects to be modified
                 * @param {THREE.Object3D} roomObj container object
                 */
                var updateAllVisibilityAndOpacity = function (opts, room, roomObj) {
                    for (var i = 0; i < opts.length; i++) {
                        updateVisibility(roomObj, opts[i].objname, opts[i].visible, room);
                        updateOpacity(roomObj, opts[i].objname, opts[i].opacity, room);
                    }
                };

                /**
                 * @description Function to update the opacity for the specified objects
                 * @param {THREE.Object3D} roomObj the container object
                 * @param {string} objname name of the object type to modify the opacity for
                 * @param {number} opacity the value to set the opacity to, range 0..1
                 * @param {Room} room the room object containing the objects to be updated
                 */
                var updateOpacity = function (roomObj, objname, opacity, room) {
                    opacity = parseFloat(opacity);
                    var handleWindowsDoorsOfWall = function (ws, ds, opacity) {
                        roomObj.traverse(function (oo) {
                            if ((oo.name === "door" && ds.indexOf(oo.userData.id) !== -1) || (oo.name === "window" && ws.indexOf(oo.userData.id) !== -1)) {
                                oo.material.transparent = opacity < 1;
                                oo.material.opacity = opacity;
                            }
                        });
                    };
                    if (Tools.isDefinedNotNull(roomObj)) {
                        roomObj.traverse(function (o) {
                            if (o.name === objname) {
                                if (objname === "cooling") {
                                    o.material.transparent = opacity < 1;
                                    if (Tools.isDefinedNotNull(o.material.uniforms)) {
                                        o.material.uniforms.opacity.value = opacity;
                                    }
                                } else if (objname === "containment") {
                                    o.traverse(function (oo) {
                                        if (oo.name === "containment_plane") {
                                            if (opacity > oo.userData.maxOpacity) {
                                                oo.material.opacity = oo.userData.maxOpacity;
                                            } else {
                                                oo.material.opacity = opacity;
                                            }
                                        }
                                        if (oo.name === "containment_door") {
                                            oo.material.transparent = opacity < 1;
                                            oo.material.opacity = opacity;
                                        }
                                    });
                                } else if (objname === "innerwall") {
                                    o.traverse(function (oo) {
                                        o.material.transparent = opacity < 1;
                                        oo.material.opacity = opacity;
                                    });
                                    var i;
                                    var windows = angular.copy(room.getWindowsForWall(o));
                                    for (i = 0; i < windows.length; i++) windows[i] = windows[i].id;
                                    var doors = angular.copy(room.getDoorsForWall(o));
                                    for (i = 0; i < doors.length; i++) doors[i] = doors[i].id;
                                    handleWindowsDoorsOfWall(windows, doors, opacity);
                                } else {
                                    o.material.transparent = opacity < 1;
                                    o.material.opacity = opacity;
                                }
                            }
                            if (objname === "wall" && o.name === "outerwall") {
                                o.material.transparent = opacity < 1;
                                o.material.opacity = opacity;
                            }
                        });
                    }
                };
                // endregion
                // region sensor overlay
                // array holding ids for labels
                var sensorOverlayLabelIds = [];

                /**
                 * @description Function to create the sensor overlay labels
                 * @param {Room} room room object containing the sensors
                 * @param physType physical type to be displayed
                 * @param {string} container name of container to add the labels to
                 * @param userTempType temperature type defined by user (Kelvin, Fahrenheit, Celsius)
                 */
                var addSensorOverlay = function (room, physType, container, userTempType) {
                    if (sensorOverlayLabelIds.length) removeSensorOverlay();
                    sensorOverlayLabelIds = [];
                    var distCamRoom = room.bbox.getCenter().distanceTo(WebGLService.getActiveCamera().position);
                    var clusters = DBScanSensors.scan(room.sensors, distCamRoom / 10);
                    for (var i = 0; i < clusters.length; i++) {
                        var c = DBScanSensors.findClusterCenter(clusters[i]);
                        TrackingDivLabelFactory.buildMultiSensorLabel(clusters[i], "mSensLabel_" + i, container, physType, c, userTempType);
                        sensorOverlayLabelIds.push("mSensLabel_" + i);
                    }
                    TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
                };

                /**
                 * @description Function to remove sensor overlay labels from view
                 */
                var removeSensorOverlay = function () {
                    for (var i in sensorOverlayLabelIds) TrackingDivLabelFactory.removeLabel(sensorOverlayLabelIds[i]);
                };
                // endregion

                /**
                 * @description Function to get the closest point on a line with respect to the provided point
                 * @param {THREE.Vector3} start start point of the ray
                 * @param {THREE.Vector3} dir direction of the ray
                 * @param {THREE.Vector3} point point to get the closest point on ray for
                 * @returns {*} returns the point on the ray closest to the provided point
                 */
                var projectOnRay = function (start, dir, point) {
                    var line = new THREE.Line3(start, start.clone().add(dir));
                    return line.closestPointToPoint(point, false);
                };

                /**
                 * @description Function to handle changes of the mark rect plane by change of 'mover' position
                 * @param {THREE.Object3D} mover container object of mover meshes
                 * @param {THREE.Object3D} markObj container object containing all necessary meshes
                 */
                var handleMarkRectMoverChange = function (mover, markObj) {
                    var midPoint = mover.position.clone();
                    midPoint.add(mover.userData.cm.position.clone().sub(mover.position).multiplyScalar(0.5));
                    var xScale = markObj.children[0].scale.x;
                    var zScale = markObj.children[0].scale.y;
                    var cdir = mover.userData.dirt.substring(1) === "x" ? "z" : "x";
                    var moversToUpdate = [];
                    for (var d = 0; d < markObj.children[1].children.length; d++) {
                        if (markObj.children[1].children[d].userData.dirt.substring(1) === cdir) moversToUpdate.push(markObj.children[1].children[d]);
                    }
                    moversToUpdate[0].position.setX(midPoint.x).setZ(midPoint.z);
                    moversToUpdate[1].position.setX(midPoint.x).setZ(midPoint.z);
                    var udir = cdir === "x" ? mover.userData.cobj.userData.obb.u[0].clone().multiplyScalar(xScale / 2) : mover.userData.cobj.userData.obb.u[2].clone().multiplyScalar(zScale / 2);
                    if (moversToUpdate[0].userData.dirt[0] === "p") {
                        moversToUpdate[0].position.add(udir);
                        moversToUpdate[1].position.add(udir.clone().multiplyScalar(-1));
                    } else {
                        moversToUpdate[0].position.add(udir.clone().multiplyScalar(-1));
                        moversToUpdate[1].position.add(udir);
                    }
                    moversToUpdate[0].userData.op = moversToUpdate[0].position.clone();
                    moversToUpdate[1].userData.op = moversToUpdate[1].position.clone();
                    var sizeX = cdir === "z" ? mover.position.clone().sub(mover.userData.cm.position).length() : xScale;
                    var sizeZ = cdir === "x" ? mover.position.clone().sub(mover.userData.cm.position).length() : zScale;
                    markObj.children[0].scale.setX(sizeX).setY(sizeZ);
                    markObj.children[0].position.setX(midPoint.x).setZ(midPoint.z);
                };

                /**
                 * @description Function to handle snapping to walls/other objects in room for mark plane
                 * @param {THREE.Vector3} p the current mover position
                 * @param {Room} room the room object containing the objects to snap to
                 * @param {THREE.Object3D} roomObj3d the container object for meshes representing the displayed
                 * @param {THREE.Object3D} mover the container object for all mover meshes
                 * @returns array of length 2, 1st element is of type boolean and states whether if mover should snap to point (2nd element)
                 */
                var checkSnapMarkRectMover = function (p, room, roomObj3d, mover) {
                    var dir = mover.userData.dir.clone();
                    var p0 = p.clone().setY(0);
                    //check against walls
                    var walls = room.getWallPointPairs();
                    for (var i = 0; i < walls.length; i++) {
                        var wl = new THREE.Line3(walls[i].s, walls[i].e);
                        var a = dir.angleTo(wl.delta().normalize());
                        if (Math.abs(Math.abs(a) - Math.PI / 2) < 0.05) {
                            var pl = wl.closestPointToPoint(p0);
                            if (p0.distanceTo(pl) < 0.25) {
                                return [true, pl];
                            }
                        }
                    }
                    //check objects
                    var testOBB = new OBB();
                    testOBB.e.set(0.01, 0.01, 0.01);
                    testOBB.c.copy(p0);
                    var check = false;
                    var pp = null;
                    var axisLine = new THREE.Line3(p0, p0.clone().add(dir));
                    roomObj3d.traverse(function (o) {
                        if (o.name === "asset" || o.name === "cooling" || o.name === "rack" || o.name === "ups") {
                            testOBB.c.setY(o.position.y);
                            if (testOBB.isIntersectionBox(o.userData.obb)) {
                                var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(dir, o.position);
                                var dp = plane.distanceToPoint(p0);
                                var h0 = Math.abs(Math.abs(dir.clone().normalize().dot(o.userData.obb.u[0])) - 1) < 0.001;
                                var axis = h0 ? o.userData.obb.u[0].clone() : o.userData.obb.u[2].clone();
                                var ex = h0 ? o.userData.obb.e.x : o.userData.obb.e.z;
                                var fac = dir.angleTo(axis) > 0.025 ? -1 : 1;
                                var ppp;
                                if (dp > 0) {
                                    ppp = o.position.clone().add(axis.clone().multiplyScalar(ex * fac)).setY(0);
                                    pp = axisLine.closestPointToPoint(ppp);
                                    check = true;
                                } else {
                                    ppp = o.position.clone().add(axis.clone().multiplyScalar(ex * fac * -1)).setY(0);
                                    pp = axisLine.closestPointToPoint(ppp);
                                    check = true;
                                }
                            }
                        }
                    });
                    if (check) {
                        return [true, pp];
                    } else {
                        return [false, null];
                    }
                };

                /**
                 * @description Function to retrieve the size of the marker plane for provided axis
                 * @param {THREE.Object3D} markObj container object of marker plane
                 * @param {string} axis the axis to get the size for
                 * @returns {*} return the size of the marked plane by the provided axis
                 */
                var getSizeForMarkedRect = function (markObj, axis) {
                    if (Tools.isDefinedNotNull(markObj)) {
                        if (axis === "x") return markObj.children[0].scale.x;
                        if (axis === "z") return markObj.children[0].scale.y;
                    }
                };

                /**
                 * @description Function to retrieve the position of the marker plane in 3 dimension
                 * @param {THREE.Object3D} markObj container object of marker plane mesh
                 * @param {string=} axis if argument is provided returned positional info will only be the value for this axis, otherwise a 3-dimensional vector will be returned
                 * @returns {*} returns 3-dimensional position of the marker plane if 2nd argument not provided or can not be applied, returns the position component defined by axis
                 */
                var getPositionMarkedRect = function (markObj, axis) {
                    if (Tools.isDefinedNotNull(markObj)) {
                        if (axis === "x") return markObj.children[0].position.x;
                        if (axis === "y") return markObj.children[0].position.y;
                        if (axis === "z") return markObj.children[0].position.z;
                        return markObj.children[0].position.clone();
                    }
                };

                /**
                 * @description function to check if all objects of a room are fully contained within the rooms boundaries
                 * @param {Room} room the room object to check against
                 * @param {THREE.Object3D} room3DObj the object containing the 3-dimensional representation of the room
                 * @returns {*} returns an array of varying length, if all objects are contained in the boundaries of the provided room the return array is of length 1 containing just a boolean value true
                 *              returns a array of length 3 if a collision is detected, additionally the colliding object and a string describing the collision type
                 */
                var checkAllObjectsWithinRoom = function (room, room3DObj) {
                    var cornerPoints = room.getCornerPoints(3);
                    if (Tools.isDefinedNotNull(room.innerWalls) && room.innerWalls.length > 0) {
                        for (var i = 0; i < room.innerWalls.length; i++) {
                            if (!Room.checkPointInPolygon(room.innerWalls[i].start, cornerPoints, true)) return false;
                            if (!Room.checkPointInPolygon(room.innerWalls[i].end, cornerPoints, true)) return false;
                        }
                    }
                    if (Tools.isDefinedNotNull(room.roomObjs) && room.roomObjs.length > 0) {
                        for (var i = 0; i < room.roomObjs.length; i++) {
                            if (room.roomObjs[i] instanceof Pillar || room.roomObjs[i] instanceof Containment) {
                                var o3d = WebGLService.findObjectByUniqueId(room.roomObjs[i].uniqueId, room3DObj);
                                if (o3d && room.roomObjs[i].checkCollision(o3d, room3DObj, o3d.position.clone(), false, room)) return false;
                                if (!Room.checkPointInPolygon(o3d.position, cornerPoints)) return false;
                            }
                        }
                    }
                    if (Tools.isDefinedNotNull(room.assets) && room.assets.length > 0 && !checkCollisionAndPointInPolygon(room, room.assets, cornerPoints, room3DObj)) return false;
                    if (Tools.isDefinedNotNull(room.coolings) && room.coolings.length > 0 && !checkCollisionAndPointInPolygon(room, room.coolings, cornerPoints, room3DObj)) return false;
                    // if (Tools.isDefinedNotNull(room.floorTiles) && room.floorTiles.length > 0 && !checkCollisionAndPointInPolygon(room, room.floorTiles, cornerPoints, room3DObj)) return false;
                    if (Tools.isDefinedNotNull(room.racks) && room.racks.length > 0 && !checkCollisionAndPointInPolygon(room, room.racks, cornerPoints, room3DObj)) return false;
                    if (Tools.isDefinedNotNull(room.ups) && room.ups.length > 0 && !checkCollisionAndPointInPolygon(room, room.ups, cornerPoints, room3DObj)) return false;
                    return true;
                };

                /**
                 * Checks the collision between objects and if all objects are in the room
                 * @param {object} room is the current room object
                 * @param {array} arrayOfRoomObjects is an array of children of the {@param room}
                 * @param {array} cornerPoints is an array of all points of the corners
                 * @param room3DObj is the 3D room that containing the entity
                 * @returns {boolean} true or false
                 */
                var checkCollisionAndPointInPolygon = function (room, arrayOfRoomObjects, cornerPoints, room3DObj) {
                    for (var index = 0; index < arrayOfRoomObjects.length; index++) {
                        var o3d = WebGLService.findObjectByUniqueId(arrayOfRoomObjects[index].uniqueId, room3DObj);
                        if (o3d && arrayOfRoomObjects[index].checkCollision(o3d, room3DObj, o3d.position.clone(), false, room)) return false;
                        if (!Room.checkPointInPolygon(o3d.position, cornerPoints)) return false;
                    }
                    return true;
                };

                //region fullscreen
                var containerParent = null;
                var disableFullScreenMode = function () {
                    var element = $('#roomEditorContainer');
                    element.detach();
                    $('#roomEditorGenControls').before(element);
                    element.removeClass("editorFullScreen");
                    $('#sidebar').show();
                    $('#page-wrapper').show();
                };
                var enableFullScreenMode = function () {
                    var element = $('#roomEditorContainer');
                    containerParent = element.parent();
                    element.detach();
                    $('body').append(element);
                    element.addClass("editorFullScreen");
                    $('#sidebar').hide();
                    $('#page-wrapper').hide();
                };
                //endregion

                //region general utils
                /**
                 * @description function to update the current url depending on the provided object
                 * @param {NamedEntity} object the object to update the url with its unique id
                 */
                var updateURLForObject = function (object) {
                    if (object !== null) {
                        var params;
                        if (object instanceof Asset || object instanceof Cooling || object instanceof Rack || object instanceof Ups || object instanceof Sensor) {
                            params = angular.copy($state.params);
                            params.ruuid = object.uniqueId;
                            $state.go($state.current.name, params, {notify: false});
                        }
                        if (object instanceof Slot) {
                            params = angular.copy($state.params);
                            params.rauuid = object.uniqueId;
                            $state.go($state.current.name, params, {notify: false});
                        }
                    } else {
                        if ($location.$$search.ruuid) {
                            delete $location.$$search.ruuid;
                            $location.$$compose();
                        }
                        if ($location.$$search.rauuid) {
                            delete $location.$$search.rauuid;
                            $location.$$compose();
                        }
                    }
                };

                /**
                 * @description function to check if object is located in povided raised floor
                 * @param {Entity} object object to run check for
                 * @param {RaisedFloor} raisedFloor the raised floor object to use for the checks
                 * @returns {boolean} returns true if object is located in raised floor otherwise false
                 */
                var checkObjectInRaisedFloor = function (object, raisedFloor) {
                    return ((object.pos.y - object.size.y / 2) < raisedFloor.size.y);
                };

                /**
                 * @description function to find object located in newly created raised floor
                 * @param {Room} room the room object to check sub-objects of
                 * @param {RaisedFloor} raisedFloor the newly created raised floor object
                 * @returns {Array} returns an array of objects that need to be modified
                 */
                var findObjectsInNewRaisedFloor = function (room, raisedFloor) {
                    var objects = [];
                    var i;
                    for (i = 0; i < room.assets.length; i++) {
                        if (checkObjectInRaisedFloor(room.assets[i], raisedFloor)) objects.push(room.assets[i]);
                    }
                    for (i = 0; i < room.coolings.length; i++) {
                        if (checkObjectInRaisedFloor(room.coolings[i], raisedFloor)) objects.push(room.coolings[i]);
                    }
                    for (i = 0; i < room.racks.length; i++) {
                        if (checkObjectInRaisedFloor(room.racks[i], raisedFloor)) objects.push(room.racks[i]);
                    }
                    for (i = 0; i < room.ups.length; i++) {
                        if (checkObjectInRaisedFloor(room.ups[i], raisedFloor)) objects.push(room.ups[i]);
                    }
                    for (i = 0; i < room.roomObjs.length; i++) {
                        if (Containment.isContainment(room.roomObjs[i].type) && checkObjectInRaisedFloor(room.roomObjs[i], raisedFloor)) objects.push(room.roomObjs[i]);
                    }
                    for (i = 0; i < room.roomObjs.length; i++) {
                        if (Door.isDoor(room.roomObjs[i].type) && checkObjectInRaisedFloor(room.roomObjs[i], raisedFloor)) objects.push(room.roomObjs[i]);
                    }
                    for (i = 0; i < room.roomObjs.length; i++) {
                        if (Window.isWindow(room.roomObjs[i].type) && checkObjectInRaisedFloor(room.roomObjs[i], raisedFloor)) objects.push(room.roomObjs[i]);
                    }
                    return objects;
                };

                /**
                 * @description function to check if object is located on raised floor
                 * @param {Entity} object the object to run the check for
                 * @param {RaisedFloor} raisedFloor raised floor object
                 * @returns {boolean} returns true if object is located on the provided raised floor otherwise false
                 */
                var checkObjectOnRaisedFloor = function (object, raisedFloor) {
                    return Math.abs((object.pos.y - object.size.y / 2) - raisedFloor.size.y) < 0.001;
                };

                /**
                 * @description function to find objects to modify after raised floor was removed
                 * @param {Room} room the room object
                 * @param {RaisedFloor} raisedFloor the raised floor object
                 * @returns {Array} array of objects that need to be modified after raised floor is removed
                 */
                var findObjectsOnRemovedRaisedFloor = function (room, raisedFloor) {
                    var objects = [];
                    var i;
                    for (i = 0; i < room.assets.length; i++) {
                        if (checkObjectOnRaisedFloor(room.assets[i], raisedFloor)) objects.push(room.assets[i]);
                    }
                    for (i = 0; i < room.coolings.length; i++) {
                        if (checkObjectOnRaisedFloor(room.coolings[i], raisedFloor)) objects.push(room.coolings[i]);
                    }
                    for (i = 0; i < room.racks.length; i++) {
                        if (checkObjectOnRaisedFloor(room.racks[i], raisedFloor)) objects.push(room.racks[i]);
                    }
                    for (i = 0; i < room.ups.length; i++) {
                        if (checkObjectOnRaisedFloor(room.ups[i], raisedFloor)) objects.push(room.ups[i]);
                    }
                    for (i = 0; i < room.roomObjs.length; i++) {
                        if (Containment.isContainment(room.roomObjs[i].type) && checkObjectOnRaisedFloor(room.roomObjs[i], raisedFloor)) objects.push(room.roomObjs[i]);
                    }
                    for (i = 0; i < room.roomObjs.length; i++) {
                        if (Door.isDoor(room.roomObjs[i].type) && checkObjectOnRaisedFloor(room.roomObjs[i], raisedFloor)) objects.push(room.roomObjs[i]);
                    }
                    // for (i = 0; i < room.roomObjs.length; i++) {
                    //     if (Window.isWindow(room.roomObjs[i].type) && checkObjectOnRaisedFloor(room.roomObjs[i], raisedFloor)) objects.push(room.roomObjs[i]);
                    // }
                    return objects;
                };

                //endregion

                //region translate
                /**
                 * @description translate needed strings to improve performance ('| translate' creates to many watchers)
                 * @returns {*}
                 */
                var getTranslations = function () {
                    return $translate(['room.fullScreenToolTip', 'room.edit.controls.moveRight', 'room.edit.controls.moveBackwards', 'room.edit.controls.moveLeft',
                        'room.edit.controls.moveForward', 'room.edit.controls.camCenter', 'room.edit.controls.focus', 'room.edit.controls.ghosts',
                        'room.edit.controls.displayOptions', 'room.edit.controls.livedata', 'room.edit.controls.heatmap', 'room.edit.controls.camRaise', 'room.edit.controls.camSink',
                        'room.edit.controls.zoomIn', 'room.edit.controls.zoomOut', 'room.edit.controls.3d', 'room.edit.controls.2d', 'room.edit.controls.selectSingle', 'room.edit.controls.selectMulti',
                        'room.edit.align.back', 'room.edit.align.front', 'room.edit.align.left', 'room.edit.align.right', 'room.edit.align.gapsX', 'room.edit.align.gapsZ', 'room.edit.align.stepBack', 'room.edit.align.stepForward', 'room.edit.align.deleteSelectedObjs',
                        'room.detail.noParamTooltip', 'room.driver.driver', 'room.detail.limit', 'room.edit.toolTitle.rectRoom', 'room.edit.toolTitle.wallOut', 'room.edit.toolTitle.wallIn', 'room.edit.toolTitle.door', 'room.edit.toolTitle.window',
                        'room.edit.toolTitle.pillar', 'room.edit.toolTitle.raisedFloor', 'room.edit.toolTitle.blueprint', 'room.edit.toolTitle.demolish', 'room.edit.layout.selectImageTip', 'room.edit.layout.scaleImageTip',
                        'room.edit.layout.positionImageTip', 'room.edit.toolSettings.pointSelect', 'room.edit.toolSettings.pointAdd', 'room.edit.toolSettings.pointRemove', 'room.edit.toolSettings.grid', 'room.edit.toolSettings.snap']).then(function (trans) {
                        return trans;
                    });
                };

                // endregion

                //region transparency modify
                /**
                 * @description function to modify the render order of containment planes depending on the heatmap position
                 */
                var modifyRenderOrder = function () {
                    HeatMapGLService.getHeatMapPlane().renderOrder = 16;
                    var posY = HeatMapGLService.getHeatMapPlane().position.y;
                    WebGLService.getSceneObject().traverse(function (o) {
                        if (o instanceof THREE.Mesh && (o.material.transparent && o.material.opacity < 1)) {
                            if (o.name === "containment_plane") {
                                if (o.userData.type !== "t") o.renderOrder = 17;
                                if (o.userData.type === "t") {
                                    if (o.position.y < posY) {
                                        o.renderOrder = 20;
                                    } else {
                                        o.renderOrder = 17;
                                    }
                                }
                            }
                        }
                    });
                };

                //endregion

                //region refactor

                /**
                 * @description function to handle opacity for walls when camera is rotated
                 * @param {THREE.Object3D} roomObj the object containing 3d room objects (walls, windows, etc.)
                 * @param {Room} room the room object
                 */
                var hideWalls = function (roomObj, room) {

                    var getIdArray = function (orgArray) {
                        for (var i = 0; i < orgArray.length; i++) orgArray[i] = orgArray[i].id;
                    };

                    var handleWindowsDoorsOfWall = function (ws, ds, opacity) {
                        roomObj.traverse(function (oo) {
                            if ((oo.name === "door" && ds.indexOf(oo.userData.id) !== -1) || (oo.name === "window" && ws.indexOf(oo.userData.id) !== -1)) {
                                oo.material.transparent = opacity < 1;
                                oo.material.opacity = opacity;
                            }
                        });
                    };
                    var camPos = WebGLService.getActiveCamera().position.clone();
                    roomObj.traverse(function (o) {
                        if (o.name === "outerwall") {
                            var windows = angular.copy(room.getWindowsForWall(o));
                            getIdArray(windows);
                            var doors = angular.copy(room.getDoorsForWall(o));
                            getIdArray(doors);
                            var a = room.bbox.getCenter().setY(0).sub(camPos.setY(0)).angleTo(o.userData.normal);
                            if (a < Math.PI / 2) {
                                o.material.transparent = true;
                                o.material.opacity = 0.2;
                                o.renderOrder = 3;
                                handleWindowsDoorsOfWall(windows, doors, o.material.opacity);
                            } else {
                                o.material.opacity = 1.0;
                                o.material.transparent = o.material.opacity < 1;
                                o.renderOrder = 0;
                                handleWindowsDoorsOfWall(windows, doors, o.material.opacity);
                            }
                            windows = null;
                            doors = null;
                            a = null;
                        }
                    });
                    camPos = null;
                };

                /**
                 * @description function builds a 3-dimensional representation of the provided room object
                 * @param {Room} room the room object to build 3-dimensional representation for
                 * @param {string} viewType view/render type for object "full" or "simple"
                 * @param {boolean} onlyEditorObjects flag, if set to true it will only build the basic room without sensors/racks etc.
                 * @returns {THREE.Object3D}
                 */
                var genRoom = function (room, viewType, onlyEditorObjects) {
                    var roomObj = Object3DFactory.buildRoom(room, viewType, onlyEditorObjects);
                    roomObj.position.add(new THREE.Vector3(0, -0.001, 0));
                    WebGLService.add3DObjectToContent(roomObj, room.bbox);
                    return roomObj;
                };

                //endregion

                /**
                 * @description function to set the building id for the provided room
                 * @param {Array} buildings array of mapping information building-room
                 * @param {Room} room the room to set the building id for/at
                 */
                var setRoomBuildingId = function (buildings, room) {
                    for (var i in buildings) {
                        for (var j in buildings[i].rooms) {
                            if (buildings[i].rooms[j].fkRoom === room.id) {
                                room.buildingId = buildings[i].id;
                                return;
                            }
                        }
                    }
                };

                /**
                 * Each object gets a prop wallRatio 0..1 describing relative distance to wall-start
                 * @param wall {object} - the wall to use
                 * @param objects {object[]} - an array of objects located on wall plane
                 */
                var setWallRatios = function (wall, objects) {
                    var wlen = wall instanceof InnerWall ? wall.end.clone().sub(wall.start).length() : wall.e.clone().sub(wall.s).length();
                    var ws = wall instanceof InnerWall ? wall.start.clone() : wall.s.clone();
                    for (var i = 0; i < objects.length; i++) {
                        var posO = new THREE.Vector3(objects[i].pos.x, 0, objects[i].pos.z);
                        var olen = posO.clone().sub(ws).length();
                        objects[i].wallRatio = olen / wlen;
                    }
                };
                /**
                 * Each object gets a distance property len wall-start - object position (y neutral)
                 * @param wall {object} - the wall to use
                 * @param objects {object[]} - an array of objects located on wall plane
                 */
                var setWallDistances = function (wall, objects) {
                    var ws = wall.start.clone();
                    for (var i = 0; i < objects.length; i++) {
                        var posO = new THREE.Vector3(objects[i].pos.x, 0, objects[i].pos.z);
                        var olen = posO.clone().sub(ws).length();
                        objects[i].wallDistance = olen;
                    }
                };

                var removeFocusFromButton = function () {
                    if ($document[0].activeElement) $document[0].activeElement.blur();
                };

                /**
                 * Removes affected windows and doors after outer wall height has changed
                 * @param room {Object} - the room to clean up in
                 */
                var cleanWindowsDoorsOuterWalls = function (room) {
                    var possibleAffectedObjects = room.getOuterWallObjects();
                    var i, ids = [];
                    if (possibleAffectedObjects.length) {
                        for (i = 0; i < possibleAffectedObjects.length; i++) {
                            if (possibleAffectedObjects[i].pos.y + possibleAffectedObjects[i].size.x / 2 >= room.size.y) ids.push(possibleAffectedObjects[i].id);
                        }
                    }
                    for (i = room.roomObjs.length - 1; i >= 0; i--) {
                        if (ids.indexOf(room.roomObjs[i].id) !== -1) {
                            room.roomObjs.splice(i, 1);
                        }
                    }
                };

                /**
                 * Removes affected windows and doors after inner wall height has changed
                 * @param room {Object} - the room to clean up in
                 * @param wall {Object} - the specific inner wall to clean up
                 */
                var cleanWindowsDoorsInnerWalls = function (room, wall) {
                    var objs = room.getWindowsForWall(wall);
                    objs = objs.concat(room.getDoorsForWall(wall));
                    var i, ids = [];
                    angular.forEach(objs, function (obj) {
                        if (obj.pos.y + obj.size.y / 2 >= wall.height) ids.push(obj.id);
                    });
                    for (i = room.roomObjs.length - 1; i >= 0; i--) {
                        if (ids.indexOf(room.roomObjs[i].id) !== -1) {
                            room.roomObjs.splice(i, 1);
                        }
                    }
                };

                /**
                 * Removes affected windows and doors after inner wall is deleted
                 * @param room {Object} - the room to clean up in
                 * @param wall {Object} - the specific inner wall to clean up
                 */

                var deleteAllWindowsDoorsInnerWalls = function (room, wall) {
                    var objs = room.getWindowsForWall(wall);
                    objs = objs.concat(room.getDoorsForWall(wall));
                    var i, ids = [];
                    angular.forEach(objs, function (obj) {
                        ids.push(obj.id);
                    });
                    for (i = room.roomObjs.length - 1; i >= 0; i--) {
                        if (ids.indexOf(room.roomObjs[i].id) !== -1) {
                            room.roomObjs.splice(i, 1);
                        }
                    }
                };

                /**
                 * @description function to check which doors/windows to draw after room height was changed
                 * @param {Room} room the room to clean
                 */
                var shadowCleanDoorsWindows = function (room) {
                    for (var i = 0; i < room.roomObjs.length; i++) {
                        if (!Window.isWindow(room.roomObjs[i].type) && !Door.isDoor(room.roomObjs[i].type)) continue;
                        if (room.roomObjs[i].pos.y + room.roomObjs[i].size.y / 2 >= room.size.y) {
                            room.roomObjs[i].shadowDeleted = true;
                        } else {
                            room.roomObjs[i].shadowDeleted = false;
                        }
                    }
                };

                // region messages

                /**
                 * @description Show dialog asking user to confirm invalidation if room object by deleting outer wall
                 * @param {function} callback0 callback function for cancel button
                 * @param {function} callback1 callback function for delete button
                 */
                var showRemoveWallInvalidRoomDialog = function (callback0, callback1) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.removeOuterWallQuestion', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.removeOuterWallQuestion'],
                            showClose: true,
                            textButton1: trans['global.btn.delete'],
                            classButton1: 'btn-delete',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                callback1();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                callback0();
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showDoorWindowRemoveDialog = function (callback0, callback1) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.modifyDoorWindowMessage', 'global.btn.yes', 'global.btn.no']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.modifyDoorWindowMessage'],
                            showClose: false,
                            textButton1: trans['global.btn.yes'],
                            classButton1: 'btn-add',
                            iconButton1: 'glyphicon glyphicon-ok',
                            textButton0: trans['global.btn.no'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                callback1();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                callback0();
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };
                var showChangeRaisedFloorOffsetDialog = function (callback) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.changeRaisedFloorMessage', 'global.btn.ok', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.changeRaisedFloorMessage'],
                            showClose: true,
                            textButton1: trans['global.btn.ok'],
                            classButton1: 'btn-add',
                            iconButton1: 'glyphicon glyphicon-ok',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                callback();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showChangeRaisedFloorHeightDialog = function (callback) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.changeRaisedFloorMessage', 'global.btn.ok', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.changeRaisedFloorMessage'],
                            showClose: true,
                            textButton1: trans['global.btn.ok'],
                            classButton1: 'btn-add',
                            iconButton1: 'glyphicon glyphicon-ok',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                callback();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showAddRaisedFloorDialog = function (callback) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.addRaisedFloorMessage', 'global.btn.ok', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.addRaisedFloorMessage'],
                            showClose: true,
                            textButton1: trans['global.btn.ok'],
                            classButton1: 'btn-add',
                            iconButton1: 'glyphicon glyphicon-ok',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                callback();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showRemoveRaisedFloorDialog = function (callback) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.removeRaisedFloorMessage', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.removeRaisedFloorMessage'],
                            showClose: true,
                            textButton1: trans['global.btn.delete'],
                            classButton1: 'btn-delete',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                callback();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showNoTempHeatmapsFoundDialogNoAdmin = function () {
                    $translate(['global.dialog.head.warning', 'room.heatmapErrorDialog.messageNoTempHeatmapsNoAdmin', 'room.heatmapErrorDialog.btnJumpToHeatmapCreate', 'room.heatmapErrorDialog.btnUseDefaults']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.heatmapErrorDialog.messageNoTempHeatmapsNoAdmin'],
                            showClose: true,
                            textButton0: trans['room.heatmapErrorDialog.btnUseDefaults'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };
                var showNoTempHeatmapsFoundDialogAdmin = function () {
                    $translate(['global.dialog.head.warning', 'room.heatmapErrorDialog.messageNoTempHeatmapsAdmin', 'room.heatmapErrorDialog.btnJumpToHeatmapCreate', 'room.heatmapErrorDialog.btnUseDefaults']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.heatmapErrorDialog.messageNoTempHeatmapsAdmin'],
                            showClose: true,
                            textButton1: trans['room.heatmapErrorDialog.btnJumpToHeatmapCreate'],
                            classButton1: 'btn-create',
                            iconButton1: 'glyphicon glyphicon-share-alt',
                            textButton0: trans['room.heatmapErrorDialog.btnUseDefaults'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                $state.go("heatmap");
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showNoHeatmapsFoundDialogAdmin = function () {
                    $translate(['global.dialog.head.warning', 'room.heatmapErrorDialog.messageNoHeatmapsAdmin', 'room.heatmapErrorDialog.btnJumpToHeatmapCreate', 'room.heatmapErrorDialog.btnUseDefaults']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.heatmapErrorDialog.messageNoHeatmapsAdmin'],
                            showClose: true,
                            textButton1: trans['room.heatmapErrorDialog.btnJumpToHeatmapCreate'],
                            classButton1: 'btn-create',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['room.heatmapErrorDialog.btnUseDefaults'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                $state.go("heatmap");
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showNoHeatmapsFoundDialogNoAdmin = function () {
                    $translate(['global.dialog.head.warning', 'room.heatmapErrorDialog.messageNoHeatmapsNoAdmin', 'room.heatmapErrorDialog.btnJumpToHeatmapCreate', 'room.heatmapErrorDialog.btnUseDefaults']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.heatmapErrorDialog.messageNoHeatmapsNoAdmin'],
                            showClose: true,
                            textButton0: trans['room.heatmapErrorDialog.btnUseDefaults'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showSaveErrorDialog = function (message) {
                    if (message === undefined || message === null || message === "") message = "room.edit.dialog.messages.saveError";
                    $translate(['global.dialog.head.warning', message, 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans[message] + "<br/><br/>",
                            showClose: true,
                            textButton0: trans['global.btn.ok'],
                            iconButton0: 'glyphicon glyphicon-ok',
                            classButton0: 'btn-default',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showSaveErrorInfoDialog = function (message, listOfInvalidItemNames) {
                    if (message === undefined || message === null || message === "") message = "room.edit.dialog.messages.saveError";
                    var invalidItemsString = "";
                    for (var i = 0; i < listOfInvalidItemNames.length; i++) {
                        if (i < listOfInvalidItemNames.length - 1) {
                            invalidItemsString = invalidItemsString.concat(listOfInvalidItemNames[i] + ", ");
                        } else {
                            invalidItemsString = invalidItemsString.concat(listOfInvalidItemNames[i]);
                        }
                    }
                    $translate(['global.dialog.head.warning', message, 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans[message] + "<br/><br/>" + invalidItemsString + "<br/><br/>",
                            showClose: true,
                            textButton0: trans['global.btn.ok'],
                            iconButton0: 'glyphicon glyphicon-ok',
                            classButton0: 'btn-default',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showErrorLoadImageDialog = function () {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.errorLoadImage', 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans['room.edit.dialog.messages.errorLoadImage'],
                            showClose: true,
                            textButton0: trans['global.btn.ok'],
                            iconButton0: 'glyphicon glyphicon-ok',
                            classButton0: 'btn-default',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showCollideDialog = function (callbackYes, callbackNo) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.collideQ', 'global.btn.yes', 'global.btn.no']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans['room.edit.dialog.messages.collideQ'],
                            showClose: false,
                            textButton0: trans['global.btn.yes'],
                            iconButton0: 'glyphicon glyphicon-ok',
                            classButton0: 'btn-default',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                                if (callbackYes) callbackYes();
                            },
                            textButton1: trans['global.btn.no'],
                            iconButton1: 'glyphicon glyphicon-remove',
                            classButton1: 'btn-default',
                            callbackButton1: function () {
                                GenDialogService.hideDialog();
                                if (callbackNo) callbackNo();
                            }
                        });
                    });
                };

                var showSlotCollideDialog = function (callbackYes, callbackNo) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.slotCollision', 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans['room.edit.dialog.messages.slotCollision'],
                            showClose: false,
                            textButton1: trans['global.btn.ok'],
                            iconButton1: 'glyphicon glyphicon-ok',
                            classButton1: 'btn-default',
                            callbackButton1: function () {
                                GenDialogService.hideDialog();
                                if (callbackNo) callbackNo();
                            }
                        });
                    });
                };

                var showSlotPositionCollideDialog = function (callbackYes, callbackNo) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.invalidPosition', 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans['room.edit.dialog.messages.invalidPosition'],
                            showClose: false,
                            textButton1: trans['global.btn.ok'],
                            iconButton1: 'glyphicon glyphicon-ok',
                            classButton1: 'btn-default',
                            callbackButton1: function () {
                                GenDialogService.hideDialog();
                                if (callbackNo) callbackNo();
                            }
                        });
                    });
                };

                var showDoorWindowNotEnoughSpaceDialog = function () {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.notEnoughSpace', 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(true, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: "glyphicon glyphicon-warning-sign",
                            messageText: trans['room.edit.dialog.messages.notEnoughSpace'],
                            showClose: true,
                            textButton0: trans['global.btn.ok'],
                            iconButton0: 'glyphicon glyphicon-ok',
                            classButton0: 'btn-default',
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showDeleteObjectDialog = function (obj, deleteCallback, cancelCallback) {
                    if (!obj) return;
                    var limitText = "";
                    if (Tools.isDefinedNotNull(obj.driverValues) && obj.driverValues !== undefined) {
                        for (var i = 0; i < obj.driverValues.length; i++) {
                            var limit = "";
                            var increment = 1;
                            if (Tools.isDefinedNotNull(obj.driverValues[i].limits) && obj.driverValues[i].limits !== undefined) {
                                for (var j = 0; j < obj.driverValues[i].limits.length; j++) {
                                    limit += increment + ". " + obj.driverValues[i].limits[j].name + '<br/>';
                                    increment++;
                                }
                                if (limit !== "") {
                                    limitText += $translate.instant(obj.driverValues[i].name) + '<br/>' + limit + '<br/>';
                                }
                            }
                        }
                    }
                    if (limitText !== "") {
                        limitText = $translate.instant('room.edit.dialog.messages.deleteObjectWithLimit') + '<br/>' + limitText;
                    }
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.deleteObject', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.deleteObject'].replace("%obj", obj.name) + '<br/><br/>' + limitText,
                            showClose: true,
                            textButton1: trans['global.btn.delete'],
                            classButton1: 'btn-delete',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                if (deleteCallback) deleteCallback();
                            },
                            callbackButton0: function () {
                                if (cancelCallback) cancelCallback();
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showDeleteSelectedObjects = function (room, deleteCallback, cancelCallback) {
                    if (!room) return;
                    var entityText = "";
                    if (selectedObjects && selectedObjects.length > 0) {
                        var increment = 1;
                        for (var i = 0; i < selectedObjects.length; i++) {
                            var currentObject = room.findObjectByUniqueID(selectedObjects[i].userData.uid);
                            entityText += increment + ". " + currentObject.name + '<br/>';
                            increment++;
                        }
                    }
                    if (entityText !== "") {
                        entityText = $translate.instant('room.edit.dialog.messages.deleteListObjects') + '<br/>' + entityText;
                    }
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.deleteSelectedObjects', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicom-warning-sign',
                            messageText: trans['room.edit.dialog.messages.deleteSelectedObjects'] + '<br/><br/>' + entityText,
                            showClose: true,
                            textButton1: trans['global.btn.delete'],
                            classButton1: 'btn-delete',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                if (deleteCallback) deleteCallback();
                            },
                            callbackButton0: function () {
                                if (cancelCallback) cancelCallback();
                                GenDialogService.hideDialog();
                            }
                        });
                    });

                };

                /**
                 * @description function to show dialog if wall height was changed and windows/doors would have to be deleted
                 * @param {function} deleteCallback function to call if user hits the delete button
                 * @param {function} cancelCallback function to call if user hits the cancel button
                 */
                var showHeightChangeWindowDoorDeleteDialog = function (deleteCallback, cancelCallback) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.deleteWindowDoorWallHeight', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicon-warning-sign',
                            messageText: trans['room.edit.dialog.messages.deleteWindowDoorWallHeight'],
                            showClose: false,
                            textButton1: trans['global.btn.delete'],
                            classButton1: 'btn-delete',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                if (deleteCallback) deleteCallback();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                if (cancelCallback) cancelCallback();
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                /**
                 * @description function to show dialog if  a inner wall is deleted and windows/doors will be deleted aswell
                 * @param {function} deleteCallback function to call if user hits the delete button
                 * @param {function} cancelCallback function to call if user hits the cancel button
                 */
                var showInnerWalleWindowDoorDeleteDialog = function (deleteCallback, cancelCallback) {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.deleteWindowDoorWallHeight', 'global.btn.delete', 'global.btn.cancel']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicon-warning-sign',
                            messageText: trans['room.edit.dialog.messages.deleteWindowDoorWallHeight'],
                            showClose: false,
                            textButton1: trans['global.btn.delete'],
                            classButton1: 'btn-delete',
                            iconButton1: 'glyphicon glyphicon-remove-circle',
                            textButton0: trans['global.btn.cancel'],
                            classButton0: 'btn-default',
                            iconButton0: 'glyphicon glyphicon-remove',
                            callbackButton1: function () {
                                if (deleteCallback) deleteCallback();
                                GenDialogService.hideDialog();
                            },
                            callbackButton0: function () {
                                if (cancelCallback) cancelCallback();
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                var showOpenInvalidRoomDialog = function () {
                    $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.openInvalidRoom', 'global.btn.ok']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicon-warning-sign',
                            messageText: trans['room.edit.dialog.messages.openInvalidRoom'],
                            showClose: false,
                            textButton1: trans['global.btn.ok'],
                            classButton1: 'btn-default',
                            iconButton1: 'glyphicon glyphicon-ok',
                            callbackButton1: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                };

                /**
                 * @description Creates a outer wall array and if defined updates the provided room object
                 * @param minPoint point (x,y,z) describing minimal point in x-z plane
                 * @param width width in meters (x-axis)
                 * @param depth depth in meters (z-axis)
                 * @param room room object optional if defined corner and outerWall properties will be updated with new information
                 */
                var buildOuterWallArrayFromMinPointWidthDepth = function (minPoint, width, depth, room) {
                    var corner = [{x: minPoint.x, y: 0, z: minPoint.z}, {x: minPoint.x, y: 0, z: minPoint.z + depth},
                        {x: minPoint.x + width, y: 0, z: minPoint.z + depth}, {x: minPoint.x + width, y: 0, z: minPoint.z}];
                    var outerWalls = Room.buildOuterWallArrayFromRoomCorners({
                        corner: corner,
                        thickness: room.thickness,
                        size: {y: room.size.y}
                    });
                    if (room !== undefined && room !== null && room instanceof Room) {
                        room.corner = corner;
                        room.outerWalls = outerWalls;
                    }
                    return outerWalls;
                };

                var handleChangeConnectingWallLoop = function (wall, wallArray) {
                    var connectedWall = findConnectedWall(wall, wallArray);
                    if (connectedWall.length >= 1) {
                        if (wall.start.equals(connectedWall[0].start) || wall.end.equals(connectedWall[0].end)) {
                            var t = wall.start.clone();
                            wall.start.copy(wall.end);
                            wall.end.copy(t);
                        }
                    }
                };
                /**
                 * @description function to set the x,y,z components of the specified property name from a vector
                 * @param {object} entity object whose property should be modified
                 * @param {string} propName name of the property
                 * @param {object} vec vector object with x,y,z component
                 */
                var setEntityPropertyXYZFromVec = function (entity, propName, vec) {
                    if (entity.hasOwnProperty(propName) &&
                        entity[propName].hasOwnProperty('x') &&
                        entity[propName].hasOwnProperty('y') &&
                        entity[propName].hasOwnProperty('z')) {
                        entity[propName].x = vec.x;
                        entity[propName].y = vec.y;
                        entity[propName].z = vec.z;
                    }
                };

                /**
                 * @description function to build a rack object from drag/drop info obj
                 * @param {object} infoObj drag/drop (size, etc)
                 * @param {THREE.Vector3} position 3-dimnesional position for the new object
                 * @param {number} raisedFloorHeight height of the 'current' room
                 * @param {number} roomId the room id to set
                 * @returns {Rack}
                 */
                var buildRackFromInfoObj = function (infoObj, position, raisedFloorHeight, roomId, deepCopy) {
                    if (infoObj instanceof Rack) {
                        return new Rack(-1, {
                                x: position.x,
                                y: infoObj.size.y / 2 + raisedFloorHeight,
                                z: position.z
                            }, angular.copy(infoObj.size),
                            {
                                x: 0,
                                y: infoObj.rot.y,
                                z: 0
                            }, infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, infoObj.type,
                            infoObj.heightUnits, deepCopy ? infoObj.slots : [], undefined, infoObj.partLibraryRackId, infoObj.url, Entity.getNewLocaleUniqueId(), roomId, infoObj.weight, infoObj.maxWeight, infoObj.totalWeight, infoObj.consumption, deepCopy);
                    }

                    return new Rack(-1,
                        {x: position.x, y: infoObj.height / 2 + raisedFloorHeight, z: position.z},
                        {x: infoObj.width, y: infoObj.height, z: infoObj.depth}, {x: 0, y: 0, z: 0},
                        infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, 1, infoObj.heightUnits, [], undefined, infoObj.id, '', Entity.getNewLocaleUniqueId(), roomId);
                };

                /**
                 * @description function to build a sensor object from drag/drop info obj
                 * @param {object} infoObj drag/drop info object (size, etc)
                 * @param {THREE.Vector3} position 3-dimensional position for the new object
                 * @param {number} roomHeight the height of the room
                 * @param {number} roomId the room id to set
                 * @returns {Sensor}
                 */
                var buildSensorFromInfoObj = function (infoObj, position, roomHeight, roomId, deepCopy) {
                    if (infoObj instanceof Sensor) {
                        return new Sensor(-1, {
                                x: position.x,
                                y: infoObj.pos.y,
                                z: position.z
                            }, angular.copy(infoObj.size), {x: 0, y: infoObj.rot.y, z: 0},
                            infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, undefined, infoObj.url, undefined, infoObj.partLibrarySensorId, null, roomId, deepCopy ? infoObj.driverValues : null);
                    }
                    return new Sensor(-1, {x: position.x, y: roomHeight - 0.05, z: position.z}, {x: 0.01, y: 0.01, z: 0.01},
                        {
                            x: 0,
                            y: 0,
                            z: 0
                        }, infoObj.name, infoObj.comment, "", infoObj.serialNumber, undefined, '', undefined, infoObj.id, null, roomId);
                };

                /**
                 * @description function to build a cooling object from drag/drop info
                 * @param {object} infoObj drag/drop info object (size, etc)
                 * @param {THREE.Vector3} position 3-dimensional position for the new object
                 * @param {number} raisedFloorHeight height of raisedFloor in 'current' room
                 * @param {number} roomId the room id to set
                 * @returns {Cooling}
                 */
                var buildCoolingFromInfoObj = function (infoObj, position, raisedFloorHeight, roomId, deepCopy) {
                    if (infoObj instanceof Cooling) {
                        return new Cooling(-1, {
                                x: position.x,
                                y: infoObj.size.y / 2 + raisedFloorHeight,
                                z: position.z
                            }, angular.copy(infoObj.size),
                            {
                                x: 0,
                                y: infoObj.rot.y,
                                z: 0
                            }, infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, undefined, infoObj.url,
                            infoObj.airFlowDirection, infoObj.type, infoObj.alertConfigType, infoObj.maxPower, infoObj.maxCooling, undefined, infoObj.partLibraryCoolingId, null, roomId, deepCopy ? infoObj.driverValues : null);
                    }

                    var obj = new Cooling(-1, {x: position.x, y: raisedFloorHeight + infoObj.height / 2, z: position.z},
                        {x: infoObj.width, y: infoObj.height, z: infoObj.depth}, {x: 0, y: 0, z: 0},
                        infoObj.name, infoObj.comment, "", infoObj.serialNumber, "", "", "", "", "", "", "", undefined, infoObj.id, null, roomId);
                    if (infoObj.stulzInfo !== null) {
                        obj.type = infoObj.model === "CyberRow" ? 3 : 2;
                        obj.airFlowDirection = getAirFlowDirectionFromPartInfo(infoObj.stulzInfo.airDirection);
                        obj.maxCooling = infoObj.stulzInfo.refridgeCap;
                    } else if (infoObj.maxCooling !== null && infoObj.airDirection !== null) {
                        obj.maxCooling = infoObj.maxCooling;
                        obj.airFlowDirection = infoObj.airDirection;
                    } else {
                        obj.type = 0;
                    }
                    return obj;
                };

                /**
                 * @description function to build a asset ffrom drag/drop info
                 * @param {object} infoObj drag/drop info object (size, etc)
                 * @param {THREE.Vector3} position 3-dimensional position for the new object
                 * @param {number} raisedFloorHeight height of raisedFloor in 'current' room
                 * @param {number} roomId the room id to set
                 * @returns {Asset}
                 */
                var buildAssetFromInfoObj = function (infoObj, position, raisedFloorHeight, roomId, deepCopy) {
                    if (infoObj instanceof Asset) {
                        return new Asset(-1, {
                                x: position.x,
                                y: infoObj.size.y / 2 + raisedFloorHeight,
                                z: position.z
                            }, angular.copy(infoObj.size),
                            {
                                x: 0,
                                y: infoObj.rot.y,
                                z: 0
                            }, infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, undefined, infoObj.url, undefined, infoObj.partLibraryAssetId, null, roomId, deepCopy ? infoObj.driverValues : null);
                    } else {
                        return new Asset(-1, {x: position.x, y: infoObj.height / 2 + raisedFloorHeight, z: position.z},
                            {x: infoObj.width, y: infoObj.height, z: infoObj.depth}, {x: 0, y: 0, z: 0},
                            infoObj.name, infoObj.comment, "", infoObj.serialNumber, undefined, "", undefined, infoObj.id, null, roomId);
                    }
                };

                /**
                 * @description function to build a floor tile object from drag/drop info
                 * @param {object} infoObj drag/drop info object (size, etc)
                 * @param {THREE.Vector3} position 3-dimensional position for the new object
                 * @param {number} raisedFloorHeight height of raisedFloor in 'current' room
                 * @param {number} roomId the room id to set
                 * @returns {FloorTile}
                 */
                var buildFloorTileFromInfoObj = function (infoObj, position, raisedFloorHeight, roomId, deepCopy) {
                    if (infoObj instanceof FloorTile) {
                        return new FloorTile(-1, {
                                x: position.x,
                                y: infoObj.size.y / 2 + raisedFloorHeight,
                                z: position.z
                            }, angular.copy(infoObj.size),
                            {
                                x: 0,
                                y: infoObj.rot.y,
                                z: 0
                            }, infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, undefined, infoObj.partLibraryFloorPanelId, false, null, roomId, deepCopy ? infoObj.driverValues : null, infoObj.url);
                    }
                    return new FloorTile(-1, {x: 0, y: raisedFloorHeight, z: 0},
                        {x: infoObj.width, y: infoObj.height, z: infoObj.depth}, {x: 0, y: 0, z: 0},
                        infoObj.name, infoObj.comment, "", infoObj.serialNumber, undefined, infoObj.id, false, null, roomId, undefined, "");
                };

                /**
                 * @description function to build a ups object from drag/drop info
                 * @param {object} infoObj drag/drop info object (size, etc)
                 * @param {THREE.Vector3} position 3-dimensional position for the new object
                 * @param {number} raisedFloorHeight height of raisedFloor in 'current' room
                 * @param {number} roomId the room id to set
                 * @returns {Ups}
                 */
                var buildUpsFromInfoObj = function (infoObj, position, raisedFloorHeight, roomId, deepCopy) {
                    if (infoObj instanceof Ups) {
                        return new Ups(-1, {
                                x: position.x,
                                y: infoObj.size.y / 2 + raisedFloorHeight,
                                z: position.z
                            }, angular.copy(infoObj.size),
                            {
                                x: 0,
                                y: infoObj.rot.y,
                                z: 0
                            }, infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, "", infoObj.url, 1, undefined, infoObj.partLibraryUsvId, null, roomId, deepCopy ? infoObj.driverValues : null);
                    }
                    return new Ups(-1, {x: position.x, y: infoObj.height / 2 + raisedFloorHeight, z: position.z},
                        {x: infoObj.width, y: infoObj.height, z: infoObj.depth}, {x: 0, y: 0, z: 0},
                        infoObj.name, infoObj.comment, infoObj.inventoryNumber, infoObj.serialNumber, "", infoObj.url, 1, undefined, infoObj.id, null, roomId);
                };

                /**
                 * @description function to build a slot object from drag/drop info
                 * @param {object} infoObj
                 * @param {number} rackId
                 * @returns {Slot}
                 */
                var buildSlotFromInfoObj = function (infoObj, rackId, deepCopy) {
                    if (infoObj instanceof Slot) {
                        return new Slot(-1, {x: 0, y: 0, z: 0}, angular.copy(infoObj.size), {x: 0, y: infoObj.rot.y, z: 0},
                            infoObj.name, infoObj.comment, infoObj.url, infoObj.inventoryNumber, infoObj.serialNumber, infoObj.type, infoObj.direction,
                            infoObj.bladeRow, infoObj.bladeCol, deepCopy ? infoObj.blades : undefined, infoObj.partlibrarySlotId, rackId, null, infoObj.weight, infoObj.consumption, deepCopy ? infoObj.driverValues : null, deepCopy);
                    }
                    var obj = new Slot(-1, {x: 0, y: 0, z: 0}, {
                            x: infoObj.width,
                            y: infoObj.height,
                            z: infoObj.depth
                        }, {x: 0, y: 0, z: 0},
                        infoObj.name, infoObj.comment, "", "", infoObj.serialNumber, infoObj.type, 0, 1, 1, undefined, infoObj.id, null, rackId);
                    if (obj.type === 3) {
                        obj.bladeCol = 8;
                        obj.bladeRow = 3;
                    }
                    return obj;
                };

                /**
                 * @description Function to update height properties of all outer walls according height value of the provided room
                 * @param {Room} room the room object to modify
                 */
                var updateOuterWallHeight = function (room) {
                    for (var i = 0; i < room.outerWalls.length; i++) room.outerWalls[i].height = room.size.y;
                };

                /**
                 * @description Function to update thickness properties of all outer walls according thickness value of the provided room
                 * @param {Room} room the room object to modify
                 */
                var updateOuterWallThickness = function (room) {
                    for (var i = 0; i < room.outerWalls.length; i++) room.outerWalls[i].thickness = room.thickness;
                };

                /**
                 * @description Function to update height properties of all pillars according to the height value of the provided room
                 * @param {Room} room the room object to modify pillars
                 */
                var updatePillarHeight = function (room) {
                    for (var i = 0; i < room.roomObjs.length; i++) {
                        if (Pillar.isPillar(room.roomObjs[i].type)) {
                            room.roomObjs[i].size.y = room.size.y;
                            room.roomObjs[i].pos.y = room.size.y / 2;
                        }
                    }
                };

                /**
                 * @description Function to update height of inner walls to fit into the provided room (after height change)
                 * @param {Room} room the room object to modify inner walls
                 */
                var updateInnerWallHeight = function (room) {
                    var raisedFloor = room.getRaisedFloor();
                    var maxHeight = null;
                    var raisedFloorHeight = raisedFloor !== null ? room.getRaisedFloor().size.y : 0;
                    for (var i = 0; i < room.innerWalls.length; i++) {
                        if (room.innerWalls[i].posType === 3) {
                            if (room.innerWalls[i].height + raisedFloorHeight > room.size.y) {
                                room.innerWalls[i].height = room.size.y - raisedFloorHeight;
                            } else {
                                maxHeight = getMaxHeightForInnerWall(room.innerWalls[i].id);
                                if (maxHeight !== null) {
                                    if (maxHeight + raisedFloorHeight > room.size.y) {
                                        room.innerWalls[i].height = room.size.y - raisedFloorHeight;
                                    } else {
                                        room.innerWalls[i].height = maxHeight;
                                    }
                                }
                            }
                        }
                        if (room.innerWalls[i].posType === 1) {
                            if (room.innerWalls[i].height > room.size.y) {
                                room.innerWalls[i].height = room.size.y;
                            } else {
                                maxHeight = getMaxHeightForInnerWall(room.innerWalls[i].id);
                                if (maxHeight !== null) {
                                    if (maxHeight > room.size.y) {
                                        room.innerWalls[i].height = room.size.y;
                                    } else {
                                        room.innerWalls[i].height = maxHeight;
                                    }
                                }
                            }
                        }
                    }
                };

                //array to hold height change info
                var heightChanges = [];

                /**
                 * @description Function to genetate a object describing the rooms state (height, inner wall info)
                 * @param {Room} room the room object to generate info object for
                 * @returns {{roomHeight: *, innerWallsInfo: Array}}
                 */
                var generateHeightChangeInfo = function (room) {
                    var infoObj = {
                        roomHeight: room.size.y,
                        wallObjectToDelete: 0,
                        innerWallsInfo: []
                    };
                    var i;
                    for (i = 0; i < room.innerWalls.length; i++) {
                        infoObj.innerWallsInfo.push(getInnerWallInfo(room.innerWalls[i]));
                    }
                    for (i = 0; i < room.roomObjs.length; i++) {
                        if (room.roomObjs[i].shadowDeleted) infoObj.wallObjectToDelete++;
                    }

                    heightChanges.push(infoObj);
                };

                /**
                 * @description Function to create a info object for inner walls
                 * @param {InnerWall} innerWall the inner wall to create a info for
                 * @returns {{id: *, height: *}}
                 */
                var getInnerWallInfo = function (innerWall) {
                    return {id: innerWall.id, height: innerWall.height};
                };

                /**
                 * @description Function to get maxHeight from height change objects for inner wall with provided id
                 * @param {number} innerWallId the inner wall id to use
                 * @returns {number} returns maximal height for inner wall with provided id, if nothing is found -1 will be returned
                 */
                var getMaxHeightForInnerWall = function (innerWallId) {
                    var maxHeight = null;
                    for (var i = 0; i < heightChanges.length; i++) {
                        for (var j = 0; j < heightChanges[i].innerWallsInfo.length; j++) {
                            if (heightChanges[i].innerWallsInfo[j].id !== innerWallId) continue;
                            if (heightChanges[i].innerWallsInfo[j].height > maxHeight) maxHeight = heightChanges[i].innerWallsInfo[j].height;
                        }
                    }
                    return maxHeight;
                };

                /**
                 * @description Function to clear height change info object
                 */
                var clearHeightChanges = function () {
                    heightChanges = [];
                };

                /**
                 * @description Function to get count of height change info objects
                 * @returns {number} returns number of height change info objects
                 */
                var getHeightChangeInfoLength = function () {
                    return heightChanges.length;
                };

                /**
                 * @description Function to get height change info objects
                 * @returns {Array} returns array of height change objects
                 */
                var getHeightChangeInfoObjects = function () {
                    return heightChanges;
                };

                /**
                 * @description Function to find last height change info data without deleted windows/doors
                 * @returns {*} returns info object if possible, otherwise null
                 */
                var findLastHeightChangeInfoObjectWithoutShadowDeletedObjects = function () {
                    for (var i = heightChanges.length - 1; i >= 0; i--) {
                        if (heightChanges[i].wallObjectToDelete === 0) return heightChanges[i];
                    }
                    return null;
                };

                /**
                 * @description function to count room entity quantity for license restriction
                 * @param {Room} room the room object to check against
                 * @param {THREE.Object3D} room3DObj the object containing the 3-dimensional representation of the room
                 * @returns {number} returns number of entities
                 */
                var checkEntityQuantity = function (room) {
                    var assets = 0;
                    var coolings = 0;
                    var ups = 0;
                    var sensors = 0;
                    var racks = 0;
                    var floorTiles = 0;

                    if (Tools.isDefinedNotNull(room.assets)) {
                        assets = room.assets.length;
                    }
                    if (Tools.isDefinedNotNull(room.coolings)) {
                        coolings = room.coolings.length;
                    }
                    if (Tools.isDefinedNotNull(room.ups)) {
                        ups = room.ups.length;
                    }
                    if (Tools.isDefinedNotNull(room.sensors)) {
                        sensors = room.sensors.length;
                    }
                    if (Tools.isDefinedNotNull(room.racks)) {
                        racks = room.racks.length;
                    }
                    if (Tools.isDefinedNotNull(room.floorTiles)) {
                        floorTiles = room.floorTiles.length;
                    }
                    return (assets + coolings + ups + sensors + racks + floorTiles);
                };

                return {
                    redrawWalls: redrawWalls,
                    get3DOuterWall: get3DOuterWall,
                    getIndexOfRoomObj: getIndexOfRoomObj,
                    rebuildObjectsOnWall: rebuildObjectsOnWall,
                    buildDummyFloor: buildDummyFloor,
                    buildDummyCurrentObjs: buildDummyCurrentObjs,
                    removeDummyCurrentObjs: removeDummyCurrentObjs,
                    checkWallIntersection: checkWallIntersection,
                    mergeWall: mergeWall,
                    splitWall: splitWall,
                    splitInnerWall: splitInnerWall,
                    findWallByPoints: findWallByPoints,
                    getStdLimits: getStdLimits,
                    getAirFlowDirections: getAirFlowDirections,
                    getAirFlowDirectionFromPartInfo: getAirFlowDirectionFromPartInfo,
                    findBackObjectForSlot: findBackObjectForSlot,
                    checkItemAlreadySelected: checkItemAlreadySelected,
                    markingHelper: markingHelper,
                    alignObjects: alignObjects,
                    closeGaps: closeGaps,
                    clearSteps: clearSteps,
                    getStepLength: getStepLength,
                    getCurrentStep: getCurrentStep,
                    addStep: addStep,
                    removeObjectFromSelection: removeObjectFromSelection,
                    addObjectToSelection: addObjectToSelection,
                    getSelectedObjectsLength: getSelectedObjectsLength,
                    getSelectedObjects: getSelectedObjects,
                    clearSelectedObjects: clearSelectedObjects,
                    alignBack: undoStep,
                    alignForward: redoStep,
                    getVisibilityOpts: getVisibilityOpts,
                    updateVisibility: updateVisibility,
                    updateAllVisibilityAndOpacity: updateAllVisibilityAndOpacity,
                    updateOpacity: updateOpacity,
                    addSensorOverlay: addSensorOverlay,
                    removeSensorOverlay: removeSensorOverlay,
                    findContainmentForObjects: findContainmentForObjects,
                    projectOnRay: projectOnRay,
                    handleMarkRectMoverChange: handleMarkRectMoverChange,
                    checkSnapMarkRectMover: checkSnapMarkRectMover,
                    getSizeForMarkedRect: getSizeForMarkedRect,
                    getPositionMarkedRect: getPositionMarkedRect,
                    disableFullScreenMode: disableFullScreenMode,
                    enableFullScreenMode: enableFullScreenMode,
                    updateURLForObject: updateURLForObject,
                    checkAllObjectsWithinRoom: checkAllObjectsWithinRoom,
                    getTranslations: getTranslations,
                    hideWalls: hideWalls,
                    findObjectsOnRemovedRaisedFloor: findObjectsOnRemovedRaisedFloor,
                    findObjectsInNewRaisedFloor: findObjectsInNewRaisedFloor,
                    genRoom: genRoom,
                    setRoomBuildingId: setRoomBuildingId,
                    modifyRenderOrder: modifyRenderOrder,
                    setWallRatios: setWallRatios,
                    setWallDistances: setWallDistances,
                    removeFocusFromButton: removeFocusFromButton,
                    cleanWindowsDoorsOuterWalls: cleanWindowsDoorsOuterWalls,
                    cleanWindowsDoorsInnerWalls: cleanWindowsDoorsInnerWalls,
                    showRemoveWallInvalidRoomDialog: showRemoveWallInvalidRoomDialog,
                    showDoorWindowRemoveDialog: showDoorWindowRemoveDialog,
                    showChangeRaisedFloorOffsetDialog: showChangeRaisedFloorOffsetDialog,
                    showChangeRaisedFloorHeightDialog: showChangeRaisedFloorHeightDialog,
                    showAddRaisedFloorDialog: showAddRaisedFloorDialog,
                    showRemoveRaisedFloorDialog: showRemoveRaisedFloorDialog,
                    showNoTempHeatmapsFoundDialogNoAdmin: showNoTempHeatmapsFoundDialogNoAdmin,
                    showNoTempHeatmapsFoundDialogAdmin: showNoTempHeatmapsFoundDialogAdmin,
                    showNoHeatmapsFoundDialogNoAdmin: showNoHeatmapsFoundDialogNoAdmin,
                    showNoHeatmapsFoundDialogAdmin: showNoHeatmapsFoundDialogAdmin,
                    showSaveErrorDialog: showSaveErrorDialog,
                    showSaveErrorInfoDialog: showSaveErrorInfoDialog,
                    showErrorLoadImageDialog: showErrorLoadImageDialog,
                    showCollideDialog: showCollideDialog,
                    showSlotCollideDialog: showSlotCollideDialog,
                    showSlotPositionCollideDialog: showSlotPositionCollideDialog,
                    showDoorWindowNotEnoughSpaceDialog: showDoorWindowNotEnoughSpaceDialog,
                    showDeleteObjectDialog: showDeleteObjectDialog,
                    showDeleteSelectedObjects: showDeleteSelectedObjects,
                    showHeightChangeWindowDoorDeleteDialog: showHeightChangeWindowDoorDeleteDialog,
                    showInnerWalleWindowDoorDeleteDialog: showInnerWalleWindowDoorDeleteDialog,
                    deleteAllWindowsDoorsInnerWalls: deleteAllWindowsDoorsInnerWalls,
                    showOpenInvalidRoomDialog: showOpenInvalidRoomDialog,
                    buildOuterWallArrayFromMinPointWidthDepth: buildOuterWallArrayFromMinPointWidthDepth,
                    addFlatWallToScene: addFlatWallToScene,
                    handleChangeConnectingWallLoop: handleChangeConnectingWallLoop,
                    setEntityPropertyXYZFromVec: setEntityPropertyXYZFromVec,
                    buildRackFromInfoObj: buildRackFromInfoObj,
                    buildSensorFromInfoObj: buildSensorFromInfoObj,
                    buildCoolingFromInfoObj: buildCoolingFromInfoObj,
                    buildAssetFromInfoObj: buildAssetFromInfoObj,
                    buildFloorTileFromInfoObj: buildFloorTileFromInfoObj,
                    buildUpsFromInfoObj: buildUpsFromInfoObj,
                    buildSlotFromInfoObj: buildSlotFromInfoObj,
                    updateOuterWallHeight: updateOuterWallHeight,
                    updateOuterWallThickness: updateOuterWallThickness,
                    shadowCleanDoorsWindows: shadowCleanDoorsWindows,
                    updatePillarHeight: updatePillarHeight,
                    updateInnerWallHeight: updateInnerWallHeight,
                    generateHeightChangeInfo: generateHeightChangeInfo,
                    clearHeightChanges: clearHeightChanges,
                    findLastHeightChangeInfoObjectWithoutShadowDeletedObjects: findLastHeightChangeInfoObjectWithoutShadowDeletedObjects,
                    getHeightChangeInfoLength: getHeightChangeInfoLength,
                    getHeightChangeInfoObjects: getHeightChangeInfoObjects,
                    checkEntityQuantity: checkEntityQuantity
                };
            }
        );
})();
