(function () {
    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('systemprops', {
                    parent: 'admin',
                    url: '/systemProps',
                    data: {
                        roles: ['ROLE_ADMIN'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'systemprops.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/system/systemProps.html',
                            controller: 'systemPropsController',
                            controllerAs: 'spctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('systemprops');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        currentLicense: ['$log', 'License', function ($log, License) {
                            return License.reload().then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error('Couldn\'t load system properties!', error);
                                return {};
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', function ($log, Principal, $state) {
                            return Principal.identity().then(function (user) {
                                $log.debug("Loading userInfo: ", user);
                            }, function (error) {
                                $state.go("notfound");
                                $log.error("Couldn't load user ", error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal, License, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) {
                                Principal.showChangePasswordState();
                            }
                        });
                    }
                })
        });

})();
