(function () {
    'use strict';

    function _convertDDToDMS(D, lng) {
        return {
            dir: D < 0 ? lng ? 'W' : 'S' : lng ? 'E' : 'N',
            deg: 0 | (D < 0 ? D = -D : D),
            min: 0 | D % 1 * 60,
            sec: Math.floor((0 | D * 60 % 1 * 6000) / 100)
        };
    }

    function _format(dms) {
        return dms.deg + '°' + dms.min + '\'' + dms.sec + '"';
    }

    function positionDirective() {
        return {
            restrict: 'E',
            scope: {
                lat: '=',
                lng: '=',
                alt: '='
            },
            templateUrl: 'scripts/components/position/position.html',
            link: function (scope) {
                scope.formated = {
                    lat: 'undefined',
                    lng: 'undefined',
                    alt: 'undefined'
                };

                scope.$watch('lat', function () {
                    var latObj = _convertDDToDMS(scope.lat, false);
                    scope.formated.lat = (latObj !== null) ? _format(latObj) : 'undefined';
                });
                scope.$watch('lng', function () {
                    var lngObj = _convertDDToDMS(scope.lng, true);
                    scope.formated.lng = (lngObj !== null) ? _format(lngObj) : 'undefined';
                });
                scope.$watch('alt', function () {
                    scope.formated.alt = (angular.isDefined(scope.alt)) ? Math.floor(scope.alt) : 'undefined';
                });
            }
        };
    }

    angular
        .module('emsv2App')
        .directive('position', positionDirective);

})();
