(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgetcontent', [function () {
        return {
            restrict: 'E',
            controller: 'WidgetContentController',
            controllerAs: 'ctrl',
            transclude:true,
            templateUrl: 'scripts/app/dashboard/widget.content/widget.html',
            scope: {
                widget: '=',
                isreportwidget: '=',
                isdashboardwidget: '=',
                isprintwidget: '=',
                isresizable: '=',
                index: '=',
                active: '='
            },
            link: function (scope, element, attrs) {
                scope.generateContent(element, attrs);
            }
        };
    }]);
})();
