(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('WarningController', function ($state, ThemeService, Principal) {
                var ctrl = this;

                ctrl.changePassword = function () {
                    Principal.setShowChangePasswordSite(false);
                    $state.go("settings");
                };

                ctrl.ignoreWarning = function () {
                    Principal.setShowChangePasswordSite(false);
                    Principal.redirectToHomepage();
                };
            }
        )
    ;
})();
