(function () {

    'use strict';

    angular.module('emsv2App')
        .directive('ipfield', ['Tools', 'Converter', function (Tools, Converter) {
            return {
                require: 'ngModel',
                restrict: 'E',
                scope: {
                    ngModel: '=',
                    readonly: '=',
                    required: '=',
                    allowEmpty: '<',
                    css: '@',
                    name: '@'
                },
                templateUrl: 'scripts/components/ipfield/ipfield.html',
                link: function (scope, element, attrs, ctrl) {
                    ctrl.$render = function () {
                        if (ctrl.$viewValue) {
                            var promise = Converter.convertTo('ip', ctrl.$viewValue);
                            promise.then(function (result) {
                                scope.tmpValue = result;
                            });
                        }
                    };

                    scope.changed = function () {

                        if (!scope.readonly) {
                            if (Tools.checkIp(scope.tmpValue)) {
                                var promise = Converter.convertFrom('ip', scope.tmpValue);
                                promise.then(function (result) {
                                    ctrl.$setValidity(scope.name, true);
                                    ctrl.$setViewValue(result);
                                });
                            }
                            else if (Tools.isDefinedNotNull(scope.allowEmpty) && scope.allowEmpty === true
                                && (Tools.isDefinedNotNull(scope.tmpValue) || scope.tmpValue === "")) {
                                ctrl.$setValidity(scope.name, true);
                                ctrl.$setViewValue("");
                            }
                            else {
                                ctrl.$setValidity(scope.name, !scope.tmpValue);
                            }
                        }
                    };
                }
            };
        }])
        .directive('macfield', ['Tools', 'Converter', function (Tools, Converter) {
            return {
                require: 'ngModel',
                restrict: 'E',
                scope: {
                    ngModel: '=',
                    readonly: '=',
                    required: '=',
                    css: '@',
                    name: '@'
                },
                templateUrl: 'scripts/components/ipfield/macfield.html',
                link: function (scope, element, attrs, ctrl) {
                    ctrl.$render = function () {
                        if (ctrl.$viewValue) {
                            var promise = Converter.convertTo('mac', ctrl.$viewValue);
                            promise.then(function (result) {
                                scope.tmpValue = result;
                            });
                        }
                    };

                    scope.changed = function () {
                        if (!scope.readonly) {
                            if (Tools.checkMac(scope.macfield.macinput.$viewValue)) {
                                var promise = Converter.convertFrom('mac', scope.macfield.macinput.$viewValue);
                                promise.then(function (result) {
                                    ctrl.$setValidity(scope.name, true);
                                    ctrl.$setViewValue(result);
                                });
                            }
                            else {
                                ctrl.$setValidity(scope.name, false);
                            }
                        }
                    };
                },
                controller: function ($scope) {
                    $scope.uiOptions = {
                        maskDefinitions: {
                            'A': /[0-9a-fA-F]/
                        },
                        allowInvalidValue: false
                    };
                }
            };
        }]);
})();
