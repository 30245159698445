(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name timezone
     * @param {Object} ngModel - ID of contact info to show/link to
     */
    angular.module('emsv2App')
        .component('timezone', {
            bindings: {
                ngModel: '<',
                definition: '='
            },
            template: '<span ng-bind="$ctrl.label" uib-tooltip="{{$ctrl.tooltip}}" ng-mouseenter="$ctrl.updateTooltip()"></span>',
            controller: ['$translate', '$log', 'Timezones', function ($translate, $log, Timezones) {
                var ctrl = this;
                var dateTimeFormat, tz = undefined;
                ctrl.label = '';
                ctrl.tooltip = '';

                ctrl.$onInit = function () {
                    dateTimeFormat = $translate.instant('global.dateTimeFormat');
                    Timezones.getAll().then(function (response) {
                        tz = response.data.find(function (item) {
                            return item.id === ctrl.ngModel;
                        });
                        if (tz !== undefined) {
                            ctrl.label = tz.timezone;
                            ctrl.updateTooltip();
                        }
                    }, function (error) {
                        $log.error('Couldn\'t load timezones!', error);
                    });
                };

                ctrl.updateTooltip = function() {
                    if (tz !== undefined && dateTimeFormat !== undefined) {
                        ctrl.tooltip = moment().tz(tz.timezone).format(dateTimeFormat);
                    }
                };
            }]
        });

})();
