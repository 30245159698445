(function () {
    'use strict';

    angular
        .module("emsv2App")
        .component("licenseComponent", {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/admin/license/license.component.html',
            controller: function ($log,$q, $translate, Tools, License, Notify, notify,localStorageService) {

                var ctrl = this;
                ctrl.mac = [];
                ctrl.selectedMac = null;
                ctrl.parsedMac = null;

                ctrl.$onInit = function () {
                    ctrl.mac = ctrl.resolve.mac;
                };

                ctrl.input = {
                    file: undefined,
                    onChange: function (file) {
                        if (!!file) {
                            var a= uploadFile(file);
                            var b= updateLocalStorage();
                            $q.all([a,b]);
                        }
                    }
                };

                ctrl.updateMac = function () {
                    ctrl.parsedMac = ctrl.selectedMac.substr(0,12);
                }

                function uploadFile(file) {
                    var data = new FormData();
                    data.append('file', file);
                    data.append('fileName', file.name);
                    data.append('fileSize', file.size);
                    data.append('fileType', file.type);
                    License.upload(data).then(function () {
                        notify({
                            messageTemplate: '<strong translate="license.notification.success.title"></strong><br>' +
                                '<span translate="license.notification.success.text"></span>' +
                                '<br><a href onclick="location.reload()" ><span>&raquo;</span> <span translate="license.notification.success.reload"></span></a>',
                            classes: 'alert-success',
                            duration: 5000
                        });
                        closeModal();
                        return 1;
                    }, function (error) {
                        if (error.status == 406) {
                            Notify.error("license.notification.error.title", "license.notification.error.invalidLicense", 3000);
                            $log.warn('Couldn\'t upload license!', error);
                            return 0;
                        }
                        else {
                            Notify.error("license.notification.error.title", "license.notification.error.text", 3000);
                            $log.warn('Couldn\'t upload license!', error);
                            return 0;
                        }
                    });
                }
                function updateLocalStorage() {
                    License.get().then( function (response) {
                        var value=response['data']['data']['hostedpublicly'];
                        if(response['data']['data']['hostedpublicly']){
                            var value=response['data']['data']['hostedpublicly'];
                            localStorageService.set("hostedpublicly",value);
                        }
                        else {
                            localStorageService.set("hostedpublicly",null);
                        }
                    })
                }

                var closeModal = function () {
                    ctrl.close();
                }
            },
            controllerAs: 'lCtrl'
        });
})();
