(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('DebugController', function ($scope, $state, $http, $log, Converter, Tools) {
            var ctrl = this;
            ctrl.tools = {
                long2mac: {
                    mac: null,
                    long: null
                },
                mac2long: null,
                long2ip: {
                    ip: null,
                    long: null
                },
                ip2long: null
            };

            ctrl.bbalarm = {
                // id_alarm: null,
                mac: 0,
                // stamp: new Date(),
                errorCode: 0,
                hash: 0,
                priority: 150,
                quit: false,
                // action: "",
                // origin: 0,
                // alarm_unique_id: "",
                active: true,
                // mail_sent: false,
                // auto_quit: false,
                version: 0
            };

            ctrl.liveformat = {
                format: null,
                value: null,
                result: null
            };

            ctrl.long2mac = function () {
                Converter.convertTo('mac', Number(ctrl.tools.long2mac.long)).then(function (result) {
                    ctrl.tools.long2mac.mac = result;
                });
            };

            ctrl.long2ip = function () {
                Converter.convertTo('ip', Number(ctrl.tools.long2ip.long)).then(function (result) {
                    ctrl.tools.long2ip.ip = result;
                });
            };

            ctrl.addAlarm = function () {
                var alarm = angular.copy(ctrl.bbalarm);
                $http.post('api/alarm/add', alarm).then(function (response) {
                    $log.info('Added alarm!', response, alarm);
                }, function (error) {
                    $log.error('Couldn\'t add alarm', error);
                });
            };

            ctrl.formatLive = function () {
                ctrl.liveformat.result = Tools.formatLiveValue(ctrl.liveformat.format, ctrl.liveformat.value);
            };

        });

})();
