(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name AuthServerProvider
     * @requires $http, $q, $log, $rootScope, localStorageService, Base64, Principal
     */
    angular.module('emsv2App').service('AuthServerProvider', function loginService($http, $q, $log, $rootScope,
                                                                                   localStorageService) {
        var token = null;

        function _login(credentials) {
            var data = 'username=' + credentials.username +
                '&password=' + credentials.password;

            return $http.post('login?' + data).then(function (response) {
                localStorageService.set('token', response.headers("authorization"));
                token = response.headers("authorization");
                return response.headers("authorization");
            }, function (error) {
                return $q.reject(error);
            });
        }

        function _logout() {
            return $http.post('logout').finally(function () {
                localStorageService.remove('token');
                token = null;
                return Promise.resolve(true);
            });
        }

        function _getToken() {
            if (token === null) {
                var jwtToken = localStorageService.get('token');
                if (jwtToken === undefined || jwtToken === null) {
                    log.error("No token found. Log in again.");
                }
            }
            return $q.resolve(jwtToken);
        }

        return {
            login: _login,
            logout: _logout,
            getToken: _getToken
        };
    });
})();
