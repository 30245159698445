(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('RegisterController', function ($scope, $state, $stateParams, $translate, $timeout, $uibModal,
                                                    $http, Pages, Auth, countries, Notify) {
            var ctrl = this;
            var currentModal = null;
            ctrl.content = null;
            ctrl.success = null;
            ctrl.error = null;
            ctrl.doNotMatch = null;
            ctrl.errorUserExists = null;
            ctrl.countries = countries;
            ctrl.defaultAdmin = ($state.current.name === 'register.defaultadmin');
            ctrl.registerAccount = {
                contact: {
                    gender: 0
                }
            };

            if ($stateParams.defaultAdminAvailable) {
                $state.go("login");
            }

            $timeout(function () {
                angular.element('[ng-model="registerAccount.login"]').focus();
            });

            ctrl.closeModal = function () {
                if (currentModal) {
                    currentModal.close();
                    currentModal = null;
                }
            };

            function _showModal(content) {
                ctrl.content = content;
                currentModal = $uibModal.open({
                    animation: false,
                    windowClass: 'animated fadeInDown',
                    templateUrl: "scripts/app/account/register/register.legal.modal.html",
                    scope: $scope,
                    size: "lg"
                });
            }

            ctrl.showModal = function (title) {
                ctrl.title = title;
                Pages.get(title).then(function (response) {
                    _showModal(response.data.content);
                }, function (error) {
                    _showModal($translate.instant("legal." + title + ".notFound"));
                });
            };

            ctrl.onLoginChanged = function (login) {
                ctrl.loginExists = false;
                if (!login || login.length < 2) {
                    return;
                }

                Auth.checkLoginExists(login).then(function (exists) {
                    ctrl.loginExists = exists.data;
                }, function (error) {
                    console.error('Couldn\'t check login.', error);
                });
            };

            ctrl.onMailChanged = function (email) {
                ctrl.emailExists = false;
                if (!email || email < 3) {
                    return;
                }

                Auth.checkEmailExists(email).then(function (result) {
                    ctrl.emailExists = result.data;
                }, function (error) {
                    console.error('Couldn\'t check email.', error);
                });
            };

            ctrl.checkRegister = function (formValue) {
                return !(formValue || ctrl.loginExists || ctrl.emailExists || (ctrl.confirmPassword && ctrl.confirmPassword !== ctrl.registerAccount.password));

                // return !(!ctrl.registerAccount.terms || formValue || ctrl.loginExists || ctrl.emailExists || (ctrl.confirmPassword && ctrl.confirmPassword !== ctrl.registerAccount.password));
            };

            ctrl.register = function () {
                ctrl.registerAccount.langKey = $translate.use();
                ctrl.doNotMatch = null;
                ctrl.error = null;
                ctrl.errorUserExists = null;
                ctrl.errorEmailExists = null;
                ctrl.errorInvalidUsername = null;
                ctrl.isCreating = true;
                var user = angular.copy(ctrl.registerAccount);
                user.sendActivationMail = true;
                user.sendInfoMail = false;
                user.notify = false;
                user.ldap = false;
                if ($state.current.name === 'register.defaultadmin') {
                    Auth.createSuperUser(user).then(function () {
                        ctrl.isCreating = false;
                        ctrl.adminSuccess = 'OK';
                        Notify.defaultSuccess();
                        $timeout(function () {
                            location.reload(); // a better solution than reload may be necessary (initial admin login state bug)
                        }, 2000);
                    }, function (response) {
                        ctrl.isCreating = false;
                        if (response === '"login"') {
                            ctrl.errorUserExists = 'ERROR';
                        }
                        else if (response === '"email"') {
                            ctrl.errorEmailExists = 'ERROR';
                        }
                        else if (response === '"invalid_username"') {
                            ctrl.errorInvalidUsername = 'ERROR';
                        }
                        else {
                            ctrl.adminError = 'ERROR';
                        }
                        Notify.defaultError();
                        $timeout(function () {
                            $state.go("login")
                        }, 2000);
                    })
                }
                else {
                    Auth.createAccount(user).then(function () {
                        ctrl.isCreating = false;
                        ctrl.success = 'OK';
                        Notify.defaultSuccess();
                    }, function (error) {
                        ctrl.isCreating = false;
                        ctrl.success = null;
                        if (error === '"login"') {
                            ctrl.errorUserExists = 'ERROR';
                        }
                        else if (error === '"email"') {
                            ctrl.errorEmailExists = 'ERROR';
                        }
                        else if (error === '"invalid_username"') {
                            ctrl.errorInvalidUsername = 'ERROR';
                        }
                        else {
                            ctrl.error = 'ERROR';
                        }
                        Notify.defaultError();
                    });
                }
            };
        });

})();
