(function () {

    'use strict';

    function updateService($http) {
        return {
            'getAll': function (hwtyp) {
                return $http.get('api/updates/hwtyp/' + hwtyp);
            },
            'getAllMap': function (hwtyp) {
                return $http.get('api/updates/hwtyp/' + hwtyp + '/map');
            },
            'get': function (id) {
                return $http.get('api/updates/' + id);
            },
        };
    }

    angular.module('emsv2App').factory('Update', updateService);

})();
