(function () {
    'use strict';

    angular.module('emsv2App').directive('emsSwitch', function () {
        return {
            scope: {
                emsSwitchOptions: '='
            },
            require: 'ngModel',
            template: [
                "<div class='onoffswitch'>",
                "<input type='checkbox' class='onoffswitch-checkbox' id='emsSwitch'>",
                "<label class='onoffswitch-label' for='emsSwitch'>",
                "<span class='onoffswitch-custom-inner' data-before='{{emsSwitchOptions.on | translate}}' data-after='{{emsSwitchOptions.off | translate}}'></span>",
                "<span class='onoffswitch-switch'></span>",
                "</label>",
                "</div>"
            ].join("\n"),
            link: function (scope, elem, attrs, ngModel) {

            }
        };
    });
})();
