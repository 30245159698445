(function () {

    'use strict';

    function widgetWeatherController($scope, $log, $interval, $uibModal, $translate, Authority, Principal, Weather, WidgetData, Notify, Tools) {

        var ctrl = this;
        var modalInstance;
        ctrl.listOfLocations = null;
        ctrl.chosenLocation = null;
        ctrl.currentWeather = null;
        var dateFormat = $translate.instant('global.dateTimeFormat');

        var runningPoll = null;
        var weatherRequest = null;
        var requestId = null;

        ctrl.saveSettings = function () {
            var newSettings = [];
            newSettings.push({key: "locationId", value: ctrl.chosenLocation.id});
            $scope.widget.settings = newSettings;
            // $log.info("Saving Settings:", $scope.widget.settings);
            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    ctrl.updateWeather();
                    ctrl.closeModal();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.weather.saveErrorMsg", 2000);
                });
            }
        };

        function getLocationById(id) {
            if (ctrl.listOfLocations) {
                for (var i = 0; i < ctrl.listOfLocations.length; i++) {
                    var loc = ctrl.listOfLocations[i];
                    if (loc.id == id) {
                        return loc;
                    }
                }
            }
            return null;
        }

        ctrl.loadSettings = function () {
            Principal.identity().then(function (user) {
                if (!user) return;
                Authority.getUserLocations(user.id).then(function (response) {
                    if (response.data.length > 0) {
                        ctrl.listOfLocations = response.data;
                        ctrl.coordsNotNull(ctrl.listOfLocations);
                    }
                    if ($scope.widget.settings.length > 0) {
                        for (var i = 0; i < $scope.widget.settings.length; i++) {
                            // $log.info('going through settings:', $scope.widget.settings);
                            if ($scope.widget.settings[i].key == 'locationId') {
                                $log.debug('weather loading location:', $scope.widget.settings[i].value);
                                ctrl.chosenLocation = getLocationById($scope.widget.settings[i].value);
                                ctrl.updateWeather();
                            }
                        }
                    }
                    else {
                        $log.debug('settings null');
                        ctrl.chosenLocation = null;
                    }
                });
            });
        };

        ctrl.coordsNotNull = function(locList) {
            for (var i = 0; i < locList.length; i++) {
                if (locList[i].pos_alt === null || locList[i].pos_lat === null || locList[i].pos_lng === null) {
                    locList.splice(i, 1);
                }
            }
            ctrl.listOfLocations = locList;
        }

        ctrl.updateWeather = function () {
            if(Tools.isDefinedNotNull(runningPoll)) {
                Notify.warning("global.notification.warning.warn", "dashboard.widget.weather.messages.pendingRequest", 2000);
                return;
            }
            if (Tools.isDefinedNotNull(ctrl.chosenLocation)) {
                requestWeather();
                //update every 10 minutes
                if(!Tools.isDefinedNotNull(weatherRequest)) {
                    weatherRequest = $interval(requestWeather, 600000);
                }
            }
        };

        $scope.$on("$destroy", function () {
            cancelWeatherPoll();
            cancelWeatherRequest();
        });

        function requestWeather() {
            Weather.requestForLocation(ctrl.chosenLocation.id).then(function (response) {
                requestId = response.data;
                if(!Tools.isDefinedNotNull(runningPoll)) {
                    runningPoll = $interval(_processWeatherPoll, 2000);
                }
                }, function () {
                    Notify.error("global.notification.error.title", "dashboard.widget.weather.messages.couldNotRequest", 2000);
                }
            );
        }

        function cancelWeatherPoll() {
            requestId = null;
            if (Tools.isDefinedNotNull(runningPoll)) {
                $interval.cancel(runningPoll);
                runningPoll = null;
            }
        }

        function cancelWeatherRequest() {
            if (Tools.isDefinedNotNull(weatherRequest)) {
                $interval.cancel(weatherRequest);
                weatherRequest = null;
            }
        }

        function _processWeatherPoll() {
            if (!Tools.isDefinedNotNull(requestId)) return;
            Weather.pollRequestId(requestId).then(function (response) {
                var weatherData = response.data;
                if (weatherData === null) {
                    cancelWeatherPoll();
                    Notify.error("global.notification.error.title", "dashboard.widget.weather.messages.noValidResponse", 2000);
                } else if (weatherData.weatherState === 2) {
                    cancelWeatherPoll();
                    Notify.error("global.notification.error.title", "dashboard.widget.weather.messages." +weatherData.errorCode, 2000);
                } else if (weatherData.weatherState === 3) {
                    cancelWeatherPoll();
                    ctrl.currentWeather = weatherData.value;
                } else if (weatherData.weatherState === 4) {
                    cancelWeatherPoll();
                    // the system just deleted the request since its outdated. new value will be available with next request
                }
            }, function () {
                Notify.error("global.notification.error.title", "dashboard.widget.weather.messages.couldNotRequest", 2000);
            });
        }

        ctrl.$onInit = function () {
            ctrl.loadSettings();
        };

        ctrl.closeModal = function () {
            modalInstance.close();
        };

        function _showOptions() {
            $log.debug('opening weather settings');
            modalInstance = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/weather/widget.weather.settings.modal.html',
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }

        // Set config method in parent
        $scope.$parent.setConfigMethod(_showOptions);
    }

    angular.module('emsv2App')
        .controller('WidgetWeatherController', widgetWeatherController);
})();


