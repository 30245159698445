(function () {
    'use strict';

    /**
     * @ngdoc factory
     * @name TrackingDivLabelFactory
     * @description Service to encapsulate tracking div label functionality
     */
    angular.module("emsv2App").factory("TrackingDivLabelFactory", function (LiveDataService) {
        // array for generated labels
        var labels = [];
        // initial id value ('unique' for each label)
        var initId = 0;

        /**
         * @ngdoc function
         * @description Function to create a new TrackingDivLabel
         * @param {THREE.Object3D} obj obj to track for
         * @param {string} type defines whether a label is readonly or editable 'text' or 'input'
         * @param {string} containerId id of the container to add the label to
         * @returns {TrackingDivLabel} returns created label
         */
        var buildLabel = function (obj, type, containerId) {
            var label = new TrackingDivLabel(initId++, obj, containerId);
            if (type === "text") {
                label.buildText();
            }
            if (type === "input") {
                label.buildInput();
            }
            // label.track();
            labels.push(label);
            return label;
        };

        /**
         * @ngdoc function
         * @description Function to remove a TrackingDivLabel from view
         * @param id id of the label
         * @returns {boolean} returns true if label was found and removed otherwise false
         */
        var removeLabel = function (id) {
            for (var i = 0; i < labels.length; i++) {
                if (labels[i].id == id) {
                    labels[i].remove();
                    labels.splice(i, 1);
                    return true;
                }
            }
            return false;
        };

        /**
         * @ngdoc function
         * @description Function to get all currently displayed labels
         * @returns {Array} returns all currently displayed labels
         */
        var getLabels = function () {
            return labels;
        };

        /**
         * @ngdoc function
         * @description Function to get a label by its id
         * @param id the id of a label
         * @returns {*} returns found TrackingDivLabel
         */
        var getLabel = function (id) {
            for (var i = 0; i < labels.length; i++) {
                if (labels[i].id == id) return labels[i];
            }
        };

        /**
         * @ngdoc function
         * @description Function to trigger tracking(movement of label corresponding to projected position of associated object)
         * @param {THREE.PerspectiveCamera | THREE.OrthographicCamera} cam the camera to get the projected positions by
         */
        var trackAll = function (cam) {
            for (var i in labels) {
                labels[i].track(cam);
            }
        };

        /**
         * @ngdoc function
         * @description Function to trigger tracking of a single label identified by its id
         * @param id id of the label to trigger tracking for
         * @param {THREE.PerspectiveCamera | THREE.OrthographicCamera} cam the camera to get projected positions by
         */
        var trackSingle = function (id, cam) {
            getLabel(id).track(cam);
        };

        /**
         * @ngdoc function
         * @description Function to create a label for multiple sensor values
         * @param {Sensor[]} sensors array of sensor objects to display values for
         * @param id the id for the new label
         * @param {string} container id of container to add label to
         * @param physType id of physical type of displayed values
         * @param {THREE.Vector3} pos the position in 3D to track by
         * @param {string} userTempType user defined temperature unit (Kelvin\Fahrenheit\Celsius)
         * @returns {MultiSensorLabel} returns the created label
         */
        var buildMultiSensorLabel = function (sensors, id, container, physType, pos, userTempType) {
            var converter = LiveDataService.getTempConverter(userTempType);
            var label = new MultiSensorLabel(sensors, pos, id, container, converter, 8, physType);
            labels.push(label);
            return label;
        };

        /**
         * @ngdoc function
         * @description Function to create an object info label
         * @param {object} obj object to build label for
         * @param id id for the label
         * @param {string} container id of the container to add the label
         * @param {THREE.Vector3} pos the position
         * @param {string} userTempType user defined temperature unit (Kelvin\Fahrenheit\Celsius)
         * @param {function} callback function to call when button in label is clicked
         * @param {string} currlang currently used language (from settings, not browser language)
         * @param {object} translator translation service
         * @param {number} weightType type of weight units 0 === kg, 1 === lb
         * @returns {ObjectInfoLabel} returns created ObjectInfoLabel
         */
        var buildObjectInfoLabel = function (obj, id, container, pos, userTempType, callback, currlang, translator, weightType) {
            var converter = LiveDataService.getTempConverter(userTempType);
            var label = new ObjectInfoLabel(obj, id, container, pos, converter, callback, currlang, translator, weightType);
            labels.push(label);
            return label;
        };

        /**
         * @ngdoc function
         * @description Function to trigger update of label content (new live data)
         */
        var updateLabelValues = function () {
            for (var l in labels) {
                if (labels[l] instanceof ObjectInfoLabel || labels[l] instanceof MultiSensorLabel) labels[l].updateContent();
            }
        };

        /**
         * @ngdoc function
         * @description Function to set whether the labels are clickable or not
         * @param {boolean} allow true - allow mouse actions for label, false - no mouse events will be triggered
         */
        var setPointerEventsAllowedForAll = function (allow) {
            for (var i in labels) {
                labels[i].allowPointerEvents(allow);
            }
        };

        /**
         * @ngdoc function
         * @description Function to remove all labels from view
         */
        var clearAll = function () {
            for (var i in labels) {
                removeLabel(labels[i].id);
            }
        };

        /**
         * @ngdoc function
         * @description Function to determine if any label is focused
         * @returns {*} returns the focused label if possible
         */
        var hasAnyLabelFocus = function(){
            for( var i in labels) {
                if($("#i"+labels[i].e.id).is(":focus")){
                    console.log("element focussed");
                    return labels[i];
                }
            }
        };

        return {
            buildLabel: buildLabel,
            getLabels: getLabels,
            removeLabel: removeLabel,
            getLabel: getLabel,
            trackAll: trackAll,
            trackSingle: trackSingle,
            buildMultiSensorLabel: buildMultiSensorLabel,
            buildObjectInfoLabel: buildObjectInfoLabel,
            updateLabelValues: updateLabelValues,
            setPointerEventsAllowedForAll: setPointerEventsAllowedForAll,
            clearAll: clearAll,
            hasAnyLabelFocus:hasAnyLabelFocus
        };
    });
})();
