(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name ProtocolService
     * @requires $http
     */
    angular.module('emsv2App').factory('EventTypeService', ['$http', function ($http) {
        return {
            //get EventType
            'getEventType': function () {
                return $http.get('api/eventType');
            }
        };
    }]);
})();
