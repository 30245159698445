'use strict';

/**
 * @description Constructor to create new cpu object
 * @param {Number} id the id to set
 * @param {Object} pos 3-dimensional vector object describing objects position (currently not used)
 * @param {Object} size 3-dimensional vector object describing objects size (currently not used)
 * @param {Object} rot 3-dimensional vector object describing objects rotation (currently not used)
 * @param {String} name the name to set
 * @param {String} comment the comment to set
 * @param {String} inventoryNumber the inventory number to set
 * @param {String} serialNumber the serial number to set
 * @param {Number} uid the unique id to set
 * @param {Number} pid the parent id to set (blade id)
 * @constructor
 */
function Cpu(id, pos, size, rot, name, comment, inventoryNumber, serialNumber, uid, pid) {
    NamedEntity.call(this, id, pos, size, rot, name, comment, uid);
    this.inventoryNumber = inventoryNumber !== undefined ? inventoryNumber : "";
    this.serialNumber = serialNumber !== undefined ? serialNumber : "";
    this.driverValues = [];
    this.bladeId = pid !== undefined ? pid : null;
    this.allowDataPoints = true;
    this.isCpu = true;

}

Cpu.prototype = Object.create(NamedEntity.prototype);
Cpu.prototype.constructor = Cpu;

/**
 * @description Function to test equality of this object with respect to the provided object
 * @param {Object} cc the object to compare this with
 * @returns {boolean} returns true if this object and the provided are equal, otherwise false
 */
Cpu.prototype.equals = function (cc) {
    if (!(cc instanceof Cpu)) return false;
    if (!this.equalsNamedEntity(cc)) return false;
    if (this.bladeId !== cc.bladeId) return false;
    if (this.inventoryNumber !== cc.inventoryNumber) return false;
    if (this.serialNumber !== cc.serialNumber) return false;
    if (!this.compareDriverValues(cc.driverValues)) return false;
    return true;
};

/**
 * @description Function to get the used and unused parameters for this object
 * @param {Object} availableParameters array (associative ) of all available parameters for this object type
 * @returns array containing sub-arrays for used(index 0) and unused(index 1) parameters
 */
Cpu.prototype.setupParameters = function (availableParameters) {

    var usedParams = [];
    var unusedParams = [];

    for (var ap in availableParameters) {
        var param = this.driverValues.filter(function (elem) {
            return elem.parameter.id === availableParameters[ap].id;
        });
        if (param.length > 0) {
            usedParams.push(availableParameters[ap]);
        }
        else {
            unusedParams.push(availableParameters[ap]);
        }
    }
    return [usedParams, unusedParams];
};

/**
 * @description Function to set type property to null value (deprecated)
 */
Cpu.prototype.resetType = function () {
    if (this.type !== undefined && this.type !== null) this.type = null;
};

/**
 * @description Function to validate this object
 * @returns array of error objects, describing errors in this object
 */
Cpu.prototype.validate = function () {
    var errorList = [];
    errorList = errorList.concat(this.validateIds());
    errorList = errorList.concat(this.validatePosition());
    errorList = errorList.concat(this.validateRotation());
    if (this.size === undefined || this.size === null) {
        errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size"));
    }
    else {
        if (this.size.x === undefined || this.size.x === null || this.size.x < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size.x"));
        if (this.size.y === undefined || this.size.y === null || this.size.y < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size.y"));
        if (this.size.z === undefined || this.size.z === null || this.size.z < 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "size.z"));
    }
    errorList = errorList.concat(this.validateNameComment());
    if (this.bladeId === undefined || this.bladeId === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "bladeId"));
    for (var i = 0; i < this.driverValues.length; i++) {
        errorList = errorList.concat(this.driverValues[i].validate());
    }

    return errorList;
};

Cpu.prototype.findObjectPathByUniqueId = function (uniqueId) {
    if (this.uniqueId === uniqueId) return [this];
    for (var i = 0; i < this.driverValues.length; i++) {
        var objs = this.driverValues[i].findObjectPathByUniqueId(uniqueId);
        if (objs instanceof Array && objs.length) {
            return [this].concat(objs);
        }
    }
    return [];
};

/**
 * @description Function retrieve possible driver types for this object
 * @param {Array} allDrivers array (associative) of all supported driver types
 * @returns {Object} returns array (associative) of driver types for this object type
 */
Cpu.getAvailableDrivers = function (allDrivers) {
    var availableDrivers = angular.merge({}, allDrivers);
    for (var d in availableDrivers) {
        if (d === "WIB8000" || d === "D0" || d === "DBUS" || d === "MBUS" || d === "MODBUS_RTU" || d === "S0" || d === "WEBBUS") delete availableDrivers[d];
    }
    return availableDrivers;
};

/**
 * @description Function to create cpu object from json data object (backend)
 * @param {Object} obj the json data object to use
 * @param {Number} pid the parent id to set (blade id)
 * @returns {Cpu} returns the created cpu object
 */
Cpu.parseFromHtmlObject = function (obj, pid) {
    var cpu = new Cpu(obj.id, undefined, undefined, undefined, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromHtmlObject(obj.driverValues, cpu);
    cpu.modifyDriverValueLimitsForFrontend();
    if (obj.type !== undefined && obj.type !== null) cpu.type = obj.type;
    return cpu;
};

/**
 * @description Function to create cpu object from copied javascript object (dummy copy loses object type)
 * @param {Object} obj the object to use
 * @param {Number} pid the parent id to set (blade id)
 * @returns {Cpu} returns the created cpu object
 */
Cpu.parseFromSimpleObject = function (obj, pid) {
    var cpu = new Cpu(obj.id, undefined, undefined, undefined, obj.name, obj.comment, obj.inventoryNumber, obj.serialNumber, obj.uniqueId, pid);
    if (obj.driverValues) Entity.parseDriverValueFromSimpleObject(obj.driverValues, cpu);
    if (obj.type !== undefined && obj.type !== null) cpu.type = obj.type;
    return cpu;
};

