(function () {

    'use strict';

    function highchartFactory($q, $log, digiChart, ValueFormatter, Notify, Tools, PIL, GenDialogService, $translate) {

        var tempSetting = null;
        var baseConfiguration = {
            options: {
                chart: {
                    zoomType: 'x',
                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        }
                    },
                    reflow: true,
                    events: {
                        selection: _handleZoomEvent
                    }
                },
                plotOptions: {
                    // series: { // TODO: Needed for alarms & errorlogs
                    //     step: 'center'
                    // },
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                yAxis: [],
                xAxis: []
            }
        };

        // needed for zooming
        var savedDatapoints;

        var savedSettings, savedOnComplete, savedColorSupplier;

        var saveHighLimit, saveLowLimit, saveSelectedHighDp, saveSelectedLowDp, saveShowLimitsFlag, saveHighColor,
            saveLowColor;

        var stdTooltipFormatter = function () {
            var formattedValue = ValueFormatter.format(this.y, this.series.tooltipOptions.format, tempSetting, null, Tools);
            var datetime = moment(this.x, 'x').format('DD.MM.YYYY - HH:mm');
            var name = this.series.name;
            return '<b>' + datetime + '</b><br/>' + name + ': ' + formattedValue;
        };

        var liveTooltipFormatter = function () {
            var formattedValue;
            if (this.series.tooltipOptions.isBool === "true") {
                formattedValue = Tools.formatLiveValue(this.series.tooltipOptions.format, this.y * 10, tempSetting);
            } else {
                formattedValue = Tools.formatLiveValue(this.series.tooltipOptions.format, this.y / 100, tempSetting);
            }
            var datetime = moment(this.x, 'x').format('DD.MM.YYYY - HH:mm');
            var name = this.series.name;
            return '<b>' + datetime + '</b><br/>' + name + ': ' + formattedValue;
        };

        function getNewConfigObject(settings) {
            var config = angular.copy(baseConfiguration);
            if (typeof settings.options === 'object') {
                config = angular.merge(config, settings.options);
            }

            config.series = [];
            return config;
        }

        /**
         * handles the zoom function
         * @param event
         * @private
         */
        function _handleSetExtremes(event) {
            if (savedSettings.originStart && savedSettings.originEnd && event.trigger !== "zoom") {
                for (var i = 0; i < savedSettings.dateRanges.length; i++) {
                    savedSettings.dateRanges[i].start = savedSettings.originStart;
                    savedSettings.dateRanges[i].end = savedSettings.originEnd;
                }
                PIL.getTempSetting().then(function (temp) {
                    buildConfiguration(temp.data, savedDatapoints, savedSettings, savedOnComplete, savedColorSupplier, saveHighLimit, saveLowLimit);
                });
            }
        }

        /**
         * handles the zoom in function
         * @param event
         * @private
         */
        function _handleZoomEvent(event) {
            if (Tools.isDefinedNotNull(event.xAxis)) {
                for (var i = 0; i < event.xAxis.length; i++) {
                    var min = moment(event.xAxis[i].min);
                    var max = moment(event.xAxis[i].max);

                    savedSettings.dateRanges[i].start = min;
                    savedSettings.dateRanges[i].end = max;

                    var duration = moment.duration(savedSettings.dateRanges[i].end.diff(savedSettings.dateRanges[i].start));
                    var hours = duration.asHours();
                    // console.warn(hours);
                    if (hours < 1) {
                        Notify.warning("global.notification.warning.info", "analysis.notification.warning.minZoomRange", 2000);
                        return;
                    }

                }
            }
            PIL.getTempSetting().then(function (temp) {
                buildConfiguration(temp.data, savedDatapoints, savedSettings, savedOnComplete, savedColorSupplier, saveHighLimit, saveLowLimit, saveSelectedHighDp, saveSelectedLowDp, saveShowLimitsFlag, saveHighColor, saveLowColor, {
                    min: min,
                    max: max
                });
            });

        }

        function buildYAxisIfNecessary(temp, axes, parameter) {
            function buildAxis(axes, format) {
                var newAxis;
                if (parameter.hasOwnProperty("isLive") && parameter.isLive) {
                    // Livedata axis formatter
                    newAxis = {
                        labels: {
                            format: format,
                            identifier: format,
                            formatter: function () {
                                if (Tools.isDefinedNotNull(parameter.SUFFIX) || Tools.isDefinedNotNull(parameter.FORMATLIVE)) {
                                    return parameter.SUFFIX ? Tools.formatCustomValue(parameter.SUFFIX, this.value / 1000) : Tools.formatLiveValue(parameter.FORMATLIVE, this.value / 100, temp);
                                } else {
                                    return ValueFormatter.format(this.value / 100, format, temp, null, Tools);
                                }
                            }
                        },
                        title: {
                            text: null
                        },
                        opposite: (axes.length % 2 === 1)
                    };
                    if (parameter.SUFFIX) {
                        newAxis.allowDecimals = false;
                    }
                } else {
                    // std data axis formatter
                    var formatValue = '{value}';
                    if (format !== null || format.suffix !== "") {
                        formatValue = formatValue + " " + format.suffix;
                    }
                    newAxis = {
                        labels: {
                            format: formatValue,
                            suffix: format.suffix,
                            identifier: format.suffix,
                            formatter: function () {
                                return ValueFormatter.format(this.value, format, temp, null, Tools);
                            }
                        },
                        title: {
                            text: null
                        },
                        opposite: (axes.length % 2 === 1)
                    };
                }

                var yAxisIndex = axes.push(newAxis);
                yAxisIndex -= 1;
                return yAxisIndex;
            }

            function indexOfAxis(axes, format) {
                var identifier = typeof (format) === 'string' ? format : format.suffix;
                for (var i = 0; i < axes.length; i++) {
                    if (axes[i].labels.identifier === identifier) {
                        return i;
                    }
                }

                return -1;
            }

            if (typeof (parameter.format) !== 'string'
                && !parameter.suffix
                && parameter.FORMATINFO) {
                parameter.format.suffix = parameter.FORMATINFO;
            }

            var index = indexOfAxis(axes, parameter.format);
            if (index > -1) {
                return index;
            } else {
                buildAxis(axes, parameter.format);
            }

            return indexOfAxis(axes, parameter.format);
        }

        function buildXAxisIfNecessary(axes, daterange) {
            var min = moment(daterange.start).format('x');
            var max = moment(daterange.end).format('x');
            var newAxis = {
                events: {
                    setExtremes: _handleSetExtremes
                },
                type: 'datetime',
                minimal: min, // ignored by highcharts, it uses min
                maximal: max, // ignored by highcharts, it uses max
                // minRange: 43200000 // 12 hours // (max - min) / 5
                minRange: 3600000 // 1 hour
            };

            for (var i = 0; i < axes.length; i++) {
                var axis = axes[i];
                if (axis.minimal === min && axis.maximal === max) {
                    return i;
                }
            }

            axes.push(newAxis);
            return axes.indexOf(newAxis);
        }

        /**
         * Extracts the original Dates for this selection of datapoints
         * @private
         */
        function _extractOriginalMinMax() {
            if (Tools.isDefinedNotNull(savedSettings.originStart) &&
                Tools.isDefinedNotNull(savedSettings.originEnd)) {
                return;
            }
            savedSettings.originStart = savedSettings.dateRanges[0].start;
            savedSettings.originEnd = savedSettings.dateRanges[0].end;
        }

        // /**
        //  * Building data set for dispalying given limit values
        //  * @param dataSet, to extract the x coordinate
        //  * @param limitValue, set as y coordinate
        //  * return a set of data to draw a curve as limit line in the chart
        function _buildHighLowLimitDataSet(dataSet, limitValue) {
            var out = [];
            if (Tools.isDefinedNotNull(dataSet)) {
                for (var i = 0; i < dataSet.length; i++) {
                    out.push([dataSet[i][0], limitValue]);
                }
            }
            return out;
        }

        // /**
        //  * searching for the chart data of config.serires[] based on a known hash
        //  * @param configSeries, config.series
        //  * @param selectedHash, hash of the selected data point
        //  * @return  a data set contains  points' coordinates on chart

        function _findSerieDataX(configSeries, selectedHash) {
            for (var i = 0; i < configSeries.length; i++) {
                if (configSeries[i].dataHash === selectedHash) {
                    return configSeries[i].data;
                }
            }
        }

        // search for the Y Axis of a series, using a selected hash
        function _findSerieYAxis(configSeries, selectedHash) {
            for (var i = 0; i < configSeries.length; i++) {
                if (configSeries[i].dataHash === selectedHash) {
                    return configSeries[i].yAxis;
                }
            }
        }

        // search for the X Axis of a series, using a selected hash
        function _findSerieXAxis(configSeries, selectedHash) {
            for (var i = 0; i < configSeries.length; i++) {
                if (configSeries[i].dataHash === selectedHash) {
                    return configSeries[i].xAxis;
                }
            }
        }

        // search for the type of a series, using a selected hash
        function _findSerieType(configSeries, selectedHash) {
            for (var i = 0; i < configSeries.length; i++) {
                if (configSeries[i].dataHash === selectedHash) {
                    return configSeries[i].type;
                }
            }
        }

        // search for the tooltip of a series, using a selected hash
        function _findSerieToolTip(configSeries, selectedHash) {
            for (var i = 0; i < configSeries.length; i++) {
                if (configSeries[i].dataHash === selectedHash) {
                    return configSeries[i].tooltip;
                }
            }
        }

        function _getResponseData(response) {
            if (Tools.isDefinedNotNull(response.data.data)) {
                return response.data.data.data;
            } else {
                return null;
            }
        }

        // remove the config.series which doesnt contain any data
        function _removeNullDataSeries(configSeriesList) {
            var result = configSeriesList.filter(function (item) {
                return Tools.isDefinedNotNull(item.data);
            });
            return result;
        }

        function _stopZoom() {
            $translate(['global.dialog.head.warning', 'analysis.notification.stopZoom', 'global.btn.ok']).then(function (trans) {
                GenDialogService.showDialog(false, {
                    headText: trans['global.dialog.head.warning'],
                    headIcon: 'glyphicon glyphicom-warning-sign',
                    messageText: trans['analysis.notification.stopZoom'],
                    showClose: true,
                    textButton0: trans['global.btn.cancel'],
                    iconButton0: 'glyphicon glyphicon-ok',
                    classButton0: 'btn-close',
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            });

        };

        function buildConfiguration(temp, datapoints, settings, onComplete, colorSupplier, highLimit, lowLimit, selectedHighDp, selectedLowDp, showLimitsFlag, highColor, lowColor, zoom) {
            savedDatapoints = datapoints;
            savedSettings = settings;
            savedOnComplete = onComplete;
            savedColorSupplier = colorSupplier;

            saveHighLimit = highLimit;
            saveLowLimit = lowLimit;
            saveSelectedHighDp = selectedHighDp;
            saveSelectedLowDp = selectedLowDp;
            saveShowLimitsFlag = showLimitsFlag;
            saveHighColor = highColor;
            saveLowColor = lowColor;

            _extractOriginalMinMax();
            Highcharts.setOptions({
                global: {
                    useUTC: false
                }
            });

            if (colorSupplier === undefined) {
                colorSupplier = new ColorSupplier();
            }

            var requests = [];
            var config = getNewConfigObject(settings);

            if (Tools.isDefinedNotNull(settings)
                && Tools.isDefinedNotNull(settings.options)
                && Tools.isDefinedNotNull(settings.options.chartType)) {
                config.options.chart.type = settings.options.chartType;
            }

            datapoints.forEach(function (node) {
                var rangeCount = 0;
                settings.dateRanges.forEach(function (date) {
                    var start = date.start.format('YYYY-MM-DDTHH:mm:ss');
                    var end = date.end.format('YYYY-MM-DDTHH:mm:ss');

                    var nodeTitle = node.title;
                    if (settings.dateRanges.length > 1) {
                        nodeTitle += ' (' + moment(start).format('DD.MM.YYYY') + '-' + moment(end).format('DD.MM.YYYY') + ')';
                    }

                    var parameter = angular.extend(angular.copy(node.parameter), {
                        dateRangeId: date.dateRangeId,
                        start: start,
                        end: end,
                        aggregation: node.aggregation,
                        group: node.grouping,
                        title: nodeTitle,
                        format: node.format
                    });

                    requests.push(digiChart.getSensorData(parameter));
                    var dateRangeCfg = {
                        type: node.type.toLowerCase(),
                        color: node.color,
                    };

                    // Reroll color for multiple dateranges
                    if (rangeCount > 0) {
                        var nextColor = colorSupplier.next();
                        if (nextColor === node.color) {
                            nextColor = colorSupplier.next();
                        }
                        dateRangeCfg.color = nextColor;
                    }
                    config.series.push(dateRangeCfg);
                    rangeCount++;
                });
            });

            $q.all(requests).then(
                function (responses) {
                    var result = responses.filter(function (dataResponse) {
                        return Tools.isDefinedNotNull(dataResponse.data.data);
                    });
                    if (result.length === 0) {
                        _stopZoom();
                        return;
                    }
                    for (var i = 0; i < responses.length; i++) {
                        var response = responses[i];
                        if (Tools.isDefinedNotNull(response.data.data.data)) {
                            var parameter = response.parameter;
                            if (parameter !== undefined) {
                                var format = parameter.format;
                                tempSetting = temp;
                                var tooltip = {
                                    isBool: parameter.IS_BOOL,
                                    valueSuffix: ' ' + format.suffix,
                                    format: format,
                                    pointFormatter: stdTooltipFormatter
                                };
                                if (parameter.format.id === 25) {
                                    format = parameter.FORMATLIVE;
                                    parameter.format = parameter.FORMATLIVE;
                                    parameter.isLive = true;
                                    tooltip = {
                                        isBool: parameter.IS_BOOL,
                                        format: format,
                                        pointFormatter: liveTooltipFormatter
                                    };
                                }

                                config.series[i] = {
                                    name: parameter.title,
                                    data: _getResponseData(response),
                                    dataHash: parameter.HASH, // try to identify the selected datapoint
                                    tooltip: tooltip,
                                    yAxis: buildYAxisIfNecessary(temp, config.options.yAxis, parameter),
                                    xAxis: buildXAxisIfNecessary(config.options.xAxis, {
                                        dateRangeId: parameter.dateRangeId,
                                        start: parameter.start,
                                        end: parameter.end
                                    }),
                                    type: config.series[i].type,
                                    color: config.series[i].color,
                                    lineWidth: 1
                                };
                                // Special rendering option for correctly show alarms & errorlogs
                                if (parameter.MODE !== undefined && parameter.MODE !== null) {
                                    if (parameter.MODE === 'ERRORLOG') {
                                        config.series[i].step = 'center';
                                    }
                                }
                            }
                        }
                    }
                    if (showLimitsFlag) {
                        if (parameter !== undefined) {
                            var last = config.series.length - 1;
                            if (selectedHighDp !== '' && Tools.isDefinedNotNull(selectedHighDp)) {
                                var highHash = selectedHighDp.parameter.HASH;
                                var highLimitSerie = {
                                    name: "High Limit Line: " + selectedHighDp.title + selectedHighDp.path,
                                    data: _buildHighLowLimitDataSet(_findSerieDataX(config.series, highHash), highLimit),
                                    tooltip: _findSerieToolTip(config.series, highHash),
                                    yAxis: _findSerieYAxis(config.series, highHash),
                                    xAxis: _findSerieXAxis(config.series, highHash),
                                    type: _findSerieType(config.series, highHash),
                                    color: highColor, // selected highlimitcolor
                                    lineWidth: 1
                                }
                                if (Tools.isDefinedNotNull(highLimitSerie)) {
                                    config.series.push(highLimitSerie);
                                }
                            }
                            if (selectedLowDp !== '' && Tools.isDefinedNotNull(selectedLowDp)) {
                                var lowHash = selectedLowDp.parameter.HASH;
                                var lowLimitSerie = {
                                    name: "Low Limit Line: " + selectedLowDp.title + selectedLowDp.path,
                                    data: _buildHighLowLimitDataSet(_findSerieDataX(config.series, lowHash), lowLimit),
                                    tooltip: _findSerieToolTip(config.series, lowHash),
                                    yAxis: _findSerieYAxis(config.series, lowHash),
                                    xAxis: _findSerieXAxis(config.series, lowHash),
                                    type: _findSerieType(config.series, lowHash),
                                    color: lowColor, // selected LowLimitcolor
                                    lineWidth: 1
                                }
                                if (Tools.isDefinedNotNull(lowLimitSerie)) {
                                    config.series.push(lowLimitSerie);
                                }
                            }
                        }

                    }


                    $log.debug("chart - dateranges:", settings.dateRanges);

                    config.options.chart.height += 20 * config.options.xAxis.length;
                    $log.debug("chart - config:", config);
                    if (onComplete) {
                        onComplete(config);
                        if (zoom) {
                            onComplete(config, true);
                        }
                    }
                    config.series = _removeNullDataSeries(config.series);
                }
            );
        }

        return {
            buildConfiguration: buildConfiguration,
            liveTooltipFormatter: liveTooltipFormatter,
            stdTooltipFormatter: stdTooltipFormatter,
            chartTypes: [
                'Line',
                'Spline',
                'Column',
                'Bar',
                'Area',
                'Polygon',
                'Waterfall'
            ]
        };
    }

    angular
        .module('emsv2App')
        .factory('highchart', highchartFactory);
})();
