(function () {
    'use strict';

    angular.module('emsv2App').service('LocationDetailService', function ($http, $rootScope, $state) {

        this.onSave = null;
        this.onBack = null;
        this.onEdit = null;
        this.canSave = null;
        this.canChange = true;
        this.onlyActiveTab = null;
        this.alarmsOnlyActiveTab = null;

        function reset() {
            this.onSave = null;
            this.onBack = function() {
                $state.go( "locations",  { reload: true });
            };
            this.onEdit = null;
            this.canSave = null;
            this.canChange = true;
            this.onlyActiveTab = null;
            this.alarmsOnlyActiveTab = null;
            return this;
        }

        function setDefaultBackToOverview() {
            this.onBack = function() {
                $state.go( "location.overview", {mode: 'view'}, { reload: true });
            };
        }

        return {
            reset: reset,
            setDefaultBackToOverview: setDefaultBackToOverview,
            onSave: this.onSave,
            onBack: this.onBack,
            onEdit: this.onEdit,
            canSave: this.canSave,
            canChange: this.canChange,
            onlyActiveTab: this.onlyActiveTab,
            alarmsOnlyActiveTab: this.alarmsOnlyActiveTab
        };
    });
})();
