(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('LegalConfirmController', function ($state, $log, $translate, Auth, Account, currentUser, Language, License, $window) {

            var ctrl = this;
            ctrl.collapse = {
                terms: true, maintenance: true, privacy: true, data: true
            };
            ctrl.readAndAcceptedTermsOfUseOnly = false;
            ctrl.readAndAcceptedTermsOfUse = false;
            ctrl.readAndAcceptedMaintenanceTerms = false;
            ctrl.readAndAcceptedDataProcessingAgreement = false;
            ctrl.readAndAcceptedTermsOfUseWithPrivacy = false;
            ctrl.statusHostedPublicly = false;

            ctrl.specification = "";
            ctrl.pricelist = "";
            ctrl.technicalAndOrganizationalMeasures = "";

            ctrl.$onInit = function () {
                License.get().then(function (response) {
                    ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
                    Language.getCurrent().then(function (result) {
                        ctrl.language = result;
                        if (ctrl.language === "de") {
                            ctrl.specification = $window.location.origin + "/legal/Leistungsverzeichnis.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/Preisliste.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnischeUndOrganisatorischeMassnahmen.pdf";
                        } else {
                            ctrl.specification = $window.location.origin + "/legal/Specification.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/PriceList.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnicalAndOrganizationalMeasures.pdf";
                        }
                        if (ctrl.statusHostedPublicly) {
                            ctrl.termsInfo = $translate.instant("legal.termsInfo", {
                                name: $translate.instant('legal.terms.title')
                            });
                        } else {
                            ctrl.termsInfo = $translate.instant("legal.termsInfo", {
                                name: $translate.instant('legal.terms.title') + ", " +
                                    $translate.instant('legal.maintenance.title') +
                                    $translate.instant('legal.and') +
                                    $translate.instant('legal.data.title')
                            });
                        }
                    });
                })
            }
            ctrl.decline = function () {
                Auth.logout();
            };

            ctrl.accept = function () {
                if (ctrl.readAndAcceptedTermsOfUseOnly ||
                    (ctrl.readAndAcceptedTermsOfUse && ctrl.readAndAcceptedMaintenanceTerms && ctrl.readAndAcceptedDataProcessingAgreement)) {
                    currentUser.terms = true;
                    Account.updateAccount(currentUser).then(function () {
                        $log.log("User Accepted Terms");
                        $state.go("home");
                    }, function (error) {
                        $log.error('Couldn\'t update user terms: ', error);
                    });
                }
            }

        });
})();
