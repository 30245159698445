(function () {

    'use strict';

    angular.module('emsv2App').factory('SecureCookieInterceptor', function ($q, $cookies, $rootScope, $timeout) {

        return {

            // optional method
            'request': function(config) {
                // do something on success
                var isApiCall = config.url && config.url.indexOf('api') >= 0;
                if(isApiCall){
                    $cookies.put('secure_sync', 'SYNC', {secure: true, SameSite : 'lax'});
                }

                return config;
            },

            response: function (response) {
                // do something on success
                var ackCookie = $cookies.get('secure_ack');

                var broadcastCooke = $cookies.get('sec_connection_broadcast');
                if(!ackCookie && !broadcastCooke){
                    $timeout(function () {
                        $rootScope.$broadcast("unsecure_connection_intercepted");
                    }, 250);
                }

                return response;
            },
            responseError: function (response) {
                // do something on error
                return $q.reject(response);
            }
        };
    });

    angular.module('emsv2App').config(function ($httpProvider) {
        $httpProvider.interceptors.push('SecureCookieInterceptor');
    });


})();