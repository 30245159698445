(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Contacts
     * @requires $http
     */
    angular.module('emsv2App').service('Contacts', function ($http) {
        return {
            getAll: function () {
                return $http.get('api/contacts');
            },
            getCompactList: function () {
                return $http.get('api/contacts/compact');
            },
            /** @param {number} id */
            getOne: function (id) {
                return $http.get('api/contacts/' + id);
            },
            /** @param {Object} contact */
            save: function (contact) {
                return $http.post('api/contacts', contact);
            },
            /** @param {number} id */
            delete: function (id) {
                return $http.delete('api/contacts/' + id);
            },
            deleteSelection : function (idlist) {
                return $http.post('api/contacts/delete', idlist);
            },
            /**
             * @param {number} page
             * @param {string} query
             */
            rsql: function (page, query) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/contacts/query/' + page + '/20/' + query);
            }
        }
    });

})();
