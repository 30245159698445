(function () {
    'use strict';

    angular.module('emsv2App').controller('FloorViewController', function ($rootScope, $scope, $stateParams, $state, AssetService, MessageService, Object3DFactory, WebGLService, Buildings, user, rooms, buildings) {
        var ctrl = this;

        ctrl.overlayMessage = "room.glCtrl.loadingData";
        ctrl.floor = $stateParams.floorid;
        ctrl.goBack = function () {
            $rootScope.back();
        };

        var initGL = function () {
            WebGLService.init('glContainer', {
                widthPercent: 100,
                heightPercent: 'adjust',
                addPickplane: false,
                antialias: user.graphics.aa == "1" ? true : false
            });
            var floorData = Object3DFactory.buildFloorView(rooms);
            WebGLService.add3DObjectToContent(floorData[0], floorData[2]);
            WebGLService.switch2D();
            WebGLService.setActiveCameraProps(new THREE.Vector3(floorData[1].x / 2, 1, 0), new THREE.Vector3(floorData[1].x / 2, 0, 0));
            // WebGLService.zoomOrtho(floorData[1].x * 0.75, floorData[1].z, new THREE.Vector3(floorData[1].x / 2, 0, 0), true);
            WebGLService.disableControlsRotate();
            WebGLService.bindMouseHandler(undefined, undefined, undefined, handleDoubleClick);
            MessageService.publish("client:showObject", "floorview");
        };

        var handleDoubleClick = function (e) {
            var i = WebGLService.pickObjects(e, WebGLService.getSceneObject());
            if (i.length > 0) {
                switch (i[0].object.name) {
                    case "asset":
                    case "cooling":
                    case "sensor":
                    case "ups":
                    case "rack":
                    case "floor":
                    case "raisedFloor":
                        var id = findParentRoomIdForObject(i[0].object);
                        $state.go("location.rooms.view", {roomid: id, roomMode: "view"});
                        break;
                    case "roomNamePlate":
                        $state.go("location.rooms.view", {roomid: i[0].object.userData.id, roomMode: "view"});
                        break;
                }
            }
        };

        var findParentRoomIdForObject = function (obj) {
            while (obj.parent !== null && obj.parent.name != "roomObj") {
                obj = obj.parent;
            }
            return obj.parent.userData.id;
        };

        ctrl.$onInit = function () {
            Buildings.setupRooms(buildings, rooms);
            rooms = rooms.filter(function (r) {return r.buildingId === parseInt($stateParams.bid);});

            if (AssetService.isReady()) {
                initGL();
            }
            else {
                WebGLService.showWaitingContainer();
                AssetService.loadData(function () {
                    WebGLService.hideWaitingContainer();
                    initGL();
                });
            }
        };

        $scope.$on("$destroy", function () {
            WebGLService.cleanContent();
            WebGLService.cleanObject(WebGLService.getSceneObject());
            WebGLService.destroy();
        });

    });

})();
