(function () {

    'use strict';

    var getBuildings = ['$log', '$stateParams', 'Buildings', function ($log, $stateParams, Buildings) {
        return Buildings.getByLocation($stateParams.id).then(function (response) {
            $log.debug('Loaded buildings: ', response.data);
            var buildings = [];
            for (var i in response.data) {
                buildings.push(Building.parseFromHtmlObject(response.data[i]));
            }
            return buildings;
        }, function (error) {
            $log.error('Couldn\'t load buildings for location ' + $stateParams.id, error);
            return [];
        });
    }];

    var getDevices = ['$log', '$stateParams', 'Location', function ($log, $stateParams, Location) {
        return Location.getDevices($stateParams.id).then(function (response) {
            $log.debug('Loaded devices: ', response);
            return response.data;
        }, function (error) {
            $log.error('Couldn\'t load devices!', error);
            return [];
        });
    }];

    var getRooms = ['$log', '$stateParams', 'RoomService', 'Tools', function ($log, $stateParams, RoomService, Tools) {
        if (Tools.isDefinedNotNull($stateParams.bid)) {
            return RoomService.getSimpleRoomsByBuilding($stateParams.bid).then(function (response) {
                $log.debug("Loaded rooms: ", response);
                var rooms = [];
                for (var i in response.data) {
                    rooms.push(Room.parseFromHtmlObject(response.data[i]));
                    rooms[rooms.length - 1].showsTree = false;
                    rooms[rooms.length - 1].ffloor = response.data[i].floor;
                }
                return rooms;
            }, function (error) {
                $log.error("Error retrieving room data for location.\n" + error);
            });
        }
        else { return []; }
    }];

    var getUser = ['$log', 'Principal', function ($log, Principal) {
        return Principal.identity().then(function (acc) {
            $log.debug("Loaded user information\n" + acc);
            return acc;
        }, function (error) {
            $log.error("Could not get current user information\n" + error);
        });
    }];

    var getBuildingName = ['$log', '$stateParams', '$q', 'Buildings', function ($log, $stateParams, $q, Buildings) {
        if (isNaN($stateParams.bid) || parseInt($stateParams.bid) < 0) return $q.when("building.newBuilding");
        return Buildings.getBuildingName($stateParams.bid).then(function (response) {
            return response.data;
        }, function () {
            $log.error("Error retrieving building name for id: " + $stateParams.bid);
        })
    }];

    var getSelectedBuilding = ['$log', '$state', '$stateParams', 'Buildings', function ($log, $state, $stateParams, Buildings) {
        return Buildings.getBuilding($stateParams.bid).then(function (response) {
            return response.data;
        }, function () {
            $log.error("Error retrieving building for id: " + $stateParams.bid);
            $state.go("notfound");
        })
    }];

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('location.buildings', {
                    parent: 'location.detail',
                    url: '/buildings',
                    data: {
                        roles: ['ROLE_USER'],
                        pageTitle: 'location.home.pageTitle',
                        breadcrumbsTitle: 'location.tab.buildings'
                    },
                    templateUrl: 'scripts/app/location/tabs/building/building.wrapper.html',
                    controller: 'BuildingWrapperController',
                    controllerAs: '$ctrl',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('location');
                            $translatePartialLoader.addPart('room');
                            $translatePartialLoader.addPart('livedata');
                            $translatePartialLoader.addPart('building');
                            return $translate.refresh();
                        }],
                        buildings: getBuildings,
                        devices: getDevices,
                        rooms: getRooms,
                        user: getUser
                    }
                })
                .state('location.buildings.detail', {
                    parent: 'location.buildings',
                    url: '/:bid/:bmode/',
                    data: {
                        roles: ['ROLE_USER'],
                        pageTitle: 'location.home.pageTitle',
                        breadcrumbsTitle: '{{buildingName}}'
                    },
                    templateUrl: 'scripts/app/location/tabs/building/detail/building.detail.html',
                    controller: 'BuildingDetailController',
                    controllerAs: '$ctrl',
                    resolve: {
                        buildingName: getBuildingName,
                        selectedBuilding: getSelectedBuilding,
                        rooms: getRooms
                    }
                })
                .state('location.buildings.new', {
                    parent: 'location.buildings',
                    url: '/create',
                    data: {
                        roles: ['ROLE_USER'],
                        pageTitle: 'location.home.pageTitle',
                        breadcrumbsTitle: 'location.home.createBuilding'
                    },
                    templateUrl: 'scripts/app/location/tabs/building/new/building.new.html',
                    controller: 'BuildingNewController',
                    controllerAs: '$ctrl'
                })
        });
})();
