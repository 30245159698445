"use strict";

/**
 * @description Class to describe a oriented bounding box
 * @param {THREE.Vector3} c the center position to set for the obb
 * @param {THREE.Vector3} e vector describing the objects width/height/depth, all values need to be half of the actual value
 *                          exp.: object with 1x2x1 dimension will result in (0.5, 1, 0.5)
 * @param {THREE.Vector3} rot vector describing the objects rotation
 * @constructor
 */
var OBB = function (c, e, rot) {
    this.c = c !== undefined ? c : new THREE.Vector3();//center
    this.e = e !== undefined ? e : new THREE.Vector3();//halfwidth extends for each axis
    this.u = [new THREE.Vector3(1, 0, 0), new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 1)];//local x,y,z axis
    if (rot !== undefined) {
        if (rot.y !== 0) this.rotateY(rot.y);
    }
    this.rotation = 0;
    this.rotationZ = 0;
    this.rotationX = 0;
};
OBB.prototype = {};
OBB.prototype.constructor = OBB;

/**
 * @description function to get closest distance of this obb to the provided plane
 * @param {THREE.Plane} plane the plane to compute distance for
 * @returns array of length 2, first element is the corner point of the obb which is closest to the plane,
 *                the second element is the actual distance to the plane, used for alignment of objects
 */
OBB.prototype.getClosestDistanceToPlane = function (plane) {
    var points = this.getPoints(false);
    var dp = Infinity;
    var p = null;
    for (var i = 0; i < points.length; i++) {
        var ddp = plane.distanceToPoint(points[i]);
        if (ddp > 0 && ddp < dp) {
            dp = ddp;
            p = points[i].clone();
        }
    }
    return [p, dp];
};

/**
 * @description function to run basic spherical intersection test for this bounding box and the provided box,
 * @param {OBB} box the box to check intersection
 * @returns {boolean} returns true if spheres are intersecting otherwise false
 */
OBB.prototype.checkSphereIntersection = function (box) {
    var tmax = this.e.length();
    var bmax = box.e.length();
    var d = this.c.distanceTo(box.c);
    return d < tmax + bmax ? true : false;
};

/**
 * @description function to run full intersection test for this bounding box and the provided box
 * @param {OBB} box the box to check intersection with
 * @returns {boolean} returns true if this box and the provided box intersect, otherwise false
 */
OBB.prototype.isIntersectionBox = function (box) {
    if (!this.checkSphereIntersection(box)) {
        return false;
    }
    var epsilon = 0.00001;
    var rThis, rBox;
    var a, ra, rb;
    var mat3 = new Array(3);
    var absR = new Array(3);
    for (var i = 0; i < 3; i++) {
        mat3[i] = new Array(3);
        for (var j = 0; j < 3; j++) {
            mat3[i][j] = this.u[i].dot(box.u[j]);
        }
    }
    var t = new THREE.Vector3().subVectors(box.c, this.c);
    a = t.clone();
    t = new THREE.Vector3(a.dot(this.u[0]), a.dot(this.u[1]), a.dot(this.u[2]));
    for (var i = 0; i < 3; i++) {
        absR[i] = new Array(3);
        for (var j = 0; j < 3; j++) {
            absR[i][j] = Math.abs(mat3[i][j]) + epsilon;
        }
    }
    //axes test
    for (var i = 0; i < 3; i++) {
        if (i == 0) ra = this.e.x;
        if (i == 1) ra = this.e.y;
        if (i == 2) ra = this.e.z;
        rb = box.e.x * absR[i][0] + box.e.y * absR[i][1] + box.e.z * absR[i][2];
        if (i == 0 && Math.abs(t.x) > ra + rb) return false;
        if (i == 1 && Math.abs(t.y) > ra + rb) return false;
        if (i == 2 && Math.abs(t.z) > ra + rb) return false;
    }
    for (var i = 0; i < 3; i++) {
        ra = this.e.x * absR[0][i] + this.e.y * absR[1][i] + this.e.z * absR[i][2];
        if (i == 0) rb = box.e.x;
        if (i == 1) rb = box.e.y;
        if (i == 2) rb = box.e.z;
        if (Math.abs(t.x * mat3[0][i] + t.y * mat3[1][i] + t.z * mat3[2][i]) > ra + rb) {
            return false;
        }
    }
    //l = a0 x b0
    ra = this.e.y * absR[2][0] + this.e.z * absR[1][0];
    rb = box.e.y * absR[0][2] + box.e.z * absR[0][1];
    if (Math.abs(t.z * mat3[1][0] - t.y * mat3[2][0]) > ra + rb) {
        return false;
    }
    //l = a0 x b1
    ra = this.e.y * absR[2][1] + this.e.z * absR[1][1];
    rb = box.e.x * absR[0][2] + box.e.z * absR[0][0];
    if (Math.abs(t.z * mat3[1][1] - t.y * mat3[2][1]) > ra + rb) {
        return false;
    }
    //l = a0 x b2
    ra = this.e.y * absR[2][2] + this.e.z * absR[1][2];
    rb = box.e.x * absR[0][1] + box.e.y * absR[0][0];
    if (Math.abs(t.z * mat3[1][2] - t.y * mat3[2][2]) > ra + rb) {
        return false;
    }
    //l = a1 x b0
    ra = this.e.x * absR[2][0] + this.e.z * absR[0][0];
    rb = box.e.y * absR[1][2] + box.e.z * absR[1][1];
    if (Math.abs(t.x * mat3[2][0] - t.z * mat3[0][0]) > ra + rb) {
        return false;
    }
    // l = a1 x b1
    ra = this.e.x * absR[2][1] + this.e.z * absR[0][1];
    rb = box.e.x * absR[1][2] + box.e.z * absR[1][0];
    if (Math.abs(t.x * mat3[2][1] - t.z * mat3[0][1]) > ra + rb) {
        return false;
    }

    //l = a1 x b2
    ra = this.e.x * absR[2][2] + this.e.z * absR[0][2];
    rb = box.e.x * absR[1][1] + box.e.y * absR[1][0];
    if (Math.abs(t.x * mat3[2][2] - t.z * mat3[0][2]) > ra + rb) {
        return false;
    }

    //l = a2 x b0
    ra = this.e.x * absR[1][0] + this.e.y * absR[0][0];
    rb = box.e.y * absR[2][2] + box.e.z * absR[2][1];
    if (Math.abs(t.y * mat3[0][0] - t.x * mat3[1][0]) > ra + rb) {
        return false;
    }

    //l = a2 x b1
    ra = this.e.x * absR[1][1] + this.e.y * absR[0][1];
    rb = box.e.x * absR[2][2] + box.e.z * absR[2][0];
    if (Math.abs(t.y * mat3[0][1] - t.x * mat3[1][1]) > ra + rb) {
        return false;
    }

    //l = a2 x b2
    ra = this.e.x * absR[1][2] + this.e.y * absR[0][2];
    rb = box.e.x * absR[2][1] + box.e.y * absR[2][0];
    if (Math.abs(t.y * mat3[0][2] - t.x * mat3[1][2]) > ra + rb) {
        return false;
    }

    return true;
};

/**
 * @description function to run intersection test for this box and the provided array of boxes
 * @param {OBB[]} boxes array of oriented bounding boxes
 * @returns {boolean} returns true if this box intersects with a box in provided array, otherwise false
 */
OBB.prototype.isIntersectionBoxes = function (boxes) {
    if (boxes === undefined || boxes.length == 0) return false;
    for (var i = 0; i < boxes.length; i++) {
        if (this.isIntersectionBox(boxes[i])) return true;
    }
    return false;
};

/**
 * @description function to rotate the bounding box around its x-axis by provided angle in radians
 * @param {number} angle the angle to rotate the box in radians
 */
OBB.prototype.rotateX = function (angle) {
    var axis = new THREE.Vector3(1, 0, 0);
    var uy = new THREE.Vector3(0,1,0).applyAxisAngle(axis, angle);
    var uz = new THREE.Vector3(0,0,1).applyAxisAngle(axis, angle);
    this.u[1] = uy.normalize();
    this.u[2] = uz.normalize();
    this.rotationX += angle;
};

/**
 * @description function to rotate the bounding box around its y-axis by provided angle in radians
 * @param {number} angle the angle to rotate the box in radians
 */
OBB.prototype.rotateY = function (angle) {
    var axis = new THREE.Vector3(0, 1, 0);
    var ux = new THREE.Vector3(1,0,0).applyAxisAngle(axis, angle);
    var uz = new THREE.Vector3(0,0,1).applyAxisAngle(axis, angle);
    this.u[0] = ux.normalize();
    this.u[2] = uz.normalize();
    this.rotation = angle;
};

/**
 * @description function to rotate the bounding box around its z-axis by provided angle in radians
 * @param {number} angle the angle to rotate the box in radians
 */
OBB.prototype.rotateZ = function (angle) {
    var ux, uy;
    var axis = new THREE.Vector3(0, 0, 1);
    ux = new THREE.Vector3(1,0,0).applyAxisAngle(axis, angle);
    uy = new THREE.Vector3(0,1,0).applyAxisAngle(axis, angle);
    this.u[0] = ux.normalize();
    this.u[1] = uy.normalize();
    this.rotationZ += angle;
};

/**
 * @description function to set the y-rotation of this object
 * @param {number} angle the angle to rotate in radians
 */
OBB.prototype.setRotationY = function (angle) {
    this.u[0] = cV(1, 0, 0);
    var ux = rotateYVec3(this.u[0], angle);
    this.u[0] = ux;
    this.u[1] = cV(0, 1, 0);
    var uy = rotateYVec3(this.u[1], angle);
    this.u[1] = uy;
    this.u[2] = cV(0, 0, 1);
    var uz = rotateYVec3(this.u[2], angle);
    this.u[2] = uz;
    this.rotation = angle;
};

/**
 * @description function to find the closest corner point to the provided point
 * @param {THREE.Vector3} p the point to use
 * @returns {*} returns the closest corner point to the provided point
 */
OBB.prototype.findClosestPoint = function (p) {
    var d = p.clone().sub(this.c);
    var q = this.c.clone();
    for (var i = 0; i < 3; i++) {
        var dist = d.dot(this.u[i]);
        var val;
        if (i === 0) {
            val = this.e.x;
        }
        if (i === 1) {
            val = this.e.y;
        }
        if (i === 2) {
            val = this.e.z;
        }
        if (dist > val) {
            dist = val;
        }
        if (dist < (val * -1)) {
            dist = val * -1;
        }
        q.add(this.u[i].multiplyScalar(dist));
    }
    return q;
};

/**
 * @description function to get the squared distance to the provided point
 * @param {THREE.Vector3} p the point to check for
 * @returns {*} returns squared distance of closest obb corner point to the provided point
 */
OBB.prototype.getDistanceSquaredToPoint = function (p) {
    var a = this.findClosestPoint(p);
    var b = a.clone().sub(p);
    return b.dot(b);
};

/**
 * @description function to find the unit vector of this box which is closest to the provided direction
 * @param {THREE.Vector3} testDir the direction to find closest unit vector for
 * @returns {number}
 */
OBB.prototype.getClosestDir = function (testDir) {
    for (var i = 0; i < this.u.length; i++) {
        var td = this.u[i].clone();
        var a = td.angleTo(testDir);
        var b = td.clone().multiplyScalar(-1).angleTo(testDir)
        if (a < Math.PI / 6) {
            return i + 3;
        }
        if (b < Math.PI / 6) {
            return -(i + 3);
        }
    }
};

/**
 * @description function to build debug mesh to add to 3d-context
 * @returns {THREE.Mesh} returns THREE.Mesh
 */
OBB.prototype.buildHelper = function () {
    var geo = new THREE.BoxGeometry(this.e.x * 2, this.e.y * 2, this.e.z * 2);
    var mat = new THREE.MeshBasicMaterial({color: 0xffff00, wireframe: true});
    var mesh = new THREE.Mesh(geo, mat);
    mesh.position.set(this.c.x, this.c.y, this.c.z);
    mesh.rotation.y = this.rotation;
    return mesh;
};
/**
 Gets the 8 points of the obb
 return array first half top plane vertices starting with back left on going clock wise second eqauly for bottom plane vertices
 */
OBB.prototype.getPoints = function (translateToOrigin) {
    return this.getTopPlanePoints(translateToOrigin).concat(this.getBottomPlanePoints(translateToOrigin));
};

/**
 * @description function to get the top corner points of this box
 * @param {boolean} translateToOrigin if set to true all point information will be returned as if the box is located in the origin
 * @returns array of points
 */
OBB.prototype.getTopPlanePoints = function (translateToOrigin) {
    var m = this.c.clone();
    var bl = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x * -1));
    bl.add(this.u[1].clone().multiplyScalar(this.e.y));
    bl.add(this.u[2].clone().multiplyScalar(this.e.z * -1));
    var br = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x));
    br.add(this.u[1].clone().multiplyScalar(this.e.y));
    br.add(this.u[2].clone().multiplyScalar(this.e.z * -1));
    var fr = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x));
    fr.add(this.u[1].clone().multiplyScalar(this.e.y));
    fr.add(this.u[2].clone().multiplyScalar(this.e.z));
    var fl = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x * -1));
    fl.add(this.u[1].clone().multiplyScalar(this.e.y));
    fl.add(this.u[2].clone().multiplyScalar(this.e.z));
    if (translateToOrigin) {
        bl.sub(this.c);
        br.sub(this.c);
        fr.sub(this.c);
        fl.sub(this.c);
    }
    return [bl, br, fr, fl];
};

/**
 * @description function to get the bottom corner points of this box
 * @param {boolean} translateToOrigin if set to true all point information will be returned as if the box is located in the origin
 * @returns array of points
 */
OBB.prototype.getBottomPlanePoints = function (translateToOrigin) {
    var m = this.c.clone();
    var bl = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x * -1));
    bl.add(this.u[1].clone().multiplyScalar(this.e.y * -1));
    bl.add(this.u[2].clone().multiplyScalar(this.e.z * -1));
    var br = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x));
    br.add(this.u[1].clone().multiplyScalar(this.e.y * -1));
    br.add(this.u[2].clone().multiplyScalar(this.e.z * -1));
    var fr = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x));
    fr.add(this.u[1].clone().multiplyScalar(this.e.y * -1));
    fr.add(this.u[2].clone().multiplyScalar(this.e.z));
    var fl = m.clone().add(this.u[0].clone().multiplyScalar(this.e.x * -1));
    fl.add(this.u[1].clone().multiplyScalar(this.e.y * -1));
    fl.add(this.u[2].clone().multiplyScalar(this.e.z));
    if (translateToOrigin) {
        bl.sub(this.c);
        br.sub(this.c);
        fr.sub(this.c);
        fl.sub(this.c);
    }

    return [bl, br, fr, fl];
};

/**
 * @description function to get axis aligned sizes for the containing AABB
 * @returns array, 1st element is the x-size, 2nd argument is the z-size
 */
OBB.prototype.getAxisSizes = function () {
    var pts = this.getBottomPlanePoints(true);
    var b = new THREE.Box3().setFromPoints(pts);
    return [b.max.x - b.min.x, b.max.z - b.min.z];
};

/**
 * @description function to get middle point for given axis
 * @param {number} axisIndex the index of this object unit vectors to find midpoint for
 * @param {boolean} back if set to true, negative values will be returned
 * @returns {*} returns middle point on given axis
 */
OBB.prototype.getMidPointAxis = function (axisIndex, back) {
    var axis = this.u[axisIndex].clone();
    var e = axisIndex === 0 ? this.e.x : this.e.z;
    if (back) {
        axis.multiplyScalar(-1)
    }
    return this.c.clone().add(axis.multiplyScalar(e));
};

/**
 * @description function to get the projected length on a given axis
 * @param {THREE.Vector3} axis the axis to project on
 * @returns {number} returns the projected length on the given axis
 */
OBB.prototype.getProjectedLengthOnAxis = function (axis) {
    var ret = 0;
    ret += this.u[0].clone().multiplyScalar(this.e.x).projectOnVector(axis).length();
    ret += this.u[2].clone().multiplyScalar(this.e.z).projectOnVector(axis).length();
    return ret * 2;
};

/**
 * @description function to get string representation for this box
 * @returns {string} returns string object representing this obb information
 */
OBB.prototype.getString = function () {
    return "Pos:" + this.c.x + "|" + this.c.y + "|" + this.c.z + " Extrudes:" + this.e.x + "|" + this.e.y + "|" + this.e.z + "UNITS: x - " + this.u[0].x + "|" + this.u[0].y + "|" + this.u[0].z + "y - " + this.u[1].x + "|" + this.u[1].y + "|" + this.u[1].z + "z - " + this.u[2].x + "|" + this.u[2].y + "|" + this.u[2].z;
};

/**
 * @description function to clone this box
 * @returns {OBB} returns cloned OBB
 */
OBB.prototype.clone = function () {
    var ret = new OBB(this.c.clone(), this.e.clone());
    ret.u[0] = this.u[0].clone();
    ret.u[1] = this.u[1].clone();
    ret.u[2] = this.u[2].clone();
    return ret;
};

/**
 * @description function to check if provided point is contained by this box
 * @param {THREE.Vector3} p the point to check for
 * @returns {boolean} returns true if points is contained in this box, otherwise false
 */
OBB.prototype.containsPoint = function(p){
    var topPoint = this.c.clone().add(this.u[1].clone().multiplyScalar(this.e.y));
    var topPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.u[1].clone().normalize(), topPoint);

    var bottomPoint = this.c.clone().add(this.u[1].clone().multiplyScalar(this.e.y * -1));
    var bottomPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.u[1].clone().multiplyScalar(-1).normalize(), bottomPoint);

    var leftPoint = this.c.clone().add(this.u[0].clone().multiplyScalar(this.e.x*-1));
    var leftPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.u[0].clone().multiplyScalar(-1).normalize(), leftPoint);

    var rightPoint = this.c.clone().add(this.u[0].clone().multiplyScalar(this.e.x));
    var rightPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.u[0].clone(), rightPoint);

    var frontPoint = this.c.clone().add(this.u[2].clone().multiplyScalar(this.e.z));
    var frontPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.u[2].clone(), frontPoint);

    var backPoint = this.c.clone().add(this.u[2].clone().multiplyScalar(this.e.z*-1));
    var backPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.u[2].clone().multiplyScalar(-1).normalize(), backPoint);

    if(topPlane.distanceToPoint(p) <= 0.0 && bottomPlane.distanceToPoint(p) <= 0.0 &&
        leftPlane.distanceToPoint(p) <= 0.0 && rightPlane.distanceToPoint(p) <= 0.0 &&
        frontPlane.distanceToPoint(p) <= 0.0 && backPlane.distanceToPoint(p) <= 0.0) return true;
    return false;

};

