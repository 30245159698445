(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('LogoutController', function ($timeout, $state, Principal, $translate, License, Language, $window) {

            var ctrl = this;
            ctrl.currentYear = null;
            ctrl.statusHostedPublicly = false;
            ctrl.specification = "";
            ctrl.pricelist = "";
            ctrl.technicalAndOrganizationalMeasures = "";

            var _logout = function () {
                Principal.authenticate(null);
                $state.go('login');
            };

            ctrl.$onInit = function () {
                License.get().then(function (response) {
                    ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
                    Language.getCurrent().then(function (language) {
                        ctrl.language = language;
                        if (ctrl.language === "de") {
                            ctrl.specification = $window.location.origin + "/legal/Leistungsverzeichnis.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/Preisliste.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnischeUndOrganisatorischeMassnahmen.pdf";
                        } else {
                            ctrl.specification = $window.location.origin + "/legal/Specification.pdf";
                            ctrl.pricelist = $window.location.origin + "/legal/PriceList.pdf";
                            ctrl.technicalAndOrganizationalMeasures = $window.location.origin + "/legal/TechnicalAndOrganizationalMeasures.pdf";
                        }
                    });
                })

                var year = new Date().getFullYear();
                ctrl.currentYear = (year !== null && year !== undefined) && year > 2014 ?
                    new Date().getFullYear() : $translate.instant("global.currentDate");
                $timeout(function () {
                    _logout();
                }, 3000);
            }

        });

})();
