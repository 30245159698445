'use strict';

/**
 * @description Constructor to create new SnmpConfigurationOfSmartEvent object
 * @constructor
 */
function SnmpConfigurationOfSmartEvent(snmpConfigurationOfSmartEvent) {
    if (snmpConfigurationOfSmartEvent !== undefined) {
        this.id = snmpConfigurationOfSmartEvent.id !== undefined ? snmpConfigurationOfSmartEvent.id : null;
        this.smartEventSnmpId = snmpConfigurationOfSmartEvent.smartEventSnmpId !== undefined ? snmpConfigurationOfSmartEvent.smartEventSnmpId : null;
        this.host = snmpConfigurationOfSmartEvent.host !== undefined ? snmpConfigurationOfSmartEvent.host : null;
        this.port = snmpConfigurationOfSmartEvent.port !== undefined ? snmpConfigurationOfSmartEvent.port : 161;
        this.communityTarget = snmpConfigurationOfSmartEvent.communityTarget !== undefined ? snmpConfigurationOfSmartEvent.communityTarget : null;
    } else {
        this.id = null;
        this.smartEventSnmpId = null;
        this.host = null;
        this.port = 161;
        this.communityTarget = null;
    }
}

SnmpConfigurationOfSmartEvent.prototype = {};
SnmpConfigurationOfSmartEvent.prototype.constructor = SnmpConfigurationOfSmartEvent;