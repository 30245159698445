(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name timestamp
     * @param {number} timestamp - The unix timestamp to format
     */
    angular.module('emsv2App')
        .component('timestamp', {
            bindings: {
                timestamp: '<'
            },
            template: '<span ng-bind="$ctrl.formatted" ng-if="$ctrl.formatted !== null && $ctrl.formatted !== undefined"></span><span ng-if="$ctrl.formatted === null || $ctrl.formatted === undefined" translate="global.na"></span>',
            controller: ['$translate', function ($translate) {
                var ctrl = this;
                var dateFormat = $translate.instant('global.dateTimeFormat');

                ctrl.$onInit = function () {
                    ctrl.formatted = _formatDate(ctrl.timestamp);
                };

                ctrl.$onChanges = function (newValue) {
                    ctrl.formatted = _formatDate(newValue);
                };

                function _formatDate(date) {
                    return date !== null && date !== undefined
                        ? moment(ctrl.timestamp, 'YYYY-MM-DDTHH:mm:ss:SSS').format(dateFormat)
                        : 'N/A'
                }

            }]
        });

})();
