(function () {
    'use strict';

    angular.module('emsv2App').directive('bootstrapPanel', function () {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                heading: '=heading',
                collapse: '=collapse'
            },
            controller: function ($scope, $element) {
                $scope.toggleCollapse = function () {
                    $scope.collapse = !$scope.collapse;
                }
            },
            template: '<div class="ibox"><div ng-show="heading !== undefined" class="ibox-title"><h5 ng-bind="heading"></h5><div ng-if="collapse !== undefined" class="ibox-tools"><a ng-click="toggleCollapse()" class="collapse-link"> <i ng-class="{\'fa\': true, \'fa-chevron-up\': collapse, \'fa-chevron-down\': !collapse}"></i> </a></div></div><div class="ibox-content" ng-class="(heading === undefined) ? \'borderless\' : \'\'" ng-transclude></div></div>'
        };
    });

})();
