'use strict';

/**
 * @description Constructor to create new SnmpConfigurationOfSmartEvent object
 * @constructor
 */
function SnmpV3ConfigurationOfSmartEvent(snmpConfigurationOfSmartEvent) {
    if (snmpConfigurationOfSmartEvent !== undefined) {
        this.id = snmpConfigurationOfSmartEvent.id !== undefined ? snmpConfigurationOfSmartEvent.id : null;
        this.smartEventSnmpId = snmpConfigurationOfSmartEvent.smartEventSnmpId !== undefined ? snmpConfigurationOfSmartEvent.smartEventSnmpId : null;
        this.host = snmpConfigurationOfSmartEvent.host !== undefined ? snmpConfigurationOfSmartEvent.host : null;
        this.port = snmpConfigurationOfSmartEvent.port !== undefined ? snmpConfigurationOfSmartEvent.port : 161;
        this.userName = snmpConfigurationOfSmartEvent.userName !== undefined ? snmpConfigurationOfSmartEvent.userName : null;
        this.authenticationProtocol = snmpConfigurationOfSmartEvent.authenticationProtocol !== undefined ? snmpConfigurationOfSmartEvent.authenticationProtocol : null;
        this.authenticationPassphrase = snmpConfigurationOfSmartEvent.authenticationPassphrase !== undefined ? snmpConfigurationOfSmartEvent.authenticationPassphrase : null;
        this.privacyProtocol = snmpConfigurationOfSmartEvent.privacyProtocol !== undefined ? snmpConfigurationOfSmartEvent.privacyProtocol : null;
        this.privacyPassphrase = snmpConfigurationOfSmartEvent.privacyPassphrase !== undefined ? snmpConfigurationOfSmartEvent.privacyPassphrase : null;
    } else {
        this.id = null;
        this.smartEventSnmpId = null;
        this.host = null;
        this.port = 161;
        this.userName = null;
        this.authenticationProtocol = null;
        this.authenticationPassphrase = null;
        this.privacyProtocol = null;
        this.privacyPassphrase = null;
    }
    this.version = "V3";
}

SnmpV3ConfigurationOfSmartEvent.prototype = {};
SnmpV3ConfigurationOfSmartEvent.prototype.constructor = SnmpV3ConfigurationOfSmartEvent;