(function () {
    'use strict';

    angular.module('emsv2App').directive("genDialog", function () {
        return {
            template:'<div id="genDialog" class="modal fade" ng-controller="GenDialogController as ctrl">\n' +
            '    <div class="modal-dialog" role="document">\n' +
            '        <div class="modal-content">\n' +
            '            <div class="modal-header">\n' +
            '                <button ng-if="ctrl.config.showClose" type="button" class="close" data-dismiss="modal"\n' +
            '                        aria-label="Close">\n' +
            '                    <span aria-hidden="true">&times;</span>\n' +
            '                </button>\n' +
            '                <h4><span class="{{ctrl.config.headIcon}}"></span>&nbsp;{{ctrl.config.headText}}</h4>\n' +
            '            </div>\n' +
            '            <div class="modal-body">\n' +
            '                <div id="genDialogMessage" ng-bind-html="ctrl.config.messageText"></div>\n' +
            '            </div>\n' +
            '            <div class="modal-footer">\n' +
            '                <button type="button" ng-show="ctrl.config.showButton0" class="{{\'btn \'+ctrl.config.classButton0}}"\n' +
            '                        ng-click="ctrl.handleButton0()"><span class="{{ctrl.config.iconButton0}}"></span>&nbsp;<span>{{ctrl.config.textButton0}}</span>\n' +
            '                </button>\n' +
            '                <button type="button" ng-show="ctrl.config.showButton1" class="{{\'btn \'+ctrl.config.classButton1}}"\n' +
            '                        ng-click="ctrl.handleButton1()"><span class="{{ctrl.config.iconButton1}}"></span>&nbsp;<span>{{ctrl.config.textButton1}}</span>\n' +
            '                </button>\n' +
            '                <button type="button" ng-show="ctrl.config.showButton2" class="{{\'btn \'+ctrl.config.classButton2}}"\n' +
            '                        ng-click="ctrl.handleButton2()"><span class="{{ctrl.config.iconButton2}}"></span>&nbsp;<span>{{ctrl.config.textButton2}}</span>\n' +
            '                </button>\n' +
            '            </div>\n' +
            '        </div>\n' +
            '    </div>\n' +
            '</div>'
            // templateUrl: "./scripts/components/gendialog/gendialog.view.html"
        };
    });
})();
