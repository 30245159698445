(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name contactLink
     * @param {Object} ngModel - ID of contact info to show/link to
     */
    angular.module('emsv2App')
        .component('contactLink', {
            bindings: {
                ngModel: '<'
            },
            template: '<span><a ui-sref="contacts-view({id: $ctrl.ngModel.id})" ng-if="$ctrl.label !== \'\'" ng-bind="$ctrl.label"></a></span>',
            controller: [function () {
                var ctrl = this;
                ctrl.label = '';

                ctrl.$onInit = function () {
                    // ctrl.label = ctrl.ngModel;
                    if (ctrl.ngModel !== undefined && ctrl.ngModel !== null && ctrl.ngModel !== '') {
                        if (ctrl.ngModel.lastname !== undefined && ctrl.ngModel.lastname !== null && ctrl.ngModel.lastname !== '') {
                            ctrl.label = ctrl.ngModel.lastname;
                        }
                        if (ctrl.ngModel.firstname !== undefined && ctrl.ngModel.firstname !== null && ctrl.ngModel.firstname !== '') {
                            if (ctrl.label.length > 0) {
                                ctrl.label += ', ';
                            }
                            ctrl.label += ctrl.ngModel.firstname;
                        }
                        if (ctrl.ngModel.company !== undefined && ctrl.ngModel.company !== null && ctrl.ngModel.company !== '') {
                            if (ctrl.label.length > 0) {
                                ctrl.label += ' (' + ctrl.ngModel.company + ')';
                            }
                            else {
                                ctrl.label += ctrl.ngModel.company;
                            }
                        }
                    }
                }
            }]
        });

})();
