(function () {

    'use strict';

    angular.module('emsv2App')
        .directive('rsqlQueryEntry', function () {
            return {
                require: ['^rsqlTable', '^rsqlFilter'],
                restrict: 'A',
                bindToController: {
                    query: '<'
                },
                templateUrl: 'scripts/components/rsql/rsqlQueryEntry.directive.html',
                controllerAs: '$ctrl',
                controller: ['$scope', '$log', '$element', '$timeout', 'Filter', function ($scope, $log, $element, $timeout, Filter) {
                    var ctrl = this;
                    ctrl.showDelete = false;
                    ctrl.deleted = false;

                    var dropdown = angular.element($element[0].querySelector('.rsql-dropdown'));
                    var textBox = angular.element(dropdown[0].querySelector('#query-text'));
                    var questionBox = angular.element(dropdown[0].querySelector('#query-question'));

                    ctrl.select = function () {
                        $scope.$emit('rsql-load-query', ctrl.query);
                    };

                    ctrl.close = function (event) {
                        showHideDelete(true);
                        event.stopPropagation();
                    };

                    ctrl.delete = function (result) {
                        if (!result) {
                            showHideDelete(false);
                        }
                        else {
                            Filter.deleteQuery(ctrl.query.table, ctrl.query.id).then(function (response) {
                                if (response.data) {
                                    $log.debug('Deleted query!', ctrl.query, response);
                                    ctrl.deleted = true;
                                    showHideDelete(false);
                                    $timeout(function () {
                                        $scope.$emit('rsql-delete-query', ctrl.query);
                                    }, 1000);
                                }
                                else {
                                    $log.error('Couldn\'t delete query!', ctrl.query);
                                    showHideDelete(false);
                                }
                            }, function (error) {
                                $log.error('Couldn\'t delete query!', ctrl.query, error);
                                showHideDelete(false)
                            });
                        }
                        event.stopPropagation();
                    };

                    function showHideDelete(value) {
                        if (value) {
                            questionBox.removeClass('fadeOutLeft');
                            textBox.addClass('fadeOutLeft');
                        }
                        else {
                            textBox.removeClass('fadeOutLeft');
                            questionBox.addClass('fadeOutLeft');
                        }
                        $timeout(function () {
                            ctrl.showDelete = value;
                        }, 500);
                    }
                }]
            };
        });

})();
