(function () {
    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('license', {
                    parent: 'admin',
                    url: '/license',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_LICENSING'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'license.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/license/license.html',
                            controller: 'LicenseController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('license');
                            return $translate.refresh();
                        }],
                        currentLicense: ['$log', 'License', function ($log, License) {
                            return License.reload().then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error('Couldn\'t load license!', error);
                                return {};
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', function ($log, Principal, $state) {
                            return Principal.identity().then(function (user) {
                                $log.debug("Loading userInfo: ", user);
                                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_LICENSING')) {
                                    return user;
                                }
                                else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error("Couldn't load user ", error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('unlicensed', {
                    parent: 'admin',
                    url: '/unlicensed',
                    data: {
                        roles: [],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: '{{\'errors.title\'|translate}} - Unlicensed'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/license/license.html',
                            controller: 'LicenseController',
                            controllerAs: '$ctrl'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('error');
                            $translatePartialLoader.addPart('license');
                            return $translate.refresh();
                        }],
                        currentLicense: ['$log', 'License', function ($log, License) {
                            return License.reload().then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error('Couldn\'t load license!', error);
                                return {};
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });

})();
