(function () {
    'use strict';
    var app = angular.module('emsv2App');

    app.directive("delayinput", function () {
        var ID = 0;
        var templateX = '<div ng-form="{{ subFormName }}">' +
            '<input class="form-control pull-left" style="width:70px;" type="number" ng-model="dd" name="dd" class="range-dd" min="-1" step="1" max="100" ng-readonly="readonly"/>' +
            '<p style="float: left; padding: 0 5px; line-height: 30px;">:</p>' +
            '<input class="form-control pull-left" style="width:70px" type="number" ng-model="hh" name="hh" class="range-hh" min="-1" step="1" max="1000" ng-readonly="readonly"/>' +
            '<p style="float: left; padding: 0 5px; line-height: 30px;">:</p>' +
            '<input class="form-control pull-left" style="width:70px" type="number" ng-model="mm" name="mm" class="range-mm" min="-1" step="1" max="10000" ng-readonly="readonly"/>' +
            '</div>';

        function genearteTime(dd, hh, mm, ss) {
            var x = ((dd * 24 * 60 * 60 * 1000) + (hh * 60 * 60 * 1000) + (mm * 60 * 1000) + (ss * 1000));
            return x;
        }

        return {
            require: "ngModel",
            restrict: "E",
            replace: true,
            scope: {
                name: "@",
                readonly: "="
            },
            template: templateX
            ,
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$render = function () {
                    var ms = ngModel.$viewValue;
                    var x = parseInt(ms / 1000);
                    x = parseInt(x / 60);
                    var minutes = x % 60;
                    x = parseInt(x / 60);
                    var hours = x % 24;
                    x = parseInt(x / 24);
                    var days = x;
                    scope.dd = days;
                    scope.hh = hours;
                    scope.mm = minutes;
                };

                scope.$watch("dd", function (newval) {
                    if (newval == undefined) {
                        newval = 0;
                        scope.dd = 0;
                    }
                    else if (newval == -1) {
                        newval = 0;
                        scope.dd = 0;
                    }
                    else if (newval > 0 && newval < 1) {
                        newval = 1;
                        scope.dd = 1;
                    }
                    else if (!Number.isInteger(newval)) {
                        newval = Math.round(newval);
                        scope.dd = Math.round(newval);
                    }
                    var result = genearteTime(newval, scope.hh, scope.mm, 0);
                    ngModel.$setViewValue(result);
                });
                scope.$watch("hh", function (newval) {
                    if (newval == undefined) {
                        newval = 0;
                        scope.hh = 0;
                    }
                    else if (newval == -1) {
                        newval = 0;
                        scope.hh = 0;
                    }
                    else if (newval > 0 && newval < 1) {
                        newval = 1;
                        scope.hh = 1;
                    }
                    else if (!Number.isInteger(newval)) {
                        newval = Math.round(newval);
                        scope.hh = Math.round(newval);
                    }
                    var result = genearteTime(scope.dd, newval, scope.mm, 0);
                    ngModel.$setViewValue(result);
                });
                scope.$watch("mm", function (newval) {
                    if (newval == undefined) {
                        newval = 0;
                        scope.mm = 0;
                    }
                    else if (newval == -1) {
                        newval = 0;
                        scope.mm = 0;
                    }
                    else if (newval > 0 && newval < 1) {
                        newval = 1;
                        scope.mm = 1;
                    }
                    else if (!Number.isInteger(newval)) {
                        newval = Math.round(newval);
                        scope.mm = Math.round(newval);
                    }
                    var result = genearteTime(scope.dd, scope.hh, newval, 0);
                    ngModel.$setViewValue(result);
                });
            }
        };
    });
})();
