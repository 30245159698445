(function () {

    'use strict';

    /**
     * @ngdoc component
     * @name contact
     * @param {Object} ngModel - The contact to edit/view
     * @param {Object[]} countries - List of available countries
     * @param {boolean} edit - Enable edit mode
     * @param {boolean} formInvalid - Form validation
     */
    angular.module('emsv2App')
        .component('contact', {
            bindings: {
                ngModel: '=',
                countries: '=',
                edit: '=',
                formInvalid: '='
            },
            templateUrl: 'scripts/app/contacts/contact/contact.component.html',
            controller: ['$scope', '$log', 'Auth', function ($scope, $log, Auth) {
                var ctrl = this;
                ctrl.currentEmail = ctrl.ngModel.email;
                ctrl.mailsIdentical = false;

                ctrl.forms = {
                    contact: {}
                };

                $scope.$watch(function () { return ctrl.forms.contact.$invalid;}, function (change) {
                    ctrl.formInvalid = change;
                });

                ctrl.onMailChanged = function (email) {
                    if (!email || email < 3 || ctrl.currentEmail === email) {
                        return;
                    }

                    Auth.checkEmailExists(email).then(function (result) {
                        ctrl.forms.contact.email.$setValidity('emailExists', !result.data);
                    }, function (error) {
                        $log.error('Couldn\'t check email.', error);
                    });
                };
            }]
        });

})();
