(function () {

    'use strict';

    angular.module('emsv2App').service('Pages', function ($http) {
        return {
            get: function (title) {
                return $http.get('api/pages/' + title);
            },
            update: function (page) {
                return $http.post('api/pages', page);
            }
        };
    });

})();
