(function () {

    'use strict';

    function locationCreateController($scope, $state, $translate, $window, $log, $q, Location, Tools, Notify, groupList, userList, GenDialogService) {
        var lctrl = this;
        var initLocation = null;
        lctrl.editMode = true;
        lctrl.vars = {
            edit: true,
            showImageSelect: false
        };
        lctrl.location = {
            "id": null,
            "name": null,
            "street": null,
            "plz": null,
            "place": null,
            "country": null,
            "state": null,
            "users": [],
            "groups": [],
            "image": null,
            "pos_lat": null,
            "pos_lng": null,
            "pos_alt": null
        };
        lctrl.userList = userList;
        lctrl.groupList = groupList;
        lctrl.timeZones = [];

        // Concat the URL to the selected device
        lctrl.getFindLocationUrl = function () {
            var url = 'http://maps.digitronic.com/?';
            var result = 'address=';
            if (Tools.isDefinedNotNull(lctrl.location.street)) {
                result += lctrl.location.street;
            }
            if (Tools.isDefinedNotNull(lctrl.location.plz)) {
                if (Tools.isDefinedNotNull(lctrl.location.street)) {
                    result += ',';
                }
                result += lctrl.location.plz;
            }
            if (Tools.isDefinedNotNull(lctrl.location.place)) {
                if (Tools.isDefinedNotNull(lctrl.location.plz)) {
                    result += ' ' + lctrl.location.place;
                } else {
                    result += ',' + lctrl.location.place;
                }
            }
            return url + result;
        };

        lctrl.imageSelect = function () {
            lctrl.vars.showImageSelect = true;
        };

        lctrl.imageDelete = function () {
            lctrl.location.image = null;
        };

        lctrl.onAutoPositionChange = function () {
            if (!lctrl.validateAutoValue()) return;
            var split = String(lctrl.tmp.position.autoValue).split(';');
            lctrl.location.pos_lat = (split.length > 2) ? split[1] : 0;
            lctrl.location.pos_lng = (split.length > 2) ? split[0] : 0;
            lctrl.location.pos_alt = (split.length > 2) ? split[2] : 0;
        };

        lctrl.manualPosition = function () {
            lctrl.tmp.position.select = 'manual';
            lctrl.tmp.position.autoValue = "";
            lctrl.editForm.autoValue.$setValidity("autoValue", true);
        }

        // Concat the URL to the selected device
        lctrl.getFindLocationUrl = function () {
            var url = 'http://maps.digitronic.com/?';
            var result = 'address=';
            if (Tools.isDefinedNotNull(lctrl.location.street)) {
                result += lctrl.location.street;
            }
            if (Tools.isDefinedNotNull(lctrl.location.plz)) {
                if (Tools.isDefinedNotNull(lctrl.location.street)) {
                    result += ',';
                }
                result += lctrl.location.plz;
            }
            if (Tools.isDefinedNotNull(lctrl.location.place)) {
                if (Tools.isDefinedNotNull(lctrl.location.plz)) {
                    result += ' ' + lctrl.location.place;
                } else {
                    result += ',' + lctrl.location.place;
                }
            }
            return url + result;
        };

        lctrl.saveNew = function () {
            var deferred = $q.defer();
            var location = angular.copy(lctrl.location);
            var users = angular.copy(location.users);
            var groups = angular.copy(location.groups);
            delete location.users;
            if (lctrl.selectedTimezone && lctrl.selectedTimezone.id) {
                location.timezone = lctrl.selectedTimezone.id;
            }

            Location.post(location).then(function (response) {
                var result = response.data;
                var jobs = [];
                if (Tools.isDefinedNotNull(users) && users.length > 0) {
                    // Save permissions
                    jobs.push(Location.setUsers(result.id, users).catch(function (error) {
                        $log.error('Could not save user permission for location: ' + location.id, error);
                    }));
                }
                if (Tools.isDefinedNotNull(groups) && groups.length > 0) {
                    jobs.push(Location.setGroups(result.id, groups).catch(function (error) {
                        $log.error('Couldn\'t save groups for new location!', error);
                    }));
                }
                $q.all(jobs).then(function () {
                    deferred.resolve();
                    Notify.success("global.notification.success.title", "location.notification.locationSaved", 2000);
                    $state.go('location.overview', {id: result.id, mode: 'view'});
                }, function (error) {
                    deferred.reject(error);
                    Notify.error("global.notification.error.title", "location.notification.locationSaveFailed", 2000);
                });
            }, function (error) {
                $log.error('Couldn\'t save location!', error);
                deferred.reject(error);
            });
            return deferred.promise;
        };

        lctrl.$onInit = function () {
            lctrl.timeZones = Location.getTimeZones().then(function (response) {
                if (response !== null && response.data !== null) {
                    lctrl.timeZones = response.data;
                    lctrl.selectedTimezone = lctrl.timeZones.find(function (tz) {
                        return tz.timezone.toLowerCase().indexOf("berlin") !== -1;
                    });
                    if (!lctrl.selectedTimezone) {
                        lctrl.selectedTimezone = lctrl.timeZones[0];
                    }
                }
            });
            initLocation = angular.copy(lctrl.location);
        };

        lctrl.compareLocationValues = function (locationA, locationB) {

            var aProps = Object.getOwnPropertyNames(locationA);
            var bProps = Object.getOwnPropertyNames(locationB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            if (locationA["id"] !== locationB["id"]) return true;
            if (locationA["name"] !== locationB["name"]) return true;
            if (locationA["street"] !== locationB["street"]) return true;
            if (locationA["plz"] !== locationB["plz"]) return true;
            if (locationA["place"] !== locationB["place"]) return true;
            if (locationA["country"] !== locationB["country"]) return true;
            if (locationA["state"] !== locationB["state"]) return true;
            if (locationA["image"] !== locationB["image"]) return true;
            if (locationA["pos_lat"] !== locationB["pos_lat"]) return true;
            if (locationA["pos_lng"] !== locationB["pos_lng"]) return true;
            if (locationA["pos_alt"] !== locationB["pos_alt"]) return true;

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (lctrl.evalLocationChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        lctrl.evalLocationChange = function () {
            var currentLocation = angular.copy(lctrl.location);
            if (lctrl.compareLocationValues(initLocation, currentLocation)) return true
        };

        lctrl.validateAutoValue = function () {
            if (lctrl.tmp.position.autoValue === "") {
                lctrl.editForm.autoValue.$setValidity("autoValue", true);
                return true;
            } else {
                    var isValid = /^-?\d+.\d+;-?\d+.\d+;-?\d+.\d+$/.test(String(lctrl.tmp.position.autoValue));
                lctrl.editForm.autoValue.$setValidity("autoValue", isValid);
                return isValid;
            }
        }

        lctrl.validateLatitude = function () {
            if (lctrl.location.pos_lat === "") {
                lctrl.editForm.latitude.$setValidity("latitude", true)
            } else {
                var isValid = /^-?\d+(((\.?,?){1}\d+){1,})*$/.test(String(lctrl.location.pos_lat));
                lctrl.editForm.latitude.$setValidity("latitude", isValid);
            }
        }
        lctrl.validateLongitude = function () {
            if (lctrl.location.pos_lng === "") {
                lctrl.editForm.pos_lng.$setValidity("longitude", true)
            } else {
                var isValid = /^-?\d+(((\.?,?){1}\d+){1,})*$/.test(String(lctrl.location.pos_lng));
                lctrl.editForm.longitude.$setValidity("longitude", isValid);
            }
        }
        lctrl.validateAltitude = function () {
            if (lctrl.location.pos_alt === "") {
                lctrl.editForm.altitude.$setValidity("altitude", true)
            } else {
                var isValid = /^-?\d+(((\.?,?){1}\d+){1,})*$/.test(String(lctrl.location.pos_alt));
                lctrl.editForm.altitude.$setValidity("altitude", isValid);
            }
        }

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (lctrl.evalLocationChange() && !ignoreNextStateChange && toState.name !== "location.overview") {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('location.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('location.modal.empty.yes'),
                    textButton0: $translate.instant('location.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initLocation = angular.copy(lctrl.location);
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

    }

    angular
        .module('emsv2App')
        .controller('LocationCreateController', locationCreateController);

})();
