(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Report
     * @requires $http
     */
    angular.module('emsv2App').factory('Report', ['$http', function ReportService($http) {
        return {
            create: function (report) {
                return $http.post('api/reports', report);
            },
            canCreate: function () {
                return $http.get('api/reports/canCreate');
            },
            delete: function (id) {
                return $http.delete('api/reports/' + id);
            },
            deleteSelection : function (idlist) {
                return $http.post('api/reports/delete', idlist);
            },
            get: function (id) {
                return $http.get('api/reports/' + id);
            },
            getAll: function () {
                return $http.get('api/reports');
            },
            generate: function (id) {
                return $http.post('api/reports/generate/' + id);
            },
            check: function (id) {
                return $http.get('api/reports/check/' + id);
            },
            getCache: function () {
                return $http.get('api/reports/cache');
            },
            update: function (report) {
                return $http.put('api/reports', report);
            },
            generateReportsToken: function () {
              return $http.get('public/generateToken/reports');
            },
            rsql: function (page, query) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/reports/query/' + page + '/20/' + query);
            }
        };
    }]);

})();
