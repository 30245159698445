(function () {
    'use strict';

    /**
     * Base rsql metadata service.
     * @ngdoc service
     * @name Filter
     * @requires $http
     */
    angular.module('emsv2App').service('RsqlQuery',
        function () {
            // Check all filters and construct a rsql query from it
            function _constructFilterQuery(definitions) {
                var query = [];

                for (var i = 0; i < definitions.length; i++) {
                    if (definitions[i].selected) {
                        var name = definitions[i].field;
                        var value = definitions[i].data;
                        var operator = definitions[i].operator;

                        if (angular.isDefined(name)
                            && name !== null
                            && name !== ''
                            && angular.isDefined(value)
                            && value !== null
                            && value !== ''
                            && angular.isDefined(operator)
                            && operator !== null) {

                            if (query.length > 0) {
                                query.push(' and '); //query.push('%3B');      // Encoded ";" = AND
                            }
                            query.push(name + operator.operator);

                            if (definitions[i].type === 'num' && !Array.isArray(definitions[i].options)) {
                                query.push(value);
                            }
                            else if ((definitions[i].type === 'date')
                                || ((definitions[i].options !== undefined && definitions[i].options !== null && (definitions[i].options.length > 0)))) {
                                if (String(value).indexOf(',') > -1) {
                                    query.push('(' + value + ')');
                                }
                                else {
                                    query.push('"' + value + '"');
                                }
                            }
                            else {
                                query.push('"' + String(value).toLowerCase() + '"');
                            }
                        }
                    }
                }
                return (query.length > 0) ? query.join('') : '';
            }

            function _constructSearchQuery(definitions, searchKey) {
                var query = [];
                if (angular.isDefined(searchKey) && searchKey !== null && searchKey.length > 0) {
                    searchKey = String(searchKey).toLowerCase();

                    for (var i = 0; i < definitions.length; i++) {
                        if (definitions[i].searchable) {
                            // Split up "%foo bar%" to look for "%foo%" and "%bar%"
                            if (String(searchKey).indexOf(' ') > 0) {
                                var split = String(searchKey).split(' ');
                                for (var j = 0; j < split.length; j++) {
                                    if (query.length > 0) {
                                        query.push(' or ');
                                    }
                                    query.push(definitions[i].field + '==' + split[j]);
                                }
                            }
                            else {
                                if (query.length > 0) {
                                    query.push(' or '); //query.push('%2C');  // Use OR only in search, encoded ","
                                }
                                query.push(definitions[i].field + '==' + searchKey);
                            }
                        }
                    }
                }
                return (query.length > 0) ? query.join('') : '';
            }

            function _parse(thequery, definitions, setOrder, addFilter) {
                var query = _parseOperators(thequery);
                // Split ordering information from the query
                if (String(query).indexOf('?') > -1) {

                    var orderField, orderDirection;
                    var splitQuery = String(query).split('?');
                    query = splitQuery[0];
                    if (String(splitQuery[1]).indexOf('&') > -1) {

                        var orderInfo = String(splitQuery[1]).split('&');
                        for (var i = 0; i < orderInfo.length; i++) {
                            if (String(orderInfo[i]).indexOf('=') > -1) {

                                var vars = String(orderInfo[i]).split('=');
                                if (vars[0] === 'orderby') {
                                    orderField = vars[1];
                                }
                                else if (vars[0] === 'direction') {
                                    orderDirection = vars[1] !== 'asc';
                                }
                            }
                        }
                    }
                    setOrder(orderField, orderDirection);
                }

                var expressions = String(query).split(';');

                for (var j = 0; j < expressions.length; j++) {
                    var filterOperator = _getOperatorForExpression(expressions[j]);

                    if (angular.isDefined(filterOperator) && filterOperator !== null) {
                        var parts = String(expressions[j]).split(filterOperator);

                        if (angular.isDefined(parts) && parts !== null && parts.length >= 2) {
                            var filterValue = String(parts[1]).replace(/"/g, '');
                            var defId = _getIndexForName(definitions, parts[0]);

                            if (defId > -1) {
                                if ((filterOperator === '=in=') || (filterOperator === '=out=')) {
                                    // If this is a list we need an array for the value
                                    if (String(filterValue).indexOf('(') > -1) {
                                        // Strip () from comma seperated values.
                                        filterValue = String(filterValue).substring(1, String(filterValue).length - 1);
                                    }
                                    filterValue = String(filterValue).split(',');
                                }
                                addFilter(definitions[defId], filterOperator, filterValue);
                            }
                        }
                    }
                }
            }

            function _getIndexForName(array, filterName) {
                return _.findIndex(array, function (el) {
                    return el.field === filterName;
                });
            }

            function _getOperatorForExpression(expression) {
                var filterOperator = null;
                if (String(expression).indexOf('==') > -1) { filterOperator = '=='; }
                else if (String(expression).indexOf('!=') > -1) { filterOperator = '!='; }
                else if (String(expression).indexOf('=lt=') > -1) { filterOperator = '=lt='; }
                else if (String(expression).indexOf('=le=') > -1) { filterOperator = '=le='; }
                else if (String(expression).indexOf('=gt=') > -1) { filterOperator = '=gt='; }
                else if (String(expression).indexOf('=ge=') > -1) { filterOperator = '=ge='; }
                else if (String(expression).indexOf('=in=') > -1) { filterOperator = '=in='; }
                else if (String(expression).indexOf('=out=') > -1) { filterOperator = '=out='; }
                return filterOperator;
            }



            function _parseOperators(query) {
                query = String(query).replaceAll(/%3E/g, '>');
                query = String(query).replaceAll(/>=/g, '=ge=');
                query = String(query).replaceAll(/>/g, '=gt=');
                query = String(query).replaceAll(/%3C/g, '<');
                query = String(query).replaceAll(/<=/g, '=le=');
                query = String(query).replaceAll(/</g, '=lt=');
                // query = String(query).replaceAll(' and ', ';');
                query = String(query).replaceAll(/%3B/g, ' and ');
                // query = String(query).replaceAll(' or ', ',');
                query = String(query).replaceAll(/%2C/g, ' or ');
                return query;
            }

            return {
                filterQuery: _constructFilterQuery,
                searchQuery: _constructSearchQuery,
                parse: _parse,
                parseOperators: _parseOperators
            };
        });

})();
