'use strict';

/**
 * @description Constructor to create new outer wall object
 * @param {Number} id
 * @param {Number} startX
 * @param {Number} startY
 * @param {Number} startZ
 * @param {Number} endX
 * @param {Number} endY
 * @param {Number} endZ
 * @param {Number} thickness
 * @param {Number} height
 * @constructor
 */
function OuterWall(id, startX, startY, startZ, endX, endY, endZ, thickness, height) {
    this.id = id !== undefined ? id : -1;
    this.start = {
        x: (startX !== undefined && !isNaN(startX) ? OuterWall.roundValuePrec3(startX) : 0),
        y: (startY !== undefined && !isNaN(startY) ? OuterWall.roundValuePrec3(startY) : 0),
        z: (startZ !== undefined && !isNaN(startZ) ? OuterWall.roundValuePrec3(startZ) : 0)
    };
    this.end = {
        x: (endX !== undefined && !isNaN(endX) ? OuterWall.roundValuePrec3(endX) : 0),
        y: (endY !== undefined && !isNaN(endY) ? OuterWall.roundValuePrec3(endY) : 0),
        z: (endZ !== undefined && !isNaN(endZ) ? OuterWall.roundValuePrec3(endZ) : 0)
    };
    this.start = new THREE.Vector3(this.start.x, this.start.y, this.start.z);
    this.end = new THREE.Vector3(this.end.x, this.end.y, this.end.z);
    this.thickness = thickness !== undefined && thickness !== null && !isNaN(thickness) ? thickness : 0.1;
    this.height = height !== undefined && height !== null && !isNaN(height) ? height : 4;
    this.ignoreInValidation = false;
}

OuterWall.prototype = {};
OuterWall.prototype.constructor = OuterWall;

/**
 * @description Function to test if this outer wall intersects provided outer wall
 * @param {OuterWall} outerWall the outer wall to use
 * @param {boolean} ignoreEndPoints if set to true endpoints will be ignored, otherwise intersection at endpoints will be considered as intersection
 * @returns {boolean} returns true if walls intersect, otherwise false
 */
OuterWall.prototype.checkIntersection = function (outerWall, ignoreEndPoints) {
    var thisStart = new THREE.Vector2(this.start.x, this.start.z);
    var thisEnd = new THREE.Vector2(this.end.x, this.end.z);
    var otherStart = new THREE.Vector2(outerWall.start.x, outerWall.start.z);
    var otherEnd = new THREE.Vector2(outerWall.end.x, outerWall.end.z);

    var ht0 = (((otherEnd.x - otherStart.x) * (thisStart.y - thisEnd.y)) - ((thisStart.x - thisEnd.x) * (otherEnd.y - otherStart.y)));
    var t0 = (((otherStart.y - otherEnd.y) * (thisStart.x - otherStart.x)) + ((otherEnd.x - otherStart.x) * (thisStart.y - otherStart.y))) / ht0;
    var t1 = (((thisStart.y - thisEnd.y) * (thisStart.x - otherStart.x)) + ((thisEnd.x - thisStart.x) * (thisStart.y - otherStart.y))) / ht0;
    if (ignoreEndPoints) {
        if (t0 > 0 && t0 < 1 && t1 > 0 && t1 < 1) return true;
    }
    else {
        if (t0 >= 0 && t0 <= 1 && t1 >= 0 && t1 <= 1) return true;
    }
    return false;
};

/**
 * @description Function to round value to 3 decimal places
 * @param {Number} val the value to round
 * @returns {number} returns rounded value
 */
OuterWall.roundValuePrec3 = function (val) {
    return Math.round(val * 1000) / 1000;
};

