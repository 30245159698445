angular.module('emsv2App').service('DateConstants', ['$translate', function ($translate) {
    return {
        getDaysOfWeek: function () {
            return [
                $translate.instant("global.daysOfWeek.sunday").substring(0, 2),
                $translate.instant("global.daysOfWeek.monday").substring(0, 2),
                $translate.instant("global.daysOfWeek.tuesday").substring(0, 2),
                $translate.instant("global.daysOfWeek.wednesday").substring(0, 2),
                $translate.instant("global.daysOfWeek.thursday").substring(0, 2),
                $translate.instant("global.daysOfWeek.friday").substring(0, 2),
                $translate.instant("global.daysOfWeek.saturday").substring(0, 2)
            ];
        },
        getMonthNames: function () {
            return [
                $translate.instant("global.monthNames.january").substring(0, 3),
                $translate.instant("global.monthNames.february").substring(0, 3),
                $translate.instant("global.monthNames.march").substring(0, 3),
                $translate.instant("global.monthNames.april").substring(0, 3),
                $translate.instant("global.monthNames.may").substring(0, 3),
                $translate.instant("global.monthNames.june").substring(0, 3),
                $translate.instant("global.monthNames.july").substring(0, 3),
                $translate.instant("global.monthNames.august").substring(0, 3),
                $translate.instant("global.monthNames.september").substring(0, 3),
                $translate.instant("global.monthNames.october").substring(0, 3),
                $translate.instant("global.monthNames.november").substring(0, 3),
                $translate.instant("global.monthNames.december").substring(0, 3)
            ];
        }
    }
}]);
