(function () {

    'use strict';

    function ebalanceController($scope, $state, $stateParams, $log, $translate, $interval, EnergyConsumerService,
                                Notify, GenDialogService, LocationDetailService, consumer, userInfo, $uibModal,oldConsumer) {


        var ebctrl = this;
        var currentModal = null;
        ebctrl.trans = null;
        ebctrl.consumerNameExists = false;
        ebctrl.chart = null;
        ebctrl.editState = false;
        ebctrl.energyFactors = [];
        ebctrl.consumers = [];
        ebctrl.oldconsumers = [];
        ebctrl.consumerLength = 0;
        ebctrl.consider = true;
        ebctrl.realtimeValue = {
            itload: 0,
            itLoadValue: 0,
            facilityLoad: 0,
            facilityLoadValue: 0,
            pue: 0,
            unit: ""
        };
        ebctrl.consumer = consumer;
        ebctrl.oldConsumer = oldConsumer;
        ebctrl.facilityCost = 0;
        ebctrl.costPerKwH = 30;
        ebctrl.sortCol = 1;
        ebctrl.sortColImpl = "name | translate";
        ebctrl.allowEdit = userInfo.roles.indexOf('ROLE_LOCATION_EDIT') !== -1;

        ebctrl.editConsumer = {
            name: $translate.instant('room.consumer.newConsumer'),
            consumer: false,
            oldConsumer: false,
            groupId: null,
            id: null,
            uniqueId: null
        };

        ebctrl.loadOptions = [
            {id: 0, name: $translate.instant("ebalance.energyFactor.loadType.facility")},
            {id: 1, name: $translate.instant("ebalance.energyFactor.loadType.it")},
            {id: 2, name: $translate.instant("ebalance.energyFactor.loadType.other")},
        ];
        ebctrl.physicalOptions = [
            {id: 12, name: $translate.instant("consumer")},
        ];

        ebctrl.isPueSettingsOpen = false;
        ebctrl.locationId = $stateParams.id;
        var isIntervalRequestIsInProgress = false;
        var timeoutId = null;

        var colors = {
            color0: "#7cb6ec",
            color1: "#434348",
            color2: "#90ed7d"
        };

        ebctrl.$onInit = function () {
            loadPUEData();
            EnergyConsumerService.getOldParameterData().then(function (response) {
                if (response.data != null) {
                    ebctrl.oldconsumers = response.data;
                    ebctrl.consumerLength = ebctrl.oldconsumers.length;
                    if (ebctrl.consumerLength > 0) {
                        oldConsumersPresentDialoug();
                    }}
            });

            timeoutId = $interval(function () {
                loadPUEData();
            }, 30000);
            LocationDetailService.reset().setDefaultBackToOverview();

        };

        ebctrl.closeModal = function () {
            if (currentModal) currentModal.close();
        };


        ebctrl.MigrateAllModal = function () {
            EnergyConsumerService.updateOldParameterData().then(function (response) {
                if (response.data === 1) {
                        Notify.success("global.notification.success.title", "ebalance.updateSuccess2", 2000);
                        ebctrl.consider = true;
                }
            });
            ebctrl.closeModal();
        };


        ebctrl.manageOldConsumer = function (consumerE) {
                        EnergyConsumerService.updateOldParameterDataById(consumerE.id).then(function (response) {
                            if (response.data === 1) {
                                Notify.success("global.notification.success.title", "ebalance.updateSuccess1", 2000);
                                ebctrl.consider = true;
                                ebctrl.updateOldConsumerTable();
                            }
                        });
                          ebctrl.closeModal();
                    };

        ebctrl.manageConsumer = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/location/tabs/ebalance/manage.consumer.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope,
                size: 'lg',
                backdrop: 'static'
            });
        };

        ebctrl.createC = function () {
            if (ebctrl.editConsumer.loadTypeObject !== undefined)
                ebctrl.editConsumer.loadType = ebctrl.editConsumer.loadTypeObject.id;
                ebctrl.editConsumer.physicalType = ebctrl.editConsumer.loadTypeObject.id;

            if (invalid(ebctrl.editConsumer.name)) {
                Notify.error("global.notification.error.title", "location.notification.consumerName", 2000);
            }
            if (isLoadTypeValid(ebctrl.editConsumer.loadType)) {
                Notify.error("global.notification.error.title", "location.notification.consumerLoadType", 2000);
            }
            if (!invalid(ebctrl.editConsumer.name) && !isLoadTypeValid(ebctrl.editConsumer.loadType)) {
                EnergyConsumerService.saveConsumer(ebctrl.editConsumer).then(function (response) {
                    if (response.data === 1) {
                        if(ebctrl.editState){
                            Notify.success("global.notification.success.title", "ebalance.updateSuccess", 2000);
                            ebctrl.consider = true;
                        }else {
                            Notify.success("global.notification.success.title", "ebalance.createSuccess", 2000);
                            ebctrl.consider = true;
                        }
                        ebctrl.updateTable();
                        ebctrl.updateOldConsumerTable();
                        ebctrl.editConsumer = {
                            name: $translate.instant('room.consumer.newConsumer'),
                            consumer: false,
                            oldConsumer:false,
                            groupId: null,
                            id: null,
                            uniqueId: null
                        };
                        ebctrl.editState = false;
                    } else if (response.data === -1) {
                        Notify.error("global.notification.error.title", "room.consumer.isEmpty", 2000);
                    } else if (response.data === -2) {
                        Notify.error("global.notification.error.title", "room.consumer.isExists", 2000);
                    } else {
                        isSavingErrorNotify();
                    }
                }, function (error) {
                    $log.error("Error saving consumer\n" + error.data);
                    isSavingErrorNotify();
                });
            }
        };


        ebctrl.createOC = function () {
            if (ebctrl.editConsumer.loadTypeObject !== undefined)
                ebctrl.editConsumer.loadType = ebctrl.editConsumer.loadTypeObject.id;

            if (invalid(ebctrl.editConsumer.name)) {
                Notify.error("global.notification.error.title", "location.notification.consumerName", 2000);
            }
            if (isLoadTypeValid(ebctrl.editConsumer.loadType)) {
                Notify.error("global.notification.error.title", "location.notification.consumerLoadType", 2000);
            }
            if (!invalid(ebctrl.editConsumer.name) && !isLoadTypeValid(ebctrl.editConsumer.loadType)) {
                EnergyConsumerService.saveConsumer(ebctrl.editConsumer).then(function (response) {
                    if (response.data === 1) {
                        if(ebctrl.editState){
                            Notify.success("global.notification.success.title", "ebalance.updateSuccess", 2000);
                            ebctrl.consider = true;
                        }else {
                            Notify.success("global.notification.success.title", "ebalance.createSuccess", 2000);
                            ebctrl.consider = true;
                        }
                        ebctrl.updateOldConsumerTable();
                        ebctrl.editConsumer = {
                            name: $translate.instant('room.consumer.newConsumer'),
                            consumer: false,
                            oldConsumer:false,
                            groupId: null,
                            id: null,
                            uniqueId: null
                        };
                        ebctrl.editState = false;
                    } else if (response.data === -1) {
                        Notify.error("global.notification.error.title", "room.consumer.isEmpty", 2000);
                    } else if (response.data === -2) {
                        Notify.error("global.notification.error.title", "room.consumer.isExists", 2000);
                    } else {
                        isSavingErrorNotify();
                    }
                }, function (error) {
                    $log.error("Error saving consumer\n" + error.data);
                    isSavingErrorNotify();
                });
            }
        };

        ebctrl.checkLoadType = function() {
            if(ebctrl.editConsumer.loadTypeObject.id === 2) {
                ebctrl.consider = false;
                ebctrl.editConsumer.consumer = false;
                ebctrl.editConsumer.oldConsumer = false;
            } else {
                ebctrl.consider = true;
            }
        }

        ebctrl.updateTable = function() {
            EnergyConsumerService.getConsumer().then(function (response) {
                ebctrl.consumer = response.data;
            }, function (error) {
                $log.error("Error retrieving energy consumer\n" + error.data);
            })
        }

        ebctrl.updateOldConsumerTable = function() {
            EnergyConsumerService.getOldParameterData().then(function (response) {
                ebctrl.oldConsumer = response.data;
            }, function (error) {
                $log.error("Error updating energy consumer\n" + error.data);
            })
        }


        var invalid = function (consumerName) {
            if (consumerName === undefined) return true;
            if (consumerName === "") return true;
            if (consumerName === $translate.instant('room.consumer.newConsumer')) return true;
            return false;
        };

        var isLoadTypeValid = function (consumerLoadType) {
            if (consumerLoadType === undefined) return true;
            return false;
        };

        var isSavingErrorNotify = function () {
            Notify.error("global.notification.error.title", "room.notifications.saveError", 2000);
        };

        ebctrl.onConsumerNameChange = function (consumerName) {
            ebctrl.consumerNameExists = false;
            if (!invalid(consumerName)) {
                EnergyConsumerService.consumerNameExists(ebctrl.editConsumer).then(function (response) {
                    if (response.data === -2) {
                        ebctrl.consumerNameExists = true;
                    }
                }, function (error) {
                    console.error('Couldn\'t check consumer name.', error);
                });
            }

        };

        ebctrl.editC = function (consumerE) {
            if (ebctrl.allowEdit) {
                ebctrl.editState = true;
                ebctrl.editConsumer = consumerE;
                if (ebctrl.editConsumer.loadType !== undefined) {
                    ebctrl.editConsumer.loadTypeObject = ebctrl.loadOptions[ebctrl.editConsumer.loadType];
                }
                ebctrl.checkLoadType();
            }
        };

        ebctrl.deleteC = function (consumerD) {
            EnergyConsumerService.deleteConsumer(consumerD.id).then(function (response) {
                if (response.data === 1) {
                    Notify.defaultSuccess();
                    EnergyConsumerService.getConsumer().then(function (responseI) {
                        ebctrl.consumer = responseI.data;
                    });
                } else if (response.data === -1) {
                    Notify.error("global.notification.error.title", "ebalance.notification.error.isInUsed", 6000);
                } else {
                    Notify.error("global.notification.error.title", "room.notifications.deleteError", 2000);
                }
            }, function (error) {
                $log.error("Error deleting consumer\n" + error.data);
                Notify.error("global.notification.error.title", "room.notifications.deleteError", 2000);
            });
        };

        ebctrl.sortCols = function (col) {
            if (Math.abs(ebctrl.sortCol) == col) {
                ebctrl.sortCol *= -1;
            } else {
                ebctrl.sortCol = col;
            }
            switch (Math.abs(ebctrl.sortCol)) {
                case 1:
                    ebctrl.sortColImpl = ebctrl.sortCol < 0 ? "-name | translate" : "name | translate";
                    break;
                case 2:
                    ebctrl.sortColImpl = ebctrl.sortCol < 0 ? "-consumer" : "consumer";
                    break;
            }
        };

        ebctrl.sortColss = function (col) {
            if (Math.abs(ebctrl.sortCol) == col) {
                ebctrl.sortCol *= -1;
            } else {
                ebctrl.sortCol = col;
            }
            switch (Math.abs(ebctrl.sortCol)) {
                case 1:
                    ebctrl.sortColImpl = ebctrl.sortCol < 0 ? "-name | translate" : "name | translate";
                    break;
                case 2:
                    ebctrl.sortColImpl = ebctrl.sortCol < 0 ? "-oldConsumer" : "oldConsumer";
                    break;
            }
        };


        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
        });

        var loadPUEData = function () {
            if (!isIntervalRequestIsInProgress) {
                isIntervalRequestIsInProgress = true;
                EnergyConsumerService.getEnergyByLocId(parseInt($stateParams.id)).then(function (response) {
                    if (response.data != null) {
                        ebctrl.energyFactors = response.data.energyFactors;
                        var realTime = response.data.realtimeValue;
                        ebctrl.realtimeValue.itLoadValue = ebctrl.modValue(realTime.itload);
                        ebctrl.realtimeValue.itload = ebctrl.modValue(realTime.itload) + " " + realTime.unit;
                        ebctrl.realtimeValue.facilityLoadValue = ebctrl.modValue(realTime.facilityLoad);
                        ebctrl.realtimeValue.facilityLoad = ebctrl.modValue(realTime.facilityLoad) + " " + realTime.unit;
                        if (realTime.pue !== -1) {
                            ebctrl.realtimeValue.pue = ebctrl.modValue(realTime.pue);
                        } else {
                            ebctrl.realtimeValue.pue = "- - -";
                        }
                        ebctrl.realtimeValue.unit = realTime.unit;
                        ebctrl.calculateFacilityCost();
                        createGaugeChart();
                        isIntervalRequestIsInProgress = false;
                    }
                }, function (error) {
                    $log.error('Error retrieving Enery Factors\n' + error.data)
                    isIntervalRequestIsInProgress = false;
                });
            }

            EnergyConsumerService.getTranslations().then(function (trans) {
                ebctrl.trans = trans;
            }, function (error) {
                ebctrl.trans = [];
            });
        }

        function oldConsumersPresentDialoug() {
            $translate(['global.dialog.head.warning', 'ebalance.alertDialoug', 'global.btn.migrateAll']).then(function (trans) {
                GenDialogService.showDialog(false, {
                    headText: trans['global.dialog.head.warning'],
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('ebalance.alertDialoug',{test:ebctrl.consumerLength}),
                    showClose: true,
                    textButton0: $translate.instant('global.btn.migrateAll'),
                    iconButton0: 'glyphicon glyphicon-ok',
                    classButton0: 'btn-default',
                    callbackButton0: function () {
                        ebctrl.MigrateAllModal();
                        GenDialogService.hideDialog();
                        GenDialogService.hideDialog();
                            currentModal = $uibModal.open({
                                templateUrl: 'scripts/app/location/tabs/ebalance/oldconsumer.modal.html',
                                animation: true,
                                windowClass: 'animated fadeInDown',
                                scope: $scope,
                                size: 'lg',
                                backdrop: 'static'
                            });

                    },
                });
            });
        }

        ebctrl.calculateFacilityCost = function () {
            var result = ebctrl.realtimeValue.facilityLoad.split(" ");
            ebctrl.facilityCost = "€ " + (result[0] * ebctrl.costPerKwH / 100).toFixed(2) + " / hour";
        };

        ebctrl.translateIsConsumerInUse = function (value) {
            if (value) {
                return $translate.instant("ebalance.energyFactor.info.yes");
            } else {
                return $translate.instant("ebalance.energyFactor.info.no");
            }
        };

        ebctrl.translateLoadType = function (value) {
            if (value === 0) {
                return $translate.instant("ebalance.energyFactor.loadType.facility");
            } else if (value === 1) {
                return $translate.instant("ebalance.energyFactor.loadType.it");
            } else {
                return $translate.instant("ebalance.energyFactor.loadType.other");
            }
        };
        ebctrl.translatePhysicalType = function (value) {
            if (value === 12) {
                return $translate.instant("consumer");
            }
        };

        ebctrl.modValue = function (value) {
            return (value / 1000).toFixed(2);
        };

        /**
         * With this you wrap the colorizeItem function of the Legend class from HighCharts.js
         * and you can set the legendColor attribute to set the symbols color in the Gauge Chart legend
         */
        (function (H) {
            H.wrap(H.Legend.prototype, 'colorizeItem', function (proceed, item, visible) {
                item.legendColor = item.options.legendColor;
                proceed.apply(this, Array.prototype.slice.call(arguments, 1));
            });
        }(Highcharts));

        var createGaugeChart = function () {
                ebctrl.chart = Highcharts.chart('pue-container', {

                    chart: {
                        type: 'solidgauge'
                    },
                    legend: {
                        backgroundColor: '#FFFFFF',
                        shadow: true,
                        align: 'left',
                        verticalAlign: 'top',
                        layout: 'vertical',
                        floating: true,
                        x: 90,
                        y: 45,
                        itemHiddenStyle: {
                            color: 'red'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                            enabled: false
                    },
                    tooltip: {
                        borderWidth: 0,
                        backgroundColor: 'none',
                        shadow: false,
                        style: {
                            fontSize: '16px'
                        },
                        formatter: function () {
                            if(this.series.name !== $translate.instant("ebalance.realtimeValues.header.pue")) {
                                return this.series.name + '<br><span style="font-weight: bold; font-size:1.5em; color: ' + this.point.color + '">' + this.point.y + ' ' + ebctrl.realtimeValue.unit +'</span>';
                            } else {
                                return this.series.name + '<br><span style="font-weight: bold; font-size:1.5em; color: ' + this.point.color + '">' + ebctrl.realtimeValue.pue +'</span>';
                            }
                        },

                        positioner: function (labelWidth) {
                            return {
                                x: (this.chart.chartWidth - labelWidth) / 2,
                                y: (this.chart.plotHeight / 2) + 15
                            };
                        }
                    },
                    title: {
                        text: 'PUE Gauge Chart',
                        style: {
                            fontSize: '24px'
                        }
                    },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [
                            createPane("112%", "88%", colors.color0),
                            createPane("87%", "63%", colors.color1),
                            createPane("62%", "38%", colors.color2)
                        ]
                    },
                    yAxis: {
                        min: 0,
                        max: 360,
                        lineWidth: 0,
                        tickPositions: []
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            stickyTracking: false,
                            showInLegend: true
                        }
                    },
                    series: [
                        createSeries($translate.instant("ebalance.realtimeValues.header.facilityLoad"), '112%', '88%', colors.color0, parseFloat(ebctrl.realtimeValue.facilityLoadValue)),
                        createSeries($translate.instant("ebalance.realtimeValues.header.ITLoad"), '87%', '63%', colors.color1, parseFloat(ebctrl.realtimeValue.itLoadValue)),
                        createSeries($translate.instant("ebalance.realtimeValues.header.pue"), '62%', '38%', colors.color2, 360/parseFloat(ebctrl.realtimeValue.pue))
                    ]

                }
                );
        }

        function createSeries (label, radius, innerRadius, color, value) {
            return {
                name: label,
                legendColor: color,
                data: [{
                    color: color,
                    radius: radius,
                    innerRadius: innerRadius,
                    y: value
                }]
            }
        }

        function createPane(outerRadius, innerRadius, backgroundColor) {
            return {
                outerRadius: outerRadius,
                innerRadius: innerRadius,
                backgroundColor: Highcharts.Color(backgroundColor).setOpacity(0.3).get(),
                borderWidth: 1
            };
        }

        ebctrl.openPueSettings = function () {
            ebctrl.isPueSettingsOpen = true;
        };
    }

    angular
        .module('emsv2App')
        .controller('ebalanceController', ebalanceController);

})();


