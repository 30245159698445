(function () {

    'use strict';

    function deleteUserController($state, $timeout, Notify, User,
                                  groupLocations, userLocationList, userInfo, countries, roles, groups, locations, $translate) {
        var ctrl = this;
        ctrl.deleteBtn = 'init';
        ctrl.isDetail = true;
        ctrl.user = userInfo;
        ctrl.roles = roles;
        ctrl.groups = groups;
        ctrl.countries = countries;
        ctrl.locationList = locations; // List of all available locations
        ctrl.userLocations = userLocationList;
        ctrl.groupLocations = groupLocations;
        ctrl.placeholder = $translate.instant('global.form.select');

        ctrl.accept = function () {
            ctrl.deleteBtn = 'load';
            User.remove(ctrl.user.id).then(function () {
                ctrl.deleteBtn = 'success';
                Notify.defaultSuccess();
                $timeout(function () {
                    $state.go('userOverview');
                }, 2000);
            }, function (err) {
                ctrl.deleteBtn = 'error';
                console.warn(err);
                Notify.error("global.notification.error.title", "user.delete.error.lastAdmin", 5000);
            });
        };
    }

    angular
        .module('emsv2App')
        .controller('DeleteUserController', deleteUserController);

})();
