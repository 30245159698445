(function () {
    'use strict';
    angular
        .module('emsv2App')
        .service('ChangeService', ['$log', '$http', function ($log, $http) {

            return {
                getListOfOjects: function (typeEnumId, listOfIds, timeInMilis) {
                    //find enum for typeEnumId in backend under DataTypes.java
                    return $http.post("/api/change/" + typeEnumId + "/" + timeInMilis + "/getNewList", listOfIds)
                },
                getServerTime: function () {
                    return $http.get("/api/change/time").then(function (response) {
                        return response.data;
                    }, function (error) {
                        $log.error("Error getting current server time");
                        return error;
                    });
                }
            }
        }]);
})();
