(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name Notify
     */
    angular.module('emsv2App')
        .service('Notify', ['notify', '$translate', function (notify) {
            var error = {
                title: "global.notification.error.title",
                message: "global.notification.error.text"
            };
            var success = {
                title: "global.notification.success.title",
                message: "global.notification.success.text"
            };
            var globalDuration = 2000;
            return {
                /**
                 * @functions default
                 */
                defaultError: function () {
                    notify({
                        messageTemplate: '<strong translate="' + error.title + '"></strong><br>' +
                        '<span translate="' + error.message + '"></span>',
                        classes: 'alert-danger',
                        duration: globalDuration
                    });
                },
                defaultSuccess: function () {
                    notify({
                        messageTemplate: '<strong translate="' + success.title + '"></strong><br>' +
                        '<span translate="' + success.message + '"></span>',
                        classes: 'alert-success',
                        duration: globalDuration
                    });
                },
                /**
                 * @functions custom
                 */
                error: function (jsonTitle, jsonMessage, duration) {
                    notify({
                        messageTemplate: '<strong translate="' + jsonTitle + '"></strong><br>' +
                        '<span translate="' + jsonMessage + '"></span>',
                        classes: 'alert-danger',
                        duration: duration
                    });
                },
                success: function (jsonTitle, jsonMessage, duration) {
                    notify({
                        messageTemplate: '<strong translate="' + jsonTitle + '"></strong><br>' +
                        '<span translate="' + jsonMessage + '"></span>',
                        classes: 'alert-success',
                        duration: duration
                    });
                },
                info: function (jsonTitle, jsonMessage, duration) {
                    notify({
                        messageTemplate: '<strong translate="' + jsonTitle + '"></strong><br>' +
                        '<span translate="' + jsonMessage + '"></span>',
                        classes: 'alert-info',
                        duration: duration
                    });
                },
                warning: function (jsonTitle, jsonMessage, duration) {
                    notify({
                        messageTemplate: '<strong translate="' + jsonTitle + '"></strong><br>' +
                        '<span translate="' + jsonMessage + '"></span>',
                        classes: 'alert-warning',
                        duration: duration
                    });
                }
            };
        }]);
})();
