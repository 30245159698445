(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name Location
     * @requires $http, EMSConstants
     */
    angular.module('emsv2App').factory('Location', function ($http, $q, $log, EMSConstants,License) {

        // New tabs have to be added here
        // Device tab is removed according too the requirements of task CGLT-768 and can be included again, if necessary
        var availableTabs = [
            {
                name: 'Overview',
                id: "tab-location-overview",
                icon: 'icon-location-globe',
                index: 0,
                state: 'location.overview',
                translation: 'overview'
            },
            {
                name: 'Rooms',
                id: "tab-location-rooms",
                icon: 'icon-rooms',
                index: 3,
                state: 'location.rooms',
                translation: 'rooms',
                license: '3droomedit'
            },
            {
                name: 'Buildings',
                id: "tab-location-buildings",
                icon: 'icon-building',
                index: 2,
                state: 'location.buildings',
                translation: 'buildings'
            },
            {
                name: 'Alarms/Errorlogs',
                id: "tab-location-errors",
                icon: 'icon-alert',
                index: 5,
                state: 'location.alarm',
                translation: 'alarms.title',
                license: 'alarms'
            },
            {
                name: 'Live Data',
                id: "tab-location-live-data",
                icon: 'icon-livedata',
                index: 6,
                state: 'location.livedata',
                translation: 'liveData',
                license: 'livedata'
            },
            {
                name: 'Energy Balance',
                id: "tab-location-energy",
                icon: 'icon-balance',
                index: 7,
                state: 'location.ebalance',
                translation: 'ebalance',
                license: 'pue/energy_tab'
            },
        ];
        
        return {
            getTimeZones: function () {
                return $http.get('/api/timezones/all');
            },
            getAvailableTabs: function () {
                return availableTabs.sort(function (a, b) { return a.index - b.index; });
            },
            query: function () {
                return $http.get('api/locations');
            },
            /** Compact list of all locations for select boxes. Contains id, custom id & name. */
            queryCompact: function () {
                return $http.get('api/locations/compact');
            },
            queryPage: function (optLimit, optOffset) {
                var offset = optOffset || 0;
                var limit = optLimit || 20;
                return $http.get('api/locations', {
                    params: {
                        page: offset,
                        per_page: limit
                    }
                });
            },
            queryAll: function () {
                // Query every location if admin, returns the same as query for non admins
                return $http.get('api/locations/all');
            },
            get: function (id) {
                return $http.get('api/locations/' + id);
            },
            isEmpty: function (id) {
                return $http.get('api/locations/isEmpty/' + id);
            },
            update: function (location) {
                return $http.put('api/locations', location);
            },
            delete: function (id) {
                return $http.delete('api/locations/' + id);
            },
            post: function (location) {
                return $http.post('api/locations', location);
            },
            getDevices: function (locationId) {
                return $http.get('api/locations/' + locationId + '/devices');
            },
            getWirelessGatewayParents: function (locationId) {
                return $http.get('api/locations/wirelessGatewayParents/' + locationId + '/devices');
            },
            getCount: function () {
                return $http.get('api/locations/count');
            },
            getUsers: function (locationId) {
                return $http.get('api/locations/' + locationId + '/users');
            },
            setUsers: function (locationId, users) {
                return $http.put('api/locations/' + locationId + '/users', users);
            },
            getGroups: function (locationId) {
                return $http.get('api/locations/' + locationId + '/groups');
            },
            setGroups: function (locationId, groups) {
                return $http.put('api/locations/' + locationId + '/groups', groups);
            },
            rsqlDevice: function (page, query, locationId) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/locations/' + locationId + '/devices/query/' + page + '/20/' + query);
            },
            rsqlDeviceItemCount: function (page, query, locationId, itemCount) {
                page = page !== undefined && page !== null ? page : 0;
                query = query !== undefined && query !== null ? query : '';
                return $http.get('api/locations/' + locationId + '/devices/query/' + page + '/' + itemCount + '/' + query);
            },
            geocode: function (searchTerm, langKey) {
                if (typeof langKey === "string" && langKey !== "de" && langKey !== "en") langKey = "de";
                return $http.get('https://nominatim.openstreetmap.org/search?format=json&limit=1&q=%TERM%&accept-language=%LANG%'
                    .replace('%TERM%', searchTerm)
                    .replace('%LANG%', langKey));
            },
            analysisLocations: function () {
                return $http.get('api/locations/all/minimal/analysis');
            }
        };
    });

})();
