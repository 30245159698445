/**
 * SmtpSMSServer
 * @ngdoc service
 * @name SmtpSMSServerConfigurationService
 * @requires $http, $log, $q
 */
angular.module('emsv2App').service('SmtpSMSServerConfigurationService', ['$http', function ($http) {
    return {
        get: function () {
            return $http.get('/api/smtpSMSServerConfiguration/get');
        },
        test: function (smtpSMSGatewayConfiguration) {
            return $http.post('/api/smtpSMSServerConfiguration/test', smtpSMSGatewayConfiguration);
        },
        save: function (smtpSMSGatewayConfiguration) {
            return $http.post('/api/smtpSMSServerConfiguration/save', smtpSMSGatewayConfiguration);
        },
    }
}]);