(function () {

    'use strict';

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('legal', {
                abstract: false,
                parent: 'site',
                url: '/legal',
                redirectTo: 'legal.imprint',
                data: {
                    pageTitle: 'global.menu.legal.pageTitle',
                    breadcrumbsTitle: 'global.menu.legal.main'
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            });
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
