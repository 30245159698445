(function () {

    'use strict';

    function WidgetDateController($scope, $log, $element, $interval, $uibModal, $http, WidgetData, Language, Notify, $state) {
        var ctrl = this;
        var modalInstance;
        ctrl.timeZones = null;
        ctrl.state = $state.current.name;
        ctrl.dateFormat = [
            {label: 'Monday, 31. January', value: 'dddd, DD. MMMM YYYY'},
            {label: '01/31/2017', value: 'MM/D/YYYY'},
            {label: '31.01.2017', value: 'DD.MM.YYYY'},
            {label: '31-01-2017', value: 'DD-MM-YYYY'},
            {label: '31/01/17', value: 'DD/MM/YY'},
            {label: '31.01.17', value: 'DD.MM.YY'},
            {label: '01-31-17', value: 'MM-DD-YY'},
            {label: '31-Jan-2017', value: 'DD-MMM-YYYY'},
            {label: '31-January-2017', value: 'DD-MMMM-YYYY'},
            {label: 'Mon, 31. January 2017', value: 'ddd, DD. MMMM YYYY'},
            {label: 'Monday, 31. January 2017', value: 'dddd, DD. MMMM YYYY'}
        ];
        ctrl.timeFormat = [
            {label: '24h', value: 'HH:mm:ss'},
            {label: '12h AM / PM', value: 'hh:mm:ss a'}
        ];
        ctrl.currentTimeZone = "Europe/Berlin";
        ctrl.currentTimeFormat = ctrl.timeFormat[0];
        ctrl.currentDateFormat = ctrl.dateFormat[0];
        ctrl.currentTime = '';
        ctrl.currentDate = '';
        ctrl.locale = 'en';
        ctrl.resize = false;

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(_showOptions);
            _loadSettings();
            Language.getCurrent().then(function (response) {
                ctrl.locale = response;
            });

            if ($scope.isreportwidget) {
                var multiplier = 1;
                var interval = $interval(function () {
                    var elementHeight = $($element).children().eq(0).innerHeight()
                        + parseInt($($element).find('h1').css('margin-top'))
                        + parseInt($($element).find('h5').css('margin-bottom'));
                    if (elementHeight > $($element).parents().eq(2).outerHeight() && multiplier > .4) {
                        multiplier *= 0.9;
                        $($element).find('.info-time').css({
                            'font-size': (200 * multiplier) + '%',
                            'margin-top': (15 * multiplier) + 'px',
                            'margin-bottom': (5 * multiplier) + 'px'
                        });
                        $($element).find('.info-date').css({
                            'font-size': (115 * multiplier) + '%',
                            'margin-top': (5 * multiplier) + 'px',
                            'margin-bottom': (5 * multiplier) + 'px'
                        });
                        $($element).find('.info-timezone').css({
                            'font-size': (90 * multiplier) + '%',
                            'margin-top': (5 * multiplier) + 'px',
                            'margin-bottom': (5 * multiplier) + 'px'
                        });
                    }
                    else {
                        $interval.cancel(interval);
                    }
                });
            }
        };

        ctrl.saveSettings = function () {
            var newSettings = [];
            newSettings.push({key: 'timezone', value: ctrl.currentTimeZone});
            newSettings.push({key: 'dateformat', value: ctrl.currentDateFormat.value});
            newSettings.push({key: 'timeformat', value: ctrl.currentTimeFormat.value});
            $scope.widget.settings = newSettings;
            $log.debug("Saving Settings:", $scope.widget.settings);

            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    ctrl.closeModal();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.date.saveErrorMsg", 2000);
                });
            } else {
                ctrl.closeModal();
            }
        };

        function getFormatByValue(value, format) {
            for (var i = 0; i < format.length; i++) {
                if (format[i].value === value) {
                    return format[i];
                }
            }
            return null;
        }

        function _loadSettings() {
            if ($scope.widget.settings !== null) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    if ($scope.widget.settings[i].key === 'timeformat') {
                        ctrl.currentTimeFormat = getFormatByValue($scope.widget.settings[i].value, ctrl.timeFormat);
                    }
                    else if ($scope.widget.settings[i].key === 'dateformat') {
                        ctrl.currentDateFormat = getFormatByValue($scope.widget.settings[i].value, ctrl.dateFormat);
                    }
                    else if ($scope.widget.settings[i].key === 'timezone') {
                        ctrl.currentTimeZone = $scope.widget.settings[i].value;
                    }
                }
            }
            else {
                $log.error('Couln\'t load date widget settings!');
            }
            return null;
        }

        ctrl.updateDate = function () {
            var out = moment().locale(ctrl.locale).tz(ctrl.currentTimeZone);
            ctrl.currentDate = out.format(ctrl.currentDateFormat.value);
            ctrl.currentTime = out.format(ctrl.currentTimeFormat.value);
        };

        ctrl.closeModal = function () {
            modalInstance.close();
        };

        function _showOptions() {
            $http.get('/api/timezones/all').then(function (response) {
                ctrl.timeZones = response.data || null;
                modalInstance = $uibModal.open({
                    templateUrl: 'scripts/app/dashboard/widget.templates/date/widget.date.settings.modal.html',
                    animation: false,
                    windowClass: 'animated fadeInDown',
                    scope: $scope
                });
            }, function () {
                $log.error('Could not acquire timezones');
            });
        }
    }

    angular.module('emsv2App')
        .controller('WidgetDateController', WidgetDateController);

})();
