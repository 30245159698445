var myApp = angular.module('emsv2App');

myApp.service('WidgetRacksService', function ($http) {

    function getAvailableRooms(macList) {
        return $http
            .post('/api/dashboard/getRoomsWithRacksForMac/', macList);
    }

    function getAvailableRoomsForLocation(locationId) {
        return $http
            .get('/api/dashboard/getAvailableLocationRooms/' + locationId);
    }

    function getAllLocationsForMac(macList) {
        return $http
            .post('/api/dashboard/getAllLocationsForMac/', macList);
    }

    function getLocationRacksForRoom(room) {
        return $http
            .get('/api/dashboard/' + room + '/getAvailableRacks');
    }

    return {
        getAvailableRooms: getAvailableRooms,
        getAvailableRoomsForLocation:getAvailableRoomsForLocation,
        getLocationRacksForRoom:getLocationRacksForRoom,
        getAllLocationsForMac: getAllLocationsForMac
    };
});
