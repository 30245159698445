(function () {
    'use strict';

    /**
     * @description directive to build tree view for given room
     */
    angular.module("emsv2App").directive('roomTree', function ($translate, MessageService, RoomService, WebGLService) {
        /**
         * @description function to build array object to work with tree
         * @param {array} arr the array to use
         * @param {Room} room the room object to build tree for
         * @param {string} rackFolderName the name for the folder object containing all rack information of given room
         * @param {string} coolingFolderName the name for the folder object containing all cooling unit information of given room
         * @param {string} sensorFolderName the name for the folder object containing all sensor information of given room
         * @param {string} assetFolderName the name for the folder object containing all asset information of given room
         * @param {string} floorTileFolderName the name for the folder object containing all floor tile information of given room
         * @param {string} ventductFolderName the name for the folder object containing all vent duct information of given room, currently not used
         * @param {string} upsFolderName the name for the folder object containing all ups information of given room
         * @param {string} containmentFolderName the name for the folder object containing all containment information of given room
         * @returns {Array} returns array to be used in tree component
         */
        var buildTreeArray = function (arr, room, rackFolderName, coolingFolderName, sensorFolderName, assetFolderName, floorTileFolderName, ventductFolderName, upsFolderName, containmentFolderName) {
            return RoomService.buildRoomTreeContent(arr, room, rackFolderName, coolingFolderName, sensorFolderName, assetFolderName, floorTileFolderName, ventductFolderName, upsFolderName, containmentFolderName, false);
        };
        return {
            scope: {
                // room object
                room: '=roomobj',
                // whether to allow selection or not
                allowselection: '=',
                treeSearchTerm: "=tsearchTerm",
                searchComparator: '='
            },
            controller: function ($scope) {
                $scope.selectedNode = null;
                $scope.expandedNodes = [];
                $scope.treeOpts = {
                    nodeChildren: "children",
                    dirSelectable: true,
                    multiSelection: false
                };
                $scope.treeData = [];
                /**
                 * @description function to handle selection see https://wix.github.io/angular-tree-control/#events
                 * @param {object} node node object that is being selected/deselected
                 * @param {boolean} selected if true node should is selected, otherwise node is unselected
                 */
                $scope.handleSelect = function (node, selected) {
                    if (selected) {
                        $scope.selectedNode = node;
                        if ($scope.allowselection && !node.folder) {
                            MessageService.publish("user:selectedItemTree", node);
                            WebGLService.unmarkObject(undefined, true);
                            var selectedObj = WebGLService.findObjectByUniqueId(node.uid);
                            WebGLService.markObject(selectedObj, undefined, true);
                        }
                    }
                    if (!selected) {
                        $scope.selectedNode = undefined;
                    }
                };
                $scope.$watch('room', function (n, o) {

                    if (n !== undefined) {
                        $translate(['room.tree.rackFolder', 'room.tree.sensorFolder', 'room.tree.coolingFolder', 'room.tree.assetFolder', 'room.tree.floorTileFolder', 'room.tree.ventductFolder', 'room.tree.upsFolder', 'room.tree.containmentFolder']).then(function (translations) {
                            buildTreeArray($scope.treeData, $scope.room, translations['room.tree.rackFolder'], translations['room.tree.coolingFolder'], translations['room.tree.sensorFolder'], translations['room.tree.assetFolder'], translations['room.tree.floorTileFolder'], translations['room.tree.ventductFolder'], translations['room.tree.upsFolder'], translations['room.tree.containmentFolder'], false);
                        }, function () {
                            buildTreeArray($scope.treeData, $scope.room, 'Racks', 'Cooling units', 'Sensors', 'Assets', 'Floor tiles', 'Vent ducts', 'UPS', 'Cold aisle containments');
                        });
                    }
                }, true);
            },
            template: "<treecontrol tree-model='treeData' options='treeOpts' on-selection='handleSelect(node, selected)' selected-node='selectedNode' expanded-nodes='expandedNodes' filter-expression='treeSearchTerm' filter-comparator='searchComparator'>" +
            "<p ng-class='{\"tree-full-line\":allowselection, \"tree-full-line-noselect\":!allowselection}'><span class='icon-{{node.typeName}}'><!--{{node.typeName}}--></span>{{node.name | translate}}</p><span style='display:none'>{{node.id}}</span></treecontrol>", //functional node.typeName-like needed for displaying corresponding icons
            link: function (scope, elem, attrs) {
                scope.treeOpts = {
                    nodeChildren: "children",
                    dirSelectable: false,
                    multiSelection: false,
                    injectClasses: {
                        iExpanded: 'fa fa-caret-down',
                        iCollapsed: 'fa fa-caret-right'
                    }
                };
            }
        };
    });
})();
